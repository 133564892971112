import React, { useEffect, useContext, useState, useRef } from 'react';
import { toJS } from 'mobx';
import FadeIn from 'react-fade-in';
import { GlobalHotKeys } from 'react-hotkeys';
import { Observer } from 'mobx-react-lite';
import List, { ItemDragging } from 'devextreme-react/list';
import DataSource from 'devextreme/data/data_source';

import QuickDrawerHeader from '../../../../_shared/QuickDrawerHeader';
import { quickDrawerFocus } from '../../../../_shared/QuickDrawer';
import RichTextEditor from '../../../../_shared/RichTextEditor';

import MedicalReportTenantExamDatasetGroupCreateStore from '../../../../../../stores/MedicalReportTenantExamDatasetGroupCreateStore';
import QuickDrawerStore from '../../../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../../../constants/errorMessages';
import * as fn from '../../../../../../utilities/_functions';
import * as oh from '../../../../../../utilities/operationHelper';

import './NewMedicalReportTenantExamDatasetGroup.scss';

function NewMedicalReportTenantExamDatasetGroup(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const newMedicalReportTenantExamDatasetGroup = useContext(MedicalReportTenantExamDatasetGroupCreateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [selectedExamDatasets, setSelectedExamDatasets] = useState([]);

    useEffect(() => {
        focusTimer.current = setTimeout(() => {
            setFocusToName();
        }, 100)

        return (() => {
            isMounted.current = false;
            newMedicalReportTenantExamDatasetGroup.clear();
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        })
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (newMedicalReportTenantExamDatasetGroup.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = event => {
        event.preventDefault();
        newMedicalReportTenantExamDatasetGroup.data.examDatasetIds = selectedExamDatasets.sort((a, b) => a.displayOrder - b.displayOrder).map(d => { return d.id })
        newMedicalReportTenantExamDatasetGroup.save()
            .then(data => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, data);
                    }
                }
            })
    }

    const handleNameChange = event => {
        newMedicalReportTenantExamDatasetGroup.data.name = event.target.value;
        newMedicalReportTenantExamDatasetGroup.data.key = event.target.value.replace(/[^0-9a-zA-Z]/g, '');
        newMedicalReportTenantExamDatasetGroup.hasUnsavedChanges = true;
    }

    const handleDescriptionChange = content => {
        const html = content;
        newMedicalReportTenantExamDatasetGroup.data.descriptionHtml = ((html === '<p><br></p>') ? null : html);
        newMedicalReportTenantExamDatasetGroup.hasUnsavedChanges = true;
    }

    const handleExamDatasetReorder = (event) => {
        const { toIndex, fromIndex } = event;
        let tempExamDatasets = [...selectedExamDatasets];

        tempExamDatasets.splice(fromIndex, 1);
        tempExamDatasets.splice(toIndex, 0, toJS(selectedExamDatasets[fromIndex]));

        for (let i = 0; i < tempExamDatasets.length; i++) {
            tempExamDatasets[i].displayOrder = (i + 1);
        }

        tempExamDatasets = tempExamDatasets.sort((a, b) => a.displayOrder - b.displayOrder);

        setSelectedExamDatasets(tempExamDatasets);
        newMedicalReportTenantExamDatasetGroup.hasUnsavedChanges = true;
    }

    const handleSelectDataset = event => {
        const extraProps = {
            examPublishedTemplateId: newMedicalReportTenantExamDatasetGroup.data.examPublishedTemplateId,
            selectedDatasets: selectedExamDatasets
        };
        quickDrawer.activateQuickDrawer('medicalReport', 'exam-data', extraProps, handleAddDatasetSuccess, handleAddDatasetCancel)
            .then(drawer => {
                if (isMounted.current) {
                    focusTimer.current = setTimeout(() => {
                        quickDrawerFocus(drawer);
                    }, 100);
                }
            });
    }

    const handleAddDatasetSuccess = (result) => {
        if (result && result.updated) {
            let tempExamDatasets = selectedExamDatasets && selectedExamDatasets.length > 0 ? selectedExamDatasets : [];
            const addExamDatasets = result.data.items.filter(i => !tempExamDatasets.some(ti => ti.id === i.id));

            tempExamDatasets = tempExamDatasets.filter(ti => result.data.items.some(i => ti.id === i.id));

            if (addExamDatasets && addExamDatasets.length > 0) {
                tempExamDatasets = Array.from(new Set([...tempExamDatasets, ...addExamDatasets]));
            }

            setSelectedExamDatasets(tempExamDatasets);
            newMedicalReportTenantExamDatasetGroup.hasUnsavedChanges = true;
        }
    }

    const handleAddDatasetCancel = () => {
    }

    const renderExamDataset = (item, index) => {
        return <>
            <div className={'form-group mb-0' + (index < (selectedExamDatasets.length - 1) ? ' border-bottom' : '')}>
                <div className='cursor-pointer'>
                    <h4 className='m-0 text-truncate text-truncate-xxl'><strong>{item.label}</strong></h4>
                    <small>{item.key}</small>
                </div>
            </div>
        </>
    }

    const setFocusToName = () => {
        setTimeout(() => {
            const element = document.querySelector('#new-medical-report-tenant-exam-dataset-group-name');
            if (element) {
                element.focus();
            }
        }, 100)
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form onSubmit={handleSubmit}>
            <fieldset disabled={newMedicalReportTenantExamDatasetGroup.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('exam-dataset', 'default')}
                        action='New'
                        category='Medical Report Exam Dataset Group'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        <FadeIn>
                            <div className='new-medical-report-exam-dataset body-content'>
                                <FadeIn>
                                    <section>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='form-group mb-4'>
                                                    <label className='required' htmlFor='new-medical-report-tenant-exam-dataset-group-name'><small>Name</small></label>
                                                    <Observer>{() =>
                                                        <input
                                                            id='new-medical-report-tenant-exam-dataset-group-name'
                                                            type='text'
                                                            maxLength={100}
                                                            className='form-control'
                                                            autoComplete={'off'}
                                                            value={newMedicalReportTenantExamDatasetGroup.data.name ? newMedicalReportTenantExamDatasetGroup.data.name : ''}
                                                            onChange={handleNameChange}
                                                        />
                                                    }</Observer>
                                                    <small className='text-gray-700'><strong>Important:</strong> Name must be unique across all dataset.</small>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section>
                                        <div className='row datasets'>
                                            <div className='col-12'>
                                                <label><small>Exam Dataset(s)</small></label>
                                                <div>
                                                    <List
                                                        dataSource={
                                                            new DataSource({
                                                                key: "id",
                                                                store: selectedExamDatasets,
                                                                paginate: false,
                                                            })
                                                        }
                                                        activeStateEnabled={false}
                                                        focusStateEnabled={false}
                                                        hoverStateEnabled={false}
                                                        itemRender={(data) => { return renderExamDataset(data) }}
                                                    >
                                                        <ItemDragging
                                                            allowReordering={true}
                                                            onReorder={handleExamDatasetReorder}
                                                        >
                                                        </ItemDragging>
                                                    </List>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section>
                                        <Observer>{() =>
                                            <>
                                                {
                                                    showAdvanced ?
                                                        <>
                                                            <FadeIn>
                                                                <Observer>{() =>
                                                                    <div className='row'>
                                                                        <div className='col-12'>
                                                                            <div className='form-group mb-4'>
                                                                                <label htmlFor='new-exam-dataset-description'><small>Description</small></label>
                                                                                <RichTextEditor
                                                                                    mode='none'
                                                                                    disableTab={true}
                                                                                    value={newMedicalReportTenantExamDatasetGroup.data.descriptionHtml ? newMedicalReportTenantExamDatasetGroup.data.descriptionHtml : ''}
                                                                                    onChange={handleDescriptionChange}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>}</Observer>
                                                            </FadeIn>
                                                        </> :
                                                        <div className='mt-4 pt-4 border-top'>
                                                            <button type='button' className='btn btn-link btn-sm p-0' onClick={() => { setShowAdvanced(true) }}>&raquo;	Show advanced options</button>
                                                        </div>
                                                }
                                            </>}</Observer>
                                    </section>
                                </FadeIn>
                            </div>
                        </FadeIn>
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-4'>
                                <button
                                    type='button'
                                    className='btn btn-light'
                                    onClick={handleSelectDataset}
                                >
                                    Add
                                </button>
                            </div>
                            <div className='col-8'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset >
        </form >
    </>
}

export default NewMedicalReportTenantExamDatasetGroup;