import React from 'react';
import uuid from 'react-uuid';
import moment from 'moment';

import * as PageSettings from '../constants/pageSettings';
import * as fn from './_functions';
import * as ph from './personHelper';
import * as fm from './formatHelper';

export const REFERRAL_TEMPLATE_PAGE_SETTINGS_PAPER_LETTER_WIDTH = PageSettings.PAPER_LETTER_WIDTH;
export const REFERRAL_TEMPLATE_PAGE_SETTINGS_PAPER_LETTER_HEIGHT = PageSettings.PAPER_LETTER_HEIGHT;
export const REFERRAL_TEMPLATE_PAGE_SETTINGS_PAPER_A4_WIDTH = PageSettings.PAPER_A4_WIDTH;
export const REFERRAL_TEMPLATE_PAGE_SETTINGS_PAPER_A4_HEIGHT = PageSettings.PAPER_A4_HEIGHT;
export const REFERRAL_TEMPLATE_PAGE_SETTINGS_PAPER_LEGAL_WIDTH = PageSettings.PAPER_LEGAL_WIDTH;
export const REFERRAL_TEMPLATE_PAGE_SETTINGS_PAPER_LEGAL_HEIGHT = PageSettings.PAPER_LEGAL_HEIGHT;
export const REFERRAL_TEMPLATE_PAGE_SETTINGS_MARGINS_NARROW = PageSettings.MARGINS_NARROW;
export const REFERRAL_TEMPLATE_PAGE_SETTINGS_MARGINS_NORMAL = PageSettings.MARGINS_NORMAL;
export const REFERRAL_TEMPLATE_PAGE_SETTINGS_MARGINS_WIDE = PageSettings.MARGINS_WIDE;
export const REFERRAL_TEMPLATE_PAGE_SETTINGS_HEADER_FOOTER_HEIGHT_MIN = PageSettings.HEADER_FOOTER_HEIGHT_MIN;
export const REFERRAL_TEMPLATE_PAGE_SETTINGS_HEADER_FOOTER_HEIGHT_DEFAULT = PageSettings.HEADER_FOOTER_HEIGHT_DEFAULT;
export const REFERRAL_TEMPLATE_PAGE_SETTINGS_HEADER_FOOTER_HEIGHT_MAX = PageSettings.HEADER_FOOTER_HEIGHT_MAX;

export const REFERRAL_TEMPLATE_CELL_WIDTH = 12.75;
export const REFERRAL_TEMPLATE_ROW_HEIGHT = 5;

export const REFERRAL_ELEMENT_RICHTEXT_EDITOR = 'Richtext';
export const REFERRAL_ELEMENT_IMAGE = 'Image';

export const REFERRAL_ELEMENT_BUSINESS_NAME = 'BusinessName';
export const REFERRAL_ELEMENT_BUSINESS_FULL_ADDRESS = 'BusinessFullAddress';
export const REFERRAL_ELEMENT_BUSINESS_ADDRESS_LINE1 = 'BusinessAddressLine1';
export const REFERRAL_ELEMENT_BUSINESS_ADDRESS_LINE2 = 'BusinessAddressLine2';
export const REFERRAL_ELEMENT_BUSINESS_ADDRESS_COUNTRY = 'BusinessAddressCountry';
export const REFERRAL_ELEMENT_BUSINESS_PHONE = 'BusinessPhone';
export const REFERRAL_ELEMENT_BUSINESS_FAX = 'BusinessFax';
export const REFERRAL_ELEMENT_BUSINESS_EMAIL = 'BusinessEmail';

export const REFERRAL_ELEMENT_PATIENT_NAME = 'PatientName';
export const REFERRAL_ELEMENT_PATIENT_FULL_ADDRESS = 'PatientFullAddress';
export const REFERRAL_ELEMENT_PATIENT_ADDRESS_LINE1 = 'PatientAddressLine1';
export const REFERRAL_ELEMENT_PATIENT_ADDRESS_LINE2 = 'PatientAddressLine2';
export const REFERRAL_ELEMENT_PATIENT_ADDRESS_COUNTRY = 'PatientAddressCountry';
export const REFERRAL_ELEMENT_PATIENT_DATE_OF_BIRTH = 'PatientDateOfBirth';
export const REFERRAL_ELEMENT_PATIENT_PHONE = 'PatientPhone';
export const REFERRAL_ELEMENT_PATIENT_EMAIL = 'PatientEmail';
export const REFERRAL_ELEMENT_PATIENT_HEALTH_CARD = 'PatientHealthCard';

export const REFERRAL_ELEMENT_EXAM_DATE = 'ExamDate';
export const REFERRAL_ELEMENT_EXAM_DOCTOR = 'ExamDoctor';
export const REFERRAL_ELEMENT_EXAM_DOCTOR_LICENSE_NUMBER = 'ExamDoctorLicenseNumber';
export const REFERRAL_ELEMENT_EXAM_DOCTOR_BILLING_NUMBER = 'ExamDoctorBillingNumber';
export const REFERRAL_ELEMENT_EXAM_DOCTOR_SIGNATURE = 'ExamDoctorSignature';
export const REFERRAL_ELEMENT_REQUESTER_SIGNATURE = 'RequesterSignature';
export const REFERRAL_ELEMENT_EXAM_DATA = 'ExamData';

export const REFERRAL_ELEMENT_SUBJECT = 'ReferralSubject';
export const REFERRAL_ELEMENT_BODY = 'ReferralBody';
export const REFERRAL_ELEMENT_PAGE_NUMBER = 'PageNumber';
export const REFERRAL_ELEMENT_PRINTED_DATE = 'PrintedDate';

export const REFERRAL_TEMPLATE_METADATA_ALIGN = 'REFERRAL_TEMPLATE_METADATA_ALIGN';
export const REFERRAL_TEMPLATE_METADATA_VALIGN = 'REFERRAL_TEMPLATE_METADATA_VALIGN';
export const REFERRAL_TEMPLATE_METADATA_PADDING = 'REFERRAL_TEMPLATE_METADATA_PADDING';
export const REFERRAL_TEMPLATE_METADATA_FONT_COLOR = 'REFERRAL_TEMPLATE_METADATA_FONT_COLOR';
export const REFERRAL_TEMPLATE_METADATA_FONT_SIZE = 'REFERRAL_TEMPLATE_METADATA_FONT_SIZE';
export const REFERRAL_TEMPLATE_METADATA_FONT_FAMILY = 'REFERRAL_TEMPLATE_METADATA_FONT_FAMILY';
export const REFERRAL_TEMPLATE_METADATA_FONT_STYLE = 'REFERRAL_TEMPLATE_METADATA_FONT_STYLE';
export const REFERRAL_TEMPLATE_METADATA_FONT_LETTER_SPACING = 'REFERRAL_TEMPLATE_METADATA_FONT_LETTER_SPACING';
export const REFERRAL_TEMPLATE_METADATA_BACKGROUND_COLOR = 'REFERRAL_TEMPLATE_METADATA_BACKGROUND_COLOR';
export const REFERRAL_TEMPLATE_METADATA_BORDER = 'REFERRAL_TEMPLATE_METADATA_BORDER';
export const REFERRAL_TEMPLATE_METADATA_BORDER_COLOR = 'REFERRAL_TEMPLATE_METADATA_BORDER_COLOR';
export const REFERRAL_TEMPLATE_METADATA_ALTERNATE_BACKGROUND_COLOR = 'REFERRAL_TEMPLATE_METADATA_BACKGROUND_COLOR';
export const REFERRAL_TEMPLATE_METADATA_CONTENT = 'REFERRAL_TEMPLATE_METADATA_CONTENT';
export const REFERRAL_TEMPLATE_METADATA_IMAGE = 'REFERRAL_TEMPLATE_METADATA_IMAGE';
export const REFERRAL_TEMPLATE_METADATA_FORMAT = 'REFERRAL_TEMPLATE_METADATA_FORMAT';
export const REFERRAL_TEMPLATE_METADATA_EXAM_DATA = 'REFERRAL_TEMPLATE_METADATA_EXAM_DATA';
export const REFERRAL_TEMPLATE_METADATA_DOCTOR_SIGNATURE = 'REFERRAL_TEMPLATE_METADATA_DOCTOR_SIGNATURE';

export const ReferralElementTypes = [
    REFERRAL_ELEMENT_RICHTEXT_EDITOR,
    REFERRAL_ELEMENT_IMAGE,
    REFERRAL_ELEMENT_BUSINESS_NAME,
    REFERRAL_ELEMENT_BUSINESS_FULL_ADDRESS,
    REFERRAL_ELEMENT_BUSINESS_ADDRESS_LINE1,
    REFERRAL_ELEMENT_BUSINESS_ADDRESS_LINE2,
    REFERRAL_ELEMENT_BUSINESS_ADDRESS_COUNTRY,
    REFERRAL_ELEMENT_BUSINESS_PHONE,
    REFERRAL_ELEMENT_BUSINESS_FAX,
    REFERRAL_ELEMENT_BUSINESS_EMAIL,
    REFERRAL_ELEMENT_PATIENT_NAME,
    REFERRAL_ELEMENT_PATIENT_FULL_ADDRESS,
    REFERRAL_ELEMENT_PATIENT_ADDRESS_LINE1,
    REFERRAL_ELEMENT_PATIENT_ADDRESS_LINE2,
    REFERRAL_ELEMENT_PATIENT_ADDRESS_COUNTRY,
    REFERRAL_ELEMENT_PATIENT_DATE_OF_BIRTH,
    REFERRAL_ELEMENT_PATIENT_PHONE,
    REFERRAL_ELEMENT_PATIENT_EMAIL,
    REFERRAL_ELEMENT_PATIENT_HEALTH_CARD,
    REFERRAL_ELEMENT_EXAM_DATE,
    REFERRAL_ELEMENT_EXAM_DOCTOR,
    REFERRAL_ELEMENT_EXAM_DOCTOR_LICENSE_NUMBER,
    REFERRAL_ELEMENT_EXAM_DOCTOR_BILLING_NUMBER,
    REFERRAL_ELEMENT_EXAM_DOCTOR_SIGNATURE,
    REFERRAL_ELEMENT_REQUESTER_SIGNATURE,
    REFERRAL_ELEMENT_EXAM_DATA,
    REFERRAL_ELEMENT_SUBJECT,
    REFERRAL_ELEMENT_BODY,
    REFERRAL_ELEMENT_PAGE_NUMBER,
    REFERRAL_ELEMENT_PRINTED_DATE,
];

export const getPrintMessage = (name) => {
    return <>
        <h2 className='text-uppercase text-center font-weight-bold text-gray-900 mb-1'>Generating...</h2>
        <p className='fs-xl'>Please wait while we generate the referral letter.</p>
    </>;
}

export const createElement = (type) => {
    return {
        id: uuid(),
        type: type,
        metadata: {},
        position: getDefaultPosition(type)
    };
}

export const isReferralElement = ({ type }) => {
    return ReferralElementTypes.some(t => t === type);
}

export const hasField = (type, field) => {
    switch (type) {
        case REFERRAL_ELEMENT_RICHTEXT_EDITOR:
            return [
                REFERRAL_TEMPLATE_METADATA_ALIGN,
                REFERRAL_TEMPLATE_METADATA_VALIGN,
                REFERRAL_TEMPLATE_METADATA_PADDING,
                REFERRAL_TEMPLATE_METADATA_FONT_COLOR,
                REFERRAL_TEMPLATE_METADATA_FONT_SIZE,
                REFERRAL_TEMPLATE_METADATA_FONT_FAMILY,
                REFERRAL_TEMPLATE_METADATA_FONT_LETTER_SPACING,
                REFERRAL_TEMPLATE_METADATA_BACKGROUND_COLOR,
                REFERRAL_TEMPLATE_METADATA_BORDER,
                REFERRAL_TEMPLATE_METADATA_BORDER_COLOR,
                REFERRAL_TEMPLATE_METADATA_CONTENT,
            ].some(f => f === field);

        case REFERRAL_ELEMENT_IMAGE:
            return [
                REFERRAL_TEMPLATE_METADATA_ALIGN,
                REFERRAL_TEMPLATE_METADATA_VALIGN,
                REFERRAL_TEMPLATE_METADATA_IMAGE,
            ].some(f => f === field);

        case REFERRAL_ELEMENT_BUSINESS_NAME:
        case REFERRAL_ELEMENT_BUSINESS_FULL_ADDRESS:
        case REFERRAL_ELEMENT_BUSINESS_ADDRESS_LINE1:
        case REFERRAL_ELEMENT_BUSINESS_ADDRESS_LINE2:
        case REFERRAL_ELEMENT_BUSINESS_ADDRESS_COUNTRY:
        case REFERRAL_ELEMENT_BUSINESS_EMAIL:
        case REFERRAL_ELEMENT_PATIENT_FULL_ADDRESS:
        case REFERRAL_ELEMENT_PATIENT_ADDRESS_LINE1:
        case REFERRAL_ELEMENT_PATIENT_ADDRESS_LINE2:
        case REFERRAL_ELEMENT_PATIENT_ADDRESS_COUNTRY:
        case REFERRAL_ELEMENT_PATIENT_EMAIL:
        case REFERRAL_ELEMENT_PATIENT_HEALTH_CARD:
        case REFERRAL_ELEMENT_SUBJECT:
        case REFERRAL_ELEMENT_BODY:
            return [
                REFERRAL_TEMPLATE_METADATA_ALIGN,
                REFERRAL_TEMPLATE_METADATA_VALIGN,
                REFERRAL_TEMPLATE_METADATA_PADDING,
                REFERRAL_TEMPLATE_METADATA_FONT_COLOR,
                REFERRAL_TEMPLATE_METADATA_FONT_SIZE,
                REFERRAL_TEMPLATE_METADATA_FONT_FAMILY,
                REFERRAL_TEMPLATE_METADATA_FONT_STYLE,
                REFERRAL_TEMPLATE_METADATA_FONT_LETTER_SPACING,
                REFERRAL_TEMPLATE_METADATA_BACKGROUND_COLOR,
                REFERRAL_TEMPLATE_METADATA_BORDER,
                REFERRAL_TEMPLATE_METADATA_BORDER_COLOR,
            ].some(f => f === field);

        case REFERRAL_ELEMENT_BUSINESS_PHONE:
        case REFERRAL_ELEMENT_BUSINESS_FAX:
        case REFERRAL_ELEMENT_PATIENT_NAME:
        case REFERRAL_ELEMENT_PATIENT_DATE_OF_BIRTH:
        case REFERRAL_ELEMENT_PATIENT_PHONE:
        case REFERRAL_ELEMENT_EXAM_DATE:
        case REFERRAL_ELEMENT_EXAM_DOCTOR:
        case REFERRAL_ELEMENT_EXAM_DOCTOR_LICENSE_NUMBER:
        case REFERRAL_ELEMENT_EXAM_DOCTOR_BILLING_NUMBER:
        case REFERRAL_ELEMENT_PAGE_NUMBER:
        case REFERRAL_ELEMENT_PRINTED_DATE:
            return [
                REFERRAL_TEMPLATE_METADATA_ALIGN,
                REFERRAL_TEMPLATE_METADATA_VALIGN,
                REFERRAL_TEMPLATE_METADATA_PADDING,
                REFERRAL_TEMPLATE_METADATA_FONT_COLOR,
                REFERRAL_TEMPLATE_METADATA_FONT_SIZE,
                REFERRAL_TEMPLATE_METADATA_FONT_FAMILY,
                REFERRAL_TEMPLATE_METADATA_FONT_STYLE,
                REFERRAL_TEMPLATE_METADATA_FONT_LETTER_SPACING,
                REFERRAL_TEMPLATE_METADATA_BACKGROUND_COLOR,
                REFERRAL_TEMPLATE_METADATA_BORDER,
                REFERRAL_TEMPLATE_METADATA_BORDER_COLOR,
                REFERRAL_TEMPLATE_METADATA_FORMAT,
            ].some(f => f === field);

        case REFERRAL_ELEMENT_EXAM_DATA:
            return [
                REFERRAL_TEMPLATE_METADATA_EXAM_DATA,
            ].some(f => f === field);

        case REFERRAL_ELEMENT_EXAM_DOCTOR_SIGNATURE:
        case REFERRAL_ELEMENT_REQUESTER_SIGNATURE:
            return [
                REFERRAL_TEMPLATE_METADATA_FONT_SIZE,
                REFERRAL_TEMPLATE_METADATA_FONT_FAMILY,
                REFERRAL_TEMPLATE_METADATA_FONT_COLOR,
                REFERRAL_TEMPLATE_METADATA_DOCTOR_SIGNATURE,
            ].some(f => f === field);

        default:
            return false;
    }
}

export const isPatientField = (type) => {
    return [
        REFERRAL_ELEMENT_PATIENT_NAME,
        REFERRAL_ELEMENT_PATIENT_FULL_ADDRESS,
        REFERRAL_ELEMENT_PATIENT_ADDRESS_LINE1,
        REFERRAL_ELEMENT_PATIENT_ADDRESS_LINE2,
        REFERRAL_ELEMENT_PATIENT_ADDRESS_COUNTRY,
        REFERRAL_ELEMENT_PATIENT_PHONE,
        REFERRAL_ELEMENT_PATIENT_EMAIL,
        REFERRAL_ELEMENT_PATIENT_DATE_OF_BIRTH,
        REFERRAL_ELEMENT_PATIENT_HEALTH_CARD,
    ].some(f => f === type);
}

export const isExamElement = (type) => {
    return [
        REFERRAL_ELEMENT_EXAM_DATE,
        REFERRAL_ELEMENT_EXAM_DOCTOR,
        REFERRAL_ELEMENT_EXAM_DOCTOR_LICENSE_NUMBER,
        REFERRAL_ELEMENT_EXAM_DOCTOR_BILLING_NUMBER,
        REFERRAL_ELEMENT_EXAM_DOCTOR_SIGNATURE,
        REFERRAL_ELEMENT_REQUESTER_SIGNATURE,
        REFERRAL_ELEMENT_EXAM_DATA,
    ].some(f => f === type);
}

export const getName = (type) => {
    switch (type) {
        case REFERRAL_ELEMENT_RICHTEXT_EDITOR:
            return 'Richtext';

        case REFERRAL_ELEMENT_IMAGE:
            return 'Image';

        case REFERRAL_ELEMENT_BUSINESS_NAME:
            return 'BusinessName';

        case REFERRAL_ELEMENT_BUSINESS_FULL_ADDRESS:
            return 'BusinessFullAddress';

        case REFERRAL_ELEMENT_BUSINESS_ADDRESS_LINE1:
            return 'BusinessAddressLine1';

        case REFERRAL_ELEMENT_BUSINESS_ADDRESS_LINE2:
            return 'BusinessAddressLine2';

        case REFERRAL_ELEMENT_BUSINESS_ADDRESS_COUNTRY:
            return 'BusinessAddressCountry';

        case REFERRAL_ELEMENT_BUSINESS_PHONE:
            return 'BusinessPhone';

        case REFERRAL_ELEMENT_BUSINESS_FAX:
            return 'BusinessFax';

        case REFERRAL_ELEMENT_BUSINESS_EMAIL:
            return 'BusinessEmail';

        case REFERRAL_ELEMENT_PATIENT_NAME:
            return 'PatientName';

        case REFERRAL_ELEMENT_PATIENT_FULL_ADDRESS:
            return 'PatientFullAddress';

        case REFERRAL_ELEMENT_PATIENT_ADDRESS_LINE1:
            return 'PatientAddressLine1';

        case REFERRAL_ELEMENT_PATIENT_ADDRESS_LINE2:
            return 'PatientAddressLine2';

        case REFERRAL_ELEMENT_PATIENT_ADDRESS_COUNTRY:
            return 'PatientAddressCountry';

        case REFERRAL_ELEMENT_PATIENT_DATE_OF_BIRTH:
            return 'PatientDateOfBirth';

        case REFERRAL_ELEMENT_PATIENT_PHONE:
            return 'PatientPhone';

        case REFERRAL_ELEMENT_PATIENT_EMAIL:
            return 'PatientEmail';

        case REFERRAL_ELEMENT_PATIENT_HEALTH_CARD:
            return 'PatientHealthCard';

        case REFERRAL_ELEMENT_EXAM_DATA:
            return 'ExamData';

        case REFERRAL_ELEMENT_EXAM_DATE:
            return 'ExamDate';

        case REFERRAL_ELEMENT_EXAM_DOCTOR:
            return 'ExamDoctor';

        case REFERRAL_ELEMENT_EXAM_DOCTOR_LICENSE_NUMBER:
            return 'ExamDoctorLicenseNumber';

        case REFERRAL_ELEMENT_EXAM_DOCTOR_BILLING_NUMBER:
            return 'ExamDoctorBillingNumber';

        case REFERRAL_ELEMENT_EXAM_DOCTOR_SIGNATURE:
            return 'ExamDoctorSignature';

        case REFERRAL_ELEMENT_REQUESTER_SIGNATURE:
            return 'RequesterSignature';

        case REFERRAL_ELEMENT_SUBJECT:
            return 'ReferralSubject';

        case REFERRAL_ELEMENT_BODY:
            return 'ReferralBody';

        case REFERRAL_ELEMENT_PAGE_NUMBER:
            return 'PageNumber';

        case REFERRAL_ELEMENT_PRINTED_DATE:
            return 'PrintedDate';

        default:
            return '';
    }
}

export const getDescription = (type) => {
    switch (type) {
        case REFERRAL_ELEMENT_RICHTEXT_EDITOR:
            return 'Richtext';

        case REFERRAL_ELEMENT_IMAGE:
            return 'Image';

        case REFERRAL_ELEMENT_BUSINESS_NAME:
            return 'Business Name';

        case REFERRAL_ELEMENT_BUSINESS_FULL_ADDRESS:
            return 'Business Full Address';

        case REFERRAL_ELEMENT_BUSINESS_ADDRESS_LINE1:
            return 'Business Address Line 1';

        case REFERRAL_ELEMENT_BUSINESS_ADDRESS_LINE2:
            return 'Business Address Line 2';

        case REFERRAL_ELEMENT_BUSINESS_ADDRESS_COUNTRY:
            return 'Business Address Country';

        case REFERRAL_ELEMENT_BUSINESS_PHONE:
            return 'Business Phone';

        case REFERRAL_ELEMENT_BUSINESS_FAX:
            return 'Business Fax';

        case REFERRAL_ELEMENT_BUSINESS_EMAIL:
            return 'Business Email';

        case REFERRAL_ELEMENT_PATIENT_NAME:
            return 'Patient Name';

        case REFERRAL_ELEMENT_PATIENT_FULL_ADDRESS:
            return 'Patient Full Address';

        case REFERRAL_ELEMENT_PATIENT_ADDRESS_LINE1:
            return 'Patient Address Line 1';

        case REFERRAL_ELEMENT_PATIENT_ADDRESS_LINE2:
            return 'Patient Address Line 2';

        case REFERRAL_ELEMENT_PATIENT_ADDRESS_COUNTRY:
            return 'Patient Address Country';

        case REFERRAL_ELEMENT_PATIENT_DATE_OF_BIRTH:
            return 'Patient Date of Birth';

        case REFERRAL_ELEMENT_PATIENT_PHONE:
            return 'Patient Phone';

        case REFERRAL_ELEMENT_PATIENT_EMAIL:
            return 'Patient Email';

        case REFERRAL_ELEMENT_PATIENT_HEALTH_CARD:
            return 'Patient Health Card';

        case REFERRAL_ELEMENT_EXAM_DATA:
            return 'Exam Data';

        case REFERRAL_ELEMENT_EXAM_DATE:
            return 'Exam Date';

        case REFERRAL_ELEMENT_EXAM_DOCTOR:
            return 'Exam Doctor';

        case REFERRAL_ELEMENT_EXAM_DOCTOR_LICENSE_NUMBER:
            return 'Exam Doctor License Number';

        case REFERRAL_ELEMENT_EXAM_DOCTOR_BILLING_NUMBER:
            return 'Exam Doctor Billing Number';

        case REFERRAL_ELEMENT_EXAM_DOCTOR_SIGNATURE:
            return 'Exam Doctor Signature';

        case REFERRAL_ELEMENT_REQUESTER_SIGNATURE:
            return 'Requester Signature';

        case REFERRAL_ELEMENT_SUBJECT:
            return 'Subject';

        case REFERRAL_ELEMENT_BODY:
            return 'Body';

        case REFERRAL_ELEMENT_PAGE_NUMBER:
            return 'Page Number';

        case REFERRAL_ELEMENT_PRINTED_DATE:
            return 'Printed Date';

        default:
            return '';
    }
}

export const getIcon = (type) => {
    switch (type) {
        case REFERRAL_ELEMENT_RICHTEXT_EDITOR:
            return 'fal fa-text-size';

        case REFERRAL_ELEMENT_IMAGE:
            return 'fal fa-image';

        case REFERRAL_ELEMENT_BUSINESS_NAME:
        case REFERRAL_ELEMENT_BUSINESS_FULL_ADDRESS:
        case REFERRAL_ELEMENT_BUSINESS_ADDRESS_LINE1:
        case REFERRAL_ELEMENT_BUSINESS_ADDRESS_LINE2:
        case REFERRAL_ELEMENT_BUSINESS_ADDRESS_COUNTRY:
        case REFERRAL_ELEMENT_BUSINESS_PHONE:
        case REFERRAL_ELEMENT_BUSINESS_FAX:
        case REFERRAL_ELEMENT_BUSINESS_EMAIL:
            return 'fal fa-building';

        case REFERRAL_ELEMENT_PATIENT_NAME:
        case REFERRAL_ELEMENT_PATIENT_FULL_ADDRESS:
        case REFERRAL_ELEMENT_PATIENT_ADDRESS_LINE1:
        case REFERRAL_ELEMENT_PATIENT_ADDRESS_LINE2:
        case REFERRAL_ELEMENT_PATIENT_ADDRESS_COUNTRY:
        case REFERRAL_ELEMENT_PATIENT_DATE_OF_BIRTH:
        case REFERRAL_ELEMENT_PATIENT_PHONE:
        case REFERRAL_ELEMENT_PATIENT_EMAIL:
        case REFERRAL_ELEMENT_PATIENT_HEALTH_CARD:
            return 'fal fa-user';

        case REFERRAL_ELEMENT_EXAM_DATE:
        case REFERRAL_ELEMENT_EXAM_DOCTOR:
        case REFERRAL_ELEMENT_EXAM_DOCTOR_LICENSE_NUMBER:
        case REFERRAL_ELEMENT_EXAM_DOCTOR_BILLING_NUMBER:
        case REFERRAL_ELEMENT_EXAM_DOCTOR_SIGNATURE:
        case REFERRAL_ELEMENT_REQUESTER_SIGNATURE:
        case REFERRAL_ELEMENT_EXAM_DATA:
            return 'fal fa-clipboard'

        case REFERRAL_ELEMENT_SUBJECT:
            return 'fal fa-external-link-square';

        case REFERRAL_ELEMENT_BODY:
            return 'fal fa-external-link-square';

        case REFERRAL_ELEMENT_PAGE_NUMBER:
            return 'fal fa-hashtag'

        case REFERRAL_ELEMENT_PRINTED_DATE:
            return 'fal fa-calendar-alt'

        default:
            return '';
    }
}

export const getDefaultValue = (type, format) => {
    const formatOption = getFormatOptions(type).filter(f => f.type === format)[0];

    switch (type) {
        case REFERRAL_ELEMENT_RICHTEXT_EDITOR:
            return 'This is a sample text.';

        case REFERRAL_ELEMENT_IMAGE:
            return '';

        case REFERRAL_ELEMENT_BUSINESS_NAME:
            return 'Company Name';

        case REFERRAL_ELEMENT_BUSINESS_FULL_ADDRESS:
            return '199 Main Street East, Toronto, ON M1N 2N2, Canada';

        case REFERRAL_ELEMENT_BUSINESS_ADDRESS_LINE1:
            return '199 Main Street East';

        case REFERRAL_ELEMENT_BUSINESS_ADDRESS_LINE2:
            return 'Toronto, ON M1N 2N2';

        case REFERRAL_ELEMENT_BUSINESS_ADDRESS_COUNTRY:
            return 'Canada';

        case REFERRAL_ELEMENT_BUSINESS_PHONE:
            return formatOption ? formatOption.example : '+14165559999';

        case REFERRAL_ELEMENT_BUSINESS_FAX:
            return formatOption ? formatOption.example : '+14162223333';

        case REFERRAL_ELEMENT_BUSINESS_EMAIL:
            return 'info@companyname.com';

        case REFERRAL_ELEMENT_PATIENT_NAME:
            return formatOption ? formatOption.example : 'Jonathan Williams';

        case REFERRAL_ELEMENT_PATIENT_FULL_ADDRESS:
            return '355-20 Applegate Street, Toronto, ON M2M 3K3, Canada';

        case REFERRAL_ELEMENT_PATIENT_ADDRESS_LINE1:
            return '355-20 Applegate Street';

        case REFERRAL_ELEMENT_PATIENT_ADDRESS_LINE2:
            return 'Toronto, ON M2M 3K3';

        case REFERRAL_ELEMENT_PATIENT_ADDRESS_COUNTRY:
            return 'Canada';

        case REFERRAL_ELEMENT_PATIENT_DATE_OF_BIRTH:
            return 'July 1, 1980';

        case REFERRAL_ELEMENT_PATIENT_PHONE:
            return formatOption ? formatOption.example : '+14166643333';

        case REFERRAL_ELEMENT_PATIENT_EMAIL:
            return 'jwilliams_20@patientemail.com';

        case REFERRAL_ELEMENT_PATIENT_HEALTH_CARD:
            return '1234-5678-910-AA';

        case REFERRAL_ELEMENT_EXAM_DATE:
            return formatOption ? formatOption.example : moment().format('YYYY-MM-DD');

        case REFERRAL_ELEMENT_EXAM_DOCTOR:
            return 'Dr. Sarah Williams';

        case REFERRAL_ELEMENT_EXAM_DOCTOR_LICENSE_NUMBER:
            return '0023456';

        case REFERRAL_ELEMENT_EXAM_DOCTOR_BILLING_NUMBER:
            return '0098765';

        case REFERRAL_ELEMENT_EXAM_DATA:
            return '';

        case REFERRAL_ELEMENT_SUBJECT:
            return 'Subject of the Referral';

        case REFERRAL_ELEMENT_BODY:
            return 'This is the body of the referral letter.';

        case REFERRAL_ELEMENT_PAGE_NUMBER:
            return formatOption ? formatOption.example : 'Page 1';

        case REFERRAL_ELEMENT_PRINTED_DATE:
            return formatOption ? formatOption.example : moment().format('YYYY-MM-DD');

        default:
            return '';
    }
}

export const getValue = (element, sources) => {
    const { type, metadata } = element;
    const { format } = metadata;
    const formatOptions = getFormatOptions(type);
    const formatOption = formatOptions.some(f => f.type === format) ? formatOptions.filter(f => f.type === format)[0] : { type: null };

    switch (type) {
        case REFERRAL_ELEMENT_BUSINESS_NAME:
            return sources && sources.tenant ? fm.apply(sources.tenant.displayName, formatOption.type) : '';

        case REFERRAL_ELEMENT_BUSINESS_FULL_ADDRESS:
            return sources && sources.tenant && sources.tenant.address && sources.tenant.address.fullLine ? fm.apply(sources.tenant.address.fullLine, formatOption.type) : null;

        case REFERRAL_ELEMENT_BUSINESS_ADDRESS_LINE1:
            return sources && sources.tenant && sources.tenant.address && sources.tenant.address.addressLine1 ? fm.apply(sources.tenant.address.addressLine1, formatOption.type) : null;

        case REFERRAL_ELEMENT_BUSINESS_ADDRESS_LINE2:
            return sources && sources.tenant && sources.tenant.address && sources.tenant.address.addressLine2 ? fm.apply(sources.tenant.address.addressLine2, formatOption.type) : null;

        case REFERRAL_ELEMENT_BUSINESS_ADDRESS_COUNTRY:
            return sources && sources.tenant && sources.tenant.address && sources.tenant.address.country ? fm.apply(sources.tenant.address.country, formatOption.type) : null;

        case REFERRAL_ELEMENT_BUSINESS_PHONE:
            return sources && sources.tenant ? fm.apply(sources.tenant.phoneNumber, formatOption.type) : null;

        case REFERRAL_ELEMENT_BUSINESS_FAX:
            return sources && sources.tenant ? fm.apply(sources.tenant.faxNumber, formatOption.type) : null;

        case REFERRAL_ELEMENT_BUSINESS_EMAIL:
            return sources && sources.tenant ? fm.apply(sources.tenant.emailAddress, formatOption.type) : null;

        case REFERRAL_ELEMENT_PATIENT_NAME:
            return sources && sources.customer ? fm.apply(sources.customer, formatOption.type, ph.getPreferredFirstLastName(sources.customer)) : null;

        case REFERRAL_ELEMENT_PATIENT_FULL_ADDRESS:
            return sources && sources.customer && sources.customer && sources.customer.address && sources.customer.address.fullLine ? fm.apply(sources.customer.address.fullLine, formatOption.type) : null;

        case REFERRAL_ELEMENT_PATIENT_ADDRESS_LINE1:
            return sources && sources.customer && sources.customer && sources.customer.address && sources.customer.address.addressLine1 ? fm.apply(sources.customer.address.addressLine1, formatOption.type) : null;

        case REFERRAL_ELEMENT_PATIENT_ADDRESS_LINE2:
            return sources && sources.customer && sources.customer && sources.customer.address && sources.customer.address.addressLine2 ? fm.apply(sources.customer.address.addressLine2, formatOption.type) : null;

        case REFERRAL_ELEMENT_PATIENT_ADDRESS_COUNTRY:
            return sources && sources.customer && sources.customer && sources.customer.address && sources.customer.address.country ? fm.apply(sources.customer.address.country, formatOption.type) : null;

        case REFERRAL_ELEMENT_PATIENT_DATE_OF_BIRTH:
            return sources && sources.customer ? fm.apply(sources.customer, formatOption.type, sources.customer.dateOfBirth) : null;

        case REFERRAL_ELEMENT_PATIENT_PHONE:
            return sources && sources.customer ? fm.apply(sources.customer.phoneNumber, formatOption.type) : null;

        case REFERRAL_ELEMENT_PATIENT_EMAIL:
            return sources && sources.customer ? fm.apply(sources.customer.emailAddress, formatOption.type) : null;

        case REFERRAL_ELEMENT_PATIENT_HEALTH_CARD:
            return sources && sources.customer && sources.customer.patientProfile ? ph.formatHealthCard(sources.customer.patientProfile.healthCardNumber) : null;

        case REFERRAL_ELEMENT_EXAM_DATE:
            return sources && sources.exam && sources.exam.createdDateUtc ? fm.apply(moment.utc(sources.exam.createdDateUtc), formatOption.type, moment.utc(sources.exam.createdDateUtc).format('YYYY-MM-DD')) : null;

        case REFERRAL_ELEMENT_EXAM_DOCTOR:
            return sources && sources.user ? fm.apply(sources.user, formatOption.type, ph.getPreferredFirstLastName(sources.user)) : null;

        case REFERRAL_ELEMENT_EXAM_DOCTOR_LICENSE_NUMBER:
            return sources && sources.user ? sources.user.licenseNumber : null;

        case REFERRAL_ELEMENT_EXAM_DOCTOR_BILLING_NUMBER:
            return sources && sources.user ? sources.user.publicInsuranceProviderNumber : null;

        case REFERRAL_ELEMENT_SUBJECT:
            return sources && sources.subject ? sources.subject : null;

        case REFERRAL_ELEMENT_BODY:
            return sources && sources.body ? sources.body : null;

        case REFERRAL_ELEMENT_PAGE_NUMBER:
            return formatOption ? formatOption.example : 'Page 1';

        case REFERRAL_ELEMENT_PRINTED_DATE:
            return fm.apply(moment(), formatOption.type, moment().format('YYYY-MM-DD'));

        default:
            return '';
    }
}

export const getFormatOptions = (type) => {
    switch (type) {
        case REFERRAL_ELEMENT_PATIENT_NAME:
            return fm.NAME_FORMAT_OPTIONS;

        case REFERRAL_ELEMENT_BUSINESS_PHONE:
        case REFERRAL_ELEMENT_BUSINESS_FAX:
        case REFERRAL_ELEMENT_PATIENT_PHONE:
            return fm.PHONE_FORMAT_OPTIONS;

        case REFERRAL_ELEMENT_PATIENT_DATE_OF_BIRTH:
        case REFERRAL_ELEMENT_EXAM_DATE:
        case REFERRAL_ELEMENT_PRINTED_DATE:
            return fm.DATE_FORMAT_OPTIONS;

        case REFERRAL_ELEMENT_PAGE_NUMBER:
            return fm.PAGE_NUMBER_FORMAT_OPTIONS;

        case REFERRAL_ELEMENT_EXAM_DOCTOR:
            return fm.DOCTOR_NAME_FORMAT_OPTIONS;

        default:
            return [];
    }
}

export const renderElement = (element, options) => {
    const defaults = {
        controlKey: `_${uuid()}`,
        readOnly: null,
        isDesignMode: false,
        defaultValue: null,
        quickDrawer: null,
        value: null,
        onClick: null,
        onDoubleClick: null,
        onContextMenu: null,
    };
    options = { ...defaults, ...options }

    if (options.pageSettings) {
        options.pageSettings.paper = options.pageSettings.paper ? options.pageSettings.paper : getPaperDimension('letter');
        options.pageSettings.margins = options.pageSettings.margins ? options.pageSettings.margins : REFERRAL_TEMPLATE_PAGE_SETTINGS_MARGINS_NORMAL;
    } else {
        options.pageSettings = {
            paper: getPaperDimension('letter'),
            margins: REFERRAL_TEMPLATE_PAGE_SETTINGS_MARGINS_NORMAL,
        };
    }

    switch (element.type) {
        case REFERRAL_ELEMENT_RICHTEXT_EDITOR:
        case REFERRAL_ELEMENT_BUSINESS_NAME:
        case REFERRAL_ELEMENT_BUSINESS_FULL_ADDRESS:
        case REFERRAL_ELEMENT_BUSINESS_ADDRESS_LINE1:
        case REFERRAL_ELEMENT_BUSINESS_ADDRESS_LINE2:
        case REFERRAL_ELEMENT_BUSINESS_ADDRESS_COUNTRY:
        case REFERRAL_ELEMENT_BUSINESS_PHONE:
        case REFERRAL_ELEMENT_BUSINESS_FAX:
        case REFERRAL_ELEMENT_BUSINESS_EMAIL:
        case REFERRAL_ELEMENT_PATIENT_NAME:
        case REFERRAL_ELEMENT_PATIENT_FULL_ADDRESS:
        case REFERRAL_ELEMENT_PATIENT_ADDRESS_LINE1:
        case REFERRAL_ELEMENT_PATIENT_ADDRESS_LINE2:
        case REFERRAL_ELEMENT_PATIENT_ADDRESS_COUNTRY:
        case REFERRAL_ELEMENT_PATIENT_DATE_OF_BIRTH:
        case REFERRAL_ELEMENT_PATIENT_PHONE:
        case REFERRAL_ELEMENT_PATIENT_EMAIL:
        case REFERRAL_ELEMENT_PATIENT_HEALTH_CARD:
        case REFERRAL_ELEMENT_EXAM_DATE:
        case REFERRAL_ELEMENT_EXAM_DOCTOR:
        case REFERRAL_ELEMENT_EXAM_DOCTOR_LICENSE_NUMBER:
        case REFERRAL_ELEMENT_EXAM_DOCTOR_BILLING_NUMBER:
        case REFERRAL_ELEMENT_PAGE_NUMBER:
        case REFERRAL_ELEMENT_PRINTED_DATE:
            return <div
                className={'form-group print-element no-label' + (element.metadata && element.metadata.valign && element.metadata.valign !== 'top' ? ` text-${element.metadata.valign}` : '')}
                style={{
                    borderTop: element.metadata && element.metadata.border && element.metadata.border.top ? element.metadata.border.top : 'none',
                    borderRight: element.metadata && element.metadata.border && element.metadata.border.right ? element.metadata.border.right : 'none',
                    borderBottom: element.metadata && element.metadata.border && element.metadata.border.bottom ? element.metadata.border.bottom : 'none',
                    borderLeft: element.metadata && element.metadata.border && element.metadata.border.left ? element.metadata.border.left : 'none',
                    backgroundColor: element.metadata && element.metadata.backgroundColor ? element.metadata.backgroundColor : 'transparent',
                }}
                data-id={element.id}
                onDoubleClick={options ? options.onDoubleClick : null}
                onContextMenu={options ? options.onContextMenu : null}
            >
                <div
                    data-key={`pe_${element.id}`}
                    className='element-wrapper'
                    style={{
                        lineHeight: 1.2,
                        textAlign: element.metadata && element.metadata.align ? element.metadata.align : 'left',
                        padding: element.metadata && element.metadata.padding && element.metadata.padding.length === 4 ? element.metadata.padding.map(p => { return `${p}px` }).join(' ') : null,
                        color: element.metadata && element.metadata.fontColor ? element.metadata.fontColor : null,
                        fontSize: element.metadata && element.metadata.fontSize ? `${element.metadata.fontSize}px` : null,
                        fontFamily: element.metadata && element.metadata.fontFamily ? element.metadata.fontFamily : null,
                        fontWeight: element.metadata && element.metadata.fontStyle && element.metadata.fontStyle.some(s => s === 'bold') ? 'bold' : null,
                        fontStyle: element.metadata && element.metadata.fontStyle && element.metadata.fontStyle.some(s => s === 'italic') ? 'italic' : null,
                        textDecoration: element.metadata && element.metadata.fontStyle && element.metadata.fontStyle.some(s => s === 'underline') ? 'underline' : null,
                        letterSpacing: element.metadata && element.metadata.fontLetterSpacing ? `${element.metadata.fontLetterSpacing}px` : null,
                    }}
                >
                    {
                        element.type === REFERRAL_ELEMENT_RICHTEXT_EDITOR ?
                            <span dangerouslySetInnerHTML={{ __html: element.metadata.content }}></span> :
                            <span><p>{
                                options.defaultValue ?
                                    options.defaultValue :
                                    options.isDesignMode ? getDefaultValue(element.type, (element.metadata && element.metadata.format ? element.metadata.format : null)) : null
                            }</p></span>
                    }
                </div>
            </div>

        case REFERRAL_ELEMENT_IMAGE:
            return <div
                className={'form-group print-element no-label' + (element.metadata && element.metadata.valign && element.metadata.valign !== 'top' ? ` text-${element.metadata.valign}` : '')}
                data-id={element.id}
                onDoubleClick={options ? options.onDoubleClick : null}
                onContextMenu={options ? options.onContextMenu : null}
            >
                <div
                    data-key={`pe_${element.id}`}
                    className='element-wrapper'
                    style={{
                        textAlign: element.metadata && element.metadata.align ? element.metadata.align : 'left',
                    }}
                >
                    <img
                        src={`${(element.metadata && element.metadata.image ? element.metadata.image.base64 : null)}`}
                        style={{
                            width: (element.metadata && element.metadata.image ? getContainImage(element, options.pageSettings).width : null),
                            height: (element.metadata && element.metadata.image ? getContainImage(element, options.pageSettings).height : null),
                        }}
                        alt='print-element'
                    />
                </div>
            </div>

        case REFERRAL_ELEMENT_SUBJECT:
        case REFERRAL_ELEMENT_BODY:
            return <div
                className={'element-wrapper element-input h-100 no-label'}
                data-id={element.id}
            >
                <div
                    className={'form-group print-element no-label' + (element.metadata && element.metadata.valign && element.metadata.valign !== 'top' ? ` text-${element.metadata.valign}` : '')}
                    onDoubleClick={options.onDoubleClick}
                    onContextMenu={options.onContextMenu}
                >
                    <div className='element-wrapper'>
                        <div
                            id={options.inputKey}
                            className='html'
                            style={{
                                textAlign: element.metadata.align ? element.metadata.align : 'left',
                                color: element.metadata.fontColor ? element.metadata.fontColor : null,
                                fontSize: element.metadata.fontSize ? `${element.metadata.fontSize}px` : null,
                                fontFamily: element.metadata.fontFamily ? element.metadata.fontFamily : null,
                                fontWeight: element.metadata.fontStyle && element.metadata.fontStyle.some(s => s === 'bold') ? 'bold' : null,
                                fontStyle: element.metadata.fontStyle && element.metadata.fontStyle.some(s => s === 'italic') ? 'italic' : null,
                                textDecoration: element.metadata.fontStyle && element.metadata.fontStyle.some(s => s === 'underline') ? 'underline' : null,
                                letterSpacing: element.metadata.fontLetterSpacing ? `${element.metadata.fontLetterSpacing}px` : null,
                            }}
                            data-key={element.type}
                        >
                            {options.isDesignMode ? getDescription(element.type) : (options.value ? options.value : '')}
                        </div>
                    </div>
                </div>
            </div>

        case REFERRAL_ELEMENT_EXAM_DATA:  // Cannot be render on TemplateGrid
            const datasets = [{ label: 'Exam Label', definition: [''] }]
            const numberOfColumns = 1;

            return <div
                className={'element-wrapper element-input h-100 no-label'}
                data-id={element.id}
            >
                <div
                    className={'form-group print-element no-label'}
                    onDoubleClick={options.onDoubleClick}
                    onContextMenu={options.onContextMenu}
                >
                    <div className='control-wrapper justify-content-start'>
                        <table cellPadding={0} cellSpacing={0}>
                            <tbody>
                                {
                                    datasets.map((ds, dsi) => {
                                        return <tr key={`${element.key}_metadata_${dsi}`}>
                                            <td valign='top'>
                                                <div
                                                    style={{
                                                        textAlign: element.metadata && element.metadata.label && element.metadata.label.align ? element.metadata.label.align : 'left',
                                                        color: element.metadata && element.metadata.label && element.metadata.label.fontColor ? element.metadata.label.fontColor : null,
                                                        fontSize: element.metadata && element.metadata.label && element.metadata.label.fontSize ? `${element.metadata.label.fontSize}px` : null,
                                                        fontFamily: element.metadata && element.metadata.label && element.metadata.label.fontFamily ? element.metadata.label.fontFamily : null,
                                                        fontWeight: element.metadata && element.metadata.label && element.metadata.label.fontStyle && element.metadata.label.fontStyle.some(s => s === 'bold') ? 'bold' : null,
                                                        fontStyle: element.metadata && element.metadata.label && element.metadata.label.fontStyle && element.metadata.label.fontStyle.some(s => s === 'italic') ? 'italic' : null,
                                                        textDecoration: element.metadata && element.metadata.label && element.metadata.label.fontStyle && element.metadata.label.fontStyle.some(s => s === 'underline') ? 'underline' : null,
                                                        padding: '5px 10px 5px 0',
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    {ds.label}
                                                </div>
                                            </td>
                                            {
                                                (() => {
                                                    const columns = [];

                                                    for (let ci = 0; ci < numberOfColumns; ci++) {
                                                        const columnValue = options.isDesignMode ? 'Exam Data' : (fn.isFunction(options.getExamDataValue) ? options.getExamDataValue(element.id, ds, ci) : '');

                                                        columns.push(<td
                                                            key={`${element.key}_metadata_column_${dsi}_${ci}`}
                                                            valign='top' style={{ width: `${100.0 / numberOfColumns}%` }}
                                                        >
                                                            <div
                                                                style={{
                                                                    textAlign: element.metadata && element.metadata.data && element.metadata.data.align ? element.metadata.data.align : 'left',
                                                                    color: element.metadata && element.metadata.data && element.metadata.data.fontColor ? element.metadata.data.fontColor : null,
                                                                    fontSize: element.metadata && element.metadata.data && element.metadata.data.fontSize ? `${element.metadata.data.fontSize}px` : null,
                                                                    fontFamily: element.metadata && element.metadata.data && element.metadata.data.fontFamily ? element.metadata.data.fontFamily : null,
                                                                    fontWeight: element.metadata && element.metadata.data && element.metadata.data.fontStyle && element.metadata.data.fontStyle.some(s => s === 'bold') ? 'bold' : null,
                                                                    fontStyle: element.metadata && element.metadata.data && element.metadata.data.fontStyle && element.metadata.data.fontStyle.some(s => s === 'italic') ? 'italic' : null,
                                                                    textDecoration: element.metadata && element.metadata.data && element.metadata.data.fontStyle && element.metadata.data.fontStyle.some(s => s === 'underline') ? 'underline' : null,
                                                                    padding: '5px',
                                                                }}
                                                            >
                                                                {columnValue}
                                                            </div>
                                                        </td>);
                                                    }

                                                    return columns;
                                                })()
                                            }
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


        case REFERRAL_ELEMENT_EXAM_DOCTOR_SIGNATURE:
        case REFERRAL_ELEMENT_REQUESTER_SIGNATURE:
            return <div
                className={
                    'form-group print-element no-label' +
                    (element.metadata && element.metadata.hideReadonly === true && options.readOnly ? ' d-none' : '')
                }
                data-id={element.id}
            >
                <div
                    data-key={`pe_${element.id}`}
                    className='element-wrapper'
                >
                    {/* <img
                        src={sh.getSignatureLineBase64(
                            options.isDesignMode ?
                                {
                                    firstName: 'Sarah',
                                    lastName: 'Williams',
                                    designation: 'MD',
                                    licenseNumber: '012345',
                                    publicInsuranceProviderNumber: '098765'
                                } : options.value,
                            false,
                            {
                                fontColor: element.metadata && element.metadata.fontColor ? element.metadata.fontColor : null,
                                fontSize: element.metadata && element.metadata.fontSize ? element.metadata.fontSize : null,
                                fontFamily: element.metadata && element.metadata.fontFamily ? element.metadata.fontFamily : null,
                                hasLine: element.metadata && element.metadata.signature && !fn.isNullOrUndefined(element.metadata.signature.hasLine) ? element.metadata.signature.hasLine : null,
                                hasDescription: element.metadata && element.metadata.signature && !fn.isNullOrUndefined(element.metadata.signature.hasDescription) ? element.metadata.signature.hasDescription : null,
                                description1Format: element.metadata && element.metadata.signature ? element.metadata.signature.description1 : null,
                                description2Format: element.metadata && element.metadata.signature ? element.metadata.signature.description2 : null,
                            },
                        )}
                        alt='print-element'
                    /> */}
                </div>
            </div>

        default:
            break;
    }
}

export const getDefaultPosition = type => {
    switch (type) {
        case REFERRAL_ELEMENT_IMAGE:
            return { w: 10, h: 10, minW: 1 };

        case REFERRAL_ELEMENT_RICHTEXT_EDITOR:
            return { w: 20, h: 10, minW: 2 };

        case REFERRAL_ELEMENT_BUSINESS_NAME:
        case REFERRAL_ELEMENT_BUSINESS_PHONE:
        case REFERRAL_ELEMENT_BUSINESS_FAX:
        case REFERRAL_ELEMENT_BUSINESS_EMAIL:
        case REFERRAL_ELEMENT_PATIENT_NAME:
        case REFERRAL_ELEMENT_PATIENT_DATE_OF_BIRTH:
        case REFERRAL_ELEMENT_PATIENT_PHONE:
        case REFERRAL_ELEMENT_PATIENT_EMAIL:
        case REFERRAL_ELEMENT_PATIENT_HEALTH_CARD:
        case REFERRAL_ELEMENT_EXAM_DATE:
        case REFERRAL_ELEMENT_EXAM_DOCTOR:
        case REFERRAL_ELEMENT_EXAM_DOCTOR_LICENSE_NUMBER:
        case REFERRAL_ELEMENT_EXAM_DOCTOR_BILLING_NUMBER:
        case REFERRAL_ELEMENT_PRINTED_DATE:
        case REFERRAL_ELEMENT_SUBJECT:
            return { w: 6, h: 4, minW: 2 };

        case REFERRAL_ELEMENT_BUSINESS_FULL_ADDRESS:
        case REFERRAL_ELEMENT_PATIENT_FULL_ADDRESS:
            return { w: 20, h: 10, minW: 2 };

        case REFERRAL_ELEMENT_BUSINESS_ADDRESS_LINE1:
        case REFERRAL_ELEMENT_BUSINESS_ADDRESS_LINE2:
        case REFERRAL_ELEMENT_BUSINESS_ADDRESS_COUNTRY:
        case REFERRAL_ELEMENT_PATIENT_ADDRESS_LINE1:
        case REFERRAL_ELEMENT_PATIENT_ADDRESS_LINE2:
        case REFERRAL_ELEMENT_PATIENT_ADDRESS_COUNTRY:
            return { w: 12, h: 4, minW: 2 };

        case REFERRAL_ELEMENT_BODY:
        case REFERRAL_ELEMENT_EXAM_DATA:
            return { w: 40, h: 10, minW: 40, maxH: 20 };

        case REFERRAL_ELEMENT_EXAM_DOCTOR_SIGNATURE:
        case REFERRAL_ELEMENT_REQUESTER_SIGNATURE:
            return { w: 33, h: 10, minW: 15 };

        default:
            return {};
    }
}

export const getContainImage = (imageElement, pageSettings) => {
    let { width: imageWidth, height: imageHeight } = imageElement.metadata.image;
    const containerWidth = parseFloat(imageElement.position.w * ((pageSettings.paper.width - (pageSettings.margins * 2)) / 64), 10);
    const containerHeight = parseFloat(imageElement.position.h * REFERRAL_TEMPLATE_ROW_HEIGHT, 10);

    if (containerHeight >= containerWidth) {
        if (imageHeight > containerHeight) {
            const heightScale = containerHeight / imageHeight;

            imageWidth = imageWidth * heightScale;
            imageHeight = containerHeight;
        }

        if (imageWidth > containerWidth) {
            const scale = containerWidth / imageWidth;

            imageWidth = containerWidth;
            imageHeight = imageHeight * scale;
        }
    }
    else if (containerWidth > containerHeight) {
        if (imageWidth > containerWidth) {
            const widthScale = containerWidth / imageWidth;

            imageWidth = containerWidth;
            imageHeight = imageHeight * widthScale;
        }

        if (imageHeight > containerHeight) {
            const scale = containerHeight / imageHeight;

            imageWidth = imageWidth * scale;
            imageHeight = containerHeight;
        }
    }

    return {
        width: imageWidth,
        height: imageHeight,
    };
}

export const getPaperDimension = (paperType) => {
    if (!paperType) {
        paperType = 'letter';
    }

    switch (paperType.toLowerCase()) {
        case 'a4':
            return {
                type: paperType.toLowerCase(),
                width: REFERRAL_TEMPLATE_PAGE_SETTINGS_PAPER_A4_WIDTH,
                height: REFERRAL_TEMPLATE_PAGE_SETTINGS_PAPER_A4_HEIGHT,
            };

        case 'legal':
            return {
                type: paperType.toLowerCase(),
                width: REFERRAL_TEMPLATE_PAGE_SETTINGS_PAPER_LEGAL_WIDTH,
                height: REFERRAL_TEMPLATE_PAGE_SETTINGS_PAPER_LEGAL_HEIGHT,
            };

        case 'letter':
        default:
            return {
                type: paperType.toLowerCase(),
                width: REFERRAL_TEMPLATE_PAGE_SETTINGS_PAPER_LETTER_WIDTH,
                height: REFERRAL_TEMPLATE_PAGE_SETTINGS_PAPER_LETTER_HEIGHT,
            };
    }
}

export const getTextAreaRows = (value) => {
    const numberOfReturns = value ? (value.match(/\n/g) || []).length : 0;
    return (numberOfReturns + 1);
}