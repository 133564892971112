import React, { useEffect, useContext, useState, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { GlobalHotKeys } from 'react-hotkeys';
import { useObserver } from 'mobx-react-lite';

import { quickDrawerFocus, renderQuickDrawerLoading } from '../../../../_shared/QuickDrawer';
import QuickDrawerHeader from '../../../../_shared/QuickDrawerHeader';
import RichTextEditor from '../../../../_shared/RichTextEditor';

import PrescriptionPrintTemplateCreateStore from '../../../../../../stores/PrescriptionPrintTemplateCreateStore';
import QuickDrawerStore from '../../../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../../../constants/errorMessages';
import * as fn from '../../../../../../utilities/_functions';
import * as oh from '../../../../../../utilities/operationHelper';

import api from '../../../../../../api';
import './NewPrescriptionPrintTemplate.scss';

function NewPrescriptionPrintTemplate(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const prescriptionPrintTemplate = useContext(PrescriptionPrintTemplateCreateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [prescriptionTypes, setPrescriptionTypes] = useState([]);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100)

        if (!isReady) {
            prescriptionPrintTemplate.initialize();
            api.PrescriptionTypes.all()
                .then(({ data }) => {
                    if (isMounted.current) {
                        setPrescriptionTypes(data);
                    }
                })
                .finally(() => {
                    if (isMounted.current) {
                        setIsReady(true);
                    }
                })
        }

        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (prescriptionPrintTemplate.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = event => {
        event.preventDefault();
        prescriptionPrintTemplate.save()
            .then(data => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, data);
                    }
                }
            })
    }

    const handleNameChange = event => {
        prescriptionPrintTemplate.data.name = event.target.value;
        prescriptionPrintTemplate.hasUnsavedChanges = true;
    }

    const handleDescriptionChange = content => {
        const html = content;
        prescriptionPrintTemplate.data.descriptionHtml = ((html === '<p><br></p>') ? null : html);
        prescriptionPrintTemplate.hasUnsavedChanges = true;
    }

    const handlePrescriptionTypeChange = event => {
        prescriptionPrintTemplate.data.prescriptionTypeId = event.target.value;
        prescriptionPrintTemplate.hasUnsavedChanges = true;
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form onSubmit={handleSubmit}>
            <fieldset disabled={prescriptionPrintTemplate.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('prescription-print-template', 'default')}
                        action='New'
                        category='Prescription Print Template'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        {
                            prescriptionPrintTemplate.isReady && isReady ?
                                <FadeIn>
                                    <div className='new-prescription-print-template body-content'>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label className='required' htmlFor='new-prescription-print-template-name'><small>Name</small></label>
                                                        <input
                                                            id='new-prescription-print-template-name'
                                                            type='text'
                                                            maxLength={100}
                                                            className='form-control'
                                                            autoComplete={'off'}
                                                            value={prescriptionPrintTemplate.data.name ? prescriptionPrintTemplate.data.name : ''}
                                                            onChange={handleNameChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label htmlFor='new-prescription-print-template-description'><small>Description</small></label>
                                                        <RichTextEditor
                                                            mode='none'
                                                            disableTab={true}
                                                            value={prescriptionPrintTemplate.data.descriptionHtml ? prescriptionPrintTemplate.data.descriptionHtml : ''}
                                                            onChange={handleDescriptionChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label className='required' htmlFor='new-prescription-print-type'><small>Prescription Type</small></label>
                                                        <select
                                                            id='new-prescription-print-type'
                                                            className='custom-select form-control'
                                                            value={prescriptionPrintTemplate.data.prescriptionTypeId ? prescriptionPrintTemplate.data.prescriptionTypeId : ''}
                                                            onChange={handlePrescriptionTypeChange}
                                                        >
                                                            <option value=''></option>
                                                            {
                                                                prescriptionTypes.map((option, pi) => {
                                                                    return <option key={`prescription_type_${pi}`} value={option.id}>{option.name}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </FadeIn> : renderQuickDrawerLoading()
                        }
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-4'></div>
                            <div className='col-8'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>)
}

export default NewPrescriptionPrintTemplate;