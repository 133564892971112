import React, { useEffect, useContext, useRef, useState } from 'react';
import { toJS } from 'mobx';
import FadeIn from 'react-fade-in';
import { Observer } from 'mobx-react-lite';
import List, { ItemDragging } from 'devextreme-react/list';
import { GlobalHotKeys } from 'react-hotkeys';
import TimeAgo from 'react-timeago';
import moment from 'moment';

import BodyEnd from '../../_shared/BodyEnd';
import ConfirmModal from '../../_shared/ConfirmModal';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';

import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as oh from '../../../../utilities/operationHelper';
import * as bh from '../../../../utilities/badgeHelper';
import * as sys from '../../../../utilities/systemHelper';

import './UpdateWorkOrder.scss';

function UpdateWorkOrder(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const focusTimer = useRef(null);
    const quickDrawer = useContext(QuickDrawerStore);
    const [confirmDeleteWorkItem, setConfirmDeleteWorkItem] = useState(false);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    useEffect(() => {
        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100)

        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleWorkOrderPublishedTemplateChange = (event, item) => {
        const value = event.target.value;
        const index = props.extraProps.workOrder.items.findIndex(i => i.id === item.id);

        if (index >= 0) {
            if (value) {
                props.extraProps.workOrder.items[index].workOrderPublishedTemplateId = value;
            } else {
                if (props.extraProps.workOrder.items[index].isNew) {
                    props.extraProps.workOrder.items.splice(index, 1);
                } else {
                    setConfirmDeleteWorkItem(item);
                }
            }
        }
        setHasUnsavedChanges(true);
    }

    const handleConfirmDeleteWorkItem = event => {
        const index = props.extraProps.workOrder.items.findIndex(i => i.id === confirmDeleteWorkItem.id);
        props.extraProps.workOrder.items.splice(index, 1);
        setConfirmDeleteWorkItem(null);
    }

    const handleConfirmDeleteWorkItemCancel = event => {
        setConfirmDeleteWorkItem(null);
    }

    const handleWorkOrderItemAdd = (event) => {
        const extraProps = {
            id: props.extraProps.workOrder.id,
            purchase: props.extraProps.purchase,
            workOrders: [props.extraProps.workOrder, ...props.extraProps.purchase.workOrders.filter(wo => !wo.isExpired && !wo.isDeactivated && wo.id !== props.extraProps.workOrder.id)],
            templates: props.extraProps.templates,
        }
        quickDrawer.activateQuickDrawer('order', 'add-item', extraProps, handleWorkOrderItemAddSuccess)
            .then(drawer => {
                if (isMounted.current) {
                    focusTimer.current = setTimeout(() => {
                        quickDrawerFocus(drawer);
                    }, 100);
                }
            });
    }

    const handleWorkOrderItemAddSuccess = (result) => {
        if (result && result.data && result.data.items && result.data.items.length > 0) {
            const workOrder = JSON.parse(JSON.stringify(props.extraProps.workOrder));

            for (let i = 0; i < result.data.items.length; i++) {
                const newWorkItem = {
                    id: result.data.items[i].id,
                    workOrderId: workOrder.id,
                    transactionItemIds: [result.data.items[i].transactionItemId, ...result.data.items[i].otherTransactionItemIds],
                    productType: result.data.items[i].referenceType,
                    brandName: result.data.items[i].product.brandName,
                    supplierName: result.data.items[i].product.supplierName,
                    descriptor: result.data.items[i].product.descriptor,
                    overriddenBrandName: null,
                    overriddenSupplierName: null,
                    overriddenDescriptor: null,
                    overriddenUnitCost: null,
                    isOverridden: false,
                    workOrderPublishedTemplateId: result.data.items[i].workOrderPublishedTemplateId,
                    displayOrder: (workOrder.items.length + 2) * 10,
                    lastUpdatedDateUtc: null,
                    createdDateUtc: moment.utc(),
                    isNew: true
                };

                workOrder.items.push(newWorkItem);
            }

            for (let x = 0; x < workOrder.items.length; x++) {
                workOrder.items[x].displayOrder = x * 10;
            }

            props.extraProps.workOrder = workOrder;
        }
    }

    const handleWorkOrderHighPriorityChange = (event) => {
        props.extraProps.workOrder.isHighPriority = !props.extraProps.workOrder.isHighPriority;
        setHasUnsavedChanges(true);
    }

    const handleWorkOrderItemReorder = (event) => {
        const { toIndex, fromIndex } = event;
        const reorderedItems = JSON.parse(JSON.stringify(props.extraProps.workOrder.items));

        reorderedItems.splice(fromIndex, 1);
        reorderedItems.splice(toIndex, 0, toJS(props.extraProps.workOrder.items[fromIndex]));

        for (let i = 0; i < reorderedItems.length; i++) {
            reorderedItems[i].displayOrder = i * 10;
        }

        props.extraProps.workOrder.items = reorderedItems.sort((a, b) => { return a.displayOrder - b.displayOrder });
        setHasUnsavedChanges(true);
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (props.extraProps.workOrder && fn.validateForm(validateRef.current)) {
            const data = toJS(props.extraProps.workOrder);

            if (isMounted.current) {
                if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                    props.onSuccess(event, { updated: true, data: data });
                }
            }
        }
    }

    const renderPurchaseDateTime = purchase => {
        const start = moment.utc(purchase.createdDateUtc).local();

        return moment().startOf('day').isSame(start.clone().startOf('day')) ?
            <TimeAgo
                className='text-gray-700 ml-auto'
                date={start.toDate()}
                title={sys.getFormattedLongDate(start, true)} 
                minPeriod={60}
            />
            : <span
                className='text-gray-700 ml-auto'
                title={sys.getFormattedLongDate(start, true)}
            >
                {sys.getFormattedLongDate(start)}
            </span>
    }

    const renderWorkOrderItems = (item) => {
        return <Observer>{() => <>
            <div className='form-group mb-0'>
                <div
                    className='product-info cursor-pointer'
                >
                    <ul className='title'>
                        <li className='quantity'>
                            <h4>
                                <strong>{item.transactionItemIds.length}<small>x</small></strong>
                            </h4>
                        </li>
                        <li className='detail has-quantity'>
                            <span className='text-gray-700'>{item.productType ? item.productType : 'Custom'}</span>
                            <h4 className='m-0 text-truncate text-truncate-xxl'><strong>{item.descriptor}</strong></h4>
                            <small className='brand'>
                                <strong className='mr-1'>{item.brandName ? item.brandName : 'Custom'}</strong>
                                <>({item.supplierName ? item.supplierName : 'Custom'})</>
                            </small>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='ml-3 mt-h mb-2'>
                <Observer>{() =>
                    <select
                        className='custom-select form-control'
                        value={item.workOrderPublishedTemplateId ? item.workOrderPublishedTemplateId : ''}
                        onChange={(e) => { handleWorkOrderPublishedTemplateChange(e, item) }}
                    >
                        <option value=''>(Remove item)</option>
                        {
                            props.extraProps.templates && props.extraProps.templates.length > 0 ?
                                props.extraProps.templates.map((t, ti) => {
                                    return <option
                                        key={`new-order-item-${item.id}-template-${ti}`}
                                        value={t.id}
                                    >
                                        {t.name}
                                    </option>
                                }) : null
                        }
                    </select>
                }</Observer>
            </div>
        </>}</Observer>
    }

    const parseWorkItem = (item) => {
        if (item) {
            const parsedItem = JSON.parse(JSON.stringify(item));
            parsedItem.template = null;
            parsedItem.productData = null;
            parsedItem.workOrderData = null;

            return parsedItem;
        }

        return null;
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <fieldset>
                <div className='new-order-container quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('work-order', 'default')}
                        action='Update'
                        category='Work Order'
                        className='orders'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        <Observer>{() =>
                            <>
                                {
                                    props.extraProps.workOrder ?
                                        <FadeIn>
                                            <div className='body-content'>
                                                {
                                                    props.extraProps.workOrder.purchase ?
                                                        <>
                                                            <section>
                                                                <div className='row'>
                                                                    <div className='col-12'>
                                                                        <div
                                                                            className='d-flex flex-column align-items-center'
                                                                        >
                                                                            <div className='d-flex flex-row w-100 pt-1'>
                                                                                <div className='mb-0 flex-1 text-dark'>
                                                                                    <div className='d-flex mb-h'>
                                                                                        Invoice # {props.extraProps.workOrder.purchase.number}
                                                                                        {renderPurchaseDateTime(props.extraProps.workOrder.purchase)}
                                                                                    </div>
                                                                                    <div className='d-flex mb-h'>
                                                                                        <div className='flex-1'>
                                                                                            {bh.renderPurchaseReferenceTypes(props.extraProps.workOrder.purchase, 'p-1 mr-1 mb-1')}
                                                                                        </div>
                                                                                        <span className='text-gray-700 ml-auto'>{bh.renderPurchaseAmount(props.extraProps.workOrder.purchase)}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </section>
                                                            <section
                                                                className='m-0'
                                                            >
                                                                <div className='row border-top mt-3 pt-3'>
                                                                    <div className='col-8'>
                                                                        <label>
                                                                            <strong className='tt-uppercase text-primary-700'>Work Order # {props.extraProps.workOrder.number}</strong>
                                                                        </label>
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <div className='float-right'>
                                                                            <button
                                                                                type='button'
                                                                                className='btn btn-icon line-height-1 mr-n2'
                                                                                title='Add work order item'
                                                                                onClick={handleWorkOrderItemAdd}
                                                                            >
                                                                                <i className='fal fa-plus text-success-600 fs-lg'></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row pb-2'>
                                                                    <div className='col-12'>
                                                                        <div className='form-group'>
                                                                            <div className='custom-control custom-checkbox'>
                                                                                <input
                                                                                    id={`new-work-order-high-priority`}
                                                                                    type='checkbox'
                                                                                    name={`new-work-order-high-priority`}
                                                                                    className='custom-control-input'
                                                                                    checked={!!props.extraProps.workOrder.isHighPriority}
                                                                                    onChange={handleWorkOrderHighPriorityChange}
                                                                                />
                                                                                <label
                                                                                    htmlFor={`new-work-order-high-priority`}
                                                                                    className={'custom-control-label' + (props.extraProps.workOrder.isHighPriority ? ' text-danger' : '')}
                                                                                >
                                                                                    High Priority
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-12'>
                                                                        {
                                                                            props.extraProps.workOrder.items && props.extraProps.workOrder.items.length > 0 ?
                                                                                <List
                                                                                    dataSource={props.extraProps.workOrder.items.map(i => { return parseWorkItem(i) })}
                                                                                    activeStateEnabled={false}
                                                                                    focusStateEnabled={false}
                                                                                    hoverStateEnabled={false}
                                                                                    itemRender={(data) => { return renderWorkOrderItems(data) }}
                                                                                >
                                                                                    <ItemDragging
                                                                                        allowReordering={true}
                                                                                        group='work-orders'
                                                                                        data={props.extraProps.workOrder.id}
                                                                                        onReorder={handleWorkOrderItemReorder}
                                                                                    >
                                                                                    </ItemDragging>
                                                                                </List> : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </section>
                                                        </> : null
                                                }
                                            </div>
                                        </FadeIn> : renderQuickDrawerLoading()
                                }
                            </>
                        }</Observer>
                    </div>
                    <div className='quick-drawer-action pl-3'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <Observer>{() =>
                                        <button
                                            type='submit'
                                            className='btn btn-success'
                                            disabled={!props.extraProps.workOrder.items || props.extraProps.workOrder.items.length === 0}
                                        >Update</button>
                                    }</Observer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
        <BodyEnd>
            <ConfirmModal
                icon={<i className='fal fa-inbox text-danger mr-2'></i>}
                message={<>Continue to <span className='fw-500'>&nbsp;remove the item</span>?  All related data will be lost.</>}
                show={confirmDeleteWorkItem}
                onOption1Click={handleConfirmDeleteWorkItem}
                onCancel={handleConfirmDeleteWorkItemCancel}
            />
        </BodyEnd>
    </>
}

export default UpdateWorkOrder;