import { createContext } from 'react';
import { decorate, observable, action, computed } from 'mobx';
import moment from 'moment';

import api from '../api';
import * as fn from '../utilities/_functions';

export class PurchaseView {
    id = null;
    data = null;
    isLoading = false;

    cancelWorkOrderSearch = null;
    cancelPurchaseOrder = null;
    cancelPurchaseStart = null;

    start = (customer) => {
        const that = this;

        this.clear();
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            api.Purchases.start(customer.id, (c) => { that.cancelPurchaseStart = c })
                .then(({ data }) => {
                    that.data = data;
                    that.data.dueDate = moment().startOf('day');
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isLoading = false;
                    that.isReady = true;
                })
        })
    }

    load = (id, clear = true) => {
        if (id === this.id) return this.reload();

        const that = this;
        
        if (clear) {
            this.clear();
        }
        
        this.id = id;
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            api.Purchases.get(id, (c) => { that.cancelPurchaseOrder = c })
                .then(({ data }) => {
                    that.data = data;
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isLoading = false;
                    that.isReady = true;
                })
        })
    }

    reload = () => {
        if (!this.id) return Promise.reject();

        const id = this.id;
        this.id = null;

        return this.load(id, false);
    }

    clear = () => {
        this.id = null;
        this.data = null;
        this.isLoading = false;
        this.isReady = false;

        if (fn.isFunction(this.cancelWorkOrderSearch)) {
            this.cancelWorkOrderSearch();
            this.cancelWorkOrderSearch = null;
        }

        if (fn.isFunction(this.cancelPurchaseOrder)) {
            this.cancelPurchaseOrder();
            this.cancelPurchaseOrder = null;
        }

        if (fn.isFunction(this.cancelPurchaseStart)) {
            this.cancelPurchaseStart();
            this.cancelPurchaseStart = null;
        }
    }

    get isNew() {
        return this.id === null;
    }

    get taxRate1() {
        const item = this.data && this.data.items && this.data.items.filter(i => i.isTaxable && i.taxRate1)[0];
        return (item ? item.taxRate1 : 0);
    }

    get taxRate2() {
        const item = this.data && this.data.items && this.data.items.filter(i => i.isTaxable && i.taxRate2)[0];
        return (item ? item.taxRate2 : 0);
    }

    get taxRate3() {
        const item = this.data && this.data.items && this.data.items.filter(i => i.isTaxable && i.taxRate3)[0];
        return (item ? item.taxRate3 : 0);
    }

    get totalTaxRate() {
        return (this.taxRate1 + this.taxRate2 + this.taxRate3);
    }
}

decorate(PurchaseView, {
    id: observable,
    data: observable,
    isNew: computed,
    isLoading: observable,
    isReady: observable,
    load: action,
    reload: action,
    clear: action,
    taxRate1: computed,
    taxRate2: computed,
    taxRate3: computed,
    totalTaxRate: computed,
})

export default createContext(new PurchaseView());