import React, { useContext, useEffect, useRef, useState } from 'react';
import { Observer } from 'mobx-react-lite';
import FadeIn from 'react-fade-in';
import { GlobalHotKeys } from 'react-hotkeys';

import BodyEnd from '../../_shared/BodyEnd';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import { renderQuickDrawerLoading } from '../../_shared/QuickDrawer';
import InternalFormViewer from '../InternalFormViewer/_index';

import InternalFormCreateStore from '../../../../stores/InternalFormCreateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';
import CacheStore from '../../../../stores/CacheStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as oh from '../../../../utilities/operationHelper';

import './StartInternalForm.scss';

function StartInternalForm(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const internalForm = useContext(InternalFormCreateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const cache = useContext(CacheStore);
    const [displayViewer, setDisplayViewer] = useState(false);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (internalForm.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleCategoryChange = event => {
        internalForm.selectedCategory = event.target.value;
        internalForm.hasUnsavedChanges = true;
    }

    const handleTemplateIdChange = event => {
        internalForm.selectedTemplateId = event.target.value;
        internalForm.hasUnsavedChanges = true;
    }

    const handleInternalFormViewerSaved = (event, result) => {
        if (isMounted.current) {
            setDisplayViewer(false);

            if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                props.onSuccess(event, result);
            }
        }
    }

    const handleInternalFormViewerClose = event => {
        setDisplayViewer(false);
        internalForm.clear();
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            internalForm.loadTemplate()
                .then(() => {
                    if (isMounted.current) {
                        setDisplayViewer(true);
                    }
                })
        }
    }

    const getDistinctCategories = () => {
        const categories = internalForm.templates.map(t => t.category).filter((c, i, a) => a.indexOf(c) === i);
        return categories;
    }

    const getFilteredTemplates = () => {
        let templates = internalForm.selectedCategory ? internalForm.templates.filter(t => t.category === internalForm.selectedCategory) : internalForm.templates;

        templates = templates && templates.length > 0 ? templates.slice().sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        }) : [];

        return templates;
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <Observer>{() =>
                <fieldset disabled={!internalForm.isReady}>
                    <div className='new-internal-form-container quick-drawer'>
                        <QuickDrawerHeader
                            drawer={props.drawer}
                            icon={oh.getIcon('internal-form')}
                            action='Select an'
                            category='Internal Form'
                            className='internal-form'
                            onCancel={props.onCancel}
                        />
                        <div className='quick-drawer-body'>
                            {
                                internalForm.isReady ?
                                    <FadeIn>
                                        <div className='body-content'>
                                            <section>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='form-group mb-4'>
                                                            <label htmlFor='new-internal-form-category'><small>Category</small></label>
                                                            <select
                                                                id='new-internal-form-category'
                                                                className='custom-select form-control'
                                                                value={internalForm.selectedCategory ? internalForm.selectedCategory : ''}
                                                                onChange={handleCategoryChange}
                                                            >
                                                                <option value=''>(All)</option>
                                                                {
                                                                    getDistinctCategories() ?
                                                                        getDistinctCategories().map((c, ci) => {
                                                                            return <option
                                                                                key={`new_internal_form_category_${ci}`}
                                                                                value={c}
                                                                            >
                                                                                {
                                                                                    cache.getReferenceDataOptions('InternalFormCategoryType').some(o => o.key === c) ?
                                                                                        cache.getReferenceDataOptions('InternalFormCategoryType').filter(o => o.key === c)[0].value : c
                                                                                }
                                                                            </option>
                                                                        }) : null
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='form-group mb-4 validate validate-required'>
                                                            <label className='required' htmlFor='new-internal-form'><small>Form</small></label>
                                                            <select
                                                                id='new-internal-form'
                                                                className='custom-select form-control'
                                                                value={internalForm.selectedTemplateId ? internalForm.selectedTemplateId : ''}
                                                                onChange={handleTemplateIdChange}
                                                            >
                                                                <option value=''></option>
                                                                {
                                                                    getFilteredTemplates() ?
                                                                        getFilteredTemplates().map((t, ti) => {
                                                                            return <option
                                                                                key={`new_internal_form_${ti}`}
                                                                                value={t.id}
                                                                            >{t.name}</option>
                                                                        }) : null
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </FadeIn> : renderQuickDrawerLoading()
                            }
                        </div>
                        <div className='quick-drawer-action pl-3'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='float-right'>
                                        <button
                                            type='button'
                                            className='btn btn-link btn-cancel mr-2'
                                            onClick={handleCancel}
                                        >Cancel</button>
                                        <button
                                            type='submit'
                                            className='btn btn-info'
                                        >Continue</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            }</Observer>
        </form>
        <BodyEnd>
            {
                displayViewer ?
                    <InternalFormViewer display={displayViewer} printOnSaved={true} onSaved={handleInternalFormViewerSaved} onClose={handleInternalFormViewerClose} /> : null
            }
        </BodyEnd>
    </>
}

export default StartInternalForm;