import { createContext } from 'react';
import { toJS } from 'mobx';
import { decorate, observable, action } from 'mobx';

import * as fn from '../utilities/_functions';
import api from '../api';

export class ExternalClinicCreate {
    id = null;
    data = {
        name: null,
        address: {
            geocode: null,
            streetNumber: null,
            streetName: null,
            unitSuiteType: null,
            unitSuite: null,
            line2: null,
            locality: null,
            sublocality: null,
            postalCode: null,
            region: null,
            regionCode: null,
            country: null,
            countryCode: null,
        },
        websiteUrl: null,
        phoneNumber: null,
        faxNumber: null,
        emailAddress: null,
        noteHtml: null,
    };
    isReady = false;
    isSaving = false;
    hasUnsavedChanges = false;

    cancelExternalClinicCreate = null;

    initialize = () => {
        this.clear();
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            if (that.hasUnsavedChanges) {
                const option = toJS(that.data);

                api.ExternalClinics.create(option, (c) => { that.cancelExternalClinicCreate = c })
                    .then(({ data }) => {
                        that.id = data.id;
                        that.hasUnsavedChanges = false;
                        resolve(data);
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                that.hasUnsavedChanges = false;
                that.isSaving = false;
                resolve();
            }
        })
    }

    clearAddress = () => {
        this.data.address.geocode = null;
        this.data.address.streetNumber = null;
        this.data.address.streetName = null;
        this.data.address.unitSuiteType = null;
        this.data.address.unitSuite = null;
        this.data.address.line2 = null;
        this.data.address.locality = null;
        this.data.address.sublocality = null;
        this.data.address.postalCode = null;
        this.data.address.region = null;
        this.data.address.regionCode = null;
        this.data.address.country = null;
        this.data.address.countryCode = null;
    }

    clear = () => {
        this.id = null;
        this.data.name = null;        
        this.data.websiteUrl = null;
        this.data.phoneNumber = null;
        this.data.faxNumber = null;
        this.data.emailAddress = null;
        this.data.noteHtml = null;
        this.clearAddress();
        this.isReady = false;
        this.isSaving = false;
        this.hasUnsavedChanges = false;

        if (fn.isFunction(this.cancelExternalClinicCreate)) {
            this.cancelExternalClinicCreate();
            this.cancelExternalClinicCreate = null;
        }
    }
}

decorate(ExternalClinicCreate, {
    id: observable,
    data: observable.deep,
    isReady: observable,
    isSaving: observable,
    hasUnsavedChanges: observable,
    initialize: action,
    save: action,
    clearAddress: action,
    clear: action,
})

export default createContext(new ExternalClinicCreate());