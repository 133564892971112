import React, { Component } from 'react';

import * as fn from '../../../utilities/_functions';

import './BorderPicker.scss';

const DEFAULT_SIZE = 75;

export default class BorderPicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            border: {
                top: (props.border && props.border.top ? props.border.top : null),
                right: (props.border && props.border.right ? props.border.right : null),
                bottom: (props.border && props.border.bottom ? props.border.bottom : null),
                left: (props.border && props.border.left ? props.border.left : null),
            },
        };

        this.containerRef = React.createRef();
        this.handleTopClick = this.handleTopClick.bind(this);
        this.handleLeftClick = this.handleLeftClick.bind(this);
        this.handleBottomClick = this.handleBottomClick.bind(this);
        this.handleRightClick = this.handleRightClick.bind(this);
    }

    componentDidMount() {
        const size = this.containerRef && this.containerRef.current ? this.containerRef.current.parentElement.offsetWidth : DEFAULT_SIZE;
        this.containerRef.current.style.width = `${size}px`;
        this.containerRef.current.style.height = `${size}px`;
    }

    handleTopClick = event => {
        const { border } = this.state;
        const { width, style, color } = this.props;
        const newBorder = JSON.parse(JSON.stringify(border));
        const value = `${width ? width : 1}px ${(style ? style : 'solid')} ${(color ? color : '#000000')}`;

        if (border.top !== value) {
            newBorder.top = value;
        } else {
            newBorder.top = 'none';
        }
        
        this.setState({ border: newBorder });

        if (fn.isFunction(this.props.onChange)) {
            this.props.onChange(event, newBorder);
        }
    }

    handleLeftClick = event => {
        const { border } = this.state;
        const { width, style, color } = this.props;
        const newBorder = JSON.parse(JSON.stringify(border));
        const value = `${width ? width : 1}px ${(style ? style : 'solid')} ${(color ? color : '#000000')}`;

        if (border.left !== value) {
            newBorder.left = value;
        } else {
            newBorder.left = 'none';
        }
        
        this.setState({ border: newBorder });

        if (fn.isFunction(this.props.onChange)) {
            this.props.onChange(event, newBorder);
        }
    }

    handleBottomClick = event => {
        const { border } = this.state;
        const { width, style, color } = this.props;
        const newBorder = JSON.parse(JSON.stringify(border));
        const value = `${width ? width : 1}px ${(style ? style : 'solid')} ${(color ? color : '#000000')}`;

        if (border.bottom !== value) {
            newBorder.bottom = value;
        } else {
            newBorder.bottom = 'none';
        }
        
        this.setState({ border: newBorder });

        if (fn.isFunction(this.props.onChange)) {
            this.props.onChange(event, newBorder);
        }
    }

    handleRightClick = event => {
        const { border } = this.state;
        const { width, style, color } = this.props;
        const newBorder = JSON.parse(JSON.stringify(border));
        const value = `${width ? width : 1}px ${(style ? style : 'solid')} ${(color ? color : '#000000')}`;

        if (border.right !== value) {
            newBorder.right = value;
        } else {
            newBorder.right = 'none';
        }

        this.setState({ border: newBorder });

        if (fn.isFunction(this.props.onChange)) {
            this.props.onChange(event, newBorder);
        }
    }

    render() {
        const { border } = this.state;

        return (
            <div 
                ref={this.containerRef} 
                className='border-picker'
            >
                <div
                    className='bp-top'
                    style={{
                        borderBottom: (border && border.top ? border.top : `1px dashed #eeeeee`)
                    }}
                    onClick={this.handleTopClick}
                ></div>
                <div className='bp-container'>
                    <div
                        className='bp-left'
                        style={{
                            borderLeft: (border && border.left ? border.left : `1px dashed #eeeeee`)
                        }}
                        onClick={this.handleLeftClick}
                    ></div>
                    <div className='bp-middle'></div>
                    <div
                        className='bp-right'
                        style={{
                            borderRight: (border && border.right ? border.right : `1px dashed #eeeeee`)
                        }}
                        onClick={this.handleRightClick}
                    ></div>
                </div>
                <div
                    className='bp-bottom'
                    style={{
                        borderTop: (border && border.bottom ? border.bottom : `1px dashed #eeeeee`)
                    }}
                    onClick={this.handleBottomClick}
                ></div>
            </div>
        )
    }
}