import { createContext } from 'react';
import { decorate, observable, action, computed, toJS } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';
import * as ifh from '../utilities/internalFormTemplateHelper';

export class InternalFormTemplateView {
    id = null;
    data = null;
    selectedPageId = null;
    isReady = false;
    isSaving = false;
    isLoading = false;
    hasUnsavedChanges = false;

    cancelInternalFormPublishedTemplateCreate = null;
    cancelInternalFormTemplateGet = null;
    cancelInternalFormTemplateUpdate = null;

    initialize = id => {
        this.clear();
        this.id = id;

        return this.refresh();
    }

    refresh = () => {
        const that = this;
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            api.InternalFormTemplates.get(that.id, (c) => { that.cancelInternalFormTemplateGet = c })
                .then(({ data }) => {
                    that.data = data;
                    that.selectedPageId = that.data.pages[0].id;
                })
                .finally(() => {
                    that.isLoading = false;
                    that.isReady = true;
                    resolve();
                })
        })
    }

    addPage = (name) => {
        const that = this;
        const newPage = {
            id: fn.newId('fmpg_'),
            name: name,
            displayOrder: that.data.pages.length,
            pageSettings: that.data.pages && that.data.pages.length > 0 ? JSON.parse(JSON.stringify(that.data.pages[that.data.pages.length - 1].pageSettings)) : ifh.getPaperDimension('letter'),
            headerDefinition: that.data.pages && that.data.pages.length > 0 ? JSON.parse(JSON.stringify(that.data.pages[that.data.pages.length - 1].headerDefinition)) : [],
            bodyDefinition: [],
            footerDefinition: that.data.pages && that.data.pages.length > 0 ? JSON.parse(JSON.stringify(that.data.pages[that.data.pages.length - 1].footerDefinition)) : [],
        };

        this.data.pages.push(newPage);
        this.selectedPageId = newPage.id;
        this.hasUnsavedChanges = true;

        return Promise.resolve();
    }

    updatePage = (id, name) => {
        for (let i = 0; i < this.data.pages.length; i++) {
            if (this.data.pages[i].id === id) {
                this.data.pages[i].name = name;
                this.hasUnsavedChanges = true;
            }
        }

        return Promise.resolve();
    }

    removePage = (id) => {
        const index = this.data.pages.findIndex(s => s.id === id);

        if (index >= 0) {
            this.data.pages.splice(index, 1);
            this.selectedPageId = this.data.pages[0].id;
            this.hasUnsavedChanges = true;
        }

        return Promise.resolve();
    }

    sortDefinition = () => {
        if (this.data && this.data.pages && this.data.pages.length > 0) {
            for (let i = 0; i < this.data.pages.length; i++) {
                if (this.data.pages[i] && this.data.pages[i].headerDefinition && this.data.pages[i].headerDefinition.length > 0) {
                    const headerDefinition = toJS(this.data.pages[i].headerDefinition)
                        .sort((a, b) => {
                            if (a.position.y === b.position.y) {
                                return a.position.x > b.position.x ? 1 : -1;
                            }

                            return a.position.y > b.position.y ? 1 : -1;
                        })

                    this.data.pages[i].headerDefinition = headerDefinition;
                }
                if (this.data.pages[i] && this.data.pages[i].bodyDefinition && this.data.pages[i].bodyDefinition.length > 0) {
                    const bodyDefinition = toJS(this.data.pages[i].bodyDefinition)
                        .sort((a, b) => {
                            if (a.position.y === b.position.y) {
                                return a.position.x > b.position.x ? 1 : -1;
                            }

                            return a.position.y > b.position.y ? 1 : -1;
                        })

                    this.data.pages[i].bodyDefinition = bodyDefinition;
                }
                if (this.data.pages[i] && this.data.pages[i].footerDefinition && this.data.pages[i].footerDefinition.length > 0) {
                    const footerDefinition = toJS(this.data.pages[i].footerDefinition)
                        .sort((a, b) => {
                            if (a.position.y === b.position.y) {
                                return a.position.x > b.position.x ? 1 : -1;
                            }

                            return a.position.y > b.position.y ? 1 : -1;
                        })

                    this.data.pages[i].footerDefinition = footerDefinition;
                }
            }
        }
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            let option = toJS(that.data);

            if (that.hasUnsavedChanges) {
                that.sortDefinition();

                api.InternalFormTemplates.update(that.id, option, (c) => { that.cancelInternalFormTemplateUpdate = c })
                    .then(() => {
                        that.hasUnsavedChanges = false;
                        resolve(option);
                    })
                    .catch(() => {
                        reject();
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                resolve(option);
            }
        })
    }

    publish = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            api.InternalFormPublishedTemplates.create({ internalFormTemplateId: that.id }, (c) => { that.cancelInternalFormPublishedTemplateCreate = c })
                .then(() => {
                    that.hasUnsavedChanges = false;
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }


    clear = () => {
        this.id = null;
        this.data = null;
        this.selectedPageId = null;
        this.isReady = false;
        this.isSaving = false;
        this.isLoading = false;
        this.hasUnsavedChanges = false;

        if (fn.isFunction(this.cancelInternalFormPublishedTemplateCreate)) {
            this.cancelInternalFormPublishedTemplateCreate();
            this.cancelInternalFormPublishedTemplateCreate = null;
        }

        if (fn.isFunction(this.cancelInternalFormTemplateGet)) {
            this.cancelInternalFormTemplateGet();
            this.cancelInternalFormTemplateGet = null;
        }

        if (fn.isFunction(this.cancelInternalFormTemplateUpdate)) {
            this.cancelInternalFormTemplateUpdate();
            this.cancelInternalFormTemplateUpdate = null;
        }
    }

    get selectedPage() {
        return this.data && this.data.pages ?
            this.data.pages.filter(s => s.id === this.selectedPageId)[0] : null;
    }

    get sortedPages() {
        if (!this.data || !this.data.pages) return [];

        const pages = toJS(this.data.pages);
        const sortedPages = pages.sort((a, b) => { return a.displayOrder - b.displayOrder });

        return sortedPages;
    }

    get isNotFound() {
        return this.isReady && !this.data;
    }
}

decorate(InternalFormTemplateView, {
    id: observable,
    data: observable.deep,
    selectedPageId: observable,
    isReady: observable,
    isSaving: observable,
    isLoading: observable,
    hasUnsavedChanges: observable,
    addPage: action,
    updatePage: action,
    removePage: action,
    initialize: action,
    refresh: action,
    save: action,
    clear: action,
    selectedPage: computed,
    sortedPages: computed,
    isNotFound: computed,
})

export default createContext(new InternalFormTemplateView());