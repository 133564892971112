import React, { useEffect, useContext, useState, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { GlobalHotKeys } from 'react-hotkeys';
import { DropdownList, Combobox } from 'react-widgets';
import { useObserver } from 'mobx-react-lite';
import { Mention, MentionsInput } from 'react-mentions';

import QuickDrawerHeader from '../../../../_shared/QuickDrawerHeader';
import { quickDrawerFocus } from '../../../../_shared/QuickDrawer';
import BorderPicker from '../../../../_shared/BorderPicker';
import ColorPicker from '../../../../_shared/ColorPicker';
import RichTextEditor from '../../../../_shared/RichTextEditor';

import InternalFormElementViewStore from '../../../../../../stores/InternalFormElementViewStore';
import QuickDrawerStore from '../../../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../../../constants/errorMessages';
import * as fn from '../../../../../../utilities/_functions';
import * as ith from '../../../../../../utilities/internalFormTemplateHelper';
import * as mh from '../../../../../../utilities/mentionHelper';

import api from '../../../../../../api';

import './UpdateExistingInternalFormElement.scss';

function UpdateExistingInternalFormElement(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const handleWorkOrderTemplatesCancel = useRef(null);
    const handleWorkOrderPublishedTemplatesCancel = useRef(null);
    const internalFormElement = useContext(InternalFormElementViewStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [align, setAlign] = useState('');
    const [valign, setValign] = useState('');
    const [padding, setPadding] = useState([]);
    const [fontColor, setFontColor] = useState('');
    const [fontSize, setFontSize] = useState(null);
    const [fontFamily, setFontFamily] = useState('');
    const [fontStyle, setFontStyle] = useState([]);
    const [fontLetterSpacing, setFontLetterSpacing] = useState(null);
    const [image, setImage] = useState(null);
    const [backgroundColor, setBackgroundColor] = useState('');
    const [border, setBorder] = useState(null);
    const [borderWidth, setBorderWidth] = useState(1);
    const [borderStyle, setBorderStyle] = useState('solid');
    const [borderColor, setBorderColor] = useState('#000');
    const [alternateBackgroundColor, setAlternateBackgroundColor] = useState('');
    const [content, setContent] = useState('');
    const [format, setFormat] = useState(null);
    const [isReady, setIsReady] = useState(false);
    const [workOrderPublishedTemplates, setWorkOrderPublishedTemplates] = useState([]);
    const [workOrderTemplateId, setWorkOrderTemplateId] = useState(null);
    const [workOrderFields, setWorkOrderFields] = useState([]);
    const [workOrderField, setWorkOrderField] = useState(null);

    useEffect(() => {
        const requests = [api.WorkOrderPublishedTemplates.all((c) => { handleWorkOrderPublishedTemplatesCancel.current = c })];
        const existingAlign = internalFormElement.getMetadata('align');
        const existingValign = internalFormElement.getMetadata('valign');
        const existingPadding = internalFormElement.getMetadata('padding');
        const existingFontColor = internalFormElement.getMetadata('fontColor');
        const existingFontSize = internalFormElement.getMetadata('fontSize');
        const existingFontFamily = internalFormElement.getMetadata('fontFamily');
        const existingFontStyle = internalFormElement.getMetadata('fontStyle');
        const existingFontLetterSpacing = internalFormElement.getMetadata('fontLetterSpacing');
        const existingImage = internalFormElement.getMetadata('image');
        const existingBackgroundColor = internalFormElement.getMetadata('backgroundColor');
        const existingBorder = internalFormElement.getMetadata('border');
        const existingAlternateBackgroundColor = internalFormElement.getMetadata('alternateBackgroundColor');
        const existingContent = internalFormElement.getMetadata('content');
        const existingFormat = internalFormElement.getMetadata('format');
        const existingWorkOrder = internalFormElement.getMetadata('workOrder');

        setAlign((existingAlign ? existingAlign : 'left'));
        setValign((existingValign ? existingValign : 'middle'));
        setPadding((existingPadding ? existingPadding : [0, 0, 0, 0]));
        setFontColor((existingFontColor ? existingFontColor : '#666666'));
        setFontSize((existingFontSize ? existingFontSize : 13));
        setFontFamily((existingFontFamily ? existingFontFamily : 'Arial'));
        setFontStyle((existingFontStyle ? existingFontStyle : []));
        setFontLetterSpacing((existingFontLetterSpacing ? existingFontLetterSpacing : 0));
        setImage((existingImage ? existingImage : null));
        setBackgroundColor((existingBackgroundColor ? existingBackgroundColor : ''));
        setBorder((existingBorder ? existingBorder : null));
        setAlternateBackgroundColor((existingAlternateBackgroundColor ? existingAlternateBackgroundColor : '#f3f3f3'));
        setContent((existingContent ? existingContent : ''));
        setFormat((existingFormat ? existingFormat : ith.getFormatOptions(internalFormElement.data.type)[0] ? ith.getFormatOptions(internalFormElement.data.type)[0].type : ''));

        if (existingWorkOrder) {
            setWorkOrderTemplateId(existingWorkOrder.id ? existingWorkOrder.id : '');
            setWorkOrderField(existingWorkOrder.field ? existingWorkOrder.field : '');
            requests.push(api.WorkOrderTemplates.get(existingWorkOrder.id, (c) => { handleWorkOrderTemplatesCancel.current = c }))
        }

        Promise.all(requests)
            .then(response => {
                if (response && isMounted.current) {
                    if (response[0] && response[0].data) {
                        setWorkOrderPublishedTemplates(response[0].data);

                        if (response[1] && response[1].data) {
                            populateWorkOrderFields(response[0].data, response[1].data.publishedTemplateId);                            
                        }
                    }
                }
            })
            .finally(() => {
                if (isMounted.current) {
                    setIsReady(true);
                }
            })

        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100)

        return (() => {
            isMounted.current = false;

            internalFormElement.clear();
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
            if (handleWorkOrderTemplatesCancel.current) { handleWorkOrderTemplatesCancel.current(); handleWorkOrderTemplatesCancel.current = null; }
            if (handleWorkOrderPublishedTemplatesCancel.current) { handleWorkOrderPublishedTemplatesCancel.current(); handleWorkOrderPublishedTemplatesCancel.current = null; }
        })
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (internalFormElement.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = event => {
        event.preventDefault();
        handlePopulateMetadata();
        internalFormElement.save()
            .then(data => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, { container: props.extraProps.container, data: data });
                    }
                }
            })
    }

    const handleWorkOrderPublishedTemplateChange = event => {
        const value = event.target.value;
        populateWorkOrderFields(workOrderPublishedTemplates, value);
        setWorkOrderTemplateId(value);
        internalFormElement.hasUnsavedChanges = true;
    }

    const handleWorkOrderFieldChange = event => {
        const value = event.target.value;
        setWorkOrderField(value);
        internalFormElement.hasUnsavedChanges = true;
    }

    const handleContentChange = content => {
        const html = ((content === '<p><br></p>') ? null : content);
        setContent(html);
        internalFormElement.hasUnsavedChanges = true;
    }

    const handleFormatChange = event => {
        setFormat(event.target.value);
        internalFormElement.hasUnsavedChanges = true;
    }

    const handleFontFamilyChange = (value) => {
        setFontFamily(value);
        internalFormElement.hasUnsavedChanges = true;
    }

    const handleFontSizeChange = (value) => {
        setFontSize(value);
        internalFormElement.hasUnsavedChanges = true;
    }

    const handleFontColorChange = (color) => {
        setFontColor(color.value);
        internalFormElement.hasUnsavedChanges = true;
    }

    const handleFontStyleChange = value => {
        const index = fontStyle.findIndex(s => s === value);
        const tempFontStyle = [...fontStyle];

        if (index === -1) {
            tempFontStyle.push(value);
        } else {
            tempFontStyle.splice(index, 1);
        }

        setFontStyle(tempFontStyle);
        internalFormElement.hasUnsavedChanges = true;
    }

    const handleFontLetterSpacing = event => {
        const spacing = event.target.value;
        setFontLetterSpacing((spacing ? spacing : 0));
        internalFormElement.hasUnsavedChanges = true;
    }

    const handleAlignChange = value => {
        setAlign((value ? value : 'left'));
        internalFormElement.hasUnsavedChanges = true;
    }

    const handleValignChange = value => {
        setValign((value ? value : 'middle'));
        internalFormElement.hasUnsavedChanges = true;
    }

    const handlePaddingChange = (event, index) => {
        const newPadding = [...padding];

        newPadding[index] = event.target.value;
        setPadding(newPadding);
        internalFormElement.hasUnsavedChanges = true;
    }

    const handleBorderChange = (event, data) => {
        setBorder(data);
        internalFormElement.hasUnsavedChanges = true;
    }

    const handleBorderStyleChange = (item) => {
        if (item) {
            setBorderWidth(item.data.width);
            setBorderStyle(item.data.style);
        }
        internalFormElement.hasUnsavedChanges = true;
    }

    const handleBorderColorChange = (color) => {
        setBorderColor(color.value);
        internalFormElement.hasUnsavedChanges = true;
    }

    const handleBackgroundColorChange = (color) => {
        setBackgroundColor(color.value);
        internalFormElement.hasUnsavedChanges = true;
    }

    const handleImageClick = event => {
        setImage(null);
        internalFormElement.hasUnsavedChanges = true;
    }

    const handleImageChange = event => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = handleFileReaderLoad;
        reader.readAsDataURL(file);
    }

    const handleFileReaderLoad = (event) => {
        const reader = event.target;
        const base64 = reader.result;
        const tempImage = new Image();

        tempImage.src = event.target.result;
        tempImage.onload = () => {
            const newImage = {
                base64: base64,
                width: tempImage.width,
                height: tempImage.height,
            }
            setImage(newImage);
        }
        internalFormElement.hasUnsavedChanges = true;
    }

    const handlePopulateMetadata = () => {
        populateMetadata(ith.INTERNAL_FORM_TEMPLATE_METADATA_ALIGN, 'align', align);
        populateMetadata(ith.INTERNAL_FORM_TEMPLATE_METADATA_VALIGN, 'valign', valign);
        populateMetadata(ith.INTERNAL_FORM_TEMPLATE_METADATA_PADDING, 'padding', padding);
        populateMetadata(ith.INTERNAL_FORM_TEMPLATE_METADATA_FONT_COLOR, 'fontColor', fontColor);
        populateMetadata(ith.INTERNAL_FORM_TEMPLATE_METADATA_FONT_SIZE, 'fontSize', fontSize);
        populateMetadata(ith.INTERNAL_FORM_TEMPLATE_METADATA_FONT_FAMILY, 'fontFamily', fontFamily);
        populateMetadata(ith.INTERNAL_FORM_TEMPLATE_METADATA_FONT_STYLE, 'fontStyle', fontStyle);
        populateMetadata(ith.INTERNAL_FORM_TEMPLATE_METADATA_FONT_LETTER_SPACING, 'fontLetterSpacing', fontLetterSpacing);
        populateMetadata(ith.INTERNAL_FORM_TEMPLATE_METADATA_BACKGROUND_COLOR, 'backgroundColor', backgroundColor);
        populateMetadata(ith.INTERNAL_FORM_TEMPLATE_METADATA_BORDER, 'border', border);
        populateMetadata(ith.INTERNAL_FORM_TEMPLATE_METADATA_BORDER_COLOR, 'borderColor', borderColor);
        populateMetadata(ith.INTERNAL_FORM_TEMPLATE_METADATA_ALTERNATE_BACKGROUND_COLOR, 'alternateBackgroundColor', alternateBackgroundColor);
        populateMetadata(ith.INTERNAL_FORM_TEMPLATE_METADATA_CONTENT, 'content', content);
        populateMetadata(ith.INTERNAL_FORM_TEMPLATE_METADATA_IMAGE, 'image', image);
        populateMetadata(ith.INTERNAL_FORM_TEMPLATE_METADATA_FORMAT, 'format', format);

        if (workOrderTemplateId || workOrderField) {
            populateMetadata(ith.INTERNAL_FORM_TEMPLATE_METADATA_FORMAT, 'workOrder', {
                id: workOrderTemplateId,
                field: workOrderField,
            });
        } else {
            populateMetadata(ith.INTERNAL_FORM_TEMPLATE_METADATA_FORMAT, 'workOrder', null);
        }
    }

    const populateMetadata = (field, key, value) => {
        if (ith.hasField(internalFormElement.data.type, field)) {
            internalFormElement.setMetadata(key, value);
        } else {
            internalFormElement.removeMetadata(key);
        }
    }

    const populateWorkOrderFields = (publishedTemplates, id) => {
        let fields = [
            { id: 'quantity', display: 'Quantity' },
            { id: 'productType', display: 'Product Type' },
            { id: 'brandName', display: 'Brand' },
            { id: 'supplierName', display: 'Supplier' },
            { id: 'brandSupplierName', display: 'Brand Name (Supplier Name)' },
            { id: 'descriptor', display: 'Descriptor' },
            { id: 'quantityDescriptor', display: 'Quantity and Descriptor' },
        ];
        const index = publishedTemplates.findIndex(t => t.id === id);

        if (index > -1) {
            fields.push(...publishedTemplates[index].definition.definition.filter(d => d.key && d.name).map(d => { return { id: d.key, display: d.name } }).flat());
        }

        setWorkOrderFields(fields);
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form onSubmit={handleSubmit}>
            <fieldset disabled={internalFormElement.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={ith.getIcon(internalFormElement.data.type)}
                        action='Update'
                        category={`${ith.getDescription(internalFormElement.data.type)}`}
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        {
                            isReady ?
                                <FadeIn>
                                    <div className='update-internal-form-text body-content'>
                                        <section>
                                            {
                                                ith.hasField(internalFormElement.data.type, ith.INTERNAL_FORM_TEMPLATE_METADATA_WORK_ORDER_FIELD) ?
                                                    <>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className='form-group mb-4'>
                                                                    <label htmlFor='update-internal-form-field-type'><small>Type</small></label>
                                                                    <select
                                                                        id='update-internal-form-field-type'
                                                                        className='custom-select form-control'
                                                                        value={workOrderTemplateId}
                                                                        onChange={handleWorkOrderPublishedTemplateChange}
                                                                    >
                                                                        <option value=''></option>
                                                                        {
                                                                            workOrderPublishedTemplates && workOrderPublishedTemplates.length > 0 ?
                                                                                workOrderPublishedTemplates.map((t, ti) => {
                                                                                    return <option key={`update_internal_form_field_type_${ti}`} value={t.definition.id}>{t.name}</option>
                                                                                }) : null
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className='form-group mb-4'>
                                                                    <label htmlFor='update-internal-form-field'><small>Field</small></label>
                                                                    <MentionsInput
                                                                        singleLine
                                                                        id='update-internal-form-field'
                                                                        className='react-mentions-wrapper'
                                                                        autoComplete='off'
                                                                        value={workOrderField ? workOrderField : ''}
                                                                        title={mh.getDisplay(workOrderField)}
                                                                        onChange={handleWorkOrderFieldChange}
                                                                    >
                                                                        <Mention
                                                                            trigger='#'
                                                                            data={workOrderFields}
                                                                            markup={mh.MENTION_MARKUP}
                                                                        />
                                                                    </MentionsInput>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </> : null
                                            }
                                            {
                                                ith.hasField(internalFormElement.data.type, ith.INTERNAL_FORM_TEMPLATE_METADATA_CONTENT) ?
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-4'>
                                                                <label htmlFor='update-internal-form-text'><small>Content</small></label>
                                                                <RichTextEditor
                                                                    mode='basic'
                                                                    disableTab={true}
                                                                    value={content}
                                                                    onChange={handleContentChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div> : null
                                            }
                                            {
                                                ith.hasField(internalFormElement.data.type, ith.INTERNAL_FORM_TEMPLATE_METADATA_FORMAT) ?
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-4'>
                                                                <label htmlFor='update-internal-form-format'><small>Format</small></label>
                                                                {
                                                                    ith.getFormatOptions(internalFormElement.data.type).map((o, oi) => {
                                                                        return <div
                                                                            key={`update-internal-form-format-${oi}`}
                                                                            className='custom-control custom-radio mb-2'
                                                                        >
                                                                            <input
                                                                                id={`update-internal-form-format-${oi}`}
                                                                                type='radio'
                                                                                name='updateInternalFormFormat'
                                                                                value={o.type}
                                                                                className='custom-control-input'
                                                                                checked={format === o.type}
                                                                                onChange={handleFormatChange}
                                                                            />
                                                                            <label
                                                                                htmlFor={`update-internal-form-format-${oi}`}
                                                                                className='custom-control-label'
                                                                            >
                                                                                {o.example}
                                                                            </label>
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div> : null
                                            }
                                            {
                                                ith.hasField(internalFormElement.data.type, ith.INTERNAL_FORM_TEMPLATE_METADATA_FONT_FAMILY) ||
                                                    ith.hasField(internalFormElement.data.type, ith.INTERNAL_FORM_TEMPLATE_METADATA_FONT_SIZE) ||
                                                    ith.hasField(internalFormElement.data.type, ith.INTERNAL_FORM_TEMPLATE_METADATA_FONT_COLOR) ?
                                                    <div className='row'>
                                                        {
                                                            ith.hasField(internalFormElement.data.type, ith.INTERNAL_FORM_TEMPLATE_METADATA_FONT_FAMILY) ?
                                                                <div className='col-6'>
                                                                    <div className='form-group mb-4'>
                                                                        <label htmlFor='update-internal-form-font-family'><small>Font</small></label>
                                                                        <DropdownList
                                                                            data={['Arial', 'Arial Black', 'Georgia', 'Impact', 'Times New Roman', 'Verdana']}
                                                                            value={fontFamily}
                                                                            itemComponent={({ item }) => (
                                                                                <div>
                                                                                    <span style={{ fontFamily: item }}>{item}</span>
                                                                                </div>
                                                                            )}
                                                                            valueComponent={({ item }) => (
                                                                                item ?
                                                                                    <div>
                                                                                        <span style={{ fontFamily: item }}>{item}</span>
                                                                                    </div> : null
                                                                            )}
                                                                            onChange={handleFontFamilyChange}
                                                                        />
                                                                    </div>
                                                                </div> : null
                                                        }
                                                        {
                                                            ith.hasField(internalFormElement.data.type, ith.INTERNAL_FORM_TEMPLATE_METADATA_FONT_SIZE) ?
                                                                <div className='col-3'>
                                                                    <div className='form-group mb-4'>
                                                                        <label htmlFor='update-internal-form-font-size'><small>Size</small></label>
                                                                        <Combobox
                                                                            data={[8, 9, 10, 11, 12, 13, 14, 18, 21, 24, 28, 30, 32, 36, 48, 60, 72, 96]}
                                                                            value={fontSize}
                                                                            autoSelectMatches={false}
                                                                            itemComponent={({ item }) => (
                                                                                <div>
                                                                                    <span>{item}</span>
                                                                                </div>
                                                                            )}
                                                                            valueComponent={({ item }) => (
                                                                                item ?
                                                                                    <div>
                                                                                        <span>{item}</span>
                                                                                    </div> : null
                                                                            )}
                                                                            onChange={handleFontSizeChange}
                                                                        />
                                                                    </div>
                                                                </div> : null
                                                        }
                                                        {
                                                            ith.hasField(internalFormElement.data.type, ith.INTERNAL_FORM_TEMPLATE_METADATA_FONT_COLOR) ?
                                                                <div className='col-3'>
                                                                    <div className='form-group mb-4'>
                                                                        <label htmlFor='update-internal-form-font-color'><small>Color</small></label>
                                                                        <ColorPicker color={fontColor} popup='right' onChange={handleFontColorChange} />
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </div> : null
                                            }
                                            {
                                                ith.hasField(internalFormElement.data.type, ith.INTERNAL_FORM_TEMPLATE_METADATA_FONT_STYLE) ||
                                                    ith.hasField(internalFormElement.data.type, ith.INTERNAL_FORM_TEMPLATE_METADATA_FONT_LETTER_SPACING) ?
                                                    <div className='row'>
                                                        {
                                                            ith.hasField(internalFormElement.data.type, ith.INTERNAL_FORM_TEMPLATE_METADATA_FONT_STYLE) ?
                                                                <div className='col-6'>
                                                                    <div className='form-group mb-4'>
                                                                        <label htmlFor='update-internal-form-font-style'><small>Style</small></label>
                                                                        <div>
                                                                            <div className='btn-group'>
                                                                                <button
                                                                                    type='button'
                                                                                    className={'btn btn-primary' + (fontStyle.some(s => s === 'bold') ? ' active' : '')}
                                                                                    value={(align ? align : '')}
                                                                                    onClick={() => { handleFontStyleChange('bold') }}
                                                                                >
                                                                                    <i className='fal fa-bold'></i>
                                                                                </button>
                                                                                <button
                                                                                    type='button'
                                                                                    className={'btn btn-primary' + (fontStyle.some(s => s === 'italic') ? ' active' : '')}
                                                                                    value={(align ? align : '')}
                                                                                    onClick={() => { handleFontStyleChange('italic') }}
                                                                                >
                                                                                    <i className='fal fa-italic'></i>
                                                                                </button>
                                                                                <button
                                                                                    type='button'
                                                                                    className={'btn btn-primary' + (fontStyle.some(s => s === 'underline') ? ' active' : '')}
                                                                                    value={(align ? align : '')}
                                                                                    onClick={() => { handleFontStyleChange('underline') }}
                                                                                >
                                                                                    <i className='fal fa-underline'></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                        {
                                                            ith.hasField(internalFormElement.data.type, ith.INTERNAL_FORM_TEMPLATE_METADATA_FONT_LETTER_SPACING) ?
                                                                <div className='col-3'>
                                                                    <div className='form-group mb-4'>
                                                                        <label htmlFor='update-internal-form-font-letter-spacing'><small>Spacing</small></label>
                                                                        <input
                                                                            id='update-internal-form-font-letter-spacing'
                                                                            type='number'
                                                                            className='form-control'
                                                                            min={-2}
                                                                            step={0.1}
                                                                            max={2}
                                                                            value={fontLetterSpacing}
                                                                            onChange={handleFontLetterSpacing}
                                                                        />
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </div> : null
                                            }
                                            {
                                                ith.hasField(internalFormElement.data.type, ith.INTERNAL_FORM_TEMPLATE_METADATA_ALIGN) ||
                                                    ith.hasField(internalFormElement.data.type, ith.INTERNAL_FORM_TEMPLATE_METADATA_VALIGN) ?
                                                    <div className='row'>
                                                        {
                                                            ith.hasField(internalFormElement.data.type, ith.INTERNAL_FORM_TEMPLATE_METADATA_ALIGN) ?
                                                                <div className='col-6'>
                                                                    <div className='form-group mb-4'>
                                                                        <label htmlFor='update-internal-form-text-align'><small>Align</small></label>
                                                                        <div>
                                                                            <div className='btn-group'>
                                                                                <button
                                                                                    type='button'
                                                                                    className={'btn btn-primary' + (align === 'left' ? ' active' : '')}
                                                                                    value={(align ? align : '')}
                                                                                    onClick={() => { handleAlignChange('left') }}
                                                                                >
                                                                                    <i className='fal fa-align-left'></i>
                                                                                </button>
                                                                                <button
                                                                                    type='button'
                                                                                    className={'btn btn-primary' + (align === 'center' ? ' active' : '')}
                                                                                    value={(align ? align : '')}
                                                                                    onClick={() => { handleAlignChange('center') }}
                                                                                >
                                                                                    <i className='fal fa-align-center'></i>
                                                                                </button>
                                                                                <button
                                                                                    type='button'
                                                                                    className={'btn btn-primary' + (align === 'right' ? ' active' : '')}
                                                                                    value={(align ? align : '')}
                                                                                    onClick={() => { handleAlignChange('right') }}
                                                                                >
                                                                                    <i className='fal fa-align-right'></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                        {
                                                            ith.hasField(internalFormElement.data.type, ith.INTERNAL_FORM_TEMPLATE_METADATA_VALIGN) ?
                                                                <div className='form-group mb-4'>
                                                                    <label htmlFor='update-internal-form-text-valign'><small>Vertical Align</small></label>
                                                                    <div>
                                                                        <div className='btn-group'>
                                                                            <button
                                                                                type='button'
                                                                                className={'btn btn-primary' + (valign === 'top' ? ' active' : '')}
                                                                                value={(valign ? valign : '')}
                                                                                onClick={() => { handleValignChange('top') }}
                                                                            >
                                                                                <i className='fal fa-arrow-to-top'></i>
                                                                            </button>
                                                                            <button
                                                                                type='button'
                                                                                className={'btn btn-primary' + (valign === 'middle' ? ' active' : '')}
                                                                                value={(valign ? valign : '')}
                                                                                onClick={() => { handleValignChange('middle') }}
                                                                            >
                                                                                <i className='fal fa-horizontal-rule'></i>
                                                                            </button>
                                                                            <button
                                                                                type='button'
                                                                                className={'btn btn-primary' + (valign === 'bottom' ? ' active' : '')}
                                                                                value={(valign ? valign : '')}
                                                                                onClick={() => { handleValignChange('bottom') }}
                                                                            >
                                                                                <i className='fal fa-arrow-to-bottom'></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </div> : null
                                            }
                                            {
                                                ith.hasField(internalFormElement.data.type, ith.INTERNAL_FORM_TEMPLATE_METADATA_PADDING) ?
                                                    <div className='row'>
                                                        <div className='col-3'>
                                                            <div className='form-group mb-4'>
                                                                <label htmlFor='update-internal-form-padding-top'><small>Top</small></label>
                                                                <input
                                                                    id='update-internal-form-padding-top'
                                                                    type='number'
                                                                    className='form-control'
                                                                    min={-50}
                                                                    step={ith.INTERNAL_FORM_TEMPLATE_ROW_HEIGHT}
                                                                    max={50}
                                                                    value={(padding && padding[0] ? padding[0] : 0)}
                                                                    onChange={(e) => { handlePaddingChange(e, 0) }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='col-3'>
                                                            <div className='form-group mb-4'>
                                                                <label htmlFor='update-internal-form-padding-right'><small>Right</small></label>
                                                                <input
                                                                    id='update-internal-form-padding-right'
                                                                    type='number'
                                                                    className='form-control'
                                                                    min={-50}
                                                                    step={ith.INTERNAL_FORM_TEMPLATE_ROW_HEIGHT}
                                                                    max={50}
                                                                    value={(padding && padding[1] ? padding[1] : 0)}
                                                                    onChange={(e) => { handlePaddingChange(e, 1) }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='col-3'>
                                                            <div className='form-group mb-4'>
                                                                <label htmlFor='update-internal-form-padding-bottom'><small>Bottom</small></label>
                                                                <input
                                                                    id='update-internal-form-padding-bottom'
                                                                    type='number'
                                                                    className='form-control'
                                                                    min={-50}
                                                                    step={ith.INTERNAL_FORM_TEMPLATE_ROW_HEIGHT}
                                                                    max={50}
                                                                    value={(padding && padding[2] ? padding[2] : 0)}
                                                                    onChange={(e) => { handlePaddingChange(e, 2) }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='col-3'>
                                                            <div className='form-group mb-4'>
                                                                <label htmlFor='update-internal-form-padding-left'><small>Left</small></label>
                                                                <input
                                                                    id='update-internal-form-padding-left'
                                                                    type='number'
                                                                    className='form-control'
                                                                    min={-50}
                                                                    step={ith.INTERNAL_FORM_TEMPLATE_ROW_HEIGHT}
                                                                    max={50}
                                                                    value={(padding && padding[3] ? padding[3] : 0)}
                                                                    onChange={(e) => { handlePaddingChange(e, 3) }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div> : null
                                            }
                                            {
                                                ith.hasField(internalFormElement.data.type, ith.INTERNAL_FORM_TEMPLATE_METADATA_BORDER) ?
                                                    <>
                                                        <div className='row'>
                                                            <div className='col-3'>
                                                                <div className='form-group mb-3'>
                                                                    <label htmlFor='update-internal-form-border'><small>Border</small></label>
                                                                    <BorderPicker border={border} width={borderWidth} style={borderStyle} color={borderColor} onChange={handleBorderChange} />
                                                                </div>
                                                            </div>
                                                            <div className='col-5 offset-1'>
                                                                <div className='form-group mb-3'>
                                                                    <label htmlFor='update-internal-form-border-style'><small>Style</small></label>
                                                                    <DropdownList
                                                                        data={[
                                                                            { key: '1px solid #000', data: { width: 1, style: 'solid' } },
                                                                            { key: '2px solid #000', data: { width: 2, style: 'solid' } },
                                                                            { key: '3px solid #000', data: { width: 3, style: 'solid' } },
                                                                            { key: '1px dashed #000', data: { width: 1, style: 'dashed' } },
                                                                            { key: '2px dashed #000', data: { width: 2, style: 'dashed' } },
                                                                            { key: '3px dashed #000', data: { width: 3, style: 'dashed' } },
                                                                            { key: '1px dotted #000', data: { width: 1, style: 'dotted' } },
                                                                            { key: '2px dotted #000', data: { width: 2, style: 'dotted' } },
                                                                            { key: '3px dotted #000', data: { width: 3, style: 'dotted' } },
                                                                            { key: '3px double #000', data: { width: 3, style: 'double' } },
                                                                            { key: '6px double #000', data: { width: 6, style: 'double' } },
                                                                        ]}
                                                                        valueField='key'
                                                                        value={(`${borderWidth}px ${borderStyle} #000`)}
                                                                        itemComponent={({ item }) => (
                                                                            <div className='p-0' style={{ fontSize: '1px', borderTop: item.key }}>&nbsp;</div>
                                                                        )}
                                                                        valueComponent={({ item }) => (
                                                                            item ? <div className='p-0' style={{ fontSize: '1px', borderTop: item.key }}>&nbsp;</div> : null
                                                                        )}
                                                                        onChange={handleBorderStyleChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-3'>
                                                                <div className='form-group mb-4'>
                                                                    <label htmlFor='update-internal-form-border-color'><small>Color</small></label>
                                                                    <ColorPicker color={borderColor} popup='right' onChange={handleBorderColorChange} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </> : null
                                            }
                                            {
                                                ith.hasField(internalFormElement.data.type, ith.INTERNAL_FORM_TEMPLATE_METADATA_BACKGROUND_COLOR) ?
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-4'>
                                                                <label htmlFor='update-internal-form-background-color'><small>Background Color</small></label>
                                                                <ColorPicker color={backgroundColor} popup='right' onChange={handleBackgroundColorChange} />
                                                            </div>
                                                        </div>
                                                    </div> : null
                                            }
                                            {
                                                ith.hasField(internalFormElement.data.type, ith.INTERNAL_FORM_TEMPLATE_METADATA_IMAGE) ?
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-4'>
                                                                <label htmlFor='update-internal-form-background-image'><small>Image</small></label>
                                                                {
                                                                    image ?
                                                                        <div>
                                                                            <img src={image.base64} width={(image.width > 300 ? 300 : image.width)} alt='Example' />
                                                                            <button
                                                                                type='button'
                                                                                className='btn btn-link px-0 btn-sm d-block'
                                                                                onClick={handleImageClick}
                                                                            >Change image</button>
                                                                        </div> :
                                                                        <div className='custom-file'>
                                                                            <input
                                                                                id='update-internal-form-background-image-url'
                                                                                type='file'
                                                                                className='custom-file-input'
                                                                                accept='image/*'
                                                                                onChange={handleImageChange} />
                                                                            <label
                                                                                htmlFor='update-internal-form-background-image-url'
                                                                                className='custom-file-label'>
                                                                            </label>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div> : null
                                            }
                                        </section>
                                    </div>
                                </FadeIn> : null
                        }
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-4'></div>
                            <div className='col-8'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>)
}

export default UpdateExistingInternalFormElement;