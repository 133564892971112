export const TEMPLATE_DESIGNER_WIDTH = 1320;
export const TEMPLATE_DESIGNER_COL_SIZE = 64;
export const TEMPLATE_DESIGNER_ROW_SIZE = 10;

export const rerenderTemplate = () => {
    if (typeof (Event) === 'function') {
        window.dispatchEvent(new Event('resize'));
    } else {
        const event = window.document.createEvent('UIEvents');
        event.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(event);
    }
}

export const prepareMobileDefinition = (definition) => {
    if (definition && definition.length > 0) {
        definition = definition.filter(d => !d.metadata || !d.metadata.display || d.metadata.display === 'mobile');

        if (definition && definition.length > 0) {
            let nextY = 1;

            for (let d = 0; d < definition.length; d++) {
                definition[d].position.x = 0;
                definition[d].position.w = 64;
                definition[d].position.y = nextY;

                if ((definition[d].type === 'Checkbox' || definition[d].type === 'Radio') && definition[d].metadata && definition[d].metadata.options) {
                    if (definition[d].metadata.options.length === 1) {
                        definition[d].position.h = definition[d].position.h < 10 ? 8 : (definition[d].position.h);
                        nextY = nextY + definition[d].position.h;
                    }
                    else if (definition[d].metadata.options.length > 1) {
                        nextY = nextY + definition[d].position.h + definition[d].metadata.options.length * 3 + 1;
                    } else {
                        nextY = nextY + definition[d].position.h;
                    }
                }
                else if (definition[d].type === 'SingleLineText' && !definition[d].label) {
                    nextY = nextY + definition[d].position.h + 2;
                }
                else if (definition[d].type === 'MultiLineText' || definition[d].type === 'RichText') {
                    nextY = nextY + definition[d].position.h + 2;
                }
                else {
                    nextY = nextY + definition[d].position.h;
                }
            }
        }
    }

    return definition;
}

export const prepareDesktopDefinition = (definition) => {
    if (definition && definition.length > 0) {
        definition = definition.filter(d => !d.metadata || !d.metadata.display || d.metadata.display === 'desktop');
    }

    return definition;
}