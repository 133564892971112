import React, { useEffect, useContext, useState, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { useObserver } from 'mobx-react-lite';
import { GlobalHotKeys } from 'react-hotkeys';
import MaskedInput from 'react-text-mask'
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';

import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';

import CustomerCreateStore from '../../../../stores/CustomerCreateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';
import CacheStore from '../../../../stores/CacheStore';

import api from '../../../../api';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as MaskKeys from '../../../../constants/maskKeys';
import * as fn from '../../../../utilities/_functions';
import * as ch from '../../../../utilities/customerHelper';
import * as ph from '../../../../utilities/personHelper';
import * as sys from '../../../../utilities/systemHelper';

import { quickDrawerFocus } from '../../_shared/QuickDrawer';

import './NewPrimaryContact.scss';

moment.locale('en');
momentLocalizer();

function NewPrimaryContact(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const searchTimer = useRef(null);
    const focusTimer = useRef(null);
    const customer = useContext(CustomerCreateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const cache = useContext(CacheStore);
    const [checkForConflict, setCheckForConflict] = useState(false);
    const [conflicts, setConflicts] = useState(null);
    const [isNameOptionsExpanded, setIsNameOptionsExpanded] = useState(false);
    const [newPrimaryContactData, setNewPrimaryContactData] = useState(null);
    const [existingPrimaryContact, isExistingPrimaryContact] = useState(true);
    const [existingPrimaryContactData, setExistingPrimaryContactData] = useState(null);
    const [primaryContactSearchTerm, setPrimaryContactSearchTerm] = useState('');
    const [primaryContactSearchResult, setPrimaryContactSearchResult] = useState('');
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [middleName, setMiddleName] = useState(null);
    const [preferredFirstName, setPreferredFirstName] = useState(null);
    const [salutationType, setSalutationType] = useState(null);
    const [suffixType, setSuffixType] = useState(null);
    const [relationship, setRelationship] = useState(null);
    const [emailAddress, setEmailAddress] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [phoneType, setPhoneType] = useState(null);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    useEffect(() => {
        return () => {
            isMounted.current = false;

            if (searchTimer.current) { clearTimeout(searchTimer.current); searchTimer.current = null; }
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        quickDrawerFocus(props.drawer);
    }, [quickDrawer.drawerOpened]) // eslint-disable-line

    const checkConflicts = () => {
        if (checkForConflict) {
            ch.checkConflicts(newPrimaryContactData)
                .then((data) => {
                    // console.log(data);
                    setConflicts(data);
                })
                .finally(() => {
                    setCheckForConflict(false);
                })
        }
    }

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleExistingPrimaryContactChange = event => {
        const existing = event.target.value === 'true';

        isExistingPrimaryContact(existing);
        setExistingPrimaryContactData(null);
        setPrimaryContactSearchTerm(null);
        setPrimaryContactSearchResult(null);
        setRelationship(null);
        setEmailAddress(null);
        setPhoneNumber(null);
        setPhoneType(null);
        setCheckForConflict(false);
        setFirstName(null);
        setLastName(null);
        setMiddleName(null);
        setPreferredFirstName(null);
        setSalutationType(null);
        setSuffixType(null);

        if (!existing) {
            setNewPrimaryContactData({});
        } else {
            setNewPrimaryContactData(null);
        }

        setHasUnsavedChanges(false);
    }

    const handlePrimaryContactSearchChange = event => {
        const term = event.target.value;

        if (newPrimaryContactData || existingPrimaryContactData) {
            setNewPrimaryContactData(null);
            setExistingPrimaryContactData(null);
        }

        setPrimaryContactSearchTerm(term);

        setPrimaryContactSearchResult(null);
        setEmailAddress(null);
        setPhoneNumber(null);
        setPhoneType(null);

        if (searchTimer.current) {
            clearTimeout(searchTimer.current);
        }

        if (term && term.length >= 2) {
            searchTimer.current = setTimeout(() => {
                api.Customers.fullSearch(term, false, false, 5)
                    .then(({ data }) => {
                        if (isMounted.current) {
                            setPrimaryContactSearchResult(data.result);
                        }
                    })
            }, 500);
        } else {
            setPrimaryContactSearchResult(null);
        }
    }

    const handlePrimaryContactSearchClick = customer => {
        setExistingPrimaryContactData(customer);
        setNewPrimaryContactData(null);
        setPrimaryContactSearchResult(null);
        setPrimaryContactSearchTerm(null);
        setEmailAddress(customer.emailAddress);

        if (customer.primaryPhoneNumber) {
            setPhoneNumber(customer.primaryPhoneNumber.number);
            setPhoneType(customer.primaryPhoneNumber.type);
        }

        setHasUnsavedChanges(true);
    }

    const handleExpandNameOptions = event => {
        setIsNameOptionsExpanded(true);
        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100)
    }

    const handleSalutationTypeChange = event => {
        const newSalutationType = event.target.value;
        const tempNewPrimaryContactData = newPrimaryContactData;

        tempNewPrimaryContactData.salutationType = newSalutationType;
        setNewPrimaryContactData(tempNewPrimaryContactData);
        setSalutationType(newSalutationType);
        setCheckForConflict(true);
        setHasUnsavedChanges(true);
    }

    const handleFirstNameChange = event => {
        const newFirstName = event.target.value;
        const tempNewPrimaryContactData = newPrimaryContactData;

        tempNewPrimaryContactData.firstName = newFirstName;
        setNewPrimaryContactData(tempNewPrimaryContactData);
        setFirstName(newFirstName);
        setCheckForConflict(true);
        setHasUnsavedChanges(true);
    }

    const handleMiddleNameChange = event => {
        const newMiddleName = event.target.value;
        const tempNewPrimaryContactData = newPrimaryContactData;

        tempNewPrimaryContactData.middleName = newMiddleName;
        setNewPrimaryContactData(tempNewPrimaryContactData);
        setMiddleName(newMiddleName);
        setCheckForConflict(true);
        setHasUnsavedChanges(true);
    }

    const handleLastNameChange = event => {
        const newLastName = event.target.value;
        const tempNewPrimaryContactData = newPrimaryContactData;

        tempNewPrimaryContactData.lastName = newLastName;
        setNewPrimaryContactData(tempNewPrimaryContactData);
        setLastName(newLastName);
        setCheckForConflict(true);
        setHasUnsavedChanges(true);
    }

    const handlePreferredFirstNameChange = event => {
        const newPreferredFirstName = event.target.value;
        const tempNewPrimaryContactData = newPrimaryContactData;

        tempNewPrimaryContactData.preferredFirstName = newPreferredFirstName;
        setNewPrimaryContactData(tempNewPrimaryContactData);
        setPreferredFirstName(newPreferredFirstName);
        setCheckForConflict(true);
    }

    const handleSuffixTypeChange = event => {
        const newSuffixType = event.target.value;
        const tempNewPrimaryContactData = newPrimaryContactData;

        tempNewPrimaryContactData.suffixType = newSuffixType;
        setNewPrimaryContactData(tempNewPrimaryContactData);
        setSuffixType(newSuffixType);
        setCheckForConflict(true);
        setHasUnsavedChanges(true);
    }

    const handleRelationshipChange = event => {
        const newRelationship = event.target.value;
        setRelationship(newRelationship);
        setCheckForConflict(true);
        setHasUnsavedChanges(true);
    }

    const handleEmailAddressChange = event => {
        const newEmailAddress = event.target.value;
        setEmailAddress(newEmailAddress);
        setCheckForConflict(true);
    }

    const handlePhoneNumberChange = event => {
        const newPhoneNumber = event.target.value;
        setPhoneNumber(newPhoneNumber);
        setCheckForConflict(true);
        setHasUnsavedChanges(true);
    }

    const handlePhoneTypeChange = event => {
        const newPhoneType = event.target.value;
        setPhoneType(newPhoneType);
        setCheckForConflict(true);
        setHasUnsavedChanges(true);
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            const data = {};

            data.id = existingPrimaryContactData ? existingPrimaryContactData.id : null;
            data.relationship = relationship;
            data.emailAddress = emailAddress;
            data.phoneNumber = phoneNumber;
            data.phoneType = phoneType;

            if (data.id) {
                data.salutationType = existingPrimaryContactData ? existingPrimaryContactData.salutationType : null;
                data.firstName = existingPrimaryContactData ? existingPrimaryContactData.firstName : null;
                data.middleName = existingPrimaryContactData ? existingPrimaryContactData.middleName : null;
                data.lastName = existingPrimaryContactData ? existingPrimaryContactData.lastName : null;
                data.preferredFirstName = existingPrimaryContactData ? existingPrimaryContactData.preferredFirstName : null;
                data.suffixType = existingPrimaryContactData ? existingPrimaryContactData.suffixType : null;
                data.color = existingPrimaryContactData ? existingPrimaryContactData.color : null;
                data.address = existingPrimaryContactData ? existingPrimaryContactData.address : null;
            } else {
                data.salutationType = newPrimaryContactData ? newPrimaryContactData.salutationType : null;
                data.firstName = newPrimaryContactData ? newPrimaryContactData.firstName : null;
                data.middleName = newPrimaryContactData ? newPrimaryContactData.middleName : null;
                data.lastName = newPrimaryContactData ? newPrimaryContactData.lastName : null;
                data.preferredFirstName = newPrimaryContactData ? newPrimaryContactData.preferredFirstName : null;
                data.suffixType = newPrimaryContactData ? newPrimaryContactData.suffixType : null;
            }

            setHasUnsavedChanges(false);

            if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                props.onSuccess(event, { updated: true, data: data });
            }
        }
    }

    const getExistingPrimaryContactName = () => {
        if (existingPrimaryContactData) {
            return ph.getLastFirstName(existingPrimaryContactData);
        } else {
            return primaryContactSearchTerm ? primaryContactSearchTerm : '';
        }
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <fieldset disabled={customer.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon='fal fa-phone-volume'
                        action='Set'
                        category='Primary Contact'
                        className='customers'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        <FadeIn>
                            <div className='new-primary-contact body-content'>
                                {
                                    conflicts && newPrimaryContactData ?
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='alert alert-warning p-3 mb-0' role='alert'>
                                                        <strong className='d-block mb-2'>Warning</strong>
                                                        <ul className='pl-3 mb-0'>
                                                            {
                                                                conflicts.name ?
                                                                    <li>There is another customer with the same name.</li> : null
                                                            }
                                                            {
                                                                conflicts.healthCard ?
                                                                    <li>There is another customer with the same health card.</li> : null
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </section> : null
                                }
                                <section>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='form-group validate validate-required'>
                                                <label className='required d-block' htmlFor='new-primary-contact-existing'><small>Existing?</small></label>
                                                <div
                                                    className='custom-control custom-checkbox custom-control-inline'
                                                >
                                                    <input
                                                        id={`new-primary-contact-existing-true`}
                                                        type='radio'
                                                        name='existingCustomer'
                                                        className='custom-control-input'
                                                        value={true}
                                                        checked={existingPrimaryContact}
                                                        onChange={handleExistingPrimaryContactChange}
                                                    />
                                                    <label
                                                        htmlFor={`new-primary-contact-existing-true`}
                                                        className='custom-control-label'
                                                    >
                                                        Yes
                                                    </label>
                                                </div>
                                                <div
                                                    className='custom-control custom-checkbox custom-control-inline'
                                                >
                                                    <input
                                                        id={`new-primary-contact-existing-false`}
                                                        type='radio'
                                                        name='existingCustomer'
                                                        className='custom-control-input'
                                                        value={false}
                                                        checked={!existingPrimaryContact}
                                                        onChange={handleExistingPrimaryContactChange}
                                                    />
                                                    <label
                                                        htmlFor={`new-primary-contact-existing-false`}
                                                        className='custom-control-label'
                                                    >
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                {
                                    existingPrimaryContact === true ?
                                        <section className='existing-primary-contact'>
                                            <div className='row'>
                                                <div className='col-12 validate validate-required'>
                                                    <FadeIn>
                                                        <label className='required' htmlFor='new-primary-contact-existing-name'><small>Primary Contact</small></label>
                                                        <div className='dropdown'>
                                                            <i className={'fal fa-check customer-status' + (existingPrimaryContactData ? ' customer-status-selected' : '')}></i>
                                                            <input
                                                                id='new-appointment-input'
                                                                type='text'
                                                                className='form-control mb-0 dropdown-toggle'
                                                                spellCheck={false}
                                                                autoComplete='off'
                                                                data-validator-required={!!existingPrimaryContactData}
                                                                value={getExistingPrimaryContactName()}
                                                                onChange={handlePrimaryContactSearchChange}
                                                            />
                                                            <ul className='dropdown-menu'>
                                                                {
                                                                    primaryContactSearchResult && primaryContactSearchResult.length > 0 ?
                                                                        primaryContactSearchResult.slice(0, 5).map((s, si) => {
                                                                            return <li
                                                                                key={`search_result_${si}`}
                                                                                className={'dropdown-menu-item'}>
                                                                                <div
                                                                                    className='profile-wrapper'
                                                                                    onClick={() => handlePrimaryContactSearchClick(s)}
                                                                                >
                                                                                    <div className='profile d-flex flex-column justify-content-center align-content-center'>
                                                                                        <span
                                                                                            className={`profile-image profile-image-md profile-initials rounded-circle text-white ${ch.getProfileColor(s)} fw-500`}
                                                                                            title={ph.getPreferredFullName(s)}
                                                                                        >
                                                                                            {s.initials}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className='description'>
                                                                                        <div className='fw-500 text-gray-700'>{ph.getLastFirstName(s, true)}
                                                                                            {
                                                                                                s.dateOfBirth || s.sex || s.gender || s.pronoun ?
                                                                                                    <small className='ml-2 text-gray'>({`${ph.getAge(s.dateOfBirth)} ${ph.getSexGenderPronounDisplay(s)}`.trim()})</small> : null
                                                                                            }
                                                                                        </div>
                                                                                        {
                                                                                            s.address && s.address.country ?
                                                                                                <div className='fs-95 info text-truncate text-truncate-xl'>{s.address.fullLine}</div> : null
                                                                                        }
                                                                                        {
                                                                                            s.dateOfBirth ?
                                                                                                <div className='fs-90 info text-truncate text-truncate-xl'>DOB: {fn.formatDate(s.dateOfBirth)}</div> : null
                                                                                        }
                                                                                        {
                                                                                            s.emailAddress ?
                                                                                                <div className='fs-90 text-info text-truncate text-truncate-xl'>{s.emailAddress}</div> : null
                                                                                        }
                                                                                        {
                                                                                            s.primaryPhoneNumber ?
                                                                                                <div className='fs-90 text-info text-truncate text-truncate-xl'>{sys.getFormattedPhoneNumber(s.primaryPhoneNumber.number)}</div> : null
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        }) : null
                                                                }
                                                            </ul>
                                                        </div>
                                                    </FadeIn>
                                                </div>
                                            </div>
                                        </section> : null
                                }
                                {
                                    existingPrimaryContact === false ?
                                        <FadeIn>
                                            <section className={'mt-4 mb-4' + (isNameOptionsExpanded ? '' : ' d-none')}>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <div className='form-group'>
                                                            <label htmlFor='new-primary-contact-salutation-type-select'><small>Prefix</small></label>
                                                            <select
                                                                id='new-primary-contact-salutation-type-select'
                                                                className='custom-select form-control'
                                                                value={salutationType ? salutationType : ''}
                                                                onChange={handleSalutationTypeChange}
                                                                onBlur={checkConflicts}
                                                            >
                                                                <option value=''></option>
                                                                {
                                                                    cache.getReferenceDataOptions('SalutationType').map((option, di) => {
                                                                        return <option key={`salutation_type_${di}`} value={option.key}>{option.value}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section className={'mb-4' + (isNameOptionsExpanded ? '' : ' mt-4')}>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <div className='form-group validate validate-required'>
                                                            <label className='required' htmlFor='new-primary-contact-firstname-input'><small>Given Name</small></label>
                                                            <input
                                                                id='new-primary-contact-firstname-input'
                                                                type='text'
                                                                className='form-control'
                                                                spellCheck={false}
                                                                maxLength='50'
                                                                autoComplete='off'
                                                                value={firstName ? firstName : ''}
                                                                onChange={handleFirstNameChange}
                                                                onBlur={checkConflicts}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className='form-group validate validate-required'>
                                                            <label className='required' htmlFor='new-primary-contact-lastname-input'><small>Last Name</small></label>
                                                            <input
                                                                id='new-primary-contact-lastname-input'
                                                                type='text'
                                                                className='form-control'
                                                                maxLength='50'
                                                                spellCheck={false}
                                                                autoComplete='off'
                                                                value={lastName ? lastName : ''}
                                                                onChange={handleLastNameChange}
                                                                onBlur={checkConflicts}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'row mt-2' + (isNameOptionsExpanded ? ' d-none' : '')}>
                                                    <div className='col-12'>
                                                        <button
                                                            type='button'
                                                            className='btn btn-link p-0'
                                                            onClick={handleExpandNameOptions}
                                                        >
                                                            <i className='fal fa-angle-down mr-2'></i>Expand name options
                                                        </button>
                                                    </div>
                                                </div>
                                            </section>
                                            <section className={'mb-4' + (isNameOptionsExpanded ? '' : ' d-none')}>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <div className='form-group'>
                                                            <label htmlFor='new-primary-contact-middlename-input'><small>Middle Name</small></label>
                                                            <input
                                                                id='new-primary-contact-middlename-input'
                                                                type='text'
                                                                className='form-control'
                                                                maxLength='50'
                                                                spellCheck={false}
                                                                autoComplete='off'
                                                                value={middleName ? middleName : ''}
                                                                onChange={handleMiddleNameChange}
                                                                onBlur={checkConflicts}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className='form-group'>
                                                            <label htmlFor='new-primary-contact-preferred-firstname-input'><small>Preferred Name</small></label>
                                                            <input
                                                                id='new-primary-contact-preferred-firstname-input'
                                                                type='text'
                                                                className='form-control'
                                                                spellCheck={false}
                                                                maxLength='50'
                                                                autoComplete='off'
                                                                value={preferredFirstName ? preferredFirstName : ''}
                                                                onChange={handlePreferredFirstNameChange}
                                                                onBlur={checkConflicts}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section className={'mb-4' + (isNameOptionsExpanded ? '' : ' d-none')}>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <div className='form-group'>
                                                            <label htmlFor='new-primary-contact-suffix-type-select'><small>Suffix</small></label>
                                                            <select
                                                                id='new-primary-contact-suffix-type-select'
                                                                className='custom-select form-control'
                                                                value={suffixType ? suffixType : ''}
                                                                onChange={handleSuffixTypeChange}
                                                                onBlur={checkConflicts}
                                                            >
                                                                <option value=''></option>
                                                                {
                                                                    cache.getReferenceDataOptions('SuffixType').map((option, di) => {
                                                                        return <option key={`suffix_type_${di}`} value={option.key}>{option.value}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </FadeIn> : null
                                }
                                {
                                    (newPrimaryContactData || existingPrimaryContactData) ?
                                        <FadeIn>
                                            <section className='mt-4'>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='form-group mb-0 validate validate-required'>
                                                            <label className='required' htmlFor='new-relationship'><small>Relationship</small></label>
                                                            <select
                                                                id='new-primary-contact-relationship'
                                                                className='custom-select form-control'
                                                                value={relationship ? relationship : ''}
                                                                onChange={handleRelationshipChange}
                                                            >
                                                                <option value=''></option>
                                                                <optgroup label='Common'>
                                                                    {
                                                                        ph.COMMON_RELATIONSHIP_TYPES.map((r, ri) => {
                                                                            return <option key={`primary_contact_common_relationship_type_${ri}`} value={r}>
                                                                                {
                                                                                    cache.getReferenceDataOptions('CustomerRelationshipType').some(o => o.key === r) ?
                                                                                        cache.getReferenceDataOptions('CustomerRelationshipType').filter(o => o.key === r)[0].value : r
                                                                                }
                                                                            </option>
                                                                        })
                                                                    }
                                                                </optgroup>
                                                                <optgroup label='Less common'>
                                                                    {
                                                                        cache.getReferenceDataOptions('CustomerRelationshipType').filter(option => !ph.COMMON_RELATIONSHIP_TYPES.some(o => o === option.key)).map((option, di) => {
                                                                            return <option key={`primary_contact_less_common_relationship_type_${di}`} value={option.key}>{option.value}</option>
                                                                        })
                                                                    }
                                                                </optgroup>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section className='mt-4'>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='form-group'>
                                                            <label htmlFor='new-primary-contact-email-input'><small>Email</small></label>
                                                            <MaskedInput
                                                                id='new-primary-contact-email-input'
                                                                type='text'
                                                                className='form-control'
                                                                spellCheck={false}
                                                                mask={MaskKeys.EMAIL_MASK}
                                                                maxLength='150'
                                                                autoComplete='off'
                                                                value={emailAddress ? emailAddress : ''}
                                                                onChange={handleEmailAddressChange}
                                                                onBlur={checkConflicts}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section className='mt-4'>
                                                <div className='row'>
                                                    <div className='col-8'>
                                                        <div className='form-group'>
                                                            <label htmlFor='new-primary-contact-phone-input'><small>Phone</small></label>
                                                            <MaskedInput
                                                                id='new-primary-contact-phone-input'
                                                                type='text'
                                                                className='form-control'
                                                                spellCheck={false}
                                                                mask={MaskKeys.PHONE_MASK}
                                                                autoComplete='off'
                                                                value={phoneNumber ? phoneNumber : ''}
                                                                onChange={handlePhoneNumberChange}
                                                                onBlur={checkConflicts}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-4'>
                                                        <div className='form-group'>
                                                            <label htmlFor='new-primary-contact-phone-type-select'><small>Type</small></label>
                                                            <select
                                                                id='new-primary-contact-phone-type-select'
                                                                className='custom-select form-control'
                                                                value={phoneType ? phoneType : ''}
                                                                onChange={handlePhoneTypeChange}
                                                            >
                                                                <option value=''></option>
                                                                {
                                                                    cache.getReferenceDataOptions('PhoneType').map((option, di) => {
                                                                        return <option key={`phone_type_${di}`} value={option.key}>{option.value}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </FadeIn> : null
                                }
                            </div>
                        </FadeIn>
                    </div>
                    <div className='quick-drawer-action pl-3'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-info'
                                    >Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>)
}

export default NewPrimaryContact;