import React, { useEffect, useContext, useState, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { GlobalHotKeys } from 'react-hotkeys';
import { useObserver } from 'mobx-react-lite';

import BodyEnd from '../../../../_shared/BodyEnd';
import QuickDrawerHeader from '../../../../_shared/QuickDrawerHeader';
import { quickDrawerFocus } from '../../../../_shared/QuickDrawer';
import RichTextEditor from '../../../../_shared/RichTextEditor';

import FormInputCreateStore from '../../../../../../stores/FormInputCreateStore';
import QuickDrawerStore from '../../../../../../stores/QuickDrawerStore';
import CacheStore from '../../../../../../stores/CacheStore';

import * as ErrorMessages from '../../../../../../constants/errorMessages';
import * as fn from '../../../../../../utilities/_functions';
import * as oh from '../../../../../../utilities/operationHelper';
import * as tih from '../../../../../../utilities/templateInputHelper';

import './NewFormInput.scss';

function NewFormInput(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const formInput = useContext(FormInputCreateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const cache = useContext(CacheStore);
    const [metadataReady, setMetadataReady] = useState(false);
    const [displayPreview, setDisplayPreview] = useState(false);
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [isRequired, setIsRequired] = useState(false);
    const [hint, setHint] = useState(null);
    const [placeholder, setPlaceholder] = useState(null);
    const [size, setSize] = useState('');
    const [align, setAlign] = useState('left');
    const [valign, setValign] = useState('top');
    const [options, setOptions] = useState([]);
    const [inline, setInline] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [imageWidth, setImageWidth] = useState(null);
    const [imageHeight, setImageHeight] = useState(null);
    const [inputmask, setInputmask] = useState(null);
    const [defaultValue, setDefaultValue] = useState(null);
    const [display, setDisplay] = useState(null);

    useEffect(() => {
        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100)

        return (() => {
            isMounted.current = false;
            formInput.clear();
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        })
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (formInput.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = event => {
        event.preventDefault();
        handlePopulateMetadata();
        formInput.save()
            .then(data => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, data);
                    }
                }
            })
    }

    const handleTypeChange = event => {
        formInput.data.type = event.target.value;

        if (!formInput.data.type) { setMetadataReady(false); return; }

        if (metadataReady) {
            formInput.data.metadata = null;
            setMetadataReady(false);
            focusTimer.current = setTimeout(() => {
                setMetadataReady(true);
                setFocusToName();
            }, 100);
        } else {
            setMetadataReady(true);
            setFocusToName();
        }

        formInput.hasUnsavedChanges = true;
    }

    const handleNameChange = event => {
        formInput.data.name = event.target.value;
        formInput.data.label = event.target.value;
        formInput.data.key = event.target.value.replace(/[^0-9a-zA-Z]/g, '');
        formInput.hasUnsavedChanges = true;
    }

    const handleLabelChange = event => {
        formInput.data.label = event.target.value;
        formInput.hasUnsavedChanges = true;
    }

    const handleHintChange = event => {
        setHint(event.target.value);
        formInput.hasUnsavedChanges = true;
    }

    const handlePlaceholderChange = event => {
        setPlaceholder(event.target.value);
        formInput.hasUnsavedChanges = true;
    }

    const handleKeyChange = event => {
        formInput.data.key = event.target.value.replace(/[^0-9a-zA-Z]/g, '');
        formInput.hasUnsavedChanges = true;
    }

    const handleDescriptionChange = content => {
        const html = content;
        formInput.data.descriptionHtml = ((html === '<p><br></p>') ? null : html);
        formInput.hasUnsavedChanges = true;
    }

    const handleIsRequiredChange = event => {
        setIsRequired(event.target.value === 'true');
        formInput.hasUnsavedChanges = true;
    }

    const handleDisplayChange = event => {
        setDisplay(event.target.value);
        formInput.hasUnsavedChanges = true;
    }

    const handleSizeChange = event => {
        setSize(event.target.value);
        formInput.hasUnsavedChanges = true;
    }

    const handleAlignChange = value => {
        setAlign((value ? value : 'left'));
        formInput.hasUnsavedChanges = true;
    }

    const handleValignChange = value => {
        setValign((value ? value : 'top'));
        formInput.hasUnsavedChanges = true;
    }

    const handleInlineChange = event => {
        setInline((event.target.value === 'true'));
        formInput.hasUnsavedChanges = true;
    }

    const handleInputmaskChange = event => {
        setInputmask(event.target.value);
        formInput.hasUnsavedChanges = true;
    }

    const handlePreviewClick = event => {
        event.preventDefault();
        handlePopulateMetadata();
        setDisplayPreview(true);
    }

    const handleClosePreviewClick = event => {
        event.preventDefault();
        setDisplayPreview(false);
    }

    const handlePopulateMetadata = () => {
        populateMetadata(tih.TEMPLATE_INPUT_METADATA_IS_REQUIRED, 'isRequired', isRequired);
        populateMetadata(tih.TEMPLATE_INPUT_METADATA_HINT, 'hint', hint);
        populateMetadata(tih.TEMPLATE_INPUT_METADATA_PLACEHOLDER, 'placeholder', placeholder);
        populateMetadata(tih.TEMPLATE_INPUT_METADATA_SIZE, 'size', size);
        populateMetadata(tih.TEMPLATE_INPUT_METADATA_ALIGN, 'align', align);
        populateMetadata(tih.TEMPLATE_INPUT_METADATA_VALIGN, 'valign', valign);
        populateMetadata(tih.TEMPLATE_INPUT_METADATA_OPTIONS, 'options', options);
        populateMetadata(tih.TEMPLATE_INPUT_METADATA_INLINE, 'inline', inline);
        populateMetadata(tih.TEMPLATE_INPUT_METADATA_IMAGE_URL, 'imageUrl', imageUrl);
        populateMetadata(tih.TEMPLATE_INPUT_METADATA_IMAGE_URL, 'imageWidth', imageWidth);
        populateMetadata(tih.TEMPLATE_INPUT_METADATA_IMAGE_URL, 'imageHeight', imageHeight);
        populateMetadata(tih.TEMPLATE_INPUT_METADATA_INPUT_MASK, 'inputmask', inputmask);
        populateMetadata(tih.TEMPLATE_INPUT_METADATA_DEFAULT_VALUE, 'defaultValue', defaultValue);
        populateMetadata(tih.TEMPLATE_INPUT_METADATA_DISPLAY, 'display', display);
    }

    const handleOptionChange = (event, index) => {
        const tempOptions = [...options];
        tempOptions[index] = event.target.value;
        setOptions(tempOptions);
        formInput.hasUnsavedChanges = true;
    }

    const handleAddOption = event => {
        event.preventDefault();
        setOptions(o => [...options, '']);
        setTimeout(() => {
            const element = document.querySelector(`#new-form-input-${formInput.data.key}-${(options.length)}`)
            if (element) { element.focus(); }
        }, 50);
        formInput.hasUnsavedChanges = true;
    }

    const handleRemoveOption = (option, index) => {
        setOptions(options.filter((o, oi) => oi !== index));
        formInput.hasUnsavedChanges = true;
    }

    const handleMoveOption = (from, to) => {
        const tempOptions = [...options];
        const element = tempOptions[from];

        tempOptions.splice(from, 1);
        tempOptions.splice(to, 0, element);

        setOptions(tempOptions);
        formInput.hasUnsavedChanges = true;
    }

    const handleImageAnnotationChange = event => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = handleFileReaderLoad;
        reader.readAsDataURL(file);
    }

    const handleFileReaderLoad = (event) => {
        const reader = event.target;
        const base64 = reader.result;
        const tempImage = new Image();

        tempImage.src = event.target.result;
        tempImage.onload = () => {
            setImageWidth(tempImage.width);
            setImageHeight(tempImage.height);
        }
        setImageUrl(base64);
        formInput.hasUnsavedChanges = true;
    }

    const handleImageAnnotationChangeClick = event => {
        setImageUrl(null);
        setImageWidth(null);
        setImageHeight(null);
    }

    const handleDefaultValueTextChange = event => {
        setDefaultValue(event.target.value);
        formInput.hasUnsavedChanges = true;
    }

    const handleDefaultValueRichTextChange = content => {
        const html = content;
        setDefaultValue(((html === '<p><br></p>') ? null : html));
        formInput.hasUnsavedChanges = true;
    }

    const handleDefaultValueCheckboxChange = event => {
        if (!defaultValue) {
            setDefaultValue([]);
        }

        if (event.target.checked) {
            defaultValue.push(event.target.value);
        } else {
            const index = defaultValue.findIndex(v => v === event.target.value);
            if (index >= 0) {
                defaultValue.splice(index, 1);
            }
        }

        if (defaultValue.length === 0) {
            setDefaultValue(null);
        }

        setDefaultValue(defaultValue);
        formInput.hasUnsavedChanges = true;
    }

    const renderOptions = () => {
        return <>
            {
                options && options.length > 0 ?
                    options.map((o, oi) => {
                        return <ul
                            key={`form_input_options_${oi}`}
                            className='list-inline no-style m-0 mb-2'
                        >
                            <li className='list-inline-item m-0 mr-1'>{oi + 1}.&nbsp;</li>
                            <li className='list-inline-item m-0 option'>
                                <input
                                    id={`new-form-input-${formInput.data.key}-${oi}`}
                                    type='text'
                                    className='form-control form-control-sm'
                                    autoComplete='off'
                                    value={o}
                                    onChange={(e) => handleOptionChange(e, oi)}
                                />
                            </li>
                            <li className='list-inline-item m-0 ml-2'>
                                <button
                                    type='button'
                                    className='btn btn-link btn-icon btn-sm'
                                    autoComplete='off'
                                    onClick={e => { handleRemoveOption(o, oi) }}
                                >
                                    <i className={'fal fa-minus-circle text-danger'}></i>
                                </button>
                            </li>
                            <li className='list-inline-item m-0'>
                                <button
                                    type='button'
                                    className='btn btn-link btn-icon btn-sm'
                                    autoComplete='off'
                                    disabled={options.length === 1 || oi === 0}
                                    onClick={e => { handleMoveOption(oi, (oi - 1)) }}
                                >
                                    <i className={'fal fa-arrow-up' + (options.length === 1 || oi === 0 ? ' text-gray-500' : ' text-primary')}></i>
                                </button>
                            </li>
                            <li className='list-inline-item m-0'>
                                <button
                                    type='button'
                                    className='btn btn-link btn-icon btn-sm'
                                    disabled={options.length === 1 || oi === (options.length - 1)}
                                    onClick={e => { handleMoveOption(oi, (oi + 1)) }}
                                >
                                    <i className={'fal fa-arrow-down' + (options.length === 1 || oi === (options.length - 1) ? ' text-gray-500' : ' text-primary')}></i>
                                </button>
                            </li>
                        </ul>
                    }) : <div></div>
            }
            <button
                type='button'
                className='btn btn-link btn-icon-left btn-sm'
                onClick={handleAddOption}
            >
                <i className='fal fa-plus-circle'></i> Add option
            </button>
        </>
    }

    const renderDefaultValueEditor = () => {
        switch (formInput.data.type) {
            case 'SingleLineText':
                return <input
                    id='new-form-input-default'
                    type='text'
                    maxLength={100}
                    className='form-control'
                    value={defaultValue ? defaultValue : ''}
                    autoComplete={'off'}
                    onChange={handleDefaultValueTextChange}
                />;

            case 'MultiLineText':
                return <textarea
                    id='new-form-input-default'
                    className='form-control'
                    value={defaultValue ? defaultValue : ''}
                    autoComplete={'off'}
                    onChange={handleDefaultValueTextChange}
                />;
                // return <RichTextEditor
                //     id='new-form-input-default'
                //     mode='none'
                //     disableTab={true}
                //     value={defaultValue ? defaultValue : ''}
                //     onChange={handleDefaultValueRichTextChange}
                // />;

            case 'RichText':
                return <RichTextEditor
                    id='new-form-input-default'
                    mode='full'
                    disableTab={true}
                    value={defaultValue ? defaultValue : ''}
                    onChange={handleDefaultValueRichTextChange}
                />;

            case 'Dropdown':
                return <select
                    id='new-form-input-default'
                    className='custom-select form-control'
                    value={defaultValue}
                    onChange={handleDefaultValueTextChange}
                >
                    <option value=''></option>
                    {
                        options && options.length > 0 ?
                            options.map((o, oi) => {
                                return <option key={`${formInput.key}_metadata_${oi}`} value={o}>{o}</option>
                            }) : null
                    }
                </select>;

            case 'Radio':
                return <div className='radio-wrapper'>
                    {
                        options && options.length > 0 ?
                            options.map((o, oi) => {
                                return <div
                                    key={`${formInput.key}_metadata_${oi}`}
                                    className={'custom-control custom-radio mb-2'}
                                >
                                    <input
                                        id={`new-form-input-default-${formInput.key}-${oi}`}
                                        type='radio'
                                        name='form-input-default'
                                        className='custom-control-input'
                                        value={o}
                                        checked={defaultValue === o}
                                        onChange={handleDefaultValueTextChange}
                                    />
                                    <label
                                        htmlFor={`new-form-input-inline-${formInput.key}-${oi}`}
                                        className='custom-control-label'
                                    >
                                        {o}
                                    </label>
                                </div>
                            }) : null
                    }
                </div>;

            case 'Checkbox':
                return <div className='checkbox-wrapper'>
                    {
                        options && options.length > 0 ?
                            options.map((o, oi) => {
                                return <div
                                    key={`${formInput.key}_metadata_${oi}`}
                                    className={'custom-control custom-checkbox mb-2'}
                                >
                                    <input
                                        id={`new-form-input-default-${formInput.key}-${oi}`}
                                        type='checkbox'
                                        name='form-input-default'
                                        className='custom-control-input'
                                        value={o}
                                        checked={defaultValue && defaultValue.split('|').some(v => v === o)}
                                        onChange={handleDefaultValueCheckboxChange}
                                    />
                                    <label
                                        htmlFor={`new-form-input-inline-${formInput.key}-${oi}`}
                                        className='custom-control-label'
                                    >
                                        {o}
                                    </label>
                                </div>
                            }) : null
                    }
                </div>;

            case 'Date':
            case 'Time':
            default:
                return null
        }
    }

    const populateMetadata = (field, key, value) => {
        if (tih.hasField(formInput.data.type, field)) {
            formInput.setMetadata(key, value);
        } else {
            formInput.removeMetadata(key);
        }
    }

    const setFocusToName = () => {
        setTimeout(() => {
            const element = document.querySelector('#new-form-input-name');
            if (element) {
                element.focus();
            }
        }, 100)
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form onSubmit={handleSubmit}>
            <fieldset disabled={formInput.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('form-input', 'default')}
                        action='New'
                        category='Form Input'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        <FadeIn>
                            <div className='new-form-input body-content'>
                                <section>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='form-group mb-4'>
                                                <label className='required' htmlFor='new-form-input-type'><small>Type</small></label>
                                                <select
                                                    id='new-form-input-type'
                                                    className='custom-select form-control'
                                                    value={formInput.data.type ? formInput.data.type : ''}
                                                    onChange={handleTypeChange}
                                                >
                                                    <option value=''></option>
                                                    {
                                                        cache.getReferenceDataOptions('TemplateInputType').map((option, di) => {
                                                            return <option key={`form_input_type_${di}`} value={option.key}>{option.value}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        metadataReady ?
                                            <FadeIn>
                                                <>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-4'>
                                                                <label className='required' htmlFor='new-form-input-name'><small>Name</small></label>
                                                                <input
                                                                    id='new-form-input-name'
                                                                    type='text'
                                                                    maxLength={100}
                                                                    className='form-control'
                                                                    autoComplete={'off'}
                                                                    value={formInput.data.name ? formInput.data.name : ''}
                                                                    onChange={handleNameChange}
                                                                />
                                                                <small className='text-gray-700'><strong>Important:</strong> Name must be unique across all inputs.</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        tih.hasField(formInput.data.type, tih.TEMPLATE_INPUT_LABEL) && tih.hasField(formInput.data.type, tih.TEMPLATE_INPUT_METADATA_HINT) ?
                                                            <div className='row'>
                                                                <div className='col-6'>
                                                                    <div className='form-group mb-4'>
                                                                        <label htmlFor='new-form-input-label'><small>Label</small></label>
                                                                        <input
                                                                            id='new-form-input-label'
                                                                            type='text'
                                                                            maxLength={100}
                                                                            className='form-control'
                                                                            value={formInput.data.label ? formInput.data.label : ''}
                                                                            autoComplete={'off'}
                                                                            onChange={handleLabelChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='col-6'>
                                                                    <div className='form-group mb-4'>
                                                                        <label htmlFor='new-form-input-hint'><small>Hint</small></label>
                                                                        <input
                                                                            id='new-form-input-hint'
                                                                            type='text'
                                                                            maxLength={100}
                                                                            className='form-control'
                                                                            value={hint ? hint : ''}
                                                                            autoComplete={'off'}
                                                                            onChange={handleHintChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div> : null
                                                    }
                                                    {
                                                        tih.hasField(formInput.data.type, tih.TEMPLATE_INPUT_LABEL) && !tih.hasField(formInput.data.type, tih.TEMPLATE_INPUT_METADATA_HINT) ?
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='form-group mb-4'>
                                                                        <label htmlFor='new-form-input-label'><small>Label</small></label>
                                                                        <input
                                                                            id='new-form-input-label'
                                                                            type='text'
                                                                            maxLength={100}
                                                                            className='form-control'
                                                                            value={formInput.data.label ? formInput.data.label : ''}
                                                                            autoComplete={'off'}
                                                                            onChange={handleLabelChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div> : null
                                                    }
                                                    {
                                                        tih.hasField(formInput.data.type, tih.TEMPLATE_INPUT_METADATA_PLACEHOLDER) ?
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='form-group mb-4'>
                                                                        <label htmlFor='new-form-input-placeholder'><small>Placeholder</small></label>
                                                                        <input
                                                                            id='new-form-input-placeholder'
                                                                            type='text'
                                                                            maxLength={100}
                                                                            className='form-control'
                                                                            value={placeholder ? placeholder : ''}
                                                                            autoComplete={'off'}
                                                                            onChange={handlePlaceholderChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div> : null
                                                    }
                                                    {
                                                        tih.hasField(formInput.data.type, tih.TEMPLATE_INPUT_METADATA_IS_REQUIRED) ?
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='form-group mb-4'>
                                                                        <label className='required'><small>Required</small></label>
                                                                        <div>
                                                                            <div className='custom-control custom-radio custom-control-inline'>
                                                                                <input
                                                                                    id={`new-form-input-is-required-true`}
                                                                                    type='radio'
                                                                                    name='form-input-is-required'
                                                                                    className='custom-control-input'
                                                                                    value={true}
                                                                                    checked={isRequired}
                                                                                    onChange={handleIsRequiredChange}
                                                                                />
                                                                                <label
                                                                                    htmlFor={`new-form-input-is-required-true`}
                                                                                    className='custom-control-label'
                                                                                >
                                                                                    Yes
                                                                                </label>
                                                                            </div>
                                                                            <div className='custom-control custom-radio custom-control-inline'>
                                                                                <input
                                                                                    id={`new-form-input-is-required-false`}
                                                                                    type='radio'
                                                                                    name='form-input-is-required'
                                                                                    className='custom-control-input'
                                                                                    value={false}
                                                                                    checked={!isRequired}
                                                                                    onChange={handleIsRequiredChange}
                                                                                />
                                                                                <label
                                                                                    htmlFor={`new-form-input-is-required-false`}
                                                                                    className='custom-control-label'
                                                                                >
                                                                                    No
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> : null
                                                    }
                                                    {
                                                        tih.hasField(formInput.data.type, tih.TEMPLATE_INPUT_METADATA_DISPLAY) ?
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='form-group mb-4'>
                                                                        <label><small>Display</small></label>
                                                                        <div>
                                                                            <div className='custom-control custom-radio custom-control-inline'>
                                                                                <input
                                                                                    id={`new-form-input-display-always`}
                                                                                    type='radio'
                                                                                    name='form-input-display'
                                                                                    className='custom-control-input'
                                                                                    value={null}
                                                                                    checked={fn.isNullOrUndefined(display)}
                                                                                    onChange={handleDisplayChange}
                                                                                />
                                                                                <label
                                                                                    htmlFor={`new-form-input-display-always`}
                                                                                    className='custom-control-label'
                                                                                >
                                                                                    Always
                                                                                </label>
                                                                            </div>
                                                                            <div className='custom-control custom-radio custom-control-inline'>
                                                                                <input
                                                                                    id={`new-form-input-display-desktop`}
                                                                                    type='radio'
                                                                                    name='form-input-display'
                                                                                    className='custom-control-input'
                                                                                    value={'desktop'}
                                                                                    checked={display === 'desktop'}
                                                                                    onChange={handleDisplayChange}
                                                                                />
                                                                                <label
                                                                                    htmlFor={`new-form-input-display-desktop`}
                                                                                    className='custom-control-label'
                                                                                >
                                                                                    Desktop only
                                                                                </label>
                                                                            </div>
                                                                            <div className='custom-control custom-radio custom-control-inline'>
                                                                                <input
                                                                                    id={`new-form-input-display-mobile`}
                                                                                    type='radio'
                                                                                    name='form-input-display'
                                                                                    className='custom-control-input'
                                                                                    value={'mobile'}
                                                                                    checked={display === 'mobile'}
                                                                                    onChange={handleDisplayChange}
                                                                                />
                                                                                <label
                                                                                    htmlFor={`new-form-input-display-mobile`}
                                                                                    className='custom-control-label'
                                                                                >
                                                                                    Mobile only
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> : null
                                                    }
                                                    {
                                                        tih.hasField(formInput.data.type, tih.TEMPLATE_INPUT_METADATA_SIZE) ?
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='form-group mb-4'>
                                                                        <label className='required' htmlFor='new-form-input-size'><small>Size</small></label>
                                                                        <select
                                                                            id='new-form-input-size'
                                                                            className='custom-select form-control'
                                                                            value={size ? size : ''}
                                                                            onChange={handleSizeChange}
                                                                        >
                                                                            <option value='sm'>Small</option>
                                                                            <option value=''>Standard</option>
                                                                            <option value='lg'>Large</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div> : null
                                                    }
                                                    {
                                                        tih.hasField(formInput.data.type, tih.TEMPLATE_INPUT_METADATA_ALIGN) ?
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='form-group mb-4'>
                                                                        <label className='required' htmlFor='new-form-input-align'><small>Align</small></label>
                                                                        <div>
                                                                            <div className='btn-group'>
                                                                                <button
                                                                                    type='button'
                                                                                    className={'btn btn-secondary' + (align === 'left' ? ' active' : '')}
                                                                                    value={(align ? align : '')}
                                                                                    onClick={() => { handleAlignChange('left') }}
                                                                                >
                                                                                    <i className='fal fa-align-left'></i>
                                                                                </button>
                                                                                <button
                                                                                    type='button'
                                                                                    className={'btn btn-secondary' + (align === 'center' ? ' active' : '')}
                                                                                    value={(align ? align : '')}
                                                                                    onClick={() => { handleAlignChange('center') }}
                                                                                >
                                                                                    <i className='fal fa-align-center'></i>
                                                                                </button>
                                                                                <button
                                                                                    type='button'
                                                                                    className={'btn btn-secondary' + (align === 'right' ? ' active' : '')}
                                                                                    value={(align ? align : '')}
                                                                                    onClick={() => { handleAlignChange('right') }}
                                                                                >
                                                                                    <i className='fal fa-align-right'></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> : null
                                                    }
                                                    {
                                                        tih.hasField(formInput.data.type, tih.TEMPLATE_INPUT_METADATA_VALIGN) ?
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='form-group mb-4'>
                                                                        <label className='required' htmlFor='new-form-input-valign'><small>Vertical Align</small></label>
                                                                        <div>
                                                                            <div className='btn-group'>
                                                                                <button
                                                                                    type='button'
                                                                                    className={'btn btn-secondary' + (valign === 'top' ? ' active' : '')}
                                                                                    value={(valign ? valign : '')}
                                                                                    onClick={() => { handleValignChange('top') }}
                                                                                >
                                                                                    <i className='fal fa-arrow-to-top'></i>
                                                                                </button>
                                                                                <button
                                                                                    type='button'
                                                                                    className={'btn btn-secondary' + (valign === 'middle' ? ' active' : '')}
                                                                                    value={(valign ? valign : '')}
                                                                                    onClick={() => { handleValignChange('middle') }}
                                                                                >
                                                                                    <i className='fal fa-horizontal-rule'></i>
                                                                                </button>
                                                                                <button
                                                                                    type='button'
                                                                                    className={'btn btn-secondary' + (valign === 'bottom' ? ' active' : '')}
                                                                                    value={(valign ? valign : '')}
                                                                                    onClick={() => { handleValignChange('bottom') }}
                                                                                >
                                                                                    <i className='fal fa-arrow-to-bottom'></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> : null
                                                    }
                                                    {
                                                        tih.hasField(formInput.data.type, tih.TEMPLATE_INPUT_METADATA_INLINE) ?
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='form-group mb-4'>
                                                                        <label><small>Inline</small></label>
                                                                        <div>
                                                                            <div className='custom-control custom-radio custom-control-inline'>
                                                                                <input
                                                                                    id={`new-form-input-inline-true`}
                                                                                    type='radio'
                                                                                    name='form-input-inline'
                                                                                    className='custom-control-input'
                                                                                    value={true}
                                                                                    checked={inline}
                                                                                    onChange={handleInlineChange}
                                                                                />
                                                                                <label
                                                                                    htmlFor={`new-form-input-inline-true`}
                                                                                    className='custom-control-label'
                                                                                >
                                                                                    Yes
                                                                                </label>
                                                                            </div>
                                                                            <div className='custom-control custom-radio custom-control-inline'>
                                                                                <input
                                                                                    id={`new-form-input-inline-false`}
                                                                                    type='radio'
                                                                                    name='form-input-inline'
                                                                                    className='custom-control-input'
                                                                                    value={false}
                                                                                    checked={!inline}
                                                                                    onChange={handleInlineChange}
                                                                                />
                                                                                <label
                                                                                    htmlFor={`new-form-input-inline-false`}
                                                                                    className='custom-control-label'
                                                                                >
                                                                                    No
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> : null
                                                    }
                                                    {
                                                        tih.hasField(formInput.data.type, tih.TEMPLATE_INPUT_METADATA_IMAGE_URL) ?
                                                            <div className='row options'>
                                                                <div className='col-12'>
                                                                    <div className='form-group mb-4'>
                                                                        <label className='required' htmlFor='new-form-input-options'><small>Image</small></label>
                                                                        {
                                                                            imageUrl ?
                                                                                <div>
                                                                                    <img src={imageUrl} width={imageWidth} height={imageHeight} alt='Annotate' />
                                                                                    <button
                                                                                        type='button'
                                                                                        className='btn btn-link px-0 btn-sm d-block'
                                                                                        onClick={handleImageAnnotationChangeClick}
                                                                                    >Change image</button>
                                                                                </div> :
                                                                                <div className='custom-file'>
                                                                                    <input
                                                                                        id='new-form-input-image-url'
                                                                                        type='file'
                                                                                        className='custom-file-input'
                                                                                        accept='image/*'
                                                                                        onChange={handleImageAnnotationChange} />
                                                                                    <label
                                                                                        htmlFor='new-form-input-image-url'
                                                                                        className='custom-file-label'>
                                                                                    </label>
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div> : null
                                                    }
                                                    {
                                                        tih.hasField(formInput.data.type, tih.TEMPLATE_INPUT_METADATA_OPTIONS) ?
                                                            <div className='row options'>
                                                                <div className='col-12'>
                                                                    <div className='form-group mb-4'>
                                                                        <label className='required' htmlFor='new-form-input-options'><small>Options</small></label>
                                                                        {renderOptions()}
                                                                    </div>
                                                                </div>
                                                            </div> : null
                                                    }
                                                    {
                                                        tih.hasField(formInput.data.type, tih.TEMPLATE_INPUT_METADATA_INPUT_MASK) ?
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='form-group mb-4'>
                                                                        <label className='required' htmlFor='new-form-input-size'><small>Mask Type</small></label>
                                                                        <select
                                                                            id='new-form-input-size'
                                                                            className='custom-select form-control'
                                                                            value={inputmask ? inputmask : ''}
                                                                            onChange={handleInputmaskChange}
                                                                        >
                                                                            <option value=''></option>
                                                                            <option value='email'>Email Address</option>
                                                                            <option value='phone'>Phone Number</option>
                                                                            <option value='postal'>Postal Code</option>
                                                                            <option value='ohip'>OHIP (w/ version code)</option>
                                                                            <option value='ohip-no-code'>OHIP (w/o version code)</option>
                                                                            <option value='ohip-code'>OHIP Version Code</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div> : null
                                                    }
                                                    {
                                                        showAdvanced ?
                                                            <>
                                                                <FadeIn>
                                                                    {
                                                                        tih.hasField(formInput.data.type, tih.TEMPLATE_INPUT_METADATA_DEFAULT_VALUE) ?
                                                                            <div className='row'>
                                                                                <div className='col-12'>
                                                                                    <div className='form-group mb-4'>
                                                                                        <label htmlFor='new-form-input-default'><small>Default Value</small></label>
                                                                                        {renderDefaultValueEditor()}
                                                                                    </div>
                                                                                </div>
                                                                            </div> : null
                                                                    }
                                                                    {
                                                                        tih.hasField(formInput.data.type, tih.TEMPLATE_INPUT_DESCRIPTION) ?
                                                                            <div className='row'>
                                                                                <div className='col-12'>
                                                                                    <div className='form-group mb-4'>
                                                                                        <label htmlFor='new-form-input-description'><small>Description</small></label>
                                                                                        <RichTextEditor
                                                                                            mode='none'
                                                                                            disableTab={true}
                                                                                            value={formInput.data.descriptionHtml ? formInput.data.descriptionHtml : ''}
                                                                                            onChange={handleDescriptionChange}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div> : null
                                                                    }
                                                                    <div className='row'>
                                                                        <div className='col-12'>
                                                                            <div className='form-group mb-4'>
                                                                                <label className='required' htmlFor='new-form-input-key'><small>Key</small></label>
                                                                                <input
                                                                                    id='new-form-input-key'
                                                                                    type='text'
                                                                                    maxLength={100}
                                                                                    className='form-control'
                                                                                    autoComplete='off'
                                                                                    value={formInput.data.key ? formInput.data.key : ''}
                                                                                    onChange={handleKeyChange}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </FadeIn>
                                                            </> :
                                                            <div className='mt-4 pt-4 border-top'>
                                                                <button type='button' className='btn btn-link btn-sm p-0' onClick={() => { setShowAdvanced(true) }}>&raquo;	Show advanced options</button>
                                                            </div>
                                                    }
                                                </>
                                            </FadeIn> : null
                                    }
                                </section>
                            </div>
                        </FadeIn>
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-4'>
                                <button
                                    type='button'
                                    className='btn btn-link px-0'
                                    onClick={handlePreviewClick}
                                >Preview</button>
                            </div>
                            <div className='col-8'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
        <BodyEnd>
            <div className={'modal fade d-block' + (displayPreview ? ' show' : '')} tabIndex='-1' role='dialog' aria-modal='true'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>
                                Quick Preview
                                <small className='m-0 text-gray-700'>Below is the preview of your <code>{formInput.data.type}</code> input.</small>
                            </h5>
                            <button
                                type='button'
                                className='close'
                                data-dismiss='modal'
                                aria-label='Close'
                                onClick={handleClosePreviewClick}
                            >
                                <span aria-hidden='true'><i className='fal fa-times'></i></span>
                            </button>
                        </div>
                        <div className='modal-body pb-0'>
                            {tih.renderInput(formInput.data, { isDesignMode: true })}
                        </div>
                        <div className='modal-footer pt-0'>
                            <button
                                type='button'
                                className='btn btn-sm btn-primary'
                                onClick={handleClosePreviewClick}
                            >
                                Done
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'modal-backdrop fade' + (displayPreview ? ' show' : '')} onClick={handleClosePreviewClick}></div>
        </BodyEnd>
    </>)
}

export default NewFormInput;