import { createContext } from 'react';
import { decorate, observable, action } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class PretestUpdate {
    id = null;
    data = null;
    isReady = false;
    isLoading = false;
    isSaving = false;
    hasUnsavedChanges = false;

    cancelPretest = null;
    cancelPretestUpdate = null;

    initialize = (id) => {
        const that = this;

        this.clear();
        this.id = id;

        return new Promise((resolve, reject) => {
            that.refresh()
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isReady = true;
                })
        })
    }

    refresh = () => {
        const that = this;
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            api.Pretests.get(that.id, (c) => { that.cancelPretest = c })
                .then(({ data }) => {
                    that.data = data;
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isLoading = false;
                })
        })
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            if (that.hasUnsavedChanges) {
                api.Pretests.update(that.id, { pretestData: that.data.pretestData }, (c) => { that.cancelPretestUpdate = c })
                    .then(() => {
                        that.hasUnsavedChanges = false;
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            }
            else {
                resolve();
                that.isSaving = false;
            }
        })
    }

    clear = () => {
        this.id = null;
        this.data = null;
        this.isReady = false;
        this.isLoading = false;
        this.isSaving = false;

        if (fn.isFunction(this.cancelPretest)) {
            this.cancelPretest();
            this.cancelPretest = null;
        }

        if (fn.isFunction(this.cancelPretestUpdate)) {
            this.cancelPretestUpdate();
            this.cancelPretestUpdate = null;
        }
    }
}

decorate(PretestUpdate, {
    id: observable,
    data: observable.deep,
    isReady: observable,
    isLoading: observable,
    isSaving: observable,
    hasUnsavedChanges: observable,
    initialize: action,
    refresh: action,
    save: action,
    clear: action,
})

export default createContext(new PretestUpdate());