import React, { Component } from 'react';
import { Tooltip } from 'devextreme-react/tooltip';
import uuid from 'react-uuid';

export default class NoteTooltip extends Component {
    constructor(props) {
        super(props);

        this.state = {
            display: false,
        };

        this.tooltipRef = React.createRef();
        this.idRef = React.createRef();
        this.idRef.current = uuid();
    }

    render() {
        return (
            <>
                <i
                    id={`tt_${this.idRef.current}`}
                    className='fas fa-file-alt text-info'
                    onMouseEnter={() => { this.setState({ display: true }) }}
                    onMouseLeave={() => { this.setState({ display: false }) }}
                ></i>
                <Tooltip
                    target={`#tt_${this.idRef.current}`}
                    visible={this.state.display}
                    position={'right'}
                    hideOnOutsideClick={false}
                >
                    <div className='html' dangerouslySetInnerHTML={{ __html: this.props.noteHtml }} />
                </Tooltip>
            </>
        )
    }
}