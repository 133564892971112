import React from 'react';
import moment from 'moment';

import { downloadFile, printFile } from '../components/views/_shared/FileViewer';

import * as fn from './_functions';
import * as ah from './accessHelper';

import api from '../api';

export const MEDICAL_REPORT_SIGNATURE_CANVAS_WIDTH = 400;
export const MEDICAL_REPORT_SIGNATURE_CANVAS_HEIGHT = 180;
export const MEDICAL_REPORT_SIGNATURE_DEMO = {
    fullName: 'Dr. John Johnstone',
    licenseNumber: '0012345'
};

const MEDICAL_REPORT_SIGNATURE_LINE_POS_Y_PERCENTAGE = 0.7;

export const PRINT_MESSAGE = <>
    <h2 className='text-uppercase text-center font-weight-bold text-gray-900 mb-1'>Generating...</h2>
    <p className='fs-xl'>Please wait while we generate the medical report.</p>
</>;

export const SEND_MESSAGE = <>
    <h2 className='text-uppercase text-center font-weight-bold text-gray-900 mb-1'>Sending...</h2>
    <p className='fs-xl'>Please wait while we generate and send the medical report.</p>
</>;

export const getSignatureLineBase64 = (user, signed, options) => {
    let result, emptySignatureLine;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const defaults = {
        lineColor: '#000000',
        lineWidth: 1,
        textColor: '#000000',
        textFont: 'arial',
        textFontSize: 13,
        descriptionColor: '#000000',
        descriptionFont: 'arial',
        descriptionFontSize: 11,
        scale: 1.0,
        signedCallback: null,
    };
    options = { ...defaults, ...options }

    const height = MEDICAL_REPORT_SIGNATURE_CANVAS_HEIGHT * options.scale;
    const width = MEDICAL_REPORT_SIGNATURE_CANVAS_WIDTH * options.scale;
    const linePositionY = height * MEDICAL_REPORT_SIGNATURE_LINE_POS_Y_PERCENTAGE;

    options.lineWidth = options.lineWidth * options.scale;
    options.textFontSize = options.textFontSize * options.scale;
    options.descriptionFontSize = options.descriptionFontSize * options.scale;

    canvas.width = width;
    canvas.height = height;

    // Draw signature line
    context.strokeStyle = options.lineColor;
    context.lineWidth = options.lineWidth;
    context.beginPath();
    context.moveTo(0, linePositionY);
    context.lineTo(canvas.width, linePositionY);
    context.stroke();

    if (user) {
        let indentX = options.textFontSize;
        let textY = (linePositionY + (options.textFontSize + options.lineWidth + (options.textFontSize)));


        context.font = `${options.textFontSize}px ${options.textFont}`;
        context.fillStyle = options.textColor;
        context.fillText(`${user.fullName}`, indentX, textY);

        // if (user.designation) {
        //     let textMeasurement = context.measureText(`${user.fullName}`);
        //     let designationX = textMeasurement.width + indentX + (options.descriptionFontSize / 2);
        //     let designationY = textY;

        //     context.font = `${options.descriptionFontSize}px ${options.descriptionFont}`;
        //     context.fillStyle = options.descriptionColor;
        //     context.fillText(`${user.designation}`, designationX, designationY);
        // }

        if (user.licenseNumber) {
            let licenseY = textY + options.textFontSize;

            context.fillStyle = options.textColor;
            context.fillText(`License number: ${user.licenseNumber}`, indentX, licenseY);
        }
    }

    emptySignatureLine = canvas.toDataURL('image/png');

    if (signed && user.signature) {
        const signedCanvas = document.createElement('canvas');
        const signedContext = signedCanvas.getContext('2d');
        const signatureLineImg = new Image(width, height);
        const signatureImg = new Image(width, height);
        let signatureLineImgLoaded = false;
        let signatureImgLoaded = false;

        signatureLineImg.src = emptySignatureLine;
        signatureImg.src = user.signature;

        signedCanvas.width = width;
        signedCanvas.height = height;

        signatureLineImg.onload = () => {
            signedContext.drawImage(signatureLineImg, 0, 0, width, height);
            signatureLineImgLoaded = true;

            if (signatureLineImgLoaded && signatureImgLoaded) {
                result = signedCanvas.toDataURL('image/png');

                if (options.signedCallback && fn.isFunction(options.signedCallback)) {
                    options.signedCallback(result);
                }
            }
        }

        signatureImg.onload = () => {
            signedContext.drawImage(signatureImg, 0, 0, width, height);
            signatureImgLoaded = true;

            if (signatureLineImgLoaded && signatureImgLoaded) {
                result = signedCanvas.toDataURL('image/png');

                if (options.signedCallback && fn.isFunction(options.signedCallback)) {
                    options.signedCallback(result);
                }
            }
        }
    } else {
        result = emptySignatureLine;
    }

    return result;
}

export const getFilename = (medicalReport) => {
    return `${fn.urlFriendly(`${medicalReport.customer.initials}-medical-report-${moment.utc(medicalReport.createdDateUtc).local().format('YYYYMMDD')}`)}.pdf`;
}

export const getLatestMedicalReportPreview = (medicalReport, isGeneratingPrintCallback, reprint = false) => {
    return new Promise((resolve) => {
        if (medicalReport) {
            const option = {
                documentType: 'MedicalReport',
                referenceId1: medicalReport.id,
                referenceDateTimeUtc: medicalReport.lastUpdatedDateUtc ? medicalReport.lastUpdatedDateUtc : medicalReport.createdDateUtc,
            };

            api.PreviewDocuments.search({
                parameters: [
                    { field: 'ReferenceId1', value: option.referenceId1 },
                    { field: 'ReferenceDateTimeUtc', value: option.referenceDateTimeUtc },
                    { field: 'DeactivatedDateUtc', value: null },
                ],
                sortByFields: [{ field: 'CreatedDateUtc', direction: 'DESC' }],
                offset: 0, limit: 1, includeTotalCount: false,
            })
                .then(({ data }) => {
                    let lastMedicalReport = data.result && data.result.length > 0 ? data.result[0] : null;

                    if (!lastMedicalReport || reprint) {
                        if (isGeneratingPrintCallback) {
                            isGeneratingPrintCallback(true);
                        }

                        api.PreviewDocuments.print(option)
                            .then(({ data }) => {
                                api.PreviewDocuments.get(data.id)
                                    .then(({ data: printedDocumentData }) => {
                                        if (isGeneratingPrintCallback) {
                                            isGeneratingPrintCallback(false);
                                        }
                                        lastMedicalReport = printedDocumentData;
                                        resolve(lastMedicalReport);
                                    })
                            })
                    } else {
                        resolve(lastMedicalReport);
                    }
                })
        }
    })
}

export const getLatestMedicalReport = (medicalReport, isGeneratingPrintCallback, reprint = false) => {
    return new Promise((resolve) => {
        if (medicalReport) {
            let option;

            switch (medicalReport.generation) {
                case 'Default':
                    option = {
                        documentType: 'MedicalReport',
                        customerId: medicalReport.customerId,
                        referenceId1: medicalReport.id,
                        referenceId2: medicalReport.examId,
                        referenceDateTimeUtc: medicalReport.lastUpdatedDateUtc ? medicalReport.lastUpdatedDateUtc : medicalReport.createdDateUtc,
                    };
                    break;

                case 'InternalForm':
                    option = {
                        documentType: 'InternalForm',
                        customerId: medicalReport.customerId,
                        referenceId1: medicalReport.internalFormId,
                        referenceId2: medicalReport.examId,
                        referenceDateTimeUtc: medicalReport.lastUpdatedDateUtc ? medicalReport.lastUpdatedDateUtc : medicalReport.createdDateUtc,
                    };
                    break;

                case 'None':
                default:
                    break;
            }

            if (option) {
                api.PrintedDocuments.search({
                    parameters: [
                        { field: 'Type', value: option.documentType },
                        { field: 'CustomerId', value: option.customerId },
                        { field: 'ReferenceId1', value: option.referenceId1 },
                        { field: 'ReferenceId2', value: option.referenceId2 },
                        { field: 'ReferenceDateTimeUtc', value: option.referenceDateTimeUtc },
                        { field: 'DeactivatedDateUtc', value: null },
                    ],
                    sortByFields: [{ field: 'CreatedDateUtc', direction: 'DESC' }],
                    offset: 0, limit: 1, includeTotalCount: false,
                })
                    .then(({ data }) => {
                        let lastMedicalReport = data.result && data.result.length > 0 ? data.result[0] : null;

                        if (!lastMedicalReport || reprint) {
                            if (isGeneratingPrintCallback) {
                                isGeneratingPrintCallback(true);
                            }

                            api.PrintedDocuments.print(option)
                                .then(({ data }) => {
                                    api.PrintedDocuments.get(data.id)
                                        .then(({ data: printedDocumentData }) => {
                                            if (isGeneratingPrintCallback) {
                                                isGeneratingPrintCallback(false);
                                            }
                                            lastMedicalReport = printedDocumentData;
                                            resolve(lastMedicalReport);
                                        })
                                })
                        } else {
                            resolve(lastMedicalReport);
                        }
                    })
            }
            else {
                resolve();
            }
        }
    })
}

export const preview = (medicalReport, fileViewer, isGeneratingPrintCallback) => {
    getLatestMedicalReportPreview(medicalReport, isGeneratingPrintCallback)
        .then((previewDocument) => {
            fileViewer.initialize(() => {
                return <div className='fs-lg'>
                    {medicalReport.customer.fullName}'s MedicalReport
                    <small className='d-block text-gray-700'>{fn.formatFullDateWithOrWithoutYear(moment.utc(medicalReport.createdDateUtc).local())}</small>
                </div>
            }, 'previewDocument', [previewDocument], 0, medicalReport.exam.customerId)
        })
}

export const regenerate = (medicalReport, fileViewer, isGeneratingPrintCallback) => {
    getLatestMedicalReport(medicalReport, isGeneratingPrintCallback, true)
        .then((printedDocument) => {
            fileViewer.initialize(() => {
                return <div className='fs-lg'>
                    {medicalReport.customer.fullName}'s MedicalReport
                    <small className='d-block text-gray-700'>{fn.formatFullDateWithOrWithoutYear(moment.utc(medicalReport.createdDateUtc).local())}</small>
                </div>
            }, 'printedDocument', [printedDocument], 0, medicalReport.customer.id)
        })
}

export const view = (medicalReport, fileViewer, isGeneratingPrintCallback, onCloseCallback) => {
    getLatestMedicalReport(medicalReport, isGeneratingPrintCallback)
        .then((printedDocument) => {
            fileViewer.initialize(() => {
                fileViewer.canEmail = false;
                fileViewer.canFax = false;
                fileViewer.onCloseCallback = onCloseCallback;
                return <div className='fs-lg'>
                    {medicalReport.customer.fullName}'s MedicalReport
                    <small className='d-block text-gray-700'>{fn.formatFullDateWithOrWithoutYear(moment.utc(medicalReport.createdDateUtc).local())}</small>
                </div>
            }, 'printedDocument', [printedDocument], 0, medicalReport.exam.customerId)
        })
}

export const download = (medicalReport, isGeneratingPrintCallback, downloadCallback) => {
    getLatestMedicalReport(medicalReport, isGeneratingPrintCallback)
        .then((printedDocument) => {
            downloadFile('printedDocument', printedDocument.fileName, printedDocument.id, null, downloadCallback);
        })
}

export const print = (medicalReport, isGeneratingPrintCallback) => {
    getLatestMedicalReport(medicalReport, isGeneratingPrintCallback)
        .then((printedDocument) => {
            api.PrintedDocuments.download(printedDocument.id)
                .then(({ data }) => {
                    printFile(URL.createObjectURL(data), printedDocument.mimeType);
                })
        })
}

export const canUpdate = (medicalReport) => {
    return !medicalReport.writtenById || ah.sameUser(medicalReport.writtenById);
}