import { createContext } from 'react';
import { decorate, observable, action, toJS } from 'mobx';
import moment from 'moment';

import api from '../api';
import { CURRENT_USER } from '../constants/storageKeys'
import * as fn from '../utilities/_functions';

export class NoteSearch {
    referenceId = null;
    referenceType = null;
    reference = null;
    filteredByReferenceId = false;
    customerId = null;
    customer = null;
    newNote = {
        attachments: [],
        bodyHtml: null,
        preview: null,
    };
    updateNote = null;
    removeNoteIds = [];
    isLoading = false;
    isReady = false;

    cancelAppointmentGet = null;
    cancelCustomerGet = null;
    cancelNoteCreate = null;
    cancelNoteUpdate = null;
    cancelNoteRemove = null;
    cancelNoteSearch = null;

    initialize = (customerId, referenceId, referenceType, filteredByReferenceId = false, filteredByReferenceType = false) => {
        this.clear();
        this.customerId = customerId;
        this.referenceId = referenceId ? referenceId : null;
        this.referenceType = referenceType ? referenceType : null;
        this.filteredByReferenceId = filteredByReferenceId;
        this.filteredByReferenceType = filteredByReferenceType;

        return this.refresh();
    }

    getReferenceRequest = () => {
        const that = this;

        switch (this.referenceType) {
            case 'Appointment':
                return api.Appointments.get(this.referenceId, (c) => { that.cancelAppointmentGet = c });

            default:
                return;
        }
    }

    refresh = () => {
        const that = this;
        return new Promise((resolve, reject) => {
            const requests = [];
            const parameters = [
                {
                    field: 'CustomerId',
                    value: that.customerId,
                },
                {
                    field: 'DeactivatedDateUtc',
                    value: null,
                },
            ];
            let refreshCustomer = false;
            let refreshReference = false;

            if (that.filteredByReferenceId === true) {
                parameters.push({
                    field: 'ReferenceId',
                    value: that.referenceId
                })
            }
            else if (that.filteredByReferenceType === true) {
                parameters.push({
                    field: 'Type',
                    value: that.referenceType
                })
            }

            requests.push(api.Notes.search(
                {
                    parameters: parameters,
                    sortByFields: [{
                        field: 'CreatedDateUtc',
                        direction: 'ASC',
                    }],
                },
                (c) => { that.cancelNoteSearch = c }
            ));

            if (!that.customer || that.customer.id !== that.customerId) {
                refreshCustomer = true;
                requests.push(api.Customers.get(that.customerId), (c) => { that.cancelCustomerGet = c });
            }

            Promise.all(requests)
                .then(response => {
                    const noteData = response[0].data.result ? response[0].data.result : [];
                    const notes = noteData;

                    if (refreshCustomer) {
                        that.customer = response[1].data;
                    }

                    if (refreshReference) {
                        that.reference = refreshCustomer ? response[2].data : response[1].data;
                    }

                    if (that.customer) {
                        that.customer.notes = notes;
                    }

                    that.isReady = true;

                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isLoading = false;
                })
        })
    }

    pin = (id, isPinned, notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            api.Notes.pin(id, { isPinned: isPinned }, (c) => { that.cancelNoteUpdate = c })
                .then(() => {
                    that.refresh();
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    update = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            if (!!that.updateNote) {
                api.Notes.update(
                    that.updateNote.id,
                    {
                        preview: that.updateNote.preview,
                        bodyHtml: that.updateNote.bodyHtml,
                    }, (c) => { that.cancelNoteUpdate = c })
                    .then(() => {
                        if (that.customer && that.customer.notes && that.customer.length > 0) {
                            const index = that.customer.notes.findIndex(n => n.id === that.updateNote.id);

                            if (index >= 0) {
                                that.customer.notes[index] = toJS(that.updateNote);
                                that.updateNote = null;
                            }
                        }
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            }
            else {
                that.isSaving = false;
                resolve();
            }
        })
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            const requests = [];

            if (that.newNote.preview || that.newNote.attachments.length > 0) {
                const currentUser = JSON.parse(window.localStorage.getItem(CURRENT_USER));

                that.newNote.noteType = (that.referenceType ? that.referenceType : 'Customer').replace(/[^a-zA-Z0-9]+/g, '');  // TODO: probably an error here.
                that.newNote.referenceId = that.referenceId ? that.referenceId : null;
                that.newNote.customerId = that.customer.id;
                that.newNote.resource = currentUser;
                that.newNote.createdDateUtc = moment.utc().toDate();

                requests.push(api.Notes.create(toJS(that.newNote), (c) => { that.cancelNoteCreate = c }));
            }

            if (that.removeNoteIds.length > 0) {
                for (let i = 0; i < that.removeNoteIds.length; i++) {
                    requests.push(api.Notes.delete(toJS(that.removeNoteIds[i]), (c) => { that.cancelNoteRemove = c }));
                }
            }

            if (requests.length > 0) {
                Promise.all(requests)
                    .then(() => {
                        that.clearNewNote();
                        resolve(true);
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                that.isSaving = false;
                resolve(false);
            }
        })
    }

    clear = () => {
        this.referenceId = null;
        this.referenceType = null;
        this.reference = null;
        this.filteredByReferenceId = false;
        this.customerId = null;
        this.customer = null;
        this.updateNote = null;
        this.removeNoteIds.clear();
        this.isLoading = false;
        this.isReady = false;
        this.clearNewNote();

        if (fn.isFunction(this.cancelAppointmentGet)) {
            this.cancelAppointmentGet();
            this.cancelAppointmentGet = null;
        }

        if (fn.isFunction(this.cancelCustomerGet)) {
            this.cancelCustomerGet();
            this.cancelCustomerGet = null;
        }

        if (fn.isFunction(this.cancelNoteCreate)) {
            this.cancelNoteCreate();
            this.cancelNoteCreate = null;
        }
    }

    clearNewNote = () => {
        this.newNote.attachments.clear();
        this.newNote.bodyHtml = null;
        this.newNote.preview = null;
    }
}

decorate(NoteSearch, {
    referenceId: observable,
    referenceType: observable,
    reference: observable,
    filteredByReferenceId: observable,
    customerId: observable,
    customer: observable.deep,
    newNote: observable.deep,
    updateNote: observable,
    removeNoteIds: observable.deep,
    isLoading: observable,
    isSaving: observable,
    isReady: observable,
    initialize: action,
    refresh: action,
    update: action,
    save: action,
    clear: action,
    clearNewNote: action,
})

export default createContext(new NoteSearch());