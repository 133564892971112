import React, { useEffect, useRef, useContext, useState } from 'react';
import { Observer } from 'mobx-react-lite';
import { ScrollView } from 'devextreme-react';
import DataGrid, { Paging, Pager, Sorting, Scrolling, Column, ColumnFixing } from 'devextreme-react/data-grid';
import { toJS } from 'mobx';
import { toast } from 'react-toastify';

import FilterProducts from './FilterProducts';
import { quickDrawerFocus } from '../../../_shared/QuickDrawer';

import ServiceSearchStore from '../../../../../stores/ServiceSearchStore';
import ProductCreateStore from '../../../../../stores/ProductCreateStore';
import InventorySearchStore from '../../../../../stores/InventorySearchStore';
import QuickDrawerStore from '../../../../../stores/QuickDrawerStore';
import CacheStore from '../../../../../stores/CacheStore';

import * as AccessType from '../../../../../constants/accessTypes';
import * as fn from '../../../../../utilities/_functions';
import * as ah from '../../../../../utilities/accessHelper';
import * as pdh from '../../../../../utilities/productHelper';
import * as bh from '../../../../../utilities/badgeHelper';

import api from '../../../../../api';

const PAGE_SIZE = 50;

function ViewProducts(props) {
    const isMounted = useRef(true);
    const searchTimer = useRef(null);
    const refreshTotalTimer = useRef(null);
    const focusTimer = useRef(null);
    const tabRef = useRef(null);
    const filterRef = useRef(null);
    const productDataGridRef = useRef(null);
    const serviceSearch = useContext(ServiceSearchStore);
    const newProduct = useContext(ProductCreateStore);
    const search = useContext(InventorySearchStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const cache = useContext(CacheStore);
    const [itemType, setItemType] = useState(null);
    const [listReady, setListReady] = useState(false);
    const [pageIndex, setPageIndex] = useState(0);
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        Promise.all([
            serviceSearch.initialize(true, false, -1),
            search.initialize('InStock', -1, props.productType)
        ])
            .then(() => {
                if (isMounted.current) {
                    setListReady(true);
                    setSelected((props.selected ? props.selected : []));
                    setPageIndex(0);
                    if (props.itemType && ['Product', 'Service'].some(t => t === props.itemType)) {
                        setItemType(props.itemType);
                    }
                    refreshTotalTimer.current = setTimeout(() => {
                        search.refreshTotal();
                    }, 50)
                }
            })

        if (ah.check(AccessType.VIEW_PRODUCT)) {
            setItemType('Product');
        }
        else if (ah.check(AccessType.VIEW_SERVICE)) {
            setItemType('Service');
        }

        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
            serviceSearch.clear();
            search.clear();
            newProduct.clear();
        }
    }, []) // eslint-disable-line

    const handleSelectorChange = (event, type, productType) => {
        event.preventDefault();
        setItemType(type);
        setPageIndex(0);

        switch (type) {
            case 'Service':
                break;

            case 'Product':
                if (isMounted.current) {
                    const id = productType ? productType.id : null;

                    if (id) {
                        search.selectorId = id;
                        search.brandId = null;
                        if (isInProductFullSearchMode()) {
                            search.search();
                        } else {
                            search.execute(1)
                                .then(() => {
                                    if (isMounted.current) {
                                        setPageIndex(0);
                                        refreshTotalTimer.current = setTimeout(() => {
                                            search.refreshTotal();
                                        }, 50)
                                    }
                                });
                        }
                    }
                }
                break;

            default:
                break;
        }
    }

    const handleProductBrandChange = (event, { id }) => {
        event.preventDefault();
        search.brandId = id;
        search.execute(1)
            .then(() => {
                if (isMounted.current) {
                    setPageIndex(0);
                }
            });
    }

    const handleProductDisplayFilterChange = event => {
        if (isMounted.current) {
            const display = event.target.value;
            search.brandId = null;
            search.display = display;
            search.pageSize = display === 'All' ? PAGE_SIZE : -1;

            if (isInProductFullSearchMode()) {
                search.search()
                    .then(() => {
                        if (isMounted.current) {
                            setPageIndex(0);
                        }
                    });
            } else {
                search.execute(1)
                    .then(() => {
                        if (isMounted.current) {
                            setPageIndex(0);

                            refreshTotalTimer.current = setTimeout(() => {
                                search.refreshTotal(true);
                            }, 50)
                        }
                    });
            }
        }
    }

    const handleServicePagePrevious = () => {
        if (getServicePage() > 1) {
            const newPageIndex = pageIndex - 1;
            setPageIndex(newPageIndex);
        }
    }

    const handleServicePageNext = () => {
        if (getServicePage() < getServicePageCount()) {
            const newPageIndex = pageIndex + 1;
            setPageIndex(newPageIndex);
        }
    }

    const handleProductPagePrevious = () => {
        if (getProductPage() > 1) {
            if (search.display === 'InStock') {
                const newPageIndex = pageIndex - 1;
                setPageIndex(newPageIndex);
            } else {
                search.execute((getProductPage() - 1));
            }
        }
    }

    const handleProductPageNext = () => {
        if (getProductPage() < getProductPageCount()) {
            if (search.display === 'InStock') {
                const newPageIndex = pageIndex + 1;
                setPageIndex(newPageIndex);
            } else {
                search.execute((getProductPage() + 1));
            }
        }
    }

    const handleProductSearchChange = (event, term) => {
        if (isMounted.current) {
            search.searchTerm = term;

            if (searchTimer.current) {
                clearTimeout(searchTimer.current);
            }

            if (isInProductFullSearchMode()) {
                if (listReady) {
                    setListReady(false);
                }

                search.search()
                    .then(() => {
                        if (isMounted.current) {
                            setListReady(true);
                        }
                    })
            } else if (!search.searchTerm || search.searchTerm.length === 0) {
                if (listReady) {
                    setListReady(false);
                }

                search.execute(1)
                    .then(() => {
                        if (isMounted.current) {
                            setListReady(true);
                            refreshTotalTimer.current = setTimeout(() => {
                                search.refreshTotal();
                            }, 50)
                        }
                    })
            }
        }
    }

    const handleProductSearchClear = event => {
        if (isMounted.current) {
            search.searchTerm = null;

            if (searchTimer.current) {
                clearTimeout(searchTimer.current);
            }

            if (listReady) {
                setListReady(false);
            }

            search.execute(1)
                .then(() => {
                    if (isMounted.current) {
                        setListReady(true);
                        setPageIndex(0);
                        refreshTotalTimer.current = setTimeout(() => {
                            search.refreshTotal();
                        }, 50)
                    }
                })
        }
    }

    const handleServiceSelect = (event, service) => {
        if (fn.isFunction(props.onSelect)) {
            const result = toJS(service);
            result.type = 'Service';
            props.onSelect(event, result);
        }
    }

    const handleProductClone = (event, product) => {
        if (product.data) {
            newProduct.initialize(product.typeId, product.brandId)
                .then(() => {
                    if (isMounted.current) {
                        newProduct.data = pdh.clone(product, newProduct.data);
                        newProduct.data.quantity = 0;
                        newProduct.data.referenceNumber = null;
                        newProduct.data.note = null;
                        newProduct.data.noteHtml = null;
                        newProduct.hasUnsavedChanges = true;
                    }
                });

            quickDrawer.activateQuickDrawer('product', 'create', null, handleProductCloneSuccess, handleProductCloneCancel)
                .then(drawer => {
                    if (isMounted.current) {
                        focusTimer.current = setTimeout(() => {
                            quickDrawerFocus(drawer);
                        }, 100);
                    }
                });
        }
    }

    const handleProductCloneSuccess = (result) => {
        if (result && result.isUpdated) {
            productDataGridRef.current.instance.refresh();

            api.Products.get(result.data.id)
                .then(({ data }) => {
                    handleProductSelect(null, data);
                })

            toast.dark(() => <p data-prd-cloned>Product cloned.</p>);
        }
        newProduct.clear();
    }

    const handleProductCloneCancel = () => {
        newProduct.clear();
    }

    const handleProductSelect = (event, product) => {
        if (fn.isFunction(props.onSelect)) {
            const result = toJS(product);
            const newSelected = [...selected];
            const quantity = product.defaultQuantity ? product.defaultQuantity : 1;

            result.type = 'Product';

            for (let i = 0; i < quantity; i++) {
                newSelected.push({
                    type: product.typeName,
                    id: product.id
                });
            }

            setSelected(newSelected);
            props.onSelect(event, result);
        }
    }

    const handleDataGridTooltip = (item) => {
        if (item && item.cellElement && item.cellElement.innerText) {
            item.cellElement.title = item.cellElement.innerText;
        }
    }

    const isInProductFullSearchMode = () => {
        return search.searchTerm && search.searchTerm.length > 2;
    }

    const getServicePage = () => {
        return (pageIndex + 1);
    }

    const getServicePageIndex = () => {
        return pageIndex;
    }

    const getServicePageCount = () => {
        return serviceSearch.total / PAGE_SIZE;
    }

    const getProductPage = () => {
        if (search.display === 'InStock') {
            return (pageIndex + 1);
        } else {
            return search.page;
        }
    }

    const getProductPageIndex = () => {
        if (search.display === 'InStock') {
            return pageIndex;
        } else {
            return search.pageIndex;
        }
    }

    const getProductPageCount = () => {
        if (search.display === 'InStock') {
            return search.total / PAGE_SIZE;
        } else {
            return search.pageCount;
        }
    }

    const renderContent = () => {
        switch (itemType) {
            case 'Service':
                return renderServiceContent();

            case 'Product':
                return renderProductContent();

            default:
                return;
        }
    }

    const renderServiceContent = () => {
        return <Observer>{() => <>
            {
                serviceSearch.isReady && listReady ? <>
                    <div className={'d-flex flex-column flex-1 border-left bg-white product-list-wrapper'}>
                        <div ref={filterRef} className='flex-grow-0 border border-top-0 border-left-0 border-right-0'>
                            <Observer>{() =>
                                <div
                                    className='d-flex flex-wrap align-items-center bg-gray-50 pl-3 pr-2 py-1'
                                >
                                    <div className={'text-muted fs-90 line-height-1 mr-1 mr-lg-3 ml-auto'}>
                                        {(getServicePageIndex() * PAGE_SIZE) + 1} - {((getServicePage() * PAGE_SIZE) < serviceSearch.total ? (getServicePage() * PAGE_SIZE) : serviceSearch.total)} <span className='hidden-lg-down'> of {serviceSearch.total} </span>
                                    </div>
                                    <div className={'flex-wrap d-flex'}>
                                        <button
                                            type='button'
                                            className='btn btn-icon'
                                            disabled={getServicePage() === 1}
                                            onClick={handleServicePagePrevious}
                                        >
                                            <i className={'fal fa-chevron-left fs-sm' + (getServicePage() === 1 ? ' text-gray-500' : '')}></i>
                                        </button>
                                        <button
                                            type='button'
                                            className='btn btn-icon'
                                            disabled={getServicePage() === getServicePageCount()}
                                            onClick={handleServicePageNext}
                                        >
                                            <i className={'fal fa-chevron-right fs-sm' + (getServicePage() === getServicePageCount() ? ' text-gray-500' : '')}></i>
                                        </button>
                                    </div>
                                </div>
                            }</Observer>
                        </div>
                        <div className='flex-1'>
                            <ScrollView width='100%' height='100%'>
                                {
                                    <DataGrid
                                        keyExpr='id'
                                        width={'1275px'}
                                        height='590px'
                                        activeStateEnabled={false}
                                        focusStateEnabled={false}
                                        hoverStateEnabled={false}
                                        dataSource={serviceSearch.services}
                                        showBorders={false}
                                        showRowLines={true}
                                        showColumnLines={false}
                                        allowColumnResizing={false}
                                        onCellPrepared={handleDataGridTooltip}
                                    >
                                        <Scrolling rowRenderingMode='standard'></Scrolling>
                                        <Sorting mode='single' />
                                        <Pager visible={false} />
                                        <Paging
                                            defaultPageSize={PAGE_SIZE}
                                            pageIndex={pageIndex}
                                        />
                                        <ColumnFixing enabled={true} />
                                        <Column
                                            dataField='id'
                                            caption=''
                                            alignment='center'
                                            width={30}
                                            cellRender={({ data }) => {
                                                return <>
                                                    {
                                                        selected && selected.some(s => s.id === data.id) ?
                                                            <span className={`badge bg-danger text-white text-shadow-0 mb-1`}>
                                                                {selected.filter(s => s.id === data.id).length}
                                                            </span> : null
                                                    }
                                                </>
                                            }}
                                        />
                                        <Column
                                            dataField='code'
                                            caption=''
                                            alignment='right'
                                            width={130}
                                            cellRender={({ data }) => {
                                                return <>
                                                    {bh.renderServiceCode(data, 'text-truncate text-truncate-xs')}
                                                </>
                                            }}
                                        />
                                        <Column
                                            dataField='name'
                                            caption=''
                                            cellRender={({ data }) => {
                                                return <>{data.name}</>
                                            }}
                                        />
                                        <Column
                                            dataField='Fee'
                                            alignment='right'
                                            width={110}
                                            fixed={true}
                                            fixedPosition='right'
                                            cellRender={({ data }) => {
                                                return <>
                                                    {fn.formatCurrency(data.fee)}
                                                </>
                                            }}
                                        />
                                        <Column
                                            alignment='center'
                                            width={75}
                                            fixed={true}
                                            fixedPosition='right'
                                            cssClass={'px-2'}
                                            cellRender={({ data }) => {
                                                return <button
                                                    className='btn btn-sm btn-info'
                                                    onClick={(e) => { handleServiceSelect(e, data) }}
                                                >
                                                    <i className='fas fa-chevron-right'></i>
                                                </button>
                                            }}
                                        />
                                    </DataGrid>
                                }
                            </ScrollView>
                        </div>
                    </div>
                </> : null
            }</>
        }</Observer>
    }

    const renderProductContent = () => {
        return <Observer>{() => <>
            {
                search.isReady && listReady ? <>
                    <div className={'flex-wrap position-relative bg-white selector-list border-left' + (isInProductFullSearchMode() ? ' d-none' : '')}>
                        <ScrollView width='100%' height='100%'>
                            <div className='position-absolute pos-top pos-bottom w-100'>
                                <div className='d-flex h-100 flex-column'>
                                    <div className='flex-1'>
                                        {
                                            search.selectedSelector ?
                                                <>
                                                    <button type='button'
                                                        className={'dropdown-item px-3 fs-sm d-flex justify-content-between border-top-left-radius-0 border-bottom-left-radius-0' + (!search.selectedBrand ? ' text-gray-700 font-weight-bold active' : ' text-gray-700')}
                                                        onClick={e => { handleProductBrandChange(e, { id: null }) }}
                                                    >
                                                        <div className='text-truncate text-truncate-sm'>All</div>
                                                        <Observer>{() => <>
                                                            {
                                                                search.selectedSelector.inventory === 'Physical' ?
                                                                    <div className='fw-400 fs-90'>({search.selectedSelector.totalQuantity ? search.selectedSelector.totalQuantity.toLocaleString('en-CA') : 0}<span className='px-o'>/</span>{search.selectedSelector.total ? search.selectedSelector.total.toLocaleString('en-CA') : 0})</div> :
                                                                    <div className='fw-400 fs-90'>({search.selectedSelector.total ? search.selectedSelector.total.toLocaleString('en-CA') : 0})</div>
                                                            }
                                                        </>}</Observer>
                                                    </button>
                                                    {
                                                        search.selectedSelector.brands.map((b, bi) => {
                                                            return <button
                                                                key={`selector-brand-${bi}`}
                                                                type='button'
                                                                className={'dropdown-item px-3 fs-sm d-flex justify-content-between border-top-left-radius-0 border-bottom-left-radius-0' + (search.selectedBrand && b.id === search.selectedBrand.id ? ' text-gray-700 font-weight-bold active' : ' text-gray-700')}
                                                                onClick={e => { handleProductBrandChange(e, b) }}
                                                            >
                                                                <Observer>{() => <>
                                                                    <div className='text-truncate text-truncate-sm'>{b.name}</div>
                                                                    {
                                                                        search.selectedSelector.inventory === 'Physical' ?
                                                                            <div className='fw-400 fs-90'>({b.quantity ? b.quantity.toLocaleString('en-CA') : 0}<span className='px-o'>/</span>{b.total ? b.total.toLocaleString('en-CA') : 0})</div> :
                                                                            <div className='fw-400 fs-90'>({b.total ? b.total.toLocaleString('en-CA') : 0})</div>
                                                                    }
                                                                </>}</Observer>
                                                            </button>
                                                        })
                                                    }
                                                </> : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </ScrollView>
                    </div>
                    <div className={'d-flex flex-column flex-1 border-left bg-white product-list-wrapper' + (search.display === 'All' ? ' display-all' : '')}>
                        <div ref={filterRef} className='flex-grow-0 border border-top-0 border-left-0 border-right-0'>
                            <Observer>{() =>
                                <div
                                    className='d-flex flex-wrap align-items-center bg-gray-50 pl-3 pr-2 py-1'
                                >
                                    {
                                        search.selectedSelector && search.selectedSelector.inventory === 'Physical' ?
                                            <div className='d-flex align-items-center'>
                                                <label className='m-0 mr-2 fs-90 fw-500 line-height-1 text-muted text-uppercase'>Show</label>
                                                <select
                                                    className='custom-select custom-select-sm form-control line-height show-filter'
                                                    value={search.display}
                                                    onChange={handleProductDisplayFilterChange}
                                                >
                                                    {
                                                        cache.getReferenceDataOptions('ProductFilterDisplayType').map((option, di) => {
                                                            return <option
                                                                key={`product_filter_display_type_${di}`}
                                                                value={option.key}
                                                            >
                                                                {option.value}
                                                            </option>
                                                        })
                                                    }
                                                </select>
                                            </div> : null
                                    }
                                    <div className='flex-1'></div>
                                    <div className={'text-muted fs-90 line-height-1 mr-1 mr-lg-3 ml-auto' + (isInProductFullSearchMode() ? ' d-none' : '')}>
                                        {(getProductPageIndex() * PAGE_SIZE) + 1} - {((getProductPage() * PAGE_SIZE) < search.total ? (getProductPage() * PAGE_SIZE) : search.total)} <span className='hidden-lg-down'> of {search.total} </span>
                                    </div>
                                    <div className={'flex-wrap' + (isInProductFullSearchMode() ? ' d-none' : ' d-flex')}>
                                        <button
                                            type='button'
                                            className='btn btn-icon'
                                            disabled={getProductPage() === 1}
                                            onClick={handleProductPagePrevious}
                                        >
                                            <i className={'fal fa-chevron-left fs-sm' + (getProductPage() === 1 ? ' text-gray-500' : '')}></i>
                                        </button>
                                        <button
                                            type='button'
                                            className='btn btn-icon'
                                            disabled={getProductPage() === getProductPageCount()}
                                            onClick={handleProductPageNext}
                                        >
                                            <i className={'fal fa-chevron-right fs-sm' + (getProductPage() === getProductPageCount() ? ' text-gray-500' : '')}></i>
                                        </button>
                                    </div>
                                </div>
                            }</Observer>
                        </div>
                        <div className='flex-1'>
                            <Observer>{() =>
                                <ScrollView width='100%' height='100%'>
                                    {
                                        <DataGrid
                                            ref={productDataGridRef}
                                            keyExpr='id'
                                            width={isInProductFullSearchMode() ? '1275px' : '1069px'}
                                            height='590px'
                                            activeStateEnabled={false}
                                            focusStateEnabled={false}
                                            hoverStateEnabled={false}
                                            dataSource={search.products}
                                            showBorders={false}
                                            showRowLines={true}
                                            showColumnLines={false}
                                            allowColumnResizing={true}
                                            onCellPrepared={handleDataGridTooltip}
                                        >
                                            <Scrolling rowRenderingMode='standard'></Scrolling>
                                            <Sorting mode='single' />
                                            <Pager visible={false} />
                                            <Paging
                                                defaultPageSize={PAGE_SIZE}
                                                pageIndex={pageIndex}
                                            />
                                            <ColumnFixing enabled={true} />
                                            {
                                                search.selectedSelector.isStocked ?
                                                    <Column
                                                        dataField='quantity'
                                                        caption='Qty'
                                                        fixed={true}
                                                        width={100}
                                                        alignment='center'
                                                        cellRender={({ data }) => {
                                                            return <>
                                                                {
                                                                    selected && selected.some(s => s.id === data.id) ?
                                                                        <span className={`badge bg-danger text-white text-shadow-0 mb-1`}>
                                                                            {selected.filter(s => s.id === data.id).length}
                                                                        </span> :
                                                                        data.isTracked ?
                                                                            <span className={data.quantity <= 0 ? 'text-danger' : ''}>{data.quantity}</span> : <i className='fal fa-infinity'></i>
                                                                }
                                                            </>
                                                        }}
                                                    /> :
                                                    selected && selected.some(s => s.type === search.selectedSelector.name) ?
                                                        <Column
                                                            dataField='selected'
                                                            caption=''
                                                            fixed={true}
                                                            alignment='center'
                                                            width={40}
                                                            cellRender={({ data }) => {
                                                                return <>
                                                                    {
                                                                        selected && selected.some(s => s.id === data.id) ?
                                                                            <span className={`badge bg-danger text-white text-shadow-0 mb-1`}>
                                                                                {selected.filter(s => s.id === data.id).length}
                                                                            </span> : null
                                                                    }
                                                                </>
                                                            }}
                                                        /> : null
                                            }
                                            {
                                                !search.selectedBrand ?
                                                    <Column
                                                        dataField='brandName'
                                                        caption='Brand'
                                                        fixed={true}
                                                        minWidth={125}
                                                        cellRender={({ data }) => {
                                                            return <>
                                                                {data.brandName}
                                                                <small className='d-block text-truncate text-gray-700'>{data.supplierName}</small>
                                                            </>
                                                        }}
                                                    /> : null
                                            }
                                            <Column
                                                dataField='name'
                                                caption='Descriptor'
                                                fixed={true}
                                                minWidth={250}
                                                cellRender={({ data }) => {
                                                    return <>
                                                        {data.name}
                                                        <small className='d-block text-truncate text-gray-700'>{data.descriptor}</small>
                                                    </>
                                                }}
                                            />
                                            {
                                                search.selectedSelector.data.map((t, ti) => {
                                                    return <Column
                                                        key={`product-item-${ti}-data`}
                                                        caption={t.name}
                                                        alignment='center'
                                                        width={100}
                                                        allowSorting={true}
                                                        cellRender={({ data }) => {
                                                            const item = data.data.filter(d => d.key === t.key)[0];
                                                            return (item ? item.value : '');
                                                        }}
                                                        calculateSortValue={(data) => {
                                                            const item = data.data.filter(d => d.key === t.key)[0];
                                                            return (item ? item.value : '');
                                                        }}
                                                    />
                                                })
                                            }
                                            <Column
                                                dataField='price'
                                                alignment='right'
                                                width={110}
                                                fixed={true}
                                                fixedPosition='right'
                                                cellRender={({ data }) => {
                                                    return <>
                                                        {fn.formatCurrency(data.price)}
                                                    </>
                                                }}
                                            />
                                            <Column
                                                alignment='center'
                                                width={120}
                                                fixed={true}
                                                fixedPosition='right'
                                                cssClass={'px-2'}
                                                cellRender={({ data }) => {
                                                    return <>
                                                        {
                                                            ah.check(AccessType.UPDATE_PRODUCT) ?
                                                                <button
                                                                    className='btn btn-sm btn-icon mr-2'
                                                                    onClick={(e) => { handleProductClone(e, data) }}
                                                                >
                                                                    <i className='fal fa-copy fs-lg'></i>
                                                                </button> : null
                                                        }
                                                        <button
                                                            className='btn btn-sm btn-info'
                                                            onClick={(e) => { handleProductSelect(e, data) }}
                                                        >
                                                            <i className='fas fa-chevron-right'></i>
                                                        </button>
                                                    </>
                                                }}
                                            />
                                        </DataGrid>
                                    }
                                </ScrollView>
                            }</Observer>
                        </div>
                    </div>
                </> : <div className='d-flex w-100 h-100 justify-content-center align-items-center'>
                    <div className='spinner-container'>
                        <div className='spinner-horizontal'>
                            <div className='spinner'></div>
                        </div>
                    </div>
                </div>
            }
        </>
        }</Observer>
    }

    return <>
        <div className='popup-title'>
            {
                itemType === 'Product' ?
                    <div className='actions left-actions'>
                        <ul>
                            <li>
                                <FilterProducts delay={500} onChange={handleProductSearchChange} onClear={handleProductSearchClear} />
                            </li>
                        </ul>
                    </div> : null
            }
            <div className='actions right-actions'>
                <ul>
                    <li>
                        <button
                            type='button'
                            className='btn btn-success btn-close mr-2'
                            onClick={props.onClose}
                        >
                            Done
                        </button>
                    </li>
                </ul>
            </div>
        </div>
        <div className='popup-body'>
            <div className='product-selector-container h-100 d-flex flex-column'>
                <Observer>{() => <>
                    <ul
                        ref={tabRef}
                        className='nav nav-tabs nav-tabs-clean'
                        role='tablist'
                    >
                        {
                            ah.check(AccessType.VIEW_PRODUCT) && search.isReady && search.selectors && search.selectors.length > 0 ?
                                search.selectors.map((s, si) => {
                                    return <li
                                        key={`selector-product-type-${si}`}
                                        className='nav-item'
                                    >
                                        <button
                                            type='button'
                                            className={'nav-link bg-transparent fs-sm' + (itemType === 'Product' && search.selectedSelector && search.selectedSelector.id === s.id ? ' text-primary font-weight-bold active' : ' fw-400 text-muted')}
                                            data-toggle='tab'
                                            role='tab'
                                            onClick={e => { handleSelectorChange(e, 'Product', s) }}
                                        >
                                            {s.name}
                                            {
                                                selected && selected.some(d => d.type === s.name) ?
                                                    <span className={`badge badge-solid-danger text-shadow-0 badge-dot float-left mr-1 ml-n2`}></span> : null
                                            }
                                        </button>
                                    </li>
                                }) : null
                        }
                        {
                            ah.check(AccessType.VIEW_SERVICE) && serviceSearch.isReady && serviceSearch.services && serviceSearch.services.length > 0 ?
                                <li
                                    className='nav-item'
                                >
                                    <button
                                        type='button'
                                        className={'nav-link bg-transparent fs-sm' + (itemType === 'Service' ? ' text-primary font-weight-bold active' : ' fw-400 text-muted')}
                                        data-toggle='tab'
                                        role='tab'
                                        onClick={e => { handleSelectorChange(e, 'Service') }}
                                    >
                                        Services
                                    </button>
                                </li> : null
                        }
                    </ul>
                </>}</Observer>
                <div className='d-flex flex-1 p-0 overflow-y-hidden'>
                    {renderContent()}
                </div>
            </div>
        </div>
    </>
}

export default ViewProducts;