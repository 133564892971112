import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePageVisibility } from 'react-page-visibility';
import moment from 'moment';

import useSignalR from '../../hooks/useSignalR';

import * as rts from '../../../constants/routes';
import api from '../../../api';

function NavbarCommunication() {
    const isMounted = useRef(true);
    const isPageVisible = useRef(null);
    const signalR = useSignalR();
    const navigate = useNavigate();
    const pageVisibility = usePageVisibility();
    const [failedCommunicationCount, setFailedCommunicationCount] = useState(0);
    const [forceReload, setForceReload] = useState(false);

    useEffect(() => {
        loadData();

        signalR.on('Communication', async (updated) => {
            if (updated && (updated.status === 'Failed' || updated.status === 'Hidden')) {
                if (isPageVisible.current) {
                    loadFailedCommunications();
                } else if (isMounted.current) {
                    setForceReload(true);
                }
            }
        });

        return () => {
            isMounted.current = false;
        }

    }, []) // eslint-disable-line

    useEffect(() => {
        if (pageVisibility && forceReload) {
            loadFailedCommunications()
                .then(() => {
                    if (isMounted.current) {
                        setForceReload(false);
                    }
                })
        }
        isPageVisible.current = pageVisibility;
    }, [pageVisibility]) // eslint-disable-line

    const loadData = () => {
        loadFailedCommunications();
    }

    const loadFailedCommunications = () => {
        return new Promise((resolve) => {
            api.Communications.search(
            {
                parameters: [
                    { field: 'Status', value: 'Failed' },
                    { field: 'Direction', value: 'Outbound' },
                    { field: 'DeactivatedDateUtc', value: null },
                    { field: 'FailedDateUtc', operator: '>', value: moment().startOf('day').add(-30, 'days') }
                ],
                includeTotalCount: true,
                includeResult: false,
            })
            .then(({ data }) => {
                if (isMounted.current) {
                    setFailedCommunicationCount((data && data.total ? data.total : 0));
                }
            })
            .finally(() => {
                resolve();
            })
        });
    }

    const handleCommunicationLogClick = () => {
        navigate(rts.Communications.Home);
    }

    return <>
        <div className={'d-flex align-items-center justify-content-center ml-2 pt-h'}>
            <button
                type='button'
                className='btn btn-icon header-icon'
                title='Communication log'
                onClick={handleCommunicationLogClick}
            >
                <i className='fal fa-paper-plane'></i>
                {
                    failedCommunicationCount && failedCommunicationCount > 0 ?
                        <span className='badge badge-icon bg-danger'>{failedCommunicationCount}</span> : null
                }
            </button>
        </div>
    </>
}

export default NavbarCommunication;