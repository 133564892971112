import React, { useEffect, useRef, useState } from 'react';

import * as fn from '../../../../../utilities/_functions';

function FilterProducts(props) {
    const isMounted = useRef(true);
    const searchTimer = useRef(null);
    const delay = useRef(null);
    const [searchTerm, setSearchTerm] = useState(null);

    useEffect(() => {
        delay.current = props.delay ? props.delay : 2000;

        return () => {
            isMounted.current = false;
            delay.current = null;
        }
    }, []) // eslint-disable-line

    const handleChange = event => {
        if (isMounted.current) {
            const term = event.target.value;

            setSearchTerm(term);

            if (searchTimer.current) {
                clearTimeout(searchTimer.current);
            }

            searchTimer.current = setTimeout(() => {
                if (fn.isFunction(props.onChange)) {
                    props.onChange(event, term);
                }
            }, delay.current);
        }
    }

    const handleClear = event => {
        if (fn.isFunction(props.onClear)) {
            setSearchTerm(null);
            props.onClear(event);
        }
    }

    return <>
        <div className='d-flex position-relative ml-auto mt-n2' style={{ zIndex: 15 }}>
            <i className='fal fa-search position-absolute pos-left fs-lg px-3 py-2 mt-1'></i>
            <input
                type='text'
                className='form-control pl-7 pr-6 control-focus-stretch'
                placeholder='Filter products'
                autoComplete='off'
                spellCheck={false}
                value={searchTerm ? searchTerm : ''}
                onChange={handleChange}
            />
            <button
                type='button'
                className={'btn btn-link p-0 position-absolute pos-right fs-lg px-3 py-2'}
                onClick={handleClear}
            >
                <i className='fal fa-times'></i>
            </button>
        </div>
    </>
}

export default FilterProducts;