import { createContext } from 'react';
import { decorate, observable, action, toJS, computed } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class MigratePaperExam {
    data = {
        customerId: null,
        userId: null,
        appointmentId: null,
        date: null,
        time: null,
        duration: null,
        services: [],
        file: null,
    };
    customer = null;
    appointments = [];
    services = [];
    isReady = false;
    isSaving = false;
    isLoading = false;
    hasUnsavedChanges = false;

    cancelMigrate = null;
    cancelServices = null;
    cancelAppointmentSearch = null;

    initialize = (customer) => {
        const that = this;

        this.clear();
        this.customer = customer;
        this.data.customerId = customer.id;
        this.data.duration = 30;
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            api.Services.all(true, (c) => { that.cancelServices = c })
                .then(({ data }) => {
                    that.services = data && data.length > 0 ? data : [];
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isReady = true;
                    that.isLoading = false;
                })
        })
    }

    getAppointments = (notify) => {
        const that = this;

        if (!!notify) {
            this.isLoading = true;
        }

        return new Promise((resolve, reject) => {
            api.Appointments.search(
                {
                    parameters: [
                        {
                            field: 'CustomerId',
                            value: that.data.customerId,
                        },
                        {
                            field: 'Start',
                            value: that.data.date.clone().startOf('day').format('YYYY-MM-DD'),
                            operator: '>='
                        },
                        {
                            field: 'Start',
                            value: that.data.date.clone().add(1, 'days').startOf('day').format('YYYY-MM-DD'),
                            operator: '<'
                        },
                        {
                            field: 'Status',
                            value: 'Completed',
                            operator: 'Contains'
                        },
                    ],
                },
                (c) => { that.cancelAppointmentSearch = c }
            )
                .then(({ data }) => {
                    that.appointments = data && data.result && data.result.length > 0 ? data.result : [];

                    if (that.appointments.length > 0) {
                        that.data.appointmentId = that.appointments[0].id;
                        that.data.userId = that.appointments[0].userId;
                    }

                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isLoading = false;
                })
        })
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            if (that.hasUnsavedChanges) {
                const option = toJS(that.data);

                option.services = option.services && option.services.length > 0 ? option.services.map(s => { return s.id }) : [];

                api.Migrate.paperExam(option, (c) => { that.cancelMigrate = c })
                    .then(({ data }) => {
                        that.data.id = data.id;
                        that.customer.notes.push(that.data);
                        resolve(toJS(that.customer.notes));
                    })
                    .catch(() => {
                        reject();
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                that.isSaving = false;
                resolve();
            }
        })
    }

    clear = () => {
        this.data.customerId = null;
        this.data.userId = null;
        this.data.appointmentId = null;
        this.data.date = null;
        this.data.time = null;
        this.data.duration = null;
        this.data.services.clear();
        this.data.file = null;
        this.customer = null;
        this.services.clear();
        this.appointments.clear();
        this.isReady = false;
        this.isSaving = false;
        this.isLoading = false;
        this.hasUnsavedChanges = false;

        if (fn.isFunction(this.cancelMigrate)) {
            this.cancelMigrate();
            this.cancelMigrate = null;
        }

        if (fn.isFunction(this.cancelServices)) {
            this.cancelServices();
            this.cancelServices = null;
        }

        if (fn.isFunction(this.cancelAppointmentSearch)) {
            this.cancelAppointmentSearch();
            this.cancelAppointmentSearch = null;
        }
    }

    get recommendedDuration() {
        return this.data.services.map(s => { return (s.duration ? s.duration : 0) }).reduce((a, b) => a + b, 0);
    }
}

decorate(MigratePaperExam, {
    data: observable.deep,
    customer: observable.deep,
    services: observable,
    appointments: observable,
    isReady: observable,
    isSaving: observable,
    isLoading: observable,
    hasUnsavedChanges: observable,
    initialize: action,
    getAppointments: action,
    save: action,
    clear: action,
    recommendedDuration: computed,
})

export default createContext(new MigratePaperExam());