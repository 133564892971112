import React, { Component, Fragment } from 'react';
import StackTrace from "stacktrace-js";
import uuid from 'react-uuid';

import { appInsights } from '../../../utilities/appInsights';
import { CURRENT_USER } from '../../../constants/storageKeys';
import * as rts  from '../../../constants/routes';

export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reference: null,
            error: null,
            errorInfo: null,
            newSourceCode: false,
        };
    }

    componentDidCatch(error, errorInfo) {
        const that = this;

        if (error && (error.name === 'ChunkLoadError' || error.toString().startsWith('ChunkLoadError'))) {
            that.setState({
                reference: null,
                error: error,
                errorInfo: errorInfo,
                newSourceCode: true,
            })
        }
        else {
            StackTrace.fromError(error)
                .then(err => {
                    const user = JSON.parse(window.localStorage.getItem(CURRENT_USER));

                    if (user) {
                        const reference = uuid();

                        that.setState({
                            reference: reference,
                            error: error,
                            errorInfo: err,
                            newSourceCode: false,
                        })

                        appInsights.trackEvent({
                            name: 'ErrorBoundary',
                            properties: {
                                reference: reference,
                                user: user,
                                url: window.location.href,
                                error: error && error.toString(),
                                errorInfo: err,
                            }
                        })

                        console.log(err);
                    } else {
                        window.location.href = rts.Logout;
                    }
                })
                .catch((errorReason) => {
                    console.error(errorReason);
                })
        }
    }

    render() {
        if (this.state.errorInfo) {
            if (!!this.state.newSourceCode) {
                return (
                    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <div>
                            <h2 style={{ fontSize: '32px', fontWeight: 'bold' }}>HelloGenki has been updated.</h2>
                            <p style={{ fontSize: '16px' }}>It looks like the application has been updated, <strong>please refresh your browser to see the latest content.</strong> :)</p>

                            <div style={{ marginBottom: '16px' }}>
                                <button
                                    type='button'
                                    style={{
                                        display: 'inline-block',
                                        fontWeight: '500',
                                        color: '#fff',
                                        textAlign: 'center',
                                        verticalAlign: 'middle',
                                        userSelect: 'none',
                                        backgroundColor: '#1dc9b7',
                                        border: '1px solid #1dc9b7',
                                        padding: '0.8rem 1.6rem',
                                        fontSize: '1.1rem',
                                        lineHeight: '1.47',
                                        marginRight: '10px'
                                    }}
                                    onClick={() => {
                                        window.location.reload();
                                    }}
                                >
                                    Refresh
                                </button>
                                <a
                                    href='/logout'
                                    style={{
                                        display: 'inline-block',
                                        fontWeight: '500',
                                        color: '#888',
                                        textAlign: 'center',
                                        verticalAlign: 'middle',
                                        userSelect: 'none',
                                        backgroundColor: 'transparent',
                                        border: '1px solid transparent',
                                        padding: '0.8rem 1.6rem',
                                        fontSize: '1.1rem',
                                        lineHeight: '1.47'
                                    }}
                                >
                                    Log out
                                </a>
                            </div>
                        </div>
                    </div>);
            } else {
                return (
                    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <div>
                            <h2 style={{ fontSize: '32px', fontWeight: 'bold' }}>Something wrong here...</h2>
                            <p style={{ fontSize: '16px', color: '#aaa', fontWeight: 'bold' }}>Ref: {this.state.reference}</p>
                            <p style={{ fontSize: '16px' }}>Sorry, we're having some technical issues (as you can see).  Our technical team has been notified, but try again, sometime works :)</p>
                            <div style={{ marginBottom: '16px' }}>
                                <button
                                    type='button'
                                    style={{
                                        display: 'inline-block',
                                        fontWeight: '500',
                                        color: '#fff',
                                        textAlign: 'center',
                                        verticalAlign: 'middle',
                                        userSelect: 'none',
                                        backgroundColor: '#1dc9b7',
                                        border: '1px solid #1dc9b7',
                                        padding: '0.8rem 1.6rem',
                                        fontSize: '1.1rem',
                                        lineHeight: '1.47',
                                        marginRight: '10px'
                                    }}
                                    onClick={() => {
                                        window.location.reload();
                                    }}
                                >
                                    Refresh
                                </button>
                                <a
                                    href='/logout'
                                    style={{
                                        display: 'inline-block',
                                        fontWeight: '500',
                                        color: '#888',
                                        textAlign: 'center',
                                        verticalAlign: 'middle',
                                        userSelect: 'none',
                                        backgroundColor: 'transparent',
                                        border: '1px solid transparent',
                                        padding: '0.8rem 1.6rem',
                                        fontSize: '1.1rem',
                                        lineHeight: '1.47'
                                    }}
                                >
                                    Log out
                                </a>
                            </div>
                            <details style={{ whiteSpace: 'pre-wrap' }}>
                                {this.state.error && this.state.error.toString()}
                                <br />
                                {this.state.errorInfo && this.state.errorInfo.length > 0 ?
                                    this.state.errorInfo.map((e, ei) => {
                                        return <Fragment key={`error_boundary_fatal_${ei}`}>{e.toString()}<br /></Fragment>
                                    }) : null
                                }
                            </details>
                        </div>
                    </div>);
            }
        } else {
            return this.props.children;
        }
    }
}