import { createContext } from 'react';
import { decorate, observable, action, computed, toJS } from 'mobx';
import moment from 'moment';

import { getNewWorkOrder, getNewWorkOrderItem } from './WorkOrderCreateStore';

import api from '../api';
import * as StorageKeys from '../constants/storageKeys';
import * as fn from '../utilities/_functions';
import * as ph from '../utilities/personHelper';

export class PurchaseUpdateStore {
    id = null;
    cachedData = null;
    data = {
        id: null,
        createdById: null,
        createdBy: null,
        createdDateUtc: null,
        lastUpdatedDateUtc: null,
        lastAnyUpdatedDateUtc: null,
        completedDateUtc: null,
        deactivatedDateUtc: null,
        referenceId: null,
        customer: null,
        customerId: null,
        customerBasicModel: null,
        customerName: null,
        customerEmail: null,
        customerPhoneNumber: null,
        customerPreviousExam: null,
        billingAddress: null,
        shippingAddress: null,
        userId: null,
        user: null,
        number: null,
        transactions: [],
        transactionItemTypes: [],
        orderDiscountDescriptor: null,
        orderDiscountRate: null,
        orderDiscountAmount: null,
        isOrderCustomDiscount: false,
        price: 0.0,
        discountAmount: 0.0,
        subTotal: 0.0,
        subsidizedTotal: 0.0,
        taxCode1: null,
        taxRate1: null,
        taxAmount1: 0.0,
        taxCode2: null,
        taxRate2: null,
        taxAmount2: 0.0,
        taxCode3: null,
        taxRate3: null,
        taxAmount3: 0.0,
        totalTaxRates: null,
        totalTaxCodes: null,
        totalTaxAmount: 0.0,
        total: 0.0,
        totalPayable: 0.0,
        totalPaid: 0.0,
        remainingBalance: 0.0,
        insuranceInformation: null,
        toBeCollectedAmount: 0.0,
        isVoided: false,
        isWaitingToBeCollected: false,
        isPaidInFull: false,
        isNoCharge: false,
        dueDate: null,
        paidInFullDate: null,
        lastPayment: null,
        lastPaymentDate: null,
        isPayablePaidInFull: false,
        status: null,
        invoiceNumber: null,
        paymentReceiptNumber: null,
        workOrders: [],
        payments: [],
        items: [],
        referenceTypes: [],
        note: null,
        noteHtml: null,
    };
    licensedUsers = null;
    refundPaymentId = null;
    refundPayments = [];
    refundAmount = null;
    refundDate = null;
    refundMethod = null;
    refundReason = null;
    refundReasonHtml = null;
    voidReason = null;
    voidReasonHtml = null;
    noChargeReason = null;
    noChargeReasonHtml = null;
    uncollectibleReason = null;
    uncollectibleReasonHtml = null;
    verifyReturnItems = [];
    returnDestinations = [];
    expiringWorkOrders = [];
    newPaymentNote = null;
    newPaymentNoteHtml = null;
    newPaymentAmount = null;
    newPaymentDate = null;
    newPaymentMethod = null;
    newPaymentPrivateInsurancePolicyId = null;
    newPaymentGovernmentProgramId = null;
    insurancePaymentId = null;
    insuranceInformation = null;
    insuranceAmount = null;
    insurancePaymentDate = null;
    insuranceNote = null;
    insuranceNoteHtml = null;
    governmentProgramPaymentId = null;
    governmentProgramInformation = null;
    governmentProgramAmount = null;
    governmentProgramPaymentDate = null;
    governmentProgramNote = null;
    governmentProgramNoteHtml = null;
    removePaymentId = null;
    removeAmount = null;
    removePaymentMethod = null;
    removeReason = null;
    removeReasonHtml = null;
    isLoading = false;
    isSaving = false;
    isReady = false;
    isCustomerLoading = false;
    hasUnsavedChanges = false;

    cancelPaymentCreate = null;
    cancelPaymentDelete = null;
    cancelPaymentInsurance = null;
    cancelPaymentGovernmentProgram = null;
    cancelPaymentRefund = null;
    cancelPurchaseComplete = null;
    cancelPurchaseCreate = null;
    cancelPurchaseDelete = null;
    cancelPurchaseGet = null;
    cancelPurchaseNoCharge = null;
    cancelPurchaseUncollectible = null;
    cancelPurchaseRefund = null;
    cancelPurchaseStart = null;
    cancelPurchaseUpdate = null;
    cancelPurchaseVoid = null;
    cancelLicensedUsers = null;
    cancelPurchaseUpdateCost = null;

    start = (customer, user) => {
        const that = this;

        that.clear();
        that.data.id = fn.newId('pc_');
        that.data.invoiceDate = moment().startOf('day');
        that.data.dueDate = moment().startOf('day');
        that.data.status = 'Incomplete';

        if (customer) {
            that.setCustomer(customer);
        }

        this.addDefaultTransactionItem();

        return new Promise((resolve) => {
            if (!that.isReady) {
                that.loadLicensedUsers(user)
                    .finally(() => {
                        that.isReady = true;
                        resolve();
                    })
            } else {
                resolve();
            }
        })
    }

    load = id => {
        if (id === this.id) return Promise.resolve();

        const that = this;
        this.clear();
        this.id = id;
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            api.Purchases.get(id, (c) => { that.cancelPurchaseGet = c })
                .then(({ data }) => {
                    that.data = data;
                    that.cachedData = JSON.stringify(data);
                    that.loadLicensedUsers(data.user, (data.reference ? data.reference.userId : null))
                        .finally(() => {
                            that.isLoading = false;
                            that.isReady = true;

                            resolve();
                        })
                })
                .catch(() => {
                    reject();
                })
        })
    }

    reload = () => {
        if (!this.id) return Promise.resolve();

        const id = this.id;
        this.id = null;

        return this.load(id);
    }

    loadLicensedUsers = (defaultUser, appointmentUserId) => {
        const that = this;
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            api.TenantUsers.search({
                parameters: [{
                    field: 'LicenseNumber',
                    value: null,
                    operator: '!=',
                }, {
                    field: 'AcceptBooking',
                    value: true,
                }],
                sortByFields: [{ field: 'FirstName' }, { field: 'LastName' }],
                includeTotalCount: false,
            }, (c) => { that.cancelLicensedUsers = c })
                .then(({ data }) => {
                    const licensedUsers = [];

                    licensedUsers.push({});

                    if (defaultUser && data.result.some(r => r.id === defaultUser.id)) {
                        licensedUsers.push(...data.result.filter(r => !r.isDeactivated || r.id === defaultUser.id));
                        that.data.user = defaultUser;
                        that.data.userId = defaultUser.id;
                    } else {
                        licensedUsers.push(...data.result.filter(r => !r.isDeactivated));
                    }

                    if (appointmentUserId && !licensedUsers.some(u => u.id === appointmentUserId) && data.result.some(r => r.id === appointmentUserId)) {
                        licensedUsers.push(...data.result.filter(r => r.id === appointmentUserId));
                    }

                    that.licensedUsers = licensedUsers;
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isLoading = false;
                })
        })
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            let option = toJS(that.data);
            option.items = toJS(that.validItems);

            if (that.hasUnsavedChanges) {
                if (that.isNew) {
                    api.Purchases.create(option, (c) => { that.cancelPurchaseCreate = c })
                        .then(({ data }) => {
                            that.hasUnsavedChanges = false;
                            that.id = data.id;
                            that.isSaving = false;
                            that.cachedData = JSON.stringify(that.data);
                            resolve({ id: data.id });
                        })
                        .catch(error => {
                            that.isSaving = false;
                            reject(error);
                        })
                } else {
                    option.returnDestinations = that.returnDestinations;
                    api.Purchases.update(that.id, option, (c) => { that.cancelPurchaseUpdate = c })
                        .then(({ data }) => {
                            if (data.requiredVerification) {
                                that.verifyReturnItems = data.verifyResult;
                                that.returnDestinations.clear();
                                that.isSaving = false;
                                resolve({
                                    id: that.id,
                                    requiredVerification: data.requiredVerification
                                });
                            } else {
                                that.replaceWorkOrders()
                                    .then(() => {
                                        that.hasUnsavedChanges = false;
                                        resolve({
                                            id: that.id,
                                            requiredVerification: false
                                        });
                                    })
                                    .finally(() => {
                                        that.isSaving = false;
                                    })
                            }
                            that.cachedData = JSON.stringify(that.data);
                        })
                        .catch(error => {
                            that.isSaving = false;
                            reject(error);
                        })
                }
            } else {
                that.hasUnsavedChanges = false;
                that.isSaving = false;
                resolve();
            }
        })
    }

    replaceWorkOrders = () => {
        const that = this;

        if (this.hasExpiringWorkOrders) {
            return new Promise((resolve, reject) => {
                api.Purchases.get(that.id, (c) => { that.cancelPurchaseGet = c })
                    .then(({ data: updatedData }) => {
                        const requests = [];

                        for (let i = 0; i < that.expiringWorkOrders.length; i++) {
                            const previousWorkOrder = that.data.workOrders.filter(wo => wo.id === that.expiringWorkOrders[i].id)[0];
                            const newWorkOrder = getNewWorkOrder(that.data.customerId, that.data.id);

                            for (let ii = 0; ii < previousWorkOrder.items.length; ii++) {
                                const existingTransactionItems = that.data.items.filter(ti => previousWorkOrder.items[ii].transactionItemIds.some(t => t === ti.id) && !that.expiringWorkOrders[i].items.some(e => e.previous.groupId === ti.groupId));
                                const updateTransactionItems = updatedData.items.filter(ti => that.expiringWorkOrders[i].items.some(e => e.new && e.new.groupId === ti.groupId));

                                if (existingTransactionItems && existingTransactionItems.length > 0) {
                                    for (let iii = 0; iii < existingTransactionItems.length; iii++) {
                                        const newWorkOrderItem = getNewWorkOrderItem(existingTransactionItems[iii]);

                                        newWorkOrderItem.workOrderPublishedTemplateId = previousWorkOrder.items[ii].workOrderPublishedTemplateId;
                                        newWorkOrderItem.displayOrder = previousWorkOrder.items[ii].displayOrder;
                                        newWorkOrderItem.product = null;
                                        newWorkOrder.items.push(newWorkOrderItem);
                                    }
                                }

                                if (updateTransactionItems && updateTransactionItems.length > 0) {
                                    for (let iiii = 0; iiii < updateTransactionItems.length; iiii++) {
                                        const previousWorkOrderItem = previousWorkOrder.items.filter(woi => woi.transactionItemIds.some(ti => that.expiringWorkOrders[i].items.filter(ei => !ei.new || ei.new.groupId === updateTransactionItems[iiii].groupId).some(ei => ei.previous.id === ti)))[0];

                                        if (!newWorkOrder.items.some(woi => woi.referenceId === updateTransactionItems[iiii].referenceId)) {
                                            const newWorkOrderItem = getNewWorkOrderItem(updateTransactionItems[iiii]);

                                            newWorkOrderItem.workOrderPublishedTemplateId = previousWorkOrderItem.workOrderPublishedTemplateId;
                                            newWorkOrderItem.displayOrder = previousWorkOrderItem.displayOrder;
                                            newWorkOrderItem.product = null;
                                            newWorkOrder.items.push(newWorkOrderItem);
                                        }
                                    }
                                }
                            }

                            requests.push(new Promise((resolve1, reject1) => {
                                if (newWorkOrder && newWorkOrder.items && newWorkOrder.items.length > 0) {
                                    return api.WorkOrders.create(newWorkOrder)
                                        .then(() => {
                                            api.WorkOrders.get(previousWorkOrder.id)
                                                .then(({ data: previousWorkOrderData }) => {
                                                    Promise.all([
                                                        api.WorkOrders.copy(newWorkOrder.id, { fromWorkOrderId: previousWorkOrder.id }),
                                                        api.WorkOrders.link(newWorkOrder.id, { selectedIds: [previousWorkOrder.id], reasonHtml: '<p>Invoice updated</p>' }),
                                                        api.WorkOrders.link(newWorkOrder.id, { selectedIds: (previousWorkOrderData.relatedWorkOrders && previousWorkOrderData.relatedWorkOrders.length > 0 ? previousWorkOrderData.relatedWorkOrders.map(r => { return r.id }) : []), reasonHtml: '<p>Same invoice</p>' }),
                                                        api.WorkOrders.status(previousWorkOrder.id, { status: 'Expired', note: 'Invoice updated', noteHtml: '<p>Invoice updated</p>' })
                                                    ])
                                                        .then(() => {
                                                            resolve1();
                                                        })
                                                        .catch(error => {
                                                            reject1(error);
                                                        })
                                                })
                                                .catch(error => {
                                                    reject1(error);
                                                })
                                        })
                                        .catch(error => {
                                            reject1(error);
                                        })
                                } else {
                                    api.WorkOrders.status(previousWorkOrder.id, { status: 'Expired', note: 'Invoice updated', noteHtml: '<p>Invoice updated</p>' })
                                        .then(() => {
                                            resolve1();
                                        })
                                        .catch(error => {
                                            reject1(error);
                                        })
                                }
                            }));
                        }

                        Promise.all(requests)
                            .then(() => {
                                that.expiringWorkOrders.clear();
                                resolve();
                            })
                            .catch(error => {
                                reject(error);
                            })
                    })
            })
        }
        else {
            return Promise.resolve();
        }
    }

    delete = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            api.Purchases.delete(that.id, (c) => { that.cancelPurchaseDelete = c })
                .then(() => {
                    resolve();
                })
                .catch(error => {
                    reject(error);
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    complete = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            api.Purchases.complete(that.id, (c) => { that.cancelPurchaseComplete = c })
                .then(() => {
                    that.data.status = 'Completed';
                    resolve(that.id);
                })
                .catch(error => {
                    reject(error);
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    saveUnitCost = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            if (that.hasUnsavedChanges) {
                const options = {
                    transactionItemCosts: that.data.items.map(i => { return { ids: i.ids, unitCost: i.unitCost } })
                };

                api.Purchases.updateCost(that.id, options, (c) => { that.cancelPurchaseUpdateCost = c })
                    .then(() => {
                        resolve(that.id);
                    })
                    .catch(error => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                that.hasUnsavedChanges = false;
                that.isSaving = false;
                resolve();
            }
        });
    }

    refundPurchase = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            that.verifyReturnItems.clear();

            if (that.hasUnsavedChanges) {
                api.Purchases.refund(
                    that.id,
                    {
                        refundPayments: that.refundPayments,
                        note: {
                            noteType: 'Purchase',
                            preview: that.refundReason,
                            bodyHtml: that.refundReasonHtml,
                        },
                        returnDestinations: that.returnDestinations
                    },
                    (c) => { that.cancelPurchaseRefund = c }
                )
                    .then(({ data }) => {
                        if (data.requiredVerification) {
                            that.verifyReturnItems = data.verifyResult;
                            that.returnDestinations.clear();
                            resolve({
                                id: that.id,
                                requiredVerification: data.requiredVerification
                            });
                        } else {
                            that.hasUnsavedChanges = false;
                            that.clearRefund();
                            that.returnDestinations.clear();
                            resolve({
                                id: that.id,
                                requiredVerification: false
                            });
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                that.hasUnsavedChanges = false;
                that.isSaving = false;
                resolve();
            }
        })
    }

    voidPurchase = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            that.verifyReturnItems.clear();

            if (that.hasUnsavedChanges) {
                api.Purchases.void(
                    that.id,
                    {
                        note: {
                            noteType: 'Purchase',
                            preview: that.voidReason,
                            bodyHtml: that.voidReasonHtml,
                        },
                        returnDestinations: that.returnDestinations
                    },
                    (c) => { that.cancelPurchaseVoid = c }
                )
                    .then(({ data }) => {
                        if (data.requiredVerification) {
                            that.verifyReturnItems = data.verifyResult;
                            that.returnDestinations.clear();
                            resolve({
                                id: that.id,
                                requiredVerification: data.requiredVerification
                            });
                        } else {
                            that.hasUnsavedChanges = false;
                            that.clearVoid();
                            that.returnDestinations.clear();
                            resolve({
                                id: that.id,
                                requiredVerification: false
                            });
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                that.hasUnsavedChanges = false;
                that.isSaving = false;
                resolve();
            }
        })
    }

    noChargePurchase = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            that.verifyReturnItems.clear();

            if (that.hasUnsavedChanges) {
                api.Purchases.noCharge(
                    that.id,
                    {
                        reason: that.noChargeReason,
                        reasonHtml: that.noChargeReasonHtml,
                    },
                    (c) => { that.cancelPurchaseNoCharge = c }
                )
                    .then(({ data }) => {
                        that.hasUnsavedChanges = false;
                        that.clearNoCharge();
                        resolve();
                    })
                    .catch(error => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                that.hasUnsavedChanges = false;
                that.isSaving = false;
                resolve();
            }
        })
    }

    uncollectiblePurchase = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            that.verifyReturnItems.clear();

            if (that.hasUnsavedChanges) {
                api.Purchases.uncollectible(
                    that.id,
                    {
                        reason: that.uncollectibleReason,
                        reasonHtml: that.uncollectibleReasonHtml,
                    },
                    (c) => { that.cancelPurchaseUncollectible = c }
                )
                    .then(({ data }) => {
                        that.hasUnsavedChanges = false;
                        that.clearUncollectible();
                        resolve();
                    })
                    .catch(error => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                that.hasUnsavedChanges = false;
                that.isSaving = false;
                resolve();
            }
        })
    }

    payment = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            api.Payments.create(
                {
                    purchaseId: that.id,
                    paymentMethod: that.newPaymentMethod,
                    insurancePolicyId: that.newPaymentPrivateInsurancePolicyId,
                    governmentProgramId: that.newPaymentGovernmentProgramId,
                    paymentDate: that.newPaymentDate ? that.newPaymentDate.clone() : null,
                    amount: that.newPaymentAmount,
                    note: {
                        noteType: 'TransactionPayment',
                        preview: that.newPaymentNote,
                        bodyHtml: that.newPaymentNoteHtml,
                    },
                },
                (c) => { that.cancelPaymentCreate = c }
            )
                .then(() => {
                    that.clearNewPayment();
                    resolve();
                })
                .catch(error => {
                    reject(error);
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    refundPayment = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            api.Payments.create(
                {
                    refundPaymentId: that.refundPaymentId,
                    purchaseId: that.id,
                    refundMethod: that.refundMethod,
                    paymentDate: that.refundDate ? that.refundDate.clone() : null,
                    amount: that.refundAmount * -1.0,
                    note: {
                        noteType: 'TransactionPayment',
                        preview: that.refundReason,
                        bodyHtml: that.refundReasonHtml,
                    },
                },
                (c) => { that.cancelPaymentRefund = c }
            )
                .then(() => {
                    that.clearRefund();
                    resolve();
                })
                .catch(error => {
                    reject(error);
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    insurancePayment = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            api.Payments.update(
                that.insurancePaymentId,
                {
                    customerId: that.data.customerId,
                    amount: that.insuranceAmount,
                    paymentDate: that.insurancePaymentDate ? that.insurancePaymentDate.clone() : null,
                    note: {
                        noteType: 'TransactionPayment',
                        preview: that.insuranceNote,
                        bodyHtml: that.insuranceNoteHtml,
                    },
                },
                (c) => { that.cancelPaymentInsurance = c }
            )
                .then(() => {
                    that.clearInsurance();
                    resolve();
                })
                .catch(error => {
                    reject(error);
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    governmentProgramPayment = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            api.Payments.update(
                that.governmentProgramPaymentId,
                {
                    customerId: that.data.customerId,
                    amount: that.governmentProgramAmount,
                    paymentDate: that.governmentProgramPaymentDate ? that.governmentProgramPaymentDate.clone() : null,
                    note: {
                        noteType: 'TransactionPayment',
                        preview: that.governmentProgramNote,
                        bodyHtml: that.governmentProgramNoteHtml,
                    },
                },
                (c) => { that.cancelPaymentGovernmentProgram = c }
            )
                .then(() => {
                    that.clearGovernmentProgram();
                    resolve();
                })
                .catch(error => {
                    reject(error);
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    removePayment = (id, notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            api.Payments.delete(
                that.removePaymentId,
                {
                    customerId: that.data.customerId,
                    deactivatedReason: that.removeReason,
                },
                (c) => { that.cancelPaymentDelete = c }
            )
                .then(() => {
                    that.clearRemove();
                    resolve();
                })
                .catch(error => {
                    reject(error);
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    clearVoid = () => {
        this.voidReason = null;
        this.voidReasonHtml = null;
    }

    clearRefund = () => {
        this.refundPaymentId = null;
        this.refundPayments.clear();
        this.refundAmount = null;
        this.refundDate = null;
        this.refundMethod = null;
        this.refundReason = null;
        this.refundReasonHtml = null;
    }

    clearNoCharge = () => {
        this.noChargeReason = null;
        this.noChargeReasonHtml = null;
    }

    clearUncollectible = () => {
        this.uncollectibleReason = null;
        this.uncollectibleReasonHtml = null;
    }

    clearNewPayment = () => {
        this.newPaymentNote = null;
        this.newPaymentNoteHtml = null;
        this.newPaymentAmount = null;
        this.newPaymentDate = null;
        this.newPaymentMethod = null;
        this.newPaymentPrivateInsurancePolicyId = null;
        this.newPaymentGovernmentProgramId = null;
    }

    clearCustomer = () => {
        this.data.customer = null;
        this.data.customerId = null;
        this.data.customerName = null;
        this.data.customerEmail = null;
        this.data.customerPhoneNumber = null;
        this.data.customerPreviousExam = null;
        this.data.billingAddress = null;
        this.data.shippingAddress = null;
        this.data.userId = null;
        this.data.user = null;
    }

    clearInsurance = () => {
        this.insurancePaymentId = null;
        this.insuranceInformation = null;
        this.insuranceAmount = null;
        this.insurancePaymentDate = null;
        this.insuranceNote = null;
        this.insuranceNoteHtml = null;
    }

    clearGovernmentProgram = () => {
        this.governmentProgramPaymentId = null;
        this.governmentProgramInformation = null;
        this.governmentProgramAmount = null;
        this.governmentProgramPaymentDate = null;
        this.governmentProgramNote = null;
        this.governmentProgramNoteHtml = null;
    }

    clearRemove = () => {
        this.removePaymentId = null;
        this.removeAmount = null;
        this.removePaymentMethod = null;
        this.removeReason = null;
        this.removeReasonHtml = null;
    }

    clear = () => {
        this.id = null;
        this.data.id = null;
        this.data.createdById = null;
        this.data.createdBy = null;
        this.data.createdDateUtc = null;
        this.data.lastUpdatedDateUtc = null;
        this.data.lastAnyUpdatedDateUtc = null;
        this.data.completedDateUtc = null;
        this.data.deactivatedDateUtc = null;
        this.data.referenceId = null;
        this.clearCustomer();
        this.data.number = null;
        this.data.transactions = [];
        this.data.transactionItemTypes = [];
        this.data.orderDiscountDescriptor = null;
        this.data.orderDiscountRate = null;
        this.data.orderDiscountAmount = null;
        this.data.isOrderCustomDiscount = false;
        this.data.price = 0.0;
        this.data.discountAmount = 0.0;
        this.data.subTotal = 0.0;
        this.data.subsidizedTotal = 0.0;
        this.data.taxCode1 = null;
        this.data.taxRate1 = null;
        this.data.taxAmount1 = 0.0;
        this.data.taxCode2 = null;
        this.data.taxRate2 = null;
        this.data.taxAmount2 = 0.0;
        this.data.taxCode3 = null;
        this.data.taxRate3 = null;
        this.data.taxAmount3 = 0.0;
        this.data.totalTaxRates = null;
        this.data.totalTaxCodes = null;
        this.data.totalTaxAmount = 0.0;
        this.data.total = 0.0;
        this.data.totalPayable = 0.0;
        this.data.totalPaid = 0.0;
        this.data.remainingBalance = 0.0;
        this.data.insuranceInformation = null;
        this.data.toBeCollectedAmount = 0.0;
        this.data.isVoided = false;
        this.data.isWaitingToBeCollected = false;
        this.data.isPaidInFull = false;
        this.data.isNoCharge = false;
        this.data.invoiceDate = null;
        this.data.dueDate = null;
        this.data.paidInFullDate = null;
        this.data.lastPayment = null;
        this.data.lastPaymentDate = null;
        this.data.isPayablePaidInFull = false;
        this.data.status = null;
        this.data.invoiceNumber = null;
        this.data.paymentReceiptNumber = null;
        this.data.payments = [];
        this.data.items = [];
        this.data.referenceTypes = [];
        this.data.note = null;
        this.data.noteHtml = null;
        this.licensedUsers = null;
        this.clearVoid();
        this.clearNewPayment();
        this.clearRefund();
        this.clearNoCharge();
        this.clearUncollectible();
        this.clearInsurance();
        this.clearRemove();
        this.verifyReturnItems.clear();
        this.returnDestinations.clear();
        this.expiringWorkOrders.clear();
        this.hasUnsavedChanges = false;
        this.isCustomerLoading = false;
        this.isLoading = false;
        this.isSaving = false;
        this.isReady = false;

        if (fn.isFunction(this.cancelPaymentCreate)) {
            this.cancelPaymentCreate();
            this.cancelPaymentCreate = null;
        }

        if (fn.isFunction(this.cancelPaymentDelete)) {
            this.cancelPaymentDelete();
            this.cancelPaymentDelete = null;
        }

        if (fn.isFunction(this.cancelPaymentInsurance)) {
            this.cancelPaymentInsurance();
            this.cancelPaymentInsurance = null;
        }

        if (fn.isFunction(this.cancelPaymentGovernmentProgram)) {
            this.cancelPaymentGovernmentProgram();
            this.cancelPaymentGovernmentProgram = null;
        }

        if (fn.isFunction(this.cancelPaymentRefund)) {
            this.cancelPaymentRefund();
            this.cancelPaymentRefund = null;
        }

        if (fn.isFunction(this.cancelPurchaseComplete)) {
            this.cancelPurchaseComplete();
            this.cancelPurchaseComplete = null;
        }

        if (fn.isFunction(this.cancelPurchaseCreate)) {
            this.cancelPurchaseCreate();
            this.cancelPurchaseCreate = null;
        }

        if (fn.isFunction(this.cancelPurchaseDelete)) {
            this.cancelPurchaseDelete();
            this.cancelPurchaseDelete = null;
        }

        if (fn.isFunction(this.cancelPurchaseGet)) {
            this.cancelPurchaseGet();
            this.cancelPurchaseGet = null;
        }

        if (fn.isFunction(this.cancelPurchaseNoCharge)) {
            this.cancelPurchaseNoCharge();
            this.cancelPurchaseNoCharge = null;
        }

        if (fn.isFunction(this.cancelPurchaseUncollectible)) {
            this.cancelPurchaseUncollectible();
            this.cancelPurchaseUncollectible = null;
        }

        if (fn.isFunction(this.cancelPurchaseRefund)) {
            this.cancelPurchaseRefund();
            this.cancelPurchaseRefund = null;
        }

        if (fn.isFunction(this.cancelPurchaseStart)) {
            this.cancelPurchaseStart();
            this.cancelPurchaseStart = null;
        }

        if (fn.isFunction(this.cancelPurchaseUpdate)) {
            this.cancelPurchaseUpdate();
            this.cancelPurchaseUpdate = null;
        }

        if (fn.isFunction(this.cancelPurchaseVoid)) {
            this.cancelPurchaseVoid();
            this.cancelPurchaseVoid = null;
        }

        if (fn.isFunction(this.cancelLicensedUsers)) {
            this.cancelLicensedUsers();
            this.cancelLicensedUsers = null;
        }

        if (fn.isFunction(this.cancelPurchaseUpdateCost)) {
            this.cancelPurchaseUpdateCost();
            this.cancelPurchaseUpdateCost = null;
        }
    }

    setCustomer = (customer) => {
        this.clearCustomer();

        if (customer) {
            const that = this;
            that.isCustomerLoading = true;

            api.Exams.previous({
                customerIds: [customer.id],
                dateTimeUtc: moment.utc(),
            })
                .then(({ data }) => {
                    action(() => {
                        that.data.customer = customer;
                        that.data.customerId = customer.id;
                        that.data.customerName = ph.getFirstLastName(customer, true);
                        that.data.customerEmail = customer.emailAddress;
                        that.data.billingAddress = customer.address;
                        that.data.customerPhoneNumber = customer.phoneNumber;
                        that.data.customerPreviousExam = data && data.length === 1 ? data[0] : null;

                        if (that.data.customerPreviousExam && that.data.customerPreviousExam.userId) {
                            that.data.userId = that.data.customerPreviousExam.userId;
                        }
                    })();
                })
                .finally(() => {
                    that.isCustomerLoading = false;
                })
        }
    }

    addDefaultTransactionItem = () => {
        this.addNewTransactionItem({
            isStocked: false,
            isOutOfStock: true,
            isFromInventory: false,
            price: null,
            descriptor: '',
            isTaxable: false,
            id: null,
            type: 'Custom',
            displayOrder: (this.data && this.data.items && this.data.items.length > 0 ? this.data.items.length * 10 : 0)
        });
    }

    isDefaultTransactionItem = (item) => {
        if (item) {
            return !item.descriptor && !item.unitPrice && !item.price;
        }

        return false;
    }

    getDefaultTransactionItem = () => {
        if (this.hasDefaultTransactionItem) {
            return this.data.items.filter(i => this.isDefaultTransactionItem(i));
        }

        return null;
    }

    resetDefaultTransactionItem = () => {
        if (this.hasDefaultTransactionItem) {
            const defaultTransactionItems = this.getDefaultTransactionItem();

            if (defaultTransactionItems.length > 1) {
                const reorderIndex = this.data.items.findIndex(i => i.id === defaultTransactionItems[0].id);

                for (let x = 1; x < defaultTransactionItems.length; x++) {
                    const removeIndex = this.data.items.findIndex(i => i.id === defaultTransactionItems[x].id);
                    this.data.items.splice(removeIndex, 1);
                }

                this.data.items.push(this.data.items.splice(reorderIndex, 1)[0]);
                return true;
            }
            else {
                const index = this.data.items.findIndex(i => i.id === defaultTransactionItems[0].id);

                if (index !== (this.data.items.length - 1)) {
                    this.data.items.push(this.data.items.splice(index, 1)[0]);
                    return true;
                }
            }

            for (let i = 0; i < this.data.items.length; i++) {
                this.data.items[i].displayOrder = i * 10;
            }
        }
        else {
            this.addDefaultTransactionItem();
            return true;
        }

        return false;
    }

    getNewTransactionItem = (item, parentGroupId = null) => {
        const id = fn.newId('tri_');
        const groupId = fn.newId('tri_g_');
        const taxRates = JSON.parse(window.localStorage.getItem(StorageKeys.TAX_RATES));
        const isOutOfStock = item.quantity && item.quantity > 0 ? item.isOutOfStock : true;
        const displayOrder = this.data && this.data.items && this.data.items.length > 0 ? this.data.items.length * 10 : 0;
        const newItem = {
            isCustomDiscount: false,
            discountAmount: 0,
            discountRate: 0,
            id: id,
            groupId: groupId,
            ids: [],
            displayOrder: displayOrder,
            isStocked: item.isStocked ? item.isStocked : false,
            isOutOfStock: isOutOfStock,
            isFromInventory: item.isStocked && !isOutOfStock,
            isFinalSale: false,
            isReturnable: false,
            items: [],
            parentGroupId: parentGroupId,
            notes: [],
            defaultQuantity: item.defaultQuantity ? item.defaultQuantity : 1,
            quantity: item.defaultQuantity ? item.defaultQuantity : 1,
            maxQuantity: item.quantity ? item.quantity : null,
            serialNumber: null,
            taxableAmount1: 0,
            taxableAmount2: 0,
            taxableAmount3: 0,
            taxCode1: taxRates && taxRates.length > 0 ? taxRates[0].code : null,
            taxCode2: taxRates && taxRates.length > 1 ? taxRates[1].code : null,
            taxCode3: taxRates && taxRates.length > 2 ? taxRates[2].code : null,
            taxRate1: taxRates && taxRates.length > 0 ? taxRates[0].rate : null,
            taxRate2: taxRates && taxRates.length > 1 ? taxRates[1].rate : null,
            taxRate3: taxRates && taxRates.length > 2 ? taxRates[2].rate : null,
            transactionId: fn.newId('tr_'),
            type: item.type,
            isNew: true,
        };

        switch (item.type.toLowerCase()) {
            case 'service':
                newItem.referenceId = item.id;
                newItem.referenceType = item.code;
                newItem.unitCost = 0;
                newItem.unitPrice = item.fee ? item.fee : null;
                newItem.price = newItem.unitPrice * newItem.quantity;
                newItem.descriptor = item.name;
                newItem.overriddenDescriptor = null;
                newItem.isOverridden = false;
                newItem.isSubsidized = item.isSubsidized;
                newItem.isTaxable = item.defaultApplyTax || item.isTaxable;
                newItem.total = item.fee;
                newItem.transactionItemType = item.type;
                break;

            case 'product':
                newItem.referenceId = item.id;
                newItem.referenceType = item.typeName;
                newItem.unitCost = item.cost;
                newItem.unitPrice = item.price ? item.price : null;
                newItem.price = newItem.unitPrice * newItem.quantity;
                newItem.descriptor = item.descriptor;
                newItem.overriddenDescriptor = null;
                newItem.isOverridden = false;
                newItem.overriddenDescriptor = null;
                newItem.isOverridden = false;
                newItem.isSubsidized = false;
                newItem.isTaxable = item.defaultApplyTax || item.isTaxable;
                newItem.total = item.price;
                newItem.transactionItemType = item.type;
                break;

            case 'custom':
            default:
                newItem.unitCost = 0;
                newItem.unitPrice = item.price ? item.price : null;
                newItem.price = newItem.unitPrice * newItem.quantity;
                newItem.descriptor = item.descriptor;
                newItem.overriddenDescriptor = null;
                newItem.isOverridden = false;
                newItem.isSubsidized = false;
                newItem.isTaxable = item.defaultApplyTax || item.isTaxable;
                newItem.total = item.price;
                newItem.transactionItemType = 'Custom';
                break;
        }

        return newItem;
    }

    addNewTransactionItem = item => {
        const newItem = this.getNewTransactionItem(item);
        const { id } = newItem;

        this.data.items.push(newItem);

        this.updateApplyTax(id, newItem.isTaxable);
        this.updateTotal(id);
        this.updatePurchaseTotal();

        return id;
    }

    addNewTransactionSubItem = (item, parentGroupId) => {
        const newItem = this.getNewTransactionItem(item, parentGroupId);
        const { id } = newItem;

        for (var i = 0; i < this.data.items.length; i++) {
            if (this.data.items[i].groupId === parentGroupId) {
                this.data.items[i].items.push(newItem);
                this.updateApplyTax(id, newItem.isTaxable);
                this.updateTotal(id);
                this.updatePurchaseTotal();
            }
        }

        return id;
    }

    // when user close the popup and not saving the changes, rollback
    reloadFromCache = () => {
        if (this.cachedData) {
            this.data = JSON.parse(this.cachedData);
        }
    }

    removeTransactionItem = item => {
        let index = this.data.items.findIndex(i => i.id === item.id);

        if (index > -1) {
            this.data.items.splice(index, 1);
            this.setExpiringWorkOrders(item, null);
        } else {
            for (let i = 0; i < this.data.items.length; i++) {
                index = this.data.items[i].items.findIndex(j => j.id === item.id);

                if (index > -1) {
                    this.data.items[i].items.splice(index, 1);
                }
            }
        }

        this.updateTotal(item.id);
        this.updatePurchaseTotal();

        return item.id;
    }

    replaceTransactionItem = (id, item) => {
        const newItem = this.getNewTransactionItem(item);

        newItem.id = id;

        for (let i = 0; i < this.data.items.length; i++) {
            if (this.data.items[i].id === id) {
                const previousTransactionItem = JSON.parse(JSON.stringify(this.data.items[i]));
                newItem.displayOrder = this.data.items[i].displayOrder;

                this.data.items[i] = newItem;
                this.data.items[i].id = fn.newId('tri_');
                this.data.items[i].groupId = fn.newId('tri_g_');

                for (let ii = 0; ii < this.data.items[i].items.length; ii++) {
                    this.data.items[i].items[ii].parentGroupId = this.data.items[i].groupId;
                }

                this.setExpiringWorkOrders(previousTransactionItem, JSON.parse(JSON.stringify(this.data.items[i])));
            }
            else {
                for (let j = 0; j < this.data.items[i].items.length; j++) {
                    if (this.data.items[i].items[j].id === id) {
                        const previousTransactionSubItem = JSON.parse(JSON.stringify(this.data.items[i].items[j]));

                        newItem.displayOrder = this.data.items[i].items[j].displayOrder;
                        newItem.parentGroupId = this.data.items[i].items[j].parentGroupId;

                        this.data.items[i].items[j] = newItem;
                        this.data.items[i].items[j].id = fn.newId('tri_');
                        this.data.items[i].items[j].groupId = fn.newId('tri_g_');

                        this.setExpiringWorkOrders(previousTransactionSubItem, JSON.parse(JSON.stringify(this.data.items[i].items[j])));
                    }
                }
            }
        }

        this.updateTotal(id);
        this.updatePurchaseTotal();
    }

    setExpiringWorkOrders = (previousTransactionItem, newTransactionItem) => {
        if (this.data && this.data.workOrders && this.data.workOrders.filter(wo => !wo.isExpired && !wo.isDeactivated).length > 0) {
            const workOrders = this.data.workOrders.filter(wo => !wo.isExpired && !wo.isDeactivated && wo.items.some(woi => woi.transactionItemIds.some(ti => ti === previousTransactionItem.id)));

            if (workOrders && workOrders.length > 0) {
                for (let wo = 0; wo < workOrders.length; wo++) {
                    const index = this.expiringWorkOrders.findIndex(ewo => ewo.id === workOrders[wo].id);

                    if (index >= 0) {
                        this.expiringWorkOrders[index].items.push({
                            previous: {
                                id: previousTransactionItem.id,
                                groupId: previousTransactionItem.groupId,
                            },
                            new: newTransactionItem ? {
                                id: newTransactionItem.id,
                                groupId: newTransactionItem.groupId,
                            } : null
                        });
                    }
                    else {
                        this.expiringWorkOrders.push({
                            id: workOrders[wo].id,
                            items: [{
                                previous: {
                                    id: previousTransactionItem.id,
                                    groupId: previousTransactionItem.groupId,
                                },
                                new: newTransactionItem ? {
                                    id: newTransactionItem.id,
                                    groupId: newTransactionItem.groupId,
                                } : null
                            }]
                        })
                    }
                }
            }

            for (let ewo = 0; ewo < this.expiringWorkOrders.length; ewo++) {
                this.expiringWorkOrders[ewo].items = this.expiringWorkOrders[ewo].items.filter(ei => !ei.new || this.data.items.some(i => i.groupId === ei.new.groupId));
            }

            this.expiringWorkOrders = this.expiringWorkOrders.filter(ewo => ewo.items && ewo.items.length > 0);
        }
    }

    updateUnitCost = (id, unitCost) => {
        for (let i = 0; i < this.data.items.length; i++) {
            if (this.data.items[i].id === id) {
                this.data.items[i].unitCost = unitCost;
            }
            else {
                for (let j = 0; j < this.data.items[i].items.length; j++) {
                    if (this.data.items[i].items[j].id === id) {
                        this.data.items[i].items[j].unitCost = unitCost;
                    }
                }
            }
        }
        this.updatePurchaseTotalCost();
    }

    updateQuantity = (id, quantity) => {
        if (quantity >= 0) {
            for (let i = 0; i < this.data.items.length; i++) {
                if (this.data.items[i].id === id) {
                    this.data.items[i].quantity = quantity === 0 ? null : quantity;
                    this.data.items[i].price = quantity * (this.data.items[i].unitPrice ? this.data.items[i].unitPrice : 0);
                    this.data.items[i].discountAmount = this.data.items[i].price * this.data.items[i].discountRate;
                    this.data.items[i].taxableAmount1 = (this.data.items[i].isTaxable && this.data.items[i].taxRate1) ? (this.data.items[i].price - this.data.items[i].discountAmount) : 0;
                    this.data.items[i].taxableAmount2 = (this.data.items[i].isTaxable && this.data.items[i].taxRate2) ? (this.data.items[i].price - this.data.items[i].discountAmount) : 0;
                    this.data.items[i].taxableAmount3 = (this.data.items[i].isTaxable && this.data.items[i].taxRate3) ? (this.data.items[i].price - this.data.items[i].discountAmount) : 0;
                    this.updateTotal(id);
                }
                else {
                    for (let j = 0; j < this.data.items[i].items.length; j++) {
                        if (this.data.items[i].items[j].id === id) {
                            this.data.items[i].items[j].quantity = quantity === 0 ? null : quantity;
                            this.data.items[i].items[j].price = quantity * this.data.items[i].items[j].unitPrice;
                            this.data.items[i].items[j].discountAmount = this.data.items[i].items[j].price * this.data.items[i].items[j].discountRate;
                            this.data.items[i].items[j].taxableAmount1 = (this.data.items[i].items[j].isTaxable && this.data.items[i].items[j].taxRate1) ? (this.data.items[i].items[j].price - this.data.items[i].items[j].discountAmount) : 0;
                            this.data.items[i].items[j].taxableAmount2 = (this.data.items[i].items[j].isTaxable && this.data.items[i].items[j].taxRate2) ? (this.data.items[i].items[j].price - this.data.items[i].items[j].discountAmount) : 0;
                            this.data.items[i].items[j].taxableAmount3 = (this.data.items[i].items[j].isTaxable && this.data.items[i].items[j].taxRate3) ? (this.data.items[i].items[j].price - this.data.items[i].items[j].discountAmount) : 0;
                            this.updateTotal(id);
                        }
                    }
                }
            }
        }
    }

    updateDescriptor = (id, descriptor) => {
        descriptor = descriptor ? descriptor : '';

        for (let i = 0; i < this.data.items.length; i++) {
            if (this.data.items[i].id === id) {
                this.data.items[i].descriptor = descriptor;
            }
            else {
                for (let j = 0; j < this.data.items[i].items.length; j++) {
                    if (this.data.items[i].items[j].id === id) {
                        this.data.items[i].items[j].descriptor = descriptor;
                    }
                }
            }
        }
    }

    updateIsOverridden = (id, isOverridden) => {
        isOverridden = !!isOverridden;

        for (let i = 0; i < this.data.items.length; i++) {
            if (this.data.items[i].id === id) {
                this.data.items[i].isOverridden = isOverridden;
                this.data.items[i].overriddenDescriptor = isOverridden ? this.data.items[i].descriptor : null;
            }
            else {
                for (let j = 0; j < this.data.items[i].items.length; j++) {
                    if (this.data.items[i].items[j].id === id) {
                        this.data.items[i].items[j].isOverridden = isOverridden;
                        this.data.items[i].items[j].overriddenDescriptor = isOverridden ? this.data.items[i].items[j].descriptor : null;
                    }
                }
            }
        }
    }

    updateOverriddenDescriptor = (id, overriddenDescriptor) => {
        overriddenDescriptor = overriddenDescriptor ? overriddenDescriptor : '';

        for (let i = 0; i < this.data.items.length; i++) {
            if (this.data.items[i].id === id) {
                this.data.items[i].overriddenDescriptor = overriddenDescriptor;
            }
            else {
                for (let j = 0; j < this.data.items[i].items.length; j++) {
                    if (this.data.items[i].items[j].id === id) {
                        this.data.items[i].items[j].overriddenDescriptor = overriddenDescriptor;
                    }
                }
            }
        }
    }

    updateIsFromInventory = (id, isFromInventory) => {
        for (let i = 0; i < this.data.items.length; i++) {
            if (this.data.items[i].id === id) {
                this.data.items[i].isFromInventory = this.data.items[i].isStocked && isFromInventory;
            }
            else {
                for (let j = 0; j < this.data.items[i].items.length; j++) {
                    if (this.data.items[i].items[j].id === id) {
                        this.data.items[i].items[j].isFromInventory = this.data.items[i].items[j].isStocked && isFromInventory;
                    }
                }
            }
        }
    }

    updateUnitPrice = (id, unitPrice) => {
        unitPrice = fn.parseCurrency(unitPrice, 0);

        for (let i = 0; i < this.data.items.length; i++) {
            if (this.data.items[i].id === id) {
                this.data.items[i].unitPrice = unitPrice;
                this.data.items[i].price = (unitPrice * (this.data.items[i].quantity ? this.data.items[i].quantity : 1));
                this.updateDiscountRate(id, this.data.items[i].discountRate);
                this.updateApplyTax(id, this.data.items[i].isTaxable);
                this.updateTotal(id);
            }
            else {
                for (let j = 0; j < this.data.items[i].items.length; j++) {
                    if (this.data.items[i].items[j].id === id) {
                        this.data.items[i].items[j].unitPrice = unitPrice;
                        this.data.items[i].items[j].price = (unitPrice * (this.data.items[i].items[j].quantity ? this.data.items[i].items[j].quantity : 1));
                        this.updateDiscountRate(id, this.data.items[i].items[j].discountRate);
                        this.updateApplyTax(id, this.data.items[i].items[j].isTaxable);
                        this.updateTotal(id);
                    }
                }
            }
        }
    }

    updateIsCustomDiscount = (id, isCustomDiscount) => {
        for (let i = 0; i < this.data.items.length; i++) {
            if (this.data.items[i].id === id) {
                this.data.items[i].isCustomDiscount = isCustomDiscount;
                this.data.items[i].discountRate = null;
                this.data.items[i].discountAmount = 0;
                this.updateTotal(id);
            }
            else {
                for (let j = 0; j < this.data.items[i].items.length; j++) {
                    if (this.data.items[i].items[j].id === id) {
                        this.data.items[i].items[j].isCustomDiscount = isCustomDiscount;
                        this.data.items[i].items[j].discountRate = null;
                        this.data.items[i].items[j].discountAmount = 0;
                        this.updateTotal(id);
                    }
                }
            }
        }
    }

    updateDiscount = (id, discount) => {
        for (let i = 0; i < this.data.items.length; i++) {
            if (this.data.items[i].id === id) {
                if (fn.isNullOrUndefined(this.data.items[i].unitPrice)) {
                    this.updateUnitPrice(id, 0);
                }
                this.data.items[i].discountRate = null;
                this.data.items[i].discountAmount = 0;
                this.data.items[i].discount = discount;
                this.updateApplyTax(id, this.data.items[i].isTaxable);
                this.updateTotal(id);
            }
            else {
                for (let j = 0; j < this.data.items[i].items.length; j++) {
                    if (this.data.items[i].items[j].id === id) {
                        if (fn.isNullOrUndefined(this.data.items[i].unitPrice)) {
                            this.updateUnitPrice(id, 0);
                        }
                        this.data.items[i].items[j].discountRate = null;
                        this.data.items[i].items[j].discountAmount = 0;
                        this.data.items[i].discount = discount;
                        this.updateApplyTax(id, this.data.items[i].items[j].isTaxable);
                        this.updateTotal(id);
                    }
                }
            }
        }
    }

    updateDiscountRate = (id, discountRate) => {
        for (let i = 0; i < this.data.items.length; i++) {
            if (this.data.items[i].id === id) {
                if (fn.isNullOrUndefined(this.data.items[i].unitPrice)) {
                    this.updateUnitPrice(id, 0);
                }
                this.data.items[i].isCustomDiscount = false;
                this.data.items[i].discount = null;
                this.data.items[i].discountRate = discountRate;
                this.data.items[i].discountAmount = fn.roundCurrency(this.data.items[i].price * this.data.items[i].discountRate);
                this.updateApplyTax(id, this.data.items[i].isTaxable);
                this.updateTotal(id);
            }
            else {
                for (let j = 0; j < this.data.items[i].items.length; j++) {
                    if (this.data.items[i].items[j].id === id) {
                        if (fn.isNullOrUndefined(this.data.items[i].unitPrice)) {
                            this.updateUnitPrice(id, 0);
                        }
                        this.data.items[i].items[j].isCustomDiscount = false;
                        this.data.items[i].items[j].discount = null;
                        this.data.items[i].items[j].discountRate = discountRate;
                        this.data.items[i].items[j].discountAmount = fn.roundCurrency(this.data.items[i].items[j].price * this.data.items[i].items[j].discountRate);
                        this.updateApplyTax(id, this.data.items[i].items[j].isTaxable);
                        this.updateTotal(id);
                    }
                }
            }
        }
    }

    updateDiscountAmount = (id, discountAmount) => {
        discountAmount = fn.parseCurrency(discountAmount, 0);

        for (let i = 0; i < this.data.items.length; i++) {
            if (this.data.items[i].id === id) {
                if (fn.isNullOrUndefined(this.data.items[i].unitPrice)) {
                    this.updateUnitPrice(id, 0);
                }
                this.data.items[i].discount = null;
                this.data.items[i].discountRate = null;
                this.data.items[i].discountAmount = discountAmount;
                this.updateApplyTax(id, this.data.items[i].isTaxable);
                this.updateTotal(id);
            }
            else {
                for (let j = 0; j < this.data.items[i].items.length; j++) {
                    if (this.data.items[i].items[j].id === id) {
                        if (fn.isNullOrUndefined(this.data.items[i].unitPrice)) {
                            this.updateUnitPrice(id, 0);
                        }
                        this.data.items[i].items[j].discount = null;
                        this.data.items[i].items[j].discountRate = null;
                        this.data.items[i].items[j].discountAmount = discountAmount;
                        this.updateApplyTax(id, this.data.items[i].items[j].isTaxable);
                        this.updateTotal(id);
                    }
                }
            }
        }
    }

    updateApplyTax = (id, applyTax) => {
        for (let i = 0; i < this.data.items.length; i++) {
            if (this.data.items[i].id === id) {
                if (fn.isNullOrUndefined(this.data.items[i].unitPrice)) {
                    this.updateUnitPrice(id, 0);
                }
                this.data.items[i].isTaxable = applyTax;
                this.data.items[i].taxableAmount1 = (this.data.items[i].isTaxable && this.data.items[i].taxRate1) ? (this.data.items[i].price - this.data.items[i].discountAmount) : 0;
                this.data.items[i].taxableAmount2 = (this.data.items[i].isTaxable && this.data.items[i].taxRate2) ? (this.data.items[i].price - this.data.items[i].discountAmount) : 0;
                this.data.items[i].taxableAmount3 = (this.data.items[i].isTaxable && this.data.items[i].taxRate3) ? (this.data.items[i].price - this.data.items[i].discountAmount) : 0;
                this.updateTotal(id);
            }
            else {
                for (let j = 0; j < this.data.items[i].items.length; j++) {
                    if (this.data.items[i].items[j].id === id) {
                        if (fn.isNullOrUndefined(this.data.items[i].unitPrice)) {
                            this.updateUnitPrice(id, 0);
                        }
                        this.data.items[i].items[j].isTaxable = applyTax;
                        this.data.items[i].items[j].taxableAmount1 = (this.data.items[i].items[j].isTaxable && this.data.items[i].items[j].taxRate1) ? (this.data.items[i].items[j].price - this.data.items[i].items[j].discountAmount) : 0;
                        this.data.items[i].items[j].taxableAmount2 = (this.data.items[i].items[j].isTaxable && this.data.items[i].items[j].taxRate2) ? (this.data.items[i].items[j].price - this.data.items[i].items[j].discountAmount) : 0;
                        this.data.items[i].items[j].taxableAmount3 = (this.data.items[i].items[j].isTaxable && this.data.items[i].items[j].taxRate3) ? (this.data.items[i].items[j].price - this.data.items[i].items[j].discountAmount) : 0;
                        this.updateTotal(id);
                    }
                }
            }
        }
    }

    updateTotal = id => {
        for (let i = 0; i < this.data.items.length; i++) {
            if (this.data.items[i].id === id) {
                this.data.items[i].totalAfterDiscount = this.data.items[i].price - this.data.items[i].discountAmount;
            }
            else {
                for (let j = 0; j < this.data.items[i].items.length; j++) {
                    if (this.data.items[i].items[j].id === id) {
                        this.data.items[i].items[j].totalAfterDiscount = this.data.items[i].items[j].price - this.data.items[i].items[j].discountAmount;
                    }
                }
            }
        }

        this.updatePurchaseTotal();
    }

    updatePurchaseTotal = () => {
        let subTotal = 0, amountAfterDiscount = 0, discountAmount = 0, taxableAmount1 = 0, taxableAmount2 = 0, taxableAmount3 = 0, totalTaxAmount = 0, total = 0;

        for (let i = 0; i < this.data.items.length; i++) {
            subTotal = subTotal + (this.data.items[i].price ? this.data.items[i].price : 0);
            amountAfterDiscount = amountAfterDiscount + (this.data.items[i].totalAfterDiscount ? this.data.items[i].totalAfterDiscount : 0);
            discountAmount = discountAmount + (this.data.items[i].discountAmount ? this.data.items[i].discountAmount : 0);
            taxableAmount1 = taxableAmount1 + (this.data.items[i].taxableAmount1 ? this.data.items[i].taxableAmount1 : 0);
            taxableAmount2 = taxableAmount2 + (this.data.items[i].taxableAmount2 ? this.data.items[i].taxableAmount2 : 0);
            taxableAmount3 = taxableAmount3 + (this.data.items[i].taxableAmount3 ? this.data.items[i].taxableAmount3 : 0);
            total = total + (this.data.items[i].total ? this.data.items[i].total : 0);

            for (let j = 0; j < this.data.items[i].items.length; j++) {
                subTotal = subTotal + (this.data.items[i].items[j].price ? this.data.items[i].items[j].price : 0);
                amountAfterDiscount = amountAfterDiscount + (this.data.items[i].items[j].totalAfterDiscount ? this.data.items[i].items[j].totalAfterDiscount : 0);
                discountAmount = discountAmount + (this.data.items[i].items[j].discountAmount ? this.data.items[i].items[j].discountAmount : 0);
                taxableAmount1 = taxableAmount1 + (this.data.items[i].items[j].taxableAmount1 ? this.data.items[i].items[j].taxableAmount1 : 0);
                taxableAmount2 = taxableAmount2 + (this.data.items[i].items[j].taxableAmount2 ? this.data.items[i].items[j].taxableAmount2 : 0);
                taxableAmount3 = taxableAmount3 + (this.data.items[i].items[j].taxableAmount3 ? this.data.items[i].items[j].taxableAmount3 : 0);
                total = total + (this.data.items[i].items[j].total ? this.data.items[i].items[j].total : 0);
            }
        }

        if (this.data.orderDiscountRate) {
            this.data.orderDiscountAmount = fn.roundCurrency((amountAfterDiscount * this.data.orderDiscountRate));
        }

        if (this.data.orderDiscountAmount) {
            amountAfterDiscount = subTotal - this.data.orderDiscountAmount;
            discountAmount = discountAmount + this.data.orderDiscountAmount;
        }

        totalTaxAmount = ((taxableAmount1 * this.taxRate1) + (taxableAmount2 * this.taxRate2) + (taxableAmount3 * this.taxRate3)) - (this.data.orderDiscountAmount * (this.taxRate1 + this.taxRate2 + this.taxRate3));

        this.data.subTotal = subTotal ? fn.roundCurrency(subTotal) : 0;
        this.data.discountAmount = discountAmount ? fn.roundCurrency(discountAmount) : 0;
        this.data.totalTaxAmount = totalTaxAmount ? fn.roundCurrency(totalTaxAmount) : 0;
        this.data.total = fn.roundCurrency((this.data.subTotal - this.data.discountAmount + this.data.totalTaxAmount));
        this.data.remainingBalance = this.data.total - this.data.totalPaid;
        this.hasUnsavedChanges = true;
    }

    updatePurchaseTotalCost = () => {
        let totalCost = 0;

        for (let i = 0; i < this.data.items.length; i++) {
            totalCost = totalCost + ((this.data.items[i].quantity ? this.data.items[i].quantity : 1) * (this.data.items[i].unitCost ? this.data.items[i].unitCost : 0));

            for (let j = 0; j < this.data.items[i].items.length; j++) {
                totalCost = totalCost + ((this.data.items[i].quantity ? this.data.items[i].quantity : 1) * (this.data.items[i].items[j].unitCost ? this.data.items[i].items[j].unitCost : 0));
            }
        }

        this.data.totalCost = totalCost ? fn.roundCurrency(totalCost) : 0;
        this.hasUnsavedChanges = true;
    }

    updateReturnDestination = (groupId, value) => {
        const index = this.returnDestinations.findIndex(r => r.groupId === groupId);

        if (index < 0) {
            this.returnDestinations.push({
                groupId: groupId,
                returnDestination: value,
            });
        } else {
            this.returnDestinations[index].returnDestination = value;
        }
    }

    get taxRate1() {
        if (this.data && this.data.items) {
            for (let i = 0; i < this.data.items.length; i++) {
                if (this.data.items[i].taxRate1) {
                    return this.data.items[i].taxRate1;
                }
                if (this.data.items[i].items) {
                    for (let j = 0; j < this.data.items[i].items.length; j++) {
                        if (this.data.items[i].items[j].taxRate1) {
                            return this.data.items[i].items[j].taxRate1;
                        }
                    }
                }
            }
        }
        return 0;
    }

    get taxRate2() {
        if (this.data && this.data.items) {
            for (let i = 0; i < this.data.items.length; i++) {
                if (this.data.items[i].taxRate2) {
                    return this.data.items[i].taxRate2;
                }
                if (this.data.items[i].items) {
                    for (let j = 0; j < this.data.items[i].items.length; j++) {
                        if (this.data.items[i].items[j].taxRate2) {
                            return this.data.items[i].items[j].taxRate2;
                        }
                    }
                }
            }
        }
        return 0;
    }

    get taxRate3() {
        if (this.data && this.data.items) {
            for (let i = 0; i < this.data.items.length; i++) {
                if (this.data.items[i].taxRate3) {
                    return this.data.items[i].taxRate3;
                }
                if (this.data.items[i].items) {
                    for (let j = 0; j < this.data.items[i].items.length; j++) {
                        if (this.data.items[i].items[j].taxRate3) {
                            return this.data.items[i].items[j].taxRate3;
                        }
                    }
                }
            }
        }
        return 0;
    }

    get totalTaxRate() {
        return (this.taxRate1 + this.taxRate2 + this.taxRate3);
    }

    get isNew() {
        return this.id === null;
    }

    get payments() {
        if (!this.data) return [];
        return this.data.transactions(t => t.payments)
    }

    get hasDefaultTransactionItem() {
        return this.data && this.data.items && this.data.items.some(i => this.isDefaultTransactionItem(i));
    }

    get validItems() {
        if (!this.data || !this.data.items || !this.data.items.some(i => !this.isDefaultTransactionItem(i))) return [];
        return this.data.items.filter(i => !this.isDefaultTransactionItem(i));
    }

    get hasExpiringWorkOrders() {
        return this.expiringWorkOrders && this.expiringWorkOrders.length > 0;
    }
}

decorate(PurchaseUpdateStore, {
    id: observable,
    data: observable,
    licensedUsers: observable,
    isNew: computed,
    isLoading: observable,
    isSaving: observable,
    isReady: observable,
    isCustomerLoading: observable,
    hasUnsavedChanges: observable,
    refundPaymentId: observable,
    refundPayments: observable,
    refundAmount: observable,
    refundDate: observable,
    refundMethod: observable,
    refundReason: observable,
    refundReasonHtml: observable,
    voidReason: observable,
    voidReasonHtml: observable,
    noChargeReason: observable,
    noChargeReasonHtml: observable,
    uncollectibleReason: observable,
    uncollectibleReasonHtml: observable,
    verifyReturnItems: observable,
    returnDestinations: observable,
    expiringWorkOrders: observable,
    newPaymentNote: observable,
    newPaymentNoteHtml: observable,
    newPaymentAmount: observable,
    newPaymentDate: observable,
    newPaymentMethod: observable,
    newPaymentPrivateInsurancePolicyId: observable,
    newPaymentGovernmentProgramId: observable,
    insurancePaymentId: observable,
    insuranceInformation: observable,
    insuranceAmount: observable,
    insurancePaymentDate: observable,
    insuranceNote: observable,
    insuranceNoteHtml: observable,
    governmentProgramPaymentId : observable,
    governmentProgramInformation : observable,
    governmentProgramAmount : observable,
    governmentProgramPaymentDate : observable,
    governmentProgramNote : observable,
    governmentProgramNoteHtml : observable,
    removePaymentId: observable,
    removeAmount: observable,
    removePaymentMethod: observable,
    removeReason: observable,
    removeReasonHtml: observable,
    load: action,
    reload: action,
    save: action,
    replaceWorkOrders: action,
    delete: action,
    clear: action,
    clearVoid: action,
    clearRefund: action,
    clearNoCharge: action,
    clearUncollectible: action,
    clearNewPayment: action,
    clearCustomer: action,
    clearInsurance: action,
    clearGovernmentProgram: action,
    clearRemove: action,
    setCustomer: action,
    voidPurchase: action,
    refundPurchase: action,
    noChargePurchase: action,
    uncollectiblePurchase: action,
    saveUnitCost: action,
    payment: action,
    refundPayment: action,
    insurancePayment: action,
    governmentProgramPayment: action,
    addDefaultTransactionItem: action,
    isDefaultTransactionItem: action,
    getDefaultTransactionItem: action,
    resetDefaultTransactionItem: action,
    getNewTransactionItem: action,
    addNewTransactionItem: action,
    addNewTransactionSubItem: action,
    reloadFromCache: action,
    removeTransactionItem: action,
    replaceTransactionItem: action,
    updateUnitCost: action,
    updateQuantity: action,
    updateDescriptor: action,
    updateIsOverridden: action,
    updateOverriddenDescriptor: action,
    updateUnitPrice: action,
    updateIsCustomDiscount: action,
    updateDiscount: action,
    updateDiscountRate: action,
    updateDiscountAmount: action,
    updateApplyTax: action,
    updateTotal: action,
    updatePurchaseTotal: action,
    updatePurchaseTotalCost: action,
    updateReturnDestination: action,
    taxRate1: computed,
    taxRate2: computed,
    taxRate3: computed,
    hasDefaultTransactionItem: computed,
    validItems: computed,
    hasExpiringWorkOrders: computed,
})

export default createContext(new PurchaseUpdateStore()); 
