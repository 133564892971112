import React, { useContext, useEffect, useState, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { Observer } from 'mobx-react-lite';
import { GlobalHotKeys } from 'react-hotkeys';

import BodyEnd from '../../_shared/BodyEnd';
import ConfirmModal from '../../_shared/ConfirmModal';
import { quickDrawerFocus } from '../../_shared/QuickDrawer';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import RichTextEditor from '../../_shared/RichTextEditor';

import PurchaseUpdateStore from '../../../../stores/PurchaseUpdateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as ch from '../../../../utilities/customerHelper';
import * as oh from '../../../../utilities/operationHelper';
import * as bh from '../../../../utilities/badgeHelper';
import * as ph from '../../../../utilities/personHelper';
import * as ah from '../../../../utilities/addressHelper';

import './VoidPurchase.scss';

function VoidPurchase(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const purchase = useContext(PurchaseUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [confirmVoid, setConfirmVoid] = useState(false);

    useEffect(() => {
        quickDrawerFocus(props.drawer);

        return () => {
            isMounted.current = false;
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (purchase.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleReturnDestinationChange = (event, { groupId }) => {
        const value = event.target.value;
        purchase.updateReturnDestination(groupId, value);
        purchase.hasUnsavedChanges = true;
    }

    const getReturnDestination = ({ groupId, isFromInventory }) => {
        let index = purchase.returnDestinations.findIndex(r => r.groupId === groupId);

        if (index < 0) {
            if (isFromInventory) {
                purchase.updateReturnDestination(groupId, 'Inventory');
            } else {
                purchase.updateReturnDestination(groupId, '');
            }
            index = 0;
        }

        purchase.hasUnsavedChanges = true;

        return purchase.returnDestinations[index].returnDestination;
    }

    const handleReasonChange = (content, delta, source, editor) => {
        const text = editor.getText().replace(/(\r\n|\n|\r)/g, ' ').trim().substring(0, 200);
        const html = content;

        purchase.voidReason = (text ? text : null);
        purchase.voidReasonHtml = ((html === '<p><br></p>') ? null : html);
        purchase.hasUnsavedChanges = true;
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            setConfirmVoid(true);
        }
    }

    const handleConfirmVoid = event => {
        purchase.voidPurchase(true)
            .then((data) => {
                if (isMounted.current) {
                    setConfirmVoid(false);
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, { requiredVerification: data.requiredVerification, data: purchase.data });
                    }
                }
            })
    }

    const handleConfirmCancel = event => {
        setConfirmVoid(false);
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) && !confirmVoid ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <Observer>{() =>
                <fieldset disabled={purchase.isSaving}>
                    <div className='void-purchase-container quick-drawer'>
                        <QuickDrawerHeader
                            drawer={props.drawer}
                            icon={oh.getIcon('purchase', 'void-purchase')}
                            action='Purchase'
                            category='Void Transaction'
                            className='delete'
                            onCancel={props.onCancel}
                        />
                        <div className='quick-drawer-body'>
                            <FadeIn>
                                <div className='body-content'>
                                    <Observer>{() =>
                                        <>
                                            {
                                                purchase.verifyReturnItems && purchase.verifyReturnItems.length > 0 ?
                                                    <>
                                                        <section>
                                                            <div className='row'>
                                                                <div className='col-6'>
                                                                    <h3 className='purchase-title'>Invoice Detail</h3>
                                                                    <p>Invoice # <strong>{purchase.data.invoiceNumber}</strong></p>
                                                                </div>
                                                                <div className='col-6'>
                                                                    <div className='text-right'>
                                                                        {bh.renderPurchaseBalanceStatus(purchase.data, 'text-truncate text-truncate-md fs-sm p-2')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                        <Observer>{() =>
                                                            <>
                                                                {
                                                                    purchase.data.customer ?
                                                                        <section className='customer'>
                                                                            <div className='row'>
                                                                                <div className='col-12'>
                                                                                    <label><small>Billing Information</small></label>
                                                                                </div>
                                                                            </div>
                                                                            <div className='row'>
                                                                                <div className='col-12'>
                                                                                    <Observer>{() =>
                                                                                        <div
                                                                                            className='profile-wrapper'
                                                                                        >
                                                                                            <div className='profile'>
                                                                                                <span
                                                                                                    className={`profile-image profile-initials rounded-circle d-flex text-white ${ch.getProfileColor(purchase.data.customer)} fw-500`}
                                                                                                    title={purchase.data.customer.fullName}
                                                                                                >
                                                                                                    {purchase.data.customer.initials}
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className='description flex-1'>
                                                                                                <div className='name'>{ph.getFullName(purchase.data.customer, true)}
                                                                                                    {
                                                                                                        purchase.data.customer.dateOfBirth || purchase.data.customer.sex || purchase.data.customer.gender || purchase.data.customer.pronoun ?
                                                                                                            <small className='text-nowrap ml-2'>({`${ph.getAge(purchase.data.customer.dateOfBirth)} ${ph.getSexGenderPronounDisplay(purchase.data.customer)}`.trim()})</small> : null
                                                                                                    }
                                                                                                </div>
                                                                                                {
                                                                                                    purchase.data.billingAddress ?
                                                                                                        <div className='info'>{ah.getAddressHtml(purchase.data.billingAddress)}</div> : null
                                                                                                }
                                                                                                {
                                                                                                    purchase.data.customerEmail ?
                                                                                                        <div className='info'>
                                                                                                            <a
                                                                                                                href={`mailto:${purchase.data.customerEmail}`}
                                                                                                            >{purchase.data.customerEmail}
                                                                                                            </a>
                                                                                                        </div> : null
                                                                                                }
                                                                                                {
                                                                                                    purchase.data.customerPhoneNumber ?
                                                                                                        <div className='info'>
                                                                                                            <a
                                                                                                                href={`tel:${purchase.data.customerPhoneNumber}`}
                                                                                                            >{purchase.data.customerPhoneNumber}
                                                                                                            </a>
                                                                                                        </div> : null
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    }</Observer>
                                                                                </div>
                                                                            </div>
                                                                        </section> : null
                                                                }
                                                            </>
                                                        }</Observer>
                                                        <section className='mb-0'>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <label className='required'><small>Return item(s)</small></label>
                                                                </div>
                                                            </div>
                                                        </section>
                                                        {
                                                            purchase.verifyReturnItems.map((i, ii) => {
                                                                return <section
                                                                    key={`return-item-${ii}`}
                                                                    className={'product-info' + (ii === 0 ? ' mt-0' : '')}
                                                                >
                                                                    <div className='row'>
                                                                        <div className='col-12'>
                                                                            <ul className='title'>
                                                                                <li className='quantity'>
                                                                                    <h4>
                                                                                        <strong>{i.quantity}<small>x</small></strong>
                                                                                    </h4>
                                                                                </li>
                                                                                <li className='detail has-quantity'>
                                                                                    <span className='text-gray'>{i.referenceType}</span>
                                                                                    <h4 className='m-0 text-truncate text-truncate-xxl'><strong>{i.descriptor}</strong></h4>
                                                                                    <small className='brand'><strong>{i.brandName}</strong> ({i.supplierName})</small>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className='col-12'>
                                                                            <div className='ml-4 mt-2'>
                                                                                <Observer>{() =>
                                                                                    <>
                                                                                        {
                                                                                            i.isFromInventory ?
                                                                                                <span className='fs-sm text-success'><strong>Note:</strong> This item was taken from inventory</span> :
                                                                                                <span className='fs-sm text-danger'><strong>Note:</strong> This item was not taken from inventory</span>
                                                                                        }
                                                                                        <select
                                                                                            className='custom-select form-control mt-2'
                                                                                            value={getReturnDestination(i)}
                                                                                            onChange={e => { handleReturnDestinationChange(e, i) }}
                                                                                        >
                                                                                            <option value=''>No action required</option>
                                                                                            <option value='Inventory'>Return to inventory</option>
                                                                                            <option value='Supplier'>Return to supplier</option>
                                                                                            <option value='Disposed'>Dispose</option>
                                                                                        </select>
                                                                                    </>
                                                                                }</Observer>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                            })
                                                        }
                                                    </> : null
                                            }
                                            <section>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='form-group validate validate-required'>
                                                            <label className='required'><small>Void Reason</small></label>
                                                            <div className='input-group'>
                                                                <Observer>{() =>
                                                                    <RichTextEditor
                                                                        mode='none'
                                                                        disableTab={true}
                                                                        value={purchase.voidReasonHtml}
                                                                        onChange={handleReasonChange}
                                                                    />
                                                                }</Observer>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </>
                                    }</Observer>
                                </div>
                            </FadeIn>
                        </div>
                        <div className='quick-drawer-action'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='float-right'>
                                        <button
                                            type='button'
                                            className='btn btn-link btn-cancel mr-2'
                                            onClick={props.onCancel}
                                        >Cancel</button>
                                        <Observer>{() =>
                                            <button
                                                type='submit'
                                                className='btn btn-danger'
                                            >Continue</button>
                                        }</Observer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            }</Observer>
        </form>
        <Observer>{() =>
            <BodyEnd>
                <ConfirmModal
                    icon={<i className={'text-danger mr-2 ' + (oh.getIcon('purchase', 'void-purchase'))}></i>}
                    message={<>Continue to void <span className='fw-500'>&nbsp; invoice # {purchase.data ? purchase.data.invoiceNumber : ''}</span>? </>}
                    descriptionClassName='warning-danger warning-flashing'
                    show={confirmVoid}
                    onOption1Click={handleConfirmVoid}
                    onCancel={handleConfirmCancel}
                />
            </BodyEnd>
        }</Observer>
    </>
}

export default VoidPurchase