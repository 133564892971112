import React, { Component } from 'react';
import { Multiselect } from 'react-widgets'

import * as fn from '../../../utilities/_functions';
import { REFERENCE_DATA } from '../../../constants/storageKeys';

import './ExamDiagnosticCodeList.scss';

export default class ExamDiagnosticCodeList extends Component {
    constructor(props) {
        super(props);
        this.multiSelectRef = React.createRef();
        this.state = {
            codes: [],
            selectedCodes: [],
            isReady: false,
        }
    }

    componentDidMount() {
        const that = this;
        const referenceData = JSON.parse(window.localStorage.getItem(REFERENCE_DATA));
        const diagnosticCodes = referenceData && referenceData.some(r => r.name === 'PublicInsuranceDiagnosticType') ? referenceData.filter(r => r.name === 'PublicInsuranceDiagnosticType')[0] : null;
        const codes = diagnosticCodes ? diagnosticCodes.options : [];
        const selectedCodes = this.props.selectedCodes ? codes.filter(c => this.props.selectedCodes.some(s => s === c.key)) : [];
        this.setState({ codes: codes, selectedCodes: selectedCodes, isReady: true }, () => {
            if (that.props.dataAttributes && that.multiSelectRef.current) {
                const input = that.multiSelectRef.current.querySelector('input');
                if (input) {
                    for (let i = 0; i < that.props.dataAttributes.length; i++) {
                        const attribute = that.props.dataAttributes[i];
                        input.setAttribute(attribute.name, attribute.value);
                    }
                }
            }
        });
    }

    componentWillUnmount() {
        this.setState({ codes: null, selectedCodes: null, isReady: null });
    }

    handleDiagnosticCodeSelection = (tag, metadata) => {
        const { selectedCodes } = this.state;

        switch (metadata.action) {
            case 'insert':
                if (!selectedCodes.some(c => c.key === metadata.dataItem.key)) {
                    selectedCodes.push(metadata.dataItem);
                    this.setState({ selectedCodes: selectedCodes });

                    if (fn.isFunction(this.props.onChange)) {
                        this.props.onChange(selectedCodes.map(c => { return c.key }));
                    }
                }
                break;

            case 'remove':
                const index = selectedCodes.findIndex(c => c.key === metadata.dataItem.key);
                if (index !== -1) {
                    selectedCodes.splice(index, 1);
                    this.setState({ selectedCodes: selectedCodes });

                    if (fn.isFunction(this.props.onChange)) {
                        this.props.onChange(selectedCodes.map(c => { return c.key }));
                    }
                }
                break;

            default:
                break;
        }
    }

    handleFilterDiagnosticCodes = (dataItem, searchTerm) => {
        return dataItem.key.toLowerCase().indexOf(searchTerm) >= 0 || dataItem.value.toLowerCase().indexOf(searchTerm) >= 0;
    }

    render() {
        const { codes, selectedCodes, isReady } = this.state;

        return <div
            ref={this.multiSelectRef}>
            {
                isReady ?
                    <Multiselect
                        allowCreate={false}
                        data={codes}
                        valueField='key'
                        textField='value'
                        defaultValue={selectedCodes}
                        readOnly={this.props.readOnly === true}
                        filter={this.handleFilterDiagnosticCodes}
                        tagComponent={({ item }) => (
                            <span
                                className='tag'
                                title={item.value}
                            >
                                <span className='fw-500 text-truncate text-truncate-sm'>{item.key} - {item.value}</span>
                            </span>
                        )}
                        itemComponent={({ item }) => (
                            <span
                                title={item.value}
                            >
                                {item.key} - {item.value}
                            </span>
                        )}
                        onChange={this.handleDiagnosticCodeSelection}
                    /> : null
            }
        </div>
    }
}