import { createContext } from 'react';
import { decorate, observable, action } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';
import moment from 'moment';

export class BusinessScheduleUpdate {
    data = null;
    effectiveStarting = null;
    isReady = false;
    isSaving = false;
    isLoading = false;
    hasUnsavedChanges = false;
    cancelBusinessSchedule = null;
    cancelBusinessScheduleUpdate = null;

    initialize = () => {
        const that = this;
        this.clear();
        this.effectiveStarting = moment().startOf('day');

        return new Promise((resolve, reject) => {
            that.refresh()
                .then(() => {
                    resolve();
                })
                .catch(error => {
                    reject(error);
                })
                .finally(() => {
                    that.isReady = true;
                });
        })
    }

    refresh = () => {
        const that = this;
        that.isLoading = true;

        return new Promise((resolve, reject) => {
            api.BusinessSchedules.get((c) => { that.cancelBusinessSchedule = c })
                .then(({ data }) => {
                    that.data = data;
                    resolve();
                })
                .catch(error => {
                    reject(error);
                })
                .finally(() => {
                    that.isLoading = false;
                })
        })
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            if (that.hasUnsavedChanges) {
                const option = {
                    effectiveStarting: that.effectiveStarting,
                    days: that.data.filter(d => !d.isClosed).map(d => {
                        return {
                            dayOfWeek: d.dayOfWeek,
                            startTime: !d.isClosed ? d.start : null,
                            endTime: !d.isClosed ? d.end : null,
                            isClosed: d.isClosed,
                        }
                    })
                }

                api.BusinessSchedules.save(option, (c) => { that.cancelBusinessScheduleUpdate = c })
                    .then(() => {
                        that.hasUnsavedChanges = false;
                        resolve();
                    })
                    .catch(error => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                that.hasUnsavedChanges = false;
                that.isSaving = false;
                resolve();
            }
        })
    }

    clear = () => {
        this.data = null;
        this.effectiveStarting = null;
        this.isReady = false;
        this.isSaving = false;
        this.isLoading = false;
        this.hasUnsavedChanges = false;

        if (fn.isFunction(this.cancelBusinessSchedule)) {
            this.cancelBusinessSchedule();
            this.cancelSchedule = null;
        }

        if (fn.isFunction(this.cancelBusinessScheduleUpdate)) {
            this.cancelBusinessScheduleUpdate();
            this.cancelBusinessScheduleUpdate = null;
        }
    }
}

decorate(BusinessScheduleUpdate, {
    data: observable,
    effectiveStarting: observable,
    isReady: observable,
    isSaving: observable,
    isLoading: observable,
    hasUnsavedChanges: observable,
    initialize: action,
    refresh: action,
    save: action,
    clear: action,
})

export default createContext(new BusinessScheduleUpdate());