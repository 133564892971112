import React, { useRef, useEffect, useContext } from 'react';
import { useObserver } from 'mobx-react-lite';

import PublicInsuranceVerificationModalStore from '../../../stores/PublicInsuranceVerificationModalStore';

function NavbarPublicInsurance() {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const modal = useContext(PublicInsuranceVerificationModalStore);

    useEffect(() => {
        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }

    }, []) // eslint-disable-line

    const handlePublicInsuranceVerificationShow = () => {
        modal.initialize();
    }

    return useObserver(() => <>
        <div className={'d-flex align-items-center justify-content-center ml-2 pt-h'}>
            <button
                type='button'
                className='btn btn-icon header-icon'
                onClick={handlePublicInsuranceVerificationShow}
                title='Check eligibility'
            >
                <i className='fal fa-id-card'></i>
            </button>
        </div>
    </>)
}

export default NavbarPublicInsurance;