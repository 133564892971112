export const ACCESS_TOKEN = '__ACCESS_TOKEN__';
export const ALL_USERS = '__ALL_USERS__';
export const AUTHORIZATION_KEY = '__AUTHORIZATION_KEY__';
export const AUTHORIZATION_TOKEN = '__AUTHORIZATION_TOKEN__';
export const CACHE_LOADED = '__CACHE_LOADED__';
export const CLIENT_IP = '__CLIENT_IP__';
export const CURRENT_USER = '__CURRENT_USER__';
export const SESSION_ID = '__SESSION_ID__';
export const EXPIRES_AT = '__EXPIRES_AT__';
export const IS_LOCK_READY = '__IS_LOCK_READY__';
export const IS_LOCKED = '__IS_LOCKED__';
export const LOGGED_IN_USER = '__LOGGED_IN_USER__';
export const NEXT_RENEWAL = '__NEXT_RENEWAL__';
export const RESOURCES = '__RESOURCES__';
export const CURRENT_TENANT = '__TENANT__';
export const SERVICES = '__SERVICES__';
export const SETTINGS = '__SETTINGS__';
export const TAX_RATES = '__TAX_RATES__';
export const CALLBACK_URL = '__CALLBACK_URL__';
export const ALL_GROUPS = '__ALL_GROUPS__';
export const REDIRECT_URL = '__REDIRECT_URL__';
export const REDIRECT_URL_EXPIRES_AT = '__REDIRECT_URL_EXPIRES_AT__';
export const REFRESH_LATER_EXPIRES_AT = '__REFRESH_LATER_EXPIRES_AT__';
export const PLANNED_MAINTENANCE_LATER_EXPIRES_AT = '__PLANNED_MAINTENANCE_LATER_EXPIRES_AT__';
export const APPOINTMENT_CONFIRMED_CONTENT = '__APPOINTMENT_CONFIRMED_CONTENT__';

// Not to be cleared
export const REFERENCE_DATA = '__REFERENCE_DATA__';
export const REPORT_PREFS_DASHBOARD_PERIOD_TYPE = '__REPORT_PREFS_DASHBOARD_PERIOD_TYPE__';
export const REPORT_PREFS_DASHBOARD_PERIOD_COMPARE_TYPE = '__REPORT_PREFS_DASHBOARD_COMPARISON__';
export const REPORT_PREFS_AGE_GROUPS = '__REPORT_PREFS_AGE_GROUPS__';
export const LAST_LOGIN_URL = '__LAST_LOGIN_URL__';
export const DEVICE_COMPUTER = '__DEVICE_COMPUTER__';
export const SHOW_DATAGRID_HEADER_FILTER = '__SHOW_DATAGRID_HEADER_FILTER__';
export const SHOW_DATAGRID_FILTER_ROW = '__SHOW_DATAGRID_FILTER_ROW__';
export const ATTACHED_DEVICES = '__ATTACHED_DEVICES__';
export const ATTACHED_DEVICES_LAST_UPDATED = '__ATTACHED_DEVICES_LAST_UPDATED__';

// These are the only fields that needs to be cleared upon logout. Don't use window.localStorage to clear everything
// User preference fields should not be cleared upon logout.
export const CLEAR_FIELDS = [
    ACCESS_TOKEN,
    ALL_USERS,
    AUTHORIZATION_KEY,
    AUTHORIZATION_TOKEN,
    CACHE_LOADED,
    CLIENT_IP,
    CURRENT_USER,
    SESSION_ID,
    EXPIRES_AT,
    IS_LOCK_READY,
    IS_LOCKED,
    LOGGED_IN_USER,
    NEXT_RENEWAL,
    //REFERENCE_DATA, // might have caused reference data error in customer Profile.js
    RESOURCES,
    CURRENT_TENANT,
    SERVICES,
    SETTINGS,
    TAX_RATES,
    CALLBACK_URL,
    REDIRECT_URL,
    REDIRECT_URL_EXPIRES_AT,
    REFRESH_LATER_EXPIRES_AT,
    PLANNED_MAINTENANCE_LATER_EXPIRES_AT,
]
