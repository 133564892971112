import React, { useEffect, useContext, useState, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { GlobalHotKeys } from 'react-hotkeys';
import { useObserver } from 'mobx-react-lite';
import { toJS } from 'mobx';

import QuickDrawerHeader from '../../../../_shared/QuickDrawerHeader';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../../../_shared/QuickDrawer';

import FormTemplateUpdateStore from '../../../../../../stores/FormTemplateUpdateStore';
import QuickDrawerStore from '../../../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../../../constants/errorMessages';
import * as fn from '../../../../../../utilities/_functions';
import * as oh from '../../../../../../utilities/operationHelper';

import api from '../../../../../../api';

import './ReorderFormPage.scss';

function ReorderFormPage(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const formTemplate = useContext(FormTemplateUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [ready, setReady] = useState(false);
    const [pages, setPages] = useState([]);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    useEffect(() => {
        setPages(toJS([...formTemplate.sortedPages]));
        focusTimer.current = setTimeout(() => {
            api.Roles.all()
                .then(({ data }) => {
                    if (isMounted.current) {
                        quickDrawerFocus(props.drawer);
                        setReady(true);
                    }
                })
        }, 100)
        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = event => {
        event.preventDefault();

        formTemplate.data.pages = pages;
        formTemplate.hasUnsavedChanges = true;

        formTemplate.save()
            .then(() => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess();
                    }
                }
            })
    }

    const handlePageOrderChange = (event, { id }, value) => {
        let newPages = [...pages];
        const index = newPages.findIndex(s => s.id === id);

        if (index >= 0) {
            const displayOrder = newPages[index].displayOrder;
            const newDisplayOrder = newPages[index].displayOrder + value;
            const flipIndex = newPages.findIndex(s => s.id !== id && s.displayOrder === newDisplayOrder);

            newPages[index].displayOrder = newDisplayOrder;

            if (flipIndex >= 0) {
                newPages[flipIndex].displayOrder = displayOrder;
            }

            newPages = newPages.sort((a, b) => { return a.displayOrder - b.displayOrder });
            setPages(newPages);
            setHasUnsavedChanges(true);
        }
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form onSubmit={handleSubmit}>
            <fieldset disabled={formTemplate.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('form-page', 'reorder')}
                        action='Reorder'
                        category='Form Pages'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        {
                            ready ?
                                <FadeIn>
                                    <div className='new-form-page body-content'>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label htmlFor='new-form-page'><small>Pages</small></label>
                                                        {
                                                            pages.length > 0 ?
                                                                <div className='border-bottom'>
                                                                    {
                                                                        pages.map((s, si) => {
                                                                            return <div
                                                                                key={`sorted_form_page_${si}`}
                                                                                className='border border-bottom-0 d-flex p-2'
                                                                            >
                                                                                <div className='d-flex align-items-center flex-1 ml-auto'><strong>{s.name}</strong></div>
                                                                                <button
                                                                                    type='button'
                                                                                    className='btn btn-icon btn-xs ml-1'
                                                                                    disabled={(si === 0)}
                                                                                    onClick={(e) => { handlePageOrderChange(e, s, -1) }}
                                                                                >
                                                                                    <i className={'fal fa-arrow-up ' + (si === 0 ? 'text-gray-500 cursor-not-allowed' : 'text-info')}></i>
                                                                                </button>
                                                                                <button
                                                                                    type='button'
                                                                                    className='btn btn-icon btn-xs ml-1'
                                                                                    disabled={(si === (pages.length - 1))}
                                                                                    onClick={(e) => { handlePageOrderChange(e, s, 1) }}
                                                                                >
                                                                                    <i className={'fal fa-arrow-down ' + (si === (pages.length - 1) ? 'text-gray-500 cursor-not-allowed' : 'text-info')}></i>
                                                                                </button>
                                                                            </div>
                                                                        })
                                                                    }
                                                                </div> : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </FadeIn> : renderQuickDrawerLoading()
                        }
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-4'></div>
                            <div className='col-8'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>)
}

export default ReorderFormPage;