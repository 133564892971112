import React, { useEffect, useContext, useState, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { GlobalHotKeys } from 'react-hotkeys';
import { useObserver } from 'mobx-react-lite';

import QuickDrawerHeader from '../../../../_shared/QuickDrawerHeader';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../../../_shared/QuickDrawer';

import ExamTemplateUpdateStore from '../../../../../../stores/ExamTemplateUpdateStore';
import QuickDrawerStore from '../../../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../../../constants/errorMessages';
import * as fn from '../../../../../../utilities/_functions';
import * as oh from '../../../../../../utilities/operationHelper';

import api from '../../../../../../api';

import './NewExamSection.scss';

function NewExamSection(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const examTemplate = useContext(ExamTemplateUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [ready, setReady] = useState(false);
    const [name, setName] = useState('');
    const [roles, setRoles] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [isHiddenWhenEmpty, setIsHiddenWhenEmpty] = useState(false);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    useEffect(() => {
        focusTimer.current = setTimeout(() => {
            api.Roles.all()
                .then(({ data }) => {
                    if (isMounted.current) {
                        setRoles(data);
                        quickDrawerFocus(props.drawer);
                        setReady(true);
                    }
                })
        }, 100)

        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = event => {
        event.preventDefault();
        examTemplate.hasUnsavedChanges = true;
        examTemplate.addSection(name, selectedRoles, isHiddenWhenEmpty)
            .then(() => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        setHasUnsavedChanges(false);
                        props.onSuccess();
                    }
                }
            })
    }

    const handleNameChange = event => {
        setName(event.target.value);
        setHasUnsavedChanges(true);
    }

    const handleSelectRole = event => {
        const id = event.target.value;
        const tmpSelectedRoles = [...selectedRoles];
        const index = tmpSelectedRoles.findIndex(r => r === id);

        if (index < 0) {
            tmpSelectedRoles.push(id);
        } else {
            tmpSelectedRoles.splice(index, 1);
        }

        setSelectedRoles(tmpSelectedRoles);
        setHasUnsavedChanges(true);
    }

    const handleIsHiddenWhenEmptyChange = (event, value) => {
        setIsHiddenWhenEmpty(value);
        setHasUnsavedChanges(true);
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form onSubmit={handleSubmit}>
            <fieldset disabled={examTemplate.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('exam-section', 'add')}
                        action='New'
                        category='Exam Tab'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        {
                            ready ?
                                <FadeIn>
                                    <div className='new-exam-section body-content'>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-2'>
                                                        <label className='required' htmlFor='new-exam-section-name'><small>Name</small></label>
                                                        <input
                                                            id='new-exam-section-name'
                                                            type='text'
                                                            maxLength={100}
                                                            className='form-control'
                                                            autoComplete={'off'}
                                                            value={name ? name : ''}
                                                            onChange={handleNameChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0'>
                                                        <label><small>Who has access?</small></label>
                                                        <div
                                                            className='custom-control custom-checkbox mb-2'
                                                        >
                                                            <input
                                                                id={`role-everyone`}
                                                                type='checkbox'
                                                                name='role'
                                                                className='custom-control-input'
                                                                value={''}
                                                                checked={selectedRoles.length === 0}
                                                                onChange={() => { setSelectedRoles([]) }}
                                                            />
                                                            <label
                                                                htmlFor={`role-everyone`}
                                                                className='custom-control-label'
                                                            >
                                                                Everyone
                                                            </label>
                                                        </div>
                                                        {
                                                            roles && roles.length > 0 ?
                                                                roles.filter(r => !r.hasFullAccess).map((r, ri) => {
                                                                    return <div
                                                                        key={`role-${ri}`}
                                                                        className='custom-control custom-checkbox mb-2'
                                                                    >
                                                                        <input
                                                                            id={`role-${ri}`}
                                                                            type='checkbox'
                                                                            name='role'
                                                                            className='custom-control-input'
                                                                            value={r.id}
                                                                            checked={selectedRoles.some(s => s === r.id)}
                                                                            onChange={handleSelectRole}
                                                                        />
                                                                        <label
                                                                            htmlFor={`role-${ri}`}
                                                                            className='custom-control-label'
                                                                        >
                                                                            {r.name}
                                                                        </label>
                                                                    </div>
                                                                }) : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-2'>
                                                        <label htmlFor='new-exam-section-display'><small>Display</small></label>
                                                        <div className='custom-control custom-radio mb-2'>
                                                            <input
                                                                id='new-exam-section-display-always'
                                                                type='radio'
                                                                name='new-exam-section-display'
                                                                className='custom-control-input'
                                                                checked={!isHiddenWhenEmpty}
                                                                onChange={(e) => { handleIsHiddenWhenEmptyChange(e, false) }}
                                                            />
                                                            <label
                                                                htmlFor='new-exam-section-display-always'
                                                                className='custom-control-label'
                                                            >
                                                                Always show tab
                                                            </label>
                                                        </div>
                                                        <div className='custom-control custom-radio mb-2'>
                                                            <input
                                                                id='new-exam-section-display-hide'
                                                                type='radio'
                                                                name='new-exam-section-display'
                                                                className='custom-control-input'
                                                                checked={isHiddenWhenEmpty}
                                                                onChange={(e) => { handleIsHiddenWhenEmptyChange(e, true) }}
                                                            />
                                                            <label
                                                                htmlFor='new-exam-section-display-hide'
                                                                className='custom-control-label'
                                                            >
                                                                Hide this tab when empty
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </FadeIn> : renderQuickDrawerLoading()
                        }
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-4'></div>
                            <div className='col-8'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                    >Add</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>)
}

export default NewExamSection;