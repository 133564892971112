import React from 'react';

import * as fn from './_functions';
import * as fm from './formatHelper';

export const SIGNATURE_CANVAS_WIDTH = 400;
export const SIGNATURE_CANVAS_HEIGHT = 180;

const SIGNATURE_LINE_POS_Y_PERCENTAGE = 0.7;

export const PRINT_MESSAGE = <>
    <h2 className='text-uppercase text-center font-weight-bold text-gray-900 mb-1'>Generating...</h2>
    <p className='fs-xl'>Please wait while we generate the prescription.</p>
</>;

export const cropSignature = (signatureBase64, onCroppedCallback) => {
    const signatureCanvas = document.createElement('canvas');
    const signatureContext = signatureCanvas.getContext('2d');
    const signatureImg = new Image(SIGNATURE_CANVAS_WIDTH, SIGNATURE_CANVAS_HEIGHT);
    let signatureImgLoaded = false;

    signatureImg.src = signatureBase64;

    signatureCanvas.width = SIGNATURE_CANVAS_WIDTH;
    signatureCanvas.height = SIGNATURE_CANVAS_HEIGHT;

    signatureImg.onload = () => {
        signatureContext.drawImage(signatureImg, 0, 0, SIGNATURE_CANVAS_WIDTH, SIGNATURE_CANVAS_HEIGHT);
        signatureImgLoaded = true;

        if (signatureImgLoaded) {
            let width = SIGNATURE_CANVAS_WIDTH, height = SIGNATURE_CANVAS_HEIGHT, x, y, index, base64;
            const imageData = signatureContext.getImageData(0, 0, SIGNATURE_CANVAS_WIDTH, SIGNATURE_CANVAS_HEIGHT);
            const picture = { x: [], y: [] };

            for (y = 0; y < height; y++) {
                for (x = 0; x < width; x++) {
                    index = (y * width + x) * 4;
                    if (imageData.data[index + 3] > 0) {
                        picture.x.push(x);
                        picture.y.push(y);
                    }
                }
            }

            picture.x.sort((a, b) => { return a - b });
            picture.y.sort((a, b) => { return a - b });

            const lines = picture.x.length - 1;

            width = 1 + picture.x[lines] - picture.x[0];
            height = 1 + picture.y[lines] - picture.y[0];

            const croppedImageData = signatureContext.getImageData(picture.x[0], picture.y[0], width, height);

            signatureCanvas.width = width;
            signatureCanvas.height = height;
            signatureContext.putImageData(croppedImageData, 0, 0);

            base64 = signatureCanvas.toDataURL('image/png');
            
            if (onCroppedCallback && fn.isFunction(onCroppedCallback)) {
                onCroppedCallback({
                    base64: base64,
                    width: width,
                    height: height
                });
            }
        }
    }
}

export const getSignatureLineBase64 = (user, signed, options) => {
    let result, emptySignatureLine;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const defaults = {
        lineWidth: 1,
        fontColor: '#000000',
        fontFamily: 'arial',
        fontSize: 13,
        scale: 1.0,
        hasLine: true,
        hasDescription: true,
        description1Format: 'FirstLastName',
        description2Format: 'LicenseOnly',
        signedCallback: null,
    };
    options = { ...defaults, ...options }

    const height = SIGNATURE_CANVAS_HEIGHT * options.scale;
    const width = SIGNATURE_CANVAS_WIDTH * options.scale;
    const linePositionY = height * SIGNATURE_LINE_POS_Y_PERCENTAGE;

    options.lineWidth = options.lineWidth * options.scale;
    options.fontSize = options.fontSize * options.scale;

    canvas.width = width;
    canvas.height = height;

    // Draw signature line
    if (options.hasLine) {
        context.strokeStyle = options.fontColor;
        context.lineWidth = options.lineWidth;
        context.beginPath();
        context.moveTo(0, linePositionY);
        context.lineTo(canvas.width, linePositionY);
        context.stroke();
    }

    if (options.hasDescription && user) {
        let indentX = 0;
        let textY = (linePositionY + (options.fontSize + (options.hasLine ? options.lineWidth : 0) + (options.fontSize)));
        const line1 = fm.apply(user, options.description1Format);
        const line2 = fm.apply(user, options.description2Format);

        context.font = `${options.fontSize}px ${options.fontFamily}`;

        if (line1) {
            context.fillStyle = options.fontColor;
            context.fillText(line1, indentX, textY);

            textY = textY + options.fontSize;
        }

        context.fillStyle = options.fontColor;
        context.fillText(line2, indentX, textY);
    }

    emptySignatureLine = canvas.toDataURL('image/png');

    if (signed && user.signature) {
        const signedCanvas = document.createElement('canvas');
        const signedContext = signedCanvas.getContext('2d');
        const signatureLineImg = new Image(width, height);
        const signatureImg = new Image(width, height);
        let signatureLineImgLoaded = false;
        let signatureImgLoaded = false;

        signatureLineImg.src = emptySignatureLine;
        signatureImg.src = user.signature;

        signedCanvas.width = width;
        signedCanvas.height = height;

        signatureLineImg.onload = () => {
            signedContext.drawImage(signatureLineImg, 0, 0, width, height);
            signatureLineImgLoaded = true;

            if (signatureLineImgLoaded && signatureImgLoaded) {
                result = signedCanvas.toDataURL('image/png');

                if (options.signedCallback && fn.isFunction(options.signedCallback)) {
                    options.signedCallback(result);
                }
            }
        }

        signatureImg.onload = () => {
            signedContext.drawImage(signatureImg, 0, 0, width, height);
            signatureImgLoaded = true;

            if (signatureLineImgLoaded && signatureImgLoaded) {
                result = signedCanvas.toDataURL('image/png');

                if (options.signedCallback && fn.isFunction(options.signedCallback)) {
                    options.signedCallback(result);
                }
            }
        }
    } else {
        result = emptySignatureLine;
    }

    return result;
}