import React, { useEffect, useContext, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { Observer } from 'mobx-react-lite';
import MaskedInput from 'react-text-mask'
import { GlobalHotKeys } from 'react-hotkeys';

import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import { quickDrawerFocus } from '../../_shared/QuickDrawer';
import HtmlEditor from '../../_shared/HtmlEditor';
import AddressInput from '../../_shared/AddressInput';

import ExternalClinicCreateStore from '../../../../stores/ExternalClinicCreateStore';
import AuthStore from '../../../../stores/AuthStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as MaskKeys from '../../../../constants/maskKeys';
import * as fn from '../../../../utilities/_functions';
import * as oh from '../../../../utilities/operationHelper';

import './AddExternalClinic.scss';

function AddExternalClinic(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const searchTimer = useRef(null);
    const focusTimer = useRef(null);
    const addressRef = useRef(null);
    const externalClinic = useContext(ExternalClinicCreateStore);
    const auth = useContext(AuthStore);
    const quickDrawer = useContext(QuickDrawerStore);

    useEffect(() => {
        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100)

        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
            if (searchTimer.current) { clearTimeout(searchTimer.current); searchTimer.current = null; }
            externalClinic.clear();
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (externalClinic.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current) && addressRef.current.validate()) {
            externalClinic.save()
                .then((data) => {
                    if (isMounted.current) {
                        if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                            props.onSuccess(event, { updated: true, data: data });
                        }
                    }
                })
        }
    }

    const handleAddressChange = (data) => {
        externalClinic.data.address = data;
        externalClinic.hasUnsavedChanges = true;
    }

    const handleNameChange = (event) => {
        const value = event.target.value;

        externalClinic.data.name = value;
        externalClinic.hasUnsavedChanges = true;
    }

    const handlePhoneNumberChange = (event) => {
        const value = event.target.value;

        externalClinic.data.phoneNumber = value;
        externalClinic.hasUnsavedChanges = true;
    }

    const handleFaxNumberChange = (event) => {
        const value = event.target.value;

        externalClinic.data.faxNumber = value;
        externalClinic.hasUnsavedChanges = true;
    }

    const handleEmailAddressChange = (event) => {
        const value = event.target.value;

        externalClinic.data.emailAddress = value;
        externalClinic.hasUnsavedChanges = true;
    }

    const handleWebsiteUrlChange = (event) => {
        const value = event.target.value;

        externalClinic.data.websiteUrl = value;
        externalClinic.hasUnsavedChanges = true;
    }

    const handleNoteChange = (element) => {
        const newNoteHtml = element.value;
        externalClinic.data.noteHtml = newNoteHtml;
    }

    return <>
        {
            (props.drawer === quickDrawer.drawerOpened) ?
                <GlobalHotKeys
                    keyMap={{
                        close: ['esc'],
                    }}
                    handlers={{
                        close: event => {
                            handleCancel(event)
                        },
                    }}
                    allowChanges={true}
                /> : null
        }
        <form ref={validateRef} onSubmit={handleSubmit}>
            <fieldset disabled={externalClinic.isSaving}>
                <div className='add-external-clinic-container quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('external-clinic', 'default')}
                        action='Add new'
                        category='External Clinic'
                        className='external-clinics'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        <FadeIn>
                            <div className='body-content'>
                                <section>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='form-group mb-0 validate validate-required'>
                                                <label className='required' htmlFor='new-external-clinic-name'><small>Name</small></label>
                                                <Observer>{() => <>
                                                    <input
                                                        id='new-external-clinic-name'
                                                        type='text'
                                                        className='form-control'
                                                        spellCheck={true}
                                                        maxLength='255'
                                                        autoComplete='off'
                                                        value={externalClinic.data.name ? externalClinic.data.name : ''}
                                                        onChange={handleNameChange}
                                                    />
                                                </>}</Observer>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <AddressInput
                                        ref={addressRef}
                                        address={(externalClinic.data && externalClinic.data.address ? externalClinic.data.address : {})}
                                        label={'Address'}
                                        defaultCountryCode={(auth.currentUser && auth.currentTenant && auth.currentTenant.address ? auth.currentTenant.address.countryCode : null)}
                                        onChange={handleAddressChange}
                                    />
                                </section>
                                <section>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='form-group mb-0 validate validate-phone'>
                                                <label htmlFor='new-external-clinic-phone'><small>Phone</small></label>
                                                <Observer>{() => <>
                                                    <MaskedInput
                                                        id='new-external-clinic-phone'
                                                        type='text'
                                                        className='form-control'
                                                        spellCheck={false}
                                                        mask={MaskKeys.PHONE_MASK}
                                                        autoComplete='off'
                                                        value={externalClinic.data.phoneNumber ? externalClinic.data.phoneNumber : ''}
                                                        onChange={handlePhoneNumberChange}
                                                    // onBlur={checkConflicts}
                                                    />
                                                </>}</Observer>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='form-group mb-0 validate validate-phone'>
                                                <label htmlFor='new-external-clinic-fax'><small>Fax</small></label>
                                                <Observer>{() => <>
                                                    <MaskedInput
                                                        id='new-external-clinic-fax'
                                                        type='text'
                                                        className='form-control'
                                                        spellCheck={false}
                                                        mask={MaskKeys.PHONE_MASK}
                                                        autoComplete='off'
                                                        value={externalClinic.data.faxNumber ? externalClinic.data.faxNumber : ''}
                                                        onChange={handleFaxNumberChange}
                                                    // onBlur={checkConflicts}
                                                    />
                                                </>}</Observer>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='form-group mb-0 validate validate-email'>
                                                <label htmlFor='new-external-clinic-email'><small>Email</small></label>
                                                <Observer>{() => <>
                                                    <MaskedInput
                                                        id='new-external-clinic-email'
                                                        type='text'
                                                        className='form-control'
                                                        spellCheck={false}
                                                        mask={MaskKeys.EMAIL_MASK}
                                                        maxLength='150'
                                                        autoComplete='off'
                                                        value={externalClinic.data.emailAddress ? externalClinic.data.emailAddress : ''}
                                                        onChange={handleEmailAddressChange}
                                                    // onBlur={checkConflicts}
                                                    />
                                                </>}</Observer>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='form-group mb-0'>
                                                <label htmlFor='new-external-clinic-website-url'><small>Website</small></label>
                                                <Observer>{() => <>
                                                    <input
                                                        id='new-external-clinic-website-url'
                                                        type='text'
                                                        className='form-control'
                                                        spellCheck={true}
                                                        maxLength='255'
                                                        autoComplete='off'
                                                        value={externalClinic.data.websiteUrl ? externalClinic.data.websiteUrl : ''}
                                                        onChange={handleWebsiteUrlChange}
                                                    />
                                                </>}</Observer>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='form-group mb-2'>
                                                <label htmlFor='new-external-clinic-note'><small>Note</small></label>
                                                <Observer>{() => <>
                                                    <HtmlEditor
                                                        disableTab={true}
                                                        onValueChanged={handleNoteChange}
                                                    />
                                                </>}</Observer>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </FadeIn>
                    </div>
                    <div className='quick-drawer-action pl-3'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>
}

export default AddExternalClinic;