import React, { Component } from 'react';
import { Popup } from 'devextreme-react';

import BodyEnd from '../../_shared/BodyEnd';
import ViewPurchase from './View';
import UpdatePurchase from './Update';

import * as fn from '../../../../utilities/_functions';

import './_index.scss';

const PURCHASE_POPUP_ID = '__purchase-popup';

const renderBalance = ({ isNew, data }) => {
    switch (data.status) {
        case "Voided":
            return <></>;

        default:
            if (isNew || data.remainingBalance !== 0) {
                return <tr>
                    <td><span className='text-danger fw-500'>Balance</span></td>
                    <td><span className={data.remainingBalance > 0 ? 'text-danger fw-500' : 'text-gray-700'}>{fn.formatCurrency(data.remainingBalance)}</span></td>
                </tr>
            }
    }
}

const getPurchaseCss = ({ data }) => {
    if (!data) return '';

    let override = '';

    if (data.status && (data.status.toLowerCase() === 'completed' || data.status.toLowerCase() === 'uncollectible')) {
        if (data.isNoCharge) {
            override += ' no-charge';
        } else if (data.isUncollectible) {
            override += ' uncollectible';
        } else if (data.isPaidInFull) {
            override += ' paid-in-full';
        }
    }

    if (data.remainingBalance > 0.0) {
        override += ' remaining-balance';
    }

    return `${(data.status ? data.status.toLowerCase() : '')}${override}`.trim();
}

export default class PurchaseModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            display: false,
            zIndex: null,
            mode: props.defaultMode,
            defaultMode: props.defaultMode,
            dueDatePickerOpen: false,
            renderRecenter: false,
        };
        this.popupRef = React.createRef();
        this.changeMode = this.changeMode.bind(this);
        this.handleModeChange = this.handleModeChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
        this.renderContent = this.renderContent.bind(this);
    }

    componentWillUnmount() {
        if (this.props.onClose) { this.props.onClose(); }
    }

    show = () => {
        const zIndex = fn.getHighestZIndex() + 1;
        this.setState({ display: true, zIndex: zIndex });
    }

    changeMode = mode => {
        this.handleModeChange(mode);
    }

    handleModeChange = mode => {
        this.setState({ mode: mode });
    }

    handleShowing = event => {
        const popup = document.getElementById(PURCHASE_POPUP_ID);
        if (popup) {
            // const { zIndex } = this.state;
            // popup.style.zIndex = zIndex;
        }
    }

    handleShown = event => {
        this.setState({ renderRecenter: true });
    }

    handleClose = event => {
        const popup = document.getElementById(PURCHASE_POPUP_ID);
        const { defaultMode } = this.state;

        if (popup) {
            // popup.style.zIndex = null;
        }

        this.setState({ mode: defaultMode, display: false, zIndex: null, renderRecenter: false });
    }

    handleSuccess = (event, result) => {
        if (fn.isFunction(this.props.onSuccess)) {
            this.props.onSuccess(event, result);
        }
    }

    recenter = () => {
        if (this.popupRef && this.popupRef.current) {
            this.popupRef.current.instance.option('position', {
                my: 'center',
                at: 'center',
                of: window,
            })
        }
    }

    renderTitle = () => {
        return <div className='popup-title-draggable'></div>
    }

    renderContent = () => {
        const { mode } = this.state;

        if (!mode) return null;

        const contentProps = {
            onModeChange: this.handleModeChange,
            onSuccess: this.handleSuccess,
            onClose: this.handleClose,
        };

        switch (mode.toLowerCase()) {
            case 'view':
                return <ViewPurchase {...contentProps} />;

            case 'update':
                return <UpdatePurchase {...contentProps} />;

            default:
                return;
        }
    }

    render() {
        return <>
            <Popup
                ref={this.popupRef}
                wrapperAttr={{ id: PURCHASE_POPUP_ID, class: 'dx-popup-purchase' }}
                animation={{
                    show: {
                        type: 'pop',
                        duration: 200,
                        from: { opacity: 0, scale: 0 },
                        to: { opacity: 1, scale: 1 }
                    },
                    hide: {
                        type: 'pop',
                        duration: 200,
                        from: { opacity: 1, scale: 1 },
                        to: { opacity: 0, scale: 0 }
                    }
                }}
                visible={this.state.display}
                shading={true}
                shadingColor="rgba(0, 0, 0, 0.2)"
                width={960}
                maxHeight={'95vh'}
                dragEnabled={true}
                dragOutsideBoundary={true}
                showTitle={true}
                titleRender={this.renderTitle}
                onShowing={this.handleShowing}
                onShown={this.handleShown}
                onHiding={this.handleClose}
            >
                {this.renderContent()}
            </Popup>
            {
                this.state.renderRecenter ?
                    <BodyEnd>
                        <button
                            type='button'
                            className='dx-popup-recenter'
                            style={{ zIndex: fn.getHighestZIndex() }}
                            title='Re-center'
                            onClick={() => { this.recenter() }}
                        >
                            <i className='fal fa-arrows'></i>
                        </button>
                    </BodyEnd> : null
            }
        </>
    }
}

export { getPurchaseCss, renderBalance };