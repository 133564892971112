import React from 'react';
import { useObserver } from 'mobx-react-lite';

function QuickDrawerHeader(props) {
    return useObserver(() => <>
        <div className='quick-drawer-header'>
            <div className={`header-content${(props.className ? ` ${props.className}` : '')}`}>
                <div className='title'>
                    <i className={'title-icon' + (props.icon ? ` ${props.icon}` : '')}></i>
                    <div className='subject'>
                        <span className='action'>{props.action}</span>
                        <span className='category'>{props.category}</span>
                    </div>
                </div>
                <button
                    data-quickdraw-header-close
                    type='button'
                    className='btn btn-icon btn-close'
                    onClick={props.onCancel}
                >
                    <i className='close-icon fal fa-times'></i>
                </button>
            </div>
        </div>
    </>)
}

export default QuickDrawerHeader;