import React, { useEffect, useContext, useRef, useState } from 'react';
import { Observer } from 'mobx-react-lite';
import { usePageVisibility } from 'react-page-visibility';
import { ScrollView } from 'devextreme-react';
import { Mention } from 'devextreme-react/html-editor';
import { GlobalHotKeys } from 'react-hotkeys';
import { toast } from 'react-toastify';
import TimeAgo from 'react-timeago';
import moment from 'moment';

import HtmlEditor from '../../_shared/HtmlEditor';
import BodyEnd from '../../_shared/BodyEnd';
import ConfirmModal from '../../_shared/ConfirmModalComponent';

import AuthStore from '../../../../stores/AuthStore';
import CacheStore from '../../../../stores/CacheStore';
import TodoUpdateStore from '../../../../stores/TodoUpdateStore';
import TodoInstanceViewStore from '../../../../stores/TodoInstanceViewStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import useSignalR from '../../../hooks/useSignalR';

import * as fn from '../../../../utilities/_functions';
import * as bh from '../../../../utilities/badgeHelper';
import * as th from '../../../../utilities/todoHelper';
import * as uh from '../../../../utilities/userHelper';
import * as sys from '../../../../utilities/systemHelper';

function ViewTodo(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const scrollViewRef = useRef(null);
    const noteRef = useRef(null);
    const newNoteHtml = useRef(null);
    const confirmModalRef = useRef(null);
    const isPageVisible = useRef(null);
    const pageVisibility = usePageVisibility();
    const auth = useContext(AuthStore);
    const cache = useContext(CacheStore);
    const todoUpdate = useContext(TodoUpdateStore);
    const todoInstance = useContext(TodoInstanceViewStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const signalR = useSignalR();
    const [allActiveUsersGroups, setAllActiveUsersGroups] = useState([]);
    const [forceReload, setForceReload] = useState(false);

    useEffect(() => {
        const users = cache.activeUsers.filter(u => u.id !== auth.currentUser.id && !u.isSystemUser).map(s => ({
            id: s.id,
            name: uh.getDisplayFullName(s),
            mention: uh.getDisplayShortName(s).replace(/[\W_]+/g, ''),
            type: 'user'
        }));
        const groups = cache.groups.map(s => ({
            id: s.id,
            name: `${s.name}`,
            mention: s.name.replace(/[\W_]+/g, ''),
            type: 'group'
        }));

        setAllActiveUsersGroups([...users.sort((a, b) => a.name.localeCompare(b.name)), ...groups.sort((a, b) => a.name.localeCompare(b.name))]);

        todoInstance.initialize(props.item.id);

        isPageVisible.current = pageVisibility;

        signalR.on('Todo', async ({ instanceIds }) => {
            if (instanceIds && instanceIds.some(i => i === todoInstance.id)) {
                if (isPageVisible.current) {
                    try {
                        await todoInstance.refresh();
                    }
                    catch {
                        handleClose();
                    }
                } else if (isMounted.current) {
                    setForceReload(true);
                }
            }
        });

        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        if (pageVisibility && forceReload) {
            if (isPageVisible.current) {
                todoInstance.refresh()
                    .then(() => {
                        setForceReload(false);
                    })
            }
        }
        isPageVisible.current = pageVisibility;
    }, [pageVisibility]) // eslint-disable-line

    const handleWorkingOnItClick = (e) => {
        todoInstance.status('InProgress');
    }

    const handleNotWorkingOnItClick = (e) => {
        todoInstance.status('Pending');
    }

    const handleReopenClick = (e) => {
        confirmModalRef.current.show({
            icon: <i className='fal fa-tasks text-warning mr-2'></i>,
            message: <>Continue to&nbsp;<span className='fw-500'>reopen this task</span>?</>,
            option1ClassName: 'btn btn-warning shadow-0 bootbox-accept',
            onOption1Click: handleConfirmReopen,
            onCancel: handleConfirmReopenCancel,
        })
    }

    const handleConfirmReopen = (e) => {
        todoInstance.status('Reopened');
        toast.dark(() => <p data-task-upd>Task updated.</p>);
        confirmModalRef.current.close();
        handleClose(e);
    }

    const handleConfirmReopenCancel = (e) => {
        confirmModalRef.current.close();
    }

    const handleMarkCompleteClick = (e) => {
        confirmModalRef.current.show({
            icon: <i className='fal fa-tasks text-success mr-2'></i>,
            message: <>Mark this task as&nbsp;<span className='fw-500'>"Completed"</span>?</>,
            option1ClassName: 'btn btn-success shadow-0 bootbox-accept',
            onOption1Click: handleConfirmComplete,
            onCancel: handleConfirmCompleteCancel,
        })
    }

    const handleConfirmComplete = (e) => {
        todoInstance.status('Completed');
        toast.dark(() => <p data-task-comp>Task completed.</p>);
        confirmModalRef.current.close();
        handleClose(e);
    }

    const handleConfirmCompleteCancel = (e) => {
        confirmModalRef.current.close();
    }

    const handleSingleDeleteClick = (e) => {
        confirmModalRef.current.show({
            icon: <i className='fal fa-tasks text-danger mr-2'></i>,
            message: <>Continue to&nbsp;<span className='fw-500'>delete this task</span>?</>,
            option1ClassName: 'btn btn-danger shadow-0 bootbox-accept',
            onOption1Click: handleConfirmSingleDelete,
            onCancel: handleConfirmSingleDeleteCancel,
        })
    }

    const handleConfirmSingleDelete = (e) => {
        todoInstance.deleteAll();
        toast.dark(() => <p data-task-del>Task deleted.</p>);
        confirmModalRef.current.close();
        handleClose(e);
    }

    const handleConfirmSingleDeleteCancel = (e) => {
        confirmModalRef.current.close();
    }

    const handleMultipleDeleteThisClick = (e) => {
        confirmModalRef.current.show({
            icon: <i className='fal fa-tasks text-danger mr-2'></i>,
            message: <>Continue to&nbsp;<span className='fw-500'>delete this task{todoInstance.data && todoInstance.data.userId ? ` for ${uh.getDisplayShortNameById(todoInstance.data.userId)}` : ''}</span>?</>,
            option1ClassName: 'btn btn-danger shadow-0 bootbox-accept',
            onOption1Click: handleConfirmMultipleDeleteThis,
            onCancel: handleConfirmMultipleDeleteThisCancel,
        })
    }

    const handleConfirmMultipleDeleteThis = (e) => {
        todoInstance.delete();
        toast.dark(() => <p data-task-del>Task deleted.</p>);
        confirmModalRef.current.close();
        handleClose(e);
    }

    const handleConfirmMultipleDeleteThisCancel = (e) => {
        confirmModalRef.current.close();
    }

    const handleMultipleDeleteAllClick = (e) => {
        confirmModalRef.current.show({
            icon: <i className='fal fa-tasks text-danger mr-2'></i>,
            message: <>Continue to&nbsp;<span className='fw-500'>delete this task for everyone that is assigned</span>?</>,
            option1ClassName: 'btn btn-danger shadow-0 bootbox-accept',
            onOption1Click: handleConfirmMultipleDeleteAll,
            onCancel: handleConfirmMultipleDeleteAllCancel
        })
    }

    const handleConfirmMultipleDeleteAll = (e) => {
        todoInstance.deleteAll();
        toast.dark(() => <p data-task-del>Task(s) deleted.</p>);
        confirmModalRef.current.close();
        handleClose(e);
    }

    const handleConfirmMultipleDeleteAllCancel = (e) => {
        confirmModalRef.current.close();
    }

    const handleUpdateTodoClick = async (e) => {
        await todoUpdate.initialize(todoInstance.data.todoId);
        quickDrawer.activateQuickDrawer('todo', 'update', { defaultBodyHtml: todoInstance.data.bodyHtml }, handleUpdateTodoSuccess, handleUpdateTodoCancel);
        handleClose(e);
    }

    const handleUpdateTodoSuccess = () => {
        toast.dark(() => <p data-task-upd>Task updated.</p>);
        todoUpdate.clear();
    }

    const handleUpdateTodoCancel = () => {
        todoUpdate.clear();
    }

    const handleNewNoteHtmlChange = (e) => {
        const html = e.value;
        newNoteHtml.current = html;
    }

    const handleNewNote = (e) => {
        if (newNoteHtml.current) {
            const html = newNoteHtml.current;

            todoInstance.note(html);
            todoUpdate.initialize(todoInstance.data.todoId)
                .then(() => {
                    const userDataIds = html.match(/data-id="usr_([^"]+)"/g);
                    const userIds = userDataIds && userDataIds.some(u => u) ? userDataIds.map(u => { return u.split('"')[1] }) : [];
                    const groupDataIds = html.match(/data-id="gr_([^"]+)"/g);
                    const groupIds = groupDataIds && groupDataIds.some(g => g) ? groupDataIds.map(g => { return g.split('"')[1] }) : [];

                    todoUpdate.addUsers(userIds, groupIds);
                });

            newNoteHtml.current = null;
            noteRef.current.getInstance().reset();
        }
    }

    const renderTitle = () => {
        return <div className='popup-title'>
            <div className='actions left-actions'>
            </div>
            <div className='actions right-actions'>
                <ul>
                    <Observer>{() => <>
                        {
                            th.isEndState(todoInstance.data) ?
                                <>
                                    {
                                        todoInstance.data.createdById === auth.currentUser.id ?
                                            <>
                                                <li>
                                                    <button
                                                        type='button'
                                                        className='btn btn-warning px-3'
                                                        onClick={handleReopenClick}
                                                    >
                                                        Reopen
                                                    </button>
                                                </li>
                                            </> : null
                                    }
                                </> :
                                <>
                                    {
                                        todoInstance.data.createdById === auth.currentUser.id ?
                                            <>
                                                <li>
                                                    <button
                                                        type='button'
                                                        className='btn btn-light px-3'
                                                        onClick={handleUpdateTodoClick}
                                                    >
                                                        Update
                                                    </button>
                                                </li>
                                                {
                                                    todoInstance.data.isSingleInstance ?
                                                        <li>
                                                            <button
                                                                type='button'
                                                                className='btn btn-danger px-3'
                                                                onClick={handleSingleDeleteClick}
                                                            >
                                                                Delete
                                                            </button>
                                                        </li> :
                                                        <>
                                                            <li>
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-danger px-3'
                                                                    onClick={handleMultipleDeleteThisClick}
                                                                >
                                                                    Delete
                                                                </button>
                                                            </li>
                                                            <li>
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-danger px-3'
                                                                    onClick={handleMultipleDeleteAllClick}
                                                                >
                                                                    Delete All
                                                                </button>
                                                            </li>
                                                        </>
                                                }
                                                <li>
                                                    <button
                                                        type='button'
                                                        className='btn btn-success px-3'
                                                        onClick={handleMarkCompleteClick}
                                                    >
                                                        Mark complete
                                                    </button>
                                                </li>
                                            </> :
                                            <>
                                                {
                                                    todoInstance.data.workingOnById && todoInstance.data.workingOnById === auth.currentUser.id ?
                                                        <li>
                                                            <button
                                                                type='button'
                                                                className='btn btn-light px-3'
                                                                onClick={handleNotWorkingOnItClick}
                                                            >
                                                                Not working on it
                                                            </button>
                                                        </li> : null
                                                }
                                                {
                                                    !todoInstance.data.workingOnById || todoInstance.data.workingOnById !== auth.currentUser.id ?
                                                        <li>
                                                            <button
                                                                type='button'
                                                                className='btn btn-light px-3'
                                                                onClick={handleWorkingOnItClick}
                                                            >
                                                                Working on it
                                                            </button>
                                                        </li> : null
                                                }
                                                <li>
                                                    <button
                                                        type='button'
                                                        className='btn btn-success px-3'
                                                        onClick={handleMarkCompleteClick}
                                                    >
                                                        Mark complete
                                                    </button>
                                                </li>
                                            </>
                                    }
                                </>
                        }</>
                    }</Observer>
                    <li>
                        <button type='button' className='btn btn-icon btn-close' onClick={e => handleClose(e)}>
                            <i className='close-icon fal fa-times fs-xl'></i>
                        </button>
                    </li>
                </ul>
            </div>
        </div >
    }

    const renderContent = () => {
        const createdByUser = uh.getById(todoInstance.data.createdById);
        const completedByUser = todoInstance.data.status === 'Completed' ? uh.getById(todoInstance.data.completedById) : null;

        return <div className='popup-body'>
            <div className='todo-detail'>
                <div className='d-flex h-100'>
                    <div className='todo-body h-100 flex-1'>
                        <Observer>{() =>
                            <ScrollView ref={scrollViewRef} width='100%' height='100%'>
                                <div className='w-100 h-100 p-4 pr-0'>
                                    <div className='d-flex'>
                                        <div className='flex-1 d-block pt-2 text-muted text-uppercase'><small>Description</small></div>
                                        <div className='text-right'>
                                            <div className={'todo-status mb-2' + (todoInstance.data.isHighPriority ? ' high-priority' : '')}>
                                                {
                                                    todoInstance.data.isHighPriority ?
                                                        <i className='fas fa-exclamation text-danger float-left flashing slow mr-2 fs-lg'></i> : null
                                                }
                                                {bh.renderTodoStatus(todoInstance.data, true, 'mr-1 p-1')}{bh.renderTodoDueStatus(todoInstance.data, 'p-1')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='description'>
                                        <span className='html' dangerouslySetInnerHTML={{ __html: todoInstance.data.bodyHtml }}></span>
                                    </div>
                                    <div className='mt-4'>
                                        <div className='d-flex flex-row'>
                                            {
                                                createdByUser ?
                                                    <div className='mr-8'>
                                                        <div><div className='d-block text-muted text-uppercase'><small>Created by</small></div>
                                                            <div className='d-inline-block align-middle'>
                                                                <span
                                                                    className={`profile-image profile-image-md rounded-circle d-block` + (createdByUser && !createdByUser.profilePictureUri ? ` profile-initials text-white bg-color${createdByUser.color}-500` : ' mt-1')}
                                                                    style={!createdByUser || createdByUser.profilePictureUri ? {
                                                                        backgroundImage: `url(${(createdByUser && createdByUser.profilePictureUri ? createdByUser.profilePictureUri : '/media/img/default-genki.jpg')})`,
                                                                        backgroundSize: 'cover',
                                                                    } : null}
                                                                    title={uh.getDisplayFullName(createdByUser)}
                                                                >
                                                                    {!createdByUser.profilePictureUri ? <div className='d-initials fs-80'>{createdByUser.initials}</div> : null}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div> : null
                                            }
                                            <div className='flex-1'>
                                                <div className='d-block text-muted text-uppercase'><small>Assigned to</small></div>
                                                {
                                                    !todoInstance.data.assignedUsers || todoInstance.data.assignedUsers.length === 0 ?
                                                        <div className='d-inline-block align-middle'>
                                                            <span
                                                                className={`profile-image profile-image-md rounded-circle d-block` + (auth.currentUser && !auth.currentUser.profilePictureUri ? ` profile-initials text-white bg-color${auth.currentUser.color}-500` : ' mt-1')}
                                                                style={!auth.currentUser || auth.currentUser.profilePictureUri ? {
                                                                    backgroundImage: `url(${(auth.currentUser && auth.currentUser.profilePictureUri ? auth.currentUser.profilePictureUri : '/media/img/default-genki.jpg')})`,
                                                                    backgroundSize: 'cover',
                                                                } : null}
                                                                title={uh.getDisplayFullName(auth.currentUser)}
                                                            >
                                                                {!auth.currentUser.profilePictureUri ? <div className='d-initials fs-80'>{auth.currentUser.initials}</div> : null}
                                                            </span>
                                                        </div> :
                                                        <>
                                                            {
                                                                todoInstance.data.assignedUsers && todoInstance.data.assignedUsers.length > 0 ?
                                                                    todoInstance.data.assignedUsers.map((u, ui) => {
                                                                        return <div key={`todo_assigned_user_${ui}`} className='d-inline-block align-middle mr-1'>
                                                                            <span
                                                                                className={`profile-image profile-image-md rounded-circle d-block` + (u && !u.profilePictureUri ? ` profile-initials text-white bg-color${u.color}-500` : ' mt-1')}
                                                                                style={!u || u.profilePictureUri ? {
                                                                                    backgroundImage: `url(${(u.profilePictureUri ? u.profilePictureUri : '/media/img/default-genki.jpg')})`,
                                                                                    backgroundSize: 'cover',
                                                                                } : null}
                                                                                title={uh.getDisplayFullName(u)}
                                                                            >
                                                                                {!u.profilePictureUri ? <div className='d-initials fs-80'>{u.initials}</div> : null}
                                                                            </span>
                                                                        </div>
                                                                    }) : null
                                                            }
                                                        </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ScrollView>
                        }</Observer>
                    </div>
                    <div className='todo-notes'>
                        <div className='card border-0 notes-container'>
                            <div className='row no-gutters row-grid'>
                                <div className={'notes-body' + (th.isEndState(todoInstance.data) ? ' notes-disabled' : '')}>
                                    <div className={'col-12 p-0 h-100'}>
                                        <div className={'d-flex flex-column align-items-center h-100'}>
                                            <Observer>{() => <>
                                                <ScrollView width='100%' height='100%'>
                                                    <div className='pt-2'>
                                                        <div
                                                            className='d-flex flex-row w-100 py-2 px-4'
                                                        >
                                                            <div className='d-inline-block align-middle mr-3'>
                                                                <span
                                                                    className={'profile-image profile-image-md rounded-circle d-block' + (createdByUser && !createdByUser.profilePictureUri ? ` profile-initials text-white bg-color${createdByUser.color}-500` : ' mt-1')}
                                                                    style={!createdByUser || createdByUser.profilePictureUri ? {
                                                                        backgroundImage: `url(${(createdByUser && createdByUser.profilePictureUri ? createdByUser.profilePictureUri : '/media/img/default-genki.jpg')})`,
                                                                        backgroundSize: 'cover',
                                                                    } : null}
                                                                    title={createdByUser ? createdByUser.fullName : 'System'}>
                                                                    {createdByUser && !createdByUser.profilePictureUri ? <div className='d-initials fs-xs'>{createdByUser.initials}</div> : null}
                                                                </span>
                                                            </div>
                                                            <div className='mb-0 flex-1 text-dark'>
                                                                <div className='d-flex'>
                                                                    <span className='text-gray-800 fw-500'>
                                                                        <span className='note-by position-relative'>
                                                                            {createdByUser ? uh.getDisplayShortName(createdByUser) : 'System'}
                                                                        </span>
                                                                    </span>
                                                                    <div className='ml-auto'>
                                                                        {
                                                                            moment().startOf('day').isSame(moment.utc(todoInstance.data.createdDateUtc).local().startOf('day')) ?
                                                                                <TimeAgo
                                                                                    className='text-muted fs-xs opacity-70'
                                                                                    date={moment.utc(todoInstance.data.createdDateUtc).local().toDate()}
                                                                                    formatter={(value, unit, suffix) => { return fn.timeAgoPastFormatter(value, unit, suffix, 'second', 10, 'Just now', true) }}
                                                                                    title={moment.utc(todoInstance.data.createdDateUtc).local().format('dddd MMMM D, YYYY h:mm a')}
                                                                                    minPeriod={60}
                                                                                /> :
                                                                                <span
                                                                                    className='text-muted fs-xs opacity-70'
                                                                                    title={sys.getFormattedLongDate(moment.utc(todoInstance.data.createdDateUtc).local(), true)}
                                                                                >
                                                                                    {sys.getFormattedLongDate(moment.utc(todoInstance.data.createdDateUtc).local())}
                                                                                </span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className={'html mt-1 mb-0'}
                                                                >
                                                                    Created the task.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            todoInstance.data.notes && todoInstance.data.notes.length > 0 ?
                                                                todoInstance.data.notes.map((n, ni) => {
                                                                    const noteCreatedBy = uh.getById(n.createdById);
                                                                    return <div
                                                                        key={`todo_note_${ni}`}
                                                                        className='d-flex flex-row w-100 py-2 px-4'
                                                                    >
                                                                        <div className='d-inline-block align-middle mr-3'>
                                                                            <span
                                                                                className={'profile-image profile-image-md rounded-circle d-block' + (noteCreatedBy && !noteCreatedBy.profilePictureUri ? ` profile-initials text-white bg-color${noteCreatedBy.color}-500` : ' mt-1')}
                                                                                style={!noteCreatedBy || noteCreatedBy.profilePictureUri ? {
                                                                                    backgroundImage: `url(${(noteCreatedBy && noteCreatedBy.profilePictureUri ? noteCreatedBy.profilePictureUri : '/media/img/default-genki.jpg')})`,
                                                                                    backgroundSize: 'cover',
                                                                                } : null}
                                                                                title={noteCreatedBy ? noteCreatedBy.fullName : 'System'}>
                                                                                {noteCreatedBy && !noteCreatedBy.profilePictureUri ? <div className='d-initials fs-xs'>{noteCreatedBy.initials}</div> : null}
                                                                            </span>
                                                                        </div>
                                                                        <div className='mb-0 flex-1 text-dark'>
                                                                            <div className='d-flex'>
                                                                                <span className='text-gray-800 fw-500'>
                                                                                    <span className='note-by position-relative'>
                                                                                        {noteCreatedBy ? uh.getDisplayShortName(noteCreatedBy) : 'System'}
                                                                                    </span>
                                                                                </span>
                                                                                <div className='ml-auto'>
                                                                                    {
                                                                                        moment().startOf('day').isSame(moment.utc(n.createdDateUtc).local().startOf('day')) ?
                                                                                            <TimeAgo
                                                                                                className='text-muted fs-xs opacity-70'
                                                                                                date={moment.utc(n.createdDateUtc).local().toDate()}
                                                                                                formatter={(value, unit, suffix) => { return fn.timeAgoPastFormatter(value, unit, suffix, 'second', 10, 'Just now', true) }}
                                                                                                title={sys.getFormattedLongDate(moment.utc(n.createdDateUtc).local(), true)}
                                                                                                minPeriod={60}
                                                                                            /> :
                                                                                            <span
                                                                                                className='text-muted fs-xs opacity-70'
                                                                                                title={sys.getFormattedLongDate(moment.utc(n.createdDateUtc).local(), true)}
                                                                                            >
                                                                                                {sys.getFormattedShortDate(moment.utc(n.createdDateUtc).local())}
                                                                                            </span>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className={'html mt-1 mb-0'}
                                                                                dangerouslySetInnerHTML={{ __html: n.bodyHtml }}
                                                                            ></div>
                                                                        </div>
                                                                    </div>
                                                                }) : null
                                                        }
                                                        {
                                                            todoInstance.data.status === 'Completed' ?
                                                                <div
                                                                    className='d-flex flex-row w-100 py-2 px-4'
                                                                >
                                                                    <div className='d-inline-block align-middle mr-3'>
                                                                        <span
                                                                            className={'profile-image profile-image-md rounded-circle d-block' + (completedByUser && !completedByUser.profilePictureUri ? ` profile-initials text-white bg-color${completedByUser.color}-500` : ' mt-1')}
                                                                            style={!completedByUser || completedByUser.profilePictureUri ? {
                                                                                backgroundImage: `url(${(completedByUser && completedByUser.profilePictureUri ? completedByUser.profilePictureUri : '/media/img/default-genki.jpg')})`,
                                                                                backgroundSize: 'cover',
                                                                            } : null}
                                                                            title={completedByUser ? completedByUser.fullName : 'System'}>
                                                                            {completedByUser && !completedByUser.profilePictureUri ? <div className='d-initials fs-xs'>{completedByUser.initials}</div> : null}
                                                                        </span>
                                                                    </div>
                                                                    <div className='mb-0 flex-1 text-dark'>
                                                                        <div className='d-flex'>
                                                                            <span className='text-gray-800 fw-500'>
                                                                                <span className='note-by position-relative'>
                                                                                    {completedByUser ? uh.getDisplayShortName(completedByUser) : 'System'}
                                                                                </span>
                                                                            </span>
                                                                            <div className='ml-auto'>
                                                                                {
                                                                                    moment().startOf('day').isSame(moment.utc(todoInstance.data.completedDateUtc).local().startOf('day')) ?
                                                                                        <TimeAgo
                                                                                            className='text-muted fs-xs opacity-70'
                                                                                            date={moment.utc(todoInstance.data.completedDateUtc).local().toDate()}
                                                                                            formatter={(value, unit, suffix) => { return fn.timeAgoPastFormatter(value, unit, suffix, 'second', 10, 'Just now', true) }}
                                                                                            title={sys.getFormattedLongDate(moment.utc(todoInstance.data.completedDateUtc).local(), true)}
                                                                                            minPeriod={60}
                                                                                        /> :
                                                                                        <span
                                                                                            className='text-muted fs-xs opacity-70'
                                                                                            title={sys.getFormattedLongDate(moment.utc(todoInstance.data.completedDateUtc).local(), true)}
                                                                                        >
                                                                                            {sys.getFormattedLongDate(moment.utc(todoInstance.data.completedDateUtc).local())}
                                                                                        </span>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className={'html mt-1 mb-0'}
                                                                        >
                                                                            Completed the task.
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </div>
                                                </ScrollView>
                                            </>
                                            }</Observer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                !th.isEndState(todoInstance.data) ?
                                    <div className='card-footer notes-footer'>
                                        <HtmlEditor
                                            ref={noteRef}
                                            className='input-sm'
                                            placeholder='Write note here...'
                                            height={'91px'}
                                            disableTab={true}
                                            onValueChanged={handleNewNoteHtmlChange}
                                        >
                                            <Mention
                                                marker={'@'}
                                                dataSource={allActiveUsersGroups}
                                                displayExpr={'mention'}
                                                valueExpr={'id'}
                                                searchExpr={['name', 'mention']}
                                                itemRender={(item) => {
                                                    return item ? <>
                                                        <div
                                                            className='p-1'
                                                        >
                                                            <div className='fs-sm'><strong>{item.name}</strong></div>
                                                        </div>
                                                    </> : null
                                                }}
                                            />
                                        </HtmlEditor>
                                        <div className='w-100 pl-2 pr-3 pb-3 pt-2'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='float-right'>
                                                        <button
                                                            type='button'
                                                            className='btn btn-dark btn-sm'
                                                            onClick={handleNewNote}
                                                        >Add note</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    }

    const handleClose = event => {
        if (fn.isFunction(props.onClose)) {
            props.onClose(event);
        }
    }

    return <>
        <Observer>{() =>
            <>
                <GlobalHotKeys
                    keyMap={{
                        close: ['esc'],
                    }}
                    handlers={{
                        close: event => {
                            if (confirmModalRef.current && !confirmModalRef.current.isVisible) {
                                handleClose(event)
                            }
                        },
                    }}
                    allowChanges={true}
                />
            </>
        }</Observer>
        <Observer>{() =>
            <>
                {
                    todoInstance.isReady && todoInstance.data ?
                        <>
                            {renderTitle()}
                            {renderContent()}
                        </> : null
                }
            </>
        }</Observer>
        <BodyEnd>
            <ConfirmModal ref={confirmModalRef} />
        </BodyEnd>
    </>
}

export default ViewTodo;