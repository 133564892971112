import React, { useEffect, useContext, useRef, useState } from 'react';
import FadeIn from 'react-fade-in';
import { Observer } from 'mobx-react-lite';
import { GlobalHotKeys } from 'react-hotkeys';
import { toast } from 'react-toastify';

import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import { quickDrawerFocus } from '../../_shared/QuickDrawer';
import ExternalClinicFilter from './ExternalClinicFilter';

import ExternalDoctorCreateStore from '../../../../stores/ExternalDoctorCreateStore';
import ExternalClinicCreateStore from '../../../../stores/ExternalClinicCreateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';
import CacheStore from '../../../../stores/CacheStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as oh from '../../../../utilities/operationHelper';
import * as adh from '../../../../utilities/addressHelper';
import * as sys from '../../../../utilities/systemHelper';

import api from '../../../../api';

import './AddExternalDoctor.scss';

function AddExternalDoctor(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const searchTimer = useRef(null);
    const focusTimer = useRef(null);
    const workAtRef = useRef(null);
    const externalClinicFilterRef = useRef(null);
    const externalDoctor = useContext(ExternalDoctorCreateStore);
    const externalClinicNew = useContext(ExternalClinicCreateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const cache = useContext(CacheStore);
    const [clinicMode, setClinicMode] = useState('Existing');
    const [externalClinicSearchResult, setExternalClinicSearchResult] = useState(null);

    useEffect(() => {
        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100)

        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
            if (searchTimer.current) { clearTimeout(searchTimer.current); searchTimer.current = null; }
            externalDoctor.clear();
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (externalDoctor.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            if (!!externalDoctor.data.externalClinic) {
                if (workAtRef.current) { workAtRef.current.classList.remove('text-danger', 'fs-500'); }
                externalDoctor.save()
                    .then(() => {
                        if (isMounted.current) {
                            if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                                props.onSuccess(event, { updated: true });
                            }
                        }
                    })
            }
            else {
                if (workAtRef.current) { workAtRef.current.classList.add('text-danger', 'fs-500'); }
                toast.error(() => <><strong>Oops!</strong> Please set a working clinic to proceed.</>, { position: 'top-center' });
            }
        }
    }

    const handleFirstNameChange = (event) => {
        const value = event.target.value;

        externalDoctor.data.firstName = value;
        externalDoctor.hasUnsavedChanges = true;
    }

    const handleLastNameChange = (event) => {
        const value = event.target.value;

        externalDoctor.data.lastName = value;
        externalDoctor.hasUnsavedChanges = true;
    }

    const handleSuffixChange = (event) => {
        const value = event.target.value;

        externalDoctor.data.suffix = value;
        externalDoctor.hasUnsavedChanges = true;
    }

    const handleBillingNumberChange = (event) => {
        const value = event.target.value;

        externalDoctor.data.billingNumber = value;
        externalDoctor.hasUnsavedChanges = true;
    }

    const handleClinicModeChange = event => {
        const value = event.target.value;

        setClinicMode(value);
        externalDoctor.hasUnsavedChanges = true;
    }

    const handleRemoveExternalClinicClick = (event) => {
        externalDoctor.data.externalClinic = null;
        externalDoctor.hasUnsavedChanges = true;
    }

    const handleExternalClinicSearchChange = (event, term) => {
        if (searchTimer.current) {
            clearTimeout(searchTimer.current);
        }

        if (term && term.length >= 2) {
            api.ExternalClinics.fullSearch(term, 5)
                .then(({ data }) => {
                    if (isMounted.current) {
                        setExternalClinicSearchResult(data.result);
                    }
                })
        } else {
            setExternalClinicSearchResult(null);
        }
    }

    const handleExternalClinicSearchClick = externalClinic => {
        externalClinicFilterRef.current.isLoading(true);
        setExternalClinicSearchResult(null);

        api.ExternalClinics.get(externalClinic.id)
            .then(({ data: externalClinicData }) => {
                externalDoctor.data.externalClinic = externalClinicData;
                externalDoctor.hasUnsavedChanges = true;
            })
    }

    const handleNewExternalClinicClick = (event) => {
        externalClinicNew.initialize();
        quickDrawer.activateQuickDrawer('externalClinic', 'create', null, handleExternalClinicAddSuccess, handleExternalClinicAddCancel);
    }

    const handleExternalClinicAddSuccess = (result) => {
        if (result && result.updated && result.data) {
            api.ExternalClinics.get(result.data.id)
                .then(({ data }) => {
                    externalDoctor.data.externalClinic = data;
                    externalDoctor.hasUnsavedChanges = true;
                });
        }
        externalClinicNew.clear();
    }

    const handleExternalClinicAddCancel = (event) => {
        externalClinicNew.clear();
    }

    return <>
        {
            (props.drawer === quickDrawer.drawerOpened) ?
                <GlobalHotKeys
                    keyMap={{
                        close: ['esc'],
                    }}
                    handlers={{
                        close: event => {
                            handleCancel(event)
                        },
                    }}
                    allowChanges={true}
                /> : null
        }
        <form ref={validateRef} onSubmit={handleSubmit}>
            <fieldset disabled={externalDoctor.isSaving}>
                <div className='add-external-doctor-container quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('external-doctor', 'default')}
                        action='Add new'
                        category='External Doctor'
                        className='external-doctors'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        <FadeIn>
                            <div className='body-content'>
                                <section>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='form-group'>
                                                <label htmlFor='new-external-doctor-salutation-type-select'><small>Prefix</small></label>
                                                <select
                                                    id='new-external-doctor-salutation-type-select'
                                                    className='custom-select form-control'
                                                    value={'Dr.'}
                                                    disabled={true}
                                                >
                                                    <option value='Dr.'>Dr.</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='form-group'>
                                                <label htmlFor='new-external-doctor-firstname-input'><small>First Name</small></label>
                                                <Observer>{() =>
                                                    <input
                                                        id='new-external-doctor-firstname-input'
                                                        type='text'
                                                        className='form-control'
                                                        spellCheck={false}
                                                        maxLength='50'
                                                        autoComplete='off'
                                                        value={externalDoctor.data.firstName ? externalDoctor.data.firstName : ''}
                                                        onChange={handleFirstNameChange}
                                                    />
                                                }</Observer>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='form-group validate validate-required'>
                                                <label className='required' htmlFor='new-external-doctor-lastname-input'><small>Last Name</small></label>
                                                <Observer>{() =>
                                                    <input
                                                        id='new-external-doctor-lastname-input'
                                                        type='text'
                                                        className='form-control'
                                                        maxLength='50'
                                                        spellCheck={false}
                                                        autoComplete='off'
                                                        value={externalDoctor.data.lastName ? externalDoctor.data.lastName : ''}
                                                        onChange={handleLastNameChange}
                                                    />
                                                }</Observer>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='form-group'>
                                                <label htmlFor='new-external-doctor-suffix-type-select'><small>Suffix</small></label>
                                                <Observer>{() =>
                                                    <select
                                                        id='new-external-doctor-suffix-type-select'
                                                        className='custom-select form-control'
                                                        value={externalDoctor.data.suffix ? externalDoctor.data.suffix : ''}
                                                        onChange={handleSuffixChange}
                                                    >
                                                        <option value=''></option>
                                                        {
                                                            cache.getReferenceDataOptions('SuffixType').map((option, di) => {
                                                                return <option key={`suffix_type_${di}`} value={option.key}>{option.value}</option>
                                                            })
                                                        }
                                                    </select>
                                                }</Observer>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='form-group'>
                                                <label htmlFor='new-external-doctor-billing-number-input'><small>Billing Number</small></label>
                                                <Observer>{() =>
                                                    <input
                                                        id='new-external-doctor-billing-number-input'
                                                        type='text'
                                                        className='form-control'
                                                        maxLength='50'
                                                        spellCheck={false}
                                                        autoComplete='off'
                                                        value={externalDoctor.data.billingNumber ? externalDoctor.data.billingNumber : ''}
                                                        onChange={handleBillingNumberChange}
                                                    />
                                                }</Observer>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='form-group'>
                                                <label className='required'><small ref={workAtRef}>Works at</small></label>
                                                <Observer>{() =>
                                                    <>
                                                        {
                                                            !!externalDoctor.data.externalClinic ?
                                                                <div
                                                                    className={'clinic-container'}
                                                                >
                                                                    <div className='clinic-detail'>
                                                                        <div className='text-truncate'><strong>{externalDoctor.data.externalClinic.name}</strong></div>
                                                                        {
                                                                            externalDoctor.data.externalClinic.address ?
                                                                                <div className='text-gray-600'>
                                                                                    {adh.getAddressHtml(externalDoctor.data.externalClinic.address)}
                                                                                </div> : null
                                                                        }
                                                                        {
                                                                            externalDoctor.data.externalClinic.emailAddress ?
                                                                                <div>
                                                                                    <strong>Email:</strong>&nbsp;<a
                                                                                        href={`mailto:${externalDoctor.data.externalClinic.emailAddress.toLowerCase()}`}
                                                                                    >{externalDoctor.data.externalClinic.emailAddress.toLowerCase()}
                                                                                    </a>
                                                                                </div> : null
                                                                        }
                                                                        {
                                                                            externalDoctor.data.externalClinic.phoneNumber ?
                                                                                <div>
                                                                                    <strong>Phone:</strong>&nbsp;<a
                                                                                        href={`tel:${externalDoctor.data.externalClinic.phoneNumber}`}
                                                                                    >{sys.getFormattedPhoneNumber(externalDoctor.data.externalClinic.phoneNumber)}
                                                                                    </a>
                                                                                </div> : null
                                                                        }
                                                                        {
                                                                            externalDoctor.data.externalClinic.faxNumber ?
                                                                                <div>
                                                                                    <strong>Fax:</strong>&nbsp;<span>{sys.getFormattedPhoneNumber(externalDoctor.data.externalClinic.faxNumber)}
                                                                                    </span>
                                                                                </div> : null
                                                                        }
                                                                    </div>
                                                                    <div className='clinic-action'>
                                                                        <button
                                                                            type='button'
                                                                            className='btn btn-icon line-height-1'
                                                                            onClick={handleRemoveExternalClinicClick}
                                                                        >
                                                                            <i className='fal fa-times text-danger fs-sm' />
                                                                        </button>
                                                                    </div>
                                                                </div> : <>
                                                                    <Observer>{() =>
                                                                        <div className='custom-control custom-radio mb-2'>
                                                                            <input
                                                                                id='new-external-doctor-existing-clinic'
                                                                                type='radio'
                                                                                name='externalDoctorClinic'
                                                                                value='Existing'
                                                                                className='custom-control-input'
                                                                                checked={clinicMode === 'Existing'}
                                                                                onChange={handleClinicModeChange}
                                                                            />
                                                                            <label
                                                                                htmlFor='new-external-doctor-existing-clinic'
                                                                                className='custom-control-label'
                                                                            >
                                                                                An existing clinic in the address book
                                                                            </label>
                                                                        </div>
                                                                    }</Observer>
                                                                    <div className='external-clinic-search mt-2'>
                                                                        <div className='pl-4 mb-4'>
                                                                            <div className='dropdown'>
                                                                                <Observer>{() => <>
                                                                                    <ExternalClinicFilter
                                                                                        id={'external-clinic-filter'}
                                                                                        ref={externalClinicFilterRef}
                                                                                        placeholder={'Search for clinic to link'}
                                                                                        delay={500}
                                                                                        disabled={clinicMode !== 'Existing'}
                                                                                        onChange={handleExternalClinicSearchChange}
                                                                                    />
                                                                                    <ul className='dropdown-menu'>
                                                                                        {
                                                                                            externalClinicSearchResult && externalClinicSearchResult.length > 0 ?
                                                                                                externalClinicSearchResult.slice(0, 5).map((s, si) => {
                                                                                                    return <li
                                                                                                        key={`search_result_${si}`}
                                                                                                        className={'dropdown-menu-item'}>
                                                                                                        <div
                                                                                                            className='external-clinic-profile'
                                                                                                            onClick={() => { handleExternalClinicSearchClick(s) }}
                                                                                                        >
                                                                                                            <div className='text-truncate'><strong>{s.name}</strong></div>
                                                                                                            {
                                                                                                                s.address ?
                                                                                                                    <div className='text-gray-600'>
                                                                                                                        {adh.getAddressHtml(s.address)}
                                                                                                                    </div> : null
                                                                                                            }
                                                                                                        </div>
                                                                                                    </li>
                                                                                                }) : null
                                                                                        }
                                                                                    </ul>
                                                                                </>
                                                                                }</Observer>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <Observer>{() =>
                                                                        <div className='custom-control custom-radio mb-2'>
                                                                            <input
                                                                                id='new-external-doctor-new-clinic'
                                                                                type='radio'
                                                                                name='externalDoctorClinic'
                                                                                value='New'
                                                                                className='custom-control-input'
                                                                                checked={clinicMode === 'New'}
                                                                                onChange={handleClinicModeChange}
                                                                            />
                                                                            <label
                                                                                htmlFor='new-external-doctor-new-clinic'
                                                                                className='custom-control-label'
                                                                            >
                                                                                A clinic that is not in your address book
                                                                            </label>
                                                                        </div>
                                                                    }</Observer>
                                                                    <button
                                                                        type='button'
                                                                        className='ml-4 btn btn-link p-0'
                                                                        disabled={clinicMode !== 'New'}
                                                                        onClick={handleNewExternalClinicClick}
                                                                    >
                                                                        Add new clinic
                                                                    </button>
                                                                </>
                                                        }
                                                    </>
                                                }</Observer>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </FadeIn>
                    </div>
                    <div className='quick-drawer-action pl-3'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>
}

export default AddExternalDoctor;