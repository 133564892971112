import React, { useEffect, useContext, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { toJS } from 'mobx';
import { GlobalHotKeys } from 'react-hotkeys';
import { useObserver } from 'mobx-react-lite';
import MaskedInput from 'react-text-mask';
import { Mention, MentionsInput } from 'react-mentions';
import { toast } from 'react-toastify';

import { quickDrawerFocus, renderQuickDrawerLoading } from '../../../../_shared/QuickDrawer';
import QuickDrawerHeader from '../../../../_shared/QuickDrawerHeader';
import RichTextEditor from '../../../../_shared/RichTextEditor';

import FormTemplateUpdateStore from '../../../../../../stores/FormTemplateUpdateStore';
import FormTemplateCloneStore from '../../../../../../stores/FormTemplateCloneStore';
import QuickDrawerStore from '../../../../../../stores/QuickDrawerStore';
import CacheStore from '../../../../../../stores/CacheStore';

import * as rts from '../../../../../../constants/routes';
import * as AccessType from '../../../../../../constants/accessTypes';
import * as MaskKeys from '../../../../../../constants/maskKeys';
import * as ErrorMessages from '../../../../../../constants/errorMessages';
import * as fn from '../../../../../../utilities/_functions';
import * as ah from '../../../../../../utilities/accessHelper';
import * as oh from '../../../../../../utilities/operationHelper';
import * as fh from '../../../../../../utilities/formTemplateHelper';
import * as mh from '../../../../../../utilities/mentionHelper';

import './UpdateFormTemplate.scss';

function UpdateFormTemplate(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const cache = useContext(CacheStore);
    const formTemplate = useContext(FormTemplateUpdateStore);
    const formTemplateClone = useContext(FormTemplateCloneStore);
    const quickDrawer = useContext(QuickDrawerStore);

    useEffect(() => {
        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100)
        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (formTemplate.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = event => {
        event.preventDefault();
        formTemplate.save()
            .then(data => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, data);
                    }
                }
            })
    }

    const handleNameChange = event => {
        formTemplate.data.name = event.target.value;
        formTemplate.hasUnsavedChanges = true;
    }

    const handleDescriptionChange = content => {
        const html = content;
        formTemplate.data.descriptionHtml = ((html === '<p><br></p>') ? null : html);
        formTemplate.hasUnsavedChanges = true;
    }

    const handleAccessChange = event => {
        const access = event.target.value;

        formTemplate.data.access = access;

        if (formTemplate.data.destinations && formTemplate.data.destinations.length > 0) {
            let destinations = formTemplate.data.destinations.filter(d => fh.isValidDestination(access, d));

            if (destinations && destinations.length > 0) {
                formTemplate.data.destinations = destinations;
            } else {
                switch (access) {
                    case 'Anonymous':
                        formTemplate.data.destinations = fh.FORM_TEMPLATE_VALID_ANONYMOUS_ACCESS;
                        break;

                    case 'System':
                        formTemplate.data.destinations = fh.FORM_TEMPLATE_VALID_SYSTEM_ACCESS;
                        break;

                    default:
                        break;
                }
            }
        }

        formTemplate.hasUnsavedChanges = true;
    }

    const handleDestinationChange = event => {
        const destination = event.target.value;
        const index = formTemplate.data.destinations.findIndex(d => d === destination);

        if (index >= 0) {
            if (formTemplate.data.destinations.length > 1) {
                formTemplate.data.destinations.splice(index, 1);
            }
        } else {
            formTemplate.data.destinations.push(destination);
        }

        if (!formTemplate.data.settings) {
            formTemplate.data.settings = [];
        }

        const settingKeys = fh.getDestinationSettingKeys(formTemplate.data.destinations);

        for (let i = 0; i < settingKeys.length; i++) {
            if (!formTemplate.data.settings.some(k => settingKeys[i] === k.id)) {
                formTemplate.data.settings.push({ id: settingKeys[i], value: '' });
            }
        }

        formTemplate.hasUnsavedChanges = true;
    }

    const handleSettingChange = (event, key) => {
        const index = formTemplate.data.settings.findIndex(d => d.id === key);

        if (index >= 0) {
            formTemplate.data.settings[index].value = event.target.value;
        } else {
            const settings = formTemplate.data.settings ? toJS(formTemplate.data.settings) : [];

            settings.push({ id: key, value: event.target.value });
            formTemplate.data.settings = settings;
        }

        formTemplate.hasUnsavedChanges = true;
    }

    const handleCloneFormTemplate = event => {
        if (ah.check(AccessType.UPDATE_SYSTEM_SETTING)) {
            formTemplateClone.initialize(formTemplate.id);
            quickDrawer.activateQuickDrawer('formTemplate', 'clone', null, handleCloneFormTemplateSuccess, handleCloneFormTemplateCancel, handleCloneFormTemplateError);
        }
    }

    const handleCloneFormTemplateSuccess = () => {
        if (isMounted.current) {
            toast.dark(() => <p data-temp-cloned>Form template is cloned.</p>);
            quickDrawer.deactivateAll();
        }        
    }

    const handleCloneFormTemplateCancel = () => {
        formTemplateClone.clear();
    }

    const handleCloneFormTemplateError = () => {
        formTemplateClone.clear();
    }

    const renderDestinationSettings = () => {
        const keys = fh.getDestinationSettingKeys(formTemplate.data.destinations);
        const fields = [].concat(...formTemplate.data.pages && formTemplate.data.pages.length > 0 ?
            formTemplate.data.pages.filter(p =>
                p.bodyDefinition && p.bodyDefinition.some(b => b.key))
                .map(p => {
                    return p.bodyDefinition.filter(b => b.key)
                        .map(b => { return { id: b.key, display: b.name } })
                }) :
            []);

        fields.push({ id: '_SubmissionDate', display: '<Submission Date>' });

        const fileNameSetting = formTemplate.data.settings.some(d => d.id === 'FileName') && formTemplate.data.settings[formTemplate.data.settings.findIndex(d => d.id === 'FileName')].value ? formTemplate.data.settings[formTemplate.data.settings.findIndex(d => d.id === 'FileName')].value : '';
        const subjectSetting = formTemplate.data.settings.some(d => d.id === 'Subject') && formTemplate.data.settings[formTemplate.data.settings.findIndex(d => d.id === 'Subject')].value ? formTemplate.data.settings[formTemplate.data.settings.findIndex(d => d.id === 'Subject')].value : '';

        return <>
            {
                keys.length > 0 ?
                    <>
                        {
                            keys.some(d => d === 'To') ?
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='form-group mb-4'>
                                            <label htmlFor='update-form-template-setting-to'><small>Send response to</small></label>
                                            <MaskedInput
                                                id='update-form-template-setting-to'
                                                type='text'
                                                className='form-control'
                                                spellCheck={false}
                                                mask={MaskKeys.EMAIL_MASK}
                                                maxLength='255'
                                                autoComplete='off'
                                                value={formTemplate.data.settings[formTemplate.data.settings.findIndex(d => d.id === 'To')].value}
                                                onChange={(e) => { handleSettingChange(e, 'To') }}
                                            />
                                        </div>
                                    </div>
                                </div> : null
                        }
                        {
                            keys.some(d => d === 'FileName') ?
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='form-group mb-4'>
                                            <label htmlFor='update-form-template-setting-filename'><small>PDF File Name</small></label>
                                            <MentionsInput
                                                singleLine
                                                id={`new-form-template-setting-filename`}
                                                className='react-mentions-wrapper'
                                                autoComplete='off'
                                                value={fileNameSetting}
                                                onChange={(e) => { handleSettingChange(e, 'FileName') }}
                                            >
                                                <Mention
                                                    trigger='#'
                                                    data={fields}
                                                    markup={mh.MENTION_MARKUP}
                                                />
                                            </MentionsInput>
                                        </div>
                                    </div>
                                </div> : null
                        }
                        {
                            keys.some(d => d === 'Subject') ?
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='form-group mb-4'>
                                            <label htmlFor='update-form-template-setting-subject'><small>Email subject</small></label>
                                            <MentionsInput
                                                singleLine
                                                id={`new-form-template-setting-subject`}
                                                className='react-mentions-wrapper'
                                                autoComplete='off'
                                                value={subjectSetting}
                                                onChange={(e) => { handleSettingChange(e, 'Subject') }}
                                            >
                                                <Mention
                                                    trigger='#'
                                                    data={fields}
                                                    markup={mh.MENTION_MARKUP}
                                                />
                                            </MentionsInput>
                                        </div>
                                    </div>
                                </div> : null
                        }
                    </> : null
            }
        </>
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form onSubmit={handleSubmit}>
            <fieldset disabled={formTemplate.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('form-template', 'default')}
                        action='Update'
                        category='Form Template'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        {
                            formTemplate.isReady ?
                                <FadeIn>
                                    <div className='update-form-template body-content'>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label className='required' htmlFor='update-form-template-name'><small>Name</small></label>
                                                        <input
                                                            id='update-form-template-name'
                                                            type='text'
                                                            maxLength={100}
                                                            className='form-control'
                                                            autoComplete={'off'}
                                                            value={formTemplate.data.name ? formTemplate.data.name : ''}
                                                            onChange={handleNameChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label htmlFor='update-form-template-description'><small>Description</small></label>
                                                        <RichTextEditor
                                                            mode='none'
                                                            disableTab={true}
                                                            value={formTemplate.data.descriptionHtml ? formTemplate.data.descriptionHtml : ''}
                                                            onChange={handleDescriptionChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label htmlFor='update-form-template-access'><small>Who can submit this form?</small></label>
                                                        {
                                                            cache.getReferenceDataOptions('FormAccessType').map((option, i) => {
                                                                return <div
                                                                    key={`form_access_type_${i}`}
                                                                    className='custom-control custom-radio mb-2'
                                                                >
                                                                    <input
                                                                        id={`new-form-template-access-${option.key}`}
                                                                        type='radio'
                                                                        name='update-form-template-access'
                                                                        className='custom-control-input'
                                                                        value={option.key}
                                                                        checked={formTemplate.data.access === option.key}
                                                                        onChange={handleAccessChange}
                                                                    />
                                                                    <label
                                                                        htmlFor={`new-form-template-access-${option.key}`}
                                                                        className='custom-control-label'
                                                                    >
                                                                        {fh.getAccessTypeDescription(option)}
                                                                    </label>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                formTemplate.data.access === 'Anonymous' ?
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-4'>
                                                                <label htmlFor='update-form-template-public-url'><small>Public link</small></label>
                                                                <input
                                                                    type='text'
                                                                    className='form-control'
                                                                    readOnly={true}
                                                                    value={`${process.env.REACT_APP_PUBLIC_URL}${rts.Forms}/${formTemplate.data.publicKey}`}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div> : null
                                            }
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label htmlFor='update-form-template-destination'><small>What happens after submit?</small></label>
                                                        {
                                                            cache.getReferenceDataOptions('FormDestinationType').map((option, i) => {
                                                                return <div
                                                                    key={`form_destination_type_${i}`}
                                                                    className={'custom-control custom-checkbox mb-2' + (fh.isValidDestination(formTemplate.data.access, option.key) ? '' : ' cursor-not-allowed')}
                                                                >
                                                                    <input
                                                                        id={`new-form-template-destination-${option.key}`}
                                                                        type='checkbox'
                                                                        name='update-form-template-destination'
                                                                        className='custom-control-input'
                                                                        readOnly={!fh.isValidDestination(formTemplate.data.access, option.key)}
                                                                        value={option.key}
                                                                        checked={formTemplate.data.destinations && formTemplate.data.destinations.findIndex(d => d === option.key) >= 0}
                                                                        onChange={(fh.isValidDestination(formTemplate.data.access, option.key) ? handleDestinationChange : null)}
                                                                    />
                                                                    <label
                                                                        htmlFor={`new-form-template-destination-${option.key}`}
                                                                        className={'custom-control-label' + (fh.isValidDestination(formTemplate.data.access, option.key) ? '' : ' text-gray-600 cursor-not-allowed')}
                                                                    >
                                                                        {fh.getDestinationTypeDescription(option)}
                                                                    </label>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {renderDestinationSettings()}
                                        </section>
                                    </div>
                                </FadeIn> : renderQuickDrawerLoading()
                        }
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-4'>
                                <button
                                    type='button'
                                    className='btn btn-icon'
                                    title='Clone template'
                                    onClick={handleCloneFormTemplate}
                                >
                                    <i className='fal fa-copy'></i>
                                </button>
                            </div>
                            <div className='col-8'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>)
}

export default UpdateFormTemplate;