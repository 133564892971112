import { createContext } from 'react';
import uuid from 'react-uuid';
import { decorate, observable, action, toJS } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';
import * as ch from '../utilities/customerHelper';
import * as ph from '../utilities/personHelper';

export class CustomerCreate {
    id = null;
    data = {
        id: null,
        salutationType: null,
        firstName: null,
        preferredFirstName: null,
        middleName: null,
        lastName: null,
        suffixType: null,
        sexType: null,
        genderType: null,
        genderOther: null,
        pronounType: null,
        pronounOther: null,
        dateOfBirth: null,
        phoneNumber: null,
        phoneNumberOnly: null,
        phoneNumberExtension: null,
        phoneType: null,
        emailAddress: null,
        preferredContactMethod: null,
        communicationConsent: null,
        relatedToId: null,
        relatedToRelationship: null,
        patientProfile: {
            healthCardNumber: null,
            healthCardExpiryDate: null,
            isVerified: false,
            verificationResponse: null,
            familyDoctorId: null,
            familyDoctorClinicId: null,
        },
        address: {
            geocode: null,
            streetNumber: null,
            streetName: null,
            unitSuiteType: null,
            unitSuite: null,
            line2: null,
            locality: null,
            sublocality: null,
            postalCode: null,
            region: null,
            regionCode: null,
            country: null,
            countryCode: null,
        },
        primaryContactPerson: {
            isReady: false,
            id: null,
            salutationType: null,
            firstName: null,
            preferredFirstName: null,
            middleName: null,
            lastName: null,
            suffixType: null,
            emailAddress: null,
            phoneNumber: null,
            phoneType: null,
            relationship: null,
            color: null,
            address: null,
        },
        notes: [],
    };
    hasUnsavedChanges = false;
    isReady = false;
    isSaving = false;
    saveToServer = true;
    cancelCustomers = null;
    cancelCustomerCreate = null;

    initialize = saveToServer => {
        this.clear();
        this.id = uuid();
        this.saveToServer = !!saveToServer;
        this.isReady = true;

        return Promise.resolve();
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            let option = toJS(that.data);

            if (option.patientProfile && !option.patientProfile.healthCardNumber && !option.patientProfile.healthCardExpiryDate) {
                option.patientProfile = null;
            } else {
                option.patientProfile.healthCardNumber = option.patientProfile.healthCardNumber.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
            }

            if (option.primaryContactPerson && (!option.primaryContactPerson.firstName || !option.primaryContactPerson.lastName || !option.primaryContactPerson.isReady)) {
                option.primaryContactPerson = null;
            }

            if (that.data.preferredContactMethod) {
                option.preferredContactMethodType = that.data.preferredContactMethod; // the backend does the string to enum conversion
            }

            if (that.hasUnsavedChanges) {
                if (that.saveToServer && !that.data.id && !that.cancelCustomerCreate) {
                    api.Customers.create(
                        option,
                        (c) => { that.cancelCustomerCreate = c }
                    )
                        .then(({ data }) => {
                            that.hasUnsavedChanges = false;
                            that.data.id = data.id;
                            option.id = data.id;
                            option.relationshipSuggestions = data.relationshipSuggestions;

                            // TODO: remove in the future.  
                            try { navigator.clipboard.writeText(ph.getLastFirstName(that.data)); } catch { }

                            resolve(option);
                        })
                        .catch(() => {
                            reject();
                        })
                        .finally(() => {
                            that.cancelCustomerCreate = null;
                            this.isSaving = false;
                        })
                } else {
                    resolve(option);
                }
            } else {
                that.hasUnsavedChanges = false;
                this.isSaving = false;
                resolve(option);
            }
        })
    }

    checkConflicts = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            ch.checkConflicts(this.data)
                .then((data) => {
                    resolve(data);
                })
                .catch(error => {
                    reject();
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    clear = () => {
        this.id = null;
        this.data.id = null;
        this.data.salutationType = null;
        this.data.firstName = null;
        this.data.preferredFirstName = null;
        this.data.middleName = null;
        this.data.lastName = null;
        this.data.suffixType = null;
        this.data.sexType = null;
        this.data.genderType = null;
        this.data.genderOther = null;
        this.data.pronounType = null;
        this.data.pronounOther = null;
        this.data.dateOfBirth = null;
        this.data.phoneNumber = null;
        this.data.phoneNumberOnly = null;
        this.data.phoneNumberExtension = null;
        this.data.phoneType = null;
        this.data.emailAddress = null;
        this.data.preferredContactMethod = null;
        this.data.communicationConsent = null;
        this.data.relatedToId = null;
        this.data.relatedToRelationship = null;
        this.data.patientProfile = {
            healthCardNumber: null,
            healthCardExpiryDate: null,
            isVerified: false,
            verificationResponse: null,
            familyDoctorId: null,
            familyDoctorClinicId: null,
        };

        this.data.notes.clear();
        this.hasUnsavedChanges = false;
        this.isReady = false;
        this.isSaving = false;
        this.saveToServer = true;
        this.clearAddress();
        this.clearPrimaryContactPerson();

        if (fn.isFunction(this.cancelCustomers)) {
            this.cancelCustomers();
            this.cancelCustomers = null;
        }

        if (fn.isFunction(this.cancelCustomerCreate)) {
            this.cancelCustomerCreate();
            this.cancelCustomerCreate = null;
        }
    }

    clearAddress = () => {
        if (this.data && this.data.address) {
            this.data.address.geocode = null;
            this.data.address.streetNumber = null;
            this.data.address.streetName = null;
            this.data.address.unitSuiteType = null;
            this.data.address.unitSuite = null;
            this.data.address.line2 = null;
            this.data.address.locality = null;
            this.data.address.sublocality = null;
            this.data.address.postalCode = null;
            this.data.address.region = null;
            this.data.address.regionCode = null;
            this.data.address.country = null;
            this.data.address.countryCode = null;
        }
    }

    clearPrimaryContactPerson = () => {
        if (this.data && this.data.primaryContactPerson) {
            this.data.primaryContactPerson.isReady = false;
            this.data.primaryContactPerson.id = null;
            this.data.primaryContactPerson.salutationType = null;
            this.data.primaryContactPerson.firstName = null;
            this.data.primaryContactPerson.preferredFirstName = null;
            this.data.primaryContactPerson.middleName = null;
            this.data.primaryContactPerson.lastName = null;
            this.data.primaryContactPerson.suffixType = null;
            this.data.primaryContactPerson.emailAddress = null;
            this.data.primaryContactPerson.phoneNumber = null;
            this.data.primaryContactPerson.phoneType = null;
            this.data.primaryContactPerson.relationship = null;
            this.data.primaryContactPerson.color = null;
            this.data.primaryContactPerson.address = null;
        }
    }
}

decorate(CustomerCreate, {
    id: observable,
    data: observable.deep,
    hasUnsavedChanges: observable,
    isReady: observable,
    isSaving: observable,
    saveToServer: observable,
    initialize: action,
    checkConflicts: action,
    save: action,
    clear: action,
    clearAddress: action,
    clearPrimaryContactPerson: action,
})

export default createContext(new CustomerCreate());