import React, { useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import FadeIn from 'react-fade-in';
import { Observer } from 'mobx-react-lite';
import { GlobalHotKeys } from 'react-hotkeys';
import { toast } from 'react-toastify';
import moment from 'moment';

import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';

import PurchaseViewStore from '../../../../stores/PurchaseViewStore';
import WorkOrderCreateStore from '../../../../stores/WorkOrderCreateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as rts  from '../../../../constants/routes';
import * as fn from '../../../../utilities/_functions';
import * as uh from '../../../../utilities/userHelper';
import * as bh from '../../../../utilities/badgeHelper';
import * as oh from '../../../../utilities/operationHelper';

import './ViewWorkOrders.scss';

function ViewWorkOrders(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const validateRef = useRef(null);
    const navigate = useNavigate();
    const newWorkOrder = useContext(WorkOrderCreateStore);
    const purchase = useContext(PurchaseViewStore);
    const quickDrawer = useContext(QuickDrawerStore);

    useEffect(() => {
        quickDrawerFocus(props.drawer);

        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            props.onCancel();
        }
    }

    const handleWorkOrderClick = (event, { id }) => {
        navigate(`${rts.WorkOrders.View}/${id}`);
    }

    const handleCreateWorkOrder = (event) => {
        newWorkOrder.initialize(purchase.data.customerId, purchase.id)
            .then(() => {
                quickDrawer.activateQuickDrawer('order', 'create', null, handleNewWorkOrderSuccess, handleNewWorkOrderCancel)
                    .then(drawer => {
                        if (isMounted.current) {
                            focusTimer.current = setTimeout(() => {
                                quickDrawerFocus(drawer);
                            }, 100);
                        }
                    });
            })
    }

    const handleNewWorkOrderSuccess = (event, result) => {
        if (result && result.updated) {
            if (result.data && result.data.length > 0) {
                toast.dark(() => <p data-wo-added>Work order(s) added.</p>);
            } 
            else {
                toast.dark(() => <p data-wo-added>Work order added.</p>);
            }
        }
        newWorkOrder.clear();
    }

    const handleNewWorkOrderCancel = (event) => {
        newWorkOrder.clear();
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form ref={validateRef}>
            <Observer>{() =>
                <fieldset>
                    <div className='quick-drawer'>
                        <QuickDrawerHeader
                            drawer={props.drawer}
                            icon={oh.getIcon('work-order', 'default')}
                            action='View'
                            category='Work Orders'
                            className='orders'
                            onCancel={handleCancel}
                        />
                        <div className='quick-drawer-body'>
                            {
                                purchase.isReady ?
                                    <FadeIn>
                                        <div className='work-orders-container'>
                                            <div className='body-content p-0'>
                                                <section className='work-orders'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-0 validate validate-required'>
                                                                <Observer>{() => <>
                                                                    {
                                                                        purchase.data && purchase.data.workOrders && purchase.data.workOrders.filter(o => o.items && o.items.length > 0).length > 0 ?
                                                                            purchase.data.workOrders.filter(o => o.items && o.items.length > 0).sort((a, b) => { return a.number - b.number }).map((o, oi) => {
                                                                                return <div
                                                                                    key={`purchase-work-order-${oi}`}
                                                                                    className={'work-order-wrapper mb-4' + (oi !== 0 ? ' pt-4 border-top' : '')}
                                                                                    onClick={(e) => { handleWorkOrderClick(e, o) }}
                                                                                >
                                                                                    <span className='d-flex flex-row'>
                                                                                        <span className='title fs-sm d-block text-gray-700'>Work Order # {o.number}</span>
                                                                                        <div className='ml-auto'>{bh.renderWorkOrderRedo(o, 'p-1 mr-1')}{bh.renderWorkOrderStatus(o, 'p-1')}</div>
                                                                                    </span>
                                                                                    <span className='descriptor fs-md fw-500 d-block text-gray-700 text-truncate text-truncate-lg'>
                                                                                        {
                                                                                            o.items[0].transactionItems && o.items[0].transactionItems.length > 1 ?
                                                                                                <span className='mr-1'>{o.items[0].transactionItems.length}x</span> : null
                                                                                        }
                                                                                        {o.items[0].descriptor}
                                                                                    </span>
                                                                                    {
                                                                                        o.items.length > 1 ?
                                                                                            o.items.slice(1).map((i, ii) => {
                                                                                                return <span
                                                                                                    key={`link-order-${oi}-${ii}`}
                                                                                                    className='fs-xs d-flex'>
                                                                                                    {
                                                                                                        i.transactionItems && i.transactionItems.length > 1 ?
                                                                                                            <span
                                                                                                                className='mr-1'
                                                                                                            >
                                                                                                                {i.transactionItems.length}x
                                                                                                            </span> : null
                                                                                                    }
                                                                                                    {i.descriptor}
                                                                                                </span>
                                                                                            }) : null
                                                                                    }
                                                                                    <span className='text-muted fs-xs d-block'>
                                                                                        By <span className='text-gray-700 fw-500'>{uh.getDisplayShortNameById(o.createdById, o.createdBy)}</span> on {fn.formatFullDateWithOrWithoutYear(moment.utc(o.createdDateUtc).local())}
                                                                                    </span>
                                                                                </div>
                                                                            }) : null
                                                                    }
                                                                </>
                                                                }</Observer>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </FadeIn> : renderQuickDrawerLoading()
                            }
                        </div>
                        <div className='quick-drawer-action'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='float-right'>
                                        <button
                                            type='button'
                                            className={'btn btn-link btn-cancel' + (purchase.dataSets && purchase.dataSets.length > 0 ? ' mr-2' : '')}
                                            onClick={handleCancel}
                                        >Cancel</button>
                                        <button
                                            type='button'
                                            className='btn btn-success'
                                            onClick={handleCreateWorkOrder}
                                        >Create</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            }</Observer>
        </form>
    </>
}

export default ViewWorkOrders;