import React, { Component } from 'react';

import ViewPretest from './View';
import CreatePretest from './Create';
import UpdatePretest from './Update';

import * as fn from '../../../../utilities/_functions';

import './_index.scss';

export const PRETEST_MODAL_GRID_WIDTH = 960;

export default class PretestModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: props.defaultMode,
            defaultMode: props.defaultMode,
            appointment: props.appointment
        };
        this.changeMode = this.changeMode.bind(this);
        this.handleModeChange = this.handleModeChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
        this.renderContent = this.renderContent.bind(this);
    }

    changeMode = mode => {
        this.handleModeChange(mode);
    }

    handleModeChange = mode => {
        this.setState({ mode });
    }

    handleClose = event => {
        const { defaultMode } = this.state;
        this.setState({ mode: defaultMode }, () => {
            if (this.props.onClose) {
                this.props.onClose(event);
            }
        });
    }

    handleSuccess = (event, result) => {
        if (fn.isFunction(this.props.onSuccess)) {
            this.props.onSuccess(event, result);
        }
    }

    renderContent = () => {
        const { mode, appointment } = this.state;
        const { display, ready, device } = this.props;

        const contentProps = {
            device: device,
            appointment: appointment,
            mode: mode,
            display: display,
            ready: ready,
            changeMode: this.changeMode,
            onModeChange: this.handleModeChange,
            onSuccess: this.handleSuccess,
            onClose: this.handleClose,
        };

        switch (mode.toLowerCase()) {
            case 'view':
                return <ViewPretest {...contentProps} />;

            case 'create':
                return <CreatePretest {...contentProps} />;

            case 'update':
                return <UpdatePretest {...contentProps} />;

            default:
                return;
        }
    }

    render() {
        return <>
            <div
                className={'pretest-modal modal d-block'}
                style={{ zIndex: (this.props.display ? fn.getHighestZIndex() : -99999) }}
            >
                {this.renderContent()}
            </div>
        </>
    }
}