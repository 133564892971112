import React, { useEffect, useContext, useState, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { Observer } from 'mobx-react-lite';
import { GlobalHotKeys } from 'react-hotkeys';
import momentLocalizer from 'react-widgets-moment';
import moment from 'moment';

import BodyEnd from '../../_shared/BodyEnd';
import ConfirmModal from '../../_shared/ConfirmModal';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import RichTextEditor from '../../_shared/RichTextEditor';

import WorkOrderUpdateStore from '../../../../stores/WorkOrderUpdateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as bh from '../../../../utilities/badgeHelper';

import './UpdateWorkOrderStatus.scss';

moment.locale('en');
momentLocalizer();

function UpdateWorkOrderStatus(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const quickDrawer = useContext(QuickDrawerStore);
    const workOrderUpdate = useContext(WorkOrderUpdateStore);
    const [confirmDelete, setConfirmDelete] = useState(false);

    useEffect(() => {
        if (isMounted.current && workOrderUpdate.data) {
            quickDrawerFocus(props.drawer);
        }

        return () => {
            isMounted.current = false;
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (workOrderUpdate.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleReasonChange = (content, delta, source, editor) => {
        const reason = editor.getText().replace(/(\r\n|\n|\r)/g, ' ').trim();
        const reasonHtml = (content === '<p><br></p>') ? null : content;

        workOrderUpdate.deleteReason = reason;
        workOrderUpdate.deleteReasonHtml = reasonHtml;
        workOrderUpdate.hasUnsavedChanges = true;
    }

    const handleSubmit = event => {
        event.preventDefault();
        if (isMounted.current) {
            if (fn.validateForm(validateRef.current)) {
                setConfirmDelete(true);
            }
        }
    }

    const handleConfirmDelete = event => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            workOrderUpdate.delete()
                .then(data => {
                    if (isMounted.current) {
                        workOrderUpdate.deleteReason = null;
                        workOrderUpdate.deleteReasonHtml = null;

                        if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                            props.onSuccess(event, data);
                        }
                    }
                })
        }
    }

    const handleConfirmCancel = event => {
        event.preventDefault();
        setConfirmDelete(false);

        if (props.onCancel && fn.isFunction(props.onCancel)) {
            props.onCancel(event);
        }
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <Observer>{() =>
                <fieldset disabled={workOrderUpdate.isSaving}>
                    <div className='quick-drawer'>
                        <QuickDrawerHeader
                            drawer={props.drawer}
                            icon={'fas fa-trash-alt'}
                            action='Delete'
                            category='Work Order'
                            className='delete'
                            onCancel={props.onCancel}
                        />
                        <div className='quick-drawer-body'>
                            {
                                workOrderUpdate.data ?
                                    <FadeIn>
                                        <div className='body-content'>
                                            <section className='mb-4'>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <Observer>{() => <>
                                                            {
                                                                workOrderUpdate.data.items && workOrderUpdate.data.items.length > 0 ?
                                                                    <div className='d-flex flex-row'>
                                                                        <div className='fs-md fw-500 d-block text-gray-700 text-truncate text-truncate-lg'>
                                                                            {
                                                                                workOrderUpdate.data.items[0].transactionItems && workOrderUpdate.data.items[0].transactionItems.length > 1 ?
                                                                                    <span className='mr-1'>{workOrderUpdate.data.items[0].transactionItems.length}x</span> : null
                                                                            }
                                                                            {workOrderUpdate.data.items[0].descriptor}
                                                                        </div>
                                                                        <div className='ml-auto'>
                                                                            {bh.renderWorkOrderRedo(workOrderUpdate.data, 'p-1 mr-1')}{bh.renderWorkOrderStatus(workOrderUpdate.data, 'p-1')}
                                                                        </div>
                                                                    </div> : null
                                                            }
                                                            {
                                                                workOrderUpdate.data.items.length > 1 ?
                                                                    workOrderUpdate.data.items.slice(1).map((i, ii) => {
                                                                        return <div
                                                                            key={`order-${ii}`}
                                                                            className='fs-xs d-flex'>
                                                                            {
                                                                                i.transactionItems && i.transactionItems.length > 1 ?
                                                                                    <div
                                                                                        className='mr-1'
                                                                                    >
                                                                                        {i.transactionItems.length}x
                                                                                    </div> : null
                                                                            }
                                                                            {i.descriptor}
                                                                        </div>
                                                                    }) : null
                                                            }
                                                        </>}</Observer>
                                                    </div>
                                                </div>
                                            </section>
                                            <section>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='form-group validate validate-required'>
                                                            <label className='required'><small>Reason</small></label>
                                                            <Observer>{() =>
                                                                <RichTextEditor
                                                                    mode='none'
                                                                    value={workOrderUpdate.deleteReasonHtml}
                                                                    disableTab={true}
                                                                    onChange={handleReasonChange}
                                                                />
                                                            }</Observer>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </FadeIn> : renderQuickDrawerLoading()
                            }
                        </div>
                        <Observer>{() =>
                            workOrderUpdate.data ?
                                <div className='quick-drawer-action'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='float-right'>
                                                <button
                                                    type='button'
                                                    className='btn btn-link btn-cancel mr-2'
                                                    onClick={props.onCancel}
                                                >Cancel</button>
                                                <button
                                                    type='submit'
                                                    className='btn btn-danger'
                                                >Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </div> : null
                        }</Observer>
                    </div>
                </fieldset>
            }</Observer>
        </form>
        <BodyEnd>
            <ConfirmModal
                icon={<i className='fas fa-trash-alt text-danger mr-2'></i>}
                message={<>Continue to delete <span className='fw-500'>&nbsp;work order # {workOrderUpdate.data ? workOrderUpdate.data.number : ''}</span>? </>}
                show={confirmDelete}
                onOption1Click={handleConfirmDelete}
                onCancel={handleConfirmCancel}
            />
        </BodyEnd>
    </>
}

export default UpdateWorkOrderStatus;