import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import { List, Map } from 'immutable';

import * as fn from '../../../utilities/_functions';

import './ImageAnnotation.scss';

export default class ImageAnnotation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lines: new List(),
            isDrawing: false
        };

        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.handleClear = this.handleClear.bind(this);

        this.drawingRef = React.createRef();
    }

    componentDidMount() {
        if (fn.isNullOrUndefined(this.props.disabled) || this.props.disabled === false) {
            this.drawingRef.current.addEventListener('mouseup', this.handleMouseUp);
        }
    }

    componentWillUnmount() {
        this.drawingRef.current.removeEventListener('mouseup', this.handleMouseUp);
    }

    handleMouseDown = event => {
        if (fn.isNullOrUndefined(this.props.disabled) || this.props.disabled === false) {
            if (event.button !== 0) return;

            const point = this.relativeCoordinatesForEvent(event);

            this.setState(previousState => ({
                lines: previousState.lines.push(new List([point])),
                isDrawing: true
            }));
        }
    }

    handleMouseMove = event => {
        if (fn.isNullOrUndefined(this.props.disabled) || this.props.disabled === false) {
            if (!this.state.isDrawing) return;

            const point = this.relativeCoordinatesForEvent(event);

            this.setState(previousState => ({
                lines: previousState.lines.updateIn([previousState.lines.size - 1], line => line.push(point))
            }));
        }
    }

    handleMouseUp = event => {
        if (fn.isNullOrUndefined(this.props.disabled) || this.props.disabled === false) {
            this.setState({ isDrawing: false });
            if (this.props.onChange && fn.isFunction(this.props.onChange)) {
                const result = ReactDOMServer.renderToStaticMarkup(
                    <Drawing lines={this.state.lines} />
                );
                this.props.onChange(event, { result: result, isClear: false, isUndo: false });
                this.setState({ lines: new List() });
            }
        }
    }

    handleUndo = event => {
        if (this.props.onChange && fn.isFunction(this.props.onChange)) {
            this.props.onChange(event, { result: null, isClear: false, isUndo: true });
        }
    }

    handleClear = event => {
        if (this.props.onChange && fn.isFunction(this.props.onChange)) {
            this.props.onChange(event, { result: null, isClear: true, isUndo: false });
            this.setState({ lines: new List() });
        }
    }

    relativeCoordinatesForEvent = event => {
        const boundingRect = this.refs.drawArea.getBoundingClientRect();
        return new Map({
            x: event.clientX - boundingRect.left,
            y: event.clientY - boundingRect.top,
        });
    }

    render() {
        return (
            <div className='image-annotation' ref={this.drawingRef}>
                <div
                    className={'drawarea' + (this.props.disabled === true ? ' draw-disabled' : '')}
                    ref='drawArea'
                    onMouseDown={fn.isNullOrUndefined(this.props.disabled) || this.props.disabled === false ? this.handleMouseDown : null}
                    onMouseMove={fn.isNullOrUndefined(this.props.disabled) || this.props.disabled === false ? this.handleMouseMove : null}
                >
                    <Drawing lines={this.state.lines} />
                </div>
                <img
                    className='image-background'
                    src={this.props.src}
                    width={this.props.width}
                    height={this.props.height}
                    data-key={this.props.key}
                    alt='Background'
                />
                <div className='path-overlay' dangerouslySetInnerHTML={{ __html: (this.props.value ? this.props.value : '') }}></div>
                <div className='buttons'>
                    <button
                        type='button'
                        className={'btn btn-icon btn-undo btn-sm' + (this.props.disabled === true ? ' d-none' : '')}
                        onClick={fn.isNullOrUndefined(this.props.disabled) || this.props.disabled === false ? this.handleUndo : null}
                    >
                        <i className='fal fa-undo'></i>
                    </button>
                    <button
                        type='button'
                        className={'btn btn-icon btn-close btn-sm' + (this.props.disabled === true ? ' d-none' : '')}
                        onClick={fn.isNullOrUndefined(this.props.disabled) || this.props.disabled === false ? this.handleClear : null}
                    >
                        <i className='fal fa-times'></i>
                    </button>
                </div>
            </div>
        )
    }
}

function Drawing({ lines }) {
    return (
        <svg>
            {
                lines.map((line, li) => (
                    <DrawingLine key={`drawing-line-${li}`} line={line} />
                ))
            }
        </svg>
    )
}

function DrawingLine({ line }) {
    const pathData = 'M ' +
        line.map(p => {
            return `${p.get('x')} ${p.get('y')}`;
        })
            .join(' L ');

    return <path className='path-line' d={pathData} />;
}
