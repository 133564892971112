import React, { useEffect, useContext, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import FadeIn from 'react-fade-in';
import { useObserver } from 'mobx-react-lite';
import { GlobalHotKeys } from 'react-hotkeys';
import moment from 'moment';

import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';

import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import api from '../../../../api';
import * as ErrorMessages from '../../../../constants/errorMessages';
import * as rts  from '../../../../constants/routes';
import * as fn from '../../../../utilities/_functions';
import * as ah from '../../../../utilities/addressHelper';
import * as ch from '../../../../utilities/customerHelper';
import * as ph from '../../../../utilities/personHelper';
import * as sys from '../../../../utilities/systemHelper';
import * as oh from '../../../../utilities/operationHelper';

import './StartPrescription.scss';

function StartPrescription(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const focusTimer = useRef(null);
    const quickDrawer = useContext(QuickDrawerStore);
    const [prescriptionTypes, setPrescriptionTypes] = useState(null);
    const [selectedPrescriptionType, setSelectedPrescriptionType] = useState(null);
    const [prescriptionTemplates, setPrescriptionTemplates] = useState(null);
    const [selectedPrescriptionTemplate, setSelectedPrescriptionTemplate] = useState(null);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    useEffect(() => {
        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100)

        if (isMounted.current && !prescriptionTypes) {
            Promise.all([
                api.PrescriptionTypes.all(),
                api.PrescriptionPublishedTemplates.all(),
            ])
                .then(response => {
                    if (isMounted.current) {
                        setPrescriptionTypes(response[0].data);
                        setPrescriptionTemplates(response[1].data);
                    }
                })
        }

        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handlePrescriptionOptionTypeChange = event => {
        const prescriptionType = prescriptionTypes.filter(t => t.id === event.target.value)[0];

        setSelectedPrescriptionType(prescriptionType);

        if (prescriptionType) {
            const prescriptionTemplate = prescriptionTemplates.filter(t => t.prescriptionTypeId === prescriptionType.id)[0];
            setSelectedPrescriptionTemplate(prescriptionTemplate);
        } else {
            setSelectedPrescriptionTemplate(null);
        }

        setHasUnsavedChanges(true);
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                props.onSuccess(event, {
                    updated: true, data: {
                        prescriptionType: selectedPrescriptionType,
                        prescriptionPublishedTemplate: selectedPrescriptionTemplate,
                    }
                });
            }
            setHasUnsavedChanges(false);
        }
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <fieldset>
                <div className='add-prescription-container quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('exam', 'prescription')}
                        action='Add'
                        category='Prescription'
                        className='exam'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        {
                            prescriptionTypes ?
                                <FadeIn>
                                    <div className='body-content'>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4 validate validate-required'>
                                                        <label className='required' htmlFor='add-customer-prescription-type'><small>Type</small></label>
                                                        <select
                                                            id='add-customer-prescription-type'
                                                            className='custom-select form-control'
                                                            value={selectedPrescriptionType ? selectedPrescriptionType.id : ''}
                                                            onChange={handlePrescriptionOptionTypeChange}
                                                        >
                                                            <option value=''></option>
                                                            {
                                                                prescriptionTypes ?
                                                                    prescriptionTypes.map((t, ti) => {
                                                                        return <option
                                                                            key={`add_customer_prescription_type_${ti}`}
                                                                            value={t.id}
                                                                        >{t.name}</option>
                                                                    }) : null
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        {
                                            props.extraProps && props.extraProps.customer ?
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-0'>
                                                                <label><small>For</small></label>
                                                                <div
                                                                    className='profile-wrapper pr-0'
                                                                >
                                                                    <div className='profile'>
                                                                        <span
                                                                            className={`profile-image profile-initials rounded-circle d-flex text-white ${ch.getProfileColor(props.extraProps.customer)} fw-500`}
                                                                            title={ph.getFullName(props.extraProps.customer)}
                                                                        >
                                                                            {props.extraProps.customer.initials}
                                                                        </span>
                                                                    </div>
                                                                    <div className='description flex-1'>
                                                                        <Link
                                                                            to={`${rts.Customers.Home}/${props.extraProps.customer.id}`}
                                                                            className='name text-gray-700 text-info-hover'
                                                                            onClick={quickDrawer.deactivateAll}
                                                                        >
                                                                            {ph.getFullName(props.extraProps.customer, true)}
                                                                            {
                                                                                props.extraProps.customer.dateOfBirth || props.extraProps.customer.sex || props.extraProps.customer.gender || props.extraProps.customer.pronoun ?
                                                                                    <small className='text-nowrap ml-2'>({`${ph.getAge(props.extraProps.customer.dateOfBirth, moment((props.extraProps.appointment ? props.extraProps.appointment.start : null)))} ${ph.getSexGenderPronounDisplay(props.extraProps.customer)}`.trim()})</small> : null
                                                                            }
                                                                        </Link>
                                                                        {
                                                                            props.extraProps.customer.address && props.extraProps.customer.address.country ?
                                                                                <div className='info'>{ah.getAddressHtml(props.extraProps.customer.address)}</div> : null
                                                                        }
                                                                        {
                                                                            props.extraProps.customer.emailAddress ?
                                                                                <div className='info'>
                                                                                    <a
                                                                                        href={`mailto:${props.extraProps.customer.emailAddress}`}
                                                                                    >{props.extraProps.customer.emailAddress}
                                                                                    </a>
                                                                                </div> : null
                                                                        }
                                                                        {
                                                                            props.extraProps.customer.phoneNumber ?
                                                                                <div className='info'>
                                                                                    <a
                                                                                        href={`tel:${props.extraProps.customer.phoneNumber}`}
                                                                                    >{sys.getFormattedPhoneNumber(props.extraProps.customer.phoneNumber)}
                                                                                    </a>
                                                                                </div> : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section> : null
                                        }
                                    </div>
                                </FadeIn> : renderQuickDrawerLoading()
                        }
                    </div>
                    <div className='quick-drawer-action pl-3'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-info'
                                    >Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>)
}

export default StartPrescription;