import { createContext } from 'react';
import { decorate, observable, action } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class OrganizationView {
    id = null;
    data = null;
    isLoading = false;

    cancelOrganization = null;

    initialize = id => {
        if (id === this.id) return Promise.resolve();

        this.clear();
        this.id = id;

        return this.refresh();
    }

    refresh = () => {
        const that = this;

        this.isLoading = true;

        return new Promise((resolve, reject) => {
            api.Organizations.all((c) => { that.cancelOrganization = c })
                .then(({ data }) => {
                    that.data = data;
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isLoading = false;
                })
        })
    }

    clear = () => {
        this.id = null;
        this.data = null;
        this.isLoading = false;

        if (fn.isFunction(this.cancelOrganization)) {
            this.cancelOrganization();
            this.cancelOrganization = null;
        }
    }
}

decorate(OrganizationView, {
    id: observable,
    data: observable.deep,
    isLoading: observable,
    initialize: action,
    clear: action,
})

export default createContext(new OrganizationView());