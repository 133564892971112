import { createContext } from 'react';
import { decorate, observable, action, computed } from 'mobx';
import { getHighestZIndex } from '../utilities/_functions';

export class QuickDrawer {
    zIndex = 0;
    drawerOpened = 0;
    context1 = null;
    action1 = null;
    extraProps1 = null;
    success1Callback = null;
    cancel1Callback = null;
    error1Callback = null;
    context2 = null;
    action2 = null;
    extraProps2 = null;
    success2Callback = null;
    cancel2Callback = null;
    error2Callback = null;
    context3 = null;
    action3 = null;
    extraProps3 = null;
    success3Callback = null;
    cancel3Callback = null;
    error3Callback = null;
    context4 = null;
    action4 = null;
    extraProps4 = null;
    success4Callback = null;
    cancel4Callback = null;
    error4Callback = null;
    context5 = null;
    action5 = null;
    extraProps5 = null;
    success5Callback = null;
    cancel5Callback = null;
    error5Callback = null;
    context6 = null;
    action6 = null;
    extraProps6 = null;
    success6Callback = null;
    cancel6Callback = null;
    error6Callback = null;
    isQuickDrawer1Activated = false;
    isQuickDrawer2Activated = false;
    isQuickDrawer3Activated = false;
    isQuickDrawer4Activated = false;
    isQuickDrawer5Activated = false;
    isQuickDrawer6Activated = false;
    quickDrawer1ZIndex = null;
    quickDrawer2ZIndex = null;
    quickDrawer3ZIndex = null;
    quickDrawer4ZIndex = null;
    quickDrawer5ZIndex = null;
    quickDrawer6ZIndex = null;

    initialize = (context, action, extraProps) => {
        this.context1 = context;
        this.action1 = action;
        this.extraProps1 = extraProps;
        this.success1Callback = null;
        this.cancel1Callback = null;
        this.error1Callback = null;
        this.context2 = null;
        this.action2 = null;
        this.extraProps2 = extraProps;
        this.success2Callback = null;
        this.cancel2Callback = null;
        this.error2Callback = null;
        this.context3 = null;
        this.action3 = null;
        this.extraProps3 = extraProps;
        this.success3Callback = null;
        this.cancel3Callback = null;
        this.error3Callback = null;
        this.context4 = null;
        this.action4 = null;
        this.extraProps4 = extraProps;
        this.success4Callback = null;
        this.cancel4Callback = null;
        this.error4Callback = null;
        this.context5 = null;
        this.action5 = null;
        this.extraProps5 = extraProps;
        this.success5Callback = null;
        this.cancel5Callback = null;
        this.error5Callback = null;
        this.context6 = null;
        this.action6 = null;
        this.extraProps6 = extraProps;
        this.success6Callback = null;
        this.cancel6Callback = null;
        this.error6Callback = null;
        this.isQuickDrawer1Activated = false;
        this.isQuickDrawer2Activated = false;
        this.isQuickDrawer3Activated = false;
        this.isQuickDrawer4Activated = false;
        this.isQuickDrawer5Activated = false;
        this.isQuickDrawer6Activated = false;
        return Promise.resolve();
    }

    activateQuickDrawer = (context, action, extraProps, successCallback, cancelCallback, errorCallback) => {
        let drawer;

        if (!(this.context1 === context && this.action1 === action) &&
            !(this.context2 === context && this.action2 === action) &&
            !(this.context3 === context && this.action3 === action) &&
            !(this.context4 === context && this.action4 === action) &&
            !(this.context5 === context && this.action5 === action) &&
            !(this.context6 === context && this.action6 === action)) {

            if (!this.isQuickDrawer1Activated) {
                this.activateQuickDrawer1(context, action, extraProps, successCallback, cancelCallback, errorCallback);
                drawer = 1;
            }
            else if (!this.isQuickDrawer2Activated) {
                this.activateQuickDrawer2(context, action, extraProps, successCallback, cancelCallback, errorCallback);
                drawer = 2;
            }
            else if (!this.isQuickDrawer3Activated) {
                this.activateQuickDrawer3(context, action, extraProps, successCallback, cancelCallback, errorCallback);
                drawer = 3;
            }
            else if (!this.isQuickDrawer4Activated) {
                this.activateQuickDrawer4(context, action, extraProps, successCallback, cancelCallback, errorCallback);
                drawer = 4;
            }
            else if (!this.isQuickDrawer5Activated) {
                this.activateQuickDrawer5(context, action, extraProps, successCallback, cancelCallback, errorCallback);
                drawer = 5;
            }
            else if (!this.isQuickDrawer6Activated) {
                this.activateQuickDrawer6(context, action, extraProps, successCallback, cancelCallback, errorCallback);
                drawer = 6;
            }
        }

        return Promise.resolve(drawer);
    }

    activateQuickDrawer1 = (context, action, extraProps, successCallback, cancelCallback, errorCallback) => {
        this.drawerOpened = 1;
        this.context1 = context;
        this.action1 = action;
        this.extraProps1 = extraProps;
        this.success1Callback = successCallback;
        this.cancel1Callback = cancelCallback;
        this.error1Callback = errorCallback;
        this.isQuickDrawer1Activated = true;
        this.zIndex = getHighestZIndex() + 10;
        this.quickDrawer1ZIndex = this.zIndex + 1;
        return Promise.resolve();
    }

    deactivateQuickDrawer1 = () => {
        this.drawerOpened = 0;
        this.context1 = null;
        this.action1 = null;
        this.extraProps1 = null;
        this.success1Callback = null;
        this.cancel1Callback = null;
        this.error1Callback = null;
        this.isQuickDrawer1Activated = false;
        this.quickDrawer1ZIndex = null;
        this.zIndex = -99999;
        return Promise.resolve();
    }

    activateQuickDrawer2 = (context, action, extraProps, successCallback, cancelCallback, errorCallback) => {
        this.drawerOpened = 2;
        this.context2 = context;
        this.action2 = action;
        this.extraProps2 = extraProps;
        this.success2Callback = successCallback;
        this.cancel2Callback = cancelCallback;
        this.error2Callback = errorCallback;
        this.isQuickDrawer2Activated = true;
        this.quickDrawer2ZIndex = Math.max(this.zIndex, getHighestZIndex()) + 2;
        return Promise.resolve();
    }

    deactivateQuickDrawer2 = () => {
        this.drawerOpened = 1;
        this.context2 = null;
        this.action2 = null;
        this.extraProps2 = null;
        this.success2Callback = null;
        this.cancel2Callback = null;
        this.error2Callback = null;
        this.isQuickDrawer2Activated = false;
        this.quickDrawer2ZIndex = null;
        return Promise.resolve();
    }

    activateQuickDrawer3 = (context, action, extraProps, successCallback, cancelCallback, errorCallback) => {
        this.drawerOpened = 3;
        this.context3 = context;
        this.action3 = action;
        this.extraProps3 = extraProps;
        this.success3Callback = successCallback;
        this.cancel3Callback = cancelCallback;
        this.error3Callback = errorCallback;
        this.isQuickDrawer3Activated = true;
        this.quickDrawer3ZIndex = Math.max(this.zIndex, getHighestZIndex()) + 3;
        return Promise.resolve();
    }

    deactivateQuickDrawer3 = () => {
        this.drawerOpened = 2;
        this.context3 = null;
        this.action3 = null;
        this.extraProps3 = null;
        this.success3Callback = null;
        this.cancel3Callback = null;
        this.error3Callback = null;
        this.isQuickDrawer3Activated = false;
        this.quickDrawer3ZIndex = null;
        return Promise.resolve();
    }

    activateQuickDrawer4 = (context, action, extraProps, successCallback, cancelCallback, errorCallback) => {
        this.drawerOpened = 4;
        this.context4 = context;
        this.action4 = action;
        this.extraProps4 = extraProps;
        this.success4Callback = successCallback;
        this.cancel4Callback = cancelCallback;
        this.error4Callback = errorCallback;
        this.isQuickDrawer4Activated = true;
        this.quickDrawer4ZIndex = Math.max(this.zIndex, getHighestZIndex()) + 4;
        return Promise.resolve();
    }

    deactivateQuickDrawer4 = () => {
        this.drawerOpened = 3;
        this.context4 = null;
        this.action4 = null;
        this.extraProps4 = null;
        this.success4Callback = null;
        this.cancel4Callback = null;
        this.error4Callback = null;
        this.isQuickDrawer4Activated = false;
        this.quickDrawer4ZIndex = null;
        return Promise.resolve();
    }

    activateQuickDrawer5 = (context, action, extraProps, successCallback, cancelCallback, errorCallback) => {
        this.drawerOpened = 5;
        this.context5 = context;
        this.action5 = action;
        this.extraProps5 = extraProps;
        this.success5Callback = successCallback;
        this.cancel5Callback = cancelCallback;
        this.error5Callback = errorCallback;
        this.isQuickDrawer5Activated = true;
        this.quickDrawer5ZIndex = Math.max(this.zIndex, getHighestZIndex()) + 5;
        return Promise.resolve();
    }

    deactivateQuickDrawer5 = () => {
        this.drawerOpened = 4;
        this.context5 = null;
        this.action5 = null;
        this.extraProps5 = null;
        this.success5Callback = null;
        this.cancel5Callback = null;
        this.error5Callback = null;
        this.isQuickDrawer5Activated = false;
        this.quickDrawer5ZIndex = null;
        return Promise.resolve();
    }

    activateQuickDrawer6 = (context, action, extraProps, successCallback, cancelCallback, errorCallback) => {
        this.drawerOpened = 6;
        this.context6 = context;
        this.action6 = action;
        this.extraProps6 = extraProps;
        this.success6Callback = successCallback;
        this.cancel6Callback = cancelCallback;
        this.error6Callback = errorCallback;
        this.isQuickDrawer6Activated = true;
        this.quickDrawer6ZIndex = Math.max(this.zIndex, getHighestZIndex()) + 6;
        return Promise.resolve();
    }

    deactivateQuickDrawer6 = () => {
        this.drawerOpened = 5;
        this.context6 = null;
        this.action6 = null;
        this.extraProps6 = null;
        this.success6Callback = null;
        this.cancel6Callback = null;
        this.error6Callback = null;
        this.isQuickDrawer6Activated = false;
        this.quickDrawer6ZIndex = null;
        return Promise.resolve();
    }

    deactivateAll = () => {
        this.deactivateQuickDrawer6();
        this.deactivateQuickDrawer5();
        this.deactivateQuickDrawer4();
        this.deactivateQuickDrawer3();
        this.deactivateQuickDrawer2();
        this.deactivateQuickDrawer1();
        this.zIndex = -99999;
        return Promise.resolve();
    }

    clear = () => {
        this.deactivateAll();
    }

    get isActivated() {
        return this.isQuickDrawer1Activated ||
            this.isQuickDrawer2Activated ||
            this.isQuickDrawer3Activated ||
            this.isQuickDrawer4Activated ||
            this.isQuickDrawer5Activated ||
            this.isQuickDrawer6Activated;
    }

    get successCallback() {
        switch (this.drawerOpened) {
            case 1:
                return this.success1Callback;

            case 2:
                return this.success2Callback;

            case 3:
                return this.success3Callback;

            case 4:
                return this.success4Callback;

            default:
                return null;
        }
    }

    get cancelCallback() {
        switch (this.drawerOpened) {
            case 1:
                return this.cancel1Callback;

            case 2:
                return this.cancel2Callback;

            case 3:
                return this.cancel3Callback;

            case 4:
                return this.cancel4Callback;

            default:
                return null;
        }
    }

    get errorCallback() {
        switch (this.drawerOpened) {
            case 1:
                return this.error1Callback;

            case 2:
                return this.error2Callback;

            case 3:
                return this.error3Callback;

            case 4:
                return this.error4Callback;

            default:
                return null;
        }
    }
}

decorate(QuickDrawer, {
    zIndex: observable,
    drawerOpened: observable,
    context1: observable,
    action1: observable,
    extraProps1: observable,
    success1Callback: observable,
    error1Callback: observable,
    context2: observable,
    action2: observable,
    extraProps2: observable,
    success2Callback: observable,
    error2Callback: observable,
    context3: observable,
    action3: observable,
    extraProps3: observable,
    success3Callback: observable,
    error3Callback: observable,
    context4: observable,
    action4: observable,
    extraProps4: observable,
    success4Callback: observable,
    error4Callback: observable,
    context5: observable,
    action5: observable,
    extraProps5: observable,
    success5Callback: observable,
    error5Callback: observable,
    context6: observable,
    action6: observable,
    extraProps6: observable,
    success6Callback: observable,
    error6Callback: observable,
    isQuickDrawer1Activated: observable,
    isQuickDrawer2Activated: observable,
    isQuickDrawer3Activated: observable,
    isQuickDrawer4Activated: observable,
    isQuickDrawer5Activated: observable,
    isQuickDrawer6Activated: observable,
    quickDrawer1ZIndex: observable,
    quickDrawer2ZIndex: observable,
    quickDrawer3ZIndex: observable,
    quickDrawer4ZIndex: observable,
    quickDrawer5ZIndex: observable,
    quickDrawer6ZIndex: observable,
    activateQuickDrawer1: action,
    deactivateQuickDrawer1: action,
    activateQuickDrawer2: action,
    deactivateQuickDrawer2: action,
    activateQuickDrawer3: action,
    deactivateQuickDrawer3: action,
    activateQuickDrawer4: action,
    deactivateQuickDrawer4: action,
    activateQuickDrawer5: action,
    deactivateQuickDrawer5: action,
    activateQuickDrawer6: action,
    deactivateQuickDrawer6: action,
    deactivateAll: action,
    clear: action,
    isActivated: computed,
    successCallback: computed,
    cancelCallback: computed,
    errorCallback: computed
})

export default createContext(new QuickDrawer());