import React, { useContext, useEffect, useState, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import FadeIn from 'react-fade-in';
import momentLocalizer from 'react-widgets-moment';
import { GlobalHotKeys } from 'react-hotkeys';
import { DateTimePicker } from 'react-widgets'
import moment from 'moment';

import QuickDrawerHeader from '../../../_shared/QuickDrawerHeader';
import { renderQuickDrawerLoading } from '../../../_shared/QuickDrawer';

import BusinessScheduleUpdateStore from '../../../../../stores/BusinessScheduleUpdateStore';
import QuickDrawerStore from '../../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../../constants/errorMessages';
import * as fn from '../../../../../utilities/_functions';
import * as oh from '../../../../../utilities/operationHelper';

import './NewBusinessHour.scss';
moment.locale('en');
momentLocalizer();

const DAYS_OF_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

function NewBusinessHour(props) {
    const businessScheduleUpdate = useContext(BusinessScheduleUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const isMounted = useRef(true);
    const pickerTimer = useRef(null);
    const [startDatePickerOpen, setStartDatePickerOpen] = useState(false);
    const [endDatePickerOpen, setEndDatePickerOpen] = useState(false);

    useEffect(() => {
        return () => {
            isMounted.current = false;

            businessScheduleUpdate.clear();
            quickDrawer.clear();

            if (pickerTimer.current) { clearTimeout(pickerTimer.current); pickerTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (businessScheduleUpdate.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleIsClosedChange = (event, dayOfWeek) => {
        const index = businessScheduleUpdate.data.findIndex(d => d.dayOfWeek === dayOfWeek);

        if (index < 0) {
            businessScheduleUpdate.data.push({
                dayOfWeek: dayOfWeek,
                start: null,
                end: null,
                isClosed: false,
            })
        } else {
            const isClosed = !businessScheduleUpdate.data[index].isClosed;

            if (!isClosed) {
                if (!businessScheduleUpdate.data[index].start || !businessScheduleUpdate.data[index].end) {
                    businessScheduleUpdate.data[index].start = '9:00';
                    businessScheduleUpdate.data[index].end = '17:00';
                }
            }

            businessScheduleUpdate.data[index].isClosed = isClosed;
        }

        businessScheduleUpdate.hasUnsavedChanges = true;
    }

    const handleStartChange = (value, dayOfWeek) => {
        const index = businessScheduleUpdate.data.findIndex(d => d.dayOfWeek === dayOfWeek);
        const time = moment(value);
        const start = time.clone().format('HH:mm');
        const end = time.clone().add(1, 'hours').format('HH:mm');

        if (index < 0) {
            businessScheduleUpdate.data.push({
                dayOfWeek: dayOfWeek,
                start: start,
                end: end,
                isClosed: false,
            })
        } else {
            businessScheduleUpdate.data[index].start = start;

            const minEnd = getStartTime(dayOfWeek).clone().add(1, 'hours');

            if (time.isBefore(minEnd)) {
                businessScheduleUpdate.data[index].end = minEnd.format('HH:mm');
            }
        }

        businessScheduleUpdate.hasUnsavedChanges = true;
    }

    const handleEndChange = (value, dayOfWeek) => {
        const index = businessScheduleUpdate.data.findIndex(d => d.dayOfWeek === dayOfWeek);
        const time = moment(value);
        const end = time.clone().format('HH:mm');

        businessScheduleUpdate.data[index].end = end;
        businessScheduleUpdate.hasUnsavedChanges = true;
    }

    const handleSubmit = event => {
        event.preventDefault();
        businessScheduleUpdate.save()
            .then(data => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, data);
                    }
                }
            })
    }

    const getStartTime = (dayOfWeek) => {
        const businessDay = businessScheduleUpdate.data.filter(d => d.dayOfWeek === dayOfWeek)[0];

        if (businessDay && businessDay.start) {
            const time = moment(businessDay.start, 'HH:mm');
            const startTime = moment().startOf('day').hours(time.hour()).minutes(time.minute());
            return startTime;
        }

        return null;
    }

    const getEndTime = (dayOfWeek) => {
        const businessDay = businessScheduleUpdate.data.filter(d => d.dayOfWeek === dayOfWeek)[0];

        if (businessDay && businessDay.end) {
            const time = moment(businessDay.end, 'HH:mm');
            const endTime = moment().startOf('day').hours(time.hour()).minutes(time.minute());
            return endTime;
        }

        return null;
    }

    const getIsClosed = (dayOfWeek) => {
        const businessDay = businessScheduleUpdate.data.filter(d => d.dayOfWeek === dayOfWeek)[0];
        return businessDay ? businessDay.isClosed : true;
    }

    return <>
        <form onSubmit={handleSubmit}>
            <Observer>{() =>
                <>
                    {
                        (startDatePickerOpen || endDatePickerOpen) ?
                            <GlobalHotKeys
                                keyMap={{
                                    closeAllPickers: ['esc'],
                                }}
                                handlers={{
                                    closeAllPickers: event => {
                                        setStartDatePickerOpen(false);
                                        setEndDatePickerOpen(false);
                                    }
                                }}
                                allowChanges={true}
                            /> :
                            <>
                                {
                                    (props.drawer === quickDrawer.drawerOpened) ?
                                        <GlobalHotKeys
                                            keyMap={{
                                                close: ['esc'],
                                            }}
                                            handlers={{
                                                close: event => {
                                                    handleCancel(event)
                                                },
                                            }}
                                            allowChanges={true}
                                        /> : null
                                }
                            </>
                    }
                    <fieldset>
                        <div className='quick-drawer'>
                            <QuickDrawerHeader
                                drawer={props.drawer}
                                icon={oh.getIcon('business-hours', 'default')}
                                action={'Update'}
                                category='Business Hours'
                                className={'schedules'}
                                onCancel={handleCancel}
                            />
                            <div className='quick-drawer-body'>
                                {
                                    businessScheduleUpdate.isReady ? <>
                                        <FadeIn>
                                            <div className='new-business-hours body-content'>
                                                {
                                                    DAYS_OF_WEEK.map((dow, di) => {
                                                        return <section
                                                            key={`business-hours-update-${di}`}
                                                            className='business-hours'
                                                        >
                                                            <div className='row'>
                                                                <Observer>{() =>
                                                                    <div className='col-12'>
                                                                        <label><small>{dow}</small></label>
                                                                        <div className='d-flex flex-row'>
                                                                            <ul
                                                                                className='list-inline no-style m-0 mb-2'
                                                                            >
                                                                                <li className='list-inline-item m-0 time'>
                                                                                    <DateTimePicker
                                                                                        value={getStartTime(dow) && !getIsClosed(dow) ? getStartTime(dow).toDate() : null}
                                                                                        step={30}
                                                                                        date={false}
                                                                                        time={true}
                                                                                        min={moment().startOf('day').add(6, 'hours').toDate()}
                                                                                        max={(getEndTime(dow) ? getEndTime(dow).clone().add(-1, 'hours') : moment().startOf('day').add(22, 'hours')).toDate()}
                                                                                        disabled={getIsClosed(dow)}
                                                                                        onChange={value => { handleStartChange(value, dow) }}
                                                                                    />
                                                                                </li>
                                                                                <li className='list-inline-item m-0 mx-2'>to</li>
                                                                                <li className='list-inline-item m-0 time'>
                                                                                    <DateTimePicker
                                                                                        value={getEndTime(dow) && !getIsClosed(dow) ? getEndTime(dow).toDate() : null}
                                                                                        step={30}
                                                                                        date={false}
                                                                                        time={true}
                                                                                        min={getStartTime(dow) ? getStartTime(dow).clone().add(1, 'hours').toDate() : null}
                                                                                        max={moment().startOf('day').add(23, 'hours').toDate()}
                                                                                        disabled={getIsClosed(dow) || !getStartTime(dow)}
                                                                                        onChange={value => { handleEndChange(value, dow) }}
                                                                                    />
                                                                                </li>
                                                                            </ul>
                                                                            <div className='p-2'>
                                                                                <div className='custom-control custom-checkbox'>
                                                                                    <input
                                                                                        id={`business-hours-close-${dow.toLowerCase()}`}
                                                                                        type='checkbox'
                                                                                        name={`business-hours-close-${dow.toLowerCase()}`}
                                                                                        className='custom-control-input'
                                                                                        checked={getIsClosed(dow)}
                                                                                        onChange={e => { handleIsClosedChange(e, dow) }}
                                                                                    />
                                                                                    <label
                                                                                        htmlFor={`business-hours-close-${dow.toLowerCase()}`}
                                                                                        className='custom-control-label'
                                                                                    >
                                                                                        Closed
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }</Observer>
                                                            </div>
                                                        </section>
                                                    })
                                                }
                                            </div>
                                        </FadeIn>
                                    </> : renderQuickDrawerLoading()
                                }
                            </div>
                            <div className='quick-drawer-action pl-3'>
                                <div className='row'>
                                    <div className='col-4'>
                                        <button
                                            type='button'
                                            className='btn btn-icon'
                                            title='Special hours'
                                            // onClick={handleCloneProduct}
                                        >
                                            <i className='fal fa-calendar-exclamation'></i>
                                        </button>
                                    </div>
                                    <div className='col-8'>
                                        <div className='float-right'>
                                            <button
                                                type='button'
                                                className='btn btn-link btn-cancel'
                                                onClick={handleCancel}
                                            >Cancel</button>
                                            <button
                                                type='submit'
                                                className='btn btn-success ml-2'
                                            >Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </>
            }</Observer>
        </form>
    </>
}

export default NewBusinessHour;