import React, { useEffect, useContext, useState, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { GlobalHotKeys } from 'react-hotkeys';
import { useObserver } from 'mobx-react-lite';

import QuickDrawerHeader from '../../../../_shared/QuickDrawerHeader';
import { quickDrawerFocus } from '../../../../_shared/QuickDrawer';
import RichTextEditor from '../../../../_shared/RichTextEditor';

import PrescriptionInputUpdateStore from '../../../../../../stores/PrescriptionInputUpdateStore';
import QuickDrawerStore from '../../../../../../stores/QuickDrawerStore';
import CacheStore from '../../../../../../stores/CacheStore';

import * as ErrorMessages from '../../../../../../constants/errorMessages';
import * as fn from '../../../../../../utilities/_functions';
import * as oh from '../../../../../../utilities/operationHelper';
import * as tih from '../../../../../../utilities/templateInputHelper';

import './UpdatePrescriptionInput.scss';

function UpdatePrescriptionInput(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const prescriptionInput = useContext(PrescriptionInputUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const cache = useContext(CacheStore);
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [size, setSize] = useState('');
    const [align, setAlign] = useState('');
    const [valign, setValign] = useState('');
    const [options, setOptions] = useState([]);
    const [inline, setInline] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [imageWidth, setImageWidth] = useState(null);
    const [imageHeight, setImageHeight] = useState(null);
    const [defaultValue, setDefaultValue] = useState(null);
    const [dataType, setDataType] = useState(null);
    const [paddingType, setPaddingType] = useState(null);
    const [paddingCharacter, setPaddingCharacter] = useState(null);
    const [paddingLength, setPaddingLength] = useState(null);
    const [decimalPlaces, setDecimalPlaces] = useState(null);
    const [isDefaultNegative, setIsDefaultNegative] = useState(null);
    const [format, setFormat] = useState(null);
    const [validation, setValidation] = useState(null);
    const [showFieldInHistory, setShowFieldInHistory] = useState(false);
    const [rowInHistory, setRowInHistory] = useState('1');
    const [rowLabelInHistory, setRowLabelInHistory] = useState('');
    const [columnInHistory, setColumnInHistory] = useState('1');
    const [columnLabelInHistory, setColumnLabelInHistory] = useState('');
    const [isColumnStickyInHistory, setIsColumnStickyInHistory] = useState(false);

    useEffect(() => {
        focusTimer.current = setTimeout(() => {
            setSize(prescriptionInput.getMetadata('size'));
            setAlign(prescriptionInput.getMetadata('align'));
            setValign(prescriptionInput.getMetadata('valign'));
            setOptions(prescriptionInput.getMetadata('options'));
            setInline(prescriptionInput.getMetadata('inline'));
            setImageUrl(prescriptionInput.getMetadata('imageUrl'));
            setImageWidth(prescriptionInput.getMetadata('imageWidth'));
            setImageHeight(prescriptionInput.getMetadata('imageHeight'));
            setDefaultValue(prescriptionInput.getMetadata('defaultValue'));
            setDataType(prescriptionInput.getMetadata('dataType'));
            setPaddingType(prescriptionInput.getMetadata('paddingType'));
            setPaddingCharacter(prescriptionInput.getMetadata('paddingCharacter'));
            setPaddingLength(prescriptionInput.getMetadata('paddingLength'));
            setIsDefaultNegative(prescriptionInput.getMetadata('isDefaultNegative'));
            setDecimalPlaces(prescriptionInput.getMetadata('decimalPlaces'));
            setFormat(prescriptionInput.getMetadata('format'));
            setValidation(prescriptionInput.getMetadata('validation'));

            const prescriptionHistory = prescriptionInput.getMetadata('prescriptionHistory');

            if (prescriptionHistory) {
                setShowFieldInHistory((prescriptionHistory.show ? prescriptionHistory.show : false));
                setColumnInHistory((prescriptionHistory.column ? prescriptionHistory.column : '1'));
                setColumnLabelInHistory((prescriptionHistory.columnLabel ? prescriptionHistory.columnLabel : ''));
                setIsColumnStickyInHistory((prescriptionHistory.sticky ? prescriptionHistory.sticky : false));
                setRowInHistory((prescriptionHistory.row ? prescriptionHistory.row : '1'));
                setRowLabelInHistory((prescriptionHistory.rowLabel ? prescriptionHistory.rowLabel : ''));
            }

            quickDrawerFocus(props.drawer);
        }, 100)

        return (() => {
            isMounted.current = false;
            prescriptionInput.clear();
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        })
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (prescriptionInput.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = event => {
        const updated = prescriptionInput.hasUnsavedChanges;

        event.preventDefault();
        handlePopulateMetadata();

        prescriptionInput.save()
            .then(data => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, { updated: updated, data: { prescriptionInput: data, } });
                    }
                }
            })
    }

    const handleLabelChange = event => {
        prescriptionInput.data.label = event.target.value;
        prescriptionInput.hasUnsavedChanges = true;
    }

    const handleDescriptionChange = content => {
        const html = content;
        prescriptionInput.data.descriptionHtml = ((html === '<p><br></p>') ? null : html);
        prescriptionInput.hasUnsavedChanges = true;
    }

    const handleSizeChange = event => {
        setSize(event.target.value);
        prescriptionInput.hasUnsavedChanges = true;
    }

    const handleAlignChange = value => {
        setAlign((value ? value : 'left'));
        prescriptionInput.hasUnsavedChanges = true;
    }

    const handleValignChange = value => {
        setValign((value ? value : 'top'));
        prescriptionInput.hasUnsavedChanges = true;
    }

    const handleInlineChange = event => {
        setInline((event.target.value === 'true'));
        prescriptionInput.hasUnsavedChanges = true;
    }

    const handlePopulateMetadata = () => {
        populateMetadata(tih.TEMPLATE_INPUT_METADATA_SIZE, 'size', size);
        populateMetadata(tih.TEMPLATE_INPUT_METADATA_ALIGN, 'align', align);
        populateMetadata(tih.TEMPLATE_INPUT_METADATA_VALIGN, 'valign', valign);
        populateMetadata(tih.TEMPLATE_INPUT_METADATA_OPTIONS, 'options', options);
        populateMetadata(tih.TEMPLATE_INPUT_METADATA_INLINE, 'inline', inline);
        populateMetadata(tih.TEMPLATE_INPUT_METADATA_IMAGE_URL, 'imageUrl', imageUrl);
        populateMetadata(tih.TEMPLATE_INPUT_METADATA_IMAGE_URL, 'imageWidth', imageWidth);
        populateMetadata(tih.TEMPLATE_INPUT_METADATA_IMAGE_URL, 'imageHeight', imageHeight);
        populateMetadata(tih.TEMPLATE_INPUT_METADATA_DATA_TYPE, 'dataType', dataType);
        if (!!paddingType && !!paddingCharacter && !!paddingLength) {
            populateMetadata(tih.TEMPLATE_INPUT_METADATA_DATA_PADDING_TYPE, 'paddingType', paddingType);
            populateMetadata(tih.TEMPLATE_INPUT_METADATA_DATA_PADDING_CHARACTER, 'paddingCharacter', paddingCharacter);
            populateMetadata(tih.TEMPLATE_INPUT_METADATA_DATA_PADDING_LENGTH, 'paddingLength', paddingLength);
        }
        else {
            populateMetadata(tih.TEMPLATE_INPUT_METADATA_DATA_PADDING_TYPE, 'paddingType', null);
            populateMetadata(tih.TEMPLATE_INPUT_METADATA_DATA_PADDING_CHARACTER, 'paddingCharacter', null);
            populateMetadata(tih.TEMPLATE_INPUT_METADATA_DATA_PADDING_LENGTH, 'paddingLength', null);
        }
        populateMetadata(tih.TEMPLATE_INPUT_METADATA_DATA_DEFAULT_NEGATIVE, 'isDefaultNegative', isDefaultNegative);
        populateMetadata(tih.TEMPLATE_INPUT_METADATA_DATA_DECIMAL_PLACES, 'decimalPlaces', decimalPlaces);
        populateMetadata(tih.TEMPLATE_INPUT_METADATA_DATA_FORMAT, 'format', format);
        populateMetadata(tih.TEMPLATE_INPUT_METADATA_DATA_VALIDATION, 'validation', validation);

        prescriptionInput.setMetadata('prescriptionHistory', {
            show: showFieldInHistory,
            row: showFieldInHistory ? rowInHistory : null,
            rowLabel: showFieldInHistory ? rowLabelInHistory : null,
            column: showFieldInHistory ? columnInHistory : null,
            columnLabel: showFieldInHistory ? columnLabelInHistory : null,
            sticky: showFieldInHistory ? isColumnStickyInHistory : null,
        });
    }

    const handleImageAnnotationChange = event => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = handleFileReaderLoad;
        reader.readAsDataURL(file);
        prescriptionInput.hasUnsavedChanges = true;
    }

    const handleFileReaderLoad = (event) => {
        const reader = event.target;
        const base64 = reader.result;
        const tempImage = new Image();

        tempImage.src = event.target.result;
        tempImage.onload = () => {
            setImageWidth(tempImage.width);
            setImageHeight(tempImage.height);
        }
        setImageUrl(base64);
    }

    const handleImageAnnotationChangeClick = event => {
        setImageUrl(null);
        setImageWidth(null);
        setImageHeight(null);
        prescriptionInput.hasUnsavedChanges = true;
    }

    const handleDataTypeChange = event => {
        const value = event.target.value;

        switch (value) {
            case 'String':
                setIsDefaultNegative(null);
                setDecimalPlaces(null);
                setFormat(null);
                setValidation(null);
                break;

            case 'Integer':
                setIsDefaultNegative(false);
                setDecimalPlaces(null);
                setFormat(null);
                setValidation(null);
                clearPadding();
                break;

            case 'Decimal':
                setIsDefaultNegative(false);
                setDecimalPlaces(2);
                setFormat(null);
                setValidation(null);
                clearPadding();
                break;

            default:
                setIsDefaultNegative(null);
                setDecimalPlaces(null);
                setFormat(null);
                setValidation(null);
                clearPadding();
                break;
        }

        setDataType(value);
        prescriptionInput.hasUnsavedChanges = true;
    }

    const handlePaddingTypeChange = event => {
        const value = event.target.value;
        setPaddingType(value);
        prescriptionInput.hasUnsavedChanges = true;

        if (!value) {
            setPaddingCharacter(null);
            setPaddingLength(null);
        }
    }

    const handlePaddingCharacterChange = event => {
        const value = event.target.value;
        setPaddingCharacter(value);
        prescriptionInput.hasUnsavedChanges = true;

        if (!paddingLength) {
            if (value && value.length > 0) {
                setPaddingLength(2)
            }
        }
    }

    const handlePaddingBlur = event => {
        if (paddingCharacter && !paddingLength) {
            setPaddingLength(2)
        }
        if (!paddingCharacter && paddingLength) {
            setPaddingLength(null)
        }
    }

    const handlePaddingLengthChange = event => {
        const value = parseInt(event.target.value, 10);
        setPaddingLength(value);
        prescriptionInput.hasUnsavedChanges = true;
    }

    const handleDefaultNegativeChange = event => {
        const value = !isDefaultNegative;
        setIsDefaultNegative(value);
        prescriptionInput.hasUnsavedChanges = true;
    }

    const handleDecimalPlacesChange = event => {
        const value = parseInt(event.target.value, 10);
        setDecimalPlaces(value);
        prescriptionInput.hasUnsavedChanges = true;
    }

    const handleFormatChange = event => {
        const value = event.target.value;
        setFormat(value);
        prescriptionInput.hasUnsavedChanges = true;
    }

    const handleValidationChange = event => {
        const value = event.target.value;
        setValidation(value);
        prescriptionInput.hasUnsavedChanges = true;
    }

    const handleDefaultValueTextChange = event => {
        setDefaultValue(event.target.value);
        prescriptionInput.hasUnsavedChanges = true;
    }

    const handleDefaultValueRichTextChange = content => {
        const html = content;
        setDefaultValue(((html === '<p><br></p>') ? null : html));
        prescriptionInput.hasUnsavedChanges = true;
    }

    const handleDefaultValueCheckboxChange = event => {
        if (!defaultValue) {
            setDefaultValue([])
        }

        if (event.target.checked) {
            defaultValue.push(event.target.value);
        } else {
            const index = defaultValue.findIndex(v => v === event.target.value);
            if (index >= 0) {
                defaultValue.splice(index, 1);
            }
        }

        if (defaultValue.length === 0) {
            setDefaultValue(null);
        }

        setDefaultValue(defaultValue);
        prescriptionInput.hasUnsavedChanges = true;
    }

    const handleOptionChange = (event, index) => {
        const tempOptions = [...options];
        tempOptions[index] = event.target.value;
        setOptions(tempOptions);
        prescriptionInput.hasUnsavedChanges = true;
    }

    const handleAddOption = event => {
        event.preventDefault();
        setOptions(o => [...options, '']);
        focusTimer.current = setTimeout(() => {
            const element = document.querySelector(`#new-prescription-input-${prescriptionInput.data.key}-${(options.length)}`)
            if (element) { element.focus(); }
        }, 50)
        prescriptionInput.hasUnsavedChanges = true;
    }

    const handleRemoveOption = (option, index) => {
        setOptions(options.filter((o, oi) => oi !== index));
        prescriptionInput.hasUnsavedChanges = true;
    }

    const handleMoveOption = (from, to) => {
        const tempOptions = [...options];
        const element = tempOptions[from];

        tempOptions.splice(from, 1);
        tempOptions.splice(to, 0, element);

        setOptions(tempOptions);
        prescriptionInput.hasUnsavedChanges = true;
    }

    const handleShowFieldInHistoryChange = event => {
        setShowFieldInHistory(!showFieldInHistory);
        prescriptionInput.hasUnsavedChanges = true;
    }

    const handleColumnInHistoryChange = event => {
        setColumnInHistory(event.target.value);
        prescriptionInput.hasUnsavedChanges = true;
    }

    const handleColumnLabelInHistoryChange = event => {
        setColumnLabelInHistory(event.target.value);
        prescriptionInput.hasUnsavedChanges = true;
    }

    const handleIsColumnStickyHistoryChange = event => {
        setIsColumnStickyInHistory(!isColumnStickyInHistory);
        prescriptionInput.hasUnsavedChanges = true;
    }

    const handleRowInHistoryChange = event => {
        setRowInHistory(event.target.value);
        prescriptionInput.hasUnsavedChanges = true;
    }

    const handleRowLabelInHistoryChange = event => {
        setRowLabelInHistory(event.target.value);
        prescriptionInput.hasUnsavedChanges = true;
    }

    const clearPadding = () => {
        setPaddingType(null);
        setPaddingCharacter(null);
        setPaddingLength(null);
    }

    const renderOptions = () => {
        return <>
            {
                options && options.length > 0 ?
                    options.map((o, oi) => {
                        return <ul
                            key={`prescription_input_options_${oi}`}
                            className='list-inline no-style m-0 mb-2'
                        >
                            <li className='list-inline-item m-0 mr-1'>{oi + 1}.&nbsp;</li>
                            <li className='list-inline-item m-0 option'>
                                <input
                                    id={`update-prescription-input-${prescriptionInput.data.key}-${oi}`}
                                    type='text'
                                    className='form-control form-control-sm'
                                    autoComplete='off'
                                    value={o}
                                    onChange={(e) => handleOptionChange(e, oi)}
                                />
                            </li>
                            <li className='list-inline-item m-0 ml-2'>
                                <button
                                    type='button'
                                    className='btn btn-link btn-icon btn-sm'
                                    autoComplete='off'
                                    onClick={e => { handleRemoveOption(o, oi) }}
                                >
                                    <i className={'fal fa-minus-circle text-danger'}></i>
                                </button>
                            </li>
                            <li className='list-inline-item m-0'>
                                <button
                                    type='button'
                                    className='btn btn-link btn-icon btn-sm'
                                    autoComplete='off'
                                    disabled={options.length === 1 || oi === 0}
                                    onClick={e => { handleMoveOption(oi, (oi - 1)) }}
                                >
                                    <i className={'fal fa-arrow-up' + (options.length === 1 || oi === 0 ? ' text-gray-500' : ' text-primary')}></i>
                                </button>
                            </li>
                            <li className='list-inline-item m-0'>
                                <button
                                    type='button'
                                    className='btn btn-link btn-icon btn-sm'
                                    disabled={options.length === 1 || oi === (options.length - 1)}
                                    onClick={e => { handleMoveOption(oi, (oi + 1)) }}
                                >
                                    <i className={'fal fa-arrow-down' + (options.length === 1 || oi === (options.length - 1) ? ' text-gray-500' : ' text-primary')}></i>
                                </button>
                            </li>
                        </ul>
                    }) : <div></div>
            }
            <button
                type='button'
                className='btn btn-link btn-icon-left btn-sm'
                onClick={handleAddOption}
            >
                <i className='fal fa-plus-circle'></i> Add option
            </button>
        </>
    }

    const renderDefaultValueEditor = () => {
        switch (prescriptionInput.data.type) {
            case 'SingleLineText':
                return <input
                    id='update-prescription-input-default'
                    type='text'
                    maxLength={100}
                    className='form-control'
                    value={defaultValue ? defaultValue : ''}
                    autoComplete={'off'}
                    onChange={handleDefaultValueTextChange}
                />;

            case 'MultiLineText':
                return <textarea
                    id='update-prescription-input-default'
                    className='form-control'
                    value={defaultValue ? defaultValue : ''}
                    autoComplete={'off'}
                    onChange={handleDefaultValueTextChange}
                />;
                // return <RichTextEditor
                //     id='update-prescription-input-default'
                //     mode='none'
                //     disableTab={true}
                //     value={defaultValue ? defaultValue : ''}
                //     onChange={handleDefaultValueRichTextChange}
                // />;

            case 'RichText':
                return <RichTextEditor
                    id='update-prescription-input-default'
                    mode='full'
                    disableTab={true}
                    value={defaultValue ? defaultValue : ''}
                    onChange={handleDefaultValueRichTextChange}
                />;

            case 'Dropdown':
                return <select
                    id='update-prescription-input-default'
                    className='custom-select form-control'
                    value={defaultValue}
                    onChange={handleDefaultValueTextChange}
                >
                    <option value=''></option>
                    {
                        options && options.length > 0 ?
                            options.map((o, oi) => {
                                return <option key={`${prescriptionInput.key}_metadata_${oi}`} value={o}>{o}</option>
                            }) : null
                    }
                </select>;

            case 'Radio':
                return <div className='radio-wrapper'>
                    {
                        options && options.length > 0 ?
                            options.map((o, oi) => {
                                return <div
                                    key={`${prescriptionInput.key}_metadata_${oi}`}
                                    className={'custom-control custom-radio mb-2'}
                                >
                                    <input
                                        id={`update-prescription-input-default-${prescriptionInput.key}-${oi}`}
                                        type='radio'
                                        name='prescription-input-default'
                                        className='custom-control-input'
                                        value={o}
                                        checked={defaultValue === o}
                                        onChange={handleDefaultValueTextChange}
                                    />
                                    <label
                                        htmlFor={`update-prescription-input-inline-${prescriptionInput.key}-${oi}`}
                                        className='custom-control-label'
                                    >
                                        {o}
                                    </label>
                                </div>
                            }) : null
                    }
                </div>;

            case 'Checkbox':
                return <div className='checkbox-wrapper'>
                    {
                        options && options.length > 0 ?
                            options.map((o, oi) => {
                                return <div
                                    key={`${prescriptionInput.key}_metadata_${oi}`}
                                    className={'custom-control custom-checkbox mb-2'}
                                >
                                    <input
                                        id={`update-prescription-input-default-${prescriptionInput.key}-${oi}`}
                                        type='checkbox'
                                        name='prescription-input-default'
                                        className='custom-control-input'
                                        value={o}
                                        checked={defaultValue && defaultValue.split('|').some(v => v === o)}
                                        onChange={handleDefaultValueCheckboxChange}
                                    />
                                    <label
                                        htmlFor={`update-prescription-input-inline-${prescriptionInput.key}-${oi}`}
                                        className='custom-control-label'
                                    >
                                        {o}
                                    </label>
                                </div>
                            }) : null
                    }
                </div>;

            case 'Date':
            case 'Time':
            default:
                return null
        }
    }

    const populateMetadata = (field, key, value) => {
        if (tih.hasField(prescriptionInput.data.type, field)) {
            prescriptionInput.setMetadata(key, value);
        } else {
            prescriptionInput.removeMetadata(key);
        }
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form onSubmit={handleSubmit}>
            <fieldset disabled={prescriptionInput.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('prescription-input', 'default')}
                        action='Update'
                        category='Prescription Input'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        <FadeIn>
                            <div className='update-prescription-input body-content'>
                                <section>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='form-group mb-4'>
                                                <label className='required' htmlFor='update-prescription-input-type'><small>Type</small></label>
                                                <select
                                                    id='update-prescription-input-type'
                                                    className='custom-select form-control'
                                                    value={prescriptionInput.data.type ? prescriptionInput.data.type : ''}
                                                    disabled={true}
                                                >
                                                    <option value=''></option>
                                                    {
                                                        cache.getReferenceDataOptions('TemplateInputType').map((option, di) => {
                                                            return <option key={`prescription_input_type_${di}`} value={option.key}>{option.value}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='form-group mb-4'>
                                                <label className='required' htmlFor='update-prescription-input-name'><small>Name</small></label>
                                                <input
                                                    id='update-prescription-input-name'
                                                    type='text'
                                                    maxLength={100}
                                                    className='form-control'
                                                    autoComplete={'off'}
                                                    disabled={true}
                                                    value={prescriptionInput.data.name ? prescriptionInput.data.name : ''}
                                                />
                                                <small className='text-gray-700'><strong>Important:</strong> Name must be unique across all inputs.</small>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        tih.hasField(prescriptionInput.data.type, tih.TEMPLATE_INPUT_LABEL) ?
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label htmlFor='update-prescription-input-label'><small>Label</small></label>
                                                        <input
                                                            id='update-prescription-input-label'
                                                            type='text'
                                                            maxLength={100}
                                                            className='form-control'
                                                            value={prescriptionInput.data.label ? prescriptionInput.data.label : ''}
                                                            autoComplete={'off'}
                                                            onChange={handleLabelChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        tih.hasField(prescriptionInput.data.type, tih.TEMPLATE_INPUT_METADATA_SIZE) ?
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label className='required' htmlFor='update-prescription-input-size'><small>Size</small></label>
                                                        <select
                                                            id='update-prescription-input-size'
                                                            className='custom-select form-control'
                                                            value={size ? size : ''}
                                                            onChange={handleSizeChange}
                                                        >
                                                            <option value='sm'>Small</option>
                                                            <option value=''>Standard</option>
                                                            <option value='lg'>Large</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        tih.hasField(prescriptionInput.data.type, tih.TEMPLATE_INPUT_METADATA_ALIGN) ?
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label className='required' htmlFor='update-prescription-input-align'><small>Align</small></label>
                                                        <div>
                                                            <div className='btn-group'>
                                                                <button
                                                                    type='button'
                                                                    className={'btn btn-primary' + (align === 'left' ? ' active' : '')}
                                                                    value={(align ? align : '')}
                                                                    onClick={() => { handleAlignChange('left') }}
                                                                >
                                                                    <i className='fal fa-align-left'></i>
                                                                </button>
                                                                <button
                                                                    type='button'
                                                                    className={'btn btn-primary' + (align === 'center' ? ' active' : '')}
                                                                    value={(align ? align : '')}
                                                                    onClick={() => { handleAlignChange('center') }}
                                                                >
                                                                    <i className='fal fa-align-center'></i>
                                                                </button>
                                                                <button
                                                                    type='button'
                                                                    className={'btn btn-primary' + (align === 'right' ? ' active' : '')}
                                                                    value={(align ? align : '')}
                                                                    onClick={() => { handleAlignChange('right') }}
                                                                >
                                                                    <i className='fal fa-align-right'></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        tih.hasField(prescriptionInput.data.type, tih.TEMPLATE_INPUT_METADATA_VALIGN) ?
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label className='required' htmlFor='update-prescription-input-valign'><small>Vertical Align</small></label>
                                                        <div>
                                                            <div className='btn-group'>
                                                                <button
                                                                    type='button'
                                                                    className={'btn btn-primary' + (valign === 'top' ? ' active' : '')}
                                                                    value={(valign ? valign : '')}
                                                                    onClick={() => { handleValignChange('top') }}
                                                                >
                                                                    <i className='fal fa-arrow-to-top'></i>
                                                                </button>
                                                                <button
                                                                    type='button'
                                                                    className={'btn btn-primary' + (valign === 'middle' ? ' active' : '')}
                                                                    value={(valign ? valign : '')}
                                                                    onClick={() => { handleValignChange('middle') }}
                                                                >
                                                                    <i className='fal fa-horizontal-rule'></i>
                                                                </button>
                                                                <button
                                                                    type='button'
                                                                    className={'btn btn-primary' + (valign === 'bottom' ? ' active' : '')}
                                                                    value={(valign ? valign : '')}
                                                                    onClick={() => { handleValignChange('bottom') }}
                                                                >
                                                                    <i className='fal fa-arrow-to-bottom'></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        tih.hasField(prescriptionInput.data.type, tih.TEMPLATE_INPUT_METADATA_INLINE) ?
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label><small>Inline</small></label>
                                                        <div>
                                                            <div className='custom-control custom-radio custom-control-inline'>
                                                                <input
                                                                    id={`update-prescription-input-inline-true`}
                                                                    type='radio'
                                                                    name='prescription-input-inline'
                                                                    className='custom-control-input'
                                                                    value={true}
                                                                    checked={inline}
                                                                    onChange={handleInlineChange}
                                                                />
                                                                <label
                                                                    htmlFor={`update-prescription-input-inline-true`}
                                                                    className='custom-control-label'
                                                                >
                                                                    Yes
                                                                </label>
                                                            </div>
                                                            <div className='custom-control custom-radio custom-control-inline'>
                                                                <input
                                                                    id={`update-prescription-input-inline-false`}
                                                                    type='radio'
                                                                    name='prescription-input-inline'
                                                                    className='custom-control-input'
                                                                    value={false}
                                                                    checked={!inline}
                                                                    onChange={handleInlineChange}
                                                                />
                                                                <label
                                                                    htmlFor={`update-prescription-input-inline-false`}
                                                                    className='custom-control-label'
                                                                >
                                                                    No
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        tih.hasField(prescriptionInput.data.type, tih.TEMPLATE_INPUT_METADATA_IMAGE_URL) ?
                                            <div className='row options'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label className='required' htmlFor='update-prescription-input-options'><small>Image</small></label>
                                                        {
                                                            imageUrl ?
                                                                <div>
                                                                    <img src={imageUrl} width={imageWidth} height={imageHeight} alt='Annotate' />
                                                                    <button
                                                                        type='button'
                                                                        className='btn btn-link px-0 btn-sm d-block'
                                                                        onClick={handleImageAnnotationChangeClick}
                                                                    >Change image</button>
                                                                </div> :
                                                                <div className='custom-file'>
                                                                    <input
                                                                        id='update-prescription-input-image-url'
                                                                        type='file'
                                                                        className='custom-file-input'
                                                                        accept='image/*'
                                                                        onChange={handleImageAnnotationChange} />
                                                                    <label
                                                                        htmlFor='update-prescription-input-image-url'
                                                                        className='custom-file-label'>
                                                                    </label>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        tih.hasField(prescriptionInput.data.type, tih.TEMPLATE_INPUT_METADATA_OPTIONS) ?
                                            <div className='row options'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label className='required' htmlFor='update-prescription-input-options'><small>Options</small></label>
                                                        {renderOptions()}
                                                    </div>
                                                </div>
                                            </div> : null
                                    }

                                    <div className='mt-4 pt-4 border-top'>
                                        <div className='row mb-2'>
                                            <div className='col-12'>
                                                <label><small>Prescription History Table</small></label>
                                            </div>
                                        </div>
                                        <div className='row mb-4'>
                                            <div className='col-12'>
                                                <div className='checkbox-wrapper'>
                                                    <div
                                                        className={'custom-control custom-checkbox mb-2'}
                                                    >
                                                        <input
                                                            id='update-prescription-show-field'
                                                            type='checkbox'
                                                            name='prescription-input-default'
                                                            className='custom-control-input'
                                                            checked={showFieldInHistory}
                                                            onChange={handleShowFieldInHistoryChange}
                                                        />
                                                        <label
                                                            htmlFor='update-prescription-show-field'
                                                            className='custom-control-label'
                                                        >
                                                            Show field
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            showFieldInHistory ?
                                                <FadeIn>
                                                    <>
                                                        <div className='row'>
                                                            <div className='col-3'>
                                                                <div className='form-group mb-4'>
                                                                    <label htmlFor='update-prescription-column'><small>Column</small></label>
                                                                    <select
                                                                        id='update-prescription-column'
                                                                        className='custom-select form-control'
                                                                        value={columnInHistory}
                                                                        onChange={handleColumnInHistoryChange}
                                                                    >
                                                                        {
                                                                            props.extraProps && props.extraProps.inputs ?
                                                                                props.extraProps.inputs.map((i, ii) => {
                                                                                    return <option key={`update-prescription-column-${ii}`} value={(ii + 1)}>{(ii + 1)}</option>
                                                                                }) : null
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className='col-6'>
                                                                <div className='form-group mb-4'>
                                                                    <label htmlFor='update-prescription-column-label'><small>Label</small></label>
                                                                    <input
                                                                        id='update-prescription-column-label'
                                                                        type='text'
                                                                        maxLength={100}
                                                                        className='form-control'
                                                                        autoComplete='off'
                                                                        disabled={rowInHistory !== '1'}
                                                                        value={columnLabelInHistory ? columnLabelInHistory : ''}
                                                                        onChange={handleColumnLabelInHistoryChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-3'>
                                                                <div className='form-group mb-4 p-1'>
                                                                    <div className='custom-control custom-checkbox mt-5'>
                                                                        <input
                                                                            id='update-prescription-column-sticky'
                                                                            type='checkbox'
                                                                            name='prescription-column-sticky'
                                                                            className='custom-control-input'
                                                                            checked={isColumnStickyInHistory ? isColumnStickyInHistory : false}
                                                                            disabled={rowInHistory !== '1'}
                                                                            onChange={handleIsColumnStickyHistoryChange}
                                                                        />
                                                                        <label
                                                                            htmlFor='update-prescription-column-sticky'
                                                                            className='custom-control-label'
                                                                        >
                                                                            Sticky
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-3'>
                                                                <div className='form-group mb-4'>
                                                                    <label htmlFor='update-prescription-row'><small>Row</small></label>
                                                                    <select
                                                                        id='update-prescription-row'
                                                                        className='custom-select form-control'
                                                                        value={rowInHistory}
                                                                        onChange={handleRowInHistoryChange}
                                                                    >
                                                                        {
                                                                            props.extraProps && props.extraProps.inputs ?
                                                                                props.extraProps.inputs.map((i, ii) => {
                                                                                    return <option key={`update-prescription-row-${ii}`} value={(ii + 1)}>{(ii + 1)}</option>
                                                                                }) : null
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className='col-6'>
                                                                <div className='form-group mb-4'>
                                                                    <label htmlFor='update-prescription-row-label'><small>Label</small></label>
                                                                    <input
                                                                        id='update-prescription-row-label'
                                                                        type='text'
                                                                        maxLength={100}
                                                                        className='form-control'
                                                                        autoComplete='off'
                                                                        disabled={columnInHistory !== '1' || rowInHistory !== '1'}
                                                                        value={rowLabelInHistory ? rowLabelInHistory : ''}
                                                                        onChange={handleRowLabelInHistoryChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                </FadeIn> : null
                                        }
                                    </div>
                                    {
                                        showAdvanced ?
                                            <>
                                                <FadeIn>
                                                    {
                                                        tih.hasField(prescriptionInput.data.type, tih.TEMPLATE_INPUT_METADATA_DATA_TYPE) ?
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='form-group mb-4'>
                                                                        <label htmlFor='update-prescription-input-data-type'><small>Data Type</small></label>
                                                                        <select
                                                                            id='update-prescription-input-data-type'
                                                                            className='custom-select form-control'
                                                                            value={dataType ? dataType : ''}
                                                                            onChange={handleDataTypeChange}
                                                                        >
                                                                            <option value=''>(Not set)</option>
                                                                            {
                                                                                tih.getDataTypes().map((dt, dti) => {
                                                                                    return <option
                                                                                        key={`update-prescription-input-data-type_${dti}`}
                                                                                        value={dt}
                                                                                    >{dt}</option>
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div> : null
                                                    }
                                                    {
                                                        tih.hasField(prescriptionInput.data.type, tih.TEMPLATE_INPUT_METADATA_DATA_PADDING_TYPE) && tih.checkDataTypeOption(dataType, tih.TEMPLATE_INPUT_METADATA_DATA_PADDING_TYPE) ?
                                                            <div className='row'>
                                                                <div className='col-4'>
                                                                    <div className='form-group mb-4'>
                                                                        <label htmlFor='update-prescription-input-padding-type'><small>Data Padding</small></label>
                                                                        <select
                                                                            id='update-prescription-input-padding-type'
                                                                            className='custom-select form-control'
                                                                            value={paddingType ? paddingType : ''}
                                                                            onChange={handlePaddingTypeChange}
                                                                        >
                                                                            <option value=''>(None)</option>
                                                                            <option value='left'>Left</option>
                                                                            <option value='right'>Right</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className='col-4'>
                                                                    <div className='form-group mb-4'>
                                                                        <label htmlFor='update-prescription-input-padding-char'><small>Character</small></label>
                                                                        <input
                                                                            id='update-prescription-input-padding-char'
                                                                            type='text'
                                                                            maxLength={1}
                                                                            className='form-control'
                                                                            autoComplete='off'
                                                                            value={paddingCharacter ? paddingCharacter : ''}
                                                                            disabled={!paddingType}
                                                                            onChange={handlePaddingCharacterChange}
                                                                            onBlur={handlePaddingBlur}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='col-4'>
                                                                    <div className='form-group mb-4'>
                                                                        <label htmlFor='update-prescription-input-padding-length'><small>Length</small></label>
                                                                        <input
                                                                            id='update-prescription-input-padding-length'
                                                                            type='number'
                                                                            min={2}
                                                                            max={10}
                                                                            step={1}
                                                                            className='form-control'
                                                                            autoComplete='off'
                                                                            disabled={!paddingCharacter || paddingCharacter.length === 0}
                                                                            value={paddingLength ? paddingLength : ''}
                                                                            onChange={handlePaddingLengthChange}
                                                                            onBlur={handlePaddingBlur}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div> : null
                                                    }
                                                    {
                                                        tih.hasField(prescriptionInput.data.type, tih.TEMPLATE_INPUT_METADATA_DATA_DEFAULT_NEGATIVE) && tih.checkDataTypeOption(dataType, tih.TEMPLATE_INPUT_METADATA_DATA_DEFAULT_NEGATIVE) ?
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='form-group mb-4'>
                                                                        <div className='custom-control custom-switch'>
                                                                            <input
                                                                                id='update-prescription-input-default-negative'
                                                                                type='checkbox'
                                                                                name='update-prescription-input-default-negative'
                                                                                className='custom-control-input'
                                                                                checked={isDefaultNegative}
                                                                                onChange={handleDefaultNegativeChange}
                                                                            />
                                                                            <label
                                                                                htmlFor='update-prescription-input-default-negative'
                                                                                className='custom-control-label'
                                                                            >
                                                                                Default as negative number
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> : null
                                                    }
                                                    {
                                                        tih.hasField(prescriptionInput.data.type, tih.TEMPLATE_INPUT_METADATA_DATA_DECIMAL_PLACES) && tih.checkDataTypeOption(dataType, tih.TEMPLATE_INPUT_METADATA_DATA_DECIMAL_PLACES) ?
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='form-group mb-4'>
                                                                        <label htmlFor='update-prescription-input-decimal-places'><small>Decimal Places</small></label>
                                                                        <input
                                                                            id='update-prescription-input-decimal-places'
                                                                            type='number'
                                                                            min={1}
                                                                            max={5}
                                                                            step={1}
                                                                            className='form-control'
                                                                            autoComplete='off'
                                                                            value={decimalPlaces ? decimalPlaces : ''}
                                                                            onChange={handleDecimalPlacesChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div> : null
                                                    }
                                                    {
                                                        tih.hasField(prescriptionInput.data.type, tih.TEMPLATE_INPUT_METADATA_DATA_FORMAT) && tih.checkDataTypeOption(dataType, tih.TEMPLATE_INPUT_METADATA_DATA_FORMAT) ?
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='form-group mb-4'>
                                                                        <label htmlFor='update-prescription-input-format'><small>Format</small></label>
                                                                        {
                                                                            tih.getFormatOptions(dataType, decimalPlaces).map((o, oi) => {
                                                                                return <div
                                                                                    key={`update-prescription-input-format-${oi}`}
                                                                                    className='custom-control custom-radio mb-2'
                                                                                >
                                                                                    <input
                                                                                        id={`update-prescription-input-format-${oi}`}
                                                                                        type='radio'
                                                                                        name='updatePrescriptionInputFormat'
                                                                                        value={o.type}
                                                                                        className='custom-control-input'
                                                                                        checked={format === o.type}
                                                                                        onChange={handleFormatChange}
                                                                                    />
                                                                                    <label
                                                                                        htmlFor={`update-prescription-input-format-${oi}`}
                                                                                        className='custom-control-label'
                                                                                    >
                                                                                        {o.example}
                                                                                    </label>
                                                                                </div>
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div> : null
                                                    }
                                                    {
                                                        tih.hasField(prescriptionInput.data.type, tih.TEMPLATE_INPUT_METADATA_DATA_VALIDATION) ?
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='form-group mb-4'>
                                                                        <label htmlFor='update-prescription-input-validation-regex'><small>Validation <span className='tt-normal fw-400'>(Regular Expression)</span></small></label>
                                                                        <input
                                                                            id='update-prescription-input-validation-regex'
                                                                            type='text'
                                                                            maxLength={255}
                                                                            className='form-control'
                                                                            autoComplete='off'
                                                                            value={validation ? validation : ''}
                                                                            onChange={handleValidationChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div> : null
                                                    }
                                                    {
                                                        tih.hasField(prescriptionInput.data.type, tih.TEMPLATE_INPUT_METADATA_DEFAULT_VALUE) ?
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='form-group mb-4'>
                                                                        <label htmlFor='update-prescription-input-default'><small>Default Value</small></label>
                                                                        {renderDefaultValueEditor()}
                                                                    </div>
                                                                </div>
                                                            </div> : null
                                                    }
                                                    {
                                                        tih.hasField(prescriptionInput.data.type, tih.TEMPLATE_INPUT_DESCRIPTION) ?
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='form-group mb-4'>
                                                                        <label htmlFor='update-prescription-input-description'><small>Description</small></label>
                                                                        <RichTextEditor
                                                                            mode='none'
                                                                            disableTab={true}
                                                                            value={prescriptionInput.data.descriptionHtml ? prescriptionInput.data.descriptionHtml : ''}
                                                                            onChange={handleDescriptionChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div> : null
                                                    }
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-4'>
                                                                <label className='required' htmlFor='update-prescription-input-key'><small>Key</small></label>
                                                                <input
                                                                    id='update-prescription-input-key'
                                                                    type='text'
                                                                    maxLength={100}
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    disabled={true}
                                                                    value={prescriptionInput.data.key ? prescriptionInput.data.key : ''}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </FadeIn>
                                            </> :
                                            <div className='mt-4 pt-4 border-top'>
                                                <button type='button' className='btn btn-link btn-sm p-0' onClick={() => { setShowAdvanced(true) }}>&raquo;	Show advanced options</button>
                                            </div>
                                    }
                                </section>
                            </div>
                        </FadeIn>
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>)
}

export default UpdatePrescriptionInput;