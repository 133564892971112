import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Observer } from 'mobx-react-lite';
import FadeIn from 'react-fade-in';
import { toast } from 'react-toastify';
import momentLocalizer from 'react-widgets-moment';
import { GlobalHotKeys } from 'react-hotkeys';
import moment from 'moment';

import BodyEnd from '../../_shared/BodyEnd';
import ConfirmModal from '../../_shared/ConfirmModal';
import CustomerMergeModal from '../CustomerMergeModal/_index';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import HtmlEditor from '../../_shared/HtmlEditor';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';

import CustomerUpdateStore from '../../../../stores/CustomerUpdateStore';
import CustomerMergeStore from '../../../../stores/CustomerMergeStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as rts from '../../../../constants/routes';
import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as ch from '../../../../utilities/customerHelper';
import * as ah from '../../../../utilities/addressHelper';
import * as ph from '../../../../utilities/personHelper';
import * as sys from '../../../../utilities/systemHelper';
import * as oh from '../../../../utilities/operationHelper';

import './DeactivateCustomer.scss';

moment.locale('en');
momentLocalizer();

function DeactivateCustomer(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const customerMergeModalRef = useRef(null);
    const customer = useContext(CustomerUpdateStore);
    const customerMerge = useContext(CustomerMergeStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [confirmDeactivate, setConfirmDeactivate] = useState(false);

    useEffect(() => {
        quickDrawerFocus(props.drawer);

        return () => {
            isMounted.current = false;
            customerMerge.clear();
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (customer.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleReasonChange = event => {
        customer.deactivateReason = event.target.value;
    }

    const handleAdditionalNoteChange = (e) => {
        const value = e.value;
        const noteHtml = value === '<p></p>' || value === '<p><br></p>' ? null : value;
        const note = !!noteHtml ? fn.stripHtml(noteHtml).substring(0, 100) : null;

        customer.deactivateNotePreview = note;
        customer.deactivateNoteBodyHtml = noteHtml;
    }

    const handleSubmit = event => {
        event.preventDefault();
        if (isMounted.current) {
            if (fn.validateForm(validateRef.current)) {
                setConfirmDeactivate(true);
            }
        }
    }

    const handleConfirmDeactivate = event => {
        event.preventDefault();
        customer.deactivate()
            .then(data => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, { updated: true, data: data });
                    }
                }
                quickDrawer.deactivateAll();
            })
            .catch(error => {
                const msg = <p><strong>Cannot deactivate customer profile.</strong>{error && error.response && error.response.data ? <>&nbsp;{error.response.data}</> : null}</p>;
                toast.error(() => msg, { position: 'top-center', style: { width: '380px' } });
            })

        setConfirmDeactivate(false);
    }

    const handleConfirmDeactivateCancel = event => {
        event.preventDefault();
        setConfirmDeactivate(false);

        if (props.onCancel && fn.isFunction(props.onCancel)) {
            props.onCancel(event);
        }
    }

    const handleCustomerMergeStart = (event) => {
        const id1 = customer.data.id;
        const id2 = customer.data.duplicateCustomerIds && customer.data.duplicateCustomerIds.length > 0 ? customer.data.duplicateCustomerIds[0] : null;

        if (!!id1 && !!id2) {
            customerMerge.initialize(id1, id2);
            customerMergeModalRef.current.show();
        }

        return;
    }

    const handleCustomerMergeSuccess = (event, result) => {
        if (result && result.updated) {
            toast.dark(() => <p data-merged-succ>Customers merged successfully.</p>);
        }

        customerMergeModalRef.current.close();
        quickDrawer.deactivateAll();
    }

    const handleCustomerMergeClose = () => {
        customerMerge.clear();
    }

    const renderCustomer = () => {
        return <div
            className='profile-wrapper'
        >
            <div className='profile'>
                <span
                    className={`profile-image profile-initials rounded-circle d-flex text-white ${ch.getProfileColor(customer.data)} fw-500`}
                    title={customer.data.fullName}
                >
                    {customer.data.initials}
                </span>
            </div>
            <div className='description'>
                <Link
                    to={`${rts.Customers.Home}/${customer.data.id}`}
                    className='name text-gray-700 text-info-hover'
                    onClick={quickDrawer.deactivateAll}>{ph.getFullName(customer.data, true)}
                    {
                        customer.data.dateOfBirth || customer.data.sex || customer.data.gender || customer.data.pronoun ?
                            <small className='text-nowrap ml-2'>({`${ph.getAge(customer.data.dateOfBirth)} ${ph.getSexGenderPronounDisplay(customer.data)}`.trim()})</small> : null
                    }
                </Link>
                {
                    customer.data.address && customer.data.address.country ?
                        <div className='info'>{ah.getAddressHtml(customer.data.address)}</div> : null
                }
                {
                    customer.data.emailAddress ?
                        <div className='info'>
                            <a
                                href={`mailto:${customer.data.emailAddress}`}
                            >{customer.data.emailAddress}
                            </a>
                        </div> : null
                }
                {
                    customer.data.phoneNumber ?
                        <div className='info'>
                            <a
                                href={`tel:${customer.data.phoneNumber}`}
                            >{sys.getFormattedPhoneNumber(customer.data.phoneNumber)}
                            </a>
                        </div> : null
                }
                {
                    customer.data.primaryContactPerson ?
                        <div className='mt-2 border-left border-3 border-gray-300 pl-3 py-1'>
                            <small className='text-primary-400 fs-75 text-uppercase d-block'>Primary Contact</small>
                            {
                                customer.data.primaryContactPerson.id ?
                                    <Link
                                        to={`${rts.Customers.Home}/${customer.data.primaryContactPerson.id}`}
                                        className='d-block fs-lg text-gray-700 text-info-hover'
                                        onClick={quickDrawer.deactivateAll}
                                    >
                                        {ph.getPreferredFirstLastName(customer.data.primaryContactPerson)}
                                        {
                                            customer.data.primaryContactPerson.relationship ?
                                                <small className='ml-1'>(<span className='fw-500 fs-90 text-success-700'>{customer.data.primaryContactPerson.relationship}</span>)</small> : null
                                        }
                                    </Link> :
                                    <div className='fs-lg text-gray-700 '>{ph.getPreferredFirstLastName(customer.data.primaryContactPerson)}
                                        {
                                            customer.data.primaryContactPerson.relationship ?
                                                <small className='ml-1'>(<span className='fw-500 fs-90 text-success-700'>{customer.data.primaryContactPerson.relationship}</span>)</small> : null
                                        }
                                    </div>
                            }
                            {
                                customer.data.primaryContactPerson.emailAddress ?
                                    <div className='info fs-95'>
                                        <a
                                            href={`mailto:${customer.data.primaryContactPerson.emailAddress}`}
                                        >{customer.data.primaryContactPerson.emailAddress}
                                        </a>
                                    </div> : null
                            }
                            {
                                customer.data.primaryContactPerson.phoneNumber ?
                                    <div className='info fs-95'>
                                        <a
                                            href={`tel:${customer.data.primaryContactPerson.phoneNumber}`}
                                        >{sys.getFormattedPhoneNumber(customer.data.primaryContactPerson.phoneNumber)}
                                        </a>
                                    </div> : null
                            }
                        </div> : null
                }
            </div>
        </div>
    }

    return <>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <Observer>{() =>
                <>
                    {
                        (props.drawer === quickDrawer.drawerOpened) && !confirmDeactivate ?
                            <GlobalHotKeys
                                keyMap={{
                                    close: ['esc'],
                                }}
                                handlers={{
                                    close: event => {
                                        handleCancel(event)
                                    },
                                }}
                                allowChanges={true}
                            /> : null
                    }
                    <fieldset disabled={customer.isSaving}>
                        <div className='quick-drawer'>
                            <QuickDrawerHeader
                                drawer={props.drawer}
                                icon={oh.getIcon('customer', 'deactivate')}
                                action='Deactivate'
                                category='Customer'
                                className='delete'
                                onCancel={handleCancel}
                            />
                            <div className='quick-drawer-body'>
                                {
                                    customer.isReady ?
                                        <FadeIn>
                                            <div className='deactivate-customer body-content'>
                                                <section className='customer'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-0'>
                                                                <label><small>Customer</small></label>
                                                                {renderCustomer()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <Observer>{() =>
                                                                <div className={'form-group mb-0'}>
                                                                    <label><small>Reason</small></label>
                                                                    <div className='custom-control custom-radio mb-2'>
                                                                        <input
                                                                            id='asked_remove'
                                                                            type='radio'
                                                                            name='deactivateReason'
                                                                            value='Removed'
                                                                            className='custom-control-input'
                                                                            checked={customer.deactivateReason === 'Removed'}
                                                                            onChange={handleReasonChange}
                                                                        />
                                                                        <label
                                                                            htmlFor='asked_remove'
                                                                            className='custom-control-label'
                                                                        >
                                                                            {ch.getDeactivatedReason('Removed')}
                                                                        </label>
                                                                    </div>
                                                                    <div className='custom-control custom-radio mb-2'>
                                                                        <input
                                                                            id='moved_away'
                                                                            type='radio'
                                                                            name='deactivateReason'
                                                                            value='Moved'
                                                                            className='custom-control-input'
                                                                            checked={customer.deactivateReason === 'Moved'}
                                                                            onChange={handleReasonChange}
                                                                        />
                                                                        <label
                                                                            htmlFor='moved_away'
                                                                            className='custom-control-label'
                                                                        >
                                                                            {ch.getDeactivatedReason('Moved')}
                                                                        </label>
                                                                    </div>
                                                                    <div className='custom-control custom-radio mb-2'>
                                                                        <input
                                                                            id='transferred'
                                                                            type='radio'
                                                                            name='deactivateReason'
                                                                            value='Transferred'
                                                                            className='custom-control-input'
                                                                            checked={customer.deactivateReason === 'Transferred'}
                                                                            onChange={handleReasonChange}
                                                                        />
                                                                        <label
                                                                            htmlFor='transferred'
                                                                            className='custom-control-label'
                                                                        >
                                                                            {ch.getDeactivatedReason('Transferred')}
                                                                        </label>
                                                                    </div>
                                                                    <div className='custom-control custom-radio mb-2'>
                                                                        <input
                                                                            id='passed_away'
                                                                            type='radio'
                                                                            name='deactivateReason'
                                                                            value='Deceased'
                                                                            className='custom-control-input'
                                                                            checked={customer.deactivateReason === 'Deceased'}
                                                                            onChange={handleReasonChange}
                                                                        />
                                                                        <label
                                                                            htmlFor='passed_away'
                                                                            className='custom-control-label'
                                                                        >
                                                                            {ch.getDeactivatedReason('Deceased')}
                                                                        </label>
                                                                    </div>
                                                                    <div className='custom-control custom-radio mb-2'>
                                                                        <input
                                                                            id='inactive'
                                                                            type='radio'
                                                                            name='deactivateReason'
                                                                            value='Inactive'
                                                                            className='custom-control-input'
                                                                            checked={customer.deactivateReason === 'Inactive'}
                                                                            onChange={handleReasonChange}
                                                                        />
                                                                        <label
                                                                            htmlFor='inactive'
                                                                            className='custom-control-label'
                                                                        >
                                                                            {ch.getDeactivatedReason('Inactive')}
                                                                        </label>
                                                                    </div>
                                                                    {
                                                                        customer.data.duplicateCustomerIds && customer.data.duplicateCustomerIds.length > 0 ?
                                                                            <div className='custom-control custom-radio mb-2'>
                                                                                <input
                                                                                    id='duplicate'
                                                                                    type='radio'
                                                                                    name='deactivateReason'
                                                                                    value='Duplicate'
                                                                                    className='custom-control-input'
                                                                                    checked={customer.deactivateReason === 'Duplicate'}
                                                                                    onChange={handleReasonChange}
                                                                                />
                                                                                <label
                                                                                    htmlFor='duplicate'
                                                                                    className='custom-control-label'
                                                                                >
                                                                                    {ch.getDeactivatedReason('Duplicate')}
                                                                                </label>
                                                                            </div> : null
                                                                    }
                                                                </div>
                                                            }</Observer>
                                                        </div>
                                                    </div>
                                                </section>
                                                {
                                                    customer.deactivateReason !== 'Duplicate' ?
                                                        <section>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className={'form-group'}>
                                                                        <label><small>Additional note</small></label>
                                                                        <div className='input-group'>
                                                                            <Observer>{() =>
                                                                                <HtmlEditor
                                                                                    disableTab={true}
                                                                                    onValueChanged={handleAdditionalNoteChange}
                                                                                />
                                                                            }</Observer>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section> : null
                                                }
                                            </div>
                                        </FadeIn> : renderQuickDrawerLoading()
                                }
                            </div>
                            <Observer>{() =>
                                customer.isReady ?
                                    <div className='quick-drawer-action'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='float-right'>
                                                    <button
                                                        type='button'
                                                        className='btn btn-link btn-cancel mr-2'
                                                        onClick={props.onCancel}
                                                    >Cancel</button>
                                                    {
                                                        customer.deactivateReason && customer.deactivateReason.toLowerCase() === 'duplicate' ?
                                                            <Observer>{() =>
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-danger'
                                                                    onClick={handleCustomerMergeStart}
                                                                >Merge</button>
                                                            }</Observer> :
                                                            <Observer>{() =>
                                                                <button
                                                                    type='submit'
                                                                    className='btn btn-danger'
                                                                >Deactivate</button>
                                                            }</Observer>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div> : null
                            }</Observer>
                        </div>
                    </fieldset>
                </>
            }</Observer>
        </form>
        <BodyEnd>
            <ConfirmModal
                icon={<i className='fal fa-user-times text-danger mr-2'></i>}
                message={<>Continue to <span className='fw-500'>&nbsp;deactivate {customer.data ? ph.getPreferredFirstName(customer.data) : ''}'s profile</span>? </>}
                show={confirmDeactivate}
                onOption1Click={handleConfirmDeactivate}
                onCancel={handleConfirmDeactivateCancel}
            />
            <Observer>{() =>
                <CustomerMergeModal ref={customerMergeModalRef} onSuccess={handleCustomerMergeSuccess} onclose={handleCustomerMergeClose} />
            }</Observer>
        </BodyEnd>
    </>
}

export default DeactivateCustomer;