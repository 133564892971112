import { createContext } from 'react';
import { decorate, observable, action, toJS } from 'mobx';

import api from '../api';

export class CustomerStoreCreditTransfer {
    id = null;
    customer = null;
    relationships = null;
    data = {
        toCustomerId: null,
        amount: null
    };
    isLoading = false;
    isSaving = false;
    isReady = false;
    hasUnsavedChanges = false;

    cancelCustomer = null;
    cancelRelationship = null;
    cancelSave = null;

    initialize = (id) => {
        const that = this;

        that.clear();
        that.id = id;
        that.isLoading = true;

        return new Promise((resolve, reject) => {
            api.Customers.get(that.id, (c) => { that.cancelCustomer = c; })
                .then(({ data: customer }) => {
                    that.customer = customer;
                    that.data.amount = customer.storeCreditBalance;
                    api.CustomerRelationships.get(that.id, true, (c) => { that.cancelCustomer = c; })
                        .then(({ data: relationships }) => {
                            that.relationships = relationships;
                            if (!!relationships && relationships.length === 1) {
                                that.data.toCustomerId = relationships[0].relatedTo.id;
                            }
                            resolve();
                        })
                        .catch(() => {
                            reject();
                        })
                        .finally(() => {
                            that.isLoading = false;
                            that.isReady = true;
                        })
                })
                .catch(() => {
                    reject();
                })
        })
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            let option = toJS(that.data);

            api.Customers.storeCreditTransfer(that.id, option, (c) => { that.cancelSave = c; })
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isSaving = false;
                    that.hasUnsavedChanges = false;
                })
        })
    }

    clear = () => {
        this.id = null;
        this.data.toCustomerId = null;
        this.data.amount = null;
        this.isLoading = false;
        this.isSaving = false;
        this.isReady = false;
        this.hasUnsavedChanges = false;
    }
}

decorate(CustomerStoreCreditTransfer, {
    id: observable,
    data: observable,
    initialize: action,
    save: action,
    isLoading: observable,
    isSaving: observable,
    isReady: observable,
    hasUnsavedChanges: observable,
})

export default createContext(new CustomerStoreCreditTransfer()); 
