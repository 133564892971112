import { createContext } from 'react';
import { decorate, observable, action, toJS } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class ServiceCreate {
    data = {
        colorHexValue: '#00acc1',
        name: null,
        code: null,
        feeCode: null,
        subsidyCode: null,
        minAgeRestriction: null,
        maxAgeRestriction: null,
        isFullExam: null,
        isBookable: null,
        isRepeatable: null,
        isTaxable: null,
        subsidizedEligibilityUnit: null,
        subsidizedEligibilityType: null,
        defaultDurationInMinutes: null,
        fee: null,
        timelineItems: [],
        examPublishedTemplateId: null,
        examTemplateId: null,
        sendReminder: false,
        activeStartingDate: null,
    };
    types = [];
    options = [];
    hasUnsavedChanges = false;
    isReady = false;
    isSaving = false;
    saveToServer = true;

    cancelServiceCreate = null;

    initialize = (saveToServer) => {
        this.clear();

        if (!fn.isNullOrUndefined(saveToServer)) {
            this.saveToServer = saveToServer;
        }
        this.isReady = true;
    }

    save = async (notify) => {
        if (!!notify) {
            this.isSaving = true;
        }

        if (this.hasUnsavedChanges) {
            try {
                let option = toJS(this.data);
                option.fee = isNaN(option.fee) ? fn.parseCurrency(option.fee) : option.fee;
                await api.Services.create(option, (c) => { this.cancelService = c });
                this.hasUnsavedChanges = false;
                return this.data;
            }
            finally {
                this.isSaving = false;
            }
        } 
        this.isSaving = false;
    }

    clear = () => {
        this.data.colorHexValue = '#00acc1';
        this.data.name = null;
        this.data.code = null;
        this.data.feeCode = null;
        this.data.subsidyCode = null;
        this.data.minAgeRestriction = null;
        this.data.maxAgeRestriction = null;
        this.data.isFullExam = null;
        this.data.isBookable = null;
        this.data.isRepeatable = null;
        this.data.isTaxable = null;
        this.data.subsidizedEligibilityUnit = null;
        this.data.subsidizedEligibilityType = null;
        this.data.defaultDurationInMinutes = null;
        this.data.fee = null;
        this.data.timelineItems = [];
        this.data.examPublishedTemplateId = null;
        this.data.examTemplateId = null;
        this.data.sendReminder = false;
        this.data.activeStartingDate = null;
        this.hasUnsavedChanges = false;
        this.isReady = false;
        this.isSaving = false;
        this.saveToServer = true;

        if (fn.isFunction(this.cancelServiceCreate)) {
            this.cancelServiceCreate();
            this.cancelServiceCreate = null;
        }
    }
}

decorate(ServiceCreate, {
    data: observable.deep,
    types: observable,
    options: observable,
    hasUnsavedChanges: observable,
    isReady: observable,
    isSaving: observable,
    saveToServer: observable,
    initialize: action,
    save: action,
    clear: action,
})

export default createContext(new ServiceCreate());