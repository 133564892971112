import React, { useContext, useEffect, useRef, useState } from 'react';
import { Observer } from 'mobx-react-lite';
import FadeIn from 'react-fade-in';
import TimeAgo from 'react-timeago';
import { GlobalHotKeys } from 'react-hotkeys';
import { toast } from 'react-toastify';
import moment from 'moment';

import BodyEnd from '../../_shared/BodyEnd';
import PurchaseModal from '../../purchases/PurchaseModal/_index';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';

import ProductViewStore from '../../../../stores/ProductViewStore';
import PurchaseViewStore from '../../../../stores/PurchaseViewStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';
import useSignalR from '../../../hooks/useSignalR';

import * as uh from '../../../../utilities/userHelper';
import * as sys from '../../../../utilities/systemHelper';

import './ViewInventoryHistory.scss';

function ViewInventoryHistory(props) {
    const isMounted = useRef(true);
    const bodyScrollTimer = useRef(null);
    const bodyRef = useRef(null);
    const purchaseModalRef = useRef(null);
    const product = useContext(ProductViewStore);
    const purchase = useContext(PurchaseViewStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const signalR = useSignalR();
    const [isRendered, setIsRendered] = useState(false);
    const [isPurchaseModalOpened, setIsPurchaseModalOpened] = useState(false);

    useEffect(() => {

        signalR.on('Product', (updated) => {
            if (updated && product.data.id === updated.id) {
                product.refresh();
            }
        });
        return () => {
            isMounted.current = false;
            if (bodyScrollTimer.current) { clearTimeout(bodyScrollTimer.current); bodyScrollTimer.current = null; }
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        if (isRendered) {
            bodyScrollTimer.current = setTimeout(() => {
                if (bodyRef.current) {
                    bodyRef.current.scrollTop = 99999;
                }
            }, 100)
        }
    }, [isRendered]) // eslint-disable-line

    const handlePurchaseModalOpen = (id) => {
        if (id && isMounted.current) {
            purchase.load(id)
                .then(() => {
                    if (isMounted.current) {
                        setIsPurchaseModalOpened(true);
                        purchaseModalRef.current.show();
                    }
                })
        }
    }

    const handlePurchaseModalClose = () => {
        purchase.clear();
        setIsPurchaseModalOpened(false);
    }

    const handlePurchaseModalSuccess = (event, result) => {
        if (result.updated) {
            toast.dark(() => <p data-purch-upd>Purchase updated.</p>);
        }
        setIsPurchaseModalOpened(false);
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) && !isPurchaseModalOpened ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    props.onCancel(event);
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form onSubmit={(e) => { e.preventDefault(); }}>
            <Observer>{() =>
                <fieldset disabled={!product.isReady}>
                    <div className='quick-drawer view-inventory-history-drawer'>
                        <QuickDrawerHeader
                            drawer={props.drawer}
                            icon='fal fa-history'
                            action='View'
                            category='Inventory History'
                            className='products'
                            onCancel={props.onCancel}
                        />
                        <div ref={bodyRef} className='quick-drawer-body'>
                            {
                                product.isReady ?
                                    <>
                                        <FadeIn>
                                            <div className='view-inventory-history body-content py-1'>
                                                {
                                                    product.data.history && product.data.history.length > 0 ?
                                                        product.data.history.map((h, hi) => {
                                                            return <div
                                                                key={`inventory-history-${hi}`}
                                                                className='d-flex flex-column align-items-center'
                                                            >
                                                                <div className='d-flex flex-row w-100 py-4 overflow-hidden'>
                                                                    <div className='d-inline-block align-middle mr-2'>
                                                                        <div className='quantity text-center ml-n2'>
                                                                            <strong className={'d-block w-100 fs-130' + (h.difference < 0 ? ' text-success-600' : ' text-info-600')}>{h.difference === 0 ? '' : (h.difference < 0 ? '-' : '+')}{Math.abs(h.difference)}</strong>
                                                                            {
                                                                                h.difference ?
                                                                                    <strong className={'d-block text-truncate fs-80 px-2' + (h.quantity > 0 ? ' text-gray-700' : ' text-danger')}>{h.quantity} in stock</strong> : 
                                                                                    <strong className='d-block text-truncate fs-80 px-2 text-danger'>Not taken<br/>from stock</strong>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className='mb-0 flex-1 text-dark'>
                                                                        <div className='d-flex'>
                                                                            <span className='text-gray-800 fw-500'>
                                                                                <span className='note-by position-relative'>
                                                                                    {h.user ? uh.getDisplayShortName(h.user) : 'System'}
                                                                                </span>
                                                                            </span>
                                                                            <span className='ml-auto'>
                                                                                {
                                                                                    moment().startOf('day').isSame(moment.utc(h.createdDateUtc).local().startOf('day')) ?
                                                                                        <TimeAgo
                                                                                            className='text-muted fs-xs opacity-70'
                                                                                            date={moment.utc(h.createdDateUtc).local().toDate()}
                                                                                            title={sys.getFormattedLongDate(moment.utc(h.createdDateUtc).local(), true)}
                                                                                            minPeriod={60}
                                                                                        /> :
                                                                                        <span
                                                                                            className='text-muted fs-xs opacity-70'
                                                                                            title={sys.getFormattedLongDate(moment.utc(h.createdDateUtc).local(), true)}
                                                                                        >
                                                                                            {sys.getFormattedLongDate(moment.utc(h.createdDateUtc).local())}
                                                                                        </span>
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                        {
                                                                            h.referenceNumber ?
                                                                                <div>
                                                                                    {
                                                                                        h.referenceId ?
                                                                                            <button
                                                                                                type='button'
                                                                                                className='btn btn-link fw-500 p-0'
                                                                                                onClick={() => { handlePurchaseModalOpen(h.referenceId) }}
                                                                                            >
                                                                                                Invoice # {h.referenceNumber}
                                                                                            </button> :
                                                                                            <>{/[0-9a-zA-Z]+/.test(h.referenceNumber) ? 'Ref No. ' : ''}{h.referenceNumber}</>
                                                                                    }
                                                                                </div> : null
                                                                        }
                                                                        <div
                                                                            className='html'
                                                                            dangerouslySetInnerHTML={{ __html: h.noteHtml }}
                                                                        ></div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    product.data.history.length !== (hi + 1) ?
                                                                        <hr className={'m-0 w-100' + (
                                                                            !moment.utc(product.data.history[hi].createdDateUtc).local().startOf('day').isSameOrAfter(moment().add(-2, 'weeks').startOf('day')) &&
                                                                                moment.utc(product.data.history[hi + 1].createdDateUtc).local().startOf('day').isSameOrAfter(moment().add(-2, 'weeks').startOf('day')) ?
                                                                                ' new' : ''
                                                                        )} /> : null
                                                                }
                                                            </div>
                                                        }) : null
                                                }
                                            </div>
                                        </FadeIn>
                                        <BodyEnd>
                                            <PurchaseModal ref={purchaseModalRef} defaultMode='view' onSuccess={handlePurchaseModalSuccess} onClose={handlePurchaseModalClose} />
                                        </BodyEnd>
                                        {setIsRendered(true)}
                                    </> : null
                            }
                        </div>
                        <div className='quick-drawer-action pr-2'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='float-right'>
                                        <button
                                            type='button'
                                            className='btn btn-link btn-cancel'
                                            onClick={props.onCancel}
                                        >Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            }</Observer>
        </form>
    </>
}

export default ViewInventoryHistory;