import React, { useContext, useEffect, useRef } from 'react';
import Clock from 'react-live-clock';
import { useObserver } from 'mobx-react-lite';

import { showSwitchPinScreen } from './SwitchPinScreen';

import AuthStore from '../../../stores/AuthStore';
import SettingStore from '../../../stores/SettingStore';
import useSignalR from '../../hooks/useSignalR';

import * as StorageKeys from '../../../constants/storageKeys';
import api from '../../../api';

import './LockScreen.scss';

function LockScreen({ imageUrl, children }) {
    const isMounted = useRef(true);
    const auth = useContext(AuthStore);
    const setting = useContext(SettingStore);
    const signalR = useSignalR();

    const activateSwitchPinScreen = () => {
        showSwitchPinScreen();
        auth.activateSwitchPinScreen();
    }

    useEffect(() => {
        signalR.on('Lockscreen', (lockscreen) => {
            if (window.localStorage.getItem(StorageKeys.SESSION_ID) === lockscreen.id) {
                if (lockscreen.isActivated && !auth.isLocked) {
                    auth.activateLockScreen();
                }
                if (!lockscreen.isActivated && auth.isLocked) {
                    auth.deactivateLockScreen();
                    auth.refresh();
                    setting.initialize();
                }
            }
        });

        api.Lockscreen.get(window.localStorage.getItem(StorageKeys.SESSION_ID))
            .then(({ data }) => {
                if (!!data) {
                    auth.activateLockScreen();
                }
            })

        return () => {
            isMounted.current = false;
        }
    }, []) // eslint-disable-line

    return useObserver(() => <>
        {
            auth.isLocked ?
                <div
                    className={`lockscreen animated faster ${(auth.isLockReady ? (auth.isLocked ? 'fadeIn' : 'fadeOutUp') : 'd-none')}`}
                    style={{ backgroundImage: `url('${imageUrl}')` }}
                    onClick={activateSwitchPinScreen}
                >
                    <div className='live-clock'>
                        <div className='time-midday'>
                            <Clock
                                className='time'
                                format='h:mm'
                                ticking={true}
                                interval={30000}
                            />
                            <Clock
                                className='midday'
                                format='A'
                                ticking={true}
                                interval={30000}
                            />
                        </div>
                        <Clock
                            className='date'
                            format='dddd, MMMM Do'
                            ticking={true}
                            interval={3600000}
                        />
                    </div>
                </div> : children
        }
    </>)
}

export default LockScreen;