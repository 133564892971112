import { createContext } from 'react';
import uuid from 'react-uuid';
import { decorate, observable, action, computed, toJS } from 'mobx';
import moment from 'moment';

import api from '../api';
import * as fn from '../utilities/_functions';
import * as ah from '../utilities/appointmentHelper';

export class AppointmentCreate {
    id = null;
    stageCustomer = null;
    data = {
        id: null,
        start: null,
        duration: null,
        customerId: null,
        userId: null,
        services: [],
        notes: [],
        repeatType: null,
        repeatUntil: null,
        repeatTotal: null,
    };
    checkUpcomingConflict = true;
    isReady = false;
    hasUnsavedChanges = false;
    isSaving = false;
    saveToServer = true;
    cancelAppointmentCreate = null;

    initialize = saveToServer => {
        this.clear();
        this.id = uuid();

        if (!fn.isNullOrUndefined(saveToServer)) {
            this.saveToServer = saveToServer;
        }

        this.isReady = true;
    }

    stage = (customer, saveToServer) => {
        this.clear();
        this.id = uuid();
        this.stageCustomer = customer;
        this.data.customerId = customer.id;
        this.checkUpcomingConflict = false;

        if (!fn.isNullOrUndefined(saveToServer)) {
            this.saveToServer = saveToServer;
        }

        this.isReady = true;
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            let option = toJS(that.data);

            option.start = that.data.start.clone().format('YYYY-MM-DDTHH:mm');
            option.utcOffset = that.data.start.clone().utcOffset() / 60;
            option.services = Array.from(new Set(...[option.services]));

            if (that.hasUnsavedChanges) {
                if (that.saveToServer && !that.cancelAppointmentCreate) {
                    api.Appointments.create(
                        option,
                        (c) => { that.cancelAppointmentCreate = c; },
                    )
                        .then(({ data }) => {
                            that.id = data.id;
                            that.data.id = data.id;
                            option.id = data.id;
                            option.groupId = null;
                            option.isGroup = false;
                            resolve(option);
                        })
                        .catch(() => {
                            reject();
                        })
                        .finally(() => {
                            that.cancelAppointmentCreate = null;
                            that.isSaving = false;
                            that.hasUnsavedChanges = false;
                        })
                } else {
                    resolve(option);
                }
            } else {
                this.isSaving = false;
                that.hasUnsavedChanges = false;
                resolve(option);
            }
        })
    }

    checkConflicts = (notify) => {
        const that = this;

        return new Promise((resolve, reject) => {
            ah.checkConflicts(that.data, that.checkUpcomingConflict)
                .then((data) => {
                    resolve(data);
                })
                .catch(error => {
                    reject();
                })
        })
    }

    checkEligibility = (customer, notify) => {
        const that = this;

        return new Promise((resolve, reject) => {
            ah.checkEligibility(customer, that.data)
                .then((data) => {
                    if (data && data.length > 0) {
                        for (let di = 0; di < data.length; di++) {
                            if (data[di].service.isSubsidized) {
                                const ii = that.data.services.findIndex(s => s.code === data[di].code);
                                if (ii > -1) {
                                    that.data.services[ii].isEligible = data[di].isEligible;
                                    that.data.services[ii].lastEligibilityCheckedDateUtc = moment().utc();
                                    that.data.services[ii].ineligibilityCode = data[di].ineligibilityCode;
                                    that.data.services[ii].ineligibilityReason = data[di].ineligibilityReason;
                                    that.data.services[ii].earliestEligibleDate = data[di].earliestEligibleDate ? moment(data[di].earliestEligibleDate) : null;
                                }
                            }
                        }
                    } else {
                        if (that.data.services && that.data.services.length > 0) {
                            for (let si = 0; si < that.data.services.length; si++) {
                                that.data.services[si].isEligible = null;
                                that.data.services[si].lastEligibilityCheckedDateUtc = null;
                                that.data.services[si].ineligibilityCode = null;
                                that.data.services[si].ineligibilityReason = null;
                                that.data.services[si].earliestEligibleDate = null;
                            }
                        }
                    }
                    resolve(data);
                })
                .catch(error => {
                    reject();
                })
        })
    }

    clear = () => {
        this.id = null;
        this.stageCustomer = null;
        this.data.id = null;
        this.data.start = null;
        this.data.duration = null;
        this.data.customerId = null;
        this.data.userId = null;
        this.data.services.clear();
        this.data.notes.clear();
        this.data.repeatType = null;
        this.data.repeatUntil = null;
        this.data.repeatTotal = null;
        this.checkUpcomingConflict = true;
        this.hasUnsavedChanges = false;
        this.isReady = false;
        this.isSaving = false;
        this.saveToServer = true;
        if (fn.isFunction(this.cancelAppointmentCreate)) {
            this.cancelAppointmentCreate();
            this.cancelAppointmentCreate = null;
        }
    }

    get end() {
        if (this.data.start && this.data.duration && this.data.duration > 0) {
            return this.data.start.clone().add(this.data.duration, 'minutes');
        }
        return null;
    }

    get startTime() {
        if (this.data.start) {
            return moment().startOf('day').hours(this.data.start.hour()).minutes(this.data.start.minute());
        }
        return null;
    }

    get endTime() {
        if (this.end) {
            return moment().startOf('day').hours(this.end.hour()).minutes(this.end.minute());
        }
        return null;
    }

    get recommendedDuration() {
        var totalDuration = this.data.services.reduce((acc, service) => {
            acc += service.duration;
            const selectedUserId = this.data.userId;
            // Iterate over userOverrides
            if (service.userOverrides) {
                const userOverride = service.userOverrides.find(o => o.userId === selectedUserId);
                if (userOverride && userOverride.isBookable && userOverride.additionalTimeInMinutes !== 0) {
                    acc += userOverride.additionalTimeInMinutes;
                }
            }
            return acc;
        }, 0);
        return totalDuration;
    }

    get isRepeatable() {
        return this.data.services.some(s => s.isRepeatable === true);
    }
}

decorate(AppointmentCreate, {
    id: observable,
    stageCustomer: observable,
    data: observable,
    checkUpcomingConflict: observable,
    end: computed,
    startTime: computed,
    endTime: computed,
    recommendedDuration: computed,
    isRepeatable: computed,
    hasUnsavedChanges: observable,
    isReady: observable,
    isSaving: observable,
    saveToServer: observable,
    initialize: action,
    checkConflicts: action,
    checkEligibility: action,
    save: action,
    clear: action,
})

export default createContext(new AppointmentCreate());