import React, { Component } from 'react';
import FadeIn from 'react-fade-in';
import { GlobalHotKeys } from 'react-hotkeys';

import * as fn from '../../../utilities/_functions';

import './ConfirmModal.scss';

export default class ConfirmModal extends Component {
    constructor(props) {
        super(props);
        this.readyTimer = React.createRef();
        this.state = {
            ready: false,
            display: false,
            option: null,
            zIndex: null,
        }
    }

    componentWillUnmount() {
        if (this.props.onClose) { this.props.onClose(); }
        if (this.readyTimer.current) { clearTimeout(this.readyTimer.current); this.readyTimer.current = null; }
    }

    show = (option) => {
        const zIndex = fn.getHighestZIndex() + 1;
        this.setState({ display: true, option: option, zIndex: zIndex });
    }

    close = () => {
        this.handleClose();
    }

    handleClose = event => {
        this.setState({ option: null, display: false, zIndex: null, ready: false });
    }

    get isVisible() {
        return this.state.display;
    }

    render() {
        const that = this;
        const { display, ready, option } = this.state;
        const modalZIndex = fn.getHighestZIndex() + 2;
        const backdropZIndex = fn.getHighestZIndex() + 1;

        if (!!option && !ready) {
            that.readyTimer.current = setTimeout(() => {
                that.setState({ ready: true });
            }, 100)
        }

        return !!option ? <>
            <GlobalHotKeys
                keyMap={{
                    close: ['esc'],
                }}
                handlers={{
                    close: event => {
                        if (fn.isFunction(option.onCancel)) {
                            option.onCancel(event)
                        }
                    },
                }}
                allowChanges={true}
            />
            <div className={'bootbox modal confirm-modal modal-alert d-block' + (display && ready ? ' show' : '')} tabIndex='-1' role='dialog' aria-modal='true' style={{ zIndex: modalZIndex }}>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <FadeIn>
                            <div>
                                <div className='modal-header'>
                                    <h5 className='modal-title'>
                                        {
                                            option.icon ?
                                                option.icon :
                                                <i className='fal fa-exclamation-triangle text-warning mr-2'></i>
                                        }
                                        {
                                            option.message ?
                                                option.message :
                                                <>Do you wish to continue?</>
                                        }
                                    </h5>
                                </div>
                                <div className='modal-body'>
                                    <div className='bootbox-body'>
                                        <span className='bootbox-modal-icon-newline'>
                                            {
                                                option.description ?
                                                    option.description :
                                                    <span className={'description' + (option.descriptionClassName ? ` ${option.descriptionClassName}` : '')}><strong className='text-danger flashing slowest'>Warning:</strong> This action cannot be undone!</span>
                                            }
                                        </span>
                                    </div>
                                </div>
                                <div className='modal-footer'>
                                    {
                                        option.renderCustomControls && fn.isFunction(option.renderCustomControls) ? option.renderCustomControls() : null
                                    }
                                    <button
                                        data-confirm-modal-option-1
                                        type='button'
                                        className={option.option1ClassName ? option.option1ClassName : 'btn btn-danger shadow-0 bootbox-accept'}
                                        disabled={option.option1Disabled}
                                        onClick={option.onOption1Click}
                                    >
                                        {option.option1Text ? option.option1Text : 'Yes'}
                                    </button>
                                    {
                                        option.onOption2Click ?
                                            <button
                                                data-confirm-modal-option-2
                                                type='button'
                                                className={option.option2ClassName ? option.option2ClassName : 'btn btn-secondary shadow-0 bootbox-accept'}
                                                disabled={option.option2Disabled}
                                                onClick={option.onOption2Click}
                                            >
                                                {option.option2Text ? option.option2Text : 'Options'}
                                            </button> : null
                                    }
                                    {
                                        option.onOption3Click ?
                                            <button
                                                data-confirm-modal-option-3
                                                type='button'
                                                className={option.option3ClassName ? option.option3ClassName : 'btn btn-info shadow-0 bootbox-accept'}
                                                disabled={option.option3Disabled}
                                                onClick={option.onOption3Click}
                                            >
                                                {option.option3Text ? option.option3Text : 'Other'}
                                            </button> : null
                                    }
                                    <button
                                        data-confirm-modal-no
                                        type='button'
                                        className={option.noButtonClassName ? option.noButtonClassName : 'btn btn-default bootbox-cancel'}
                                        onClick={option.onCancel}
                                    >
                                        {option.cancelText ? option.cancelText : 'No'}
                                    </button>
                                </div>
                            </div>
                        </FadeIn>
                    </div>
                </div>
            </div>
            <div className={'modal-backdrop fade' + (display && ready ? ' show' : '')} style={{ zIndex: backdropZIndex }}></div>
        </> : null
    }
}