import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { Observer } from 'mobx-react-lite';
import FadeIn from 'react-fade-in';
import TimeAgo from 'react-timeago';
import moment from 'moment';
import { toJS } from 'mobx';

import QuickDrawerHeader from '../_shared/QuickDrawerHeader';
import { GlobalHotKeys } from 'react-hotkeys';

import NoteSearchStore from '../../../stores/NoteSearchStore';
import FileViewerStore from '../../../stores/FileViewerStore';
import QuickDrawerStore from '../../../stores/QuickDrawerStore';

import * as fn from '../../../utilities/_functions';
import * as ph from '../../../utilities/personHelper';
import * as uh from '../../../utilities/userHelper';
import * as oh from '../../../utilities/operationHelper';

import './CorrespondenceAttachments.scss';

function CorrespondenceAttachments(props) {
    const isMounted = useRef(true);
    const renderTimer = useRef(null);
    const editorTimer = useRef(null);
    const bodyRef = useRef(null);
    const noteSearch = useContext(NoteSearchStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const fileViewer = useContext(FileViewerStore);
    const [isRendered, setIsRendered] = useState(false);

    const restoreSelectedAttachments = (selectedIds) => {
        noteSearch.customer.notes.forEach(n => {
            if (n.attachments && n.attachments.length) {
                n.attachments.forEach(a => {
                    if (selectedIds.includes(a.id)) {
                        a.selected = true;
                    }
                })
            }
        })
    }

    useEffect(() => {
        noteSearch.initialize(props.extraProps.customer.id).then(() => {
            if (props.extraProps.selectedAttachmentIds && props.extraProps.selectedAttachmentIds.length) {
                // set up the checkbox "checked" state based on the props.extraProps.selectedAttachmentIds
                restoreSelectedAttachments(props.extraProps.selectedAttachmentIds);
            }
        });

        renderTimer.current = setTimeout(() => {
            if (!isRendered) {
                if (bodyRef.current) {
                    bodyRef.current.scrollTop = 99999;
                }
                setIsRendered(true);
            }
        }, 500)
        return () => {
            isMounted.current = false;
            if (renderTimer.current) { clearTimeout(renderTimer.current); renderTimer.current = null; }
            if (editorTimer.current) { clearTimeout(editorTimer.current); editorTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleAttachmentPreview = (event, attachment) => {
        const attachments = (noteSearch.customer && noteSearch.customer.notes && noteSearch.customer.notes.some(n => n.attachments && n.attachments.length > 0) ?
            noteSearch.customer.notes.filter(n =>
                n.attachments && n.attachments.length > 0).map(n => {
                    return n.attachments.map(a => { return a })
                }) : []).flat();
        const index = attachments.findIndex(a => a.id === attachment.id);

        fileViewer.initialize(
            () => {
                return noteSearch.customer ? <div className='fs-lg'>
                    {ph.getPreferredFirstLastName(noteSearch.customer)}
                    <small className='d-block text-gray-700'>View attachment(s)</small>
                </div> : null
            },
            'attachment',
            attachments,
            index,
            noteSearch.customer.id
        );
    }

    const handleSelectAttachment = (event, note, attachment) => {
        const noteIndex = noteSearch.customer.notes.findIndex(n => n.id === note.id);

        if (noteIndex > -1) {
            const attachmentIndex = noteSearch.customer.notes[noteIndex].attachments.findIndex(a => a.id === attachment.id);

            if (attachmentIndex > -1) {
                noteSearch.customer.notes[noteIndex].attachments[attachmentIndex].selected = !noteSearch.customer.notes[noteIndex].attachments[attachmentIndex].selected;
            }
        }
    }

    const handleSeeAttachments = () => {
        noteSearch.clearNewNote();
        quickDrawer.activateQuickDrawer('note', 'attachment', null, handleSeeAttachmentUpdateSuccess);
    }

    const handleSeeAttachmentUpdateSuccess = () => {
        noteSearch.initialize(props.extraProps.customer.id);
    }

    const handleSelectedAttachments = (event) => {
        if (isMounted.current) {
            if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                var selectedAttachments = [];
                for (let note of noteSearch.customer.notes) {
                    selectedAttachments.push(...note.attachments.filter(a => a.selected));
                }
                props.onSuccess(event, toJS(selectedAttachments));
            }
        }
    }

    const renderExistingAttachments = () => {
        const existingAttachmentNotes = getExistingCustomerAttachmentNotes();
        return <>
            {
                existingAttachmentNotes.length > 0 ?
                    existingAttachmentNotes.map((n, ni) => {
                        return <Fragment key={`note-${ni}`}>
                            {
                                n.attachments && n.attachments.length > 0 ?
                                    n.attachments.map((a, ai) => {
                                        return <div key={`note-${ni}-${ai}`} className={'d-flex flex-column align-items-center'}>
                                            <div className='d-flex flex-row w-100 py-4'>
                                                <div className='d-inline-block align-middle mr-3'>
                                                    <div className='custom-control custom-checkbox'>
                                                        <input
                                                            id={`existing-attachment-checkbox-${ni}-${ai}`}
                                                            type='checkbox'
                                                            name={`existing-attachment-checkbox-${ni}-${ai}`}
                                                            className='custom-control-input'
                                                            checked={(a.selected ? a.selected : false)}
                                                            onChange={(e) => { handleSelectAttachment(e, n, a) }}
                                                        />
                                                        <label
                                                            htmlFor={`existing-attachment-checkbox-${ni}-${ai}`}
                                                            className='custom-control-label'
                                                        >
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className='mb-0 flex-1 text-dark'>
                                                    <div className='d-flex'>
                                                        <span className='text-gray-800 fw-500'>
                                                            {n.resource ? uh.getDisplayShortName(n.resource) : 'System'}
                                                        </span>
                                                        <TimeAgo
                                                            className='text-muted fs-xs opacity-70 ml-auto'
                                                            date={moment.utc(n.createdDateUtc).local().toDate()}
                                                            title={moment.utc(n.createdDateUtc).local().format('dddd MMMM D, YYYY h:mm a')} minPeriod={60}
                                                        />
                                                    </div>
                                                    <div className={'card attachment border' + (ai !== (n.attachments.length - 1) ? ' mb-2' : '')}>
                                                        <div className='card-body'>
                                                            <div className='d-flex flex-row w-100'>
                                                                <div className='d-flex align-middle align-items-center mr-3'>
                                                                    {fn.toFileIcon(a.mimeType, 'fs-xxl')}
                                                                </div>
                                                                <div className='flex-1'>
                                                                    <div className='d-block text-truncate text-truncate-md fw-500 text-gray-800'>{a.fileName}</div>
                                                                    <div className='d-flex flex-row align-items-center fs-xs'>
                                                                        <button
                                                                            type='button'
                                                                            className='btn btn-xs btn-link p-0'
                                                                            onClick={(e) => { handleAttachmentPreview(e, a) }}
                                                                        >
                                                                            Preview
                                                                        </button>
                                                                        <span className='line-height-1 mt-h'>&nbsp;&middot;&nbsp;</span>
                                                                        <span className='line-height-1 text-gray-500 fs-xs mt-h'>{fn.toFileSize(a.size, true)}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }) : null
                            }
                        </Fragment>
                    }) : null
            }
        </>
    }

    const getExistingCustomerAttachmentNotes = () => {
        if (!noteSearch.customer || !noteSearch.customer.notes) return [];
        return noteSearch.customer.notes.filter(n => n.attachments && n.attachments.length > 0);
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    props.onCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form onSubmit={() => { }}>
            <Observer>{() =>
                <fieldset disabled={noteSearch.isSaving}>
                    <div className='quick-drawer correspondence-attachments-drawer'>
                        <QuickDrawerHeader
                            drawer={props.drawer}
                            icon={oh.getIcon('note', 'attachment')}
                            action='Select'
                            category={(props.extraProps && props.extraProps.category ? props.extraProps.category : 'Attachments')}
                            className='notes'
                            onCancel={props.onCancel}
                        />
                        <div ref={bodyRef} className='quick-drawer-body'>
                            {
                                noteSearch.isReady ?
                                    <FadeIn>
                                        <div className={'view-note body-content py-1' + (isRendered ? ' rendered' : '')}>
                                            {renderExistingAttachments()}
                                        </div>
                                    </FadeIn> : null
                            }
                        </div>
                        <div className='quick-drawer-action'>
                            <div className='row'>
                                <div className='col-4'>
                                    <button
                                        type='button'
                                        className='btn btn-icon'
                                        onClick={handleSeeAttachments}
                                        title='Attachments'
                                    >
                                        <i className='fal fa-paperclip'></i>
                                    </button>
                                </div>
                                <div className='col-8'>
                                    <div className='float-right'>
                                        <button
                                            type='button'
                                            className='btn btn-link btn-cancel mr-2'
                                            onClick={props.onCancel}
                                        >Cancel</button>
                                        <button
                                            type='button'
                                            disabled={noteSearch.customer && noteSearch.customer.notes && !noteSearch.customer.notes.some(n => n.attachments && n.attachments.some(a => a.selected)) /* if none of the attachments are selected, disable SELECT button */}
                                            className='btn btn-success'
                                            onClick={handleSelectedAttachments}
                                        >Select</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            }</Observer>
        </form>
    </>
}

export default CorrespondenceAttachments;