import { createContext } from 'react';
import { decorate, observable, action, computed, toJS } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';
import * as eh from '../utilities/examHelper';

export class PreviousExamSelect {
    examTemplateId = null;
    dataSets = [];
    appointment = null;
    selectedId = null;
    isLoading = false;
    isSaving = false;
    isReady = false;

    cancelPreviousExams = null;

    initialize = (appointment) => {
        const that = this;
        this.clear();

        if (appointment) {
            this.appointment = appointment;
            this.examTemplateId = eh.getDefaultExamTemplateId(appointment.services);
        }

        return new Promise((resolve, reject) => {
            that.isLoading = true;

            api.Exams.search(
                {
                    parameters: [
                        {
                            field: 'CustomerId',
                            value: that.appointment.customerId,
                        },
                        {
                            field: 'ActualStart',
                            operator: '<',
                            value: that.appointment.start,
                        },
                        {
                            field: 'AppointmentId',
                            operator: '!=',
                            value: that.appointment.id
                        },
                        {
                            field: 'DeactivatedDateUtc',
                            value: null,
                        },
                        {
                            field: 'Status',
                            operator: '!=',
                            value: 'Deleted',
                        },
                    ],
                    sortByFields: [{
                        field: 'ScheduledStartUtc',
                        direction: 'DESC',
                    }, {
                        field: 'CreatedDateUtc',
                        direction: 'DESC',
                    }],
                    loadProperties: false,
                    includeTotalCount: false,
                },
                (c) => { that.cancelPreviousExams = c }
            )
                .then(({ data }) => {
                    that.dataSets = data.result ? data.result : [];
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isLoading = false;
                    that.isReady = true;
                })
        })
    }

    save = () => {
        const that = this;

        return new Promise((resolve) => {
            resolve(toJS(that.selectedData));
        })
    }

    clear = () => {
        this.examTemplateId = null;
        this.selectedId = null;
        this.dataSets.clear();
        this.appointment = null;
        this.isLoading = false;
        this.isSaving = false;
        this.isReady = false;

        if (fn.isFunction(this.cancelPreviousExams)) {
            this.cancelPreviousExams();
            this.cancelPreviousExams = null;
        }
    }

    get selectedData() {
        if (this.filteredDataSets && this.filteredDataSets.length > 0) {
            return this.filteredDataSets.filter(d => d.id === this.selectedId)[0];
        }

        return null;
    }

    get filteredDataSets() {
        return this.dataSets && this.dataSets.length > 0 ? this.dataSets : [];
    }
}

decorate(PreviousExamSelect, {
    examTemplateId: observable,
    selectedId: observable,
    dataSets: observable.deep,
    appointment: observable,
    isLoading: observable,
    isSaving: observable,
    isReady: observable,
    save: action,
    selectedData: computed,
    filteredDataSets: computed,
})

export default createContext(new PreviousExamSelect());