import React, { useEffect, useContext, useRef, useState } from 'react';
import { toJS } from 'mobx';
import FadeIn from 'react-fade-in';
import { Observer } from 'mobx-react-lite';
import List, { ItemDragging } from 'devextreme-react/list';
import { GlobalHotKeys } from 'react-hotkeys';
import TimeAgo from 'react-timeago';
import moment from 'moment';

import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';

import WorkOrderCreateStore, { getNewWorkOrder } from '../../../../stores/WorkOrderCreateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as oh from '../../../../utilities/operationHelper';
import * as bh from '../../../../utilities/badgeHelper';
import * as sys from '../../../../utilities/systemHelper';

import './CreateWorkOrder.scss';

function CreateWorkOrder(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const focusTimer = useRef(null);
    const newWorkOrder = useContext(WorkOrderCreateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100)

        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (newWorkOrder.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleWorkOrderPublishedTemplateChange = (event, workOrder, item) => {
        const value = event.target.value;

        if (value) {
            newWorkOrder.updateWorkOrderPublishedTemplate(workOrder, item, value);
        } else {
            handleWorkOrderItemDragRemove({
                fromIndex: workOrder.items.findIndex(it => it.id === item.id),
                fromData: workOrder.id,
            })
        }
        newWorkOrder.hasUnsavedChanges = true;
    }

    const handleWorkOrderAdd = (event) => {
        const workOrder = getNewWorkOrder(newWorkOrder.customerId, newWorkOrder.purchaseId);
        newWorkOrder.data.push(workOrder);
    }

    const handleWorkOrderItemAdd = (event, workOrderId) => {
        const extraProps = {
            id: workOrderId,
            purchase: newWorkOrder.purchase,
            workOrders: [],
            templates: newWorkOrder.templates,
        };

        if (newWorkOrder.purchase.workOrders && newWorkOrder.purchase.workOrders.filter(wo => !wo.isExpired && !wo.isDeactivated).length > 0) {
            extraProps.workOrders = [...extraProps.workOrders, ...newWorkOrder.purchase.workOrders.filter(wo => !wo.isExpired && !wo.isDeactivated)];
        }

        if (newWorkOrder.data && newWorkOrder.data.filter(wo => !wo.isExpired && !wo.isDeactivated).length > 0) {
            extraProps.workOrders = [...extraProps.workOrders, ...newWorkOrder.data.filter(wo => !wo.isExpired && !wo.isDeactivated)];
        }

        quickDrawer.activateQuickDrawer('order', 'add-item', extraProps, handleWorkOrderItemAddSuccess)
            .then(drawer => {
                if (isMounted.current) {
                    focusTimer.current = setTimeout(() => {
                        quickDrawerFocus(drawer);
                    }, 100);
                }
            });
    }

    const handleWorkOrderItemAddSuccess = (result) => {
        if (result && result.data && result.data.items && result.data.items.length > 0) {
            const index = newWorkOrder.data.findIndex(wo => wo.id === result.data.id);

            if (index >= 0) {
                for (let i = 0; i < result.data.items.length; i++) {
                    result.data.items[i].displayOrder = (newWorkOrder.data[index].items.length + 2) * 10;
                    newWorkOrder.data[index].items.push(result.data.items[i]);
                    newWorkOrder.data[index] = JSON.parse(JSON.stringify(newWorkOrder.data[index]));
                }
            }

            // console.log(newWorkOrder.data);
        }
    }

    const handleWorkOrderRemove = (event, workOrderId) => {
        newWorkOrder.data.splice(newWorkOrder.data.findIndex(o => o.id === workOrderId), 1);
        newWorkOrder.hasUnsavedChanges = true;
    }

    const handleWorkOrderHighPriorityChange = (event, { id }) => {
        const index = newWorkOrder.data.findIndex(o => o.id === id)

        if (index >= 0) {
            newWorkOrder.data[index].isHighPriority = !newWorkOrder.data[index].isHighPriority;
        }

        newWorkOrder.hasUnsavedChanges = true;
    }

    const handleWorkOrderItemDragAdd = (event) => {
        const { toData, fromData, toIndex, fromIndex } = event;
        const toItems = toJS([...(newWorkOrder.data.filter(o => o.id === toData)[0].items)]);

        toItems.splice(toIndex, 0, toJS(newWorkOrder.data.filter(o => o.id === fromData)[0].items[fromIndex]));

        for (let i = 0; i < toItems.length; i++) {
            toItems[i].displayOrder = (i + 1) * 10;
        }

        newWorkOrder.data.filter(o => o.id === toData)[0].items = toItems.sort((a, b) => { return a.displayOrder - b.displayOrder });
        newWorkOrder.hasUnsavedChanges = true;
    }

    const handleWorkOrderItemDragRemove = (event) => {
        const { fromData, fromIndex } = event;
        const fromItems = toJS([...(newWorkOrder.data.filter(o => o.id === fromData)[0].items)]);

        fromItems.splice(fromIndex, 1);

        for (let i = 0; i < fromItems.length; i++) {
            fromItems[i].displayOrder = (i + 1) * 10;
        }

        newWorkOrder.data.filter(o => o.id === fromData)[0].items = fromItems.sort((a, b) => { return a.displayOrder - b.displayOrder });

        if (!newWorkOrder.data.filter(o => o.id === fromData)[0].items || newWorkOrder.data.filter(o => o.id === fromData)[0].items.length === 0) {
            handleWorkOrderRemove(event, fromData);
        }

        newWorkOrder.hasUnsavedChanges = true;
    }

    const handleWorkOrderItemReorder = (event) => {
        const { toData, toIndex, fromIndex } = event;
        const reorderedItems = toJS([...(newWorkOrder.data.filter(o => o.id === toData)[0].items)]);

        reorderedItems.splice(fromIndex, 1);
        reorderedItems.splice(toIndex, 0, toJS(newWorkOrder.data.filter(o => o.id === toData)[0].items[fromIndex]));

        for (let i = 0; i < reorderedItems.length; i++) {
            reorderedItems[i].displayOrder = (i + 1) * 10;
        }

        newWorkOrder.data.filter(o => o.id === toData)[0].items = reorderedItems.sort((a, b) => { return a.displayOrder - b.displayOrder });
        newWorkOrder.hasUnsavedChanges = true;
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (newWorkOrder.data && newWorkOrder.data.length > 0 && fn.validateForm(validateRef.current)) {
            const data = toJS([...newWorkOrder.data]);

            newWorkOrder.save()
                .then(() => {
                    if (isMounted.current) {
                        if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                            if (isMounted.current) {
                                setIsSaving(false);
                            }

                            props.onSuccess(event, { updated: true, data: data });
                        }
                    }
                })
        }
    }

    const renderPurchaseDateTime = purchase => {
        const start = moment.utc(purchase.createdDateUtc).local();

        return moment().startOf('day').isSame(start.clone().startOf('day')) ?
            <TimeAgo
                className='text-gray-700 ml-auto'
                date={start.toDate()}
                title={sys.getFormattedLongDate(start, true)} 
                minPeriod={60}
            /> :
            <span
                className='text-gray-700 ml-auto'
                title={sys.getFormattedLongDate(start, true)}
            >
                {sys.getFormattedLongDate(start)}
            </span>
    }

    const renderWorkOrderItems = (workOrder, item) => {
        return <Observer>{() => <>
            <div className='form-group mb-0'>
                <div
                    className='product-info cursor-pointer'
                >
                    <ul className='title'>
                        <li className='quantity'>
                            <h4>
                                <strong>{item.quantity}<small>x</small></strong>
                            </h4>
                        </li>
                        <li className='detail has-quantity'>
                            <span className='text-gray-700'>{item.referenceType ? item.referenceType : 'Custom'}</span>
                            <h4 className='m-0 text-truncate text-truncate-xxl'><strong>{item.descriptor}</strong></h4>
                            <small className='brand'>
                                <strong className='mr-1'>{item.product && item.product.brandName ? item.product.brandName : 'Custom'}</strong>
                                <>({item.product && item.product.supplierName ? item.product.supplierName : 'Custom'})</>
                            </small>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='ml-3 mt-h mb-2'>
                <Observer>{() =>
                    <select
                        className='custom-select form-control'
                        value={item.workOrderPublishedTemplateId ? item.workOrderPublishedTemplateId : ''}
                        onChange={(e) => { handleWorkOrderPublishedTemplateChange(e, workOrder, item) }}
                    >
                        <option value=''>(Remove item)</option>
                        {
                            newWorkOrder.templates && newWorkOrder.templates.length > 0 ?
                                newWorkOrder.templates.map((t, ti) => {
                                    return <option
                                        key={`new-order-item-${item.id}-template-${ti}`}
                                        value={t.id}
                                    >
                                        {t.name}
                                    </option>
                                }) : null
                        }
                    </select>
                }</Observer>
            </div>
        </>}</Observer>
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <fieldset disabled={newWorkOrder.isSaving || isSaving}>
                <div className='new-order-container quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('work-order', 'default')}
                        action='Create New'
                        category='Work Order(s)'
                        className='orders'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        <Observer>{() =>
                            <>
                                {
                                    newWorkOrder.isReady ?
                                        <FadeIn>
                                            <div className='body-content'>
                                                {
                                                    newWorkOrder.purchase ?
                                                        <>
                                                            <section>
                                                                <div className='row'>
                                                                    <div className='col-12'>
                                                                        <div
                                                                            className='d-flex flex-column align-items-center'
                                                                        >
                                                                            <div className='d-flex flex-row w-100 pt-1'>
                                                                                <div className='mb-0 flex-1 text-dark'>
                                                                                    <div className='d-flex mb-h'>
                                                                                        Invoice # {newWorkOrder.purchase.number}
                                                                                        {renderPurchaseDateTime(newWorkOrder.purchase)}
                                                                                    </div>
                                                                                    <div className='d-flex mb-h'>
                                                                                        <div className='flex-1'>
                                                                                            {bh.renderPurchaseReferenceTypes(newWorkOrder.purchase, 'p-1 mr-1 mb-1')}
                                                                                        </div>
                                                                                        <span className='text-gray-700 ml-auto'>{bh.renderPurchaseAmount(newWorkOrder.purchase)}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </section>
                                                            {
                                                                newWorkOrder.data && newWorkOrder.data.length > 0 ?
                                                                    <>
                                                                        {
                                                                            newWorkOrder.data.map((o, oi) => {
                                                                                return <section
                                                                                    key={`new-work-order-${oi}`}
                                                                                    className='m-0'
                                                                                >
                                                                                    <div className='row border-top mt-3 pt-3'>
                                                                                        <div className='col-8'>
                                                                                            <label>
                                                                                                <strong className='tt-uppercase text-primary-700'>
                                                                                                    {
                                                                                                        newWorkOrder.data.length > 1 ?
                                                                                                            `New Work Order #${(oi + 1)}` :
                                                                                                            `New Work Order`
                                                                                                    }
                                                                                                </strong>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className='col-4'>
                                                                                            <div className='float-right'>
                                                                                                <button
                                                                                                    type='button'
                                                                                                    className='btn btn-icon line-height-1 mr-n2'
                                                                                                    title='Add work order item'
                                                                                                    onClick={(e) => { handleWorkOrderItemAdd(e, o.id) }}
                                                                                                >
                                                                                                    <i className='fal fa-plus text-success-600 fs-lg'></i>
                                                                                                </button>
                                                                                                <button
                                                                                                    type='button'
                                                                                                    className='btn btn-icon line-height-1 mr-n2'
                                                                                                    title='Remove work order'
                                                                                                    onClick={(e) => { handleWorkOrderRemove(e, o.id) }}
                                                                                                >
                                                                                                    <i className='fal fa-times text-danger fs-lg'></i>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row pb-2'>
                                                                                        <div className='col-12'>
                                                                                            <div className='form-group'>
                                                                                                <div className='custom-control custom-checkbox'>
                                                                                                    <input
                                                                                                        id={`new-work-order-high-priority-${oi}`}
                                                                                                        type='checkbox'
                                                                                                        name={`new-work-order-high-priority-${oi}`}
                                                                                                        className='custom-control-input'
                                                                                                        checked={!!o.isHighPriority}
                                                                                                        onChange={(e) => { handleWorkOrderHighPriorityChange(e, o) }}
                                                                                                    />
                                                                                                    <label
                                                                                                        htmlFor={`new-work-order-high-priority-${oi}`}
                                                                                                        className={'custom-control-label' + (o.isHighPriority ? ' text-danger' : '')}
                                                                                                    >
                                                                                                        High Priority
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row'>
                                                                                        <div className='col-12'>
                                                                                            {
                                                                                                o && o.items && o.items.length > 0 ?
                                                                                                    <List
                                                                                                        dataSource={o.items}
                                                                                                        activeStateEnabled={false}
                                                                                                        focusStateEnabled={false}
                                                                                                        hoverStateEnabled={false}
                                                                                                        itemRender={(data) => { return renderWorkOrderItems(o, data) }}
                                                                                                    >
                                                                                                        <ItemDragging
                                                                                                            allowReordering={true}
                                                                                                            group='work-orders'
                                                                                                            data={o.id}
                                                                                                            onAdd={handleWorkOrderItemDragAdd}
                                                                                                            onRemove={handleWorkOrderItemDragRemove}
                                                                                                            onReorder={handleWorkOrderItemReorder}
                                                                                                        >
                                                                                                        </ItemDragging>
                                                                                                    </List> : null
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </section>
                                                                            })
                                                                        }
                                                                    </> : null
                                                            }
                                                        </> : null
                                                }
                                            </div>
                                        </FadeIn> : renderQuickDrawerLoading()
                                }
                            </>
                        }</Observer>
                    </div>
                    <div className='quick-drawer-action pl-3'>
                        <div className='row'>
                            <div className='col-4'>
                                <button
                                    type='button'
                                    className='btn btn-default'
                                    onClick={handleWorkOrderAdd}
                                >Add</button>
                            </div>
                            <div className='col-8'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <Observer>{() =>
                                        <button
                                            type='submit'
                                            className='btn btn-success'
                                            disabled={!newWorkOrder.data || newWorkOrder.data.length === 0}
                                        >Create All</button>
                                    }</Observer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>
}

export default CreateWorkOrder;