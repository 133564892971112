import { createContext } from 'react';
import { decorate, observable, action, toJS } from 'mobx';
import moment from 'moment';

import api from '../api';
import { CURRENT_USER } from '../constants/storageKeys'
import * as fn from '../utilities/_functions';

export class GroupAppointmentNoteSearch {
    appointments = [];
    notes = [];
    newNote = {
        bodyHtml: null,
        preview: null,
    };
    updateNote = null;
    removeNoteIds = [];
    isLoading = false;
    isReady = false;

    cancelAppointmentGet = null;
    cancelCustomerGet = null;
    cancelNoteCreate = null;
    cancelNoteUpdate = null;
    cancelNoteRemove = null;
    cancelGroupNoteSearch = null;
    cancelPinnedNoteSearch = null;

    initialize = (appointments) => {
        this.clear();
        this.appointments = Array.isArray(appointments) ? appointments : [];

        return this.refresh();
    }

    refresh = () => {
        const that = this;
        return new Promise((resolve, reject) => {
            const requests = [
                api.Notes.search(
                    {
                        parameters: [
                            {
                                field: 'ReferenceId',
                                operator: 'Contains',
                                value: Array.from(new Set([...that.appointments.map(a => { return a.id })])).join(','),
                            },
                            {
                                field: 'DeactivatedDateUtc',
                                value: null,
                            },
                        ],
                        sortByFields: [{
                            field: 'CreatedDateUtc',
                            direction: 'ASC',
                        }],
                    },
                    (c) => { that.cancelGroupNoteSearch = c }
                ),
                api.Notes.search(
                    {
                        parameters: [
                            {
                                field: 'CustomerId',
                                operator: 'Contains',
                                value: Array.from(new Set([...that.appointments.map(a => { return a.customerId })])).join(','),
                            },
                            {
                                field: 'PinnedDateUtc',
                                operator: '!=',
                                value: null,
                            },
                            {
                                field: 'DeactivatedDateUtc',
                                value: null,
                            },
                        ],
                        sortByFields: [{
                            field: 'CreatedDateUtc',
                            direction: 'ASC',
                        }],
                    },
                    (c) => { that.cancelPinnedNoteSearch = c }
                )];

            Promise.all(requests)
                .then(response => {
                    if (response) {
                        const newNotes = [];
                        const groupNoteData = response[0] && response[0].data && Array.isArray(response[0].data.result) ? response[0].data.result : [];
                        const pinnedNoteData = response[1] && response[1].data && Array.isArray(response[1].data.result) ? response[1].data.result : [];

                        for (let gn = 0; gn < groupNoteData.length; gn++) {
                            const groupNoteIndex = newNotes.findIndex(n => n.bodyHtml === groupNoteData[gn].bodyHtml && that.appointments.some(a => a.id === n.referenceId));

                            if (groupNoteIndex < 0) {
                                groupNoteData[gn].ids = [groupNoteData[gn].id];
                                groupNoteData[gn].customerIds = [groupNoteData[gn].customerId];
                                groupNoteData[gn].customers = [groupNoteData[gn].customer];
                                newNotes.push(groupNoteData[gn]);
                            }
                            else {
                                newNotes[groupNoteIndex].ids.push(groupNoteData[gn].id);
                                newNotes[groupNoteIndex].customerIds.push(groupNoteData[gn].customerId);
                                newNotes[groupNoteIndex].customers.push(groupNoteData[gn].customer);
                                newNotes[groupNoteIndex].ids = Array.from(new Set([...newNotes[groupNoteIndex].ids]));
                                newNotes[groupNoteIndex].customerIds = Array.from(new Set([...newNotes[groupNoteIndex].customerIds]));
                                newNotes[groupNoteIndex].customers = Array.from(new Set([...newNotes[groupNoteIndex].customers]));
                            }
                        }

                        for (let pn = 0; pn < pinnedNoteData.length; pn++) {
                            const pinnedNoteIndex = newNotes.findIndex(n => n.bodyHtml === pinnedNoteData[pn].bodyHtml && that.appointments.some(a => a.id === n.referenceId));

                            if (pinnedNoteIndex < 0) {
                                pinnedNoteData[pn].ids = [pinnedNoteData[pn].id];
                                pinnedNoteData[pn].customerIds = [pinnedNoteData[pn].customerId];
                                pinnedNoteData[pn].customers = [pinnedNoteData[pn].customer];
                                newNotes.push(pinnedNoteData[pn]);
                            }
                            else {
                                newNotes[pinnedNoteIndex].isPinned = newNotes[pinnedNoteIndex].isPinned || pinnedNoteData[pn].isPinned;
                                newNotes[pinnedNoteIndex].ids.push(pinnedNoteData[pn].id);
                                newNotes[pinnedNoteIndex].customerIds.push(pinnedNoteData[pn].customerId);
                                newNotes[pinnedNoteIndex].ids = Array.from(new Set([...newNotes[pinnedNoteIndex].ids]));
                                newNotes[pinnedNoteIndex].customerIds = Array.from(new Set([...newNotes[pinnedNoteIndex].customerIds]));
                                newNotes[pinnedNoteIndex].customers = Array.from(new Set([...newNotes[pinnedNoteIndex].customers]));
                            }
                        }

                        that.notes = newNotes.sort((a, b) => { return moment(a.createdDateUtc).isBefore(moment(b.createdDateUtc)) ? -1 : 1 });
                    }

                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isReady = true;
                    that.isLoading = false;
                })
        })
    }

    pin = (id, isPinned, notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            api.Notes.pin(id, { isPinned: isPinned }, (c) => { that.cancelNoteUpdate = c })
                .then(() => {
                    that.refresh();
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    update = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            if (!!that.updateNote) {
                const requests = [];

                for (let i = 0; i < that.updateNote.ids.length; i++) {
                    requests.push(
                        api.Notes.update(
                            that.updateNote.ids[i],
                            {
                                preview: that.updateNote.preview,
                                bodyHtml: that.updateNote.bodyHtml,
                            }, (c) => { that.cancelNoteUpdate = c }))
                }

                Promise.all(requests)
                    .then(() => {
                        if (that.notes && that.notes.length > 0) {
                            const index = that.notes.findIndex(n => n.id === that.updateNote.id);

                            if (index >= 0) {
                                that.notes[index] = toJS(that.updateNote);
                                that.updateNote = null;
                            }
                        }
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            }
            else {
                that.isSaving = false;
                resolve();
            }
        })
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            const requests = [];

            if (that.newNote.preview) {
                for (let ai = 0; ai < that.appointments.length; ai++) {
                    const option = JSON.parse(JSON.stringify(that.newNote));
                    const currentUser = JSON.parse(window.localStorage.getItem(CURRENT_USER));

                    option.noteType = 'Appointment';
                    option.referenceId = that.appointments[ai].id;
                    option.customerId = that.appointments[ai].customerId;
                    option.resource = currentUser;
                    option.createdDateUtc = moment.utc().toDate();

                    requests.push(api.Notes.create(toJS(option), (c) => { that.cancelNoteCreate = c }));
                }
            }

            if (that.removeNoteIds.length > 0) {
                for (let i = 0; i < that.removeNoteIds.length; i++) {
                    requests.push(api.Notes.delete(toJS(that.removeNoteIds[i]), (c) => { that.cancelNoteRemove = c }));
                }
            }

            if (requests.length > 0) {
                Promise.all(requests)
                    .then(() => {
                        that.clearNewNote();
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                that.isSaving = false;
                resolve();
            }
        })
    }

    clear = () => {
        this.appointments.clear();
        this.notes.clear();
        this.updateNote = null;
        this.removeNoteIds.clear();
        this.isLoading = false;
        this.isReady = false;

        this.clearNewNote();

        if (fn.isFunction(this.cancelAppointmentGet)) {
            this.cancelAppointmentGet();
            this.cancelAppointmentGet = null;
        }

        if (fn.isFunction(this.cancelCustomerGet)) {
            this.cancelCustomerGet();
            this.cancelCustomerGet = null;
        }

        if (fn.isFunction(this.cancelNoteCreate)) {
            this.cancelNoteCreate();
            this.cancelNoteCreate = null;
        }
    }

    clearNewNote = () => {
        this.newNote.bodyHtml = null;
        this.newNote.preview = null;
    }
}

decorate(GroupAppointmentNoteSearch, {
    appointments: observable,
    notes: observable,
    newNote: observable,
    updateNote: observable,
    removeNoteIds: observable,
    isLoading: observable,
    isReady: observable,
    initialize: action,
    refresh: action,
    update: action,
    save: action,
    clear: action,
    clearNewNote: action,
})

export default createContext(new GroupAppointmentNoteSearch());