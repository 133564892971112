import React, { useEffect, useContext, useState, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { GlobalHotKeys } from 'react-hotkeys';
import { useObserver } from 'mobx-react-lite';
import { toJS } from 'mobx';

import QuickDrawerHeader from '../../../../_shared/QuickDrawerHeader';
import { renderQuickDrawerLoading } from '../../../../_shared/QuickDrawer';

import WorkOrderTemplateViewStore from '../../../../../../stores/WorkOrderTemplateViewStore';
import WorkOrderInputUpdateStore from '../../../../../../stores/WorkOrderInputUpdateStore';
import QuickDrawerStore from '../../../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../../../constants/errorMessages';
import * as fn from '../../../../../../utilities/_functions';
import * as oh from '../../../../../../utilities/operationHelper';

import api from '../../../../../../api';

import './MapWorkOrderInput.scss';

function MapWorkOrderInput(props) {
    const isMounted = useRef(true);
    const workOrderTemplate = useContext(WorkOrderTemplateViewStore);
    const workOrderInput = useContext(WorkOrderInputUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [updated, setUpdated] = useState(false);
    const [productTypes, setProductTypes] = useState([]);
    const [dataSources, setDataSources] = useState([]);

    useEffect(() => {
        if (!productTypes || productTypes.length === 0) {
            api.ProductTypes.all()
                .then(({ data }) => {
                    if (isMounted.current) {
                        setProductTypes(data);
                    }
                })
        }

        setDataSources((workOrderTemplate.data.dataSources ? toJS(workOrderTemplate.data.dataSources) : []));

        return () => {
            isMounted.current = false;
            setDataSources([]);
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (updated) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleDataSourceChange = (event, { id }) => {
        const key = event.target.value;
        const newDataSources = dataSources;
        const index = newDataSources.findIndex(d => d.sourceId === id && d.workOrderInputId === workOrderInput.id);

        if (index < 0) {
            if (key) {
                setDataSources(dataSources => [...dataSources, {
                    source: 'ProductType',
                    sourceId: id,
                    workOrderInputId: workOrderInput.id,
                    key: key,
                }]);
            }
        } else {
            if (key) {
                setDataSources(dataSources.filter(d => !(d.sourceId === id && d.workOrderInputId === workOrderInput.id)));
                setDataSources(dataSources => [...dataSources, {
                    source: 'ProductType',
                    sourceId: id,
                    workOrderInputId: workOrderInput.id,
                    key: key,
                }]);
            } else {
                
            }
        }
        setUpdated(true)
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (props.onSuccess && fn.isFunction(props.onSuccess)) {
            props.onSuccess(event, { updated: updated, data: dataSources });
        }
    }

    const getDataSource = ({ id }) => {
        if (id) {
            if (dataSources.some(d => d.sourceId === id && d.workOrderInputId === workOrderInput.id)) {
                const key = dataSources.filter(d => d.sourceId === id && d.workOrderInputId === workOrderInput.id)[0].key
                return key;
            }
        }

        return '';
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form onSubmit={handleSubmit}>
            <fieldset>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('work-order-input', 'datasource')}
                        action='Order Input'
                        category='Data Source'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        {
                            workOrderTemplate.data && workOrderInput.data ?
                                <FadeIn>
                                    <div className='order-input-datasource-map body-content'>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label><small>Template</small></label>
                                                        <h5 className='mb-0'>{workOrderTemplate.data.name}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label><small>Input</small></label>
                                                        <h5 className='mb-0'>{workOrderInput.data.name}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group'>
                                                        <label htmlFor='order-input-datasource'><small>Data Source(s)</small></label>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                workOrderTemplate.data && workOrderTemplate.data.productTypes && workOrderTemplate.data.productTypes.length > 0 ?
                                                    <>
                                                        {
                                                            workOrderTemplate.data.productTypes.map((p, pi) => {
                                                                return <div
                                                                    key={`work-order_input_datasource_container_${pi}`}
                                                                    className='row mb-2'
                                                                >
                                                                    <div className='col-4'>
                                                                        <div className='text-truncate text-truncate-sm fw-500 py-2'>
                                                                            {p.name}
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-2'>
                                                                        <div className='text-center py-2'>
                                                                            <i className='fal fa-angle-double-right'></i>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-6'>
                                                                        <div className='form-group'>
                                                                            <select
                                                                                id={`order-input-datasource-${pi}`}
                                                                                className='custom-select form-control'
                                                                                value={getDataSource(p)}
                                                                                onChange={(e) => { handleDataSourceChange(e, p) }}
                                                                            >
                                                                                <option value=''></option>
                                                                                {
                                                                                    productTypes && productTypes.length > 0 ?
                                                                                        <>
                                                                                            {
                                                                                                productTypes.filter(t => t.id === p.id)[0].data.map((d, di) => {
                                                                                                    return <option key={`work-order_input_datasource_${pi}_${di}`} value={d.key}>{d.name}</option>
                                                                                                })
                                                                                            }
                                                                                        </> : null
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                    </> : null
                                            }
                                        </section>
                                    </div>
                                </FadeIn> : renderQuickDrawerLoading()
                        }
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>)
}

export default MapWorkOrderInput;