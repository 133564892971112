import React, { useContext, useEffect, useRef, useState, Component } from 'react';
import FadeIn from 'react-fade-in';
import { Observer } from 'mobx-react-lite';
import momentLocalizer from 'react-widgets-moment';
import { GlobalHotKeys } from 'react-hotkeys';
import moment from 'moment';
import DateBox from 'devextreme-react/date-box';
import CustomStore from 'devextreme/data/custom_store';
import { Multiselect } from 'react-widgets'
import { Mention } from 'devextreme-react/html-editor';

import { renderQuickDrawerLoading } from '../../_shared/QuickDrawer';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import HtmlEditor from '../../_shared/HtmlEditor';

import TodoCreateStore from '../../../../stores/TodoCreateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';
import AuthStore from '../../../../stores/AuthStore';
import CacheStore from '../../../../stores/CacheStore';

import * as rts  from '../../../../constants/routes';
import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as ph from '../../../../utilities/personHelper';
import * as uh from '../../../../utilities/userHelper';

import api from '../../../../api';

import './NewTodo.scss';

moment.locale('en');
momentLocalizer();

function NewTodo(props) {
    const USER_COLOR = '#00acc1';
    const GROUP_COLOR = '#fb8c00';

    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const assignedToRef = useRef(null);
    const assigneeHistoryRef = useRef([]);
    const editorRef = useRef(null);
    const todo = useContext(TodoCreateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const auth = useContext(AuthStore);
    const cache = useContext(CacheStore);
    const [selectedDate, setSelectedDate] = useState(null);
    const [dueChoice, setDueChoice] = useState('Asap');
    const [dueInterval, setDueInterval] = useState(15);
    const [dueIntervalType, setDueIntervalType] = useState('minutes');
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [allActiveUsersGroups, setAllActiveUsersGroups] = useState([]);
    const [canSelectEveryone, setCanSelectEveryone] = useState(false);

    const mapUsersGroupsToList = (src) => {
        const users = src.activeUsers.filter(u => u.id !== auth.currentUser.id && !u.isSystemUser).map(s => ({
            id: s.id,
            name: uh.getDisplayFullName(s),
            mention: uh.getDisplayShortName(s).replace(/[\W_]+/g, ''),
            colorHexValue: USER_COLOR,
            type: 'user'
        }));
        const groups = src.groups.map(s => ({
            id: s.id,
            name: `${s.name}`,
            mention: s.name.replace(/[\W_]+/g, ''),
            colorHexValue: GROUP_COLOR,
            type: 'group'
        }));

        return [...users.sort((a, b) => a.name.localeCompare(b.name)), ...groups.sort((a, b) => a.name.localeCompare(b.name))];
    }

    useEffect(() => {
        todo.initialize();

        setAllActiveUsersGroups(mapUsersGroupsToList(cache));
        setSelectedDate(moment().startOf('day').add(1, 'days').toDate());

        setTimeout(() => {
            editorRef.current.quillRef.current.instance.focus();
        }, 500)

        todo.hasUnsavedChanges = false;

        return () => {
            todo.clear();
            isMounted.current = false;
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (todo.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            const selectedUsersGroups = allActiveUsersGroups.filter(a => assignedToRef.current.getValues().some(v => v === a.id));

            todo.data.users = [];
            todo.data.groups = [];

            if (selectedUsersGroups && selectedUsersGroups.length > 0) {
                selectedUsersGroups.forEach(t => {
                    if (t.type === 'user') {
                        todo.data.users.push(t.id);
                    }
                    else { // groups
                        todo.data.groups.push(t.id);
                    }
                });
            }

            todo.data.due = dueChoice;

            switch (dueChoice) {
                case 'Asap':
                    todo.data.dueDateTimeUtc = null;
                    break;

                case 'Interval':
                    const newTime = moment.utc().add(dueInterval, dueIntervalType);

                    todo.data.dueValue = `${dueInterval} ${dueIntervalType}`;
                    todo.data.dueDateTimeUtc = newTime;
                    break;

                case 'Date':
                    const newDate = moment(selectedDate).startOf('day').utc();

                    todo.data.dueValue = newDate;
                    todo.data.dueDateTimeUtc = newDate;
                    break;

                default:
                    break;
            }

            const customerIds = todo.data.bodyHtml.match(/data-id="cus_([^"]+)"/g);

            if (customerIds && customerIds.length > 0) {
                for (let ci = 0; ci < customerIds.length; ci++) {
                    const index = todo.data.bodyHtml.indexOf(customerIds[ci]);

                    if (index >= 0) {
                        const start = todo.data.bodyHtml.substring(0, index).lastIndexOf('<span class="dx-mention"');
                        const end = todo.data.bodyHtml.indexOf('﻿</span>', index) + 8;

                        if (start > 0 && end > 0) {
                            const original = todo.data.bodyHtml.substring(start, end);
                            const id = original.match(/data-id="([^"]+)"/)[1];
                            const name = original.match(/<\/span>([^"]+)<\/span>﻿<\/span>/)[1];

                            if (id && name) {
                                const replace = `<a href="${rts.Customers.Home}/${id}">${name}</a>`;
                                todo.data.bodyHtml = todo.data.bodyHtml.substring(0, start) + replace + todo.data.bodyHtml.substring(end);
                            }
                        }
                    }
                }
            }

            const data = await todo.save(true);

            if (isMounted.current) {
                if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                    props.onSuccess(event, data);
                }
            }
        }
    }

    const handleIsSingleInstanceChange = (event) => {
        todo.data.isSingleInstance = (event.target.value === 'true');
        todo.hasUnsavedChanges = true;
    }

    const handleDueDateChoiceChange = async (event) => {
        setDueChoice(event.target.value);
        todo.hasUnsavedChanges = true;
    }

    const handleHighPriorityChange = (e) => {
        todo.data.isHighPriority = !todo.data.isHighPriority;
        todo.hasUnsavedChanges = true;
    }

    const handleBodyHtmlChange = (e) => {
        let html = e.value;
        const userDataIds = html.match(/data-id="usr_([^"]+)"/g);
        const userIds = userDataIds && userDataIds.some(u => u) ? userDataIds.map(u => { return u.split('"')[1] }) : [];
        const groupDataIds = html.match(/data-id="gr_([^"]+)"/g);
        const groupIds = groupDataIds && groupDataIds.some(g => g) ? groupDataIds.map(g => { return g.split('"')[1] }) : [];
        const customerIds = html.match(/data-id="cus_([^"]+)"/g);

        if (customerIds && customerIds.length > 0) {
            for (let ci = 0; ci < customerIds.length; ci++) {
                const index = html.indexOf(customerIds[ci]);
                const start = html.substring(0, index).lastIndexOf('<span class="dx-mention"');
                const end = html.indexOf('﻿</span>', index) + 8;
                const original = html.substring(start, end);
                const id = original.match(/data-id="([^"]+)"/)[1];
                const name = original.match(/<\/span>([^"]+)<\/span>﻿<\/span>/)[1];
                const replace = `<a href="${rts.Customers.Home}/${id}">${name}</a>`;

                html = html.substring(0, start) + replace + html.substring(end);
            }
        }

        todo.data.bodyHtml = html;

        if (userIds && userIds.length > 0) {
            const selectedUserValues = assignedToRef.current.getValues();
            const addUserIds = userIds.filter(u => !selectedUserValues.some(s => s === u));

            if (addUserIds && addUserIds.length > 0) {
                for (let a = 0; a < addUserIds.length; a++) {
                    const validUserGroups = allActiveUsersGroups;

                    if (validUserGroups.some(u => u.id === addUserIds[a]) && !assigneeHistoryRef.current.some(h => h === addUserIds[a])) {
                        assignedToRef.current.add(addUserIds[a]);
                        assigneeHistoryRef.current.push(addUserIds[a]);
                    }
                }
            }
        }

        if (groupIds && groupIds.length > 0) {
            const selectedGroupValues = assignedToRef.current.getValues();
            const addGroupIds = groupIds.filter(g => !selectedGroupValues.some(s => s === g));

            if (addGroupIds && addGroupIds.length > 0) {
                for (let a = 0; a < addGroupIds.length; a++) {
                    const validUserGroups = allActiveUsersGroups;

                    if (validUserGroups.some(u => u.id === addGroupIds[a]) && !assigneeHistoryRef.current.some(h => h === addGroupIds[a])) {
                        assignedToRef.current.add(addGroupIds[a]);
                        assigneeHistoryRef.current.push(addGroupIds[a]);
                    }
                }
            }
        }

        todo.hasUnsavedChanges = true;
    }

    const handleDueIntervalChange = (event) => {
        setDueInterval(event.target.value);
        todo.hasUnsavedChanges = true;
    }

    const handleDueIntervalTypeChange = (event) => {
        setDueIntervalType(event.target.value);
        todo.hasUnsavedChanges = true;
    }

    const handleDueDateChanged = (event) => {
        setSelectedDate(event.value);
        todo.hasUnsavedChanges = true;
    }

    const handleUserGroupSelection = (tag, metadata) => {
        let selected = assignedToRef.current.getValues();

        switch (metadata.action) {
            case 'insert':
                selected = assignedToRef.current.add(metadata.dataItem.id);
                break;

            case 'remove':
                selected = assignedToRef.current.remove(metadata.dataItem.id);
                break;

            default:
                break;
        }

        if (!selected || selected.length <= 1) {
            setCanSelectEveryone(false);
            todo.data.isSingleInstance = true;
        } else {
            setCanSelectEveryone(true);
        }

        todo.hasUnsavedChanges = true;
    }

    const getDueIntervalMaxValue = () => {
        switch (dueIntervalType) {
            case 'minutes':
                return 59;

            case 'hours':
                return 23;

            case 'days':
                return 30;

            default:
                return null;
        }
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <fieldset disabled={todo.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon='fal fa-tasks'
                        action='Add New'
                        category='Task'
                        className='new-todo'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        <Observer>{() =>
                            todo.isReady ?
                                <FadeIn>
                                    <div className='new-todo body-content'>
                                        <section>
                                            <div className='row pb-2'>
                                                <div className='col-12'>
                                                    <div className='form-group'>
                                                        <div className='custom-control custom-checkbox'>
                                                            <input
                                                                id={`new-todo-high-priority`}
                                                                type='checkbox'
                                                                name={`new-todo-high-priority`}
                                                                className='custom-control-input'
                                                                checked={!!todo.data.isHighPriority}
                                                                onChange={handleHighPriorityChange}
                                                            />
                                                            <label
                                                                htmlFor={`new-todo-high-priority`}
                                                                className={'custom-control-label' + (!!todo.data.isHighPriority ? ' text-danger' : '')}
                                                            >
                                                                High Priority
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group validate validate-required'>
                                                        <label className='required'><small>Description</small></label>
                                                        <div className='input-group'>
                                                            <Observer>{() =>
                                                                <>
                                                                    <HtmlEditor
                                                                        ref={editorRef}
                                                                        disableTab={true}
                                                                        onValueChanged={handleBodyHtmlChange}
                                                                    >
                                                                        <Mention
                                                                            marker={'@'}
                                                                            dataSource={allActiveUsersGroups}
                                                                            displayExpr={'mention'}
                                                                            valueExpr={'id'}
                                                                            searchExpr={['name', 'mention']}
                                                                            itemRender={(item) => {
                                                                                return item ? <>
                                                                                    <div
                                                                                        className='p-1'
                                                                                    >
                                                                                        <div className='fs-sm'><strong>{item.name}</strong></div>
                                                                                    </div>
                                                                                </> : null
                                                                            }}
                                                                        />
                                                                        <Mention
                                                                            marker={'#'}
                                                                            minSearchLength={2}
                                                                            dataSource={
                                                                                new CustomStore({
                                                                                    key: "id",
                                                                                    load: async (loadOptions) => {
                                                                                        const result = props && props.extraProps && props.extraProps.customer ? [props.extraProps.customer] : [];

                                                                                        if (loadOptions && loadOptions.searchValue && loadOptions.searchValue.length >= 2) {
                                                                                            const { data } = await api.Customers.fullSearch(loadOptions.searchValue, false, false, loadOptions.take, loadOptions.skip)
                                                                                            return [...result, ...data.result];
                                                                                        }
                                                                                        return result;
                                                                                    }
                                                                                })
                                                                            }
                                                                            displayExpr={'preferredFullName'}
                                                                            valueExpr={'id'}
                                                                            searchExpr={['preferredFullName', 'fullName']}
                                                                            itemRender={(item) => {
                                                                                return item ? <>
                                                                                    <div
                                                                                        className='p-1'
                                                                                    >
                                                                                        <div className='fs-sm'><strong>{ph.getLastFirstName(item, true)}</strong>
                                                                                            {
                                                                                                item.dateOfBirth || item.sex || item.gender || item.pronoun ?
                                                                                                    <small className='ml-1'>({`${ph.getAge(item.dateOfBirth)} ${ph.getSexGenderPronounDisplay(item)}`.trim()})</small> : null
                                                                                            }
                                                                                        </div>
                                                                                        <div className='fs-xs'>{item.address && item.address.addressLine1 ? item.address.addressLine1 : null}</div>
                                                                                    </div>
                                                                                </> : null
                                                                            }}
                                                                        />
                                                                    </HtmlEditor>
                                                                </>
                                                            }</Observer>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className={'form-group'}>
                                                        <label><small>Assign to </small></label>
                                                        <AssignedTo
                                                            ref={assignedToRef}
                                                            data={allActiveUsersGroups}
                                                            onChange={handleUserGroupSelection}
                                                        />
                                                        <div className='text-gray-500 fs-xs mt-2'>(Leave blank to assign to yourself)</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        {
                                            showAdvanced ?
                                                <>
                                                    <FadeIn>
                                                        <section className='mt-4'>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <Observer>{() =>
                                                                        <div className='form-group mb-0'>
                                                                            <label className='mb-0'><small>When</small></label>
                                                                            <div className='d-flex align-items-center mb-1'>
                                                                                <div className='mr-1'>
                                                                                    <div className='custom-control custom-radio'>
                                                                                        <input
                                                                                            id='due-asap'
                                                                                            type='radio'
                                                                                            name='dueDate'
                                                                                            value='Asap'
                                                                                            className='custom-control-input'
                                                                                            checked={dueChoice === 'Asap'}
                                                                                            onChange={handleDueDateChoiceChange}
                                                                                        />
                                                                                        <label htmlFor='due-asap' className='custom-control-label'>
                                                                                            As soon as possible
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='flex-1'>
                                                                                    <div className='form-group'>
                                                                                        <input
                                                                                            className='form-control d-invisible'
                                                                                            disabled={true}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex align-items-center mb-1'>
                                                                                <div className='due-option'>
                                                                                    <div className='custom-control custom-radio'>
                                                                                        <input
                                                                                            id='due-interval'
                                                                                            type='radio'
                                                                                            name='dueDate'
                                                                                            value='Interval'
                                                                                            className='custom-control-input'
                                                                                            checked={dueChoice === 'Interval'}
                                                                                            onChange={handleDueDateChoiceChange}
                                                                                        />
                                                                                        <label htmlFor='due-interval' className='custom-control-label'>
                                                                                            Due in
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='flex-1'>
                                                                                    <div className='form-group'>
                                                                                        <div className='input-group'>
                                                                                            <input
                                                                                                id='due-interval-value'
                                                                                                type='number'
                                                                                                className='form-control'
                                                                                                step={1}
                                                                                                min={1}
                                                                                                max={getDueIntervalMaxValue()}
                                                                                                autoComplete='off'
                                                                                                disabled={dueChoice !== 'Interval'}
                                                                                                value={dueInterval}
                                                                                                onChange={handleDueIntervalChange}
                                                                                            />
                                                                                            <select
                                                                                                className='custom-select form-control'
                                                                                                disabled={dueChoice !== 'Interval'}
                                                                                                value={dueIntervalType}
                                                                                                onChange={handleDueIntervalTypeChange}
                                                                                            >
                                                                                                <option value='minutes'>Minute(s)</option>
                                                                                                <option value='hours'>Hour(s)</option>
                                                                                                <option value='days'>Day(s)</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex align-items-center mb-1'>
                                                                                <div className='due-option'>
                                                                                    <div className='custom-control custom-radio'>
                                                                                        <input
                                                                                            id='due-date'
                                                                                            type='radio'
                                                                                            name='dueDate'
                                                                                            className='custom-control-input'
                                                                                            checked={dueChoice === 'Date'}
                                                                                            value='Date'
                                                                                            onChange={handleDueDateChoiceChange}
                                                                                        />
                                                                                        <label htmlFor='due-date' className='custom-control-label'>
                                                                                            Due on
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='flex-1'>
                                                                                    <div className='form-group'>
                                                                                        <DateBox
                                                                                            type='date'
                                                                                            disabled={dueChoice !== 'Date'}
                                                                                            displayFormat={'yyyy/MM/dd'}
                                                                                            useMaskBehavior={true}
                                                                                            min={moment().startOf('day').add(1, 'days').toDate()}
                                                                                            defaultValue={selectedDate}
                                                                                            value={selectedDate}
                                                                                            onValueChanged={handleDueDateChanged}
                                                                                        >
                                                                                        </DateBox>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }</Observer>
                                                                </div>
                                                            </div>
                                                        </section>
                                                        {
                                                            canSelectEveryone ?
                                                                <section className='mt-4'>
                                                                    <div className='row'>
                                                                        <div className='col-12'>
                                                                            <Observer>{() =>
                                                                                <div className='form-group mb-0'>
                                                                                    <label className='mb-2'><small>To be completed by</small></label>
                                                                                    <div className='custom-control custom-radio mb-2'>
                                                                                        <input
                                                                                            id='isOneTaskOnly'
                                                                                            type='radio'
                                                                                            name='isSingleInstance'
                                                                                            value='true'
                                                                                            className='custom-control-input'
                                                                                            checked={todo.data.isSingleInstance}
                                                                                            onChange={handleIsSingleInstanceChange}
                                                                                        />
                                                                                        <label htmlFor='isOneTaskOnly' className='custom-control-label mb-2'>
                                                                                            Anyone that was assigned
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className='custom-control custom-radio mb-2'>
                                                                                        <input
                                                                                            id='isOneTaskPerUser'
                                                                                            type='radio'
                                                                                            name='isSingleInstance'
                                                                                            value='false'
                                                                                            className='custom-control-input'
                                                                                            checked={!todo.data.isSingleInstance}
                                                                                            onChange={handleIsSingleInstanceChange}
                                                                                        />
                                                                                        <label htmlFor='isOneTaskPerUser' className='custom-control-label mb-2'>
                                                                                            Everyone that were assigned
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            }</Observer>
                                                                        </div>
                                                                    </div>
                                                                </section> : null
                                                        }
                                                    </FadeIn>
                                                </> :
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='mt-2 pt-4 border-top'>
                                                                <button type='button' className='btn btn-link btn-sm p-0' onClick={() => { setShowAdvanced(true) }}>&raquo;	Show advanced options</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                        }
                                    </div>
                                </FadeIn> : renderQuickDrawerLoading()
                        }</Observer>
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>
}

class AssignedTo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedValue: []
        }
    }

    hasValues = () => {
        return this.state.selectedValue && this.state.selectedValue.length > 0;
    }

    count = () => {
        return this.state.selectedValue ? this.state.selectedValue.length : 0;
    }

    getValues = () => {
        return this.state.selectedValue ? [...this.state.selectedValue] : [];
    }

    add = (id) => {
        const newSelectedValue = [...this.state.selectedValue];
        newSelectedValue.push(id);
        this.setState({ selectedValue: newSelectedValue });

        return newSelectedValue;
    }

    remove = (id) => {
        const newSelectedValue = [...this.state.selectedValue];
        const index = newSelectedValue.findIndex(v => v === id);
        newSelectedValue.splice(index, 1);
        this.setState({ selectedValue: newSelectedValue });

        return newSelectedValue;
    }

    render() {
        return <Multiselect
            allowCreate={false}
            data={this.props.data}
            valueField='id'
            textField='name'
            defaultValue={[]}
            value={this.state.selectedValue}
            tagComponent={({ item }) => (
                <span
                    className='tag'
                    style={{
                        backgroundColor: item.colorHexValue,
                        borderColor: item.colorHexValue,
                    }}
                >
                    <strong>{item.name}</strong>
                </span>
            )}
            onChange={this.props.onChange}
        />
    }
}

export default NewTodo;

