import { createContext } from 'react';
import uuid from 'react-uuid';
import { decorate, observable, action, toJS } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class PretestInputCreate {
    id = null;
    data = {
        key: null,
        name: null,
        descriptionHtml: null,
        label: null,
        type: null,
        metadata: null,
        pretestTemplateId: null,
    };
    isReady = false;
    isSaving = false;
    hasUnsavedChanges = false;

    cancelPretestInputCreate = null;

    initialize = (pretestTemplateId) => {
        this.clear();
        this.id = uuid();
        this.data.pretestTemplateId = pretestTemplateId;
        this.isReady = true;
    }

    setMetadata = (key, value) => {
        let metadata = this.data.metadata;

        if (!metadata) {
            metadata = {};
        }

        if (!metadata.hasOwnProperty(key)) {
            metadata = { ...metadata, ...{ [key]: value } };
        } else {
            metadata[key] = value;
        }

        this.data.metadata = metadata;
    }

    removeMetadata = (key) => {
        let metadata = this.data.metadata;

        if (metadata && metadata.hasOwnProperty(key)) {
            delete metadata[key];
        }
    }

    getMetadata = (key) => {
        if (!this.data.metadata) return undefined;
        return this.data.metadata[key];
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            let option = toJS(that.data);

            if (that.hasUnsavedChanges) {
                api.PretestInputs.create(option, (c) => { that.cancelPretestInputCreate = c })
                    .then(({ data }) => {
                        that.hasUnsavedChanges = false;
                        that.id = data.id;
                        option.id = data.id;
                        resolve(option);
                    })
                    .catch(() => {
                        reject();
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                that.hasUnsavedChanges = false;
                that.isSaving = false;
                resolve(option);
            }
        })
    }

    clear = () => {
        this.id = null;
        this.data.key = null;
        this.data.name = null;
        this.data.descriptionHtml = null;
        this.data.label = null;
        this.data.type = null;
        this.data.metadata = null;
        this.data.pretestTemplateId = null;
        this.isReady = false;
        this.isSaving = false;
        this.hasUnsavedChanges = false;

        if (fn.isFunction(this.cancelPretestInputCreate)) {
            this.cancelPretestInputCreate();
            this.cancelPretestInputCreate = null;
        }
    }
}

decorate(PretestInputCreate, {
    id: observable,
    data: observable.deep,
    isReady: observable,
    isSaving: observable,
    hasUnsavedChanges: observable,
    initialize: action,
    setMetadata: action,
    removeMetadata: action,
    getMetadata: action,
    save: action,
    clear: action,
})

export default createContext(new PretestInputCreate());