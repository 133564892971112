import React, { useEffect, useContext, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import FadeIn from 'react-fade-in';
import { GlobalHotKeys } from 'react-hotkeys';
import { toast } from 'react-toastify';
import { evaluate } from 'mathjs';
import moment from 'moment';

import LoadingOverlay from '../../_shared/LoadingOverlay'
import TemplateGrid from '../../_shared/TemplateGrid';

import PretestUpdateStore from '../../../../stores/PretestUpdateStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as th from '../../../../utilities/templateHelper';
import * as tih from '../../../../utilities/templateInputHelper';
import * as tch from '../../../../utilities/templateControlHelper';
import * as mth from '../../../../utilities/modalTemplateHelper';
import * as mh from '../../../../utilities/mentionHelper';

function UpdatePretest(props) {
    const isMounted = useRef(true);
    const renderTimer = useRef(null);
    const modalBodyRef = useRef(null);
    const pretest = useContext(PretestUpdateStore);

    useEffect(() => {
        return () => {
            isMounted.current = false;
            if (renderTimer.current) { clearTimeout(renderTimer.current); renderTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleSave = event => {
        if (!pretest.data.pretestData || pretest.data.pretestData.filter(d => !d.id.startsWith('__')).length === 0) {
            toast.error(() => ErrorMessages.GENERIC_FORM_ERROR_HTML, { position: 'top-center', style: { width: '380px' } });
        } else {
            pretest.hasUnsavedChanges = true;
            pretest.save()
                .then(() => {
                    if (isMounted.current) {
                        props.onSuccess(event, { updated: true });
                    }
                })
        }
    }

    const handleClose = event => {
        if (fn.isFunction(props.onClose)) {
            if (pretest.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    pretest.hasUnsavedChanges = false;
                    props.onClose(event);
                }
            } else {
                props.onClose(event);
            }
        }
    }

    const renderPretestTemplateContent = () => {
        if (isMounted.current) {
            if (pretest.data.template && pretest.data.template.definition && pretest.data.template.definition.length > 0) {
                return pretest.data.template.definition.map((o) => {
                    return <div
                        key={o.id}
                        data-grid={o.position}
                    >
                        {
                            isMounted.current ?
                                <>
                                    {
                                        mth.isModalElement(o) ? mth.renderElement(o, {
                                            defaultValue: null,
                                            modalSettings: pretest.data.template.modalSettings,
                                        }) : null
                                    }
                                    {
                                        tch.isControlElement(o) ? <Observer>{() =>
                                            tch.renderControl(o, {
                                                readOnly: false,
                                                value: getControlValue(o),
                                                onClick: handleControlClick
                                            })
                                        }</Observer> : null
                                    }
                                    {
                                        tih.isInputElement(o) ? <Observer>{() =>
                                            tih.renderInput(o, {
                                                readOnly: false,
                                                value: getValue(o.key),
                                                onChange: handleDataChange(o),
                                                onBlur: handleInputFormatting,
                                            })
                                        }</Observer> : null
                                    }
                                </> : null
                        }
                    </div>
                })
            }
        }
    }

    const handleDataChange = ({ type }) => {
        switch (type) {
            case 'SingleLineText':
            case 'MultiLineText':
            case 'Dropdown':
            case 'Date':
            case 'Time':
                return handleInputChange;

            // case 'MultiLineText':
            case 'RichText':
                return handleRichTextEditorChange;

            case 'Radio':
                return handleRadioChange;

            case 'Checkbox':
                return handleCheckboxChange;

            default:
                return () => { };
        }
    }

    const handleInputFormatting = (event, key, input) => {
        if (!key || !input) return;

        const value = getValue(key);
        applyInputFormatting(key, value, input.metadata);
    }

    const handleInputChange = (event, key) => {
        const value = event.target.value;
        setValue(key, value);
    }

    const handleRichTextEditorChange = (content, key) => {
        const html = content === '<p><br></p>' ? null : content;
        setValue(key, html);
    }

    const handleRadioChange = (event, key) => {
        const value = event.target.value;
        const originalValue = getValue(key);

        if (value === originalValue) {
            setValue(key, null);
        } else {
            setValue(key, value);
        }
    }

    const handleCheckboxChange = (event, key) => {
        const value = event.target.value;
        const originalValue = getValue(key);
        const originalArray = originalValue ? originalValue.split('|') : [];
        const index = originalArray.findIndex(o => o === value);

        if (index >= 0) {
            originalArray.splice(index, 1);
        } else {
            originalArray.push(value);
        }

        setValue(key, originalArray.join('|'));
    }

    const handleControlClick = (event, control) => {
        switch (control.type) {
            case tch.TEMPLATE_CONTROL_TIMESTAMP_BUTTON:
                if (control.metadata.timestamp) {
                    const variables = mh.parseVariables(control.metadata.timestamp);

                    if (variables && variables.length > 0) {
                        for (let i = 0; i < variables.length; i++) {
                            if (variables[i] && variables[i].key) {
                                setValue(variables[i].key, moment().format('h:mm a'));
                            }
                        }
                    }
                }
                break;

            default:
                break;
        }
    }

    const getValue = (key) => {
        const data = pretest.data.pretestData.filter(d => d.id === key);
        if (data && data.length > 0) {
            return data[0].value;
        }

        return null;
    }

    const getControlValue = control => {
        let value = '';

        switch (control.type) {
            case tch.TEMPLATE_CONTROL_CALCULATION_TEXTBOX:
                let equation = control.metadata.equation;
                const variables = mh.parseVariables(equation);

                if (variables && variables.length > 0) {
                    for (let i = 0; i < variables.length; i++) {
                        const data = pretest.data.pretestData.filter(d => d.id === variables[i].key)[0];
                        equation = data ? equation.replace(variables[i].markup, data.value) : '';
                    }

                    try {
                        value = Math.round((evaluate(equation) + Number.EPSILON) * 10000) / 10000;
                    } catch {
                        value = '';
                    }
                }
                return value;

            default:
                return value;
        }
    }

    const setValue = (key, value) => {
        const index = pretest.data.pretestData.findIndex(d => d.id === key);

        if (index >= 0) {
            if (value) {
                pretest.data.pretestData[index].value = value;
            } else {
                pretest.data.pretestData.splice(index, 1);
            }
        } else {
            pretest.data.pretestData.push({ id: key, value: value });
        }

        pretest.hasUnsavedChanges = true;
    }

    const setGridRendered = () => {
        if (pretest.data.template) {
            renderTimer.current = setTimeout(() => {
                th.rerenderTemplate();
            }, 500)
        }
    }

    const getTemplateCols = () => {
        let grid;

        if (pretest.data.template.modalSettings && pretest.data.template.modalSettings.size && pretest.data.template.modalSettings.size.grid) {
            grid = pretest.data.template.modalSettings.size.grid;
        } else {
            grid = mth.getSizeDimension('medium').grid;
        }

        return { lg: grid, md: grid, sm: grid, xs: grid, xxs: grid }
    }

    const getTemplateGridStyle = () => {
        if (pretest.data.template) {
            const { modalSettings } = pretest.data.template;

            if (modalSettings) {
                const size = modalSettings.size ? modalSettings.size : mth.getSizeDimension('medium');
                const margins = modalSettings.margins ? modalSettings.margins : mth.MODAL_TEMPLATE_MARGINS;

                return {
                    width: `${size.width}px`,
                    padding: `${margins}px`,
                }
            }
        }

        return {
            width: `${mth.getSizeDimension('medium').width}px`,
            height: `${mth.getSizeDimension('medium').height}px`,
            padding: `${mth.MODAL_TEMPLATE_MARGINS / 2}px`,
        }
    }

    const applyInputFormatting = (key, value, metadata) => {
        if (!!key && !!metadata) {
            let updatedValue = value;
            const isDataTypeValid = tih.validateDataType(metadata, updatedValue);

            if (isDataTypeValid) {
                updatedValue = tih.applyPadding(metadata, updatedValue);
                updatedValue = tih.applyFormat(metadata, updatedValue);
            }

            if (value !== updatedValue) {
                setValue(key, updatedValue);
                return true;
            }
        }

        return false;
    }

    return <>
        <Observer>{() =>
            <>
                {
                    props.display && props.mode.toLowerCase() === 'view' ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleClose(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <Observer>{() =>
            pretest.isReady ?
                <div
                    className={'modal-dialog animated fastest' + (props.ready ? ' zoomIn' : ' zoomOut')}
                    role='document'
                    style={{
                        width: `${pretest.data.template.modalSettings.size.width}px`,
                        maxWidth: `${pretest.data.template.modalSettings.size.width}px`
                    }}
                >
                    <div className='modal-content'>
                        <Observer>{() => <LoadingOverlay isLoading={pretest.isSaving} />}</Observer>
                        <div className='modal-header'>
                            <div className='actions right-actions'>
                                <ul>
                                    <li>
                                        <button
                                            data-pretest-save
                                            type='button'
                                            className='btn btn-success px-3'
                                            onClick={handleSave}
                                        >
                                            Save
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            type='button'
                                            className='btn btn-icon btn-close'
                                            onClick={handleClose}
                                        >
                                            <i className='close-icon fal fa-times fs-xl'></i>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div ref={modalBodyRef} className='modal-body p-0'>
                            <FadeIn>
                                <div className='pretest-header'>
                                    <div className='pretest-description'>
                                        <Observer>{() =>
                                            <div
                                                className='form-template form-template-internal'
                                                style={getTemplateGridStyle()}
                                            >
                                                <h3 className='py-0 px-1'>{pretest.data.template.name}</h3>
                                                <TemplateGrid
                                                    isEditable={false}
                                                    cols={getTemplateCols()}
                                                    rowHeight={mth.MODAL_TEMPLATE_ROW_HEIGHT}
                                                    margin={[0, 0]}
                                                >
                                                    {renderPretestTemplateContent()}
                                                </TemplateGrid>
                                            </div>
                                        }</Observer>
                                        {setGridRendered()}
                                    </div>
                                </div>
                            </FadeIn>
                        </div>
                    </div>
                </div> : null
        }</Observer>
    </>
}

export default UpdatePretest;