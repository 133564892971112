import React, { useContext, useEffect, useRef, useState } from 'react';
import { Observer } from 'mobx-react-lite';
import FadeIn from 'react-fade-in';
import { GlobalHotKeys } from 'react-hotkeys';

import BodyEnd from '../../_shared/BodyEnd';
import ConfirmModal from '../../_shared/ConfirmModal';
import RichTextEditor from '../../_shared/RichTextEditor';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';

import ExamViewStore from '../../../../stores/ExamViewStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as fn from '../../../../utilities/_functions';
import * as ErrorMessages from '../../../../constants/errorMessages';

import './ReopenExam.scss';

function ReopenExam(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const viewExam = useContext(ExamViewStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [confirmReopenExam, setConfirmReopenExam] = useState(false);

    useEffect(() => {
        quickDrawerFocus(props.drawer);

        return () => {
            isMounted.current = false;
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (viewExam.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            setConfirmReopenExam(true);
        }
    }

    const handleReasonChange = (content, delta, source, editor) => {
        const html = (content === '<p><br></p>') ? null : content;

        viewExam.reopenReason = html;
        viewExam.hasUnsavedChanges = true;
    }

    const handleConfirmReopenExam = event => {
        if (isMounted.current) {
            if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                props.onSuccess(event, { updated: true });
            }
            setConfirmReopenExam(false);
        }
    }

    const handleConfirmCancelReopenExam = event => {
        if (isMounted.current) {
            setConfirmReopenExam(false);

            if (fn.isFunction(props.onCancel)) {
                props.onCancel();
            }
        }
    }

    return <>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <Observer>{() =>
                <>
                    {
                        (props.drawer === quickDrawer.drawerOpened) ?
                            <GlobalHotKeys
                                keyMap={{
                                    close: ['esc'],
                                }}
                                handlers={{
                                    close: event => {
                                        handleCancel(event)
                                    },
                                }}
                                allowChanges={true}
                            /> : null
                    }
                    <fieldset>
                        <div className='quick-drawer'>
                            <QuickDrawerHeader
                                drawer={props.drawer}
                                icon={'fal fa-clipboard-list-check'}
                                action='Reopen'
                                category='Exam'
                                className='delete'
                                onCancel={handleCancel}
                            />
                            <div className='quick-drawer-body'>
                                {
                                    viewExam.isReady ?
                                        <FadeIn>
                                            <div className='reopen-exam body-content'>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group validate validate-required'>
                                                                <label className='required'><small>Reopen Reason</small></label>
                                                                <div className='input-group'>
                                                                    <Observer>{() =>
                                                                        <RichTextEditor
                                                                            mode='none'
                                                                            value={viewExam.reopenReason}
                                                                            disableTab={true}
                                                                            onChange={handleReasonChange}
                                                                        />
                                                                    }</Observer>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </FadeIn> : renderQuickDrawerLoading()
                                }
                            </div>
                            <Observer>{() =>
                                viewExam.isReady ?
                                    <div className='quick-drawer-action'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='float-right'>
                                                    <button
                                                        type='button'
                                                        className='btn btn-link btn-cancel mr-2'
                                                        onClick={handleCancel}
                                                    >Cancel</button>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-danger'
                                                    >Continue</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : null
                            }</Observer>
                        </div>
                    </fieldset>
                </>
            }</Observer>
        </form>
        <BodyEnd>
            <Observer>{() =>
                <ConfirmModal
                    icon={<i className='fal fa-clipboard-list-check text-danger mr-2'></i>}
                    message={<>
                        {
                            confirmReopenExam ?
                                <>Continue to <span className='fw-500'>&nbsp;reopen the exam</span>?</> :
                                <></>
                        }
                    </>}
                    show={confirmReopenExam}
                    onOption1Click={handleConfirmReopenExam}
                    onCancel={handleConfirmCancelReopenExam}
                />
            }</Observer>
        </BodyEnd>
    </>
}

export default ReopenExam;