import { createContext } from 'react';
import { decorate, observable, action, toJS } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class MedicalReportTemplateUpdate {
    id = null;
    data = null;
    isReady = false;
    isSaving = false;
    isLoading = false;
    hasUnsavedChanges = false;

    cancelMedicalReportPublishedTemplateCreate = null;
    cancelMedicalReportTemplateGet = null;
    cancelMedicalReportTemplateUpdate = null;

    initialize = id => {
        this.clear();
        this.id = id;

        return this.refresh();
    }

    refresh = () => {
        const that = this;
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            api.MedicalReportTemplates.get((c) => { that.cancelMedicalReportTemplateGet = c })
                .then(({ data }) => {
                    that.data = data;
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isLoading = false;
                    that.isReady = true;
                })
        })
    }

    sortDefinition = () => {
        if (this.data) {
            if (this.data.headerDefinition && this.data.headerDefinition.length > 0) {
                const headerDefinition = toJS(this.data.headerDefinition)
                    .sort((a, b) => {
                        if (a.position.y === b.position.y) {
                            return a.position.x > b.position.x ? 1 : -1;
                        }

                        return a.position.y > b.position.y ? 1 : -1;
                    })

                this.data.headerDefinition = headerDefinition;
            }
            if (this.data.bodyDefinition && this.data.bodyDefinition.length > 0) {
                const bodyDefinition = toJS(this.data.bodyDefinition)
                    .sort((a, b) => {
                        if (a.position.y === b.position.y) {
                            return a.position.x > b.position.x ? 1 : -1;
                        }

                        return a.position.y > b.position.y ? 1 : -1;
                    })

                this.data.bodyDefinition = bodyDefinition;
            }
            if (this.data.footerDefinition && this.data.footerDefinition.length > 0) {
                const footerDefinition = toJS(this.data.footerDefinition)
                    .sort((a, b) => {
                        if (a.position.y === b.position.y) {
                            return a.position.x > b.position.x ? 1 : -1;
                        }

                        return a.position.y > b.position.y ? 1 : -1;
                    })

                this.data.footerDefinition = footerDefinition;
            }
        }
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            let option = toJS(that.data);

            if (that.hasUnsavedChanges) {
                that.sortDefinition();

                api.MedicalReportTemplates.update(option, (c) => { that.cancelMedicalReportTemplateUpdate = c })
                    .then(() => {
                        that.hasUnsavedChanges = false;
                        resolve(option);
                    })
                    .catch(() => {
                        reject();
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                resolve(option);
            }
        })
    }

    publish = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            api.MedicalReportPublishedTemplates.create({ referralTemplateId: that.id }, (c) => { that.cancelMedicalReportPublishedTemplateCreate = c })
                .then(() => {
                    that.hasUnsavedChanges = false;
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }


    clear = () => {
        this.id = null;
        this.data = null;
        this.isReady = false;
        this.isSaving = false;
        this.isLoading = false;
        this.hasUnsavedChanges = false;

        if (fn.isFunction(this.cancelMedicalReportPublishedTemplateCreate)) {
            this.cancelMedicalReportPublishedTemplateCreate();
            this.cancelMedicalReportPublishedTemplateCreate = null;
        }

        if (fn.isFunction(this.cancelMedicalReportTemplateGet)) {
            this.cancelMedicalReportTemplateGet();
            this.cancelMedicalReportTemplateGet = null;
        }

        if (fn.isFunction(this.cancelMedicalReportTemplateUpdate)) {
            this.cancelMedicalReportTemplateUpdate();
            this.cancelMedicalReportTemplateUpdate = null;
        }
    }
}

decorate(MedicalReportTemplateUpdate, {
    id: observable,
    data: observable.deep,
    isReady: observable,
    isSaving: observable,
    isLoading: observable,
    hasUnsavedChanges: observable,
    initialize: action,
    refresh: action,
    save: action,
    clear: action,
})

export default createContext(new MedicalReportTemplateUpdate());