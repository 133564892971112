import React, { useContext, useEffect, useRef, useState } from 'react';
import FadeIn from 'react-fade-in';
import { Observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import { DropdownList } from 'react-widgets'
import { GlobalHotKeys } from 'react-hotkeys';
import momentLocalizer from 'react-widgets-moment';
import moment from 'moment';

import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';

import ProductBrandCreateStore from '../../../../stores/ProductBrandCreateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as oh from '../../../../utilities/operationHelper';

import api from '../../../../api';

import './NewProductBrand.scss';

moment.locale('en');
momentLocalizer();

function NewProductBrand(props) {
    const isMounted = useRef(true);
    const productBrandRef = useRef(null);
    const validateRef = useRef(null);
    const cancelProductDeleteSearch = useRef(null);
    const productBrand = useContext(ProductBrandCreateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [isProductBrandChecked, setIsProductBrandChecked] = useState(false);

    useEffect(() => {
        quickDrawerFocus(props.drawer);

        if (props && props.extraProps) {
            if (props.extraProps.isProductBrandChecked) {
                setIsProductBrandChecked(props.extraProps.isProductBrandChecked);
            }
        }

        return () => {
            isMounted.current = false;
            if (cancelProductDeleteSearch.current && fn.isFunction(cancelProductDeleteSearch.current)) { cancelProductDeleteSearch.current(); cancelProductDeleteSearch.current = null; }
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (productBrand.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleNameChange = event => {
        productBrand.data.name = event.target.value;
        productBrand.hasUnsavedChanges = true;
    }

    const handleCheckProductBrand = event => {
        const { name } = productBrand.data;
        const searchName = name ? name.trim() : null;

        if (!isProductBrandChecked) {
            if (searchName) {
                productBrand.load(searchName)
                    .then(() => {
                        setIsProductBrandChecked(true);
                    })
            }
        }
    }

    const handleTypeChange = event => {
        var id = event.target.value;
        var index = productBrand.data.typeIds.findIndex(t => t === id);

        if (index > -1) {
            productBrand.data.typeIds.splice(index, 1);
        } else {
            productBrand.data.typeIds.push(id);
        }

        productBrand.hasUnsavedChanges = true;
    }

    const handleSupplierChange = ({ id }) => {
        productBrand.data.supplierId = id;
        productBrand.hasUnsavedChanges = true;
    }

    const handleDelete = event => {
        api.Products.search(
            {
                parameters: [{
                    field: 'BrandId',
                    operator: 'Contains',
                    value: productBrand.data.ids.join(','),
                }, {
                    field: 'DeactivatedDateUtc',
                    value: null,
                }],
                includeTotalCount: true,
                includeResult: false,
            },
            (c) => { cancelProductDeleteSearch.current = c })
            .then(({ data }) => {
                if (isMounted.current) {
                    if (data && data.total > 0) {
                        toast.error(() => <p><strong>Cannot delete.</strong> There are still product(s) associated to {productBrand.data.name}.</p>, { position: 'top-center', style: { width: '380px' } });
                    } else {
                        productBrand.delete()
                            .then(() => {
                                if (isMounted.current) {
                                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                                        props.onSuccess(event, { isUpdated: true });
                                    }
                                }
                            })
                    }
                }
            })
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            if (isMounted.current) {
                const updated = productBrand.hasUnsavedChanges;

                if (updated) {
                    const removedTypeIds = productBrand.originalData && productBrand.originalData.typeIds ? productBrand.originalData.typeIds.filter(t => !productBrand.data.typeIds.some(i => i === t)) : null;

                    if (removedTypeIds && removedTypeIds.length > 0) {
                        api.Products.search(
                            {
                                parameters: [{
                                    field: 'BrandId',
                                    operator: 'Contains',
                                    value: productBrand.data.ids.join(','),
                                },{
                                    field: 'TypeId',
                                    operator: 'Contains',
                                    value: removedTypeIds.join(','),
                                }, {
                                    field: 'DeactivatedDateUtc',
                                    value: null,
                                }],
                                includeTotalCount: true,
                                includeResult: false,
                            },
                            (c) => { cancelProductDeleteSearch.current = c })
                            .then(({ data }) => {
                                if (isMounted.current) {
                                    if (data && data.total > 0) {
                                        toast.error(() => <p><strong>Cannot update.</strong> There are still product(s) associated to the removed categories.</p>, { position: 'top-center', style: { width: '380px' } });
                                    } else {
                                        handleSave(event, updated);
                                    }
                                }
                            })
                    } else {
                        handleSave(event, updated);
                    }
                } else {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, { isUpdated: false, data: null });
                    }
                }
            }
        }
    }

    const handleSave = (event, updated) => {
        productBrand.save(true)
            .then(data => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, { isUpdated: updated, data: data });
                    }
                }
            })
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <fieldset disabled={productBrand.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('product', 'brand')}
                        action={productBrand.data.id ? 'Update' : 'Add New'}
                        category='Product Brand'
                        className='products'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        <Observer>{() => <>
                            {
                                productBrand.isReady ?
                                    <FadeIn>
                                        <div className='new-product-brand body-content'>
                                            <Observer>{() =>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div ref={productBrandRef} className='form-group mb-0 validate validate-required'>
                                                                <label htmlFor='new-product-brand-name-input' className='required'><small>Name</small></label>
                                                                <input
                                                                    id='new-product-brand-name-input'
                                                                    type='text'
                                                                    className='form-control'
                                                                    spellCheck={false}
                                                                    maxLength='150'
                                                                    autoComplete='off'
                                                                    value={productBrand.data && productBrand.data.name ? productBrand.data.name : ''}
                                                                    onChange={handleNameChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            }</Observer>
                                            <Observer>{() =>
                                                <>
                                                    {
                                                        isProductBrandChecked ? <>
                                                            <Observer>{() =>
                                                                <section className='mt-4'>
                                                                    <div className='row'>
                                                                        <div className='col-12'>
                                                                            <div className='form-group mb-0 validate validate-required'>
                                                                                <label htmlFor='new-product-brand-supplier-input' className='required'><small>Supplier</small></label>
                                                                                <DropdownList
                                                                                    data-supplier-list-dropdown
                                                                                    data={productBrand.suppliers}
                                                                                    valueField='id'
                                                                                    value={productBrand.data.supplierId}
                                                                                    itemComponent={({ item }) => (
                                                                                        item ? <>{item.name}</> : <></>
                                                                                    )}
                                                                                    valueComponent={({ item }) => (
                                                                                        item ? <>{item.name}</> : <></>
                                                                                    )}
                                                                                    onChange={handleSupplierChange}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                            }</Observer>
                                                            <Observer>{() =>
                                                                <section className='mt-4'>
                                                                    <div className='row'>
                                                                        <div className='col-12'>
                                                                            <div className='form-group mb-0 validate validate-required'>
                                                                                <label htmlFor='new-product-brand-type-input' className='required'><small>Categories</small></label>
                                                                                {
                                                                                    productBrand.types && productBrand.types.length > 0 ?
                                                                                        productBrand.types.map((t, ti) => {
                                                                                            return <div
                                                                                                key={`product_brand_category_${ti}`}
                                                                                                className='custom-control custom-checkbox mb-1'
                                                                                            >
                                                                                                <input
                                                                                                    id={`product-brand-category-${ti}`}
                                                                                                    type='checkbox'
                                                                                                    className='custom-control-input'
                                                                                                    value={t.id}
                                                                                                    checked={productBrand.data.typeIds && productBrand.data.typeIds.some(i => i === t.id)}
                                                                                                    onChange={(e) => { handleTypeChange(e, t) }}
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`product-brand-category-${ti}`}
                                                                                                    className='custom-control-label d-block'
                                                                                                >
                                                                                                    {t.name}
                                                                                                </label>
                                                                                            </div>
                                                                                        }) : null
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                            }</Observer>
                                                        </> : null
                                                    }
                                                </>
                                            }</Observer>
                                        </div>
                                    </FadeIn> : renderQuickDrawerLoading()
                            }
                        </>
                        }</Observer>
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    {
                                        productBrand.data.id ?
                                            <button
                                                type='button'
                                                className='btn btn-danger mr-2'
                                                onClick={handleDelete}
                                            >
                                                Delete
                                            </button> :
                                            <button
                                                type='button'
                                                className='btn btn-link btn-cancel mr-2'
                                                onClick={handleCancel}
                                            >
                                                Cancel
                                            </button>
                                    }
                                    {
                                        isProductBrandChecked ?
                                            <button
                                                data-submit-save-new-brand
                                                type='submit'
                                                className='btn btn-success'
                                            >
                                                Save
                                            </button> :
                                            <button
                                                data-check-new-brand-name
                                                type='button'
                                                className='btn btn-alt'
                                                onClick={handleCheckProductBrand}
                                            >
                                                Check
                                            </button>

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>
}

export default NewProductBrand;