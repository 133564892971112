import React, { useEffect, useContext, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { toJS } from 'mobx';
import { useObserver } from 'mobx-react-lite';
import { GlobalHotKeys } from 'react-hotkeys';

import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';

import PurchaseUpdateStore from '../../../../stores/PurchaseUpdateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as oh from '../../../../utilities/operationHelper';

import './VerifyReturns.scss';

function VerifyReturns(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const action = useRef(null);
    const purchase = useContext(PurchaseUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);

    useEffect(() => {
        action.current = props.extraProps && props.extraProps.action ? props.extraProps.action : null;
        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100)

        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (purchase.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleReturnDestinationChange = (event, { groupId }) => {
        const value = event.target.value;
        purchase.updateReturnDestination(groupId, value);
        purchase.hasUnsavedChanges = true;
    }

    const getReturnDestination = ({ groupId, isFromInventory }) => {
        let index = purchase.returnDestinations.findIndex(r => r.groupId === groupId);

        if (index < 0) {
            if (isFromInventory) {
                purchase.updateReturnDestination(groupId, 'Inventory');
            } else {
                purchase.updateReturnDestination(groupId, '');
            }
            index = 0;
        }

        purchase.hasUnsavedChanges = true;

        return purchase.returnDestinations[index].returnDestination;
    }

    const handleSubmit = event => {
        event.preventDefault();

        getAction(true)
            .then(() => {
                if (isMounted.current) {
                    const data = toJS(purchase.data);
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, { updated: true, data: data });
                    }
                }
            })
    }

    const getAction = (notify) => {
        switch (action.current) {
            case 'void':
                return purchase.voidPurchase(notify);

            case 'refund':
                return purchase.refundPurchase(notify);

            default:
                return purchase.save(notify);
        }
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form onSubmit={handleSubmit}>
            <fieldset disabled={purchase.isSaving}>
                <div className='verify-returns-container quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('transaction-item', 'return-to-inventory')}
                        action='Purchase'
                        category='Verify Returns'
                        className='purchases'
                        onCancel={props.onCancel}
                    />
                    <div className='quick-drawer-body'>
                        {
                            purchase.verifyReturnItems && purchase.verifyReturnItems.length > 0 ?
                                <FadeIn>
                                    <div className='body-content'>
                                        {
                                            purchase.verifyReturnItems.map((i, ii) => {
                                                return <section
                                                    key={`return-item-${ii}`}
                                                    className='product-info'
                                                >
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <ul className='title'>
                                                                <li className='quantity'>
                                                                    <h4>
                                                                        <strong>{i.quantity}<small>x</small></strong>
                                                                    </h4>
                                                                </li>
                                                                <li className='detail has-quantity'>
                                                                    <span className='text-gray'>{i.referenceType}</span>
                                                                    <h4 className='m-0 text-truncate text-truncate-xxl'><strong>{i.descriptor}</strong></h4>
                                                                    <small className='brand'><strong>{i.brandName}</strong> ({i.supplierName})</small>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='ml-4 mt-2'>
                                                                {
                                                                    i.isFromInventory ?
                                                                        <span className='fs-sm text-success'><strong>Note:</strong> This item was taken from inventory</span> :
                                                                        <span className='fs-sm text-danger'><strong>Note:</strong> This item was not taken from inventory</span>
                                                                }
                                                                <select
                                                                    className='custom-select form-control mt-2'
                                                                    value={getReturnDestination(i)}
                                                                    onChange={e => { handleReturnDestinationChange(e, i) }}
                                                                >
                                                                    <option value=''>No action taken</option>
                                                                    <option value='Inventory'>Return to inventory</option>
                                                                    <option value='Supplier'>Return to supplier</option>
                                                                    <option value='Disposed'>Dispose</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            })
                                        }
                                    </div>
                                </FadeIn> : renderQuickDrawerLoading()
                        }
                    </div>
                    <div className='quick-drawer-action pl-3'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={props.onCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-info'
                                    >Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>)
}

export default VerifyReturns;