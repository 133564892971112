import { createContext } from 'react';
import { decorate, observable, action, toJS } from 'mobx';

import * as fn from '../utilities/_functions';
import api from '../api';

export class ExternalClinicUpdate {
    id = null;
    data = null;
    originalData = null;
    newAddress = {
        geocode: null,
        streetNumber: null,
        streetName: null,
        unitSuiteType: null,
        unitSuite: null,
        line2: null,
        locality: null,
        sublocality: null,
        postalCode: null,
        region: null,
        regionCode: null,
        country: null,
        countryCode: null,
    };
    isReady = false;
    isSaving = false;
    isLoading = false;
    hasUnsavedChanges = false;

    cancelExternalClinicGet = null;
    cancelExternalClinicUpdate = null;

    initialize = (id) => {
        const that = this;
        this.clear();

        that.id = id;
        that.isLoading = true;

        return new Promise((resolve, reject) => {
            api.ExternalClinics.get(id, (c) => { that.cancelExternalClinicGet = c; })
                .then(({ data }) => {
                    that.originalData = data;
                    that.data = data;
                    if (data.address) {
                        that.newAddress = data.address;
                    }
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isLoading = false;
                    that.isReady = true;
                })
        })
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            if (that.hasUnsavedChanges) {
                const option = toJS(that.data);

                option.existingAddress = that.originalData.address;
                option.newAddress = that.newAddress;

                api.ExternalClinics.update(that.id, option, (c) => { that.cancelExternalClinicUpdate = c })
                    .then(() => {
                        that.hasUnsavedChanges = false;
                        resolve(that.data);
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                that.hasUnsavedChanges = false;
                that.isSaving = false;
                resolve();
            }
        })
    }

    delete = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            api.ExternalClinics.delete(that.id, (c) => { that.cancelExternalClinicUpdate = c })
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    clearAddress = () => {
        this.newAddress.geocode = null;
        this.newAddress.streetNumber = null;
        this.newAddress.streetName = null;
        this.newAddress.unitSuiteType = null;
        this.newAddress.unitSuite = null;
        this.newAddress.line2 = null;
        this.newAddress.locality = null;
        this.newAddress.sublocality = null;
        this.newAddress.postalCode = null;
        this.newAddress.region = null;
        this.newAddress.regionCode = null;
        this.newAddress.country = null;
        this.newAddress.countryCode = null;
    }

    clear = () => {
        this.id = null;
        this.originalData = null;
        this.data = null;
        this.clearAddress();
        this.isReady = false;
        this.isSaving = false;
        this.hasUnsavedChanges = false;

        if (fn.isFunction(this.cancelExternalClinicGet)) {
            this.cancelExternalClinicGet();
            this.cancelExternalClinicGet = null;
        }

        if (fn.isFunction(this.cancelExternalClinicUpdate)) {
            this.cancelExternalClinicUpdate();
            this.cancelExternalClinicUpdate = null;
        }
    }
}

decorate(ExternalClinicUpdate, {
    id: observable,
    originalData: observable.deep,
    data: observable.deep,
    newAddress: observable,
    isReady: observable,
    isSaving: observable,
    hasUnsavedChanges: observable,
    initialize: action,
    save: action,
    delete: action,
    clearAddress: action,
    clear: action,
})

export default createContext(new ExternalClinicUpdate());