import { createContext } from 'react';
import { decorate, observable, action, toJS } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class PretestTemplateCreate {
    id = null;
    pretestTypes = [];
    data = {
        pretestTypeId: null,
        name: null,
        descriptionHtml: null,
        launchReader: false,
    };
    isReady = false;
    isSaving = false;
    hasUnsavedChanges = false;

    cancelPretestTemplateCreate = null;
    cancelPretestTypes = null;

    initialize = () => {
        const that = this;

        this.clear();
        this.id = fn.newId('ptty_');

        return new Promise((resolve, reject) => {
            api.PretestTypes.all(false, (c) => { that.cancelPretestTypes = c })
                .then(({ data }) => {
                    that.pretestTypes = data;
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isReady = true;
                })
        })
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            let option = toJS(that.data);

            if (that.hasUnsavedChanges) {
                api.PretestTemplates.create(option, (c) => { that.cancelPretestTemplateCreate = c })
                    .then(({ data }) => {
                        that.hasUnsavedChanges = false;
                        that.id = data.id;
                        option.id = data.id;
                        resolve(option);
                    })
                    .catch(() => {
                        reject();
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                that.hasUnsavedChanges = false;
                that.isSaving = false;
                resolve(option);
            }
        })
    }

    clear = () => {
        this.id = null;
        this.pretestTypes.clear();
        this.data.pretestTypeId = null;
        this.data.name = null;
        this.data.descriptionHtml = null;
        this.data.launchReader = false;
        this.hasUnsavedChanges = false;

        if (fn.isFunction(this.cancelPretestTemplateCreate)) {
            this.cancelPretestTemplateCreate();
            this.cancelPretestTemplateCreate = null;
        }

        if (fn.isFunction(this.cancelPretestTypes)) {
            this.cancelPretestTypes();
            this.cancelPretestTypes = null;
        }
    }
}

decorate(PretestTemplateCreate, {
    id: observable,
    pretestTypes: observable.deep,
    data: observable.deep,
    hasUnsavedChanges: observable,
    isReady: observable,
    isSaving: observable,
    initialize: action,
    save: action,
    clear: action,
})

export default createContext(new PretestTemplateCreate());