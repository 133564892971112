import React, { useEffect, useContext, useRef, useState } from 'react';
import FadeIn from 'react-fade-in';
import { GlobalHotKeys } from 'react-hotkeys';
import { useObserver } from 'mobx-react-lite';

import { quickDrawerFocus, renderQuickDrawerLoading } from '../../../../_shared/QuickDrawer';
import QuickDrawerHeader from '../../../../_shared/QuickDrawerHeader';
import RichTextEditor from '../../../../_shared/RichTextEditor';

import LogicalDeviceCreateStore from '../../../../../../stores/LogicalDeviceCreateStore';
import AuthStore from '../../../../../../stores/AuthStore';
import QuickDrawerStore from '../../../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../../../constants/errorMessages';
import * as fn from '../../../../../../utilities/_functions';

import './NewLogicalDevice.scss';

function NewLogicalDevice(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const validateRef = useRef(null);
    const auth = useContext(AuthStore);
    const logicalDevice = useContext(LogicalDeviceCreateStore);
    const [industryId, setIndustryId] = useState(auth.currentTenant.industryId);
    const quickDrawer = useContext(QuickDrawerStore);

    useEffect(() => {
        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100)

        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (logicalDevice.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            logicalDevice.save()
                .then(data => {
                    if (isMounted.current) {
                        if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                            props.onSuccess(event, data);
                        }
                    }
                })
        }
    }

    const handleIndustryChange = event => {
        setIndustryId(event.target.value);
        logicalDevice.hasUnsavedChanges = true;
    }

    const handleIndustryDeviceChange = event => {
        logicalDevice.data.industryDeviceId = event.target.value;
        logicalDevice.hasUnsavedChanges = true;
    }

    const handleNameChange = event => {
        logicalDevice.data.name = event.target.value;
        logicalDevice.hasUnsavedChanges = true;
    }

    const handleDescriptionChange = content => {
        const html = content;
        logicalDevice.data.descriptionHtml = ((html === '<p><br></p>') ? null : html);
        logicalDevice.hasUnsavedChanges = true;
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <fieldset disabled={logicalDevice.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={'fal fa-laptop-code'}
                        action='New'
                        category='Logical Device'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        {
                            logicalDevice.isReady ?
                                <FadeIn>
                                    <div className='new-logical-device body-content'>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4 validate validate-required'>
                                                        <label className='required' htmlFor='new-logical-device-industry'><small>Industry</small></label>
                                                        <select
                                                            id='new-logical-device-industry'
                                                            className='custom-select form-control'
                                                            value={industryId ? industryId : ''}
                                                            onChange={handleIndustryChange}
                                                        >
                                                            <option value=''></option>
                                                            {
                                                                logicalDevice.industries.map((option, ii) => {
                                                                    return <option key={`logical_device_industry_${ii}`} value={option.id}>{option.name}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4 validate validate-required'>
                                                        <label className='required' htmlFor='new-logical-device-industry-device'><small>Physical Device (Make / Model)</small></label>
                                                        <select
                                                            id='new-logical-device-industry-device'
                                                            className='custom-select form-control'
                                                            value={logicalDevice.data.industryDeviceId ? logicalDevice.data.industryDeviceId : ''}
                                                            onChange={handleIndustryDeviceChange}
                                                        >
                                                            <option value=''></option>
                                                            {
                                                                logicalDevice.industryDevices.map((option, di) => {
                                                                    return <option key={`logical_device_industry_device_${di}`} value={option.id}>{option.displayName}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4 validate validate-required'>
                                                        <label className='required' htmlFor='new-logical-device-name'><small>Name</small></label>
                                                        <input
                                                            id='new-logical-device-name'
                                                            type='text'
                                                            maxLength={100}
                                                            className='form-control'
                                                            autoComplete={'off'}
                                                            value={logicalDevice.data.name ? logicalDevice.data.name : ''}
                                                            onChange={handleNameChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label htmlFor='new-logical-device-description'><small>Description</small></label>
                                                        <RichTextEditor
                                                            mode='none'
                                                            disableTab={true}
                                                            value={logicalDevice.data.descriptionHtml ? logicalDevice.data.descriptionHtml : ''}
                                                            onChange={handleDescriptionChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </FadeIn> : renderQuickDrawerLoading()
                        }
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-4'></div>
                            <div className='col-8'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>)
}

export default NewLogicalDevice;