import React, { useContext, useEffect, useState, useRef } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useObserver } from 'mobx-react-lite';

import AuthStore from '../../../stores/AuthStore';
import SearchBarStore from '../../../stores/SearchBarStore';
import CacheStore from '../../../stores/CacheStore';
import SettingStore from '../../../stores/SettingStore';

import * as rts from '../../../constants/routes';
import * as AccessType from '../../../constants/accessTypes';
import * as fn from '../../../utilities/_functions';
import * as ah from '../../../utilities/accessHelper';

import './Sidebar.scss';
import api from '../../../api';

function Sidebar({ children }) {
    const isMounted = useRef(true);
    const cancelLockscreenActivateRef = useRef();
    const auth = useContext(AuthStore);
    const searchbar = useContext(SearchBarStore);
    const cache = useContext(CacheStore);
    const setting = useContext(SettingStore);
    const location = useLocation();
    const [isInAnimation, setIsInAnimation] = useState(false);

    useEffect(() => {
        setting.initialize();

        return () => {
            isMounted.current = false;
            if (fn.isFunction(cancelLockscreenActivateRef.current)) { cancelLockscreenActivateRef.current(); cancelLockscreenActivateRef.current = null; }
        }
    }, []) // eslint-disable-line

    const renderCurrentUserPicture = () => {
        if (cache.activeUsers && auth.currentUser) {
            const currentUser = cache.activeUsers.filter(a => a.id === auth.currentUser.id)[0];

            return <span
                className={'profile-image rounded-circle m-0 d-block' + (currentUser && !currentUser.profilePictureUri ? ` profile-initials text-white bg-color${currentUser.color}-500` : '')}
                style={currentUser && currentUser.profilePictureUri ? {
                    backgroundImage: `url(${(currentUser.profilePictureUri)})`,
                    backgroundSize: 'cover',
                } : null}
                title={currentUser ? currentUser.fullName : null}>
                {currentUser && !currentUser.profilePictureUri ? <div className='d-initials'>{currentUser.initials}</div> : null}
            </span>
        }
    }

    const handleToggleSidebar = () => {
        const toggle = !setting.isSidebarOpened;
        setting.isSidebarOpened = toggle;
        setIsInAnimation(true);
        setTimeout(() => {
            setIsInAnimation(false);
        }, 300)
    }

    const isActiveUrl = route => {
        return location.pathname.startsWith(route);
    }

    const handleStopImpersonation = async () => {
        auth.currentUser = auth.loggedInUser;
        const userId = auth.currentUser.id;
        const { data: authorization } = await api.Authorize.switch({ pin: "1234", userId });
        auth.setAuthorization(authorization);
    }

    return useObserver(() => <>
        {
            !!auth.currentUser ?
                <aside className='page-sidebar'>
                    <div className={'searchbar-overlay' + (searchbar.isActivated ? ' active' : '')} onClick={() => { searchbar.isActivated = false; }} />
                    <nav className='primary-nav' role='navigation'>
                        <div className={'info-card animated fastest' + (isInAnimation ? ' d-none' : ' fadeIn')}>
                            {renderCurrentUserPicture()}
                            <div className={'info-card-text mt-3' + (auth.isImpersonating ? ' flashing' : '')} >
                                <a href='/' className='d-flex align-items-center text-white'>
                                    <span className='text-truncate text-truncate-sm d-inline-block'>
                                        {auth.currentUser.fullName}
                                    </span>
                                </a>
                                <span className='d-inline-block text-truncate text-truncate-sm'>{auth.currentTenant ? auth.currentTenant.displayName : ''}</span>
                            </div>
                            {
                                auth.isImpersonating &&
                                <div>
                                    <button
                                        type='button'
                                        className='btn btn-icon'
                                        onClick={handleStopImpersonation}
                                        title='Stop impersonation'
                                    >
                                        <i className={'fal fa-door-open text-white'}></i>
                                    </button>
                                </div>
                            }
                        </div>
                        <ul className={'nav-menu animated fastest' + (isInAnimation ? ' d-none' : ' fadeIn')}>
                            <li className='nav-title'>Reception</li>
                            {
                                ah.check(AccessType.VIEW_APPOINTMENT) ?
                                    <li className={isActiveUrl(rts.Appointments.Home) ? 'active' : ''}>
                                        <Link to={rts.Appointments.Home}>
                                            <i className='fal fa-calendar-alt text-appointment-400'></i>
                                            <span className='nav-link-text'>Appointments</span>
                                        </Link>
                                    </li> : null
                            }
                            {
                                ah.check(AccessType.VIEW_CUSTOMER) ?
                                    <li className={isActiveUrl(rts.Customers.Home) ? 'active' : ''}>
                                        <Link to={rts.Customers.Home}>
                                            <i className='fal fa-address-book text-customer-400'></i>
                                            <span className='nav-link-text'>Customers</span>
                                        </Link>
                                    </li> : null
                            }
                            {
                                ah.check(AccessType.VIEW_PURCHASE) ?
                                    <li className={isActiveUrl(rts.Purchases.Home) ? 'active' : ''}>
                                        <Link to={rts.Purchases.Invoices}>
                                            <i className='fal fa-shopping-bag text-purchase-400'></i>
                                            <span className='nav-link-text'>Purchases</span>
                                        </Link>
                                    </li> : null
                            }
                            <li className='nav-title'>Services</li>
                            {
                                ah.check(AccessType.VIEW_EXAM) ?
                                    <li className={isActiveUrl(rts.Exams.List) || isActiveUrl(rts.Exams.View) ? 'active' : ''}>
                                        <Link to={rts.Exams.List}>
                                            <i className='fal fa-clipboard-list-check text-exam-400'></i>
                                            <span className='nav-link-text'>Exams</span>
                                        </Link>
                                    </li> : null
                            }
                            {/* {
                        ah.check(AccessType.VIEW_WORK_ORDER) ?
                            <li className={isActiveUrl(rts.Quotes.Home) ? 'active' : ''}>
                                <Link to={rts.Quotes.Home}>
                                    <i className='fal fa-file-edit text-quote-400'></i>
                                    <span className='nav-link-text'>Quotes</span>
                                </Link>
                            </li> : null
                    } */}
                            {
                                ah.check(AccessType.VIEW_REFERRAL_APPOINTMENT) ?
                                    <li className={isActiveUrl(rts.Referrals.Home) ? 'active' : ''}>
                                        <Link to={rts.Referrals.Home}>
                                            <i className='fal fa-hospital-user text-referral-400'></i>
                                            <span className='nav-link-text'>Referrals</span>
                                        </Link>
                                    </li> : null
                            }
                            {
                                ah.check(AccessType.VIEW_WORK_ORDER) ?
                                    <li className={isActiveUrl(rts.WorkOrders.List) || isActiveUrl(rts.WorkOrders.View) ? 'active' : ''}>
                                        <Link to={rts.WorkOrders.List}>
                                            <i className='fal fa-inbox text-work-order-400'></i>
                                            <span className='nav-link-text'>Work Orders</span>
                                        </Link>
                                    </li> : null
                            }
                            <li className='nav-title'>Inventory</li>
                            {
                                ah.check(AccessType.VIEW_PRODUCT) ?
                                    <li className={isActiveUrl(rts.Products.Home) ? 'active' : ''}>
                                        <Link to={rts.Products.Home}>
                                            <i className='fal fa-box-check text-product-400'></i>
                                            <span className='nav-link-text'>Products</span>
                                        </Link>
                                    </li> : null
                            }
                            {
                                ah.check(AccessType.VIEW_SERVICE) ?
                                    <li className={isActiveUrl(rts.Services.Home) ? 'active' : ''}>
                                        <Link to={rts.Services.Home}>
                                            <i className='fal fa-user-md-chat text-service-400'></i>
                                            <span className='nav-link-text'>Services</span>
                                        </Link>
                                    </li> : null
                            }

                            <li className='nav-title'>Administrative</li>
                            {
                                ah.check(AccessType.VIEW_SCHEDULE) ?
                                    <li className={isActiveUrl(rts.Schedules.Home) ? 'active' : ''}>
                                        <Link to={rts.Schedules.Home}>
                                            <i className='fal fa-clock text-schedule-400'></i>
                                            <span className='nav-link-text'>Schedules</span>
                                        </Link>
                                    </li> : null
                            }
                            {/* {
                                ah.check(AccessType.VIEW_REPORT) ?
                                    <li className={isActiveUrl(rts.Reports.Home) ? 'active' : ''}>
                                        <Link to={rts.Reports.Home}>
                                            <i className='fal fa-chart-pie text-report-400'></i>
                                            <span className='nav-link-text'>Reports</span>
                                        </Link>
                                    </li> : null
                            } */}
                            {/* {
                        ah.check(AccessType.VIEW_USER_GROUP) ?
                            <li className={isActiveUrl(rts.Settings.Users) ? 'active' : ''}>
                                <Link to={rts.Settings.Users}>
                                    <i className='fal fa-user text-report-400'></i>
                                    <span className='nav-link-text'>Users</span>
                                </Link>
                            </li> : null
                    }
                    {
                        ah.check(AccessType.VIEW_USER_GROUP) ?
                            <li className={isActiveUrl(rts.Settings.Groups) ? 'active' : ''}>
                                <Link to={rts.Settings.Groups}>
                                    <i className='fal fa-users text-report-400'></i>
                                    <span className='nav-link-text'>Groups</span>
                                </Link>
                            </li> : null
                    } */}
                        </ul>
                    </nav>
                    <div className='nav-footer shadow-top'>
                        <button
                            type='button'
                            className={'btn btn-link btn-block nav-function-minify-expand' + (setting.isSidebarOpened ? ' d-none' : '')}
                            onClick={handleToggleSidebar}
                            title='Expand'
                        >
                            <i className='ni ni-chevron-right'></i>
                            <i className='ni ni-chevron-right'></i>
                        </button>
                        <ul className='list-table m-auto nav-footer-buttons'>
                            <li>
                                <button
                                    type='button'
                                    className={setting.isSidebarOpened ? '' : 'd-none'}
                                    data-toggle='tooltip'
                                    data-placement='top'
                                    title='Minimize'
                                    onClick={handleToggleSidebar}
                                >
                                    <i className='ni ni-chevron-left'></i>
                                    <i className='ni ni-chevron-left'></i>
                                </button>
                            </li>
                            <li>
                                <button
                                    type='button'
                                    data-toggle='tooltip'
                                    data-placement='top'
                                    title='Lock'
                                // onClick={handleLockScreen}
                                >
                                    <i className='fal fa-lock'></i>
                                </button>
                            </li>
                            <li>
                                <Link to='/logout' data-toggle='tooltip' data-placement='top' title='Log out'>
                                    <i className='fal fa-sign-out-alt'></i>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </aside> : null
        }
    </>)
}

export default Sidebar;
