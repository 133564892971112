import { createContext } from 'react';
import { decorate, observable, action, computed } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class Communication {
    type = null;
    action = null;
    templates = [];
    customerId = null;
    customer = null;
    selectedTemplateId = null;
    defaultContact = null;
    reference1Id = null;
    reference2Id = null;
    reference3Id = null;
    relatedReferenceIds = [];
    tags = [];
    renderedContent = null;
    files = [];
    emailOption = null;
    smsOption = null;
    faxOption = null;
    hasUnsavedChanges = false;
    isReady = false;
    isSaving = false;
    isTemplateLoading = false;
    cancelFillTemplate = null;
    cancelTemplateSearch = null;
    cancelCustomer = null;
    cancelSend = null;

    initialize = (type, action, reference1Id, files, customerId, defaultContact, reference2Id, reference3Id, tags, relatedReferenceIds) => {
        const that = this;

        this.clear();
        this.type = type;
        this.action = action;
        this.reference1Id = reference1Id;
        this.reference2Id = reference2Id;
        this.reference3Id = reference3Id;
        this.files = files;
        this.customerId = customerId;
        this.defaultContact = defaultContact;
        this.tags = tags && Array.isArray(tags) ? tags : [];
        this.relatedReferenceIds = relatedReferenceIds && Array.isArray(relatedReferenceIds) ? relatedReferenceIds : [];

        return new Promise((resolve, reject) => {
            new Promise((resolveCustomer, rejectCustomer) => {
                if (that.customerId) {
                    api.Customers.get(that.customerId, (c) => { that.cancelCustomer = c })
                        .then(({ data }) => {
                            that.customer = data;
                            resolveCustomer();
                        })
                        .catch(() => {
                            rejectCustomer();
                        })
                }
                else {
                    resolveCustomer();
                }
            })
                .then(() => {
                    if (that.type) {
                        if (that.action && that.reference1Id) {
                            const option = {
                                language: that.customer && that.customer.communicationLanguage ? that.customer.communicationLanguage : null,
                                appointmentId: that.reference1Id.startsWith('apt_') && !that.reference1Id.startsWith('apt_g_') ? that.reference1Id : null,
                                appointmentGroupId: that.reference1Id.startsWith('apt_g_') ? that.reference1Id : null,
                                parameters: [
                                    { field: 'Communication', value: that.type, },
                                    { field: 'Type', value: that.action, },
                                ],
                                tags: that.tags,
                                includeTotalCount: false,
                                loadProperties: true,
                            };

                            api.CommunicationTemplates.search(option, (c) => { that.cancelTemplateSearch = c })
                                .then(({ data }) => {
                                    const templates = data && data.result && data.result.length > 0 ? data.result : [];
                                    that.templates = templates;

                                    if (that.templates.length > 0 && that.action && that.action.length > 0) {
                                        const templateId = that.templates.some(t => t.action === that.action[0]) ?
                                            that.templates.filter(t => t.action === that.action[0])[0].id : that.templates[0].id

                                        that.updateSelectedTemplateId(templateId)
                                            .finally(() => {
                                                resolve();
                                            })
                                    }
                                    else {
                                        resolve();
                                    }
                                })
                                .catch(error => {
                                    reject(error);
                                })
                                .finally(() => {
                                    that.isReady = true;
                                })
                        } else {
                            that.isReady = true;
                            resolve();
                        }
                    }
                })
        })
    }

    updateSelectedTemplateId = (selectedTemplateId) => {
        const that = this;

        this.selectedTemplateId = selectedTemplateId;
        this.isTemplateLoading = true;

        return new Promise((resolve, reject) => {
            const index = that.templates.findIndex(t => t.id === selectedTemplateId);

            if (that.selectedTemplateId) {
                if (that.selectedTemplate && !that.selectedTemplate.loaded) {
                    const option = {
                        language: that.customer && that.customer.communicationLanguage ? that.customer.communicationLanguage : null,
                        referenceIds: [that.reference1Id]
                    };
                    api.CommunicationTemplates.fill(selectedTemplateId, option, (c) => { that.cancelFillTemplate = c })
                        .then(({ data }) => {
                            that.templates[index].subject = data.subject;
                            that.templates[index].preview = data.preview;
                            that.templates[index].body = data.body;
                            that.templates[index].renderedTemplate = data.renderedTemplate;
                            that.templates[index].loaded = true;

                            that.renderedContent = that.templates[index].renderedTemplate;

                            resolve();
                        })
                        .catch(error => {
                            reject(error);
                        })
                        .finally(() => {
                            that.isTemplateLoading = false;
                        })
                }
                else {
                    that.renderedContent = that.templates[index].renderedTemplate;
                    that.isTemplateLoading = false;
                    resolve();
                }
            }
            else {
                resolve();
            }
        })
    }

    send = (option, notify) => {
        const that = this;

        if (!this.type) return Promise.reject();

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            if (that.type) {
                const communicationOption = option;

                communicationOption.language = that.customer && that.customer.communicationLanguage ? that.customer.communicationLanguage : null;
                communicationOption.printedDocumentIds = [];
                communicationOption.attachmentIds = [];
                communicationOption.communicationTemplateId = that.selectedTemplate ? that.selectedTemplate.id : null;
                communicationOption.customerId = that.customerId;

                for (let f of that.files) {
                    if (f.type === 'attachment') {
                        communicationOption.attachmentIds.push(f.id);
                    }
                    else {
                        communicationOption.printedDocumentIds.push(f.id);
                    }
                }

                // communicationOption.printedDocumentIds = that.files && that.files.length > 0 ? that.files.map(f => { return f.id }) : [];
                communicationOption.reference = that.action;
                communicationOption.reference1Id = that.reference1Id;
                communicationOption.reference2Id = that.reference2Id;
                communicationOption.reference3Id = that.reference3Id;
                communicationOption.relatedReferenceIds = that.relatedReferenceIds;

                switch (that.type.toLowerCase()) {
                    case 'email':
                        const emailRequests = [];

                        communicationOption.type = communicationOption.secured === true ? 'SecureEmail' : 'Email';

                        if (that.selectedTemplate) {
                            for (let e = 0; e < communicationOption.to.length; e++) {
                                emailRequests.push(
                                    new Promise((resolve1, reject1) => {
                                        const destination = communicationOption.to[e];

                                        api.CommunicationTemplates.fill(that.selectedTemplate.id, {
                                            destination: (destination ? destination.value : null),
                                            referenceIds: [that.reference1Id],
                                            subject: option.emailOption.subject,
                                            preview: option.emailOption.preview,
                                            body: option.emailOption.bodyHtml,
                                        }, (c) => { that.cancelFillTemplate = c })
                                            .then(({ data }) => {
                                                const newCommunicationOption = JSON.parse(JSON.stringify(communicationOption));

                                                newCommunicationOption.to = [destination];
                                                newCommunicationOption.emailOption.subject = data.subject;
                                                newCommunicationOption.emailOption.preview = data.preview;
                                                newCommunicationOption.emailOption.bodyHtml = data.renderedTemplate;

                                                api.Communications.send(newCommunicationOption, (c) => { that.cancelSend = c }).then((response) => { resolve1(response); }).catch(() => { reject1(); })
                                            })
                                    }));
                            }
                        }
                        else {
                            emailRequests.push(api.Communications.send(communicationOption, (c) => { that.cancelSend = c }));
                        }

                        Promise.all(emailRequests)
                            .then((response) => {
                                resolve(response);
                            })
                            .catch(() => { reject(); })
                            .finally(() => { this.isSaving = false; })
                        break;

                    case 'sms':
                        const smsRequests = [];

                        if (that.selectedTemplate) {
                            for (let e = 0; e < communicationOption.to.length; e++) {
                                smsRequests.push(
                                    new Promise((resolve1, reject1) => {
                                        const destination = communicationOption.to[e];

                                        api.CommunicationTemplates.fill(that.selectedTemplate.id, {
                                            destination: (destination ? destination.value : null),
                                            referenceIds: [that.reference1Id],
                                            body: option.smsOption.message,
                                        }, (c) => { that.cancelFillTemplate = c })
                                            .then(({ data }) => {
                                                const newCommunicationOption = JSON.parse(JSON.stringify(communicationOption));

                                                newCommunicationOption.to = [destination];
                                                newCommunicationOption.smsOption.message = data.renderedTemplate;

                                                api.Communications.send(newCommunicationOption, (c) => { that.cancelSend = c }).then((response) => { resolve1(response); }).catch(() => { reject1(); })
                                            })
                                    }));
                            }
                        }
                        else {
                            smsRequests.push(api.Communications.send(communicationOption, (c) => { that.cancelSend = c }));
                        }

                        Promise.all(smsRequests)
                            .then((response) => { resolve(response); })
                            .catch(() => { reject(); })
                            .finally(() => { this.isSaving = false; })
                        break;

                    case 'fax':
                        communicationOption.type = 'fax';

                        api.Communications.send(communicationOption, (c) => { that.cancelSend = c })
                            .then((response) => { resolve(response); })
                            .catch(() => { reject(); })
                            .finally(() => { this.isSaving = false; })
                        break;

                    default:
                        reject();
                        break;
                }
            } else {
                reject();
            }
        })
    }

    clear = () => {
        this.type = null;
        this.action = null;
        this.templates.clear();
        this.customerId = null;
        this.customer = null;
        this.selectedTemplateId = null;
        this.defaultContact = null;
        this.reference1Id = null;
        this.reference2Id = null;
        this.reference3Id = null;
        this.relatedReferenceIds.clear();
        this.files.clear();
        this.renderedContent = null;
        this.emailOption = null;
        this.smsOption = null;
        this.faxOption = null;
        this.hasUnsavedChanges = false;
        this.isTemplateLoading = false;
        this.isReady = false;
        this.isSaving = false;

        if (fn.isFunction(this.cancelFillTemplate)) {
            this.cancelFillTemplate();
            this.cancelFillTemplate = null;
        }

        if (fn.isFunction(this.cancelTemplateSearch)) {
            this.cancelTemplateSearch();
            this.cancelTemplateSearch = null;
        }

        if (fn.isFunction(this.cancelSend)) {
            this.cancelSend();
            this.cancelSend = null;
        }
    }

    get selectedTemplate() {
        if (this.templates && this.templates.length > 0 && this.selectedTemplateId) {
            return this.templates.filter(t => t.id === this.selectedTemplateId)[0];
        }
        return null;
    }

    get suggestedEmails() {
        if (this.customer && this.customer.emailAddressContacts && this.customer.emailAddressContacts.length > 0) {
            return this.customer.emailAddressContacts;
        }
        return []
    }
}

decorate(Communication, {
    type: observable,
    action: observable,
    templates: observable,
    customerId: observable,
    customer: observable,
    selectedTemplateId: observable,
    defaultContact: observable,
    reference1Id: observable,
    reference2Id: observable,
    reference3Id: observable,
    relatedReferenceIds: observable,
    files: observable,
    renderedContent: observable,
    emailOption: observable,
    smsOption: observable,
    faxOption: observable,
    hasUnsavedChanges: observable,
    isReady: observable,
    isTemplateLoading: observable,
    isSaving: observable,
    initialize: action,
    send: action,
    clear: action,
    selectedTemplate: computed,
    suggestedEmails: computed,
})

export default createContext(new Communication());
