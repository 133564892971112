import React, { useEffect, useContext, useState, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { GlobalHotKeys } from 'react-hotkeys';
import { toJS } from 'mobx';
import { useObserver } from 'mobx-react-lite';
import { Mention, MentionsInput } from 'react-mentions';

import BodyEnd from '../../../../_shared/BodyEnd';
import QuickDrawerHeader from '../../../../_shared/QuickDrawerHeader';
import { quickDrawerFocus } from '../../../../_shared/QuickDrawer';

import PrescriptionControlViewStore from '../../../../../../stores/PrescriptionControlViewStore';
import QuickDrawerStore from '../../../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../../../constants/errorMessages';
import * as fn from '../../../../../../utilities/_functions';
import * as oh from '../../../../../../utilities/operationHelper';
import * as tch from '../../../../../../utilities/templateControlHelper';
import * as mh from '../../../../../../utilities/mentionHelper';

import './UpdateExistingPrescriptionControl.scss';

function UpdateExistingPretestControl(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const prescriptionControl = useContext(PrescriptionControlViewStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [displayPreview, setDisplayPreview] = useState(false);
    const [size, setSize] = useState('');
    const [align, setAlign] = useState('');
    const [valign, setValign] = useState('');
    const [equation, setEquation] = useState('');
    const [timestampDestination, setTimestampDestination] = useState('');
    const [overwrite, setOverwrite] = useState([]);
    const [copyFieldsMapping, setCopyFieldsMapping] = useState([]);
    const [setFieldsMapping, setSetFieldsMapping] = useState([]);
    const [options, setOptions] = useState([]);
    const [inline, setInline] = useState(false);
    const [link, setLink] = useState(false);
    const [inputOptions, setInputOptions] = useState([]);

    useEffect(() => {
        if (props.extraProps.inputOptions && props.extraProps.inputOptions.length > 0) {
            setInputOptions(toJS(props.extraProps.inputOptions));
        }

        focusTimer.current = setTimeout(() => {
            setSize(prescriptionControl.getMetadata('size'));
            setAlign(prescriptionControl.getMetadata('align'));
            setValign(prescriptionControl.getMetadata('valign'));
            setOptions(prescriptionControl.getMetadata('options'));
            setInline(prescriptionControl.getMetadata('inline'));
            setLink(prescriptionControl.getMetadata('link'));
            setEquation(prescriptionControl.getMetadata('equation'));
            setTimestampDestination(prescriptionControl.getMetadata('timestamp'));

            if (tch.hasField(prescriptionControl.data.type, tch.TEMPLATE_CONTROL_METADATA_OVERWRITE)) {
                setOverwrite(prescriptionControl.getMetadata('overwrite'));
            }

            if (tch.hasField(prescriptionControl.data.type, tch.TEMPLATE_CONTROL_METADATA_COPY_FIELDS_OPTIONS)) {
                const copyFieldsOption = prescriptionControl.getMetadata('copyFields');

                if (copyFieldsOption) {
                    setCopyFieldsMapping(copyFieldsOption.mapping);
                }
            }

            if (tch.hasField(prescriptionControl.data.type, tch.TEMPLATE_CONTROL_METADATA_SET_FIELDS_OPTIONS)) {
                const setFieldsOption = prescriptionControl.getMetadata('setFields');

                if (setFieldsOption) {
                    setSetFieldsMapping(setFieldsOption.mapping);
                }
            }

            quickDrawerFocus(props.drawer);
        }, 100)

        return (() => {
            isMounted.current = false;
            prescriptionControl.clear();
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        })
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (prescriptionControl.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = event => {
        event.preventDefault();
        handlePopulateMetadata();
        prescriptionControl.save()
            .then(data => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, data);
                    }
                }
            })
    }

    const handleLabelChange = event => {
        prescriptionControl.data.label = event.target.value;
        prescriptionControl.hasUnsavedChanges = true;
    }

    const handleTextChange = event => {
        prescriptionControl.data.text = event.target.value;
        prescriptionControl.hasUnsavedChanges = true;
    }

    const handleSizeChange = event => {
        setSize(event.target.value);
        prescriptionControl.hasUnsavedChanges = true;
    }

    const handleAlignChange = value => {
        setAlign((value ? value : 'left'));
        prescriptionControl.hasUnsavedChanges = true;
    }

    const handleValignChange = value => {
        setValign((value ? value : 'top'));
        prescriptionControl.hasUnsavedChanges = true;
    }

    const handleInlineChange = event => {
        setInline((event.target.value === 'true'));
        prescriptionControl.hasUnsavedChanges = true;
    }

    const handleLinkChange = event => {
        setLink((event.target.value === 'true'));
        prescriptionControl.hasUnsavedChanges = true;
    }

    const handleEquationChange = (event, newValue) => {
        setEquation(newValue);
        prescriptionControl.hasUnsavedChanges = true;
    }

    const handleTimestampChange = (event, newValue) => {
        setTimestampDestination(newValue);
        prescriptionControl.hasUnsavedChanges = true;
    }

    const handlePreviewClick = event => {
        event.preventDefault();
        handlePopulateMetadata();
        setDisplayPreview(true);
        prescriptionControl.hasUnsavedChanges = true;
    }

    const handleClosePreviewClick = event => {
        event.preventDefault();
        setDisplayPreview(false);
        prescriptionControl.hasUnsavedChanges = true;
    }

    const handleOverwriteChange = event => {
        setOverwrite((event.target.value === 'true'));
        prescriptionControl.hasUnsavedChanges = true;
    }

    const handleCopyFieldMappingSourceChange = (event, index) => {
        const tempCopyFieldsMapping = [...copyFieldsMapping];

        tempCopyFieldsMapping[index].source = event.target.value;
        setCopyFieldsMapping(tempCopyFieldsMapping);
        prescriptionControl.hasUnsavedChanges = true;
    }

    const handleCopyFieldMappingDestinationChange = (event, index) => {
        const tempCopyFieldMapping = [...copyFieldsMapping];

        tempCopyFieldMapping[index].destination = event.target.value;
        setCopyFieldsMapping(tempCopyFieldMapping);
        prescriptionControl.hasUnsavedChanges = true;
    }

    const handleAddCopyFieldMapping = event => {
        event.preventDefault();
        setCopyFieldsMapping(p => [...copyFieldsMapping, { source: '', destination: '' }]);
        focusTimer.current = setTimeout(() => {
            const element = document.querySelector(`#new-prescription-control-copy-field-source-${(copyFieldsMapping.length)}`)
            if (element) { element.focus(); }
        }, 50);
        prescriptionControl.hasUnsavedChanges = true;
    }

    const handleRemoveCopyFieldMapping = (mapping, index) => {
        setCopyFieldsMapping(copyFieldsMapping.filter((o, oi) => oi !== index));
        prescriptionControl.hasUnsavedChanges = true;
    }

    const handleSetFieldMappingKeyChange = (event, index) => {
        const tempSetFieldsMapping = [...setFieldsMapping];

        tempSetFieldsMapping[index].key = event.target.value;
        setSetFieldsMapping(tempSetFieldsMapping);
        prescriptionControl.hasUnsavedChanges = true;
    }

    const handleSetFieldMappingValueChange = (event, index) => {
        const tempSetFieldMapping = [...setFieldsMapping];

        tempSetFieldMapping[index].value = event.target.value;
        setSetFieldsMapping(tempSetFieldMapping);
        prescriptionControl.hasUnsavedChanges = true;
    }

    const handleAddSetFieldMapping = event => {
        event.preventDefault();
        setSetFieldsMapping(p => [...setFieldsMapping, { key: '', value: '' }]);
        focusTimer.current = setTimeout(() => {
            const element = document.querySelector(`#new-prescription-control-set-field-${(setFieldsMapping.length)}`)
            if (element) { element.focus(); }
        }, 50);
        prescriptionControl.hasUnsavedChanges = true;
    }

    const handleRemoveSetFieldMapping = (mapping, index) => {
        setSetFieldsMapping(setFieldsMapping.filter((o, oi) => oi !== index));
        prescriptionControl.hasUnsavedChanges = true;
    }

    const handlePopulateMetadata = () => {
        populateMetadata(tch.TEMPLATE_CONTROL_METADATA_SIZE, 'size', size);
        populateMetadata(tch.TEMPLATE_CONTROL_METADATA_ALIGN, 'align', align);
        populateMetadata(tch.TEMPLATE_CONTROL_METADATA_VALIGN, 'valign', valign);
        populateMetadata(tch.TEMPLATE_CONTROL_METADATA_OPTIONS, 'options', options);
        populateMetadata(tch.TEMPLATE_CONTROL_METADATA_INLINE, 'inline', inline);
        populateMetadata(tch.TEMPLATE_CONTROL_METADATA_LINK, 'link', link);
        populateMetadata(tch.TEMPLATE_CONTROL_METADATA_EQUATION, 'equation', equation);
        populateMetadata(tch.TEMPLATE_CONTROL_METADATA_TIMESTAMP_DESTINATION, 'timestamp', timestampDestination);
        populateMetadata(tch.TEMPLATE_CONTROL_METADATA_OVERWRITE, 'overwrite', overwrite);
        populateMetadata(tch.TEMPLATE_CONTROL_METADATA_COPY_FIELDS_OPTIONS, 'copyFields', (
            copyFieldsMapping !== null ? {
                mapping: copyFieldsMapping,
            } : null
        ));
        populateMetadata(tch.TEMPLATE_CONTROL_METADATA_SET_FIELDS_OPTIONS, 'setFields', (
            setFieldsMapping !== null ? {
                mapping: setFieldsMapping,
            } : null
        ));
    }

    const handleOptionChange = (event, index) => {
        const tempOptions = [...options];
        tempOptions[index] = event.target.value;
        setOptions(tempOptions);
        prescriptionControl.hasUnsavedChanges = true;
    }

    const handleAddOption = event => {
        event.preventDefault();
        setOptions(o => [...options, '']);
        focusTimer.current = setTimeout(() => {
            const element = document.querySelector(`#new-prescription-control-${prescriptionControl.data.key}-${(options.length)}`)
            if (element) { element.focus(); }
        }, 50);
        prescriptionControl.hasUnsavedChanges = true;
    }

    const handleRemoveOption = (option, index) => {
        setOptions(options.filter((o, oi) => oi !== index));
        prescriptionControl.hasUnsavedChanges = true;
    }

    const handleMoveOption = (from, to) => {
        const tempOptions = [...options];
        const element = tempOptions[from];

        tempOptions.splice(from, 1);
        tempOptions.splice(to, 0, element);

        setOptions(tempOptions);
        prescriptionControl.hasUnsavedChanges = true;
    }

    const renderCopyFieldMapping = () => {
        return <>
            {
                copyFieldsMapping && copyFieldsMapping.length > 0 ?
                    copyFieldsMapping.map((f, fi) => {
                        return <ul
                            key={`prescription_input_copy_field_mapping_${fi}`}
                            className='list-inline no-style m-0 mt-n2 mb-2'
                        >
                            <li className='list-inline-item m-0 option-sm'>
                                <MentionsInput
                                    singleLine
                                    id={`new-prescription-control-copy-field-source-${fi}`}
                                    className='react-mentions-wrapper'
                                    autoComplete='off'
                                    value={f.source ? f.source : ''}
                                    title={mh.getDisplay(f.source)}
                                    onChange={e => { handleCopyFieldMappingSourceChange(e, fi) }}
                                >
                                    <Mention
                                        trigger='#'
                                        data={inputOptions}
                                        markup={mh.MENTION_MARKUP}
                                    />
                                </MentionsInput>
                            </li>
                            <li className='list-inline-item mx-2'>to</li>
                            <li className='list-inline-item m-0 option-sm'>
                                <MentionsInput
                                    singleLine
                                    id={`new-prescription-control-copy-field-destination-${fi}`}
                                    className='react-mentions-wrapper'
                                    autoComplete='off'
                                    value={f.destination ? f.destination : ''}
                                    title={mh.getDisplay(f.destination)}
                                    onChange={e => { handleCopyFieldMappingDestinationChange(e, fi) }}
                                >
                                    <Mention
                                        trigger='#'
                                        data={inputOptions}
                                        markup={mh.MENTION_MARKUP}
                                    />
                                </MentionsInput>
                            </li>
                            <li className='list-inline-item m-0 ml-2'>
                                <button
                                    type='button'
                                    className='btn btn-link btn-icon btn-sm'
                                    autoComplete='off'
                                    onClick={e => { handleRemoveCopyFieldMapping(f, fi) }}
                                >
                                    <i className={'fal fa-minus-circle text-danger'}></i>
                                </button>
                            </li>
                        </ul>
                    }) : <div></div>
            }
            <button
                type='button'
                className='btn btn-link btn-icon-left btn-sm'
                onClick={handleAddCopyFieldMapping}
            >
                <i className='fal fa-plus-circle'></i> Add mapping
            </button>
        </>
    }

    const renderSetFieldMapping = () => {
        return <>
            {
                setFieldsMapping && setFieldsMapping.length > 0 ?
                    setFieldsMapping.map((f, fi) => {
                        return <ul
                            key={`prescription_input_set_field_mapping_${fi}`}
                            className='list-inline no-style m-0 mt-n2 mb-2'
                        >
                            <li className='list-inline-item m-0 option-sm'>
                                <MentionsInput
                                    singleLine
                                    id={`new-prescription-control-set-field-${fi}`}
                                    className='react-mentions-wrapper'
                                    autoComplete='off'
                                    value={f.key ? f.key : ''}
                                    title={mh.getDisplay(f.key)}
                                    onChange={e => { handleSetFieldMappingKeyChange(e, fi) }}
                                >
                                    <Mention
                                        trigger='#'
                                        data={inputOptions}
                                        markup={mh.MENTION_MARKUP}
                                    />
                                </MentionsInput>
                            </li>
                            <li className='list-inline-item mx-2'>=</li>
                            <li className='list-inline-item m-0 option-sm'>
                                <input
                                    id={`new-prescription-control-set-field-value-${fi}`}
                                    type='text'
                                    className='form-control form-control-sm'
                                    autoComplete='off'
                                    value={(f.value ? f.value : '')}
                                    onChange={(e) => handleSetFieldMappingValueChange(e, fi)}
                                />
                            </li>
                            <li className='list-inline-item m-0 ml-2'>
                                <button
                                    type='button'
                                    className='btn btn-link btn-icon btn-sm'
                                    autoComplete='off'
                                    onClick={e => { handleRemoveSetFieldMapping(f, fi) }}
                                >
                                    <i className={'fal fa-minus-circle text-danger'}></i>
                                </button>
                            </li>
                        </ul>
                    }) : <div></div>
            }
            <button
                type='button'
                className='btn btn-link btn-icon-left btn-sm'
                onClick={handleAddSetFieldMapping}
            >
                <i className='fal fa-plus-circle'></i> Add mapping
            </button>
        </>
    }

    const renderOptions = () => {
        return <>
            {
                options && options.length > 0 ?
                    options.map((o, oi) => {
                        return <ul
                            key={`prescription_input_options_${oi}`}
                            className='list-inline no-style m-0 mb-2'
                        >
                            <li className='list-inline-item m-0 mr-1'>{oi + 1}.&nbsp;</li>
                            <li className='list-inline-item m-0 option'>
                                <input
                                    id={`update-prescription-control-${prescriptionControl.data.key}-${oi}`}
                                    type='text'
                                    className='form-control form-control-sm'
                                    autoComplete='off'
                                    value={o}
                                    onChange={(e) => handleOptionChange(e, oi)}
                                />
                            </li>
                            <li className='list-inline-item m-0 ml-2'>
                                <button
                                    type='button'
                                    className='btn btn-link btn-icon btn-sm'
                                    autoComplete='off'
                                    onClick={e => { handleRemoveOption(o, oi) }}
                                >
                                    <i className={'fal fa-minus-circle text-danger'}></i>
                                </button>
                            </li>
                            <li className='list-inline-item m-0'>
                                <button
                                    type='button'
                                    className='btn btn-link btn-icon btn-sm'
                                    autoComplete='off'
                                    disabled={options.length === 1 || oi === 0}
                                    onClick={e => { handleMoveOption(oi, (oi - 1)) }}
                                >
                                    <i className={'fal fa-arrow-up' + (options.length === 1 || oi === 0 ? ' text-gray-500' : ' text-primary')}></i>
                                </button>
                            </li>
                            <li className='list-inline-item m-0'>
                                <button
                                    type='button'
                                    className='btn btn-link btn-icon btn-sm'
                                    disabled={options.length === 1 || oi === (options.length - 1)}
                                    onClick={e => { handleMoveOption(oi, (oi + 1)) }}
                                >
                                    <i className={'fal fa-arrow-down' + (options.length === 1 || oi === (options.length - 1) ? ' text-gray-500' : ' text-primary')}></i>
                                </button>
                            </li>
                        </ul>
                    }) : <div></div>
            }
            <button
                type='button'
                className='btn btn-link btn-icon-left btn-sm'
                onClick={handleAddOption}
            >
                <i className='fal fa-plus-circle'></i> Add option
            </button>
        </>
    }

    const populateMetadata = (field, key, value) => {
        if (tch.hasField(prescriptionControl.data.type, field)) {
            prescriptionControl.setMetadata(key, value);
        } else {
            prescriptionControl.removeMetadata(key);
        }
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form onSubmit={handleSubmit}>
            <fieldset disabled={prescriptionControl.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('pretest-input', 'control')}
                        action='Update'
                        category='Pretest Control'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        <FadeIn>
                            <div className='update-prescription-control body-content'>
                                <section>
                                    {
                                        tch.hasField(prescriptionControl.data.type, tch.TEMPLATE_CONTROL_LABEL) ?
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label htmlFor='new-prescription-control-label'><small>Label</small></label>
                                                        <input
                                                            id='new-prescription-control-label'
                                                            type='text'
                                                            maxLength={100}
                                                            className='form-control'
                                                            value={prescriptionControl.data.label ? prescriptionControl.data.label : ''}
                                                            autoComplete={'off'}
                                                            onChange={handleLabelChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        tch.hasField(prescriptionControl.data.type, tch.TEMPLATE_CONTROL_TEXT) ?
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label htmlFor='new-prescription-control-text'><small>Text</small></label>
                                                        <input
                                                            id='new-prescription-control-text'
                                                            type='text'
                                                            maxLength={100}
                                                            className='form-control'
                                                            value={prescriptionControl.data.text ? prescriptionControl.data.text : ''}
                                                            autoComplete={'off'}
                                                            onChange={handleTextChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        tch.hasField(prescriptionControl.data.type, tch.TEMPLATE_CONTROL_METADATA_SIZE) ?
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label className='required' htmlFor='new-prescription-control-size'><small>Size</small></label>
                                                        <select
                                                            id='new-prescription-control-size'
                                                            className='custom-select form-control'
                                                            value={size ? size : ''}
                                                            onChange={handleSizeChange}
                                                        >
                                                            <option value='sm'>Small</option>
                                                            <option value=''>Standard</option>
                                                            <option value='lg'>Large</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        tch.hasField(prescriptionControl.data.type, tch.TEMPLATE_CONTROL_METADATA_ALIGN) ?
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label className='required' htmlFor='new-prescription-control-align'><small>Align</small></label>
                                                        <div>
                                                            <div className='btn-group'>
                                                                <button
                                                                    type='button'
                                                                    className={'btn btn-secondary' + (align === 'left' ? ' active' : '')}
                                                                    value={(align ? align : '')}
                                                                    onClick={() => { handleAlignChange('left') }}
                                                                >
                                                                    <i className='fal fa-align-left'></i>
                                                                </button>
                                                                <button
                                                                    type='button'
                                                                    className={'btn btn-secondary' + (align === 'center' ? ' active' : '')}
                                                                    value={(align ? align : '')}
                                                                    onClick={() => { handleAlignChange('center') }}
                                                                >
                                                                    <i className='fal fa-align-center'></i>
                                                                </button>
                                                                <button
                                                                    type='button'
                                                                    className={'btn btn-secondary' + (align === 'right' ? ' active' : '')}
                                                                    value={(align ? align : '')}
                                                                    onClick={() => { handleAlignChange('right') }}
                                                                >
                                                                    <i className='fal fa-align-right'></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        tch.hasField(prescriptionControl.data.type, tch.TEMPLATE_CONTROL_METADATA_VALIGN) ?
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label className='required' htmlFor='new-prescription-control-valign'><small>Vertical Align</small></label>
                                                        <div>
                                                            <div className='btn-group'>
                                                                <button
                                                                    type='button'
                                                                    className={'btn btn-secondary' + (valign === 'top' ? ' active' : '')}
                                                                    value={(valign ? valign : '')}
                                                                    onClick={() => { handleValignChange('top') }}
                                                                >
                                                                    <i className='fal fa-arrow-to-top'></i>
                                                                </button>
                                                                <button
                                                                    type='button'
                                                                    className={'btn btn-secondary' + (valign === 'middle' ? ' active' : '')}
                                                                    value={(valign ? valign : '')}
                                                                    onClick={() => { handleValignChange('middle') }}
                                                                >
                                                                    <i className='fal fa-horizontal-rule'></i>
                                                                </button>
                                                                <button
                                                                    type='button'
                                                                    className={'btn btn-secondary' + (valign === 'bottom' ? ' active' : '')}
                                                                    value={(valign ? valign : '')}
                                                                    onClick={() => { handleValignChange('bottom') }}
                                                                >
                                                                    <i className='fal fa-arrow-to-bottom'></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        tch.hasField(prescriptionControl.data.type, tch.TEMPLATE_CONTROL_METADATA_INLINE) ?
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label><small>Inline</small></label>
                                                        <div>
                                                            <div className='custom-control custom-radio custom-control-inline'>
                                                                <input
                                                                    id={`new-prescription-control-inline-true`}
                                                                    type='radio'
                                                                    name='pretest-control-inline'
                                                                    className='custom-control-input'
                                                                    value={true}
                                                                    checked={inline}
                                                                    onChange={handleInlineChange}
                                                                />
                                                                <label
                                                                    htmlFor={`new-prescription-control-inline-true`}
                                                                    className='custom-control-label'
                                                                >
                                                                    Yes
                                                                </label>
                                                            </div>
                                                            <div className='custom-control custom-radio custom-control-inline'>
                                                                <input
                                                                    id={`new-prescription-control-inline-false`}
                                                                    type='radio'
                                                                    name='pretest-control-inline'
                                                                    className='custom-control-input'
                                                                    value={false}
                                                                    checked={!inline}
                                                                    onChange={handleInlineChange}
                                                                />
                                                                <label
                                                                    htmlFor={`new-prescription-control-inline-false`}
                                                                    className='custom-control-label'
                                                                >
                                                                    No
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        tch.hasField(prescriptionControl.data.type, tch.TEMPLATE_CONTROL_METADATA_LINK) ?
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label><small>Link</small></label>
                                                        <div>
                                                            <div className='custom-control custom-radio custom-control-inline'>
                                                                <input
                                                                    id={`new-prescription-control-link-true`}
                                                                    type='radio'
                                                                    name='pretest-control-link'
                                                                    className='custom-control-input'
                                                                    value={true}
                                                                    checked={link}
                                                                    onChange={handleLinkChange}
                                                                />
                                                                <label
                                                                    htmlFor={`new-prescription-control-link-true`}
                                                                    className='custom-control-label'
                                                                >
                                                                    Yes
                                                                </label>
                                                            </div>
                                                            <div className='custom-control custom-radio custom-control-inline'>
                                                                <input
                                                                    id={`new-prescription-control-link-false`}
                                                                    type='radio'
                                                                    name='pretest-control-link'
                                                                    className='custom-control-input'
                                                                    value={false}
                                                                    checked={!link}
                                                                    onChange={handleLinkChange}
                                                                />
                                                                <label
                                                                    htmlFor={`new-prescription-control-link-false`}
                                                                    className='custom-control-label'
                                                                >
                                                                    No
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        tch.hasField(prescriptionControl.data.type, tch.TEMPLATE_CONTROL_METADATA_OPTIONS) ?
                                            <div className='row options'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label className='required' htmlFor='new-prescription-control-options'><small>Options</small></label>
                                                        {renderOptions()}
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        tch.hasField(prescriptionControl.data.type, tch.TEMPLATE_CONTROL_METADATA_EQUATION) ?
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label className='required' htmlFor='new-prescription-control-equation'><small>Equation</small></label>
                                                        <MentionsInput
                                                            singleLine
                                                            className='react-mentions-wrapper'
                                                            value={equation ? equation : ''}
                                                            onChange={handleEquationChange}
                                                        >
                                                            <Mention
                                                                trigger='#'
                                                                data={inputOptions}
                                                                markup={mh.MENTION_MARKUP}
                                                            />
                                                        </MentionsInput>
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        tch.hasField(prescriptionControl.data.type, tch.TEMPLATE_CONTROL_METADATA_TIMESTAMP_DESTINATION) ?
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label className='required' htmlFor='new-prescription-control-timestamp'><small>Map to</small></label>
                                                        <MentionsInput
                                                            singleLine
                                                            className='react-mentions-wrapper'
                                                            value={timestampDestination ? timestampDestination : ''}
                                                            onChange={handleTimestampChange}
                                                        >
                                                            <Mention
                                                                trigger='#'
                                                                data={inputOptions}
                                                                markup={mh.MENTION_MARKUP}
                                                            />
                                                        </MentionsInput>
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        tch.hasField(prescriptionControl.data.type, tch.TEMPLATE_CONTROL_METADATA_OVERWRITE) ?
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label><small>Overwrite existing data</small></label>
                                                        <div>
                                                            <div className='custom-control custom-radio custom-control-link mb-2'>
                                                                <input
                                                                    id={`new-prescription-control-overwrite-true`}
                                                                    type='radio'
                                                                    name='prescription-control-overwrite'
                                                                    className='custom-control-input'
                                                                    value={true}
                                                                    checked={(fn.isNullOrUndefined(overwrite) || overwrite)}
                                                                    onChange={handleOverwriteChange}
                                                                />
                                                                <label
                                                                    htmlFor={`new-prescription-control-overwrite-true`}
                                                                    className='custom-control-label'
                                                                >
                                                                    Yes
                                                                </label>
                                                            </div>
                                                            <div className='custom-control custom-radio custom-control-link'>
                                                                <input
                                                                    id={`new-prescription-control-overwrite-false`}
                                                                    type='radio'
                                                                    name='prescription-control-overwrite'
                                                                    className='custom-control-input'
                                                                    value={false}
                                                                    checked={overwrite === false}
                                                                    onChange={handleOverwriteChange}
                                                                />
                                                                <label
                                                                    htmlFor={`new-prescription-control-overwrite-false`}
                                                                    className='custom-control-label'
                                                                >
                                                                    No
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        tch.hasField(prescriptionControl.data.type, tch.TEMPLATE_CONTROL_METADATA_COPY_FIELDS_OPTIONS) ?
                                            <>
                                                <div className='row options'>
                                                    <div className='col-12'>
                                                        <div className='form-group mb-4'>
                                                            <label className='required' htmlFor='new-prescription-control-options'><small>Field mappings</small></label>
                                                            {renderCopyFieldMapping()}
                                                        </div>
                                                    </div>
                                                </div>
                                            </> : null
                                    }
                                    {
                                        tch.hasField(prescriptionControl.data.type, tch.TEMPLATE_CONTROL_METADATA_SET_FIELDS_OPTIONS) ?
                                            <>
                                                <div className='row options'>
                                                    <div className='col-12'>
                                                        <div className='form-group mb-4'>
                                                            <label className='required' htmlFor='new-prescription-control-options'><small>Field mappings</small></label>
                                                            {renderSetFieldMapping()}
                                                        </div>
                                                    </div>
                                                </div>
                                            </> : null
                                    }
                                </section>
                            </div>
                        </FadeIn>
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-4'>
                                <button
                                    type='button'
                                    className='btn btn-link px-0'
                                    onClick={handlePreviewClick}
                                >Preview</button>
                            </div>
                            <div className='col-8'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
        <BodyEnd>
            <div className={'modal fade d-block' + (displayPreview ? ' show' : '')} tabIndex='-1' role='dialog' aria-modal='true'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>
                                Quick Preview
                                <small className='m-0 text-gray-700'>Below is the preview of your <code>{prescriptionControl.data.type}</code> input.</small>
                            </h5>
                            <button
                                type='button'
                                className='close'
                                data-dismiss='modal'
                                aria-label='Close'
                                onClick={handleClosePreviewClick}
                            >
                                <span aria-hidden='true'><i className='fal fa-times'></i></span>
                            </button>
                        </div>
                        <div className='modal-body pb-0'>
                            {tch.renderControl(prescriptionControl.data, { isDesignMode: true })}
                        </div>
                    </div>
                </div>
            </div>
            <div className={'modal-backdrop fade' + (displayPreview ? ' show' : '')} onClick={handleClosePreviewClick}></div>
        </BodyEnd>
    </>)
}

export default UpdateExistingPretestControl;