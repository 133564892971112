import React, { useContext, useEffect, useRef } from 'react';
import { useObserver } from 'mobx-react-lite';
import { GlobalHotKeys, configure } from 'react-hotkeys';

import { showSwitchPinScreen, hideSwitchPinScreen } from '../_shared/SwitchPinScreen';
import { searchbarFocus, searchbarBlur } from '../_shared/Searchbar';
import AuthStore from '../../../stores/AuthStore';
import SearchBarStore from '../../../stores/SearchBarStore';

import * as fn from '../../../utilities/_functions';
import * as StorageKeys from '../../../constants/storageKeys';

import api from '../../../api';

configure({
    ignoreTags: [],
})

function HotKeys() {
    const isMounted = useRef(true);
    const cancelLockscreenActivateRef = useRef();
    const auth = useContext(AuthStore);
    const search = useContext(SearchBarStore);

    useEffect(() => {
        return () => {
            isMounted.current = false;
            search.clear();
            if (fn.isFunction(cancelLockscreenActivateRef.current)) { cancelLockscreenActivateRef.current(); cancelLockscreenActivateRef.current = null; }
        }
    }, []) // eslint-disable-line

    const activateLockScreen = event => {
        event.preventDefault();
        search.isActivated = false;
        api.Lockscreen.activate(window.localStorage.getItem(StorageKeys.SESSION_ID), (c) => { cancelLockscreenActivateRef.current = c });
        hideSwitchPinScreen();
    }
    const activateSearchbar = event => {
        event.preventDefault();
        search.activateSearchbar()
            .then(() => {
                if (isMounted.current) {
                    searchbarFocus();
                }
            })
    }
    const deactivateSearchbar = event => {
        search.deactivateSearchbar()
            .then(() => {
                if (isMounted.current) {
                    searchbarBlur();
                }
            })
    }
    const defaultKeyMap = {
        // activateLockScreen: ['ctrl+l'],
        activateSearchbar: ['ctrl+f'],
    }
    const defaultHandler = {
        activateLockScreen: activateLockScreen,
        activateSearchbar: activateSearchbar,
    };
    const searchbarMap = {
        // activateLockScreen: ['ctrl+l'],
        deactivateSearchbar: ['esc'],
    }
    const searchbarHandler = {
        activateLockScreen: activateLockScreen,
        deactivateSearchbar: deactivateSearchbar,
    };
    const lockedKeyMap = {
        activateSwitchPinScreen: ['esc', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'enter'],
    }
    const lockedHandler = {
        activateSwitchPinScreen: event => {
            showSwitchPinScreen();

            if (!auth.switchPin) {
                const code = event.which || event.keyCode;
                if (code >= 48 && code <= 57) {
                    auth.switchPin = String.fromCharCode(code);
                }
            }

            auth.activateSwitchPinScreen();
        },
    }
    const switchPinKeyMap = {
        // activateLockScreen: ['ctrl+l'],
        deactivateSwitchPinScreen: ['esc'],
    }
    const switchPinHandler = {
        activateLockScreen: activateLockScreen,
        deactivateSwitchPinScreen: event => {
            auth.deactivateSwitchPinScreen();
            hideSwitchPinScreen();

            setTimeout(() => {
                auth.switchPin = null;
            }, 550);
        }
    }
    const keyMapSelector = () => {
        if (search.isActivated) {
            return searchbarMap;
        }

        if (auth.isSwitchPinActivated) {
            return switchPinKeyMap;
        }

        if (auth.isLocked) {
            return lockedKeyMap;
        }

        return defaultKeyMap;
    }

    const handlersSelector = () => {
        if (search.isActivated) {
            return searchbarHandler;
        }

        if (auth.isSwitchPinActivated) {
            return switchPinHandler;
        }

        if (auth.isLocked) {
            return lockedHandler;
        }

        return defaultHandler;
    }

    return useObserver(() => <>
        <GlobalHotKeys
            keyMap={keyMapSelector()}
            handlers={handlersSelector()}
            allowChanges={true}
        />
    </>)
}

export default HotKeys;