import moment from "moment";
import api from "../../../../api";
import { lazy } from "react";
export const NULL_USER_ID = 'NULL_USER_ID';
export const ALL_USER_ID = 'ALL_USER_ID';
export const NULL_PRODUCT_TYPE = 'NULL_PRODUCT_TYPE';
export const ALL_PRODUCT_TYPE = 'ALL_PRODUCT_TYPE';
export const ALL_SERVICE_CODE = 'ALL_SERVICE_CODE';
export const NULL_AGE_GROUP = 'NULL_AGE_GROUP';

export const ConversionWindows = ['D0', 'D3', 'D7', 'D30', 'D60'];
export const ConversionWindowLabels = {
    D0: 'Same Day',
    D3: "Less than 3 Days",
    D7: "Less than 7 Days",
    D30: 'Less than 30 days',
    D60: 'Less than 60 days'
};

export const ReportTier = {
    Essential: 'Essential',
    Growth: 'Growth',
    Custom: 'Custom'
}

export const ReportTag = {
    Revenue: 'Revenue',
    Sales: 'Sales',
    Total: 'Total',
    DoctorDropdown: 'DoctorDropdown',
    ProductServiceSeries: 'ProductServiceSeries',
    PublicPrivateSeries: 'PublicPrivateSeries',
    PublicPrivateDropdown: 'PublicPrivateDropdown',
    ProductTypeSeries: 'ProductTypeSeries',
    ProductTypeDropdown: 'ProductTypeDropdown',
    ProductBrandSeries: 'ProductBrandSeries',
    ServiceCodeSeries: 'ServiceCodeSeries',
    MissingUnitCost: 'MissingUnitCost',
    CreditCardSeries: 'CreditCardSeries',
    PaymentMethodTypeSeries: 'PaymentMethodTypeSeries',
    BookedAppointment: 'BookedAppointment',
    AppointmentStatusSeries: 'AppointmentStatusSeries',
    BookedExam: 'BookedExam',
    CompletedExam: 'CompletedExam',
    Email: "Email",
    SMS: "SMS",
    Fax: "Fax",
    TimeframeControl: "TimeframeControl"
}

const examAgeGroup = {
    'under-20': 'Under 20',
    '21-64': '21 to 64',
    'over-65': '65 and up'
}
export const ExamAgeGroups = [ ...Object.entries(examAgeGroup).map(([key, value]) => ({
    id: key,
    text: value,
})), { id: NULL_AGE_GROUP, text: 'Unknown' } ];

const generalAgeGroup = {
    '1-3': 'Toddlers: 1-3 years',
    '3-5': 'Preschoolers: 3-5 years',
    '6-12': 'Children: 6-12 years',
    '13-19': 'Teenagers: 13-19 years',
    '20-35': 'Young Adults: 20-35 years',
    '36-55': 'Middle-Aged Adults: 36-55 years',
    '56-64': 'Older Adults: 56-64 years',
    'over-65': 'Seniors: 65 years and above'
}
export const GeneralAgeGroups = [ ...Object.entries(generalAgeGroup).map(([key, value]) => ({
    id: key,
    text: value,
})), { value: NULL_AGE_GROUP, label: 'Unknown' }];

const ReportComponent = {
    SalesRevenueTotalReport: lazy(() => import('../financial/sales-revenue/sale-revenue-breakdown/_index')),
    SalesRevenueProductTotalReport: lazy(() => import('../financial/sales-revenue/sales-revenue-product-revenue-breakdown/_index')),
    ExamProductSalesConversionRateReport: lazy(() => import('../financial/sales-revenue/exam-product-sales-conversion-rate/_index')),
    ExamSalesRevenueReport: lazy(() => import('../financial/sales-revenue/exam-sales-revenue/_index')),
    NewVsReturnCustomerReport: lazy(() => import('../operational/customer/new-vs-return/_index')),
    CustomerLocationReport: lazy(() => import('../operational/customer/customer-location/_index')),
    CostOfGoodsSoldReport: lazy(() => import('../financial/expense/cost-of-goods-sold/_index')),
    AppointmentStatusBreakdownReport: lazy(() => import('../operational/appointment/appointment-status-breakdown/_index')),
    CashFlowReport: lazy(() => import('../financial/cash-flow/cash-flow-summary/_index')),
    InvoiceAgingSummaryReport: lazy(() => import('../financial/cash-flow/invoice-aging-summary/_index')),
    AppointmentArrivalTimeReport: lazy(() => import('../operational/appointment/appointment-arrival-time/_index')),
    ExamStatusBreakdownReport: lazy(() => import('../operational/exam/exam-status-breakdown/_index')),
    CustomerDemographicsReport: lazy(() => import('../operational/customer/customer-demographics/_index')),
    ScheduleUtilizationReport: lazy(() => import('../operational/schedule-utilization/_index')),
    CommunicationUsageReport: lazy(() => import('../administrative/communication-usage/_index')),
    ProfitMarginGoodsSold: lazy(() => import('../financial/profit-margin/profit-margin-goods-sold/_index')),
    CustomerExamIntervalsReport: lazy(() => import('../operational/recall/customer-exam-interval/_index')),
}

export const ReportDefinition = {
    Home: {
        path: '/reports'
    },
    TotalSales: {
        path: '/reports/total-sales',
        title: 'Total Sales',
        description: 'Sum of all units sold across the practice in a given period of time',
        tier: ReportTier.Essential,
        tags: [ReportTag.Total],
        component: ReportComponent.SalesRevenueTotalReport,
        fnSearch: api.Reports.SalesRevenueBreakdown.search
    },
    SalesByProductService: {
        path: '/reports/sales-by-product-service',
        title: 'Sales by Product vs. Service',
        description: '',
        tier: ReportTier.Essential,
        tags: [ReportTag.DoctorDropdown, ReportTag.ProductServiceSeries],      // Tags are modifier for the report. E.g. if "Doctor" tag is present, then the report will generate the "doctor" dropdown
        component: ReportComponent.SalesRevenueTotalReport,
        fnSearch: api.Reports.SalesRevenueBreakdown.search
    },
    SalesByPublicPrivate: {
        path: '/reports/sales-by-public-private',
        title: 'Sales by OHIP vs. non-OHIP Service',
        description: '',
        tier: ReportTier.Essential,
        tags: [ReportTag.Sales, ReportTag.DoctorDropdown, ReportTag.PublicPrivateSeries],
        component: ReportComponent.SalesRevenueTotalReport,
        fnSearch: api.Reports.SalesRevenueBreakdown.search
    },
    SalesByServiceCode: {
        path: '/reports/sales-by-service-code',
        title: 'Sales by Service Code',
        description: '',
        tier: ReportTier.Growth,
        tags: [ReportTag.Sales, ReportTag.DoctorDropdown, ReportTag.ServiceCodeSeries, ReportTag.PublicPrivateDropdown],
        component: ReportComponent.SalesRevenueTotalReport,
        fnSearch: api.Reports.SalesRevenueBreakdown.search
    },
    SalesByProductType: {
        path: '/reports/sales-by-product-type',
        title: 'Sales by Product Type',
        description: '',
        tier: ReportTier.Growth,
        tags: [ReportTag.Sales, ReportTag.DoctorDropdown, ReportTag.ProductTypeSeries],
        component: ReportComponent.SalesRevenueTotalReport,
        fnSearch: api.Reports.SalesRevenueBreakdown.search
    },
    SalesByProductBrand: {
        path: '/reports/sales-by-product-brand',
        title: 'Sales by Product Brand',
        description: '',
        tier: ReportTier.Growth,
        tags: [ReportTag.Sales, ReportTag.DoctorDropdown, ReportTag.ProductTypeSeries],
        component: ReportComponent.SalesRevenueProductTotalReport,
        fnSearch: api.Reports.SalesRevenueProductBreakdown.search
    },

    RevenuePerExamVisit: {
        path: '/reports/revenue-per-exam-visit',
        title: 'Revenue Per Exam Visit',
        description: '',
        tier: ReportTier.Growth,
        tags: [ReportTag.DoctorDropdown],
        component: ReportComponent.ExamSalesRevenueReport,
        fnSearch: api.Reports.ExamSalesRevenue.search
    },
    ExamConversionRate: {
        path: '/reports/exam-conversion-rate',
        title: 'Exam Conversion Rate',
        description: '',
        tier: ReportTier.Growth,
        tags: [ReportTag.DoctorDropdown],
        component: ReportComponent.ExamProductSalesConversionRateReport,
        fnSearch: api.Reports.ExamSalesConversionRate.search
    },
    ExamProductConversionRate: {
        path: '/reports/exam-product-conversion-rate',
        title: 'Exam Product Conversion Rate',
        description: '',
        tier: ReportTier.Growth,
        tags: [ReportTag.DoctorDropdown, ReportTag.ProductTypeSeries],
        component: ReportComponent.ExamProductSalesConversionRateReport,
        fnSearch: api.Reports.ExamSalesConversionRate.search
    },
    CostOfGoodsSold: {
        path: '/reports/cost-of-goods-sold',
        title: 'Cost of Goods Sold',
        description: '',
        tier: ReportTier.Essential,
        tags: [],
        component: ReportComponent.CostOfGoodsSoldReport,
        fnSearch: api.Reports.ProductSales.search
    },
    MissingCostOfGoodsSold: {
        path: '/reports/missing-cost-of-goods-sold',
        title: 'Missing Cost of Goods Sold',
        description: '',
        tier: ReportTier.Essential,
        tags: [ReportTag.MissingUnitCost],
        component: ReportComponent.CostOfGoodsSoldReport,
        fnSearch: api.Reports.ProductSales.search
    },
    CostOfGoodsSoldByProductType: {
        path: '/reports/cost-of-goods-sold-by-product-type',
        title: 'Cost of Goods Sold by Product Type',
        description: '',
        tier: ReportTier.Growth,
        tags: [ReportTag.ProductTypeSeries],
        component: ReportComponent.CostOfGoodsSoldReport,
        fnSearch: api.Reports.ProductSales.search
    },
    ProfitMarginGoodsSold: {
        path: '/reports/profit-margin-goods-sold',
        title: 'Profit Margin of Goods Sold',
        description: '',
        tier: ReportTier.Essential,
        tags: [],
        component: ReportComponent.ProfitMarginGoodsSold,
        fnSearch: api.Reports.ProductSales.search
    },
    ProfitMarginByProductType: {
        path: '/reports/profit-margin-by-product-type',
        title: 'Profit Margin by Product Type',
        description: '',
        tier: ReportTier.Growth,
        tags: [ReportTag.ProductTypeSeries],
        component: ReportComponent.ProfitMarginGoodsSold,
        fnSearch: api.Reports.ProductSales.search
    },
    // CostOfGoodsSoldByProductBrand: {
    //     path: '/reports/cost-of-goods-sold-by-product-brand',
    //     title: 'Cost of Goods Sold by Product Brand',
    //     description: '',
    //     tier: ReportTier.Growth,
    //     tags: [ReportTag.ProductTypeDropdown, ReportTag.ProductBrandSeries]
    // },
    NewVsReturnCustomer: {
        path: '/reports/new-vs-return-customer',
        title: 'New vs Return Customer',
        description: '',
        tier: ReportTier.Essential,
        tags: [],
        component: ReportComponent.NewVsReturnCustomerReport
    },
    CustomerLocation: {
        path: '/reports/customer-location',
        title: 'Customer Location',
        description: '',
        tier: ReportTier.Growth,
        tags: [],
        component: ReportComponent.CustomerLocationReport
    },
    CustomerAge: {
        path: '/reports/customer-age',
        title: 'Customer Age',
        description: '',
        tier: ReportTier.Essential,
        tags: [],
        component: ReportComponent.CustomerDemographicsReport,
        fnSearch: api.Reports.CustomerDemographics.search
    },
    CashFlowSummary: {
        path: '/reports/cash-flow-summary',
        title: 'Cash Flow Summary',
        description: '',
        tier: ReportTier.Essential,
        tags: [ReportTag.PaymentMethodTypeSeries],
        component: ReportComponent.CashFlowReport,
        fnSearch: api.Reports.CashFlow.search
    },
    InvoiceAgingSummary: {
        path: '/reports/invoice-aging-summary',
        title: 'Invoice Aging Summary',
        description: '',
        tier: ReportTier.Essential,
        tags: [],
        component: ReportComponent.InvoiceAgingSummaryReport,
        fnSearch: api.Reports.InvoiceAgingSummary.search
    },
    CreditCardBreakdown: {
        path: '/reports/credit-card-breakdown',
        title: 'Credit Card Breakdown',
        description: '',
        tier: ReportTier.Growth,
        tags: [ReportTag.CreditCardSeries],
        component: ReportComponent.CashFlowReport,
        fnSearch: api.Reports.CashFlow.search
    },
    AppointmentBooked: {
        path: '/reports/appointment-booked',
        title: 'Appointment Booked',
        description: '',
        tier: ReportTier.Essential,
        tags: [ReportTag.BookedAppointment],
        component: ReportComponent.AppointmentStatusBreakdownReport,
        fnSearch: api.Reports.AppointmentStatusBreakdown.search
    },
    AppointmentStatus: {
        path: '/reports/appointment-status',
        title: 'Appointment Status',
        description: '',
        tier: ReportTier.Essential,
        tags: [ReportTag.AppointmentStatusSeries],
        component: ReportComponent.AppointmentStatusBreakdownReport,
        fnSearch: api.Reports.AppointmentStatusBreakdown.search
    },
    ExamBooked: {
        path: '/reports/exam-booked',
        title: 'Exam Booked',
        description: '',
        tier: ReportTier.Essential,
        tags: [ReportTag.BookedExam],
        component: ReportComponent.ExamStatusBreakdownReport,
        fnSearch: api.Reports.ExamStatusBreakdown.search
    },
    ExamCompleted: {
        path: '/reports/exam-completed',
        title: 'Exam Completed',
        description: '',
        tier: ReportTier.Essential,
        tags: [ReportTag.CompletedExam],
        component: ReportComponent.ExamStatusBreakdownReport,
        fnSearch: api.Reports.ExamStatusBreakdown.search
    },
    AppointmentArrivalTime: {
        path: '/reports/appointment-arrival-time',
        title: 'On-time vs. Late Arrival',
        description: '',
        tier: ReportTier.Essential,
        tags: [],
        component: ReportComponent.AppointmentArrivalTimeReport,
        fnSearch: api.Reports.AppointmentArrivalTime.search
    },
    ScheduleUtilization: {
        path: '/reports/schedule-utilization',
        title: 'Schedule Utilization',
        description: '',
        tier: ReportTier.Essential,
        tags: [],
        component: ReportComponent.ScheduleUtilizationReport,
        fnSearch: api.Reports.ScheduleUtilization.search
    },
    EmailSent: {
        path: '/reports/email-sent',
        title: 'Email Sent',
        description: '',
        tier: ReportTier.Essential,
        tags: [ReportTag.Email],
        component: ReportComponent.CommunicationUsageReport,
        fnSearch: api.Reports.CommunicationUsage.search
    },
    SMSSent: {
        path: '/reports/sms-sent',
        title: 'SMS Sent',
        description: '',
        tier: ReportTier.Essential,
        tags: [ReportTag.SMS],
        component: ReportComponent.CommunicationUsageReport,
        fnSearch: api.Reports.CommunicationUsage.search
    },
    FaxSent: {
        path: '/reports/fax-sent',
        title: 'Fax Sent',
        description: '',
        tier: ReportTier.Essential,
        tags: [ReportTag.Fax],
        component: ReportComponent.CommunicationUsageReport,
        fnSearch: api.Reports.CommunicationUsage.search
    },
    MonthsBetweenFullExam : {
        path: '/reports/months-between-full-exam',
        title: 'Months Between Full Exam',
        description: '',
        tier: ReportTier.Essential,
        tags: [],
        component: ReportComponent.CustomerExamIntervalsReport,
        fnSearch: api.Reports.CustomerExamInterval.search
    },
    MonthsSinceLastFullExam: {
        path: '/reports/months-since-last-full-exam',
        title: 'Months since Last Full Exam',
        description: '',
        tier: ReportTier.Essential,
        tags: [ReportTag.TimeframeControl],
        component: ReportComponent.CustomerExamIntervalsReport,
        fnSearch: api.Reports.CustomerExamInterval.search
    },
}

export const compareToLastYearByRatio = (currTotal, prevTotal) => {
    return `(${(currTotal >= prevTotal ? '+' : '') + ((currTotal - prevTotal) * 100 / prevTotal).toFixed(1)}% Compared to Previous Year)`;
}

export const compareToLastYearByDiff = (currRate, prevRate) => {
    return `(${(currRate >= prevRate ? '+' : '') + ((currRate - prevRate) * 100).toFixed(1)}% Compared to Previous Year)`;
}

// I use symbols like 'D0' to represent 'Same day', 'D30' => within 30 days. Here is the actual logic to map to date range
export const mapDaysToConversionWindow = (dateDiff) => {
    let mapToWins = [];
    if (dateDiff === 0) {
        mapToWins.push('D0');
    }
    if (dateDiff < 3) {
        mapToWins.push('D3');
    }
    if (dateDiff < 7) {
        mapToWins.push('D7');
    }
    if (dateDiff < 30) {
        mapToWins.push('D30');
    }
    if (dateDiff < 60) {
        mapToWins.push('D60');
    }
    return mapToWins;
}

export const GrossRevenueReportSubtype = {
    Total: "Total",
    ProductService: "ProductService",
    ProductServiceByDoctor: "ProductServiceByDoctor",
    Category: "Category",
    CategoryByDoctor: "CategoryByDoctor"
}

export const ExamProductSalesReportSubtype = {
    Overall: "Overall",
    OverallByDoctor: "OverallByDoctor",
    CategoryByDoctor: "CategoryByDoctor"
}

export const ExamSalesRevenueSubtype = {
    Overall: "Overall",
    OverallByDoctor: "OverallByDoctor",
    Category: "Category",
    CategoryByDoctor: "CategoryByDoctor"
}

export function calculateDatesByTimeFrame(timeframe, startDate, endDate) {
    var currentDate = moment().startOf('day');

    let prevEndDate = null, prevStartDate = null;
    let periodAggregate = '';
    //console.log('timeframe', timeframe, startDate.toDate(), endDate.toDate());
    switch (timeframe) {
        case 'lastMonth':
            startDate = currentDate.clone().subtract(1, 'month').startOf('month');
            endDate = currentDate.clone().subtract(1, 'month').endOf('month');
            periodAggregate = 'day';
            break;

        case 'monthBeforeLast':
            startDate = currentDate.clone().subtract(2, 'month').startOf('month');
            endDate = currentDate.clone().subtract(2, 'month').endOf('month');
            periodAggregate = 'day';
            break;

        case 'q1':
            startDate = currentDate.clone().startOf('year');  // Sets to January 1st of the current year
            endDate = currentDate.clone().startOf('year').add(2, 'month').endOf('month');  // Sets to March 31st of the current year
            periodAggregate = 'month';
            break;

        case 'q2':
            startDate = currentDate.clone().startOf('year').add(3, 'month'); // April 1st
            endDate = currentDate.clone().startOf('year').add(5, 'month').endOf('month'); // June 30th
            periodAggregate = 'month';
            break;

        case 'q3':
            startDate = currentDate.clone().startOf('year').add(6, 'month'); // July 1st
            endDate = currentDate.clone().startOf('year').add(8, 'month').endOf('month'); // September 30th
            periodAggregate = 'month';
            break;

        case 'q4':
            startDate = currentDate.clone().startOf('year').add(9, 'month'); // October 1st
            endDate = currentDate.clone().startOf('year').add(11, 'month').endOf('month'); // December 31st
            periodAggregate = 'month';
            break;

        case 'h1':
            startDate = currentDate.clone().startOf('year'); // January 1st
            endDate = currentDate.clone().startOf('year').add(5, 'month').endOf('month'); // June 30th
            periodAggregate = 'month';
            break;

        case 'h2':
            startDate = currentDate.clone().startOf('year').add(6, 'month'); // July 1st
            endDate = currentDate.clone().startOf('year').add(11, 'month').endOf('month'); // December 31st
            periodAggregate = 'month';
            break;

        case 'yearToDate':
            startDate = currentDate.clone().startOf('year');
            endDate = currentDate.clone().subtract(1, 'day');
            periodAggregate = 'month';
            break;

        case 'monthToDate':
            startDate = currentDate.clone().startOf('month');
            endDate = currentDate.clone().subtract(1, 'day');
            periodAggregate = 'day';
            break;


        case 'last12Months':
            startDate = currentDate.clone().subtract(1, 'year').startOf('month');
            endDate = currentDate.clone().subtract(1, 'month').endOf('month');
            periodAggregate = 'month';
            break;

        case 'last6Months':
            startDate = currentDate.clone().subtract(6, 'month').startOf('month');
            endDate = currentDate.clone().subtract(1, 'month').endOf('month');
            periodAggregate = 'month';
            break;

        case 'dateRange':
            var dayDiff = Math.abs(startDate.diff(endDate, 'day'));
            if (dayDiff < 14)
                periodAggregate = 'day';
            else if (dayDiff < 60)
                periodAggregate = 'week';
            else
                periodAggregate = 'month';
            break;

        default:
            break;
    }

    prevEndDate = endDate.clone().subtract(1, 'year');
    prevStartDate = startDate.clone().subtract(1, 'year');

    return [startDate, endDate, prevStartDate, prevEndDate, periodAggregate];
}

export function breakToPeriods(groupByPeriod, startDate, endDate) {
    // Parse the start and end dates using moment.js
    let start = moment(startDate).startOf('day'); // zero out the time
    const end = moment(endDate).startOf('day');

    // This array will hold the resulting periods
    const periods = [];

    // Iterate over the range, breaking it into periods
    while (start.isSameOrBefore(end)) {
        const periodStart = start.clone();
        const nextPeriodStart = start.add(1, groupByPeriod);
        // Adjust the end date of the last period to not exceed the overall endDate
        const periodEnd = nextPeriodStart.clone();

        periods.push([periodStart.toDate(), periodEnd.toDate()]);

        // Move start to the next period start for the next iteration
        start = nextPeriodStart;
    }

    return periods;
}

export function findPeriodForDate(periods, checkDate) {
    // Parse the checkDate using moment.js for comparison
    const dateToCheck = moment(checkDate).startOf('day'); // zero out the time

    // Find the period that is within [startDate, endDate) - inclusive startDate, exclusive endDate
    // Use the find method to search for the period
    const foundPeriod = periods.find(([periodStart, periodEnd]) => {
        // Convert period dates to moment objects for comparison
        const start = moment(periodStart);
        const end = moment(periodEnd);

        // Return true if dateToCheck falls within the current period
        return dateToCheck.isSameOrAfter(start) && dateToCheck.isBefore(end);
    });

    // foundPeriod will be undefined if no matching period is found
    return foundPeriod; // This will return the period itself or undefined
}

export const formatCurrency = (value, numDecimals = 2) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: numDecimals, maximumFractionDigits: numDecimals
    });

    return formatter.format(value);
}

export const formatDecimal = (value, numDecimals = 0) => {
    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: numDecimals, maximumFractionDigits: numDecimals
    });

    return formatter.format(value);
}

export const getPeriodAxisLabel = (periodAggregate, date) => {
    return periodAggregate === 'day' ? moment(date).format('MMM DD, YY') :
        periodAggregate === 'week' ? 'Week of ' + moment(date).format('MMM DD, YY') :
            periodAggregate === 'month' ? moment(date).format('MMM YYYY') : moment(date).format('dd MMM YY')
}

export const hasTimeframeControl = (reportDefn) => {
    return reportDefn.tags.includes(ReportTag.TimeframeControl);
}
export const hasDoctorDropdown = (reportDefn) => {
    return reportDefn.tags.includes(ReportTag.DoctorDropdown);
}
export const hasProductServiceSeries = (reportDefn) => {
    return reportDefn.tags.includes(ReportTag.ProductServiceSeries);
}
export const hasProductTypeSeries = (reportDefn) => {
    return reportDefn.tags.includes(ReportTag.ProductTypeSeries);
}
export const hasProductBrandSeries = (reportDefn) => {
    return reportDefn.tags.includes(ReportTag.ProductBrandSeries);
}
export const hasPublicPrivateDropdown = (reportDefn) => {
    return reportDefn.tags.includes(ReportTag.PublicPrivateDropdown);
}
export const hasPublicPrivateSeries = (reportDefn) => {
    return reportDefn.tags.includes(ReportTag.PublicPrivateSeries);
}
export const hasServiceCodeSeries = (reportDefn) => {
    return reportDefn.tags.includes(ReportTag.ServiceCodeSeries);
}
export const hasServiceCodeTypeDropdown = (reportDefn) => {
    return reportDefn.tags.includes(ReportTag.ServiceCodeTypeDropdown);
}
export const isMissingUnitCost = (reportDefn) => {
    return reportDefn.tags.includes(ReportTag.MissingUnitCost);
}
export const isSales = (reportDefn) => {
    return reportDefn.tags.includes(ReportTag.Sales);
}
export const isTotal = (reportDefn) => {
    return reportDefn.tags.includes(ReportTag.Total);
}
export const isBookedAppointment = (reportDefn) => {
    return reportDefn.tags.includes(ReportTag.BookedAppointment);
}
export const hasAppointmentStatusSeries = (reportDefn) => {
    return reportDefn.tags.includes(ReportTag.AppointmentStatusSeries);
}
export const hasPaymentMethodTypeSeries = (reportDefn) => {
    return reportDefn.tags.includes(ReportTag.PaymentMethodTypeSeries);
}
export const hasCreditCardSeries = (reportDefn) => {
    return reportDefn.tags.includes(ReportTag.CreditCardSeries);
}
export const isBookedExam = (reportDefn) => {
    return reportDefn.tags.includes(ReportTag.BookedExam);
}
export const isCompletedExam = (reportDefn) => {
    return reportDefn.tags.includes(ReportTag.CompletedExam);
}
export const isEmail = (reportDefn) => {
    return reportDefn.tags.includes(ReportTag.Email);
}
export const isSMS = (reportDefn) => {
    return reportDefn.tags.includes(ReportTag.SMS);
}
export const isFax = (reportDefn) => {
    return reportDefn.tags.includes(ReportTag.Fax);
}


export const roundUpNextHigher = (num) => {
    // mainly used by the chart to find out what the range of Y-axis should be
    // To round a number to the next higher number that has zeros in all places except for the first digit.
    if (num === 0) return 0; // Handle zero directly

    const isNegative = num < 0;
    const absNum = Math.abs(num);

    if (absNum < 10) return isNegative ? -10 : 10; // Handling single-digit cases

    const length = Math.floor(Math.log10(absNum + 1)); // Get the number of digits of the absolute value
    const power = Math.pow(10, length - 1); // Compute the rounding factor
    console.log('length power', absNum, length, power);
    const rounded = Math.ceil(absNum / power) * power; // Round up the absolute value

    return isNegative ? -rounded : rounded; // Return negative if the original number was negative
}

// In some cases, I use this function to sort just the product types, i.e. when the data to be sorted has no services in it.
export const serviceProductTypeSort = (allProductTypes, a, b) => {
    // I want to sort them so that 'Subsidized Service', 'Service' comes out at the top and the rest are just sorted alphabetically
    const priority = {
        "Subsidized Service": 1,
        "Service": 2
    };

    // Check if both items have special priorities
    if (priority[a] && priority[b]) {
        return priority[a] - priority[b];
    }

    // If only one of the items has a special priority
    if (priority[a]) return -1; // a has priority, so it should come first
    if (priority[b]) return 1;  // b has priority, so a should come after b

    // If neither item has a special priority, sort alphabetically
    const getOrder = typeName => {
        const productType = allProductTypes.find(c => c.name === typeName);
        if (productType)
            return productType.displayOrder;

        return 32768;
    }
    return getOrder(a) - getOrder(b);
}

export const toTitleCase = (phrase) => {
    // capitalize first letter of each word
    return phrase
        .toLowerCase()  // Optional: Ensuring the rest of the words are lowercase
        .split(' ')  // Split the phrase into words
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))  // Capitalize the first letter of each word
        .join(' ');  // Join the words back into a single string
}