import React from 'react';
import * as fn from '../../../utilities/_functions';

import './LoadingOverlay.scss';

function LoadingOverlay(props) {
    const renderLoadingOverlay = (position) => {
        return <div className={'loading-overlay' + (props.isLoading ? '' : ' d-none')} style={{ position: !!position ? position : 'fixed', zIndex: fn.getHighestZIndex() + 1 }}>
            {
                props.spinner && props.spinner === 'horizontal' ?
                    <div className='h-spinner-container'>
                        <div className='spinner-horizontal'>
                            <div className='spinner'></div>
                        </div>
                    </div> :
                    <div className='spinner-border text-primary' style={{ width: '5rem', height: '5rem' }} role='status'>
                        <span className='sr-only'>Loading...</span>
                    </div>
            }
            {
                props.message ?
                    <div className='loading-wrapper'>
                        <div className='loading-message'>
                            {props.message}
                        </div>
                    </div> : null
            }
        </div>
    }

    return !!props.relativeToPageContent ?
        <div className={'loading-relative-container' + (props.isLoading ? '' : ' d-none')} style={{ zIndex: fn.getHighestZIndex() }}>
            {renderLoadingOverlay('absolute')}
        </div> : renderLoadingOverlay('fixed')

}

export default LoadingOverlay;