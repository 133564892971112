import React from 'react';
import moment from 'moment';

import { downloadFile, printFile } from '../components/views/_shared/FileViewer';

import api from '../api';
import * as bh from './badgeHelper';
import * as dg from './dx/dataGridHelper';

export const PRINT_MESSAGE = <>
    <h2 className='text-uppercase text-center font-weight-bold text-gray-900 mb-1'>Generating...</h2>
    <p className='fs-xl'>Please wait while we generate the invoice.</p>
</>;

export const isEndState = purchase => {
    return purchase && ['Completed', 'Refunded'].some(s => s === purchase.status) && !purchase.isNoCharge
}

export const getLatestInvoiceAndPaymentReceipt = (purchase, isGeneratingPrintCallback, reprint = false) => {
    return new Promise((resolve) => {
        if (purchase) {
            if (purchase.payments && purchase.payments.length > 0) {
                const paymentReceiptOption = {
                    documentType: 'PaymentReceipt',
                    customerId: purchase.customer.id,
                    referenceId1: purchase.id,
                    referenceId2: purchase.transactions[purchase.transactions.length - 1].id,
                    referenceId3: purchase.lastPayment ? purchase.lastPayment.id : null,
                    referenceDateTimeUtc: purchase.lastPayment ? moment(purchase.lastPaymentDate).utc() : null,
                };

                api.PrintedDocuments.search({
                    parameters: [
                        { field: 'Type', value: paymentReceiptOption.documentType },
                        { field: 'ReferenceId1', value: paymentReceiptOption.referenceId1 },
                        { field: 'ReferenceId2', value: paymentReceiptOption.referenceId2 },
                        { field: 'ReferenceId3', value: paymentReceiptOption.referenceId3 },
                        { field: 'ReferenceDateTimeUtc', value: paymentReceiptOption.referenceDateTimeUtc },
                        { field: 'DeactivatedDateUtc', value: null },
                    ],
                    sortByFields: [{ field: 'CreatedDateUtc', direction: 'DESC' }],
                    offset: 0, limit: 1, includeTotalCount: false,
                })
                    .then(({ data }) => {
                        let paymentDocument = data.result && data.result.length > 0 ? data.result[0] : null;

                        if (!paymentDocument || reprint) {
                            if (isGeneratingPrintCallback) {
                                isGeneratingPrintCallback(true);
                            }

                            api.PrintedDocuments.print(paymentReceiptOption)
                                .then(({ data }) => {
                                    api.PrintedDocuments.get(data.id)
                                        .then(({ data: printedDocumentData }) => {
                                            if (isGeneratingPrintCallback) {
                                                isGeneratingPrintCallback(false);
                                            }
                                            paymentDocument = printedDocumentData;
                                            resolve(paymentDocument);
                                        })
                                })
                        } else {
                            resolve(paymentDocument);
                        }
                    })
            } else {
                const invoiceOption = {
                    documentType: 'Invoice',
                    customerId: purchase.customer ? purchase.customer.id : null,
                    referenceId1: purchase.id,
                    referenceId2: purchase.transactions[purchase.transactions.length - 1].id,
                    referenceDateTimeUtc: purchase.lastAnyUpdatedDateUtc,
                };

                api.PrintedDocuments.search({
                    parameters: [
                        { field: 'Type', value: invoiceOption.documentType },
                        { field: 'ReferenceId1', value: invoiceOption.referenceId1 },
                        { field: 'ReferenceId2', value: invoiceOption.referenceId2 },
                        { field: 'ReferenceDateTimeUtc', value: invoiceOption.referenceDateTimeUtc },
                        { field: 'DeactivatedDateUtc', value: null },
                    ],
                    sortByFields: [{ field: 'CreatedDateUtc', direction: 'DESC' }],
                    offset: 0, limit: 1, includeTotalCount: false,
                })
                    .then(({ data }) => {
                        let invoiceDocument = data.result && data.result.length > 0 ? data.result[0] : null;

                        if (!invoiceDocument || reprint) {
                            if (isGeneratingPrintCallback) {
                                isGeneratingPrintCallback(true);
                            }

                            api.PrintedDocuments.print(invoiceOption)
                                .then(({ data }) => {
                                    api.PrintedDocuments.get(data.id)
                                        .then(({ data: printedDocumentData }) => {
                                            if (isGeneratingPrintCallback) {
                                                isGeneratingPrintCallback(false);
                                            }
                                            invoiceDocument = printedDocumentData;
                                            resolve(invoiceDocument);
                                        })
                                })
                        } else {
                            resolve(invoiceDocument);
                        }
                    })
            }
        }
    })
}



export const renderPurchaseServiceReferenceTypes = (purchase, onClick) => {
    if (!purchase) return;

    const services = purchase.items.filter(i => i.type === 'Service');

    if (services && services.length > 0) {
        const referenceTypes = [...new Set(services.map(s => { return s.referenceType }))];

        return referenceTypes && referenceTypes.length > 0 ?
            referenceTypes.map((r, ri) => {
                return <div
                    key={`service_reference_type_${ri}`}
                    className={'d-inline-block' + (purchase.referenceId ? ' cursor-pointer' : '')}
                    onClick={onClick}
                >
                    {bh.renderPurchaseReferenceType(r, 'p-1 mr-1')}
                </div>
            }) : null
    }

}

export const renderPurchaseProductReferenceTypes = (purchase, onClick) => {
    if (!purchase) return;

    const products = purchase.items.filter(i => i.type === 'Product' || i.type === 'Custom');

    if (products && products.length > 0) {
        const referenceTypes = [...new Set(products.map(s => { return s.referenceType }))];

        for (let i = 0; i < products.length; i++) {
            for (let j = 0; j < products[i].items.length; j++) {
                if (!referenceTypes.some(r => r === products[i].items[j].referenceType)) {
                    referenceTypes.push(products[i].items[j].referenceType);
                }
            }
        }

        return referenceTypes && referenceTypes.length > 0 ?
            referenceTypes.map((r, ri) => {
                return <div
                    key={`product_reference_type_${ri}`}
                    className={'d-inline-block cursor-pointer'}
                    onClick={onClick}
                >
                    {bh.renderPurchaseReferenceType(r, 'p-1 mr-1')}
                </div>
            }) : null
    }

}

export const regenerate = (purchase, fileViewer, isGeneratingPrintCallback) => {
    getLatestInvoiceAndPaymentReceipt(purchase, isGeneratingPrintCallback, true)
        .then((printedDocument) => {
            fileViewer.initialize(() => {
                return <div className='fs-lg'>
                    {purchase.lastPayment ? 'Payment Receipt' : 'Invoice'}
                    <small className='d-block text-gray-700'>{purchase.lastPayment ? purchase.paymentReceiptNumber : purchase.invoiceNumber}</small>
                </div>
            }, 'printedDocument', [printedDocument], 0, (purchase.customer ? purchase.customer.id : ''))
        })
}

export const view = (purchase, fileViewer, isGeneratingPrintCallback) => {
    getLatestInvoiceAndPaymentReceipt(purchase, isGeneratingPrintCallback)
        .then((printedDocument) => {
            fileViewer.initialize(() => {
                return <div className='fs-lg'>
                    {purchase.lastPayment ? 'Payment Receipt' : 'Invoice'}
                    <small className='d-block text-gray-700'>{purchase.lastPayment ? purchase.paymentReceiptNumber : purchase.invoiceNumber}</small>
                </div>
            }, 'printedDocument', [printedDocument], 0, (purchase.customer ? purchase.customer.id : ''))
        })
}

export const download = (purchase, isGeneratingPrintCallback, downloadCallback) => {
    getLatestInvoiceAndPaymentReceipt(purchase, isGeneratingPrintCallback)
        .then((printedDocument) => {
            downloadFile('printedDocument', printedDocument.fileName, printedDocument.id, null, downloadCallback);
        })
}

export const print = (purchase, isGeneratingPrintCallback) => {
    getLatestInvoiceAndPaymentReceipt(purchase, isGeneratingPrintCallback)
        .then((printedDocument) => {
            api.PrintedDocuments.download(printedDocument.id)
                .then(({ data }) => {
                    printFile(URL.createObjectURL(data), printedDocument.mimeType);
                })
        })
}

export const email = (purchase, communication, isGeneratingPrintCallback) => {
    return new Promise((resolve) => {
        getLatestInvoiceAndPaymentReceipt(purchase, isGeneratingPrintCallback)
            .then((printedDocument) => {
                const action = purchase.payments && purchase.payments.length > 0 ? 'PaymentReceipt' : 'Invoice';

                communication.initialize(
                    'email',
                    action,
                    purchase.id,
                    [printedDocument],
                    purchase.customer.id,
                    purchase.customer.defaultEmailAddressContact,
                )
                    .then(() => {
                        resolve();
                    })
            })
    })
}

export const getStatusFilters = () => {
    return [
        {
            text: 'Incomplete',
            value: 'Incomplete'
        },
        {
            text: 'Completed',
            value: 'Completed'
        },
        {
            text: 'Refunded',
            value: 'Refunded'
        },
        {
            text: 'Voided',
            value: 'Voided'
        },
        {
            text: 'Deleted',
            value: 'Deleted'
        },
    ];
}

export const getBalanceStatusFilters = () => {
    return [
        {
            text: 'Paid in full',
            value: 'Paid'
        },
        {
            text: 'Refunded',
            value: 'Refunded'
        },
        {
            text: 'Insurance pending',
            value: 'InsurancePending'
        },
        {
            text: 'Government program pending',
            value: 'GovernmentPending'
        },
        {
            text: 'Insurance / Government pending',
            value: 'InsuranceGovernmentPending'
        },
        {
            text: 'Outstanding',
            value: 'Outstanding'
        },
        {
            text: 'Overpaid',
            value: 'OverPaid'
        },
        {
            text: 'Voided',
            value: 'Voided'
        },
        {
            text: 'No charge',
            value: 'NoCharge'
        },
        {
            text: 'Uncollectible',
            value: 'Uncollectible'
        },
    ];
}

export const getInvoiceDateFilters = () => {
    return dg.getDefaultLastDateFilters('InvoiceDate');
}

export const getLastPaymentFilters = () => {
    return dg.getDefaultLastDateFilters('LastPaymentDate');
}

export const getLastTransactionDateFilters = () => {
    return dg.getDefaultLastDateFilters('LastTransactionDate');
}

export const getQuickFilterDescription = (type) => {
    if (type) {
        switch (type) {
            case 'TodayInvoices':
                return `Today invoices`;

            case 'YesterdayInvoices':
                return `Yesterday invoices`;

            case 'ThisWeekInvoices':
                return `This week invoices`;

            case 'ThisMonthInvoices':
                return `This month invoices`;

            case 'LastWeekInvoices':
                return `Last week invoices`;

            case 'LastMonthInvoices':
                return `Last month invoices`;

            case 'OutstandingInvoices':
                return `Outstanding invoices`;

            case 'UncollectibleInvoices':
                return `Uncollectible invoices`;

            case 'InsurancePendingInvoices':
                return `Insurance pending invoices`;

            case 'GovernmentProgramPendingInvoices':
                return `Government program pending invoices`;

            case 'RecentRefundedInvoices':
                return `Recently refunded invoices`;

            case 'RecentVoidedInvoices':
                return `Recently voided invoices`;

            case 'RecentNoChargeInvoices':
                return `Recently no charge invoices`;

            default:
                return null;
        }
    }
    return null;
}

export const getQuickFilterDefinition = (type) => {
    if (type) {
        const statusFilters = getStatusFilters();
        const lastTransactionDateFilters = getLastTransactionDateFilters();
        const balanceTypeFilters = getBalanceStatusFilters();

        switch (type) {
            case 'TodayInvoices':
                return [{
                    column: 'LastTransactionDate',
                    headerFilters: [lastTransactionDateFilters.filter(u => u.text === 'Today')[0].value],
                    sortOrder: 'asc'
                }, {
                    column: 'Status',
                    headerFilters: [statusFilters.filter(u => u.text === 'Completed')[0].value, statusFilters.filter(u => u.text === 'Refunded')[0].value, statusFilters.filter(u => u.text === 'Voided')[0].value],
                }]

            case 'YesterdayInvoices':
                return [{
                    column: 'LastTransactionDate',
                    headerFilters: [lastTransactionDateFilters.filter(u => u.text === 'Yesterday')[0].value],
                    sortOrder: 'asc'
                }, {
                    column: 'Status',
                    headerFilters: [statusFilters.filter(u => u.text === 'Completed')[0].value, statusFilters.filter(u => u.text === 'Refunded')[0].value, statusFilters.filter(u => u.text === 'Voided')[0].value],
                }]

            case 'ThisWeekInvoices':
                return [{
                    column: 'LastTransactionDate',
                    filter: {
                        operator: 'between',
                        value: [moment().startOf('week').startOf('day').toDate(), moment().endOf('week').startOf('day').toDate()],
                    },
                    sortOrder: 'asc'
                }, {
                    column: 'Status',
                    headerFilters: [statusFilters.filter(u => u.text === 'Completed')[0].value, statusFilters.filter(u => u.text === 'Refunded')[0].value, statusFilters.filter(u => u.text === 'Voided')[0].value],
                }]

            case 'ThisMonthInvoices':
                return [{
                    column: 'LastTransactionDate',
                    filter: {
                        operator: 'between',
                        value: [moment().startOf('month').startOf('day').toDate(), moment().endOf('month').startOf('day').toDate()],
                    },
                    sortOrder: 'asc'
                }, {
                    column: 'Status',
                    headerFilters: [statusFilters.filter(u => u.text === 'Completed')[0].value, statusFilters.filter(u => u.text === 'Refunded')[0].value, statusFilters.filter(u => u.text === 'Voided')[0].value],
                }]

            case 'LastWeekInvoices':
                return [{
                    column: 'LastTransactionDate',
                    filter: {
                        operator: 'between',
                        value: [moment().add(-1, 'weeks').startOf('week').startOf('day').toDate(), moment().add(-1, 'weeks').endOf('week').startOf('day').toDate()],
                    },
                    sortOrder: 'asc'
                }, {
                    column: 'Status',
                    headerFilters: [statusFilters.filter(u => u.text === 'Completed')[0].value, statusFilters.filter(u => u.text === 'Refunded')[0].value, statusFilters.filter(u => u.text === 'Voided')[0].value],
                }]

            case 'LastMonthInvoices':
                return [{
                    column: 'LastTransactionDate',
                    filter: {
                        operator: 'between',
                        value: [moment().add(-1, 'months').startOf('month').startOf('day').toDate(), moment().add(-1, 'months').endOf('month').startOf('day').toDate()],
                    },
                    sortOrder: 'asc'
                }, {
                    column: 'Status',
                    headerFilters: [statusFilters.filter(u => u.text === 'Completed')[0].value, statusFilters.filter(u => u.text === 'Refunded')[0].value, statusFilters.filter(u => u.text === 'Voided')[0].value],
                }]

            case 'OutstandingInvoices':
                return [{
                    column: 'LastTransactionDate',
                    sortOrder: 'asc'
                }, {
                    column: 'Status',
                    headerFilters: [statusFilters.filter(u => u.text === 'Completed')[0].value],
                }, {
                    column: 'BalanceType',
                    headerFilters: [balanceTypeFilters.filter(u => u.text === 'Outstanding')[0].value],
                }]

            case 'UncollectibleInvoices':
                return [{
                    column: 'LastTransactionDate',
                    sortOrder: 'asc'
                }, {
                    column: 'BalanceType',
                    headerFilters: [balanceTypeFilters.filter(u => u.text === 'Uncollectible')[0].value],
                }]

            case 'InsurancePendingInvoices':
                return [{
                    column: 'LastTransactionDate',
                    sortOrder: 'asc'
                }, {
                    column: 'Status',
                    headerFilters: [statusFilters.filter(u => u.text === 'Completed')[0].value],
                }, {
                    column: 'BalanceType',
                    headerFilters: [balanceTypeFilters.filter(u => u.text === 'Insurance pending')[0].value, balanceTypeFilters.filter(u => u.text === 'Insurance / Government pending')[0].value],
                }]

            case 'GovernmentProgramPendingInvoices':
                return [{
                    column: 'LastTransactionDate',
                    sortOrder: 'asc'
                }, {
                    column: 'Status',
                    headerFilters: [statusFilters.filter(u => u.text === 'Completed')[0].value],
                }, {
                    column: 'BalanceType',
                    headerFilters: [balanceTypeFilters.filter(u => u.text === 'Government program pending')[0].value, balanceTypeFilters.filter(u => u.text === 'Insurance / Government pending')[0].value],
                }]

            case 'RecentRefundedInvoices':
                return [{
                    column: 'LastTransactionDate',
                    headerFilters: [lastTransactionDateFilters.filter(u => u.text === 'Last 30 days')[0].value],
                    sortOrder: 'asc'
                }, {
                    column: 'Status',
                    headerFilters: [statusFilters.filter(u => u.text === 'Refunded')[0].value],
                }]

            case 'RecentVoidedInvoices':
                return [{
                    column: 'LastTransactionDate',
                    headerFilters: [lastTransactionDateFilters.filter(u => u.text === 'Last 30 days')[0].value],
                    sortOrder: 'asc'
                }, {
                    column: 'Status',
                    headerFilters: [statusFilters.filter(u => u.text === 'Voided')[0].value],
                }]

            case 'RecentNoChargeInvoices':
                return [{
                    column: 'LastTransactionDate',
                    headerFilters: [lastTransactionDateFilters.filter(u => u.text === 'Last 30 days')[0].value],
                    sortOrder: 'asc'
                }, {
                    column: 'BalanceType',
                    headerFilters: [balanceTypeFilters.filter(u => u.text === 'Uncollectible')[0].value],
                }]

            default:
                return;
        }
    }
}

export const toBalanceTypeIcon = (balanceType, className) => {
    if (balanceType) {
        switch (balanceType.toLowerCase()) {
            case 'collected':
                return <i className={'fal fa-money-check-alt text-success-800 text-center' + (className ? ` ${className}` : '')} style={{ minWidth: '35px' }}></i>;

            case 'InsuranceGovernmentPending':
                return <i className={'fal fa-envelope-open-dollar text-center' + (className ? ` ${className}` : '')} style={{ color: '#009e9c', minWidth: '35px' }}></i>;

            case 'insurancepending':
                return <i className={'fal fa-envelope-open-dollar text-center' + (className ? ` ${className}` : '')} style={{ color: '#009e9c', minWidth: '35px' }}></i>;

            case 'governmentpending':
                return <i className={'fal fa-envelope-open-dollar text-center' + (className ? ` ${className}` : '')} style={{ color: '#009e9c', minWidth: '35px' }}></i>;

            case 'refunded':
                return <i className={'fal fa-money-check-alt text-warning-800 text-center' + (className ? ` ${className}` : '')} style={{ minWidth: '35px' }}></i>;

            case 'uncollectible':
                return <i className={'fal fa-times-square text-danger text-center' + (className ? ` ${className}` : '')} style={{ minWidth: '35px' }}></i>;

            default:
                break;
        }
    }

    return <i className={'fal fa-question-square text-center' + (className ? ` ${className}` : '')} style={{ color: '#f5af14', minWidth: '35px' }}></i>;
}

export const getPendingPaymentDescription = (payment) => {
    if (payment) {
        switch (payment.paymentMethod.toLowerCase()) {
            case 'privateinsurance':
                return 'Insurance pending';

            case 'governmentprogram':
                const description = 'Government program pending';
                return description;

            default:
                break;
        }
    }
}

export const getBalanceTypeDescription = (balanceType) => {
    if (balanceType) {
        switch (balanceType.toLowerCase()) {
            case 'collected':
                return 'Collected';

            case 'insurancepending':
                return 'Insurance pending';

            case 'governmentpending':
                return 'Government program pending';

            case 'InsuranceGovernmentPending':
                return 'Insurance / Government pending';

            case 'refunded':
                return 'Refunded';

            case 'uncollectible':
                return 'Uncollectible';

            default:
                break;
        }
    }
}

export const getBalanceTypeTextColor = (balanceType) => {
    if (balanceType) {
        switch (balanceType.toLowerCase()) {
            case 'paid':
            case 'InsuranceGovernmentPending':
            case 'insurancepending':
            case 'governmentpending':
            case 'nocharge':
            case 'voided':
                return 'text-primary';

            case 'outstanding':
            case 'uncollectible':
                return 'text-danger';

            case 'overpaid':
                return 'text-warning-800';

            default:
                break;
        }
    }

    return 'text-primary';
}

export const getPaymentMethodDescription = (paymentMethod) => {
    if (!paymentMethod || !paymentMethod.key) return '';

    switch (paymentMethod.key) {
        case 'eTransfer':
            return 'e-Transfer';

        case 'PreAuthorized':
            return 'Pre-Authorized';

        default:
            return paymentMethod.value;
    }
}

export const getRefundMethodDescription = (refundMethod) => {
    if (!refundMethod || !refundMethod.key) return '';

    switch (refundMethod.key) {
        case 'eTransfer':
            return 'e-Transfer';

        default:
            return refundMethod.value;
    }
}