import React, { Component } from 'react';

import * as fn from '../../../utilities/_functions';

export default class TabbedTextArea extends Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    handleKeyDown = (event) => {
        if (event.shiftKey && event.key === 'Tab' && this.ref.current) {
            const that = this;
            const value = this.ref.current.value;
            event.preventDefault(); // Prevent the default shift+tab behavior

            // Get the current cursor position and selection
            const start = event.target.selectionStart;
            const end = event.target.selectionEnd;

            // Insert the tab character into the value where the cursor is
            const newValue = value.substring(0, start) + '\t' + value.substring(end);

            if (this.props.onKeyDown && fn.isFunction(this.props.onKeyDown)) {
                this.props.onKeyDown(event);
            }

            if (this.props.onChange && fn.isFunction(this.props.onChange)) {
                event.target.value = newValue;
                this.props.onChange(event);
                if (that.ref.current) {
                    that.ref.current.selectionEnd = start + 1;
                }
            }
        }
    };

    render() {
        const options = {
            onKeyDown: this.handleKeyDown
        };
        const props = { ...this.props, ...options }

        return <textarea
            ref={this.ref}
            {...props}
        />
    }
}