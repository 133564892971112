import React, { useContext } from 'react';
import { useObserver } from 'mobx-react-lite';
import FadeIn from 'react-fade-in';
import { GlobalHotKeys } from 'react-hotkeys';

import QuickDrawerHeader from './QuickDrawerHeader';
import QuickDrawerStore from '../../../stores/QuickDrawerStore';

import * as fn from '../../../utilities/_functions';

function QuickDrawerAccessDenied(props) {
    const quickDrawer = useContext(QuickDrawerStore);

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                if (fn.isFunction(props.onClose)) {
                                    props.onClose(event);
                                } else {
                                    quickDrawer.deactivateAll();
                                }
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form>
            <fieldset>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={'fal fa-ban'}
                        action='Security'
                        category='Access Denied'
                        onCancel={props.onCancel}
                    />
                    <div className='quick-drawer-body'>
                        <FadeIn>
                            <div className='body-content'>
                                <section>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='alert alert-danger mb-0 pb-0' role='alert'>
                                                <strong className='d-block mb-2'>Important</strong>
                                                <p>Your account does not have sufficient privileges to perform this action.</p>
                                                <p>Contact your administrator for access.</p>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </FadeIn>
                    </div>
                    <div className='quick-drawer-action pl-3'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel'
                                        onClick={props.onCancel}
                                    >Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>)
}

export default QuickDrawerAccessDenied;