import React, { useContext, useState } from 'react';
import AuthStore from '../../../stores/AuthStore';
import CacheStore from '../../../stores/CacheStore';
import SettingStore from '../../../stores/SettingStore';
import SignalRContext from '../../SignalRContext'
import jwt_decode from "jwt-decode";
import uuid from 'react-uuid';
import api from '../../../api';
import { ACCESS_TOKEN, SESSION_ID } from '../../../constants/storageKeys';
import LoadingOverlay from '../_shared/LoadingOverlay';

const FailoverLogin = ({ onLoginSuccess }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [error, setError] = useState('');

    const auth = useContext(AuthStore);
    const cache = useContext(CacheStore);
    const setting = useContext(SettingStore);
    const signalr = useContext(SignalRContext);

    const requestCode = async (event) => {
        event.preventDefault();
        try {
            setIsLoading(true);
            const { data } = await api.Passcode.request(username);
            if (data.emailAddress) {
                setResponseMessage(`Your passcode is sent to ${data.emailAddress}. Please check your email inbox.`)
            } else {
                setResponseMessage('Requesting passcode failed. Please try again.');
            }
        } catch (err) {
            setResponseMessage('Requesting passcode failed. Please try again.');
        }
        setIsLoading(false);
    }

    const handleLogin = async (event) => {
        event.preventDefault();
        if (!password || !username) {
            alert('You need to enter both login and password');
            return;
        }
        try {
            setIsLoading(true);
            const response = await api.Passcode.verify(password, username);
            if (response.data.accessToken) {
                const accessToken = response.data.accessToken;
                const authResult = jwt_decode(accessToken);

                const sessionId = uuid();
                const sessionField = document.querySelector('#_si');

                window.localStorage.setItem(ACCESS_TOKEN, accessToken);
                window.localStorage.setItem(SESSION_ID, sessionId);

                if (sessionField) { sessionField.value = sessionId; }

                Promise.all([
                    cache.clear(),
                    auth.handleAuthentication({ sub: authResult.sub }, authResult),
                ])
                    .then(() => {
                        Promise.all([
                            cache.initialize(),
                            setting.initialize(),
                        ])
                            .then(() => {
                                signalr.connect();
                                setIsLoading(false);
                                onLoginSuccess();
                            })
                    }).catch(reason => {
                        console.log("********** failover login error", reason);
                    }).finally(() => setIsLoading(false));

            } else {
                setError('Invalid login credentials');
                setIsLoading(false)
            }
        } catch (err) {
            setError('Login failed. Please try again.');
            setIsLoading(false)
        }
    };

    return (
        <div className="container mt-5">
            <div class="row">
                <div class="col-12">
                    <h1>Genki EMR</h1>
                </div>
            </div>
            <form onSubmit={requestCode}>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        id="inputLogin"
                        placeholder="Login name"
                        value={username}
                        required
                        onChange={e => setUsername(e.target.value)}
                    />
                    <button
                        type="submit"
                        className="btn btn-outline-secondary btn-sm mt-2"
                    >
                        Request Passcode
                    </button>
                </div>
            </form>

            <div class="mb-4">{responseMessage}</div>

            <form onSubmit={handleLogin} >
                <div className="form-group">
                    <input
                        type="password"
                        className="form-control"
                        id="inputPasscode"
                        placeholder="Passcode"
                        value={password}
                        required
                        onChange={e => setPassword(e.target.value)}
                    />
                </div>
                <button type="submit" className="btn btn-primary btn-md">Login</button>
                {
                    isLoading ? <LoadingOverlay isLoading={true}></LoadingOverlay> : null
                }
                <div>{error}</div>
            </form>
        </div>);
};

export default FailoverLogin;