import { createContext } from 'react';
import { decorate, observable, action, computed, toJS } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class MedicalReportTenantExamDatasetGroupView {
    id = null;
    data = null;
    datasets = [];
    isLoading = false;
    isSaving = false;
    isReady = false;
    saveToServer = true;
    hasUnsavedChanges = false;

    cancelMedicalReportTenantExamDatasetGroupGet = null;
    cancelMedicalReportExamDatasetGroupUpdate = null;

    load = (id, saveToServer) => {
        const that = this;
        this.clear();
        this.id = id;
        this.isLoading = true;

        if (saveToServer) {
            this.saveToServer = saveToServer;
        }

        return new Promise((resolve, reject) => {
            api.MedicalReportTenantExamDatasetGroups.get(id, (c) => { that.cancelMedicalReportTenantExamDatasetGroupGet = c })
                .then(({ data: medicalReportData }) => {
                    if (medicalReportData && medicalReportData.examPublishedTemplateId) {
                        api.ExamDatasetGroups.search({
                            parameters: [{
                                field: 'ExamPublishedTemplateId',
                                value: medicalReportData.examPublishedTemplateId,
                            }],
                            sortByFields: [{
                                field: 'CreatedDateUtc',
                                direction: 'DESC',
                            }],
                            includeTotalCount: false,
                            loadProperties: true,
                            limit: 1
                        })
                            .then(({ data: datasetGroupData }) => {
                                that.data = medicalReportData;
                                that.datasets = datasetGroupData != null && datasetGroupData.result && datasetGroupData.result.length > 0 && datasetGroupData.result[0].examDatasets && datasetGroupData.result[0].examDatasets.length > 0 ? datasetGroupData.result[0].examDatasets : []

                                resolve();
                            })
                            .catch(() => {
                                reject();
                            })
                            .finally(() => {
                                that.isLoading = false;
                                that.isReady = true;
                            })
                    }
                })
                .catch(() => {
                    reject();
                })
        })
    }

    loadExisting = (existing) => {
        this.clear();
        this.id = existing.id;
        this.saveToServer = false;
        this.data = existing;

        return Promise.resolve();
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            const option = toJS(that.data);

            if (that.hasUnsavedChanges) {
                if (that.saveToServer) {
                    api.MedicalReportTenantExamDatasetGroups.update(that.id, option, (c) => { that.cancelMedicalReportExamDatasetGroupUpdate = c })
                        .then(() => {
                            that.hasUnsavedChanges = false;
                            resolve(option);
                        })
                        .catch(() => {
                            reject();
                        })
                        .finally(() => {
                            that.isSaving = false;
                        })
                } else {
                    resolve(option);
                }
            } else {
                that.hasUnsavedChanges = false;
                that.isSaving = false;
                resolve();
            }
        })
    }

    delete = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            api.MedicalReportTenantExamDatasetGroups.delete(that.id, (c) => { that.cancelMedicalReportExamDatasetGroupUpdate = c })
                .then(() => {
                    that.hasUnsavedChanges = false;
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    clear = () => {
        this.id = null;
        this.data = null;
        this.datasets.clear();
        this.hasUnsavedChanges = false;
        this.isLoading = false;
        this.isSaving = false;
        this.isReady = false;
        this.saveToServer = true;

        if (fn.isFunction(this.cancelMedicalReportTenantExamDatasetGroupGet)) {
            this.cancelMedicalReportTenantExamDatasetGroupGet();
            this.cancelMedicalReportTenantExamDatasetGroupGet = null;
        }

        if (fn.isFunction(this.cancelMedicalReportExamDatasetGroupUpdate)) {
            this.cancelMedicalReportExamDatasetGroupUpdate();
            this.cancelMedicalReportExamDatasetGroupUpdate = null;
        }
    }

    get selectedDatasets() {
        if (this.data && this.data.examDatasetIds && this.data.examDatasetIds.length > 0 && this.datasets && this.datasets.length > 0) {
            const that = this;

            return that.data.examDatasetIds.filter(id => that.datasets.some(d => d.id === id)).map((id, index) => {
                const dataset = that.datasets.filter(d => d.id === id)[0];
                dataset.displayOrder = (index + 1);
                return dataset;
            });
        }

        return [];
    }
}

decorate(MedicalReportTenantExamDatasetGroupView, {
    id: observable,
    data: observable,
    datasets: observable,
    hasUnsavedChanges: observable,
    isLoading: observable,
    isSaving: observable,
    isReady: observable,
    load: action,
    save: action,
    delete: action,
    clear: action,
    selectedDatasets: computed,
})

export default createContext(new MedicalReportTenantExamDatasetGroupView());