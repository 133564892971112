import { createContext } from 'react';
import { decorate, observable, action } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class GroupCreateUpdate {
    data = null;
    isLoading = false;
    isSaving = false;
    isReady = false;
    hasUnsavedChanges = false;

    cancelGroupCreateUpdate = null;

    initialize = (item) => {
        this.clear();
        if (item) {
            this.data = item;
        }
        this.isReady = true;
    }

    save = async (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        try {
            if (this.data.id) {
                await api.Groups.update(
                    this.data.id,
                    {
                        name: this.data.name,
                        displayOrder: this.data.displayOrder,
                        users: this.data.users
                    },
                    (c) => { that.cancelGroupCreateUpdate = c });
            }
            else {
                await api.Groups.create({
                    name: this.data.name,
                    displayOrder: this.data.displayOrder,
                    users: this.data.users
                },
                    (c) => { that.cancelGroupCreateUpdate = c });
            }
            that.hasUnsavedChanges = false;
        }
        catch(e) {
            if (e.response) {
                throw e.response.data;
            }
            else throw e;
        }
        finally {
            this.isSaving = false;
        }
    }

    delete = (groups) => {
        const that = this;

        this.isSaving = true;

        return new Promise((resolve, reject) => {
            Promise.all(
                [...groups].map(g => {
                    return api.Groups.delete(g.id, (c) => { that.cancelGroupCreateUpdate = c })
                })
            )
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    clear = () => {
        this.data = {
            name: '',
            displayOrder: 100,
            users: []
        };
        this.isLoading = false;
        this.isReady = false;
        this.hasUnsavedChanges = false;

        if (fn.isFunction(this.cancelGroupCreateUpdate)) {
            this.cancelGroupCreateUpdate();
            this.cancelGroupCreateUpdate = null;
        }
    }
}

decorate(GroupCreateUpdate, {
    data: observable.deep,
    isSaving: observable,
    isLoading: observable,
    isReady: observable,
    hasUnsavedChanges: observable,
    initialize: action,
    refresh: action,
    clear: action,
})

export default createContext(new GroupCreateUpdate());