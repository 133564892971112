import React, { useEffect, useContext, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { Observer } from 'mobx-react-lite';
import MaskedInput from 'react-text-mask'
import { toast } from 'react-toastify';
import { GlobalHotKeys } from 'react-hotkeys';
import { DateTimePicker } from 'react-widgets'
import moment from 'moment';

import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import RichTextEditor from '../../_shared/RichTextEditor';

import PurchaseUpdateStore from '../../../../stores/PurchaseUpdateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as MaskKeys from '../../../../constants/maskKeys';
import * as fn from '../../../../utilities/_functions';
import * as oh from '../../../../utilities/operationHelper';
import * as ph from '../../../../utilities/personHelper';
import * as ah from '../../../../utilities/addressHelper';
import * as ch from '../../../../utilities/customerHelper';
import * as bh from '../../../../utilities/badgeHelper';

import './GovernmentProgramPayment.scss';

function GovernmentProgramPayment(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const purchase = useContext(PurchaseUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);

    useEffect(() => {
        if (props.extraProps) {
            purchase.governmentProgramPaymentId = props.extraProps.id ? props.extraProps.id : null;
            purchase.governmentProgramInformation = props.extraProps.data ? props.extraProps.data : null;
            purchase.governmentProgramAmount = props.extraProps.data ? props.extraProps.data.amount : null;
        }

        purchase.governmentProgramPaymentDate = moment().startOf('day');

        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100)

        return () => {
            isMounted.current = false;

            purchase.clearGovernmentProgram();
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (purchase.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleAmountChange = event => {
        const amount = event.target.value ? fn.parseCurrency(event.target.value) : null;
        purchase.governmentProgramAmount = amount;
        purchase.hasUnsavedChanges = true;
    }

    const handlePaymentDateChange = value => {
        const date = moment(value);
        purchase.governmentProgramPaymentDate = date;
        purchase.hasUnsavedChanges = true;
    }

    const handleReasonChange = (content, delta, source, editor) => {
        const note = editor.getText().replace(/(\r\n|\n|\r)/g, ' ').trim();
        const noteHtml = (content === '<p><br></p>') ? null : content;

        purchase.governmentProgramNote = note;
        purchase.governmentProgramNoteHtml = noteHtml;
        purchase.hasUnsavedChanges = true;
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (!purchase.governmentProgramAmount) {
            toast.error(() => ErrorMessages.GENERIC_FORM_ERROR_HTML, { position: 'top-center', style: { width: '380px' } });
        }
        else {
            purchase.governmentProgramPayment(true)
                .then(() => {
                    if (isMounted.current) {
                        if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                            props.onSuccess(event, { updated: true });
                        }
                    }
                })
        }
    }

    const getRemainingBalance = () => {
        if (purchase && purchase.data) {
            return (purchase.data.remainingBalance + (purchase.data.toBeCollectedAmount - (purchase.governmentProgramAmount ? purchase.governmentProgramAmount : 0)));
        }

        return 0;
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form onSubmit={handleSubmit}>
            <Observer>{() =>
                <fieldset disabled={purchase.isSaving}>
                    <div className='government-program-payment-container quick-drawer'>
                        <QuickDrawerHeader
                            drawer={props.drawer}
                            icon={oh.getIcon('transaction-payment', 'government-program')}
                            action='Government Program Payment'
                            category='Mark as Collected'
                            className='payment'
                            onCancel={handleCancel}
                        />
                        <div className='quick-drawer-body'>
                            <Observer>{() =>
                                <>
                                    {
                                        purchase.data ?
                                            <FadeIn>
                                                <div className='body-content'>
                                                    <section>
                                                        <div className='row'>
                                                            <div className='col-6'>
                                                                <h3 className='purchase-title'>Invoice Detail</h3>
                                                                <p>Invoice # <strong>{purchase.data.invoiceNumber}</strong></p>
                                                            </div>
                                                            <div className='col-6'>
                                                                <div className='text-right'>
                                                                    {bh.renderPurchaseBalanceStatus(purchase.data, 'text-truncate text-truncate-md fs-sm p-2')}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <Observer>{() =>
                                                        <>
                                                            {
                                                                purchase.data.customer ?
                                                                    <section className='customer'>
                                                                        <div className='row'>
                                                                            <div className='col-12'>
                                                                                <label><small>Billing Information</small></label>
                                                                            </div>
                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className='col-12'>
                                                                                <Observer>{() =>
                                                                                    <div
                                                                                        className='profile-wrapper'
                                                                                    >
                                                                                        <div className='profile'>
                                                                                            <span
                                                                                                className={`profile-image profile-initials rounded-circle d-flex text-white ${ch.getProfileColor(purchase.data.customer)} fw-500`}
                                                                                                title={purchase.data.customer.fullName}
                                                                                            >
                                                                                                {purchase.data.customer.initials}
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className='description flex-1'>
                                                                                            <div className='name'>{ph.getFullName(purchase.data.customer, true)}
                                                                                                {
                                                                                                    purchase.data.customer.dateOfBirth || purchase.data.customer.sex || purchase.data.customer.gender || purchase.data.customer.pronoun ?
                                                                                                        <small className='text-nowrap ml-2'>({`${ph.getAge(purchase.data.customer.dateOfBirth)} ${ph.getSexGenderPronounDisplay(purchase.data.customer)}`.trim()})</small> : null
                                                                                                }
                                                                                            </div>
                                                                                            {
                                                                                                purchase.data.billingAddress ?
                                                                                                    <div className='info'>{ah.getAddressHtml(purchase.data.billingAddress)}</div> : null
                                                                                            }
                                                                                            {
                                                                                                purchase.data.customerEmail ?
                                                                                                    <div className='info'>
                                                                                                        <a
                                                                                                            href={`mailto:${purchase.data.customerEmail}`}
                                                                                                        >{purchase.data.customerEmail}
                                                                                                        </a>
                                                                                                    </div> : null
                                                                                            }
                                                                                            {
                                                                                                purchase.data.customerPhoneNumber ?
                                                                                                    <div className='info'>
                                                                                                        <a
                                                                                                            href={`tel:${purchase.data.customerPhoneNumber}`}
                                                                                                        >{purchase.data.customerPhoneNumber}
                                                                                                        </a>
                                                                                                    </div> : null
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                }</Observer>
                                                                            </div>
                                                                        </div>
                                                                    </section> : null
                                                            }
                                                        </>
                                                    }</Observer>
                                                    <section>
                                                        <div className='row'>
                                                            <div className='col-4'>
                                                                <label><small>Invoice Total</small></label>
                                                                <Observer>{() =>
                                                                    <strong className='d-block fs-xl'>{fn.formatCurrency(purchase.data.total)}</strong>
                                                                }</Observer>
                                                            </div>
                                                            <div className='col-7'>
                                                                <label><small>Remaining Balance</small></label>
                                                                <Observer>{() =>
                                                                    <strong className={'d-block fs-xl' + (getRemainingBalance() < 0 ? ' text-danger' : '')}>{fn.formatCurrency(getRemainingBalance())}</strong>
                                                                }</Observer>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <section>
                                                        <div className='row'>
                                                            <div className='col-5'>
                                                                <label htmlFor='government-program-payment-amount' className='required'><small>Amount</small></label>
                                                                <Observer>{() =>
                                                                    <MaskedInput
                                                                        id='government-program-payment-amount'
                                                                        className='form-control currency-input'
                                                                        mask={MaskKeys.CURRENCY_POSITIVE_MASK}
                                                                        autoComplete='off'
                                                                        value={purchase.governmentProgramAmount ? purchase.governmentProgramAmount : ''}
                                                                        onChange={handleAmountChange}
                                                                    />
                                                                }</Observer>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <section>
                                                        <div className='row'>
                                                            <div className='col-7'>
                                                                <div className='form-group mb-0 validate validate-required'>
                                                                    <label className='required'><small>Paid on (YYYY-MM-DD)</small></label>
                                                                    <Observer>{() =>
                                                                        <DateTimePicker
                                                                            dateFormat={dt => String(dt.getDate())}
                                                                            dayFormat={day => ['S', 'M', 'T', 'W', 'T', 'F', 'S'][day.getDay()]}
                                                                            format={'YYYY-MM-DD'}
                                                                            views={['month', 'year']}
                                                                            footer={false}
                                                                            date={true}
                                                                            time={false}
                                                                            min={moment.utc(purchase.createdDateUtc).local().add(-1, 'days').toDate()}
                                                                            max={moment().toDate()}
                                                                            value={purchase.governmentProgramPaymentDate ? moment(purchase.governmentProgramPaymentDate).toDate() : null}
                                                                            onChange={handlePaymentDateChange}
                                                                        />
                                                                    }</Observer>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    {
                                                        purchase.governmentProgramInformation ?
                                                            <section>
                                                                <div className='row'>
                                                                    <div className='col-12'>
                                                                        <div className='form-group mb-0'>
                                                                            <label><small>Government Program Information</small></label>
                                                                            <div className='profile-wrapper pb-0'>
                                                                                <div className='description flex-1'>
                                                                                    {
                                                                                        purchase.governmentProgramInformation.name ?
                                                                                            <div className='name'>{purchase.governmentProgramInformation.name}{(purchase.governmentProgramInformation.code ? ` (${purchase.governmentProgramInformation.code})` : '')}</div> : null
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </section> : null
                                                    }
                                                    <section>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className='form-group'>
                                                                    <label><small>Reference Note</small></label>
                                                                    <div className='input-group'>
                                                                        <Observer>{() =>
                                                                            <RichTextEditor
                                                                                mode='none'
                                                                                disableTab={true}
                                                                                value={purchase.governmentProgramNoteHtml}
                                                                                onChange={handleReasonChange}
                                                                            />
                                                                        }</Observer>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </FadeIn> : renderQuickDrawerLoading()
                                    }
                                </>
                            }</Observer>
                        </div>
                        <div className='quick-drawer-action pl-3'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='float-right'>
                                        <button
                                            type='button'
                                            className='btn btn-link btn-cancel mr-2'
                                            onClick={handleCancel}
                                        >Cancel</button>
                                        <button
                                            type='submit'
                                            className='btn btn-success'
                                        >Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            }</Observer>
        </form>
    </>
}

export default GovernmentProgramPayment;