import { createContext } from 'react';
import uuid from 'react-uuid';
import { decorate, observable, action, computed, toJS } from 'mobx';
import moment from 'moment';

import api from '../api';
import * as fn from '../utilities/_functions';
import * as ah from '../utilities/appointmentHelper';

export class GroupAppointmentCreate {
    id = null;
    groupId = null;
    stageCustomer = null;
    data = {
        groupId: null,
        existingAppointmentId: null,
        start: null,
        duration: null,
        userId: null,
        customerServices: [],
        primaryContactId: null,
        primaryContact: null,
        notes: [],
    };
    checkUpcomingConflict = true;
    isReady = false;
    hasUnsavedChanges = false;
    isSaving = false;
    cancelAppointmentCreate = null;

    initialize = (existingAppointmentId) => {
        this.clear();
        this.addNewCustomerService();
        this.data.existingAppointmentId = existingAppointmentId;
        this.isReady = true;
    }

    stage = (customer) => {
        this.clear();
        this.id = uuid();
        this.stageCustomer = customer;
        this.data.customerId = customer.id;
        this.checkUpcomingConflict = false;

        this.isReady = true;
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            let option = toJS(that.data);

            option.start = that.data.start.clone().format('YYYY-MM-DDTHH:mm');

            if (that.hasUnsavedChanges) {
                api.GroupAppointments.create(
                    option,
                    (c) => { that.cancelAppointmentCreate = c; },
                )
                    .then(({ data }) => {
                        that.id = data.id;
                        that.groupId = data.groupId;
                        that.data.id = data.id;
                        option.id = data.id;
                        option.groupId = data.groupId;
                        option.isGroup = true;
                        resolve(option);
                    })
                    .catch(() => {
                        reject();
                    })
                    .finally(() => {
                        that.isSaving = false;
                        that.hasUnsavedChanges = false;
                    })
            } else {
                this.isSaving = false;
                that.hasUnsavedChanges = false;
                resolve(option);
            }
        })
    }

    checkConflicts = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            const options = {
                id: null,
                customerIds: that.data.customerServices.filter(c => !!c.customer).map(c => { return c.customer.id }),
                start: that.data.start,
                duration: that.data.duration,
                userId: that.data.userId,
            }

            ah.checkConflictsForGroup(options, that.checkUpcomingConflict)
                .then((data) => {
                    resolve(data);
                })
                .catch(error => {
                    reject();
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    checkEligibility = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            const options = that.data.customerServices.filter(c => !!c.customer && !!c.services && c.services.length > 0).map(c => {
                return {
                    customer: c.customer,
                    data: {
                        start: that.data.start,
                        services: c.services
                    }
                }
            })

            if (options && options.length > 0) {
                ah.checkEligibilityForGroup(options)
                    .then((data) => {
                        if (data && data.length > 0) {
                            for (let di = 0; di < data.length; di++) {
                                if (data[di].service && data[di].service.isSubsidized) {
                                    const ci = that.data.customerServices.findIndex(c => !!c.customer && !!data[di].customer && c.customer.id === data[di].customer.id && c.services && c.services.length > 0);

                                    if (ci > -1) {
                                        const ii = that.data.customerServices[ci].services.findIndex(s => s.code === data[di].code);

                                        if (ii > -1) {
                                            that.data.customerServices[ci].services[ii].isEligible = data[di].isEligible;
                                            that.data.customerServices[ci].services[ii].lastEligibilityCheckedDateUtc = moment().utc();
                                            that.data.customerServices[ci].services[ii].ineligibilityCode = data[di].ineligibilityCode;
                                            that.data.customerServices[ci].services[ii].ineligibilityReason = data[di].ineligibilityReason;
                                            that.data.customerServices[ci].services[ii].earliestEligibleDate = data[di].earliestEligibleDate ? moment(data[di].earliestEligibleDate) : null;
                                        }
                                    }
                                }
                            }
                        } else {
                            if (that.data.customerServices && that.data.customerServices.length > 0) {
                                for (let si = 0; si < that.data.customerServices.length; si++) {

                                    if (that.data.customerServices[si].services && that.data.customerServices[si].services.length > 0) {
                                        for (let sii = 0; sii < that.data.customerServices[si].services.length; sii++) {
                                            that.data.customerServices[si].services[sii].isEligible = null;
                                            that.data.customerServices[si].services[sii].lastEligibilityCheckedDateUtc = null;
                                            that.data.customerServices[si].services[sii].ineligibilityCode = null;
                                            that.data.customerServices[si].services[sii].ineligibilityReason = null;
                                            that.data.customerServices[si].services[sii].earliestEligibleDate = null;
                                        }
                                    }
                                }
                            }
                        }
                        resolve(data);
                    })
                    .catch(error => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            }
            else {
                this.isSaving = false;
                resolve(null);
            }
        })
    }

    addNewCustomerService = () => {
        this.data.customerServices.push(this.getNewCustomerService());
    }

    getNewCustomerService = () => {
        return {
            customerId: null,
            customer: null,
            relationship: null,
            services: [],
        };
    }

    clear = () => {
        this.id = null;
        this.groupId = null;
        this.stageCustomer = null;
        this.data.groupId = null;
        this.data.existingAppointmentId = null;
        this.data.start = null;
        this.data.duration = null;
        this.data.primaryContactId = null;
        this.data.primaryContact = null;
        this.data.userId = null;
        this.data.customerServices.clear();
        this.data.notes.clear();
        this.checkUpcomingConflict = true;
        this.hasUnsavedChanges = false;
        this.isReady = false;
        this.isSaving = false;

        if (fn.isFunction(this.cancelAppointmentCreate)) {
            this.cancelAppointmentCreate();
            this.cancelAppointmentCreate = null;
        }
    }

    get end() {
        if (this.data.start && this.data.duration && this.data.duration > 0) {
            return this.data.start.clone().add(this.data.duration, 'minutes');
        }
        return null;
    }

    get startTime() {
        if (this.data.start) {
            return moment().startOf('day').hours(this.data.start.hour()).minutes(this.data.start.minute());
        }
        return null;
    }

    get endTime() {
        if (this.end) {
            return moment().startOf('day').hours(this.end.hour()).minutes(this.end.minute());
        }
        return null;
    }

    get recommendedDuration() {
        return this.data.customerServices
            .map(c => {
                var totalDuration = c.services.reduce((acc, service) => {
                    acc += service.duration;
                    const selectedUserId = this.data.userId;
                    // Iterate over userOverrides
                    if (service.userOverrides) {
                        const userOverride = service.userOverrides.find(o => o.userId === selectedUserId);
                        if (userOverride && userOverride.isBookable && userOverride.additionalTimeInMinutes !== 0) {
                            acc += userOverride.additionalTimeInMinutes;
                        }
                    }
                    return acc;
                }, 0);
                return totalDuration;
        
                //return c.services.map(s => { return s.duration ? s.duration : 0 }).reduce((a, b) => a + b, 0)
            })
            .reduce((x, y) => x + y, 0);
    }
}

decorate(GroupAppointmentCreate, {
    id: observable,
    groupId: observable,
    stageCustomer: observable,
    data: observable.deep,
    checkUpcomingConflict: observable,
    end: computed,
    startTime: computed,
    endTime: computed,
    recommendedDuration: computed,
    hasUnsavedChanges: observable,
    isReady: observable,
    isSaving: observable,
    initialize: action,
    checkConflicts: action,
    checkEligibility: action,
    save: action,
    clear: action,
    addNewCustomerService: action,
})

export default createContext(new GroupAppointmentCreate());