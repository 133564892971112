export const hasValue = (geocode, key) => {
    if (!geocode) return null;
    return geocode.address_components.some(c => c.types.some(t => t === key))[0];
}

export const getValue = (geocode, key, getFullDescription = true) => {
    if (!geocode) return null;
    const obj = geocode.address_components.filter(c => c.types.some(t => t === key))[0];
    return obj ? (getFullDescription ? obj.long_name : obj.short_name) : null;
}

export const setValue = (geocode, key, value) => {
    if (!geocode) return null;
    
    let found = false;

    for (let i = 0; i < geocode.address_components.length; i++) {
        if (geocode.address_components[i].types.some(t => t === key)) {
            geocode.address_components[i].long_name = value;
            geocode.address_components[i].short_name = value;
            found = true;
        }
    }

    if (!found) {
        geocode.address_components.push({
            long_name: value,
            short_name: value,
            types: [key]
        })
    }

    return geocode;
}

export const setAddress = (geocode) => {
    const newAddress = {
        id: null,
        streetNumber: getValue(geocode, 'street_number'),
        streetName: getValue(geocode, 'route'), 
        unitSuiteType: null,
        unitSuite: getValue(geocode, 'subpremise'), 
        line2: null,
        neighborhood: getValue(geocode, 'neighborhood'),  
        locality: getValue(geocode, 'locality'),  
        sublocality: getValue(geocode, 'sublocality'),  
        postalCode: getValue(geocode, 'postal_code'),  
        region: getValue(geocode, 'administrative_area_level_1'),   
        regionCode: getValue(geocode, 'administrative_area_level_1', false),   
        country: getValue(geocode, 'country'),   
        countryCode: getValue(geocode, 'country', false),   
        latitude: getValue(geocode, 'latitude'),   
        longitude: getValue(geocode, 'longitude'),
    };

    if (!!newAddress.unitSuite && !newAddress.unitSuiteType) {
        newAddress.unitSuiteType = 'Unit';
    }

    if (!!newAddress.streetNumber && !!newAddress.streetName) 
    {
        newAddress.geocode = geocode;
    }

    return newAddress;
}

export const getStreetNumberName = (address) => {
    if (!address) return null;

    const streetNumberName = `${(address.streetNumber ? address.streetNumber : '')} ${(address.streetName ? address.streetName : '')}`.trim();
    return !!streetNumberName ? streetNumberName : null;    
}