import React, { useRef, useEffect } from 'react';
import { useObserver } from 'mobx-react-lite';

import Searchbar from './Searchbar';
import NavbarAdminMenu from './NavbarAdminMenu';
import NavbarCommunication from './NavbarCommunication';
import NavbarNotification from './NavbarNotification';
import NavbarPublicInsurance from './NavbarPublicInsurance';
import UserMenu from './UserMenu';

import * as ah from '../../../utilities/accessHelper';
import * as AccessType from '../../../constants/accessTypes';

import './Navbar.scss';

function Navbar() {
    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        }

    }, []) // eslint-disable-line

    return useObserver(() => <>
        <header className='page-header' role='banner'>
            <div className='hidden-lg-up'>
                <a href='/' className='header-btn btn press-scale-down' data-action='toggle'>
                    <i className='ni ni-menu'></i>
                </a>
            </div>
            <Searchbar />
            <div className='ml-auto d-flex'>
                {
                    ah.checkAny([AccessType.VIEW_ADDRESS_BOOK, AccessType.UPDATE_ADDRESS_BOOK, AccessType.UPDATE_SYSTEM_SETTING]) ?
                        <NavbarAdminMenu /> : null
                }
                <NavbarPublicInsurance />
                <NavbarCommunication />
                <NavbarNotification />
                <UserMenu />
            </div>
        </header>
    </>)
}

export default Navbar;