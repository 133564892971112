import React from 'react';
import { ALL_USERS, CURRENT_USER } from '../constants/storageKeys';
import * as ph from './personHelper';

export const getCurrent = () => {
    const user = JSON.parse(window.localStorage.getItem(CURRENT_USER));
    const currentUser = getById(user.id);
    return currentUser;
}

export const getById = (id) => {
    if (!id) return null;
    const users = JSON.parse(window.localStorage.getItem(ALL_USERS));
    if (users && users.length === 0) throw new Error('No users found');
    const found = users ? users.filter(u => u.id === id)[0] : false;
    return found;
}

export const getDisplayFullName = (user) => {
    if (!user) return '';
    const displayName = getDisplayFullNameById(user.id);
    return displayName ? displayName : ph.getPreferredFirstLastName(user)
}

export const getDisplayShortName = (user) => {
    if (!user) return '';
    const displayName = getDisplayShortNameById(user.id);
    return displayName ? displayName : ph.getPreferredFirstName(user)
}

export const getDisplayFullNameById = (id, defaultName = '') => {
    if (!id) return defaultName;
    const found = getById(id);
    return found ? found.fullName : defaultName;
}

export const getDisplayShortNameById = (id, defaultName = '') => {
    if (!id) return defaultName;
    const found = getById(id);
    if (found) {
        if (found.fullName && found.fullName.startsWith('Dr.')) {
            return found.fullName;
        }
        else {
            return found.displayShortName ? found.displayShortName : defaultName
        }
    }
    return defaultName;
}

export const getPasswordGuidelineHTML = () => {
    return <ul style={{ padding: 0, margin: 0 }}>
        <li>Password must be at least 8 characters in length</li>
        <li>Contains upper case (A-Z) and lower case (a-z) letters </li>
        <li>Contains at least 1 number</li>
        <li>Contains at least 1 special character (e.g. @ # $ % ^ & *)</li>
    </ul>
}