import React, { useEffect, useContext, useState, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { toJS } from 'mobx';
import { useObserver } from 'mobx-react-lite';
import MaskedInput from 'react-text-mask'
import { DropdownList } from 'react-widgets'
import { toast } from 'react-toastify';
import { GlobalHotKeys } from 'react-hotkeys';

import BodyEnd from '../../_shared/BodyEnd';
import LoadingOverlay from '../../_shared/LoadingOverlay';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';

import CustomerUpdateStore from '../../../../stores/CustomerUpdateStore';
import NoteSearchStore from '../../../../stores/NoteSearchStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';
import CacheStore from '../../../../stores/CacheStore';
import useSignalR from '../../../hooks/useSignalR';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as MaskKeys from '../../../../constants/maskKeys';
import * as fn from '../../../../utilities/_functions';
import * as ch from '../../../../utilities/customerHelper';
import * as ph from '../../../../utilities/personHelper';
import * as oh from '../../../../utilities/operationHelper';
import * as nh from '../../../../utilities/noteHelper';

import './UpdateCustomer.scss';

function UpdateCustomer(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const focusTimer = useRef(null);
    const customer = useContext(CustomerUpdateStore);
    const noteSearch = useContext(NoteSearchStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const cache = useContext(CacheStore);
    const signalR = useSignalR();
    const [isUploadingAttachments, setIsUploadingAttachments] = useState(false);
    const [isAddressInformationActive, setIsAddressInformationActive] = useState(false);
    const [isContactInformationActive, setIsContactInformationActive] = useState(false);
    const [isPatientInformationActive, setIsPatientInformationActive] = useState(false);
    const [isLinkedRelationshipActive, setIsLinkedRelationshipActive] = useState(false);
    const [isInsuranceActive, setIsInsuranceActive] = useState(false);

    useEffect(() => {

        signalR.on('Customer', (updated) => {
            if (updated && customer && customer.id === updated.id) {
                customer.refresh();
            }
        });
        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100)

        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (customer.hasPersonalUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleColorChange = (color) => {
        customer.hasPersonalUnsavedChanges = true;
        customer.data.color = color.value;
    }

    const handleSalutationChange = event => {
        customer.hasPersonalUnsavedChanges = true;
        customer.data.salutation = event.target.value;
    }

    const handleFirstNameChange = event => {
        customer.hasPersonalUnsavedChanges = true;
        customer.data.firstName = event.target.value;
    }

    const handleLastNameChange = event => {
        customer.hasPersonalUnsavedChanges = true;
        customer.data.lastName = event.target.value;
    }

    const handleMiddleNameChange = event => {
        customer.hasPersonalUnsavedChanges = true;
        customer.data.middleName = event.target.value;
    }

    const handlePreferredFirstNameChange = event => {
        customer.hasPersonalUnsavedChanges = true;
        customer.data.preferredFirstName = event.target.value;
    }

    const handleSuffixChange = event => {
        customer.hasPersonalUnsavedChanges = true;
        customer.data.suffix = event.target.value;
    }

    const handleSexChange = (event) => {
        const sex = event.target.value;

        customer.hasPersonalUnsavedChanges = true;
        customer.data.sex = customer.data.sex === sex ? null : sex;
    }

    const handleGenderChange = (event) => {
        const gender = event.target.value;

        customer.hasPersonalUnsavedChanges = true;
        customer.data.gender = customer.data.gender === gender ? null : gender;

        if (customer.data.gender === 'Other') {
            setTimeout(() => {
                const otherInput = document.querySelector('#update-customer-gender-other-input');
                if (otherInput) {
                    otherInput.focus();
                }
            }, 200);
        }
    }

    const handleGenderOtherChange = (event) => {
        const genderOther = event.target.value;

        customer.hasPersonalUnsavedChanges = true;
        customer.data.genderOther = genderOther;
    }

    const handleGenderOtherClear = event => {
        customer.data.genderType = null;
        customer.data.genderOther = null;
        customer.hasPersonalUnsavedChanges = true;
    }

    const handlePronounChange = (event) => {
        const pronoun = event.target.value;

        customer.hasPersonalUnsavedChanges = true;
        customer.data.pronoun = customer.data.pronoun === pronoun ? null : pronoun;

        if (customer.data.pronoun === 'Other') {
            setTimeout(() => {
                const otherInput = document.querySelector('#update-customer-pronoun-other-input');
                if (otherInput) {
                    otherInput.focus();
                }
            }, 200);
        }
    }

    const handlePronounOtherChange = (event) => {
        const pronounOther = event.target.value;

        customer.hasPersonalUnsavedChanges = true;
        customer.data.pronounOther = pronounOther;
    }

    const handlePronounOtherClear = event => {
        customer.data.pronounType = null;
        customer.data.pronounOther = null;
        customer.hasPersonalUnsavedChanges = true;
    }

    const handleDateOfBirthChange = event => {
        customer.hasPersonalUnsavedChanges = true;
        customer.data.dateOfBirth = event.target.value;
    }

    const handleSeeNotes = () => {
        noteSearch.initialize(customer.data.id);
        quickDrawer.activateQuickDrawer('note', 'view', null, handleNoteUpdateSuccess, handleNoteUpdateCancel);
    }

    const handleNoteUpdateSuccess = () => {
        noteSearch.clear();
    }

    const handleNoteUpdateCancel = () => {
        noteSearch.clear();
    }

    const handleAddressInformation = () => {
        setIsAddressInformationActive(true);
        quickDrawer.activateQuickDrawer('customer', 'address', null, handleAddressInformationUpdateSuccess, handleAddressInformationUpdateCancel);
    }

    const handleAddressInformationUpdateSuccess = (result) => {
        if (result && result.updated) {
            toast.dark(() => <p data-addr-upd>Customer's address updated.</p>);
        }
        setIsAddressInformationActive(false);
    }

    const handleAddressInformationUpdateCancel = () => {
        setIsAddressInformationActive(false);
    }

    const handleContactInformation = () => {
        customer.loadRelationships();
        setIsContactInformationActive(true);
        quickDrawer.activateQuickDrawer('customer', 'contact', null, handleContactInformationUpdateSuccess, handleContactInformationUpdateCancel);
    }

    const handleContactInformationUpdateSuccess = (result) => {
        if (result && result.updated) {
            toast.dark(() => <p data-addr-upd>Customer's contact updated.</p>);
        }
        setIsContactInformationActive(false);
    }

    const handleContactInformationUpdateCancel = () => {
        setIsContactInformationActive(false);
    }

    const handlePatientInformation = () => {
        customer.loadPatientProfile();
        quickDrawer.activateQuickDrawer('customer', 'patient', null, handlePatientInformationUpdateSuccess, handlePatientInformationUpdateCancel);
        setIsPatientInformationActive(true);
    }

    const handlePatientInformationUpdateSuccess = (result) => {
        if (result && result.updated) {
            toast.dark(() => <p data-profile-upd>Patient profile updated.</p>);
        }
        setIsPatientInformationActive(false);
    }

    const handlePatientInformationUpdateCancel = () => {
        setIsPatientInformationActive(false);
    }

    const handleLinkedRelationship = () => {
        customer.loadRelationships();
        quickDrawer.activateQuickDrawer('customer', 'relationship-update', null, handleLinkedRelationshipUpdateSuccess, handleLinkedRelationshipUpdateCancel);
        setIsLinkedRelationshipActive(true);
    }

    const handleLinkedRelationshipUpdateSuccess = (result) => {
        if (result && result.updated) {
            toast.dark(() => <p data-linked-rel-updated>Linked relationships updated.</p>);
        }
        setIsLinkedRelationshipActive(false);
    }

    const handleLinkedRelationshipUpdateCancel = () => {
        setIsLinkedRelationshipActive(false);
    }

    const handleInsuranceInformation = () => {
        const requests = [];

        if (!customer.isReady) {
            requests.push(new Promise(resolve => {
                customer.initialize(customer.id)
                    .then(() => {
                        Promise.all([
                            customer.loadRelationships(),
                            customer.loadInsurance()
                        ])
                            .then(() => {
                                resolve();
                            })
                    })
            }));
        } else {
            requests.push(customer.loadRelationships());
            requests.push(customer.loadInsurance());
        }

        Promise.all(requests)
            .then(() => {
                if (isMounted.current) {
                    const privateInsurances = customer.privateInsurances && customer.privateInsurances.length > 0 ? toJS(customer.privateInsurances) : [];

                    if (!privateInsurances || privateInsurances.length === 0) {
                        quickDrawer.activateQuickDrawer('customer', 'private-insurance-create', null, handleInsuranceInformationUpdateSuccess, handleInsuranceInformationUpdateCancel);
                    }
                    else if (privateInsurances.length === 1) {
                        customer.selectedPrivateInsuranceId = privateInsurances[0].privateInsurancePolicyId;
                        quickDrawer.activateQuickDrawer('customer', 'private-insurance-update', null, handleInsuranceInformationUpdateSuccess, handleInsuranceInformationUpdateCancel);
                    }
                    else {
                        quickDrawer.activateQuickDrawer('customer', 'private-insurance', { canUpdate: true }, handleInsuranceInformationUpdateSuccess, handleInsuranceInformationUpdateCancel);
                    }

                    setIsInsuranceActive(true);
                }
            })
    }

    const handleInsuranceInformationUpdateSuccess = (result) => {
        if (result && result.updated) {
            toast.dark(() => <p data-ins-upd>Customer's insurance updated.</p>);
        }
        setIsInsuranceActive(false);
    }

    const handleInsuranceInformationUpdateCancel = () => {
        setIsInsuranceActive(false);
    }

    const handleReactivate = (event) => {
        quickDrawer.activateQuickDrawer('customer', 'reactivate', null, handleReactivateSuccess);
    }

    const handleReactivateSuccess = (result) => {
        if (result && result.updated) {
            customer.refresh();
            toast.dark(() => <p data-cust-reactivated>Customer has been reactivated.</p>);
        }
    }

    const handleDeactivate = (event) => {
        quickDrawer.activateQuickDrawer('customer', 'deactivate', null, handleDeactivateSuccess);
    }

    const handleDeactivateSuccess = (result) => {
        if (result && result.updated) {
            customer.refresh();
            toast.dark(() => <p data-cust-deactivated>Customer has been deactivated.</p>);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            if (!customer.data.firstName || !customer.data.lastName) {
                toast.error(() => ErrorMessages.GENERIC_FORM_ERROR_HTML, { position: 'top-center', style: { width: '380px' } });
            }
            else {
                if (customer.saveToServer && customer.data.notes && customer.data.notes.length > 0 && customer.data.notes.some(n => n.attachments && n.attachments.length > 0)) {
                    setIsUploadingAttachments(true);
                }

                if (customer.hasPersonalUnsavedChanges || isUploadingAttachments) {
                    const updated = customer.hasPersonalUnsavedChanges;

                    customer.updatePersonalInformation(true)
                        .then((data) => {
                            if (isMounted.current) {
                                setIsUploadingAttachments(false);
                                if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                                    props.onSuccess(event, { updated: updated, data: data });
                                }
                            }
                        })
                } else {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, { updated: false, data: null });
                    }
                }
            }
        }
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <fieldset disabled={customer.isSaving}>
                <div className='update-customer-container quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('customer', 'personal')}
                        action='Update'
                        category='Customer'
                        className='customers'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        {
                            customer.isReady ?
                                <FadeIn>
                                    <div className='body-content'>
                                        <section>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='form-group'>
                                                        <label htmlFor='update-personal-color-select'><small>Group</small></label>
                                                        <DropdownList
                                                            data={ch.GROUPS}
                                                            disabled={!ch.checkCanUpdate(customer.data)}
                                                            value={customer.data && customer.data.color && ch.GROUPS.some(g => g.value === customer.data.color) ? customer.data.color : null}
                                                            itemComponent={({ item }) => (
                                                                item ? <><i className={`fas fa-circle mr-2 text-${(item && item.value ? item.value.toLowerCase() : 'default')}`}></i>{`Group ${item.key ? item.key : 'default'}`}</> : <></>
                                                            )}
                                                            valueComponent={({ item }) => (
                                                                item && ch.GROUPS.some(g => g.value === item && g.key) ? <>Group {ch.GROUPS.filter(g => g.value === item)[0].key}</> : <>Group default</>
                                                            )}
                                                            onChange={handleColorChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='form-group'>
                                                        <label htmlFor='update-personal-salutation-type-select'><small>Prefix</small></label>
                                                        <select
                                                            id='update-personal-salutation-type-select'
                                                            className='custom-select form-control'
                                                            disabled={!ch.checkCanUpdate(customer.data)}
                                                            value={customer.data.salutation ? customer.data.salutation : ''}
                                                            onChange={handleSalutationChange}
                                                        >
                                                            <option value=''></option>
                                                            {
                                                                cache.getReferenceDataOptions('SalutationType').map((option, di) => {
                                                                    return <option key={`salutation_type_${di}`} value={option.key}>{option.value}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='form-group validate validate-required'>
                                                        <label className='required' htmlFor='update-personal-firstname-input'><small>Given Name</small></label>
                                                        <input
                                                            id='update-personal-firstname-input'
                                                            type='text'
                                                            className='form-control'
                                                            spellCheck={false}
                                                            maxLength='50'
                                                            autoComplete='off'
                                                            disabled={!ch.checkCanUpdate(customer.data)}
                                                            value={customer.data.firstName ? customer.data.firstName : ''}
                                                            onChange={handleFirstNameChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='form-group validate validate-required'>
                                                        <label className='required' htmlFor='update-personal-lastname-input'><small>Last Name</small></label>
                                                        <input
                                                            id='update-personal-lastname-input'
                                                            type='text'
                                                            className='form-control'
                                                            maxLength='50'
                                                            spellCheck={false}
                                                            autoComplete='off'
                                                            disabled={!ch.checkCanUpdate(customer.data)}
                                                            value={customer.data.lastName ? customer.data.lastName : ''}
                                                            onChange={handleLastNameChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='form-group'>
                                                        <label htmlFor='update-personal-middlename-input'><small>Middle Name</small></label>
                                                        <input
                                                            id='update-personal-middlename-input'
                                                            type='text'
                                                            className='form-control'
                                                            maxLength='50'
                                                            spellCheck={false}
                                                            autoComplete='off'
                                                            disabled={!ch.checkCanUpdate(customer.data)}
                                                            value={customer.data.middleName ? customer.data.middleName : ''}
                                                            onChange={handleMiddleNameChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='form-group'>
                                                        <label htmlFor='update-personal-preferred-firstname-input'><small>Preferred Name</small></label>
                                                        <input
                                                            id='update-personal-preferred-firstname-input'
                                                            type='text'
                                                            className='form-control'
                                                            spellCheck={false}
                                                            maxLength='50'
                                                            autoComplete='off'
                                                            disabled={!ch.checkCanUpdate(customer.data)}
                                                            value={customer.data.preferredFirstName ? customer.data.preferredFirstName : ''}
                                                            onChange={handlePreferredFirstNameChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='form-group'>
                                                        <label htmlFor='update-personal-suffix-type-select'><small>Suffix</small></label>
                                                        <select
                                                            id='update-personal-suffix-type-select'
                                                            className='custom-select form-control'
                                                            disabled={!ch.checkCanUpdate(customer.data)}
                                                            value={customer.data.suffix ? customer.data.suffix : ''}
                                                            onChange={handleSuffixChange}
                                                        >
                                                            <option value=''></option>
                                                            {
                                                                cache.getReferenceDataOptions('SuffixType').map((option, di) => {
                                                                    return <option key={`suffix_type_${di}`} value={option.key}>{option.value}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='form-group'>
                                                        <label className='d-block' htmlFor='update-personal-sex-input'><small>Sex</small></label>
                                                        <select
                                                            id='update-personal-sex-input'
                                                            className='custom-select form-control'
                                                            disabled={!ch.checkCanUpdate(customer.data)}
                                                            value={customer.data.sex ? customer.data.sex : ''}
                                                            onChange={handleSexChange}
                                                        >
                                                            <option value=''></option>
                                                            {
                                                                cache.getReferenceDataOptions('SexType').map((option, di) => {
                                                                    return <option key={`sex_type_${di}`} value={option.key}>{option.value}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='form-group'>
                                                        <label className='d-block' htmlFor='update-personal-gender-input'><small>Gender</small></label>
                                                        <select
                                                            id='update-personal-gender-input'
                                                            className={'custom-select form-control' + (customer.data.gender === 'Other' ? ' d-none' : '')}
                                                            disabled={!ch.checkCanUpdate(customer.data)}
                                                            value={customer.data.gender ? customer.data.gender : ''}
                                                            onChange={handleGenderChange}
                                                        >
                                                            <option value=''></option>
                                                            <option value='Male'>{ph.getGenderDescription('Male')}</option>
                                                            <option value='Female'>{ph.getGenderDescription('Female')}</option>
                                                            <option value='NonBinary'>{ph.getGenderDescription('NonBinary')}</option>
                                                            <option value='Other'>{ph.getGenderDescription('Other')}</option>
                                                        </select>
                                                        <div className={'relative' + (customer.data.gender !== 'Other' ? ' d-none' : '')}>
                                                            <input
                                                                id='update-customer-gender-other-input'
                                                                type='text'
                                                                className={'form-control' + (customer.data.gender !== 'Other' ? ' d-none' : '')}
                                                                spellCheck={false}
                                                                maxLength='50'
                                                                autoComplete='off'
                                                                disabled={!ch.checkCanUpdate(customer.data)}
                                                                value={customer.data.genderOther ? customer.data.genderOther : ''}
                                                                onChange={handleGenderOtherChange}
                                                            />
                                                            <button
                                                                type='button'
                                                                className='btn btn-icon-only position-absolute absolute-right absolute-bottom'
                                                                disabled={!ch.checkCanUpdate(customer.data)}
                                                                onClick={handleGenderOtherClear}
                                                            >
                                                                <i className='fal fa-times text-danger'></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='form-group'>
                                                        <label className='d-block' htmlFor='update-personal-pronoun-input'><small>Pronoun</small></label>
                                                        <select
                                                            id='update-personal-pronoun-input'
                                                            className={'custom-select form-control' + (customer.data.pronoun === 'Other' ? ' d-none' : '')}
                                                            disabled={!ch.checkCanUpdate(customer.data)}
                                                            value={customer.data.pronoun ? customer.data.pronoun : ''}
                                                            onChange={handlePronounChange}
                                                        >
                                                            <option value=''></option>
                                                            <option value='HeHim'>{ph.getPronounDescription('HeHim')}</option>
                                                            <option value='SheHer'>{ph.getPronounDescription('SheHer')}</option>
                                                            <option value='TheyThem'>{ph.getPronounDescription('TheyThem')}</option>
                                                            <option value='HeThey'>{ph.getPronounDescription('HeThey')}</option>
                                                            <option value='SheThey'>{ph.getPronounDescription('SheThey')}</option>
                                                            <option value='AeAer'>{ph.getPronounDescription('AeAer')}</option>
                                                            <option value='FaeFaer'>{ph.getPronounDescription('FaeFaer')}</option>
                                                            <option value='EyEm'>{ph.getPronounDescription('EyEm')}</option>
                                                            <option value='PerPer'>{ph.getPronounDescription('PerPer')}</option>
                                                            <option value='VeVer'>{ph.getPronounDescription('VeVer')}</option>
                                                            <option value='XeXem'>{ph.getPronounDescription('XeXem')}</option>
                                                            <option value='ZeHir'>{ph.getPronounDescription('ZeHir')}</option>
                                                            <option value='Other'>{ph.getPronounDescription('Other')}</option>
                                                        </select>
                                                        <div className={'relative' + (customer.data.pronoun !== 'Other' ? ' d-none' : '')}>
                                                            <input
                                                                id='update-customer-pronoun-other-input'
                                                                type='text'
                                                                className={'form-control' + (customer.data.pronoun !== 'Other' ? ' d-none' : '')}
                                                                spellCheck={false}
                                                                maxLength='50'
                                                                autoComplete='off'
                                                                disabled={!ch.checkCanUpdate(customer.data)}
                                                                value={customer.data.pronounOther ? customer.data.pronounOther : ''}
                                                                onChange={handlePronounOtherChange}
                                                            />
                                                            <button
                                                                type='button'
                                                                className='btn btn-icon-only position-absolute absolute-right absolute-bottom'
                                                                disabled={!ch.checkCanUpdate(customer.data)}
                                                                onClick={handlePronounOtherClear}
                                                            >
                                                                <i className='fal fa-times text-danger'></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='form-group validate validate-date'>
                                                        <label htmlFor='update-personal-dob-input'><small>Date of Birth <span className='text-gray-500'>(yyyy-mm-dd)</span></small></label>
                                                        <div className='input-group'>
                                                            <MaskedInput
                                                                id='update-personal-dob-input'
                                                                type='text'
                                                                className='form-control'
                                                                spellCheck={false}
                                                                mask={MaskKeys.DATE_MASK}
                                                                pipe={MaskKeys.DATE_PIPE}
                                                                maxLength='25'
                                                                autoComplete='off'
                                                                disabled={!ch.checkCanUpdate(customer.data)}
                                                                value={customer.data.dateOfBirth ? customer.data.dateOfBirth : ''}
                                                                onChange={handleDateOfBirthChange}
                                                            />
                                                            <div
                                                                className='input-group-append'
                                                                style={{ minWidth: '50px' }}
                                                            >
                                                                <span
                                                                    className='input-group-text'
                                                                    style={{ minWidth: '50px' }}
                                                                >
                                                                    {ph.getAge(customer.data.dateOfBirth)}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </FadeIn> : renderQuickDrawerLoading()
                        }
                    </div>
                    <div className='customer-info-nav'>
                        <button
                            type='button'
                            className={'btn btn-icon' + (isAddressInformationActive ? ' text-primary' : '')}
                            title='Address information'
                            disabled={!customer.isReady}
                            onClick={handleAddressInformation}
                        >
                            <i className={oh.getIcon('customer', 'address')}></i>
                        </button>
                        <button
                            type='button'
                            className={'btn btn-icon' + (isContactInformationActive ? ' text-primary' : '')}
                            title='Contact information'
                            disabled={!customer.isReady}
                            onClick={handleContactInformation}
                        >
                            <i className={oh.getIcon('customer', 'contact')}></i>
                        </button>
                        <button
                            type='button'
                            className={'btn btn-icon' + (isPatientInformationActive ? ' text-primary' : '')}
                            title='Patient information'
                            disabled={!customer.isReady}
                            onClick={handlePatientInformation}
                        >
                            <i className={oh.getIcon('customer', 'patient')}></i>
                        </button>
                        <button
                            type='button'
                            className={'btn btn-icon' + (isLinkedRelationshipActive ? ' text-primary' : '')}
                            title='Linked relationship'
                            disabled={!customer.isReady}
                            onClick={handleLinkedRelationship}
                        >
                            <i className={oh.getIcon('customer', 'relationship')}></i>
                        </button>
                        <button
                            type='button'
                            className={'btn btn-icon' + (isInsuranceActive ? ' text-primary' : '')}
                            title='Insurance information'
                            disabled={!customer.isReady}
                            onClick={handleInsuranceInformation}
                        >
                            <i className={oh.getIcon('customer', 'private-insurance')}></i>
                        </button>
                    </div>
                    <div className='quick-drawer-action pl-3'>
                        <div className='row'>
                            <div className='col-4'>
                                <button
                                    type='button'
                                    className='btn btn-icon'
                                    disabled={!customer.isReady || !ch.checkCanUpdate(customer.data)}
                                    onClick={handleSeeNotes}
                                    title='Notes'
                                >
                                    <i className='fal fa-comment-alt-lines'></i>
                                    {
                                        customer.data && customer.data.notes && customer.data.notes.length > 0 ?
                                            <span className={`badge badge-icon`}>{customer.data.notes.length}</span> : null
                                    }
                                </button>
                            </div>
                            <div className='col-8'>
                                <div className='float-right'>
                                    {
                                        customer.isReady ?
                                            <>
                                                {
                                                    customer.data.isActive ?
                                                        <>
                                                            <button
                                                                type='button'
                                                                className='btn btn-danger mr-2'
                                                                onClick={handleDeactivate}
                                                            >Deactivate</button>
                                                            <button
                                                                type='submit'
                                                                className='btn btn-success'
                                                                disabled={!ch.checkCanUpdate(customer.data)}
                                                            >Save</button>
                                                        </> :
                                                        <>
                                                            <button
                                                                type='button'
                                                                className='btn btn-success'
                                                                onClick={handleReactivate}
                                                            >Reactivate</button>
                                                        </>
                                                }
                                            </> : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
        <BodyEnd>
            <LoadingOverlay isLoading={isUploadingAttachments} message={nh.UPLOAD_MESSAGE} />
        </BodyEnd>
    </>)
}

export default UpdateCustomer;