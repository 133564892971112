import React, { useContext, useEffect, useState, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { useObserver } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import momentLocalizer from 'react-widgets-moment';
import { GlobalHotKeys } from 'react-hotkeys';
import moment from 'moment';

import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';

import BodyEnd from '../../_shared/BodyEnd';
import ConfirmModal from '../../_shared/ConfirmModal';
import ProductUpdateStore from '../../../../stores/ProductUpdateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as AccessType from '../../../../constants/accessTypes';
import * as fn from '../../../../utilities/_functions';
import * as ah from '../../../../utilities/accessHelper';
import * as oh from '../../../../utilities/operationHelper';

import './DeleteProduct.scss';

moment.locale('en');
momentLocalizer();


function DeleteProduct(props) {
    const isMounted = useRef(true);
    const updateProduct = useContext(ProductUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [confirmDelete, setConfirmDelete] = useState(false);

    useEffect(() => {
        quickDrawerFocus(props.drawer);
        return () => {
            isMounted.current = false;
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (updateProduct.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (updateProduct.data.isStocked && updateProduct.data.isTracked && updateProduct.data.quantity > 0) {
            toast.error(() => <p><strong>Cannot delete.</strong> There is still inventory left with this product.</p>, { position: 'top-center', style: { width: '380px' } });
        } else {
            setConfirmDelete(true);
        }
    }

    const handleConfirmDelete = event => {
        updateProduct.delete()
            .then(data => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, data);
                    }
                }
            })
    }

    const handleConfirmCancel = event => {
        setConfirmDelete(false);

        if (props.onCancel && fn.isFunction(props.onCancel)) {
            props.onCancel(event);
        }
    }

    const getOptionValue = key => {
        if (updateProduct.data.data && updateProduct.data.data.some(d => d.key === key)) {
            return updateProduct.data.data.filter(d => d.key === key)[0].value;
        }
        return '';
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) && !confirmDelete ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                props.onCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form onSubmit={handleSubmit}>
            <fieldset disabled={updateProduct.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('product', 'delete')}
                        action='Delete'
                        category='Product'
                        className='delete'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        {
                            updateProduct.isReady ?
                                <FadeIn>
                                    <div className='delete-product body-content'>
                                        <section className='product-info'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <label><small>{updateProduct.data.type.name}</small></label>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <ul className='title'>
                                                        {
                                                            updateProduct.data.isStocked && updateProduct.data.isTracked ?
                                                                <li className='quantity'>
                                                                    <h4 className={updateProduct.data.quantity ? '' : 'text-danger'}>
                                                                        <strong>{updateProduct.data.quantity ? updateProduct.data.quantity : 0}<small>x</small></strong>
                                                                    </h4>
                                                                </li> : null
                                                        }
                                                        <li className={'detail' + (updateProduct.data.isStocked && updateProduct.data.isTracked ? ' has-quantity' : '')}>
                                                            <small className='brand text-truncate' title={`${updateProduct.data.brand.name} (${updateProduct.data.supplier.name})`}><strong>{updateProduct.data.brand.name}</strong> ({updateProduct.data.supplier.name})</small>
                                                            <h4 className='m-0 text-truncate' title={updateProduct.data.name}><strong>{updateProduct.data.name}</strong></h4>
                                                            <span className='text-gray text-truncate' title={updateProduct.data.descriptor}>{updateProduct.data.descriptor}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </section>
                                        <section className='product-price'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <label><small>Price</small></label>
                                                    <h4><strong className={updateProduct.data.price < 0 ? ' text-danger' : ''}>{fn.formatCurrency(updateProduct.data.price, '--')}</strong> {ah.check(AccessType.UPDATE_PRODUCT) ? <small className={updateProduct.data.cost ? '' : 'd-none'}>({fn.formatCurrency(updateProduct.data.cost, '--')})</small> : null}</h4>
                                                </div>
                                            </div>
                                        </section>
                                        {
                                            updateProduct.data.data && updateProduct.data.data.length > 0 ?
                                                <section className='product-data'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <label><small>Extra Data</small></label>
                                                            <ul className='leaders'>
                                                                {
                                                                    updateProduct.data.type.data.map((t, ti) => {
                                                                        return <li
                                                                            key={`product_data_${ti}`}
                                                                        >
                                                                            <span>{t.name}</span>
                                                                            <span>{getOptionValue(t.key)}</span>
                                                                        </li>
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </section> : null
                                        }
                                    </div>
                                </FadeIn> : renderQuickDrawerLoading()
                        }
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-danger'
                                    >Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
        <BodyEnd>
            <ConfirmModal
                icon={<i className={'text-danger mr-2 ' + oh.getIcon('product', 'delete')}></i>}
                message={<>Continue to delete <span className='fw-500'>&nbsp;{updateProduct.data ? updateProduct.data.name : ''}</span>? </>}
                descriptionClassName='warning-danger warning-flashing'
                show={confirmDelete}
                onOption1Click={handleConfirmDelete}
                onCancel={handleConfirmCancel}
            />
        </BodyEnd>
    </>)
}

export default DeleteProduct;