import moment from 'moment';

import * as fn from './_functions';
import * as ph from './personHelper';
import * as uh from './userHelper';
import * as sys from './systemHelper';

export const format = (data, timelineItem, formatString, isHtml) => {
    if (!data) return null;
    const isArray = Array.isArray(data);
    const appointment = isArray && data.length > 0 ? data[0] : data;
    const performedDate = timelineItem.performedDateUtc ? moment.utc(timelineItem.performedDateUtc).local() : null;
    const customerFirstName = ph.getPreferredFirstName(appointment.customer);
    const customerFirstNames = isArray ? data.filter(d => d).map(d => { return ph.getPreferredFirstName(d.customer) }).join(', ').replace(/,(?=[^,]+$)/, ' and') : '';
    const result = (formatString ? formatString : '')
        .replace('{CustomerFirstName}', isArray && timelineItem.isGrouped ? customerFirstNames : customerFirstName)
        .replace('{PerformedBy}', uh.getDisplayShortNameById(timelineItem.performedById, timelineItem.performedBy))
        .replace('{PerformedDate}', performedDate ? sys.getFormattedLongDate(performedDate) : '')
        .replace('{PerformedTime}', performedDate ? performedDate.format('h:mm a') : '')
        .replace('by System', '')
        .replace('System', '')

    return result;
}

export const quickDrawerTrigger = (type, quickDrawer, extraProps, onSuccess, onCancel) => {
    if (type) {
        switch (type.toLowerCase()) {
            case 'pretest':
                quickDrawer.activateQuickDrawer('pretest', 'create', extraProps, onDrawerTriggerSuccess(type, onSuccess), onCancel);
                break;

            default:
                onDrawerTriggerSuccess(type, onSuccess);
                break;
        }
    }
}

export const isEnabled = (appointment, restriction, id, displayOrder) => {
    let isEnabled = true;

    if (appointment && appointment.timeline && restriction) {
        const isPerformed = appointment.timeline.some(t => t.performedDateUtc && t.id === id);
        const isBeforeAppointment = appointment && moment(appointment.start).isSameOrAfter(moment());
        // TODO: not sure how this worked before without considering displayOrder.
        // The last condition I added means if displayOrder is not null, check only items after this one
        // In plain english: Don't show this timeline item if there's a "performed" item after this one
        const isBeforeOtherItem = !appointment.timeline.some(t => t.performedDateUtc && t.id !== id && (!displayOrder || t.displayOrder > displayOrder));
        const isAppointmentDay = appointment && moment(appointment.start).startOf('day').isSame(moment().startOf('day'));

        switch (restriction) {
            case 'BeforeAppointment':
                isEnabled = isPerformed || isBeforeAppointment;
                break;

            case 'BeforeAppointmentAndOtherItem':
                isEnabled = isPerformed || (isBeforeAppointment && isBeforeOtherItem);
                break;

            case 'BeforeOtherItem':
                isEnabled = isPerformed || isBeforeOtherItem;
                break;

            case 'AppointmentDayOnly':
                isEnabled = isPerformed || isAppointmentDay;
                break;

            default:
                break;
        }
    }

    return isEnabled;
}

const onDrawerTriggerSuccess = (type, callback) => {
    if (fn.isFunction(callback)) {
        return callback(type);
    }
}