import * as StorageKey from '../constants/storageKeys';
import * as TenantKey from '../constants/tenantSettingKeys';

import * as fh from './formatHelper';

export const configuration = {
    customer: {
        get spokenLanguages() {
            const languages = getArrayValue(TenantKey.SPOKEN_LANGUAGES);
            return languages && languages.length > 0 ? languages : [];
        },
        get communicationLanguages() {
            const referenceData = JSON.parse(window.localStorage.getItem(StorageKey.REFERENCE_DATA));
            const supportLanguages = referenceData && referenceData.some(r => r.name === 'CommunicationLanguageType') ? referenceData.filter(r => r.name === 'CommunicationLanguageType')[0].options : [];
            const languages = supportLanguages.filter(l1 => getArrayValue(TenantKey.COMMUNICATION_LANGUAGES).some(l2 => l1.key === l2));
            return languages && languages.length > 0 ? languages : [];
        },
    },
    appointment: {
        get dayViewGotoOptions() {
            const options = getAppointmentGotoOptions(TenantKey.APPOINTMENTS_DAYVIEW_GOTO_OPTIONS);
            return options;
        },
        get weekViewGotoOptions() {
            const options = getAppointmentGotoOptions(TenantKey.APPOINTMENTS_WEEKVIEW_GOTO_OPTIONS);
            return options;
        },
        get isGroupAppointmentEnabled() {
            const isEnabled = getBooleanValue(TenantKey.GROUP_APPOINTMENTS_ENABLED, false);
            return isEnabled;
        }
    },
    recall: {
        get maxAttempt() {
            const value = parseInt(getValue(TenantKey.RECALL_MAX_ATTEMPTS));
            return value && !isNaN(value) ? value : 2;
        },
    },
    purchase: {
        get governmentProgramCodes() {
            const governmentPrograms = getArrayValue(TenantKey.GOVERNMENT_PROGRAMS);
            return governmentPrograms && governmentPrograms.length > 0 ? governmentPrograms : [];
        },
        get isUnitPriceUpdateEnabled() {
            const isEnabled = getBooleanValue(TenantKey.PURCHASE_UNIT_PRICE_UPDATE_ENABLED, false);
            return isEnabled;
        },
    },
    exam: {
        get isPaperMigrationEnabled() {
            const isEnabled = getBooleanValue(TenantKey.PAPER_MIGRATION_ENABLED);
            return isEnabled === true;
        },
    },
    prescription: {
        get isDraftPrintEnabled() {
            const isEnabled = getBooleanValue(TenantKey.PRESCRIPTION_DRAFT_PRINT_ENABLED);
            return isEnabled === true;
        }
    },
    workOrder: {
        get isReviewEnabled() {
            const isEnabled = getBooleanValue(TenantKey.WORK_ORDER_REVIEW_ENABLED);
            return isEnabled === true;
        },
        get delayInDays() {
            const value = parseInt(getValue(TenantKey.WORK_ORDER_REVIEW_DELAY_IN_DAYS));
            return value && !isNaN(value) ? value : 3;
        },
    },
    endOfDay: {
        get isEnabled() {
            const isEnabled = getBooleanValue(TenantKey.END_OF_DAY_ENABLED);
            return isEnabled === true;
        },
        get isReviewEnabled() {
            const isEnabled = getBooleanValue(TenantKey.END_OF_DAY_REVIEW_ENABLED);
            return isEnabled === true;
        },
        get isReviewSelectAllEnabled() {
            const isEnabled = getBooleanValue(TenantKey.END_OF_DAY_REVIEW_SELECT_ALL);
            return isEnabled === true;
        },
        get delayInDays() {
            const value = parseInt(getValue(TenantKey.END_OF_DAY_REVIEW_DELAY_IN_DAYS));
            return value && !isNaN(value) ? value : 3;
        },
    },
}

export const getFormattedPhoneNumber = (value) => {
    return getFormattedValue(TenantKey.PHONE_NUMBER_FORMAT, value, 'PhoneBrackets');
}

export const getFormattedLongDate = (value, includeTime = false) => {
    const date = getFormattedValue(TenantKey.LONG_DATE_FORMAT, value, 'ShortDayOfWeekDate');

    if (!!includeTime) {
        return `${date} @ ${value.format('h:mm a')}`;
    }

    return date;
}

export const getFormattedShortDate = (value, includeTime = false) => {
    const date = getFormattedValue(TenantKey.SHORT_DATE_FORMAT, value, 'DashYearMonthDay');

    if (!!includeTime) {
        return `${date} @ ${value.format('h:mm a')}`;
    }

    return date;
}

const getAppointmentGotoOptions = (key) => {
    const array = getArrayValue(key);
    const options = array && array.length > 0 ? array.map(a => {
        if (a) {
            const parts = a.split(',');

            if (parts && parts.length === 3) {
                let text;
                const number = parseInt(parts[0], 10);
                const unit = parts[1];
                const addDay = parseInt(parts[2], 10);
                const key = `_${number}${unit}`;

                if (!isNaN(number) && ['week', 'month', 'year'].some(u => unit === u) && !isNaN(addDay)) {
                    switch (unit.toLowerCase()) {
                        case 'week':
                            text = `${number} wk`;
                            break;

                        case 'month':
                            text = `${number} mo`;
                            break;

                        case 'year':
                            text = `${number} yr`;
                            break;

                        default:
                            break;
                    }

                    return { key, text, number, unit, addDay }
                }
            }
        }
        return null;
    }).filter(o => !!o) : null;

    return options && options.length > 0 ? options : [];
}

const getValue = (key, defaultValue) => {
    const settings = window.localStorage.getItem(StorageKey.SETTINGS) ? JSON.parse(window.localStorage.getItem(StorageKey.SETTINGS)) : [];
    const setting = settings.filter(setting => setting.key === key)[0];

    return setting && setting.value ? setting.value : defaultValue;
}

const getFormattedValue = (key, value, defaultFormat) => {
    const settings = window.localStorage.getItem(StorageKey.SETTINGS) ? JSON.parse(window.localStorage.getItem(StorageKey.SETTINGS)) : [];
    const formatSetting = settings.filter(setting => setting.key === key)[0];

    if (formatSetting) {
        return fh.apply(value, formatSetting.value, value);
    }

    return fh.apply(value, defaultFormat, value);
}

const getBooleanValue = (key, defaultValue) => {
    const settings = window.localStorage.getItem(StorageKey.SETTINGS) ? JSON.parse(window.localStorage.getItem(StorageKey.SETTINGS)) : [];
    const booleanSetting = settings.filter(setting => setting.key === key)[0];

    if (booleanSetting) {
        return booleanSetting.value === 'true';
    }

    return defaultValue;
}

const getArrayValue = (key) => {
    const settings = window.localStorage.getItem(StorageKey.SETTINGS) ? JSON.parse(window.localStorage.getItem(StorageKey.SETTINGS)) : [];
    const arraySetting = settings.filter(setting => setting.key === key)[0];

    if (arraySetting && arraySetting.value) {
        if (arraySetting.value.indexOf('|') >= 0) {
            return arraySetting.value.split('|');
        }
        else {
            return [arraySetting.value];
        }
    }

    return [];
}