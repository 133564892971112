import React from 'react';
import uuid from 'react-uuid';
import moment from 'moment';

import * as PageSettings from '../constants/pageSettings';
import * as pph from '../utilities/prescriptionHelper';

export const PRINT_TEMPLATE_PAGE_SETTINGS_PAPER_LETTER_WIDTH = PageSettings.PAPER_LETTER_WIDTH;
export const PRINT_TEMPLATE_PAGE_SETTINGS_PAPER_LETTER_HEIGHT = PageSettings.PAPER_LETTER_HEIGHT;
export const PRINT_TEMPLATE_PAGE_SETTINGS_PAPER_A4_WIDTH = PageSettings.PAPER_A4_WIDTH;
export const PRINT_TEMPLATE_PAGE_SETTINGS_PAPER_A4_HEIGHT = PageSettings.PAPER_A4_WIDTH;
export const PRINT_TEMPLATE_PAGE_SETTINGS_PAPER_LEGAL_WIDTH = PageSettings.PAPER_LEGAL_WIDTH;
export const PRINT_TEMPLATE_PAGE_SETTINGS_PAPER_LEGAL_HEIGHT = PageSettings.PAPER_LEGAL_WIDTH;
export const PRINT_TEMPLATE_PAGE_SETTINGS_MARGINS_NARROW = PageSettings.MARGINS_NARROW;
export const PRINT_TEMPLATE_PAGE_SETTINGS_MARGINS_NORMAL = PageSettings.MARGINS_NORMAL;
export const PRINT_TEMPLATE_PAGE_SETTINGS_MARGINS_WIDE = PageSettings.MARGINS_WIDE;
export const PRINT_TEMPLATE_PAGE_SETTINGS_HEADER_FOOTER_HEIGHT_MIN = PageSettings.HEADER_FOOTER_HEIGHT_MIN;
export const PRINT_TEMPLATE_PAGE_SETTINGS_HEADER_FOOTER_HEIGHT_DEFAULT = PageSettings.HEADER_FOOTER_HEIGHT_DEFAULT;
export const PRINT_TEMPLATE_PAGE_SETTINGS_HEADER_FOOTER_HEIGHT_MAX = PageSettings.HEADER_FOOTER_HEIGHT_MAX;

export const PRINT_TEMPLATE_CELL_WIDTH = 12.75;
export const PRINT_TEMPLATE_ROW_HEIGHT = 5;

export const PRINT_ELEMENT_RICHTEXT_EDITOR = 'Richtext';
export const PRINT_ELEMENT_IMAGE = 'Image';

export const PRINT_ELEMENT_BUSINESS_NAME = 'BusinessName';
export const PRINT_ELEMENT_BUSINESS_FULL_ADDRESS = 'BusinessFullAddress';
export const PRINT_ELEMENT_BUSINESS_ADDRESS_LINE1 = 'BusinessAddressLine1';
export const PRINT_ELEMENT_BUSINESS_ADDRESS_LINE2 = 'BusinessAddressLine2';
export const PRINT_ELEMENT_BUSINESS_ADDRESS_COUNTRY = 'BusinessAddressCountry';
export const PRINT_ELEMENT_BUSINESS_PHONE = 'BusinessPhone';
export const PRINT_ELEMENT_BUSINESS_FAX = 'BusinessFax';
export const PRINT_ELEMENT_BUSINESS_EMAIL = 'BusinessEmail';

export const PRINT_ELEMENT_CUSTOMER_NAME = 'CustomerName';
export const PRINT_ELEMENT_CUSTOMER_FULL_ADDRESS = 'CustomerFullAddress';
export const PRINT_ELEMENT_CUSTOMER_ADDRESS_LINE1 = 'CustomerAddressLine1';
export const PRINT_ELEMENT_CUSTOMER_ADDRESS_LINE2 = 'CustomerAddressLine2';
export const PRINT_ELEMENT_CUSTOMER_ADDRESS_COUNTRY = 'CustomerAddressCountry';
export const PRINT_ELEMENT_CUSTOMER_PHONE = 'CustomerPhone';
export const PRINT_ELEMENT_CUSTOMER_EMAIL = 'CustomerEmail';
export const PRINT_ELEMENT_CUSTOMER_NEXT_APPOINTMENT = 'CustomerNextAppointment';

export const PRINT_ELEMENT_PRESCRIPTION_DOCTOR_NAME = 'PrescriptionDoctorName';
export const PRINT_ELEMENT_PRESCRIPTION_DOCTOR_LICENSE = 'PrescriptionDoctorLicense';
export const PRINT_ELEMENT_PRESCRIPTION_DOCTOR_SIGNATURE = 'PrescriptionDoctorSignature';
export const PRINT_ELEMENT_PRESCRIPTION_WRITTEN_DATE = 'PrescriptionWrittenDate';
export const PRINT_ELEMENT_PRESCRIPTION_EXPIRY_DATE = 'PrescriptionExpiryDate';
export const PRINT_ELEMENT_PRESCRIPTION_FIELDS = 'PrescriptionFields';

export const PRINT_ELEMENT_INVOICE_NUMBER = 'InvoiceNumber';
export const PRINT_ELEMENT_INVOICE_DATE = 'InvoiceDate';
export const PRINT_ELEMENT_INVOICE_DUE_DATE = 'InvoiceDueDate';
export const PRINT_ELEMENT_INVOICE_LICENSED_USER = 'InvoiceLicensedUser';
export const PRINT_ELEMENT_INVOICE_LICENSED_USER_NUMBER = 'InvoiceLicensedUserNumber';
export const PRINT_ELEMENT_INVOICE_SUB_TOTAL = 'InvoiceSubTotal';
export const PRINT_ELEMENT_INVOICE_TAX_RATE = 'InvoiceTaxRate';
export const PRINT_ELEMENT_INVOICE_TAX_AMOUNT = 'InvoiceTaxAmount';
export const PRINT_ELEMENT_INVOICE_DISCOUNT_RATE = 'InvoiceDiscountRate';
export const PRINT_ELEMENT_INVOICE_DISCOUNT_AMOUNT = 'InvoiceDiscountAmount';
export const PRINT_ELEMENT_INVOICE_TOTAL = 'InvoiceTotal';
export const PRINT_ELEMENT_INVOICE_AMOUNT_PAID = 'InvoiceAmountPaid';
export const PRINT_ELEMENT_INVOICE_TOTAL_DUE = 'InvoiceTotalDue';
export const PRINT_ELEMENT_INVOICE_NOTE = 'InvoiceNote';

export const PRINT_ELEMENT_INVOICE_ITEM_DESCRIPTION = 'InvoiceItemDescription';
export const PRINT_ELEMENT_INVOICE_ITEM_UNIT_PRICE = 'InvoiceItemUnitPrice';
export const PRINT_ELEMENT_INVOICE_ITEM_QUANTITY = 'InvoiceItemQuantity';
export const PRINT_ELEMENT_INVOICE_ITEM_DISCOUNT_RATE = 'InvoiceItemDiscountRate';
export const PRINT_ELEMENT_INVOICE_ITEM_DISCOUNT_AMOUNT = 'InvoiceItemDiscountAmount';
export const PRINT_ELEMENT_INVOICE_ITEM_TAX_RATE = 'InvoiceItemTaxRate';
export const PRINT_ELEMENT_INVOICE_ITEM_TAX_AMOUNT = 'InvoiceItemTaxAmount';
export const PRINT_ELEMENT_INVOICE_ITEM_TOTAL = 'InvoiceItemTotal';

export const PRINT_ELEMENT_PAYMENT_NUMBER = 'PaymentNumber';
export const PRINT_ELEMENT_PAYMENT_DATE = 'PaymentDate';
export const PRINT_ELEMENT_PAYMENT_TOTAL = 'PaymentTotal';
export const PRINT_ELEMENT_PAYMENT_PREVIOUS_TOTAL = 'PaymentPreviousTotal';

export const PRINT_ELEMENT_PAYMENT_ITEM_DESCRIPTION = 'PaymentItemDescription';
export const PRINT_ELEMENT_PAYMENT_ITEM_DATE = 'PaymentItemDate';
export const PRINT_ELEMENT_PAYMENT_ITEM_METHOD = 'PaymentItemMethod';
export const PRINT_ELEMENT_PAYMENT_ITEM_AMOUNT = 'PaymentItemAmount';

export const PRINT_ELEMENT_FAX_COVER_PAGE_SENDER = 'FaxCoverPageSender';
export const PRINT_ELEMENT_FAX_COVER_PAGE_RECIPIENT = 'FaxCoverPageRecipient';
export const PRINT_ELEMENT_FAX_COVER_PAGE_FAX_NUMBER = 'FaxCoverPageFaxNumber';
export const PRINT_ELEMENT_FAX_COVER_PAGE_SUBJECT = 'FaxCoverPageSubject';
export const PRINT_ELEMENT_FAX_COVER_PAGE_MESSAGE = 'FaxCoverPageMessage';
export const PRINT_ELEMENT_FAX_COVER_PAGE_NUMBER_OF_PAGES = 'FaxCoverPageNumberOfPages';

export const PRINT_ELEMENT_PRINT_DATE = 'PrintDate';
export const PRINT_ELEMENT_PAGE_NUMBER = 'PageNumber';

export const PRINT_TEMPLATE_METADATA_FIELDS = 'PRINT_TEMPLATE_METADATA_FIELDS';
export const PRINT_TEMPLATE_METADATA_ALIGN = 'PRINT_TEMPLATE_METADATA_ALIGN';
export const PRINT_TEMPLATE_METADATA_VALIGN = 'PRINT_TEMPLATE_METADATA_VALIGN';
export const PRINT_TEMPLATE_METADATA_PADDING = 'PRINT_TEMPLATE_METADATA_PADDING';
export const PRINT_TEMPLATE_METADATA_FONT_COLOR = 'PRINT_TEMPLATE_METADATA_FONT_COLOR';
export const PRINT_TEMPLATE_METADATA_FONT_SIZE = 'PRINT_TEMPLATE_METADATA_FONT_SIZE';
export const PRINT_TEMPLATE_METADATA_FONT_FAMILY = 'PRINT_TEMPLATE_METADATA_FONT_FAMILY';
export const PRINT_TEMPLATE_METADATA_FONT_STYLE = 'PRINT_TEMPLATE_METADATA_FONT_STYLE';
export const PRINT_TEMPLATE_METADATA_FONT_LETTER_SPACING = 'PRINT_TEMPLATE_METADATA_FONT_LETTER_SPACING';
export const PRINT_TEMPLATE_METADATA_BACKGROUND_COLOR = 'PRINT_TEMPLATE_METADATA_BACKGROUND_COLOR';
export const PRINT_TEMPLATE_METADATA_BORDER = 'PRINT_TEMPLATE_METADATA_BORDER';
export const PRINT_TEMPLATE_METADATA_BORDER_COLOR = 'PRINT_TEMPLATE_METADATA_BORDER_COLOR';
export const PRINT_TEMPLATE_METADATA_ALTERNATE_BACKGROUND_COLOR = 'PRINT_TEMPLATE_METADATA_BACKGROUND_COLOR';
export const PRINT_TEMPLATE_METADATA_CONTENT = 'PRINT_TEMPLATE_METADATA_CONTENT';
export const PRINT_TEMPLATE_METADATA_IMAGE = 'PRINT_TEMPLATE_METADATA_IMAGE';
export const PRINT_TEMPLATE_METADATA_FORMAT = 'PRINT_TEMPLATE_METADATA_FORMAT';
export const PRINT_TEMPLATE_METADATA_DISPLAY = 'PRINT_TEMPLATE_METADATA_DISPLAY';
export const PRINT_TEMPLATE_METADATA_HIDE_READONLY = 'PRINT_TEMPLATE_METADATA_HIDE_READONLY';
export const PRINT_TEMPLATE_METADATA_SIGNATURE = 'PRINT_TEMPLATE_METADATA_SIGNATURE';

export const PrintElementTypes = [
    PRINT_ELEMENT_RICHTEXT_EDITOR,
    PRINT_ELEMENT_IMAGE,
    PRINT_ELEMENT_BUSINESS_NAME,
    PRINT_ELEMENT_BUSINESS_FULL_ADDRESS,
    PRINT_ELEMENT_BUSINESS_ADDRESS_LINE1,
    PRINT_ELEMENT_BUSINESS_ADDRESS_LINE2,
    PRINT_ELEMENT_BUSINESS_ADDRESS_COUNTRY,
    PRINT_ELEMENT_BUSINESS_PHONE,
    PRINT_ELEMENT_BUSINESS_FAX,
    PRINT_ELEMENT_BUSINESS_EMAIL,
    PRINT_ELEMENT_CUSTOMER_NAME,
    PRINT_ELEMENT_CUSTOMER_FULL_ADDRESS,
    PRINT_ELEMENT_CUSTOMER_ADDRESS_LINE1,
    PRINT_ELEMENT_CUSTOMER_ADDRESS_LINE2,
    PRINT_ELEMENT_CUSTOMER_ADDRESS_COUNTRY,
    PRINT_ELEMENT_CUSTOMER_PHONE,
    PRINT_ELEMENT_CUSTOMER_EMAIL,
    PRINT_ELEMENT_CUSTOMER_NEXT_APPOINTMENT,
    PRINT_ELEMENT_PRESCRIPTION_DOCTOR_NAME,
    PRINT_ELEMENT_PRESCRIPTION_DOCTOR_LICENSE,
    PRINT_ELEMENT_PRESCRIPTION_DOCTOR_SIGNATURE,
    PRINT_ELEMENT_PRESCRIPTION_WRITTEN_DATE,
    PRINT_ELEMENT_PRESCRIPTION_EXPIRY_DATE,
    PRINT_ELEMENT_PRESCRIPTION_FIELDS,
    PRINT_ELEMENT_INVOICE_NUMBER,
    PRINT_ELEMENT_INVOICE_DATE,
    PRINT_ELEMENT_INVOICE_DUE_DATE,
    PRINT_ELEMENT_INVOICE_LICENSED_USER,
    PRINT_ELEMENT_INVOICE_LICENSED_USER_NUMBER,
    PRINT_ELEMENT_INVOICE_ITEM_DESCRIPTION,
    PRINT_ELEMENT_INVOICE_ITEM_UNIT_PRICE,
    PRINT_ELEMENT_INVOICE_ITEM_QUANTITY,
    PRINT_ELEMENT_INVOICE_ITEM_DISCOUNT_RATE,
    PRINT_ELEMENT_INVOICE_ITEM_DISCOUNT_AMOUNT,
    PRINT_ELEMENT_INVOICE_ITEM_TAX_RATE,
    PRINT_ELEMENT_INVOICE_ITEM_TAX_AMOUNT,
    PRINT_ELEMENT_INVOICE_ITEM_TOTAL,
    PRINT_ELEMENT_INVOICE_SUB_TOTAL,
    PRINT_ELEMENT_INVOICE_TAX_RATE,
    PRINT_ELEMENT_INVOICE_TAX_AMOUNT,
    PRINT_ELEMENT_INVOICE_DISCOUNT_RATE,
    PRINT_ELEMENT_INVOICE_DISCOUNT_AMOUNT,
    PRINT_ELEMENT_INVOICE_TOTAL,
    PRINT_ELEMENT_INVOICE_AMOUNT_PAID,
    PRINT_ELEMENT_INVOICE_TOTAL_DUE,
    PRINT_ELEMENT_INVOICE_NOTE,
    PRINT_ELEMENT_PAYMENT_NUMBER,
    PRINT_ELEMENT_PAYMENT_DATE,
    PRINT_ELEMENT_PAYMENT_TOTAL,
    PRINT_ELEMENT_PAYMENT_PREVIOUS_TOTAL,
    PRINT_ELEMENT_PAYMENT_ITEM_DATE,
    PRINT_ELEMENT_PAYMENT_ITEM_DESCRIPTION,
    PRINT_ELEMENT_PAYMENT_ITEM_METHOD,
    PRINT_ELEMENT_PAYMENT_ITEM_AMOUNT,
    PRINT_ELEMENT_FAX_COVER_PAGE_SENDER,
    PRINT_ELEMENT_FAX_COVER_PAGE_RECIPIENT,
    PRINT_ELEMENT_FAX_COVER_PAGE_FAX_NUMBER,
    PRINT_ELEMENT_FAX_COVER_PAGE_SUBJECT,
    PRINT_ELEMENT_FAX_COVER_PAGE_MESSAGE,
    PRINT_ELEMENT_FAX_COVER_PAGE_NUMBER_OF_PAGES,
    PRINT_ELEMENT_PRINT_DATE,
    PRINT_ELEMENT_PAGE_NUMBER,
];

export const createElement = (type) => {
    return {
        id: uuid(),
        type: type,
        metadata: {},
        position: getDefaultPosition(type),
    };
}

export const isPrintElement = ({ type }) => {
    return PrintElementTypes.some(t => t === type);
}

export const hasField = (type, field) => {
    switch (type) {
        case PRINT_ELEMENT_RICHTEXT_EDITOR:
            return [
                PRINT_TEMPLATE_METADATA_ALIGN,
                PRINT_TEMPLATE_METADATA_VALIGN,
                PRINT_TEMPLATE_METADATA_PADDING,
                PRINT_TEMPLATE_METADATA_FONT_COLOR,
                PRINT_TEMPLATE_METADATA_FONT_SIZE,
                PRINT_TEMPLATE_METADATA_FONT_FAMILY,
                PRINT_TEMPLATE_METADATA_FONT_LETTER_SPACING,
                PRINT_TEMPLATE_METADATA_BACKGROUND_COLOR,
                PRINT_TEMPLATE_METADATA_BORDER,
                PRINT_TEMPLATE_METADATA_BORDER_COLOR,
                PRINT_TEMPLATE_METADATA_CONTENT,
                PRINT_TEMPLATE_METADATA_DISPLAY,
                PRINT_TEMPLATE_METADATA_HIDE_READONLY,
            ].some(f => f === field);

        case PRINT_ELEMENT_IMAGE:
            return [
                PRINT_TEMPLATE_METADATA_ALIGN,
                PRINT_TEMPLATE_METADATA_VALIGN,
                PRINT_TEMPLATE_METADATA_IMAGE,
                PRINT_TEMPLATE_METADATA_DISPLAY,
                PRINT_TEMPLATE_METADATA_HIDE_READONLY,
            ].some(f => f === field);

        case PRINT_ELEMENT_BUSINESS_NAME:
        case PRINT_ELEMENT_BUSINESS_FULL_ADDRESS:
        case PRINT_ELEMENT_BUSINESS_ADDRESS_LINE1:
        case PRINT_ELEMENT_BUSINESS_ADDRESS_LINE2:
        case PRINT_ELEMENT_BUSINESS_ADDRESS_COUNTRY:
        case PRINT_ELEMENT_CUSTOMER_NAME:
        case PRINT_ELEMENT_BUSINESS_EMAIL:
        case PRINT_ELEMENT_CUSTOMER_FULL_ADDRESS:
        case PRINT_ELEMENT_CUSTOMER_ADDRESS_LINE1:
        case PRINT_ELEMENT_CUSTOMER_ADDRESS_LINE2:
        case PRINT_ELEMENT_CUSTOMER_ADDRESS_COUNTRY:
        case PRINT_ELEMENT_CUSTOMER_PHONE:
        case PRINT_ELEMENT_CUSTOMER_EMAIL:
        case PRINT_ELEMENT_CUSTOMER_NEXT_APPOINTMENT:
        case PRINT_ELEMENT_PRESCRIPTION_DOCTOR_NAME:
        case PRINT_ELEMENT_PRESCRIPTION_DOCTOR_LICENSE:
        case PRINT_ELEMENT_INVOICE_LICENSED_USER:
        case PRINT_ELEMENT_INVOICE_LICENSED_USER_NUMBER:
        case PRINT_ELEMENT_INVOICE_NUMBER:
        case PRINT_ELEMENT_INVOICE_SUB_TOTAL:
        case PRINT_ELEMENT_INVOICE_TAX_AMOUNT:
        case PRINT_ELEMENT_INVOICE_DISCOUNT_AMOUNT:
        case PRINT_ELEMENT_INVOICE_TOTAL:
        case PRINT_ELEMENT_INVOICE_AMOUNT_PAID:
        case PRINT_ELEMENT_INVOICE_TOTAL_DUE:
        case PRINT_ELEMENT_INVOICE_NOTE:
        case PRINT_ELEMENT_PAYMENT_NUMBER:
        case PRINT_ELEMENT_PAYMENT_TOTAL:
        case PRINT_ELEMENT_PAYMENT_PREVIOUS_TOTAL:
        case PRINT_ELEMENT_FAX_COVER_PAGE_SENDER:
        case PRINT_ELEMENT_FAX_COVER_PAGE_RECIPIENT:
        case PRINT_ELEMENT_FAX_COVER_PAGE_SUBJECT:
        case PRINT_ELEMENT_FAX_COVER_PAGE_MESSAGE:
        case PRINT_ELEMENT_FAX_COVER_PAGE_NUMBER_OF_PAGES:
            return [
                PRINT_TEMPLATE_METADATA_ALIGN,
                PRINT_TEMPLATE_METADATA_VALIGN,
                PRINT_TEMPLATE_METADATA_PADDING,
                PRINT_TEMPLATE_METADATA_FONT_COLOR,
                PRINT_TEMPLATE_METADATA_FONT_SIZE,
                PRINT_TEMPLATE_METADATA_FONT_FAMILY,
                PRINT_TEMPLATE_METADATA_FONT_STYLE,
                PRINT_TEMPLATE_METADATA_FONT_LETTER_SPACING,
                PRINT_TEMPLATE_METADATA_BACKGROUND_COLOR,
                PRINT_TEMPLATE_METADATA_BORDER,
                PRINT_TEMPLATE_METADATA_BORDER_COLOR,
                PRINT_TEMPLATE_METADATA_DISPLAY,
                PRINT_TEMPLATE_METADATA_HIDE_READONLY,
            ].some(f => f === field);

        case PRINT_ELEMENT_PRESCRIPTION_FIELDS:
            return [
                PRINT_TEMPLATE_METADATA_FIELDS,
                PRINT_TEMPLATE_METADATA_ALIGN,
                PRINT_TEMPLATE_METADATA_VALIGN,
                PRINT_TEMPLATE_METADATA_PADDING,
                PRINT_TEMPLATE_METADATA_FONT_COLOR,
                PRINT_TEMPLATE_METADATA_FONT_SIZE,
                PRINT_TEMPLATE_METADATA_FONT_FAMILY,
                PRINT_TEMPLATE_METADATA_FONT_STYLE,
                PRINT_TEMPLATE_METADATA_FONT_LETTER_SPACING,
                PRINT_TEMPLATE_METADATA_BACKGROUND_COLOR,
                PRINT_TEMPLATE_METADATA_BORDER,
                PRINT_TEMPLATE_METADATA_BORDER_COLOR,
                PRINT_TEMPLATE_METADATA_FORMAT,
                PRINT_TEMPLATE_METADATA_DISPLAY,
                PRINT_TEMPLATE_METADATA_HIDE_READONLY,
            ].some(f => f === field);

        case PRINT_ELEMENT_BUSINESS_PHONE:
        case PRINT_ELEMENT_BUSINESS_FAX:
        case PRINT_ELEMENT_PRESCRIPTION_WRITTEN_DATE:
        case PRINT_ELEMENT_PRESCRIPTION_EXPIRY_DATE:
        case PRINT_ELEMENT_INVOICE_DATE:
        case PRINT_ELEMENT_INVOICE_DUE_DATE:
        case PRINT_ELEMENT_INVOICE_TAX_RATE:
        case PRINT_ELEMENT_INVOICE_DISCOUNT_RATE:
        case PRINT_ELEMENT_PAYMENT_DATE:
        case PRINT_ELEMENT_PAYMENT_ITEM_DATE:
        case PRINT_ELEMENT_FAX_COVER_PAGE_FAX_NUMBER:
        case PRINT_ELEMENT_PRINT_DATE:
        case PRINT_ELEMENT_PAGE_NUMBER:
            return [
                PRINT_TEMPLATE_METADATA_ALIGN,
                PRINT_TEMPLATE_METADATA_VALIGN,
                PRINT_TEMPLATE_METADATA_PADDING,
                PRINT_TEMPLATE_METADATA_FONT_COLOR,
                PRINT_TEMPLATE_METADATA_FONT_SIZE,
                PRINT_TEMPLATE_METADATA_FONT_FAMILY,
                PRINT_TEMPLATE_METADATA_FONT_STYLE,
                PRINT_TEMPLATE_METADATA_FONT_LETTER_SPACING,
                PRINT_TEMPLATE_METADATA_BACKGROUND_COLOR,
                PRINT_TEMPLATE_METADATA_BORDER,
                PRINT_TEMPLATE_METADATA_BORDER_COLOR,
                PRINT_TEMPLATE_METADATA_FORMAT,
                PRINT_TEMPLATE_METADATA_DISPLAY,
                PRINT_TEMPLATE_METADATA_HIDE_READONLY,
            ].some(f => f === field);

        case PRINT_ELEMENT_INVOICE_ITEM_DESCRIPTION:
        case PRINT_ELEMENT_INVOICE_ITEM_UNIT_PRICE:
        case PRINT_ELEMENT_INVOICE_ITEM_DISCOUNT_AMOUNT:
        case PRINT_ELEMENT_INVOICE_ITEM_TAX_AMOUNT:
        case PRINT_ELEMENT_INVOICE_ITEM_TOTAL:
        case PRINT_ELEMENT_PAYMENT_ITEM_DESCRIPTION:
        case PRINT_ELEMENT_PAYMENT_ITEM_METHOD:
        case PRINT_ELEMENT_PAYMENT_ITEM_AMOUNT:
            return [
                PRINT_TEMPLATE_METADATA_ALIGN,
                PRINT_TEMPLATE_METADATA_VALIGN,
                PRINT_TEMPLATE_METADATA_PADDING,
                PRINT_TEMPLATE_METADATA_FONT_COLOR,
                PRINT_TEMPLATE_METADATA_FONT_SIZE,
                PRINT_TEMPLATE_METADATA_FONT_FAMILY,
                PRINT_TEMPLATE_METADATA_FONT_STYLE,
                PRINT_TEMPLATE_METADATA_FONT_LETTER_SPACING,
                PRINT_TEMPLATE_METADATA_BACKGROUND_COLOR,
                PRINT_TEMPLATE_METADATA_BORDER,
                PRINT_TEMPLATE_METADATA_BORDER_COLOR,
                PRINT_TEMPLATE_METADATA_ALTERNATE_BACKGROUND_COLOR,
                PRINT_TEMPLATE_METADATA_DISPLAY,
                PRINT_TEMPLATE_METADATA_HIDE_READONLY,
            ].some(f => f === field);

        case PRINT_ELEMENT_INVOICE_ITEM_QUANTITY:
        case PRINT_ELEMENT_INVOICE_ITEM_DISCOUNT_RATE:
        case PRINT_ELEMENT_INVOICE_ITEM_TAX_RATE:
            return [
                PRINT_TEMPLATE_METADATA_ALIGN,
                PRINT_TEMPLATE_METADATA_VALIGN,
                PRINT_TEMPLATE_METADATA_PADDING,
                PRINT_TEMPLATE_METADATA_FONT_COLOR,
                PRINT_TEMPLATE_METADATA_FONT_SIZE,
                PRINT_TEMPLATE_METADATA_FONT_FAMILY,
                PRINT_TEMPLATE_METADATA_FONT_STYLE,
                PRINT_TEMPLATE_METADATA_FONT_LETTER_SPACING,
                PRINT_TEMPLATE_METADATA_BACKGROUND_COLOR,
                PRINT_TEMPLATE_METADATA_BORDER,
                PRINT_TEMPLATE_METADATA_BORDER_COLOR,
                PRINT_TEMPLATE_METADATA_ALTERNATE_BACKGROUND_COLOR,
                PRINT_TEMPLATE_METADATA_FORMAT,
                PRINT_TEMPLATE_METADATA_DISPLAY,
                PRINT_TEMPLATE_METADATA_HIDE_READONLY,
            ].some(f => f === field);

        case PRINT_ELEMENT_PRESCRIPTION_DOCTOR_SIGNATURE:
            return [
                PRINT_TEMPLATE_METADATA_SIGNATURE,
            ].some(f => f === field);

        default:
            return false;
    }
}

export const getName = (type) => {
    switch (type) {
        case PRINT_ELEMENT_RICHTEXT_EDITOR:
            return 'Richtext';

        case PRINT_ELEMENT_IMAGE:
            return 'Image';

        case PRINT_ELEMENT_BUSINESS_NAME:
            return 'BusinessName';

        case PRINT_ELEMENT_BUSINESS_FULL_ADDRESS:
            return 'BusinessFullAddress';

        case PRINT_ELEMENT_BUSINESS_ADDRESS_LINE1:
            return 'BusinessAddressLine1';

        case PRINT_ELEMENT_BUSINESS_ADDRESS_LINE2:
            return 'BusinessAddressLine2';

        case PRINT_ELEMENT_BUSINESS_ADDRESS_COUNTRY:
            return 'BusinessAddressCountry';

        case PRINT_ELEMENT_BUSINESS_PHONE:
            return 'BusinessPhone';

        case PRINT_ELEMENT_BUSINESS_FAX:
            return 'BusinessFax';

        case PRINT_ELEMENT_BUSINESS_EMAIL:
            return 'BusinessEmail';

        case PRINT_ELEMENT_CUSTOMER_NAME:
            return 'CustomerName';

        case PRINT_ELEMENT_CUSTOMER_FULL_ADDRESS:
            return 'CustomerFullAddress';

        case PRINT_ELEMENT_CUSTOMER_ADDRESS_LINE1:
            return 'CustomerAddressLine1';

        case PRINT_ELEMENT_CUSTOMER_ADDRESS_LINE2:
            return 'CustomerAddressLine2';

        case PRINT_ELEMENT_CUSTOMER_ADDRESS_COUNTRY:
            return 'CustomerAddressCountry';

        case PRINT_ELEMENT_CUSTOMER_PHONE:
            return 'CustomerPhone';

        case PRINT_ELEMENT_CUSTOMER_EMAIL:
            return 'CustomerEmail';

        case PRINT_ELEMENT_CUSTOMER_NEXT_APPOINTMENT:
            return 'CustomerNextAppointment';

        case PRINT_ELEMENT_PRESCRIPTION_DOCTOR_NAME:
            return 'PrescriptionDoctorName';

        case PRINT_ELEMENT_PRESCRIPTION_DOCTOR_LICENSE:
            return 'PrescriptionDoctorLicense';

        case PRINT_ELEMENT_PRESCRIPTION_DOCTOR_SIGNATURE:
            return 'PrescriptionDoctorSignature';

        case PRINT_ELEMENT_PRESCRIPTION_WRITTEN_DATE:
            return 'PrescriptionWrittenDate';

        case PRINT_ELEMENT_PRESCRIPTION_EXPIRY_DATE:
            return 'PrescriptionExpiryDate';

        case PRINT_ELEMENT_PRESCRIPTION_FIELDS:
            return 'PrescriptionFields';

        case PRINT_ELEMENT_INVOICE_NUMBER:
            return 'InvoiceNumber';

        case PRINT_ELEMENT_INVOICE_LICENSED_USER:
            return 'InvoiceLicensedUser';

        case PRINT_ELEMENT_INVOICE_LICENSED_USER_NUMBER:
            return 'InvoiceLicensedUserNumber';

        case PRINT_ELEMENT_INVOICE_DUE_DATE:
            return 'InvoiceDueDate';

        case PRINT_ELEMENT_INVOICE_DATE:
            return 'InvoiceDate';

        case PRINT_ELEMENT_INVOICE_SUB_TOTAL:
            return 'InvoiceSubTotal';

        case PRINT_ELEMENT_INVOICE_TAX_RATE:
            return 'InvoiceTaxRate';

        case PRINT_ELEMENT_INVOICE_TAX_AMOUNT:
            return 'InvoiceTaxAmount';

        case PRINT_ELEMENT_INVOICE_DISCOUNT_RATE:
            return 'InvoiceDiscountRate';

        case PRINT_ELEMENT_INVOICE_DISCOUNT_AMOUNT:
            return 'InvoiceDiscountAmount';

        case PRINT_ELEMENT_INVOICE_TOTAL:
            return 'InvoiceTotal';

        case PRINT_ELEMENT_INVOICE_AMOUNT_PAID:
            return 'InvoiceAmountPaid';

        case PRINT_ELEMENT_INVOICE_TOTAL_DUE:
            return 'InvoiceTotalDue';

        case PRINT_ELEMENT_INVOICE_NOTE:
            return 'InvoiceNote';

        case PRINT_ELEMENT_INVOICE_ITEM_DESCRIPTION:
            return 'InvoiceItemDescription';

        case PRINT_ELEMENT_INVOICE_ITEM_UNIT_PRICE:
            return 'InvoiceItemUnitPrice';

        case PRINT_ELEMENT_INVOICE_ITEM_QUANTITY:
            return 'InvoiceItemQuantity';

        case PRINT_ELEMENT_INVOICE_ITEM_DISCOUNT_RATE:
            return 'InvoiceItemDiscountRate';

        case PRINT_ELEMENT_INVOICE_ITEM_DISCOUNT_AMOUNT:
            return 'InvoiceItemDiscountAmount';

        case PRINT_ELEMENT_INVOICE_ITEM_TAX_RATE:
            return 'InvoiceItemTaxRate';

        case PRINT_ELEMENT_INVOICE_ITEM_TAX_AMOUNT:
            return 'InvoiceItemTaxAmount';

        case PRINT_ELEMENT_INVOICE_ITEM_TOTAL:
            return 'InvoiceItemTotal';

        case PRINT_ELEMENT_PAYMENT_NUMBER:
            return 'PaymentNumber';

        case PRINT_ELEMENT_PAYMENT_DATE:
            return 'PaymentDate';

        case PRINT_ELEMENT_PAYMENT_TOTAL:
            return 'PaymentTotal';

        case PRINT_ELEMENT_PAYMENT_PREVIOUS_TOTAL:
            return 'PaymentPreviousTotal';

        case PRINT_ELEMENT_PAYMENT_ITEM_DATE:
            return 'PaymentItemDate';

        case PRINT_ELEMENT_PAYMENT_ITEM_DESCRIPTION:
            return 'PaymentItemDescription';

        case PRINT_ELEMENT_PAYMENT_ITEM_METHOD:
            return 'PaymentItemMethod';

        case PRINT_ELEMENT_PAYMENT_ITEM_AMOUNT:
            return 'PaymentItemAmount';

        case PRINT_ELEMENT_FAX_COVER_PAGE_SENDER:
            return 'FaxCoverPageSender';

        case PRINT_ELEMENT_FAX_COVER_PAGE_RECIPIENT:
            return 'FaxCoverPageRecipient';

        case PRINT_ELEMENT_FAX_COVER_PAGE_FAX_NUMBER:
            return 'FaxCoverPageFaxNumber';

        case PRINT_ELEMENT_FAX_COVER_PAGE_SUBJECT:
            return 'FaxCoverPageSubject';

        case PRINT_ELEMENT_FAX_COVER_PAGE_MESSAGE:
            return 'FaxCoverPageMessage';

        case PRINT_ELEMENT_FAX_COVER_PAGE_NUMBER_OF_PAGES:
            return 'FaxCoverPageNumberOfPages';

        case PRINT_ELEMENT_PRINT_DATE:
            return 'PrintDate';

        case PRINT_ELEMENT_PAGE_NUMBER:
            return 'PageNumber';

        default:
            return '';
    }
}

export const getDescription = (type) => {
    switch (type) {
        case PRINT_ELEMENT_RICHTEXT_EDITOR:
            return 'Richtext';

        case PRINT_ELEMENT_IMAGE:
            return 'Image';

        case PRINT_ELEMENT_BUSINESS_NAME:
            return 'Business Name';

        case PRINT_ELEMENT_BUSINESS_FULL_ADDRESS:
            return 'Business Full Address';

        case PRINT_ELEMENT_BUSINESS_ADDRESS_LINE1:
            return 'Business Address Line 1';

        case PRINT_ELEMENT_BUSINESS_ADDRESS_LINE2:
            return 'Business Address Line 2';

        case PRINT_ELEMENT_BUSINESS_ADDRESS_COUNTRY:
            return 'Business Address Country';

        case PRINT_ELEMENT_BUSINESS_PHONE:
            return 'Business Phone';

        case PRINT_ELEMENT_BUSINESS_FAX:
            return 'Business Fax';

        case PRINT_ELEMENT_BUSINESS_EMAIL:
            return 'Business Email';

        case PRINT_ELEMENT_CUSTOMER_NAME:
            return 'Customer Name';

        case PRINT_ELEMENT_CUSTOMER_FULL_ADDRESS:
            return 'Customer Full Address';

        case PRINT_ELEMENT_CUSTOMER_ADDRESS_LINE1:
            return 'Customer Address Line 1';

        case PRINT_ELEMENT_CUSTOMER_ADDRESS_LINE2:
            return 'Customer Address Line 2';

        case PRINT_ELEMENT_CUSTOMER_ADDRESS_COUNTRY:
            return 'Customer Address Country';

        case PRINT_ELEMENT_CUSTOMER_PHONE:
            return 'Customer Phone';

        case PRINT_ELEMENT_CUSTOMER_EMAIL:
            return 'Customer Email';

        case PRINT_ELEMENT_CUSTOMER_NEXT_APPOINTMENT:
            return 'Customer Next Appointment';

        case PRINT_ELEMENT_PRESCRIPTION_DOCTOR_NAME:
            return 'Prescription Doctor Name';

        case PRINT_ELEMENT_PRESCRIPTION_DOCTOR_LICENSE:
            return 'Prescription Doctor License';

        case PRINT_ELEMENT_PRESCRIPTION_DOCTOR_SIGNATURE:
            return 'Prescription Doctor Signature';

        case PRINT_ELEMENT_PRESCRIPTION_WRITTEN_DATE:
            return 'Prescription Written Date';

        case PRINT_ELEMENT_PRESCRIPTION_EXPIRY_DATE:
            return 'Prescription Expiry Date';

        case PRINT_ELEMENT_PRESCRIPTION_FIELDS:
            return 'Prescription Fields';

        case PRINT_ELEMENT_INVOICE_LICENSED_USER:
            return 'Invoice Licensed User';

        case PRINT_ELEMENT_INVOICE_LICENSED_USER_NUMBER:
            return 'Invoice Licensed User Number';

        case PRINT_ELEMENT_INVOICE_NUMBER:
            return 'Invoice Number';

        case PRINT_ELEMENT_INVOICE_DUE_DATE:
            return 'Invoice Due Date';

        case PRINT_ELEMENT_INVOICE_DATE:
            return 'Invoice Date';

        case PRINT_ELEMENT_INVOICE_SUB_TOTAL:
            return 'Invoice Sub Total';

        case PRINT_ELEMENT_INVOICE_TAX_RATE:
            return 'Invoice Tax Rate';

        case PRINT_ELEMENT_INVOICE_TAX_AMOUNT:
            return 'Invoice Tax Amount';

        case PRINT_ELEMENT_INVOICE_DISCOUNT_RATE:
            return 'Invoice Discount Rate';

        case PRINT_ELEMENT_INVOICE_DISCOUNT_AMOUNT:
            return 'Invoice Discount Amount';

        case PRINT_ELEMENT_INVOICE_TOTAL:
            return 'Invoice Total';

        case PRINT_ELEMENT_INVOICE_AMOUNT_PAID:
            return 'Invoice Amount Paid';

        case PRINT_ELEMENT_INVOICE_TOTAL_DUE:
            return 'Invoice Total Due';

        case PRINT_ELEMENT_INVOICE_NOTE:
            return 'Invoice Note';

        case PRINT_ELEMENT_INVOICE_ITEM_DESCRIPTION:
            return 'Invoice Item Description';

        case PRINT_ELEMENT_INVOICE_ITEM_UNIT_PRICE:
            return 'Invoice Item Unit Price';

        case PRINT_ELEMENT_INVOICE_ITEM_QUANTITY:
            return 'Invoice Item Quantity';

        case PRINT_ELEMENT_INVOICE_ITEM_DISCOUNT_RATE:
            return 'Invoice Item Discount Rate';

        case PRINT_ELEMENT_INVOICE_ITEM_DISCOUNT_AMOUNT:
            return 'Invoice Item Discount Amount';

        case PRINT_ELEMENT_INVOICE_ITEM_TAX_RATE:
            return 'Invoice Item Tax Rate';

        case PRINT_ELEMENT_INVOICE_ITEM_TAX_AMOUNT:
            return 'Invoice Item Tax Amount';

        case PRINT_ELEMENT_INVOICE_ITEM_TOTAL:
            return 'Invoice Item Total';

        case PRINT_ELEMENT_PAYMENT_NUMBER:
            return 'Payment Number';

        case PRINT_ELEMENT_PAYMENT_DATE:
            return 'Payment Date';

        case PRINT_ELEMENT_PAYMENT_TOTAL:
            return 'Payment Total';

        case PRINT_ELEMENT_PAYMENT_PREVIOUS_TOTAL:
            return 'Payment Previous Total';

        case PRINT_ELEMENT_PAYMENT_ITEM_DATE:
            return 'Payment Item Date';

        case PRINT_ELEMENT_PAYMENT_ITEM_DESCRIPTION:
            return 'Payment Item Description';

        case PRINT_ELEMENT_PAYMENT_ITEM_METHOD:
            return 'Payment Item Method';

        case PRINT_ELEMENT_PAYMENT_ITEM_AMOUNT:
            return 'Payment Item Amount';

        case PRINT_ELEMENT_FAX_COVER_PAGE_SENDER:
            return 'Fax Cover Page Sender';

        case PRINT_ELEMENT_FAX_COVER_PAGE_RECIPIENT:
            return 'Fax Cover Page Recipient';

        case PRINT_ELEMENT_FAX_COVER_PAGE_FAX_NUMBER:
            return 'Fax Cover Page Fax Number';

        case PRINT_ELEMENT_FAX_COVER_PAGE_SUBJECT:
            return 'Fax Cover Page Subject';

        case PRINT_ELEMENT_FAX_COVER_PAGE_MESSAGE:
            return 'Fax Cover Page Message';

        case PRINT_ELEMENT_FAX_COVER_PAGE_NUMBER_OF_PAGES:
            return 'Fax Cover Page Number of Pages';

        case PRINT_ELEMENT_PRINT_DATE:
            return 'Print Date';

        case PRINT_ELEMENT_PAGE_NUMBER:
            return 'Page Number';

        default:
            return '';
    }
}

export const getIcon = (type) => {
    switch (type) {
        case PRINT_ELEMENT_RICHTEXT_EDITOR:
            return 'fal fa-text-size';

        case PRINT_ELEMENT_IMAGE:
            return 'fal fa-image';

        case PRINT_ELEMENT_BUSINESS_NAME:
        case PRINT_ELEMENT_BUSINESS_FULL_ADDRESS:
        case PRINT_ELEMENT_BUSINESS_ADDRESS_LINE1:
        case PRINT_ELEMENT_BUSINESS_ADDRESS_LINE2:
        case PRINT_ELEMENT_BUSINESS_ADDRESS_COUNTRY:
        case PRINT_ELEMENT_BUSINESS_PHONE:
        case PRINT_ELEMENT_BUSINESS_FAX:
        case PRINT_ELEMENT_BUSINESS_EMAIL:
            return 'fal fa-building';

        case PRINT_ELEMENT_CUSTOMER_NAME:
        case PRINT_ELEMENT_CUSTOMER_FULL_ADDRESS:
        case PRINT_ELEMENT_CUSTOMER_ADDRESS_LINE1:
        case PRINT_ELEMENT_CUSTOMER_ADDRESS_LINE2:
        case PRINT_ELEMENT_CUSTOMER_ADDRESS_COUNTRY:
        case PRINT_ELEMENT_CUSTOMER_PHONE:
        case PRINT_ELEMENT_CUSTOMER_EMAIL:
        case PRINT_ELEMENT_CUSTOMER_NEXT_APPOINTMENT:
            return 'fal fa-user';

        case PRINT_ELEMENT_PRESCRIPTION_DOCTOR_NAME:
        case PRINT_ELEMENT_PRESCRIPTION_DOCTOR_LICENSE:
        case PRINT_ELEMENT_PRESCRIPTION_DOCTOR_SIGNATURE:
        case PRINT_ELEMENT_PRESCRIPTION_WRITTEN_DATE:
        case PRINT_ELEMENT_PRESCRIPTION_EXPIRY_DATE:
        case PRINT_ELEMENT_PRESCRIPTION_FIELDS:
            return 'fal fa-file-prescription';

        case PRINT_ELEMENT_INVOICE_NUMBER:
        case PRINT_ELEMENT_INVOICE_DUE_DATE:
        case PRINT_ELEMENT_INVOICE_DATE:
        case PRINT_ELEMENT_INVOICE_LICENSED_USER:
        case PRINT_ELEMENT_INVOICE_LICENSED_USER_NUMBER:
        case PRINT_ELEMENT_INVOICE_SUB_TOTAL:
        case PRINT_ELEMENT_INVOICE_TAX_RATE:
        case PRINT_ELEMENT_INVOICE_TAX_AMOUNT:
        case PRINT_ELEMENT_INVOICE_DISCOUNT_RATE:
        case PRINT_ELEMENT_INVOICE_DISCOUNT_AMOUNT:
        case PRINT_ELEMENT_INVOICE_TOTAL:
        case PRINT_ELEMENT_INVOICE_AMOUNT_PAID:
        case PRINT_ELEMENT_INVOICE_TOTAL_DUE:
        case PRINT_ELEMENT_INVOICE_NOTE:
            return 'fal fa-file-invoice-dollar';

        case PRINT_ELEMENT_INVOICE_ITEM_DESCRIPTION:
        case PRINT_ELEMENT_INVOICE_ITEM_UNIT_PRICE:
        case PRINT_ELEMENT_INVOICE_ITEM_QUANTITY:
        case PRINT_ELEMENT_INVOICE_ITEM_DISCOUNT_RATE:
        case PRINT_ELEMENT_INVOICE_ITEM_DISCOUNT_AMOUNT:
        case PRINT_ELEMENT_INVOICE_ITEM_TAX_RATE:
        case PRINT_ELEMENT_INVOICE_ITEM_TAX_AMOUNT:
        case PRINT_ELEMENT_INVOICE_ITEM_TOTAL:
            return 'fal fa-server';

        case PRINT_ELEMENT_PAYMENT_NUMBER:
        case PRINT_ELEMENT_PAYMENT_DATE:
        case PRINT_ELEMENT_PAYMENT_TOTAL:
        case PRINT_ELEMENT_PAYMENT_PREVIOUS_TOTAL:
        case PRINT_ELEMENT_PAYMENT_ITEM_DATE:
        case PRINT_ELEMENT_PAYMENT_ITEM_DESCRIPTION:
        case PRINT_ELEMENT_PAYMENT_ITEM_METHOD:
        case PRINT_ELEMENT_PAYMENT_ITEM_AMOUNT:
            return 'fal fa-receipt';

        case PRINT_ELEMENT_FAX_COVER_PAGE_SENDER:
        case PRINT_ELEMENT_FAX_COVER_PAGE_RECIPIENT:
        case PRINT_ELEMENT_FAX_COVER_PAGE_FAX_NUMBER:
        case PRINT_ELEMENT_FAX_COVER_PAGE_SUBJECT:
        case PRINT_ELEMENT_FAX_COVER_PAGE_MESSAGE:
        case PRINT_ELEMENT_FAX_COVER_PAGE_NUMBER_OF_PAGES:
            return 'fal fa-fax';

        case PRINT_ELEMENT_PRINT_DATE:
            return 'fal fa-calendar-alt';

        case PRINT_ELEMENT_PAGE_NUMBER:
            return 'fal fa-hashtag'

        default:
            return '';
    }
}

export const getDefaultValue = (type, format) => {
    const formatOption = getFormatOptions(type).filter(f => f.type === format)[0];

    switch (type) {
        case PRINT_ELEMENT_RICHTEXT_EDITOR:
            return 'This is a sample text.';

        case PRINT_ELEMENT_IMAGE:
            return '';

        case PRINT_ELEMENT_BUSINESS_NAME:
            return 'Company Name';

        case PRINT_ELEMENT_BUSINESS_FULL_ADDRESS:
            return '199 Main Street East, Toronto, ON M1N 2N2, Canada';

        case PRINT_ELEMENT_BUSINESS_ADDRESS_LINE1:
            return '199 Main Street East';

        case PRINT_ELEMENT_BUSINESS_ADDRESS_LINE2:
            return 'Toronto, ON M1N 2N2';

        case PRINT_ELEMENT_BUSINESS_ADDRESS_COUNTRY:
            return 'Canada';

        case PRINT_ELEMENT_BUSINESS_PHONE:
            return formatOption ? formatOption.example : '+14165559999';

        case PRINT_ELEMENT_BUSINESS_FAX:
            return formatOption ? formatOption.example : '+14163331111';

        case PRINT_ELEMENT_BUSINESS_EMAIL:
            return 'info@companyname.com';

        case PRINT_ELEMENT_CUSTOMER_NAME:
            return 'Jonathan Williams';

        case PRINT_ELEMENT_CUSTOMER_FULL_ADDRESS:
            return '355-20 Applegate Street, Toronto, ON M2M 3K3, Canada';

        case PRINT_ELEMENT_CUSTOMER_ADDRESS_LINE1:
            return '355-20 Applegate Street';

        case PRINT_ELEMENT_CUSTOMER_ADDRESS_LINE2:
            return 'Toronto, ON M2M 3K3';

        case PRINT_ELEMENT_CUSTOMER_ADDRESS_COUNTRY:
            return 'Canada';

        case PRINT_ELEMENT_CUSTOMER_PHONE:
            return formatOption ? formatOption.example : '+14166643333';

        case PRINT_ELEMENT_CUSTOMER_EMAIL:
            return 'jwilliams_20@customeremail.com';

        case PRINT_ELEMENT_CUSTOMER_NEXT_APPOINTMENT:
            return '--';

        case PRINT_ELEMENT_PRESCRIPTION_DOCTOR_NAME:
            return 'Dr. John Johnstone';

        case PRINT_ELEMENT_PRESCRIPTION_DOCTOR_LICENSE:
            return '0012345';

        case PRINT_ELEMENT_PRESCRIPTION_WRITTEN_DATE:
            return formatOption ? formatOption.example : moment().format('YYYY-MM-DD');

        case PRINT_ELEMENT_PRESCRIPTION_EXPIRY_DATE:
            return formatOption ? formatOption.example : moment().add(1, 'years').format('YYYY-MM-DD');

        case PRINT_ELEMENT_PRESCRIPTION_FIELDS:
            return '--';

        case PRINT_ELEMENT_INVOICE_LICENSED_USER:
            return 'Dr. Jake Thompson';

        case PRINT_ELEMENT_INVOICE_LICENSED_USER_NUMBER:
            return '0012345';

        case PRINT_ELEMENT_INVOICE_NUMBER:
            return '00025288';

        case PRINT_ELEMENT_INVOICE_DUE_DATE:
            return formatOption ? formatOption.example : moment().format('YYYY-MM-DD');

        case PRINT_ELEMENT_INVOICE_DATE:
            return formatOption ? formatOption.example : moment().format('YYYY-MM-DD');

        case PRINT_ELEMENT_INVOICE_SUB_TOTAL:
            return '$100.00';

        case PRINT_ELEMENT_INVOICE_TAX_RATE:
            return formatOption ? formatOption.example : '13%';

        case PRINT_ELEMENT_INVOICE_TAX_AMOUNT:
            return '$13.00';

        case PRINT_ELEMENT_INVOICE_DISCOUNT_RATE:
            return formatOption ? formatOption.example : '--';

        case PRINT_ELEMENT_INVOICE_DISCOUNT_AMOUNT:
            return '--';

        case PRINT_ELEMENT_INVOICE_TOTAL:
            return '$113.00';

        case PRINT_ELEMENT_INVOICE_AMOUNT_PAID:
            return '$0.00';

        case PRINT_ELEMENT_INVOICE_TOTAL_DUE:
            return '$113.00';

        case PRINT_ELEMENT_INVOICE_NOTE:
            return 'Note...'

        case PRINT_ELEMENT_INVOICE_ITEM_DESCRIPTION:
            return 'Professional service';

        case PRINT_ELEMENT_INVOICE_ITEM_UNIT_PRICE:
            return '$100.00';

        case PRINT_ELEMENT_INVOICE_ITEM_QUANTITY:
            return formatOption ? formatOption.example : '1';

        case PRINT_ELEMENT_INVOICE_ITEM_DISCOUNT_RATE:
            return formatOption ? formatOption.example : '--';

        case PRINT_ELEMENT_INVOICE_ITEM_DISCOUNT_AMOUNT:
            return '--';

        case PRINT_ELEMENT_INVOICE_ITEM_TAX_RATE:
            return formatOption ? formatOption.example : '13%';

        case PRINT_ELEMENT_INVOICE_ITEM_TAX_AMOUNT:
            return '$13.00';

        case PRINT_ELEMENT_INVOICE_ITEM_TOTAL:
            return '$113.00';

        case PRINT_ELEMENT_PAYMENT_NUMBER:
            return 'P00025288-001';

        case PRINT_ELEMENT_PAYMENT_DATE:
            return formatOption ? formatOption.example : moment().format('YYYY-MM-DD');

        case PRINT_ELEMENT_PAYMENT_TOTAL:
            return '$50.00';

        case PRINT_ELEMENT_PAYMENT_PREVIOUS_TOTAL:
            return '$20.00';

        case PRINT_ELEMENT_PAYMENT_ITEM_DATE:
            return formatOption ? formatOption.example : moment().format('YYYY-MM-DD');

        case PRINT_ELEMENT_PAYMENT_ITEM_DESCRIPTION:
            return 'MasterCard - 5790';

        case PRINT_ELEMENT_PAYMENT_ITEM_METHOD:
            return 'MasterCard';

        case PRINT_ELEMENT_PAYMENT_ITEM_AMOUNT:
            return '$50.00';

        case PRINT_ELEMENT_FAX_COVER_PAGE_SENDER:
            return 'Michael Smith';

        case PRINT_ELEMENT_FAX_COVER_PAGE_RECIPIENT:
            return 'Joanne Hill';

        case PRINT_ELEMENT_FAX_COVER_PAGE_FAX_NUMBER:
            return '+14161234567';

        case PRINT_ELEMENT_FAX_COVER_PAGE_SUBJECT:
            return 'Patient Report';

        case PRINT_ELEMENT_FAX_COVER_PAGE_MESSAGE:
            return '--';

        case PRINT_ELEMENT_FAX_COVER_PAGE_NUMBER_OF_PAGES:
            return '2';

        case PRINT_ELEMENT_PRINT_DATE:
            return formatOption ? formatOption.example : moment().format('YYYY-MM-DD');

        case PRINT_ELEMENT_PAGE_NUMBER:
            return formatOption ? formatOption.example : 'Page 1';

        default:
            return '';
    }
}

export const getFormatOptions = (type) => {
    switch (type) {
        case PRINT_ELEMENT_BUSINESS_PHONE:
            return [{
                type: 'PhoneInternational',
                example: '+14165559999'
            }, {
                type: 'PhoneDash',
                example: '416-555-9999',
            }, {
                type: 'PhoneBrackets',
                example: '(416) 555-9999',
            }, {
                type: 'PhoneDashPlusOne',
                example: '1-416-555-9999',
            }, {
                type: 'PhoneBracketsPlusOne',
                example: '1 (416) 555-9999',
            }];

        case PRINT_ELEMENT_BUSINESS_FAX:
        case PRINT_ELEMENT_FAX_COVER_PAGE_FAX_NUMBER:
            return [{
                type: 'PhoneInternational',
                example: '+14163331111'
            }, {
                type: 'PhoneDash',
                example: '416-333-1111',
            }, {
                type: 'PhoneBrackets',
                example: '(416) 333-1111',
            }, {
                type: 'PhoneDashPlusOne',
                example: '1-416-333-1111',
            }, {
                type: 'PhoneBracketsPlusOne',
                example: '1 (416) 333-1111',
            }];

        case PRINT_ELEMENT_INVOICE_DUE_DATE:
        case PRINT_ELEMENT_INVOICE_DATE:
        case PRINT_ELEMENT_PAYMENT_DATE:
        case PRINT_ELEMENT_PAYMENT_ITEM_DATE:
        case PRINT_ELEMENT_PRINT_DATE:
            return [{
                type: 'DashYearMonthDay',
                example: moment().format('YYYY-MM-DD'),
            }, {
                type: 'SlashYearMonthDay',
                example: moment().format('YYYY/MM/DD'),
            }, {
                type: 'FullDate',
                example: moment().format('MMMM D, YYYY'),
            }, {
                type: 'ShortDate',
                example: moment().format('MMM D, YYYY'),
            }];

        case PRINT_ELEMENT_INVOICE_TAX_RATE:
            return [{
                type: 'RateOnly',
                example: '13%',
            }, {
                type: 'PrefixRateOnly',
                example: 'Tax (13%)',
            }, {
                type: 'CodeRate',
                example: 'HST (13%)',
            }, {
                type: 'PrefixCodeRate',
                example: 'Tax (HST 13%)',
            }];

        case PRINT_ELEMENT_INVOICE_DISCOUNT_RATE:
            return [{
                type: 'RateOnly',
                example: '20%',
            }, {
                type: 'PrefixRateOnly',
                example: 'Discount (20%)',
            }];

        case PRINT_ELEMENT_INVOICE_ITEM_QUANTITY:
            return [{
                type: 'QuantityOnly',
                example: '1',
            }, {
                type: 'QuantityX',
                example: '1 x',
            }, {
                type: 'XQuantity',
                example: 'x 1',
            }];

        case PRINT_ELEMENT_INVOICE_ITEM_DISCOUNT_RATE:
            return [{
                type: 'RateOnly',
                example: '20%',
            }, {
                type: 'PrefixRateOnly',
                example: 'Discount (20%)',
            }];

        case PRINT_ELEMENT_INVOICE_ITEM_TAX_RATE:
            return [{
                type: 'RateOnly',
                example: '13%',
            }, {
                type: 'PrefixRateOnly',
                example: 'Tax (13%)',
            }, {
                type: 'CodeRate',
                example: 'HST (13%)',
            }, {
                type: 'PrefixCodeRate',
                example: 'Tax (HST 13%)',
            }];

        case PRINT_ELEMENT_PAGE_NUMBER:
            return [{
                type: 'PageNumberOnly',
                example: '1',
            }, {
                type: 'PrefixPageNumberOnly',
                example: 'Page 1',
            }, {
                type: 'PageNumberAndTotal',
                example: '1 of 1',
            }, {
                type: 'PrefixPageNumberAndTotal',
                example: 'Page 1 of 1',
            }]

        case PRINT_ELEMENT_PRESCRIPTION_FIELDS:
            return [{
                type: 'None',
                example: 'Display as is',
            }, {
                type: 'PlusMinus',
                example: '+1.00, -1.00, 0.00',
            }, {
                type: 'PlusMinusPlusZero',
                example: '+1.00, -1.00, +0.00',
            }]

        default:
            return [];
    }
}

export const renderElement = (element, options) => {
    const defaults = {
        controlKey: `_${uuid()}`,
        readOnly: null,
        isDesignMode: true,
        defaultValue: null,
        quickDrawer: null,
        onClick: null,
        onDoubleClick: null,
        onContextMenu: null,
    };
    options = { ...defaults, ...options }

    if (options.pageSettings) {
        options.pageSettings.paper = options.pageSettings.paper ? options.pageSettings.paper : getPaperDimension('letter');
        options.pageSettings.margins = options.pageSettings.margins ? options.pageSettings.margins : PRINT_TEMPLATE_PAGE_SETTINGS_MARGINS_NORMAL;
    } else {
        options.pageSettings = {
            paper: getPaperDimension('letter'),
            margins: PRINT_TEMPLATE_PAGE_SETTINGS_MARGINS_NORMAL,
        };
    }

    switch (element.type) {
        case PRINT_ELEMENT_RICHTEXT_EDITOR:
        case PRINT_ELEMENT_BUSINESS_NAME:
        case PRINT_ELEMENT_BUSINESS_FULL_ADDRESS:
        case PRINT_ELEMENT_BUSINESS_ADDRESS_LINE1:
        case PRINT_ELEMENT_BUSINESS_ADDRESS_LINE2:
        case PRINT_ELEMENT_BUSINESS_ADDRESS_COUNTRY:
        case PRINT_ELEMENT_BUSINESS_PHONE:
        case PRINT_ELEMENT_BUSINESS_FAX:
        case PRINT_ELEMENT_BUSINESS_EMAIL:
        case PRINT_ELEMENT_CUSTOMER_NAME:
        case PRINT_ELEMENT_CUSTOMER_FULL_ADDRESS:
        case PRINT_ELEMENT_CUSTOMER_ADDRESS_LINE1:
        case PRINT_ELEMENT_CUSTOMER_ADDRESS_LINE2:
        case PRINT_ELEMENT_CUSTOMER_ADDRESS_COUNTRY:
        case PRINT_ELEMENT_CUSTOMER_PHONE:
        case PRINT_ELEMENT_CUSTOMER_EMAIL:
        case PRINT_ELEMENT_CUSTOMER_NEXT_APPOINTMENT:
        case PRINT_ELEMENT_PRESCRIPTION_DOCTOR_NAME:
        case PRINT_ELEMENT_PRESCRIPTION_DOCTOR_LICENSE:
        case PRINT_ELEMENT_PRESCRIPTION_WRITTEN_DATE:
        case PRINT_ELEMENT_PRESCRIPTION_EXPIRY_DATE:
        case PRINT_ELEMENT_PRESCRIPTION_FIELDS:
        case PRINT_ELEMENT_INVOICE_NUMBER:
        case PRINT_ELEMENT_INVOICE_DUE_DATE:
        case PRINT_ELEMENT_INVOICE_DATE:
        case PRINT_ELEMENT_INVOICE_LICENSED_USER:
        case PRINT_ELEMENT_INVOICE_LICENSED_USER_NUMBER:
        case PRINT_ELEMENT_INVOICE_ITEM_DESCRIPTION:
        case PRINT_ELEMENT_INVOICE_ITEM_UNIT_PRICE:
        case PRINT_ELEMENT_INVOICE_ITEM_QUANTITY:
        case PRINT_ELEMENT_INVOICE_ITEM_DISCOUNT_RATE:
        case PRINT_ELEMENT_INVOICE_ITEM_DISCOUNT_AMOUNT:
        case PRINT_ELEMENT_INVOICE_ITEM_TAX_RATE:
        case PRINT_ELEMENT_INVOICE_ITEM_TAX_AMOUNT:
        case PRINT_ELEMENT_INVOICE_ITEM_TOTAL:
        case PRINT_ELEMENT_INVOICE_SUB_TOTAL:
        case PRINT_ELEMENT_INVOICE_TAX_RATE:
        case PRINT_ELEMENT_INVOICE_TAX_AMOUNT:
        case PRINT_ELEMENT_INVOICE_DISCOUNT_RATE:
        case PRINT_ELEMENT_INVOICE_DISCOUNT_AMOUNT:
        case PRINT_ELEMENT_INVOICE_TOTAL:
        case PRINT_ELEMENT_INVOICE_AMOUNT_PAID:
        case PRINT_ELEMENT_INVOICE_TOTAL_DUE:
        case PRINT_ELEMENT_INVOICE_NOTE:
        case PRINT_ELEMENT_PAYMENT_NUMBER:
        case PRINT_ELEMENT_PAYMENT_DATE:
        case PRINT_ELEMENT_PAYMENT_TOTAL:
        case PRINT_ELEMENT_PAYMENT_PREVIOUS_TOTAL:
        case PRINT_ELEMENT_PAYMENT_ITEM_DATE:
        case PRINT_ELEMENT_PAYMENT_ITEM_DESCRIPTION:
        case PRINT_ELEMENT_PAYMENT_ITEM_METHOD:
        case PRINT_ELEMENT_PAYMENT_ITEM_AMOUNT:
        case PRINT_ELEMENT_FAX_COVER_PAGE_SENDER:
        case PRINT_ELEMENT_FAX_COVER_PAGE_RECIPIENT:
        case PRINT_ELEMENT_FAX_COVER_PAGE_FAX_NUMBER:
        case PRINT_ELEMENT_FAX_COVER_PAGE_SUBJECT:
        case PRINT_ELEMENT_FAX_COVER_PAGE_MESSAGE:
        case PRINT_ELEMENT_FAX_COVER_PAGE_NUMBER_OF_PAGES:
        case PRINT_ELEMENT_PRINT_DATE:
        case PRINT_ELEMENT_PAGE_NUMBER:
            return <div
                className={
                    'form-group print-element no-label' +
                    (element.metadata && element.metadata.valign && element.metadata.valign !== 'top' ? ` text-${element.metadata.valign}` : '') +
                    (element.metadata && element.metadata.hideReadonly === true && options.readOnly ? ' d-none' : '')
                }
                style={{
                    borderTop: element.metadata && element.metadata.border && element.metadata.border.top ? element.metadata.border.top : 'none',
                    borderRight: element.metadata && element.metadata.border && element.metadata.border.right ? element.metadata.border.right : 'none',
                    borderBottom: element.metadata && element.metadata.border && element.metadata.border.bottom ? element.metadata.border.bottom : 'none',
                    borderLeft: element.metadata && element.metadata.border && element.metadata.border.left ? element.metadata.border.left : 'none',
                    backgroundColor: element.metadata && element.metadata.backgroundColor ? element.metadata.backgroundColor : 'transparent',
                }}
                data-id={element.id}
                onDoubleClick={options ? options.onDoubleClick : null}
                onContextMenu={options ? options.onContextMenu : null}
            >
                <div
                    data-key={`pe_${element.id}`}
                    className='element-wrapper'
                    style={{
                        lineHeight: 1.2,
                        textAlign: element.metadata && element.metadata.align ? element.metadata.align : 'left',
                        padding: element.metadata && element.metadata.padding && element.metadata.padding.length === 4 ? element.metadata.padding.map(p => { return `${p}px` }).join(' ') : null,
                        color: element.metadata && element.metadata.fontColor ? element.metadata.fontColor : null,
                        fontSize: element.metadata && element.metadata.fontSize ? `${element.metadata.fontSize}px` : null,
                        fontFamily: element.metadata && element.metadata.fontFamily ? element.metadata.fontFamily : null,
                        fontWeight: element.metadata && element.metadata.fontStyle && element.metadata.fontStyle.some(s => s === 'bold') ? 'bold' : null,
                        fontStyle: element.metadata && element.metadata.fontStyle && element.metadata.fontStyle.some(s => s === 'italic') ? 'italic' : null,
                        textDecoration: element.metadata && element.metadata.fontStyle && element.metadata.fontStyle.some(s => s === 'underline') ? 'underline' : null,
                        letterSpacing: element.metadata && element.metadata.fontLetterSpacing ? `${element.metadata.fontLetterSpacing}px` : null,
                    }}
                >
                    {
                        element.type === PRINT_ELEMENT_RICHTEXT_EDITOR ?
                            <span dangerouslySetInnerHTML={{ __html: element.metadata.content }}></span> :
                            <span><p>{options.defaultValue ? options.defaultValue : getDefaultValue(element.type, (element.metadata && element.metadata.format ? element.metadata.format : null))}</p></span>
                    }
                </div>
            </div>

        case PRINT_ELEMENT_IMAGE:
            return <div
                className={
                    'form-group print-element no-label' +
                    (element.metadata && element.metadata.valign && element.metadata.valign !== 'top' ? ` text-${element.metadata.valign}` : '') +
                    (element.metadata && element.metadata.hideReadonly === true && options.readOnly ? ' d-none' : '')
                }
                data-id={element.id}
                onDoubleClick={options ? options.onDoubleClick : null}
                onContextMenu={options ? options.onContextMenu : null}
            >
                <div
                    data-key={`pe_${element.id}`}
                    className='element-wrapper'
                    style={{
                        textAlign: element.metadata && element.metadata.align ? element.metadata.align : 'left',
                    }}
                >
                    <img
                        src={`${(element.metadata && element.metadata.image ? element.metadata.image.base64 : null)}`}
                        style={{
                            width: (element.metadata && element.metadata.image ? getContainImage(element, options.pageSettings).width : null),
                            height: (element.metadata && element.metadata.image ? getContainImage(element, options.pageSettings).height : null),
                        }}
                        alt='print-element'
                    />
                </div>
            </div>

        case PRINT_ELEMENT_PRESCRIPTION_DOCTOR_SIGNATURE:
            return <div
                className={
                    'form-group print-element no-label' +
                    (element.metadata && element.metadata.hideReadonly === true && options.readOnly ? ' d-none' : '')
                }
                data-id={element.id}
            >
                <div
                    data-key={`pe_${element.id}`}
                    className='element-wrapper'
                >
                    <img
                        src={pph.getSignatureLineBase64(pph.PRESCRIPTION_SIGNATURE_DEMO)}
                        style={{
                            width: `${pph.PRESCRIPTION_SIGNATURE_CANVAS_WIDTH}px`,
                            height: `${pph.PRESCRIPTION_SIGNATURE_CANVAS_HEIGHT}px`,
                        }}
                        alt='print-element'
                    />
                </div>
            </div>


        default:
            break;
    }
}

export const getDefaultPosition = type => {
    switch (type) {
        case PRINT_ELEMENT_IMAGE:
            return { w: 10, h: 10, minW: 1 };

        case PRINT_ELEMENT_RICHTEXT_EDITOR:
            return { w: 5, h: 5, minW: 2 };

        case PRINT_ELEMENT_BUSINESS_NAME:
        case PRINT_ELEMENT_BUSINESS_PHONE:
        case PRINT_ELEMENT_BUSINESS_FAX:
        case PRINT_ELEMENT_BUSINESS_EMAIL:
        case PRINT_ELEMENT_CUSTOMER_NAME:
        case PRINT_ELEMENT_CUSTOMER_PHONE:
        case PRINT_ELEMENT_CUSTOMER_EMAIL:
        case PRINT_ELEMENT_CUSTOMER_NEXT_APPOINTMENT:
        case PRINT_ELEMENT_PRESCRIPTION_DOCTOR_NAME:
        case PRINT_ELEMENT_PRESCRIPTION_DOCTOR_LICENSE:
        case PRINT_ELEMENT_PRESCRIPTION_WRITTEN_DATE:
        case PRINT_ELEMENT_PRESCRIPTION_EXPIRY_DATE:
        case PRINT_ELEMENT_PRESCRIPTION_FIELDS:
        case PRINT_ELEMENT_INVOICE_LICENSED_USER:
        case PRINT_ELEMENT_INVOICE_LICENSED_USER_NUMBER:
        case PRINT_ELEMENT_INVOICE_NUMBER:
        case PRINT_ELEMENT_INVOICE_DUE_DATE:
        case PRINT_ELEMENT_INVOICE_DATE:
        case PRINT_ELEMENT_INVOICE_SUB_TOTAL:
        case PRINT_ELEMENT_INVOICE_TAX_RATE:
        case PRINT_ELEMENT_INVOICE_TAX_AMOUNT:
        case PRINT_ELEMENT_INVOICE_DISCOUNT_RATE:
        case PRINT_ELEMENT_INVOICE_DISCOUNT_AMOUNT:
        case PRINT_ELEMENT_INVOICE_TOTAL:
        case PRINT_ELEMENT_INVOICE_AMOUNT_PAID:
        case PRINT_ELEMENT_INVOICE_TOTAL_DUE:
        case PRINT_ELEMENT_INVOICE_NOTE:
        case PRINT_ELEMENT_INVOICE_ITEM_UNIT_PRICE:
        case PRINT_ELEMENT_INVOICE_ITEM_QUANTITY:
        case PRINT_ELEMENT_INVOICE_ITEM_DISCOUNT_RATE:
        case PRINT_ELEMENT_INVOICE_ITEM_DISCOUNT_AMOUNT:
        case PRINT_ELEMENT_INVOICE_ITEM_TAX_RATE:
        case PRINT_ELEMENT_INVOICE_ITEM_TAX_AMOUNT:
        case PRINT_ELEMENT_INVOICE_ITEM_TOTAL:
        case PRINT_ELEMENT_PAYMENT_NUMBER:
        case PRINT_ELEMENT_PAYMENT_DATE:
        case PRINT_ELEMENT_PAYMENT_TOTAL:
        case PRINT_ELEMENT_PAYMENT_PREVIOUS_TOTAL:
        case PRINT_ELEMENT_PAYMENT_ITEM_DATE:
        case PRINT_ELEMENT_PAYMENT_ITEM_METHOD:
        case PRINT_ELEMENT_PAYMENT_ITEM_AMOUNT:
        case PRINT_ELEMENT_FAX_COVER_PAGE_SENDER:
        case PRINT_ELEMENT_FAX_COVER_PAGE_RECIPIENT:
        case PRINT_ELEMENT_FAX_COVER_PAGE_FAX_NUMBER:
        case PRINT_ELEMENT_FAX_COVER_PAGE_SUBJECT:
        case PRINT_ELEMENT_FAX_COVER_PAGE_MESSAGE:
        case PRINT_ELEMENT_FAX_COVER_PAGE_NUMBER_OF_PAGES:
        case PRINT_ELEMENT_PRINT_DATE:
            return { w: 6, h: 4, minW: 2 };

        case PRINT_ELEMENT_BUSINESS_FULL_ADDRESS:
        case PRINT_ELEMENT_CUSTOMER_FULL_ADDRESS:
            return { w: 20, h: 10, minW: 2 };

        case PRINT_ELEMENT_BUSINESS_ADDRESS_LINE1:
        case PRINT_ELEMENT_BUSINESS_ADDRESS_LINE2:
        case PRINT_ELEMENT_BUSINESS_ADDRESS_COUNTRY:
        case PRINT_ELEMENT_CUSTOMER_ADDRESS_LINE1:
        case PRINT_ELEMENT_CUSTOMER_ADDRESS_LINE2:
        case PRINT_ELEMENT_CUSTOMER_ADDRESS_COUNTRY:
        case PRINT_ELEMENT_INVOICE_ITEM_DESCRIPTION:
        case PRINT_ELEMENT_PAYMENT_ITEM_DESCRIPTION:
            return { w: 12, h: 4, minW: 2 };

        case PRINT_ELEMENT_PRESCRIPTION_DOCTOR_SIGNATURE:
            return { w: 33, h: 36, minW: 33, maxW: 33, minH: 36, maxH: 36 };

        default:
            return { w: 10, h: 5, minW: 2, };
    }
}

export const getContainImage = (imageElement, pageSettings) => {
    let { width: imageWidth, height: imageHeight } = imageElement.metadata.image;
    const containerWidth = parseFloat(imageElement.position.w * ((pageSettings.paper.width - (pageSettings.margins * 2)) / 64), 10);
    const containerHeight = parseFloat(imageElement.position.h * PRINT_TEMPLATE_ROW_HEIGHT, 10);

    if (containerHeight >= containerWidth) {
        if (imageHeight > containerHeight) {
            const heightScale = containerHeight / imageHeight;

            imageWidth = imageWidth * heightScale;
            imageHeight = containerHeight;
        }

        if (imageWidth > containerWidth) {
            const scale = containerWidth / imageWidth;

            imageWidth = containerWidth;
            imageHeight = imageHeight * scale;
        }
    }
    else if (containerWidth > containerHeight) {
        if (imageWidth > containerWidth) {
            const widthScale = containerWidth / imageWidth;

            imageWidth = containerWidth;
            imageHeight = imageHeight * widthScale;
        }

        if (imageHeight > containerHeight) {
            const scale = containerHeight / imageHeight;

            imageWidth = imageWidth * scale;
            imageHeight = containerHeight;
        }
    }

    return {
        width: imageWidth,
        height: imageHeight,
    };
}

export const getPaperDimension = (paperType) => {
    if (!paperType) {
        paperType = 'letter';
    }

    switch (paperType.toLowerCase()) {
        case 'a4':
            return {
                type: paperType.toLowerCase(),
                width: PRINT_TEMPLATE_PAGE_SETTINGS_PAPER_A4_WIDTH,
                height: PRINT_TEMPLATE_PAGE_SETTINGS_PAPER_A4_HEIGHT,
            };

        case 'legal':
            return {
                type: paperType.toLowerCase(),
                width: PRINT_TEMPLATE_PAGE_SETTINGS_PAPER_LEGAL_WIDTH,
                height: PRINT_TEMPLATE_PAGE_SETTINGS_PAPER_LEGAL_HEIGHT,
            };

        case 'letter':
        default:
            return {
                type: paperType.toLowerCase(),
                width: PRINT_TEMPLATE_PAGE_SETTINGS_PAPER_LETTER_WIDTH,
                height: PRINT_TEMPLATE_PAGE_SETTINGS_PAPER_LETTER_HEIGHT,
            };
    }
}