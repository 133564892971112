import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import { isMobile } from 'react-device-detect';

const noneModule = {
    toolbar: []
};
const biuModule = {
    toolbar: [
        ['bold', 'italic', 'underline'],]
};
const basicModule = {
    toolbar: [
        ['bold', 'italic', 'underline'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    ]
};
const fullModule = {
    toolbar: [
        ['bold', 'italic', 'underline'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'color': [] }, { 'background': [] }]],
};
const noneFormats = [];
const biuFormats = [
    'bold', 'italic', 'underline',
];
const basicFormats = [
    'bold', 'italic', 'underline',
    'list', 'bullet', 'indent',
];
const fullFormats = [
    'bold', 'italic', 'underline',
    'list', 'bullet', 'indent',
    'background', 'color',
];

export default class RichTextEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFocus: false,
            validContent: null,
        }
        this.quillRef = React.createRef();
        this.id = `_${Math.random().toString(36).substr(2, 5)}`;
        this.onChange = this.props.onChange ? this.props.onChange : null;
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    componentDidMount() {
        const that = this;
        this.labelEditorElement = this.quillRef.current;

        // delete this.quillRef.current.editor.getModule('keyboard').bindings['9'];

        if (this.props.maxLength) {
            this.quillRef.current.editor.on('text-change', function (delta, old) {
                if (that.quillRef.current.editor.getLength() > (that.props.maxLength + 1)) {
                    try {
                        that.quillRef.current.editor.setContents(old, 'silent');
                    } catch (e) {
                        setTimeout(() => {
                            that.quillRef.current.editor.focus();
                        }, 500);
                    }
                }
            })
        }

        if (!!this.props.disableTab) {
            delete this.quillRef.current.editor.getModule('keyboard').bindings["9"];
        }

        if (!!this.props.disableEnter) {
            delete this.quillRef.current.editor.getModule('keyboard').bindings["13"];
        }

        if (this.props.dataAttributes && this.quillRef.current.editor.container) {
            for (let i = 0; i < this.props.dataAttributes.length; i++) {
                const attribute = this.props.dataAttributes[i];
                this.quillRef.current.editor.container.setAttribute(attribute.name, attribute.value);
            }
        }

        if (this.quillRef.current && this.quillRef.current.editor && this.quillRef.current.editor.clipboard) {
            this.quillRef.current.editor.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
                let ops = [];
                delta.ops.forEach(op => {
                    if (op.insert && typeof op.insert === 'string') {
                        ops.push({
                            insert: op.insert
                        })
                    }
                })
                delta.ops = ops;
                return delta;
            })
        }
    }

    onFocus = () => {
        this.setState({ isFocus: true });
    }

    onBlur = () => {
        this.setState({ isFocus: false });
    }

    getModules = () => {
        const { mode } = this.props;
        switch (mode) {
            case 'none':
                return noneModule;

            case 'biu':
                return biuModule;

            case 'basic':
                return basicModule;

            case 'full':
            default:
                return fullModule;
        }
    }

    getFormats = () => {
        const { mode } = this.props;
        switch (mode) {
            case 'none':
                return noneFormats;

            case 'biu':
                return biuFormats;

            case 'basic':
                return basicFormats;

            case 'full':
            default:
                return fullFormats;
        }
    }

    render() {
        if (this.props.isRequired !== null && this.props.isRequired === false) {
        }

        return (
            <ReactQuill
                id={this.props.id ? this.props.id : this.id}
                ref={this.quillRef}
                name={this.props.name ? this.props.name : this.id}
                className={(this.props.className ? this.props.className : '') + (this.props.mode === 'none' || this.props.theme === 'bubble' ? ' quill-no-toolbar' : '') + (isMobile ? ' quill-mobile' : '') + (this.state.isFocus ? ' focus' : '') + (this.props.readOnly === true ? ' ql-disabled' : '')}
                placeholder={this.props.placeholder ? this.props.placeholder : ''}
                tabIndex={this.props.tabIndex ? this.props.tabIndex : null}
                readOnly={this.props.readOnly && this.props.readOnly === true}
                value={this.props.value}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                modules={this.getModules()}
                formats={this.getFormats()}
                theme={this.props.theme ? this.props.theme : 'snow'}
            />
        )
    }
}