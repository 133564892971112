export const MENTION_MARKUP = '{#[__display__]`__id__`}';

export const parseVariables = (value) => {
    const variables = [];
    const regexOuter = /{#([^}]+)`}/g;
    const regexKey = /`([^}]+)`/g;
    let match = regexOuter.exec(value);

    while (match) {
        let keyMatch = regexKey.exec(match[0]);

        while (keyMatch) {
            const variable = keyMatch[1];
            variables.push({ markup: match[0], key: variable });

            keyMatch = regexKey.exec(match[0]);
        }

        match = regexOuter.exec(value);
    }

    return variables;
}

export const getDisplay = (value) => {
    const regexOuter = /{#([^}]+)`}/g;
    const regexDisplay = /\[([^[]+)\]/g;
    const match = regexOuter.exec(value);

    if (match) {
        const displayMatch = regexDisplay.exec(match[0]);

        if (displayMatch) {
            return displayMatch[1];
        }
    }

    return '';
}