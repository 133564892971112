import React, { useEffect, useContext, useState, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { Observer } from 'mobx-react-lite';
import MaskedInput from 'react-text-mask'
import { GlobalHotKeys } from 'react-hotkeys';

import BodyEnd from '../../_shared/BodyEnd';
import ConfirmModal from '../../_shared/ConfirmModal';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import RichTextEditor from '../../_shared/RichTextEditor';

import PurchaseUpdateStore from '../../../../stores/PurchaseUpdateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';
import CacheStore from '../../../../stores/CacheStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as MaskKeys from '../../../../constants/maskKeys';
import * as fn from '../../../../utilities/_functions';
import * as ch from '../../../../utilities/customerHelper';
import * as oh from '../../../../utilities/operationHelper';
import * as ph from '../../../../utilities/personHelper';
import * as ah from '../../../../utilities/addressHelper';
import * as bh from '../../../../utilities/badgeHelper';

import './DeletePayment.scss';

function DeletePayment(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const focusTimer = useRef(null);
    const purchase = useContext(PurchaseUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const cache = useContext(CacheStore);
    const [confirmDelete, setConfirmDelete] = useState(false);

    useEffect(() => {
        if (props.extraProps) {
            purchase.removePaymentId = props.extraProps.id ? props.extraProps.id : null;
            purchase.removeAmount = props.extraProps.amount ? props.extraProps.amount : null;
            purchase.removePaymentMethod = props.extraProps.paymentMethod ? props.extraProps.paymentMethod : null;
        }

        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100)

        return () => {
            isMounted.current = false;

            purchase.clearRemove();
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (purchase.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleReasonChange = (content, delta, source, editor) => {
        const note = editor.getText().replace(/(\r\n|\n|\r)/g, ' ').trim();
        const noteHtml = (content === '<p><br></p>') ? null : content;

        purchase.removeReason = note;
        purchase.removeReasonHtml = noteHtml;
        purchase.hasUnsavedChanges = true;
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            setConfirmDelete(true);
        }
    }

    const handleConfirmDelete = event => {
        purchase.removePayment(true)
            .then(() => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, { updated: true });
                    }
                }
            })
    }

    const handleConfirmCancel = event => {
        setConfirmDelete(false);
    }

    const getRemainingBalance = () => {
        if (purchase && purchase.data) {
            return (purchase.data.remainingBalance + (purchase.removeAmount ? purchase.removeAmount : 0));
        }
        return 0;
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) && !confirmDelete ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form onSubmit={handleSubmit}>
            <Observer>{() =>
                <fieldset disabled={purchase.isSaving}>
                    <div className='delete-payment-container quick-drawer'>
                        <QuickDrawerHeader
                            drawer={props.drawer}
                            icon={oh.getIcon('purchase', 'delete-payment')}
                            action='Purchase'
                            category='Delete Payment'
                            className='delete'
                            onCancel={handleCancel}
                        />
                        <div className='quick-drawer-body'>
                            <Observer>{() =>
                                <>
                                    {
                                        purchase.data ?
                                            <FadeIn>
                                                <div className='body-content'>
                                                    <section>
                                                        <div className='row'>
                                                            <div className='col-6'>
                                                                <h3 className='purchase-title'>Invoice Detail</h3>
                                                                <p>Invoice # <strong>{purchase.data.invoiceNumber}</strong></p>
                                                            </div>
                                                            <div className='col-6'>
                                                                <div className='text-right pr-4'>
                                                                    {bh.renderPurchaseBalanceStatus(purchase.data, 'text-truncate text-truncate-md fs-xs p-2')}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <Observer>{() =>
                                                        <>
                                                            {
                                                                purchase.data.customer ?
                                                                    <section className='customer'>
                                                                        <div className='row'>
                                                                            <div className='col-12'>
                                                                                <label><small>Billing Information</small></label>
                                                                            </div>
                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className='col-12'>
                                                                                <Observer>{() =>
                                                                                    <div
                                                                                        className='profile-wrapper'
                                                                                    >
                                                                                        <div className='profile'>
                                                                                            <span
                                                                                                className={`profile-image profile-initials rounded-circle d-flex text-white ${ch.getProfileColor(purchase.data.customer)} fw-500`}
                                                                                                title={purchase.data.customer.fullName}
                                                                                            >
                                                                                                {purchase.data.customer.initials}
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className='description flex-1'>
                                                                                            <div className='name'>{ph.getFullName(purchase.data.customer, true)}
                                                                                                {
                                                                                                    purchase.data.customer.dateOfBirth || purchase.data.customer.sex || purchase.data.customer.gender || purchase.data.customer.pronoun ?
                                                                                                        <small className='text-nowrap ml-2'>({`${ph.getAge(purchase.data.customer.dateOfBirth)} ${ph.getSexGenderPronounDisplay(purchase.data.customer)}`.trim()})</small> : null
                                                                                                }
                                                                                            </div>
                                                                                            {
                                                                                                purchase.data.billingAddress ?
                                                                                                    <div className='info'>{ah.getAddressHtml(purchase.data.billingAddress)}</div> : null
                                                                                            }
                                                                                            {
                                                                                                purchase.data.customerEmail ?
                                                                                                    <div className='info'>
                                                                                                        <a
                                                                                                            href={`mailto:${purchase.data.customerEmail}`}
                                                                                                        >{purchase.data.customerEmail}
                                                                                                        </a>
                                                                                                    </div> : null
                                                                                            }
                                                                                            {
                                                                                                purchase.data.customerPhoneNumber ?
                                                                                                    <div className='info'>
                                                                                                        <a
                                                                                                            href={`tel:${purchase.data.customerPhoneNumber}`}
                                                                                                        >{purchase.data.customerPhoneNumber}
                                                                                                        </a>
                                                                                                    </div> : null
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                }</Observer>
                                                                            </div>
                                                                        </div>
                                                                    </section> : null
                                                            }
                                                        </>
                                                    }</Observer>
                                                    <section>
                                                        <div className='row'>
                                                            <div className='col-4'>
                                                                <label><small>Invoice Total</small></label>
                                                                <Observer>{() =>
                                                                    <strong className='d-block fs-xl'>{fn.formatCurrency(purchase.data.total)}</strong>
                                                                }</Observer>
                                                            </div>
                                                            <div className='col-8'>
                                                                <label><small className='text-danger'>New Remaining Balance</small></label>
                                                                <Observer>{() =>
                                                                    <strong className={'d-block fs-xl text-danger' + (getRemainingBalance() < 0 ? ' text-danger' : '')}>{fn.formatCurrency(getRemainingBalance())}</strong>
                                                                }</Observer>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <section>
                                                        <div className='row'>
                                                            <div className='col-4'>
                                                                <label htmlFor='delete-payment-amount'><small>Amount</small></label>
                                                                <Observer>{() =>
                                                                    <MaskedInput
                                                                        id='delete-payment-amount'
                                                                        className='form-control currency-input'
                                                                        mask={MaskKeys.CURRENCY_POSITIVE_MASK}
                                                                        disabled={true}
                                                                        value={purchase.removeAmount ? purchase.removeAmount : ''}
                                                                    />
                                                                }</Observer>
                                                            </div>
                                                            <div className='col-8'>
                                                                <label htmlFor='delete-payment-paid-by'><small>Paid by</small></label>
                                                                <Observer>{() =>
                                                                    <select
                                                                        id='delete-payment-paid-by'
                                                                        className='custom-select form-control'
                                                                        disabled={true}
                                                                        value={purchase.removePaymentMethod ? purchase.removePaymentMethod : ''}
                                                                    >
                                                                        <option value=''></option>
                                                                        {
                                                                            cache.getReferenceDataOptions('PaymentMethodType').map((option, di) => {
                                                                                return <option key={`payment_method_type_${di}`} value={option.key}>{option.value}</option>
                                                                            })
                                                                        }
                                                                    </select>
                                                                }</Observer>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <section>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className='form-group validate validate-required'>
                                                                    <label className='required'><small>Reason</small></label>
                                                                    <div className='input-group'>
                                                                        <Observer>{() =>
                                                                            <RichTextEditor
                                                                                mode='none'
                                                                                disableTab={true}
                                                                                value={purchase.removeReasonHtml}
                                                                                onChange={handleReasonChange}
                                                                            />
                                                                        }</Observer>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </FadeIn> : renderQuickDrawerLoading()
                                    }
                                </>
                            }</Observer>
                        </div>
                        <div className='quick-drawer-action pl-3'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='float-right'>
                                        <button
                                            type='button'
                                            className='btn btn-link btn-cancel mr-2'
                                            onClick={handleCancel}
                                        >Cancel</button>
                                        <button
                                            type='submit'
                                            className='btn btn-danger'
                                        >Delete</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            }</Observer>
        </form>
        <Observer>{() =>
            <BodyEnd>
                <ConfirmModal
                    icon={<i className={'text-danger mr-2 ' + (oh.getIcon('purchase', 'delete-payment'))}></i>}
                    message={<>Continue to<span className='fw-500'>&nbsp;delete payment</span>? </>}
                    descriptionClassName='warning-danger warning-flashing'
                    show={confirmDelete}
                    onOption1Click={handleConfirmDelete}
                    onCancel={handleConfirmCancel}
                />
            </BodyEnd>
        }</Observer>
    </>
}

export default DeletePayment;