import { createContext } from 'react';
import { decorate, observable, action, toJS } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class ProductBrandCreate {
    data = {
        id: null,
        ids: null,
        typeIds: [],
        supplierId: null,
    };
    originalData = null;
    types = [];
    suppliers = [];
    hasUnsavedChanges = false;
    isReady = false;
    isSaving = false;
    isLoading = false;
    saveToServer = true;

    cancelProductTypes = null;
    cancelProductSuppliers = null;
    cancelProductBrandCreate = null;
    cancelProductBrandDelete = null;
    cancelProductBrandSearch = null;

    initialize = (saveToServer) => {
        const that = this;
        this.clear();

        if (!fn.isNullOrUndefined(saveToServer)) {
            this.saveToServer = saveToServer;
        }

        return new Promise((resolve, reject) => {
            Promise.all([
                api.ProductTypes.all((c) => { this.cancelProductTypes = c; }),
                api.ProductSuppliers.all((c) => { this.cancelProductSuppliers = c; })
            ])
                .then(response => {
                    that.types = response && response[0] ? response[0].data : [];
                    that.suppliers = response && response[1] ? response[1].data : [];
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isReady = true;
                })
        })
    }

    load = (name) => {
        const that = this;

        this.isLoading = true;

        return new Promise((resolve, reject) => {
            api.ProductBrands.search({
                parameters: [{
                    field: 'Name',
                    value: name,
                }, {
                    field: 'DeactivatedDateUtc',
                    value: null,
                }],
                includeTotalCount: false,
                loadProperties: true,
            }, (c) => { that.cancelProductBrandSearch = c })
                .then(({ data }) => {
                    if (data && data.result && data.result.length > 0) {
                        that.data.id = data.result[0].ids[0];
                        that.data.ids = data.result[0].ids;
                        that.data.name = data.result[0].name;
                        that.data.typeIds = data.result[0].typeIds;
                        that.data.supplierId = data.result[0].supplierId;
                        that.originalData = JSON.parse(JSON.stringify(that.data));
                    } else {
                        that.data.id = null;
                        that.data.ids = null;
                        that.data.typeIds = [];
                        that.data.supplierId = null;
                        that.originalData = null;
                    }
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isLoading = false;
                })
        })
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            let option = toJS(that.data);

            if (that.hasUnsavedChanges) {
                if (that.saveToServer) {
                    if (that.data.id) {
                        api.ProductBrands.update(that.data.id, option, (c) => { that.cancelProductBrandCreate = c })
                            .then(() => {
                                that.hasUnsavedChanges = false;
                                resolve(that.data);
                            })
                            .catch(() => {
                                reject();
                            })
                            .finally(() => {
                                that.isSaving = false;
                            })
                    } else {
                        api.ProductBrands.create(option, (c) => { that.cancelProductBrandCreate = c })
                            .then(({ data }) => {
                                that.hasUnsavedChanges = false;
                                that.data.id = data.id;
                                resolve(that.data);
                            })
                            .catch(() => {
                                reject();
                            })
                            .finally(() => {
                                that.isSaving = false;
                            })
                    }
                } else {
                    resolve(option);
                }
            } else {
                that.hasUnsavedChanges = false;
                that.isSaving = false;
                resolve(option);
            }
        })
    }

    delete = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            if (that.data.id) {
                api.ProductBrands.delete(that.data.id, (c) => { that.cancelProductBrandDelete = c })
                    .then(() => {
                        resolve(that.data);
                    })
                    .catch(() => {
                        reject();
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            }
            else 
                reject();
        })
    }

    clear = () => {
        this.data.id = null;
        this.data.name = null;
        this.data.typeIds.clear();
        this.data.supplierId = null;
        this.originalData = null;
        this.types.clear();
        this.suppliers.clear();
        this.hasUnsavedChanges = false;
        this.isReady = false;
        this.isSaving = false;
        this.isLoading = false;
        this.saveToServer = true;

        if (fn.isFunction(this.cancelProductTypes)) {
            this.cancelProductTypes();
            this.cancelProductTypes = null;
        }

        if (fn.isFunction(this.cancelProductSuppliers)) {
            this.cancelProductSuppliers();
            this.cancelProductSuppliers = null;
        }

        if (fn.isFunction(this.cancelProductBrandCreate)) {
            this.cancelProductBrandCreate();
            this.cancelProductBrandCreate = null;
        }

        if (fn.isFunction(this.cancelProductBrandDelete)) {
            this.cancelProductBrandDelete();
            this.cancelProductBrandDelete = null;
        }

        if (fn.isFunction(this.cancelProductBrandSearch)) {
            this.cancelProductBrandSearch();
            this.cancelProductBrandSearch = null;
        }
    }
}

decorate(ProductBrandCreate, {
    data: observable.deep,
    originalData: observable,
    types: observable,
    suppliers: observable,
    hasUnsavedChanges: observable,
    isReady: observable,
    isSaving: observable,
    isLoading: observable,
    saveToServer: observable,
    initialize: action,
    load: action,
    save: action,
    delete: action,
    clear: action,
})

export default createContext(new ProductBrandCreate());