import React, { useEffect, useContext, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import FadeIn from 'react-fade-in';
import { useObserver } from 'mobx-react-lite';
import { GlobalHotKeys } from 'react-hotkeys';

import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';

import CustomerUpdateStore from '../../../../stores/CustomerUpdateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';
import CacheStore from '../../../../stores/CacheStore';

import api from '../../../../api';
import * as ErrorMessages from '../../../../constants/errorMessages';
import * as rts  from '../../../../constants/routes';
import * as fn from '../../../../utilities/_functions';
import * as ch from '../../../../utilities/customerHelper';
import * as ph from '../../../../utilities/personHelper';
import * as sys from '../../../../utilities/systemHelper';
import * as oh from '../../../../utilities/operationHelper';
import * as ah from '../../../../utilities/addressHelper';

import './AddRelationship.scss';

function AddRelationship(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const searchTimer = useRef(null);
    const focusTimer = useRef(null);
    const handleCancelRelatedToSearchRef = useRef(null);
    const handleCancelCustomerSuggestionRef = useRef(null);
    const handleCancelRelatedToSuggestionRef = useRef(null);
    const customer = useContext(CustomerUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const cache = useContext(CacheStore);
    const [relatedToSearchTerm, setRelatedToSearchTerm] = useState('');
    const [relatedToSearchResult, setRelatedToSearchResult] = useState('');
    const [relatedTo, setRelatedTo] = useState(null);
    const [relationship, setRelationship] = useState(null);
    const [suggestions, setSuggestions] = useState(null);
    const [otherRelationships, setOtherRelationships] = useState(null);

    useEffect(() => {
        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100)

        return () => {
            isMounted.current = false;

            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
            if (searchTimer.current) { clearTimeout(searchTimer.current); searchTimer.current = null; }
            if (handleCancelRelatedToSearchRef.current) { handleCancelRelatedToSearchRef.current(); handleCancelRelatedToSearchRef.current = null; }
            if (handleCancelCustomerSuggestionRef.current) { handleCancelCustomerSuggestionRef.current(); handleCancelCustomerSuggestionRef.current = null; }
            if (handleCancelRelatedToSuggestionRef.current) { handleCancelRelatedToSuggestionRef.current(); handleCancelRelatedToSuggestionRef.current = null; }
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (customer.hasRelationshipUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleLinkedRelationshipChange = (event) => {
        const linkedRelationship = event.target.value;
        const relationships = [];

        setRelationship(linkedRelationship);

        if (suggestions && suggestions.length) {
            for (let i = 0; i < suggestions.length; i++) {
                const reversedRelationship = ph.getReversedRelationship(customer.data, linkedRelationship);
                const relationshipType = ph.getFamilyRelationship(reversedRelationship, suggestions[i].relationship);

                if (relationshipType) {
                    relationships.push({
                        party1: customer.data,
                        party2: suggestions[i].relatedTo,
                        relationshipType: relationshipType,
                    })
                }
            }
        }

        setOtherRelationships(relationships);

        customer.hasRelationshipUnsavedChanges = true;
    }

    const handleCustomerSearchChange = event => {
        const term = event.target.value;

        if (relatedTo) {
            setRelatedTo(null);
            setRelationship(null);
            setSuggestions(null);
        }

        setRelatedToSearchTerm(term);

        if (searchTimer.current) {
            clearTimeout(searchTimer.current);
        }

        if (term && term.length >= 2) {
            searchTimer.current = setTimeout(() => {
                api.Customers.fullSearch(term, false, false, 5, null, null, null, null, null, null, null, (c) => { handleCancelRelatedToSearchRef.current = c })
                    .then(({ data }) => {
                        if (isMounted.current) {
                            setRelatedToSearchResult(data.result);
                        }
                    })
            }, 500);
        } else {
            setRelatedToSearchResult(null);
        }
    }

    const handleRelatedToSearchClick = relatedToPerson => {
        if (!ch.checkCanUpdate(customer.data)) return;

        setRelatedTo(relatedToPerson);
        setRelatedToSearchResult(null);
        setRelatedToSearchTerm(null);

        Promise.all([
            api.CustomerRelationships.get(customer.data.id, false, (c) => { handleCancelRelatedToSuggestionRef.current = c }),
            api.CustomerRelationships.get(relatedToPerson.id, false, (c) => { handleCancelRelatedToSuggestionRef.current = c })
        ])
            .then(response => {
                if (isMounted.current) {
                    const relationships = [];
                    const existingRelationships = (response[0].data && response[0].data.length > 0 ? response[0].data : []);
                    const suggestions = (response[1].data && response[1].data.length > 0 ? response[1].data : []).filter(s => !existingRelationships.some(r => r.relatedTo.id === s.relatedTo.id));

                    setSuggestions(suggestions);
                    setOtherRelationships(relationships);
                }
            })

        customer.hasRelationshipUnsavedChanges = true;
    }

    const handleSuggestionChange = (event, suggestion) => {
        const tempOtherRelationships = [...otherRelationships];

        const index = tempOtherRelationships.findIndex(r => r.party2 && r.party2.id === suggestion.relatedTo.id);
        if (index === -1) {
            tempOtherRelationships.push({
                party1: customer.data,
                party2: suggestion.relatedTo,
                relationshipType: null,
            })
        } else {
            tempOtherRelationships.splice(index, 1);
        }

        setOtherRelationships(tempOtherRelationships);
    }

    const handleSuggestionRelationshipChange = (event, suggestion) => {
        const tempOtherRelationships = [...otherRelationships];
        const newRelationshipType = event.target.value;

        const index = tempOtherRelationships.findIndex(r => r.party2 && r.party2.id === suggestion.relatedTo.id);
        if (index > -1) {
            tempOtherRelationships[index].relationshipType = newRelationshipType;
            setOtherRelationships(tempOtherRelationships);
        }
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            customer.relationships.push({
                relatedTo: relatedTo,
                relationship: relationship,
            });

            if (otherRelationships && otherRelationships.length > 0) {
                for (let i = 0; i < otherRelationships.length; i++) {
                    customer.relationships.push({
                        relatedTo: otherRelationships[i].party2,
                        relationship: otherRelationships[i].relationshipType,
                    });
                }
            }

            customer.hasRelationshipUnsavedChanges = true;

            customer.updateRelationshipInformation()
                .then(data => {
                    if (isMounted.current) {
                        if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                            props.onSuccess(event, { updated: true, data: data });
                        }
                    }
                })
        }
    }

    const renderCustomer = () => {
        return <div
            className='profile-wrapper'
        >
            <div className='profile'>
                <span
                    className={`profile-image profile-initials rounded-circle d-flex text-white ${ch.getProfileColor(customer.data)} fw-500`}
                    title={ph.getFullName(customer.data)}
                >
                    {customer.data.initials}
                </span>
            </div>
            <div className='description flex-1'>
                <Link to={`${rts.Customers.Home}/${customer.data.id}`} className='name text-gray-700' onClick={quickDrawer.deactivateAll}>{ph.getFullName(customer.data, true)}
                    {
                        customer.data.dateOfBirth || customer.data.sex || customer.data.gender || customer.data.pronoun ?
                            <small className='text-nowrap ml-2'>({`${ph.getAge(customer.data.dateOfBirth)} ${ph.getSexGenderPronounDisplay(customer.data)}`.trim()})</small> : null
                    }
                </Link>
                {
                    customer.data.address && customer.data.address.country ?
                        <div className='info'>{ah.getAddressHtml(customer.data.address)}</div> : null
                }
                {
                    customer.data.emailAddress ?
                        <div className='info'>
                            <a
                                href={`mailto:${customer.data.emailAddress}`}
                            >{customer.data.emailAddress}
                            </a>
                        </div> : null
                }
                {
                    customer.data.phoneNumber ?
                        <div className='info'>
                            <a
                                href={`tel:${customer.data.phoneNumber}`}
                            >{sys.getFormattedPhoneNumber(customer.data.phoneNumber)}
                            </a>
                        </div> : null
                }
            </div>
        </div>
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <fieldset disabled={customer.isSaving}>
                <div className='add-customer-relationships-container quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('customer', 'relationship')}
                        action='Add Customer'
                        category='Relationship'
                        className='customers'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        {
                            customer.isReady && customer.relationships ?
                                <FadeIn>
                                    <div className='body-content'>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0'>
                                                        <label><small>Customer</small></label>
                                                        {renderCustomer()}
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section className='customer'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0 validate validate-required'>
                                                        <label className='required' htmlFor='new-related-to'><small>Related to</small></label>
                                                        <div className='dropdown'>
                                                            <i className={'fal fa-check customer-status' + (relatedTo ? ' customer-status-selected' : '')}></i>
                                                            <input
                                                                id='new-related-to'
                                                                type='text'
                                                                className='form-control mb-0 dropdown-toggle'
                                                                spellCheck={false}
                                                                autoComplete='off'
                                                                data-validator-required={!!relatedTo}
                                                                disabled={!ch.checkCanUpdate(customer.data)}
                                                                value={relatedTo ? ph.getLastFirstName(relatedTo) : (relatedToSearchTerm ? relatedToSearchTerm : '')}
                                                                onChange={handleCustomerSearchChange}
                                                            />
                                                            <ul className='dropdown-menu'>
                                                                {
                                                                    relatedToSearchResult && relatedToSearchResult.length > 0 ?
                                                                        relatedToSearchResult
                                                                            .filter(s => s.id !== customer.data.id && !customer.relationships.some(r => r.relatedTo.id === s.id))
                                                                            .slice(0, 5)
                                                                            .map((s, si) => {
                                                                                return <li
                                                                                    key={`search_result_${si}`}
                                                                                    className={'dropdown-menu-item'}>
                                                                                    <div
                                                                                        className='profile-wrapper'
                                                                                        onClick={() => handleRelatedToSearchClick(s)}
                                                                                    >
                                                                                        <div className='profile'>
                                                                                            <span
                                                                                                className={`profile-image profile-image-md profile-initials rounded-circle text-white ${ch.getProfileColor(s)} fw-500`}
                                                                                                title={ph.getPreferredFullName(s)}
                                                                                            >
                                                                                                {s.initials}
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className='description'>
                                                                                            <div className='name'>{ph.getLastFirstName(s, true)}
                                                                                                {
                                                                                                    s.dateOfBirth || s.sex || s.gender || s.pronoun ?
                                                                                                        <small className='ml-2 text-gray'>({`${ph.getAge(s.dateOfBirth)} ${ph.getSexGenderPronounDisplay(s)}`.trim()})</small> : null
                                                                                                }
                                                                                            </div>
                                                                                            <div className='info'>{s.address && s.address.addressLine1 ? s.address.addressLine1 : null}</div>
                                                                                            <div className='info fs-90'>{s.dateOfBirth ? `DOB: ${fn.formatDate(s.dateOfBirth)}` : null}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            }) : null
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0 validate validate-required'>
                                                        <label className='required' htmlFor='new-relationship'><small>Relationship</small></label>
                                                        <select
                                                            id='new-relationship'
                                                            className='custom-select form-control'
                                                            disabled={!ch.checkCanUpdate(customer.data)}
                                                            value={relationship ? relationship : ''}
                                                            onChange={handleLinkedRelationshipChange}
                                                        >
                                                            <option value=''></option>
                                                            <optgroup label='Common'>
                                                                {
                                                                    ph.COMMON_RELATIONSHIP_TYPES.map((r, ri) => {
                                                                        return <option key={`customer_common_relationship_type_${ri}`} value={r}>
                                                                            {
                                                                                cache.getReferenceDataOptions('CustomerRelationshipType').some(o => o.key === r) ?
                                                                                    cache.getReferenceDataOptions('CustomerRelationshipType').filter(o => o.key === r)[0].value : r
                                                                            }
                                                                        </option>
                                                                    })
                                                                }
                                                            </optgroup>
                                                            <optgroup label='Less common'>
                                                                {
                                                                    cache.getReferenceDataOptions('CustomerRelationshipType').filter(option => !ph.COMMON_RELATIONSHIP_TYPES.some(o => o === option.key)).map((option, di) => {
                                                                        return <option key={`customer_less_common_relationship_type_${di}`} value={option.key}>{option.value}</option>
                                                                    })
                                                                }
                                                            </optgroup>
                                                        </select>
                                                        {
                                                            relationship && relatedTo ?
                                                                <div className='pt-1 px-2 text-info-400'>{relatedTo ? ph.getPreferredFirstName(relatedTo) : ''} is <strong className='tt-underline'>{ph.getPreferredFirstName(customer.data)}'s {cache.getReferenceDataOptions('CustomerRelationshipType').filter(o => o.key === relationship)[0].value.toLowerCase()}</strong>.</div> : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        {
                                            suggestions && suggestions.length > 0 && relationship ?
                                                <section className='border-top pt-4'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-0'>
                                                                <label><small>Add other relationship(s)?</small></label>
                                                                {
                                                                    suggestions.map((s, si) => {
                                                                        return <div
                                                                            key={`relationship-suggestion-${si}`}
                                                                            className='mb-4'
                                                                        >
                                                                            <div className='d-flex'>
                                                                                <div className='pt-1 pr-2'>
                                                                                    <div className='custom-control custom-checkbox'>
                                                                                        <input
                                                                                            id={`relationship-suggestion-${si}`}
                                                                                            type='checkbox'
                                                                                            name='relationshipSuggestion'
                                                                                            className='custom-control-input'
                                                                                            disabled={!ch.checkCanUpdate(customer.data)}
                                                                                            value={s.relatedTo.id}
                                                                                            checked={otherRelationships && otherRelationships.some(r => r.party2 && r.party2.id === s.relatedTo.id)}
                                                                                            onChange={(e) => { handleSuggestionChange(e, s) }}
                                                                                        />
                                                                                        <label
                                                                                            htmlFor={`relationship-suggestion-${si}`}
                                                                                            className='custom-control-label d-block'
                                                                                        >
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className='d-block flex-1'
                                                                                >
                                                                                    <div className='d-flex flex-column'>
                                                                                        <div className='fs-xl text-gray-700'>
                                                                                            {ph.getFullName(s.relatedTo, true)}
                                                                                            {
                                                                                                s.relatedTo.dateOfBirth || s.relatedTo.sex || s.relatedTo.gender || s.relatedTo.pronoun ?
                                                                                                    <span className='fs-75 text-nowrap ml-2'>({`${ph.getAge(s.relatedTo.dateOfBirth)} ${ph.getSexGenderPronounDisplay(s)}`.trim()})</span> : null
                                                                                            }
                                                                                        </div>
                                                                                        <div>
                                                                                            {
                                                                                                s.relatedTo.address && s.relatedTo.address.country ?
                                                                                                    <div className='text-gray-600 info'>{ah.getAddressHtml(s.relatedTo.address)}</div> : null
                                                                                            }
                                                                                        </div>
                                                                                        <select
                                                                                            id='new-relationship'
                                                                                            className='custom-select form-control mt-1'
                                                                                            disabled={!ch.checkCanUpdate(customer.data)}
                                                                                            value={(otherRelationships && otherRelationships.some(r => r.party2 && r.party2.id === s.relatedTo.id && r.relationshipType) ? otherRelationships.filter(r => r.party2 && r.party2.id === s.relatedTo.id)[0].relationshipType : '')}
                                                                                            onChange={(e) => handleSuggestionRelationshipChange(e, s)}
                                                                                        >
                                                                                            <option value=''></option>
                                                                                            <optgroup label='Common'>
                                                                                                {
                                                                                                    ph.COMMON_RELATIONSHIP_TYPES.map((r, ri) => {
                                                                                                        return <option key={`customer_common_relationship_type_${ri}`} value={r}>
                                                                                                            {
                                                                                                                cache.getReferenceDataOptions('CustomerRelationshipType').some(o => o.key === r) ?
                                                                                                                    cache.getReferenceDataOptions('CustomerRelationshipType').filter(o => o.key === r)[0].value : r
                                                                                                            }
                                                                                                        </option>
                                                                                                    })
                                                                                                }
                                                                                            </optgroup>
                                                                                            <optgroup label='Less common'>
                                                                                                {
                                                                                                    cache.getReferenceDataOptions('CustomerRelationshipType').filter(option => !ph.COMMON_RELATIONSHIP_TYPES.some(o => o === option.key)).map((option, di) => {
                                                                                                        return <option key={`customer_less_common_relationship_type_${di}`} value={option.key}>{option.value}</option>
                                                                                                    })
                                                                                                }
                                                                                            </optgroup>
                                                                                        </select>
                                                                                        {
                                                                                            otherRelationships && otherRelationships.some(r => r.party2 && r.party2.id === s.relatedTo.id && r.relationshipType) ?
                                                                                                <div className='pt-1 px-2 text-info-400'>{ph.getPreferredFirstName(s.relatedTo)} is <strong className='tt-underline'>{ph.getPreferredFirstName(customer.data)}'s {cache.getReferenceDataOptions('CustomerRelationshipType').filter(o => o.key === otherRelationships.filter(r => r.party2 && r.party2.id === s.relatedTo.id)[0].relationshipType)[0].value.toLowerCase()}</strong>.</div> : null
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section> : null
                                        }
                                    </div>
                                </FadeIn> : renderQuickDrawerLoading()
                        }
                    </div>
                    <div className='quick-drawer-action pl-3'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        disabled={!ch.checkCanUpdate(customer.data)}
                                        className='btn btn-success'
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>)
}

export default AddRelationship;