import { createContext } from 'react';
import { toJS } from 'mobx';
import { decorate, observable, action } from 'mobx';

import * as fn from '../utilities/_functions';
import api from '../api';

export class ContactCreate {
    id = null;
    data = {
        name: null,
        companyName: null,
        address: {
            text: null,
            geocode: null,
            unitSuiteType: null,
            unitSuite: null,
            line2: null,
        },
        primaryPhoneNumber: null,
        secondaryPhoneNumber: null,
        faxNumber: null,
        primaryEmailAddress: null,
        secondaryEmailAddress: null,
        websiteUrl: null,
        noteHtml: null,
    };
    isReady = false;
    isSaving = false;
    hasUnsavedChanges = false;

    cancelContactCreate = null;

    initialize = () => {
        this.clear();
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            if (that.hasUnsavedChanges) {
                const option = toJS(that.data);

                api.Contacts.create(option, (c) => { that.cancelContactCreate = c })
                    .then(({ data }) => {
                        that.id = data.id;
                        that.hasUnsavedChanges = false;
                        resolve(data);
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                that.hasUnsavedChanges = false;
                that.isSaving = false;
                resolve();
            }
        })
    }

    clearAddress = () => {
        this.data.address.text = null;
        this.data.address.geocode = null;
        this.data.address.unitSuiteType = null;
        this.data.address.unitSuite = null;
        this.data.address.line2 = null;
    }

    clear = () => {
        this.id = null;
        this.data.name = null;   
        this.data.companyName = null;      
        this.data.primaryPhoneNumber = null;
        this.data.secondaryPhoneNumber = null;
        this.data.faxNumber = null;
        this.data.primaryEmailAddress = null;
        this.data.secondaryEmailAddress = null;
        this.data.websiteUrl = null;
        this.data.noteHtml = null;
        this.clearAddress();
        this.isReady = false;
        this.isSaving = false;
        this.hasUnsavedChanges = false;

        if (fn.isFunction(this.cancelContactCreate)) {
            this.cancelContactCreate();
            this.cancelContactCreate = null;
        }
    }
}

decorate(ContactCreate, {
    id: observable,
    data: observable.deep,
    isReady: observable,
    isSaving: observable,
    hasUnsavedChanges: observable,
    initialize: action,
    save: action,
    clearAddress: action,
    clear: action,
})

export default createContext(new ContactCreate());