import React, { useContext, useEffect, useState, useRef, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useObserver } from 'mobx-react-lite';
import FadeIn from 'react-fade-in';
import { toast } from 'react-toastify';
import { GlobalHotKeys } from 'react-hotkeys';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';

import BodyEnd from '../../_shared/BodyEnd';
import ConfirmModal from '../../_shared/ConfirmModalComponent';
import CommunicationModal from '../../communications/CommunicationModal/_index';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';

import AppointmentViewStore from '../../../../stores/AppointmentViewStore';
import AppointmentCreateStore from '../../../../stores/AppointmentCreateStore';
import AppointmentUpdateStore from '../../../../stores/AppointmentUpdateStore';
import CustomerViewStore from '../../../../stores/CustomerViewStore';
import CustomerUpdateStore from '../../../../stores/CustomerUpdateStore';
import NoteSearchStore from '../../../../stores/NoteSearchStore';
import CommunicationStore from '../../../../stores/CommunicationStore';
import AuthStore from '../../../../stores/AuthStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';
import useSignalR from '../../../hooks/useSignalR';

import * as rts from '../../../../constants/routes';
import * as fn from '../../../../utilities/_functions';
import * as ah from '../../../../utilities/addressHelper';
import * as ch from '../../../../utilities/customerHelper';
import * as aph from '../../../../utilities/appointmentHelper';
import * as amh from '../../../../utilities/appointmentCommunicationHelper';
import * as bh from '../../../../utilities/badgeHelper';
import * as ph from '../../../../utilities/personHelper';
import * as sys from '../../../../utilities/systemHelper';
import * as uh from '../../../../utilities/userHelper';
import * as th from '../../../../utilities/timelineHelper';
import * as ach from '../../../../utilities/accessHelper';
import * as oh from '../../../../utilities/operationHelper';

import './ViewAppointment.scss';

moment.locale('en');
momentLocalizer();

function ViewAppointment(props) {
    const isMounted = useRef(true);
    const quickDrawerTimer = useRef(null);
    const communicationModalRef = useRef(null);
    const confirmModalRef = useRef(null);
    const noteSearch = useContext(NoteSearchStore);
    const appointment = useContext(AppointmentViewStore);
    const newAppointment = useContext(AppointmentCreateStore);
    const updateAppointment = useContext(AppointmentUpdateStore);
    const customerView = useContext(CustomerViewStore);
    const customerUpdate = useContext(CustomerUpdateStore);
    const communication = useContext(CommunicationStore);
    const auth = useContext(AuthStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const signalR = useSignalR();
    const [isSaving, setIsSaving] = useState(false);
    const [eligibles, setEligibles] = useState(null);
    const [ineligibles, setIneligibles] = useState(null);
    const [mohReady, setMohReady] = useState(false);
    const [communicationActive, setCommunicationActive] = useState(false);
    let onSuccess, onCancel, onError;

    useEffect(() => {

        signalR.on('Appointment', (updated) => {
            if (updated && appointment.data && appointment.data.id === updated.id) {
                appointment.refresh();
                updateAppointment.refresh();
            }
        });
        signalR.on('Customer', (updated) => {
            if (updated && appointment.data && appointment.data.customerId === updated.id) {
                appointment.refresh();
                updateAppointment.refresh();
            }
        });
        signalR.on('Note', (updated) => {
            if (updated && appointment.data && appointment.data.customerId === updated.customerId) {
                appointment.refresh();
                updateAppointment.refresh();
            }
        });
        signalR.on('Purchase', (updated) => {
            if (updated && appointment.data && appointment.data.customerId === updated.customerId) {
                appointment.refresh();
                updateAppointment.refresh();
            }
        });
        signalR.on('Payment', (updated) => {
            if (updated && appointment.data && appointment.data.customerId === updated.customerId) {
                appointment.refresh();
                updateAppointment.refresh();
            }
        });
        signalR.on('Pretest', (updated) => {
            if (updated && appointment.data && appointment.data.customerId === updated.customerId) {
                appointment.refresh();
                updateAppointment.refresh();
            }
        });

        quickDrawerFocus(props.drawer);

        return () => {
            if (quickDrawerTimer.current) { clearTimeout(quickDrawerTimer.current); quickDrawerTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleChangeAppointment = () => {
        onSuccess = quickDrawer.success1Callback;
        onCancel = quickDrawer.cancel1Callback;
        onError = quickDrawer.error1Callback;

        quickDrawer.deactivateQuickDrawer1()
            .then(() => {
                if (isMounted.current) {
                    quickDrawerTimer.current = setTimeout(() => {
                        quickDrawer.activateQuickDrawer('appointment', 'update', null, handleChangeAppointmentSuccess, handleChangeAppointmentCancel, handleChangeAppointmentError);
                    }, 200)
                }
            })
    }

    const handleChangeAppointmentSuccess = (result) => {
        if (fn.isFunction(onSuccess)) {
            result.data.action = 'updated';
            onSuccess(result);
            onSuccess = null;
            onCancel = null;
            onError = null;
        }
        updateAppointment.clear();
    }

    const handleChangeAppointmentCancel = () => {
        if (fn.isFunction(onCancel)) {
            onCancel();
            onSuccess = null;
            onCancel = null;
            onError = null;
        }
        updateAppointment.clear();
    }

    const handleChangeAppointmentError = () => {
        if (fn.isFunction(onError)) {
            onError();
            onSuccess = null;
            onCancel = null;
            onError = null;
        }
        updateAppointment.clear();
    }

    const handleVerifyEligibility = () => {
        updateAppointment.checkEligibility()
            .then((data) => {
                const eligibleData = data && data.some(e => e.isPublicHealthChecked && e.isEligible) ? data.filter(e => e.isPublicHealthChecked && e.isEligible) : null;
                const ineligibleData = data && data.some(e => !e.isPublicHealthChecked || !e.isEligible) ? data.filter(e => !e.isPublicHealthChecked || !e.isEligible) : null;
                const isMohReady = data && !data.some(e => !e.isMohReady);

                setEligibles(eligibleData);
                setIneligibles(ineligibleData);
                setMohReady(isMohReady);

                updateAppointment.hasUnsavedChanges = true;
                updateAppointment.save();
            })
    }

    const handleUpdateCustomer = () => {
        customerUpdate.initialize(appointment.data.customer.id, false)
        quickDrawer.activateQuickDrawer('customer', 'personal', null, handleUpdateCustomerSuccess, handleUpdateCustomerCancel);
    }

    const handleUpdateCustomerSuccess = (result) => {
        if (result && result.updated) {
            toast.dark(() => <p data-cust-upd>Customer updated.</p>);
        }
        customerUpdate.clear();
    }

    const handleUpdateCustomerCancel = () => {
        customerUpdate.clear();
    }

    const handleSeeNotes = () => {
        if (appointment.data.customer) {
            noteSearch.initialize(appointment.data.customer.id)
                .then(() => {
                    if (isMounted.current) {
                        noteSearch.reference = appointment.data;
                        noteSearch.referenceId = appointment.data ? appointment.data.id : null;
                        noteSearch.referenceType = 'appointment';
                    }
                });
            quickDrawer.activateQuickDrawer('note', 'view', null, handleNoteUpdateSuccess, handleNoteUpdateCancel);
        }
    }

    const handleNoteUpdateSuccess = () => {
        noteSearch.clear();
    }

    const handleNoteUpdateCancel = () => {
        noteSearch.clear();
    }

    const handleSeeAttachments = () => {
        noteSearch.initialize(appointment.data.customer.id);
        quickDrawer.activateQuickDrawer('note', 'attachment', null, handleSeeAttachmentUpdateSuccess, handleSeeAttachmentUpdateCancel);
    }

    const handleSeeAttachmentUpdateSuccess = () => {
        noteSearch.clear();
    }

    const handleSeeAttachmentUpdateCancel = () => {
        noteSearch.clear();
    }

    const handleDeleteAppointment = () => {
        onSuccess = quickDrawer.success1Callback;
        onCancel = quickDrawer.cancel1Callback;
        onError = quickDrawer.error1Callback;

        updateAppointment.initialize(appointment.id);
        quickDrawer.activateQuickDrawer('appointment', 'delete', null, handleDeleteAppointmentSuccess);
    }

    const handleDeleteAppointmentSuccess = () => {
        quickDrawer.deactivateQuickDrawer1();
        if (fn.isFunction(onSuccess)) {
            onSuccess('deleted');
            onSuccess = null;
            onCancel = null;
            onError = null;
        }
        updateAppointment.clear();
    }

    const handleViewInvoices = () => {
        if (isMounted.current) {
            customerView.initialize(appointment.data.customer.id, false)
                .then(() => {
                    if (isMounted.current) {
                        customerView.loadPurchases(true);
                    }
                })

            quickDrawer.activateQuickDrawer('appointment', 'invoices', { customerId: appointment.data.customer.id }, handleViewInvoicesDone, handleViewInvoicesDone);
        }
    }

    const handleViewInvoicesDone = () => {
        customerView.clear();
    }

    const handleAppointmentTimelineChange = (data, timelineItem) => {
        const reminderType = data.isPrebook ? 'AppointmentPrebookReminder' : ((data.isNew || data.isFirstExam) ? 'AppointmentReminderNew' : 'AppointmentReminder');

        if (timelineItem.onClick) {
            const onSuccess = timelineItem.name === 'Reminder' ? () => { handleSendAppointmentCommunication(reminderType) } : null;
            const extraProps = {
                timelineItem: timelineItem,
                appointment: updateAppointment.data,
                customer: updateAppointment.data.customer,
            };
            th.quickDrawerTrigger(timelineItem.onClick, quickDrawer, extraProps, onSuccess);
        } else {
            if (timelineItem.performedDateUtc) {
                handleConfirmAppointmentTimelineShow(timelineItem);
            } else {
                handleAppointmentTimelineCommit(timelineItem);
            }
        }
    }

    const handleConfirmAppointmentTimelineShow = (timelineItem) => {
        confirmModalRef.current.show({
            icon: <i className='fal fa-backspace text-danger mr-2'></i>,
            message: <>Continue to <span className='fw-500'>&nbsp;undo&nbsp;'{timelineItem ? th.format(appointment.data, timelineItem, timelineItem.menuFormat) : ''}'&nbsp;</span>? </>,
            onOption1Click: () => { handleConfirmAppointmentTimelineDelete(timelineItem) },
            onCancel: handleConfirmAppointmentTimelineCancel,
        })
    }

    const handleConfirmAppointmentTimelineDelete = (timelineItem) => {
        handleAppointmentTimelineCommit(timelineItem);
        confirmModalRef.current.close();
    }

    const handleConfirmAppointmentTimelineCancel = () => {
        confirmModalRef.current.close();
    }

    const handleAppointmentTimelineCommit = (timelineItem) => {
        setIsSaving(true);
        updateAppointment.timeline(timelineItem.id)
            .then(() => {
                if (isMounted.current) {
                    toast.dark(() => <p data-appt-upd>Appointment updated.</p>);
                    setIsSaving(false);
                }
            })
    }

    const handleSendAppointmentCommunication = (action) => {
        const isValid = amh.checkIndividualAppointmentIsValid(appointment.data);
        const description = !isValid ?
            <strong data-please-setup-contact className='text-danger'>Please setup contact information and try again.</strong> :
            (
                appointment.data && appointment.data.customer.contactInstructions && appointment.data.customer.contactInstructions.length > 0 ?
                    <><button type='button' className='btn btn-link p-0 fs-lg' onClick={e => { handleSendAppointmentCommunicationContactInstructions(appointment.data.customer.id) }}><strong className='text-danger'>Important:</strong>&nbsp;<span className='text-white'>See communication instructions</span></button></> : null
            );

            confirmModalRef.current.show({
            icon: amh.getIcon(appointment.data, action),
            message: amh.getMessage(appointment.data, action),
            description: description,
            option1ClassName: 'btn btn-appointment shadow-0 bootbox-accept' + (!isValid ? ' d-none' : ''),
            option1Text: 'Email',
            option1Disabled: (!appointment.data || !appointment.data.customer.defaultEmailAddressContact),
            option2ClassName: 'btn btn-secondary shadow-0 bootbox-accept' + (!isValid ? ' d-none' : ''),
            option2Text: 'SMS',
            option2Disabled: (!appointment.data || !appointment.data.customer.defaultPhoneNumberContact),
            cancelText: (isValid ? 'No' : 'OK'),
            onOption1Click: (e) => { handleCommunicationSend(e, 'email', action) },
            onOption2Click: (e) => { handleCommunicationSend(e, 'sms', action) },
            onCancel: handleCommunicationCancel
        })

        if (isMounted.current) {
            setCommunicationActive(true);
        }
    }

    const handleSendAppointmentCommunicationContactInstructions = (customerId) => {
        confirmModalRef.current.close();
        handleContactInstructions(customerId);
    }

    const handleCommunicationSend = (event, type, action) => {
        let defaultContact;

        if (type) {
            switch (type.toLowerCase()) {
                case 'email':
                    defaultContact = appointment.data.customer.defaultEmailAddressContact;
                    break;

                case 'sms':
                    defaultContact = appointment.data.customer.defaultMobileNumberContact;
                    break;

                default:
                    break;
            }
        }

        communication.initialize(
            type,
            action,
            appointment.data.id,
            [],
            appointment.data.customerId,
            defaultContact,
        )
            .then(() => {
                confirmModalRef.current.close();
                communicationModalRef.current.show(type);
            })
    }

    const handleCommunicationCancel = (event) => {
        confirmModalRef.current.close();

        if (isMounted.current) {
            setCommunicationActive(false);
        }
    }

    const handleAppointmentStatusChange = (newStatus) => {
        setIsSaving(true);
        updateAppointment.status(newStatus)
            .then(() => {
                if (isMounted.current) {
                    toast.dark(() => <p data-appt-upd>Appointment updated.</p>);
                    setIsSaving(false);
                }
            })
    }

    const handleBookAnotherAppointment = (event) => {
        newAppointment.stage(appointment.data.customer);

        if (fn.isFunction(props.onCancel)) {
            props.onCancel();
        }
    }

    const handleCommunicationSent = (event, result) => {
        if (result && result.type) {
            toast.dark(() => <p data-comm-sent><span className='tt-capitalize'>{result.type}</span> sent.</p>);
        }
        else {
            toast.dark(() => <p data-comm-sent>Communication sent.</p>);
        }

        communicationModalRef.current.close();
    }

    const handleCommunicationClose = () => {
        if (isMounted.current) {
            setCommunicationActive(false);
        }
    }

    const handleContactInstructions = (customerId) => {
        noteSearch.initialize(customerId, null, 'contactInstruction', false, true);
        quickDrawer.activateQuickDrawer('note', 'view', { title: 'Communication Instructions', icon: 'fal fa-comment-alt-exclamation', renderFilters: false });
    }

    const renderAppointmentDateTime = () => {
        if (!appointment.data || !appointment.data.start) return null;

        const start = moment(appointment.data.start);
        const end = appointment.data.end ? moment(appointment.data.end) : null;
        const hasEndTime = !!end;
        const weekday = start.format('dddd');
        const day = start.format('D');
        const ordinal = start.format('Do').replace(day, '');
        const dateHtml = `${start.format('MMMM D')}<sup>${ordinal}</sup>${((start.year() !== moment().year()) ? `, ${start.format('YYYY')}` : '')}`;
        const startTimeHtml = `${start.format('h:mm')}${(!hasEndTime || start.format('a') !== end.format('a') ? ` ${start.format('a')}` : '')}`;
        const endTimeHtml = hasEndTime ? `${end.format('h:mm')} ${end.format('a')}` : '';

        return <div>
            <div className='d-flex'>
                <div className='flex-1'>
                    <ul className='list-inline m-0'>
                        <li className='list-inline-item m-0'>
                            <div className='text'>
                                <small className='weekday'>{weekday}</small>
                                <span className='date' dangerouslySetInnerHTML={{ __html: dateHtml }}></span>
                            </div>
                        </li>
                        <li className='list-inline-item my-0 mx-1'><small>@</small></li>
                        <li className='list-inline-item m-0'>
                            <div className='text'>
                                <span className='time' dangerouslySetInnerHTML={{ __html: startTimeHtml }}></span>
                            </div>
                        </li>
                        <li className='list-inline-item my-0 mx-1'><small>to</small></li>
                        <li className='list-inline-item m-0'>
                            <div className='text'>
                                <span className='time' dangerouslySetInnerHTML={{ __html: endTimeHtml }}></span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    }

    const renderResource = () => {
        if (!appointment.data) return;

        const resource = appointment.data.resource;

        return <div
            className='profile-wrapper pr-0'
        >
            <div className='profile'>
                <span
                    className={`profile-image rounded-circle d-block fw-500` + (resource && !resource.profilePictureUri ? ` profile-initials bg-color${resource.color}-500` : '')}
                    style={resource && resource.profilePictureUri ? {
                        backgroundImage: `url(${resource.profilePictureUri})`,
                        backgroundSize: 'cover',
                    } : null}
                    title={resource ? resource.fullName : 'System'}>
                    {resource && !resource.profilePictureUri ? <div className='d-initials'>{resource.initials}</div> : null}
                </span>
            </div>
            <div className='description flex-1'>
                <div className='name'>{resource.fullName}</div>
                <div className='services'>
                    {bh.renderAppointmentNew(appointment.data, 'p-1 mr-1')}
                    {bh.renderAppointmentFirstExam(appointment.data, 'p-1 mr-1')}
                    {bh.renderAppointmentPrebook(appointment.data, 'mr-1')}
                    {
                        appointment.data.services.map((s, si) => {
                            return <Fragment
                                key={`service_${si}`}
                            >
                                {bh.renderServiceCode(s, 'p-1 mr-1', (!s.isEligible || !aph.isEndState(appointment.data)))}
                            </Fragment>
                        })
                    }
                    <div>
                        {bh.renderAppointmentRescheduledByOffice(appointment.data, 'p-1 mr-1 mt-1')}
                        {bh.renderAppointmentRescheduledByCustomer(appointment.data, 'p-1 mr-1 mt-1')}
                    </div>
                </div>
            </div>
            {
                appointment.data.services.some(s => s.isEligibilityCheckRequired) && getIsEditable() ?
                    <div className='fs-sm pl-2'>
                        <div className='mt-4'>
                            <button
                                type='button'
                                className='btn btn-icon line-height-1 mr-n2'
                                title={`Verify eligibility`}
                                onClick={handleVerifyEligibility}
                            >
                                <i className='fal fa-file-search fs-lg'></i>
                            </button>
                        </div>
                    </div> : null
            }
        </div>
    }

    const renderCustomer = () => {
        if (!appointment.data || !appointment.data.customer) return;

        const { customer, primaryContact } = appointment.data;
        const isSameAsPrimaryContact = primaryContact && primaryContact.id === customer.id

        return <div
            className='profile-wrapper pr-0'
        >
            <div className='profile'>
                <span
                    className={`profile-image profile-initials rounded-circle d-flex text-white ${ch.getProfileColor(customer)} fw-500`}
                    title={ph.getFullName(customer)}
                >
                    {customer.initials}
                </span>
            </div>
            <div className='description flex-1'>
                {bh.renderSpokenLanguage(customer, 'fs-sm')}
                <Link
                    to={`${rts.Customers.Home}/${customer.id}`}
                    className='name text-gray-700 text-info-hover'
                    onClick={quickDrawer.deactivateAll}
                >
                    {ph.getFullName(customer, true)}
                    {
                        customer.dateOfBirth || customer.sex || customer.gender || customer.pronoun ?
                            <small className='text-nowrap ml-2'>({`${ph.getAge(customer.dateOfBirth, moment(appointment.data.start))} ${ph.getSexGenderPronounDisplay(customer)}`.trim()})</small> : null
                    }
                </Link>
                {
                    customer.dateOfBirth ?
                        <div className='info text-gray-700'><span className='text-nowrap mr-1'><strong className='mr-1'>DOB:</strong>{sys.getFormattedLongDate(customer.dateOfBirth)}</span></div> : null
                }
                {
                    customer.patientProfile && customer.patientProfile.healthCardNumber ?
                        <div className='info text-gray-700'><span className='text-nowrap mr-1'><strong className='mr-1'>HC #:</strong>{ph.formatHealthCard(customer.patientProfile.healthCardNumber)}</span><small className={'text-nowrap'}>(Exp. {fn.formatDate(customer.patientProfile.healthCardExpiryDate, 'MMM Do, YYYY')})</small></div> : null
                }
                {
                    customer.address && customer.address.country ?
                        <div className='info'>{ah.getAddressHtml(customer.address)}</div> : null
                }
                {
                    isSameAsPrimaryContact ?
                        <>
                            {
                                customer.emailAddress ?
                                    <div className='info'>
                                        <a
                                            href={`mailto:${customer.emailAddress}`}
                                        >{customer.emailAddress}
                                        </a>
                                    </div> : null
                            }
                            {
                                customer.phoneNumber ?
                                    <div className='info'>
                                        <a
                                            href={`tel:${customer.phoneNumber}`}
                                        >{sys.getFormattedPhoneNumber(customer.phoneNumber)}
                                        </a>
                                    </div> : null
                            }
                            {
                                customer.contactInstructions && customer.contactInstructions.length > 0 ?
                                    <div className='info'>
                                        <button
                                            type='button'
                                            className='btn btn-link text-danger text-danger-hover fw-500 p-0'
                                            onClick={e => { handleContactInstructions(customer.id) }}
                                        >See communication instructions</button>
                                    </div> : null
                            }
                        </> : <>
                            <div className='mt-2 border-left border-3 border-gray-300 pl-3 py-1'>
                                <small className='text-primary-400 fs-75 text-uppercase d-block'>Primary Contact</small>
                                {
                                    primaryContact.id ?
                                        <Link
                                            to={`${rts.Customers.Home}/${primaryContact.id}`}
                                            className='d-block fs-lg text-gray-700 text-info-hover'
                                            onClick={quickDrawer.deactivateAll}
                                        >
                                            <div>{bh.renderSpokenLanguage(primaryContact, 'fs-sm')}</div>
                                            {ph.getPreferredFirstLastName(primaryContact)}
                                            {
                                                primaryContact.relationship ?
                                                    <small className='ml-1'>(<span className='fw-500 fs-90 text-success-700'>{primaryContact.relationship}</span>)</small> : null
                                            }
                                        </Link> :
                                        <div className='fs-lg text-gray-700 '>{ph.getPreferredFirstLastName(primaryContact)}
                                            {
                                                primaryContact.relationship ?
                                                    <small className='ml-1'>(<span className='fw-500 fs-90 text-success-700'>{primaryContact.relationship}</span>)</small> : null
                                            }
                                        </div>
                                }
                                {
                                    primaryContact.emailAddress ?
                                        <div className='info'>
                                            <a
                                                href={`mailto:${primaryContact.emailAddress}`}
                                            >{primaryContact.emailAddress}
                                            </a>
                                        </div> : null
                                }
                                {
                                    primaryContact.phoneNumber ?
                                        <div className='info'>
                                            <a
                                                href={`tel:${primaryContact.phoneNumber}`}
                                            >{sys.getFormattedPhoneNumber(primaryContact.phoneNumber)}
                                            </a>
                                        </div> : null
                                }
                                {
                                    primaryContact.contactInstructions && primaryContact.contactInstructions.length > 0 ?
                                        <div className='info'>
                                            <button
                                                type='button'
                                                className='btn btn-link text-danger text-danger-hover fw-500 p-0'
                                                onClick={e => { handleContactInstructions(primaryContact.id) }}
                                            >See communication instructions</button>
                                        </div> : null
                                }
                            </div>
                        </>
                }
            </div>
            <div className='fs-sm pl-2'>
                <div className='mb-2'>
                    <button
                        type='button'
                        className='btn btn-icon line-height-1 mr-n2'
                        title={`Edit ${ph.getFullName(customer, true)}`}
                        onClick={handleUpdateCustomer}
                    >
                        <i className='fal fa-pen fs-lg'></i>
                    </button>
                </div>
                <div>
                    <button
                        type='button'
                        className='btn btn-icon line-height-1 mr-n2'
                        title='Book new appointment'
                        onClick={handleBookAnotherAppointment}
                    >
                        <i className='fal fa-calendar-plus fs-lg'></i>
                    </button>
                </div>
            </div>
        </div>
    }

    const renderAppointmentTimeline = () => {
        const isEditable = getIsEditable();
        const timeline = isEditable ? appointment.data.timeline.filter(t => th.isEnabled(appointment.data, t.restriction, t.id, t.id, t.displayOrder) && (t.performedDateUtc || !t.isAutomated)) : appointment.data.timeline.filter(t => t.performedDateUtc);

        return timeline && timeline.length > 0 ?
            timeline.sort((a, b) => a.displayOrder - b.displayOrder).map((t, ti) => {
                const started = (appointment.data.timeline.some(l => l.displayOrder > t.displayOrder && l.performedDateUtc) || appointment.data.status === 'Completed' || appointment.data.status === 'NoShow');

                return <div
                    key={`timeline_${ti}`}
                    className={
                        'timeline-item' +
                        ((isEditable && !t.performedDateUtc) ? ' incomplete' : '') +
                        (started ? ' started' : '')}
                >
                    <div className='timeline-left'>
                        <span
                            className={'timeline-icon has-icon'}
                            title={th.format(appointment.data, t, t.tooltipFormat)}
                            style={
                                (isEditable && !t.performedDateUtc) ? null : {
                                    borderColor: t.colorHexValue,
                                    color: t.colorHexValue
                                }
                            }
                        >
                            <i className={`fal fa-${t.icon}`}></i>
                        </span>
                    </div>
                    <div className='timeline-content'>
                        <div className='d-flex'>
                            {
                                (isEditable && (t.onClick || !t.performedDateUtc)) ?
                                    <>
                                        <div className='flex-1'>
                                            <button
                                                type='button'
                                                className='btn btn-link p-0'
                                                disabled={isSaving}
                                                title={(t.performedDateUtc ? th.format(appointment.data, t, t.tooltipFormat) : null)}
                                                onClick={() => handleAppointmentTimelineChange(appointment.data, t)}
                                            >
                                                <span dangerouslySetInnerHTML={{ __html: th.format(appointment.data, t, (t.onClick && t.performedDateUtc ? t.performedHtmlFormat : t.menuFormat), true) }}></span>
                                            </button>
                                        </div>
                                    </> :
                                    <>
                                        <span
                                            title={(t.performedDateUtc ? th.format(appointment.data, t, t.tooltipFormat) : null)}
                                            dangerouslySetInnerHTML={{ __html: th.format(appointment.data, t, t.performedHtmlFormat, true) }}
                                        >
                                        </span>
                                        {
                                            (isEditable && !t.isAutomated)
                                                ? <button
                                                    type='button'
                                                    className='btn btn-icon btn-close mr-n2'
                                                    title={(t.performedDateUtc ? th.format(appointment.data, t, t.tooltipFormat) : null)}
                                                    onClick={() => handleAppointmentTimelineChange(appointment.data, t)}
                                                >
                                                    <i className='fal fa-times'></i>
                                                </button> : null
                                        }
                                    </>
                            }
                        </div>
                    </div>
                </div>
            }) : null
    }

    const renderIconButtons = () => {
        return <>
            <button
                type='button'
                className='btn btn-icon'
                onClick={handleSeeNotes}
                title='Notes'
            >
                <i className='fal fa-comment-alt-lines'></i>
                {
                    appointment.data && appointment.data.notes && appointment.data.notes.length > 0 ?
                        <span className={`badge badge-icon`}>{appointment.data.notes.length}</span> : null
                }
            </button>
            <button
                type='button'
                className='btn btn-icon'
                onClick={handleSeeAttachments}
                title='Attachments'
            >
                <i className='fal fa-paperclip'></i>
            </button>
            <button
                type='button'
                className='btn btn-icon'
                onClick={handleViewInvoices}
                title='Invoice(s)'
            >
                <i className={oh.getIcon('invoice')}></i>
            </button>
        </>
    }

    const getIsEditable = () => {
        return (auth.currentUser && ach.hasFullAccess(auth.currentUser.id)) || (appointment.data && (appointment.data.status !== 'Completed' && appointment.data.status !== 'NoShow' && moment(appointment.data.start).startOf('day').diff(moment().startOf('day')) >= 0));
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) && !communicationActive && !communication.isReady ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                props.onCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form>
            <fieldset>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('appointment')}
                        action='View'
                        category='Appointment'
                        className='appointments'
                        onCancel={props.onCancel}
                    />
                    <div className='quick-drawer-body'>
                        {
                            appointment.isReady ?
                                <FadeIn>
                                    <div className='view-appointment body-content'>
                                        {
                                            appointment.data && appointment.data.customer && appointment.data.customer.hasOutstandingBalance ?
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='alert alert-danger mb-0' role='alert'>
                                                                <strong className='d-block mb-2'>Important</strong>
                                                                <ul className='pl-3 mb-0'>
                                                                    <li>{ph.getPreferredFirstName(appointment.data.customer)} an outstanding balance on file.</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section> : null
                                        }
                                        {
                                            eligibles ?
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            {
                                                                mohReady ?
                                                                    <div className={'alert alert-info p-3 mb-0'} role='alert'>
                                                                        <strong className='d-block mb-2'>Information</strong>
                                                                        <ul className='pl-3 mb-0'>
                                                                            {
                                                                                eligibles.map((i, ii) => {
                                                                                    return <li key={`view_appointment_eligible_${ii}`}>{bh.renderServiceCode(i.service, 'float-left mr-1')} Eligibility confirmed.</li>
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div> :
                                                                    <div className={'alert alert-warning p-3 mb-0'} role='alert'>
                                                                        <strong className='d-block mb-2'>Warning</strong>
                                                                        <ul className='pl-3 mb-0'>
                                                                            <li><strong className='text-warning-900'>{auth.currentTenant.publicInsuranceUnitId} verification has not been setup.</strong></li>
                                                                            {
                                                                                eligibles.filter(e => e.isAgeChecked).map((i, ii) => {
                                                                                    return <li key={`view_appointment_eligible_${ii}`}>{bh.renderServiceCode(i.service, 'float-left mr-1')} Age eligibility confirmed.</li>
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </section> : null
                                        }
                                        {
                                            ineligibles ?
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='alert alert-warning p-3 mb-0' role='alert'>
                                                                <strong className='d-block mb-2'>Warning</strong>
                                                                <ul className='pl-3 mb-0'>
                                                                    {
                                                                        !mohReady ? <li><strong className='text-warning-900'>{auth.currentTenant.publicInsuranceUnitId} verification has not been setup.</strong></li> : null
                                                                    }
                                                                    {
                                                                        ineligibles.map((i, ii) => {
                                                                            return <li key={`view_appointment_ineligible_${ii}`}>{bh.renderServiceCode(i.service, 'float-left mr-1')} {i.earliestEligibleDate ? <>{i.ineligibilityCode ? `(Error: ${i.ineligibilityCode}) ` : ''}Not eligible until <span className='tt-underline'>{moment(i.earliestEligibleDate).format('YYYY-MM-DD')}</span>.</> : (i.ineligibilityReason ? `${(!!i.ineligibilityCode ? `(Error: ${i.ineligibilityCode}) ` : '')}${i.ineligibilityReason}` : 'Cannot verify.  Reason unknown.')}</li>
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section> : null
                                        }
                                        <section className='date-time'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    {renderAppointmentDateTime()}
                                                </div>
                                            </div>
                                        </section>
                                        {
                                            appointment.data && appointment.data.isRepeating ?
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <label><small>Repeating</small></label>
                                                            <div className='fs-lg'>
                                                                Every {moment(appointment.data.repeatUntil).format('dddd')} until {fn.formatDate(appointment.data.repeatUntil, 'MMMM D')}<sup>{fn.formatDate(appointment.data.repeatUntil, 'Do').replace(fn.formatDate(appointment.data.repeatUntil, 'D'), '')}</sup>{(fn.formatDate(appointment.data.repeatUntil, 'YYYY') !== fn.formatDate(appointment.data.start, 'YYYY') ? `, ${fn.formatDate(appointment.data.repeatUntil, 'YYYY')}` : '')}
                                                                <br />
                                                                <small>(# {appointment.data.repeatIndex} of {appointment.data.repeatTotal})</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section> : null
                                        }
                                        <section className='resource'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0'>
                                                        <label><small>With</small></label>
                                                        {renderResource()}
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section className='customer'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0'>
                                                        <label><small>For</small></label>
                                                        {renderCustomer()}
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        {
                                            appointment.data ?
                                                <section className='timeline-status'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className={'timeline' + ((appointment.data.status === 'Completed' ? ' completed' : '')) + ((appointment.data.status === 'NoShow' ? ' no-show' : ''))}>
                                                                <div className={'timeline-item' + ((appointment.data.timeline.some(t => t.performedDateUtc) || appointment.data.status === 'Completed' || appointment.data.status === 'NoShow') ? ' started' : '')}>
                                                                    <div className='timeline-left'>
                                                                        <span className='timeline-icon' title={`${(appointment.data.isImported ? 'Imported' : 'Booked')} on ${fn.formatFullDateWithOrWithoutYear(moment.utc(appointment.data.createdDateUtc).local(), moment(appointment.data.start))}\n${uh.getDisplayShortNameById(appointment.data.createdById, appointment.data.createdBy)}`}></span>
                                                                    </div>
                                                                    <div className='timeline-content'>
                                                                        <div className='d-flex'>
                                                                            <span className='flex-1'>
                                                                                {
                                                                                    appointment.data.status === 'Booked' && getIsEditable() ?
                                                                                        <button
                                                                                            type='button'
                                                                                            className='btn btn-link btn-sm p-0'
                                                                                            onClick={() => { handleSendAppointmentCommunication(((appointment.data.isNew || appointment.data.isFirstExam) ? 'AppointmentBookedNew' : 'AppointmentBooked')) }}
                                                                                            title={`${(appointment.data.isImported ? 'Imported' : 'Booked')} on ${th.format(appointment.data, { performedDateUtc: appointment.data.createdDateUtc, performedBy: appointment.data.createdBy, performedById: appointment.data.createdById }, '{PerformedDate} by {PerformedBy}', false)}`}
                                                                                        >
                                                                                            <>{(appointment.data.isImported ? 'Imported' : 'Booked')}</> on <strong><span dangerouslySetInnerHTML={{ __html: th.format(appointment.data, { performedDateUtc: appointment.data.createdDateUtc, performedBy: appointment.data.createdBy, performedById: appointment.data.createdById }, '{PerformedDate}', true) }}></span></strong>
                                                                                        </button> :
                                                                                        <span
                                                                                            title={`${(appointment.data.isImported ? 'Imported' : 'Booked')} on ${th.format(appointment.data, { performedDateUtc: appointment.data.createdDateUtc, performedBy: appointment.data.createdBy, performedById: appointment.data.createdById }, '{PerformedDate} by {PerformedBy}', false)}`}
                                                                                        >
                                                                                            <>{(appointment.data.isImported ? 'Imported' : 'Booked')}</> on <strong><span dangerouslySetInnerHTML={{ __html: th.format(appointment.data, { performedDateUtc: appointment.data.createdDateUtc, performedBy: appointment.data.createdBy, performedById: appointment.data.createdById }, '{PerformedDate}', true) }}></span></strong>
                                                                                        </span>
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    updateAppointment.isReady ?
                                                                        <>{renderAppointmentTimeline()}</> : null
                                                                }
                                                                {
                                                                    updateAppointment.isReady ?
                                                                        <>
                                                                            {
                                                                                appointment.data.status === 'Booked' || appointment.data.status === 'Completed' ?
                                                                                    <div className={'timeline-item' + (appointment.data.status === 'Completed' ? '' : ' incomplete')}>
                                                                                        <div className='timeline-left'>
                                                                                            <span
                                                                                                className={'timeline-icon has-icon' + (appointment.data.status === 'Completed' ? ' text-success-600 border-success-600' : '')}
                                                                                            >
                                                                                                <i className={`fas fa-check`}></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className='timeline-content'>
                                                                                            {
                                                                                                appointment.data.status === 'Completed' ?
                                                                                                    <>
                                                                                                        <span
                                                                                                            className='fw-500 text-success-600'
                                                                                                            title={`Completed at ${th.format(appointment.data, { performedDateUtc: appointment.data.completedDateUtc, performedBy: appointment.data.completedBy, performedById: appointment.data.completedById }, '{PerformedTime} by {PerformedBy}', false)}`}
                                                                                                        >
                                                                                                            Appointment completed
                                                                                                        </span>
                                                                                                        {
                                                                                                            ach.hasFullAccess(auth.currentUser.id) || !appointment.data.completedDateUtc || moment.utc(appointment.data.completedDateUtc).local().startOf('day').isSame(moment().startOf('day'))
                                                                                                                ? <button
                                                                                                                    type='button'
                                                                                                                    className='btn btn-icon btn-close mr-n2'
                                                                                                                    onClick={() => handleAppointmentStatusChange('Booked')}
                                                                                                                >
                                                                                                                    <i className='fal fa-times'></i>
                                                                                                                </button> : null
                                                                                                        }
                                                                                                    </> :
                                                                                                    <>
                                                                                                        {
                                                                                                            ach.hasFullAccess(auth.currentUser.id) ?
                                                                                                                <button
                                                                                                                    type='button'
                                                                                                                    className='btn btn-link p-0'
                                                                                                                    disabled={isSaving}
                                                                                                                    onClick={() => handleAppointmentStatusChange('Completed')}
                                                                                                                >
                                                                                                                    Mark as Completed
                                                                                                                </button> :
                                                                                                                <button
                                                                                                                    type='button'
                                                                                                                    className='btn btn-link p-0'
                                                                                                                    disabled={isSaving || !th.isEnabled(appointment.data, 'AppointmentDayOnly')}
                                                                                                                    onClick={() => handleAppointmentStatusChange('Completed')}
                                                                                                                >
                                                                                                                    Mark as Completed
                                                                                                                </button>
                                                                                                        }
                                                                                                    </>
                                                                                            }
                                                                                        </div>
                                                                                    </div> : null
                                                                            }
                                                                            {
                                                                                appointment.data.status === 'Booked' || appointment.data.status === 'NoShow' ?
                                                                                    <div className={'timeline-item' + (appointment.data.status === 'NoShow' ? '' : ' incomplete')}>
                                                                                        <div className='timeline-left'>
                                                                                            <span
                                                                                                className={'timeline-icon has-icon' + (appointment.data.status === 'NoShow' ? ' text-danger border-danger' : '')}
                                                                                            >
                                                                                                <i className={`fas fa-exclamation`}></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className='timeline-content'>
                                                                                            {
                                                                                                appointment.data.status === 'NoShow' ?
                                                                                                    <>
                                                                                                        <span
                                                                                                            className='fw-500 text-danger'
                                                                                                            title={`Marked as No Show at ${th.format(appointment.data, { performedDateUtc: appointment.data.noShowDateUtc, performedBy: appointment.data.noShowBy, performedById: appointment.data.noShowById }, '{PerformedTime} by {PerformedBy}', false)}`}
                                                                                                        >
                                                                                                            Customer did not show
                                                                                                        </span>
                                                                                                        {
                                                                                                            ach.hasFullAccess(auth.currentUser.id) || !appointment.data.noShowDateUtc || moment.utc(appointment.data.noShowDateUtc).local().startOf('day').isSame(moment().startOf('day'))
                                                                                                                ? <button
                                                                                                                    type='button'
                                                                                                                    className='btn btn-icon btn-close mr-n2'
                                                                                                                    onClick={() => handleAppointmentStatusChange('Booked')}
                                                                                                                >
                                                                                                                    <i className='fal fa-times'></i>
                                                                                                                </button> : null
                                                                                                        }
                                                                                                    </> :
                                                                                                    <>
                                                                                                        {
                                                                                                            ach.hasFullAccess(auth.currentUser.id) ?
                                                                                                                <button
                                                                                                                    type='button'
                                                                                                                    className='btn btn-link p-0'
                                                                                                                    disabled={isSaving}
                                                                                                                    onClick={() => handleAppointmentStatusChange('NoShow')}
                                                                                                                >
                                                                                                                    Mark as No Show
                                                                                                                </button> :
                                                                                                                <button
                                                                                                                    type='button'
                                                                                                                    className='btn btn-link p-0'
                                                                                                                    disabled={isSaving || !th.isEnabled(appointment.data, 'AppointmentDayOnly')}
                                                                                                                    onClick={() => handleAppointmentStatusChange('NoShow')}
                                                                                                                >
                                                                                                                    Mark as No Show
                                                                                                                </button>
                                                                                                        }
                                                                                                    </>
                                                                                            }
                                                                                        </div>
                                                                                    </div> : null
                                                                            }
                                                                        </> : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section> : null
                                        }
                                    </div>
                                </FadeIn> : renderQuickDrawerLoading()
                        }
                    </div>
                    <div className='quick-drawer-action pl-3'>
                        <div className='row'>
                            <div className='col-5'>
                                {renderIconButtons()}
                            </div>
                            <div className='col-7'>
                                <div className='float-right'>
                                    {
                                        getIsEditable() && !aph.isEndState(appointment.data) ?
                                            <>
                                                <button
                                                    type='button'
                                                    className='btn btn-danger mr-2'
                                                    onClick={handleDeleteAppointment}
                                                >Delete</button>
                                                <button
                                                    type='button'
                                                    className='btn btn-primary'
                                                    onClick={handleChangeAppointment}
                                                >Change</button>
                                            </> :
                                            <>
                                                <button
                                                    type='button'
                                                    className='btn btn-link btn-cancel'
                                                    onClick={props.onCancel}
                                                >Cancel</button>
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
        <BodyEnd>
            <ConfirmModal ref={confirmModalRef} />
            <CommunicationModal ref={communicationModalRef} onSent={handleCommunicationSent} onClose={handleCommunicationClose} />
        </BodyEnd>
    </>)
}

export default ViewAppointment;