import React, { useEffect, useContext, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { GlobalHotKeys } from 'react-hotkeys';
import { useObserver } from 'mobx-react-lite';

import { quickDrawerFocus } from '../../../../_shared/QuickDrawer';
import QuickDrawerHeader from '../../../../_shared/QuickDrawerHeader';
import RichTextEditor from '../../../../_shared/RichTextEditor';

import MedicalReportTemplateCreateStore from '../../../../../../stores/MedicalReportTemplateCreateStore';
import QuickDrawerStore from '../../../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../../../constants/errorMessages';
import * as fn from '../../../../../../utilities/_functions';
import * as oh from '../../../../../../utilities/operationHelper';

import './NewMedicalReportTemplate.scss';

function NewMedicalReportTemplate(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const medicalReportTemplate = useContext(MedicalReportTemplateCreateStore);
    const quickDrawer = useContext(QuickDrawerStore);

    useEffect(() => {
        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100)
        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (medicalReportTemplate.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = event => {
        event.preventDefault();
        medicalReportTemplate.save()
            .then(data => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, data);
                    }
                }
            })
    }

    const handleNameChange = event => {
        medicalReportTemplate.data.name = event.target.value;
        medicalReportTemplate.hasUnsavedChanges = true;
    }

    const handleDescriptionChange = content => {
        const html = content;
        medicalReportTemplate.data.descriptionHtml = ((html === '<p><br></p>') ? null : html);
        medicalReportTemplate.hasUnsavedChanges = true;
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form onSubmit={handleSubmit}>
            <fieldset disabled={medicalReportTemplate.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('medical-report-template', 'default')}
                        action='New'
                        category='Medical Report Template'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        {
                            medicalReportTemplate.isReady ?
                                <FadeIn>
                                    <div className='new-medical-report-template body-content'>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label className='required' htmlFor='new-medical-report-template-name'><small>Name</small></label>
                                                        <input
                                                            id='new-medical-report-template-name'
                                                            type='text'
                                                            maxLength={100}
                                                            className='form-control'
                                                            autoComplete={'off'}
                                                            value={medicalReportTemplate.data.name ? medicalReportTemplate.data.name : ''}
                                                            onChange={handleNameChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label htmlFor='new-medical-report-template-description'><small>Description</small></label>
                                                        <RichTextEditor
                                                            mode='none'
                                                            disableTab={true}
                                                            value={medicalReportTemplate.data.descriptionHtml ? medicalReportTemplate.data.descriptionHtml : ''}
                                                            onChange={handleDescriptionChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </FadeIn> : null
                        }
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-4'></div>
                            <div className='col-8'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>)
}

export default NewMedicalReportTemplate;