import React, { useEffect, useContext, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { Observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import { GlobalHotKeys } from 'react-hotkeys';
import moment from 'moment';

import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';
import RichTextEditor from '../../_shared/RichTextEditor';

import LinkedWorkOrderSelectStore from '../../../../stores/LinkedWorkOrderSelectStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as uh from '../../../../utilities/userHelper';
import * as bh from '../../../../utilities/badgeHelper';
import * as oh from '../../../../utilities/operationHelper';

import './LinkWorkOrders.scss';

function LinkWorkOrders(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const linkWorkOrders = useContext(LinkedWorkOrderSelectStore);
    const quickDrawer = useContext(QuickDrawerStore);

    useEffect(() => {
        quickDrawerFocus(props.drawer);
        return () => {
            isMounted.current = false;
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (linkWorkOrders.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            linkWorkOrders.save()
                .then(data => {
                    if (isMounted.current) {
                        if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                            props.onSuccess(event, data);
                        }
                    }
                })
        }
    }

    const handleSelectLinkWorkOrder = event => {
        const id = event.target.value;
        const selectedIds = [...toJS(linkWorkOrders.data.selectedIds)];
        const index = selectedIds.findIndex(j => j === id);

        if (index < 0) {
            selectedIds.push(id);
        }
        else {
            selectedIds.splice(index, 1);
        }

        linkWorkOrders.data.selectedIds = selectedIds;
        linkWorkOrders.hasUnsavedChanges = true;
    }

    const handleReasonChange = (content, delta, source, editor) => {
        const reason = editor.getText().replace(/(\r\n|\n|\r)/g, ' ').trim();
        const reasonHtml = (content === '<p><br></p>') ? null : content;

        linkWorkOrders.data.reason = reason;
        linkWorkOrders.data.reasonHtml = reasonHtml;
        linkWorkOrders.hasUnsavedChanges = true;
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <Observer>{() =>
                <fieldset disabled={linkWorkOrders.isSaving}>
                    <div className='quick-drawer'>
                        <QuickDrawerHeader
                            drawer={props.drawer}
                            icon={oh.getIcon('work-order', 'link')}
                            action='Link'
                            category='Work Orders'
                            className='orders'
                            onCancel={handleCancel}
                        />
                        <div className='quick-drawer-body'>
                            {
                                linkWorkOrders.isReady ?
                                    <FadeIn>
                                        <div className='link-orders'>
                                            <div className='body-content p-0'>
                                                {
                                                    linkWorkOrders.dataSets && linkWorkOrders.dataSets.length > 0 ?
                                                        <>
                                                            <section className='orders'>
                                                                <div className='row'>
                                                                    <div className='col-12'>
                                                                        <div className='form-group mb-0 validate validate-required'>
                                                                            <label className='required'><small>Select all order(s) to link</small></label>
                                                                            <Observer>{() => <>
                                                                                {
                                                                                    linkWorkOrders.dataSets && linkWorkOrders.dataSets.length > 0 ?
                                                                                        linkWorkOrders.dataSets.map((d, di) => {
                                                                                            return d.items && d.items.length > 0 ?
                                                                                                <div
                                                                                                    key={`link-order-${di}`}
                                                                                                    className='custom-control custom-checkbox mb-4'
                                                                                                >
                                                                                                    <input
                                                                                                        id={`link-order-${di}`}
                                                                                                        type='checkbox'
                                                                                                        name='linkWorkOrder'
                                                                                                        className='custom-control-input'
                                                                                                        value={d.id}
                                                                                                        checked={linkWorkOrders.data.selectedIds.some(s => s === d.id)}
                                                                                                        onChange={handleSelectLinkWorkOrder}
                                                                                                    />
                                                                                                    <label
                                                                                                        htmlFor={`link-order-${di}`}
                                                                                                        className='custom-control-label d-block'
                                                                                                    >
                                                                                                        <span className='d-flex flex-row'>
                                                                                                            <span className='fs-sm d-block text-gray-700'>Work Order # {d.number}</span>
                                                                                                            <span className='ml-auto'>{bh.renderWorkOrderRedo(d, 'p-1 mr-1')}{bh.renderWorkOrderStatus(d, 'p-1')}</span>
                                                                                                        </span>
                                                                                                        <span className='fs-md fw-500 d-block text-gray-700 text-truncate text-truncate-lg'>
                                                                                                            {
                                                                                                                d.items[0].transactionItems && d.items[0].transactionItems.length > 1 ?
                                                                                                                    <span className='mr-1'>{d.items[0].transactionItems.length}x</span> : null
                                                                                                            }
                                                                                                            {d.items[0].descriptor}
                                                                                                        </span>
                                                                                                        {
                                                                                                            d.items.length > 1 ?
                                                                                                                d.items.slice(1).map((i, ii) => {
                                                                                                                    return <span
                                                                                                                        key={`link-order-${di}-${ii}`}
                                                                                                                        className='fs-xs d-flex'>
                                                                                                                        {
                                                                                                                            i.transactionItems && i.transactionItems.length > 1 ?
                                                                                                                                <span
                                                                                                                                    className='mr-1'
                                                                                                                                >
                                                                                                                                    {i.transactionItems.length}x
                                                                                                                                </span> : null
                                                                                                                        }
                                                                                                                        {i.descriptor}
                                                                                                                    </span>
                                                                                                                }) : null
                                                                                                        }
                                                                                                        <span className='text-muted fs-xs d-block'>
                                                                                                            By <span className='text-gray-700 fw-500'>{uh.getDisplayShortNameById(d.createdById, d.createdBy)}</span> on {fn.formatFullDateWithOrWithoutYear(moment.utc(d.createdDateUtc).local())}
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </div> : <></>
                                                                                        }) : null
                                                                                }
                                                                            </>
                                                                            }</Observer>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </section>
                                                            <section className='reason'>
                                                                <div className='row'>
                                                                    <div className='col-12'>
                                                                        <div className='form-group mb-0 validate validate-required'>
                                                                            <label className='required'><small>Reason</small></label>
                                                                            <Observer>{() =>
                                                                                <RichTextEditor
                                                                                    mode='none'
                                                                                    value={linkWorkOrders.data.reasonHtml}
                                                                                    disableTab={true}
                                                                                    readOnly={linkWorkOrders.isSaving}
                                                                                    onChange={handleReasonChange}
                                                                                />
                                                                            }</Observer>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </section>
                                                        </> :
                                                        <>
                                                            <section className='orders'>
                                                                <div className='row'>
                                                                    <div className='col-12'>
                                                                        <p className='text-muted'>
                                                                            No other order found
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </section>
                                                        </>
                                                }
                                            </div>
                                        </div>
                                    </FadeIn> : renderQuickDrawerLoading()
                            }
                        </div>
                        <div className='quick-drawer-action'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='float-right'>
                                        <button
                                            type='button'
                                            className={'btn btn-link btn-cancel' + (linkWorkOrders.dataSets && linkWorkOrders.dataSets.length > 0 ? ' mr-2' : '')}
                                            onClick={handleCancel}
                                        >Cancel</button>
                                        {
                                            linkWorkOrders.dataSets && linkWorkOrders.dataSets.length > 0 ?
                                                <button
                                                    type='submit'
                                                    className='btn btn-success'
                                                >Save</button> : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            }</Observer>
        </form>
    </>
}

export default LinkWorkOrders;