import React, { useEffect, useContext, useState, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { GlobalHotKeys } from 'react-hotkeys';
import { DropdownList, Combobox } from 'react-widgets';
import { useObserver } from 'mobx-react-lite';
import { Mention, MentionsInput } from 'react-mentions';

import QuickDrawerHeader from '../../../../_shared/QuickDrawerHeader';
import { quickDrawerFocus } from '../../../../_shared/QuickDrawer';
import BorderPicker from '../../../../_shared/BorderPicker';
import ColorPicker from '../../../../_shared/ColorPicker';
import RichTextEditor from '../../../../_shared/RichTextEditor';

import PrescriptionPrintElementViewStore from '../../../../../../stores/PrescriptionPrintElementViewStore';
import QuickDrawerStore from '../../../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../../../constants/errorMessages';
import * as fn from '../../../../../../utilities/_functions';
import * as pth from '../../../../../../utilities/printTemplateHelper';
import * as pph from '../../../../../../utilities/prescriptionHelper';
import * as mh from '../../../../../../utilities/mentionHelper';

import './UpdateExistingPrescriptionPrintElement.scss';

function UpdateExistingPrescriptionPrintElement(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const prescriptionPrintElement = useContext(PrescriptionPrintElementViewStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [fields, setFields] = useState(null);
    const [align, setAlign] = useState('');
    const [valign, setValign] = useState('');
    const [padding, setPadding] = useState([]);
    const [fontColor, setFontColor] = useState('');
    const [fontSize, setFontSize] = useState(null);
    const [fontFamily, setFontFamily] = useState('');
    const [fontStyle, setFontStyle] = useState([]);
    const [fontLetterSpacing, setFontLetterSpacing] = useState(null);
    const [image, setImage] = useState(null);
    const [backgroundColor, setBackgroundColor] = useState('');
    const [border, setBorder] = useState(null);
    const [borderWidth, setBorderWidth] = useState(1);
    const [borderStyle, setBorderStyle] = useState('solid');
    const [borderColor, setBorderColor] = useState('#000');
    const [alternateBackgroundColor, setAlternateBackgroundColor] = useState('');
    const [content, setContent] = useState('');
    const [format, setFormat] = useState(null);
    const [display, setDisplay] = useState(null);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        focusTimer.current = setTimeout(() => {
            const existingFields = prescriptionPrintElement.getMetadata('fields');
            const existingAlign = prescriptionPrintElement.getMetadata('align');
            const existingValign = prescriptionPrintElement.getMetadata('valign');
            const existingPadding = prescriptionPrintElement.getMetadata('padding');
            const existingFontColor = prescriptionPrintElement.getMetadata('fontColor');
            const existingFontSize = prescriptionPrintElement.getMetadata('fontSize');
            const existingFontFamily = prescriptionPrintElement.getMetadata('fontFamily');
            const existingFontStyle = prescriptionPrintElement.getMetadata('fontStyle');
            const existingFontLetterSpacing = prescriptionPrintElement.getMetadata('fontLetterSpacing');
            const existingImage = prescriptionPrintElement.getMetadata('image');
            const existingBackgroundColor = prescriptionPrintElement.getMetadata('backgroundColor');
            const existingBorder = prescriptionPrintElement.getMetadata('border');
            const existingAlternateBackgroundColor = prescriptionPrintElement.getMetadata('alternateBackgroundColor');
            const existingContent = prescriptionPrintElement.getMetadata('content');
            const existingFormat = prescriptionPrintElement.getMetadata('format');
            const display = prescriptionPrintElement.getMetadata('display');

            setFields((existingFields ? existingFields : []));
            setAlign((existingAlign ? existingAlign : 'left'));
            setValign((existingValign ? existingValign : 'middle'));
            setPadding((existingPadding ? existingPadding : [0, 0, 0, 0]));
            setFontColor((existingFontColor ? existingFontColor : '#666666'));
            setFontSize((existingFontSize ? existingFontSize : 13));
            setFontFamily((existingFontFamily ? existingFontFamily : 'Arial'));
            setFontStyle((existingFontStyle ? existingFontStyle : []));
            setFontLetterSpacing((existingFontLetterSpacing ? existingFontLetterSpacing : 0));
            setImage((existingImage ? existingImage : null));
            setBackgroundColor((existingBackgroundColor ? existingBackgroundColor : ''));
            setBorder((existingBorder ? existingBorder : null));
            setAlternateBackgroundColor((existingAlternateBackgroundColor ? existingAlternateBackgroundColor : '#f3f3f3'));
            setContent((existingContent ? existingContent : ''));
            setFormat((existingFormat ? existingFormat : pth.getFormatOptions(prescriptionPrintElement.data.type)[0] ? pth.getFormatOptions(prescriptionPrintElement.data.type)[0].type : ''));
            setDisplay((display ? display : null));
            setIsReady(true);

            quickDrawerFocus(props.drawer);
        }, 100)

        return (() => {
            isMounted.current = false;
            prescriptionPrintElement.clear();
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        })
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (prescriptionPrintElement.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = event => {
        event.preventDefault();
        handlePopulateMetadata();
        prescriptionPrintElement.save()
            .then(data => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, { container: props.extraProps.container, data: data });
                    }
                }
            })
    }

    const handleFieldChange = (event, index) => {
        const tempFields = [...fields];
        tempFields[index] = event.target.value;
        setFields(tempFields);
        prescriptionPrintElement.hasUnsavedChanges = true;
    }

    const handleAddField = event => {
        event.preventDefault();
        setFields(o => [...fields, '']);
        focusTimer.current = setTimeout(() => {
            const element = document.querySelector(`#update-prescription-print-field-${fields.length}`)
            if (element) { element.focus(); }
        }, 50);
        prescriptionPrintElement.hasUnsavedChanges = true;
    }

    const handleRemoveField = (option, index) => {
        setFields(fields.filter((f, fi) => fi !== index));
        prescriptionPrintElement.hasUnsavedChanges = true;
    }

    const handleMoveField = (from, to) => {
        const tempFields = [...fields];
        const element = tempFields[from];

        tempFields.splice(from, 1);
        tempFields.splice(to, 0, element);

        setFields(tempFields);
        prescriptionPrintElement.hasUnsavedChanges = true;
    }

    const handleContentChange = content => {
        const html = ((content === '<p><br></p>') ? null : content);
        setContent(html);
        prescriptionPrintElement.hasUnsavedChanges = true;
    }

    const handleFormatChange = event => {
        setFormat(event.target.value);
        prescriptionPrintElement.hasUnsavedChanges = true;
    }

    const handleFontFamilyChange = (value) => {
        setFontFamily(value);
        prescriptionPrintElement.hasUnsavedChanges = true;
    }

    const handleFontSizeChange = (value) => {
        setFontSize(value);
        prescriptionPrintElement.hasUnsavedChanges = true;
    }

    const handleFontColorChange = (color) => {
        setFontColor(color.value);
        prescriptionPrintElement.hasUnsavedChanges = true;
    }

    const handleFontStyleChange = value => {
        const index = fontStyle.findIndex(s => s === value);
        const tempFontStyle = [...fontStyle];

        if (index === -1) {
            tempFontStyle.push(value);
        } else {
            tempFontStyle.splice(index, 1);
        }

        setFontStyle(tempFontStyle);
        prescriptionPrintElement.hasUnsavedChanges = true;
    }

    const handleFontLetterSpacing = event => {
        const spacing = event.target.value;
        setFontLetterSpacing((spacing ? spacing : 0));
        prescriptionPrintElement.hasUnsavedChanges = true;
    }

    const handleAlignChange = value => {
        setAlign((value ? value : 'left'));
        prescriptionPrintElement.hasUnsavedChanges = true;
    }

    const handleValignChange = value => {
        setValign((value ? value : 'middle'));
        prescriptionPrintElement.hasUnsavedChanges = true;
    }

    const handlePaddingChange = (event, index) => {
        const newPadding = [...padding];

        newPadding[index] = event.target.value;
        setPadding(newPadding);
        prescriptionPrintElement.hasUnsavedChanges = true;
    }

    const handleBorderChange = (event, data) => {
        setBorder(data);
        prescriptionPrintElement.hasUnsavedChanges = true;
    }

    const handleBorderStyleChange = (item) => {
        if (item) {
            setBorderWidth(item.data.width);
            setBorderStyle(item.data.style);
        }
        prescriptionPrintElement.hasUnsavedChanges = true;
    }

    const handleBorderColorChange = (color) => {
        setBorderColor(color.value);
        prescriptionPrintElement.hasUnsavedChanges = true;
    }

    const handleBackgroundColorChange = (color) => {
        setBackgroundColor(color.value);
        prescriptionPrintElement.hasUnsavedChanges = true;
    }

    const handleImageClick = event => {
        setImage(null);
        prescriptionPrintElement.hasUnsavedChanges = true;
    }

    const handleImageChange = event => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = handleFileReaderLoad;
        reader.readAsDataURL(file);
    }

    const handleFileReaderLoad = (event) => {
        const reader = event.target;
        const base64 = reader.result;
        const tempImage = new Image();

        tempImage.src = event.target.result;
        tempImage.onload = () => {
            const newImage = {
                base64: base64,
                width: tempImage.width,
                height: tempImage.height,
            }
            setImage(newImage);
        }
        prescriptionPrintElement.hasUnsavedChanges = true;
    }

    const handlePopulateMetadata = () => {
        populateMetadata(pth.PRINT_TEMPLATE_METADATA_FIELDS, 'fields', fields);
        populateMetadata(pth.PRINT_TEMPLATE_METADATA_ALIGN, 'align', align);
        populateMetadata(pth.PRINT_TEMPLATE_METADATA_VALIGN, 'valign', valign);
        populateMetadata(pth.PRINT_TEMPLATE_METADATA_PADDING, 'padding', padding);
        populateMetadata(pth.PRINT_TEMPLATE_METADATA_FONT_COLOR, 'fontColor', fontColor);
        populateMetadata(pth.PRINT_TEMPLATE_METADATA_FONT_SIZE, 'fontSize', fontSize);
        populateMetadata(pth.PRINT_TEMPLATE_METADATA_FONT_FAMILY, 'fontFamily', fontFamily);
        populateMetadata(pth.PRINT_TEMPLATE_METADATA_FONT_STYLE, 'fontStyle', fontStyle);
        populateMetadata(pth.PRINT_TEMPLATE_METADATA_FONT_LETTER_SPACING, 'fontLetterSpacing', fontLetterSpacing);
        populateMetadata(pth.PRINT_TEMPLATE_METADATA_BACKGROUND_COLOR, 'backgroundColor', backgroundColor);
        populateMetadata(pth.PRINT_TEMPLATE_METADATA_BORDER, 'border', border);
        populateMetadata(pth.PRINT_TEMPLATE_METADATA_BORDER_COLOR, 'borderColor', borderColor);
        populateMetadata(pth.PRINT_TEMPLATE_METADATA_ALTERNATE_BACKGROUND_COLOR, 'alternateBackgroundColor', alternateBackgroundColor);
        populateMetadata(pth.PRINT_TEMPLATE_METADATA_CONTENT, 'content', content);
        populateMetadata(pth.PRINT_TEMPLATE_METADATA_IMAGE, 'image', image);
        populateMetadata(pth.PRINT_TEMPLATE_METADATA_FORMAT, 'format', format);
        populateMetadata(pth.PRINT_TEMPLATE_METADATA_DISPLAY, 'display', display);
    }

    const populateMetadata = (field, key, value) => {
        if (pth.hasField(prescriptionPrintElement.data.type, field)) {
            prescriptionPrintElement.setMetadata(key, value);
        } else {
            prescriptionPrintElement.removeMetadata(key);
        }
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form onSubmit={handleSubmit}>
            <fieldset disabled={prescriptionPrintElement.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={pth.getIcon(prescriptionPrintElement.data.type)}
                        action='Update'
                        category={`${pth.getDescription(prescriptionPrintElement.data.type)}`}
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        {
                            isReady ?
                                <FadeIn>
                                    <div className='update-prescription-print-text body-content'>
                                        <section>
                                            {
                                                pth.hasField(prescriptionPrintElement.data.type, pth.PRINT_TEMPLATE_METADATA_FIELDS) ?
                                                    <div className='row fields'>
                                                        <div className='col'>
                                                            <div className='form-group mb-4'>
                                                                <label className='required' htmlFor='update-prescription-print-field'><small>Fields</small></label>
                                                                <>
                                                                    {
                                                                        fields && fields.length > 0 ?
                                                                            fields.map((f, fi) => {
                                                                                return <ul
                                                                                    key={`update_prescription_print_field_${fi}`}
                                                                                    className='list-inline no-style m-0 mb-2'
                                                                                >
                                                                                    <li className='list-inline-item m-0 mr-1'>{fi + 1}.&nbsp;</li>
                                                                                    <li className='list-inline-item m-0 field flex-1'>
                                                                                        <MentionsInput
                                                                                            singleLine
                                                                                            id={`update-prescription-print-field-${fi}`}
                                                                                            className='react-mentions-wrapper w-100'
                                                                                            autoComplete='off'
                                                                                            value={f}
                                                                                            title={mh.getDisplay(f)}
                                                                                            onChange={(e) => { handleFieldChange(e, fi) }}
                                                                                        >
                                                                                            <Mention
                                                                                                trigger='#'
                                                                                                data={props.extraProps && props.extraProps.fieldOptions ? props.extraProps.fieldOptions : []}
                                                                                                markup={mh.MENTION_MARKUP}
                                                                                            />
                                                                                        </MentionsInput>
                                                                                    </li>
                                                                                    <li className='list-inline-item m-0 ml-2'>
                                                                                        <button
                                                                                            type='button'
                                                                                            className='btn btn-link btn-icon btn-sm'
                                                                                            autoComplete='off'
                                                                                            onClick={e => { handleRemoveField(f, fi) }}
                                                                                        >
                                                                                            <i className={'fal fa-minus-circle text-danger'}></i>
                                                                                        </button>
                                                                                    </li>
                                                                                    <li className='list-inline-item m-0'>
                                                                                        <button
                                                                                            type='button'
                                                                                            className='btn btn-link btn-icon btn-sm'
                                                                                            autoComplete='off'
                                                                                            disabled={fields.length === 1 || fi === 0}
                                                                                            onClick={e => { handleMoveField(fi, (fi - 1)) }}
                                                                                        >
                                                                                            <i className={'fal fa-arrow-up' + (fields.length === 1 || fi === 0 ? ' text-gray-500' : ' text-primary')}></i>
                                                                                        </button>
                                                                                    </li>
                                                                                    <li className='list-inline-item m-0'>
                                                                                        <button
                                                                                            type='button'
                                                                                            className='btn btn-link btn-icon btn-sm'
                                                                                            disabled={fields.length === 1 || fi === (fields.length - 1)}
                                                                                            onClick={e => { handleMoveField(fi, (fi + 1)) }}
                                                                                        >
                                                                                            <i className={'fal fa-arrow-down' + (fields.length === 1 || fi === (fields.length - 1) ? ' text-gray-500' : ' text-primary')}></i>
                                                                                        </button>
                                                                                    </li>
                                                                                </ul>
                                                                            }) : <div></div>
                                                                    }
                                                                    <button
                                                                        type='button'
                                                                        className='btn btn-link btn-icon-left btn-sm'
                                                                        onClick={handleAddField}
                                                                    >
                                                                        <i className='fal fa-plus-circle'></i> Add field
                                                                    </button>
                                                                </>
                                                                {/* <select
                                                                    id='update-prescription-print-field'
                                                                    className='custom-select form-control'
                                                                    value={field}
                                                                    onChange={handleFieldChange}
                                                                >
                                                                    <option value=''></option>
                                                                    {
                                                                        props.extraProps.fields && props.extraProps.fields.length > 0 ?
                                                                            props.extraProps.fields.map((f, fi) => {
                                                                                return <option
                                                                                    key={`update-prescription-print-field-${fi}`}
                                                                                    value={f.key}
                                                                                >
                                                                                    {f.name}
                                                                                </option>
                                                                            }) : null
                                                                    }
                                                                </select> */}
                                                            </div>
                                                        </div>
                                                    </div> : null
                                            }
                                            {
                                                pth.hasField(prescriptionPrintElement.data.type, pth.PRINT_TEMPLATE_METADATA_CONTENT) ?
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-4'>
                                                                <label htmlFor='update-prescription-print-text'><small>Content</small></label>
                                                                <RichTextEditor
                                                                    mode='basic'
                                                                    disableTab={true}
                                                                    value={content}
                                                                    onChange={handleContentChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div> : null
                                            }
                                            {
                                                pth.hasField(prescriptionPrintElement.data.type, pth.PRINT_TEMPLATE_METADATA_FORMAT) ?
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-4'>
                                                                <label htmlFor='update-prescription-print-format'><small>Format</small></label>
                                                                {
                                                                    pth.getFormatOptions(prescriptionPrintElement.data.type).map((o, oi) => {
                                                                        return <div
                                                                            key={`update-prescription-print-format-${oi}`}
                                                                            className='custom-control custom-radio mb-2'
                                                                        >
                                                                            <input
                                                                                id={`update-prescription-print-format-${oi}`}
                                                                                type='radio'
                                                                                name='updateFormFormat'
                                                                                value={o.type}
                                                                                className='custom-control-input'
                                                                                checked={format === o.type}
                                                                                onChange={handleFormatChange}
                                                                            />
                                                                            <label
                                                                                htmlFor={`update-prescription-print-format-${oi}`}
                                                                                className='custom-control-label'
                                                                            >
                                                                                {o.example}
                                                                            </label>
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div> : null
                                            }
                                            {
                                                pth.hasField(prescriptionPrintElement.data.type, pth.PRINT_TEMPLATE_METADATA_FONT_FAMILY) ||
                                                    pth.hasField(prescriptionPrintElement.data.type, pth.PRINT_TEMPLATE_METADATA_FONT_SIZE) ||
                                                    pth.hasField(prescriptionPrintElement.data.type, pth.PRINT_TEMPLATE_METADATA_FONT_COLOR) ?
                                                    <div className='row'>
                                                        {
                                                            pth.hasField(prescriptionPrintElement.data.type, pth.PRINT_TEMPLATE_METADATA_FONT_FAMILY) ?
                                                                <div className='col-6'>
                                                                    <div className='form-group mb-4'>
                                                                        <label htmlFor='update-prescription-print-font-family'><small>Font</small></label>
                                                                        <DropdownList
                                                                            data={['Arial', 'Arial Black', 'Georgia', 'Impact', 'Times New Roman', 'Verdana']}
                                                                            value={fontFamily}
                                                                            itemComponent={({ item }) => (
                                                                                <div>
                                                                                    <span style={{ fontFamily: item }}>{item}</span>
                                                                                </div>
                                                                            )}
                                                                            valueComponent={({ item }) => (
                                                                                item ?
                                                                                    <div>
                                                                                        <span style={{ fontFamily: item }}>{item}</span>
                                                                                    </div> : null
                                                                            )}
                                                                            onChange={handleFontFamilyChange}
                                                                        />
                                                                    </div>
                                                                </div> : null
                                                        }
                                                        {
                                                            pth.hasField(prescriptionPrintElement.data.type, pth.PRINT_TEMPLATE_METADATA_FONT_SIZE) ?
                                                                <div className='col-3'>
                                                                    <div className='form-group mb-4'>
                                                                        <label htmlFor='update-prescription-print-font-size'><small>Size</small></label>
                                                                        <Combobox
                                                                            data={[8, 9, 10, 11, 12, 13, 14, 18, 21, 24, 28, 30, 32, 36, 48, 60, 72, 96]}
                                                                            value={fontSize}
                                                                            autoSelectMatches={false}
                                                                            itemComponent={({ item }) => (
                                                                                <div>
                                                                                    <span>{item}</span>
                                                                                </div>
                                                                            )}
                                                                            valueComponent={({ item }) => (
                                                                                item ?
                                                                                    <div>
                                                                                        <span>{item}</span>
                                                                                    </div> : null
                                                                            )}
                                                                            onChange={handleFontSizeChange}
                                                                        />
                                                                    </div>
                                                                </div> : null
                                                        }
                                                        {
                                                            pth.hasField(prescriptionPrintElement.data.type, pth.PRINT_TEMPLATE_METADATA_FONT_COLOR) ?
                                                                <div className='col-3'>
                                                                    <div className='form-group mb-4'>
                                                                        <label htmlFor='update-prescription-print-font-color'><small>Color</small></label>
                                                                        <ColorPicker color={fontColor} popup='right' onChange={handleFontColorChange} />
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </div> : null
                                            }
                                            {
                                                pth.hasField(prescriptionPrintElement.data.type, pth.PRINT_TEMPLATE_METADATA_FONT_STYLE) ||
                                                    pth.hasField(prescriptionPrintElement.data.type, pth.PRINT_TEMPLATE_METADATA_FONT_LETTER_SPACING) ?
                                                    <div className='row'>
                                                        {
                                                            pth.hasField(prescriptionPrintElement.data.type, pth.PRINT_TEMPLATE_METADATA_FONT_STYLE) ?
                                                                <div className='col-6'>
                                                                    <div className='form-group mb-4'>
                                                                        <label htmlFor='update-prescription-print-font-style'><small>Style</small></label>
                                                                        <div>
                                                                            <div className='btn-group'>
                                                                                <button
                                                                                    type='button'
                                                                                    className={'btn btn-primary' + (fontStyle.some(s => s === 'bold') ? ' active' : '')}
                                                                                    value={(align ? align : '')}
                                                                                    onClick={() => { handleFontStyleChange('bold') }}
                                                                                >
                                                                                    <i className='fal fa-bold'></i>
                                                                                </button>
                                                                                <button
                                                                                    type='button'
                                                                                    className={'btn btn-primary' + (fontStyle.some(s => s === 'italic') ? ' active' : '')}
                                                                                    value={(align ? align : '')}
                                                                                    onClick={() => { handleFontStyleChange('italic') }}
                                                                                >
                                                                                    <i className='fal fa-italic'></i>
                                                                                </button>
                                                                                <button
                                                                                    type='button'
                                                                                    className={'btn btn-primary' + (fontStyle.some(s => s === 'underline') ? ' active' : '')}
                                                                                    value={(align ? align : '')}
                                                                                    onClick={() => { handleFontStyleChange('underline') }}
                                                                                >
                                                                                    <i className='fal fa-underline'></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                        {
                                                            pth.hasField(prescriptionPrintElement.data.type, pth.PRINT_TEMPLATE_METADATA_FONT_LETTER_SPACING) ?
                                                                <div className='col-3'>
                                                                    <div className='form-group mb-4'>
                                                                        <label htmlFor='update-prescription-print-font-letter-spacing'><small>Spacing</small></label>
                                                                        <input
                                                                            id='update-prescription-print-font-letter-spacing'
                                                                            type='number'
                                                                            className='form-control'
                                                                            min={-2}
                                                                            step={0.1}
                                                                            max={2}
                                                                            value={fontLetterSpacing}
                                                                            onChange={handleFontLetterSpacing}
                                                                        />
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </div> : null
                                            }
                                            {
                                                pth.hasField(prescriptionPrintElement.data.type, pth.PRINT_TEMPLATE_METADATA_ALIGN) ||
                                                    pth.hasField(prescriptionPrintElement.data.type, pth.PRINT_TEMPLATE_METADATA_VALIGN) ?
                                                    <div className='row'>
                                                        {
                                                            pth.hasField(prescriptionPrintElement.data.type, pth.PRINT_TEMPLATE_METADATA_ALIGN) ?
                                                                <div className='col-6'>
                                                                    <div className='form-group mb-4'>
                                                                        <label htmlFor='update-prescription-print-text-align'><small>Align</small></label>
                                                                        <div>
                                                                            <div className='btn-group'>
                                                                                <button
                                                                                    type='button'
                                                                                    className={'btn btn-primary' + (align === 'left' ? ' active' : '')}
                                                                                    value={(align ? align : '')}
                                                                                    onClick={() => { handleAlignChange('left') }}
                                                                                >
                                                                                    <i className='fal fa-align-left'></i>
                                                                                </button>
                                                                                <button
                                                                                    type='button'
                                                                                    className={'btn btn-primary' + (align === 'center' ? ' active' : '')}
                                                                                    value={(align ? align : '')}
                                                                                    onClick={() => { handleAlignChange('center') }}
                                                                                >
                                                                                    <i className='fal fa-align-center'></i>
                                                                                </button>
                                                                                <button
                                                                                    type='button'
                                                                                    className={'btn btn-primary' + (align === 'right' ? ' active' : '')}
                                                                                    value={(align ? align : '')}
                                                                                    onClick={() => { handleAlignChange('right') }}
                                                                                >
                                                                                    <i className='fal fa-align-right'></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                        {
                                                            pth.hasField(prescriptionPrintElement.data.type, pth.PRINT_TEMPLATE_METADATA_VALIGN) ?
                                                                <div className='form-group mb-4'>
                                                                    <label htmlFor='update-prescription-print-text-valign'><small>Vertical Align</small></label>
                                                                    <div>
                                                                        <div className='btn-group'>
                                                                            <button
                                                                                type='button'
                                                                                className={'btn btn-primary' + (valign === 'top' ? ' active' : '')}
                                                                                value={(valign ? valign : '')}
                                                                                onClick={() => { handleValignChange('top') }}
                                                                            >
                                                                                <i className='fal fa-arrow-to-top'></i>
                                                                            </button>
                                                                            <button
                                                                                type='button'
                                                                                className={'btn btn-primary' + (valign === 'middle' ? ' active' : '')}
                                                                                value={(valign ? valign : '')}
                                                                                onClick={() => { handleValignChange('middle') }}
                                                                            >
                                                                                <i className='fal fa-horizontal-rule'></i>
                                                                            </button>
                                                                            <button
                                                                                type='button'
                                                                                className={'btn btn-primary' + (valign === 'bottom' ? ' active' : '')}
                                                                                value={(valign ? valign : '')}
                                                                                onClick={() => { handleValignChange('bottom') }}
                                                                            >
                                                                                <i className='fal fa-arrow-to-bottom'></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </div> : null
                                            }
                                            {
                                                pth.hasField(prescriptionPrintElement.data.type, pth.PRINT_TEMPLATE_METADATA_PADDING) ?
                                                    <div className='row'>
                                                        <div className='col-3'>
                                                            <div className='form-group mb-4'>
                                                                <label htmlFor='update-prescription-print-padding-top'><small>Top</small></label>
                                                                <input
                                                                    id='update-prescription-print-padding-top'
                                                                    type='number'
                                                                    className='form-control'
                                                                    min={-50}
                                                                    step={pth.PRINT_TEMPLATE_ROW_HEIGHT}
                                                                    max={50}
                                                                    value={(padding && padding[0] ? padding[0] : 0)}
                                                                    onChange={(e) => { handlePaddingChange(e, 0) }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='col-3'>
                                                            <div className='form-group mb-4'>
                                                                <label htmlFor='update-prescription-print-padding-right'><small>Right</small></label>
                                                                <input
                                                                    id='update-prescription-print-padding-right'
                                                                    type='number'
                                                                    className='form-control'
                                                                    min={-50}
                                                                    step={pth.PRINT_TEMPLATE_ROW_HEIGHT}
                                                                    max={50}
                                                                    value={(padding && padding[1] ? padding[1] : 0)}
                                                                    onChange={(e) => { handlePaddingChange(e, 1) }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='col-3'>
                                                            <div className='form-group mb-4'>
                                                                <label htmlFor='update-prescription-print-padding-bottom'><small>Bottom</small></label>
                                                                <input
                                                                    id='update-prescription-print-padding-bottom'
                                                                    type='number'
                                                                    className='form-control'
                                                                    min={-50}
                                                                    step={pth.PRINT_TEMPLATE_ROW_HEIGHT}
                                                                    max={50}
                                                                    value={(padding && padding[2] ? padding[2] : 0)}
                                                                    onChange={(e) => { handlePaddingChange(e, 2) }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='col-3'>
                                                            <div className='form-group mb-4'>
                                                                <label htmlFor='update-prescription-print-padding-left'><small>Left</small></label>
                                                                <input
                                                                    id='update-prescription-print-padding-left'
                                                                    type='number'
                                                                    className='form-control'
                                                                    min={-50}
                                                                    step={pth.PRINT_TEMPLATE_ROW_HEIGHT}
                                                                    max={50}
                                                                    value={(padding && padding[3] ? padding[3] : 0)}
                                                                    onChange={(e) => { handlePaddingChange(e, 3) }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div> : null
                                            }
                                            {
                                                pth.hasField(prescriptionPrintElement.data.type, pth.PRINT_TEMPLATE_METADATA_BORDER) ?
                                                    <>
                                                        <div className='row'>
                                                            <div className='col-3'>
                                                                <div className='form-group mb-3'>
                                                                    <label htmlFor='update-prescription-print-border'><small>Border</small></label>
                                                                    <BorderPicker border={border} width={borderWidth} style={borderStyle} color={borderColor} onChange={handleBorderChange} />
                                                                </div>
                                                            </div>
                                                            <div className='col-5 offset-1'>
                                                                <div className='form-group mb-3'>
                                                                    <label htmlFor='update-prescription-print-border-style'><small>Style</small></label>
                                                                    <DropdownList
                                                                        data={[
                                                                            { key: '1px solid #000', data: { width: 1, style: 'solid' } },
                                                                            { key: '2px solid #000', data: { width: 2, style: 'solid' } },
                                                                            { key: '3px solid #000', data: { width: 3, style: 'solid' } },
                                                                            { key: '1px dashed #000', data: { width: 1, style: 'dashed' } },
                                                                            { key: '2px dashed #000', data: { width: 2, style: 'dashed' } },
                                                                            { key: '3px dashed #000', data: { width: 3, style: 'dashed' } },
                                                                            { key: '1px dotted #000', data: { width: 1, style: 'dotted' } },
                                                                            { key: '2px dotted #000', data: { width: 2, style: 'dotted' } },
                                                                            { key: '3px dotted #000', data: { width: 3, style: 'dotted' } },
                                                                            { key: '3px double #000', data: { width: 3, style: 'double' } },
                                                                            { key: '6px double #000', data: { width: 6, style: 'double' } },
                                                                        ]}
                                                                        valueField='key'
                                                                        value={(`${borderWidth}px ${borderStyle} #000`)}
                                                                        itemComponent={({ item }) => (
                                                                            <div className='p-0' style={{ fontSize: '1px', borderTop: item.key }}>&nbsp;</div>
                                                                        )}
                                                                        valueComponent={({ item }) => (
                                                                            item ? <div className='p-0' style={{ fontSize: '1px', borderTop: item.key }}>&nbsp;</div> : null
                                                                        )}
                                                                        onChange={handleBorderStyleChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-3'>
                                                                <div className='form-group mb-4'>
                                                                    <label htmlFor='update-prescription-print-border-color'><small>Color</small></label>
                                                                    <ColorPicker color={borderColor} popup='right' onChange={handleBorderColorChange} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </> : null
                                            }
                                            {
                                                pth.hasField(prescriptionPrintElement.data.type, pth.PRINT_TEMPLATE_METADATA_BACKGROUND_COLOR) ?
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-4'>
                                                                <label htmlFor='update-prescription-print-background-color'><small>Background Color</small></label>
                                                                <ColorPicker color={backgroundColor} popup='right' onChange={handleBackgroundColorChange} />
                                                            </div>
                                                        </div>
                                                    </div> : null
                                            }
                                            {
                                                pth.hasField(prescriptionPrintElement.data.type, pth.PRINT_TEMPLATE_METADATA_IMAGE) ?
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-4'>
                                                                <label htmlFor='update-prescription-print-background-image'><small>Image</small></label>
                                                                {
                                                                    image ?
                                                                        <div>
                                                                            <img src={image.base64} width={(image.width > 300 ? 300 : image.width)} alt='Example' />
                                                                            <button
                                                                                type='button'
                                                                                className='btn btn-link px-0 btn-sm d-block'
                                                                                onClick={handleImageClick}
                                                                            >Change image</button>
                                                                        </div> :
                                                                        <div className='custom-file'>
                                                                            <input
                                                                                id='update-prescription-print-background-image-url'
                                                                                type='file'
                                                                                className='custom-file-input'
                                                                                accept='image/*'
                                                                                onChange={handleImageChange} />
                                                                            <label
                                                                                htmlFor='update-prescription-print-background-image-url'
                                                                                className='custom-file-label'>
                                                                            </label>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div> : null
                                            }
                                            {
                                                pth.hasField(prescriptionPrintElement.data.type, pth.PRINT_TEMPLATE_METADATA_SIGNATURE) ?
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-4'>
                                                                <label htmlFor='update-prescription-print-signature'><small>Signature</small></label>
                                                                <div>
                                                                    <img
                                                                        src={pph.getSignatureLineBase64(pph.PRESCRIPTION_SIGNATURE_DEMO, false, { scale: 0.8 })}
                                                                        width={pph.PRESCRIPTION_SIGNATURE_CANVAS_WIDTH * 0.8}
                                                                        height={pph.PRESCRIPTION_SIGNATURE_CANVAS_HEIGHT * 0.8}
                                                                        alt='Sample signature'
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> : null
                                            }
                                        </section>
                                    </div>
                                </FadeIn> : null
                        }
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-4'></div>
                            <div className='col-8'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>)
}

export default UpdateExistingPrescriptionPrintElement;