import { createContext } from 'react';
import { decorate, observable, action, toJS } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class ProductTypeCreate {
    data = {
        id: null,
        name: null,
        defaultQuantity: 1,
        defaultApplyTax: true,
        extraData: [],
        workOrderPublishedTemplateId: null,
    };
    originalData = null;
    workOrderPublishedTemplates = [];
    hasUnsavedChanges = false;
    isReady = false;
    isSaving = false;
    isLoading = false;
    saveToServer = true;

    cancelProductTypeCreate = null;
    cancelProductTypeUpdate = null;
    cancelProductTypeDelete = null;
    cancelProductTypeSearch = null;
    cancelWorkOrderPublishTemplates = null;

    initialize = (saveToServer) => {
        const that = this;

        this.clear();

        if (!fn.isNullOrUndefined(saveToServer)) {
            this.saveToServer = saveToServer;
        }

        return new Promise((resolve, reject) => {
            api.WorkOrderPublishedTemplates.all((c) => { this.cancelWorkOrderPublishTemplates = c })
                .then(({ data }) => {
                    that.workOrderPublishedTemplates = data ? data : [];
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isReady = true;
                })
        })
    }

    load = (name) => {
        const that = this;

        this.isLoading = true;

        return new Promise((resolve, reject) => {
            api.ProductTypes.search({
                parameters: [{
                    field: 'Name',
                    value: name,
                }, {
                    field: 'DeactivatedDateUtc',
                    value: null,
                }],
                includeTotalCount: false,
                loadProperties: true,
            }, (c) => { that.cancelProductTypeSearch = c })
                .then(({ data }) => {
                    if (data && data.result && data.result.length > 0) {
                        that.data.id = data.result[0].id;
                        that.data.name = data.result[0].name;
                        that.data.defaultQuantity = data.result[0].defaultQuantity;
                        that.data.defaultApplyTax = data.result[0].defaultApplyTax;
                        that.data.extraData = data.result[0].data && data.result[0].data.length > 0 ? data.result[0].data.map(d => { return { originalName: d.name, name: d.name } }) : [];
                        that.data.workOrderPublishedTemplateId = data.result[0].workOrderPublishedTemplateId;
                        that.originalData = JSON.parse(JSON.stringify(that.data));
                    } else {
                        that.data.id = null;
                        that.data.defaultQuantity = 1;
                        that.data.defaultApplyTax = true;
                        that.data.extraData = [];
                        that.data.workOrderPublishedTemplateId = null;
                        that.originalData = null;
                    }
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isLoading = false;
                })
        })
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            let option = toJS(that.data);

            if (that.hasUnsavedChanges) {
                if (that.saveToServer) {
                    if (that.data.id) {
                        api.ProductTypes.update(that.data.id, option, (c) => { that.cancelProductTypeUpdate = c })
                            .then(() => {
                                that.hasUnsavedChanges = false;
                                resolve(that.data);
                            })
                            .catch(() => {
                                reject();
                            })
                            .finally(() => {
                                that.isSaving = false;
                            })
                    } else {
                        api.ProductTypes.create(option, (c) => { that.cancelProductTypeCreate = c })
                            .then(({ data }) => {
                                that.hasUnsavedChanges = false;
                                that.data.id = data.id;
                                resolve(that.data);
                            })
                            .catch(() => {
                                reject();
                            })
                            .finally(() => {
                                that.isSaving = false;
                            })
                    }
                } else {
                    resolve(option);
                }
            } else {
                that.hasUnsavedChanges = false;
                that.isSaving = false;
                resolve(option);
            }
        })
    }

    delete = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            if (that.data.id) {
                api.ProductTypes.delete(that.data.id, (c) => { that.cancelProductTypeDelete = c })
                    .then(() => {
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            }
            else
                reject();
        })
    }

    clear = () => {
        this.data.id = null;
        this.data.name = null;
        this.data.defaultQuantity = 1;
        this.data.defaultApplyTax = true;
        this.data.extraData.clear();
        this.data.workOrderPublishedTemplateId = null;
        this.originalData = null;
        this.workOrderPublishedTemplates.clear();
        this.hasUnsavedChanges = false;
        this.isReady = false;
        this.isSaving = false;
        this.isLoading = false;
        this.saveToServer = true;

        if (fn.isFunction(this.cancelProductTypeCreate)) {
            this.cancelProductTypeCreate();
            this.cancelProductTypeCreate = null;
        }

        if (fn.isFunction(this.cancelProductTypeUpdate)) {
            this.cancelProductTypeUpdate();
            this.cancelProductTypeUpdate = null;
        }

        if (fn.isFunction(this.cancelProductTypeDelete)) {
            this.cancelProductTypeDelete();
            this.cancelProductTypeDelete = null;
        }

        if (fn.isFunction(this.cancelProductTypeSearch)) {
            this.cancelProductTypeSearch();
            this.cancelProductTypeSearch = null;
        }

        if (fn.isFunction(this.cancelWorkOrderPublishTemplates)) {
            this.cancelWorkOrderPublishTemplates();
            this.cancelWorkOrderPublishTemplates = null;
        }
    }
}

decorate(ProductTypeCreate, {
    data: observable.deep,
    workOrderPublishedTemplates: observable,
    hasUnsavedChanges: observable,
    isReady: observable,
    isSaving: observable,
    isLoading: observable,
    saveToServer: observable,
    initialize: action,
    load: action,
    save: action,
    clear: action,
})

export default createContext(new ProductTypeCreate());