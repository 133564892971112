import { createContext } from 'react';
import { decorate, observable, action } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class WorkOrderTemplateView {
    id = null;
    data = null;
    isReady = false;
    isLoading = false;

    cancelWorkOrderTemplate = null;

    load = id => {
        const that = this;
        this.clear();
        this.id = id;
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            api.WorkOrderTemplates.get(id, (c) => { that.cancelWorkOrderTemplate = c })
                .then(({ data }) => {
                    that.data = data;
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isLoading = false;
                    that.isReady = true;
                })
        })
    }

    clear = () => {
        this.id = null;
        this.data = null;
        this.isReady = false;
        this.isLoading = false;

        if (fn.isFunction(this.cancelWorkOrderTemplate)) {
            this.cancelWorkOrderTemplate();
            this.cancelWorkOrderTemplate = null;
        }
    }
}

decorate(WorkOrderTemplateView, {
    id: observable,
    data: observable.deep,
    selectedSectionId: observable,
    isReady: observable,
    isLoading: observable,
    load: action,
    clear: action,
})

export default createContext(new WorkOrderTemplateView());