import React from 'react';
import uuid from 'react-uuid';
import moment from 'moment';

import * as PageSettings from '../constants/pageSettings';

export const FORM_TEMPLATE_PAGE_SETTINGS_PAPER_LETTER_WIDTH = PageSettings.PAPER_LETTER_WIDTH;
export const FORM_TEMPLATE_PAGE_SETTINGS_PAPER_LETTER_HEIGHT = PageSettings.PAPER_LETTER_HEIGHT;
export const FORM_TEMPLATE_PAGE_SETTINGS_PAPER_A4_WIDTH = PageSettings.PAPER_A4_WIDTH;
export const FORM_TEMPLATE_PAGE_SETTINGS_PAPER_A4_HEIGHT = PageSettings.PAPER_A4_HEIGHT;
export const FORM_TEMPLATE_PAGE_SETTINGS_PAPER_LEGAL_WIDTH = PageSettings.PAPER_LEGAL_WIDTH;
export const FORM_TEMPLATE_PAGE_SETTINGS_PAPER_LEGAL_HEIGHT = PageSettings.PAPER_LEGAL_HEIGHT;
export const FORM_TEMPLATE_PAGE_SETTINGS_MARGINS_NARROW = PageSettings.MARGINS_NARROW;
export const FORM_TEMPLATE_PAGE_SETTINGS_MARGINS_NORMAL = PageSettings.MARGINS_NORMAL;
export const FORM_TEMPLATE_PAGE_SETTINGS_MARGINS_WIDE = PageSettings.MARGINS_WIDE;
export const FORM_TEMPLATE_PAGE_SETTINGS_HEADER_FOOTER_HEIGHT_MIN = PageSettings.HEADER_FOOTER_HEIGHT_MIN;
export const FORM_TEMPLATE_PAGE_SETTINGS_HEADER_FOOTER_HEIGHT_DEFAULT = PageSettings.HEADER_FOOTER_HEIGHT_DEFAULT;
export const FORM_TEMPLATE_PAGE_SETTINGS_HEADER_FOOTER_HEIGHT_MAX = PageSettings.HEADER_FOOTER_HEIGHT_MAX;

export const FORM_TEMPLATE_CELL_WIDTH = 12.75;
export const FORM_TEMPLATE_ROW_HEIGHT = 5;

export const FORM_ELEMENT_RICHTEXT_EDITOR = 'Richtext';
export const FORM_ELEMENT_IMAGE = 'Image';

export const FORM_ELEMENT_BUSINESS_NAME = 'BusinessName';
export const FORM_ELEMENT_BUSINESS_FULL_ADDRESS = 'BusinessFullAddress';
export const FORM_ELEMENT_BUSINESS_ADDRESS_LINE1 = 'BusinessAddressLine1';
export const FORM_ELEMENT_BUSINESS_ADDRESS_LINE2 = 'BusinessAddressLine2';
export const FORM_ELEMENT_BUSINESS_ADDRESS_COUNTRY = 'BusinessAddressCountry';
export const FORM_ELEMENT_BUSINESS_PHONE = 'BusinessPhone';
export const FORM_ELEMENT_BUSINESS_EMAIL = 'BusinessEmail';

export const FORM_ELEMENT_CUSTOMER_NAME = 'CustomerName';
export const FORM_ELEMENT_CUSTOMER_FULL_ADDRESS = 'CustomerFullAddress';
export const FORM_ELEMENT_CUSTOMER_ADDRESS_LINE1 = 'CustomerAddressLine1';
export const FORM_ELEMENT_CUSTOMER_ADDRESS_LINE2 = 'CustomerAddressLine2';
export const FORM_ELEMENT_CUSTOMER_ADDRESS_COUNTRY = 'CustomerAddressCountry';
export const FORM_ELEMENT_CUSTOMER_PHONE = 'CustomerPhone';
export const FORM_ELEMENT_CUSTOMER_EMAIL = 'CustomerEmail';

export const FORM_ELEMENT_PAGE_NUMBER = 'PageNumber';

export const FORM_TEMPLATE_METADATA_ALIGN = 'FORM_TEMPLATE_METADATA_ALIGN';
export const FORM_TEMPLATE_METADATA_VALIGN = 'FORM_TEMPLATE_METADATA_VALIGN';
export const FORM_TEMPLATE_METADATA_PADDING = 'FORM_TEMPLATE_METADATA_PADDING';
export const FORM_TEMPLATE_METADATA_FONT_COLOR = 'FORM_TEMPLATE_METADATA_FONT_COLOR';
export const FORM_TEMPLATE_METADATA_FONT_SIZE = 'FORM_TEMPLATE_METADATA_FONT_SIZE';
export const FORM_TEMPLATE_METADATA_FONT_FAMILY = 'FORM_TEMPLATE_METADATA_FONT_FAMILY';
export const FORM_TEMPLATE_METADATA_FONT_STYLE = 'FORM_TEMPLATE_METADATA_FONT_STYLE';
export const FORM_TEMPLATE_METADATA_FONT_LETTER_SPACING = 'FORM_TEMPLATE_METADATA_FONT_LETTER_SPACING';
export const FORM_TEMPLATE_METADATA_BACKGROUND_COLOR = 'FORM_TEMPLATE_METADATA_BACKGROUND_COLOR';
export const FORM_TEMPLATE_METADATA_BORDER = 'FORM_TEMPLATE_METADATA_BORDER';
export const FORM_TEMPLATE_METADATA_BORDER_COLOR = 'FORM_TEMPLATE_METADATA_BORDER_COLOR';
export const FORM_TEMPLATE_METADATA_ALTERNATE_BACKGROUND_COLOR = 'FORM_TEMPLATE_METADATA_BACKGROUND_COLOR';
export const FORM_TEMPLATE_METADATA_CONTENT = 'FORM_TEMPLATE_METADATA_CONTENT';
export const FORM_TEMPLATE_METADATA_IMAGE = 'FORM_TEMPLATE_METADATA_IMAGE';
export const FORM_TEMPLATE_METADATA_FORMAT = 'FORM_TEMPLATE_METADATA_FORMAT';

export const FORM_TEMPLATE_VALID_ANONYMOUS_ACCESS = ['Email'];
export const FORM_TEMPLATE_VALID_SYSTEM_ACCESS = ['Email', 'Database', 'Attachment'];

export const PrintElementTypes = [
    FORM_ELEMENT_RICHTEXT_EDITOR,
    FORM_ELEMENT_IMAGE,
    FORM_ELEMENT_BUSINESS_NAME,
    FORM_ELEMENT_BUSINESS_FULL_ADDRESS,
    FORM_ELEMENT_BUSINESS_ADDRESS_LINE1,
    FORM_ELEMENT_BUSINESS_ADDRESS_LINE2,
    FORM_ELEMENT_BUSINESS_ADDRESS_COUNTRY,
    FORM_ELEMENT_BUSINESS_PHONE,
    FORM_ELEMENT_BUSINESS_EMAIL,
    FORM_ELEMENT_CUSTOMER_NAME,
    FORM_ELEMENT_CUSTOMER_FULL_ADDRESS,
    FORM_ELEMENT_CUSTOMER_ADDRESS_LINE1,
    FORM_ELEMENT_CUSTOMER_ADDRESS_LINE2,
    FORM_ELEMENT_CUSTOMER_ADDRESS_COUNTRY,
    FORM_ELEMENT_CUSTOMER_PHONE,
    FORM_ELEMENT_CUSTOMER_EMAIL,
    FORM_ELEMENT_PAGE_NUMBER,
];

export const createElement = (type) => {
    return {
        id: uuid(),
        type: type,
        metadata: {},
        position: { w: 10, h: 5, minW: 2, }
    };
}

export const isPrintElement = ({ type }) => {
    return PrintElementTypes.some(t => t === type);
}

export const hasField = (type, field) => {
    switch (type) {
        case FORM_ELEMENT_RICHTEXT_EDITOR:
            return [
                FORM_TEMPLATE_METADATA_ALIGN,
                FORM_TEMPLATE_METADATA_VALIGN,
                FORM_TEMPLATE_METADATA_PADDING,
                FORM_TEMPLATE_METADATA_FONT_COLOR,
                FORM_TEMPLATE_METADATA_FONT_SIZE,
                FORM_TEMPLATE_METADATA_FONT_FAMILY,
                FORM_TEMPLATE_METADATA_FONT_LETTER_SPACING,
                FORM_TEMPLATE_METADATA_BACKGROUND_COLOR,
                FORM_TEMPLATE_METADATA_BORDER,
                FORM_TEMPLATE_METADATA_BORDER_COLOR,
                FORM_TEMPLATE_METADATA_CONTENT,
            ].some(f => f === field);

        case FORM_ELEMENT_IMAGE:
            return [
                FORM_TEMPLATE_METADATA_ALIGN,
                FORM_TEMPLATE_METADATA_VALIGN,
                FORM_TEMPLATE_METADATA_IMAGE,
            ].some(f => f === field);

        case FORM_ELEMENT_BUSINESS_NAME:
        case FORM_ELEMENT_BUSINESS_FULL_ADDRESS:
        case FORM_ELEMENT_BUSINESS_ADDRESS_LINE1:
        case FORM_ELEMENT_BUSINESS_ADDRESS_LINE2:
        case FORM_ELEMENT_BUSINESS_ADDRESS_COUNTRY:
        case FORM_ELEMENT_CUSTOMER_NAME:
        case FORM_ELEMENT_BUSINESS_EMAIL:
        case FORM_ELEMENT_CUSTOMER_FULL_ADDRESS:
        case FORM_ELEMENT_CUSTOMER_ADDRESS_LINE1:
        case FORM_ELEMENT_CUSTOMER_ADDRESS_LINE2:
        case FORM_ELEMENT_CUSTOMER_ADDRESS_COUNTRY:
        case FORM_ELEMENT_CUSTOMER_EMAIL:
            return [
                FORM_TEMPLATE_METADATA_ALIGN,
                FORM_TEMPLATE_METADATA_VALIGN,
                FORM_TEMPLATE_METADATA_PADDING,
                FORM_TEMPLATE_METADATA_FONT_COLOR,
                FORM_TEMPLATE_METADATA_FONT_SIZE,
                FORM_TEMPLATE_METADATA_FONT_FAMILY,
                FORM_TEMPLATE_METADATA_FONT_STYLE,
                FORM_TEMPLATE_METADATA_FONT_LETTER_SPACING,
                FORM_TEMPLATE_METADATA_BACKGROUND_COLOR,
                FORM_TEMPLATE_METADATA_BORDER,
                FORM_TEMPLATE_METADATA_BORDER_COLOR,
            ].some(f => f === field);

        case FORM_ELEMENT_BUSINESS_PHONE:
        case FORM_ELEMENT_CUSTOMER_PHONE:
        case FORM_ELEMENT_PAGE_NUMBER:
            return [
                FORM_TEMPLATE_METADATA_ALIGN,
                FORM_TEMPLATE_METADATA_VALIGN,
                FORM_TEMPLATE_METADATA_PADDING,
                FORM_TEMPLATE_METADATA_FONT_COLOR,
                FORM_TEMPLATE_METADATA_FONT_SIZE,
                FORM_TEMPLATE_METADATA_FONT_FAMILY,
                FORM_TEMPLATE_METADATA_FONT_STYLE,
                FORM_TEMPLATE_METADATA_FONT_LETTER_SPACING,
                FORM_TEMPLATE_METADATA_BACKGROUND_COLOR,
                FORM_TEMPLATE_METADATA_BORDER,
                FORM_TEMPLATE_METADATA_BORDER_COLOR,
                FORM_TEMPLATE_METADATA_FORMAT,
            ].some(f => f === field);

        default:
            return false;
    }
}

export const getName = (type) => {
    switch (type) {
        case FORM_ELEMENT_RICHTEXT_EDITOR:
            return 'Richtext';

        case FORM_ELEMENT_IMAGE:
            return 'Image';

        case FORM_ELEMENT_BUSINESS_NAME:
            return 'BusinessName';

        case FORM_ELEMENT_BUSINESS_FULL_ADDRESS:
            return 'BusinessFullAddress';

        case FORM_ELEMENT_BUSINESS_ADDRESS_LINE1:
            return 'BusinessAddressLine1';

        case FORM_ELEMENT_BUSINESS_ADDRESS_LINE2:
            return 'BusinessAddressLine2';

        case FORM_ELEMENT_BUSINESS_ADDRESS_COUNTRY:
            return 'BusinessAddressCountry';

        case FORM_ELEMENT_BUSINESS_PHONE:
            return 'BusinessPhone';

        case FORM_ELEMENT_BUSINESS_EMAIL:
            return 'BusinessEmail';

        case FORM_ELEMENT_CUSTOMER_NAME:
            return 'CustomerName';

        case FORM_ELEMENT_CUSTOMER_FULL_ADDRESS:
            return 'CustomerFullAddress';

        case FORM_ELEMENT_CUSTOMER_ADDRESS_LINE1:
            return 'CustomerAddressLine1';

        case FORM_ELEMENT_CUSTOMER_ADDRESS_LINE2:
            return 'CustomerAddressLine2';

        case FORM_ELEMENT_CUSTOMER_ADDRESS_COUNTRY:
            return 'CustomerAddressCountry';

        case FORM_ELEMENT_CUSTOMER_PHONE:
            return 'CustomerPhone';

        case FORM_ELEMENT_CUSTOMER_EMAIL:
            return 'CustomerEmail';

        case FORM_ELEMENT_PAGE_NUMBER:
            return 'PageNumber';

        default:
            return '';
    }
}

export const getDescription = (type) => {
    switch (type) {
        case FORM_ELEMENT_RICHTEXT_EDITOR:
            return 'Richtext';

        case FORM_ELEMENT_IMAGE:
            return 'Image';

        case FORM_ELEMENT_BUSINESS_NAME:
            return 'Business Name';

        case FORM_ELEMENT_BUSINESS_FULL_ADDRESS:
            return 'Business Full Address';

        case FORM_ELEMENT_BUSINESS_ADDRESS_LINE1:
            return 'Business Address Line 1';

        case FORM_ELEMENT_BUSINESS_ADDRESS_LINE2:
            return 'Business Address Line 2';

        case FORM_ELEMENT_BUSINESS_ADDRESS_COUNTRY:
            return 'Business Address Country';

        case FORM_ELEMENT_BUSINESS_PHONE:
            return 'Business Phone';

        case FORM_ELEMENT_BUSINESS_EMAIL:
            return 'Business Email';

        case FORM_ELEMENT_CUSTOMER_NAME:
            return 'Customer Name';

        case FORM_ELEMENT_CUSTOMER_FULL_ADDRESS:
            return 'Customer Full Address';

        case FORM_ELEMENT_CUSTOMER_ADDRESS_LINE1:
            return 'Customer Address Line 1';

        case FORM_ELEMENT_CUSTOMER_ADDRESS_LINE2:
            return 'Customer Address Line 2';

        case FORM_ELEMENT_CUSTOMER_ADDRESS_COUNTRY:
            return 'Customer Address Country';

        case FORM_ELEMENT_CUSTOMER_PHONE:
            return 'Customer Phone';

        case FORM_ELEMENT_CUSTOMER_EMAIL:
            return 'Customer Email';

        case FORM_ELEMENT_PAGE_NUMBER:
            return 'Page Number';

        default:
            return '';
    }
}

export const getIcon = (type) => {
    switch (type) {
        case FORM_ELEMENT_RICHTEXT_EDITOR:
            return 'fal fa-text-size';

        case FORM_ELEMENT_IMAGE:
            return 'fal fa-image';

        case FORM_ELEMENT_BUSINESS_NAME:
        case FORM_ELEMENT_BUSINESS_FULL_ADDRESS:
        case FORM_ELEMENT_BUSINESS_ADDRESS_LINE1:
        case FORM_ELEMENT_BUSINESS_ADDRESS_LINE2:
        case FORM_ELEMENT_BUSINESS_ADDRESS_COUNTRY:
        case FORM_ELEMENT_BUSINESS_PHONE:
        case FORM_ELEMENT_BUSINESS_EMAIL:
            return 'fal fa-building';

        case FORM_ELEMENT_CUSTOMER_NAME:
        case FORM_ELEMENT_CUSTOMER_FULL_ADDRESS:
        case FORM_ELEMENT_CUSTOMER_ADDRESS_LINE1:
        case FORM_ELEMENT_CUSTOMER_ADDRESS_LINE2:
        case FORM_ELEMENT_CUSTOMER_ADDRESS_COUNTRY:
        case FORM_ELEMENT_CUSTOMER_PHONE:
        case FORM_ELEMENT_CUSTOMER_EMAIL:
            return 'fal fa-user';

        case FORM_ELEMENT_PAGE_NUMBER:
            return 'fal fa-hashtag'

        default:
            return '';
    }
}

export const getDefaultValue = (type, format) => {
    const formatOption = getFormatOptions(type).filter(f => f.type === format)[0];

    switch (type) {
        case FORM_ELEMENT_RICHTEXT_EDITOR:
            return 'This is a sample text.';

        case FORM_ELEMENT_IMAGE:
            return '';

        case FORM_ELEMENT_BUSINESS_NAME:
            return 'Company Name';

        case FORM_ELEMENT_BUSINESS_FULL_ADDRESS:
            return '199 Main Street East, Toronto, ON M1N 2N2, Canada';

        case FORM_ELEMENT_BUSINESS_ADDRESS_LINE1:
            return '199 Main Street East';

        case FORM_ELEMENT_BUSINESS_ADDRESS_LINE2:
            return 'Toronto, ON M1N 2N2';

        case FORM_ELEMENT_BUSINESS_ADDRESS_COUNTRY:
            return 'Canada';

        case FORM_ELEMENT_BUSINESS_PHONE:
            return formatOption ? formatOption.example : '+14165559999';

        case FORM_ELEMENT_BUSINESS_EMAIL:
            return 'info@companyname.com';

        case FORM_ELEMENT_CUSTOMER_NAME:
            return 'Jonathan Williams';

        case FORM_ELEMENT_CUSTOMER_FULL_ADDRESS:
            return '355-20 Applegate Street, Toronto, ON M2M 3K3, Canada';

        case FORM_ELEMENT_CUSTOMER_ADDRESS_LINE1:
            return '355-20 Applegate Street';

        case FORM_ELEMENT_CUSTOMER_ADDRESS_LINE2:
            return 'Toronto, ON M2M 3K3';

        case FORM_ELEMENT_CUSTOMER_ADDRESS_COUNTRY:
            return 'Canada';

        case FORM_ELEMENT_CUSTOMER_PHONE:
            return formatOption ? formatOption.example : '+14166643333';

        case FORM_ELEMENT_CUSTOMER_EMAIL:
            return 'jwilliams_20@customeremail.com';

        case FORM_ELEMENT_PAGE_NUMBER:
            return formatOption ? formatOption.example : 'Page 1';

        default:
            return '';
    }
}

export const getValue = (element, sources) => {
    const { type, metadata } = element;
    const { format } = metadata;
    const formatOption = getFormatOptions(type).filter(f => f.type === format)[0];

    switch (type) {
        case FORM_ELEMENT_BUSINESS_NAME:
            return sources && sources.tenant ? applyFormat(sources.tenant.displayName, formatOption) : '';

        case FORM_ELEMENT_BUSINESS_FULL_ADDRESS:
            return sources && sources.tenant && sources.tenant.address && sources.tenant.address.fullLine ? applyFormat(sources.tenant.address.fullLine, formatOption) : null;

        case FORM_ELEMENT_BUSINESS_ADDRESS_LINE1:
            return sources && sources.tenant && sources.tenant.address && sources.tenant.address.addressLine1 ? applyFormat(sources.tenant.address.addressLine1, formatOption) : null;

        case FORM_ELEMENT_BUSINESS_ADDRESS_LINE2:
            return sources && sources.tenant && sources.tenant.address && sources.tenant.address.addressLine2 ? applyFormat(sources.tenant.address.addressLine2, formatOption) : null;

        case FORM_ELEMENT_BUSINESS_ADDRESS_COUNTRY:
            return sources && sources.tenant && sources.tenant.address && sources.tenant.address.country ? applyFormat(sources.tenant.address.country, formatOption) : null;

        case FORM_ELEMENT_BUSINESS_PHONE:
            return sources && sources.tenant ? applyFormat(sources.tenant.phoneNumber, formatOption) : null;

        case FORM_ELEMENT_BUSINESS_EMAIL:
            return sources && sources.tenant ? applyFormat(sources.tenant.emailAddress, formatOption) : null;

        case FORM_ELEMENT_CUSTOMER_NAME:
            return 'Jonathan Williams';

        case FORM_ELEMENT_CUSTOMER_FULL_ADDRESS:
            return '355-20 Applegate Street, Toronto, ON M2M 3K3, Canada';

        case FORM_ELEMENT_CUSTOMER_ADDRESS_LINE1:
            return '355-20 Applegate Street';

        case FORM_ELEMENT_CUSTOMER_ADDRESS_LINE2:
            return 'Toronto, ON M2M 3K3';

        case FORM_ELEMENT_CUSTOMER_ADDRESS_COUNTRY:
            return 'Canada';

        case FORM_ELEMENT_CUSTOMER_PHONE:
            return formatOption ? formatOption.example : '+14166643333';

        case FORM_ELEMENT_CUSTOMER_EMAIL:
            return 'jwilliams_20@customeremail.com';

        case FORM_ELEMENT_PAGE_NUMBER:
            return formatOption ? formatOption.example : 'Page 1';

        default:
            return '';
    }
}

export const getFormatOptions = (type) => {
    switch (type) {
        case FORM_ELEMENT_BUSINESS_PHONE:
        case FORM_ELEMENT_CUSTOMER_PHONE:
            return [{
                type: 'PhoneInternational',
                example: '+14165559999'
            }, {
                type: 'PhoneDash',
                example: '416-555-9999',
            }, {
                type: 'PhoneBrackets',
                example: '(416) 555-9999',
            }, {
                type: 'PhoneDashPlusOne',
                example: '1-416-555-9999',
            }, {
                type: 'PhoneBracketsPlusOne',
                example: '1 (416) 555-9999',
            }];

        case FORM_ELEMENT_PAGE_NUMBER:
            return [{
                type: 'PageNumberOnly',
                example: '1',
            }, {
                type: 'PrefixPageNumberOnly',
                example: 'Page 1',
            }, {
                type: 'PageNumberAndTotal',
                example: '1 of 1',
            }, {
                type: 'PrefixPageNumberAndTotal',
                example: 'Page 1 of 1',
            }]

        default:
            return [];
    }
}

export const renderElement = (element, options) => {
    const defaults = {
        controlKey: `_${uuid()}`,
        readOnly: null,
        isDesignMode: true,
        defaultValue: null,
        quickDrawer: null,
        onClick: null,
        onDoubleClick: null,
        onContextMenu: null,
    };
    options = { ...defaults, ...options }

    if (options.pageSettings) {
        options.pageSettings.paper = options.pageSettings.paper ? options.pageSettings.paper : getPaperDimension('letter');
        options.pageSettings.margins = options.pageSettings.margins ? options.pageSettings.margins : FORM_TEMPLATE_PAGE_SETTINGS_MARGINS_NORMAL;
    } else {
        options.pageSettings = {
            paper: getPaperDimension('letter'),
            margins: FORM_TEMPLATE_PAGE_SETTINGS_MARGINS_NORMAL,
        };
    }

    switch (element.type) {
        case FORM_ELEMENT_RICHTEXT_EDITOR:
        case FORM_ELEMENT_BUSINESS_NAME:
        case FORM_ELEMENT_BUSINESS_FULL_ADDRESS:
        case FORM_ELEMENT_BUSINESS_ADDRESS_LINE1:
        case FORM_ELEMENT_BUSINESS_ADDRESS_LINE2:
        case FORM_ELEMENT_BUSINESS_ADDRESS_COUNTRY:
        case FORM_ELEMENT_BUSINESS_PHONE:
        case FORM_ELEMENT_BUSINESS_EMAIL:
        case FORM_ELEMENT_CUSTOMER_NAME:
        case FORM_ELEMENT_CUSTOMER_FULL_ADDRESS:
        case FORM_ELEMENT_CUSTOMER_ADDRESS_LINE1:
        case FORM_ELEMENT_CUSTOMER_ADDRESS_LINE2:
        case FORM_ELEMENT_CUSTOMER_ADDRESS_COUNTRY:
        case FORM_ELEMENT_CUSTOMER_PHONE:
        case FORM_ELEMENT_CUSTOMER_EMAIL:
        case FORM_ELEMENT_PAGE_NUMBER:
            return <div
                className={'form-group print-element no-label' + (element.metadata && element.metadata.valign && element.metadata.valign !== 'top' ? ` text-${element.metadata.valign}` : '')}
                style={{
                    borderTop: element.metadata && element.metadata.border && element.metadata.border.top ? element.metadata.border.top : 'none',
                    borderRight: element.metadata && element.metadata.border && element.metadata.border.right ? element.metadata.border.right : 'none',
                    borderBottom: element.metadata && element.metadata.border && element.metadata.border.bottom ? element.metadata.border.bottom : 'none',
                    borderLeft: element.metadata && element.metadata.border && element.metadata.border.left ? element.metadata.border.left : 'none',
                    backgroundColor: element.metadata && element.metadata.backgroundColor ? element.metadata.backgroundColor : 'transparent',
                }}
                data-id={element.id}
                onDoubleClick={options ? options.onDoubleClick : null}
                onContextMenu={options ? options.onContextMenu : null}
            >
                <div
                    data-key={`pe_${element.id}`}
                    className='element-wrapper'
                    style={{
                        lineHeight: 1.2,
                        textAlign: element.metadata && element.metadata.align ? element.metadata.align : 'left',
                        padding: element.metadata && element.metadata.padding && element.metadata.padding.length === 4 ? element.metadata.padding.map(p => { return `${p}px` }).join(' ') : null,
                        color: element.metadata && element.metadata.fontColor ? element.metadata.fontColor : null,
                        fontSize: element.metadata && element.metadata.fontSize ? `${element.metadata.fontSize}px` : null,
                        fontFamily: element.metadata && element.metadata.fontFamily ? element.metadata.fontFamily : null,
                        fontWeight: element.metadata && element.metadata.fontStyle && element.metadata.fontStyle.some(s => s === 'bold') ? 'bold' : null,
                        fontStyle: element.metadata && element.metadata.fontStyle && element.metadata.fontStyle.some(s => s === 'italic') ? 'italic' : null,
                        textDecoration: element.metadata && element.metadata.fontStyle && element.metadata.fontStyle.some(s => s === 'underline') ? 'underline' : null,
                        letterSpacing: element.metadata && element.metadata.fontLetterSpacing ? `${element.metadata.fontLetterSpacing}px` : null,
                    }}
                >
                    {
                        element.type === FORM_ELEMENT_RICHTEXT_EDITOR ?
                            <span dangerouslySetInnerHTML={{ __html: element.metadata.content }}></span> :
                            <span><p>{options.defaultValue ? options.defaultValue : getDefaultValue(element.type, (element.metadata && element.metadata.format ? element.metadata.format : null))}</p></span>
                    }
                </div>
            </div>

        case FORM_ELEMENT_IMAGE:
            return <div
                className={'form-group print-element no-label' + (element.metadata && element.metadata.valign && element.metadata.valign !== 'top' ? ` text-${element.metadata.valign}` : '')}
                data-id={element.id}
                onDoubleClick={options ? options.onDoubleClick : null}
                onContextMenu={options ? options.onContextMenu : null}
            >
                <div
                    data-key={`pe_${element.id}`}
                    className='element-wrapper'
                    style={{
                        textAlign: element.metadata && element.metadata.align ? element.metadata.align : 'left',
                    }}
                >
                    <img
                        src={`${(element.metadata && element.metadata.image ? element.metadata.image.base64 : null)}`}
                        style={{
                            width: (element.metadata && element.metadata.image ? getContainImage(element, options.pageSettings).width : null),
                            height: (element.metadata && element.metadata.image ? getContainImage(element, options.pageSettings).height : null),
                        }}
                        alt='print-element'
                    />
                </div>
            </div>


        default:
            break;
    }
}

export const getDefaultPosition = type => {
    switch (type) {
        case FORM_ELEMENT_IMAGE:
            return { w: 10, h: 10, minW: 1 };

        case FORM_ELEMENT_RICHTEXT_EDITOR:
            return { w: 20, h: 10, minW: 2 };

        case FORM_ELEMENT_BUSINESS_NAME:
        case FORM_ELEMENT_BUSINESS_PHONE:
        case FORM_ELEMENT_BUSINESS_EMAIL:
        case FORM_ELEMENT_CUSTOMER_NAME:
        case FORM_ELEMENT_CUSTOMER_PHONE:
        case FORM_ELEMENT_CUSTOMER_EMAIL:
            return { w: 6, h: 4, minW: 2 };

        case FORM_ELEMENT_BUSINESS_FULL_ADDRESS:
        case FORM_ELEMENT_CUSTOMER_FULL_ADDRESS:
            return { w: 20, h: 10, minW: 2 };

        case FORM_ELEMENT_BUSINESS_ADDRESS_LINE1:
        case FORM_ELEMENT_BUSINESS_ADDRESS_LINE2:
        case FORM_ELEMENT_BUSINESS_ADDRESS_COUNTRY:
        case FORM_ELEMENT_CUSTOMER_ADDRESS_LINE1:
        case FORM_ELEMENT_CUSTOMER_ADDRESS_LINE2:
        case FORM_ELEMENT_CUSTOMER_ADDRESS_COUNTRY:
            return { w: 12, h: 4, minW: 2 };

        default:
            return {};
    }
}

export const getContainImage = (imageElement, pageSettings) => {
    let { width: imageWidth, height: imageHeight } = imageElement.metadata.image;
    const containerWidth = parseFloat(imageElement.position.w * ((pageSettings.paper.width - (pageSettings.margins * 2)) / 64), 10);
    const containerHeight = parseFloat(imageElement.position.h * FORM_TEMPLATE_ROW_HEIGHT, 10);

    if (containerHeight >= containerWidth) {
        if (imageHeight > containerHeight) {
            const heightScale = containerHeight / imageHeight;

            imageWidth = imageWidth * heightScale;
            imageHeight = containerHeight;
        }

        if (imageWidth > containerWidth) {
            const scale = containerWidth / imageWidth;

            imageWidth = containerWidth;
            imageHeight = imageHeight * scale;
        }
    }
    else if (containerWidth > containerHeight) {
        if (imageWidth > containerWidth) {
            const widthScale = containerWidth / imageWidth;

            imageWidth = containerWidth;
            imageHeight = imageHeight * widthScale;
        }

        if (imageHeight > containerHeight) {
            const scale = containerHeight / imageHeight;

            imageWidth = imageWidth * scale;
            imageHeight = containerHeight;
        }
    }

    return {
        width: imageWidth,
        height: imageHeight,
    };
}

export const getPaperDimension = (paperType) => {
    if (!paperType) {
        paperType = 'letter';
    }

    switch (paperType.toLowerCase()) {
        case 'a4':
            return {
                type: paperType.toLowerCase(),
                width: FORM_TEMPLATE_PAGE_SETTINGS_PAPER_A4_WIDTH,
                height: FORM_TEMPLATE_PAGE_SETTINGS_PAPER_A4_HEIGHT,
            };

        case 'legal':
            return {
                type: paperType.toLowerCase(),
                width: FORM_TEMPLATE_PAGE_SETTINGS_PAPER_LEGAL_WIDTH,
                height: FORM_TEMPLATE_PAGE_SETTINGS_PAPER_LEGAL_HEIGHT,
            };

        case 'letter':
        default:
            return {
                type: paperType.toLowerCase(),
                width: FORM_TEMPLATE_PAGE_SETTINGS_PAPER_LETTER_WIDTH,
                height: FORM_TEMPLATE_PAGE_SETTINGS_PAPER_LETTER_HEIGHT,
            };
    }
}

export const isValidDestination = (access, destination) => {
    switch (access) {
        case 'Anonymous':
            return FORM_TEMPLATE_VALID_ANONYMOUS_ACCESS.some(d => d === destination);

        case 'System':
            return FORM_TEMPLATE_VALID_SYSTEM_ACCESS.some(d => d === destination);

        default:
            return false;
    }
}

export const getAccessTypeDescription = ({ key, value }) => {
    switch (key) {
        case 'Anonymous':
            return 'Anyone who has the public link';

        case 'System':
            return 'Only people who received a system generated link';

        default:
            return value;
    }
}

export const getDestinationTypeDescription = ({ key, value }) => {
    switch (key) {
        case 'Email':
            return 'Email result as PDF';

        case 'Database':
            return 'Update customer information';

        case 'Attachment':
            return 'Save result as PDF and attach to customer';

        default:
            return value;
    }
}

export const getDestinationSettingKeys = (destinations) => {
    const keys = [];

    if (destinations) {
        if (destinations.some(d => d === 'Email')) {
            keys.push('To');
            keys.push('FileName');
            keys.push('Subject');
        }
    }

    return keys;
}

export const applyFormat = (value, format) => {
    if (!value || !format || !format.type) return value;

    switch (format.type) {
        case 'PhoneInternational':
        case 'PhoneDash':
        case 'PhoneBrackets':
        case 'PhoneDashPlusOne':
        case 'PhoneBracketsPlusOne':
            let unformattedPhone = value.replace(/\D/g, '');

            switch (format.type) {
                case 'PhoneInternational':
                    if (unformattedPhone.length === 10) {
                        return `+1${unformattedPhone}`;
                    }
                    else if (unformattedPhone.length === 11 && unformattedPhone[0] === '1') {
                        return `+${unformattedPhone}`;
                    }
                    break;

                case 'PhoneDash':
                    if (unformattedPhone.length === 10) {
                        return `${(unformattedPhone.substr(0, 1) === '8' ? '1-' : '')}${unformattedPhone.substr(0, 3)}-${unformattedPhone.substr(3, 3)}-${unformattedPhone.substr(6)}`;
                    }
                    else if (unformattedPhone.length === 11 && unformattedPhone[0] === '1') {
                        return `${(unformattedPhone.substr(1, 1) === '8' ? '1-' : '')}${unformattedPhone.substr(1, 3)}-${unformattedPhone.substr(4, 3)}-${unformattedPhone.substr(7)}`;
                    }
                    break;

                case 'PhoneBrackets':
                    if (unformattedPhone.length === 10) {
                        return `${(unformattedPhone.substr(0, 1) === '8' ? '1 ' : '')}(${unformattedPhone.substr(0, 3)}) ${unformattedPhone.substr(3, 3)}-${unformattedPhone.substr(6)}`;
                    }
                    else if (unformattedPhone.length === 11 && unformattedPhone[0] === '1') {
                        return `${(unformattedPhone.substr(1, 1) === '8' ? '1 ' : '')}(${unformattedPhone.substr(1, 3)}) ${unformattedPhone.substr(4, 3)}-${unformattedPhone.substr(7)}`;
                    }
                    break;

                case 'PhoneDashPlusOne':
                    if (unformattedPhone.length === 10) {
                        return `1-${unformattedPhone.substr(0, 3)}-${unformattedPhone.substr(3, 3)}-${unformattedPhone.substr(6)}`;
                    }
                    else if (unformattedPhone.length === 11 && unformattedPhone[0] === '1') {
                        return `1-${unformattedPhone.substr(1, 3)}-${unformattedPhone.substr(4, 3)}-${unformattedPhone.substr(7)}`;
                    }
                    break;

                case 'PhoneBracketsPlusOne':
                    if (unformattedPhone.length === 10) {
                        return `1-(${unformattedPhone.substr(0, 3)}) ${unformattedPhone.substr(3, 3)}-${unformattedPhone.substr(6)}`;
                    }
                    else if (unformattedPhone.length === 11 && unformattedPhone[0] === '1') {
                        return `1-(${unformattedPhone.substr(1, 3)}) ${unformattedPhone.substr(4, 3)}-${unformattedPhone.substr(7)}`;
                    }
                    break;

                default:
                    return value;
            }
            break;

        case 'DashYearMonthDay':
        case 'SlashYearMonthDay':
        case 'FullDate':
        case 'ShortDate':
            let date = moment(value);

            switch (format.type) {
                case 'DashYearMonthDay':
                    return date.format("YYYY-MM-DD");

                case 'SlashYearMonthDay':
                    return date.format("YYYY/MM/DD");

                case 'FullDate':
                    return date.format("MMMM D, YYYY");

                case 'ShortDate':
                    return date.format("MMM D, YYYY");

                default:
                    break;
            }
            break;

        case 'PlusMinus':
        case 'PlusMinusPlusZero':
            let field = value;

            if (Number.isInteger(field) || Number.IsDecimal(field) || Number.IsDouble(field)) {
                switch (format.type) {
                    case 'PlusMinus':
                        return `${(parseFloat(field) > 0 ? ' +' : '')}${field}`.replace('++', '+');

                    case 'PlusMinusPlusZero':
                        return `${(parseFloat(field) >= 0 ? ' +' : '')}${field}`.replace('++', '+');

                    default:
                        break;
                }
            }
            break;

        default:
            break;
    }

    return value;
}