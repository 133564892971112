import React, { useContext, useEffect, useRef, useState } from 'react';
import FadeIn from 'react-fade-in';
import { Observer } from 'mobx-react-lite';
import { GlobalHotKeys } from 'react-hotkeys';
import moment from 'moment';

import BodyEnd from '../../../../_shared/BodyEnd';
import ConfirmModal from '../../../../_shared/ConfirmModal';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../../../_shared/QuickDrawer';
import QuickDrawerHeader from '../../../../_shared/QuickDrawerHeader';

import QuickDrawerStore from '../../../../../../stores/QuickDrawerStore';
import TenantCreateUpdateStore from '../../../../../../stores/TenantCreateUpdateStore';
import OrganizationViewStore from '../../../../../../stores/OrganizationViewStore';
import AuthStore from '../../../../../../stores/AuthStore';

import * as ErrorMessages from '../../../../../../constants/errorMessages';
import * as MaskKeys from '../../../../../../constants/maskKeys';
import * as fn from '../../../../../../utilities/_functions';
import * as ah from '../../../../../../utilities/accessHelper';
import * as oh from '../../../../../../utilities/operationHelper';

import './CreateUpdateTenant.scss';
import MaskedInput from 'react-text-mask';
import { toast } from 'react-toastify';
import useSignalR from '../../../../../hooks/useSignalR';

moment.locale('en');

function CreateUpdateTenant(props) {
    const auth = useContext(AuthStore);
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const tenant = useContext(TenantCreateUpdateStore);
    const organizationStore = useContext(OrganizationViewStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [confirmDeleteTenant, setConfirmDeleteTenant] = useState(null);
    const [isAddressInformationActive, setIsAddressInformationActive] = useState(false);
    const [ready, setReady] = useState(false);
    const signalR = useSignalR();

    const hasFullAccess = ah.hasFullAccess(auth.currentUser.id);

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (tenant.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (fn.validateForm(validateRef.current)) {
            if (!tenant.data.id && !tenant.newAddress.geocode) {
                window.alert('You must provide an address.');
                return;
            }

            try {
                const data = await tenant.save(true);
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, data);
                    }
                }
            }
            catch (e) {
                // The backend may return 409 if there's a name conflict
                window.alert(e.Message || e);
            }
        }
    }
    const handlePhoneNumberChange = (event) => {
        tenant.data.phoneNumber = event.target.value;
        tenant.hasUnsavedChanges = true;
    }
    const handleFaxNumberChange = (event) => {
        tenant.data.faxNumber = event.target.value;
        tenant.hasUnsavedChanges = true;
    }
    const handleEmailAddressChange = (event) => {
        tenant.data.emailAddress = event.target.value;
        tenant.hasUnsavedChanges = true;
    }
    const handleEmailPrefixChange = (event) => {
        tenant.data.emailPrefix = event.target.value;
        tenant.hasUnsavedChanges = true;
    }
    const handleLegalNameChange = (event) => {
        tenant.data.legalName = event.target.value;
        tenant.hasUnsavedChanges = true;
    }
    const handleOperatingAsNameChange = (event) => {
        tenant.data.operateAsName = event.target.value;
        tenant.hasUnsavedChanges = true;
    }
    const handlePublicInsuranceConsentChange = (event) => {
        tenant.data.publicInsuranceConsent = !tenant.data.publicInsuranceConsent;
        tenant.hasUnsavedChanges = true;
    }
    const handlePublicInsuranceReadyChange = (event) => {
        tenant.data.publicInsuranceReady = !tenant.data.publicInsuranceReady;
        tenant.hasUnsavedChanges = true;
    }
    const handleEndOfDayHourChange = (event) => {
        tenant.data.endOfDayHour = event.target.value;
        tenant.hasUnsavedChanges = true;
    }
    const handlePublicInsuranceGroupNumberChange = (event) => {
        tenant.data.publicInsuranceGroupNumber = event.target.value;
        tenant.hasUnsavedChanges = true;
    }
    const handleOrganizationChange = (event) => {
        const orgId = event.target.value;
        tenant.data.organizationId = orgId;
    }
    const handleAddressInformation = () => {
        setIsAddressInformationActive(true);
        quickDrawer.activateQuickDrawer('tenant', 'address', null, handleAddressInformationUpdateSuccess, handleAddressInformationUpdateCancel);
    }

    const handleAddressInformationUpdateSuccess = (result) => {
        if (tenant.data.id && result && result.updated) {
            toast.dark(() => <p data-addr-upd>Customer's address updated.</p>);
            tenant.clearNewAddress();
        }
        setIsAddressInformationActive(false);
    }

    const handleAddressInformationUpdateCancel = () => {
        setIsAddressInformationActive(false);
    }

    const loadData = async () => {
        const item = props.extraProps;
        await organizationStore.refresh();
        await tenant.initialize(item);
        setReady(true);
        quickDrawerFocus(props.drawer);
    }

    useEffect(() => {
        
        signalR.on('Tenant', (updated) => {
            if (updated && tenant && tenant.id === updated.id) {
                tenant.refresh();
            }
        });
        loadData();

        return () => {
            isMounted.current = false;
            tenant.clear();
        }
    }, []) // eslint-disable-line

    const handleDeleteTenant = (id) => {
        setConfirmDeleteTenant(id);
    }

    const handleConfirmDeleteTenants = async (e) => {
        await tenant.delete([{ id: confirmDeleteTenant }]);
        if (isMounted.current) {
            setConfirmDeleteTenant(null);
            if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                props.onSuccess(e);
            }
        }
    }

    const handleConfirmDeleteTenantCancel = () => {
        setConfirmDeleteTenant(null);
    }

    return <>
        <Observer>{() =>
            ready &&
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
                <form ref={validateRef} onSubmit={handleSubmit}>
                    <fieldset disabled={tenant.isSaving}>
                        <div className='quick-drawer'>
                            <QuickDrawerHeader
                                drawer={props.drawer}
                                icon={oh.getIcon('tenant', 'new')}
                                action={props.extraProps ? 'Update' : 'Add new'}
                                category='Tenant'
                                className='tenants'
                                onCancel={handleCancel}
                            />
                            <div className='quick-drawer-body'>
                                {
                                    tenant.isReady ?
                                        <FadeIn>
                                            <div className='update-tenant body-content'>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-tenant mb-0 validate validate-required'>
                                                                <label className='required'><small>Legal Name</small></label>
                                                                <input
                                                                    id='tenant-legal_name'
                                                                    type='text'
                                                                    className='form-control'
                                                                    maxLength='50'
                                                                    autoComplete='off'
                                                                    value={tenant.data.legalName}
                                                                    onChange={handleLegalNameChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-tenant mb-0 validate validate-required'>
                                                                <label className='required'><small>Operate as Name</small></label>
                                                                <input
                                                                    id='tenant-operate-as-name'
                                                                    type='text'
                                                                    className='form-control'
                                                                    maxLength='50'
                                                                    autoComplete='off'
                                                                    value={tenant.data.operateAsName}
                                                                    onChange={handleOperatingAsNameChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                { hasFullAccess &&
                                                    <section>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className='form-tenant mb-0 validate validate-required'>
                                                                    <label className='required'><small>Parent Organization</small></label>
                                                                    <select
                                                                        id='update-organization-select'
                                                                        className='custom-select form-control'
                                                                        value={tenant.data.organizationId || ''}
                                                                        onChange={handleOrganizationChange}
                                                                    >
                                                                        <option value=''>Not selected</option>
                                                                        {organizationStore.data.map(d => <option value={d.id}>{d.legalName}</option>)}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                }
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-0 validate validate-email validate-required'>
                                                                <label className='required' htmlFor='new-contact-primary-email'><small>Primary Email</small></label>
                                                                <MaskedInput
                                                                    id='new-contact-primary-email'
                                                                    type='text'
                                                                    className='form-control'
                                                                    spellCheck={false}
                                                                    mask={MaskKeys.EMAIL_MASK}
                                                                    maxLength='150'
                                                                    autoComplete='off'
                                                                    value={tenant.data.emailAddress ? tenant.data.emailAddress : ''}
                                                                    onChange={handleEmailAddressChange}
                                                                // onBlur={checkConflicts}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-0 validate validate-required'>
                                                                <label className='required' htmlFor='tenant-email-prefix'><small>Email Prefix</small></label>
                                                                <input
                                                                    id='tenant-email-prefix'
                                                                    type='text'
                                                                    className='form-control'
                                                                    maxLength='80'
                                                                    autoComplete='off'
                                                                    value={tenant.data.emailPrefix}
                                                                    onChange={handleEmailPrefixChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-0 validate validate-phone validate-required'>
                                                                <label className='required' htmlFor='new-contact-primary-phone'><small>Phone Number</small></label>
                                                                <MaskedInput
                                                                    id='new-contact-primary-phone'
                                                                    type='text'
                                                                    className='form-control'
                                                                    spellCheck={false}
                                                                    mask={MaskKeys.PHONE_MASK}
                                                                    autoComplete='off'
                                                                    value={tenant.data.phoneNumber || ''}
                                                                    onChange={handlePhoneNumberChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-0 validate validate-phone'>
                                                                <label htmlFor='new-contact-fax'><small>Fax Phone</small></label>
                                                                <MaskedInput
                                                                    id='new-contact-fax'
                                                                    type='text'
                                                                    className='form-control'
                                                                    spellCheck={false}
                                                                    mask={MaskKeys.PHONE_MASK}
                                                                    autoComplete='off'
                                                                    value={tenant.data.faxNumber || ''}
                                                                    onChange={handleFaxNumberChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-tenant mb-0'>
                                                                <label><small>Public health insurance number</small></label>
                                                                <input
                                                                    id='public-insurance-num'
                                                                    type='text'
                                                                    className='form-control'
                                                                    maxLength='50'
                                                                    autoComplete='off'
                                                                    value={tenant.data.publicInsuranceGroupNumber}
                                                                    onChange={handlePublicInsuranceGroupNumberChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-tenant mb-0'>
                                                                <label><small>End Of Day Hour (24 hr format)</small></label>
                                                                <input
                                                                    id='end-of-day-hour'
                                                                    type='number'
                                                                    className='form-control'
                                                                    maxLength='50'
                                                                    autoComplete='off'
                                                                    value={tenant.data.endOfDayHour}
                                                                    onChange={handleEndOfDayHourChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-check'>
                                                                <input
                                                                    id='is-bookable'
                                                                    type="checkbox"
                                                                    name='is-bookable'
                                                                    className='form-check-input'
                                                                    defaultChecked={tenant.data.publicInsuranceConsent}
                                                                    onChange={(evt) => handlePublicInsuranceConsentChange(evt)}
                                                                />
                                                                <label className='form-check-label' htmlFor='is-bookable'>Consent to submit billing data?</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-check'>
                                                                <input
                                                                    id='is-bookable'
                                                                    type="checkbox"
                                                                    name='is-bookable'
                                                                    className='form-check-input'
                                                                    defaultChecked={tenant.data.publicInsuranceReady}
                                                                    onChange={(evt) => handlePublicInsuranceReadyChange(evt)}
                                                                />
                                                                <label className='form-check-label' htmlFor='is-bookable'>Public insurance account ready?</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </FadeIn> : renderQuickDrawerLoading()
                                }
                            </div>
                            <div className='quick-drawer-action'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='float-left'>
                                            <button
                                                type='button'
                                                className={'btn btn-icon' + (isAddressInformationActive ? ' text-primary' : '')}
                                                title='Address information'
                                                onClick={handleAddressInformation}
                                            >
                                                <i className={oh.getIcon('customer', 'address')}></i>
                                            </button>
                                        </div>
                                        <div className='float-right'>
                                            {
                                                hasFullAccess && props.extraProps &&
                                                <button
                                                    type='button'
                                                    className='btn btn-danger mr-4'
                                                    onClick={() => handleDeleteTenant(props.extraProps.id)}
                                                >Delete</button>
                                            }
                                            <button
                                                type='button'
                                                className='btn btn-link btn-cancel mr-2'
                                                onClick={handleCancel}
                                            >Cancel</button>
                                            <button
                                                type='submit'
                                                className='btn btn-success'
                                            >Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form >
                <BodyEnd>
                    <ConfirmModal
                        icon={<i className={'text-danger mr-2 ' + oh.getIcon('tenant', 'delete')}></i>}
                        message={<>Continue to delete <span className='fw-500'>&nbsp;{tenant.data ? tenant.data.legalName : ''}</span>? </>}
                        descriptionClassName='warning-danger warning-flashing'
                        show={confirmDeleteTenant}
                        onOption1Click={handleConfirmDeleteTenants}
                        onCancel={handleConfirmDeleteTenantCancel}
                    />
                </BodyEnd>
            </>
        }</Observer>
    </>
}

export default CreateUpdateTenant;
