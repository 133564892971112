import React, { useEffect, useContext, useState, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { GlobalHotKeys } from 'react-hotkeys';
import { useObserver } from 'mobx-react-lite';
import * as pdfjsLib from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

import QuickDrawerHeader from '../../../../_shared/QuickDrawerHeader';

import InternalFormTemplateUpdateStore from '../../../../../../stores/InternalFormTemplateUpdateStore';
import QuickDrawerStore from '../../../../../../stores/QuickDrawerStore';

import * as fn from '../../../../../../utilities/_functions';
import * as ith from '../../../../../../utilities/internalFormTemplateHelper';

import './ImportPdf.scss';

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

function ImportPdf(props) {
    const isMounted = useRef(true);
    const canvasRef = useRef(null);
    const internalFormTemplateUpdate = useContext(InternalFormTemplateUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [pdfFile, setPdfFile] = useState(null);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            props.onCancel();
            setPdfFile(null);
        }
    }

    const handleSubmit = async event => {
        event.preventDefault();
        if (isMounted.current) {
            if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                const images = [];
                const arrayBuffer = await pdfFile.arrayBuffer();
                const pdfDoc = await pdfjsLib.getDocument(arrayBuffer).promise;

                if (isMounted.current && pdfDoc && pdfDoc.numPages > 0) {
                    for (let pi = 0; pi < pdfDoc.numPages; pi++) {
                        const pageNumber = pi + 1;
                        const pdfPage = await pdfDoc.getPage(pageNumber);

                        if (!internalFormTemplateUpdate.data.pages[pi]) {
                            internalFormTemplateUpdate.addPage(`Page ${(pi + 1)}`);
                        }

                        const page = internalFormTemplateUpdate.data.pages[pi];
                        const { pageSettings } = page;
                        const pdfWidth = pdfPage.view[2];
                        const pdfHeight = pdfPage.view[3];
                        const { width, height } = pageSettings && pageSettings.paper ? pageSettings.paper : ith.getPaperDimension('letter');
                        let scale = 1.0;

                        if (pdfHeight >= pdfWidth) {
                            if (height !== pdfHeight) {
                                scale = height / pdfHeight;
                            }
                            else if (width !== pdfWidth) {
                                scale = width / pdfWidth;
                            }
                        }
                        else if (pdfWidth > pdfHeight) {
                            if (width !== pdfWidth) {
                                scale = width / pdfWidth;
                            }
                            else if (height !== pdfHeight) {
                                scale = height / pdfHeight;
                            }
                        }

                        const viewport = pdfPage.getViewport({ scale: scale });
                        const canvas = canvasRef.current;
                        const context = canvas.getContext('2d');
                        canvas.height = viewport.height;
                        canvas.width = viewport.width;

                        const renderContext = {
                            canvasContext: context,
                            viewport: viewport,
                        };

                        await pdfPage.render(renderContext).promise;
                        // Convert the canvas to a JPEG DataURL
                        images.push({
                            width: canvas.width,
                            height: canvas.height,
                            base64: canvas.toDataURL('image/jpeg')
                        });
                    }
                }

                props.onSuccess(event, { images: images });
                setPdfFile(null);
            }
        }
    }

    const handlePdfChange = event => {
        const file = event.target.files[0];
        setPdfFile(file);
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form onSubmit={handleSubmit}>
            <fieldset>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={'fal fa-file-pdf'}
                        action='Import'
                        category='PDF to Internal Form'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        <FadeIn>
                            <div className='internal-form-import-pdf body-content'>
                                <section>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='form-group mb-4'>
                                                <label htmlFor='internal-form-setting-pdf-file'><small>PDF File</small></label>
                                                <div className='custom-file'>
                                                    <input
                                                        id='internal-form-setting-pdf-file'
                                                        type='file'
                                                        className='custom-file-input custom-file-input-sm'
                                                        accept='.pdf'
                                                        onChange={handlePdfChange} />
                                                    <label
                                                        htmlFor='internal-form-setting-pdf-file'
                                                        className='custom-file-label'>
                                                        {pdfFile ? <span className='d-block text-truncate text-truncate-xl'>{pdfFile.name}</span> : null}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <canvas ref={canvasRef}></canvas>
                        </FadeIn>
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-4'></div>
                            <div className='col-8'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                    >Import</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>)
}

export default ImportPdf;