import { createContext } from 'react';
import { decorate, observable, action, toJS } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class ProductSupplierCreate {
    data = {
        id: null,
        name: null,
        accountNumber: null,
        contactPerson: null,
        address: {
            geocode: null,
            streetNumber: null,
            streetName: null,
            unitSuiteType: null,
            unitSuite: null,
            line2: null,
            locality: null,
            sublocality: null,
            postalCode: null,
            region: null,
            country: null,
        },
        existingAddress: null,
        primaryPhone: null,
        secondaryPhone: null,
        fax: null,
        emailAddress: null,
        websiteUrl: null,
        noteHtml: null,
    };
    originalData = null;
    hasUnsavedChanges = false;
    isReady = false;
    isSaving = false;
    isLoading = false;
    saveToServer = true;

    cancelProductSupplierCreate = null;
    cancelProductSupplierSearch = null;
    cancelProductSupplierUpdate = null;
    cancelProductSupplierDelete = null;

    initialize = (saveToServer) => {
        this.clear();

        if (!fn.isNullOrUndefined(saveToServer)) {
            this.saveToServer = saveToServer;
        }

        this.isReady = true;

        return Promise.resolve();
    }

    load = (name) => {
        const that = this;

        this.isLoading = true;

        return new Promise((resolve, reject) => {
            api.ProductSuppliers.search({
                parameters: [{
                    field: 'Name',
                    value: name,
                }, {
                    field: 'DeactivatedDateUtc',
                    value: null,
                }],
                includeTotalCount: false,
                loadProperties: true,
            }, (c) => { that.cancelProductSupplierSearch = c })
                .then(({ data }) => {
                    if (data && data.result && data.result.length > 0) {
                        that.data.id = data.result[0].id;
                        that.data.name = data.result[0].name;
                        that.data.accountNumber = data.result[0].accountNumber;
                        that.data.contactPerson = data.result[0].contactPerson;
                        that.data.existingAddress = data.result[0].address ? data.result[0].address : null;
                        that.data.primaryPhone = data.result[0].primaryPhoneNumber ? data.result[0].primaryPhoneNumber.number : null;
                        that.data.secondaryPhone = data.result[0].secondaryPhoneNumber ? data.result[0].secondaryPhoneNumber.number : null;
                        that.data.fax = data.result[0].faxNumber ? data.result[0].faxNumber.number : null;
                        that.data.emailAddress = data.result[0].emailAddress;
                        that.data.websiteUrl = data.result[0].websiteUrl;
                        that.data.noteHtml = data.result[0].noteHtml;
                        that.clearAddress();
                        that.originalData = JSON.parse(JSON.stringify(that.data));
                    } else {
                        that.data.id = null;
                        that.data.accountNumber = null;
                        that.data.contactPerson = null;
                        that.data.existingAddress = null;
                        that.data.primaryPhone = null;
                        that.data.secondaryPhone = null;
                        that.data.fax = null;
                        that.data.emailAddress = null;
                        that.data.websiteUrl = null;
                        that.data.noteHtml = null;
                        that.clearAddress();
                        that.originalData = null;
                    }
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isLoading = false;
                })
        })
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            let option = toJS(that.data);

            if (that.hasUnsavedChanges) {
                if (that.saveToServer) {
                    if (that.data.id) {
                        api.ProductSuppliers.update(that.data.id, option, (c) => { that.cancelProductSupplierUpdate = c })
                            .then(() => {
                                that.hasUnsavedChanges = false;
                                resolve(that.data);
                            })
                            .catch(() => {
                                reject();
                            })
                            .finally(() => {
                                that.isSaving = false;
                            })
                    }
                    else {
                        api.ProductSuppliers.create(option, (c) => { that.cancelProductSupplierCreate = c })
                            .then(({ data }) => {
                                that.hasUnsavedChanges = false;
                                that.data.id = data.id;
                                resolve(that.data);
                            })
                            .catch(() => {
                                reject();
                            })
                            .finally(() => {
                                that.isSaving = false;
                            })
                    }
                } else {
                    resolve(option);
                }
            } else {
                that.hasUnsavedChanges = false;
                that.isSaving = false;
                resolve(option);
            }
        })
    }

    delete = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            if (that.data.id) {
                api.ProductSuppliers.delete(that.data.id, (c) => { that.cancelProductSupplierDelete = c })
                    .then(() => {
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            }
            else 
                reject();
        })
    }

    clearAddress = () => {
        this.data.address.geocode = null;
        this.data.address.streetNumber = null;
        this.data.address.streetName = null;
        this.data.address.unitSuiteType = null;
        this.data.address.unitSuite = null;
        this.data.address.line2 = null;
        this.data.address.locality = null;
        this.data.address.sublocality = null;
        this.data.address.postalCode = null;
        this.data.address.region = null;
        this.data.address.country = null;
    }

    clear = () => {
        this.data.id = null;
        this.data.name = null;
        this.data.accountNumber = null;
        this.data.contactPerson = null;
        this.data.existingAddress = null;
        this.data.primaryPhone = null;
        this.data.secondaryPhone = null;
        this.data.fax = null;
        this.data.emailAddress = null;
        this.data.websiteUrl = null;
        this.data.noteHtml = null;
        this.originalData = null;
        this.hasUnsavedChanges = false;
        this.isReady = false;
        this.isSaving = false;
        this.saveToServer = true;
        this.clearAddress();

        if (fn.isFunction(this.cancelProductSupplierCreate)) {
            this.cancelProductSupplierCreate();
            this.cancelProductSupplierCreate = null;
        }

        if (fn.isFunction(this.cancelProductSupplierUpdate)) {
            this.cancelProductSupplierUpdate();
            this.cancelProductSupplierUpdate = null;
        }

        if (fn.isFunction(this.cancelProductSupplierDelete)) {
            this.cancelProductSupplierDelete();
            this.cancelProductSupplierDelete = null;
        }

        if (fn.isFunction(this.cancelProductSupplierSearch)) {
            this.cancelProductSupplierSearch();
            this.cancelProductSupplierSearch = null;
        }
    }
}

decorate(ProductSupplierCreate, {
    data: observable.deep,
    originalData: observable,
    hasUnsavedChanges: observable,
    isReady: observable,
    isSaving: observable,
    isLoading: observable,
    saveToServer: observable,
    initialize: action,
    load: action,
    save: action,
    clearAddress: action,
    clear: action,
})

export default createContext(new ProductSupplierCreate());