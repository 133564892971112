import * as dg from './dx/dataGridHelper';

export const isEndState = todo => {
    return todo && ['Completed', 'Canceled'].some(s => s === todo.status)
}

export const getTodoInstanceStatusDescription = status => {
    if (status) {
        switch (status.toLowerCase()) {
            case 'inprogress':
                return 'In Progress';

            default:
                return status;
        }
    }
}

export const getStatusFilters = () => {
    return [
        {
            text: 'Pending',
            value: 'Pending'
        },
        {
            text: 'In Progress',
            value: 'InProgress'
        },
        {
            text: 'Reopened',
            value: 'Reopened'
        },
        {
            text: 'Completed',
            value: 'Completed'
        }
    ];
}

export const getLastUpdatedDateFilters = () => {
    return dg.getDefaultLastDateFilters('LastUpdatedDateUtc');
}

export const getQuickFilterDescription = (type) => {
    if (type) {
        switch (type) {
            case 'YourOutstanding':
                return 'Your outstanding tasks';

            case 'YourRecentlyCompleted':
                return 'Your recently completed tasks';

            case 'YourCompleted':
                return 'All of your completed tasks';

            case 'AllOutstanding':
                return 'All outstanding tasks';

            case 'RecentlyUpdated':
                return 'Recently updated tasks';

            case 'RecentlyCompleted':
                return 'Recently completed tasks';

            default:
                return null;
        }
    }
    return null;
}

export const getQuickFilterDefinition = (type, auth) => {
    if (type) {
        const lastUpdatedDateFilters = getLastUpdatedDateFilters();

        const outstandingStatusFilter =
        {
            column: 'Status',
            headerFilters: ['Pending', 'InProgress', 'Reopened'],
        };
        const completedStatusFilter =
        {
            column: 'Status',
            headerFilters: ['Completed'],
        };

        switch (type) {
            case 'YourOutstanding':
                return [{
                    column: 'RelatedTo',
                    headerFilters: auth && auth.currentUser ? [auth.currentUser.id] : [],
                }, {
                    column: 'BodyHtml',
                    sort: 'asc',
                }, outstandingStatusFilter];

            case 'YourRecentlyCompleted':
                return [{
                    column: 'CompletedBy',
                    headerFilters: auth && auth.currentUser ? [auth.currentUser.id] : [],
                }, {
                    column: 'LastUpdatedDateUtc',
                    headerFilters: [lastUpdatedDateFilters.filter(u => u.text === 'Last 30 days')[0].value],
                    sortOrder: 'asc'
                }, completedStatusFilter];

            case 'YourCompleted':
                return [{
                    column: 'CompletedBy',
                    headerFilters: auth && auth.currentUser ? [auth.currentUser.id] : [],
                }, {
                    column: 'LastUpdatedDateUtc',
                    sortOrder: 'asc'
                }, completedStatusFilter];

            case 'AllOutstanding':
                return [{
                    column: 'BodyHtml',
                    sort: 'asc',
                }, outstandingStatusFilter];

            case 'RecentlyUpdated':
                return [{
                    column: 'LastUpdatedDateUtc',
                    headerFilters: [lastUpdatedDateFilters.filter(u => u.text === 'Last 30 days')[0].value],
                    sortOrder: 'asc'
                }, outstandingStatusFilter];

            case 'RecentlyCompleted':
                return [{
                    column: 'LastUpdatedDateUtc',
                    headerFilters: [lastUpdatedDateFilters.filter(u => u.text === 'Last 30 days')[0].value],
                    sortOrder: 'asc'
                }, completedStatusFilter];

            default:
                return;
        }
    }
}
