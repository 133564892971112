import React from 'react';
import moment from 'moment';

import { downloadFile, printFile } from '../components/views/_shared/FileViewer';

import * as fn from './_functions';
import * as ah from './accessHelper';
import * as sh from './signatureHelper';
import * as AccessType from '../constants/accessTypes'

import api from '../api';

export const PRESCRIPTION_SIGNATURE_CANVAS_WIDTH = sh.SIGNATURE_CANVAS_WIDTH;
export const PRESCRIPTION_SIGNATURE_CANVAS_HEIGHT = sh.SIGNATURE_CANVAS_HEIGHT;
export const PRESCRIPTION_SIGNATURE_DEMO = {
    fullName: 'Dr. John Johnstone',
    licenseNumber: '0012345'
};

const PRESCRIPTION_SIGNATURE_LINE_POS_Y_PERCENTAGE = 0.7;

export const PRINT_MESSAGE = <>
    <h2 className='text-uppercase text-center font-weight-bold text-gray-900 mb-1'>Generating...</h2>
    <p className='fs-xl'>Please wait while we generate the prescription.</p>
</>;

export const getSignatureLineBase64 = (user, signed, options) => {
    let result, emptySignatureLine;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const defaults = {
        lineColor: '#000000',
        lineWidth: 1,
        textColor: '#000000',
        textFont: 'arial',
        textFontSize: 13,
        descriptionColor: '#000000',
        descriptionFont: 'arial',
        descriptionFontSize: 11,
        scale: 1.0,
        signedCallback: null,
    };
    options = { ...defaults, ...options }

    const height = PRESCRIPTION_SIGNATURE_CANVAS_HEIGHT * options.scale;
    const width = PRESCRIPTION_SIGNATURE_CANVAS_WIDTH * options.scale;
    const linePositionY = height * PRESCRIPTION_SIGNATURE_LINE_POS_Y_PERCENTAGE;

    options.lineWidth = options.lineWidth * options.scale;
    options.textFontSize = options.textFontSize * options.scale;
    options.descriptionFontSize = options.descriptionFontSize * options.scale;

    canvas.width = width;
    canvas.height = height;

    // Draw signature line
    context.strokeStyle = options.lineColor;
    context.lineWidth = options.lineWidth;
    context.beginPath();
    context.moveTo(0, linePositionY);
    context.lineTo(canvas.width, linePositionY);
    context.stroke();

    if (user) {
        let indentX = options.textFontSize;
        let textY = (linePositionY + (options.textFontSize + options.lineWidth + (options.textFontSize)));


        context.font = `${options.textFontSize}px ${options.textFont}`;
        context.fillStyle = options.textColor;
        context.fillText(`${user.fullName}`, indentX, textY);

        if (user.licenseNumber) {
            let licenseY = textY + options.textFontSize;

            context.fillStyle = options.textColor;
            context.fillText(`License number: ${user.licenseNumber}`, indentX, licenseY);
        }
    }

    emptySignatureLine = canvas.toDataURL('image/png');

    if (signed && user.signature) {
        const signedCanvas = document.createElement('canvas');
        const signedContext = signedCanvas.getContext('2d');
        const signatureLineImg = new Image(width, height);
        const signatureImg = new Image(width, height);
        let signatureLineImgLoaded = false;
        let signatureImgLoaded = false;

        signatureLineImg.src = emptySignatureLine;
        signatureImg.src = user.signature;

        signedCanvas.width = width;
        signedCanvas.height = height;

        signatureLineImg.onload = () => {
            signedContext.drawImage(signatureLineImg, 0, 0, width, height);
            signatureLineImgLoaded = true;

            if (signatureLineImgLoaded && signatureImgLoaded) {
                result = signedCanvas.toDataURL('image/png');

                if (options.signedCallback && fn.isFunction(options.signedCallback)) {
                    options.signedCallback(result);
                }
            }
        }

        signatureImg.onload = () => {
            signedContext.drawImage(signatureImg, 0, 0, width, height);
            signatureImgLoaded = true;

            if (signatureLineImgLoaded && signatureImgLoaded) {
                result = signedCanvas.toDataURL('image/png');

                if (options.signedCallback && fn.isFunction(options.signedCallback)) {
                    options.signedCallback(result);
                }
            }
        }
    } else {
        result = emptySignatureLine;
    }

    return result;
}

export const getFilename = (prescription) => {
    return `${fn.urlFriendly(`${prescription.customer.initials}-${prescription.prescriptionType.name}-${moment.utc(prescription.createdDateUtc).local().format('YYYYMMDD')}`)}.pdf`;
}

export const getLatestPrescription = (prescription, isGeneratingPrintCallback, reprint = false) => {
    return new Promise((resolve) => {
        if (prescription) {
            const option = {
                documentType: 'Prescription',
                customerId: prescription.customer.id,
                referenceId1: prescription.id,
                referenceDateTimeUtc: prescription.lastUpdatedDateUtc ? prescription.lastUpdatedDateUtc : prescription.createdDateUtc,
            };

            api.PrintedDocuments.search({
                parameters: [
                    { field: 'Type', value: option.documentType },
                    { field: 'CustomerId', value: option.customerId },
                    { field: 'ReferenceId1', value: option.referenceId1 },
                    { field: 'ReferenceDateTimeUtc', value: option.referenceDateTimeUtc },
                    { field: 'DeactivatedDateUtc', value: null },
                ],
                sortByFields: [{ field: 'CreatedDateUtc', direction: 'DESC' }],
                offset: 0, limit: 1, includeTotalCount: false,
            })
                .then(({ data }) => {
                    let lastPrescription = data.result && data.result.length > 0 ? data.result[0] : null;

                    if (!lastPrescription || reprint) {
                        if (isGeneratingPrintCallback) {
                            isGeneratingPrintCallback(true);
                        }

                        api.PrintedDocuments.print(option)
                            .then(({ data }) => {
                                api.PrintedDocuments.get(data.id)
                                    .then(({ data: printedDocumentData }) => {
                                        if (isGeneratingPrintCallback) {
                                            isGeneratingPrintCallback(false);
                                        }
                                        lastPrescription = printedDocumentData;
                                        resolve(lastPrescription);
                                    })
                            })
                    } else {
                        resolve(lastPrescription);
                    }
                })
        }
    })
}

export const regenerate = (prescription, fileViewer, isGeneratingPrintCallback) => {
    getLatestPrescription(prescription, isGeneratingPrintCallback, true)
        .then((printedDocument) => {
            fileViewer.initialize(() => {
                return <div className='fs-lg'>
                    {prescription.customer.fullName}'s {prescription.prescriptionType.name} Prescription
                    <small className='d-block text-gray-700'>{fn.formatFullDateWithOrWithoutYear(moment.utc(prescription.createdDateUtc).local())}</small>
                </div>
            }, 'printedDocument', [printedDocument], 0, prescription.customer.id)
        })
}

export const view = (prescription, fileViewer, isGeneratingPrintCallback) => {
    getLatestPrescription(prescription, isGeneratingPrintCallback)
        .then((printedDocument) => {
            fileViewer.initialize(() => {
                return <div className='fs-lg'>
                    {prescription.customer.fullName}'s {prescription.prescriptionType.name} Prescription
                    <small className='d-block text-gray-700'>{fn.formatFullDateWithOrWithoutYear(moment.utc(prescription.createdDateUtc).local())}</small>
                </div>
            }, 'printedDocument', [printedDocument], 0, prescription.customer.id)
        })
}

export const download = (prescription, isGeneratingPrintCallback, downloadCallback) => {
    getLatestPrescription(prescription, isGeneratingPrintCallback)
        .then((printedDocument) => {
            downloadFile('printedDocument', printedDocument.fileName, printedDocument.id, null, downloadCallback);
        })
}

export const print = (prescription, isGeneratingPrintCallback) => {
    getLatestPrescription(prescription, isGeneratingPrintCallback)
        .then((printedDocument) => {
            api.PrintedDocuments.download(printedDocument.id)
                .then(({ data }) => {
                    printFile(URL.createObjectURL(data), printedDocument.mimeType);
                })
        })
}

export const email = (prescription, communication, isGeneratingPrintCallback) => {
    return new Promise((resolve) => {
        getLatestPrescription(prescription, isGeneratingPrintCallback)
            .then((printedDocument) => {
                communication.initialize(
                    'email',
                    'Prescription',
                    prescription.id,
                    [printedDocument],
                    prescription.customer.id,
                    prescription.customer.defaultEmailAddressContact,
                )
                    .then(() => {
                        resolve();
                    })
            })
    })
}

export const fax = (prescription, communication, isGeneratingPrintCallback) => {
    return new Promise((resolve) => {
        getLatestPrescription(prescription, isGeneratingPrintCallback)
            .then((printedDocument) => {
                communication.initialize(
                    'fax',
                    'Prescription',
                    prescription.id,
                    [printedDocument],
                    prescription.customer.id,
                )
                    .then(() => {
                        resolve();
                    })
            })
    })
}

export const canUpdate = (prescription) => {
    return !prescription.writtenById || ah.sameUser(prescription.writtenById);
}

export const canClone = (prescription) => {
    return !!prescription.writtenById && ah.check(AccessType.SYSTEM_CAN_PRESCRIBE);
}

export const getStatus = (prescription) => {
    if (!prescription) return;

    if (!!prescription.isOutsidePrescription) {
        return 'Outside';
    }
    if (isDraft(prescription)) {
        return 'Draft';
    }
    if (isSigned(prescription)) {
        return 'Signed';
    }
    if (isPending(prescription)) {
        return 'Pending';
    }
    if (isFinalized(prescription)) {
        return 'Finalized';
    }
}

export const isDraft = (prescription) => {
    if (!prescription) return false;
    return !prescription.isOutsidePrescription && !prescription.requestFinalizeDateUtc && !prescription.finalizedDateUtc;
}

export const isSigned = (prescription) => {
    if (!prescription) return false;
    return !prescription.isOutsidePrescription && !!prescription.finalizedDateUtc && !!prescription.signedDateUtc;
}

export const isFinalized = (prescription) => {
    if (!prescription) return false;
    return !prescription.isOutsidePrescription && !!prescription.finalizedDateUtc;
}

export const isPending = (prescription) => {
    if (!prescription) return false;
    return !prescription.isOutsidePrescription && !!prescription.requestFinalizeDateUtc && !prescription.finalizedDateUtc;
}