import React, { useEffect, useContext, useState, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { GlobalHotKeys } from 'react-hotkeys';
import { DropdownList, Combobox } from 'react-widgets';
import { useObserver } from 'mobx-react-lite';

import QuickDrawerHeader from '../../../../_shared/QuickDrawerHeader';
import { quickDrawerFocus } from '../../../../_shared/QuickDrawer';
import BorderPicker from '../../../../_shared/BorderPicker';
import ColorPicker from '../../../../_shared/ColorPicker';
import RichTextEditor from '../../../../_shared/RichTextEditor';

import PretestElementViewStore from '../../../../../../stores/PretestElementViewStore';
import QuickDrawerStore from '../../../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../../../constants/errorMessages';
import * as fn from '../../../../../../utilities/_functions';
import * as mth from '../../../../../../utilities/modalTemplateHelper';

import './UpdateExistingPretestElement.scss';

function UpdateExistingPretestElement(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const pretestElement = useContext(PretestElementViewStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [align, setAlign] = useState('');
    const [valign, setValign] = useState('');
    const [padding, setPadding] = useState([]);
    const [fontColor, setFontColor] = useState('#868e96');
    const [fontSize, setFontSize] = useState(null);
    const [fontFamily, setFontFamily] = useState('');
    const [fontStyle, setFontStyle] = useState([]);
    const [fontLetterSpacing, setFontLetterSpacing] = useState(null);
    const [image, setImage] = useState(null);
    const [backgroundColor, setBackgroundColor] = useState('');
    const [border, setBorder] = useState(null);
    const [borderWidth, setBorderWidth] = useState(1);
    const [borderStyle, setBorderStyle] = useState('solid');
    const [borderColor, setBorderColor] = useState('#000000');
    const [alternateBackgroundColor, setAlternateBackgroundColor] = useState('');
    const [content, setContent] = useState('');
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        focusTimer.current = setTimeout(() => {
            const existingAlign = pretestElement.getMetadata('align');
            const existingValign = pretestElement.getMetadata('valign');
            const existingPadding = pretestElement.getMetadata('padding');
            const existingFontColor = pretestElement.getMetadata('fontColor');
            const existingFontSize = pretestElement.getMetadata('fontSize');
            const existingFontFamily = pretestElement.getMetadata('fontFamily');
            const existingFontStyle = pretestElement.getMetadata('fontStyle');
            const existingFontLetterSpacing = pretestElement.getMetadata('fontLetterSpacing');
            const existingImage = pretestElement.getMetadata('image');
            const existingBackgroundColor = pretestElement.getMetadata('backgroundColor');
            const existingBorder = pretestElement.getMetadata('border');
            const existingAlternateBackgroundColor = pretestElement.getMetadata('alternateBackgroundColor');
            const existingContent = pretestElement.getMetadata('content');

            setAlign((existingAlign ? existingAlign : 'left'));
            setValign((existingValign ? existingValign : 'middle'));
            setPadding((existingPadding ? existingPadding : [0, 0, 0, 0]));
            setFontColor((existingFontColor ? existingFontColor : '#868e96'));
            setFontSize((existingFontSize ? existingFontSize : 13));
            setFontFamily((existingFontFamily ? existingFontFamily : 'Roboto'));
            setFontStyle((existingFontStyle ? existingFontStyle : []));
            setFontLetterSpacing((existingFontLetterSpacing ? existingFontLetterSpacing : 0));
            setImage((existingImage ? existingImage : null));
            setBackgroundColor((existingBackgroundColor ? existingBackgroundColor : ''));
            setBorder((existingBorder ? existingBorder : null));
            setAlternateBackgroundColor((existingAlternateBackgroundColor ? existingAlternateBackgroundColor : '#f3f3f3'));
            setContent((existingContent ? existingContent : ''));
            setIsReady(true);

            quickDrawerFocus(props.drawer);
        }, 100)

        return (() => {
            isMounted.current = false;
            pretestElement.clear();
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        })
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (pretestElement.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = event => {
        event.preventDefault();
        handlePopulateMetadata();
        pretestElement.save()
            .then(data => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, { data: data });
                    }
                }
            })
    }

    const handleContentChange = content => {
        const html = ((content === '<p><br></p>') ? null : content);
        setContent(html);
        pretestElement.hasUnsavedChanges = true;
    }

    const handleFontFamilyChange = (value) => {
        setFontFamily(value);
        pretestElement.hasUnsavedChanges = true;
    }

    const handleFontSizeChange = (value) => {
        setFontSize(value);
        pretestElement.hasUnsavedChanges = true;
    }

    const handleFontColorChange = (color) => {
        setFontColor(color.value);
        pretestElement.hasUnsavedChanges = true;
    }

    const handleFontStyleChange = value => {
        const index = fontStyle.findIndex(s => s === value);
        const tempFontStyle = [...fontStyle];

        if (index === -1) {
            tempFontStyle.push(value);
        } else {
            tempFontStyle.splice(index, 1);
        }

        setFontStyle(tempFontStyle);
        pretestElement.hasUnsavedChanges = true;
    }

    const handleFontLetterSpacing = event => {
        const spacing = event.target.value;
        setFontLetterSpacing((spacing ? spacing : 0));
        pretestElement.hasUnsavedChanges = true;
    }

    const handleAlignChange = value => {
        setAlign((value ? value : 'left'));
        pretestElement.hasUnsavedChanges = true;
    }

    const handleValignChange = value => {
        setValign((value ? value : 'middle'));
        pretestElement.hasUnsavedChanges = true;
    }

    const handlePaddingChange = (event, index) => {
        const newPadding = [...padding];

        newPadding[index] = event.target.value;
        setPadding(newPadding);
        pretestElement.hasUnsavedChanges = true;
    }

    const handleBorderChange = (event, data) => {
        setBorder(data);
        pretestElement.hasUnsavedChanges = true;
    }

    const handleBorderStyleChange = (item) => {
        if (item) {
            setBorderWidth(item.data.width);
            setBorderStyle(item.data.style);
        }
        pretestElement.hasUnsavedChanges = true;
    }

    const handleBorderColorChange = (color) => {
        setBorderColor(color.value);
        pretestElement.hasUnsavedChanges = true;
    }

    const handleBackgroundColorChange = (color) => {
        setBackgroundColor(color.value);
        pretestElement.hasUnsavedChanges = true;
    }

    const handleImageClick = event => {
        setImage(null);
        pretestElement.hasUnsavedChanges = true;
    }

    const handleImageChange = event => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = handleFileReaderLoad;
        reader.readAsDataURL(file);
    }

    const handleFileReaderLoad = (event) => {
        const reader = event.target;
        const base64 = reader.result;
        const tempImage = new Image();

        tempImage.src = event.target.result;
        tempImage.onload = () => {
            const newImage = {
                base64: base64,
                width: tempImage.width,
                height: tempImage.height,
            }
            setImage(newImage);
        }
        pretestElement.hasUnsavedChanges = true;
    }

    const handlePopulateMetadata = () => {
        populateMetadata(mth.MODAL_TEMPLATE_METADATA_ALIGN, 'align', align);
        populateMetadata(mth.MODAL_TEMPLATE_METADATA_VALIGN, 'valign', valign);
        populateMetadata(mth.MODAL_TEMPLATE_METADATA_PADDING, 'padding', padding);
        populateMetadata(mth.MODAL_TEMPLATE_METADATA_FONT_COLOR, 'fontColor', fontColor);
        populateMetadata(mth.MODAL_TEMPLATE_METADATA_FONT_SIZE, 'fontSize', fontSize);
        populateMetadata(mth.MODAL_TEMPLATE_METADATA_FONT_FAMILY, 'fontFamily', fontFamily);
        populateMetadata(mth.MODAL_TEMPLATE_METADATA_FONT_STYLE, 'fontStyle', fontStyle);
        populateMetadata(mth.MODAL_TEMPLATE_METADATA_FONT_LETTER_SPACING, 'fontLetterSpacing', fontLetterSpacing);
        populateMetadata(mth.MODAL_TEMPLATE_METADATA_BACKGROUND_COLOR, 'backgroundColor', backgroundColor);
        populateMetadata(mth.MODAL_TEMPLATE_METADATA_BORDER, 'border', border);
        populateMetadata(mth.MODAL_TEMPLATE_METADATA_BORDER_COLOR, 'borderColor', borderColor);
        populateMetadata(mth.MODAL_TEMPLATE_METADATA_ALTERNATE_BACKGROUND_COLOR, 'alternateBackgroundColor', alternateBackgroundColor);
        populateMetadata(mth.MODAL_TEMPLATE_METADATA_CONTENT, 'content', content);
        populateMetadata(mth.MODAL_TEMPLATE_METADATA_IMAGE, 'image', image);
    }

    const populateMetadata = (field, key, value) => {
        if (mth.hasField(pretestElement.data.type, field)) {
            pretestElement.setMetadata(key, value);
        } else {
            pretestElement.removeMetadata(key);
        }
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form onSubmit={handleSubmit}>
            <fieldset disabled={pretestElement.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={mth.getIcon(pretestElement.data.type)}
                        action='Update'
                        category={`${mth.getDescription(pretestElement.data.type)}`}
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        {
                            isReady ?
                                <FadeIn>
                                    <div className='update-pretest-text body-content'>
                                        <section>
                                            {
                                                mth.hasField(pretestElement.data.type, mth.MODAL_TEMPLATE_METADATA_CONTENT) ?
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-4'>
                                                                <label htmlFor='update-pretest-text'><small>Content</small></label>
                                                                <RichTextEditor
                                                                    mode='basic'
                                                                    disableTab={true}
                                                                    value={content}
                                                                    onChange={handleContentChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div> : null
                                            }
                                            {
                                                mth.hasField(pretestElement.data.type, mth.MODAL_TEMPLATE_METADATA_FONT_FAMILY) ||
                                                    mth.hasField(pretestElement.data.type, mth.MODAL_TEMPLATE_METADATA_FONT_SIZE) ||
                                                    mth.hasField(pretestElement.data.type, mth.MODAL_TEMPLATE_METADATA_FONT_COLOR) ?
                                                    <div className='row'>
                                                        {
                                                            mth.hasField(pretestElement.data.type, mth.MODAL_TEMPLATE_METADATA_FONT_FAMILY) ?
                                                                <div className='col-6'>
                                                                    <div className='form-group mb-4'>
                                                                        <label htmlFor='update-pretest-font-family'><small>Font</small></label>
                                                                        <DropdownList
                                                                            data={['Roboto', 'Arial', 'Arial Black', 'Georgia', 'Impact', 'Times New Roman', 'Verdana']}
                                                                            value={fontFamily}
                                                                            itemComponent={({ item }) => (
                                                                                <div>
                                                                                    <span style={{ fontFamily: item }}>{item}</span>
                                                                                </div>
                                                                            )}
                                                                            valueComponent={({ item }) => (
                                                                                item ?
                                                                                    <div>
                                                                                        <span style={{ fontFamily: item }}>{item}</span>
                                                                                    </div> : null
                                                                            )}
                                                                            onChange={handleFontFamilyChange}
                                                                        />
                                                                    </div>
                                                                </div> : null
                                                        }
                                                        {
                                                            mth.hasField(pretestElement.data.type, mth.MODAL_TEMPLATE_METADATA_FONT_SIZE) ?
                                                                <div className='col-3'>
                                                                    <div className='form-group mb-4'>
                                                                        <label htmlFor='update-pretest-font-size'><small>Size</small></label>
                                                                        <Combobox
                                                                            data={[8, 9, 10, 11, 12, 13, 14, 18, 21, 24, 28, 30, 32, 36, 48, 60, 72, 96]}
                                                                            value={fontSize}
                                                                            autoSelectMatches={false}
                                                                            itemComponent={({ item }) => (
                                                                                <div>
                                                                                    <span>{item}</span>
                                                                                </div>
                                                                            )}
                                                                            valueComponent={({ item }) => (
                                                                                item ?
                                                                                    <div>
                                                                                        <span>{item}</span>
                                                                                    </div> : null
                                                                            )}
                                                                            onChange={handleFontSizeChange}
                                                                        />
                                                                    </div>
                                                                </div> : null
                                                        }
                                                        {
                                                            mth.hasField(pretestElement.data.type, mth.MODAL_TEMPLATE_METADATA_FONT_COLOR) ?
                                                                <div className='col-3'>
                                                                    <div className='form-group mb-4'>
                                                                        <label htmlFor='update-pretest-font-color'><small>Color</small></label>
                                                                        <ColorPicker color={fontColor} popup='right' onChange={handleFontColorChange} />
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </div> : null
                                            }
                                            {
                                                mth.hasField(pretestElement.data.type, mth.MODAL_TEMPLATE_METADATA_FONT_STYLE) ||
                                                    mth.hasField(pretestElement.data.type, mth.MODAL_TEMPLATE_METADATA_FONT_LETTER_SPACING) ?
                                                    <div className='row'>
                                                        {
                                                            mth.hasField(pretestElement.data.type, mth.MODAL_TEMPLATE_METADATA_FONT_STYLE) ?
                                                                <div className='col-6'>
                                                                    <div className='form-group mb-4'>
                                                                        <label htmlFor='update-pretest-font-style'><small>Style</small></label>
                                                                        <div>
                                                                            <div className='btn-group'>
                                                                                <button
                                                                                    type='button'
                                                                                    className={'btn btn-primary' + (fontStyle.some(s => s === 'bold') ? ' active' : '')}
                                                                                    value={(align ? align : '')}
                                                                                    onClick={() => { handleFontStyleChange('bold') }}
                                                                                >
                                                                                    <i className='fal fa-bold'></i>
                                                                                </button>
                                                                                <button
                                                                                    type='button'
                                                                                    className={'btn btn-primary' + (fontStyle.some(s => s === 'italic') ? ' active' : '')}
                                                                                    value={(align ? align : '')}
                                                                                    onClick={() => { handleFontStyleChange('italic') }}
                                                                                >
                                                                                    <i className='fal fa-italic'></i>
                                                                                </button>
                                                                                <button
                                                                                    type='button'
                                                                                    className={'btn btn-primary' + (fontStyle.some(s => s === 'underline') ? ' active' : '')}
                                                                                    value={(align ? align : '')}
                                                                                    onClick={() => { handleFontStyleChange('underline') }}
                                                                                >
                                                                                    <i className='fal fa-underline'></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                        {
                                                            mth.hasField(pretestElement.data.type, mth.MODAL_TEMPLATE_METADATA_FONT_LETTER_SPACING) ?
                                                                <div className='col-3'>
                                                                    <div className='form-group mb-4'>
                                                                        <label htmlFor='update-pretest-font-letter-spacing'><small>Spacing</small></label>
                                                                        <input
                                                                            id='update-pretest-font-letter-spacing'
                                                                            type='number'
                                                                            className='form-control'
                                                                            min={-2}
                                                                            step={0.1}
                                                                            max={2}
                                                                            value={fontLetterSpacing}
                                                                            onChange={handleFontLetterSpacing}
                                                                        />
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </div> : null
                                            }
                                            {
                                                mth.hasField(pretestElement.data.type, mth.MODAL_TEMPLATE_METADATA_ALIGN) ||
                                                    mth.hasField(pretestElement.data.type, mth.MODAL_TEMPLATE_METADATA_VALIGN) ?
                                                    <div className='row'>
                                                        {
                                                            mth.hasField(pretestElement.data.type, mth.MODAL_TEMPLATE_METADATA_ALIGN) ?
                                                                <div className='col-6'>
                                                                    <div className='form-group mb-4'>
                                                                        <label htmlFor='update-pretest-text-align'><small>Align</small></label>
                                                                        <div>
                                                                            <div className='btn-group'>
                                                                                <button
                                                                                    type='button'
                                                                                    className={'btn btn-primary' + (align === 'left' ? ' active' : '')}
                                                                                    value={(align ? align : '')}
                                                                                    onClick={() => { handleAlignChange('left') }}
                                                                                >
                                                                                    <i className='fal fa-align-left'></i>
                                                                                </button>
                                                                                <button
                                                                                    type='button'
                                                                                    className={'btn btn-primary' + (align === 'center' ? ' active' : '')}
                                                                                    value={(align ? align : '')}
                                                                                    onClick={() => { handleAlignChange('center') }}
                                                                                >
                                                                                    <i className='fal fa-align-center'></i>
                                                                                </button>
                                                                                <button
                                                                                    type='button'
                                                                                    className={'btn btn-primary' + (align === 'right' ? ' active' : '')}
                                                                                    value={(align ? align : '')}
                                                                                    onClick={() => { handleAlignChange('right') }}
                                                                                >
                                                                                    <i className='fal fa-align-right'></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                        {
                                                            mth.hasField(pretestElement.data.type, mth.MODAL_TEMPLATE_METADATA_VALIGN) ?
                                                                <div className='form-group mb-4'>
                                                                    <label htmlFor='update-pretest-text-valign'><small>Vertical Align</small></label>
                                                                    <div>
                                                                        <div className='btn-group'>
                                                                            <button
                                                                                type='button'
                                                                                className={'btn btn-primary' + (valign === 'top' ? ' active' : '')}
                                                                                value={(valign ? valign : '')}
                                                                                onClick={() => { handleValignChange('top') }}
                                                                            >
                                                                                <i className='fal fa-arrow-to-top'></i>
                                                                            </button>
                                                                            <button
                                                                                type='button'
                                                                                className={'btn btn-primary' + (valign === 'middle' ? ' active' : '')}
                                                                                value={(valign ? valign : '')}
                                                                                onClick={() => { handleValignChange('middle') }}
                                                                            >
                                                                                <i className='fal fa-horizontal-rule'></i>
                                                                            </button>
                                                                            <button
                                                                                type='button'
                                                                                className={'btn btn-primary' + (valign === 'bottom' ? ' active' : '')}
                                                                                value={(valign ? valign : '')}
                                                                                onClick={() => { handleValignChange('bottom') }}
                                                                            >
                                                                                <i className='fal fa-arrow-to-bottom'></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </div> : null
                                            }
                                            {
                                                mth.hasField(pretestElement.data.type, mth.MODAL_TEMPLATE_METADATA_PADDING) ?
                                                    <div className='row'>
                                                        <div className='col-3'>
                                                            <div className='form-group mb-4'>
                                                                <label htmlFor='update-pretest-padding-top'><small>Top</small></label>
                                                                <input
                                                                    id='update-pretest-padding-top'
                                                                    type='number'
                                                                    className='form-control'
                                                                    min={-50}
                                                                    step={mth.MODAL_TEMPLATE_ROW_HEIGHT}
                                                                    max={50}
                                                                    value={(padding && padding[0] ? padding[0] : 0)}
                                                                    onChange={(e) => { handlePaddingChange(e, 0) }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='col-3'>
                                                            <div className='form-group mb-4'>
                                                                <label htmlFor='update-pretest-padding-right'><small>Right</small></label>
                                                                <input
                                                                    id='update-pretest-padding-right'
                                                                    type='number'
                                                                    className='form-control'
                                                                    min={-50}
                                                                    step={mth.MODAL_TEMPLATE_ROW_HEIGHT}
                                                                    max={50}
                                                                    value={(padding && padding[1] ? padding[1] : 0)}
                                                                    onChange={(e) => { handlePaddingChange(e, 1) }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='col-3'>
                                                            <div className='form-group mb-4'>
                                                                <label htmlFor='update-pretest-padding-bottom'><small>Bottom</small></label>
                                                                <input
                                                                    id='update-pretest-padding-bottom'
                                                                    type='number'
                                                                    className='form-control'
                                                                    min={-50}
                                                                    step={mth.MODAL_TEMPLATE_ROW_HEIGHT}
                                                                    max={50}
                                                                    value={(padding && padding[2] ? padding[2] : 0)}
                                                                    onChange={(e) => { handlePaddingChange(e, 2) }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='col-3'>
                                                            <div className='form-group mb-4'>
                                                                <label htmlFor='update-pretest-padding-left'><small>Left</small></label>
                                                                <input
                                                                    id='update-pretest-padding-left'
                                                                    type='number'
                                                                    className='form-control'
                                                                    min={-50}
                                                                    step={mth.MODAL_TEMPLATE_ROW_HEIGHT}
                                                                    max={50}
                                                                    value={(padding && padding[3] ? padding[3] : 0)}
                                                                    onChange={(e) => { handlePaddingChange(e, 3) }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div> : null
                                            }
                                            {
                                                mth.hasField(pretestElement.data.type, mth.MODAL_TEMPLATE_METADATA_BORDER) ?
                                                    <>
                                                        <div className='row'>
                                                            <div className='col-3'>
                                                                <div className='form-group mb-3'>
                                                                    <label htmlFor='update-pretest-border'><small>Border</small></label>
                                                                    <BorderPicker border={border} width={borderWidth} style={borderStyle} color={borderColor} onChange={handleBorderChange} />
                                                                </div>
                                                            </div>
                                                            <div className='col-5 offset-1'>
                                                                <div className='form-group mb-3'>
                                                                    <label htmlFor='update-pretest-border-style'><small>Style</small></label>
                                                                    <DropdownList
                                                                        data={[
                                                                            { key: '1px solid #000', data: { width: 1, style: 'solid' } },
                                                                            { key: '2px solid #000', data: { width: 2, style: 'solid' } },
                                                                            { key: '3px solid #000', data: { width: 3, style: 'solid' } },
                                                                            { key: '1px dashed #000', data: { width: 1, style: 'dashed' } },
                                                                            { key: '2px dashed #000', data: { width: 2, style: 'dashed' } },
                                                                            { key: '3px dashed #000', data: { width: 3, style: 'dashed' } },
                                                                            { key: '1px dotted #000', data: { width: 1, style: 'dotted' } },
                                                                            { key: '2px dotted #000', data: { width: 2, style: 'dotted' } },
                                                                            { key: '3px dotted #000', data: { width: 3, style: 'dotted' } },
                                                                            { key: '3px double #000', data: { width: 3, style: 'double' } },
                                                                            { key: '6px double #000', data: { width: 6, style: 'double' } },
                                                                        ]}
                                                                        valueField='key'
                                                                        value={(`${borderWidth}px ${borderStyle} #000`)}
                                                                        itemComponent={({ item }) => (
                                                                            <div className='p-0' style={{ fontSize: '1px', borderTop: item.key }}>&nbsp;</div>
                                                                        )}
                                                                        valueComponent={({ item }) => (
                                                                            item ? <div className='p-0' style={{ fontSize: '1px', borderTop: item.key }}>&nbsp;</div> : null
                                                                        )}
                                                                        onChange={handleBorderStyleChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-3'>
                                                                <div className='form-group mb-4'>
                                                                    <label htmlFor='update-pretest-border-color'><small>Color</small></label>
                                                                    <ColorPicker color={borderColor} popup='right' onChange={handleBorderColorChange} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </> : null
                                            }
                                            {
                                                mth.hasField(pretestElement.data.type, mth.MODAL_TEMPLATE_METADATA_BACKGROUND_COLOR) ?
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-4'>
                                                                <label htmlFor='update-pretest-background-color'><small>Background Color</small></label>
                                                                <ColorPicker color={backgroundColor} popup='right' onChange={handleBackgroundColorChange} />
                                                            </div>
                                                        </div>
                                                    </div> : null
                                            }
                                            {
                                                mth.hasField(pretestElement.data.type, mth.MODAL_TEMPLATE_METADATA_IMAGE) ?
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-4'>
                                                                <label htmlFor='update-pretest-background-image'><small>Image</small></label>
                                                                {
                                                                    image ?
                                                                        <div>
                                                                            <img src={image.base64} width={(image.width > 300 ? 300 : image.width)} alt='Example' />
                                                                            <button
                                                                                type='button'
                                                                                className='btn btn-link px-0 btn-sm d-block'
                                                                                onClick={handleImageClick}
                                                                            >Change image</button>
                                                                        </div> :
                                                                        <div className='custom-file'>
                                                                            <input
                                                                                id='update-pretest-background-image-url'
                                                                                type='file'
                                                                                className='custom-file-input'
                                                                                accept='image/*'
                                                                                onChange={handleImageChange} />
                                                                            <label
                                                                                htmlFor='update-pretest-background-image-url'
                                                                                className='custom-file-label'>
                                                                            </label>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div> : null
                                            }
                                        </section>
                                    </div>
                                </FadeIn> : null
                        }
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-4'></div>
                            <div className='col-8'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>)
}

export default UpdateExistingPretestElement;