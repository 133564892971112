import React, { Component } from 'react';
import MaskedInput from 'react-text-mask';
import { Multiselect } from 'react-widgets';
import moment from 'moment';
import uuid from 'react-uuid';

import * as MaskKeys from '../../../constants/maskKeys';
import * as fn from '../../../utilities/_functions';

import api from '../../../api';

export default class PublicInsuranceVerificationControl extends Component {
    constructor(props) {
        super(props);

        const defaults = {
            className: 'row',
            healthCardNumberWrapperClassName: 'col-6',
            healthCardNumberGroupClassName: 'form-group',
            healthCardNumberControlClassName: 'form-control text-uppercase',
            healthCardNumberControlLabel: 'Health Card Number',
            servicesWrapperClassName: 'col-6',
            servicesGroupClassName: 'form-group',
            servicesControlClassName: null,
            servicesControlLabel: 'Service(s)',
            subsidizedServices: null,
            validateHealthCardOnChange: true,
            validateHealthCardOnBlur: false,
            validateSubsidizedServiceOnChange: true,
            validateSubsidizedServiceOnBlur: false,
        };

        const options = { ...defaults, ...props };

        this.state = {
            props: options,
            healthCardNumber: null,
            healthCardResponse: null,
            verifyServices: [],
        };

        this.healthCardNumberInputRef = React.createRef();
        this.serviceInputRef = React.createRef();
        this.searchTimer = React.createRef();
        this.instanceId = React.createRef();

        this.instanceId.current = uuid();
    }

    handleHealthCardChange = event => {
        const that = this;
        const { value } = event.target;
        const { props } = this.state;
        const healthCardResponse = !!value ? this.state.healthCardResponse : null;

        this.setState({
            healthCardNumber: value.replace(/\W/g, ''),
            healthCardResponse: healthCardResponse,
            verifyServices: []
        }, () => {
            if (!!props.validateHealthCardOnChange) {
                if (that.searchTimer.current) {
                    clearTimeout(that.searchTimer.current);
                }

                that.searchTimer.current = setTimeout(() => {
                    that.validateHealthCard();
                }, 500);
            }
        });
    }

    handleHealthCardBlur = event => {
        const { props } = this.state;

        if (!!props.validateHealthCardOnBlur) {
            this.validateHealthCard();
        }
    }

    handleSubsidizedServiceSelection = (tag, metadata) => {
        const { verifyServices, props } = this.state;
        const newVerifyServices = [...verifyServices];

        switch (metadata.action) {
            case 'insert':
                if (!newVerifyServices.some(s => s.id === metadata.dataItem.id)) {
                    newVerifyServices.push({
                        id: metadata.dataItem.id,
                        code: metadata.dataItem.code,
                        name: metadata.dataItem.code,
                        duration: metadata.dataItem.duration,
                        isRepeatable: metadata.dataItem.isRepeatable,
                    });
                }
                break;

            case 'remove':
                const index = newVerifyServices.findIndex(s => s.id === metadata.dataItem.id);
                if (index !== -1) {
                    newVerifyServices.splice(index, 1);
                }
                break;

            default:
                break;
        }

        this.setState({ verifyServices: newVerifyServices }, () => {
            if (!!props.validateSubsidizedServiceOnChange) {
                this.validateSubsidizedServices();
            }
        })
    }

    handleSubsidizedServiceBlur = event => {
        const { props } = this.state;

        if (!!props.validateSubsidizedServiceBlur) {
            this.validateSubsidizedServices();
        }
    }

    validateHealthCard = () => {
        const that = this;

        const { healthCardNumber, props } = this.state

        if (healthCardNumber && (fn.regexTester.ohipOld.test(healthCardNumber) || fn.regexTester.ohip.test(healthCardNumber))) {
            Promise.all([
                api.Customers.healthCard({ healthCardNumber: healthCardNumber }),
                api.Customers.fullSearch(healthCardNumber, false, false)
            ])
                .then((response) => {
                    const healthCardResponse = response && response[0].data ? response[0].data : null;
                    const customerSearchResponse = response[1] && response[1].data && response[1].data.result && response[1].data.result.length > 0 ? response[1].data.result[0] : null;

                    if (healthCardResponse) {
                        that.setState({ healthCardResponse: healthCardResponse }, () => {
                            that.validateSubsidizedServices();
                        });
                    }
                    if (customerSearchResponse) {
                        api.Customers.get(customerSearchResponse.id)
                            .then(({ data }) => {
                                if (props.onValidateHealthCard && fn.isFunction(props.onValidateHealthCard)) {
                                    props.onValidateHealthCard({
                                        isValid: !!healthCardResponse,
                                        data: healthCardResponse,
                                        customer: data
                                    });
                                }
                            });
                    }
                    else {
                        if (props.onValidateHealthCard && fn.isFunction(props.onValidateHealthCard)) {
                            props.onValidateHealthCard({
                                isValid: !!healthCardResponse,
                                data: healthCardResponse,
                                customer: null
                            });
                        }
                    }
                })
        }
        else {
            if (props.onValidateHealthCard && fn.isFunction(props.onValidateHealthCard)) {
                props.onValidateHealthCard({ isValid: !fn.isNullOrUndefined(healthCardNumber) });
            }
        }
    }

    validateSubsidizedServices = () => {
        const { healthCardNumber, healthCardResponse, verifyServices, props } = this.state

        if (healthCardNumber && healthCardResponse && healthCardNumber.toLowerCase() === healthCardResponse.healthCardNumber.toLowerCase() && healthCardResponse.isVerified && healthCardResponse.exists && verifyServices && verifyServices.length > 0) {
            const { healthCardNumber, dateOfBirth } = healthCardResponse;

            api.ServiceEligibility.check({
                dateOfBirth: moment(dateOfBirth, 'YYYY-MM-DD'),
                healthCardNumber: healthCardNumber,
                appointmentDate: moment().startOf('day'),
                serviceIds: verifyServices.map(s => { return s.id })
            })
                .then(({ data }) => {
                    if (props.onValidateSubsidizedServices && fn.isFunction(props.onValidateSubsidizedServices)) {
                        props.onValidateSubsidizedServices({
                            isValid: true,
                            data: data,
                        });
                    }
                })
        }
        else {
            if (props.onValidateSubsidizedServices && fn.isFunction(props.onValidateSubsidizedServices)) {
                props.onValidateSubsidizedServices({ isValid: !!healthCardResponse });
            }
        }
    }

    renderServices = () => {
        const { subsidizedServices } = this.state.props;

        return subsidizedServices && subsidizedServices.length > 0 ? subsidizedServices.map(s => {
            return {
                id: s.id,
                name: `${s.code} - ${s.name}`,
                code: s.code,
                colorHexValue: s.colorHexValue,
                duration: (s.defaultDurationInMinutes ? s.defaultDurationInMinutes : 0),
                isRepeatable: s.isRepeatable,
            }
        }) : [];
    }

    get healthCardInput() {
        return this.healthCardNumberInputRef.current;
    }

    get serviceInput() {
        return this.serviceInputRef.current;
    }

    render() {
        const { props, healthCardNumber, healthCardResponse, verifyServices } = this.state;

        return <>
            <div className={props.className}>
                <div className={props.healthCardNumberWrapperClassName}>
                    <div className={props.healthCardNumberGroupClassName}>
                        <label htmlFor='__health-card-number'><small>{props.healthCardNumberControlLabel}</small></label>
                        <MaskedInput
                            id={`__health-card-number__${this.instanceId.current}`}
                            ref={this.healthCardNumberInputRef}
                            type='text'
                            className={props.healthCardNumberControlClassName}
                            spellCheck={false}
                            mask={MaskKeys.OHIP_MASK}
                            guide={false}
                            maxLength='25'
                            autoComplete='off'
                            value={healthCardNumber ? healthCardNumber : ''}
                            onChange={this.handleHealthCardChange}
                            onBlur={this.handleHealthCardBlur}
                        />
                    </div>
                </div>
                <div className={props.servicesWrapperClassName}>
                    <div className={props.servicesGroupClassName}>
                        <label htmlFor='__public-insurance-verify-services'><small>{props.servicesControlLabel}</small></label>
                        <Multiselect
                            id={`__public-insurance-verify-services__${this.instanceId.current}`}
                            ref={this.serviceInputRef}
                            allowCreate={false}
                            data={this.renderServices()}
                            className={props.servicesControlClassName}
                            valueField='id'
                            textField='name'
                            tagComponent={({ item }) => (
                                <span
                                    className='tag'
                                    style={{
                                        backgroundColor: item.colorHexValue,
                                        borderColor: item.colorHexValue,
                                    }}
                                >
                                    <strong>{item.code}</strong>
                                </span>
                            )}
                            disabled={!healthCardResponse || !healthCardResponse.isVerified || !healthCardResponse.exists}
                            value={verifyServices}
                            onChange={this.handleSubsidizedServiceSelection}
                            onBlur={this.handleSubsidizedServiceBlur}
                        />
                    </div>
                </div>
            </div>
        </>
    }
}