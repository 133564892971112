import React, { useContext, useEffect, useState, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { Observer } from 'mobx-react-lite';
import { GlobalHotKeys } from 'react-hotkeys';
import momentLocalizer from 'react-widgets-moment';
import MaskedInput from 'react-text-mask';
import moment from 'moment';

import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import RichTextEditor from '../../_shared/RichTextEditor';

import WorkOrderItemUpdateStore from '../../../../stores/WorkOrderItemUpdateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as MaskKeys from '../../../../constants/maskKeys';
import * as fn from '../../../../utilities/_functions';

import './UpdateWorkOrderItem.scss';

moment.locale('en');
momentLocalizer();


function UpdateWorkOrderItem(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const workOrderItem = useContext(WorkOrderItemUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [overriddenBrandName, setOverriddenBrandName] = useState(null);
    const [overriddenSupplierName, setOverriddenSupplierName] = useState(null);
    const [overriddenDescriptor, setOverriddenDescriptor] = useState(null);
    const [overriddenUnitCost, setOverriddenUnitCost] = useState(null);

    useEffect(() => {
        if (isMounted.current) {
            setOverriddenBrandName(props.extraProps.brandName);
            setOverriddenSupplierName(props.extraProps.supplierName);
            setOverriddenDescriptor(props.extraProps.descriptor);
            setOverriddenUnitCost(props.extraProps.unitCost);
            quickDrawerFocus(props.drawer);
        }
        
        return () => {
            isMounted.current = false;
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (workOrderItem.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleOverrideBrandNameChange = event => {
        const brandName = event.target.value;

        setOverriddenBrandName(brandName);
        workOrderItem.data.overriddenBrandName = brandName ? brandName : null;
        workOrderItem.hasUnsavedChanges = true;
    }

    const handleOverrideSupplierNameChange = event => {
        const supplierName = event.target.value;

        setOverriddenSupplierName(supplierName);
        workOrderItem.data.overriddenSupplierName = supplierName ? supplierName : null;
        workOrderItem.hasUnsavedChanges = true;
    }

    const handleOverrideDescriptorChange = event => {
        const descriptor = event.target.value;

        setOverriddenDescriptor(descriptor);
        workOrderItem.data.overriddenDescriptor = descriptor ? descriptor : null;
        workOrderItem.hasUnsavedChanges = true;
    }

    const handleOverrideUnitCostChange = event => {
        const unitCost = event.target.value;

        setOverriddenUnitCost(unitCost);
        workOrderItem.data.overriddenUnitCost = unitCost ? fn.parseCurrency(unitCost) : null;
        workOrderItem.hasUnsavedChanges = true;
    }

    const handleReasonChange = (content, delta, source, editor) => {
        const reason = editor.getText().replace(/(\r\n|\n|\r)/g, ' ').trim();
        const reasonHtml = (content === '<p><br></p>') ? null : content;

        workOrderItem.updatedReason = reason;
        workOrderItem.updatedReasonHtml = reasonHtml;
        workOrderItem.hasUnsavedChanges = true;
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            const updated = workOrderItem.hasUnsavedChanges;

            workOrderItem.save()
                .then(data => {
                    if (isMounted.current) {
                        if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                            props.onSuccess(event, { updated: updated, data: data });
                        }
                    }
                })
        }
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <fieldset disabled={workOrderItem.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={'fal fa-edit'}
                        action='Update Work Order Item'
                        category='Description'
                        className='orders'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        <Observer>{() =>
                            workOrderItem.isReady ?
                                <FadeIn>
                                    <div className='update-product body-content'>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <span className='text-danger'><strong>Important:</strong><br />The description change only applies to the order item, and not the invoice.</span>
                                                </div>
                                            </div>
                                        </section>
                                        <Observer>{() =>
                                            <section>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='form-group mb-0 validate validate-required'>
                                                            <label className='required'><small>Brand</small></label>
                                                            <input
                                                                id='update-work-orderitem-brand'
                                                                type='text'
                                                                className='form-control'
                                                                spellCheck={false}
                                                                maxLength='150'
                                                                autoComplete='off'
                                                                value={overriddenBrandName ? overriddenBrandName : ''}
                                                                onChange={handleOverrideBrandNameChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        }</Observer>
                                        <Observer>{() =>
                                            <section>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='form-group mb-0 validate validate-required'>
                                                            <label className='required'><small>Supplier</small></label>
                                                            <input
                                                                id='update-work-orderitem-supplier'
                                                                type='text'
                                                                className='form-control'
                                                                spellCheck={false}
                                                                maxLength='150'
                                                                autoComplete='off'
                                                                value={overriddenSupplierName ? overriddenSupplierName : ''}
                                                                onChange={handleOverrideSupplierNameChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        }</Observer>
                                        <Observer>{() =>
                                            <section>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='form-group mb-0 validate validate-required'>
                                                            <label className='required'><small>Description</small></label>
                                                            <input
                                                                type='text'
                                                                className='form-control'
                                                                spellCheck={false}
                                                                maxLength='150'
                                                                autoComplete='off'
                                                                value={overriddenDescriptor ? overriddenDescriptor : ''}
                                                                onChange={handleOverrideDescriptorChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        }</Observer>
                                        <Observer>{() =>
                                            <section>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <div className='form-group mb-0'>
                                                            <label><small>Quantity</small></label>
                                                            <input
                                                                id='new-work-orderitem-quantity'
                                                                type='number'
                                                                className={'form-control'}
                                                                disabled={true}
                                                                value={(workOrderItem.data && workOrderItem.data.transactionItems ? workOrderItem.data.transactionItems.length : 0)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-4'>
                                                        <div className='form-group mb-0'>
                                                            <label><small>Unit Price</small></label>
                                                            <MaskedInput
                                                                id='new-work-orderitem-price'
                                                                type='text'
                                                                className='form-control'
                                                                spellCheck={false}
                                                                mask={MaskKeys.CURRENCY_MASK}
                                                                maxLength='25'
                                                                autoComplete='off'
                                                                disabled={true}
                                                                value={workOrderItem.data && workOrderItem.data.transactionItems && workOrderItem.data.transactionItems.length > 0 ? workOrderItem.data.transactionItems[0].unitPrice : 0}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-4'>
                                                        <div className='form-group mb-0'>
                                                            <label htmlFor='new-product-cost'><small>Unit Cost</small></label>
                                                            <MaskedInput
                                                                id='new-product-cost'
                                                                type='text'
                                                                className='form-control placeholder placeholder-sm'
                                                                spellCheck={false}
                                                                mask={MaskKeys.CURRENCY_MASK}
                                                                maxLength='25'
                                                                autoComplete='off'
                                                                value={overriddenUnitCost}
                                                                onChange={handleOverrideUnitCostChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        }</Observer>
                                        <Observer>{() =>
                                            <section>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='form-group mb-0'>
                                                            <label><small>Reason</small></label>
                                                            <RichTextEditor
                                                                mode='basic'
                                                                value={workOrderItem.updatedReasonHtml}
                                                                disableTab={true}
                                                                readOnly={workOrderItem.isSaving}
                                                                onChange={handleReasonChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        }</Observer>
                                    </div>
                                </FadeIn> : renderQuickDrawerLoading()
                        }</Observer>
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form >
    </>
}

export default UpdateWorkOrderItem;