import React, { useContext, useEffect, useRef, useState } from 'react';
import { Observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import FadeIn from 'react-fade-in';
import TimeAgo from 'react-timeago';
import { GlobalHotKeys } from 'react-hotkeys';
import { ContextMenu } from 'devextreme-react/context-menu';
import { toast } from 'react-toastify';
import moment from 'moment';

import BodyEnd from '../../_shared/BodyEnd';
import ConfirmModal from '../../_shared/ConfirmModalComponent';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import HtmlEditor from '../../_shared/HtmlEditor';

import GroupAppointmentNoteSearchStore from '../../../../stores/GroupAppointmentNoteSearchStore';
import NoteSearchStore from '../../../../stores/NoteSearchStore';
import FileViewerStore from '../../../../stores/FileViewerStore';
import AuthStore from '../../../../stores/AuthStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';
import useSignalR from '../../../hooks/useSignalR';

import * as fn from '../../../../utilities/_functions';
import * as ph from '../../../../utilities/personHelper';
import * as uh from '../../../../utilities/userHelper';
import * as ah from '../../../../utilities/accessHelper';
import * as oh from '../../../../utilities/operationHelper';
import * as sys from '../../../../utilities/systemHelper';

import './ViewGroupAppointmentNotes.scss';

function ViewGroupAppointmentNotes(props) {
    const isMounted = useRef(true);
    const bodyScrollTimer = useRef(null);
    const validateRef = useRef(null);
    const bodyRef = useRef(null);
    const newEditorRef = useRef(null);
    const updateEditorRef = useRef(null);
    const updateNoteRef = useRef(null);
    const confirmModalRef = useRef(null);
    const groupNoteSearch = useContext(GroupAppointmentNoteSearchStore);
    const noteSearch = useContext(NoteSearchStore);
    const auth = useContext(AuthStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const fileViewer = useContext(FileViewerStore);
    const signalR = useSignalR();
    const [isRendered, setIsRendered] = useState(false);

    useEffect(() => {
        signalR.on('Note', (updated) => {
            if (updated && groupNoteSearch.customer && groupNoteSearch.customer && groupNoteSearch.customer.id === updated.customerId) {
                groupNoteSearch.refresh();
            }
        });
        return () => {
            isMounted.current = false;
            if (bodyScrollTimer.current) { clearTimeout(bodyScrollTimer.current); bodyScrollTimer.current = null; }
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        newEditorRef.current.getInstance().focus();

        if (isRendered) {
            bodyScrollTimer.current = setTimeout(() => {
                if (bodyRef.current) {
                    bodyRef.current.scrollTop = 99999;
                }
            }, 100)
        }
    }, [isRendered]) // eslint-disable-line

    const handleViewCustomerNoteChange = (e) => {
        const customerId = e.target.value;
        const appointment = groupNoteSearch.appointments.filter(a => a.customerId === customerId)[0];

        if (appointment) {
            noteSearch.initialize(appointment.customer.id)
                .then(() => {
                    if (isMounted.current) {
                        noteSearch.reference = appointment;
                        noteSearch.referenceId = appointment.id;
                        noteSearch.referenceType = 'appointment';
                    }
                });
            quickDrawer.activateQuickDrawer('note', 'view', null, handleSeeNotesSuccess, handleSeeNotesCancel);
        }
    }

    const handleSeeNotesSuccess = () => {
        noteSearch.clear();
        groupNoteSearch.refresh();
    }

    const handleSeeNotesCancel = () => {
        noteSearch.clear();
    }

    const handleUpdateMessageChange = (e) => {
        const html = e.value;
        const preview = fn.stripHtml(html).trim().substring(0, 200);
        const bodyHtml = (html === '<p></p>') || (html === '<p><br></p>') ? '' : html;

        updateNoteRef.current.preview = preview;
        updateNoteRef.current.bodyHtml = bodyHtml;
    }

    const handleMessageChange = (e) => {
        const html = e.value;
        const preview = fn.stripHtml(html).trim().substring(0, 200);
        const bodyHtml = (html === '<p></p>') || (html === '<p><br></p>') ? '' : html;

        groupNoteSearch.newNote.bodyHtml = bodyHtml;
        groupNoteSearch.newNote.preview = preview;
    }

    const handleCancelUpdateNote = (e) => {
        groupNoteSearch.updateNote = null;
        updateNoteRef.current = null;
    }

    const handleSaveUpdateNote = (e) => {
        if (fn.validateForm(validateRef.current)) {
            groupNoteSearch.updateNote = updateNoteRef.current;
            groupNoteSearch.update()
                .then(() => {
                    updateEditorRef.current.getInstance().option('value', null);
                    updateNoteRef.current = null;
                    groupNoteSearch.updateNote = null;
                    toast.dark(() => <p data-note-upd>Note updated.</p>);
                })
        }
    }

    const handleEditNote = (event, note) => {
        const instance = updateEditorRef.current.getInstance();

        updateNoteRef.current = toJS(note);
        groupNoteSearch.updateNote = toJS(note);

        instance.option('value', updateNoteRef.current.bodyHtml);
        instance.setSelection(0, 9999);
        instance.focus();
    }

    const handleDeleteNote = (event, note) => {
        confirmModalRef.current.show({
            icon: <i className='fal fa-calendar text-danger mr-2'></i>,
            message: <>Continue to delete this note? </>,
            onOption1Click: (e) => { handleConfirmDeleteNote(e, note) },
            onCancel: handleConfirmNoteCancel
        })
    }

    const handleConfirmDeleteNote = (event, note) => {
        groupNoteSearch.removeNoteIds = note.ids;
        groupNoteSearch.save()
            .then(() => {
                if (isMounted.current) {
                    groupNoteSearch.refresh();
                }
            })
        confirmModalRef.current.close();
    }

    const handleConfirmNoteCancel = event => {
        confirmModalRef.current.close();
    }

    const handleContextMenuItemClick = event => {
        const { itemData } = event;

        if (itemData.callback && fn.isFunction(itemData.callback)) {
            itemData.callback();
        }

        event.component.hide();
    }

    const handleSubmit = event => {
        event.preventDefault();
        groupNoteSearch.save()
            .then(() => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event);
                    }
                }
            })
    }

    const handleAttachmentPreview = (event, attachment) => {
        const attachments = (groupNoteSearch.customer && groupNoteSearch.customer.notes && groupNoteSearch.customer.notes.some(n => n.attachments && n.attachments.length > 0) ?
            groupNoteSearch.customer.notes.filter(n =>
                n.attachments && n.attachments.length > 0).map(n => {
                    return n.attachments.map(a => { return a })
                }) : []).flat();
        const index = attachments.findIndex(a => a.id === attachment.id);

        fileViewer.initialize(
            () => {
                return groupNoteSearch.customer ? <div className='fs-lg'>
                    {ph.getPreferredFirstLastName(groupNoteSearch.customer)}
                    <small className='d-block text-gray-700'>View attachment(s)</small>
                </div> : null
            },
            'attachment',
            attachments,
            index,
            groupNoteSearch.customer.id
        );
    }

    const renderNoteFor = (note) => {
        if (note && note.customers) {
            const appointmentCustomers = Array.from(new Set([...groupNoteSearch.appointments.map(a => { return a.customerId })]));
            const noteCustomers = Array.from(new Set([...note.customers.map(c => { return c.id })]));

            appointmentCustomers.sort();
            noteCustomers.sort();

            if (appointmentCustomers.length === noteCustomers.length && !appointmentCustomers.some(ac => !noteCustomers.some(nc => nc === ac)) && !noteCustomers.some(nc => !appointmentCustomers.some(ac => ac === nc))) {
                return 'Everyone';
            }
            else {
                if (note.customers.length === 1) {
                    return ph.getPreferredFirstName(note.customers[0]);
                }
                else if (note.customers.length === 2) {
                    return note.customers.map(c => { return ph.getPreferredFirstName(c) }).join(' and ');
                }
                else if (note.customers.length > 2) {
                    return `${note.customers.slice(0, 2).map(c => { return ph.getPreferredFirstName(c) }).join(', ')} and ${note.customers.length - 2} others`;
                }
            }
        }

        return 'Unknown';
    }

    const getNoteIndicator = (note) => {
        if (!fn.isNullOrUndefined(note.deactivatedDateUtc)) {
            return <span className='badge p-0'><i className='fas fa-trash-alt text-gray-900'></i></span>
        }
        else if (note.isPinned) {
            return <span className='badge p-0'><i className='fas fa-thumbtack rotate-45 text-info'></i></span>
        }
        else {
            return <span className={`badge badge-icon${(note.referenceId === groupNoteSearch.referenceId ? '' : ` bg-${note.filterType}-900`)}`}></span>
        }
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    props.onCancel(event);
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <Observer>{() =>
                <fieldset disabled={!groupNoteSearch.isReady}>
                    <div className='quick-drawer view-group-appointment-note-drawer'>
                        <QuickDrawerHeader
                            drawer={props.drawer}
                            icon={oh.getIcon('note')}
                            action='Group Appointment'
                            category='Notes'
                            className='notes'
                            onCancel={props.onCancel}
                        />
                        <div className='note-filters-wrapper'>
                            <div className='d-flex align-items-center w-100'>
                                <strong className='mr-3'>View:</strong>
                                <div className='flex-1'>
                                    <select
                                        className='custom-select form-control'
                                        value=''
                                        onChange={handleViewCustomerNoteChange}
                                    >
                                        <option value=''>Everyone's notes</option>
                                        {
                                            groupNoteSearch.appointments && groupNoteSearch.appointments.length > 0 ?
                                                groupNoteSearch.appointments.map((a, ai) => {
                                                    return <option key={`view_note_${ai}`} value={a.customerId}>{ph.getPreferredFirstName(a.customer)}'s notes only</option>
                                                }) : null
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div ref={bodyRef} className='quick-drawer-body'>
                            {
                                groupNoteSearch.isReady ?
                                    <>
                                        <FadeIn>
                                            <div className={'view-note body-content py-1' + (isRendered ? ' rendered' : '')}>
                                                {
                                                    groupNoteSearch.notes && groupNoteSearch.notes.length > 0 ?
                                                        groupNoteSearch.notes.map((n, ni) => {
                                                            return <div
                                                                key={`note-${ni}`}
                                                                className={
                                                                    'd-flex flex-column align-items-center position-relative' +
                                                                    (!fn.isNullOrUndefined(n.deactivatedDateUtc) ? ' deleted' : '')
                                                                }
                                                            >
                                                                {
                                                                    !!groupNoteSearch.updateNote && groupNoteSearch.updateNote.id !== n.id ?
                                                                        <div className='note-overlay'></div> : null
                                                                }
                                                                <div className='d-flex flex-row w-100 py-4 overflow-hidden'>
                                                                    <div className='d-inline-block align-middle mr-3'>
                                                                        <span
                                                                            className={'profile-image profile-image-md rounded-circle d-block' + (n.resource && !n.resource.profilePictureUri ? ` profile-initials text-white bg-color${n.resource.color}-500` : ' mt-1')}
                                                                            style={!n.resource || n.resource.profilePictureUri ? {
                                                                                backgroundImage: `url(${(n.resource && n.resource.profilePictureUri ? n.resource.profilePictureUri : '/media/img/default-genki.jpg')})`,
                                                                                backgroundSize: 'cover',
                                                                            } : null}
                                                                            title={n.resource ? n.resource.fullName : 'System'}>
                                                                            {n.resource && !n.resource.profilePictureUri ? <div className='d-initials fs-xs'>{n.resource.initials}</div> : null}
                                                                            {getNoteIndicator(n)}
                                                                        </span>
                                                                    </div>
                                                                    <div className='mb-0 flex-1 text-dark'>
                                                                        <div className='d-flex'>
                                                                            <div className='text-gray-800 fw-500'>
                                                                                <div className='note-by position-relative'>
                                                                                    <div className='text-truncate text-truncate-md' title={renderNoteFor(n)}>For: {renderNoteFor(n)}</div>
                                                                                </div>
                                                                            </div>
                                                                            <Observer>{() => <span className='flex-1 position-relative'>
                                                                                <span className='badges pl-1'>
                                                                                    {
                                                                                        !!groupNoteSearch.updateNote && n.id === groupNoteSearch.updateNote.id ?
                                                                                            <>
                                                                                                <span className='badge badge-danger ml-h'>Editing ...</span>
                                                                                            </> :
                                                                                            <>
                                                                                                {
                                                                                                    n.lastUpdatedDateUtc ?
                                                                                                        <span className='badge badge-dark ml-h'>Edited</span> : null
                                                                                                }
                                                                                                {
                                                                                                    n.isPinned ?
                                                                                                        <span className='badge badge-info ml-h'>Pinned</span> : null
                                                                                                }
                                                                                            </>
                                                                                    }
                                                                                </span>
                                                                            </span>
                                                                            }</Observer>
                                                                            <span className='ml-auto'>
                                                                                {
                                                                                    n.lastUpdatedDateUtc ?
                                                                                        moment().startOf('day').isSame(moment.utc(n.lastUpdatedDateUtc).local().startOf('day')) ?
                                                                                            <TimeAgo
                                                                                                className='text-muted fs-xs opacity-70'
                                                                                                date={moment.utc(n.lastUpdatedDateUtc).local().toDate()}
                                                                                                title={sys.getFormattedLongDate(moment.utc(n.lastUpdatedDateUtc).local(), true)}
                                                                                                formatter={(value, unit, suffix) => { return fn.timeAgoPastFormatter(value, unit, suffix, 'second', 10, 'Just now', true) }}
                                                                                                minPeriod={60}
                                                                                            /> :
                                                                                            <span
                                                                                                className='text-muted fs-xs opacity-70'
                                                                                                title={sys.getFormattedLongDate(moment.utc(n.lastUpdatedDateUtc).local(), true)}
                                                                                            >
                                                                                                {sys.getFormattedShortDate(moment.utc(n.lastUpdatedDateUtc).local())}
                                                                                            </span> :
                                                                                        moment().startOf('day').isSame(moment.utc(n.createdDateUtc).local().startOf('day')) ?
                                                                                            <TimeAgo
                                                                                                className='text-muted fs-xs opacity-70'
                                                                                                date={moment.utc(n.createdDateUtc).local().toDate()}
                                                                                                title={moment.utc(n.createdDateUtc).local().format('dddd MMMM D, YYYY h:mm a')}
                                                                                                formatter={(value, unit, suffix) => { return fn.timeAgoPastFormatter(value, unit, suffix, 'second', 10, 'Just now', true) }}
                                                                                                minPeriod={60}
                                                                                            /> :
                                                                                            <span
                                                                                                className='text-muted fs-xs opacity-70'
                                                                                                title={sys.getFormattedLongDate(moment.utc(n.createdDateUtc).local(), true)}
                                                                                            >
                                                                                                {sys.getFormattedShortDate(moment.utc(n.createdDateUtc).local())}
                                                                                            </span>
                                                                                }
                                                                                <div
                                                                                    id={`${n.id}`}
                                                                                    className='btn btn-icon btn-xs p-0 line-height-1 mr-n2'
                                                                                >
                                                                                    <i className='fal fa-ellipsis-v fs-xl'></i>
                                                                                </div>
                                                                                <ContextMenu
                                                                                    target={`#${n.id}`}
                                                                                    position={{ my: 'top right', at: 'top left' }}
                                                                                    dataSource={[{
                                                                                        icon: 'fal fa-pen',
                                                                                        name: 'Edit',
                                                                                        disabled: auth.currentUser.id !== n.createdById,
                                                                                        callback: (e) => handleEditNote(e, n),
                                                                                    }, {
                                                                                        icon: 'fal fa-trash-alt',
                                                                                        name: 'Delete',
                                                                                        disabled: !ah.hasFullAccess(auth.currentUser.id) && auth.currentUser.id !== n.createdById,
                                                                                        callback: (e) => handleDeleteNote(e, n),
                                                                                    }]}
                                                                                    displayExpr='name'
                                                                                    showEvent="dxcontextmenu click"
                                                                                    onItemClick={handleContextMenuItemClick}
                                                                                >
                                                                                </ContextMenu>
                                                                            </span>
                                                                        </div>
                                                                        <div className='d-flex'>
                                                                            <span className='text-gray-600'>
                                                                                By: {n.resource ? uh.getDisplayShortName(n.resource) : 'System'}
                                                                            </span>
                                                                        </div>
                                                                        <div
                                                                            className={'html mt-1 ' + (n.attachments && n.attachments.length > 0 ? 'mb-2' : 'mb-0')}
                                                                            dangerouslySetInnerHTML={{ __html: n.bodyHtml }}
                                                                        ></div>
                                                                        {
                                                                            !fn.isNullOrUndefined(n.deactivatedDateUtc) ?
                                                                                <div className='text-gray-600'>
                                                                                    <small><em>Deleted by: {uh.getDisplayShortNameById(n.deactivatedById, n.deactivatedBy)} - {moment.utc(n.deactivatedDateUtc).local().format('YYYY-MM-DD')}</em></small>
                                                                                </div> : null
                                                                        }
                                                                        {
                                                                            n.attachments && n.attachments.length > 0 ?
                                                                                <>
                                                                                    {
                                                                                        n.attachments.slice(0, 3).map((a, ai) => {
                                                                                            return <div
                                                                                                key={`attachment-${ni}-${ai}`}
                                                                                                className={'card attachment border' + (ai !== (n.attachments.length - 1) ? ' mb-2' : '')}
                                                                                            >
                                                                                                <div className='card-body'>
                                                                                                    <div className='d-flex flex-row w-100'>
                                                                                                        <div className='d-flex align-middle align-items-center mr-3'>
                                                                                                            {fn.toFileIcon(a.mimeType, 'fs-xxl')}
                                                                                                        </div>
                                                                                                        <div className='flex-1'>
                                                                                                            <div className='d-block text-truncate text-truncate-md fw-500 text-gray-800'>{a.fileName}</div>
                                                                                                            <div className='d-flex flex-row align-items-center fs-xs'>
                                                                                                                <button
                                                                                                                    type='button'
                                                                                                                    className='btn btn-xs btn-link p-0'
                                                                                                                    onClick={(e) => { handleAttachmentPreview(e, a) }}
                                                                                                                >
                                                                                                                    Preview
                                                                                                                </button>
                                                                                                                <span className='line-height-1'>&nbsp;&middot;&nbsp;</span>
                                                                                                                <span className='line-height-1 text-gray-500 fs-xs'>{fn.toFileSize(a.size, true)}</span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        })
                                                                                    }
                                                                                    {
                                                                                        n.attachments.length > 3 ?
                                                                                            <div
                                                                                                className='card border cursor-pointer border-dashed d-inline-block'
                                                                                                onClick={(e) => { handleAttachmentPreview(e, n.attachments[3]) }}
                                                                                            >
                                                                                                <div className='card-body'>
                                                                                                    <div className='fs-lg p-2 text-center'>
                                                                                                        +{n.attachments.length - 3}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div> : null
                                                                                    }
                                                                                </> : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {
                                                                    groupNoteSearch.notes.length !== (ni + 1) ?
                                                                        <hr className={'m-0 w-100' + (
                                                                            !moment.utc(groupNoteSearch.notes[ni].createdDateUtc).local().startOf('day').isSameOrAfter(moment().add(-2, 'weeks').startOf('day')) &&
                                                                                moment.utc(groupNoteSearch.notes[ni + 1].createdDateUtc).local().startOf('day').isSameOrAfter(moment().add(-2, 'weeks').startOf('day')) ?
                                                                                ' new' : ''
                                                                        )} /> : null
                                                                }
                                                            </div>
                                                        }) : null
                                                }
                                            </div>
                                        </FadeIn>
                                        {setIsRendered(true)}
                                    </> : null
                            }
                        </div>
                        <div className='quick-drawer-action'>
                            <div className='row'>
                                <div className='col-12'>
                                    <hr className='m-0 w-100' />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 validate validate-required'>
                                    <Observer>{() => <>
                                        <HtmlEditor
                                            ref={newEditorRef}
                                            height={130}
                                            className={!!groupNoteSearch.updateNote ? 'd-none' : ''}
                                            placeholder='Write note here...'
                                            toolbar={{ items: ['bold', 'italic', 'underline', 'separator', 'orderedList', 'bulletList', 'separator', 'color', 'background'] }}
                                            disableTab={true}
                                            onValueChanged={handleMessageChange}
                                        ></HtmlEditor>
                                        <HtmlEditor
                                            ref={updateEditorRef}
                                            height={130}
                                            className={!!groupNoteSearch.updateNote ? '' : 'd-none'}
                                            placeholder='Update note here...'
                                            toolbar={{ items: ['bold', 'italic', 'underline', 'separator', 'orderedList', 'bulletList', 'separator', 'color', 'background'] }}
                                            disableTab={true}
                                            onValueChanged={handleUpdateMessageChange}
                                        ></HtmlEditor>
                                    </>
                                    }</Observer>
                                </div>
                            </div>
                            <div className='row px-4 py-3'>
                                <div className='col-12'>
                                    {
                                        !!groupNoteSearch.updateNote ?
                                            <div className='float-right'>
                                                <button
                                                    type='button'
                                                    className='btn btn-link btn-cancel mr-2'
                                                    onClick={handleCancelUpdateNote}
                                                >Cancel</button>
                                                <button
                                                    type='button'
                                                    className='btn btn-success'
                                                    onClick={handleSaveUpdateNote}
                                                >Save</button>
                                            </div> :
                                            <div className='float-right'>
                                                <button
                                                    type='button'
                                                    className='btn btn-link btn-cancel mr-2'
                                                    onClick={props.onCancel}
                                                >Cancel</button>
                                                <button
                                                    type='submit'
                                                    className='btn btn-success'
                                                >Save</button>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            }</Observer>
        </form>
        <BodyEnd>
            <Observer>{() =>
                <ConfirmModal ref={confirmModalRef} />
            }</Observer>
        </BodyEnd>
    </>
}

export default ViewGroupAppointmentNotes;