import { createContext } from 'react';
import uuid from 'react-uuid';
import { decorate, observable, action, toJS } from 'mobx';

import * as fn from '../utilities/_functions';

import api from '../api';

export class MedicalReportTemplateCreate {
    id = null;
    data = {
        category: null,
        name: null,
        descriptionHtml: null,
        pageSettings: null,
        headerDefinition: null,
        bodyDefinition: null,
        footerDefinition: null,
    };
    isReady = false;
    isSaving = false;
    hasUnsavedChanges = false;

    cancelMedicalReportTemplateCreate = null;

    initialize = () => {
        this.clear();
        this.id = uuid();
        this.isReady = true;
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            let option = toJS(that.data);

            api.MedicalReportTemplates.create(option, (c) => { that.cancelMedicalReportTemplateCreate = c })
                .then(({ data }) => {
                    that.id = data.id;
                    that.hasUnsavedChanges = false;
                    option.id = data.id;
                    resolve(option);
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    clear = () => {
        this.id = null;
        this.data.category = null;
        this.data.name = null;
        this.data.descriptionHtml = null;
        this.data.pageSettings = null;
        this.data.headerDefinition = null;
        this.data.bodyDefinition = null;
        this.data.footerDefinition = null;
        this.hasUnsavedChanges = false;

        if (fn.isFunction(this.cancelMedicalReportTemplateCreate)) {
            this.cancelMedicalReportTemplateCreate();
            this.cancelMedicalReportTemplateCreate = null;
        }
    }
}

decorate(MedicalReportTemplateCreate, {
    id: observable,
    data: observable.deep,
    hasUnsavedChanges: observable,
    isReady: observable,
    isSaving: observable,
    initialize: action,
    save: action,
    clear: action,
})

export default createContext(new MedicalReportTemplateCreate());