import { createContext } from 'react';
import { decorate, observable, action } from 'mobx';
import moment from 'moment';

import api from '../api';
import * as fn from '../utilities/_functions';

export class CustomerMerge {
    id1 = null;
    id2 = null;
    mergeFromData = null;
    mergeToData = null;
    isLoading = false;
    isSaving = false;
    isReady = false;

    cancelCustomer1 = null;
    cancelCustomer2 = null;
    cancelAppointments1 = null;
    cancelAppointments2 = null;
    cancelExams1 = null;
    cancelExams2 = null;
    cancelPurchases1 = null;
    cancelPurchases2 = null;
    cancelWorkOrders1 = null;
    cancelWorkOrders2 = null;
    cancelPrescriptions1 = null;
    cancelPrescriptions2 = null;
    cancelReferralAppointments1 = null;
    cancelReferralAppointments2 = null;
    cancelNotes1 = null;
    cancelNotes2 = null;
    cancelSave = null;

    initialize = (id1, id2) => {
        const that = this;

        this.clear();
        this.id1 = id1;
        this.id2 = id2;

        return new Promise((resolve, reject) => {
            Promise.all([
                api.Customers.get(that.id1, (c) => { that.cancelCustomer1 = c }),
                api.Customers.get(that.id2, (c) => { that.cancelCustomer2 = c }),
                api.Appointments.search({ parameters: [{ field: 'CustomerId', value: that.id1, }, { field: 'DeactivatedDateUtc', value: null, }], includeTotalCount: true, }, (c) => { that.cancelAppointments1 = c }),
                api.Appointments.search({ parameters: [{ field: 'CustomerId', value: that.id2, }, { field: 'DeactivatedDateUtc', value: null, }], includeTotalCount: true, }, (c) => { that.cancelAppointments2 = c }),
                api.Exams.search({ parameters: [{ field: 'CustomerId', value: that.id1, }, { field: 'DeactivatedDateUtc', value: null, }, { field: 'Status', operator: '!=', value: 'Deleted', }], includeTotalCount: true, }, (c) => { that.cancelExams1 = c }),
                api.Exams.search({ parameters: [{ field: 'CustomerId', value: that.id2, }, { field: 'DeactivatedDateUtc', value: null, }, { field: 'Status', operator: '!=', value: 'Deleted', }], includeTotalCount: true, }, (c) => { that.cancelExams2 = c }),
                api.Purchases.search({ parameters: [{ field: 'CustomerId', value: that.id1, }, { field: 'DeactivatedDateUtc', value: null, }], includeTotalCount: true, }, (c) => { that.cancelPurchases1 = c }),
                api.Purchases.search({ parameters: [{ field: 'CustomerId', value: that.id2, }, { field: 'DeactivatedDateUtc', value: null, }], includeTotalCount: true, }, (c) => { that.cancelPurchases2 = c }),
                api.WorkOrders.search({ parameters: [{ field: 'CustomerId', value: that.id1, }, { field: 'DeactivatedDateUtc', value: null, }], includeTotalCount: true, }, (c) => { that.cancelWorkOrders1 = c }),
                api.WorkOrders.search({ parameters: [{ field: 'CustomerId', value: that.id2, }, { field: 'DeactivatedDateUtc', value: null, }], includeTotalCount: true, }, (c) => { that.cancelWorkOrders2 = c }),
                api.Prescriptions.search({ parameters: [{ field: 'CustomerId', value: that.id1, }, { field: 'DeactivatedDateUtc', value: null, }], includeTotalCount: true, }, (c) => { that.cancelPrescriptions1 = c }),
                api.Prescriptions.search({ parameters: [{ field: 'CustomerId', value: that.id2, }, { field: 'DeactivatedDateUtc', value: null, }], includeTotalCount: true, }, (c) => { that.cancelPrescriptions2 = c }),
                api.ReferralAppointments.search({ parameters: [{ field: 'CustomerId', value: that.id1, }, { field: 'DeactivatedDateUtc', value: null, }], includeTotalCount: true, }, (c) => { that.cancelReferralAppointments1 = c }),
                api.ReferralAppointments.search({ parameters: [{ field: 'CustomerId', value: that.id2, }, { field: 'DeactivatedDateUtc', value: null, }], includeTotalCount: true, }, (c) => { that.cancelReferralAppointments2 = c }),
                api.Notes.search({ parameters: [{ field: 'CustomerId', value: that.id1, }, { field: 'DeactivatedDateUtc', value: null, }], includeTotalCount: true, }, (c) => { that.cancelReferralAppointments1 = c }),
                api.Notes.search({ parameters: [{ field: 'CustomerId', value: that.id2, }, { field: 'DeactivatedDateUtc', value: null, }], includeTotalCount: true, }, (c) => { that.cancelReferralAppointments2 = c }),
            ])
                .then((response) => {
                    const data1 = response[0].data;
                    const data2 = response[1].data;

                    data1.appointmentCount = response[2].data ? response[2].data.total : 0;
                    data2.appointmentCount = response[3].data ? response[3].data.total : 0;
                    data1.examCount = response[4].data ? response[4].data.total : 0;
                    data2.examCount = response[5].data ? response[5].data.total : 0;
                    data1.purchaseCount = response[6].data ? response[6].data.total : 0;
                    data2.purchaseCount = response[7].data ? response[7].data.total : 0;
                    data1.workOrderCount = response[8].data ? response[8].data.total : 0;
                    data2.workOrderCount = response[9].data ? response[9].data.total : 0;
                    data1.prescriptionCount = response[10].data ? response[10].data.total : 0;
                    data2.prescriptionCount = response[11].data ? response[11].data.total : 0;
                    data1.referralAppointmentCount = response[12].data ? response[12].data.total : 0;
                    data2.referralAppointmentCount = response[13].data ? response[13].data.total : 0;
                    data1.noteCount = response[14].data ? response[14].data.total : 0;
                    data2.noteCount = response[15].data ? response[15].data.total : 0;

                    data1.score = data1.appointmentCount + data1.examCount + data1.purchaseCount + data1.workOrderCount + data1.prescriptionCount + data1.referralAppointmentCount + data1.noteCount;
                    data2.score = data2.appointmentCount + data2.examCount + data2.purchaseCount + data2.workOrderCount + data2.prescriptionCount + data2.referralAppointmentCount + data1.noteCount;

                    if (data1.score >= data2.score) {
                        that.id1 = data2.id;
                        that.id2 = data1.id;
                        that.mergeFromData = data2;
                        that.mergeToData = data1;
                    }
                    else {
                        that.id1 = data1.id;
                        that.id2 = data2.id;
                        that.mergeFromData = data1;
                        that.mergeToData = data2;
                    }
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isLoading = false;
                    that.isReady = true;
                })
        })
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            api.Customers.deactivate(that.mergeFromData.id, {
                reason: 'Duplicate',
                mergeToId: that.mergeToData.id,
                deactivateDateUtc: moment(),
            }, (c) => { that.cancelSave = c; })
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    clear = () => {
        this.id1 = null;
        this.id2 = null;
        this.mergeFromData = null;
        this.mergeToData = null;
        this.isLoading = false;
        this.isSaving = false;
        this.isReady = false;

        if (fn.isFunction(this.cancelCustomer1)) {
            this.cancelCustomer1();
            this.cancelCustomer1 = null;
        }

        if (fn.isFunction(this.cancelCustomer2)) {
            this.cancelCustomer2();
            this.cancelCustomer2 = null;
        }

        if (fn.isFunction(this.cancelAppointments1)) {
            this.cancelAppointments1();
            this.cancelAppointments1 = null;
        }

        if (fn.isFunction(this.cancelAppointments2)) {
            this.cancelAppointments2();
            this.cancelAppointments2 = null;
        }

        if (fn.isFunction(this.cancelExams1)) {
            this.cancelExams1();
            this.cancelExams1 = null;
        }

        if (fn.isFunction(this.cancelExams2)) {
            this.cancelExams2();
            this.cancelExams2 = null;
        }

        if (fn.isFunction(this.cancelPurchases1)) {
            this.cancelPurchases1();
            this.cancelPurchases1 = null;
        }

        if (fn.isFunction(this.cancelPurchases2)) {
            this.cancelPurchases2();
            this.cancelPurchases2 = null;
        }

        if (fn.isFunction(this.cancelWorkOrders1)) {
            this.cancelWorkOrders1();
            this.cancelWorkOrders1 = null;
        }

        if (fn.isFunction(this.cancelWorkOrders2)) {
            this.cancelWorkOrders2();
            this.cancelWorkOrders2 = null;
        }

        if (fn.isFunction(this.cancelPrescriptions1)) {
            this.cancelPrescriptions1();
            this.cancelPrescriptions1 = null;
        }

        if (fn.isFunction(this.cancelPrescriptions2)) {
            this.cancelPrescriptions2();
            this.cancelPrescriptions2 = null;
        }

        if (fn.isFunction(this.cancelReferralAppointments1)) {
            this.cancelReferralAppointments1();
            this.cancelReferralAppointments1 = null;
        }

        if (fn.isFunction(this.cancelReferralAppointments2)) {
            this.cancelReferralAppointments2();
            this.cancelReferralAppointments2 = null;
        }

        if (fn.isFunction(this.cancelSave)) {
            this.cancelSave();
            this.cancelSave = null;
        }
    }
}

decorate(CustomerMerge, {
    id1: observable,
    id2: observable,
    mergeFromData: observable,
    mergeToData: observable,
    isLoading: observable,
    isSaving: observable,
    isReady: observable,
    initialize: action,
    clear: action,
    save: action,
})

export default createContext(new CustomerMerge());