import { createContext } from 'react';
import { decorate, observable, action } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class SearchBar {
    isActivated = false;
    searchTerm = null;
    searchResult = [];
    isLoading = false;

    cancelCustomerFullSearch = null;

    activateSearchbar = () => {
        this.isActivated = true;
        return Promise.resolve();
    }

    deactivateSearchbar = () => {
        this.isActivated = false;
        return Promise.resolve();
    }

    execute = () => {
        if (!this.searchTerm || this.searchTerm.length < 2) {
            this.searchResult.clear();
            return Promise.resolve();
        }

        const that = this;
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            Promise.all([
                api.Customers.fullSearch(that.searchTerm, false, true, 5, null, null, null, null, null, null, (c) => { that.cancelCustomerFullSearch = c })
            ])
                .then(response => {
                    const searchResult = response[0].data.result ? response[0].data.result : [];
                    that.searchResult.clear();
                    that.searchResult = searchResult;
                    resolve();
                })
                .catch(error => {
                    reject(error);
                })
                .finally(() => {
                    that.isLoading = false;
                });
        })
    }

    clear = () => {
        this.isActivated = false;
        this.searchTerm = null;
        this.searchResult = [];
        this.isLoading = false;

        if (fn.isFunction(this.cancelCustomerFullSearch)) {
            this.cancelCustomerFullSearch();
            this.cancelCustomerFullSearch = null;
        }
    }
}

decorate(SearchBar, {
    isActivated: observable,
    searchTerm: observable,
    searchResult: observable.deep,
    isLoading: observable,
    execute: action,
})

export default createContext(new SearchBar());