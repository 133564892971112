import React, { useEffect, useContext, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import FadeIn from 'react-fade-in';
import { useObserver } from 'mobx-react-lite';
import MaskedInput from 'react-text-mask'
import { GlobalHotKeys } from 'react-hotkeys';
import moment from 'moment';

import BodyEnd from '../../_shared/BodyEnd';
import ConfirmModal from '../../_shared/ConfirmModal';
import AddressBookModal from '../../_shared/AddressBookModal/_index';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';

import CustomerUpdateStore from '../../../../stores/CustomerUpdateStore';
import AuthStore from '../../../../stores/AuthStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as rts from '../../../../constants/routes';
import * as MaskKeys from '../../../../constants/maskKeys';
import * as fn from '../../../../utilities/_functions';
import * as ch from '../../../../utilities/customerHelper';
import * as ph from '../../../../utilities/personHelper';
import * as sys from '../../../../utilities/systemHelper';
import * as oh from '../../../../utilities/operationHelper';
import * as ah from '../../../../utilities/addressHelper';

import api from '../../../../api';

import './UpdatePatient.scss';

function UpdatePatient(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const focusTimer = useRef(null);
    const addressBookModalRef = useRef(null);
    const customer = useContext(CustomerUpdateStore);
    const auth = useContext(AuthStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [validatedHealthCard, setValidatedHealthCard] = useState(null);
    const [confirmPopulateCustomer, setConfirmPopulateCustomer] = useState(false);
    const [isValidatingHealthCard, setIsValidatingHealthCard] = useState(false);
    const [isHealthCardChanged, setIsHealthCardChanged] = useState(false);

    useEffect(() => {
        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100)

        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const validateHealthCard = () => {
        if (customer.patientProfile && customer.patientProfile.healthCardNumber && (!validatedHealthCard || validatedHealthCard.healthCardNumber !== customer.patientProfile.healthCardNumber.replace(/[^a-zA-Z0-9]/g, ''))) {
            customer.hasPatientUnsavedChanges = true;
            setIsValidatingHealthCard(true);

            api.Customers.healthCard({ healthCardNumber: customer.patientProfile.healthCardNumber })
                .then(({ data }) => {
                    if (isMounted.current && data) {
                        setValidatedHealthCard(data);

                        customer.patientProfile.isVerified = !!data.isVerified;

                        if (!!data.isVerified && !data.isValid) {
                            customer.patientProfile.verificationResponse = !!data.errorDescription ? data.errorDescription : 'Unknown reason.';
                        }
                        else {
                            customer.patientProfile.verificationResponse = null;
                        }

                        if (data.isVerified && data.isValid) {
                            customer.patientProfile.healthCardExpiryDate = data.expiryDate ? moment(data.expiryDate).format('YYYY-MM-DD') : null;
                            setConfirmPopulateCustomer(true);
                        }
                    }
                })
                .finally(() => {
                    if (isMounted.current) {
                        setIsValidatingHealthCard(false);
                    }
                })
        }
    }

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (customer.hasPatientUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleHealthCardNumberChange = event => {
        customer.hasPatientUnsavedChanges = true;
        customer.patientProfile.healthCardNumber = event.target.value;
        customer.patientProfile.healthCardExpiryDate = null;
        setIsHealthCardChanged(true);
        setValidatedHealthCard(null);
    }

    const handleHealthCardNumberBlur = event => {
        if (isHealthCardChanged) {
            validateHealthCard();
        }
    }

    const handleHealthCardExpiryChange = event => {
        customer.hasPatientUnsavedChanges = true;
        customer.patientProfile.healthCardExpiryDate = event.target.value;
    }

    const handleRecallFrequencyChange = event => {
        customer.hasPatientUnsavedChanges = true;
        customer.patientProfile.recallFrequencyInMonth = event.target.value;
    }

    const handleDoNotRecallChange = event => {
        customer.hasPatientUnsavedChanges = true;
        customer.patientProfile.doNotRecall = !customer.patientProfile.doNotRecall;
    }

    const handleAddressBookOpen = (event) => {
        addressBookModalRef.current.show('ExternalDoctor');
    }

    const handleFamilyDoctorClinicSelected = async (rowInfo) => {
        if (rowInfo && rowInfo.selectedRowsData) {
            if (rowInfo.selectedRowsData[0].type === 'ExternalClinic') {
                customer.patientProfile.familyDoctorClinicId = rowInfo.selectedRowsData[0].id;
                customer.patientProfile.familyDoctorClinic = (await api.ExternalClinics.get(rowInfo.selectedRowsData[0].id)).data;
                customer.patientProfile.familyDoctorId = null;
            }
            else if (rowInfo.selectedRowsData[0].type === 'ExternalDoctor') {
                customer.patientProfile.familyDoctorId = rowInfo.selectedRowsData[0].id;
                customer.patientProfile.familyDoctor = (await api.ExternalDoctors.get(rowInfo.selectedRowsData[0].id)).data;
                customer.patientProfile.familyDoctorClinicId = rowInfo.selectedRowsData[0].secondaryId;
                customer.patientProfile.familyDoctorClinic = (await api.ExternalClinics.get(rowInfo.selectedRowsData[0].secondaryId)).data;
            }

            customer.hasPatientUnsavedChanges = true;
            addressBookModalRef.current.close();
        }
    }

    const handleFamilyDoctorClinicClear = () => {
        customer.patientProfile.familyDoctorClinicId = null;
        customer.patientProfile.familyDoctorClinic = null;
        customer.patientProfile.familyDoctorId = null;
        customer.patientProfile.familyDoctor = null;

        customer.hasPatientUnsavedChanges = true;
    }

    const handlePopulateCustomerConfirm = event => {
        if (customer.data.firstName && validatedHealthCard.firstName && fn.similarity(customer.data.firstName, validatedHealthCard.firstName) < 0.6 && customer.data.firstName !== `${validatedHealthCard.firstName} ${validatedHealthCard.middleName}`.trim()) {
            customer.data.preferredFirstName = customer.data.firstName;
        }
        if (validatedHealthCard.firstName) {
            customer.data.firstName = validatedHealthCard.firstName;
        }
        if (validatedHealthCard.middleName) {
            customer.data.middleName = validatedHealthCard.middleName;
        }
        if (validatedHealthCard.lastName) {
            customer.data.lastName = validatedHealthCard.lastName;
        }
        if (validatedHealthCard.sex) {
            customer.data.sexType = validatedHealthCard.sex;
        }
        if (validatedHealthCard.dateOfBirth) {
            customer.data.dateOfBirth = moment(validatedHealthCard.dateOfBirth).format('YYYY-MM-DD');
        }
        if (customer.data.preferredFirstName === customer.data.firstName) {
            customer.data.preferredFirstName = null;
        }

        customer.hasPatientUnsavedChanges = true;
        customer.hasPersonalUnsavedChanges = true;
        setConfirmPopulateCustomer(false);
    }

    const handlePopulateCustomerCancel = event => {
        setConfirmPopulateCustomer(false);
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            if (customer.hasPatientUnsavedChanges) {
                const updated = customer.hasPatientUnsavedChanges;

                customer.updatePatientInformation(true)
                    .then(data => {
                        if (customer.hasPersonalUnsavedChanges) {
                            customer.updatePersonalInformation(true)
                                .then(() => {
                                    if (isMounted.current) {
                                        if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                                            props.onSuccess(event, { updated: updated, data: data });
                                        }
                                    }
                                })
                        }
                        else {
                            if (isMounted.current) {
                                if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                                    props.onSuccess(event, { updated: updated, data: data });
                                }
                            }
                        }
                    })
            } else {
                if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                    props.onSuccess(event, { updated: false, data: null });
                }
            }
        }
    }

    const renderCustomer = () => {
        return <div
            className='profile-wrapper'
        >
            <div className='profile'>
                <span
                    className={`profile-image profile-initials rounded-circle d-flex text-white ${ch.getProfileColor(customer.data)} fw-500`}
                    title={ph.getFullName(customer.data)}
                >
                    {customer.data.initials}
                </span>
            </div>
            <div className='description flex-1'>
                <Link to={`${rts.Customers.Home}/${customer.data.id}`} className='name text-gray-700' onClick={quickDrawer.deactivateAll}>{ph.getFullName(customer.data, true)}
                    {
                        customer.data.dateOfBirth || customer.data.sex || customer.data.gender || customer.data.pronoun ?
                            <small className='text-nowrap ml-2'>({`${ph.getAge(customer.data.dateOfBirth)} ${ph.getSexGenderPronounDisplay(customer.data)}`.trim()})</small> : null
                    }
                </Link>
                {
                    customer.data.address && customer.data.address.country ?
                        <div className='info'>{ah.getAddressHtml(customer.data.address)}</div> : null
                }
                {
                    customer.data.emailAddress ?
                        <div className='info'>
                            <a
                                href={`mailto:${customer.data.emailAddress}`}
                            >{customer.data.emailAddress}
                            </a>
                        </div> : null
                }
                {
                    customer.data.phoneNumber ?
                        <div className='info'>
                            <a
                                href={`tel:${customer.data.phoneNumber}`}
                            >{sys.getFormattedPhoneNumber(customer.data.phoneNumber)}
                            </a>
                        </div> : null
                }
            </div>
        </div>
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <fieldset disabled={customer.isSaving || isValidatingHealthCard}>
                <div className='update-customer-patient-container quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('customer', 'patient')}
                        action='Update Customer'
                        category='Patient Profile'
                        className='customers'
                        onCancel={props.onCancel}
                    />
                    <div className='quick-drawer-body'>
                        {
                            customer.isReady && customer.patientProfile ?
                                <FadeIn>
                                    <div className='body-content'>
                                        {
                                            !!validatedHealthCard ?
                                                <>
                                                    {
                                                        !validatedHealthCard.isVerified ?
                                                            <section>
                                                                <div className='row'>
                                                                    <div className='col-12'>
                                                                        <div className='alert alert-warning p-3 mb-0' role='alert'>
                                                                            <strong className='d-block mb-2'>Health card not verified</strong>
                                                                            <ul className='pl-3 mb-0'>
                                                                                <li><strong className='text-warning-900'>{auth.currentTenant.publicInsuranceUnitId} verification has not been setup.</strong></li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </section> : null
                                                    }
                                                    {
                                                        !!validatedHealthCard.isVerified && !validatedHealthCard.isValid ?
                                                            <section>
                                                                <div className='row'>
                                                                    <div className='col-12'>
                                                                        <div className='alert alert-warning p-3 mb-0' role='alert'>
                                                                            <strong className='d-block mb-2'>Invalid health card</strong>
                                                                            <ul className='pl-3 mb-0'>
                                                                                <li>{(!!validatedHealthCard.errorCode ? `(Error: ${validatedHealthCard.errorCode}) ` : '')}{validatedHealthCard.errorDescription}</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </section> : null
                                                    }
                                                </> : null
                                        }
                                        <section className='customer'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0'>
                                                        <label><small>Customer</small></label>
                                                        {renderCustomer()}
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-7'>
                                                    <div className={'form-group'}>
                                                        <label htmlFor='update-customer-ohip-input'><small>Health Card Number</small></label>
                                                        <MaskedInput
                                                            id='update-customer-ohip-input'
                                                            type='text'
                                                            className='form-control text-uppercase'
                                                            spellCheck={false}
                                                            mask={MaskKeys.OHIP_MASK}
                                                            guide={false}
                                                            maxLength='25'
                                                            autoComplete='off'
                                                            disabled={!ch.checkCanUpdate(customer.data)}
                                                            value={customer.patientProfile.healthCardNumber ? customer.patientProfile.healthCardNumber : ''}
                                                            onChange={handleHealthCardNumberChange}
                                                            onBlur={handleHealthCardNumberBlur}
                                                        />
                                                        <button
                                                            type='button'
                                                            className='btn btn-link px-0'
                                                            onClick={validateHealthCard}
                                                        >{auth.currentTenant.publicInsuranceUnitId} verification</button>
                                                    </div>
                                                </div>
                                                <div className='col-5'>
                                                    <div className='form-group'>
                                                        <label htmlFor='update-customer-ohip-expry-input'><small>Expiry <span className='text-gray-500'>(yyyy-mm-dd)</span></small></label>
                                                        <MaskedInput
                                                            id='update-customer-ohip-expry-input'
                                                            type='text'
                                                            className='form-control'
                                                            spellCheck={false}
                                                            mask={MaskKeys.DATE_MASK}
                                                            pipe={MaskKeys.DATE_PIPE}
                                                            maxLength='25'
                                                            autoComplete='off'
                                                            disabled={!ch.checkCanUpdate(customer.data) || (validatedHealthCard && validatedHealthCard.isVerified)}
                                                            value={customer.patientProfile.healthCardExpiryDate ? customer.patientProfile.healthCardExpiryDate : ''}
                                                            onChange={handleHealthCardExpiryChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-5'>
                                                    <div className='form-group'>
                                                        <label htmlFor='update-customer-recall-input'><small>Recall Every</small></label>
                                                        <div className='input-group'>
                                                            <input
                                                                id='update-customer-recall-input'
                                                                type='number'
                                                                className='form-control'
                                                                min={0}
                                                                max={60}
                                                                step={1}
                                                                disabled={customer.patientProfile.doNotRecall || !ch.checkCanUpdate(customer.data)}
                                                                value={customer.patientProfile.recallFrequencyInMonth ? customer.patientProfile.recallFrequencyInMonth : ''}
                                                                onChange={handleRecallFrequencyChange}
                                                            />
                                                            <div className='input-group-append'>
                                                                <span className='input-group-text fs-sm'>month</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-7'>
                                                    <div className='form-group'>
                                                        <label><small>&nbsp;</small></label>
                                                        <div className='custom-control custom-checkbox mt-2'>
                                                            <input
                                                                id='update-customer-donotrecall'
                                                                type='checkbox'
                                                                name='update-customer-donotrecall'
                                                                className='custom-control-input'
                                                                disabled={!ch.checkCanUpdate(customer.data)}
                                                                checked={customer.patientProfile.doNotRecall ? customer.patientProfile.doNotRecall : false}
                                                                onChange={handleDoNotRecallChange}
                                                            />
                                                            <label
                                                                htmlFor='update-customer-donotrecall'
                                                                className='custom-control-label'
                                                            >
                                                                Do not recall
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group'>
                                                        <label htmlFor='update-customer-recall-input'><small>Family Doctor</small></label>
                                                        {
                                                            customer.patientProfile && customer.patientProfile.familyDoctorClinic ?
                                                                <>
                                                                    <div className='d-flex w-100 border border-1 py-2 px-3 text-gray-700'>
                                                                        <div className='flex-1'>
                                                                            {
                                                                                customer.patientProfile.familyDoctor ?
                                                                                    <div>
                                                                                        <strong className='d-inline-block'>
                                                                                            {customer.patientProfile.familyDoctor.fullName}
                                                                                        </strong>
                                                                                        {
                                                                                            customer.patientProfile.familyDoctorClinic ?
                                                                                                <small className='ml-2'>({customer.patientProfile.familyDoctorClinic.name})</small> : null
                                                                                        }
                                                                                    </div> : null
                                                                            }
                                                                            {
                                                                                customer.patientProfile.familyDoctorClinic && !customer.patientProfile.familyDoctor ?
                                                                                    <strong className='d-block'>{customer.patientProfile.familyDoctorClinic.name}</strong> : null
                                                                            }
                                                                            {
                                                                                customer.patientProfile.familyDoctorClinic.address ?
                                                                                    <div className='text-gray-600 address-sameline'>{ah.getAddressHtml(customer.patientProfile.familyDoctorClinic.address)}</div> : null
                                                                            }
                                                                            {
                                                                                customer.patientProfile.familyDoctorClinic.emailAddress ?
                                                                                    <div><a href={`mailto:${customer.patientProfile.familyDoctorClinic.emailAddress.toLowerCase()}`}>{customer.patientProfile.familyDoctorClinic.emailAddress.toLowerCase()}</a></div> : null
                                                                            }
                                                                            {
                                                                                customer.patientProfile.familyDoctorClinic.phoneNumber ?
                                                                                    <span className='mr-2'>P:<a className='ml-1' href={`tel:${customer.patientProfile.familyDoctorClinic.phoneNumber}`}>{sys.getFormattedPhoneNumber(customer.patientProfile.familyDoctorClinic.phoneNumber)}</a></span> : null
                                                                            }
                                                                            {
                                                                                customer.patientProfile.familyDoctorClinic.faxNumber ?
                                                                                    <span>F:<span className='ml-1'>{sys.getFormattedPhoneNumber(customer.patientProfile.familyDoctorClinic.faxNumber)}</span></span> : null
                                                                            }
                                                                        </div>
                                                                        <div className='ml-2'>
                                                                            <button 
                                                                                type='button' 
                                                                                className='btn btn-icon-only p-0' 
                                                                                onClick={handleFamilyDoctorClinicClear}
                                                                            >
                                                                                <i className='fal fa-times text-danger'></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </> :
                                                                <div>
                                                                    <button type='button' className='btn btn-link p-0' onClick={handleAddressBookOpen}>Pick from address book</button>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </FadeIn> : renderQuickDrawerLoading()
                        }
                    </div>
                    <div className='quick-drawer-action pl-3'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={props.onCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                        disabled={!ch.checkCanUpdate(customer.data)}
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
        <BodyEnd>
            <ConfirmModal
                icon={<i className={`${oh.getIcon('customer', 'patient')} text-info mr-2`}></i>}
                message={<><strong>Health card verified</strong>. Update customer's information?</>}
                option1ClassName={'btn btn-info shadow-0 bootbox-accept'}
                show={confirmPopulateCustomer}
                onOption1Click={handlePopulateCustomerConfirm}
                onCancel={handlePopulateCustomerCancel}
            />
            <AddressBookModal
                ref={addressBookModalRef}
                shading={true}
                dragEnabled={false}
                allowedTypes={['ExternalClinic', 'ExternalDoctor']}
                onSelected={handleFamilyDoctorClinicSelected}
            />
        </BodyEnd>
    </>)
}

export default UpdatePatient;