import React, { Component } from 'react';
import { Popup } from 'devextreme-react';
import uuid from 'react-uuid';

import AddressBookList from './List';

import * as fn from '../../../../utilities/_functions';
import './_index.scss';

const ADDRESS_BOOK_POPUP_ID = `__address-book-popup-${uuid()}`;
export const ADDRESS_BOOK_MODAL_GRID_WIDTH = 980;
export const ADDRESS_BOOK_MODAL_GRID_HEIGHT = 700;

export default class AddressBookModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            display: false,
            zIndex: null,
            type: '',
        };
        this.dataGridRef = React.createRef();
        this.handleClose = this.handleClose.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
    }

    componentWillUnmount() {
        if (this.props.onClose) { this.props.onClose(); }
    }

    show = (selectedType) => {
        const zIndex = fn.getHighestZIndex() + 1;
        this.setState({ display: true, zIndex: zIndex, type: (selectedType ? selectedType : '') });
    }

    close = () => {
        this.handleClose();
    }

    handleInitialized = event => {
        event.component.registerKeyHandler('escape', () => { });
    }

    handleShowing = event => {
        const popup = document.getElementById(ADDRESS_BOOK_POPUP_ID);
        if (popup) {
            // const { zIndex } = this.state;
            // popup.style.zIndex = zIndex;
        }
    }

    handleClose = event => {
        const popup = document.getElementById(ADDRESS_BOOK_POPUP_ID);

        if (popup) {
            // popup.style.zIndex = null;
        }

        this.setState({ display: false, zIndex: null });
    }

    handleSuccess = (event, result) => {
        if (fn.isFunction(this.props.onSuccess)) {
            this.props.onSuccess(event, result);
        }
        this.handleClose(event);
    }

    renderTitle = () => {
        return <div className='popup-title-draggable'></div>
    }

    renderContent = () => {
        const contentProps = {
            type: this.state.type,
            allowedTypes: this.props.allowedTypes,
            onSelected: this.props.onSelected,
            onSuccess: this.handleSuccess,
            onClose: this.handleClose,
        };

        return this.state.display ? <AddressBookList {...contentProps} /> : null;
    }

    render() {
        return <>            
            <Popup
                wrapperAttr={{ id: ADDRESS_BOOK_POPUP_ID, class: 'dx-popup-address-book' }}
                animation={{
                    show: {
                        type: 'pop',
                        duration: 200,
                        from: { opacity: 0, scale: 0 },
                        to: { opacity: 1, scale: 1 }
                    },
                    hide: {
                        type: 'pop',
                        duration: 200,
                        from: { opacity: 1, scale: 1 },
                        to: { opacity: 0, scale: 0 }
                    }
                }}
                visible={!!this.state.display}
                shading={!fn.isNullOrUndefined(this.props.shading) ? this.props.shading : null}
                shadingColor={!fn.isNullOrUndefined(this.props.shading) && this.props.shading === true ? 'rgba(0, 0, 0, 0.75)' : null}
                width={ADDRESS_BOOK_MODAL_GRID_WIDTH}
                height={ADDRESS_BOOK_MODAL_GRID_HEIGHT}
                dragEnabled={!fn.isNullOrUndefined(this.props.dragEnabled) ? this.props.dragEnabled : true}
                dragOutsideBoundary={true}
                showTitle={true}
                titleRender={this.renderTitle}
                contentRender={this.renderContent}
                onShowing={this.handleShowing}
                onShown={this.handleShowing}
                onHiding={this.handleClose}
                onInitialized={this.handleInitialized}
            />
        </>
    }
}