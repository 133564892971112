import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";

export const AuthWrapper = ({ children }) => {
    const hostParts = window.location.host.split('.');
    let subdomain = hostParts[0]; 
    const callbackUrl = process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_AUTH0_CALLBACK_URL : process.env.REACT_APP_AUTH0_CALLBACK_URL.replace(/^https:\/\/[^.]+/, `https://${subdomain}`);

    // validate subdomain ??

    const options = {
        domain: process.env.REACT_APP_AUTH0_DOMAIN,
        clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
        responseType: process.env.REACT_APP_AUTH0_RESPONSE_TYPE,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: process.env.REACT_APP_AUTH0_SCOPE,
        redirectUri: callbackUrl,
        cacheLocation: 'localstorage'
    };
    return <Auth0Provider {...options}>{children}</Auth0Provider>;
};
