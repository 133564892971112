import { HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { CURRENT_TENANT } from '../constants/storageKeys';
import { createContext } from 'react';

export class SignalRContext {
    _connection;
    _callbacks = new Map();
    _retryInMillisec = 5000;
    _trace = process.env.REACT_APP_TRACE_SIGNALR;

    constructor() {
        console.log('SignalR TRACE is', !!this._trace);

        this._connection = new HubConnectionBuilder()
            .configureLogging(this._trace ? LogLevel.Trace : LogLevel.None)
            .withUrl(process.env.REACT_APP_SIGNALR_URL)
            .withAutomaticReconnect([0, 2000, 4000, 4000, 4000, 4000, 4000, 4000, 4000, 4000, 4000, 4000, 4000, 4000])
            .build();

        this._connection.onreconnected((connectionId) => {
            !!this._trace && console.log("Reconnected:::");
            this._joinGroup();
        });

        this._connection.onclose(() => {
            // This is no built-in option to keep retrying the connection. Need to code your own retry logic.
            !!this._trace && console.log("Connection is closed:::") ;
            setTimeout(() => {
                this.connect();
            }, this._retryInMillisec);
        })

        window.addEventListener('focus', this.handleWindowFocus);
    }

    handleWindowFocus = () => {
        !!this._trace && console.log("Windows Focused:::") ;
        if (this._connection.state !== HubConnectionState.Connected) {
            this.connect();
        }
    }

    connect() {
        if (this._connection.state !== HubConnectionState.Disconnected)
            return;

        this._connection.start()
            .then(() => {
                !!this._trace && console.log('Connected:::');
                this._joinGroup();
                return this._connection.connectionId;
            })
            .catch(err => {
                !!this._trace && console.error("Error starting connection:::", err);
                // Handle the error and implement reconnection logic
                setTimeout(() => this.connect(), this._retryInMillisec); // Retry after 5 seconds
            });
    }
    disconnect = () => {
        if (this._connection && this._connection.state !== 'Disconnected') {
            this._connection.stop().catch((error) => !!this._trace && console.error(error));
        }
        window.removeEventListener('focus', this.handleWindowFocus);        
    }
    offAll = (subscriberId) => {
        for (let message of this._callbacks.keys()) {
            this.off(subscriberId, message);
        }
    }
    off(subscriberId, message) {
        if (!this._callbacks.has(message))
            return;

        !!this._trace && console.log('Unsubscribing:::', subscriberId, message);

        // remove the subscriber to this message
        const index = this._callbacks.get(message).findIndex(c => c.id === subscriberId);
        if (index !== -1) {
            !!this._trace && console.log('Found index:::', subscriberId, index);
            this._callbacks.get(message).splice(index, 1);
        }

        if (this._callbacks.get(message).length === 0) {
            // no more subscribers, turn off signalr on this message as well.
            !!this._trace && console.log('No more subscribers to this message:::', message)
            this._callbacks.delete(message);
            this._connection.off(message);
        }
    }
    on(subscriberId, message, callback) {
        if (!this._callbacks.has(message)) {
            // Make sure we only call hubconnection.on only once per type of message
            const closedFn = this._routeMessage(message);
            this._connection.on(message, closedFn);
        }

        // the basic structure is message -> [{ id, fn}]
        if (!this._callbacks.has(message)) {
            this._callbacks.set(message, []);
        }

        // prevent duplicates in the callbacks list
        if (!this._callbacks.get(message).some(s => s.id === subscriberId)) {
            this._callbacks.get(message).push({
                id: subscriberId,
                fn: callback
            });
        }
    }

    get isConnected() {
        !!this._trace && console.log('this._connection.state:::', this._connection.state);
        return this._connection.state === HubConnectionState.Connected;
    }

    _routeMessage(message) {
        // using javascript closure so that when hubconnection calls the callback, we know which "message" it's talking about.
        // Then we call the registered callbacks
        let that = this;

        return function (obj) {
            // only works if the .Net broadcast with one parameter only
            !!this._trace && console.log('Complete Map of Callback:::', that._callbacks);
            const subscriberList = that._callbacks.get(message);
            for (let subscriber of subscriberList) {
                subscriber.fn(obj);
            }
        }
    }
    // should be private
    _joinGroup = () => {
        const currentTenant = JSON.parse(window.localStorage.getItem(CURRENT_TENANT));

        if (currentTenant && currentTenant.id) {
            this._connection.invoke('subscribe', currentTenant.id)
        }
    }
}

export default createContext(new SignalRContext());