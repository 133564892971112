import React, { useContext, useEffect, useState, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import FadeIn from 'react-fade-in';
import momentLocalizer from 'react-widgets-moment';
import { GlobalHotKeys } from 'react-hotkeys';
import { DateTimePicker, DropdownList } from 'react-widgets'
import moment from 'moment';

import BodyEnd from '../../_shared/BodyEnd';
import ConfirmModal from '../../_shared/ConfirmModal';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import RichTextEditor from '../../_shared/RichTextEditor';
import { renderQuickDrawerLoading } from '../../_shared/QuickDrawer';

import TimeOffCreateStore from '../../../../stores/TimeOffCreateStore';
import TimeOffUpdateStore from '../../../../stores/TimeOffUpdateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';
import AuthStore from '../../../../stores/AuthStore';
import CacheStore from '../../../../stores/CacheStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as AccessType from '../../../../constants/accessTypes';
import * as fn from '../../../../utilities/_functions';
import * as ph from '../../../../utilities/personHelper';
import * as ah from '../../../../utilities/accessHelper';
import * as oh from '../../../../utilities/operationHelper';

import api from '../../../../api';

import './NewTimeOff.scss';
moment.locale('en');
momentLocalizer();

function NewTimeOff(props) {
    const timeOff = useContext(TimeOffCreateStore);
    const timeOffUpdate = useContext(TimeOffUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const auth = useContext(AuthStore);
    const cache = useContext(CacheStore);
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const handleCancelSpecialDates = useRef(null);
    const pickerTimer = useRef(null);
    const [users, setUsers] = useState([]);
    const [specialDates, setSpecialDates] = useState([]);
    const [startDatePickerOpen, setStartDatePickerOpen] = useState(false);
    const [untilDatePickerOpen, setUntilDatePickerOpen] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    useEffect(() => {
        setUsers(Array.from(new Set([...cache.users.filter(u => u.id === timeOff.userId), ...cache.activeUsers])));
        refreshSpecialDates();

        return () => {
            isMounted.current = false;

            timeOff.clear();
            quickDrawer.clear();

            if (pickerTimer.current) { clearTimeout(pickerTimer.current); pickerTimer.current = null; }
            if (fn.isFunction(handleCancelSpecialDates.current)) { handleCancelSpecialDates.current(); handleCancelSpecialDates.current = null; }
        }
    }, []) // eslint-disable-line

    const refreshSpecialDates = () => {
        setSpecialDates([]);

        if (!timeOff.isMultipleDays) {
            api.SpecialDates.get(timeOff.start.format('YYYY-MM-DD'), null, (c) => { handleCancelSpecialDates.current = c })
                .then(({ data }) => {
                    if (isMounted.current) {
                        if (data && data.length > 0) {
                            setSpecialDates(data);
                        } else {
                            setSpecialDates([]);
                        }
                    }
                })
        }
    }

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (timeOff.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleMultipleDaysChange = event => {
        const isMultipleDays = event.target.value === 'true';

        if (isMultipleDays) {
            if (timeOff.until.clone().startOf('day').isSameOrBefore(timeOff.start.clone().startOf('day'))) {
                timeOff.until = timeOff.start.clone().startOf('day').add(2, 'days').add(-1, 'seconds');
            }
        } else {
            timeOff.until = timeOff.start.clone().startOf('day').add(1, 'days').add(-1, 'seconds');
        }

        timeOff.isMultipleDays = isMultipleDays;
        timeOff.hasUnsavedChanges = true;
    }

    const handleStartDatePickerDisplay = event => {
        setUntilDatePickerOpen(false);

        if (!startDatePickerOpen) {
            setStartDatePickerOpen('date');
        }
    }

    const handleStartDatePickerChange = value => {
        const newDate = moment(value).startOf('day');
        timeOff.start = newDate;

        if (timeOff.start.isAfter(timeOff.until)) {
            timeOff.until = newDate.clone().add(1, 'days').add(-1, 'seconds');
        }
        timeOff.refresh();
        timeOff.hasUnsavedChanges = true;
        refreshSpecialDates();
        setStartDatePickerOpen(false);
    }

    const handleUntilDatePickerDisplay = event => {
        setStartDatePickerOpen(false);

        if (!untilDatePickerOpen) {
            setUntilDatePickerOpen('date');
        }
    }

    const handleUntilDatePickerChange = value => {
        const newDate = moment(value).startOf('day').add(1, 'days').add(-1, 'seconds');
        timeOff.until = newDate;

        if (timeOff.until.isBefore(timeOff.start)) {
            timeOff.start = newDate.clone().startOf('day');
        }
        timeOff.refresh();
        timeOff.hasUnsavedChanges = true;
        refreshSpecialDates();
        setUntilDatePickerOpen(false);
    }

    const handleUserChange = ({ id }) => {
        timeOff.userId = id;
        timeOff.refresh();
        timeOff.hasUnsavedChanges = true;
    }

    const handleReasonChange = (event) => {
        var reason = event.target.value;
        timeOff.reason = reason ? reason : null;
        timeOff.hasUnsavedChanges = true;
    }

    const handleNoteChange = (content, delta, source, editor) => {
        const note = editor.getText().replace(/(\r\n|\n|\r)/g, ' ').trim();
        const noteHtml = (content === '<p><br></p>') ? null : content;

        timeOff.note = note;
        timeOff.noteHtml = noteHtml;
        timeOff.hasUnsavedChanges = true;
    }

    const handleDelete = event => {
        if (timeOff.isExisting) {
            timeOffUpdate.initialize(timeOff.id)
                .then(() => {
                    if (isMounted.current) {
                        if (timeOffUpdate.data) {
                            setConfirmDelete(true);
                        }
                    }
                })
        }
    }

    const handleConfirmDelete = event => {
        event.preventDefault();
        timeOffUpdate.delete()
            .then(data => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, { updated: true, data: data });
                    }
                }
            })

        setConfirmDelete(false);
    }

    const handleConfirmCancel = event => {
        event.preventDefault();
        setConfirmDelete(false);

        if (props.onCancel && fn.isFunction(props.onCancel)) {
            props.onCancel(event);
        }
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            timeOff.save()
                .then(data => {
                    if (isMounted.current) {
                        if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                            props.onSuccess(event, data);
                        }
                    }
                })
        }
    }

    const getTimeOffDateTime = () => {
        if (!timeOff.start) return null;

        const startWeekday = timeOff.start.format('dddd');
        const startDay = timeOff.start.format('D');
        const startOrdinal = timeOff.start.format('Do').replace(startDay, '');
        const startDateHtml = `${timeOff.start.format('MMMM D')}<sup>${startOrdinal}</sup>${((timeOff.start.year() !== moment().year()) ? `, ${timeOff.start.format('YYYY')}` : '')}`;
        const untilWeekday = timeOff.until.format('dddd');
        const untilDay = timeOff.until.format('D');
        const untilOrdinal = timeOff.until.format('Do').replace(untilDay, '');
        const untilDateHtml = `${timeOff.until.format('MMMM D')}<sup>${untilOrdinal}</sup>${((timeOff.until.year() !== moment().year()) ? `, ${timeOff.until.format('YYYY')}` : '')}`;

        return <>
            <div className='form-group'>
                <ul className='list-inline no-style m-0'>
                    <li className='list-inline-item m-0' onClick={!timeOff.isExisting ? handleStartDatePickerDisplay : null}>
                        <div className='control transparent'>
                            <div
                                className={'control-overlay' + (startDatePickerOpen ? '' : ' d-none')}
                                onClick={() => { setStartDatePickerOpen(false) }}
                            ></div>
                            <DateTimePicker
                                id='startDatePicker'
                                defaultOpen={false}
                                min={moment().startOf('day').toDate()}
                                open={startDatePickerOpen}
                                dateFormat={dt => String(dt.getDate())}
                                dayFormat={day => ['S', 'M', 'T', 'W', 'T', 'F', 'S'][day.getDay()]}
                                views={['month', 'year']}
                                value={timeOff.start.toDate()}
                                footer={false}
                                date={true}
                                time={false}
                                disabled={timeOff.isExisting}
                                onSelect={handleStartDatePickerChange}
                            />
                        </div>
                        <div className={'text' + (startDatePickerOpen ? ' active' : '')}>
                            <small className='weekday'>{startWeekday}</small>
                            <span className='date' dangerouslySetInnerHTML={{ __html: startDateHtml }}></span>
                        </div>
                    </li>
                    {
                        timeOff.isMultipleDays ? <>
                            <li className='list-inline-item my-0 mx-2'><small>to</small></li>
                            <li className='list-inline-item m-0' onClick={!timeOff.isExisting ? handleUntilDatePickerDisplay : null}>
                                <div className='control right transparent'>
                                    <div
                                        className={'control-overlay' + (untilDatePickerOpen ? '' : ' d-none')}
                                        onClick={() => { setUntilDatePickerOpen(false) }}
                                    ></div>
                                    <DateTimePicker
                                        id='untilDatePicker'
                                        defaultOpen={false}
                                        min={timeOff.start.clone().startOf('day').add(1, 'days').toDate()}
                                        open={untilDatePickerOpen}
                                        dateFormat={dt => String(dt.getDate())}
                                        dayFormat={day => ['S', 'M', 'T', 'W', 'T', 'F', 'S'][day.getDay()]}
                                        views={['month', 'year']}
                                        value={timeOff.until.startOf('day').toDate()}
                                        footer={false}
                                        date={true}
                                        time={false}
                                        disabled={timeOff.isExisting}
                                        onSelect={handleUntilDatePickerChange}
                                    />
                                </div>
                                <div className={'text' + (untilDatePickerOpen ? ' active' : '')}>
                                    <small className='weekday'>{untilWeekday}</small>
                                    <span className='date' dangerouslySetInnerHTML={{ __html: untilDateHtml }}></span>
                                </div>
                            </li>
                        </> : null
                    }
                </ul>
            </div>
        </>
    }

    return <>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <Observer>{() =>
                <>
                    {
                        (startDatePickerOpen || untilDatePickerOpen) ?
                            <GlobalHotKeys
                                keyMap={{
                                    closeAllPickers: ['esc'],
                                }}
                                handlers={{
                                    closeAllPickers: event => {
                                        setStartDatePickerOpen(false);
                                        setUntilDatePickerOpen(false);
                                    }
                                }}
                                allowChanges={true}
                            /> :
                            <>
                                {
                                    (props.drawer === quickDrawer.drawerOpened) ?
                                        <GlobalHotKeys
                                            keyMap={{
                                                close: ['esc'],
                                            }}
                                            handlers={{
                                                close: event => {
                                                    handleCancel(event)
                                                },
                                            }}
                                            allowChanges={true}
                                        /> : null
                                }
                            </>
                    }
                    <fieldset>
                        <div className='quick-drawer'>
                            <QuickDrawerHeader
                                drawer={props.drawer}
                                icon={oh.getIcon('schedule', 'timeoff')}
                                action={'Update'}
                                category='Time Off'
                                className={'schedules'}
                                onCancel={handleCancel}
                            />
                            <div className='quick-drawer-body'>
                                {
                                    timeOff.isReady ? <>
                                        <FadeIn>
                                            <div className='new-timeoff body-content'>
                                                {
                                                    timeOff.until.isBefore(moment()) || (specialDates && specialDates.length > 0) ?
                                                        <section>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className={'alert mb-0 alert-warning'} role='alert'>
                                                                        <strong className='d-block mb-2'>Important</strong>
                                                                        <ul className='pl-3 mb-0'>
                                                                            {
                                                                                timeOff.until.isBefore(moment()) ?
                                                                                    <li>Schedule locked. This is in the past.</li> : null
                                                                            }
                                                                            {
                                                                                specialDates.map((d, di) => {
                                                                                    return <li key={`special-date-${di}`}>{d.name}{d.isHoliday ? <span className='badge bg-danger-700 text-white text-shadow-0 mx-o ml-1'>Holiday</span> : <></>}</li>
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section> : null
                                                }
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group validate validate-required'>
                                                                <label className='required d-block'><small>Type</small></label>
                                                                <div
                                                                    className='custom-control custom-checkbox custom-control-inline'
                                                                >
                                                                    <input
                                                                        id={`timeoff-type-single`}
                                                                        type='radio'
                                                                        name='timeOffType'
                                                                        className='custom-control-input'
                                                                        value={false}
                                                                        checked={!timeOff.isMultipleDays}
                                                                        disabled={timeOff.isExisting}
                                                                        onChange={handleMultipleDaysChange}
                                                                    />
                                                                    <label
                                                                        htmlFor={`timeoff-type-single`}
                                                                        className='custom-control-label'
                                                                    >
                                                                        Single day
                                                                    </label>
                                                                </div>
                                                                <div
                                                                    className='custom-control custom-checkbox custom-control-inline'
                                                                >
                                                                    <input
                                                                        id={`timeoff-type-multiple`}
                                                                        type='radio'
                                                                        name='timeOffType'
                                                                        className='custom-control-input'
                                                                        value={true}
                                                                        checked={timeOff.isMultipleDays}
                                                                        disabled={timeOff.isExisting}
                                                                        onChange={handleMultipleDaysChange}
                                                                    />
                                                                    <label
                                                                        htmlFor={`timeoff-type-multiple`}
                                                                        className='custom-control-label'
                                                                    >
                                                                        Multiple days
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section className='user'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <label><small>For</small></label>
                                                            <Observer>{() =>
                                                                <DropdownList
                                                                    data={ah.check(AccessType.UPDATE_OTHER_SCHEDULE) ? users : users.filter(u => u.id === auth.currentUser.id)}
                                                                    valueField='id'
                                                                    value={timeOff.userId}
                                                                    disabled={timeOff.isExisting}
                                                                    itemComponent={({ item }) => (
                                                                        <div
                                                                            className='profile-wrapper'
                                                                        >
                                                                            <div className='profile'>
                                                                                <span
                                                                                    className={`profile-image rounded-circle fw-500 d-block` + (item && !item.profilePictureUri ? ` profile-initials bg-color${item.color}-500` : '')}
                                                                                    style={item && item.profilePictureUri ? {
                                                                                        backgroundImage: `url(${item.profilePictureUri})`,
                                                                                        backgroundSize: 'cover',
                                                                                    } : null}
                                                                                    title={item ? item.fullName : 'System'}>
                                                                                    {item && !item.profilePictureUri ? <div className='d-initials'>{item.initials}</div> : null}
                                                                                </span>
                                                                            </div>
                                                                            <span className='description'>{item.fullName}</span>
                                                                        </div>
                                                                    )}
                                                                    valueComponent={({ item }) => (
                                                                        item ?
                                                                            <div
                                                                                className='profile-wrapper'
                                                                            >
                                                                                <div className='profile'>
                                                                                    <span
                                                                                        className={`profile-image rounded-circle fw-500 d-block` + (item && !item.profilePictureUri ? ` profile-initials bg-color${item.color}-500` : '')}
                                                                                        style={item && item.profilePictureUri ? {
                                                                                            backgroundImage: `url(${item.profilePictureUri})`,
                                                                                            backgroundSize: 'cover',
                                                                                        } : null}
                                                                                        title={item ? item.fullName : 'System'}>
                                                                                        {item && !item.profilePictureUri ? <div className='d-initials'>{item.initials}</div> : null}
                                                                                    </span>
                                                                                </div>
                                                                                <span className='description'>{item.fullName}</span>
                                                                            </div> : null
                                                                    )}
                                                                    onChange={handleUserChange}
                                                                />
                                                            }</Observer>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section className='date-time'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <Observer>{() =>
                                                                <>{getTimeOffDateTime()}</>
                                                            }</Observer>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <Observer>{() =>
                                                                <div className='form-group mb-0 validate validate-required'>
                                                                    <label className='required' htmlFor='timeoff-reason'><small>Reason</small></label>
                                                                    <select
                                                                        id='timeoff-reason'
                                                                        className='custom-select form-control'
                                                                        value={timeOff.reason ? timeOff.reason : ''}
                                                                        disabled={timeOff.isExisting}
                                                                        onChange={handleReasonChange}
                                                                    >
                                                                        <option value=''></option>
                                                                        {
                                                                            cache.getReferenceDataOptions('WorkDayTimeOffReasonType').map((option, di) => {
                                                                                return <option key={`timeoff_reason_type_${di}`} value={option.key}>{option.value}</option>
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            }</Observer>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group'>
                                                                <label><small>Note</small></label>
                                                                <div className='input-group'>
                                                                    <Observer>{() =>
                                                                        <RichTextEditor
                                                                            mode='basic'
                                                                            value={timeOff.noteHtml}
                                                                            disableTab={true}
                                                                            readOnly={timeOff.isExisting}
                                                                            onChange={handleNoteChange}
                                                                        />
                                                                    }</Observer>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </FadeIn>
                                    </> : renderQuickDrawerLoading()
                                }
                            </div>
                            <div className='quick-drawer-action'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='float-right'>
                                            <button
                                                type='button'
                                                className='btn btn-link btn-cancel'
                                                onClick={handleCancel}
                                            >Cancel</button>
                                            {
                                                timeOff.isExisting && !timeOff.until.isBefore(moment()) ?
                                                    <button
                                                        type='button'
                                                        className={'btn btn-danger ml-2'}
                                                        onClick={handleDelete}
                                                    >Delete</button> : null
                                            }
                                            {
                                                !timeOff.isExisting ?
                                                    <button
                                                        type='submit'
                                                        className='btn btn-success ml-2'
                                                    >Save</button> : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </>
            }</Observer>
        </form>
        <BodyEnd>
            <ConfirmModal
                icon={<i className='fal fa-calendar-times text-danger mr-2'></i>}
                message={<>Continue to delete <span className='fw-500'>&nbsp;{timeOffUpdate.data ? ph.getPreferredFirstName(timeOffUpdate.data.user) : ''}'s time off</span>? </>}
                show={confirmDelete}
                onOption1Click={handleConfirmDelete}
                onCancel={handleConfirmCancel}
            />
        </BodyEnd>
    </>
}

export default NewTimeOff;