import React, { useEffect, useContext, useState, useRef, Fragment } from 'react';
import { Link } from 'react-router-dom';
import FadeIn from 'react-fade-in';
import { useObserver } from 'mobx-react-lite';
import { GlobalHotKeys } from 'react-hotkeys';
import DateBox from 'devextreme-react/date-box';
import { DropdownList, Multiselect } from 'react-widgets'
import moment from 'moment';

import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';

import MigratePaperExamStore from '../../../../stores/MigratePaperExamStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';
import CacheStore from '../../../../stores/CacheStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as rts from '../../../../constants/routes';
import * as fn from '../../../../utilities/_functions';
import * as bh from '../../../../utilities/badgeHelper';
import * as ch from '../../../../utilities/customerHelper';
import * as aph from '../../../../utilities/appointmentHelper';
import * as ph from '../../../../utilities/personHelper';
import * as sys from '../../../../utilities/systemHelper';
import * as ah from '../../../../utilities/addressHelper';

import './MigratePaperExam.scss';

function MigratePaperExam(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const searchTimer = useRef(null);
    const focusTimer = useRef(null);
    const handleCancelRelatedToSearchRef = useRef(null);
    const handleCancelCustomerSuggestionRef = useRef(null);
    const handleCancelRelatedToSuggestionRef = useRef(null);
    const migrate = useContext(MigratePaperExamStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const cache = useContext(CacheStore);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        setUsers(cache.users.filter(u => u.acceptBooking === true));

        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100)

        return () => {
            isMounted.current = false;

            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
            if (searchTimer.current) { clearTimeout(searchTimer.current); searchTimer.current = null; }
            if (handleCancelRelatedToSearchRef.current) { handleCancelRelatedToSearchRef.current(); handleCancelRelatedToSearchRef.current = null; }
            if (handleCancelCustomerSuggestionRef.current) { handleCancelCustomerSuggestionRef.current(); handleCancelCustomerSuggestionRef.current = null; }
            if (handleCancelRelatedToSuggestionRef.current) { handleCancelRelatedToSuggestionRef.current(); handleCancelRelatedToSuggestionRef.current = null; }
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        if (migrate.isReady && users && users.length === 1) {
            migrate.data.userId = users[0].id;
        }
    }, [migrate.isReady]) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (migrate.hasRelationshipUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handlePagerExamFileChange = event => {
        const { files } = event.target;

        if (files && files.length === 1) {
            const reader = new FileReader();
            reader.onload = event => { handleFileReaderLoad(event, files[0]) };
            reader.readAsDataURL(files[0]);
        }
    }

    const handleFileReaderLoad = (event, file) => {
        const reader = event.target;
        const base64 = reader.result;

        migrate.data.file = {
            fileName: file.name,
            size: file.size,
            base64: base64
        };

        if (!migrate.data.date) {
            const dateMatches = file.name.match(/\b\d{4}-\d{1,2}-\d{1,2}\b/g);

            if (dateMatches && dateMatches.length > 0) {
                const dateParts = dateMatches[0].split('-');
                const year = parseInt(dateParts[0], 10);
                const month = parseInt(dateParts[1], 10);
                const day = parseInt(dateParts[2], 10);
                const date = moment(`${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`, 'YYYY-MM-DD');

                if (date.isValid() && date.isSameOrBefore(moment())) {
                    migrate.data.date = date;
                }
            }
        }

        migrate.hasUnsavedChanges = true;
    }

    const handlePaperExamDateChange = element => {
        const value = element.value;

        migrate.data.date = value ? moment(value) : null;

        if (migrate.data.date) {
            migrate.data.time = null;
            migrate.getAppointments();
        }
        else {
            migrate.appointments.clear();
        }

        migrate.hasUnsavedChanges = true;
    }

    const handlePaperExamTimeChange = element => {
        const value = element.value;

        migrate.data.time = value ? moment(value).format('HH:mm:ss') : null;
        migrate.hasUnsavedChanges = true;
    }

    const handlePaperExamResourceChange = ({ id }) => {
        migrate.data.userId = id;
        migrate.hasUnsavedChanges = true;
    }

    const handleServiceSelection = (tag, metadata) => {
        switch (metadata.action) {
            case 'insert':
                if (!migrate.data.services.some(s => s.id === metadata.dataItem.id)) {
                    migrate.data.services.push({
                        id: metadata.dataItem.id,
                        code: metadata.dataItem.code,
                        name: metadata.dataItem.code,
                        duration: metadata.dataItem.duration,
                        isRepeatable: metadata.dataItem.isRepeatable,
                    });
                }
                break;

            case 'remove':
                const index = migrate.data.services.findIndex(s => s.id === metadata.dataItem.id);
                if (index !== -1) {
                    migrate.data.services.splice(index, 1);
                }
                break;

            default:
                break;
        }

        migrate.data.duration = migrate.recommendedDuration ? migrate.recommendedDuration : 30;
        migrate.hasUnsavedChanges = true;
    }

    const handlePaperExamDurationChange = event => {
        migrate.data.duration = parseInt(event.target.value, 10);
        migrate.hasUnsavedChanges = true;
    }

    const handleMigrateAppointmentChange = event => {
        const appointmentId = event.target.value;
        const appointment = migrate.appointments.some(a => a.id === appointmentId) ? migrate.appointments.filter(a => a.id === appointmentId)[0] : null;

        if (appointment) {
            migrate.data.appointmentId = appointment.id;
            migrate.data.userId = appointment.userId;
            migrate.hasUnsavedChanges = true;
        }
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            migrate.save()
                .then(data => {
                    if (isMounted.current) {
                        if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                            props.onSuccess(event, { updated: true, data: data });
                        }
                    }
                })
        }
    }

    const renderCustomer = () => {
        return <div
            className='profile-wrapper'
        >
            <div className='profile'>
                <span
                    className={`profile-image profile-initials rounded-circle d-flex text-white ${ch.getProfileColor(migrate.customer)} fw-500`}
                    title={ph.getFullName(migrate.customer)}
                >
                    {migrate.customer.initials}
                </span>
            </div>
            <div className='description flex-1'>
                <Link to={`${rts.Customers.Home}/${migrate.customer.id}`} className='name text-gray-700' onClick={quickDrawer.deactivateAll}>{ph.getFullName(migrate.customer, true)}
                    {
                        migrate.customer.dateOfBirth || migrate.customer.sex || migrate.customer.gender || migrate.customer.pronoun ?
                            <small className='text-nowrap ml-2'>({`${ph.getAge(migrate.customer.dateOfBirth)} ${ph.getSexGenderPronounDisplay(migrate.customer)}`.trim()})</small> : null
                    }
                </Link>
                {
                    migrate.customer.address && migrate.customer.address.country ?
                        <div className='info'>{ah.getAddressHtml(migrate.customer.address)}</div> : null
                }
                {
                    migrate.customer.emailAddress ?
                        <div className='info'>
                            <a
                                href={`mailto:${migrate.customer.emailAddress}`}
                            >{migrate.customer.emailAddress}
                            </a>
                        </div> : null
                }
                {
                    migrate.customer.phoneNumber ?
                        <div className='info'>
                            <a
                                href={`tel:${migrate.customer.phoneNumber}`}
                            >{sys.getFormattedPhoneNumber(migrate.customer.phoneNumber)}
                            </a>
                        </div> : null
                }
            </div>
        </div>
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <fieldset disabled={migrate.isSaving || migrate.isLoading}>
                <div className='migrate-paper-exam-container quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon='fal fa-file-upload'
                        action='Import'
                        category='Paper Exam'
                        className='exams'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        {
                            migrate.isReady ?
                                <FadeIn>
                                    <div className='body-content'>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0'>
                                                        <label><small>Customer</small></label>
                                                        {renderCustomer()}
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group validate validate-required'>
                                                        <label className='required' htmlFor='paper-file'><small>Paper Exam File</small></label>
                                                        <div className='custom-file'>
                                                            <input
                                                                id='paper-file'
                                                                type='file'
                                                                className='custom-file-input'
                                                                accept='.jpg, .jpeg, .png, .gif, .bmp, .pdf'
                                                                autoComplete='off'
                                                                multiple={false}
                                                                onChange={handlePagerExamFileChange}
                                                            />
                                                            <label
                                                                htmlFor='paper-file'
                                                                className='custom-file-label'
                                                            >
                                                                {
                                                                    migrate.data.file && migrate.data.file.fileName ? migrate.data.file.fileName : null
                                                                }
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='form-group validate validate-required'>
                                                        <label className='required' htmlFor='migrate-exam-date'><small>Date</small></label>
                                                        <DateBox
                                                            id='migrate-exam-date'
                                                            type='date'
                                                            displayFormat={'yyyy-MM-dd'}
                                                            useMaskBehavior={true}
                                                            showClearButton={true}
                                                            max={moment().startOf('day').toDate()}
                                                            value={migrate.data.date ? moment(migrate.data.date).startOf('day').toDate() : null}
                                                            onValueChanged={handlePaperExamDateChange}
                                                        >
                                                        </DateBox>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className={'form-group' + (migrate.appointments.length === 0 || !migrate.data.appointmentId ? ' validate validate-required' : '')}>
                                                        <label className='required' htmlFor='migrate-exam-time'><small>Time</small></label>
                                                        <DateBox
                                                            id='migrate-exam-time'
                                                            type='time'
                                                            displayFormat={'hh:mm a'}
                                                            useMaskBehavior={true}
                                                            showClearButton={true}
                                                            disabled={!migrate.data.date || (migrate.appointments && migrate.appointments.length > 0) || !!migrate.data.appointmentId}
                                                            value={migrate.data.time ? moment(migrate.data.time, 'HH:mm:ss').toDate() : null}
                                                            onValueChanged={handlePaperExamTimeChange}
                                                        >
                                                        </DateBox>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        {
                                            migrate.data.date && migrate.data.time && migrate.appointments.length === 0 ?
                                                <>
                                                    <section className='resource'>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className='form-group validate validate-required'>
                                                                    <label className='required'><small>With</small></label>
                                                                    <DropdownList
                                                                        data={users}
                                                                        valueField='id'
                                                                        value={migrate.data.userId}
                                                                        itemComponent={({ item }) => (
                                                                            <div
                                                                                className='profile-wrapper'
                                                                            >
                                                                                <div className='profile'>
                                                                                    <span
                                                                                        className={`profile-image rounded-circle d-block fw-500` + (item && !item.profilePictureUri ? ` profile-initials bg-color${item.color}-500` : '')}
                                                                                        style={item && item.profilePictureUri ? {
                                                                                            backgroundImage: `url(${item.profilePictureUri})`,
                                                                                            backgroundSize: 'cover',
                                                                                        } : null}
                                                                                        title={item ? item.fullName : 'System'}>
                                                                                        {!item.profilePictureUri ? <div className='d-initials fs-xs'>{item.initials}</div> : null}
                                                                                    </span>
                                                                                </div>
                                                                                <span className='description'>{item.fullName}</span>
                                                                            </div>
                                                                        )}
                                                                        valueComponent={({ item }) => (
                                                                            item ?
                                                                                <div
                                                                                    className='profile-wrapper'
                                                                                >
                                                                                    <div className='profile'>
                                                                                        <span
                                                                                            className={`profile-image rounded-circle d-block fw-500` + (item && !item.profilePictureUri ? ` profile-initials bg-color${item.color}-500` : '')}
                                                                                            style={item && item.profilePictureUri ? {
                                                                                                backgroundImage: `url(${item.profilePictureUri})`,
                                                                                                backgroundSize: 'cover',
                                                                                            } : null}
                                                                                            title={item ? item.fullName : 'System'}>
                                                                                            {item && !item.profilePictureUri ? <div className='d-initials fs-xs'>{item.initials}</div> : null}
                                                                                        </span>
                                                                                    </div>
                                                                                    <span className='description'>{item.fullName}</span>
                                                                                </div> : null
                                                                        )}
                                                                        onChange={handlePaperExamResourceChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <section className='services'>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className='form-group'>
                                                                    <label htmlFor='migrate-exam-services'><small>Services</small></label>
                                                                    <Multiselect
                                                                        allowCreate={false}
                                                                        data={migrate.services}
                                                                        valueField='id'
                                                                        textField='name'
                                                                        tagComponent={({ item }) => (
                                                                            <span
                                                                                className='tag'
                                                                                style={{
                                                                                    backgroundColor: item.colorHexValue,
                                                                                    borderColor: item.colorHexValue,
                                                                                }}
                                                                            >
                                                                                <strong>{item.code}</strong>
                                                                            </span>
                                                                        )}
                                                                        defaultValue={migrate.data.services}
                                                                        onChange={handleServiceSelection}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <section>
                                                        <div className='row'>
                                                            <div className='col-5'>
                                                                <div className='form-group'>
                                                                    <label htmlFor='migrate-exam-duration'><small>Duration</small></label>
                                                                    <div className='input-group'>
                                                                        <input
                                                                            id='migrate-exam-duration'
                                                                            type='number'
                                                                            className='form-control'
                                                                            min={5}
                                                                            step={5}
                                                                            value={(migrate.data.duration ? migrate.data.duration : '')}
                                                                            onChange={handlePaperExamDurationChange}
                                                                        />
                                                                        <div className='input-group-append'>
                                                                            <span className='input-group-text'>min.</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </> : null
                                        }
                                        {
                                            migrate.data.date && migrate.appointments.length > 0 ?
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <label><small>Appointment</small></label>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        {
                                                            migrate.appointments.map((a, ai) => {
                                                                return <div
                                                                    key={`migrate-paper-appointment-${ai}`}
                                                                    name={'appointment'}
                                                                    className={'col-12' + (migrate.appointments.length > 1 && migrate.appointments.length < (ai + 1) ? ' border-bottom pb-2' : '')}
                                                                    data-id={a.id}
                                                                >
                                                                    <div
                                                                        className='w-100 py-1'
                                                                    >
                                                                        <div className='d-flex flex-row'>
                                                                            <div className='d-flex flex-column justify-content-center text-right pr-2'>
                                                                                <div className='custom-control custom-radio'>
                                                                                    <input
                                                                                        id={`migrate_paper_appointment-${ai}`}
                                                                                        type='radio'
                                                                                        name='migrateAppointment'
                                                                                        value={a.id}
                                                                                        className='custom-control-input'
                                                                                        checked={migrate.data.appointmentId === a.id}
                                                                                        onChange={handleMigrateAppointmentChange}
                                                                                    />
                                                                                    <label
                                                                                        htmlFor={`migrate_paper_appointment-${ai}`}
                                                                                        className='custom-control-label'
                                                                                    >&nbsp;</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex flex-column justify-content-center text-dark'>
                                                                                <div
                                                                                    className='fs-lg fw-500 line-height-1'
                                                                                >
                                                                                    <small className={(migrate.data.appointmentId === a.id ? 'text-gray-700' : '')}>
                                                                                        With <span className='fw-500'>{a.resource ? ph.getPreferredFullName(a.resource) : 'System'}</span>
                                                                                    </small>
                                                                                </div>
                                                                                <div className='mb-h'>
                                                                                    {sys.getFormattedLongDate(moment(a.start), true)}
                                                                                </div>
                                                                                <div>
                                                                                    <>
                                                                                        {
                                                                                            a.services && a.services.length > 0 ?
                                                                                                a.services.map((s, si) => {
                                                                                                    return <Fragment
                                                                                                        key={`customer-appointment-service-${si}`}
                                                                                                    >
                                                                                                        <div
                                                                                                            className='d-inline-block'
                                                                                                        >
                                                                                                            {bh.renderServiceCode(s, 'p-1 mr-1', (!s.isEligible || !aph.isEndState(a)))}
                                                                                                        </div>
                                                                                                    </Fragment>
                                                                                                }) : null
                                                                                        }
                                                                                    </>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </section> : null
                                        }
                                    </div>
                                </FadeIn> : renderQuickDrawerLoading()
                        }
                    </div>
                    <div className='quick-drawer-action pl-3'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>)
}

export default MigratePaperExam;