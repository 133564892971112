import { createContext } from 'react';
import { decorate, observable, action, toJS } from 'mobx';

export class ReferralElementView {
    id = null;
    data = null;
    hasUnsavedChanges = false;

    load = data => {
        this.clear();
        this.id = data.id;
        this.data = data;

        return Promise.resolve();
    }

    setMetadata = (key, value) => {
        let metadata = this.data.metadata;

        if (!metadata) {
            metadata = {};
        }

        if (!metadata.hasOwnProperty(key)) {
            metadata = { ...metadata, ...{ [key]: value } };
        } else {
            metadata[key] = value;
        }

        this.data.metadata = metadata;
    }

    removeMetadata = (key) => {
        let metadata = this.data.metadata;

        if (metadata && metadata.hasOwnProperty(key)) {
            delete metadata[key];
        }
    }

    getMetadata = (key) => {
        if (!this.data.metadata) return undefined;
        return this.data.metadata[key];
    }

    setPosition = (key, value) => {
        let position = this.data.position;

        if (!position.hasOwnProperty(key)) {
            position = { ...position, ...{ [key]: value } };
        } else {
            position[key] = value;
        }

        this.data.position = position;
    }

    save = () => {
        const that = this;

        return new Promise((resolve) => {
            const option = toJS(that.data);
            that.hasUnsavedChanges = false;
            resolve(option);
        })
    }

    clear = () => {
        this.id = null;
        this.data = null;
        this.hasUnsavedChanges = false;
    }
}

decorate(ReferralElementView, {
    id: observable,
    data: observable,
    hasUnsavedChanges: observable,
    load: action,
    save: action,
    setMetadata: action,
    removeMetadata: action,
    getMetadata: action,
    setPosition: action,
    clear: action,
})

export default createContext(new ReferralElementView());