import React, { useContext, useEffect, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { Observer } from 'mobx-react-lite';
import momentLocalizer from 'react-widgets-moment';
import MaskedInput from 'react-text-mask'
import { GlobalHotKeys } from 'react-hotkeys';
import { Combobox, DropdownList } from 'react-widgets'
import moment from 'moment';

import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import RichTextEditor from '../../_shared/RichTextEditor';

import ProductCreateStore from '../../../../stores/ProductCreateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as MaskKeys from '../../../../constants/maskKeys';
import * as AccessType from '../../../../constants/accessTypes';
import * as fn from '../../../../utilities/_functions';
import * as ph from '../../../../utilities/productHelper';
import * as ah from '../../../../utilities/accessHelper';
import * as oh from '../../../../utilities/operationHelper';

import './NewProduct.scss';

moment.locale('en');
momentLocalizer();

function NewProduct(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const product = useContext(ProductCreateStore);
    const quickDrawer = useContext(QuickDrawerStore);

    useEffect(() => {
        quickDrawerFocus(props.drawer);

        return () => {
            isMounted.current = false;
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (product.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleTypeChange = ({ id }) => {
        product.updateType(id);
    }

    const handleBrandChange = ({ id }) => {
        product.data.brandId = id;
        product.hasUnsavedChanges = true;
    }

    const handleNameChange = event => {
        const previousName = product.data.name;
        const value = event.target.value;

        product.data.name = value;

        if (product.data.descriptor === previousName) {
            product.data.descriptor = value;
        }

        product.hasUnsavedChanges = true;
    }

    const handleDescriptorChange = event => {
        product.data.descriptor = event.target.value;
        product.hasUnsavedChanges = true;
    }

    // const handleSkuNumberChange = event => {
    //     product.data.skuNumber = event.target.value;
    //     product.hasUnsavedChanges = true;
    // }

    // const handleIsStockedChange = event => {
    //     const isStocked = !product.data.isStocked;

    //     product.data.isStocked = isStocked;
    //     product.data.isTracked = isStocked;
    //     product.hasUnsavedChanges = true;
    // }

    const handleIsTrackedChange = event => {
        const isTracked = !product.data.isTracked;
        product.data.isTracked = isTracked;
        product.data.quantity = product.data.quantity ? product.data.quantity : 1;
        product.hasUnsavedChanges = true;
    }

    const handleDefaultApplyTaxChange = event => {
        const defaultApplyTax = !product.data.defaultApplyTax;
        product.data.defaultApplyTax = defaultApplyTax;
        product.hasUnsavedChanges = true;
    }

    const handleReferenceNumberChange = event => {
        product.data.referenceNumber = event.target.value;
        product.hasUnsavedChanges = true;
    }

    const handleNoteChange = (content, delta, source, editor) => {
        const note = editor.getText().replace(/(\r\n|\n|\r)/g, ' ').trim();
        const noteHtml = (content === '<p><br></p>') ? null : content;

        product.data.note = note;
        product.data.noteHtml = noteHtml;
        product.hasUnsavedChanges = true;
    }

    // const handleSerialNumberChange = (event, index) => {
    //     product.data.serialNumbers[index] = event.target.value;
    //     product.hasUnsavedChanges = true;
    // }

    const handleOptionTextChange = (event, key) => {
        const text = event.target.value;
        handleOptionValueChange(key, text);
        product.hasUnsavedChanges = true;
    }

    const handleOptionValueChange = (key, option) => {
        const index = product.data.data.findIndex(d => d.key === key);
        const value = option.value ? option.value : option;

        if (index >= 0) {
            if (value) {
                product.data.data[index].value = value;
            } else {
                product.data.data.remove(product.data.data[index]);
            }
        } else {
            if (value) {
                const option = product.selectedType.data.filter(d => d.key === key)[0];

                option.value = value;
                product.data.data.push(option);
            }
        }
        product.hasUnsavedChanges = true;
    }

    const handleQuantityChange = event => {
        const quantity = parseInt(event.target.value, 10);
        product.data.quantity = quantity;
        product.hasUnsavedChanges = true;
    }

    const handleQuantityBlur = () => {
        if (product.data.serialNumbers.length > product.data.quantity) {
            let count = 0;
            const diff = product.data.serialNumbers.length - product.data.quantity;

            do {
                product.data.serialNumbers.remove(product.data.serialNumbers[product.data.serialNumbers.length - 1]);
                count++;
            } while (diff !== count)
        }
    }

    const handleCostChange = event => {
        product.data.cost = event.target.value;
        product.hasUnsavedChanges = true;
    }

    const handlePriceChange = event => {
        product.data.price = event.target.value;
        product.hasUnsavedChanges = true;
    }

    // const handleSerialNumberAdd = (event, index) => {
    //     product.data.serialNumbers.splice((index + 1), 0, '');
    //     product.hasUnsavedChanges = true;
    // }

    // const handleSerialNumberRemove = (event, index) => {
    //     product.data.serialNumbers.remove(product.data.serialNumbers[index]);
    //     product.hasUnsavedChanges = true;
    // }

    const handleSubmit = event => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            product.save(true)
                .then(data => {
                    if (isMounted.current) {
                        if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                            const originalId = props.extraProps && props.extraProps.originalId ? props.extraProps.originalId : null;
                            props.onSuccess(event, { isUpdated: true, originalId: originalId, data: data });
                        }
                    }
                })
        }
    }

    const getOptionValue = key => {
        if (product.data.data && product.data.data.some(d => d.key === key)) {
            return product.data.data.filter(d => d.key === key)[0].value;
        }
        return '';
    }

    const getIsDisabled = () => {
        return !product.brands || product.brands.length === 0;
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <fieldset disabled={product.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('product', 'new')}
                        action='Add New'
                        category='Product'
                        className='products'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        <Observer>{() => <>
                            {
                                product.isReady ?
                                    <FadeIn>
                                        <div className='new-product body-content'>
                                            <Observer>{() =>
                                                <section className='type'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-0 validate validate-required'>
                                                                <label className='required'><small>Category</small></label>
                                                                <DropdownList
                                                                    data-product-type-dropdown
                                                                    data={product.types}
                                                                    valueField='id'
                                                                    value={product.data.typeId}
                                                                    itemComponent={({ item }) => (
                                                                        item ? <>{item.name}</> : <></>
                                                                    )}
                                                                    valueComponent={({ item }) => (
                                                                        item ? <>{item.name}</> : <></>
                                                                    )}
                                                                    onChange={handleTypeChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            }</Observer>
                                            <Observer>{() =>
                                                <section className='brand'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-0 validate validate-required'>
                                                                <label className='required'><small>Brand</small></label>
                                                                <DropdownList
                                                                    data-product-brand-dropdown
                                                                    data={product.brands}
                                                                    valueField='id'
                                                                    value={product.data.brandId}
                                                                    itemComponent={({ item }) => (
                                                                        item ? <>
                                                                            {item.name}
                                                                            {
                                                                                item.supplier ?
                                                                                    <>
                                                                                        <br /><small>{item.supplier.name}</small>
                                                                                    </> : null
                                                                            }
                                                                        </> : <></>
                                                                    )}
                                                                    valueComponent={({ item }) => (
                                                                        item ? <>
                                                                            {item.name}
                                                                            {
                                                                                item.supplier ?
                                                                                    <>
                                                                                        <small className='text-gray-700 ml-1'>({item.supplier.name})</small>
                                                                                    </> : null
                                                                            }
                                                                        </> : <></>
                                                                    )}
                                                                    disabled={getIsDisabled()}
                                                                    onChange={handleBrandChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            }</Observer>
                                            <section>
                                                <div className='row'>
                                                    {
                                                        product.data.isStocked ?
                                                            <>
                                                                <div className='col-6'>
                                                                    <Observer>{() =>
                                                                        <div className='form-group mb-0'>
                                                                            <label><small>{ph.getInventoryTypeDescription(product.selectedType.inventory)}</small></label>
                                                                            <div className='custom-control custom-checkbox'>
                                                                                <input
                                                                                    id='product-is-tracked'
                                                                                    type='checkbox'
                                                                                    name='product-is-tracked'
                                                                                    className='custom-control-input'
                                                                                    checked={product.data.isTracked ? product.data.isTracked : false}
                                                                                    onChange={handleIsTrackedChange}
                                                                                />
                                                                                <label
                                                                                    htmlFor='product-is-tracked'
                                                                                    className='custom-control-label'
                                                                                >
                                                                                    Track stock level
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    }</Observer>
                                                                </div>
                                                                <div className='col-6'>
                                                                    <Observer>{() =>
                                                                        <div className='form-group mb-0'>
                                                                            <label><small>&nbsp;</small></label>
                                                                            <div className='custom-control custom-checkbox'>
                                                                                <input
                                                                                    id='product-apply-tax'
                                                                                    type='checkbox'
                                                                                    name='product-apply-tax'
                                                                                    className='custom-control-input'
                                                                                    checked={product.data.defaultApplyTax === true ? product.data.defaultApplyTax : false}
                                                                                    onChange={handleDefaultApplyTaxChange}
                                                                                />
                                                                                <label
                                                                                    htmlFor='product-apply-tax'
                                                                                    className='custom-control-label'
                                                                                >
                                                                                    Apply Tax By Default
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    }</Observer>
                                                                </div>
                                                            </> :
                                                            <div className='col-6'>
                                                                <Observer>{() =>
                                                                    <div className='form-group mb-0'>
                                                                        <label><small>{ph.getInventoryTypeDescription(product.selectedType.inventory)}</small></label>
                                                                        <div className='custom-control custom-checkbox'>
                                                                            <input
                                                                                id='product-apply-tax'
                                                                                type='checkbox'
                                                                                name='product-apply-tax'
                                                                                className='custom-control-input'
                                                                                checked={product.data.defaultApplyTax === true ? product.data.defaultApplyTax : false}
                                                                                onChange={handleDefaultApplyTaxChange}
                                                                            />
                                                                            <label
                                                                                htmlFor='product-apply-tax'
                                                                                className='custom-control-label'
                                                                            >
                                                                                Apply Tax By Default
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                }</Observer>
                                                            </div>
                                                    }
                                                </div>
                                            </section>
                                            {
                                                <Observer>{() =>
                                                    <section>
                                                        <div className='row'>
                                                            <div className={product.data.isStocked && product.data.isTracked ? 'col-4' : 'd-none'}>
                                                                <div className='form-group mb-0 validate validate-required'>
                                                                    <label className='required' htmlFor='new-product-quantity'><small>Quantity</small></label>
                                                                    <input
                                                                        id='new-appointment-quantity'
                                                                        type='number'
                                                                        className='form-control'
                                                                        min={0}
                                                                        step={1}
                                                                        disabled={getIsDisabled() || !product.data.isStocked || !product.data.isTracked}
                                                                        value={(!fn.isNullOrUndefined(product.data.quantity) ? product.data.quantity : 1)}
                                                                        onChange={handleQuantityChange}
                                                                        onBlur={handleQuantityBlur}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className={product.data.isStocked && product.data.isTracked ? 'col-4' : 'col-6'}>
                                                                <div className='form-group mb-0 validate validate-required'>
                                                                    <label className='required' htmlFor='new-product-price'><small>Price</small></label>
                                                                    <MaskedInput
                                                                        id='new-product-price'
                                                                        type='text'
                                                                        className='form-control'
                                                                        spellCheck={false}
                                                                        mask={MaskKeys.CURRENCY_MASK}
                                                                        maxLength='25'
                                                                        autoComplete='off'
                                                                        disabled={getIsDisabled()}
                                                                        value={product.data.price ? product.data.price : ''}
                                                                        onChange={handlePriceChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {
                                                                ah.check(AccessType.UPDATE_PRODUCT) ?
                                                                    <div className={product.data.isStocked && product.data.isTracked ? 'col-4' : 'col-6'}>
                                                                        <div className='form-group mb-0'>
                                                                            <label htmlFor='new-product-cost'><small>Cost</small></label>
                                                                            <MaskedInput
                                                                                id='new-product-cost'
                                                                                type='text'
                                                                                className='form-control'
                                                                                spellCheck={false}
                                                                                mask={MaskKeys.CURRENCY_MASK}
                                                                                maxLength='25'
                                                                                autoComplete='off'
                                                                                disabled={getIsDisabled()}
                                                                                value={product.data.cost ? product.data.cost : ''}
                                                                                onChange={handleCostChange}
                                                                            />
                                                                        </div>
                                                                    </div> : null
                                                            }
                                                        </div>
                                                    </section>
                                                }</Observer>
                                            }
                                            {
                                                <Observer>{() =>
                                                    <section className={product.data.isStocked && product.data.isTracked && product.data.quantity > 0 ? '' : 'd-none'}>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className={'form-group'}>
                                                                    <label htmlFor='new-product-reference'><small>Purchase Reference No.</small></label>
                                                                    <input
                                                                        id='new-product-reference'
                                                                        type='text'
                                                                        className='form-control'
                                                                        autoComplete='off'
                                                                        value={product.data.referenceNumber ? product.data.referenceNumber : ''}
                                                                        onChange={handleReferenceNumberChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                }</Observer>
                                            }
                                            {
                                                <Observer>{() =>
                                                    <section className={product.data.isStocked && product.data.isTracked && product.data.quantity > 0 ? '' : 'd-none'}>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className={'form-group'}>
                                                                    <label htmlFor='new-product-note'><small>Purchase Note</small></label>
                                                                    <RichTextEditor
                                                                        mode='basic'
                                                                        value={product.data.noteHtml}
                                                                        disableTab={true}
                                                                        onChange={handleNoteChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                }</Observer>
                                            }
                                            <Observer>{() =>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-0 validate validate-required'>
                                                                <label className='required'><small>Name</small></label>
                                                                <input
                                                                    data-product-name-input
                                                                    type='text'
                                                                    className='form-control'
                                                                    spellCheck={false}
                                                                    maxLength='150'
                                                                    autoComplete='off'
                                                                    disabled={getIsDisabled()}
                                                                    value={product.data.name ? product.data.name : ''}
                                                                    onChange={handleNameChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            }</Observer>
                                            <Observer>{() =>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-0 validate validate-required'>
                                                                <label className='required'><small>Invoice Descriptor</small></label>
                                                                <input
                                                                    data-product-invoice-descriptor-input
                                                                    type='text'
                                                                    className='form-control'
                                                                    spellCheck={false}
                                                                    maxLength='150'
                                                                    autoComplete='off'
                                                                    disabled={getIsDisabled()}
                                                                    value={product.data.descriptor ? product.data.descriptor : ''}
                                                                    onChange={handleDescriptorChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            }</Observer>
                                            {/* <Observer>{() =>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-0'>
                                                                <label><small>Sku Number</small></label>
                                                                <input
                                                                    type='text'
                                                                    className='form-control'
                                                                    spellCheck={false}
                                                                    maxLength='150'
                                                                    autoComplete='off'
                                                                    disabled={getIsDisabled()}
                                                                    value={product.data.skuNumber ? product.data.skuNumber : ''}
                                                                    onChange={handleSkuNumberChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            }</Observer> */}
                                            {/* <Observer>{() => (
                                                product.selectedType && product.data.isStocked && product.data.isTracked && product.data.quantity > 0 ? <>
                                                    <section className='serial-numbers'>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className='form-group'>
                                                                    <label><small>Serial Number{product.data.serialNumbers.length > 1 ? 's' : ''}</small></label>
                                                                    {
                                                                        product.data.serialNumbers.map((n, ni) => {
                                                                            return <div
                                                                                key={`serial_number_${ni}`}
                                                                                className={'serial-number-input' + (product.data.quantity < (ni + 1) ? ' d-none' : '') + (product.data.quantity === (ni + 1) ? ' last-input' : '') + (ni === 0 ? '' : ' mt-2')}
                                                                            >
                                                                                <input
                                                                                    type='text'
                                                                                    className='form-control'
                                                                                    spellCheck={false}
                                                                                    maxLength='100'
                                                                                    autoComplete='off'
                                                                                    placeholder={`${(product.data.serialNumbers.length === 1 ? '' : `Serial Number #${ni + 1}`)}`}
                                                                                    value={n ? n : ''}
                                                                                    onChange={e => { handleSerialNumberChange(e, ni) }}
                                                                                />
                                                                                <div className='button-group'>
                                                                                    <button
                                                                                        type='button'
                                                                                        className='btn btn-link btn-icon btn-sm'
                                                                                        onClick={e => { handleSerialNumberAdd(e, ni) }}
                                                                                        disabled={product.data.quantity === product.data.serialNumbers.length}
                                                                                    >
                                                                                        <i className='fal fa-plus-circle text-success'></i>
                                                                                    </button>
                                                                                    <button
                                                                                        type='button'
                                                                                        className='btn btn-link btn-icon btn-sm'
                                                                                        onClick={e => { handleSerialNumberRemove(e, ni) }}
                                                                                        disabled={ni === 0 && product.data.serialNumbers.length === 1}
                                                                                    >
                                                                                        <i className='fal fa-minus-circle text-danger'></i>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </> : null
                                            )}</Observer> */}
                                            <Observer>{() => (
                                                product.selectedType && product.options && product.selectedType.data && product.selectedType.data.length > 0 ?
                                                    <section className='mt-4'>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <label><small>Extra Data</small></label>
                                                                <div className='divider mb-4' />
                                                            </div>
                                                        </div>
                                                        {
                                                            product.selectedType.data.map((d, di) => {
                                                                return <div key={`extra_data_${di}`} className='row'>
                                                                    <div className='col-12'>
                                                                        <div className='form-group mb-4'>
                                                                            <label htmlFor={d.key}><small>{d.name}</small></label>
                                                                            {
                                                                                product.options.some(o => o.key === d.key) ?
                                                                                    <Combobox
                                                                                        className='placeholder placeholder-sm'
                                                                                        data={product.options.filter(o => o.key === d.key)}
                                                                                        dropUp={product.selectedType.data.length - di < 4}
                                                                                        filter={(item, value) => {
                                                                                            return item.value.toLowerCase().startsWith(value.toLowerCase());
                                                                                        }}
                                                                                        value={getOptionValue(d.key)}
                                                                                        itemComponent={({ item }) => (
                                                                                            item ? <>{item.value}</> : <></>
                                                                                        )}
                                                                                        valueComponent={({ item }) => (
                                                                                            item ? <>{item.value}</> : <></>
                                                                                        )}
                                                                                        onChange={value => { handleOptionValueChange(d.key, value) }}
                                                                                    /> :
                                                                                    <input
                                                                                        id={d.key}
                                                                                        type='text'
                                                                                        className='form-control'
                                                                                        spellCheck={false}
                                                                                        maxLength='50'
                                                                                        autoComplete='off'
                                                                                        value={getOptionValue(d.key)}
                                                                                        onChange={e => { handleOptionTextChange(e, d.key) }}
                                                                                    />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                    </section> : null
                                            )}</Observer>
                                        </div>
                                    </FadeIn> : renderQuickDrawerLoading()
                            }
                        </>}</Observer>
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        data-submit-save-new-product
                                        type='submit'
                                        className='btn btn-success'
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form >
    </>
}

export default NewProduct;