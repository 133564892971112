import React from 'react';
import uuid from 'react-uuid';

import * as fn from '../utilities/_functions';
import * as ith from '../utilities/internalFormTemplateHelper';

export const INTERNAL_FORM_INPUT_DESCRIPTION = 'INTERNAL_FORM_INPUT_DESCRIPTION';

export const INTERNAL_FORM_INPUT_METADATA_ALIGN = 'INTERNAL_FORM_INPUT_METADATA_ALIGN';
export const INTERNAL_FORM_INPUT_METADATA_FONT_COLOR = 'INTERNAL_FORM_INPUT_METADATA_FONT_COLOR';
export const INTERNAL_FORM_INPUT_METADATA_FONT_SIZE = 'INTERNAL_FORM_INPUT_METADATA_FONT_SIZE';
export const INTERNAL_FORM_INPUT_METADATA_FONT_FAMILY = 'INTERNAL_FORM_INPUT_METADATA_FONT_FAMILY';
export const INTERNAL_FORM_INPUT_METADATA_FONT_STYLE = 'INTERNAL_FORM_INPUT_METADATA_FONT_STYLE';
export const INTERNAL_FORM_INPUT_METADATA_FONT_LETTER_SPACING = 'INTERNAL_FORM_INPUT_METADATA_FONT_LETTER_SPACING';
export const INTERNAL_FORM_INPUT_METADATA_OPTIONS = 'INTERNAL_FORM_INPUT_METADATA_OPTIONS';
export const INTERNAL_FORM_INPUT_METADATA_DEFAULT_VALUE = 'INTERNAL_FORM_INPUT_METADATA_DEFAULT_VALUE';
export const INTERNAL_FORM_INPUT_METADATA_BORDER = 'INTERNAL_FORM_INPUT_METADATA_BORDER';
export const INTERNAL_FORM_INPUT_METADATA_BORDER_COLOR = 'INTERNAL_FORM_INPUT_METADATA_BORDER_COLOR';
export const INTERNAL_FORM_INPUT_METADATA_BORDER_WIDTH = 'INTERNAL_FORM_INPUT_METADATA_BORDER_WIDTH';

export const InputElementTypes = ['SingleLineText', 'MultiLineText', 'Dropdown', 'Checkbox', 'Circle', 'Check', 'Signature'];

export const isInputElement = ({ type }) => {
    return InputElementTypes.some(t => t === type);
}

export const getIcon = (type) => {
    switch (type) {
        case 'SingleLineText':
            return 'fal fa-text';

        case 'MultiLineText':
            return 'fal fa-align-left';

        case 'Dropdown':
            return 'fal fa-chevron-square-down';

        case 'Checkbox':
            return 'fal fa-check-square';

        case 'Circle':
            return 'fal fa-circle';

        case 'Check':
            return 'fal fa-check';

        case 'Signature':
            return 'fal fa-signature';

        default:
            return false
    }
}

export const hasField = (type, field) => {
    switch (type) {
        case 'SingleLineText':
        case 'MultiLineText':
            return [
                INTERNAL_FORM_INPUT_DESCRIPTION,
                INTERNAL_FORM_INPUT_METADATA_ALIGN,
                INTERNAL_FORM_INPUT_METADATA_FONT_COLOR,
                INTERNAL_FORM_INPUT_METADATA_FONT_SIZE,
                INTERNAL_FORM_INPUT_METADATA_FONT_FAMILY,
                INTERNAL_FORM_INPUT_METADATA_FONT_STYLE,
                INTERNAL_FORM_INPUT_METADATA_FONT_LETTER_SPACING,
                INTERNAL_FORM_INPUT_METADATA_DEFAULT_VALUE,
            ].some(f => f === field);

        case 'Dropdown':
            return [
                INTERNAL_FORM_INPUT_DESCRIPTION,
                INTERNAL_FORM_INPUT_METADATA_ALIGN,
                INTERNAL_FORM_INPUT_METADATA_FONT_COLOR,
                INTERNAL_FORM_INPUT_METADATA_FONT_SIZE,
                INTERNAL_FORM_INPUT_METADATA_FONT_FAMILY,
                INTERNAL_FORM_INPUT_METADATA_FONT_STYLE,
                INTERNAL_FORM_INPUT_METADATA_FONT_LETTER_SPACING,
                INTERNAL_FORM_INPUT_METADATA_OPTIONS,
                INTERNAL_FORM_INPUT_METADATA_DEFAULT_VALUE,
            ].some(f => f === field);

        case 'Checkbox':
            return [
                INTERNAL_FORM_INPUT_DESCRIPTION,
                INTERNAL_FORM_INPUT_METADATA_ALIGN,
                INTERNAL_FORM_INPUT_METADATA_FONT_COLOR,
                INTERNAL_FORM_INPUT_METADATA_FONT_SIZE,
                INTERNAL_FORM_INPUT_METADATA_FONT_FAMILY,
                INTERNAL_FORM_INPUT_METADATA_FONT_STYLE,
                INTERNAL_FORM_INPUT_METADATA_FONT_LETTER_SPACING,
                INTERNAL_FORM_INPUT_METADATA_OPTIONS,
                INTERNAL_FORM_INPUT_METADATA_DEFAULT_VALUE,
            ].some(f => f === field);

        case 'Circle':
            return [
                INTERNAL_FORM_INPUT_DESCRIPTION,
                INTERNAL_FORM_INPUT_METADATA_DEFAULT_VALUE,
                INTERNAL_FORM_INPUT_METADATA_BORDER,
                INTERNAL_FORM_INPUT_METADATA_BORDER_COLOR,
                INTERNAL_FORM_INPUT_METADATA_BORDER_WIDTH,
            ].some(f => f === field);

        case 'Check':
            return [
                INTERNAL_FORM_INPUT_DESCRIPTION,
                INTERNAL_FORM_INPUT_METADATA_DEFAULT_VALUE,
            ].some(f => f === field);

        case 'Signature':
            return [
                INTERNAL_FORM_INPUT_DESCRIPTION,
            ].some(f => f === field);

        default:
            return false
    }
}

export const renderInput = (input, options) => {
    const defaults = {
        inputKey: `_${uuid()}`,
        readOnly: null,
        referenceObject: null,
        value: null,
        onChange: null,
        onClick: null,
        onDoubleClick: null,
        onContextMenu: null,
    };
    options = { ...defaults, ...options }

    switch (input.type) {
        case 'SingleLineText':
            return <div
                className={'element-wrapper element-input h-100 no-label'}
                data-id={input.id}
            >
                <div
                    className={'form-group no-label' + (options.value ? ' has-value' : '')}
                    onClick={options.onClick}
                    onDoubleClick={options.onDoubleClick}
                    onContextMenu={options.onContextMenu}
                >
                    <div className='control-wrapper'>
                        <input
                            id={options.inputKey}
                            type='text'
                            className='form-control'
                            style={{
                                textAlign: input.metadata && input.metadata.align ? input.metadata.align : 'left',
                                color: input.metadata && input.metadata.fontColor ? input.metadata.fontColor : null,
                                fontSize: input.metadata && input.metadata.fontSize ? `${input.metadata.fontSize}px` : null,
                                fontFamily: input.metadata && input.metadata.fontFamily ? input.metadata.fontFamily : null,
                                fontWeight: input.metadata && input.metadata.fontStyle && input.metadata.fontStyle.some(s => s === 'bold') ? 'bold' : null,
                                fontStyle: input.metadata && input.metadata.fontStyle && input.metadata.fontStyle.some(s => s === 'italic') ? 'italic' : null,
                                textDecoration: input.metadata && input.metadata.fontStyle && input.metadata.fontStyle.some(s => s === 'underline') ? 'underline' : null,
                                letterSpacing: input.metadata && input.metadata.fontLetterSpacing ? `${input.metadata.fontLetterSpacing}px` : null,
                            }}
                            data-key={input.key}
                            autoComplete='off'
                            disabled={options.readOnly}
                            title={options.value ? options.value : null}
                            value={options.readOnly ? input.name : (options.value ? options.value : '')}
                            onChange={fn.isFunction(options.onChange) && !options.readOnly ? (e) => { options.onChange(e, input.key, options.referenceObject) } : () => { }}
                        />
                    </div>
                </div>
            </div>

        case 'MultiLineText':
            return <div
                className={'element-wrapper element-input h-100 no-label'}
                data-id={input.id}
            >
                <div
                    className={'form-group no-label' + (options.value ? ' has-value' : '')}
                    onDoubleClick={options.onDoubleClick}
                    onContextMenu={options.onContextMenu}
                >
                    <div className='control-wrapper'>
                        <textarea
                            id={options.inputKey}
                            className='form-control'
                            style={{
                                height: '100%',
                                textAlign: input.metadata && input.metadata.align ? input.metadata.align : 'left',
                                color: input.metadata && input.metadata.fontColor ? input.metadata.fontColor : null,
                                fontSize: input.metadata && input.metadata.fontSize ? `${input.metadata.fontSize}px` : null,
                                fontFamily: input.metadata && input.metadata.fontFamily ? input.metadata.fontFamily : null,
                                fontWeight: input.metadata && input.metadata.fontStyle && input.metadata.fontStyle.some(s => s === 'bold') ? 'bold' : null,
                                fontStyle: input.metadata && input.metadata.fontStyle && input.metadata.fontStyle.some(s => s === 'italic') ? 'italic' : null,
                                textDecoration: input.metadata && input.metadata.fontStyle && input.metadata.fontStyle.some(s => s === 'underline') ? 'underline' : null,
                                letterSpacing: input.metadata && input.metadata.fontLetterSpacing ? `${input.metadata.fontLetterSpacing}px` : null,
                            }}
                            data-key={input.key}
                            autoComplete='off'
                            disabled={options.readOnly}
                            title={options.value ? options.value : null}
                            value={options.readOnly ? input.name : (options.value ? options.value : '')}
                            onChange={fn.isFunction(options.onChange) && !options.readOnly ? (e) => { options.onChange(e, input.key, options.referenceObject) } : () => { }}
                        />
                    </div>
                </div>
            </div>

        case 'Dropdown':
            return <div
                className={'element-wrapper element-input h-100 no-label'}
                data-id={input.id}
            >
                <div
                    className={'form-group no-label'}
                    onDoubleClick={options.onDoubleClick}
                    onContextMenu={options.onContextMenu}
                >
                    <div className='control-wrapper'>
                        {
                            options.readOnly ?
                                <input
                                    id={options.inputKey}
                                    type='text'
                                    className='form-control'
                                    style={{
                                        textAlign: input.metadata && input.metadata.align ? input.metadata.align : 'left',
                                        color: input.metadata && input.metadata.fontColor ? input.metadata.fontColor : null,
                                        fontSize: input.metadata && input.metadata.fontSize ? `${input.metadata.fontSize}px` : null,
                                        fontFamily: input.metadata && input.metadata.fontFamily ? input.metadata.fontFamily : null,
                                        fontWeight: input.metadata && input.metadata.fontStyle && input.metadata.fontStyle.some(s => s === 'bold') ? 'bold' : null,
                                        fontStyle: input.metadata && input.metadata.fontStyle && input.metadata.fontStyle.some(s => s === 'italic') ? 'italic' : null,
                                        textDecoration: input.metadata && input.metadata.fontStyle && input.metadata.fontStyle.some(s => s === 'underline') ? 'underline' : null,
                                        letterSpacing: input.metadata && input.metadata.fontLetterSpacing ? `${input.metadata.fontLetterSpacing}px` : null,
                                    }}
                                    data-key={input.key}
                                    autoComplete='off'
                                    disabled={true}
                                    title={options.value ? options.value : null}
                                    value={options.value ? options.value : ''}
                                /> :
                                <select
                                    id={options.inputKey}
                                    className='custom-select form-control'
                                    style={{
                                        textAlign: input.metadata && input.metadata.align ? input.metadata.align : 'left',
                                        color: input.metadata && input.metadata.fontColor ? input.metadata.fontColor : null,
                                        fontSize: input.metadata && input.metadata.fontSize ? `${input.metadata.fontSize}px` : null,
                                        fontFamily: input.metadata && input.metadata.fontFamily ? input.metadata.fontFamily : null,
                                        fontWeight: input.metadata && input.metadata.fontStyle && input.metadata.fontStyle.some(s => s === 'bold') ? 'bold' : null,
                                        fontStyle: input.metadata && input.metadata.fontStyle && input.metadata.fontStyle.some(s => s === 'italic') ? 'italic' : null,
                                        textDecoration: input.metadata && input.metadata.fontStyle && input.metadata.fontStyle.some(s => s === 'underline') ? 'underline' : null,
                                        letterSpacing: input.metadata && input.metadata.fontLetterSpacing ? `${input.metadata.fontLetterSpacing}px` : null,
                                    }}
                                    data-key={input.key}
                                    title={options.value ? options.value : null}
                                    value={options.value ? options.value : ''}
                                    disabled={options.readOnly}
                                    onChange={fn.isFunction(options.onChange) && !options.readOnly ? (e) => { options.onChange(e, input.key, options.referenceObject) } : () => { }}
                                >
                                    <option value=''></option>
                                    {
                                        input.metadata && input.metadata.options && input.metadata.options.length > 0 ?
                                            input.metadata.options.map((o, oi) => {
                                                return <option
                                                    key={`${input.key}_metadata_${oi}`}
                                                    value={o}
                                                >
                                                    {o}
                                                </option>
                                            }) : null
                                    }
                                </select>
                        }
                    </div>
                </div>
            </div>

        case 'Checkbox':
            return <div
                className={'element-wrapper element-input h-100 no-label'}
                data-id={input.id}
            >
                <div
                    className={'form-group no-label text-middle' + (options.value ? ' has-value' : '')}
                    onDoubleClick={options.onDoubleClick}
                    onContextMenu={options.onContextMenu}
                >
                    <div className={(input.label && input.label.length > 0 ? 'checkbox-wrapper' : '')}>
                        {
                            input.metadata && input.metadata.options && input.metadata.options.length > 0 ?
                                input.metadata.options.map((o, oi) => {
                                    return <div
                                        key={`${input.key}_metadata_${oi}`}
                                        className={'custom-control custom-checkbox'}
                                    >
                                        <input
                                            id={`${input.key}-${oi}`}
                                            type='checkbox'
                                            name={`new-template-input-inline-${input.key}`}
                                            className={
                                                'custom-control-input'
                                            }
                                            disabled={options.readOnly}
                                            value={o ? o : ''}
                                            data-key={input.key}
                                            checked={(options.value && options.value.split('|').some(v => v === o))}
                                            onChange={fn.isFunction(options.onChange) && !options.readOnly ? (e) => { options.onChange(e, input.key, options.referenceObject) } : (e) => { e.preventDefault(); e.stopPropagation(); }}
                                            onClick={!fn.isFunction(options.onChange) || options.readOnly ? (e) => { e.preventDefault(); e.stopPropagation(); } : null}
                                        />
                                        <label
                                            htmlFor={options.readOnly ? '' : `${input.key}-${oi}`}
                                            className={
                                                'custom-control-label'
                                            }
                                        >
                                        </label>
                                    </div>
                                }) : null
                        }
                    </div>
                </div>
            </div>

        case 'Circle':
            return <div
                className={'element-wrapper element-input h-100 no-label'}
                data-id={input.id}
            >
                <div
                    className={'form-group no-label text-middle' + (options.value ? ' has-value' : '')}
                    onDoubleClick={options.onDoubleClick}
                    onContextMenu={options.onContextMenu}
                >
                    <div className='circle-wrapper'>
                        <div
                            className={'circle-input' + (!options.readOnly && options.value ? ' selected' : '')}
                            style={{
                                border: !options.readOnly && options.value ? `${input.metadata.borderWidth ? input.metadata.borderWidth : '2'}px solid ${input.metadata.borderColor ? input.metadata.borderColor : '#000'}` : null,
                            }}
                            onClick={fn.isFunction(options.onChange) && !options.readOnly ? (e) => { options.onChange(e, input.key, options.referenceObject) } : (e) => { e.preventDefault(); e.stopPropagation(); }}
                        >
                        </div>
                    </div>
                </div>
            </div>

        case 'Check':
            const width = input.position.w * ith.INTERNAL_FORM_TEMPLATE_CELL_WIDTH;
            const height = input.position.h * ith.INTERNAL_FORM_TEMPLATE_ROW_HEIGHT;
            const size = Math.min(width, height);

            return <div
                className={'element-wrapper element-input h-100 no-label'}
                data-id={input.id}
            >
                <div
                    className={'form-group no-label text-middle' + (options.value ? ' has-value' : '')}
                    onDoubleClick={options.onDoubleClick}
                    onContextMenu={options.onContextMenu}
                >
                    <div className='check-wrapper'>
                        <div
                            className={'check-input' + (!options.readOnly && options.value ? ' selected' : '')}
                            onClick={fn.isFunction(options.onChange) && !options.readOnly ? (e) => { options.onChange(e, input.key, options.referenceObject) } : (e) => { e.preventDefault(); e.stopPropagation(); }}
                        >

                            <img className={!options.readOnly && options.value ? '' : 'd-none'} src='/media/img/check.png' alt='check' width={size} height={size} />
                        </div>
                    </div>
                </div>
            </div>

        case 'Signature':
            return <div
                className={'element-wrapper element-input h-100 no-label'}
                data-id={input.id}
            >
                <div
                    className={'form-group no-label text-middle'}
                    onDoubleClick={options.onDoubleClick}
                    onContextMenu={options.onContextMenu}
                >
                </div>
            </div>

        default:
            break;
    }
}

export const getDefaultPosition = templateInput => {
    switch (templateInput.type) {
        case 'SingleLineText':
        case 'Dropdown':
            return { w: 12, h: 4 };

        case 'MultiLineText':
            return { w: 12, h: 6 };

        case 'Checkbox':
        case 'Circle':
        case 'Check':
            return { w: 4, h: 4 };

        case 'Signature':
            return { w: 8, h: 6 };

        default:
            return {};
    }
}