import React, { useContext, useEffect, useRef, useState } from 'react';
import FadeIn from 'react-fade-in';
import { Observer } from 'mobx-react-lite';
import { GlobalHotKeys } from 'react-hotkeys';
import moment from 'moment';

import { quickDrawerFocus, renderQuickDrawerLoading } from '../../../../../_shared/QuickDrawer';
import QuickDrawerHeader from '../../../../../_shared/QuickDrawerHeader';

import QuickDrawerStore from '../../../../../../../stores/QuickDrawerStore';
import UserCreateStore from '../../../../../../../stores/UserCreateStore';

import * as ErrorMessages from '../../../../../../../constants/errorMessages';
import * as fn from '../../../../../../../utilities/_functions';
import * as oh from '../../../../../../../utilities/operationHelper';

import './CreateUser.scss';
import { toast } from 'react-toastify';
import { getPasswordGuidelineHTML } from '../../../../../../../utilities/userHelper';

moment.locale('en');

function NewUser(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const userStore = useContext(UserCreateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [showPassword, setShowPassword] = useState(false);

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (userStore.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (fn.validateForm(validateRef.current)) {
            try {
                const data = await userStore.save(true);
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, data);
                    }
                }
            }
            catch (e) {
                if (JSON.stringify(e).toLowerCase().includes('passwordstrength')) {
                    toast.error(() => <p><strong>Password is too weak</strong>
                        {getPasswordGuidelineHTML()}
                    </p>, { position: 'top-center', autoClose: 15000, pauseOnHover: true });
                }
                else {
                    window.alert(e);
                }
            }
        }
    }

    const handleFirstNameChange = (event) => {
        userStore.data.firstName = event.target.value;
        userStore.hasUnsavedChanges = true;
    }

    const handleLastNameChange = (event) => {
        userStore.data.lastName = event.target.value;
        userStore.hasUnsavedChanges = true;
    }

    const handleEmailChange = (event) => {
        userStore.data.emailAddress = event.target.value;
        userStore.hasUnsavedChanges = true;
    }

    const handleProviderTypeChange = (event) => {
        userStore.data.providerType = event.target.value;
        userStore.hasUnsavedChanges = true;
    }

    const handleProviderNameChange = (event) => {
        userStore.data.providerName = event.target.value;
        userStore.hasUnsavedChanges = true;
    }

    const handleUsernameChange = (event) => {
        userStore.data.username = event.target.value;
        userStore.hasUnsavedChanges = true;
    }

    const handlePasswordChange = (event) => {
        userStore.data.password = event.target.value;
        userStore.hasUnsavedChanges = true;
    }

    const handleToggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    useEffect(() => {
        userStore.initialize();
        quickDrawerFocus(props.drawer);
        return () => {
            isMounted.current = false;
        }
    }, []) // eslint-disable-line

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <fieldset disabled={userStore.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('user', 'new')}
                        action='Add New'
                        category='User'
                        className='users'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        <Observer>{() =>
                            userStore.isReady ?
                                <FadeIn>
                                    <div className='update-user body-content'>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0 validate validate-required'>
                                                        <label className='required'><small>First Name</small></label>
                                                        <input
                                                            id='user-first-name'
                                                            type='text'
                                                            className='form-control'
                                                            maxLength='50'
                                                            autoComplete='off'
                                                            value={userStore.data.firstName}
                                                            onChange={handleFirstNameChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>

                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0 validate validate-required'>
                                                        <label className='required'><small>Last Name</small></label>
                                                        <input
                                                            id='user-last-name'
                                                            type='text'
                                                            className='form-control'
                                                            maxLength='50'
                                                            autoComplete='off'
                                                            value={userStore.data.lastName}
                                                            onChange={handleLastNameChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>

                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0 validate validate-required'>
                                                        <label className='required'><small>Email Address</small></label>
                                                        <input
                                                            id='user-email'
                                                            type='text'
                                                            className='form-control'
                                                            maxLength='150'
                                                            autoComplete='off'
                                                            value={userStore.data.emailAddress}
                                                            onChange={handleEmailChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>

                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0'>
                                                        <label><small>Identity Provider</small></label>
                                                        <select
                                                            id='user-identity-provider-type'
                                                            className='custom-select form-control'
                                                            value={userStore.data.providerType}
                                                            onChange={handleProviderTypeChange}
                                                        >
                                                            <option value='Social'>Social</option>
                                                            <option value='UsernamePassword'>Email and Password</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>

                                        {
                                            userStore.data.providerType === 'Social' ?
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-0'>
                                                                <label><small>Identity Provider</small></label>
                                                                <select
                                                                    id='user-identity-provider-name'
                                                                    className='custom-select form-control'
                                                                    value={userStore.data.providerName}
                                                                    onChange={handleProviderNameChange}
                                                                    disabled={true}
                                                                >
                                                                    <option value='Google'>Google</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section> :
                                                <>
                                                    <section>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className='form-group mb-0 validate validate-required'>
                                                                    <label className='required'><small>Username</small></label>
                                                                    <input
                                                                        id='user-username'
                                                                        type='text'
                                                                        className='form-control'
                                                                        maxLength='150'
                                                                        autoComplete='off'
                                                                        value={userStore.data.username}
                                                                        onChange={handleUsernameChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <section>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className='form-group mb-0'>
                                                                    <label><small>Password</small></label>
                                                                    <input
                                                                        id='user-password'
                                                                        type={showPassword ? 'text' : 'password'}
                                                                        className='form-control'
                                                                        maxLength='150'
                                                                        autoComplete='off'
                                                                        value={userStore.data.password}
                                                                        onChange={handlePasswordChange}
                                                                    />
                                                                </div>
                                                                <div className='form-group mb-0'>
                                                                    <div className='form-check'>
                                                                        <input
                                                                            id='is-showing-password'
                                                                            type="checkbox"
                                                                            name='is-showing-password'
                                                                            className='form-check-input'
                                                                            defaultChecked={false}
                                                                            onChange={handleToggleShowPassword}
                                                                        />
                                                                        <label className='form-check-label' htmlFor='is-showing-password'>Show Password</label>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </section>
                                                </>
                                        }
                                    </div>
                                </FadeIn> : renderQuickDrawerLoading()
                        }</Observer>
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form >
    </>
}

export default NewUser;