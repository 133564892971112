export const getDescription = (key, plural = false) => {
    const obj = operationMappings.some(m => m.key.toLowerCase() === key.toLowerCase()) ? operationMappings.filter(m => m.key.toLowerCase() === key.toLowerCase())[0] : null;
    return obj ? (plural ? obj.descriptionPlural : obj.description) : '';
}

export const getIcon = (key, action = 'default') => {
    const actions = operationMappings.some(m => m.key === key) ? operationMappings.filter(m => m.key === key)[0].actions : [];
    let obj = actions.filter(a => a.key === action)[0];

    if (!obj) {
        obj = actions.filter(a => a.key === 'default')[0];
    }

    return obj ? obj.icon : '';
}

const operationMappings = [
    {
        key: 'customer',
        description: 'Customer',
        descriptionPlural: 'Customers',
        actions: [
            { key: 'default', icon: 'fal fa-address-book' },
            { key: 'view', icon: 'fal fa-user' },
            { key: 'new', icon: 'fal fa-user-plus' },
            { key: 'deactivate', icon: 'fal fa-user-times' },
            { key: 'personal', icon: 'fal fa-user-edit' },
            { key: 'address', icon: 'fal fa-map-marked-alt' },
            { key: 'contact', icon: 'fal fa-phone-volume' },
            { key: 'patient', icon: 'fal fa-address-card' },
            { key: 'relationship', icon: 'fal fa-users' },
            { key: 'private-insurance', icon: 'fal fa-shield-check' },
            { key: 'government-program', icon: 'fal fa-university' },
        ],
    },
    {
        key: 'appointment',
        description: 'Appointment',
        descriptionPlural: 'Appointments',
        actions: [
            { key: 'default', icon: 'fal fa-calendar-alt' },
            { key: 'view', icon: 'fal fa-calendar-alt' },
            { key: 'new', icon: 'fal fa-calendar-plus' },
            { key: 'delete', icon: 'fal fa-calendar-times' },
            { key: 'update', icon: 'fal fa-calendar-edit' },
        ],
    },
    {
        key: 'pretest',
        description: 'Pretest',
        descriptionPlural: 'Pretests',
        actions: [
            { key: 'default', icon: 'fal fa-bullseye-pointer' },
        ],
    },
    {
        key: 'exam',
        description: 'Exam',
        descriptionPlural: 'Exams',
        actions: [
            { key: 'default', icon: 'fal fa-clipboard-list-check' },
            { key: 'view', icon: 'fal fa-paste' },
            { key: 'new', icon: 'fal fa-clipboard' },
            { key: 'delete', icon: 'fal fa-file-times' },
            { key: 'update', icon: 'fal fa-file-alt' },
            { key: 'prescription', icon: 'fal fa-file-prescription' },
            { key: 'prefill', icon: 'fal fa-clipboard-list-check' },
        ],
    },
    {
        key: 'prescription',
        description: 'Prescription',
        descriptionPlural: 'Prescriptions',
        actions: [
            { key: 'default', icon: 'fal fa-file-prescription' },
        ],
    },
    {
        key: 'invoice',
        description: 'Invoice',
        descriptionPlural: 'Invoices',
        actions: [
            { key: 'default', icon: 'fal fa-file-invoice-dollar' },
            { key: 'view', icon: 'fal fa-file-invoice-dollar' },
            { key: 'new', icon: 'fal fa-file-invoice' },
        ],
    },
    {
        key: 'payment-receipt',
        description: 'Payment Receipt',
        descriptionPlural: 'Payment Receipts',
        actions: [
            { key: 'default', icon: 'fal fa-file-invoice-dollar' },
            { key: 'view', icon: 'fal fa-file-invoice-dollar' },
            { key: 'new', icon: 'fal fa-file-invoice' },
        ],
    },
    {
        key: 'purchase',
        description: 'Purchase',
        descriptionPlural: 'Purchases',
        actions: [
            { key: 'default', icon: 'fal fa-shopping-bag' },
            { key: 'new', icon: 'fal fa-bags-shopping' },
            { key: 'payment', icon: 'fal fa-money-bill-wave' },
            { key: 'history', icon: 'fal fa-receipt' },
            { key: 'refund-purchase', icon: 'fal fa-file-import' },
            { key: 'refund-payment', icon: 'fal fa-hand-holding-usd' },
            { key: 'delete-payment', icon: 'fal fa-trash-alt' },
            { key: 'void-purchase', icon: 'fal fa-file-times' },
            { key: 'no-charge', icon: 'fab fa-creative-commons-nc' },
        ],
    },
    {
        key: 'todo',
        description: 'Task',
        descriptionPlural: 'Tasks',
        actions: [
            { key: 'default', icon: 'fal fa-tasks' },
            { key: 'new', icon: 'fal fa-tasks' },
        ],
    },
    {
        key: 'transaction',
        description: 'Transaction',
        descriptionPlural: 'Transactions',
        actions: [
            { key: 'default', icon: 'fal fa-exchange' },
        ],
    },
    {
        key: 'transaction-item',
        description: 'Transaction Item',
        descriptionPlural: 'Transaction Items',
        actions: [
            { key: 'default', icon: 'fal fa-exchange' },
            { key: 'take-from-inventory', icon: 'fal fa-inbox-out' },
            { key: 'return-to-inventory', icon: 'fal fa-inbox-in' },
        ],
    },
    {
        key: 'transaction-payment',
        description: 'Transaction Payment',
        descriptionPlural: 'Transaction Payments',
        actions: [
            { key: 'default', icon: 'fal fa-money-bill-wave' },
            { key: 'cash', icon: 'fal fa-money-bill' },
            { key: 'debit', icon: 'fal fa-credit-card-front' },
            { key: 'cheque', icon: 'fal fa-money-check-edit-alt' },
            { key: 'visa', icon: 'fal fa-cc-visa' },
            { key: 'mastercard', icon: 'fal fa-cc-mastercard' },
            { key: 'amex', icon: 'fal fa-cc-amex' },
            { key: 'preauthorized', icon: 'fal fa-retweet' },
            { key: 'private-insurance', icon: 'fal fa-envelope-open-dollar' },
            { key: 'government-program', icon: 'fal fa-envelope-open-dollar' },
            { key: 'account', icon: 'fal fa-box-usd' },
            { key: 'other', icon: 'fal fa-question-square' },
        ],
    },
    {
        key: 'work-order',
        description: 'Work Order',
        descriptionPlural: 'Work Orders',
        actions: [
            { key: 'default', icon: 'fal fa-inbox' },
            { key: 'link', icon: 'fal fa-link' },
        ],
    },
    {
        key: 'work-order-item',
        description: 'Work Order Item',
        descriptionPlural: 'Work Order Items',
        actions: [
            { key: 'default', icon: 'fal fa-inbox' },
        ],
    },
    {
        key: 'estimate-order',
        description: 'Estimate Order',
        descriptionPlural: 'Estimate Orders',
        actions: [
            { key: 'default', icon: 'fal fa-money-check-alt' },
        ],
    },
    {
        key: 'estimate-order-item',
        description: 'Estimate Order Item',
        descriptionPlural: 'Estimate Order Items',
        actions: [
            { key: 'default', icon: 'fal fa-money-check-alt' },
        ],
    },
    {
        key: 'private-insurance-provider',
        description: 'Insurance Provider',
        descriptionPlural: 'Insurance Providers',
        actions: [
            { key: 'default', icon: 'fal fa-shield-check' },
        ],
    },
    {
        key: 'external-clinic',
        description: 'External Clinic',
        descriptionPlural: 'External Clinics',
        actions: [
            { key: 'default', icon: 'fal fa-clinic-medical' },
        ],
    },
    {
        key: 'external-doctor',
        description: 'External Doctor',
        descriptionPlural: 'External Doctors',
        actions: [
            { key: 'default', icon: 'fal fa-user-md' },
        ],
    },
    {
        key: 'product',
        description: 'Product',
        descriptionPlural: 'Products',
        actions: [
            { key: 'default', icon: 'fal fa-box-check' },
            { key: 'new', icon: 'fal fa-box' },
            { key: 'update', icon: 'fal fa-box' },
            { key: 'delete', icon: 'fal fa-box-open' },
            { key: 'brand', icon: 'fal fa-star-exclamation' },
            { key: 'supplier', icon: 'fal fa-truck' },
            { key: 'type', icon: 'fal fa-ballot' },
        ],
    },
    {
        key: 'service',
        description: 'Service',
        descriptionPlural: 'Services',
        actions: [
            { key: 'default', icon: 'fal fa-box-check' },
            { key: 'new', icon: 'fal fa-user-md-chat' },
            { key: 'update', icon: 'fal fa-user-md-chat' },
            { key: 'delete', icon: 'fal fa-box-open' },
            { key: 'brand', icon: 'fal fa-star-exclamation' },
            { key: 'supplier', icon: 'fal fa-truck' },
            { key: 'type', icon: 'fal fa-ballot' },
        ],
    },
    {
        key: 'note',
        description: 'Note',
        descriptionPlural: 'Notes',
        actions: [
            { key: 'default', icon: 'fal fa-comment-alt-lines' },
            { key: 'attachment', icon: 'fal fa-paperclip' },
        ],
    },
    {
        key: 'schedule',
        description: 'Schedule',
        descriptionPlural: 'Schedules',
        actions: [
            { key: 'default', icon: 'fal fa-clock' },
            { key: 'new', icon: 'fal fa-clock' },
            { key: 'timeoff', icon: 'fal fa-alarm-snooze' },
        ],
    },
    {
        key: 'system',
        description: 'System',
        descriptionPlural: 'Systems',
        actions: [
            { key: 'default', icon: 'fal fa-cog' },
        ],
    },
    {
        key: 'exam-template',
        description: 'Exam Template',
        descriptionPlural: 'Exam Templates',
        actions: [
            { key: 'default', icon: 'fal fa-file-code' },
        ],
    },
    {
        key: 'exam-section',
        description: 'Exam Template',
        descriptionPlural: 'Exam Templates',
        actions: [
            { key: 'default', icon: 'fal fa-folder' },
            { key: 'add', icon: 'fal fa-folder-plus' },
            { key: 'update', icon: 'fal fa-folder' },
            { key: 'remove', icon: 'fal fa-folder-minus' },
            { key: 'reorder', icon: 'fal fa-sort-size-down-alt' },
        ],
    },
    {
        key: 'exam-input',
        description: 'Exam Input',
        descriptionPlural: 'Exam Inputs',
        actions: [
            { key: 'default', icon: 'fal fa-window-restore' },
            { key: 'delete', icon: 'fal fa-window-close' },
            { key: 'text', icon: 'fal fa-font-case' },
            { key: 'control', icon: 'fal fa-network-wired' },
        ],
    },
    {
        key: 'work-order-template',
        description: 'Work-Order Template',
        descriptionPlural: 'Order Templates',
        actions: [
            { key: 'default', icon: 'fal fa-file-code' },
        ],
    },
    {
        key: 'work-order-input',
        description: 'Work Order Input',
        descriptionPlural: 'Order Inputs',
        actions: [
            { key: 'default', icon: 'fal fa-window-restore' },
            { key: 'delete', icon: 'fal fa-window-close' },
            { key: 'text', icon: 'fal fa-font-case' },
            { key: 'datasource', icon: 'fal fa-database' },
        ],
    },
    {
        key: 'prescription-template',
        description: 'Prescription Template',
        descriptionPlural: 'Prescription Templates',
        actions: [
            { key: 'default', icon: 'fal fa-file-code' },
        ],
    },
    {
        key: 'prescription-input',
        description: 'Prescription Input',
        descriptionPlural: 'Prescription Inputs',
        actions: [
            { key: 'default', icon: 'fal fa-window-restore' },
            { key: 'delete', icon: 'fal fa-window-close' },
            { key: 'text', icon: 'fal fa-font-case' },
            { key: 'datasource', icon: 'fal fa-database' },
        ],
    },
    {
        key: 'pretest-template',
        description: 'Pretest Template',
        descriptionPlural: 'Pretest Templates',
        actions: [
            { key: 'default', icon: 'fal fa-file-code' },
        ],
    },
    {
        key: 'pretest-input',
        description: 'Pretest Input',
        descriptionPlural: 'Pretest Inputs',
        actions: [
            { key: 'default', icon: 'fal fa-window-restore' },
            { key: 'delete', icon: 'fal fa-window-close' },
            { key: 'text', icon: 'fal fa-font-case' },
            { key: 'datasource', icon: 'fal fa-database' },
            { key: 'control', icon: 'fal fa-network-wired' },
        ],
    },
    {
        key: 'communication',
        description: 'Communication',
        descriptionPlural: 'Communications',
        actions: [
            { key: 'fax', icon: 'fal fa-fax' },
            { key: 'sms', icon: 'fal fa-comments-alt' },
            { key: 'email', icon: 'fal fa-envelope' },
        ]
    },
    {
        key: 'form-template',
        description: 'Form Template',
        descriptionPlural: 'Form Templates',
        actions: [
            { key: 'default', icon: 'fal fa-file-code' },
        ],
    },
    {
        key: 'form-page',
        description: 'Form Page',
        descriptionPlural: 'Form Pages',
        actions: [
            { key: 'default', icon: 'fal fa-folder' },
            { key: 'add', icon: 'fal fa-folder-plus' },
            { key: 'update', icon: 'fal fa-folder' },
            { key: 'remove', icon: 'fal fa-folder-minus' },
            { key: 'reorder', icon: 'fal fa-sort-size-down-alt' },
        ],
    },
    {
        key: 'form-input',
        description: 'Form Input',
        descriptionPlural: 'Form Inputs',
        actions: [
            { key: 'default', icon: 'fal fa-window-restore' },
            { key: 'delete', icon: 'fal fa-window-close' },
            { key: 'text', icon: 'fal fa-font-case' },
            { key: 'control', icon: 'fal fa-network-wired' },
        ],
    },
    {
        key: 'internal-form',
        description: 'Internal Form',
        descriptionPlural: 'Internal Forms',
        actions: [
            { key: 'default', icon: 'fal fa-file-spreadsheet' },
        ],
    },
    {
        key: 'internal-form-template',
        description: 'Form Template',
        descriptionPlural: 'Form Templates',
        actions: [
            { key: 'default', icon: 'fal fa-file-code' },
        ],
    },
    {
        key: 'internal-form-page',
        description: 'Form Page',
        descriptionPlural: 'Form Pages',
        actions: [
            { key: 'default', icon: 'fal fa-folder' },
            { key: 'add', icon: 'fal fa-folder-plus' },
            { key: 'update', icon: 'fal fa-folder' },
            { key: 'remove', icon: 'fal fa-folder-minus' },
            { key: 'reorder', icon: 'fal fa-sort-size-down-alt' },
        ],
    },
    {
        key: 'internal-form-input',
        description: 'Form Input',
        descriptionPlural: 'Form Inputs',
        actions: [
            { key: 'default', icon: 'fal fa-window-restore' },
            { key: 'delete', icon: 'fal fa-window-close' },
            { key: 'text', icon: 'fal fa-font-case' },
            { key: 'control', icon: 'fal fa-network-wired' },
        ],
    },
    {
        key: 'referral',
        description: 'Referral',
        descriptionPlural: 'Referrals',
        actions: [
            { key: 'default', icon: 'fal fa-hospital-user' },
        ],
    },
    {
        key: 'referral-template',
        description: 'Referral Template',
        descriptionPlural: 'Referral Templates',
        actions: [
            { key: 'default', icon: 'fal fa-file-code' },
        ],
    },
    {
        key: 'referral-page',
        description: 'Referral Page',
        descriptionPlural: 'Referral Pages',
        actions: [
            { key: 'default', icon: 'fal fa-folder' },
            { key: 'add', icon: 'fal fa-folder-plus' },
            { key: 'update', icon: 'fal fa-folder' },
            { key: 'remove', icon: 'fal fa-folder-minus' },
            { key: 'reorder', icon: 'fal fa-sort-size-down-alt' },
        ],
    },
    {
        key: 'referral-input',
        description: 'Referral Input',
        descriptionPlural: 'Referral Inputs',
        actions: [
            { key: 'default', icon: 'fal fa-window-restore' },
            { key: 'delete', icon: 'fal fa-window-close' },
            { key: 'text', icon: 'fal fa-font-case' },
            { key: 'control', icon: 'fal fa-network-wired' },
        ],
    },
    {
        key: 'medical-report',
        description: 'Medical Report',
        descriptionPlural: 'Medical Reports',
        actions: [
            { key: 'default', icon: 'fal fa-notes-medical' },
        ],
    },
    {
        key: 'medical-report-template',
        description: 'Medical Report Template',
        descriptionPlural: 'Medical Report Templates',
        actions: [
            { key: 'default', icon: 'fal fa-file-code' },
        ],
    },
    {
        key: 'medical-report-page',
        description: 'Medical Report Page',
        descriptionPlural: 'Medical Report Pages',
        actions: [
            { key: 'default', icon: 'fal fa-folder' },
            { key: 'add', icon: 'fal fa-folder-plus' },
            { key: 'update', icon: 'fal fa-folder' },
            { key: 'remove', icon: 'fal fa-folder-minus' },
            { key: 'reorder', icon: 'fal fa-sort-size-down-alt' },
        ],
    },
    {
        key: 'medical-report-input',
        description: 'Medical Report Input',
        descriptionPlural: 'Medical Report Inputs',
        actions: [
            { key: 'default', icon: 'fal fa-window-restore' },
            { key: 'delete', icon: 'fal fa-window-close' },
            { key: 'text', icon: 'fal fa-font-case' },
            { key: 'control', icon: 'fal fa-network-wired' },
        ],
    },
    {
        key: 'exam-dataset',
        description: 'Exam Dataset',
        descriptionPlural: 'Exam Datasets',
        actions: [
            { key: 'default', icon: 'fal fa-database' },
            { key: 'delete', icon: 'fal fa-database' },
        ],
    },
    {
        key: 'prescription-print-template',
        description: 'Prescription Print Template',
        descriptionPlural: 'Prescription Print Templates',
        actions: [
            { key: 'default', icon: 'fal fa-file-code' },
        ],
    },
    {
        key: 'business-hours',
        description: 'Business Hours',
        descriptionPlural: 'Business Hours',
        actions: [
            { key: 'default', icon: 'fal fa-business-time' },
        ],
    },
    {
        key: 'user',
        description: 'User',
        descriptionPlural: 'Users',
        actions: [
            { key: 'new', icon: 'fal fa-user-plus' },
        ],
    },
    {
        key: 'group',
        description: 'group',
        descriptionPlural: 'Groups',
        actions: [
            { key: 'new', icon: 'fal fa-users' },
            { key: 'update', icon: 'fal fa-users' },
            { key: 'delete', icon: 'fal fa-users' },
        ],
    },
    {
        key: 'tenant',
        description: 'tenant',
        descriptionPlural: 'Tenants',
        actions: [
            { key: 'new', icon: 'fal fa-house-user' },
            { key: 'update', icon: 'fal fa-house-user' },
            { key: 'delete', icon: 'fal fa-house-user' },
        ],
    },
    {
        key: 'organization',
        description: 'organization',
        descriptionPlural: 'Organizations',
        actions: [
            { key: 'new', icon: 'fal fa-building' },
            { key: 'update', icon: 'fal fa-building' },
            { key: 'delete', icon: 'fal fa-building' },
        ],
    },
    {
        key: 'contact',
        description: 'contact',
        descriptionPlural: 'contacts',
        actions: [
            { key: 'default', icon: 'fal fa-book-user' },
        ],
    },
    {
        key: 'subscription',
        description: 'Subscription',
        descriptionPlural: 'Subscription',
        actions: [
            { key: 'default', icon: 'fal fa-book' },
        ],
    }
]
