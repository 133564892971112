import React, { useContext, useEffect, useRef, useState } from 'react';
import FadeIn from 'react-fade-in';
import { Multiselect } from 'react-widgets';
import { Observer } from 'mobx-react-lite';
import { GlobalHotKeys } from 'react-hotkeys';
import moment from 'moment';

import BodyEnd from '../../../../../_shared/BodyEnd';
import ConfirmModal from '../../../../../_shared/ConfirmModal';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../../../../_shared/QuickDrawer';
import QuickDrawerHeader from '../../../../../_shared/QuickDrawerHeader';

import QuickDrawerStore from '../../../../../../../stores/QuickDrawerStore';
import GroupCreateStore from '../../../../../../../stores/GroupCreateUpdateStore';
import CacheStore from '../../../../../../../stores/CacheStore';

import * as ErrorMessages from '../../../../../../../constants/errorMessages';
import * as fn from '../../../../../../../utilities/_functions';
import * as oh from '../../../../../../../utilities/operationHelper';

import './CreateUpdateGroup.scss';

moment.locale('en');

function CreateUpdateGroup(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const cache = useContext(CacheStore);
    const groupStore = useContext(GroupCreateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const USER_COLOR = '#00acc1';
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [confirmDeleteGroup, setConfirmDeleteGroup] = useState(null);

    const mapUsersToList = (srcUsers, addAllUser) => {
        const users = srcUsers.map(s => ({
            id: s.id,
            name: `${s.firstName || ''} ${s.lastName || ''}`,
            colorHexValue: USER_COLOR,
            type: 'user'
        }));

        var multSelectItems = [...users.sort((a, b) => a.name.localeCompare(b.name))];
        if (addAllUser)
            multSelectItems.push({ id: "@all", name: "@all", colorHexValue: '#F95D3C' });

        return multSelectItems;
    }

    const updateControls = (item) => {
        const groupMembers = mapUsersToList(item.users);
        setSelectedUsers(groupMembers);
    }

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (groupStore.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (selectedUsers.some(u => u.id === '@all')) {
            groupStore.data.users = cache.activeUsers.map(u => u.id);
        }
        else {
            groupStore.data.users = [...(selectedUsers.map(u => u.id))];
        }
        if (fn.validateForm(validateRef.current)) {
            try {
                const data = await groupStore.save(true);
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, data);
                    }
                }
            }
            catch (e) {
                // The backend may return 409 if there's a name conflict
                window.alert(e);
                // if (props.onCancel && fn.isFunction(props.onCancel)) {
                //     props.onCancel();
                // }
            }
        }
    }

    const handleNameChange = (event) => {
        groupStore.data.name = event.target.value;
        groupStore.hasUnsavedChanges = true;
    }

    const handleDisplayOrderChange = (event) => {
        groupStore.data.displayOrder = event.target.value;
        groupStore.hasUnsavedChanges = true;
    }

    const handleUserSelection = (tag, metadata) => {
        if (JSON.stringify(selectedUsers) !== JSON.stringify(tag)) {
            groupStore.hasUnsavedChanges = true;
            setSelectedUsers(tag);
        }
    }

    const allUsers = () => {
        return mapUsersToList(cache.activeUsers, true);
    }

    useEffect(() => {
        const item = props.extraProps;
        groupStore.initialize(item);
        if (item) {
            updateControls(item);
        }
        quickDrawerFocus(props.drawer);

        return () => {
            isMounted.current = false;
            groupStore.clear();
        }
    }, []) // eslint-disable-line

    const handleDeleteGroup = (id) => {
        setConfirmDeleteGroup(id);
    }

    const handleConfirmDeleteGroups = async (e) => {
        await groupStore.delete([{ id: confirmDeleteGroup }]);
        if (isMounted.current) {
            setConfirmDeleteGroup(null);
            if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                props.onSuccess(e);
            }
        }
    }

    const handleConfirmDeleteGroupCancel = () => {
        setConfirmDeleteGroup(null);
    }


    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
                <form ref={validateRef} onSubmit={handleSubmit}>
                    <fieldset disabled={groupStore.isSaving}>
                        <div className='quick-drawer'>
                            <QuickDrawerHeader
                                drawer={props.drawer}
                                icon={oh.getIcon('group', 'new')}
                                action={props.extraProps ? 'Update' : 'Add new'}
                                category='Group'
                                className='groups'
                                onCancel={handleCancel}
                            />
                            <div className='quick-drawer-body'>
                                {
                                    groupStore.isReady ?
                                        <FadeIn>
                                            <div className='update-group body-content'>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-0 validate validate-required'>
                                                                <label className='required'><small>Group Name</small></label>
                                                                <input
                                                                    id='group-name'
                                                                    type='text'
                                                                    className='form-control'
                                                                    maxLength='50'
                                                                    autoComplete='off'
                                                                    value={groupStore.data.name}
                                                                    onChange={handleNameChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>

                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-0 validate validate-required'>
                                                                <label className='required'><small>Display Order</small></label>
                                                                <input
                                                                    id='group-display-order'
                                                                    type='number'
                                                                    className='form-control'
                                                                    maxLength='50'
                                                                    autoComplete='off'
                                                                    value={groupStore.data.displayOrder}
                                                                    onChange={handleDisplayOrderChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>

                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className={'form-group validate validate-required'}>
                                                                <label className='required'><small>Group Members</small></label>
                                                                <Multiselect
                                                                    allowCreate={false}
                                                                    data={allUsers()}
                                                                    valueField='id'
                                                                    textField='name'
                                                                    defaultValue={[]}
                                                                    value={selectedUsers}
                                                                    tagComponent={({ item }) => (
                                                                        <span
                                                                            className='tag'
                                                                            style={{
                                                                                backgroundColor: item.colorHexValue,
                                                                                borderColor: item.colorHexValue,
                                                                            }}
                                                                        >
                                                                            <strong>{item.name}</strong>
                                                                        </span>
                                                                    )}
                                                                    onChange={handleUserSelection}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>

                                            </div>
                                        </FadeIn> : renderQuickDrawerLoading()
                                }
                            </div>
                            <div className='quick-drawer-action'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='float-right'>
                                            {
                                                props.extraProps &&
                                                <button
                                                    type='button'
                                                    className='btn btn-danger mr-4'
                                                    onClick={() => handleDeleteGroup(props.extraProps.id)}
                                                >Delete</button>
                                            }
                                            <button
                                                type='button'
                                                className='btn btn-link btn-cancel mr-2'
                                                onClick={handleCancel}
                                            >Cancel</button>
                                            <button
                                                type='submit'
                                                className='btn btn-success'
                                            >Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form >
                <BodyEnd>
                    <ConfirmModal
                        icon={<i className={'text-danger mr-2 ' + oh.getIcon('group', 'delete')}></i>}
                        message={<>Continue to delete <span className='fw-500'>&nbsp;{groupStore.data ? groupStore.data.name : ''}</span>? </>}
                        descriptionClassName='warning-danger warning-flashing'
                        show={confirmDeleteGroup}
                        onOption1Click={handleConfirmDeleteGroups}
                        onCancel={handleConfirmDeleteGroupCancel}
                    />
                </BodyEnd>
            </>
        }</Observer>
    </>
}

export default CreateUpdateGroup;
