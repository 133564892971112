import React, { useEffect, useContext, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { useObserver } from 'mobx-react-lite';
import { GlobalHotKeys } from 'react-hotkeys';
import moment from 'moment';

import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';

import PreviousExamSelectStore from '../../../../stores/PreviousExamSelectStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as fn from '../../../../utilities/_functions';
import * as bh from '../../../../utilities/badgeHelper'
import * as oh from '../../../../utilities/operationHelper';

import './PreviousExam.scss';

function PreviousExam(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const previousExam = useContext(PreviousExamSelectStore);
    const quickDrawer = useContext(QuickDrawerStore);

    useEffect(() => {
        quickDrawerFocus(props.drawer);
        return () => {
            isMounted.current = false;
        }
    }, []) // eslint-disable-line

    const handleSubmit = event => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            previousExam.save()
                .then(data => {
                    if (isMounted.current) {
                        if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                            props.onSuccess(event, data);
                        }
                    }
                })
        }
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                props.onCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <fieldset disabled={previousExam.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('exam')}
                        action='Copy from a'
                        category='Previous Exam'
                        className='exams'
                        onCancel={props.onCancel}
                    />
                    <div className='quick-drawer-body'>
                        {
                            previousExam.isReady ?
                                <FadeIn>
                                    <div className='previous-exam body-content'>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0 validate validate-required'>
                                                        <label className='required'><small>Select an exam</small></label>
                                                        {
                                                            previousExam.dataSets && previousExam.dataSets.length > 0 ?
                                                                previousExam.dataSets.map((d, di) => {
                                                                    return <div
                                                                        key={`previous-exam-${di}`}
                                                                        className='custom-control custom-radio mb-3'
                                                                    >
                                                                        <input
                                                                            id={`previousExam-${di}`}
                                                                            type='radio'
                                                                            name='previousExam'
                                                                            className='custom-control-input'
                                                                            value={d.id}
                                                                            checked={previousExam.selectedId === d.id}
                                                                            onChange={() => { previousExam.selectedId = d.id }}
                                                                        />
                                                                        <label
                                                                            htmlFor={`previousExam-${di}`}
                                                                            className='custom-control-label'
                                                                        >
                                                                            {fn.formatFullDateWithOrWithoutYear(moment.utc(d.scheduledStartUtc).local())}<br />
                                                                            <div className='d-inline-block'>{d.resource.fullName}</div>
                                                                            {
                                                                                d.appointment && d.appointment.services && d.appointment.services.length > 0 ?
                                                                                    <>
                                                                                        {
                                                                                            d.appointment.services.map((s, si) => {
                                                                                                return <span
                                                                                                    key={`previous-exam-service-${si}`}
                                                                                                >
                                                                                                    {bh.renderServiceCode(s, 'ml-1')}
                                                                                                </span>
                                                                                            })
                                                                                        }
                                                                                    </> : null
                                                                            }
                                                                        </label>
                                                                    </div>
                                                                }) : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </FadeIn> : renderQuickDrawerLoading()
                        }
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={props.onCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                    >Select</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>)
}

export default PreviousExam;