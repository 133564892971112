import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { toJS } from 'mobx';
import { Observer } from 'mobx-react-lite';
import FadeIn from 'react-fade-in';
import momentLocalizer from 'react-widgets-moment';
import { GlobalHotKeys } from 'react-hotkeys';
import { DateTimePicker, DropdownList, Multiselect } from 'react-widgets'
import { toast } from 'react-toastify';
import moment, { isMoment } from 'moment';

import BodyEnd from '../../_shared/BodyEnd';
import ConfirmModal from '../../_shared/ConfirmModal';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import RichTextEditor from '../../_shared/RichTextEditor';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';

import AppointmentUpdateStore from '../../../../stores/AppointmentUpdateStore';
import GroupAppointmentCreateStore from '../../../../stores/GroupAppointmentCreateStore';
import AuthStore from '../../../../stores/AuthStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';
import SettingStore from '../../../../stores/SettingStore';
import CacheStore from '../../../../stores/CacheStore';

import * as rts from '../../../../constants/routes';
import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as ah from '../../../../utilities/addressHelper';
import * as ch from '../../../../utilities/customerHelper';
import * as ph from '../../../../utilities/personHelper';
import * as sys from '../../../../utilities/systemHelper';
import * as oh from '../../../../utilities/operationHelper';
import * as bh from '../../../../utilities/badgeHelper';

import api from '../../../../api';

import './UpdateAppointment.scss';

moment.locale('en');
momentLocalizer();

function UpdateAppointment(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const startTimePickerTimer = useRef(null);
    const endTimePickerTimer = useRef(null);
    const cache = useContext(CacheStore);
    const appointment = useContext(AppointmentUpdateStore);
    const groupAppointment = useContext(GroupAppointmentCreateStore);
    const auth = useContext(AuthStore);
    const setting = useContext(SettingStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [services, setServices] = useState([]);
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [startTimePickerOpen, setStartTimePickerOpen] = useState(false);
    const [endTimePickerOpen, setEndTimePickerOpen] = useState(false);
    const [conflicts, setConflicts] = useState(false);
    const [confirmConflicts, setConfirmConflicts] = useState(false);
    const [eligibles, setEligibles] = useState(null);
    const [ineligibles, setIneligibles] = useState(null);
    const [confirmIneligibles, setConfirmIneligibles] = useState(false);
    const [originalServices, setOriginalServices] = useState([]);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        quickDrawerFocus(props.drawer);
        checkEligibility();
        checkConflicts();

        api.Services.all()
            .then(({ data }) => {
                if (isMounted.current) {
                    if (data) {
                        setServices(data);
                    }
                }
            })
            .finally(() => {
                if (isMounted.current) {
                    setIsReady(true);
                }
            })

        return () => {
            isMounted.current = false;
            if (startTimePickerTimer.current) { clearTimeout(startTimePickerTimer.current); startTimePickerTimer.current = null; }
            if (endTimePickerTimer.current) { clearTimeout(endTimePickerTimer.current); endTimePickerTimer.current = null; }
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        if (appointment.isReady && appointment.data.services) {
            setOriginalServices(toJS(appointment.data.services));
        }
    }, [appointment.isReady]) // eslint-disable-line

    const checkConflicts = () => {
        appointment.checkConflicts()
            .then(conflicts => {
                if (isMounted.current) {
                    setConflicts(conflicts);
                }
            })
    }

    const checkEligibility = () => {
        if (appointment.data && appointment.data.customer && appointment.data.services && appointment.data.services.length > 0) {
            appointment.checkEligibility()
                .then(data => {
                    if (isMounted.current) {
                        const eligibleData = data && data.some(d => d.isPublicHealthChecked && d.isEligible) ? data.filter(d => d.isPublicHealthChecked && d.isEligible) : null;
                        const ineligibleData = data && data.some(d => !d.isPublicHealthChecked || !d.isEligible) ? data.filter(d => !d.isPublicHealthChecked || !d.isEligible) : null;
                        setEligibles(eligibleData);
                        setIneligibles(ineligibleData);
                    }
                })
        } else {
            setEligibles(null);
            setIneligibles(null);
        }
    }

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (appointment.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleRescheduleAppointment = () => {
        const stageId = appointment.id;
        appointment.stage(stageId);
        appointment.hasUnsavedChanges = true;
        quickDrawer.deactivateAll();
    }

    const handleResourceChange = ({ id }) => {
        appointment.data.userId = id;
        appointment.hasUnsavedChanges = true;
        checkConflicts();
    }

    const handleDatePickerDisplay = event => {
        setStartTimePickerOpen(false);
        setEndTimePickerOpen(false);
        if (!datePickerOpen) {
            setDatePickerOpen('date');
        }
    }

    const handleDatePickerChange = value => {
        const newStart = moment(`${moment(value).format('YYYY-MM-DD')} ${appointment.start.format('h:mm a')}`, 'YYYY-MM-DD h:mm a');
        const newEnd = newStart.clone().add(appointment.data.duration, 'minutes');

        appointment.data.start = newStart.format('YYYY-MM-DDTHH:mm:ss');
        appointment.data.utcOffset = newStart.utcOffset() / 60;
        appointment.data.end = newEnd.format('YYYY-MM-DDTHH:mm:ss');
        appointment.hasUnsavedChanges = true;

        checkConflicts();
        checkEligibility();
        setDatePickerOpen(false);
    }

    const handleStartTimePickerDisplay = event => {
        setDatePickerOpen(false);
        setEndTimePickerOpen(false);
        if (!startTimePickerOpen) {
            setStartTimePickerOpen('time');
            startTimePickerTimer.current = setTimeout(() => {
                const picker = document.querySelector('#startTimePicker .rw-state-selected');
                if (picker) {
                    picker.focus();
                }  // UI HACK: need to pass focus to the selected item otherwise mouse scroll and then click will cause focus to trigger.
            }, 300)
        }
    }

    const handleStartTimePickerChange = value => {
        const newStart = moment(`${appointment.start.format('YYYY-MM-DD')} ${moment(value).format('h:mm a')}`, 'YYYY-MM-DD h:mm a');
        const newEnd = newStart.clone().add(appointment.data.duration, 'minutes');

        appointment.data.start = newStart.format('YYYY-MM-DDTHH:mm:ss');
        appointment.data.utcOffset = newStart.utcOffset() / 60;
        appointment.data.end = newEnd.format('YYYY-MM-DDTHH:mm:ss');
        appointment.hasUnsavedChanges = true;

        checkConflicts();
        setStartTimePickerOpen(false);
    }

    const handleEndTimePickerDisplay = event => {
        setDatePickerOpen(false);
        setStartTimePickerOpen(false);
        if (!endTimePickerOpen) {
            setEndTimePickerOpen('time');
            endTimePickerTimer.current = setTimeout(() => {
                const picker = document.querySelector('#endTimePicker .rw-state-selected');
                if (picker) {
                    picker.focus();
                }  // UI HACK: need to pass focus to the selected item otherwise mouse scroll and then click will cause focus to trigger.
            }, 300)
        }
    }

    const handleEndTimePickerChange = value => {
        const start = appointment.start.clone();
        const newEnd = moment(`${appointment.end.format('YYYY-MM-DD')} ${moment(value).format('h:mm a')}`, 'YYYY-MM-DD h:mm a');
        const duration = newEnd.diff(start, 'minutes');

        appointment.data.end = newEnd.format('YYYY-MM-DDTHH:mm:ss');
        appointment.data.duration = duration;
        appointment.hasUnsavedChanges = true;

        checkConflicts();
        setEndTimePickerOpen(false);
    }

    const handleDurationChange = event => {
        appointment.data.duration = parseInt(event.target.value, 10);
        appointment.data.end = appointment.start.clone().add(appointment.data.duration, 'minutes');
        appointment.hasUnsavedChanges = true;
        checkConflicts();
    }

    const handleServiceChange = (tag, metadata) => {
        switch (metadata.action) {
            case 'insert':
                if (!appointment.data.services.some(s => s.id === metadata.dataItem.id)) {
                    appointment.data.services.push({
                        id: metadata.dataItem.id,
                        code: metadata.dataItem.code,
                        name: metadata.dataItem.code,
                        isSubsidized: metadata.dataItem.isSubsidized,
                        duration: metadata.dataItem.duration,
                    });
                }
                break;

            case 'remove':
                const index = appointment.data.services.findIndex(s => s.id === metadata.dataItem.id);
                if (index !== -1) {
                    appointment.data.services.splice(index, 1);
                }
                break;

            default:
                break;
        }

        appointment.hasUnsavedChanges = true;
        checkConflicts();
        checkEligibility();
    }

    const handleRequestByChange = event => {
        appointment.rescheduleRequestBy = event.target.value;
        appointment.hasUnsavedChanges = true;
    }

    const handleReasonChange = (content, delta, source, editor) => {
        const reason = editor.getText().replace(/(\r\n|\n|\r)/g, ' ').trim();
        const reasonHtml = (content === '<p><br></p>') ? null : content;

        appointment.rescheduleRequestReason = reason;
        appointment.rescheduleRequestReasonHtml = reasonHtml;
        appointment.hasUnsavedChanges = true;
    }

    const handleRepeatUpdateFollowingChange = event => {
        const repeatUpdateFollowing = event.target.value === 'true';

        appointment.repeatUpdateFollowing = repeatUpdateFollowing;

        if (repeatUpdateFollowing) {
            const start = isMoment(appointment.data.start) ? appointment.data.start.clone() : moment(appointment.data.start);
            const remaining = appointment.data.repeatTotal - appointment.data.repeatIndex;
            let until;

            switch (appointment.data.repeat.toLowerCase()) {
                case 'weekly':
                    until = start.clone().add(remaining, 'weeks');
                    break;

                case 'biweekly':
                    until = start.clone().add((remaining * 2), 'weeks');
                    break;

                case 'monthly':
                    until = start.clone().add(remaining, 'months');
                    break;

                case 'yearly':
                    until = start.clone().add(remaining, 'years');
                    break;

                default:
                    break;
            }
            appointment.repeatUpdateRemaining = remaining;
            appointment.repeatUpdateUntil = until;
        } else {
            appointment.repeatUpdateRemaining = null;
            appointment.repeatUpdateUntil = null;
        }

        appointment.hasUnsavedChanges = true;
    }

    const handleRepeatUpdateUtilChange = value => {
        let remaining = appointment.data.repeatTotal - appointment.data.repeatIndex;
        const start = isMoment(appointment.data.start) ? appointment.data.start.clone() : moment(appointment.data.start);
        const until = value ? moment(value) : start.clone();

        if (appointment.data.repeat) {

            switch (appointment.data.repeat.toLowerCase()) {
                case 'weekly':
                    remaining = Math.floor(until.clone().diff(start.clone(), 'week'));
                    break;

                case 'biweekly':
                    remaining = Math.floor((until.clone().diff(start.clone(), 'week')) / 2);
                    break;

                case 'monthly':
                    remaining = Math.floor(until.clone().diff(start.clone(), 'month'));
                    break;

                case 'yearly':
                    remaining = Math.floor(until.clone().diff(start.clone(), 'year'));
                    break;

                default:
                    break;
            }
        }

        appointment.repeatUpdateUntil = until;
        appointment.repeatUpdateRemaining = remaining;
        appointment.hasUnsavedChanges = true;
    }

    const handleRepeatUpdateRemainingChange = event => {
        let until = null;
        const remaining = event.target.value || event.target.value === '0' ? parseInt(event.target.value) : 0;

        if ((remaining || remaining === 0) && appointment.repeatUpdateFollowing) {
            const start = isMoment(appointment.data.start) ? appointment.data.start.clone() : moment(appointment.data.start);

            switch (appointment.data.repeat.toLowerCase()) {
                case 'weekly':
                    until = start.clone().add(remaining, 'weeks');
                    break;

                case 'biweekly':
                    until = start.clone().add((remaining * 2), 'weeks');
                    break;

                case 'monthly':
                    until = start.clone().add(remaining, 'months');
                    break;

                case 'yearly':
                    until = start.clone().add(remaining, 'years');
                    break;

                default:
                    break;
            }
        }

        appointment.repeatUpdateUntil = until;
        appointment.repeatUpdateRemaining = remaining;
        appointment.hasUnsavedChanges = true;
    }

    const handleSubmit = event => {
        event.preventDefault();

        const hasInvalidSubsidizedCode = getHasInvalidSubsidizedCodes();

        if (hasInvalidSubsidizedCode) {
            toast.error(() => ErrorMessages.GENERIC_ERROR_HTML, { position: 'top-center' });
        }
        else if (fn.validateForm(validateRef.current)) {
            appointment.checkEligibility(true)
                .then(eligibilityData => {
                    if (isMounted.current) {
                        const eligibleData = eligibilityData && eligibilityData.some(d => d.isEligible) ? eligibilityData.filter(d => d.isEligible) : null;
                        const ineligibleData = eligibilityData && eligibilityData.some(d => !d.isEligible) ? eligibilityData.filter(d => !d.isEligible) : null;

                        setEligibles(eligibleData);
                        setIneligibles(ineligibleData);

                        if (!ineligibleData) {
                            appointment.checkConflicts(true)
                                .then(conflictData => {
                                    if (isMounted.current) {
                                        if (!conflictData) {
                                            handleCommitUpdate(event);
                                        } else {
                                            setConfirmConflicts(true);
                                        }
                                    }
                                })
                        } else {
                            setConfirmIneligibles(true);
                        }
                    }
                })
        }
    }

    const handleCommitUpdate = event => {
        appointment.save(true)
            .then(() => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, { updated: true, data: { id: appointment.id } });
                    }
                }
            })
    }

    const handleIneligibleConfirm = event => {
        appointment.checkConflicts(true)
            .then(conflicts => {
                if (isMounted.current) {
                    if (!conflicts) {
                        handleCommitUpdate(event);
                    } else {
                        setConfirmConflicts(true);
                    }
                }
            })

        setConfirmIneligibles(false);
    }

    const handleIneligibleCancel = event => {
        setConfirmIneligibles(false);
    }

    const handleConflictsConfirm = event => {
        handleCommitUpdate(event);
        setConfirmConflicts(false);
    }

    const handleConflictsCancel = event => {
        setConfirmConflicts(false);
    }

    const handleGroupAppointmentClick = event => {
        const successCallback = getGroupAppointmentSuccessCallback();
        const cancelCallback = getGroupAppointmentCancelCallback();
        const errorCallback = getGroupAppointmentErrorCallback();

        quickDrawer.deactivateAll()
            .then(() => {
                setTimeout(() => {
                    const extraProps = !!appointment.data.customer ? {
                        customer: toJS(appointment.data.customer),
                        services: toJS(appointment.data.services),
                    } : null;

                    groupAppointment.initialize(appointment.data.id);
                    groupAppointment.data.start = moment(appointment.data.start);
                    groupAppointment.data.duration = appointment.data.duration;
                    groupAppointment.data.userId = appointment.data.userId;

                    quickDrawer.activateQuickDrawer('group-appointment', 'create', extraProps, successCallback, cancelCallback, errorCallback);
                }, 300);
            })
    }

    const getHasPublicInsuranceBillingLocked = () => {
        return appointment.data.exam && appointment.data.exam.services && appointment.data.exam.services.some(s => s.lastPublicInsuranceBilling && s.lastPublicInsuranceBilling.isLocked);
    }

    const getIsExamCompleted = () => {
        return !!appointment.data.exam && !!appointment.data.exam.completedDateUtc;
    }

    const getHasInvalidSubsidizedCodes = () => {
        let found = false;

        if (appointment.data.services && appointment.data.services.some(s => s.isSubsidized)) {
            const hasPublicInsuranceBillingLocked = getHasPublicInsuranceBillingLocked();
            const isExamCompleted = getIsExamCompleted();

            if (hasPublicInsuranceBillingLocked || isExamCompleted) {
                const hasNewSubsidizedServices = appointment.data.services.some(s => s.isSubsidized && !originalServices.some(o => o.id === s.id));
                const hasRemovedSubsidizedServices = originalServices.some(o => o.isSubsidized && !appointment.data.services.some(s => s.id === o.id));

                found = hasNewSubsidizedServices || hasRemovedSubsidizedServices;
            }
        }

        return found;
    }

    const getGroupAppointmentSuccessCallback = () => {
        if (quickDrawer.successCallback && fn.isFunction(quickDrawer.successCallback)) {
            const callback = quickDrawer.successCallback.bind(null);

            return (result) => {
                groupAppointment.clear();
                callback(result);
            }
        }
    }

    const getGroupAppointmentCancelCallback = () => {
        if (quickDrawer.cancelCallback && fn.isFunction(quickDrawer.cancelCallback)) {
            const callback = quickDrawer.cancelCallback.bind(null);

            return () => {
                groupAppointment.clear();
                callback();
            }
        }
    }

    const getGroupAppointmentErrorCallback = () => {
        if (quickDrawer.errorCallback && fn.isFunction(quickDrawer.errorCallback)) {
            const callback = quickDrawer.errorCallback.bind(null);

            return () => {
                groupAppointment.clear();
                callback();
            }
        }
    }

    const canUpdateSubsidizedService = () => {
        let valid = true;

        if (appointment.data) {
            const hasCompletedExam = appointment.data.exam && !!appointment.data.exam.completedDateUtc;
            const hasPublicInsuranceBillingLocked = appointment.data.services && appointment.data.services.some(s => s.lastPublicInsuranceBilling && s.lastPublicInsuranceBilling.isLocked);

            valid = !hasCompletedExam && !hasPublicInsuranceBillingLocked;
        }

        return valid;
    }

    const renderAppointmentDateTime = () => {
        if (!appointment.data.start) return null;

        const start = moment(appointment.data.start);
        const end = appointment.data.end ? moment(appointment.data.end) : null;
        const hasEndTime = !!end;
        const weekday = start.format('dddd');
        const day = start.format('D');
        const ordinal = start.format('Do').replace(day, '');
        const dateHtml = `${start.format('MMMM D')}<sup>${ordinal}</sup>${((start.year() !== moment().year()) ? `, ${start.format('YYYY')}` : '')}`;
        const startTimeHtml = `${start.format('h:mm')}${(!hasEndTime || start.format('a') !== end.format('a') ? ` ${start.format('a')}` : '')}`;
        const endTimeHtml = hasEndTime ? `${end.format('h:mm')} ${end.format('a')}` : '';
        const startTime = moment().startOf('day').hours(start.hour()).minutes(start.minute());
        const endTime = moment().startOf('day').hours(end.hour()).minutes(end.minute());

        return <div className='d-flex'>
            <div className='flex-1'>
                <ul className='list-inline no-style m-0'>
                    <li className='list-inline-item m-0' onClick={handleDatePickerDisplay}>
                        <div className='control transparent'>
                            <div
                                className={'control-overlay' + (datePickerOpen ? '' : ' d-none')}
                                onClick={() => { setDatePickerOpen(false) }}
                            ></div>
                            <DateTimePicker
                                id='datePicker'
                                defaultOpen={false}
                                open={datePickerOpen}
                                dateFormat={dt => String(dt.getDate())}
                                dayFormat={day => ['S', 'M', 'T', 'W', 'T', 'F', 'S'][day.getDay()]}
                                views={['month', 'year']}
                                value={start.toDate()}
                                footer={false}
                                date={true}
                                time={false}
                                onSelect={handleDatePickerChange}
                            />
                        </div>
                        <div className={'text' + (datePickerOpen ? ' active' : '')}>
                            <small className='weekday'>{weekday}</small>
                            <span className='date' dangerouslySetInnerHTML={{ __html: dateHtml }}></span>
                        </div>
                    </li>
                    <li className='list-inline-item my-0 mx-1'><small>@</small></li>
                    <li className='list-inline-item m-0' onClick={handleStartTimePickerDisplay}>
                        <div className='control transparent'>
                            <div
                                className={'control-overlay' + (startTimePickerOpen ? '' : ' d-none')}
                                onClick={() => { setStartTimePickerOpen(false) }}
                            ></div>
                            <DateTimePicker
                                id='startTimePicker'
                                defaultOpen={false}
                                open={startTimePickerOpen}
                                value={startTime.toDate()}
                                step={5}
                                date={false}
                                time={true}
                                onSelect={handleStartTimePickerChange}
                            />
                        </div>
                        <div className={'text' + (startTimePickerOpen ? ' active' : '')}>
                            <span data-start-time className='time' dangerouslySetInnerHTML={{ __html: startTimeHtml }}></span>
                        </div>
                    </li>
                    {
                        hasEndTime ? <>
                            <li className='list-inline-item my-0 mx-1'><small>to</small></li>
                            <li className='list-inline-item m-0' onClick={handleEndTimePickerDisplay}>
                                <div className='control transparent'>
                                    <div
                                        className={'control-overlay' + (endTimePickerOpen ? '' : ' d-none')}
                                        onClick={() => { setEndTimePickerOpen(false) }}
                                    ></div>
                                    <DateTimePicker
                                        id='endTimePicker'
                                        defaultOpen={false}
                                        open={endTimePickerOpen}
                                        value={endTime.toDate()}
                                        step={5}
                                        date={false}
                                        time={true}
                                        onSelect={handleEndTimePickerChange}
                                    />
                                </div>
                                <div className={'text' + (endTimePickerOpen ? ' active' : '')}>
                                    <span data-end-time className='time' dangerouslySetInnerHTML={{ __html: endTimeHtml }}></span>
                                </div>
                            </li>
                        </> : null
                    }
                </ul>
            </div>
            <div className='d-flex ml-auto align-middle pt-3'>
                {
                    !appointment.stageId && appointment.data && appointment.data.status === 'Booked' ?
                        <button
                            type='button'
                            className='btn btn-icon line-height-1 mr-n2'
                            title='Reschedule appointment'
                            onClick={handleRescheduleAppointment}
                        >
                            <i className='fal fa-pen fs-lg'></i>
                        </button> : null
                }
            </div>
        </div>
    }

    const renderCustomer = () => {
        const customer = appointment.data.customer;
        return <div
            className='profile-wrapper'
        >
            <div className='profile'>
                <span
                    className={`profile-image profile-initials rounded-circle d-flex text-white ${ch.getProfileColor(customer)} fw-500`}
                    title={customer.fullName}
                >
                    {customer.initials}
                </span>
            </div>
            <div className='description flex-1'>
                <div className='name'>{ph.getFullName(customer, true)}
                    {
                        customer.dateOfBirth || customer.sex || customer.gender || customer.pronoun ?
                            <small className='text-nowrap ml-2'>({`${ph.getAge(customer.dateOfBirth, moment(appointment.data.start))} ${ph.getSexGenderPronounDisplay(customer)}`.trim()})</small> : null
                    }
                </div>
                {
                    customer.address && customer.address.country ?
                        <div className='info'>{ah.getAddressHtml(customer.address)}</div> : null
                }
                {
                    customer.emailAddress ?
                        <div className='info'>
                            <a
                                href={`mailto:${customer.emailAddress}`}
                            >{customer.emailAddress}
                            </a>
                        </div> : null
                }
                {
                    customer.phoneNumber ?
                        <div className='info'>
                            <a
                                href={`tel:${customer.phoneNumber}`}
                            >{sys.getFormattedPhoneNumber(customer.phoneNumber)}
                            </a>
                        </div> : null
                }
                {
                    customer.primaryContactPerson ?
                        <div className='mt-2 border-left border-3 border-gray-300 pl-3 py-1'>
                            <small className='text-primary-400 fs-75 text-uppercase d-block'>Primary Contact</small>
                            {
                                customer.primaryContactPerson.id ?
                                    <Link
                                        to={`${rts.Customers.Home}/${customer.primaryContactPerson.id}`}
                                        className='d-block fs-lg text-gray-700 text-info-hover'
                                        onClick={quickDrawer.deactivateAll}
                                    >
                                        {ph.getPreferredFirstLastName(customer.primaryContactPerson)}
                                        {
                                            customer.primaryContactPerson.relationship ?
                                                <small className='ml-1'>(<span className='fw-500 fs-90 text-success-700'>{customer.primaryContactPerson.relationship}</span>)</small> : null
                                        }
                                    </Link> :
                                    <div className='fs-lg text-gray-700 '>{ph.getPreferredFirstLastName(customer.primaryContactPerson)}
                                        {
                                            customer.primaryContactPerson.relationship ?
                                                <small className='ml-1'>(<span className='fw-500 fs-90 text-success-700'>{customer.primaryContactPerson.relationship}</span>)</small> : null
                                        }
                                    </div>
                            }
                            {
                                customer.primaryContactPerson.emailAddress ?
                                    <div className='info fs-95'>
                                        <a
                                            href={`mailto:${customer.primaryContactPerson.emailAddress}`}
                                        >{customer.primaryContactPerson.emailAddress}
                                        </a>
                                    </div> : null
                            }
                            {
                                customer.primaryContactPerson.phoneNumber ?
                                    <div className='info fs-95'>
                                        <a
                                            href={`tel:${customer.primaryContactPerson.phoneNumber}`}
                                        >{sys.getFormattedPhoneNumber(customer.primaryContactPerson.phoneNumber)}
                                        </a>
                                    </div> : null
                            }
                        </div> : null
                }
            </div>
        </div>
    }

    const renderServices = () => {
        const filteredServices = services.filter(s =>
            (!s.activeStartingDate || moment(s.activeStartingDate).isSameOrBefore(moment(appointment.data.start))) &&
            appointment.data.services.filter(a => a.id === s.id).length === 0);

        return filteredServices.map(s => {
            return {
                id: s.id,
                name: `${s.code} - ${s.name}`,
                code: s.code,
                colorHexValue: s.colorHexValue,
                isSubsidized: s.isSubsidized,
                duration: (s.defaultDurationInMinutes ? s.defaultDurationInMinutes : 0),
            }
        })
    }

    return <>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <Observer>{() =>
                <>
                    {
                        (datePickerOpen || startTimePickerOpen || endTimePickerOpen) ?
                            <GlobalHotKeys
                                keyMap={{
                                    closeAllPickers: ['esc'],
                                }}
                                handlers={{
                                    closeAllPickers: event => {
                                        setDatePickerOpen(false);
                                        setStartTimePickerOpen(false);
                                        setEndTimePickerOpen(false);
                                    }
                                }}
                                allowChanges={true}
                            /> :
                            <>
                                {
                                    (props.drawer === quickDrawer.drawerOpened) && !confirmConflicts ?
                                        <GlobalHotKeys
                                            keyMap={{
                                                close: ['esc'],
                                            }}
                                            handlers={{
                                                close: event => {
                                                    handleCancel(event)
                                                },
                                            }}
                                            allowChanges={true}
                                        /> : null
                                }
                            </>
                    }
                    <fieldset disabled={appointment.isSaving}>
                        <div className='quick-drawer'>
                            <QuickDrawerHeader
                                drawer={props.drawer}
                                icon={oh.getIcon('appointment', 'update')}
                                action='Update'
                                category='Appointment'
                                className='appointments'
                                onCancel={handleCancel}
                            />
                            <div className='quick-drawer-body'>
                                {
                                    appointment.isReady && isReady ?
                                        <FadeIn>
                                            <div className='update-appointment body-content'>
                                                {
                                                    getHasInvalidSubsidizedCodes() ?
                                                        <section>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='alert alert-danger p-3 mb-0' role='alert'>
                                                                        <strong className='d-block mb-2'>Cannot update {auth.currentTenant.publicInsuranceUnitId} service code</strong>
                                                                        <ul className='pl-3 mb-0'>
                                                                            {
                                                                                getIsExamCompleted() && !getHasPublicInsuranceBillingLocked() ?
                                                                                    <li>Exam has been locked.</li> : null
                                                                            }
                                                                            {
                                                                                getHasPublicInsuranceBillingLocked() ?
                                                                                    <li>Exam has been submitted to {auth.currentTenant.publicInsuranceUnitId} already.</li> : null
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section> : null
                                                }
                                                {
                                                    eligibles && cache.resources.filter(r => r.id === appointment.data.userId)[0] ?
                                                        <section>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='alert alert-info p-3 mb-0' role='alert'>
                                                                        <strong className='d-block mb-2'>Information</strong>
                                                                        <ul className='pl-3 mb-0'>
                                                                            {
                                                                                eligibles.map((i, ii) => {
                                                                                    return <li key={`update_appointment_eligible_${ii}`}>{bh.renderServiceCode(i.service, 'float-left mr-1')} Eligibility confirmed.</li>
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section> : null
                                                }
                                                {
                                                    conflicts || ineligibles ?
                                                        <section>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='alert alert-warning p-3 mb-0' role='alert'>
                                                                        <strong className='d-block mb-2'>Warning</strong>
                                                                        <ul className='pl-3 mb-0'>
                                                                            {
                                                                                ineligibles ?
                                                                                    <>
                                                                                        {
                                                                                            ineligibles.map((i, ii) => {
                                                                                                return <li key={`update_appointment_ineligible_${ii}`}>{bh.renderServiceCode(i.service, 'float-left mr-1')} {i.earliestEligibleDate ? <>{i.ineligibilityCode ? `(Error: ${i.ineligibilityCode}) ` : ''}Not eligible until <span className='tt-underline'>{moment(i.earliestEligibleDate).format('YYYY-MM-DD')}</span>.</> : (i.ineligibilityReason ? `${(!!i.ineligibilityCode ? `(Error: ${i.ineligibilityCode}) ` : '')}${i.ineligibilityReason}` : 'Cannot verify.  Reason unknown.')}</li>
                                                                                            })
                                                                                        }
                                                                                    </> : null
                                                                            }
                                                                            {
                                                                                conflicts ?
                                                                                    <>
                                                                                        {
                                                                                            conflicts.appointment && cache.resources.filter(r => r.id === appointment.data.userId)[0] ?
                                                                                                <li>{cache.resources.filter(r => r.id === appointment.data.userId)[0].shortName} has another appointment at the same time.</li> : null
                                                                                        }
                                                                                        {
                                                                                            conflicts.schedule && cache.resources.filter(r => r.id === appointment.data.userId)[0] ?
                                                                                                <li>{cache.resources.filter(r => r.id === appointment.data.userId)[0].shortName} is not scheduled to work at this time.</li> : null
                                                                                        }
                                                                                        {
                                                                                            !conflicts.schedule && conflicts.businessDay && cache.resources.filter(r => r.id === appointment.data.userId)[0] ?
                                                                                                <li>This is outside of business hours.</li> : null
                                                                                        }
                                                                                        {
                                                                                            conflicts.break && cache.resources.filter(r => r.id === appointment.data.userId)[0] ?
                                                                                                <li>{cache.resources.filter(r => r.id === appointment.data.userId)[0].shortName} is scheduled to be on break at this time.</li> : null
                                                                                        }
                                                                                        {
                                                                                            conflicts.timeOff && cache.resources.filter(r => r.id === appointment.data.userId)[0] ?
                                                                                                <li>{cache.resources.filter(r => r.id === appointment.data.userId)[0].shortName} is scheduled to be away on this day.</li> : null
                                                                                        }
                                                                                    </> : null
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section> : null
                                                }
                                                <section className='date-time'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <Observer>{() => renderAppointmentDateTime()}</Observer>
                                                            <div>
                                                                {bh.renderAppointmentRescheduledByOffice(appointment.data, 'fs-xs mr-1 mt-1')}
                                                                {bh.renderAppointmentRescheduledByCustomer(appointment.data, 'fs-xs mr-1 mt-1')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section className='resource'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-0'>
                                                                <label><small>With</small></label>
                                                                <Observer>{() =>
                                                                    <DropdownList
                                                                        data={cache.resources}
                                                                        valueField='id'
                                                                        value={appointment.data.userId}
                                                                        itemComponent={({ item }) => (
                                                                            <div
                                                                                className='profile-wrapper'
                                                                            >
                                                                                <div className='profile'>
                                                                                    <span
                                                                                        className={`profile-image rounded-circle fw-500 d-block` + (item && !item.profilePictureUri ? ` profile-initials bg-color${item.color}-500` : '')}
                                                                                        style={item && item.profilePictureUri ? {
                                                                                            backgroundImage: `url(${item.profilePictureUri})`,
                                                                                            backgroundSize: 'cover',
                                                                                        } : null}
                                                                                        title={item ? item.fullName : 'System'}>
                                                                                        {item && !item.profilePictureUri ? <div className='d-initials fs-xs'>{item.initials}</div> : null}
                                                                                    </span>
                                                                                </div>
                                                                                <span className='description'>{item.fullName}</span>
                                                                            </div>
                                                                        )}
                                                                        valueComponent={({ item }) => (
                                                                            item ?
                                                                                <div
                                                                                    className='profile-wrapper'
                                                                                >
                                                                                    <div className='profile'>
                                                                                        <span
                                                                                            className={`profile-image rounded-circle fw-500 d-block` + (item && !item.profilePictureUri ? ` profile-initials bg-color${item.color}-500` : '')}
                                                                                            style={item && item.profilePictureUri ? {
                                                                                                backgroundImage: `url(${item.profilePictureUri})`,
                                                                                                backgroundSize: 'cover',
                                                                                            } : null}
                                                                                            title={item ? item.fullName : 'System'}>
                                                                                            {item && !item.profilePictureUri ? <div className='d-initials fs-xs'>{item.initials}</div> : null}
                                                                                        </span>
                                                                                    </div>
                                                                                    <span className='description'>{item.fullName}</span>
                                                                                </div> : null
                                                                        )}
                                                                        onChange={handleResourceChange}
                                                                    />}</Observer>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section className='customer'>
                                                    <div className='row'>
                                                        <div className='col-12 mb-0'>
                                                            <label><small>For</small></label>
                                                            <Observer>{() => renderCustomer()}</Observer>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section className='services'>
                                                    <div className='row'>
                                                        <div className='col-12 mb-0'>
                                                            <div className='form-group validate validate-required'>
                                                                <label htmlFor='new-appointment-services' className='required'>
                                                                    <small>Services</small>
                                                                </label>
                                                                <Observer>{() =>
                                                                    <>
                                                                        <Multiselect
                                                                            allowCreate={false}
                                                                            data={renderServices()}
                                                                            defaultValue={appointment.data.services}
                                                                            valueField='id'
                                                                            textField='name'
                                                                            tagComponent={({ item }) => (
                                                                                <span
                                                                                    className='tag'
                                                                                    style={{
                                                                                        backgroundColor: item.colorHexValue,
                                                                                        borderColor: item.colorHexValue,
                                                                                    }}
                                                                                >
                                                                                    <strong>{item.code}</strong>
                                                                                </span>
                                                                            )}
                                                                            onChange={handleServiceChange}
                                                                        />
                                                                    </>}</Observer>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-5'>
                                                            <Observer>{() =>
                                                                <div className='form-group mb-0 validate validate-required'>
                                                                    <label className='required' htmlFor='new-appointment-duration'><small>Duration</small></label>
                                                                    <div className='input-group'>
                                                                        <input
                                                                            id='new-appointment-duration'
                                                                            type='number'
                                                                            className='form-control'
                                                                            min={5}
                                                                            step={5}
                                                                            value={(appointment.data.duration ? appointment.data.duration : '')}
                                                                            onChange={handleDurationChange}
                                                                        />
                                                                        <div className='input-group-append'>
                                                                            <span className='input-group-text'>min.</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }</Observer>
                                                        </div>
                                                    </div>
                                                </section>
                                                <div className={appointment.isReschedule ? '' : 'd-none'}>
                                                    <section className='pt-4'>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <Observer>{() =>
                                                                    <div className='form-group mb-0 validate validate-required'>
                                                                        <label className='required'><small>Reschedule by</small></label>
                                                                        <div className='custom-control custom-radio mb-2'>
                                                                            <input
                                                                                id='requestByCustomer'
                                                                                type='radio'
                                                                                name='requestBy'
                                                                                value='Customer'
                                                                                className='custom-control-input'
                                                                                checked={appointment.rescheduleRequestBy === 'Customer'}
                                                                                onChange={handleRequestByChange}
                                                                            />
                                                                            <label
                                                                                htmlFor='requestByCustomer'
                                                                                className='custom-control-label'
                                                                            >
                                                                                {
                                                                                    appointment.originalData ?
                                                                                        <>{ph.getFullName(appointment.originalData.customer, true)} <span className='text-smaller text-gray-700'>(Patient)</span></> : <>Patient</>
                                                                                }
                                                                            </label>
                                                                        </div>
                                                                        <div className='custom-control custom-radio mb-2'>
                                                                            <input
                                                                                id='requestByResource'
                                                                                type='radio'
                                                                                name='requestBy'
                                                                                value='Resource'
                                                                                className='custom-control-input'
                                                                                checked={appointment.rescheduleRequestBy === 'Resource'}
                                                                                onChange={handleRequestByChange}
                                                                            />
                                                                            <label
                                                                                htmlFor='requestByResource'
                                                                                className='custom-control-label'
                                                                            >
                                                                                {
                                                                                    appointment.originalData ?
                                                                                        <>{ph.getFullName(appointment.originalData.resource, true)} <span className='text-smaller text-gray-700'>(Our office)</span></> : <>Doctor</>
                                                                                }
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                }</Observer>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <section>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className='form-group'>
                                                                    <label><small>Reschedule Reason</small></label>
                                                                    <Observer>{() =>
                                                                        <>
                                                                            <div className={'input-group' + (!appointment.rescheduleRequestBy || appointment.rescheduleRequestBy === 'Customer' ? '' : ' d-none')}>
                                                                                <RichTextEditor
                                                                                    mode='none'
                                                                                    disableTab={true}
                                                                                    value={appointment.rescheduleRequestReasonHtml}
                                                                                    onChange={handleReasonChange}
                                                                                />
                                                                            </div>
                                                                            <div className={'input-group' + (appointment.rescheduleRequestBy === 'Resource' ? '' : ' d-none')}>
                                                                                <RichTextEditor
                                                                                    mode='none'
                                                                                    disableTab={true}
                                                                                    value={appointment.rescheduleRequestReasonHtml}
                                                                                    onChange={handleReasonChange}
                                                                                />
                                                                            </div>
                                                                        </>
                                                                    }</Observer>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                                <Observer>{() =>
                                                    <>
                                                        {
                                                            appointment.data.isRepeating ?
                                                                <>
                                                                    <section>
                                                                        <div className='row'>
                                                                            <div className='col-12'>
                                                                                <div className='form-group'>
                                                                                    <label><small>Updating</small></label>
                                                                                    <Observer>{() =>
                                                                                        <>
                                                                                            <div className='custom-control custom-radio mb-2'>
                                                                                                <input
                                                                                                    id='updateThisAppointment'
                                                                                                    type='radio'
                                                                                                    name='repeatUpdateFollowing'
                                                                                                    value='false'
                                                                                                    className='custom-control-input'
                                                                                                    checked={!appointment.repeatUpdateFollowing}
                                                                                                    onChange={handleRepeatUpdateFollowingChange}
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor='updateThisAppointment'
                                                                                                    className='custom-control-label'
                                                                                                >
                                                                                                    This appointment only
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className='custom-control custom-radio mb-2'>
                                                                                                <input
                                                                                                    id='updateOutstandingAppointments'
                                                                                                    type='radio'
                                                                                                    name='repeatUpdateFollowing'
                                                                                                    value='true'
                                                                                                    className='custom-control-input'
                                                                                                    checked={appointment.repeatUpdateFollowing}
                                                                                                    onChange={handleRepeatUpdateFollowingChange}
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor='updateOutstandingAppointments'
                                                                                                    className='custom-control-label'
                                                                                                >
                                                                                                    This and following appointment(s)
                                                                                                </label>
                                                                                            </div>
                                                                                        </>
                                                                                    }</Observer>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </section>
                                                                    {
                                                                        appointment.repeatUpdateFollowing ?
                                                                            <section>
                                                                                <div className='row'>
                                                                                    <div className='col-6'>
                                                                                        <div className='form-group mb-0 validate validate-required'>
                                                                                            <label className='required' htmlFor='new-appointment-repeat-until'><small>Until (YYYY-MM-DD)</small></label>
                                                                                            <Observer>{() =>
                                                                                                <DateTimePicker
                                                                                                    dateFormat={dt => String(dt.getDate())}
                                                                                                    dayFormat={day => ['M', 'T', 'W', 'T', 'F', 'S'][day.getDay()]}
                                                                                                    format={'YYYY-MM-DD'}
                                                                                                    views={['month', 'year']}
                                                                                                    footer={false}
                                                                                                    date={true}
                                                                                                    time={false}
                                                                                                    dropUp={true}
                                                                                                    min={(isMoment(appointment.data.start) ? appointment.data.start : moment(appointment.data.start)).toDate()}
                                                                                                    value={appointment.repeatUpdateUntil ? appointment.repeatUpdateUntil.toDate() : null}
                                                                                                    onChange={handleRepeatUpdateUtilChange}
                                                                                                />
                                                                                            }</Observer>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-6'>
                                                                                        <div className='form-group mb-0 validate validate-required'>
                                                                                            <label className='required' htmlFor='new-appointment-repeat-total'><small>Remaining</small></label>
                                                                                            <Observer>{() =>
                                                                                                <div className='input-group'>
                                                                                                    <input
                                                                                                        id='new-appointment-repeat-total'
                                                                                                        type='number'
                                                                                                        className='form-control'
                                                                                                        min={0}
                                                                                                        max={100}
                                                                                                        step={1}
                                                                                                        value={(appointment.repeatUpdateRemaining || appointment.repeatUpdateRemaining === 0 ? appointment.repeatUpdateRemaining : '')}
                                                                                                        onChange={handleRepeatUpdateRemainingChange}
                                                                                                    />
                                                                                                    <div className='input-group-append'>
                                                                                                        <span className='input-group-text'>appt.</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            }</Observer>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </section> : null
                                                                    }
                                                                </> : null
                                                        }
                                                    </>
                                                }</Observer>
                                            </div>
                                        </FadeIn> : renderQuickDrawerLoading()
                                }
                            </div>
                            <Observer>{() =>
                                appointment.isReady ?
                                    <div className='quick-drawer-action'>
                                        <div className='row'>
                                            <div className='col-4'>
                                                <Observer>{() => <>
                                                    {
                                                        sys.configuration.appointment.isGroupAppointmentEnabled === true ?
                                                            <button
                                                                type='button'
                                                                className={'btn btn-icon'}
                                                                title='Start group appointment'
                                                                onClick={handleGroupAppointmentClick}
                                                            >
                                                                <i className='fal fa-users-class'></i>
                                                            </button> : null
                                                    }
                                                </>}</Observer>
                                            </div>
                                            <div className='col-8'>
                                                <div className='float-right'>
                                                    <button
                                                        type='button'
                                                        className='btn btn-link btn-cancel mr-2'
                                                        onClick={handleCancel}
                                                    >Cancel</button>
                                                    <button
                                                        data-save-change-appointment
                                                        type='submit'
                                                        className='btn btn-success'
                                                    >Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : null
                            }</Observer>
                        </div>
                    </fieldset>
                </>
            }</Observer>
        </form>
        <BodyEnd>
            <ConfirmModal
                icon={<i className={`${oh.getIcon('appointment', 'new')} text-warning mr-2`}></i>}
                message={<>{appointment.data && appointment.data.customer ? ph.getPreferredFirstName(appointment.data.customer) : ''}&nbsp;<strong>might not be eligible</strong>&nbsp;for the service(s).  Continue?</>}
                option1ClassName={'btn btn-warning shadow-0 bootbox-accept'}
                show={confirmIneligibles}
                onOption1Click={handleIneligibleConfirm}
                onCancel={handleIneligibleCancel}
            />
            <ConfirmModal
                icon={<i className={`${oh.getIcon('appointment', 'update')} text-warning mr-2`}></i>}
                message={<><span data-warn-appointment-conflict>Appointment&nbsp;<strong>conflict(s) detected</strong>.  Continue?</span></>}
                option1ClassName={'btn btn-warning shadow-0 bootbox-accept'}
                show={confirmConflicts}
                onOption1Click={handleConflictsConfirm}
                onCancel={handleConflictsCancel}
            />
        </BodyEnd>
    </>
}

export default UpdateAppointment;