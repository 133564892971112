import React, { useEffect, useContext, useState, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { Observer } from 'mobx-react-lite';
import MaskedInput from 'react-text-mask'
import DateBox from 'devextreme-react/date-box';
import { DropdownList } from 'react-widgets'
import { toast } from 'react-toastify';
import { GlobalHotKeys } from 'react-hotkeys';
import moment from 'moment';

import BodyEnd from '../../_shared/BodyEnd';
import ConfirmModal from '../../_shared/ConfirmModal';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import RichTextEditor from '../../_shared/RichTextEditor';

import PurchaseUpdateStore from '../../../../stores/PurchaseUpdateStore';
import CustomerUpdateStore from '../../../../stores/CustomerUpdateStore';
import AuthStore from '../../../../stores/AuthStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';
import CacheStore from '../../../../stores/CacheStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as MaskKeys from '../../../../constants/maskKeys';
import * as fn from '../../../../utilities/_functions';
import * as oh from '../../../../utilities/operationHelper';
import * as ph from '../../../../utilities/personHelper';
import * as ch from '../../../../utilities/customerHelper';
import * as ah from '../../../../utilities/addressHelper';
import * as bh from '../../../../utilities/badgeHelper';
import * as ih from '../../../../utilities/invoiceHelper';
import * as sys from '../../../../utilities/systemHelper';

import api from '../../../../api';

import './NewPayment.scss';

function NewPayment(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const focusTimer = useRef(null);
    const purchase = useContext(PurchaseUpdateStore);
    const customerUpdate = useContext(CustomerUpdateStore);
    const auth = useContext(AuthStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const cache = useContext(CacheStore);
    const [privateInsurances, setPrivateInsurances] = useState(null);
    const [governmentPrograms, setGovernmentPrograms] = useState(null);
    const [confirmOverpay, setConfirmOverpay] = useState(false);
    const [confirmUseStoreCredit, setConfirmUseStoreCredit] = useState(false);

    useEffect(() => {
        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100);

        if (!privateInsurances && purchase.data) {
            loadPrivateInsurances();
        }

        if (!governmentPrograms && purchase.data) {
            loadGovernmentPrograms();
        }

        if (!!purchase.data && !!purchase.data.customer && !!purchase.data.remainingBalance) {
            if (!!purchase.data.customer.storeCreditBalance) {
                purchase.newPaymentMethod = 'StoreCredit';
                purchase.newPaymentAmount = purchase.data.customer.storeCreditBalance >= purchase.data.remainingBalance ? purchase.data.remainingBalance : purchase.data.customer.storeCreditBalance;
            }
            else {
                purchase.newPaymentAmount = purchase.data.remainingBalance;
            }
        }
        purchase.newPaymentDate = moment().startOf('day');

        return () => {
            isMounted.current = false;

            purchase.clearNewPayment();
            setPrivateInsurances(null);
            setGovernmentPrograms(null);

            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const loadPrivateInsurances = () => {
        api.CustomerPrivateInsurances.search({
            parameters: [{
                field: 'CustomerId',
                value: purchase.data.customerId,
            }],
            sortByFields: [{
                field: 'DisplayOrder',
                direction: 'ASC',
            }],
            includeTotalCount: false,
        })
            .then(({ data }) => {
                if (isMounted.current) {
                    const insurances = data && data.result && data.result.length > 0 ? data.result : [];
                    setPrivateInsurances(insurances);
                }
            })
    }

    const loadGovernmentPrograms = () => {
        const codes = sys.configuration.purchase.governmentProgramCodes;

        if (codes && codes.length > 0) {
            api.GovernmentPrograms.search({
                parameters: [
                    {
                        field: 'IndustryId',
                        value: auth.currentTenant.industryId,
                    },
                    {
                        field: 'Code',
                        value: codes.join(','),
                        operator: 'Contains'
                    },
                    {
                        field: 'DeactivatedDateUtc',
                        value: null,
                    },
                ],
                sortByFields: [{
                    field: 'Code',
                    direction: 'ASC',
                }],
                includeTotalCount: false,
            })
                .then(({ data }) => {
                    if (isMounted.current) {
                        const result = data && data.result && data.result.length > 0 ? data.result : [];
                        setGovernmentPrograms(result);
                    }
                })
        }
        else {
            if (isMounted.current) {
                setGovernmentPrograms([]);
            }
        }
    }

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (purchase.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleAmountChange = event => {
        const amount = event.target.value ? fn.parseCurrency(event.target.value) : null;
        purchase.newPaymentAmount = amount;
        purchase.hasUnsavedChanges = true;
    }

    const handlePaymentDateChange = element => {
        const value = element.value;

        purchase.newPaymentDate = value ? moment(value).startOf('day') : moment().startOf('day');
        purchase.hasUnsavedChanges = true;
    }

    const handlePaymentMethodChange = event => {
        const paymentMethod = event.target.value;
        purchase.newPaymentMethod = paymentMethod;

        switch (purchase.newPaymentMethod) {
            case 'PrivateInsurance':
                purchase.newPaymentPrivateInsurancePolicyId = privateInsurances && privateInsurances.length > 0 ? privateInsurances[0].privateInsurancePolicyId : null;
                break;

            case 'GovernmentProgram':
                purchase.newPaymentGovernmentProgramId = governmentPrograms && governmentPrograms.length > 0 ? governmentPrograms[0].id : null;
                break;

            case 'StoreCredit':
                purchase.newPaymentAmount = !!purchase.data.customer.storeCreditBalance ? purchase.data.customer.storeCreditBalance : 0.0;
                break;

            default:
                break;
        }

        purchase.hasUnsavedChanges = true;
    }

    const handleNoteChange = (content, delta, source, editor) => {
        const note = editor.getText().replace(/(\r\n|\n|\r)/g, ' ').trim();
        const noteHtml = (content === '<p><br></p>') ? null : content;

        purchase.newPaymentNote = note;
        purchase.newPaymentNoteHtml = noteHtml;
        purchase.hasUnsavedChanges = true;
    }

    const handlePaymentHistory = event => {
        quickDrawer.activateQuickDrawer('purchase', 'payment-history', null)
            .then(drawer => {
                if (isMounted.current) {
                    focusTimer.current = setTimeout(() => {
                        quickDrawerFocus(drawer);
                    }, 100);
                }
            });
    }

    const handleAddCustomerPrivateInsurance = event => {
        customerUpdate.initialize(purchase.data.customerId)
            .then(() => {
                if (isMounted.current) {
                    Promise.all([
                        customerUpdate.loadRelationships(),
                        customerUpdate.loadInsurance()
                    ])
                        .then(() => {
                            if (isMounted.current) {
                                quickDrawer.activateQuickDrawer('customer', 'private-insurance-create', null, handleInsuranceInformationAddSuccess, handleInsuranceInformationAddCancel);
                            }
                        })
                }
            })
    }

    const handleInsuranceInformationAddSuccess = (result) => {
        if (result && result.updated) {
            loadPrivateInsurances();
            toast.dark(() => <p data-ins-upd>Customer's insurance updated.</p>);
        }
    }

    const handleInsuranceInformationAddCancel = () => { }

    const handleUpdateCustomerPrivateInsurance = event => {
        customerUpdate.initialize(purchase.data.customerId)
            .then(() => {
                if (isMounted.current) {
                    Promise.all([
                        customerUpdate.loadRelationships(),
                        customerUpdate.loadInsurance()
                    ])
                        .then(() => {
                            if (isMounted.current) {
                                customerUpdate.selectedPrivateInsuranceId = purchase.newPaymentPrivateInsurancePolicyId;
                                quickDrawer.activateQuickDrawer('customer', 'private-insurance-update', null, handleInsuranceInformationUpdateSuccess, handleInsuranceInformationUpdateCancel);
                            }
                        })
                }
            })
    }

    const handleInsuranceInformationUpdateSuccess = (result) => {
        if (result && result.updated) {
            loadPrivateInsurances();
            toast.dark(() => <p data-ins-upd>Customer's insurance updated.</p>);
        }
    }

    const handleInsuranceInformationUpdateCancel = () => { }

    const handlePrivateInsuranceChange = item => {
        if (item && item.privateInsurancePolicyId && purchase.newPaymentMethod === 'PrivateInsurance') {
            purchase.newPaymentPrivateInsurancePolicyId = item.privateInsurancePolicyId;
            purchase.hasUnsavedChanges = true;
        }
    }

    const handleGovernmentProgramChange = event => {
        const id = event.target.value;

        if (id && purchase.newPaymentMethod === 'GovernmentProgram') {
            purchase.newPaymentGovernmentProgramId = id;
            purchase.hasUnsavedChanges = true;
        }
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (purchase.newPaymentAmount > purchase.data.remainingBalance) {
            setConfirmOverpay(true);
        }
        else {
            if (getStoreCreditUsedAmount() < 0.0) {
                setConfirmUseStoreCredit(true);
            }
            else {
                handleCreatePayment(event);
            }
        }
    }

    const handleCreatePayment = event => {
        if (fn.validateForm(validateRef.current)) {
            purchase.payment(true)
                .then(() => {
                    if (isMounted.current) {
                        if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                            props.onSuccess(event, { updated: true });
                        }
                    }
                })
        }
    }

    const handleConfirmOverpayCancel = event => {
        setConfirmOverpay(false);
    }

    const handleConfirmUseStoreCreditCancel = event => {
        setConfirmUseStoreCredit(false);
    }

    const renderPaymentMethods = () => {
        let options = cache.getReferenceDataOptions('PaymentMethodType');

        if (!governmentPrograms || governmentPrograms.length === 0) {
            options = options.filter(option => option.key !== 'GovernmentProgram');
        }

        return <Observer>{() =>
            <select
                id='new-payment-paid-by'
                className='custom-select form-control'
                value={purchase.newPaymentMethod ? purchase.newPaymentMethod : ''}
                onChange={handlePaymentMethodChange}
            >
                <option value=''></option>
                {
                    options.map((option, di) => {
                        return <option key={`payment_method_type_${di}`} value={option.key}>{ih.getPaymentMethodDescription(option)}</option>
                    })
                }
            </select>
        }</Observer>
    }

    const renderPrivateInsuranceDropdownList = () => {
        return privateInsurances && Array.isArray(privateInsurances) ?
            <Observer>{() =>
                <DropdownList
                    data={privateInsurances}
                    dropUp={true}
                    valueField='privateInsurancePolicyId'
                    value={purchase.newPaymentPrivateInsurancePolicyId}
                    itemComponent={({ item }) => (
                        <div className='profile-wrapper pb-0'>
                            <div className='description flex-1'>
                                {privateInsurances && privateInsurances.findIndex(i => i.privateInsurancePolicyId === item.privateInsurancePolicyId) === 0 ? <div className='mb-1'><span className='badge badge-success fs-80 text-shadow-0'>Primary</span></div> : null}
                                {privateInsurances && privateInsurances.findIndex(i => i.privateInsurancePolicyId === item.privateInsurancePolicyId) === 1 ? <div className='mb-1'><span className='badge badge-secondary fs-80 text-shadow-0'>Secondary</span></div> : null}
                                {privateInsurances && privateInsurances.findIndex(i => i.privateInsurancePolicyId === item.privateInsurancePolicyId) > 1 ? <div className='mb-1'><span className='badge badge-secondary fs-80 text-shadow-0'>Other</span></div> : null}
                                {
                                    item.privateInsuranceProvider ?
                                        <div className='name'>{item.privateInsuranceProvider.name}</div> : null
                                }
                                {
                                    item.policyHolderName ?
                                        <div className='info'><strong className='mr-1'>Primary holder:</strong>{item.policyHolderName}</div> : null
                                }
                                {
                                    item.memberId || item.planNumber || item.accountNumber || item.policyNumber ?
                                        <div className='info'>{[item.memberId, item.planNumber, item.accountNumber, item.policyNumber].filter(i => !!i).join(' - ')}</div> : null
                                }
                            </div>
                        </div>
                    )}
                    valueComponent={({ item }) => (
                        item ? <div className='profile-wrapper pb-0'>
                            <div className='description flex-1'>
                                {privateInsurances && privateInsurances.findIndex(i => i.privateInsurancePolicyId === item.privateInsurancePolicyId) === 0 ? <div className='mb-1'><span className='badge badge-success fs-80 text-shadow-0'>Primary</span></div> : null}
                                {privateInsurances && privateInsurances.findIndex(i => i.privateInsurancePolicyId === item.privateInsurancePolicyId) === 1 ? <div className='mb-1'><span className='badge badge-secondary fs-80 text-shadow-0'>Secondary</span></div> : null}
                                {privateInsurances && privateInsurances.findIndex(i => i.privateInsurancePolicyId === item.privateInsurancePolicyId) > 1 ? <div className='mb-1'><span className='badge badge-secondary fs-80 text-shadow-0'>Other</span></div> : null}
                                {
                                    item.privateInsuranceProvider ?
                                        <div className='name'>{item.privateInsuranceProvider.name}</div> : null
                                }
                                {
                                    item.policyHolderName ?
                                        <div className='info'><strong className='mr-1'>Primary holder:</strong>{item.policyHolderName}</div> : null
                                }
                                {
                                    item.memberId || item.planNumber || item.accountNumber || item.policyNumber ?
                                        <div className='info'>{[item.memberId, item.planNumber, item.accountNumber, item.policyNumber].filter(i => !!i).join(' - ')}</div> : null
                                }
                            </div>
                        </div> : null
                    )}
                    onChange={handlePrivateInsuranceChange}
                />}
            </Observer> : null;
    }

    const renderGovernmentProgramDropdownList = () => {
        return governmentPrograms && Array.isArray(governmentPrograms) ?
            <Observer>{() =>
                <select
                    id='new-payment-government-program'
                    className='custom-select form-control fw-500'
                    value={purchase.newPaymentGovernmentProgramId ? purchase.newPaymentGovernmentProgramId : ''}
                    onChange={handleGovernmentProgramChange}
                >
                    {
                        governmentPrograms && governmentPrograms.length > 0 ? governmentPrograms.map((g, gi) => {
                            return <option key={`new-payment-government-program-${gi}`} value={g.id}>{g.name}{(g.code ? ` (${g.code})` : '')}</option>
                        }) : null
                    }
                </select>
            }</Observer> : null;
    }

    const getStoreCreditUsedAmount = () => {
        return !!purchase.data.customer.storeCreditBalance ?
            (purchase.data.customer.storeCreditBalance - (purchase.newPaymentMethod === 'StoreCredit' ? purchase.newPaymentAmount : 0.0)) : 0.0;
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <Observer>{() =>
                <fieldset disabled={purchase.isSaving}>
                    <div className='new-payment-container quick-drawer'>
                        <QuickDrawerHeader
                            drawer={props.drawer}
                            icon={oh.getIcon('purchase', 'payment')}
                            action='Purchase'
                            category='Create Payment'
                            className='purchases'
                            onCancel={handleCancel}
                        />
                        <div className='quick-drawer-body'>
                            <Observer>{() =>
                                <>
                                    {
                                        purchase.data && Array.isArray(privateInsurances) && Array.isArray(governmentPrograms) ?
                                            <FadeIn>
                                                <div className='body-content'>
                                                    <section>
                                                        <div className='row'>
                                                            <div className='col-6'>
                                                                <h3 className='purchase-title'>Invoice Detail</h3>
                                                                <p>Invoice # <strong>{purchase.data.invoiceNumber}</strong></p>
                                                            </div>
                                                            <div className='col-6'>
                                                                <div className='text-right'>
                                                                    {bh.renderPurchaseBalanceStatus(purchase.data, 'text-truncate text-truncate-md fs-sm p-2')}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <Observer>{() =>
                                                        <>
                                                            {
                                                                purchase.data.customer ?
                                                                    <section className='customer'>
                                                                        <div className='row'>
                                                                            <div className='col-12'>
                                                                                <label><small>Billing Information</small></label>
                                                                            </div>
                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className='col-12'>
                                                                                <Observer>{() =>
                                                                                    <div
                                                                                        className='profile-wrapper'
                                                                                    >
                                                                                        <div className='profile'>
                                                                                            <span
                                                                                                className={`profile-image profile-initials rounded-circle d-flex text-white ${ch.getProfileColor(purchase.data.customer)} fw-500`}
                                                                                                title={purchase.data.customer.fullName}
                                                                                            >
                                                                                                {purchase.data.customer.initials}
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className='description flex-1'>
                                                                                            <div className='name'>{ph.getFullName(purchase.data.customer, true)}
                                                                                                {
                                                                                                    purchase.data.customer.dateOfBirth || purchase.data.customer.sex || purchase.data.customer.gender || purchase.data.customer.pronoun ?
                                                                                                        <small className='text-nowrap ml-2'>({`${ph.getAge(purchase.data.customer.dateOfBirth)} ${ph.getSexGenderPronounDisplay(purchase.data.customer)}`.trim()})</small> : null
                                                                                                }
                                                                                            </div>
                                                                                            {
                                                                                                purchase.data.billingAddress ?
                                                                                                    <div className='info'>{ah.getAddressHtml(purchase.data.billingAddress)}</div> : null
                                                                                            }
                                                                                            {
                                                                                                purchase.data.customerEmail ?
                                                                                                    <div className='info'>
                                                                                                        <a
                                                                                                            href={`mailto:${purchase.data.customerEmail}`}
                                                                                                        >{purchase.data.customerEmail}
                                                                                                        </a>
                                                                                                    </div> : null
                                                                                            }
                                                                                            {
                                                                                                purchase.data.customerPhoneNumber ?
                                                                                                    <div className='info'>
                                                                                                        <a
                                                                                                            href={`tel:${purchase.data.customerPhoneNumber}`}
                                                                                                        >{purchase.data.customerPhoneNumber}
                                                                                                        </a>
                                                                                                    </div> : null
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                }</Observer>
                                                                            </div>
                                                                        </div>
                                                                    </section> : null
                                                            }
                                                        </>
                                                    }</Observer>
                                                    <section>
                                                        <div className='row'>
                                                            <div className='col-4'>
                                                                <label><small>Invoice Total</small></label>
                                                                <Observer>{() =>
                                                                    <strong className='d-block fs-xl'>{fn.formatCurrency(purchase.data.total)}</strong>
                                                                }</Observer>
                                                            </div>
                                                            <div className='col-4'>
                                                                <label><small className={((purchase.newPaymentAmount - purchase.data.remainingBalance) !== 0 ? 'text-danger' : '')}>Remaining</small></label>
                                                                <Observer>{() =>
                                                                    <strong className={'d-block fs-xl' + (purchase.newPaymentAmount - purchase.data.remainingBalance !== 0 ? ' text-danger' : '')}>{fn.formatCurrency((purchase.data.remainingBalance - purchase.newPaymentAmount))}</strong>
                                                                }</Observer>
                                                            </div>
                                                            {
                                                                !!purchase.data && !!purchase.data.customer && !!purchase.data.customer.storeCreditBalance ?
                                                                    <div className='col-4'>
                                                                        <label><small className={(getStoreCreditUsedAmount() >= 0 ? 'text-info' : 'text-danger')}>Store Credit</small></label>
                                                                        <Observer>{() =>
                                                                            <strong className={'d-block fs-xl ' + (getStoreCreditUsedAmount() >= 0 ? 'text-info' : 'text-danger')}>{fn.formatCurrency(getStoreCreditUsedAmount())}</strong>
                                                                        }</Observer>
                                                                    </div> : null
                                                            }
                                                        </div>
                                                    </section>
                                                    <section className={(!purchase.data || purchase.data.remainingBalance <= 0 ? 'd-none' : '')}>
                                                        <div className='row'>
                                                            <div className='col-7 validate validate-required'>
                                                                <label htmlFor='new-payment-paid-by' className='required'><small>Paid by</small></label>
                                                                {renderPaymentMethods()}
                                                            </div>
                                                            <div className='col-5 validate validate-required'>
                                                                <label htmlFor='new-payment-amount' className='required'><small>Amount</small></label>
                                                                <Observer>{() =>
                                                                    <MaskedInput
                                                                        id='new-payment-amount'
                                                                        type='text'
                                                                        className='form-control currency-input'
                                                                        mask={MaskKeys.CURRENCY_POSITIVE_MASK}
                                                                        autoComplete='off'
                                                                        value={purchase.newPaymentAmount ? purchase.newPaymentAmount : ''}
                                                                        onChange={handleAmountChange}
                                                                    />
                                                                }</Observer>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <section className={(!purchase.data || purchase.data.remainingBalance <= 0 ? 'd-none' : '')}>
                                                        <div className='row relative'>
                                                            <div className='col-7'>
                                                                <div className='form-group mb-0 validate validate-required'>
                                                                    <label className='required'><small>Paid on (YYYY-MM-DD)</small></label>
                                                                    <Observer>{() =>
                                                                        <DateBox
                                                                            id='purchase-invoice-date'
                                                                            type='date'
                                                                            displayFormat={'yyyy-MM-dd'}
                                                                            useMaskBehavior={false}
                                                                            showClearButton={false}
                                                                            min={moment(purchase.data.invoiceDate).toDate()}
                                                                            max={moment().startOf('day').toDate()}
                                                                            value={purchase.newPaymentDate ? moment(purchase.newPaymentDate).toDate() : null}
                                                                            onValueChanged={handlePaymentDateChange}
                                                                            onOpened={() => { document.querySelector('.dx-datebox-wrapper').style.zIndex = fn.getHighestZIndex() + 1; }}
                                                                        >
                                                                        </DateBox>
                                                                    }</Observer>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <section className={purchase.newPaymentMethod !== 'PrivateInsurance' ? 'd-none' : ''}>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className='form-group'>
                                                                    <label><small>Insurance Information</small></label>
                                                                    {renderPrivateInsuranceDropdownList()}
                                                                    <div className='pt-1 px-1'>
                                                                        {
                                                                            purchase.newPaymentPrivateInsurancePolicyId ?
                                                                                <>
                                                                                    <button
                                                                                        type='button'
                                                                                        className='btn btn-link p-0'
                                                                                        title='Add insurance'
                                                                                        onClick={handleUpdateCustomerPrivateInsurance}
                                                                                    >
                                                                                        Update insurance
                                                                                    </button><span className='mx-2'>|</span>
                                                                                </> : null
                                                                        }
                                                                        {
                                                                            <button
                                                                                type='button'
                                                                                className='btn btn-link p-0'
                                                                                title='Add insurance'
                                                                                onClick={handleAddCustomerPrivateInsurance}
                                                                            >
                                                                                Add new insurance
                                                                            </button>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <section className={purchase.newPaymentMethod !== 'GovernmentProgram' ? 'd-none' : ''}>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className='form-group'>
                                                                    <label><small>Government Program</small></label>
                                                                    {renderGovernmentProgramDropdownList()}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <section className={(!purchase.data || purchase.data.remainingBalance <= 0 ? 'd-none' : '')}>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className='form-group'>
                                                                    <label><small>Note</small></label>
                                                                    <div className='input-group'>
                                                                        <Observer>{() =>
                                                                            <RichTextEditor
                                                                                mode='none'
                                                                                disableTab={true}
                                                                                value={purchase.newPaymentNoteHtml}
                                                                                onChange={handleNoteChange}
                                                                            />
                                                                        }</Observer>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </FadeIn> : renderQuickDrawerLoading()
                                    }
                                </>
                            }</Observer>
                        </div>
                        <div className='quick-drawer-action pl-3'>
                            <div className='row'>
                                <div className='col-4'>
                                    <button
                                        type='button'
                                        className={'btn btn-icon'}
                                        onClick={handlePaymentHistory}
                                    >
                                        <i className={oh.getIcon('purchase', 'history')}></i>
                                        {
                                            purchase.data && purchase.data.payments && purchase.data.payments.length > 0 ?
                                                <span className={`badge badge-icon`}>{purchase.data.payments.length}</span> : null
                                        }
                                    </button>
                                </div>
                                <div className='col-8'>
                                    <div className='float-right'>
                                        <button
                                            type='button'
                                            className='btn btn-link btn-cancel mr-2'
                                            onClick={handleCancel}
                                        >Cancel</button>
                                        <button
                                            type='submit'
                                            className='btn btn-success'
                                        >Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            }</Observer>
        </form>
        <BodyEnd>
            <Observer>{() =>
                <ConfirmModal
                    icon={<i className={`${oh.getIcon('purchase', 'payment')} text-danger mr-2`}></i>}
                    message={<>You are about to&nbsp;<span className='fw-500'>overpay the balance</span>&nbsp;on file.  Continue? </>}
                    show={confirmOverpay}
                    onOption1Click={handleCreatePayment}
                    onCancel={handleConfirmOverpayCancel}
                />
            }</Observer>
            <Observer>{() =>
                <ConfirmModal
                    icon={<i className={`${oh.getIcon('purchase', 'payment')} text-danger mr-2`}></i>}
                    message={<>There is&nbsp;<span className='fw-500'>not enough store credit</span>&nbsp;on file.  Continue? </>}
                    show={confirmUseStoreCredit}
                    onOption1Click={handleCreatePayment}
                    onCancel={handleConfirmUseStoreCreditCancel}
                />
            }</Observer>
        </BodyEnd>
    </>
}

export default NewPayment;