import React, { useEffect, useContext, useRef, useState } from 'react';
import FadeIn from 'react-fade-in';
import { Observer } from 'mobx-react-lite';
import { GlobalHotKeys } from 'react-hotkeys';
import { ContextMenu } from 'devextreme-react/context-menu';
import moment from 'moment';

import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';

import ExamViewStore from '../../../../stores/ExamViewStore';
import FileViewerStore from '../../../../stores/FileViewerStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as fn from '../../../../utilities/_functions';
import * as ah from '../../../../utilities/addressHelper';
import * as uh from '../../../../utilities/userHelper';
import * as bh from '../../../../utilities/badgeHelper';
import * as oh from '../../../../utilities/operationHelper';
import * as mh from '../../../../utilities/medicalReportHelper';

import './ViewMedicalReports.scss';

function ViewMedicalReports(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const validateRef = useRef(null);
    const exam = useContext(ExamViewStore);
    const fileViewer = useContext(FileViewerStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        loadData();
        quickDrawerFocus(props.drawer);

        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const loadData = () => {
        if (!exam.isMedicalReportsReady) {
            exam.loadMedicalReports()
                .then(() => {
                    if (isMounted.current) {
                        setIsReady(true);
                    }
                })
        }
        setIsReady(true);
    }

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            props.onCancel();
        }
    }

    const handleMedicalReportClick = (event, { id }) => {
        if (isMounted.current) {
            if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                props.onSuccess(event, { action: 'update', updated: false, data: { id: id } });
            }
        }
    }

    const handleCopyMedicalReportClick = (event, medicalReportId) => {
        if (isMounted.current) {
            if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                props.onSuccess(event, { action: 'copy', data: { id: medicalReportId } });
            }
        }
    }

    const handleMedicalReportResponseClick = (event, medicalReport) => {
        if (isMounted.current) {
            mh.view(medicalReport, fileViewer);
        }
    }

    const handleCreateMedicalReport = (event) => {
        if (isMounted.current) {
            if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                props.onSuccess(event, { action: 'create', updated: false });
            }
        }
    }

    const handleContextMenuItemClick = event => {
        const { itemData } = event;

        if (itemData.callback && fn.isFunction(itemData.callback)) {
            itemData.callback();
        }
    }

    const getReferredToName = (medicalReportResponse) => {
        if (!medicalReportResponse) return null;
        if (medicalReportResponse.externalDoctorName) return medicalReportResponse.externalDoctorName;
        if (medicalReportResponse.externalClinicName) return medicalReportResponse.externalClinicName;
        return medicalReportResponse.recipientName;
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form ref={validateRef}>
            <Observer>{() =>
                <fieldset>
                    <div className='quick-drawer'>
                        <QuickDrawerHeader
                            drawer={props.drawer}
                            icon={oh.getIcon('medical-report', 'default')}
                            action='View'
                            category='Medical Reports'
                            className='reports'
                            onCancel={handleCancel}
                        />
                        <div className='quick-drawer-body'>
                            {
                                isReady && exam.isMedicalReportsReady ?
                                    <FadeIn>
                                        <div className='medical-reports-container'>
                                            <div className='body-content p-0'>
                                                <section className='medical-reports'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-0 validate validate-required'>
                                                                <Observer>{() => <>
                                                                    {
                                                                        exam.medicalReports && exam.medicalReports.filter(m => !m.isCompleted).length > 0 ?
                                                                            exam.medicalReports.filter(r => !r.isCompleted).sort((a, b) => { return moment.utc(a.CreatedDateUtc) - moment.utc(b.CreatedDateUtc) }).map((m, mi) => {
                                                                                return <div
                                                                                    key={`exam-draft-medical-report-${mi}`}
                                                                                    className={'medical-report-wrapper mb-4' + (mi !== 0 ? ' pt-4 border-top' : '')}
                                                                                    onClick={(e) => { handleMedicalReportClick(e, m) }}
                                                                                >
                                                                                    <span className='d-flex flex-row mb-1'>
                                                                                        {bh.renderMedicalReportStatus(m, 'p-1')}
                                                                                    </span>
                                                                                    <span className='descriptor fw-500 d-block text-gray-700 text-truncate text-truncate-xxl'>
                                                                                        {m.subject}
                                                                                    </span>
                                                                                    <span className='text-muted fs-xs d-block'>
                                                                                        By <span className='text-gray-700 fw-500'>{uh.getDisplayFullNameById(m.createdById)}</span> on {fn.formatFullDateWithOrWithoutYear(moment.utc(m.createdDateUtc).local())}
                                                                                    </span>
                                                                                </div>
                                                                            }) : null
                                                                    }
                                                                    {
                                                                        exam.medicalReports && exam.medicalReports.filter(m => m.isCompleted).length > 0 ?
                                                                            exam.medicalReports.filter(m => m.isCompleted).sort((a, b) => { return moment.utc(a.CreatedDateUtc) - moment.utc(b.CreatedDateUtc) }).map((m, mi) => {
                                                                                return m.medicalReportResponses && m.medicalReportResponses.length > 0 ?
                                                                                    m.medicalReportResponses.sort((x, y) => { return moment.utc(x.CreatedDateUtc) - moment.utc(y.CreatedDateUtc) }).map((mr, mri) => {
                                                                                        return <div
                                                                                            key={`exam-medical-report-response-${mi}-${mri}`}
                                                                                            className={'medical-report-wrapper mb-4' + (mi !== 0 || exam.medicalReports.filter(r => !r.isCompleted).length > 0 ? ' pt-4 border-top' : '')}
                                                                                        >
                                                                                            <div className='d-flex flex-row'>
                                                                                                <div className='flex-1'
                                                                                                    onClick={(e) => { handleMedicalReportResponseClick(e, m) }}
                                                                                                >
                                                                                                    <span className='d-flex flex-row mb-1'>
                                                                                                        {bh.renderMedicalReportStatus(m, 'p-1')}
                                                                                                    </span>
                                                                                                    <span className='descriptor fw-500 d-block text-gray-700 text-truncate'>
                                                                                                        {m.subject}
                                                                                                    </span>
                                                                                                    <span className='descriptor d-block text-gray-700 text-truncate mb-1'>
                                                                                                        {getReferredToName(mr)}
                                                                                                        {
                                                                                                            mr.externalAddress ?
                                                                                                                <>
                                                                                                                    {ah.getAddressHtml(mr.externalAddress)}
                                                                                                                </> : null
                                                                                                        }
                                                                                                    </span>
                                                                                                    <span className='text-muted fs-xs d-block'>
                                                                                                        By <span className='text-gray-700 fw-500'>{uh.getDisplayFullNameById(mr.createdById)}</span> on {fn.formatFullDateWithOrWithoutYear(moment.utc(m.createdDateUtc).local())}
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <div id={`medical_report_${mr.id}`} className='btn btn-icon'>
                                                                                                        <i className='fal fa-ellipsis-v fs-xxl'></i>
                                                                                                    </div>
                                                                                                    <ContextMenu
                                                                                                        target={`#medical_report_${mr.id}`}
                                                                                                        position={{ my: 'top right', at: 'top left' }}
                                                                                                        dataSource={[{
                                                                                                            icon: 'fal fa-copy',
                                                                                                            name: 'Clone',
                                                                                                            callback: (e) => handleCopyMedicalReportClick(e, mr.medicalReportId)
                                                                                                        }]}
                                                                                                        displayExpr='name'
                                                                                                        showEvent="dxcontextmenu click"
                                                                                                        onItemClick={handleContextMenuItemClick}
                                                                                                    >
                                                                                                    </ContextMenu>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    }) : null
                                                                            }) : null
                                                                    }
                                                                </>
                                                                }</Observer>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </FadeIn> : renderQuickDrawerLoading()
                            }
                        </div>
                        <div className='quick-drawer-action'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='float-right'>
                                        <button
                                            type='button'
                                            className={'btn btn-link btn-cancel mr-2'}
                                            onClick={handleCancel}
                                        >Cancel</button>
                                        <button
                                            type='button'
                                            className='btn btn-success'
                                            onClick={handleCreateMedicalReport}
                                        >Create</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            }</Observer>
        </form>
    </>
}

export default ViewMedicalReports;