import React, { Component } from 'react';
import { Accordion } from 'devextreme-react';
import DataGrid, { Scrolling, Column, ColumnFixing } from 'devextreme-react/data-grid';
import { ContextMenu } from 'devextreme-react/context-menu';
import uuid from 'react-uuid';

import PrescriptionSummaryModal from './PrescriptionSummaryModal';

import * as fn from '../../../utilities/_functions';
import * as ph from '../../../utilities/prescriptionHelper';
import * as bh from '../../../utilities/badgeHelper';
import * as ah from '../../../utilities/accessHelper';
import * as sys from '../../../utilities/systemHelper';

import './PrescriptionSummary.scss';

export default class PrescriptionSummary extends Component {
    constructor(props) {
        super(props);

        const filteredTemplates = this.filterTemplates(props.showEmptyPrescriptionTypes, props.templates, props.prescriptions);
        let selectedTemplates = [];

        if (!fn.isNullOrUndefined(props.multiple) ? !!props.multiple : true) {
            selectedTemplates = filteredTemplates;
        } else {
            selectedTemplates = filteredTemplates && filteredTemplates.length > 0 ? [filteredTemplates[0]] : [];
        }

        this.containerRef = React.createRef();
        this.dataGridRefs = React.createRef();
        this.prescriptionsRef = React.createRef();
        this.prescriptionSummaryModalRef = React.createRef();
        this.state = {
            instanceId: uuid(),
            showTitle: !fn.isNullOrUndefined(props.showTitle) ? !!props.showTitle : true,
            showEmptyPrescriptionTypes: !fn.isNullOrUndefined(props.showEmptyPrescriptionTypes) ? !!props.showEmptyPrescriptionTypes : false,
            enableAction: !fn.isNullOrUndefined(props.enableAction) ? !!props.enableAction : false,
            enableSelect: !fn.isNullOrUndefined(props.enableSelect) ? !!props.enableSelect : false,
            enableExpand: !fn.isNullOrUndefined(props.enableExpand) ? !!props.enableExpand : false,
            enableHover: !fn.isNullOrUndefined(props.enableHover) ? !!props.enableHover : true,
            showDraftDefault: !fn.isNullOrUndefined(props.showDraftDefault) ? !!props.showDraftDefault : false,
            showDraftTypes: (!fn.isNullOrUndefined(props.showDraftDefault) ? !!props.showDraftDefault : false) ? filteredTemplates.map(t => { return t.prescriptionTypeId }).filter((v, i, a) => a.indexOf(v) === i) : [],
            scaleToFit: !fn.isNullOrUndefined(props.scaleToFit) ? !!props.scaleToFit : true,
            multiple: !fn.isNullOrUndefined(props.multiple) ? !!props.multiple : true,
            height: props.height ? props.height : '100%',
            templates: filteredTemplates,
            selectedTemplates: selectedTemplates,
            prescriptions: props.prescriptions && props.prescriptions.length > 0 ? props.prescriptions : [],
        };

        this.dataGridRefs.current = [];
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleClone = this.handleClone.bind(this);
        this.handleView = this.handleView.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.handleSendEmail = this.handleSendEmail.bind(this);
        this.handleSendSms = this.handleSendSms.bind(this);
        this.handleSendFax = this.handleSendFax.bind(this);
        this.handlePrint = this.handlePrint.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleDblClick = this.handleDblClick.bind(this);
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps) {
            let isUpdated = false;

            if (!isUpdated && prevProps.templates && this.props.templates && prevProps.templates.length !== this.props.templates.length) {
                isUpdated = true;
            }

            if (!isUpdated && prevProps.prescriptions && this.props.prescriptions && prevProps.prescriptions.length !== this.props.prescriptions.length) {
                isUpdated = true;
            }

            if (!isUpdated && prevProps.prescriptions && this.props.prescriptions && prevProps.prescriptions.length > 0 && this.props.prescriptions.length > 0) {
                for (let pp = 0; pp < prevProps.prescriptions.length; pp++) {
                    const previousPrescription = prevProps.prescriptions[pp];

                    if (!isUpdated && previousPrescription) {
                        const prescription = this.props.prescriptions.filter(p => p && previousPrescription && p.id === previousPrescription.id)[0];

                        if (prescription) {
                            isUpdated =
                                previousPrescription.description !== prescription.description ||
                                previousPrescription.writtenBy !== prescription.writtenBy ||
                                previousPrescription.writtenDate !== prescription.writtenDate ||
                                previousPrescription.expiryDate !== prescription.expiryDate ||
                                previousPrescription.isSigned !== prescription.isSigned ||
                                previousPrescription.isDraft !== prescription.isDraft ||
                                previousPrescription.isFinalized !== prescription.isFinalized ||
                                JSON.stringify(previousPrescription.prescriptionData) !== JSON.stringify(prescription.prescriptionData);
                        }
                    }
                }
            }

            if (isUpdated) {
                const that = this;
                const { showEmptyPrescriptionTypes, multiple } = this.state;
                const filteredTemplates = this.filterTemplates(showEmptyPrescriptionTypes, this.props.templates, this.props.prescriptions);
                let selectedTemplates = [];

                if (multiple) {
                    selectedTemplates = filteredTemplates;
                } else {
                    selectedTemplates = filteredTemplates && filteredTemplates.length > 0 ? [filteredTemplates[0]] : [];
                }

                this.setState({
                    templates: filteredTemplates,
                    prescriptions: this.props.prescriptions
                }, () => {
                    // Have to set state.selectedTemplates after state.templates is resolved; 
                    // otherwise dxAccordion will throw an exception.  (Related to deferredItems??)
                    that.setState({
                        selectedTemplates: selectedTemplates
                    })
                });

                if (this.prescriptionSummaryModalRef.current.isOpened()) {
                    this.prescriptionSummaryModalRef.current.refresh(this.props.prescriptions);
                }
            }
        }
    }

    filterTemplates = (showEmptyPrescriptionTypes, templates, prescriptions) => {
        return templates ? (!!showEmptyPrescriptionTypes ? templates : templates.filter(t => prescriptions && prescriptions.some(p => p.prescriptionTypeId === t.prescriptionTypeId))) : [];
    }

    refresh = (templates, prescriptions) => {
        const that = this;
        const { showEmptyPrescriptionTypes } = this.state;
        const filteredTemplates = this.filterTemplates(showEmptyPrescriptionTypes, templates, prescriptions);

        this.setState({
            templates: filteredTemplates,
            prescriptions: prescriptions,
        }, () => {
            if (that.prescriptionsRef.current && that.prescriptionsRef.current.instance) {
                that.prescriptionsRef.current.instance.refresh();
            }
        })
    }

    refreshPrescriptions = (prescriptions) => {
        const that = this;
        this.setState({
            prescriptions: prescriptions,
        }, () => {
            if (that.prescriptionsRef.current && that.prescriptionsRef.current.instance) {
                that.prescriptionsRef.current.instance.refresh();
            }
        })
    }

    handleMultipleSelectionChanged = event => {
        let newSelectedTemplates = [...this.state.selectedTemplates];
        event.removedItems.forEach((item) => {
            const index = newSelectedTemplates.indexOf(item);
            if (index >= 0) {
                newSelectedTemplates.splice(index, 1);
            }
        });
        if (event.addedItems.length) {
            newSelectedTemplates = [...newSelectedTemplates, ...event.addedItems];
        }
        this.setState({
            selectedTemplates: newSelectedTemplates,
        });
    }

    handleSingleSelectionChanged = event => {
        this.setState({
            selectedTemplates: event.addedItems,
        });
    }

    handleContextMenuItemClick = event => {
        const { itemData } = event;

        if (itemData.callback && fn.isFunction(itemData.callback)) {
            itemData.callback();
        }
    }

    handleUpdate = (event, prescription) => {
        if (fn.isFunction(this.props.onUpdate)) {
            this.props.onUpdate(event, prescription);
        }
    }

    handleClone = (event, prescription) => {
        if (fn.isFunction(this.props.onClone)) {
            this.props.onClone(event, prescription);
        }
    }

    handleRegenerate = (event, prescription) => {
        if (fn.isFunction(this.props.onRegenerate)) {
            this.props.onRegenerate(event, prescription);
        }
    }

    handleView = (event, prescription) => {
        if (fn.isFunction(this.props.onView)) {
            this.props.onView(event, prescription);
        }
    }

    handleDownload = (event, prescription) => {
        if (fn.isFunction(this.props.onDownload)) {
            this.props.onDownload(event, prescription);
        }
    }

    handleSendEmail = (event, prescription) => {
        if (fn.isFunction(this.props.onSendEmail)) {
            this.props.onSendEmail(event, prescription);
        }
    }

    handleSendSms = (event, prescription) => {
        if (fn.isFunction(this.props.onSendSms)) {
            this.props.onSendSms(event, prescription);
        }
    }

    handleSendFax = (event, prescription) => {
        if (fn.isFunction(this.props.onSendFax)) {
            this.props.onSendFax(event, prescription);
        }
    }

    handlePrint = (event, prescription) => {
        if (fn.isFunction(this.props.onPrint)) {
            this.props.onPrint(event, prescription);
        }
    }

    handleDelete = (event, prescription) => {
        if (fn.isFunction(this.props.onDelete)) {
            this.props.onDelete(event, prescription);
        }
    }

    handleAddNote = (event, prescription) => {
        if (fn.isFunction(this.props.onAddNote)) {
            this.props.onAddNote(event, prescription);
        }
    }

    handleRequestFinalize = (event, prescription) => {
        if (fn.isFunction(this.props.onRequestFinalize)) {
            this.props.onRequestFinalize(event, prescription);
        }
    }

    handleSelect = (event, prescription) => {
        if (fn.isFunction(this.props.onSelect)) {
            this.props.onSelect(event, prescription);
        }
    }

    handleDblClick = (event) => {
        if (fn.isFunction(this.props.onDblClick) && event.values && event.columnIndex < (event.values.length - 1) && !!event.column.caption) {
            this.props.onDblClick(event, event.data);
        }
    }

    handleAccordionItemTitleClick = ({ event, itemData }) => {
        if (event.target.classList.contains('btn-expand')) {
            event.stopPropagation();
            const filteredPrescriptions = this.state.prescriptions.filter(p => p.prescriptionTypeId === itemData.prescriptionTypeId);
            this.handlePrescriptionTypeExpandClick(event, itemData, (filteredPrescriptions && filteredPrescriptions.length > 0 ? filteredPrescriptions : []));
        }
        else if (event.target.classList.contains('btn-draft')) {
            event.stopPropagation();
            const prescriptionTypeId = event.target.getAttribute('data-prescription-type');
            const tempShowDraftTypes = this.state.showDraftTypes && this.state.showDraftTypes.length > 0 ? this.state.showDraftTypes : [];
            const tempShowDraftTypeIndex = tempShowDraftTypes.findIndex(t => t === prescriptionTypeId);

            if (tempShowDraftTypeIndex >= 0) {
                tempShowDraftTypes.splice(tempShowDraftTypeIndex, 1);
            }
            else {
                tempShowDraftTypes.push(prescriptionTypeId);
            }

            this.setState({ showDraftTypes: tempShowDraftTypes });
        }
    }

    renderAccordionItem = (template, multiple) => {
        if (!template) return null;

        const { scaleToFit, templates, prescriptions, enableAction, enableSelect, enableHover, showDraftTypes } = this.state;
        const filteredPrescriptions = prescriptions.filter(p => p.prescriptionTypeId === template.prescriptionTypeId);
        const draftPrescriptions = filteredPrescriptions.filter(p => ph.getStatus(p) === 'Draft');
        const hasDrafts = draftPrescriptions && draftPrescriptions.length > 0;
        const nonDraftPrescriptions = hasDrafts ? filteredPrescriptions.filter(f => !draftPrescriptions.some(d => d.id === f.id)) : filteredPrescriptions;
        const showDraft = showDraftTypes.some(d => d === template.prescriptionTypeId);
        const headers = this.getHeaderDefinitions(template, filteredPrescriptions);
        const rowColumn = this.getRowColumnDefinitions(template);
        const templateIndex = templates && templates.length > 0 ? templates.findIndex(t => template.id === t.id) : 0;

        return <div>
            <DataGrid
                id={`__prescription-summary_datagrid_${uuid()}`}
                ref={(el) => this.dataGridRefs.current[templateIndex] = el}
                keyExpr='id'
                width='100%'
                height={this.props.gridHeight}
                className={(this.props.onDblClick ? 'can-dblclick' : '')}
                activeStateEnabled={false}
                focusStateEnabled={false}
                hoverStateEnabled={enableHover}
                dataSource={(showDraft ? filteredPrescriptions : nonDraftPrescriptions)}
                showBorders={false}
                showRowLines={true}
                showColumnLines={false}
                allowColumnReordering={false}
                allowColumnResizing={true}
                paging={{ enabled: false }}
                onCellDblClick={this.handleDblClick}
                onContentReady={scaleToFit ? (multiple ? this.handleMultipleScaleToFitRender : this.handleSingleScaleToFitRender) : null}
            >
                <Scrolling rowRenderingMode='standard'></Scrolling>
                <ColumnFixing enabled={true} />
                <Column
                    caption=''
                    alignment='left'
                    width={25}
                    fixed={true}
                    cellRender={({ data }) => {
                        return <>
                            {
                                data.notes && data.notes.length > 0 ?
                                    <i
                                        className='fal fa-comment-alt-lines text-info cursor-pointer'
                                        title={fn.stripHtml(data.notes[data.notes.length - 1].bodyHtml)}
                                        onClick={this.props.onAddNote ? (e) => { this.props.onAddNote(e, data) } : null}
                                    ></i> : null
                            }
                        </>
                    }}
                />
                {
                    <Column
                        caption=''
                        alignment='left'
                        width={80}
                        fixed={true}
                        cellRender={({ data }) => {
                            return <>{bh.renderPrescriptionStatus(data)}</>
                        }}
                    />
                }
                {
                    headers && headers.length > 0 ?
                        headers.map((ph, phi) => {
                            return <Column
                                key={`prescription_summary_header_${template.id}_${phi}`}
                                caption={ph.metadata.prescriptionHistory.columnLabel}
                                alignment={ph.metadata.align}
                                fixed={!!ph.metadata.prescriptionHistory.sticky}
                                cellRender={({ data }) => {
                                    const keys = rowColumn.filter(h => h.metadata.prescriptionHistory.column === ph.metadata.prescriptionHistory.column).map(c => { return c.key });
                                    return <>
                                        {
                                            keys && keys.length > 0 ?
                                                keys.map((key, ki) => {
                                                    const value = data.prescriptionData.some(p => p.id === key) ? data.prescriptionData.filter(p => p.id === key)[0].value : '';
                                                    const isHtml = fn.regexTester.html.test(value);

                                                    return keys.length > 1 ? <div
                                                        key={`prescription_summary_${template.id}_${phi}_${ki}`}
                                                        title={isHtml ? fn.stripHtml(value) : value}
                                                        className={isHtml ? 'dx-html' : null}
                                                        dangerouslySetInnerHTML={{
                                                            __html: value ? value : '&nbsp;'
                                                        }}
                                                    >
                                                    </div> : (isHtml ? fn.stripHtml(value) : value)
                                                }) : null
                                        }
                                    </>
                                }}
                            />
                        }) : null
                }
                {
                    enableAction ?
                        <Column
                            caption={''}
                            width={75}
                            alignment='right'
                            fixed={true}
                            fixedPosition='right'
                            cellRender={({ data }) => {
                                const id = `prescription_summary_action_${uuid()}`;
                                const contextMenuItems = [];

                                if (ph.isFinalized(data) && this.props.onRegenerate && fn.isFunction(this.props.onRegenerate) && data.isPrinted) {
                                    contextMenuItems.push({
                                        icon: 'fab fa-sync text-primary',
                                        name: 'Regenerate',
                                        callback: (e) => this.handleRegenerate(e, data)
                                    })
                                }

                                if (this.props.onUpdate && fn.isFunction(this.props.onUpdate) && ph.canUpdate(data)) {
                                    contextMenuItems.push({
                                        icon: 'fal fa-pen',
                                        name: 'Update',
                                        callback: (e) => this.handleUpdate(e, data)
                                    })
                                }

                                if (this.props.onClone && fn.isFunction(this.props.onClone) && ph.canClone(data)) {
                                    contextMenuItems.push({
                                        icon: 'fal fa-copy',
                                        name: 'Clone',
                                        callback: (e) => this.handleClone(e, data)
                                    })
                                }

                                if (ph.isFinalized(data) || ph.isDraft(data)) {
                                    if (ph.isFinalized(data) || (ph.isDraft(data) && sys.configuration.prescription.isDraftPrintEnabled))
                                    {
                                        if (this.props.onView && fn.isFunction(this.props.onView)) {
                                            contextMenuItems.push({
                                                icon: 'fal fa-file-search',
                                                name: 'View',
                                                callback: (e) => this.handleView(e, data)
                                            })
                                        }
                                        if (this.props.onDownload && fn.isFunction(this.props.onDownload) && data.allowDownload) {
                                            contextMenuItems.push({
                                                icon: 'fal fa-file-download',
                                                name: 'Download',
                                                callback: (e) => this.handleDownload(e, data)
                                            })
                                        }
                                    }
                                    if (!ph.isDraft(data) && ph.isSigned(data)) {
                                        if (this.props.onSendEmail && fn.isFunction(this.props.onSendEmail) && data.allowEmail) {
                                            contextMenuItems.push({
                                                icon: 'fal fa-envelope',
                                                name: 'Email',
                                                callback: (e) => this.handleSendEmail(e, data)
                                            })
                                        }

                                        if (this.props.onSendSms && fn.isFunction(this.props.onSendSms) && data.allowSms) {
                                            contextMenuItems.push({
                                                icon: 'fal fa-comments-alt',
                                                name: 'SMS',
                                                callback: (e) => this.handleSendSms(e, data)
                                            })
                                        }

                                        if (this.props.onSendFax && fn.isFunction(this.props.onSendFax) && data.allowFax) {
                                            contextMenuItems.push({
                                                icon: 'fal fa-fax',
                                                name: 'Fax',
                                                callback: (e) => this.handleSendFax(e, data)
                                            })
                                        }
                                    }
                                    if ((ph.isFinalized(data) || (ph.isDraft(data) && sys.configuration.prescription.isDraftPrintEnabled)) && this.props.onPrint && fn.isFunction(this.props.onPrint) && data.allowPrint) {
                                        contextMenuItems.push({
                                            icon: 'fal fa-print',
                                            name: 'Print',
                                            callback: (e) => this.handlePrint(e, data)
                                        })
                                    }
                                }

                                if (this.props.onRequestFinalize && fn.isFunction(this.props.onRequestFinalize) && ph.isDraft(data) && !ah.sameUser(data.writtenById)) {
                                    contextMenuItems.push({
                                        icon: 'fal fa-share text-info',
                                        name: 'Request to finalize',
                                        callback: (e) => this.handleRequestFinalize(e, data)
                                    })
                                }

                                if (this.props.onAddNote && fn.isFunction(this.props.onAddNote)) {
                                    contextMenuItems.push({
                                        icon: 'fal fa-comment-alt-lines',
                                        name: 'Add note',
                                        callback: (e) => this.handleAddNote(e, data)
                                    })
                                }

                                if (this.props.onDelete && fn.isFunction(this.props.onDelete) && ph.canUpdate(data)) {
                                    contextMenuItems.push({
                                        icon: 'fal fa-trash-alt text-danger',
                                        name: 'Delete',
                                        callback: (e) => this.handleDelete(e, data)
                                    })
                                }

                                return <>
                                    {
                                        contextMenuItems && contextMenuItems.length > 0 ?
                                            <>
                                                <div id={id} className='d-inline-block pl-2 pr-2 cursor-pointer'>
                                                    <i className={'fal fa-ellipsis-v fs-xxl'}></i>
                                                </div>
                                                <ContextMenu
                                                    target={`#${id}`}
                                                    position={{ my: 'top right', at: 'top left' }}
                                                    dataSource={contextMenuItems}
                                                    displayExpr='name'
                                                    showEvent="dxcontextmenu click"
                                                    onItemClick={this.handleContextMenuItemClick}
                                                >
                                                </ContextMenu>
                                            </> : null
                                    }
                                </>
                            }}
                        /> : null
                }
                {
                    enableSelect ?
                        <Column
                            caption={''}
                            width={75}
                            alignment='right'
                            fixed={true}
                            fixedPosition='right'
                            cellRender={({ data }) => {
                                return <>
                                    <button
                                        className='btn btn-sm btn-info'
                                        onClick={(e) => { this.handleSelect(e, data) }}
                                    >
                                        <i className='fas fa-chevron-right'></i>
                                    </button>
                                </>
                            }}
                        /> : null
                }
            </DataGrid>
        </div>
    }

    handleSingleScaleToFitRender = (event) => {
        let dataGrid, dataGridHeaderRow, dataGridDataRow, dataGridWrapper, dataGridMinHeight, dataGridWrapperHeight, accordionItems, accordionItemsSpaces = 0;

        if (this.dataGridRefs.current.length > 0) {
            dataGrid = this.dataGridRefs.current.filter(r => r && r.props && r.props.id === event.element.id)[0];
            dataGridWrapper = event.element.parentElement;
            accordionItems = Array.from(this.containerRef.current.querySelectorAll('.dx-accordion-item'));

            if (dataGridWrapper) {
                dataGridHeaderRow = event.element.querySelector('.dx-header-row');
                dataGridDataRow = event.element.querySelector('.dx-data-row');
                dataGridMinHeight = (dataGridHeaderRow ? dataGridHeaderRow.offsetHeight : 0) + (dataGridDataRow ? dataGridDataRow.offsetHeight : 0);

                if (accordionItems && accordionItems.length > 0) {
                    for (let i = 0; i < accordionItems.length; i++) {
                        const accordionItemTitle = accordionItems[i].querySelector('.dx-accordion-item-title');
                        const accordionItemTitleStyles = accordionItemTitle ? window.getComputedStyle(accordionItemTitle) : null;
                        const accordionItemTitlePaddingY = accordionItemTitle ? parseFloat(accordionItemTitleStyles.getPropertyValue('padding-top').replace('px', '')) + parseFloat(accordionItemTitleStyles.getPropertyValue('padding-bottom').replace('px', '')) : 0;
                        const accordionItemTitleMarginY = accordionItemTitle ? parseFloat(accordionItemTitleStyles.getPropertyValue('margin-top').replace('px', '')) + parseFloat(accordionItemTitleStyles.getPropertyValue('margin-bottom').replace('px', '')) : 0;
                        const accordionItemTitleBorderY = accordionItemTitle ? parseFloat(accordionItemTitleStyles.getPropertyValue('border-top-width').replace('px', '')) + parseFloat(accordionItemTitleStyles.getPropertyValue('border-bottom-width').replace('px', '')) : 0;

                        accordionItemsSpaces = accordionItemsSpaces + accordionItemTitlePaddingY + accordionItemTitleMarginY + accordionItemTitleBorderY + accordionItemTitle.offsetHeight;
                    }
                }

                dataGridWrapperHeight = (this.containerRef.current.offsetHeight - accordionItemsSpaces);
                dataGridWrapperHeight = dataGridWrapperHeight < dataGridMinHeight ? dataGridMinHeight : dataGridWrapperHeight
                dataGridWrapper.style.height = `${dataGridWrapperHeight}px`;

                if (dataGrid) {
                    dataGrid.instance.option('height', `${dataGridWrapperHeight}px`);
                }
            }
        }
    }

    handleMultipleScaleToFitRender = (event) => {
        const { templates } = this.state;
        let dataGrid, dataGridHeaderRow, dataGridDataRow, dataGridWrapper, dataGridMinHeight, dataGridWrapperHeight, accordionItems, accordionItemsSpaces = 0;

        if (this.dataGridRefs.current.length > 0) {
            dataGrid = this.dataGridRefs.current.filter(r => r && r.props && r.props.id === event.element.id)[0];
            dataGridWrapper = event.element.parentElement;
            accordionItems = Array.from(this.containerRef.current.querySelectorAll('.dx-accordion-item'));

            if (dataGridWrapper) {
                dataGridHeaderRow = event.element.querySelector('.dx-header-row');
                dataGridDataRow = event.element.querySelector('.dx-data-row');
                dataGridMinHeight = (dataGridHeaderRow ? dataGridHeaderRow.offsetHeight : 0) + (dataGridDataRow ? dataGridDataRow.offsetHeight : 0);

                if (accordionItems && accordionItems.length > 0) {
                    for (let i = 0; i < accordionItems.length; i++) {
                        const accordionItemStyles = window.getComputedStyle(accordionItems[i]);
                        const accordionItemPaddingY = parseFloat(accordionItemStyles.getPropertyValue('padding-top').replace('px', '')) + parseFloat(accordionItemStyles.getPropertyValue('padding-bottom').replace('px', ''));
                        const accordionItemMarginY = parseFloat(accordionItemStyles.getPropertyValue('margin-top').replace('px', '')) + parseFloat(accordionItemStyles.getPropertyValue('margin-bottom').replace('px', ''));
                        const accordionItemTitle = accordionItems[i].querySelector('.dx-accordion-item-title');
                        const accordionItemTitleStyles = accordionItemTitle ? window.getComputedStyle(accordionItemTitle) : null;
                        const accordionItemTitlePaddingY = accordionItemTitle ? parseFloat(accordionItemTitleStyles.getPropertyValue('padding-top').replace('px', '')) + parseFloat(accordionItemTitleStyles.getPropertyValue('padding-bottom').replace('px', '')) : 0;
                        const accordionItemTitleMarginY = accordionItemTitle ? parseFloat(accordionItemTitleStyles.getPropertyValue('margin-top').replace('px', '')) + parseFloat(accordionItemTitleStyles.getPropertyValue('margin-bottom').replace('px', '')) : 0;
                        const accordionItemTitleBorderY = accordionItemTitle ? parseFloat(accordionItemTitleStyles.getPropertyValue('border-top-width').replace('px', '')) + parseFloat(accordionItemTitleStyles.getPropertyValue('border-bottom-width').replace('px', '')) : 0;

                        accordionItemsSpaces = accordionItemsSpaces + accordionItemPaddingY + accordionItemMarginY + accordionItemTitlePaddingY + accordionItemTitleMarginY + accordionItemTitleBorderY + accordionItemTitle.offsetHeight;
                    }
                }

                dataGridWrapperHeight = (this.containerRef.current.offsetHeight - accordionItemsSpaces) / (templates && templates.length > 0 ? templates.length : 1);
                dataGridWrapperHeight = dataGridWrapperHeight < dataGridMinHeight ? dataGridMinHeight : dataGridWrapperHeight
                dataGridWrapper.style.height = `${dataGridWrapperHeight}px`;

                if (dataGrid) {
                    dataGrid.instance.option('height', `${dataGridWrapperHeight}px`);
                }
            }
        }
    }

    handlePrescriptionTypeExpandClick = (event, template, prescriptions) => {
        event.stopPropagation();

        const options = {
            template: template,
            prescriptions: prescriptions,
            enableAction: this.state.enableAction,
            enableSelect: this.state.enableSelect,
            enableHover: this.state.enableHover,
            showTitle: this.state.showTitle,
            showDraftDefault: this.state.showDraftDefault,
            onDblClick: this.props.onDblClick,
            onRegenerate: this.props.onRegenerate,
            onView: this.props.onView,
            onUpdate: this.props.onUpdate,
            onClone: this.props.onClone,
            onDownload: this.props.onDownload,
            onPrint: this.props.onPrint,
            onDelete: this.props.onDelete,
            onSelect: this.props.onSelect,
            onAddNote: this.props.onAddNote,
            onRequestFinalize: this.props.onRequestFinalize,
        }
        this.prescriptionSummaryModalRef.current.show(options);
    }

    getHeaderDefinitions = (template, prescriptionData) => {
        const { definition: templateLayout } = template;
        const { definition: controlDefinitions } = templateLayout;
        let headerDefinitions = controlDefinitions
            .filter(d => d.metadata && d.metadata.prescriptionHistory && d.metadata.prescriptionHistory.show)
            .sort((a, b) => parseInt(a.metadata.prescriptionHistory.column, 10) - parseInt(b.metadata.prescriptionHistory.column, 10));

        if (!fn.isNullOrUndefined(prescriptionData) && prescriptionData.length > 0) {
            const filteredHeaderDefinitions = headerDefinitions.filter(h => prescriptionData.some(p => !!p.prescriptionData && p.prescriptionData.some(d => h.key === d.id)));
            headerDefinitions = filteredHeaderDefinitions && filteredHeaderDefinitions.length > 0 ? filteredHeaderDefinitions : headerDefinitions;
        } else {
            headerDefinitions = headerDefinitions.filter(d => d.key.startsWith('__'));
        }

        const missingHeaders = headerDefinitions.filter(d1 => d1.metadata.prescriptionHistory.row === '2' && !headerDefinitions.some(d2 => d2.metadata.prescriptionHistory.row === '1' && d2.metadata.prescriptionHistory.column === d1.metadata.prescriptionHistory.column));

        if (missingHeaders && missingHeaders.length > 0) {
            for (let m = 0; m < missingHeaders.length; m++) {
                headerDefinitions.push(controlDefinitions.filter(c => c.metadata && c.metadata.prescriptionHistory && c.metadata.prescriptionHistory.row === '1' && c.metadata.prescriptionHistory.column === missingHeaders[m].metadata.prescriptionHistory.column)[0]);
            }
        }

        headerDefinitions = headerDefinitions
            .filter(h => h.metadata.prescriptionHistory.row === '1')
            .sort((a, b) => parseInt(a.metadata.prescriptionHistory.row, 10) < parseInt(b.metadata.prescriptionHistory.row, 10) ? -1 : 1)
            .sort((a, b) => parseInt(a.metadata.prescriptionHistory.column, 10) < parseInt(b.metadata.prescriptionHistory.column, 10) ? -1 : 1);

        return headerDefinitions;
    }

    getRowColumnDefinitions = (template) => {
        const { definition: templateLayout } = template;
        const { definition: controlDefinitions } = templateLayout;
        return controlDefinitions
            .filter(d => d.metadata && d.metadata.prescriptionHistory && d.metadata.prescriptionHistory.show)
            .sort((a, b) => parseInt(`${a.metadata.prescriptionHistory.column}${a.metadata.prescriptionHistory.row}`, 10) - parseInt(`${b.metadata.prescriptionHistory.column}${b.metadata.prescriptionHistory.row}`, 10));
    }

    render() {
        const { instanceId, templates, selectedTemplates, enableExpand, scaleToFit, multiple, showDraftTypes, showTitle } = this.state;

        return (<>
            {
                templates && templates.length > 0 && instanceId ?
                    <div
                        id={`_prescription-summary-${instanceId}`}
                        ref={this.containerRef}
                        className={'prescription-summary' + (this.props.className ? ` ${this.props.className}` : '')}
                        style={{ height: (scaleToFit ? '100%' : null) }}
                    >
                        <Accordion
                            className={!showTitle ? 'dx-accordion-title-hidden' : null}
                            activeStateEnabled={false}
                            focusStateEnabled={false}
                            hoverStateEnabled={false}
                            dataSource={templates}
                            collapsible={false}
                            multiple={multiple}
                            animationDuration={300}
                            selectedItems={selectedTemplates}
                            onSelectionChanged={multiple ? this.handleMultipleSelectionChanged : this.handleSingleSelectionChanged}
                            itemTitleRender={(template) => {
                                const { prescriptions } = this.state;
                                const filteredPrescriptions = prescriptions.filter(p => p.prescriptionTypeId === template.prescriptionTypeId);
                                const draftPrescriptions = filteredPrescriptions.filter(p => ph.getStatus(p) === 'Draft');
                                const hasDrafts = draftPrescriptions && draftPrescriptions.length > 0;
                                return <>
                                    <strong>{template.name}</strong>
                                    <small className='ml-1 text-gray-600'>({(filteredPrescriptions ? filteredPrescriptions.length : 0) - (draftPrescriptions ? draftPrescriptions.length : 0)})</small>
                                    {
                                        enableExpand ?
                                            <button
                                                type='button'
                                                className='btn btn-expand btn-xs btn-link p-0 mr-2 float-right'
                                            >
                                                <i className='fal fa-external-link fs-80 mr-1'></i>Expand
                                            </button> : null
                                    }
                                    {
                                        enableExpand && hasDrafts ?
                                            <span className='text-info mx-2 float-right'>|</span> : null
                                    }
                                    {
                                        hasDrafts ?
                                            <button
                                                type='button'
                                                className='btn btn-draft btn-xs btn-link text-danger fw-500 p-0 float-right'
                                                data-prescription-type={template.prescriptionTypeId}
                                            >
                                                {`${(showDraftTypes.some(t => t === template.prescriptionTypeId) ? 'Hide' : 'Show')} Drafts `}<small>({draftPrescriptions.length})</small>
                                            </button> : null
                                    }
                                </>
                            }}
                            itemRender={(template) => { return this.renderAccordionItem(template, multiple) }}
                            onItemTitleClick={this.handleAccordionItemTitleClick}
                        >
                        </Accordion>
                    </div > :
                    <div
                        id={`_prescription-summary-${instanceId}`}
                        ref={this.containerRef}
                        className={'prescription-summary' + (this.props.className ? ` ${this.props.className}` : '')}
                        style={{ height: (scaleToFit ? '100%' : null) }}
                    >
                        <Accordion
                            activeStateEnabled={false}
                            focusStateEnabled={false}
                            hoverStateEnabled={false}
                            dataSource={[this.props.templates[0]]}
                            collapsible={false}
                            multiple={false}
                            animationDuration={300}
                            selectedItems={[this.props.templates[0]]}
                            itemTitleRender={() => {
                                return <div className='mb-2'><strong>Prescription</strong><small className='ml-1 text-gray-600'>(0)</small></div>
                            }}
                            itemRender={(template) => { return this.renderAccordionItem(template, false) }}
                        ></Accordion>
                    </div>
            }
            <PrescriptionSummaryModal ref={this.prescriptionSummaryModalRef} />
        </>)
    }
}