import { createContext } from 'react';
import { toJS } from 'mobx';
import { decorate, observable, action } from 'mobx';

import * as fn from '../utilities/_functions';
import api from '../api';

export class ExternalDoctorCreate {
    id = null;
    data = {
        firstName: null,
        lastName: null,
        suffix: null,
        billingNumber: null,
        phoneNumber: null,
        faxNumber: null,
        emailAddress: null,
        externalClinic: null,
    };
    isReady = false;
    isSaving = false;
    hasUnsavedChanges = false;

    cancelExternalDoctorCreate = null;

    initialize = () => {
        this.clear();
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            if (that.hasUnsavedChanges) {
                const option = toJS(that.data);

                api.ExternalDoctors.create(option, (c) => { that.cancelExternalDoctorCreate = c })
                    .then(() => {
                        that.hasUnsavedChanges = false;
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                that.hasUnsavedChanges = false;
                that.isSaving = false;
                resolve();
            }
        })
    }

    clear = () => {
        this.id = null;
        this.data.firstName = null;        
        this.data.lastName = null;        
        this.data.suffix = null;
        this.data.billingNumber = null;
        this.data.phoneNumber = null;
        this.data.faxNumber = null;
        this.data.emailAddress = null;
        this.data.externalClinic = null;
        this.isReady = false;
        this.isSaving = false;
        this.hasUnsavedChanges = false;

        if (fn.isFunction(this.cancelExternalDoctorCreate)) {
            this.cancelExternalDoctorCreate();
            this.cancelExternalClinicCreate = null;
        }
    }
}

decorate(ExternalDoctorCreate, {
    id: observable,
    data: observable.deep,
    isReady: observable,
    isSaving: observable,
    hasUnsavedChanges: observable,
    initialize: action,
    save: action,
    clearAddress: action,
    clear: action,
})

export default createContext(new ExternalDoctorCreate());