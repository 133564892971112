import React, { useContext, useEffect, useState, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import FadeIn from 'react-fade-in';
import momentLocalizer from 'react-widgets-moment';
import { GlobalHotKeys } from 'react-hotkeys';
import { DateTimePicker, DropdownList } from 'react-widgets'
import { toast } from 'react-toastify';
import moment, { isMoment } from 'moment';

import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import RichTextEditor from '../../_shared/RichTextEditor';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';

import ScheduleCreateStore from '../../../../stores/ScheduleCreateStore';
import ScheduleUpdateStore from '../../../../stores/ScheduleUpdateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';
import AuthStore from '../../../../stores/AuthStore';
import CacheStore from '../../../../stores/CacheStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as AccessType from '../../../../constants/accessTypes';
import * as fn from '../../../../utilities/_functions';
import * as ah from '../../../../utilities/accessHelper';
import * as oh from '../../../../utilities/operationHelper';
import * as uh from '../../../../utilities/userHelper';

import api from '../../../../api';

import './NewSchedule.scss';
moment.locale('en');
momentLocalizer();

function NewSchedule(props) {
    const schedule = useContext(ScheduleCreateStore);
    const scheduleUpdate = useContext(ScheduleUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const auth = useContext(AuthStore);
    const cache = useContext(CacheStore);
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const handleCancelSpecialDates = useRef(null);
    const pickerTimer = useRef(null);
    const [users, setUsers] = useState([]);
    const [conflicts, setConflicts] = useState([]);
    const [specialDates, setSpecialDates] = useState([]);
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [startTimePickerOpen, setStartTimePickerOpen] = useState(false);
    const [endTimePickerOpen, setEndTimePickerOpen] = useState(false);
    const [allowAcceptBookingChange, setAllowAcceptBookingChange] = useState(true);
    const [allowIsLockedChange, setAllowIsLockedChange] = useState(true);
    const [allowDateChange, setAllowDateChange] = useState(true);
    const [allowUserChange, setAllowUserChange] = useState(true);
    const [allowRepeatChange, setAllowRepeatChange] = useState(true);
    const [allowDelete, setAllowDelete] = useState(true);

    let onSuccess, onCancel, onError;

    useEffect(() => {
        if (props.extraProps) {
            if (props.extraProps.allowAcceptBookingChange != null) {
                setAllowAcceptBookingChange(props.extraProps.allowAcceptBookingChange);
            }
            if (props.extraProps.allowIsLockedChange != null) {
                setAllowIsLockedChange(props.extraProps.allowIsLockedChange);
            }
            if (props.extraProps.allowDateChange != null) {
                setAllowDateChange(props.extraProps.allowDateChange);
            }
            if (props.extraProps.allowUserChange != null) {
                setAllowUserChange(props.extraProps.allowUserChange);
            }
            if (props.extraProps.allowRepeatChange != null) {
                setAllowRepeatChange(props.extraProps.allowRepeatChange);
            }
            if (props.extraProps.allowDelete != null) {
                setAllowDelete(props.extraProps.allowDelete);
            }
        }

        setUsers(Array.from(new Set([...cache.users.filter(u => u.id === schedule.userId), ...cache.activeUsers])));
        refreshSpecialDates();
        refreshConflicts();

        return () => {
            isMounted.current = false;

            schedule.clear();
            quickDrawer.clear();

            if (pickerTimer.current) { clearTimeout(pickerTimer.current); pickerTimer.current = null; }
            if (fn.isFunction(handleCancelSpecialDates.current)) { handleCancelSpecialDates.current(); handleCancelSpecialDates.current = null; }
        }
    }, []) // eslint-disable-line

    const refreshConflicts = () => {
        setConflicts([]);
        schedule.overwriteWorkDayIds.clear();
        schedule.overwriteTimeOffIds.clear();
        schedule.workingHolidays.clear();

        if (schedule.frequency && schedule.frequency !== 'once') {
            schedule.conflict()
                .then(data => {
                    if (isMounted.current) {
                        if (data && data.length > 0) {
                            setConflicts(data);
                        } else {
                            setConflicts([]);
                        }
                    }
                })
        }
    }

    const refreshSpecialDates = () => {
        setSpecialDates([]);

        if (schedule.frequency && schedule.frequency === 'once') {
            api.SpecialDates.get(schedule.date.format('YYYY-MM-DD'), null, (c) => { handleCancelSpecialDates.current = c })
                .then(({ data }) => {
                    if (isMounted.current) {
                        if (data && data.length > 0) {
                            setSpecialDates(data);
                        } else {
                            setSpecialDates([]);
                        }
                    }
                })
        }
    }

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (schedule.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleAcceptBookingChange = event => {
        if (allowAcceptBookingChange) {
            schedule.acceptBooking = !schedule.acceptBooking;
            schedule.frequency = 'once';
            schedule.isTimeOff = !schedule.acceptBooking;
            schedule.hasUnsavedChanges = true;
        }
    }

    const handleIsTimeOffChange = event => {
        if (allowAcceptBookingChange) {
            schedule.isTimeOff = !schedule.isTimeOff;
            schedule.hasUnsavedChanges = true;
        }
    }

    const handleDatePickerDisplay = event => {
        setStartTimePickerOpen(false);
        setEndTimePickerOpen(false);
        if (!datePickerOpen) {
            setDatePickerOpen('date');
        }
    }

    const handleDatePickerChange = value => {
        const newDate = moment(value).startOf('day');
        schedule.date = newDate;
        schedule.daysOfWeek.clear();
        schedule.daysOfWeek.push(newDate.format('dddd'));
        schedule.start = moment(`${newDate.format('YYYY-MM-DD')} ${schedule.start.format('h:mm a')}`, 'YYYY-MM-DD h:mm a');
        schedule.end = moment(`${newDate.format('YYYY-MM-DD')} ${schedule.end.format('h:mm a')}`, 'YYYY-MM-DD h:mm a');
        schedule.refresh();
        schedule.hasUnsavedChanges = true;
        refreshSpecialDates();
        refreshConflicts();
        setDatePickerOpen(false);
    }

    const handleUserChange = ({ id }) => {
        schedule.userId = id;
        schedule.refresh();

        refreshConflicts();
        schedule.hasUnsavedChanges = true;
    }

    const handleStartTimePickerDisplay = event => {
        setEndTimePickerOpen(false);
        if (!startTimePickerOpen) {
            setStartTimePickerOpen('time');
            pickerTimer.current = setTimeout(() => {
                const element = document.querySelector('#startTimePicker .rw-state-selected');
                if (element) {
                    element.focus();  // UI HACK: need to pass focus to the selected item otherwise mouse scroll and then click will cause focus to trigger.
                }
            }, 500)
        }
    }

    const handleStartTimePickerChange = value => {
        const startTime = moment(value).format('h:mm a');
        const newStart = moment(`${schedule.date.format('YYYY-MM-DD')} ${startTime}`, 'YYYY-MM-DD h:mm a');
        const endTime = moment(`${schedule.date.format('YYYY-MM-DD')} ${getEndTime(schedule.end).format('h:mm a')}`, 'YYYY-MM-DD h:mm a');

        if (!schedule.start.isSame(newStart)) {
            schedule.start = newStart;
            if (newStart.isSameOrAfter(endTime)) {
                schedule.end = newStart.clone().add(15, 'minutes');
            }
            if (schedule.frequency === 'once' && allowIsLockedChange) {
                schedule.isLocked = true;
            }
            schedule.hasUnsavedChanges = true;
        }

        setStartTimePickerOpen(false);
    }

    const handleEndTimePickerDisplay = event => {
        setStartTimePickerOpen(false);
        if (!endTimePickerOpen) {
            setEndTimePickerOpen('time');
            pickerTimer.current = setTimeout(() => {
                const element = document.querySelector('#endTimePicker .rw-state-selected');
                if (element) {
                    element.focus(); // UI HACK: need to pass focus to the selected item otherwise mouse scroll and then click will cause focus to trigger.
                }
            }, 500)
        }
    }

    const handleEndTimePickerChange = value => {
        const endTime = moment(value).format('h:mm a');
        const newEnd = moment(`${schedule.date.format('YYYY-MM-DD')} ${endTime}`, 'YYYY-MM-DD h:mm a');
        const startTime = moment(`${schedule.date.format('YYYY-MM-DD')} ${getStartTime(schedule.start).format('h:mm a')}`, 'YYYY-MM-DD h:mm a');

        if (!schedule.end.isSame(newEnd)) {
            schedule.end = newEnd;
            if (startTime.isSameOrAfter(newEnd)) {
                schedule.start = newEnd.clone().add(-15, 'minutes');
            }
            if (schedule.frequency === 'once' && allowIsLockedChange) {
                schedule.isLocked = true;
            }
            schedule.hasUnsavedChanges = true;
        }
        setEndTimePickerOpen(false);
    }

    const handleScheduleTypeChange = event => {
        const frequency = event.target.value;
        schedule.frequency = frequency;
        refreshSpecialDates();
        refreshConflicts();
        schedule.hasUnsavedChanges = true;
    }

    const handleEffectiveTypeChange = (event, key) => {
        schedule.effectiveType = key;
        refreshSpecialDates();
        refreshConflicts();
        schedule.hasUnsavedChanges = true;
    }

    const handleOverwriteKeepConflict = (event, conflict) => {
        if (conflict) {
            const { id, date, type } = conflict;

            switch (type) {
                case 'TimeOff':
                    const timeOffIndex = schedule.overwriteTimeOffIds.findIndex(o => o === id);

                    if (timeOffIndex > -1) {
                        schedule.overwriteTimeOffIds.splice(timeOffIndex, 1);
                    } else {
                        schedule.overwriteTimeOffIds.push(id);
                    }
                    break;

                case 'SpecialDate':
                    const holidayDate = moment(date).format('YYYY-MM-DD');
                    const holidayIndex = schedule.workingHolidays.findIndex(o => o === holidayDate);

                    if (holidayIndex > -1) {
                        schedule.workingHolidays.splice(holidayIndex, 1);
                    } else {
                        schedule.workingHolidays.push(holidayDate);
                    }
                    break;

                case 'WorkDay':
                default:
                    const workDayIndex = schedule.overwriteWorkDayIds.findIndex(o => o === id);

                    if (workDayIndex > -1) {
                        schedule.overwriteWorkDayIds.splice(workDayIndex, 1);
                    } else {
                        schedule.overwriteWorkDayIds.push(id);
                    }
                    break;
            }

            schedule.hasUnsavedChanges = true;
        }
    }

    const handleAddBreak = event => {
        schedule.breaks.push({
            start: schedule.start.clone().add(15, 'minutes'),
            end: schedule.start.clone().add(30, 'minutes'),
        })
        schedule.hasUnsavedChanges = true;
    }

    const handleRemoveBreak = (event, index) => {
        schedule.breaks.splice(index, 1);
        schedule.hasUnsavedChanges = true;
    }

    const handleBreakTitleChange = (event, index) => {
        const value = event.target.value;
        schedule.breaks[index].title = value ? value : null;
        schedule.hasUnsavedChanges = true;
    }

    const handleBreakStartTimePickerChange = (value, index) => {
        const startTime = moment(value).format('h:mm a');
        const newStart = moment(`${schedule.date.format('YYYY-MM-DD')} ${startTime}`, 'YYYY-MM-DD h:mm a');
        schedule.breaks[index].start = newStart;
        if (newStart > schedule.breaks[index].end) {
            schedule.breaks[index].end = newStart.clone().add(1, 'hours');
        }
        schedule.hasUnsavedChanges = true;
    }

    const handleBreakEndTimePickerChange = (value, index) => {
        const endTime = moment(value).format('h:mm a');
        const newEnd = moment(`${schedule.date.format('YYYY-MM-DD')} ${endTime}`, 'YYYY-MM-DD h:mm a');
        schedule.breaks[index].end = newEnd;
        schedule.hasUnsavedChanges = true;
    }

    const handleDaysOfWeekChange = event => {
        const dayOfWeek = event.target.value;
        const index = schedule.daysOfWeek.findIndex(d => d === dayOfWeek);

        if ((schedule.daysOfWeek.length === 1 && schedule.daysOfWeek[0] !== dayOfWeek) || schedule.daysOfWeek.length > 1) {
            if (index === -1) {
                schedule.daysOfWeek.push(dayOfWeek);
            } else {
                schedule.daysOfWeek.splice(index, 1);
            }
        }

        refreshConflicts();
        schedule.hasUnsavedChanges = true;
    }

    const handleNoteChange = (content, delta, source, editor) => {
        const note = editor.getText().replace(/(\r\n|\n|\r)/g, ' ').trim();
        const noteHtml = (content === '<p><br></p>') ? null : content;

        schedule.note = note;
        schedule.noteHtml = noteHtml;
        schedule.hasUnsavedChanges = true;
    }

    const handleDelete = event => {
        if (schedule.isExisting) {
            scheduleUpdate.initialize(schedule.userId, schedule.date.clone().startOf('day'))
                .then(() => {
                    quickDrawer.activateQuickDrawer('schedule', 'delete', null, handleDeleteScheduleSuccess, handleDeleteScheduleCancel, handleDeleteScheduleError)
                        .then(drawer => {
                            if (isMounted.current) {
                                focusTimer.current = setTimeout(() => {
                                    quickDrawerFocus(drawer);
                                }, 100);
                            }
                        });
                })
        }
    }

    const handleDeleteScheduleSuccess = () => {
        onSuccess = quickDrawer.success1Callback;
        onCancel = quickDrawer.cancel1Callback;
        onError = quickDrawer.error1Callback;

        quickDrawer.deactivateQuickDrawer1();
        if (fn.isFunction(onSuccess)) {
            onSuccess('deleted');
            onSuccess = null;
            onCancel = null;
            onError = null;
        }

        toast.dark(() => <p data-schd-del>Schedule deleted.</p>);
        scheduleUpdate.clear();
    }

    const handleDeleteScheduleCancel = () => {
        if (fn.isFunction(onCancel)) {
            onCancel();
            onSuccess = null;
            onCancel = null;
            onError = null;
        }
        scheduleUpdate.clear();
    }

    const handleDeleteScheduleError = () => {
        if (fn.isFunction(onError)) {
            onError();
            onSuccess = null;
            onCancel = null;
            onError = null;
        }
        scheduleUpdate.clear();
    }

    const handleSubmit = event => {
        event.preventDefault();
        const updated = schedule.hasUnsavedChanges;

        schedule.save()
            .then(() => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        const user = users.filter(u => u.id === schedule.userId)[0];
                        props.onSuccess(event, { updated: updated, user: user });
                    }
                }
            })
    }

    const renderEffectiveUntilRadio = (key, text, value) => {
        return <div className='custom-control custom-radio mb-2'>
            <input
                id={`effective-until-${key}`}
                type='radio'
                name={`effective-until`}
                className='custom-control-input'
                disabled={schedule.isExpired}
                checked={schedule.effectiveType === key}
                onChange={e => { handleEffectiveTypeChange(e, key) }}
            />
            <label
                htmlFor={`effective-until-${key}`}
                className={'custom-control-label' + (schedule.effectiveType === key ? '' : ' text-gray-700')}
            >
                {text}
            </label>
        </div>
    }

    const renderWorkDayTimeOffConflict = (conflict, index) => {
        const willOverwrite = schedule.overwriteWorkDayIds.some(o => o === conflict.id) || schedule.overwriteTimeOffIds.some(o => o === conflict.id);

        return <>
            <div className='flex-1'>
                <div>
                    <strong className={(willOverwrite ? 'text-gray-500 text-strike' : 'text-danger')}>
                        {
                            conflict.type === 'WorkDay' ? <>{moment(conflict.date).format('ddd MMM D, YYYY')}{conflict.acceptBooking ? <> @ {fn.formatTime(moment(conflict.startDateTime), moment(conflict.endDateTime), true)}</> : <> - Not accept bookings</>}</> : null
                        }
                        {
                            conflict.type === 'TimeOff' ? <>{moment(conflict.date).format('ddd MMM D, YYYY')} - Time off</> : null
                        }
                    </strong>
                </div>
                {
                    conflict.type === 'WorkDay' && conflict.lockedById ?
                        <div className={(willOverwrite ? 'text-gray-500' : '')}>Last updated: {uh.getDisplayShortNameById(conflict.lockedById)}</div> : null
                }
                {
                    conflict.type === 'TimeOff' && conflict.createdById ?
                        <div className={(willOverwrite ? 'text-gray-500' : '')}>Last updated: {uh.getDisplayShortNameById(conflict.createdById)}</div> : null
                }
                {
                    conflict.note ?
                        <div className={'text-truncate text-truncate-md' + (willOverwrite ? ' text-gray-500' : '')} title={conflict.note}>Note: {conflict.note}</div> : null
                }
            </div>
            <div className='d-flex text-right'>
                <div className='custom-control custom-switch'>
                    <input
                        id={`work-day-conflict-${conflict.type}-${index}`}
                        type='checkbox'
                        name={`work-day-conflict-${conflict.type}-${index}`}
                        className='custom-control-input'
                        checked={willOverwrite}
                        onChange={e => { handleOverwriteKeepConflict(e, conflict) }}
                    />
                    <label
                        htmlFor={`work-day-conflict-${conflict.type}-${index}`}
                        className='custom-control-label'
                    >
                    </label>
                </div>
            </div>
        </>
    }

    const renderSpecialDateConflict = (conflict, index) => {
        const holidayDate = moment(conflict.date).format('YYYY-MM-DD');
        const willWork = schedule.workingHolidays.some(o => o === holidayDate);

        return <>
            <div className='flex-1'>
                <div className={(willWork ? 'text-danger' : '')}>
                    <div className='fw-500 text-truncate text-truncate-xxl'>
                        {willWork ? 'Working on ' : 'Not working on '}<span className='tt-underline'>{conflict.holiday}</span>
                    </div>
                    {moment(conflict.date).format('ddd MMM D, YYYY')}
                </div>
            </div>
            <div className='d-flex text-right'>
                <div className='custom-control custom-switch'>
                    <input
                        id={`special-date-conflict-${index}`}
                        type='checkbox'
                        name={`special-date-conflict-${index}`}
                        className='custom-control-input'
                        checked={willWork}
                        onChange={e => { handleOverwriteKeepConflict(e, conflict) }}
                    />
                    <label
                        htmlFor={`special-date-conflict-${index}`}
                        className='custom-control-label'
                    >
                    </label>
                </div>
            </div>
        </>
    }

    const getScheduleDateTime = () => {
        if (!schedule.date) return null;

        const weekday = schedule.date.format('dddd');
        const day = schedule.date.format('D');
        const ordinal = schedule.date.format('Do').replace(day, '');
        const dateHtml = `${schedule.date.format('MMMM D')}<sup>${ordinal}</sup>${((schedule.date.year() !== moment().year()) ? `, ${schedule.date.format('YYYY')}` : '')}`;
        const start = getStartTime(schedule.start).clone();
        const end = getEndTime(schedule.end).clone();
        const startTimeHtml = `${start.format('h:mm')}${(start.format('a') !== end.format('a') ? ` ${start.format('a')}` : '')}`;
        const endTimeHtml = `${end.format('h:mm')} ${end.format('a')}`;

        return <>
            <label className='mb-0'><small>
                {
                    schedule.repeatFrequency ?
                        'Starting' : 'Working'
                }
            </small></label>
            <div className='form-group'>
                <ul className='list-inline no-style m-0'>
                    <li className='list-inline-item m-0' onClick={((schedule.isExisting && schedule.isExpired) || !allowDateChange ? null : handleDatePickerDisplay)}>
                        <div className='control transparent'>
                            <div
                                className={'control-overlay' + (datePickerOpen ? '' : ' d-none')}
                                onClick={() => { setDatePickerOpen(false) }}
                            ></div>
                            <DateTimePicker
                                id='datePicker'
                                defaultOpen={false}
                                min={moment().startOf('day').toDate()}
                                open={datePickerOpen}
                                dateFormat={dt => String(dt.getDate())}
                                dayFormat={day => ['S', 'M', 'T', 'W', 'T', 'F', 'S'][day.getDay()]}
                                views={['month', 'year']}
                                value={schedule.date.toDate()}
                                footer={false}
                                date={true}
                                time={false}
                                onSelect={handleDatePickerChange}
                            />
                        </div>
                        <div className={'text' + (datePickerOpen ? ' active' : '')}>
                            <small className='weekday'>{weekday}</small>
                            <span className='date' dangerouslySetInnerHTML={{ __html: dateHtml }}></span>
                        </div>
                    </li>
                    <li className='list-inline-item my-0 mx-1'><small>@</small></li>
                    <li className={'list-inline-item m-0'} onClick={(schedule.isExisting && schedule.isExpired ? null : handleStartTimePickerDisplay)}>
                        <div className='control transparent'>
                            <div
                                className={'control-overlay' + (startTimePickerOpen ? '' : ' d-none')}
                                onClick={() => { setStartTimePickerOpen(false) }}
                            ></div>
                            <DateTimePicker
                                id='startTimePicker'
                                defaultOpen={false}
                                open={startTimePickerOpen}
                                value={getStartTime(schedule.start).toDate()}
                                step={10}
                                date={false}
                                time={true}
                                onSelect={handleStartTimePickerChange}
                            />
                        </div>
                        <div className={'text' + (startTimePickerOpen ? ' active' : '')}>
                            <span className='time' dangerouslySetInnerHTML={{ __html: startTimeHtml }}></span>
                        </div>
                    </li>
                    <li className={'list-inline-item m-0'}><span className='px-1'>to</span></li>
                    <li className={'list-inline-item m-0'} onClick={(schedule.isExisting && schedule.isExpired ? null : handleEndTimePickerDisplay)}>
                        <div className='control transparent'>
                            <div
                                className={'control-overlay' + (endTimePickerOpen ? '' : ' d-none')}
                                onClick={() => { setEndTimePickerOpen(false) }}
                            ></div>
                            <DateTimePicker
                                id='endTimePicker'
                                defaultOpen={false}
                                open={endTimePickerOpen}
                                value={getEndTime(schedule.end).toDate()}
                                step={5}
                                date={false}
                                time={true}
                                onSelect={handleEndTimePickerChange}
                            />
                        </div>
                        <div className={'text' + (endTimePickerOpen ? ' active' : '')}>
                            <span className='time' dangerouslySetInnerHTML={{ __html: endTimeHtml }}></span>
                        </div>
                    </li>
                </ul>
            </div>
        </>
    }

    const getDaysOfWeekText = () => {
        let text;
        const daysOfWeek = [...toJS(schedule.daysOfWeek)];

        if (daysOfWeek.length === 0) {
            text = schedule.date.clone().format('dddd');
        }
        else if (daysOfWeek.length === 1) {
            text = daysOfWeek[0];
        }
        else {
            text = `${daysOfWeek
                .sort((a, b) => {
                    return moment(a, 'dddd').weekday() - moment(b, 'dddd').weekday();
                })
                .join(', ')
                .replace(/,([^,]*)$/, ' and$1')
                }`;
        }

        return text;
    }

    const getStartTime = (start) => {
        if (start && isMoment(start)) {
            const time = start.clone();
            const startTime = moment().startOf('day').hours(time.hour()).minutes(time.minute());
            return startTime;
        }
        return null;
    }

    const getEndTime = (end) => {
        if (end && isMoment(end)) {
            const time = end.clone();
            const endTime = moment().startOf('day').hours(time.hour()).minutes(time.minute());
            return endTime;
        }
        return null;
    }

    return <>
        <form onSubmit={handleSubmit}>
            <Observer>{() =>
                <>
                    {
                        (startTimePickerOpen || endTimePickerOpen) ?
                            <GlobalHotKeys
                                keyMap={{
                                    closeAllPickers: ['esc'],
                                }}
                                handlers={{
                                    closeAllPickers: event => {
                                        setStartTimePickerOpen(false);
                                        setEndTimePickerOpen(false);
                                    }
                                }}
                                allowChanges={true}
                            /> :
                            <>
                                {
                                    (props.drawer === quickDrawer.drawerOpened) ?
                                        <GlobalHotKeys
                                            keyMap={{
                                                close: ['esc'],
                                            }}
                                            handlers={{
                                                close: event => {
                                                    handleCancel(event)
                                                },
                                            }}
                                            allowChanges={true}
                                        /> : null
                                }
                            </>
                    }
                    <fieldset>
                        <div className='quick-drawer'>
                            <QuickDrawerHeader
                                drawer={props.drawer}
                                icon={oh.getIcon('schedule', 'new')}
                                action={schedule.isExisting ? 'Update' : 'Create'}
                                category='Schedule'
                                className={'schedules'}
                                onCancel={handleCancel}
                            />
                            <div className='quick-drawer-body'>
                                {
                                    schedule.isReady ? <>
                                        <FadeIn>
                                            <div className='new-schedule body-content'>
                                                {
                                                    schedule.isExpired || (specialDates && specialDates.length > 0) ?
                                                        <section>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className={'alert mb-0 alert-warning'} role='alert'>
                                                                        <strong className='d-block mb-2'>Important</strong>
                                                                        <ul className='pl-3 mb-0'>
                                                                            {
                                                                                schedule.isExpired ?
                                                                                    <li>Schedule locked. This is in the past.</li> : null
                                                                            }
                                                                            {
                                                                                specialDates.map((d, di) => {
                                                                                    return <li key={`special-date-${di}`}>{d.name}{d.isHoliday ? <span className='badge bg-danger-700 text-white text-shadow-0 mx-o ml-1'>Holiday</span> : <></>}</li>
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section> : null
                                                }
                                                {
                                                    (uh.getById(schedule.userId).acceptBooking && allowAcceptBookingChange) ?
                                                        <section>
                                                            <div className='row'>
                                                                <div className='col-6'>
                                                                    <div className='form-group'>
                                                                        <div className='custom-control custom-switch'>
                                                                            <input
                                                                                id='schedule-accept-booking'
                                                                                type='checkbox'
                                                                                name='schedule-accept-booking'
                                                                                className='custom-control-input'
                                                                                disabled={schedule.isExpired}
                                                                                checked={schedule.acceptBooking}
                                                                                onChange={handleAcceptBookingChange}
                                                                            />
                                                                            <label
                                                                                htmlFor='schedule-accept-booking'
                                                                                className='custom-control-label'
                                                                            >
                                                                                {
                                                                                    schedule.acceptBooking ?
                                                                                        <strong>Accept bookings</strong> :
                                                                                        <strong><span className='fw-sm text-danger'>Not accept bookings</span></strong>
                                                                                }
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    !schedule.acceptBooking ?
                                                                        <div className='col-6'>
                                                                            <div className='form-group'>
                                                                                <div className='custom-control custom-switch'>
                                                                                    <input
                                                                                        id='schedule-time-off'
                                                                                        type='checkbox'
                                                                                        name='schedule-time-off'
                                                                                        className='custom-control-input'
                                                                                        disabled={schedule.isExpired}
                                                                                        checked={schedule.isTimeOff}
                                                                                        onChange={handleIsTimeOffChange}
                                                                                    />
                                                                                    <label
                                                                                        htmlFor='schedule-time-off'
                                                                                        className='custom-control-label'
                                                                                    >
                                                                                        {
                                                                                            schedule.isTimeOff ?
                                                                                                <strong>Time off</strong> :
                                                                                                <strong>In office</strong>
                                                                                        }
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div> : null
                                                                }
                                                            </div>
                                                        </section> : null
                                                }
                                                <section className='user'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <label><small>For</small></label>
                                                            <Observer>{() =>
                                                                <DropdownList
                                                                    className={!allowUserChange ? 'rw-state-disabled-override' : null}
                                                                    data={ah.check(AccessType.UPDATE_OTHER_SCHEDULE) ? users : users.filter(u => u.id === auth.currentUser.id)}
                                                                    valueField='id'
                                                                    value={schedule.userId}
                                                                    disabled={schedule.isExpired || !allowUserChange}
                                                                    itemComponent={({ item }) => (
                                                                        <div
                                                                            className='profile-wrapper'
                                                                        >
                                                                            <div className='profile'>
                                                                                <span
                                                                                    className={`profile-image rounded-circle fw-500 d-block` + (item && !item.profilePictureUri ? ` profile-initials bg-color${item.color}-500` : '')}
                                                                                    style={item && item.profilePictureUri ? {
                                                                                        backgroundImage: `url(${item.profilePictureUri})`,
                                                                                        backgroundSize: 'cover',
                                                                                    } : null}
                                                                                    title={item ? item.fullName : 'System'}>
                                                                                    {item && !item.profilePictureUri ? <div className='d-initials'>{item.initials}</div> : null}
                                                                                </span>
                                                                            </div>
                                                                            <span className='description'>{item.fullName}</span>
                                                                        </div>
                                                                    )}
                                                                    valueComponent={({ item }) => (
                                                                        item ?
                                                                            <div
                                                                                className='profile-wrapper'
                                                                            >
                                                                                <div className='profile'>
                                                                                    <span
                                                                                        className={`profile-image rounded-circle fw-500 d-block` + (item && !item.profilePictureUri ? ` profile-initials bg-color${item.color}-500` : '')}
                                                                                        style={item && item.profilePictureUri ? {
                                                                                            backgroundImage: `url(${item.profilePictureUri})`,
                                                                                            backgroundSize: 'cover',
                                                                                        } : null}
                                                                                        title={item ? item.fullName : 'System'}>
                                                                                        {item && !item.profilePictureUri ? <div className='d-initials'>{item.initials}</div> : null}
                                                                                    </span>
                                                                                </div>
                                                                                <span className='description'>{item.fullName}</span>
                                                                            </div> : null
                                                                    )}
                                                                    onChange={allowUserChange ? handleUserChange : null}
                                                                />
                                                            }</Observer>
                                                        </div>
                                                    </div>
                                                </section>
                                                <Observer>{() =>
                                                    <>
                                                        {
                                                            // (!uh.getById(schedule.userId).acceptBooking || schedule.acceptBooking) && getStartTime(schedule.start) && getEndTime(schedule.end) ?
                                                            !schedule.isTimeOff && getStartTime(schedule.start) && getEndTime(schedule.end) ?

                                                                <>
                                                                    <section className='date-time'>
                                                                        <div className='row'>
                                                                            <div className='col-12'>
                                                                                <Observer>{() =>
                                                                                    <>{getScheduleDateTime()}</>
                                                                                }</Observer>
                                                                            </div>
                                                                        </div>
                                                                    </section>
                                                                    {
                                                                        allowRepeatChange ? <>
                                                                            <section>
                                                                                <div className='row'>
                                                                                    <div className='col-12'>
                                                                                        <label><small>Repeat</small></label>
                                                                                        <div className='form-group'>
                                                                                            <div>
                                                                                                <Observer>{() =>
                                                                                                    <select
                                                                                                        className={'custom-select form-control'}
                                                                                                        value={schedule.frequency}
                                                                                                        disabled={schedule.isExpired}
                                                                                                        onChange={handleScheduleTypeChange}
                                                                                                    >
                                                                                                        <option value='once'>No repeat</option>
                                                                                                        <option value='every'>Every {getDaysOfWeekText()}</option>
                                                                                                        <option value='everyOther'>Every other {getDaysOfWeekText()}</option>
                                                                                                        <option value='every3Weeks'>Every 3 weeks on {getDaysOfWeekText()}</option>
                                                                                                        <option value='every4Weeks'>Every 4 weeks on {getDaysOfWeekText()}</option>
                                                                                                    </select>
                                                                                                }</Observer>
                                                                                                <div className={'row my-2 days-of-week' + (schedule.frequency === 'every' || schedule.frequency === 'everyOther' ? '' : ' d-none')}>
                                                                                                    <div className='col'>
                                                                                                        <Observer>{() =>
                                                                                                            <div className='custom-control custom-checkbox mb-2'>
                                                                                                                <input
                                                                                                                    id='schedule-day-of-week-sunday'
                                                                                                                    type='checkbox'
                                                                                                                    name='schedule-day-of-week'
                                                                                                                    className='custom-control-input'
                                                                                                                    value='Sunday'
                                                                                                                    disabled={schedule.isExpired}
                                                                                                                    checked={schedule.daysOfWeek.some(d => d === 'Sunday')}
                                                                                                                    onChange={handleDaysOfWeekChange}
                                                                                                                />
                                                                                                                <label
                                                                                                                    htmlFor='schedule-day-of-week-sunday'
                                                                                                                    className='custom-control-label'
                                                                                                                >
                                                                                                                    <br /><small>Sun</small>
                                                                                                                </label>
                                                                                                            </div>
                                                                                                        }</Observer>
                                                                                                    </div>
                                                                                                    <div className='col'>
                                                                                                        <Observer>{() =>
                                                                                                            <div className='custom-control custom-checkbox mb-2'>
                                                                                                                <input
                                                                                                                    id='schedule-day-of-week-monday'
                                                                                                                    type='checkbox'
                                                                                                                    name='schedule-day-of-week'
                                                                                                                    className='custom-control-input'
                                                                                                                    value='Monday'
                                                                                                                    disabled={schedule.isExpired}
                                                                                                                    checked={schedule.daysOfWeek.some(d => d === 'Monday')}
                                                                                                                    onChange={handleDaysOfWeekChange}
                                                                                                                />
                                                                                                                <label
                                                                                                                    htmlFor='schedule-day-of-week-monday'
                                                                                                                    className='custom-control-label'
                                                                                                                >
                                                                                                                    <br /><small>Mon</small>
                                                                                                                </label>
                                                                                                            </div>
                                                                                                        }</Observer>
                                                                                                    </div>
                                                                                                    <div className='col'>
                                                                                                        <Observer>{() =>
                                                                                                            <div className='custom-control custom-checkbox mb-2'>
                                                                                                                <input
                                                                                                                    id='schedule-day-of-week-tuesday'
                                                                                                                    type='checkbox'
                                                                                                                    name='schedule-day-of-week'
                                                                                                                    className='custom-control-input'
                                                                                                                    value='Tuesday'
                                                                                                                    disabled={schedule.isExpired}
                                                                                                                    checked={schedule.daysOfWeek.some(d => d === 'Tuesday')}
                                                                                                                    onChange={handleDaysOfWeekChange}
                                                                                                                />
                                                                                                                <label
                                                                                                                    htmlFor='schedule-day-of-week-tuesday'
                                                                                                                    className='custom-control-label'
                                                                                                                >
                                                                                                                    <br /><small>Tue</small>
                                                                                                                </label>
                                                                                                            </div>
                                                                                                        }</Observer>
                                                                                                    </div>
                                                                                                    <div className='col'>
                                                                                                        <Observer>{() =>
                                                                                                            <div className='custom-control custom-checkbox mb-2'>
                                                                                                                <input
                                                                                                                    id='schedule-day-of-week-wednesday'
                                                                                                                    type='checkbox'
                                                                                                                    name='schedule-day-of-week'
                                                                                                                    className='custom-control-input'
                                                                                                                    value='Wednesday'
                                                                                                                    disabled={schedule.isExpired}
                                                                                                                    checked={schedule.daysOfWeek.some(d => d === 'Wednesday')}
                                                                                                                    onChange={handleDaysOfWeekChange}
                                                                                                                />
                                                                                                                <label
                                                                                                                    htmlFor='schedule-day-of-week-wednesday'
                                                                                                                    className='custom-control-label'
                                                                                                                >
                                                                                                                    <br /><small>Wed</small>
                                                                                                                </label>
                                                                                                            </div>
                                                                                                        }</Observer>
                                                                                                    </div>
                                                                                                    <div className='col'>
                                                                                                        <Observer>{() =>
                                                                                                            <div className='custom-control custom-checkbox mb-2'>
                                                                                                                <input
                                                                                                                    id='schedule-day-of-week-thursday'
                                                                                                                    type='checkbox'
                                                                                                                    name='schedule-day-of-week'
                                                                                                                    className='custom-control-input'
                                                                                                                    value='Thursday'
                                                                                                                    disabled={schedule.isExpired}
                                                                                                                    checked={schedule.daysOfWeek.some(d => d === 'Thursday')}
                                                                                                                    onChange={handleDaysOfWeekChange}
                                                                                                                />
                                                                                                                <label
                                                                                                                    htmlFor='schedule-day-of-week-thursday'
                                                                                                                    className='custom-control-label'
                                                                                                                >
                                                                                                                    <br /><small>Thu</small>
                                                                                                                </label>
                                                                                                            </div>
                                                                                                        }</Observer>
                                                                                                    </div>
                                                                                                    <div className='col'>
                                                                                                        <Observer>{() =>
                                                                                                            <div className='custom-control custom-checkbox mb-2'>
                                                                                                                <input
                                                                                                                    id='schedule-day-of-week-friday'
                                                                                                                    type='checkbox'
                                                                                                                    name='schedule-day-of-week'
                                                                                                                    className='custom-control-input'
                                                                                                                    value='Friday'
                                                                                                                    disabled={schedule.isExpired}
                                                                                                                    checked={schedule.daysOfWeek.some(d => d === 'Friday')}
                                                                                                                    onChange={handleDaysOfWeekChange}
                                                                                                                />
                                                                                                                <label
                                                                                                                    htmlFor='schedule-day-of-week-friday'
                                                                                                                    className='custom-control-label'
                                                                                                                >
                                                                                                                    <br /><small>Fri</small>
                                                                                                                </label>
                                                                                                            </div>
                                                                                                        }</Observer>
                                                                                                    </div>
                                                                                                    <div className='col'>
                                                                                                        <Observer>{() =>
                                                                                                            <div className='custom-control custom-checkbox mb-2'>
                                                                                                                <input
                                                                                                                    id='schedule-day-of-week-saturday'
                                                                                                                    type='checkbox'
                                                                                                                    name='schedule-day-of-week'
                                                                                                                    className='custom-control-input'
                                                                                                                    value='Saturday'
                                                                                                                    disabled={schedule.isExpired}
                                                                                                                    checked={schedule.daysOfWeek.some(d => d === 'Saturday')}
                                                                                                                    onChange={handleDaysOfWeekChange}
                                                                                                                />
                                                                                                                <label
                                                                                                                    htmlFor='schedule-day-of-week-saturday'
                                                                                                                    className='custom-control-label'
                                                                                                                >
                                                                                                                    <br /><small>Sat</small>
                                                                                                                </label>
                                                                                                            </div>
                                                                                                        }</Observer>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </section>
                                                                            <Observer>{() =>
                                                                                <>
                                                                                    {
                                                                                        schedule.frequency !== 'once' ?
                                                                                            <>
                                                                                                <section>
                                                                                                    <div className='row'>
                                                                                                        <div className='col-12'>
                                                                                                            <label><small>Effective</small></label>
                                                                                                            <div className='form-group'>
                                                                                                                <div className='row pl-2'>
                                                                                                                    <div className='col-12'>
                                                                                                                        {renderEffectiveUntilRadio('next4Weeks', 'for the next 4 weeks', schedule.date.clone().startOf('day').add(4, 'weeks').format())}
                                                                                                                    </div>
                                                                                                                    <div className='col-12'>
                                                                                                                        {renderEffectiveUntilRadio('next8Weeks', 'for the next 8 weeks', schedule.date.clone().startOf('day').add(8, 'weeks').format())}
                                                                                                                    </div>
                                                                                                                    <div className='col-12'>
                                                                                                                        {renderEffectiveUntilRadio('endOfThisMonth', `until end of ${schedule.date.clone().format('MMMM')}`, schedule.date.clone().startOf('day').endOf('month').format())}
                                                                                                                    </div>
                                                                                                                    <div className='col-12'>
                                                                                                                        {renderEffectiveUntilRadio('endOfNextMonth', `until end of ${schedule.date.clone().add(1, 'months').format('MMMM')}`, schedule.date.clone().add(1, 'months').startOf('day').endOf('month').format())}
                                                                                                                    </div>
                                                                                                                    <div className='col-12'>
                                                                                                                        {renderEffectiveUntilRadio('endOfYear', `until end of ${schedule.date.clone().format('YYYY')}`, schedule.date.clone().startOf('day').endOf('year').format())}
                                                                                                                    </div>
                                                                                                                    <div className='col-12'>
                                                                                                                        {renderEffectiveUntilRadio('forever', 'from now on', '')}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </section>
                                                                                            </> : null
                                                                                    }
                                                                                </>
                                                                            }</Observer>
                                                                        </> : null
                                                                    }
                                                                    {
                                                                        schedule.frequency !== 'once' && conflicts && conflicts.some(c => ['WorkDay', 'TimeOff'].some(t => c.type === t)) ?
                                                                            <section className='conflicts'>
                                                                                <div className='row'>
                                                                                    <Observer>{() =>
                                                                                        <div className='col-12'>
                                                                                            <label><small>Conflicts</small></label>
                                                                                            {
                                                                                                conflicts.map((c, ci) => {
                                                                                                    return <div
                                                                                                        key={`conflict_${ci}`}
                                                                                                        className='d-flex w-100 mb-2'
                                                                                                    >
                                                                                                        {renderWorkDayTimeOffConflict(c, ci)}
                                                                                                    </div>
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    }</Observer>
                                                                                </div>
                                                                            </section> : null
                                                                    }
                                                                    {
                                                                        schedule.frequency !== 'once' && schedule.effectiveType !== 'forever' && conflicts && conflicts.some(c => ['SpecialDate'].some(t => c.type === t)) ?
                                                                            <section className='conflicts'>
                                                                                <div className='row'>
                                                                                    <Observer>{() =>
                                                                                        <div className='col-12'>
                                                                                            <label><small>Holidays</small></label>
                                                                                            {
                                                                                                conflicts.map((c, ci) => {
                                                                                                    return <div
                                                                                                        key={`conflict_${ci}`}
                                                                                                        className='d-flex w-100 mb-2'
                                                                                                    >
                                                                                                        {renderSpecialDateConflict(c, ci)}
                                                                                                    </div>
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    }</Observer>
                                                                                </div>
                                                                            </section> : null
                                                                    }
                                                                    {
                                                                        schedule.frequency !== 'once' && schedule.effectiveType === 'forever' ?
                                                                            <section className='conflicts'>
                                                                                <div className='row'>
                                                                                    <Observer>{() =>
                                                                                        <div className='col-12'>
                                                                                            <label><small>Holidays</small></label>
                                                                                            <div className='text-danger'>
                                                                                                <strong>Schedule will not be opened on <span className='tt-underline'>all holidays</span>.</strong>
                                                                                            </div>
                                                                                        </div>
                                                                                    }</Observer>
                                                                                </div>
                                                                            </section> : null
                                                                    }
                                                                    <section className='breaks'>
                                                                        <div className='row'>
                                                                            <Observer>{() =>
                                                                                <div className='col-12'>
                                                                                    <label><small>Breaks</small></label>
                                                                                    {
                                                                                        schedule.breaks && schedule.breaks.length > 0 ?
                                                                                            schedule.breaks.map((b, bi) => {
                                                                                                return <div
                                                                                                    key={`break_${bi}`}
                                                                                                >
                                                                                                    <ul
                                                                                                        className='list-inline no-style m-0 mb-1'
                                                                                                    >
                                                                                                        <li className='list-inline-item m-0 mr-1 number'>{bi + 1}.&nbsp;</li>
                                                                                                        <li className='list-inline-item m-0 title'>
                                                                                                            <input
                                                                                                                type='text'
                                                                                                                className='form-control form-control-sm'
                                                                                                                placeholder='Title'
                                                                                                                maxLength={150}
                                                                                                                disabled={schedule.isExpired}
                                                                                                                value={(b.title ? b.title : '')}
                                                                                                                onChange={e => { handleBreakTitleChange(e, bi) }}
                                                                                                            />
                                                                                                        </li>
                                                                                                        <li className='list-inline-item m-0 ml-2'>
                                                                                                            <button
                                                                                                                type='button'
                                                                                                                className='btn btn-link btn-icon btn-sm'
                                                                                                                disabled={schedule.isExpired}
                                                                                                                onClick={e => { handleRemoveBreak(e, bi) }}
                                                                                                            >
                                                                                                                <i className='fal fa-minus-circle text-danger'></i>
                                                                                                            </button>
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                    <ul
                                                                                                        className='list-inline no-style m-0 mb-2'
                                                                                                    >
                                                                                                        <li className='list-inline-item m-0 mr-1 number'></li>
                                                                                                        <li className='list-inline-item m-0 time'>
                                                                                                            <DateTimePicker
                                                                                                                value={getStartTime(b.start).toDate()}
                                                                                                                step={15}
                                                                                                                date={false}
                                                                                                                time={true}
                                                                                                                min={getStartTime(schedule.start).clone().add(15, 'minutes').toDate()}
                                                                                                                max={getEndTime(schedule.end).clone().add(-30, 'minutes').toDate()}
                                                                                                                disabled={schedule.isExpired}
                                                                                                                onChange={value => { handleBreakStartTimePickerChange(value, bi) }}
                                                                                                            />
                                                                                                        </li>
                                                                                                        <li className='list-inline-item m-0 mx-2'>to</li>
                                                                                                        <li className='list-inline-item m-0 time'>
                                                                                                            <DateTimePicker
                                                                                                                value={getEndTime(b.end).toDate()}
                                                                                                                step={15}
                                                                                                                date={false}
                                                                                                                time={true}
                                                                                                                min={b.start.clone().add(15, 'minutes').toDate()}
                                                                                                                max={getEndTime(schedule.end).clone().add(-15, 'minutes').toDate()}
                                                                                                                disabled={schedule.isExpired}
                                                                                                                onChange={value => { handleBreakEndTimePickerChange(value, bi) }}
                                                                                                            />
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </div>
                                                                                            }) :
                                                                                            <p className='text-gray-700 mb-2'><em>No breaks has been scheduled</em></p>

                                                                                    }
                                                                                    <button
                                                                                        type='button'
                                                                                        className='btn btn-link btn-icon-left btn-sm'
                                                                                        disabled={schedule.isExpired}
                                                                                        onClick={handleAddBreak}
                                                                                    >
                                                                                        <i className='fal fa-plus-circle'></i> Add break
                                                                                    </button>
                                                                                </div>
                                                                            }</Observer>
                                                                        </div>
                                                                    </section>
                                                                </> : null
                                                        }
                                                        {
                                                            !schedule.acceptBooking || schedule.frequency === 'once' ?
                                                                <section>
                                                                    <div className='row'>
                                                                        <div className='col-12'>
                                                                            <div className='form-group'>
                                                                                <label><small>Note</small></label>
                                                                                <div className='input-group'>
                                                                                    <Observer>{() =>
                                                                                        <RichTextEditor
                                                                                            mode='basic'
                                                                                            value={schedule.noteHtml}
                                                                                            disableTab={true}
                                                                                            readOnly={schedule.isExpired}
                                                                                            onChange={handleNoteChange}
                                                                                        />
                                                                                    }</Observer>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </section> : null
                                                        }
                                                    </>
                                                }</Observer>
                                            </div>
                                        </FadeIn>
                                    </> : renderQuickDrawerLoading()
                                }
                            </div>
                            <div className='quick-drawer-action'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='float-right'>
                                            {
                                                schedule.isExisting && !schedule.isExpired && allowDelete ?
                                                    <button
                                                        type='button'
                                                        className='btn btn-danger mr-2'
                                                        onClick={handleDelete}
                                                    >Delete</button> :
                                                    <button
                                                        type='button'
                                                        className='btn btn-link btn-cancel mr-2'
                                                        onClick={handleCancel}
                                                    >Cancel</button>
                                            }
                                            <button
                                                type='submit'
                                                className='btn btn-success'
                                                disabled={schedule.isExpired}
                                            >Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </>
            }</Observer>
        </form>
    </>
}

export default NewSchedule;