import { createContext } from 'react';
import { decorate, observable, action, toJS, computed } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class WorkOrderTemplateUpdate {
    id = null;
    data = null;
    isSaving = false;
    isLoading = false;
    hasUnsavedChanges = false;

    cancelWorkOrderPublishedTemplateCreate = null;
    cancelWorkOrderTemplateGet = null;
    cancelWorkOrderTemplateUpdate = null;

    load = id => {
        const that = this;

        this.clear();
        this.id = id;

        return new Promise((resolve) => {
            that.refresh()
                .finally(() => {
                    that.isReady = true;
                    resolve();
                });
        })
    }

    refresh = () => {
        const that = this;
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            api.WorkOrderTemplates.get(that.id, (c) => { that.cancelWorkOrderTemplateGet = c })
                .then(({ data }) => {
                    that.data = data;
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isLoading = false;
                })
        })
    }

    sortDefinition = () => {
        if (this.data && this.data.definition && this.data.definition.length > 0) {
            const definition = toJS(this.data.definition)
                .sort((a, b) => {
                    if (a.position.y === b.position.y) {
                        return a.position.x > b.position.x ? 1 : -1;
                    }

                    return a.position.y > b.position.y ? 1 : -1;
                })

            this.data.definition = definition;
        }
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            if (that.hasUnsavedChanges) {
                that.sortDefinition();
                let option = toJS(that.data);

                if (that.hasUnsavedChanges) {
                    api.WorkOrderTemplates.update(that.id, option, (c) => { that.cancelWorkOrderTemplateUpdate = c })
                        .then(() => {
                            that.hasUnsavedChanges = false;
                            resolve(option);
                        })
                        .catch(() => {
                            reject();
                        })
                        .finally(() => {
                            that.isSaving = false;
                        })
                } else {
                    that.hasUnsavedChanges = false;
                    that.isSaving = false;
                    resolve(option);
                }
            }
        })
    }

    publish = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            api.WorkOrderPublishedTemplates.create({ workOrderTemplateId: that.id }, (c) => { that.cancelWorkOrderPublishedTemplateCreate = c })
                .then(() => {
                    that.hasUnsavedChanges = false;
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    clear = () => {
        this.id = null;
        this.data = null;
        this.isReady = false;
        this.isSaving = false;
        this.isLoading = false;
        this.hasUnsavedChanges = false;

        if (fn.isFunction(this.cancelWorkOrderPublishedTemplateCreate)) {
            this.cancelWorkOrderPublishedTemplateCreate();
            this.cancelWorkOrderPublishedTemplateCreate = null;
        }

        if (fn.isFunction(this.cancelWorkOrderTemplateGet)) {
            this.cancelWorkOrderTemplateGet();
            this.cancelWorkOrderTemplateGet = null;
        }

        if (fn.isFunction(this.cancelWorkOrderTemplateUpdate)) {
            this.cancelWorkOrderTemplateUpdate();
            this.cancelWorkOrderTemplateUpdate = null;
        }
    }

    get isNotFound() {
        return this.isReady && !this.data;
    }
}

decorate(WorkOrderTemplateUpdate, {
    id: observable,
    data: observable.deep,
    selectedSectionId: observable,
    isReady: observable,
    isSaving: observable,
    isLoading: observable,
    hasUnsavedChanges: observable,
    load: action,
    refresh: action,
    save: action,
    publish: action,
    sortDefinition: action,
    clear: action,
    isNotFound: computed,
})

export default createContext(new WorkOrderTemplateUpdate());