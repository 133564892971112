import { createContext } from 'react';
import { decorate, observable, action, toJS } from 'mobx';

import * as fn from '../utilities/_functions';
import api from '../api';

export class TodoCreate {
    defaultDueInMinutes = 15;

    data = {
        isHighPriority: false,
        bodyHtml: null,
        due: 'Asap',
        dueValue: null,
        dueDateTime: null,
        dueDateTimeUtc: null,
        dueInMinutes: this.defaultDueInMinutes,
        isSingleInstance: true,
        users: [],
        groups: []
    };

    hasUnsavedChanges = false;
    isReady = false;
    isSaving = false;

    cancelTodo = null;

    initialize = () => {
        this.clear();
        this.isReady = true;
    }

    resetStatus = async (id, notify) => {
        if (!!notify) {
            this.isSaving = true;
        }

        try {
            await api.Todos.resetCompletionStatus(id, (c) => { this.cancelTodo = c });
        }
        finally {
            this.isSaving = false;
        }
        this.isSaving = false;
    }

    save = async (notify) => {
        if (!!notify) {
            this.isSaving = true;
        }

        if (this.hasUnsavedChanges) {
            try {
                let option = toJS(this.data);
                
                await api.Todos.create(option, (c) => { this.cancelTodo = c });
                this.hasUnsavedChanges = false;

                return this.data;
            }
            finally {
                this.isSaving = false;
            }
        }
        this.isSaving = false;
    }

    clear = () => {
        this.data = {
            isHighPriority: false,
            bodyHtml: null,
            due: 'Asap',
            dueValue: null,
            dueDateTime: null,
            dueDateTimeUtc: null,
            dueInMinutes: this.defaultDueInMinutes,
            isSingleInstance: true,
            users: [],
            groups: []
        };

        this.hasUnsavedChanges = false;
        this.isReady = false;
        this.isSaving = false;

        if (fn.isFunction(this.cancelTodo)) {
            this.cancelTodo();
            this.cancelTodo = null;
        }

    }
}

decorate(TodoCreate, {
    data: observable.deep,
    hasUnsavedChanges: observable,
    isReady: observable,
    isSaving: observable,
    initialize: action,
    resetStatus: action,
    save: action,
    clear: action,
})

export default createContext(new TodoCreate());

