import { createContext } from 'react';
import { decorate, observable, action, computed } from 'mobx';
import moment, { isMoment } from 'moment';

import * as fn from '../utilities/_functions';
import * as ah from '../utilities/appointmentHelper';
import api from '../api';

export class AppointmentUpdate {
    id = null;
    stageId = null;
    data = null;
    originalData = null;
    rescheduleRequestBy = null;
    rescheduleRequestReason = null;
    rescheduleRequestReasonHtml = null;
    cancelRequestBy = null;
    cancelRequestReason = null;
    cancelRequestReasonHtml = null;
    repeatUpdateFollowing = false;
    repeatUpdateUntil = null;
    repeatUpdateRemaining = null;
    deleteAndExtend = null;
    hasUnsavedChanges = false;
    isLoading = false;
    isSaving = false;
    isReady = false;
    cancelAppointmentGet = null;
    cancelAppointmentStage = null;
    cancelAppointmentTimeline = null;
    cancelAppointmentStatus = null;
    cancelAppointmentDelete = null;
    cancelAppointmentUpdate = null;
    cancelPatientProfile = null;

    initialize = (id) => {
        const that = this;

        this.clear();
        this.id = id;
        this.isReady = false;

        return new Promise((resolve, reject) => {
            that.refresh()
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isReady = true;
                })
        })
    }

    stage = (id) => {
        const that = this;

        this.clear();
        this.stageId = id;
        this.isReady = false;
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            api.Appointments.get(
                that.stageId,
                (c) => { this.cancelAppointmentStage = c; }
            )
                .then(({ data }) => {
                    that.data = data;
                    that.originalData = data;
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isLoading = false;
                })
        })
    }

    staged = () => {
        if (this.stageId) {
            this.id = this.stageId;
            this.isReady = true;
        }

        return Promise.resolve();
    }

    refresh = () => {
        const that = this;
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            api.Appointments.get(
                that.id,
                (c) => { this.cancelAppointmentGet = c; }
            )
                .then(({ data }) => {
                    that.data = data;
                    that.originalData = data;
                    that.loadPatientProfile()
                        .then(() => {
                            resolve();
                        });
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isLoading = false;
                })
        })
    }

    timeline = (timelineItemId, notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            const timeline = that.data.timeline.filter(s => s.performedDateUtc);
            const updatedTimeline = timeline ? timeline.map(s => { return s.id; }) : [];
            const index = updatedTimeline.findIndex(s => s === timelineItemId);

            if (index < 0) {
                updatedTimeline.push(timelineItemId);
            } else {
                updatedTimeline.splice(index, 1);
            }

            api.Appointments.update(
                that.data.id,
                { timelineItems: updatedTimeline, },
                (c) => { this.cancelAppointmentTimeline = c; }
            )
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    status = (newStatus, notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            api.Appointments.status(
                that.data.id,
                { newStatus: newStatus, },
                (c) => { this.cancelAppointmentStatus = c; }
            )
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    loadPatientProfile = () => {
        const that = this;
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            if (that.data.customer.patientProfileId) {
                api.PatientProfiles.get(
                    that.data.customer.patientProfileId,
                    (c) => { that.cancelPatientProfile = c; }
                )
                    .then(({ data }) => {
                        if (that.data) {
                            that.data.customer.patientProfile = data;
                        }
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isLoading = false;
                    })
            } else {
                resolve();
            }
        })
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            if (that.hasUnsavedChanges) {
                api.Appointments.update(
                    that.id,
                    {
                        start: isMoment(that.data.start) ? that.data.start.clone().format('YYYY-MM-DDTHH:mm') : moment(that.data.start).format('YYYY-MM-DDTHH:mm'),
                        duration: that.data.duration,
                        userId: that.data.userId,
                        services: that.data.services,
                        rescheduleRequestBy: that.isReschedule ? that.rescheduleRequestBy : null,
                        rescheduleRequestReason: that.isReschedule ? that.rescheduleRequestReason : null,
                        rescheduleRequestReasonHtml: that.isReschedule ? that.rescheduleRequestReasonHtml : null,
                        repeatUpdateFollowing: that.repeatUpdateFollowing ? that.repeatUpdateFollowing : null,
                        repeatUpdateUntil: that.repeatUpdateFollowing ?
                            (isMoment(that.repeatUpdateUntil) ? that.repeatUpdateUntil.clone().format('YYYY-MM-DDTHH:mm') : moment(that.repeatUpdateUntil).format('YYYY-MM-DDTHH:mm')) :
                            null,
                    },
                    (c) => { this.cancelAppointmentUpdate = c; }
                )
                    .then(() => {
                        that.hasUnsavedChanges = false;
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                that.isSaving = false;
                that.hasUnsavedChanges = false;
                resolve();
            }
        })
    }

    delete = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            api.Appointments.delete(
                that.id,
                {
                    cancelRequestBy: that.cancelRequestBy,
                    cancelRequestReason: that.cancelRequestReason,
                    cancelRequestReasonHtml: that.cancelRequestReasonHtml,
                    repeatUpdateFollowing: that.repeatUpdateFollowing ? that.repeatUpdateFollowing : null,
                    deleteAndExtend: that.deleteAndExtend ? that.deleteAndExtend : null,
                },
                (c) => { this.cancelAppointmentDelete = c; }
            )
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    checkConflicts = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            ah.checkConflicts(that.data)
                .then((data) => {
                    resolve(data);
                })
                .catch(error => {
                    reject();
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    checkEligibility = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            ah.checkEligibility(that.data.customer, that.data)
                .then((data) => {
                    if (data && data.length > 0) {
                        for (let di = 0; di < data.length; di++) {
                            if (data[di].service.isSubsidized) {
                                const ii = that.data.services.findIndex(s => s.code === data[di].code);
                                if (ii > -1) {
                                    that.data.services[ii].isEligible = data[di].isEligible;
                                    that.data.services[ii].lastEligibilityCheckedDateUtc = moment().utc();
                                    that.data.services[ii].ineligibilityCode = data[di].ineligibilityCode;
                                    that.data.services[ii].ineligibilityReason = data[di].ineligibilityReason;
                                    that.data.services[ii].earliestEligibleDate = data[di].earliestEligibleDate ? moment(data[di].earliestEligibleDate) : null;
                                }
                            }
                        }
                    } else {
                        if (that.data.services && that.data.services.length > 0) {
                            for (let si = 0; si < that.data.services.length; si++) {
                                that.data.services[si].isEligible = null;
                                that.data.services[si].lastEligibilityCheckedDateUtc = null;
                                that.data.services[si].ineligibilityCode = null;
                                that.data.services[si].ineligibilityReason = null;
                                that.data.services[si].earliestEligibleDate = null;
                            }
                        }
                    }
                    resolve(data);
                })
                .catch(error => {
                    reject();
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    clear = () => {
        this.id = null;
        this.stageId = null;
        this.data = null;
        this.originalData = null;
        this.rescheduleRequestBy = null;
        this.rescheduleRequestReason = null;
        this.rescheduleRequestReasonHtml = null;
        this.cancelRequestBy = null;
        this.cancelRequestReason = null;
        this.cancelRequestReasonHtml = null;
        this.repeatUpdateFollowing = false;
        this.repeatUpdateUntil = null;
        this.repeatUpdateRemaining = null;
        this.deleteAndExtend = null;
        this.hasUnsavedChanges = false;
        this.isLoading = false;
        this.isSaving = false;
        this.isReady = false;

        if (fn.isFunction(this.cancelAppointmentGet)) {
            this.cancelAppointmentGet();
            this.cancelAppointmentGet = null;
        }

        if (fn.isFunction(this.cancelAppointmentStage)) {
            this.cancelAppointmentStage();
            this.cancelAppointmentStage = null;
        }

        if (fn.isFunction(this.cancelAppointmentTimeline)) {
            this.cancelAppointmentTimeline();
            this.cancelAppointmentTimeline = null;
        }

        if (fn.isFunction(this.cancelAppointmentStatus)) {
            this.cancelAppointmentStatus();
            this.cancelAppointmentStatus = null;
        }

        if (fn.isFunction(this.cancelAppointmentDelete)) {
            this.cancelAppointmentDelete();
            this.cancelAppointmentDelete = null;
        }

        if (fn.isFunction(this.cancelAppointmentUpdate)) {
            this.cancelAppointmentUpdate();
            this.cancelAppointmentUpdate = null;
        }

        if (fn.isFunction(this.cancelPatientProfile)) {
            this.cancelPatientProfile();
            this.cancelPatientProfile = null;
        }
    }

    get start() {
        if (!this.data) return null;
        return moment(this.data.start);
    }

    get startUtc() {
        if (!this.data) return null;
        return moment.utc(this.data.startUtc);
    }

    get end() {
        if (!this.data) return null;
        return moment(this.data.end);
    }

    get endUtc() {
        if (!this.data) return null;
        return moment(this.data.endUtc);
    }

    get isReschedule() {
        if (!this.data) return false;

        return this.data.start !== this.originalData.start ||
            this.data.startUtc !== this.originalData.startUtc ||
            this.data.userId !== this.originalData.userId;
    }

    get recommendedDuration() {
        return this.data.services.map(s => { return (s.duration ? s.duration : 0) }).reduce((a, b) => a + b, 0);
    }

    get isStaging() {
        return !this.id && this.stageId;
    }

    get isStaged() {
        return this.id && this.stageId && this.id === this.stageId;
    }
}

decorate(AppointmentUpdate, {
    id: observable,
    stageId: observable,
    data: observable,
    originalData: observable,
    rescheduleRequestBy: observable,
    rescheduleRequestReason: observable,
    rescheduleRequestReasonHtml: observable,
    cancelRequestBy: observable,
    cancelRequestReason: observable,
    cancelRequestReasonHtml: observable,
    repeatUpdateFollowing: observable,
    repeatUpdateUntil: observable,
    repeatUpdateRemaining: observable,
    deleteAndExtend: observable,
    hasUnsavedChanges: observable,
    isLoading: observable,
    isSaving: observable,
    isReady: observable,
    initialize: action,
    stage: action,
    staged: action,
    refresh: action,
    timeline: action,
    status: action,
    loadPatientProfile: action,
    save: action,
    delete: action,
    clear: action,
    checkConflicts: action,
    checkEligibility: action,
    start: computed,
    startUtc: computed,
    end: computed,
    endUtc: computed,
    isReschedule: computed,
    recommendedDuration: computed,
    isStaging: computed,
    isStaged: computed,
})

export default createContext(new AppointmentUpdate());