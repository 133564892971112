import { createContext } from 'react';
import { decorate, observable, action } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';
import moment, { isMoment } from 'moment';

export class TimeOffCreate {
    id = null;
    start = null;
    until = null;
    userId = null;
    note = null;
    noteHtml = null;
    reason = null;
    isMultipleDays = false;
    isExisting = false;
    hasUnsavedChanges = false;
    cancelTimeOffUpdate = null;

    initialize = (userId, start, until) => {
        const that = this;

        this.clear();
        this.userId = userId;
        this.start = start && isMoment(start) ? start.startOf('day') : moment().startOf('day');
        this.until = (until && isMoment(until) ? until.startOf('day') : moment().startOf('day')).add(1, 'days').add(-1, 'seconds');
        this.isMultipleDays = !(this.start.startOf('day').isSame(this.until.startOf('day')));

        return new Promise((resolve, reject) => {
            that.refresh()
                .then(() => {
                    that.hasUnsavedChanges = !that.isExisting;
                    resolve();
                })
                .catch(error => {
                    reject(error);
                })
                .finally(() => {
                    that.isReady = true;
                })
        })        
    }

    refresh = () => {
        const that = this;
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            api.TimeOffs.search({
                parameters: [{
                    field: 'Start',
                    value: that.start,
                    operator: '<='
                },{
                    field: 'Until',
                    value: that.until,
                    operator: '>='
                },{
                    field: 'UserId',
                    value: that.userId,
                },{
                    field: 'DeactivatedDateUtc',
                    value: null,
                }],
                includeTotalCount: false,
            })
                .then(({ data }) => {
                    if (data && data.result && data.result.length > 0) {
                        that.id = data.result[0].id;
                        that.start = moment(data.result[0].start);
                        that.until = moment(data.result[0].until);
                        that.reason = data.result[0].reason;
                        that.note = data.result[0].note;
                        that.noteHtml = data.result[0].noteHtml;
                        that.isMultipleDays = !(this.start.startOf('day').isSame(this.until.startOf('day')));
                        that.isExisting = true;
                    }
                    resolve();
                })
                .catch(error => {
                    reject(error);
                })
                .finally(() => {
                    that.isLoading = false;
                })
        })
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            if (that.hasUnsavedChanges) {
                api.TimeOffs.create(
                    {
                        userId: that.userId,
                        start: that.start.local(),
                        until: (that.isMultipleDays ? that.until : that.start.clone()).local().startOf('day').add(1, 'days').add(-1, 'seconds'),
                        reason: that.reason ? that.reason : null,
                        note: that.note ? that.note : null,
                        noteHtml: that.noteHtml ? that.noteHtml : null,
                    },
                    (c) => { that.cancelTimeOffUpdate = c }
                )
                    .then(() => {
                        that.hasUnsavedChanges = false;
                        resolve();
                    })
                    .catch(error => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                that.hasUnsavedChanges = false;
                that.isSaving = false;
                resolve();
            }
        })
    }

    clear = () => {
        this.id = null;
        this.start = null;
        this.until = null;
        this.userId = null;
        this.note = null;
        this.noteHtml = null;
        this.reason = null;
        this.isMultipleDays = false;
        this.isExisting = false;
        this.isReady = false;
        this.isSaving = false;
        this.isLoading = false;
        this.hasUnsavedChanges = false;

        if (fn.isFunction(this.cancelTimeOffUpdate)) {
            this.cancelTimeOffUpdate();
            this.cancelTimeOffUpdate = null;
        }
    }
}

decorate(TimeOffCreate, {
    id: observable,
    start: observable,
    until: observable,
    userId: observable,
    note: observable,
    noteHtml: observable,
    reason: observable,
    isMultipleDays: observable,
    isExisting: observable,
    isReady: observable,
    isSaving: observable,
    isLoading: observable,
    hasUnsavedChanges: observable,
    initialize: action,
    refresh: action,
    save: action,
    clear: action,
})

export default createContext(new TimeOffCreate());