import React, { Component } from 'react';
import { SketchPicker } from 'react-color';

import * as fn from '../../../utilities/_functions';

import './ColorPicker.scss';

export default class ColorPicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isPickerOpen: false,
            color: (props.color ? props.color : '#000'),
        };

        this.popoverRef = React.createRef();
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        if (this.popoverRef && this.popoverRef.current) {
            const inputs = this.popoverRef.current.querySelectorAll('[id^=rc-editable-input]');

            if (inputs && inputs.length > 0) {
                for (let i = 0; i < inputs.length; i++) {
                    inputs[i].style.width = '100%';
                    inputs[i].style.textAlign = 'center';
                }
            }
        }
    }

    handleClick = () => {
        if (fn.isNullOrUndefined(this.props.disabled) || this.props.disabled === false) {
            this.setState({ isPickerOpen: !this.state.isPickerOpen })

            if (this.props.onClick && fn.isFunction(this.props.onClick)) {
                this.props.onClick({ value: this.state.color });
            }
        }
    };

    handleClose = () => {
        if (fn.isNullOrUndefined(this.props.disabled) || this.props.disabled === false) {
            this.setState({ isPickerOpen: false })

            if (this.props.onClose && fn.isFunction(this.props.onClose)) {
                this.props.onClose({ value: this.state.color });
            }
        }
    };

    handleChange = (color) => {
        if (fn.isNullOrUndefined(this.props.disabled) || this.props.disabled === false) {
            this.setState({ color: color.hex })

            if (this.props.onChange && fn.isFunction(this.props.onChange)) {
                this.props.onChange({ value: color.hex });
            }
        }
    };

    render() {
        return (
            <div className='color-picker'>
                <div className='cp-swatch' onClick={this.handleClick}>
                    <div className='cp-color' style={{ backgroundColor: this.state.color }}></div>
                </div>
                <div ref={this.popoverRef} className={'cp-popover' + (this.props.popup === 'right' ? ' cp-popover-right' : '') + (this.state.isPickerOpen ? '' : ' d-none')}>
                    <div className='cp-cover' onClick={this.handleClose}></div>
                    <SketchPicker color={this.state.color} onChange={this.handleChange} />
                </div>
            </div>
        )
    }
}