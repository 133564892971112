import { createContext } from 'react';
import { decorate, observable, action } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class OrganizationCreateUpdate {
    data = null;
    isLoading = false;
    isSaving = false;
    isReady = false;
    hasUnsavedChanges = false;
    newAddress = {
        geocode: null,
        streetNumber: null,
        streetName: null,
        unitSuiteType: null,
        unitSuite: null,
        line2: null,
        locality: null,
        sublocality: null,
        postalCode: null,
        region: null,
        regionCode: null,
        country: null,
        countryCode: null,
    };
    newBillingAddress = {
        geocode: null,
        streetNumber: null,
        streetName: null,
        unitSuiteType: null,
        unitSuite: null,
        line2: null,
        locality: null,
        sublocality: null,
        postalCode: null,
        region: null,
        regionCode: null,
        country: null,
        countryCode: null,
    };

    cancelOrganizationCreateUpdate = null;
    cancelAddressInformationUpdate = null;
    
    initialize = (item) => {
        this.clear();
        if (item) {
            this.data = item;
        }
        this.isReady = true;
    }

    refresh = () => {
        const that = this;
        return new Promise((resolve, reject) => {
            api.Organizations.get(
                that.data.id,
                (c) => { that.cancelOrganizationCreateUpdate = c }
            )
                .then(({ data }) => {
                    that.data = data;
                    that.clearNewAddress();
                    if (data.address) {
                        that.newAddress = data.address;
                    }
                    if (data.billingAddress) {
                        that.newBillingAddress = data.billingAddress;
                    }
                    resolve();
                })
                .catch(() => {
                    reject();
                })
        })
    }

    save = async (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        try {
            if (this.data.id) {
                await api.Organizations.update(
                    this.data.id,
                    this.data,
                    (c) => { that.cancelOrganizationCreateUpdate = c });
            }
            else {
                await api.Organizations.create({
                    ...this.data, 
                    address: this.newAddress,
                    billingAddress: this.newBillingAddress
                },
                    (c) => { that.cancelOrganizationCreateUpdate = c });
            }
            that.hasUnsavedChanges = false;
        }
        catch(e) {
            if (e.response) {
                throw e.response.data;
            }
            else throw e;
        }
        finally {
            this.isSaving = false;
        }
    }

    updateAddressInformation = (notify) => {
        const that = this;
        return new Promise((resolve, reject) => {
            if (that.hasAddressUnsavedChanges) {
                if (!!notify) {
                    that.isSaving = true;
                }

                api.Organizations.update(
                    that.data.id,
                    {
                        addressInformation: {
                            existingAddress: that.data.address,
                            newAddress: that.newAddress,
                            existingBillingAddress: that.data.billingAddress,
                            newBillingAddress: that.newBillingAddress,
                        }
                    },
                    (c) => { that.cancelAddressInformationUpdate = c }
                )
                    .then(({data}) => {
                        this.data.address = data.address;
                        this.data.billingAddress = data.billingAddress;
                        that.clearNewAddress();
                        that.clearNewBillingAddress();
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    })
                    .finally(() => {
                        that.isSaving = false;
                        that.hasAddressUnsavedChanges = false;
                    })
            } else {
                resolve();
            }
        })
    }

    delete = (organizations) => {
        const that = this;

        this.isSaving = true;

        return new Promise((resolve, reject) => {
            Promise.all(
                [...organizations].map(g => {
                    return api.Organizations.delete(g.id, (c) => { that.cancelOrganizationCreateUpdate = c })
                })
            )
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    clear = () => {
        this.data = {
            name: '',
            displayOrder: 100,
            users: []
        };
        this.id = null;
        this.isLoading = false;
        this.isReady = false;
        this.hasUnsavedChanges = false;
        this.clearNewAddress();
        this.clearNewBillingAddress();    
        if (fn.isFunction(this.cancelOrganizationCreateUpdate)) {
            this.cancelOrganizationCreateUpdate();
            this.cancelOrganizationCreateUpdate = null;
        }
    }

    clearNewAddress = () => {
        this.newAddress.geocode = null;
        this.newAddress.streetNumber = null;
        this.newAddress.streetName = null;
        this.newAddress.unitSuiteType = null;
        this.newAddress.unitSuite = null;
        this.newAddress.line2 = null;
        this.newAddress.locality = null;
        this.newAddress.sublocality = null;
        this.newAddress.postalCode = null;
        this.newAddress.region = null;
        this.newAddress.regionCode = null;
        this.newAddress.country = null;
        this.newAddress.countryCode = null;
    }

    clearNewBillingAddress = () => {
        this.newBillingAddress.geocode = null;
        this.newBillingAddress.streetNumber = null;
        this.newBillingAddress.streetName = null;
        this.newBillingAddress.unitSuiteType = null;
        this.newBillingAddress.unitSuite = null;
        this.newBillingAddress.line2 = null;
        this.newBillingAddress.locality = null;
        this.newBillingAddress.sublocality = null;
        this.newBillingAddress.postalCode = null;
        this.newBillingAddress.region = null;
        this.newBillingAddress.regionCode = null;
        this.newBillingAddress.country = null;
        this.newBillingAddress.countryCode = null;
    }
}

decorate(OrganizationCreateUpdate, {
    data: observable.deep,
    isSaving: observable,
    isLoading: observable,
    isReady: observable,
    newAddress: observable.deep,
    newBillingAddress: observable.deep,
    hasUnsavedChanges: observable,
    initialize: action,
    refresh: action,
    clear: action,
    clearNewAddress: action,
    clearNewBillingAddress: action
})

export default createContext(new OrganizationCreateUpdate());