// Appointments
export const APPOINTMENTS_CALENDAR_MODE = '__APPOINTMENTS_CALENDAR_MODE__';
export const APPOINTMENTS_SLOT_SIZE = '__APPOINTMENTS_SLOT_SIZE__';

// Exams
export const EXAMS_CALENDAR_MODE = '__EXAMS_CALENDAR_MODE__';
export const EXAMS_SLOT_SIZE = '__EXAMS_SLOT_SIZE__';
export const EXAMS_START_NEW_WINDOW = '__EXAMS_START_NEW_WINDOW__';
export const EXAMS_COMPARE_SYNC = '__EXAMS_COMPARE_SYNC__';

// Schedules
export const IS_SCHEDULES_CALENDAR_SIDEBAR_OPENED = '__IS_SCHEDULES_CALENDAR_SIDEBAR_OPENED__';
export const SCHEDULES_CALENDAR_MODE = '__SCHEDULES_CALENDAR_MODE__';

// System
export const IS_SIDEBAR_OPENED = '__IS_SIDEBAR_OPENED__';
export const SHOW_CREATED_BY_YOU_TASKS = '__SHOW_CREATED_BY_YOU_TASKS__';