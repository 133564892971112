import React, { Component } from 'react';

import * as dx from 'devextreme-react';
import * as fn from '../../../utilities/_functions';

export default class HtmlEditor extends Component {
    constructor(props) {
        super(props);
        this.quillRef = React.createRef();
        this.preserveFormat = React.createRef();
        this.id = `_${Math.random().toString(36).substr(2, 5)}`;
        this.preserveFormat.current = false;
    }

    componentDidMount() {
        if (this.props.dataAttributes && this.quillRef.current.editor.container) {
            for (let i = 0; i < this.props.dataAttributes.length; i++) {
                const attribute = this.props.dataAttributes[i];
                this.quillRef.current.editor.container.setAttribute(attribute.name, attribute.value);
            }
        }

        if (this.quillRef.current) {
            const instance = this.quillRef.current.instance.getQuillInstance();

            if (instance && instance.keyboard) {
                if (!!this.props.disableTab && instance.keyboard.bindings['tab']) {
                    delete instance.keyboard.bindings['tab'];
                }

                if (!!this.props.disableEnter && instance.keyboard.bindings['enter']) {
                    delete instance.keyboard.bindings['enter'];
                }
            }
        }
    }

    handleContentReady = (e) => {
        if (e && e.component) {
            const that = this;
            const instance = e && e.component.getQuillInstance();

            if (instance) {
                if (instance.keyboard) {
                    if (!!this.props.disableTab && instance.keyboard.bindings['tab']) {
                        delete instance.keyboard.bindings['tab'];
                    }

                    if (!!this.props.disableEnter && instance.keyboard.bindings['enter']) {
                        delete instance.keyboard.bindings['enter'];
                    }
                }
                if (instance.clipboard) {
                    instance.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
                        if (!that.preserveFormat.current) {
                            let ops = [];
                            delta.ops.forEach(op => {
                                if (op.insert && typeof op.insert === 'string') {
                                    ops.push({
                                        insert: op.insert
                                    })
                                }
                            })
                            delta.ops = ops;
                        }
                        
                        return delta;
                    })
                }
            }
        }

        if (this.props && fn.isFunction(this.props.onContentReady)) {
            this.props.onContentReady(e);
        }
    }

    getInstance = () => {
        return this.quillRef.current && this.quillRef.current.instance ? this.quillRef.current.instance : null;
    }

    setHtml = (html, preserveFormat = false) => {
        if (!!preserveFormat) {
            this.preserveFormat.current = true;
        }

        this.getInstance().option('value', html);
        this.preserveFormat.current = false;
    }

    focus = () => {
        const instance = this.getInstance();

        if (instance) {
            instance.focus();
        }
    }

    render() {
        return <dx.HtmlEditor
            id={this.props.id ? this.props.id : this.id}
            ref={this.quillRef}
            {...this.props}
            onContentReady={this.handleContentReady}
        >
            {this.props.children}
        </dx.HtmlEditor>
    }
}