import React, { useContext, useEffect, useRef, useState } from 'react';
import { Observer } from 'mobx-react-lite';
import FadeIn from 'react-fade-in';
import TimeAgo from 'react-timeago';
import { GlobalHotKeys } from 'react-hotkeys';
import moment from 'moment';

import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import HtmlEditor from '../../_shared/HtmlEditor';

import AuthStore from '../../../../stores/AuthStore';
import NoteCreateStore from '../../../../stores/NoteCreateStore';
import NoteSearchStore from '../../../../stores/NoteSearchStore';
import FileViewerStore from '../../../../stores/FileViewerStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as ph from '../../../../utilities/personHelper';
import * as uh from '../../../../utilities/userHelper';
import * as oh from '../../../../utilities/operationHelper';
import * as sys from '../../../../utilities/systemHelper';

import './NewNote.scss';

function NewNote(props) {
    const isMounted = useRef(true);
    const startTimer = useRef(null);
    const bodyScrollTimer = useRef(null);
    const bodyRef = useRef(null);
    const editorRef = useRef(null);
    const auth = useContext(AuthStore);
    const newNote = useContext(NoteCreateStore);
    const noteSearch = useContext(NoteSearchStore);
    const fileViewer = useContext(FileViewerStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [isRendered, setIsRendered] = useState(false);
    const [expandFilter, setExpandFilter] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState([]);

    useEffect(() => {
        editorRef.current.getInstance().focus();
        startTimer.current = setTimeout(() => {
            const filters = [];

            if (bodyRef.current) {
                bodyRef.current.scrollTop = 99999;
            }
            setIsRendered(true);

            if (newNote.referenceId) {
                filters.push(newNote.referenceId);
            }

            setSelectedFilters(filters);
        }, 500)

        return () => {
            isMounted.current = false;
            if (startTimer.current) { clearTimeout(startTimer.current); startTimer.current = null; }
            if (bodyScrollTimer.current) { clearTimeout(bodyScrollTimer.current); bodyScrollTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (newNote.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleMessageChange = (e) => {
        const html = e.value;
        const preview = fn.stripHtml(html).trim().substring(0, 200);
        const bodyHtml = (html === '<p></p>') || (html === '<p><br></p>') ? '' : html;

        newNote.data.bodyHtml = bodyHtml;
        newNote.data.preview = preview;
    }

    const handleSeeAttachments = () => {
        noteSearch.clearNewNote();
        quickDrawer.activateQuickDrawer('note', 'attachment', null, handleAttachmentUpdateSuccess);
    }

    const handleAttachmentUpdateSuccess = () => { }

    const handleAttachmentPreview = (event, attachment) => {
        const attachments = (newNote.customer && newNote.customer.notes && newNote.customer.notes.some(n => n.attachments && n.attachments.length > 0) ?
            newNote.customer.notes.filter(n =>
                n.attachments && n.attachments.length > 0).map(n => {
                    return n.attachments.map(a => { return a })
                }) : []).flat();
        const index = attachments.findIndex(a => a.id === attachment.id);

        if (index < 0) {
            fileViewer.initialize(
                () => {
                    return newNote.customer ? <div className='fs-lg'>
                        {ph.getPreferredFirstLastName(newNote.customer)}
                        <small className='d-block text-gray-700'>View attachment(s)</small>
                    </div> : null
                },
                'attachment',
                [attachment],
                0,
                newNote.customer.id
            );
        } else {
            fileViewer.initialize(
                () => {
                    return newNote.customer ? <div className='fs-lg'>
                        {ph.getPreferredFirstLastName(newNote.customer)}
                        <small className='d-block text-gray-700'>View attachment(s)</small>
                    </div> : null
                },
                'attachment',
                [attachment],
                0,
                newNote.customer.id
            );
        }
    }

    const handleAttachmentRemove = (event, index) => {
        event.stopPropagation();
        newNote.data.attachments.splice(index, 1);
    }

    const handleSubmit = event => {
        event.preventDefault();

        const updated = newNote.hasUnsavedChanges;

        newNote.save(true)
            .then(data => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, { updated: updated, data: data });
                    }
                }
            })
    }

    const handleFilterToggle = (event, filter) => {
        event.preventDefault();

        const filters = [...selectedFilters];
        const index = filters.findIndex(f => f === filter);

        if (index === -1) {
            filters.push(filter);
        } else {
            filters.splice(index, 1);
        }

        setSelectedFilters(filters);

        bodyScrollTimer.current = setTimeout(() => {
            if (bodyRef.current) {
                bodyRef.current.scrollTop = 99999;
            }
        }, 100)
    }

    const handleFilterClear = (event) => {
        event.preventDefault();
        setSelectedFilters([]);

        bodyScrollTimer.current = setTimeout(() => {
            if (bodyRef.current) {
                bodyRef.current.scrollTop = 99999;
            }
        }, 100)
    }

    const handleFilterExpandToggle = event => {
        event.preventDefault();
        setExpandFilter((!expandFilter));
    }

    const hasFilter = (filter) => {
        return selectedFilters.some(f => f === filter);
    }

    const getReferenceFilteredNoteCount = () => {
        return newNote.customer ? newNote.customer.notes.filter(n => n.referenceId === newNote.referenceId).length : 0;
    }

    const getFilteredNoteCount = (filterType) => {
        return newNote.customer ? newNote.customer.notes.filter(n => n.filterType === filterType).length : 0;
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form onSubmit={handleSubmit}>
            <Observer>{() =>
                <fieldset disabled={newNote.isSaving}>
                    <div className='quick-drawer view-note-drawer'>
                        <QuickDrawerHeader
                            drawer={props.drawer}
                            icon={oh.getIcon('note')}
                            action='New'
                            category='Note'
                            className='notes'
                            onCancel={handleCancel}
                        />
                        <div className={'note-filters-wrapper' + (expandFilter ? ' expanded' : '')}>
                            {
                                newNote.customer && newNote.customer.notes && isRendered ?
                                    <ul className='note-filters'>
                                        <li>
                                            <button
                                                type='button'
                                                className={'btn btn-sm btn-toggle-default' + (selectedFilters.length === 0 ? ' active' : '')}
                                                onClick={e => handleFilterClear(e)}
                                            >
                                                <span className='fal fa-filter mr-0'></span> All Notes ({newNote.customer.notes.length})
                                            </button>
                                        </li>
                                        {
                                            newNote.referenceId && newNote.referenceType ?
                                                <li>
                                                    <button
                                                        type='button'
                                                        className={`btn btn-sm btn-toggle-danger` + (hasFilter(newNote.referenceId) ? ' active' : '')}
                                                        onClick={e => handleFilterToggle(e, newNote.referenceId)}
                                                    >
                                                        <span className={`${oh.getIcon(newNote.referenceType)} mr-2`}></span>This {oh.getDescription(newNote.referenceType)} ({getReferenceFilteredNoteCount()})
                                                    </button>
                                                </li> : null
                                        }
                                        <li>
                                            <button
                                                type='button'
                                                className={'btn btn-sm btn-toggle-customer' + (hasFilter('customer') ? ' active' : '')}
                                                onClick={e => handleFilterToggle(e, 'customer')}
                                            >
                                                <span className={`${oh.getIcon('customer')} mr-2`}></span>{oh.getDescription('customer')} ({getFilteredNoteCount('customer')})
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                type='button'
                                                className={'btn btn-sm btn-toggle-appointment' + (hasFilter('appointment') ? ' active' : '')}
                                                onClick={e => handleFilterToggle(e, 'appointment')}
                                            >
                                                <span className={`${oh.getIcon('appointment')} mr-2`}></span>{oh.getDescription('appointment')} ({getFilteredNoteCount('appointment')})
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                type='button'
                                                className={'btn btn-sm btn-toggle-purchase' + (hasFilter('purchase') ? ' active' : '')}
                                                onClick={e => handleFilterToggle(e, 'purchase')}
                                            >
                                                <span className={`${oh.getIcon('purchase')} mr-2`}></span>{oh.getDescription('purchase')} ({getFilteredNoteCount('purchase')})
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                type='button'
                                                className={'btn btn-sm btn-toggle-work-order' + (hasFilter('order') ? ' active' : '')}
                                                onClick={e => handleFilterToggle(e, 'order')}
                                            >
                                                <span className={`${oh.getIcon('work-order')} mr-2`}></span>{oh.getDescription('order')} ({getFilteredNoteCount('order')})
                                            </button>
                                        </li>
                                    </ul> : null
                            }
                            <button
                                type='button'
                                className='btn btn-icon btn-expand'
                                onClick={handleFilterExpandToggle}
                            >
                                <i className={`fal fa-${(expandFilter ? 'times' : 'angle-down')}`}></i>
                            </button>
                        </div>
                        <div ref={bodyRef} className='quick-drawer-body'>
                            {
                                <FadeIn>
                                    <div className={'view-note body-content py-1' + (isRendered ? ' rendered' : '')}>
                                        {
                                            newNote.customer && newNote.customer.notes && newNote.customer.notes.length > 0 ?
                                                newNote.customer.notes.map((n, ni) => {
                                                    return <div key={`note-${ni}`} className={'d-flex flex-column align-items-center' + ((selectedFilters.length === 0 || hasFilter(n.referenceId) || hasFilter(n.filterType)) ? '' : ' d-none')}>
                                                        <div className='d-flex flex-row w-100 py-4'>
                                                            <div className='d-inline-block align-middle mr-3'>
                                                                <span
                                                                    className={'profile-image profile-image-md rounded-circle d-block' + (n.resource && !n.resource.profilePictureUri ? ` profile-initials text-white bg-color${n.resource.color}-500` : ' mt-1')}
                                                                    style={!n.resource || n.resource.profilePictureUri ? {
                                                                        backgroundImage: `url(${(n.resource && n.resource.profilePictureUri ? n.resource.profilePictureUri : '/media/img/default-genki.jpg')})`,
                                                                        backgroundSize: 'cover',
                                                                    } : null}
                                                                    title={n.resource ? n.resource.fullName : 'System'}>
                                                                    {n.resource && !n.resource.profilePictureUri ? <div className='d-initials fs-xs'>{n.resource.initials}</div> : null}
                                                                    <span className={`badge badge-icon bg-color${n.filterType}-900`}></span>
                                                                </span>
                                                            </div>
                                                            <div className='mb-0 flex-1 text-dark'>
                                                                <div className='d-flex'>
                                                                    <span className='text-dark fw-500'>
                                                                        <span className='text-primary'>{n.resource ? ph.getPreferredFullName(n.resource) : 'System'}</span>
                                                                    </span>
                                                                    {
                                                                        moment().startOf('day').isSame(moment.utc(n.createdDateUtc).local().startOf('day')) ?
                                                                            <TimeAgo
                                                                                className='text-muted fs-xs opacity-70 ml-auto'
                                                                                date={moment.utc(n.createdDateUtc).local().toDate()}
                                                                                title={sys.getFormattedLongDate(moment.utc(n.createdDateUtc).local(), true)}
                                                                                minPeriod={60}
                                                                            /> :
                                                                            <span
                                                                                className='text-muted fs-xs opacity-70 ml-auto'
                                                                                title={sys.getFormattedLongDate(moment.utc(n.createdDateUtc).local(), true)}
                                                                            >
                                                                                {sys.getFormattedShortDate(moment.utc(n.createdDateUtc).local())}
                                                                            </span>
                                                                    }
                                                                </div>
                                                                <div
                                                                    className={'html mt-1 ' + (n.attachments && n.attachments.length > 0 ? 'mb-2' : 'mb-0')}
                                                                    dangerouslySetInnerHTML={{ __html: n.bodyHtml }}
                                                                ></div>
                                                                {
                                                                    n.attachments && n.attachments.length > 0 ?
                                                                        <>
                                                                            {
                                                                                n.attachments.slice(0, 3).map((a, ai) => {
                                                                                    return <div
                                                                                        key={`attachment-${ni}-${ai}`}
                                                                                        className={'card attachment border' + (ai !== (n.attachments.length - 1) ? ' mb-2' : '')}
                                                                                    >
                                                                                        <div className='card-body'>
                                                                                            <div className='d-flex flex-row w-100'>
                                                                                                <div className='d-flex align-middle align-items-center mr-3'>
                                                                                                    {fn.toFileIcon(a.mimeType, 'fs-xxl')}
                                                                                                </div>
                                                                                                <div className='flex-1'>
                                                                                                    <div className='d-block text-truncate text-truncate-md fw-500 text-gray-800'>{a.fileName}</div>
                                                                                                    <div className='d-flex flex-row align-items-center fs-xs'>
                                                                                                        <button
                                                                                                            type='button'
                                                                                                            className='btn btn-xs btn-link p-0'
                                                                                                            onClick={(e) => { handleAttachmentPreview(e, a) }}
                                                                                                        >
                                                                                                            Preview
                                                                                                        </button>
                                                                                                        <span className='line-height-1'>&nbsp;&middot;&nbsp;</span>
                                                                                                        <span className='line-height-1 text-gray-500 fs-xs'>{fn.toFileSize(a.size, true)}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                })
                                                                            }
                                                                            {
                                                                                n.attachments.length > 3 ?
                                                                                    <div
                                                                                        className='card border cursor-pointer border-dashed d-inline-block'
                                                                                        onClick={(e) => { handleAttachmentPreview(e, n.attachments[3]) }}
                                                                                    >
                                                                                        <div className='card-body'>
                                                                                            <div className='fs-lg p-2 text-center'>
                                                                                                +{n.attachments.length - 3}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div> : null
                                                                            }
                                                                        </> : null
                                                                }
                                                            </div>
                                                        </div>
                                                        {
                                                            newNote.customer.notes.length !== (ni + 1) ?
                                                                <hr className='m-0 w-100' /> : null
                                                        }
                                                    </div>
                                                }) : null
                                        }
                                        {
                                            newNote.data && newNote.data.attachments && newNote.data.attachments.length > 0 ?
                                                <div className={'d-flex flex-column align-items-center'}>
                                                    <div className='d-flex flex-row w-100 py-4'>
                                                        <div className='d-inline-block align-middle mr-3'>
                                                            <span
                                                                className={'profile-image profile-image-md rounded-circle d-block' + (!auth.currentUser && auth.currentUser.profilePictureUri ? ` profile-initials text-white bg-color${auth.currentUser.color}-500` : ' mt-1')}
                                                                style={auth.currentUser && auth.currentUser.profilePictureUri ? {
                                                                    backgroundImage: `url(${(auth.currentUser.profilePictureUri)})`,
                                                                    backgroundSize: 'cover',
                                                                } : null}
                                                                title={auth.currentUser ? auth.currentUser.fullName : 'System'}>
                                                                {auth.currentUser && !auth.currentUser.profilePictureUri ? <div className='d-initials fs-xs'>{auth.currentUser.initials}</div> : null}
                                                            </span>
                                                        </div>
                                                        <div className='mb-0 flex-1 text-dark'>
                                                            <div className='d-flex'>
                                                                <span className='text-dark fw-500'>
                                                                    <span className='text-primary'>{auth.currentUser ? uh.getDisplayShortName(auth.currentUser) : 'System'}</span>
                                                                </span>
                                                                <span className='text-danger fs-xs ml-auto'>
                                                                    Not uploaded yet
                                                                </span>
                                                            </div>
                                                            {
                                                                newNote.data.bodyHtml ?
                                                                    <div
                                                                        className={'html mt-1 ' + (newNote.data.attachments && newNote.data.attachments.length > 0 ? 'mb-2' : 'mb-0')}
                                                                        dangerouslySetInnerHTML={{ __html: newNote.data.bodyHtml }}
                                                                    ></div> : null
                                                            }
                                                            {
                                                                newNote.data.attachments && newNote.data.attachments.length > 0 ?
                                                                    newNote.data.attachments.map((a, ai) => {
                                                                        return <div
                                                                            key={`attachment-new-${ai}`}
                                                                            className={'card attachment border' + (ai !== (newNote.data.attachments.length - 1) ? ' mb-2' : '')}
                                                                        >
                                                                            <div className='card-body'>
                                                                                <div className='d-flex flex-row w-100'>
                                                                                    <div className='d-flex align-middle align-items-center mr-3'>
                                                                                        {fn.toFileIcon(a.mimeType, 'fs-xxl')}
                                                                                    </div>
                                                                                    <div className='flex-1'>
                                                                                        <div className='d-block text-truncate text-truncate-md fw-500 text-gray-800'>{a.fileName}</div>
                                                                                        <div className='d-flex flex-row align-items-center fs-xs'>
                                                                                            <button
                                                                                                type='button'
                                                                                                className='btn btn-xs btn-link p-0'
                                                                                                onClick={(e) => { handleAttachmentPreview(e, a) }}
                                                                                            >
                                                                                                Preview
                                                                                            </button>
                                                                                            <span className='line-height-1'>&nbsp;&middot;&nbsp;</span>
                                                                                            <button
                                                                                                type='button'
                                                                                                className='btn btn-xs btn-link p-0 text-danger'
                                                                                                onClick={(e) => { handleAttachmentRemove(e, ai) }}
                                                                                            >
                                                                                                Remove
                                                                                            </button>
                                                                                            <span className='line-height-1'>&nbsp;&middot;&nbsp;</span>
                                                                                            <span className='line-height-1 text-gray-500 fs-xs'>{fn.toFileSize(a.size, true)}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }) : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                    </div>
                                </FadeIn>
                            }
                        </div>
                        <div className='quick-drawer-action'>
                            <div className='row'>
                                <div className='col-12'>
                                    <hr className='m-0 w-100' />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <Observer>{() =>
                                        <HtmlEditor
                                            ref={editorRef}
                                            height={130}
                                            placeholder='Write note here...'
                                            toolbar={{ items: ['bold', 'italic', 'underline', 'separator', 'orderedList', 'bulletList', 'separator', 'color', 'background'] }}
                                            disableTab={true}
                                            onValueChanged={handleMessageChange}
                                        ></HtmlEditor>
                                        // <RichTextEditor
                                        //     ref={editorRef}
                                        //     placeholder='Write note here...'
                                        //     disableTab={true}
                                        //     value={newNote.data.bodyHtml}
                                        //     onChange={handleNoteChange}
                                        // />
                                    }</Observer>
                                </div>
                            </div>
                            <div className='row px-4 py-3'>
                                <div className='col-4'>
                                    <button
                                        type='button'
                                        className='btn btn-icon ml-n2'
                                        title='Attachments'
                                        onClick={handleSeeAttachments}
                                    >
                                        <i className='fal fa-paperclip'></i>
                                    </button>
                                </div>
                                <div className='col-8'>
                                    <div className='float-right'>
                                        <button
                                            type='button'
                                            className='btn btn-link btn-cancel mr-2'
                                            onClick={handleCancel}
                                        >Cancel</button>
                                        <button
                                            type='submit'
                                            className='btn btn-success'
                                        >Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            }</Observer>
        </form>
    </>
}

export default NewNote;