import { createContext } from 'react';
import { decorate, observable, action, computed } from 'mobx';
import moment from 'moment';

import api from '../api';
import { CURRENT_USER } from '../constants/storageKeys';

import * as fn from '../utilities/_functions';
import * as uh from '../utilities/userHelper';

export class InternalFormCreate {
    id = null;
    templates = [];
    data = [];
    customer = null;
    workOrder = null;
    appointment = null;
    exam = null;
    notes = null;
    selectedCategory = null;
    selectedTemplateId = null;
    selectedTemplate = null;
    selectedPageIndex = 0;
    signedDateUtc = null;
    signedBy = null;
    signedById = null;
    hasUnsavedChanges = false;
    isLoading = false;
    isSaving = false;
    isReady = false;

    cancelInternalFormCreate = null;
    cancelInternalFormGet = null;
    cancelInternalFormUpdate = null;
    cancelInternalFormAll = null;

    initialize = (customer, option) => {
        const that = this;

        this.clear();
        this.isReady = false;

        if (!customer) throw new Error('Customer is required.');

        return new Promise((resolve, reject) => {
            const parameters = [
                { field: 'PublishedTemplateId', value: null, operator: '!=' },
                { field: 'DeactivatedDateUtc', value: null },
            ];

            if (option) {
                if (option.selectedCategory) {
                    that.selectedCategory = option.selectedCategory;
                }

                if (option.selectedTemplateId) {
                    parameters.push({ field: 'Id', value: option.selectedTemplateId })
                }
            }

            api.InternalFormTemplates.search({
                parameters: parameters,
                sortByFields: [{ field: 'Name', direction: 'ASC', }],
                includeTotalCount: false,
            },
                (c) => { that.cancelInternalFormAll = c })
                .then(({ data }) => {
                    that.templates = data && data.result ? data.result : [];

                    if (option) {
                        if (option.id) {
                            api.InternalForms.get(option.id, (c) => { that.cancelInternalFormGet = c })
                                .then(({ data: existingData }) => {
                                    if (existingData) {
                                        that.customer = existingData.customer;
                                        that.workOrder = existingData.workOrder ? existingData.workOrder : null;
                                        that.appointment = existingData.appointment ? existingData.appointment : null;
                                        that.exam = existingData.exam ? existingData.exam : null;
                                        that.data = existingData.data ? existingData.data : [];
                                        that.signedDateUtc = existingData.signedDateUtc;
                                        that.signedBy = existingData.signedBy;
                                        that.signedById = existingData.signedById;

                                        if (existingData.internalFormTemplateId) {
                                            that.selectedTemplateId = existingData.internalFormTemplateId;
                                            that.loadTemplate().then(() => { resolve(); })
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                })
                        }
                        else {
                            that.customer = customer;
                            that.workOrder = option.workOrder ? option.workOrder : null;
                            that.appointment = option.appointment ? option.appointment : null;
                            that.exam = option.exam ? option.exam : null;
                            that.notes = option.notes ? option.notes : null;

                            if (option.selectedTemplateId) {
                                that.selectedTemplateId = option.selectedTemplateId;
                                that.loadTemplate().then(() => { resolve(); })
                            }
                            else {
                                resolve();
                            }
                        }
                    }
                    else {
                        resolve();
                    }
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isReady = true;
                })
        })
    }

    loadTemplate = (notify) => {
        const that = this;

        if (!!notify) {
            this.isLoading = true;
        }

        return new Promise((resolve, reject) => {
            api.InternalFormPublishedTemplates.get(that.templates.filter(t => t.id === that.selectedTemplateId)[0].publishedTemplateId, (c) => { that.cancelInternalFormGet = c })
                .then(({ data }) => {
                    const index = that.templates.findIndex(f => f.id === that.selectedTemplateId);

                    if (index >= -1) {
                        that.selectedTemplate = data;
                    }

                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isLoading = false;
                })
        })
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            let request;

            if (that.id) {
                request = api.InternalForms.update(that.id, {
                    internalFormPublishedTemplateId: that.selectedTemplate.publishedTemplateId,
                    data: that.data,
                    customerId: that.customer ? that.customer.id : null,
                    workOrderId: that.workOrder ? that.workOrder.id : null,
                    appointmentId: that.appointment ? that.appointment.id : null,
                    examId: that.exam ? that.exam.id : null,
                }, (c) => { that.cancelInternalFormUpdate = c })
            }
            else {
                request = api.InternalForms.create({
                    internalFormPublishedTemplateId: that.selectedTemplate.publishedTemplateId,
                    data: that.data,
                    customerId: that.customer ? that.customer.id : null,
                    workOrderId: that.workOrder ? that.workOrder.id : null,
                    appointmentId: that.appointment ? that.appointment.id : null,
                    examId: that.exam ? that.exam.id : null,
                }, (c) => { that.cancelInternalFormUpdate = c })
            }

            request.then(({ data }) => {
                that.id = data.id;
                resolve(data);
            })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    sign = (notify) => {
        const that = this;
        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            api.InternalForms.sign(that.id, (c) => { that.cancelInternalFormUpdate = c })
                .then(() => {
                    const currentUser = JSON.parse(window.localStorage.getItem(CURRENT_USER));

                    that.signedDateUtc = moment();
                    that.signedBy = uh.getDisplayFullName(currentUser);
                    that.signedById = currentUser.id;

                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    unSign = (notify) => {
        const that = this;
        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            const currentUser = JSON.parse(window.localStorage.getItem(CURRENT_USER));

            api.InternalForms.unSign({ userId: currentUser.id, }, (c) => { that.cancelInternalFormUpdate = c })
                .then(() => {
                    that.signedDateUtc = null;
                    that.signedBy = null;
                    that.signedById = null;
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    clear = () => {
        this.id = null;
        this.templates.clear();
        this.data.clear();
        this.customer = null;
        this.workOrder = null;
        this.appointment = null;
        this.exam = null;
        this.notes = null;
        this.selectedCategory = null;
        this.selectedTemplateId = null;
        this.selectedTemplate = null;
        this.selectedPageIndex = 0;
        this.signedDateUtc = null;
        this.signedBy = null;
        this.signedById = null;
        this.hasUnsavedChanges = false;
        this.isLoading = false;
        this.isSaving = false;
        this.isReady = false;

        if (fn.isFunction(this.cancelInternalFormCreate)) {
            this.cancelInternalFormCreate();
            this.cancelInternalFormCreate = null;
        }

        if (fn.isFunction(this.cancelInternalFormGet)) {
            this.cancelInternalFormGet();
            this.cancelInternalFormGet = null;
        }

        if (fn.isFunction(this.cancelInternalFormUpdate)) {
            this.cancelInternalFormUpdate();
            this.cancelInternalFormUpdate = null;
        }

        if (fn.isFunction(this.cancelInternalFormAll)) {
            this.cancelInternalFormAll();
            this.cancelInternalFormAll = null;
        }
    }

    get isSelectedTemplateReady() {
        return this.isReady && this.selectedTemplate && this.selectedTemplate.pages && this.selectedTemplate.pages.length > 0;
    }

    get selectedPage() {
        if (this.selectedPageIndex >= 0 && this.selectedTemplate && this.selectedTemplate.pages && this.selectedTemplate.pages.length > 0) {
            return this.selectedTemplate.pages[this.selectedPageIndex];
        }

        return {};
    }

    get isSigned() {
        return (!!this.signedDateUtc && !!this.signedBy);
    }
}

decorate(InternalFormCreate, {
    id: observable,
    templates: observable,
    data: observable,
    customer: observable,
    workOrder: observable,
    appointment: observable,
    exam: observable,
    notes: observable,
    selectedCategory: observable,
    selectedTemplateId: observable,
    selectedTemplate: observable,
    selectedPageIndex: observable,
    signedDateUtc: observable,
    signedBy: observable,
    signedById: observable,
    initialize: action,
    loadTemplate: action,
    save: action,
    sign: action,
    unSign: action,
    clear: action,
    hasUnsavedChanges: observable,
    isLoading: observable,
    isSaving: observable,
    isReady: observable,
    isSelectedTemplateReady: computed,
    isSigned: computed,
    selectedPage: computed,
})

export default createContext(new InternalFormCreate());