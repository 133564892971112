import React, { useRef, useEffect, useContext, useState } from 'react';
import { useObserver } from 'mobx-react-lite';
import { GlobalHotKeys } from 'react-hotkeys';

import AuthStore from '../../../stores/AuthStore';
import CacheStore from '../../../stores/CacheStore';

import * as rts from '../../../constants/routes';
import { Link } from 'react-router-dom';

const USER_MENU_ID = '__user_menu__'
const USER_MENU_BUTTON_ID = '__user_menu_button__';

function UserMenu() {
    const isMounted = useRef(true);
    const auth = useContext(AuthStore);
    const cache = useContext(CacheStore);
    const [showUserMenu, setShowUserMenu] = useState(false);

    useEffect(() => {
        document.addEventListener('click', handleHideUserMenuClick);

        return () => {
            isMounted.current = false;
            document.removeEventListener('click', handleHideUserMenuClick);
        }

    }, []) // eslint-disable-line

    const handleUserMenuClick = event => {
        const newShowUserMenu = !showUserMenu;
        setShowUserMenu(newShowUserMenu);
    }

    const renderCurrentUserPicture = () => {
        if (cache.activeUsers && auth.currentUser) {
            const currentUser = cache.activeUsers.filter(a => a.id === auth.currentUser.id)[0];

            return <span
                className={'profile-image profile-image-md rounded-circle m-0 d-block' + (currentUser && !currentUser.profilePictureUri ? ` profile-initials text-white bg-color${currentUser.color}-500` : '')}
                style={currentUser && currentUser.profilePictureUri ? {
                    backgroundImage: `url(${(currentUser.profilePictureUri)})`,
                    backgroundSize: 'cover',
                } : null}
                title={currentUser ? currentUser.fullName : null}>
                {currentUser && !currentUser.profilePictureUri ? <div className='d-initials'>{currentUser.initials}</div> : null}
            </span>
        }
    }

    const handleHideUserMenuClick = (event) => {
        const userMenu = document.querySelector(`#${USER_MENU_ID}`);
        const userMenuButton = document.querySelector(`#${USER_MENU_BUTTON_ID}`);
        const isMenuVisible = userMenu.offsetParent !== null;
        const isMenu = event.target.id === USER_MENU_ID || userMenu.contains(event.target);
        const isButton = event.target.id === USER_MENU_BUTTON_ID || userMenuButton.contains(event.target);
        const hideUserMenu = isMenuVisible && !isButton && !isMenu;

        if (hideUserMenu) {
            setShowUserMenu(false);
        }
    }

    return useObserver(() => <>
        {
            !!showUserMenu ?
                <GlobalHotKeys
                    keyMap={{
                        close: ['esc'],
                    }}
                    handlers={{
                        close: () => {
                            setShowUserMenu(false)
                        },
                    }}
                    allowChanges={true}
                /> : null
        }
        <div className={'d-flex align-items-center align-items-center justify-content-center ml-2 mt-n1' + (showUserMenu ? ' show' : '')}>
            <button
                id={USER_MENU_BUTTON_ID}
                type='button'
                className='btn btn-icon header-icon'
                data-toggle="dropdown"
                onClick={handleUserMenuClick}
            >
                {renderCurrentUserPicture()}
            </button>
            <div id={USER_MENU_ID} className={'dropdown-menu' + (showUserMenu ? ' mt-h show' : '')}>
                <Link
                    to={`${rts.Settings.UserAccount}`}
                    className='dropdown-item'
                // onClick={() => handleModeChange('update')}
                >
                    User Account
                </Link>
                {
                    auth.currentUser && auth.currentUser.allowedTenants && auth.currentUser.allowedTenants.length > 1 ?
                        <Link to={`${rts.SwitchTenant.Home}`}
                            className='dropdown-item'>
                            Switch Location
                        </Link> : null
                }
                <div className='dropdown-divider my-o'></div>
                <a href='https://download.anydesk.com/AnyDesk.exe' className='dropdown-item'>Remote Support</a>
                <div className='dropdown-divider my-o'></div>
                <a
                    href={rts.Logout}
                    className='dropdown-item'
                >
                    Log out
                </a>
            </div>
        </div>
    </>)
}

export default UserMenu;