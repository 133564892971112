import React, { useContext, useEffect, useRef, useState } from 'react';
import FadeIn from 'react-fade-in';
import { Observer } from 'mobx-react-lite';
import momentLocalizer from 'react-widgets-moment';
import MaskedInput from 'react-text-mask'
import { GlobalHotKeys } from 'react-hotkeys';
import { ColorBox, DateBox } from 'devextreme-react';
import moment from 'moment';

import BodyEnd from '../../_shared/BodyEnd';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import LoadingOverlay from '../../_shared/LoadingOverlay';

import ServiceCreateStore from '../../../../stores/ServiceCreateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as MaskKeys from '../../../../constants/maskKeys';
import * as fn from '../../../../utilities/_functions';
import * as oh from '../../../../utilities/operationHelper';

import './UpdateServiceAttribute.scss';
import api from '../../../../api';

moment.locale('en');
momentLocalizer();


function NewService(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const service = useContext(ServiceCreateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [ready, setReady] = useState(false);
    const [examTemplates, setExamTemplates] = useState([]);

    const loadData = async () => {
        try {
            const { data } = await api.ExamTemplates.search({
                parameters: [
                    {
                        field: 'PublishedTemplateId',
                        operator: '!=',
                        value: null
                    }
                ],
                sortByFields: [{ field: 'Name', direction: 'ASC' }],
                includeTotalCount: false,
            });
            if (isMounted.current) {
                const examTemplates = data && data.result && data.result.length > 0 ? data.result : [];
                setExamTemplates(examTemplates);
                setReady(true);
            }
        }
        catch (e) {
            window.alert(e);
        }
    }
    useEffect(() => {
        loadData();
        quickDrawerFocus(props.drawer);
        return () => {
            isMounted.current = false;
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (service.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            const data = await service.save(true);
            if (isMounted.current) {
                if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                    props.onSuccess(event, data);
                }
            }
        }
    }

    const handleDefaultDurationChange = async (event) => {
        const duration = parseInt(event.target.value);
        if (isNaN(duration))
            service.data.defaultDurationInMinutes = null;
        else
            service.data.defaultDurationInMinutes = duration;
        service.hasUnsavedChanges = true;
    }

    const handleDefaultRecallChange = async (event) => {
        const recall = parseInt(event.target.value);
        if (isNaN(recall) || recall <= 0)
            service.data.defaultRecallPeriodInMonth = null;
        else
            service.data.defaultRecallPeriodInMonth = recall;
        service.hasUnsavedChanges = true;
    }

    const handleColorChange = (element) => {
        const value = element.value;

        service.data.colorHexValue = value;
        service.hasUnsavedChanges = true;
    }

    const handleNameChange = (event) => {
        service.data.name = event.target.value;
        service.hasUnsavedChanges = true;
    }

    const handleFeeChange = async (event) => {
        if (event.target.value) {
            const amount = parseFloat(fn.parseCurrency(event.target.value));
            service.data.fee = amount;
        } else {
            service.data.fee = null;
        }
        service.hasUnsavedChanges = true;
    }

    const handleCodeChange = (event) => {
        service.data.code = event.target.value;
        service.hasUnsavedChanges = true;
    }

    const handleFeeCodeChange = (event) => {
        service.data.feeCode = event.target.value;
        service.hasUnsavedChanges = true;
    }

    const handleSubsidyCodeChange = (event) => {
        service.data.subsidyCode = event.target.value;
        service.hasUnsavedChanges = true;
    }

    const handleMinAgeRestrictionChange = (event) => {
        service.data.minAgeRestriction = event.target.value;
        service.hasUnsavedChanges = true;
    }

    const handleMaxAgeRestrictionChange = (event) => {
        service.data.maxAgeRestriction = event.target.value;
        service.hasUnsavedChanges = true;
    }

    const handleIsFullExamChange = (event) => {
        service.data.isFullExam = !service.data.isFullExam;
        service.hasUnsavedChanges = true;
    }
    const handleIsBookableChange = (event) => {
        service.data.isBookable = !service.data.isBookable;
        service.hasUnsavedChanges = true;
    }
    const handleIsRepeatableChange = (event) => {
        service.data.isRepeatable = !service.data.isRepeatable;
        service.hasUnsavedChanges = true;
    }
    const handleIsTaxableChange = (event) => {
        service.data.isTaxable = !service.data.isTaxable;
        service.hasUnsavedChanges = true;
    }
    const handleSendReminderChange = (event) => {
        service.data.sendReminder = !service.data.sendReminder;
        service.hasUnsavedChanges = true;
    }

    const handleActiveStartingDateChange = element => {
        const value = element.value;

        service.data.activeStartingDate = value ? moment(value).startOf('day') : moment().startOf('day');
        service.hasUnsavedChanges = true;
    }

    const handleClaimEligibilityTypeChange = (event) => {
        service.data.subsidizedEligibilityType = event.target.value;
        service.hasUnsavedChanges = true;
    }
    const handleClaimEligibilityUnitChange = (event) => {
        service.data.subsidizedEligibilityUnit = event.target.value;
        service.hasUnsavedChanges = true;
    }
    const handleServiceTimelineItems = async () => {
        if (isMounted.current) {
            // passing {} into UpdateServiceTimelineItems simplify the code inside
            quickDrawer.activateQuickDrawer('serviceTimelineItems', 'update', {}, handleServiceTimelineItemsSuccess, handleServiceTimelineItemsCancel, handleServiceTimelineItemsError);
        }
    }
    const handleServiceTimelineItemsSuccess = (event) => {
    }
    const handleServiceTimelineItemsCancel = () => {
    }
    const handleServiceTimelineItemsError = () => {
    }
    const handleExamTemplateChange = (event) => {
        const templateId = event.target.value;
        service.data.examTemplateId = templateId;
        service.hasUnsavedChanges = true;
        if (!!templateId) {
            const template = examTemplates.find(e => e.id === templateId);
            service.data.examPublishedTemplateId = template.publishedTemplateId;
        }
        else {
            service.data.examPublishedTemplateId = null;
        }
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <fieldset disabled={service.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('service', 'new')}
                        action='Add New'
                        category='Service'
                        className='services'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        <Observer>{() =>
                            service.isReady && ready ?
                                <FadeIn>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <strong>{service.data.id ? `There are pending changes that will be applied on ${moment(service.data.effectiveStarting).format('YYYY-MM-DD')}` : ''}</strong>
                                        </div>
                                    </div>
                                    <div className='update-service body-content'>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0'>
                                                        <div className='form-group'>
                                                            <label><small>Color</small></label>
                                                            <ColorBox
                                                                id='color'
                                                                applyValueMode={'instantly'}
                                                                value={service.data.colorHexValue}
                                                                onValueChanged={handleColorChange}
                                                            >
                                                            </ColorBox>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0 validate validate-required'>
                                                        <label className='required'><small>Name</small></label>
                                                        <input
                                                            id='service-name'
                                                            type='text'
                                                            className='form-control'
                                                            maxLength='150'
                                                            autoComplete='off'
                                                            value={service.data.name}
                                                            onChange={handleNameChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0 validate validate-required'>
                                                        <label className='required'><small>Code</small></label>
                                                        <input
                                                            id='service-code'
                                                            type='text'
                                                            className='form-control'
                                                            maxLength="25"
                                                            autoComplete='off'
                                                            value={service.data.code}
                                                            onChange={handleCodeChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0'>
                                                        <label><small>Fee Code</small></label>
                                                        <input
                                                            id='service-fee-code'
                                                            type='text'
                                                            className='form-control'
                                                            maxLength="25"
                                                            autoComplete='off'
                                                            value={service.data.feeCode}
                                                            onChange={handleFeeCodeChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0'>
                                                        <label><small>Claim Code</small></label>
                                                        <input
                                                            id='service-subsidy-code'
                                                            type='text'
                                                            className='form-control'
                                                            maxLength="25"
                                                            autoComplete='off'
                                                            value={service.data.subsidyCode}
                                                            onChange={handleSubsidyCodeChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>


                                        <section>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='form-group mb-0'>
                                                        <label><small>Eligibility Type</small></label>
                                                        <select
                                                            id='new-appointment-repeat'
                                                            className='custom-select form-control'
                                                            value={service.data.subsidizedEligibilityType}
                                                            onChange={handleClaimEligibilityTypeChange}
                                                        >
                                                            <option value=''></option>
                                                            <option value='Days'>Day(s)</option>
                                                            <option value='Months'>Month(s)</option>
                                                            <option value='Years'>Year(s)</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='form-group mb-0'>
                                                        <label><small>Eligibility Unit</small></label>
                                                        <input
                                                            id='service-eligibility-unit'
                                                            type='number'
                                                            className='form-control'
                                                            step={1}
                                                            autoComplete='off'
                                                            value={service.data.subsidizedEligibilityUnit}
                                                            onChange={handleClaimEligibilityUnitChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>


                                        <section>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='form-group mb-0'>
                                                        <label><small>Minimum Age Restriction</small></label>
                                                        <input
                                                            id='service-min-age-restriction'
                                                            type='number'
                                                            className='form-control'
                                                            autoComplete='off'
                                                            value={service.data.minAgeRestriction}
                                                            onChange={handleMinAgeRestrictionChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='form-group mb-0'>
                                                        <label><small>Maximum Age Restriction</small></label>
                                                        <input
                                                            id='service-max-age-restriction'
                                                            type='number'
                                                            className='form-control'
                                                            autoComplete='off'
                                                            value={service.data.maxAgeRestriction}
                                                            onChange={handleMaxAgeRestrictionChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0'>
                                                        <div className='form-custom-control custom-switch'>
                                                            <input
                                                                id='is-full-exam'
                                                                type="checkbox"
                                                                name='is-full-exam'
                                                                className='custom-control-input'
                                                                defaultChecked={service.data.isFullExam}
                                                                onChange={handleIsFullExamChange}
                                                            />
                                                            <label className='custom-control-label' htmlFor='is-full-exam'>Full Exam</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0'>
                                                        <div className='form-custom-control custom-switch'>
                                                            <input
                                                                id='is-bookable'
                                                                type="checkbox"
                                                                name='is-bookable'
                                                                className='custom-control-input'
                                                                defaultChecked={service.data.isBookable}
                                                                onChange={handleIsBookableChange}
                                                            />
                                                            <label className='custom-control-label' htmlFor='is-bookable'>Bookable</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0'>
                                                        <div className='form-custom-control custom-switch'>
                                                            <input
                                                                id='is-repeatable'
                                                                type="checkbox"
                                                                name='is-repeatable'
                                                                className='custom-control-input'
                                                                defaultChecked={service.data.isRepeatable}
                                                                onChange={handleIsRepeatableChange}
                                                            />
                                                            <label className='custom-control-label' htmlFor='is-repeatable'>Repeatable</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0'>
                                                        <div className='form-custom-control custom-switch'>
                                                            <input
                                                                id='is-taxable'
                                                                type="checkbox"
                                                                name='is-taxable'
                                                                className='custom-control-input'
                                                                defaultChecked={service.data.isTaxable}
                                                                onChange={handleIsTaxableChange}
                                                            />
                                                            <label className='custom-control-label' htmlFor='is-taxable'>Taxable</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0'>
                                                        <label><small>Default Duration (minutes)</small></label>
                                                        <input
                                                            id='service-default-duration'
                                                            type='number'
                                                            className='form-control'
                                                            step={5}
                                                            autoComplete='off'
                                                            value={service.data.defaultDurationInMinutes}
                                                            onChange={handleDefaultDurationChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0'>
                                                        <label><small>Default Recall Period (months)</small></label>
                                                        <input
                                                            id='service-default-recall'
                                                            type='number'
                                                            className='form-control'
                                                            step={1}
                                                            autoComplete='off'
                                                            value={service.data.defaultRecallPeriodInMonth}
                                                            onChange={handleDefaultRecallChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0'>
                                                        <label><small>Fee</small></label>
                                                        <MaskedInput
                                                            id='service-fee'
                                                            type='text'
                                                            className='form-control'
                                                            spellCheck={false}
                                                            mask={MaskKeys.CURRENCY_MASK}
                                                            autoComplete='off'
                                                            value={service.data.fee ? service.data.fee : ''}
                                                            onChange={handleFeeChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-tenant mb-0'>
                                                        <label><small>Exam Template</small></label>
                                                        <select
                                                            id='update-exam-template-select'
                                                            className='custom-select form-control'
                                                            value={service.data.examTemplateId || ''}
                                                            onChange={handleExamTemplateChange}
                                                        >
                                                            <option value=''>Not selected</option>
                                                            {examTemplates.map(d => <option value={d.id}>{d.name}</option>)}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0'>
                                                        <div className='form-custom-control custom-switch'>
                                                            <input
                                                                id='send-reminder'
                                                                type="checkbox"
                                                                name='send-reminder'
                                                                className='custom-control-input'
                                                                defaultChecked={service.data.sendReminder}
                                                                onChange={handleSendReminderChange}
                                                            />
                                                            <label className='custom-control-label' htmlFor='send-reminder'>Send Reminder</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0'>
                                                        <div className='form-group'>
                                                            <label><small>Active starting date</small></label>
                                                            <DateBox
                                                                id='active-starting-date'
                                                                type='date'
                                                                displayFormat={'yyyy-MM-dd'}
                                                                useMaskBehavior={false}
                                                                showClearButton={false}
                                                                min={moment().toDate()}
                                                                value={service.data.activeStartingDate ? moment(service.data.activeStartingDate).toDate() : null}
                                                                onValueChanged={handleActiveStartingDateChange}
                                                                onOpened={() => { document.querySelector('.dx-datebox-wrapper').style.zIndex = fn.getHighestZIndex() + 1; }}
                                                            >
                                                            </DateBox>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </FadeIn> : renderQuickDrawerLoading()
                        }</Observer>
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-4'>
                                <button
                                    type='button'
                                    className='btn btn-icon'
                                    title='Manage Timeline Items'
                                    onClick={handleServiceTimelineItems}
                                >
                                    <i className='fal fa-clock'></i>
                                </button>
                            </div>
                            <div className='col-8'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
        <BodyEnd>
            <Observer>{() => <LoadingOverlay isLoading={service.isSaving}></LoadingOverlay>}</Observer>
        </BodyEnd>
    </>
}

export default NewService;