import { createContext } from 'react';
import { decorate, observable, action } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class TimeOffUpdate {
    id = null;
    data = null;
    isReady = false;
    isSaving = false;
    isLoading = false;
    hasUnsavedChanges = false;
    cancelTimeOff = null;
    cancelTimeOffUpdate = null;
    cancelTimeOffDelete = null;

    initialize = (id) => {
        const that = this;

        this.clear();
        this.id = id;

        return new Promise((resolve, reject) => {
            that.refresh()
                .then(() => {
                    that.hasUnsavedChanges = !that.isExisting;
                    resolve();
                })
                .catch(error => {
                    reject(error);
                })
                .finally(() => {
                    that.isReady = true;
                })
        })
    }

    refresh = () => {
        const that = this;
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            api.TimeOffs.get(that.id, (c) => { that.cancelTimeOff = c })
                .then(({ data }) => {
                    that.data = data ? data : null;
                    resolve();
                })
                .catch(error => {
                    reject(error);
                })
                .finally(() => {
                    that.isLoading = false;
                })
        })
    }

    delete = (notify) => {
        const that = this;

        if (notify) {
            that.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            api.TimeOffs.delete(that.id, (c) => { that.cancelTimeOffDelete = c })
                .then(() => {
                    resolve();
                })
                .catch(error => {
                    reject(error);
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    clear = () => {
        this.id = null;
        this.data = null;
        this.isReady = false;
        this.isSaving = false;
        this.isLoading = false;
        this.hasUnsavedChanges = false;

        if (fn.isFunction(this.cancelTimeOff)) {
            this.cancelTimeOff();
            this.cancelTimeOff = null;
        }

        if (fn.isFunction(this.cancelTimeOffUpdate)) {
            this.cancelTimeOffUpdate();
            this.cancelTimeOffUpdate = null;
        }

        if (fn.isFunction(this.cancelTimeOffDelete)) {
            this.cancelTimeOffDelete();
            this.cancelTimeOffDelete = null;
        }
    }
}

decorate(TimeOffUpdate, {
    id: observable,
    data: observable,
    isReady: observable,
    isSaving: observable,
    isLoading: observable,
    hasUnsavedChanges: observable,
    initialize: action,
    refresh: action,
    delete: action,
    clear: action,
})

export default createContext(new TimeOffUpdate());