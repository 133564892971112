import { createContext } from 'react';
import { decorate, observable, action, toJS } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class LinkedWorkOrderSelect {
    workOrderId = null;
    customerId = null;
    dataSets = [];
    data = {
        selectedIds: [],
        reason: null,
        reasonHtml: null,
    };
    hasUnsavedChanges = false;
    isLoading = false;
    isSaving = false;
    isReady = false;
    saveToServer = false;

    cancelWorkOrder = null;
    cancelWorkOrderLink = null;
    cancelWorkOrderSearch = null;

    initialize = (workOrderId, customerId, saveToServer) => {
        const that = this;
        this.clear();

        return new Promise((resolve, reject) => {
            that.workOrderId = workOrderId;
            that.customerId = customerId;
            that.isLoading = true;

            if (saveToServer === true) {
                that.saveToServer = true;
            }

            api.WorkOrders.get(that.workOrderId, (c) => { that.cancelWorkOrder = c })
                .then(({ data: order }) => {
                    if (order) {
                        const relatedWorkOrders = (order.relatedWorkOrders && order.relatedWorkOrders.length > 0 ? order.relatedWorkOrders.map(r => r.id) : []).concat([...[that.workOrderId]]);

                        api.WorkOrders.search(
                            {
                                parameters: [
                                    {
                                        field: 'CustomerId',
                                        value: that.customerId,
                                    },
                                    {
                                        field: 'Id',
                                        value: relatedWorkOrders.join(','),
                                        operator: 'NotContains'
                                    },
                                    {
                                        field: 'Status',
                                        value: 'Abandoned',
                                        operator: '!='
                                    },
                                ],
                                sortByFields: [{
                                    field: 'CreatedDateUtc',
                                    direction: 'DESC',
                                }, {
                                    field: 'Number',
                                    direction: 'ASC',
                                }],
                                offset: 0,
                                limit: 100,
                                includeTotalCount: false,
                            },
                            (c) => { that.cancelWorkOrderSearch = c }
                        )
                            .then(({ data }) => {
                                that.dataSets = data && data.result && data.result.length > 0 ? data.result : [];
                                resolve();
                            })
                            .catch((error) => {
                                reject(error);
                            })
                            .finally(() => {
                                that.isLoading = false;
                                that.isReady = true;
                            })
                    } else {
                        resolve();
                    }
                })
        })
    }

    save = () => {
        const that = this;

        return new Promise((resolve, reject) => {
            const option = toJS(that.data);

            if (that.hasUnsavedChanges) {
                if (that.saveToServer) {
                    that.isSaving = true;

                    api.WorkOrders.link(that.workOrderId, option, (c) => { that.cancelWorkOrderLink = c })
                        .then(() => {
                            that.hasUnsavedChanges = false;
                            resolve(option);
                        })
                        .catch((error) => {
                            reject(error);
                        })
                        .finally(() => {
                            that.isSaving = false;
                        })
                } else {
                    resolve(option);
                }
            } else {
                that.hasUnsavedChanges = false;
                that.isSaving = false;
                resolve(option);
            }
        })
    }

    clear = () => {
        this.workOrderId = null;
        this.customerId = null;
        this.dataSets.clear();
        this.data.selectedIds.clear();
        this.data.reason = null;
        this.data.reasonHtml = null;
        this.hasUnsavedChanges = false;
        this.isLoading = false;
        this.isSaving = false;
        this.isReady = false;
        this.saveToServer = false;

        if (fn.isFunction(this.cancelWorkOrder)) {
            this.cancelWorkOrder();
            this.cancelWorkOrder = null;
        }

        if (fn.isFunction(this.cancelWorkOrderLink)) {
            this.cancelWorkOrderLink();
            this.cancelWorkOrderLink = null;
        }

        if (fn.isFunction(this.cancelWorkOrderSearch)) {
            this.cancelWorkOrderSearch();
            this.cancelWorkOrderSearch = null;
        }
    }
}

decorate(LinkedWorkOrderSelect, {
    workOrderId: observable,
    customerId: observable,
    data: observable.deep,
    dataSets: observable.deep,
    hasUnsavedChanges: observable,
    isLoading: observable,
    isSaving: observable,
    isReady: observable,
    saveToServer: observable,
    save: action,
})

export default createContext(new LinkedWorkOrderSelect());