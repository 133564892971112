import React from 'react';
import { Observer } from 'mobx-react-lite';
import uuid from 'react-uuid';
import moment from 'moment';

import * as PageSettings from '../constants/pageSettings';
import * as fn from './_functions';
import * as ph from './personHelper';
import * as mh from './mentionHelper';
import * as uh from './userHelper';
import * as fm from './formatHelper';
import * as tch from './templateControlHelper';
import * as iih from './internalFormInputHelper';

export const INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_PAPER_LETTER_WIDTH = PageSettings.PAPER_LETTER_WIDTH;
export const INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_PAPER_LETTER_HEIGHT = PageSettings.PAPER_LETTER_HEIGHT;
export const INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_PAPER_A4_WIDTH = PageSettings.PAPER_A4_WIDTH;
export const INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_PAPER_A4_HEIGHT = PageSettings.PAPER_A4_HEIGHT;
export const INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_PAPER_LEGAL_WIDTH = PageSettings.PAPER_LEGAL_WIDTH;
export const INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_PAPER_LEGAL_HEIGHT = PageSettings.PAPER_LEGAL_HEIGHT;
export const INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_MARGINS_NARROW = PageSettings.MARGINS_NARROW;
export const INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_MARGINS_NORMAL = PageSettings.MARGINS_NORMAL;
export const INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_MARGINS_WIDE = PageSettings.MARGINS_WIDE;
export const INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_HEADER_FOOTER_HEIGHT_MIN = PageSettings.HEADER_FOOTER_HEIGHT_MIN;
export const INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_HEADER_FOOTER_HEIGHT_DEFAULT = PageSettings.HEADER_FOOTER_HEIGHT_DEFAULT;
export const INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_HEADER_FOOTER_HEIGHT_MAX = PageSettings.HEADER_FOOTER_HEIGHT_MAX;

export const INTERNAL_FORM_TEMPLATE_CELL_WIDTH = 12.75;
export const INTERNAL_FORM_TEMPLATE_ROW_HEIGHT = 5;

export const INTERNAL_FORM_ELEMENT_RICHTEXT_EDITOR = 'Richtext';
export const INTERNAL_FORM_ELEMENT_IMAGE = 'Image';

export const INTERNAL_FORM_ELEMENT_BUSINESS_NAME = 'BusinessName';
export const INTERNAL_FORM_ELEMENT_BUSINESS_FULL_ADDRESS = 'BusinessFullAddress';
export const INTERNAL_FORM_ELEMENT_BUSINESS_ADDRESS_LINE1 = 'BusinessAddressLine1';
export const INTERNAL_FORM_ELEMENT_BUSINESS_ADDRESS_LINE2 = 'BusinessAddressLine2';
export const INTERNAL_FORM_ELEMENT_BUSINESS_ADDRESS_COUNTRY = 'BusinessAddressCountry';
export const INTERNAL_FORM_ELEMENT_BUSINESS_PHONE = 'BusinessPhone';
export const INTERNAL_FORM_ELEMENT_BUSINESS_EMAIL = 'BusinessEmail';

export const INTERNAL_FORM_ELEMENT_CUSTOMER_NAME = 'CustomerName';
export const INTERNAL_FORM_ELEMENT_CUSTOMER_FULL_ADDRESS = 'CustomerFullAddress';
export const INTERNAL_FORM_ELEMENT_CUSTOMER_ADDRESS_LINE1 = 'CustomerAddressLine1';
export const INTERNAL_FORM_ELEMENT_CUSTOMER_ADDRESS_LINE2 = 'CustomerAddressLine2';
export const INTERNAL_FORM_ELEMENT_CUSTOMER_ADDRESS_COUNTRY = 'CustomerAddressCountry';
export const INTERNAL_FORM_ELEMENT_CUSTOMER_PHONE = 'CustomerPhone';
export const INTERNAL_FORM_ELEMENT_CUSTOMER_EMAIL = 'CustomerEmail';
export const INTERNAL_FORM_ELEMENT_CUSTOMER_DATE_OF_BIRTH = 'CustomerDateOfBirth';
export const INTERNAL_FORM_ELEMENT_CUSTOMER_AGE = 'CustomerAge';
export const INTERNAL_FORM_ELEMENT_CUSTOMER_HEALTH_CARD = 'CustomerHealthCard';

export const INTERNAL_FORM_ELEMENT_EXAM_DATE = 'ExamDate';
export const INTERNAL_FORM_ELEMENT_EXAM_DOCTOR = 'ExamDoctor';
export const INTERNAL_FORM_ELEMENT_EXAM_DOCTOR_LICENSE_NUMBER = 'ExamDoctorLicenseNumber';
export const INTERNAL_FORM_ELEMENT_EXAM_DOCTOR_BILLING_NUMBER = 'ExamDoctorBillingNumber';

export const INTERNAL_FORM_ELEMENT_WORK_ORDER_NUMBER = 'WorkOrderNumber';
export const INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_NUMBER = 'WorkOrderInvoiceNumber';
export const INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_TOTAL = 'WorkOrderInvoiceTotal';
export const INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_BALANCE = 'WorkOrderInvoiceBalance';
export const INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_DOCTOR = 'WorkOrderInvoiceDoctor';
export const INTERNAL_FORM_ELEMENT_WORK_ORDER_CREATED_DATE = 'WorkOrderCreatedDate';
export const INTERNAL_FORM_ELEMENT_WORK_ORDER_CREATED_BY = 'WorkOrderCreatedBy';
export const INTERNAL_FORM_ELEMENT_WORK_ORDER_SUBMITTED_DATE = 'WorkOrderSubmittedDate';
export const INTERNAL_FORM_ELEMENT_WORK_ORDER_SUBMITTED_BY = 'WorkOrderSubmittedBy';
export const INTERNAL_FORM_ELEMENT_WORK_ORDER_NOTES = 'WorkOrderNotes';
export const INTERNAL_FORM_ELEMENT_WORK_ORDER_FIELD = 'WorkOrderField';

export const INTERNAL_FORM_ELEMENT_PAGE_NUMBER = 'PageNumber';

export const INTERNAL_FORM_TEMPLATE_METADATA_ALIGN = 'INTERNAL_FORM_TEMPLATE_METADATA_ALIGN';
export const INTERNAL_FORM_TEMPLATE_METADATA_VALIGN = 'INTERNAL_FORM_TEMPLATE_METADATA_VALIGN';
export const INTERNAL_FORM_TEMPLATE_METADATA_PADDING = 'INTERNAL_FORM_TEMPLATE_METADATA_PADDING';
export const INTERNAL_FORM_TEMPLATE_METADATA_FONT_COLOR = 'INTERNAL_FORM_TEMPLATE_METADATA_FONT_COLOR';
export const INTERNAL_FORM_TEMPLATE_METADATA_FONT_SIZE = 'INTERNAL_FORM_TEMPLATE_METADATA_FONT_SIZE';
export const INTERNAL_FORM_TEMPLATE_METADATA_FONT_FAMILY = 'INTERNAL_FORM_TEMPLATE_METADATA_FONT_FAMILY';
export const INTERNAL_FORM_TEMPLATE_METADATA_FONT_STYLE = 'INTERNAL_FORM_TEMPLATE_METADATA_FONT_STYLE';
export const INTERNAL_FORM_TEMPLATE_METADATA_FONT_LETTER_SPACING = 'INTERNAL_FORM_TEMPLATE_METADATA_FONT_LETTER_SPACING';
export const INTERNAL_FORM_TEMPLATE_METADATA_BACKGROUND_COLOR = 'INTERNAL_FORM_TEMPLATE_METADATA_BACKGROUND_COLOR';
export const INTERNAL_FORM_TEMPLATE_METADATA_BORDER = 'INTERNAL_FORM_TEMPLATE_METADATA_BORDER';
export const INTERNAL_FORM_TEMPLATE_METADATA_BORDER_COLOR = 'INTERNAL_FORM_TEMPLATE_METADATA_BORDER_COLOR';
export const INTERNAL_FORM_TEMPLATE_METADATA_ALTERNATE_BACKGROUND_COLOR = 'INTERNAL_FORM_TEMPLATE_METADATA_BACKGROUND_COLOR';
export const INTERNAL_FORM_TEMPLATE_METADATA_CONTENT = 'INTERNAL_FORM_TEMPLATE_METADATA_CONTENT';
export const INTERNAL_FORM_TEMPLATE_METADATA_IMAGE = 'INTERNAL_FORM_TEMPLATE_METADATA_IMAGE';
export const INTERNAL_FORM_TEMPLATE_METADATA_FORMAT = 'INTERNAL_FORM_TEMPLATE_METADATA_FORMAT';
export const INTERNAL_FORM_TEMPLATE_METADATA_WORK_ORDER_FIELD = 'INTERNAL_FORM_TEMPLATE_METADATA_WORK_ORDER_FIELD';

export const InternalFormElementTypes = [
    INTERNAL_FORM_ELEMENT_RICHTEXT_EDITOR,
    INTERNAL_FORM_ELEMENT_IMAGE,
    INTERNAL_FORM_ELEMENT_BUSINESS_NAME,
    INTERNAL_FORM_ELEMENT_BUSINESS_FULL_ADDRESS,
    INTERNAL_FORM_ELEMENT_BUSINESS_ADDRESS_LINE1,
    INTERNAL_FORM_ELEMENT_BUSINESS_ADDRESS_LINE2,
    INTERNAL_FORM_ELEMENT_BUSINESS_ADDRESS_COUNTRY,
    INTERNAL_FORM_ELEMENT_BUSINESS_PHONE,
    INTERNAL_FORM_ELEMENT_BUSINESS_EMAIL,
    INTERNAL_FORM_ELEMENT_CUSTOMER_NAME,
    INTERNAL_FORM_ELEMENT_CUSTOMER_FULL_ADDRESS,
    INTERNAL_FORM_ELEMENT_CUSTOMER_ADDRESS_LINE1,
    INTERNAL_FORM_ELEMENT_CUSTOMER_ADDRESS_LINE2,
    INTERNAL_FORM_ELEMENT_CUSTOMER_ADDRESS_COUNTRY,
    INTERNAL_FORM_ELEMENT_CUSTOMER_PHONE,
    INTERNAL_FORM_ELEMENT_CUSTOMER_EMAIL,
    INTERNAL_FORM_ELEMENT_CUSTOMER_DATE_OF_BIRTH,
    INTERNAL_FORM_ELEMENT_CUSTOMER_AGE,
    INTERNAL_FORM_ELEMENT_CUSTOMER_HEALTH_CARD,
    INTERNAL_FORM_ELEMENT_EXAM_DATE,
    INTERNAL_FORM_ELEMENT_EXAM_DOCTOR,
    INTERNAL_FORM_ELEMENT_EXAM_DOCTOR_LICENSE_NUMBER,
    INTERNAL_FORM_ELEMENT_EXAM_DOCTOR_BILLING_NUMBER,
    INTERNAL_FORM_ELEMENT_WORK_ORDER_NUMBER,
    INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_NUMBER,
    INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_TOTAL,
    INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_BALANCE,
    INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_DOCTOR,
    INTERNAL_FORM_ELEMENT_WORK_ORDER_CREATED_DATE,
    INTERNAL_FORM_ELEMENT_WORK_ORDER_CREATED_BY,
    INTERNAL_FORM_ELEMENT_WORK_ORDER_SUBMITTED_DATE,
    INTERNAL_FORM_ELEMENT_WORK_ORDER_SUBMITTED_BY,
    INTERNAL_FORM_ELEMENT_WORK_ORDER_NOTES,
    INTERNAL_FORM_ELEMENT_WORK_ORDER_FIELD,
    INTERNAL_FORM_ELEMENT_PAGE_NUMBER,
];

export const getPrintMessage = (name) => {
    return <>
        <h2 className='text-uppercase text-center font-weight-bold text-gray-900 mb-1'>Generating...</h2>
        <p className='fs-xl'>Please wait while we generate the {(name ? name.toLowerCase() : 'custom form')}.</p>
    </>;
}

export const createElement = (type) => {
    return {
        id: uuid(),
        type: type,
        metadata: {},
        position: { w: 10, h: 5, minW: 2, }
    };
}

export const isInternalFormElement = ({ type }) => {
    return InternalFormElementTypes.some(t => t === type);
}

export const hasField = (type, field) => {
    switch (type) {
        case INTERNAL_FORM_ELEMENT_RICHTEXT_EDITOR:
            return [
                INTERNAL_FORM_TEMPLATE_METADATA_ALIGN,
                INTERNAL_FORM_TEMPLATE_METADATA_VALIGN,
                INTERNAL_FORM_TEMPLATE_METADATA_PADDING,
                INTERNAL_FORM_TEMPLATE_METADATA_FONT_COLOR,
                INTERNAL_FORM_TEMPLATE_METADATA_FONT_SIZE,
                INTERNAL_FORM_TEMPLATE_METADATA_FONT_FAMILY,
                INTERNAL_FORM_TEMPLATE_METADATA_FONT_LETTER_SPACING,
                INTERNAL_FORM_TEMPLATE_METADATA_BACKGROUND_COLOR,
                INTERNAL_FORM_TEMPLATE_METADATA_BORDER,
                INTERNAL_FORM_TEMPLATE_METADATA_BORDER_COLOR,
                INTERNAL_FORM_TEMPLATE_METADATA_CONTENT,
            ].some(f => f === field);

        case INTERNAL_FORM_ELEMENT_IMAGE:
            return [
                INTERNAL_FORM_TEMPLATE_METADATA_ALIGN,
                INTERNAL_FORM_TEMPLATE_METADATA_VALIGN,
                INTERNAL_FORM_TEMPLATE_METADATA_IMAGE,
            ].some(f => f === field);

        case INTERNAL_FORM_ELEMENT_BUSINESS_NAME:
        case INTERNAL_FORM_ELEMENT_BUSINESS_FULL_ADDRESS:
        case INTERNAL_FORM_ELEMENT_BUSINESS_ADDRESS_LINE1:
        case INTERNAL_FORM_ELEMENT_BUSINESS_ADDRESS_LINE2:
        case INTERNAL_FORM_ELEMENT_BUSINESS_ADDRESS_COUNTRY:
        case INTERNAL_FORM_ELEMENT_BUSINESS_EMAIL:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_FULL_ADDRESS:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_ADDRESS_LINE1:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_ADDRESS_LINE2:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_ADDRESS_COUNTRY:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_EMAIL:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_AGE:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_HEALTH_CARD:
        case INTERNAL_FORM_ELEMENT_EXAM_DOCTOR:
        case INTERNAL_FORM_ELEMENT_EXAM_DOCTOR_LICENSE_NUMBER:
        case INTERNAL_FORM_ELEMENT_EXAM_DOCTOR_BILLING_NUMBER:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_NUMBER:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_NUMBER:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_DOCTOR:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_NOTES:
            return [
                INTERNAL_FORM_TEMPLATE_METADATA_ALIGN,
                INTERNAL_FORM_TEMPLATE_METADATA_VALIGN,
                INTERNAL_FORM_TEMPLATE_METADATA_PADDING,
                INTERNAL_FORM_TEMPLATE_METADATA_FONT_COLOR,
                INTERNAL_FORM_TEMPLATE_METADATA_FONT_SIZE,
                INTERNAL_FORM_TEMPLATE_METADATA_FONT_FAMILY,
                INTERNAL_FORM_TEMPLATE_METADATA_FONT_STYLE,
                INTERNAL_FORM_TEMPLATE_METADATA_FONT_LETTER_SPACING,
                INTERNAL_FORM_TEMPLATE_METADATA_BACKGROUND_COLOR,
                INTERNAL_FORM_TEMPLATE_METADATA_BORDER,
                INTERNAL_FORM_TEMPLATE_METADATA_BORDER_COLOR,
            ].some(f => f === field);

        case INTERNAL_FORM_ELEMENT_BUSINESS_PHONE:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_NAME:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_PHONE:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_DATE_OF_BIRTH:
        case INTERNAL_FORM_ELEMENT_EXAM_DATE:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_TOTAL:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_BALANCE:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_CREATED_DATE:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_CREATED_BY:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_SUBMITTED_DATE:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_SUBMITTED_BY:
        case INTERNAL_FORM_ELEMENT_PAGE_NUMBER:
            return [
                INTERNAL_FORM_TEMPLATE_METADATA_ALIGN,
                INTERNAL_FORM_TEMPLATE_METADATA_VALIGN,
                INTERNAL_FORM_TEMPLATE_METADATA_PADDING,
                INTERNAL_FORM_TEMPLATE_METADATA_FONT_COLOR,
                INTERNAL_FORM_TEMPLATE_METADATA_FONT_SIZE,
                INTERNAL_FORM_TEMPLATE_METADATA_FONT_FAMILY,
                INTERNAL_FORM_TEMPLATE_METADATA_FONT_STYLE,
                INTERNAL_FORM_TEMPLATE_METADATA_FONT_LETTER_SPACING,
                INTERNAL_FORM_TEMPLATE_METADATA_BACKGROUND_COLOR,
                INTERNAL_FORM_TEMPLATE_METADATA_BORDER,
                INTERNAL_FORM_TEMPLATE_METADATA_BORDER_COLOR,
                INTERNAL_FORM_TEMPLATE_METADATA_FORMAT,
            ].some(f => f === field);

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_FIELD:
            return [
                INTERNAL_FORM_TEMPLATE_METADATA_ALIGN,
                INTERNAL_FORM_TEMPLATE_METADATA_VALIGN,
                INTERNAL_FORM_TEMPLATE_METADATA_PADDING,
                INTERNAL_FORM_TEMPLATE_METADATA_FONT_COLOR,
                INTERNAL_FORM_TEMPLATE_METADATA_FONT_SIZE,
                INTERNAL_FORM_TEMPLATE_METADATA_FONT_FAMILY,
                INTERNAL_FORM_TEMPLATE_METADATA_FONT_STYLE,
                INTERNAL_FORM_TEMPLATE_METADATA_FONT_LETTER_SPACING,
                INTERNAL_FORM_TEMPLATE_METADATA_BACKGROUND_COLOR,
                INTERNAL_FORM_TEMPLATE_METADATA_BORDER,
                INTERNAL_FORM_TEMPLATE_METADATA_BORDER_COLOR,
                INTERNAL_FORM_TEMPLATE_METADATA_FORMAT,
                INTERNAL_FORM_TEMPLATE_METADATA_WORK_ORDER_FIELD,
            ].some(f => f === field);

        default:
            return false;
    }
}

export const isCustomerField = (type) => {
    return [
        INTERNAL_FORM_ELEMENT_CUSTOMER_NAME,
        INTERNAL_FORM_ELEMENT_CUSTOMER_FULL_ADDRESS,
        INTERNAL_FORM_ELEMENT_CUSTOMER_ADDRESS_LINE1,
        INTERNAL_FORM_ELEMENT_CUSTOMER_ADDRESS_LINE2,
        INTERNAL_FORM_ELEMENT_CUSTOMER_ADDRESS_COUNTRY,
        INTERNAL_FORM_ELEMENT_CUSTOMER_PHONE,
        INTERNAL_FORM_ELEMENT_CUSTOMER_EMAIL,
        INTERNAL_FORM_ELEMENT_CUSTOMER_DATE_OF_BIRTH,
        INTERNAL_FORM_ELEMENT_CUSTOMER_AGE,
        INTERNAL_FORM_ELEMENT_CUSTOMER_HEALTH_CARD,
    ].some(f => f === type);
}

export const isExamField = (type) => {
    return [
        INTERNAL_FORM_ELEMENT_EXAM_DATE,
        INTERNAL_FORM_ELEMENT_EXAM_DOCTOR,
        INTERNAL_FORM_ELEMENT_EXAM_DOCTOR_LICENSE_NUMBER,
        INTERNAL_FORM_ELEMENT_EXAM_DOCTOR_BILLING_NUMBER,
    ].some(f => f === type);
}

export const isWorkOrderField = (type) => {
    return [
        INTERNAL_FORM_ELEMENT_WORK_ORDER_NUMBER,
        INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_NUMBER,
        INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_TOTAL,
        INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_BALANCE,
        INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_DOCTOR,
        INTERNAL_FORM_ELEMENT_WORK_ORDER_CREATED_DATE,
        INTERNAL_FORM_ELEMENT_WORK_ORDER_CREATED_BY,
        INTERNAL_FORM_ELEMENT_WORK_ORDER_SUBMITTED_DATE,
        INTERNAL_FORM_ELEMENT_WORK_ORDER_SUBMITTED_BY,
        INTERNAL_FORM_ELEMENT_WORK_ORDER_NOTES,
        INTERNAL_FORM_ELEMENT_WORK_ORDER_FIELD,
    ].some(f => f === type);
}

export const getName = (type) => {
    switch (type) {
        case INTERNAL_FORM_ELEMENT_RICHTEXT_EDITOR:
            return 'Richtext';

        case INTERNAL_FORM_ELEMENT_IMAGE:
            return 'Image';

        case INTERNAL_FORM_ELEMENT_BUSINESS_NAME:
            return 'BusinessName';

        case INTERNAL_FORM_ELEMENT_BUSINESS_FULL_ADDRESS:
            return 'BusinessFullAddress';

        case INTERNAL_FORM_ELEMENT_BUSINESS_ADDRESS_LINE1:
            return 'BusinessAddressLine1';

        case INTERNAL_FORM_ELEMENT_BUSINESS_ADDRESS_LINE2:
            return 'BusinessAddressLine2';

        case INTERNAL_FORM_ELEMENT_BUSINESS_ADDRESS_COUNTRY:
            return 'BusinessAddressCountry';

        case INTERNAL_FORM_ELEMENT_BUSINESS_PHONE:
            return 'BusinessPhone';

        case INTERNAL_FORM_ELEMENT_BUSINESS_EMAIL:
            return 'BusinessEmail';

        case INTERNAL_FORM_ELEMENT_CUSTOMER_NAME:
            return 'CustomerName';

        case INTERNAL_FORM_ELEMENT_CUSTOMER_FULL_ADDRESS:
            return 'CustomerFullAddress';

        case INTERNAL_FORM_ELEMENT_CUSTOMER_ADDRESS_LINE1:
            return 'CustomerAddressLine1';

        case INTERNAL_FORM_ELEMENT_CUSTOMER_ADDRESS_LINE2:
            return 'CustomerAddressLine2';

        case INTERNAL_FORM_ELEMENT_CUSTOMER_ADDRESS_COUNTRY:
            return 'CustomerAddressCountry';

        case INTERNAL_FORM_ELEMENT_CUSTOMER_PHONE:
            return 'CustomerPhone';

        case INTERNAL_FORM_ELEMENT_CUSTOMER_EMAIL:
            return 'CustomerEmail';

        case INTERNAL_FORM_ELEMENT_CUSTOMER_DATE_OF_BIRTH:
            return 'CustomerDateOfBirth';

        case INTERNAL_FORM_ELEMENT_CUSTOMER_AGE:
            return 'CustomerAge';

        case INTERNAL_FORM_ELEMENT_CUSTOMER_HEALTH_CARD:
            return 'CustomerHealthCard';

        case INTERNAL_FORM_ELEMENT_EXAM_DATE:
            return 'ExamDate';

        case INTERNAL_FORM_ELEMENT_EXAM_DOCTOR:
            return 'ExamDoctorName';

        case INTERNAL_FORM_ELEMENT_EXAM_DOCTOR_LICENSE_NUMBER:
            return 'ExamDoctorLicenseNumber';

        case INTERNAL_FORM_ELEMENT_EXAM_DOCTOR_BILLING_NUMBER:
            return 'ExamDoctorBillingNumber';

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_NUMBER:
            return 'WorkOrderNumber';

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_NUMBER:
            return 'WorkOrderInvoiceNumber';

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_TOTAL:
            return 'WorkOrderInvoiceTotal';

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_BALANCE:
            return 'WorkOrderInvoiceBalance';

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_DOCTOR:
            return 'WorkOrderInvoiceDoctor';

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_CREATED_DATE:
            return 'WorkOrderCreatedDate';

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_CREATED_BY:
            return 'WorkOrderCreatedBy';

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_SUBMITTED_DATE:
            return 'WorkOrderSubmittedDate';

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_SUBMITTED_BY:
            return 'WorkOrderSubmittedBy';

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_NOTES:
            return 'WorkOrderNotes';

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_FIELD:
            return 'WorkOrderField';

        case INTERNAL_FORM_ELEMENT_PAGE_NUMBER:
            return 'PageNumber';

        default:
            return '';
    }
}

export const getDescription = (type) => {
    switch (type) {
        case INTERNAL_FORM_ELEMENT_RICHTEXT_EDITOR:
            return 'Richtext';

        case INTERNAL_FORM_ELEMENT_IMAGE:
            return 'Image';

        case INTERNAL_FORM_ELEMENT_BUSINESS_NAME:
            return 'Business Name';

        case INTERNAL_FORM_ELEMENT_BUSINESS_FULL_ADDRESS:
            return 'Business Full Address';

        case INTERNAL_FORM_ELEMENT_BUSINESS_ADDRESS_LINE1:
            return 'Business Address Line 1';

        case INTERNAL_FORM_ELEMENT_BUSINESS_ADDRESS_LINE2:
            return 'Business Address Line 2';

        case INTERNAL_FORM_ELEMENT_BUSINESS_ADDRESS_COUNTRY:
            return 'Business Address Country';

        case INTERNAL_FORM_ELEMENT_BUSINESS_PHONE:
            return 'Business Phone';

        case INTERNAL_FORM_ELEMENT_BUSINESS_EMAIL:
            return 'Business Email';

        case INTERNAL_FORM_ELEMENT_CUSTOMER_NAME:
            return 'Customer Name';

        case INTERNAL_FORM_ELEMENT_CUSTOMER_FULL_ADDRESS:
            return 'Customer Full Address';

        case INTERNAL_FORM_ELEMENT_CUSTOMER_ADDRESS_LINE1:
            return 'Customer Address Line 1';

        case INTERNAL_FORM_ELEMENT_CUSTOMER_ADDRESS_LINE2:
            return 'Customer Address Line 2';

        case INTERNAL_FORM_ELEMENT_CUSTOMER_ADDRESS_COUNTRY:
            return 'Customer Address Country';

        case INTERNAL_FORM_ELEMENT_CUSTOMER_PHONE:
            return 'Customer Phone';

        case INTERNAL_FORM_ELEMENT_CUSTOMER_EMAIL:
            return 'Customer Email';

        case INTERNAL_FORM_ELEMENT_CUSTOMER_DATE_OF_BIRTH:
            return 'Customer Date of Birth';

        case INTERNAL_FORM_ELEMENT_CUSTOMER_AGE:
            return 'Customer Age';

        case INTERNAL_FORM_ELEMENT_CUSTOMER_HEALTH_CARD:
            return 'Customer Health Card';

        case INTERNAL_FORM_ELEMENT_EXAM_DATE:
            return 'Exam Date';

        case INTERNAL_FORM_ELEMENT_EXAM_DOCTOR:
            return 'Exam Doctor Name';

        case INTERNAL_FORM_ELEMENT_EXAM_DOCTOR_LICENSE_NUMBER:
            return 'Exam Doctor License Number';

        case INTERNAL_FORM_ELEMENT_EXAM_DOCTOR_BILLING_NUMBER:
            return 'Exam Doctor Billing Number';

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_NUMBER:
            return 'Work Order Number';

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_NUMBER:
            return 'Work Order Invoice Number';

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_TOTAL:
            return 'Work Order Invoice Total';

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_BALANCE:
            return 'Work Order Invoice Balance';

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_DOCTOR:
            return 'Work Order Invoice Doctor';

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_CREATED_DATE:
            return 'Work Order Created Date';

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_CREATED_BY:
            return 'Work Order Created By';

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_SUBMITTED_DATE:
            return 'Work Order Submitted Date';

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_SUBMITTED_BY:
            return 'Work Order Submitted By';

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_NOTES:
            return 'Work Order Notes';

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_FIELD:
            return 'Work Order Field';

        case INTERNAL_FORM_ELEMENT_PAGE_NUMBER:
            return 'Page Number';

        default:
            return '';
    }
}

export const getIcon = (type) => {
    switch (type) {
        case INTERNAL_FORM_ELEMENT_RICHTEXT_EDITOR:
            return 'fal fa-text-size';

        case INTERNAL_FORM_ELEMENT_IMAGE:
            return 'fal fa-image';

        case INTERNAL_FORM_ELEMENT_BUSINESS_NAME:
        case INTERNAL_FORM_ELEMENT_BUSINESS_FULL_ADDRESS:
        case INTERNAL_FORM_ELEMENT_BUSINESS_ADDRESS_LINE1:
        case INTERNAL_FORM_ELEMENT_BUSINESS_ADDRESS_LINE2:
        case INTERNAL_FORM_ELEMENT_BUSINESS_ADDRESS_COUNTRY:
        case INTERNAL_FORM_ELEMENT_BUSINESS_PHONE:
        case INTERNAL_FORM_ELEMENT_BUSINESS_EMAIL:
            return 'fal fa-building';

        case INTERNAL_FORM_ELEMENT_CUSTOMER_NAME:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_FULL_ADDRESS:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_ADDRESS_LINE1:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_ADDRESS_LINE2:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_ADDRESS_COUNTRY:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_PHONE:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_EMAIL:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_DATE_OF_BIRTH:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_AGE:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_HEALTH_CARD:
            return 'fal fa-user';

        case INTERNAL_FORM_ELEMENT_EXAM_DATE:
        case INTERNAL_FORM_ELEMENT_EXAM_DOCTOR:
        case INTERNAL_FORM_ELEMENT_EXAM_DOCTOR_LICENSE_NUMBER:
        case INTERNAL_FORM_ELEMENT_EXAM_DOCTOR_BILLING_NUMBER:
            return 'fal fa-clipboard-list-check';

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_NUMBER:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_NUMBER:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_TOTAL:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_BALANCE:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_DOCTOR:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_CREATED_DATE:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_CREATED_BY:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_SUBMITTED_DATE:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_SUBMITTED_BY:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_NOTES:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_FIELD:
            return 'fal fa-inbox'

        case INTERNAL_FORM_ELEMENT_PAGE_NUMBER:
            return 'fal fa-hashtag'

        default:
            return '';
    }
}

export const getDefaultValue = (type, format) => {
    const formatOption = getFormatOptions(type).filter(f => f.type === format)[0];

    switch (type) {
        case INTERNAL_FORM_ELEMENT_RICHTEXT_EDITOR:
            return 'This is a sample text.';

        case INTERNAL_FORM_ELEMENT_IMAGE:
            return '';

        case INTERNAL_FORM_ELEMENT_BUSINESS_NAME:
            return 'Company Name';

        case INTERNAL_FORM_ELEMENT_BUSINESS_FULL_ADDRESS:
            return '199 Main Street East, Toronto, ON M1N 2N2, Canada';

        case INTERNAL_FORM_ELEMENT_BUSINESS_ADDRESS_LINE1:
            return '199 Main Street East';

        case INTERNAL_FORM_ELEMENT_BUSINESS_ADDRESS_LINE2:
            return 'Toronto, ON M1N 2N2';

        case INTERNAL_FORM_ELEMENT_BUSINESS_ADDRESS_COUNTRY:
            return 'Canada';

        case INTERNAL_FORM_ELEMENT_BUSINESS_PHONE:
            return formatOption ? formatOption.example : '+14165559999';

        case INTERNAL_FORM_ELEMENT_BUSINESS_EMAIL:
            return 'info@companyname.com';

        case INTERNAL_FORM_ELEMENT_CUSTOMER_NAME:
            return formatOption ? formatOption.example : 'Jonathan Williams';

        case INTERNAL_FORM_ELEMENT_CUSTOMER_FULL_ADDRESS:
            return '355-20 Applegate Street, Toronto, ON M2M 3K3, Canada';

        case INTERNAL_FORM_ELEMENT_CUSTOMER_ADDRESS_LINE1:
            return '355-20 Applegate Street';

        case INTERNAL_FORM_ELEMENT_CUSTOMER_ADDRESS_LINE2:
            return 'Toronto, ON M2M 3K3';

        case INTERNAL_FORM_ELEMENT_CUSTOMER_ADDRESS_COUNTRY:
            return 'Canada';

        case INTERNAL_FORM_ELEMENT_CUSTOMER_PHONE:
            return formatOption ? formatOption.example : '+14166643333';

        case INTERNAL_FORM_ELEMENT_CUSTOMER_EMAIL:
            return 'jwilliams_20@customeremail.com';

        case INTERNAL_FORM_ELEMENT_CUSTOMER_DATE_OF_BIRTH:
            return 'Jan 10, 2005';

        case INTERNAL_FORM_ELEMENT_CUSTOMER_AGE:
            return '18 yo.';

        case INTERNAL_FORM_ELEMENT_CUSTOMER_HEALTH_CARD:
            return ph.formatHealthCard('9001555123XX');

        case INTERNAL_FORM_ELEMENT_EXAM_DATE:
            return formatOption ? formatOption.example : moment().format('YYYY-MM-DD');

        case INTERNAL_FORM_ELEMENT_EXAM_DOCTOR:
            return 'Dr. Sarah Williams';

        case INTERNAL_FORM_ELEMENT_EXAM_DOCTOR_LICENSE_NUMBER:
            return '0023456';

        case INTERNAL_FORM_ELEMENT_EXAM_DOCTOR_BILLING_NUMBER:
            return '0098765';

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_NUMBER:
            return '0000203';

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_NUMBER:
            return '0005120-001';

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_TOTAL:
            return '$300.00';

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_BALANCE:
            return '$200.00';

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_DOCTOR:
            return 'Dr. John Johnson';

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_CREATED_DATE:
            return formatOption ? formatOption.example : moment().format('YYYY-MM-DD');

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_CREATED_BY:
            return 'John Williams';

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_SUBMITTED_DATE:
            return formatOption ? formatOption.example : moment().format('YYYY-MM-DD');

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_SUBMITTED_BY:
            return 'Jane Williams';

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_NOTES:
            return '--';

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_FIELD:
            return '--';

        case INTERNAL_FORM_ELEMENT_PAGE_NUMBER:
            return formatOption ? formatOption.example : 'Page 1';

        default:
            return '';
    }
}

export const getValue = (element, sources) => {
    const { type, metadata } = element;
    const { format } = metadata;
    const formatOption = getFormatOptions(type).filter(f => f.type === format)[0];

    switch (type) {
        case INTERNAL_FORM_ELEMENT_BUSINESS_NAME:
            return sources && sources.tenant ? fm.apply(sources.tenant.displayName, formatOption) : '';

        case INTERNAL_FORM_ELEMENT_BUSINESS_FULL_ADDRESS:
            return sources && sources.tenant && sources.tenant.address && sources.tenant.address.fullLine ? fm.apply(sources.tenant.address.fullLine, formatOption) : null;

        case INTERNAL_FORM_ELEMENT_BUSINESS_ADDRESS_LINE1:
            return sources && sources.tenant && sources.tenant.address && sources.tenant.address.addressLine1 ? fm.apply(sources.tenant.address.addressLine1, formatOption) : null;

        case INTERNAL_FORM_ELEMENT_BUSINESS_ADDRESS_LINE2:
            return sources && sources.tenant && sources.tenant.address && sources.tenant.address.addressLine2 ? fm.apply(sources.tenant.address.addressLine2, formatOption) : null;

        case INTERNAL_FORM_ELEMENT_BUSINESS_ADDRESS_COUNTRY:
            return sources && sources.tenant && sources.tenant.address && sources.tenant.address.country ? fm.apply(sources.tenant.address.country, formatOption) : null;

        case INTERNAL_FORM_ELEMENT_BUSINESS_PHONE:
            return sources && sources.tenant ? fm.apply(sources.tenant.phoneNumber, formatOption) : null;

        case INTERNAL_FORM_ELEMENT_BUSINESS_EMAIL:
            return sources && sources.tenant ? fm.apply(sources.tenant.emailAddress, formatOption) : null;

        case INTERNAL_FORM_ELEMENT_CUSTOMER_NAME:
            return sources && sources.customer ? fm.apply(sources.customer, formatOption, ph.getPreferredFirstLastName(sources.customer)) : null;

        case INTERNAL_FORM_ELEMENT_CUSTOMER_FULL_ADDRESS:
            return sources && sources.customer && sources.customer && sources.customer.address && sources.customer.address.fullLine ? fm.apply(sources.customer.address.fullLine, formatOption) : null;

        case INTERNAL_FORM_ELEMENT_CUSTOMER_ADDRESS_LINE1:
            return sources && sources.customer && sources.customer && sources.customer.address && sources.customer.address.addressLine1 ? fm.apply(sources.customer.address.addressLine1, formatOption) : null;

        case INTERNAL_FORM_ELEMENT_CUSTOMER_ADDRESS_LINE2:
            return sources && sources.customer && sources.customer && sources.customer.address && sources.customer.address.addressLine2 ? fm.apply(sources.customer.address.addressLine2, formatOption) : null;

        case INTERNAL_FORM_ELEMENT_CUSTOMER_ADDRESS_COUNTRY:
            return sources && sources.customer && sources.customer && sources.customer.address && sources.customer.address.country ? fm.apply(sources.customer.address.country, formatOption) : null;

        case INTERNAL_FORM_ELEMENT_CUSTOMER_PHONE:
            return sources && sources.customer ? fm.apply(sources.customer.phoneNumber, formatOption) : null;

        case INTERNAL_FORM_ELEMENT_CUSTOMER_EMAIL:
            return sources && sources.customer ? fm.apply(sources.customer.emailAddress, formatOption) : null;

        case INTERNAL_FORM_ELEMENT_CUSTOMER_DATE_OF_BIRTH:
            return sources && sources.customer ? fm.apply((sources.customer.dateOfBirth ? moment(sources.customer.dateOfBirth) : null), formatOption.type, (sources.customer.dateOfBirth ? moment(sources.customer.dateOfBirth).format('YYYY-MM-DD') : null)) : null;

        case INTERNAL_FORM_ELEMENT_CUSTOMER_AGE:
            return sources && sources.customer ? ph.getAge(sources.customer.dateOfBirth) : null;

        case INTERNAL_FORM_ELEMENT_CUSTOMER_HEALTH_CARD:
            return sources && sources.customer && sources.customer.patientProfile && sources.customer.patientProfile.healthCardNumber ? ph.formatHealthCard(sources.customer.patientProfile.healthCardNumber) : null;

        case INTERNAL_FORM_ELEMENT_EXAM_DATE:
            return sources && sources.exam && sources.exam.createdDateUtc ? fm.apply(moment.utc(sources.exam.createdDateUtc), formatOption.type, moment.utc(sources.exam.createdDateUtc).format('YYYY-MM-DD')) : null;

        case INTERNAL_FORM_ELEMENT_EXAM_DOCTOR:
            return sources && sources.user ? fm.apply(sources.user, formatOption.type, ph.getPreferredFirstLastName(sources.user)) : null;

        case INTERNAL_FORM_ELEMENT_EXAM_DOCTOR_LICENSE_NUMBER:
            return sources && sources.user ? sources.user.licenseNumber : null;

        case INTERNAL_FORM_ELEMENT_EXAM_DOCTOR_BILLING_NUMBER:
            return sources && sources.user ? sources.user.publicInsuranceProviderNumber : null;

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_NUMBER:
            return sources && sources.workOrder ? fm.apply(sources.workOrder.number, formatOption) : null;

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_NUMBER:
            return sources && sources.workOrder && sources.workOrder.purchase ? fm.apply(sources.workOrder.purchase.invoiceNumber, formatOption) : null;

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_TOTAL:
            return sources && sources.workOrder && sources.workOrder.purchase ? fm.apply(fn.formatCurrency(sources.workOrder.purchase.total), formatOption) : null;

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_BALANCE:
            return sources && sources.workOrder && sources.workOrder.purchase ? fm.apply(fn.formatCurrency(sources.workOrder.purchase.remainingBalance), formatOption) : null;

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_DOCTOR:
            return sources && sources.workOrder && sources.workOrder.purchase && sources.workOrder.purchase.user ? uh.getDisplayFullNameById(sources.workOrder.purchase.user.id) : null;

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_CREATED_DATE:
            return sources && sources.workOrder && sources.workOrder.createdDateUtc ? fm.apply(moment.utc(sources.workOrder.createdDateUtc), formatOption, moment.utc(sources.workOrder.createdDateUtc).format('YYYY-MM-DD')) : null;

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_CREATED_BY:
            return sources && sources.workOrder ? fm.apply(sources.workOrder.createdBy, formatOption) : null;

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_SUBMITTED_DATE:
            return sources && sources.workOrder && sources.workOrder.submittedDateUtc ? fm.apply(moment.utc(sources.workOrder.submittedDateUtc), formatOption, moment.utc(sources.workOrder.submittedDateUtc).format('YYYY-MM-DD')) : null;

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_SUBMITTED_BY:
            return sources && sources.workOrder ? fm.apply(sources.workOrder.submittedBy, formatOption) : null;

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_NOTES:
            if (sources && sources.notes && sources.workOrder) {
                const filteredNotes = sources.notes.filter(n => n.referenceId === sources.workOrder.id);

                if (filteredNotes && filteredNotes.length > 0) {
                    return filteredNotes.map(n => { return n.bodyHtml }).reduce((a, b) => `${a}<br />${b}`);
                }
            }

            return null;

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_FIELD:
            if (sources && sources.workOrder && element.metadata.workOrder && element.metadata.workOrder.field) {
                const variables = mh.parseVariables(element.metadata.workOrder.field);

                if (variables && variables.length > 0) {
                    for (let i = 0; i < variables.length; i++) {
                        if (variables[i] && variables[i].key) {
                            let items = sources.workOrder.items.filter(t => t.template.id === element.metadata.workOrder.id);

                            if (items && items.length > 0) {
                                switch (variables[i].key) {
                                    case 'quantity':
                                        return items.map(t => { return t.transactionItems ? t.transactionItems.length : 1 }).reduce((a, b) => a + b);

                                    case 'productType':
                                        items = items.filter(t => t.productType);
                                        return items && items.length > 0 ? items.map(t => { return t.productType }).filter((v, x, a) => a.indexOf(v) === x).reduce((a, b) => `${a}, ${b}`) : null;

                                    case 'brandName':
                                        items = items.filter(t => t.brandName);
                                        return items && items.length > 0 ? items.map(t => { return t.isOverridden && t.overriddenBrandName ? t.overriddenBrandName : (t.brandName ? t.brandName : 'Custom') }).filter((v, x, a) => a.indexOf(v) === x).reduce((a, b) => `${a}, ${b}`) : null;

                                    case 'supplierName':
                                        items = items.filter(t => t.supplierName);
                                        return items && items.length > 0 ? items.map(t => { return t.isOverridden && t.overriddenSupplierName ? t.overriddenSupplierName : (t.supplierName ? t.supplierName : 'Custom') }).filter((v, x, a) => a.indexOf(v) === x).reduce((a, b) => `${a}, ${b}`) : null;

                                    case 'brandSupplierName':
                                        return items.map(t => { return `${t.isOverridden && t.overriddenBrandName ? t.overriddenBrandName : (t.brandName ? t.brandName : 'Custom')} (${t.isOverridden && t.overriddenSupplierName ? t.overriddenSupplierName : (t.supplierName ? t.supplierName : 'Custom')})` }).filter((v, x, a) => a.indexOf(v) === x).reduce((a, b) => `${a}, ${b}`);

                                    case 'descriptor':
                                        items = items.filter(t => t.descriptor);
                                        return items && items.length > 0 ? items.map(t => { return t.isOverridden && t.overriddenDescriptor ? t.overriddenDescriptor : t.descriptor }).filter((v, x, a) => a.indexOf(v) === x).reduce((a, b) => `${a}, ${b}`) : null;

                                    case 'quantityDescriptor':
                                        const group = [];

                                        items = items.filter(t => t.descriptor);

                                        if (items && items.length > 0) {
                                            const descriptors = items.map(t => { return { quantity: t.transactionItems ? t.transactionItems.length : 1, descriptor: t.isOverridden && t.overriddenDescriptor ? t.overriddenDescriptor : t.descriptor } });

                                            for (let y = 0; y < descriptors.length; y++) {
                                                const groupIndex = (group.indexOf(g => g.descriptor === descriptors[y].descriptor));

                                                if (groupIndex > -1) {
                                                    group[groupIndex].quantity = group[groupIndex].quantity + descriptors[y].quantity;
                                                } else {
                                                    group.push(descriptors[y]);
                                                }
                                            }

                                            return group && group.length > 0 ? group.map(g => { return `${g.quantity}x ${g.descriptor}` }).reduce((a, b) => `${a}, ${b}`) : null;
                                        }
                                        return null;

                                    default:
                                        const found = [];

                                        for (let j = 0; j < items.length; j++) {
                                            const data = items[j].workOrderData ? items[j].workOrderData.filter(d => d.id === variables[i].key)[0] : null;

                                            if (data != null) {
                                                found.push(data);
                                            }
                                        }

                                        return found && found.length > 0 ?
                                            found.map(f => fm.apply(f.value, formatOption)).reduce((a, b) => `${a}, ${b}`) : null;
                                }
                            }
                        }
                    }
                }
            }

            return null;

        case INTERNAL_FORM_ELEMENT_PAGE_NUMBER:
            return formatOption ? formatOption.example : 'Page 1';

        default:
            return '';
    }
}

export const getFormatOptions = (type) => {
    switch (type) {
        case INTERNAL_FORM_ELEMENT_CUSTOMER_NAME:
            return fm.NAME_FORMAT_OPTIONS;

        case INTERNAL_FORM_ELEMENT_BUSINESS_PHONE:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_PHONE:
            return fm.PHONE_FORMAT_OPTIONS;

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_CREATED_DATE:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_SUBMITTED_DATE:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_DATE_OF_BIRTH:
        case INTERNAL_FORM_ELEMENT_EXAM_DATE:
            return fm.DATE_FORMAT_OPTIONS;

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_FIELD:
            return fm.DISPLAY_STRING_OPTIONS;

        case INTERNAL_FORM_ELEMENT_PAGE_NUMBER:
            return fm.PAGE_NUMBER_FORMAT_OPTIONS;

        case INTERNAL_FORM_ELEMENT_EXAM_DOCTOR:
            return fm.DOCTOR_NAME_FORMAT_OPTIONS;

        default:
            return [];
    }
}

export const renderElement = (element, options) => {
    const defaults = {
        controlKey: `_${uuid()}`,
        readOnly: null,
        isDesignMode: true,
        defaultValue: null,
        quickDrawer: null,
        onClick: null,
        onDoubleClick: null,
        onContextMenu: null,
    };
    options = { ...defaults, ...options }

    if (options.pageSettings) {
        options.pageSettings.paper = options.pageSettings.paper ? options.pageSettings.paper : getPaperDimension('letter');
        options.pageSettings.margins = options.pageSettings.margins ? options.pageSettings.margins : INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_MARGINS_NORMAL;
    } else {
        options.pageSettings = {
            paper: getPaperDimension('letter'),
            margins: INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_MARGINS_NORMAL,
        };
    }

    switch (element.type) {
        case INTERNAL_FORM_ELEMENT_RICHTEXT_EDITOR:
        case INTERNAL_FORM_ELEMENT_BUSINESS_NAME:
        case INTERNAL_FORM_ELEMENT_BUSINESS_FULL_ADDRESS:
        case INTERNAL_FORM_ELEMENT_BUSINESS_ADDRESS_LINE1:
        case INTERNAL_FORM_ELEMENT_BUSINESS_ADDRESS_LINE2:
        case INTERNAL_FORM_ELEMENT_BUSINESS_ADDRESS_COUNTRY:
        case INTERNAL_FORM_ELEMENT_BUSINESS_PHONE:
        case INTERNAL_FORM_ELEMENT_BUSINESS_EMAIL:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_NAME:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_FULL_ADDRESS:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_ADDRESS_LINE1:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_ADDRESS_LINE2:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_ADDRESS_COUNTRY:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_PHONE:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_EMAIL:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_DATE_OF_BIRTH:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_AGE:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_HEALTH_CARD:
        case INTERNAL_FORM_ELEMENT_EXAM_DATE:
        case INTERNAL_FORM_ELEMENT_EXAM_DOCTOR:
        case INTERNAL_FORM_ELEMENT_EXAM_DOCTOR_LICENSE_NUMBER:
        case INTERNAL_FORM_ELEMENT_EXAM_DOCTOR_BILLING_NUMBER:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_NUMBER:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_NUMBER:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_TOTAL:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_BALANCE:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_DOCTOR:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_CREATED_DATE:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_CREATED_BY:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_SUBMITTED_DATE:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_SUBMITTED_BY:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_FIELD:
        case INTERNAL_FORM_ELEMENT_PAGE_NUMBER:
            return <div
                className={'form-group print-element no-label' + (element.metadata && element.metadata.valign && element.metadata.valign !== 'top' ? ` text-${element.metadata.valign}` : '')}
                style={{
                    borderTop: element.metadata && element.metadata.border && element.metadata.border.top ? element.metadata.border.top : 'none',
                    borderRight: element.metadata && element.metadata.border && element.metadata.border.right ? element.metadata.border.right : 'none',
                    borderBottom: element.metadata && element.metadata.border && element.metadata.border.bottom ? element.metadata.border.bottom : 'none',
                    borderLeft: element.metadata && element.metadata.border && element.metadata.border.left ? element.metadata.border.left : 'none',
                    backgroundColor: element.metadata && element.metadata.backgroundColor ? element.metadata.backgroundColor : 'transparent',
                }}
                data-id={element.id}
                onDoubleClick={options ? options.onDoubleClick : null}
                onContextMenu={options ? options.onContextMenu : null}
            >
                <div
                    data-key={`pe_${element.id}`}
                    className='element-wrapper'
                    style={{
                        lineHeight: 1.2,
                        textAlign: element.metadata && element.metadata.align ? element.metadata.align : 'left',
                        padding: element.metadata && element.metadata.padding && element.metadata.padding.length === 4 ? element.metadata.padding.map(p => { return `${p}px` }).join(' ') : null,
                        color: element.metadata && element.metadata.fontColor ? element.metadata.fontColor : null,
                        fontSize: element.metadata && element.metadata.fontSize ? `${element.metadata.fontSize}px` : null,
                        fontFamily: element.metadata && element.metadata.fontFamily ? element.metadata.fontFamily : null,
                        fontWeight: element.metadata && element.metadata.fontStyle && element.metadata.fontStyle.some(s => s === 'bold') ? 'bold' : null,
                        fontStyle: element.metadata && element.metadata.fontStyle && element.metadata.fontStyle.some(s => s === 'italic') ? 'italic' : null,
                        textDecoration: element.metadata && element.metadata.fontStyle && element.metadata.fontStyle.some(s => s === 'underline') ? 'underline' : null,
                        letterSpacing: element.metadata && element.metadata.fontLetterSpacing ? `${element.metadata.fontLetterSpacing}px` : null,
                    }}
                >
                    {
                        element.type === INTERNAL_FORM_ELEMENT_RICHTEXT_EDITOR ?
                            <span dangerouslySetInnerHTML={{ __html: element.metadata.content }}></span> :
                            <span><p>{
                                options.defaultValue ?
                                    options.defaultValue :
                                    options.isDesignMode ? getDefaultValue(element.type, (element.metadata && element.metadata.format ? element.metadata.format : null)) : null
                            }</p></span>
                    }
                </div>
            </div>

        case INTERNAL_FORM_ELEMENT_WORK_ORDER_NOTES:
            return <div
                className={'form-group print-element no-label' + (element.metadata && element.metadata.valign && element.metadata.valign !== 'top' ? ` text-${element.metadata.valign}` : '')}
                style={{
                    borderTop: element.metadata && element.metadata.border && element.metadata.border.top ? element.metadata.border.top : 'none',
                    borderRight: element.metadata && element.metadata.border && element.metadata.border.right ? element.metadata.border.right : 'none',
                    borderBottom: element.metadata && element.metadata.border && element.metadata.border.bottom ? element.metadata.border.bottom : 'none',
                    borderLeft: element.metadata && element.metadata.border && element.metadata.border.left ? element.metadata.border.left : 'none',
                    backgroundColor: element.metadata && element.metadata.backgroundColor ? element.metadata.backgroundColor : 'transparent',
                }}
                data-id={element.id}
                onDoubleClick={options ? options.onDoubleClick : null}
                onContextMenu={options ? options.onContextMenu : null}
            >
                <div
                    data-key={`pe_${element.id}`}
                    className='element-wrapper'
                    style={{
                        lineHeight: 1.2,
                        textAlign: element.metadata && element.metadata.align ? element.metadata.align : 'left',
                        padding: element.metadata && element.metadata.padding && element.metadata.padding.length === 4 ? element.metadata.padding.map(p => { return `${p}px` }).join(' ') : null,
                        color: element.metadata && element.metadata.fontColor ? element.metadata.fontColor : null,
                        fontSize: element.metadata && element.metadata.fontSize ? `${element.metadata.fontSize}px` : null,
                        fontFamily: element.metadata && element.metadata.fontFamily ? element.metadata.fontFamily : null,
                        fontWeight: element.metadata && element.metadata.fontStyle && element.metadata.fontStyle.some(s => s === 'bold') ? 'bold' : null,
                        fontStyle: element.metadata && element.metadata.fontStyle && element.metadata.fontStyle.some(s => s === 'italic') ? 'italic' : null,
                        textDecoration: element.metadata && element.metadata.fontStyle && element.metadata.fontStyle.some(s => s === 'underline') ? 'underline' : null,
                        letterSpacing: element.metadata && element.metadata.fontLetterSpacing ? `${element.metadata.fontLetterSpacing}px` : null,
                    }}
                >
                    <span dangerouslySetInnerHTML={{
                        __html:
                            options.defaultValue ?
                                options.defaultValue :
                                options.isDesignMode ? getDefaultValue(element.type, (element.metadata && element.metadata.format ? element.metadata.format : null)) : null
                    }}></span>
                </div>
            </div>

        case INTERNAL_FORM_ELEMENT_IMAGE:
            return <div
                className={'form-group print-element no-label' + (element.metadata && element.metadata.valign && element.metadata.valign !== 'top' ? ` text-${element.metadata.valign}` : '')}
                data-id={element.id}
                onDoubleClick={options ? options.onDoubleClick : null}
                onContextMenu={options ? options.onContextMenu : null}
            >
                <div
                    data-key={`pe_${element.id}`}
                    className='element-wrapper'
                    style={{
                        textAlign: element.metadata && element.metadata.align ? element.metadata.align : 'left',
                    }}
                >
                    <img
                        src={`${(element.metadata && element.metadata.image ? element.metadata.image.base64 : null)}`}
                        style={{
                            width: (element.metadata && element.metadata.image ? getContainImage(element, options.pageSettings).width : null),
                            height: (element.metadata && element.metadata.image ? getContainImage(element, options.pageSettings).height : null),
                        }}
                        alt='print-element'
                    />
                </div>
            </div>


        default:
            break;
    }
}

export const getDefaultPosition = type => {
    switch (type) {
        case INTERNAL_FORM_ELEMENT_IMAGE:
            return { w: 10, h: 10, minW: 1 };

        case INTERNAL_FORM_ELEMENT_RICHTEXT_EDITOR:
            return { w: 20, h: 10, minW: 2 };

        case INTERNAL_FORM_ELEMENT_BUSINESS_NAME:
        case INTERNAL_FORM_ELEMENT_BUSINESS_PHONE:
        case INTERNAL_FORM_ELEMENT_BUSINESS_EMAIL:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_NAME:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_PHONE:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_EMAIL:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_DATE_OF_BIRTH:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_AGE:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_HEALTH_CARD:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_NUMBER:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_NUMBER:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_TOTAL:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_BALANCE:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_INVOICE_DOCTOR:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_CREATED_DATE:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_CREATED_BY:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_SUBMITTED_DATE:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_SUBMITTED_BY:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_NOTES:
        case INTERNAL_FORM_ELEMENT_WORK_ORDER_FIELD:
            return { w: 6, h: 4, minW: 2 };

        case INTERNAL_FORM_ELEMENT_BUSINESS_FULL_ADDRESS:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_FULL_ADDRESS:
            return { w: 20, h: 10, minW: 2 };

        case INTERNAL_FORM_ELEMENT_BUSINESS_ADDRESS_LINE1:
        case INTERNAL_FORM_ELEMENT_BUSINESS_ADDRESS_LINE2:
        case INTERNAL_FORM_ELEMENT_BUSINESS_ADDRESS_COUNTRY:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_ADDRESS_LINE1:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_ADDRESS_LINE2:
        case INTERNAL_FORM_ELEMENT_CUSTOMER_ADDRESS_COUNTRY:
            return { w: 12, h: 4, minW: 2 };

        default:
            return {};
    }
}

export const getContainImage = (imageElement, pageSettings) => {
    let { width: imageWidth, height: imageHeight } = imageElement.metadata.image;
    const containerWidth = parseFloat(imageElement.position.w * ((pageSettings.paper.width - (pageSettings.margins * 2)) / 64), 10);
    const containerHeight = parseFloat(imageElement.position.h * INTERNAL_FORM_TEMPLATE_ROW_HEIGHT, 10);

    if (containerHeight >= containerWidth) {
        if (imageHeight > containerHeight) {
            const heightScale = containerHeight / imageHeight;

            imageWidth = imageWidth * heightScale;
            imageHeight = containerHeight;
        }

        if (imageWidth > containerWidth) {
            const scale = containerWidth / imageWidth;

            imageWidth = containerWidth;
            imageHeight = imageHeight * scale;
        }
    }
    else if (containerWidth > containerHeight) {
        if (imageWidth > containerWidth) {
            const widthScale = containerWidth / imageWidth;

            imageWidth = containerWidth;
            imageHeight = imageHeight * widthScale;
        }

        if (imageHeight > containerHeight) {
            const scale = containerHeight / imageHeight;

            imageWidth = imageWidth * scale;
            imageHeight = containerHeight;
        }
    }

    return {
        width: imageWidth,
        height: imageHeight,
    };
}

export const getPaperDimension = (paperType, margins = INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_MARGINS_NORMAL) => {
    if (!paperType) {
        paperType = 'letter';
    }

    switch (paperType.toLowerCase()) {
        case 'a4':
            return {
                type: paperType.toLowerCase(),
                width: INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_PAPER_A4_WIDTH,
                height: INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_PAPER_A4_HEIGHT,
                grid: getGridSizeByWidthAndMargins(INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_PAPER_A4_WIDTH, margins),
            };

        case 'legal':
            return {
                type: paperType.toLowerCase(),
                width: INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_PAPER_LEGAL_WIDTH,
                height: INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_PAPER_LEGAL_HEIGHT,
                grid: getGridSizeByWidthAndMargins(INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_PAPER_LEGAL_WIDTH - margins),
            };

        case 'letter':
        default:
            return {
                type: paperType.toLowerCase(),
                width: INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_PAPER_LETTER_WIDTH,
                height: INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_PAPER_LETTER_HEIGHT,
                grid: getGridSizeByWidthAndMargins(INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_PAPER_LETTER_WIDTH - margins),
            };
    }
}

export const getPageStyle = (pageSettings) => {
    if (pageSettings) {
        const backgroundImage = pageSettings.backgroundImage ? pageSettings.backgroundImage : null;
        const paper = pageSettings.paper ? pageSettings.paper : getPaperDimension('letter');
        const margins = pageSettings.margins ? pageSettings.margins : INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_MARGINS_NORMAL;
        const width = paper.orientation === 'landscape' ? paper.height : paper.width;
        const height = paper.orientation === 'landscape' ? paper.width : paper.height;

        return {
            width: `${width}px`,
            height: `${height}px`,
            padding: `${margins}px`,
            backgroundImage: backgroundImage ? `url(${backgroundImage.base64})` : null,
            backgroundRepeat: backgroundImage ? 'no-repeat' : null,
        }
    }

    return {
        width: `${getPaperDimension('letter').width}px`,
        height: `${getPaperDimension('letter').height}px`,
        padding: `${INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_MARGINS_NORMAL}px`,
    }
}

export const getPageContentStyle = (pageSettings) => {
    if (pageSettings) {
        const paper = pageSettings.paper ? pageSettings.paper : getPaperDimension('letter');
        const width = paper.orientation === 'landscape' ? paper.height : paper.width;
        const height = paper.orientation === 'landscape' ? paper.width : paper.height;

        return {
            width: `${width}px`,
            height: `${(height + 2)}px`,
        }
    }

    return {
        width: `${getPaperDimension('letter').width}px`,
        height: `${getPaperDimension('letter').height}px`
    }
}

export const getHeaderFooterTemplateContainerStyle = (pageSettings) => {
    if (pageSettings) {
        const margins = pageSettings.margins ? pageSettings.margins : INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_MARGINS_NORMAL;

        return {
            marginLeft: `${(margins * -1)}px`,
            marginRight: `${(margins * -1)}px`,
            paddingLeft: `${margins}px`,
            paddingRight: `${margins}px`,
        }
    }

    return {
        marginLeft: `${(INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_MARGINS_NORMAL * -1)}px`,
        marginRight: `${(INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_MARGINS_NORMAL * -1)}px`,
        paddingLeft: `${INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_MARGINS_NORMAL}px`,
        paddingRight: `${INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_MARGINS_NORMAL}px`,
    }
}

export const getHeaderTemplateGridStyle = (pageSettings) => {
    if (pageSettings) {
        const paper = pageSettings.paper ? pageSettings.paper : getPaperDimension('letter');
        const width = paper.orientation === 'landscape' ? paper.height : paper.width;
        const margins = pageSettings.margins ? pageSettings.margins : INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_MARGINS_NORMAL;
        let height = paper.orientation === 'landscape' ? paper.width : paper.height;

        height = pageSettings.header ? pageSettings.header : INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_HEADER_FOOTER_HEIGHT_DEFAULT;

        return {
            width: `${(width - (margins * 2))}px`,
            height: `${height}px`,
            minHeight: `${height}px`,
        }
    }

    return {
        width: `${(getPaperDimension('letter').width - (INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_MARGINS_NORMAL * 2))}px`,
        height: `${INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_HEADER_FOOTER_HEIGHT_DEFAULT}px`,
        minHeight: `${INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_HEADER_FOOTER_HEIGHT_DEFAULT}px`,
    }
}

export const getBodyTemplateGridStyle = (pageSettings) => {
    if (pageSettings) {
        const paper = pageSettings.paper ? pageSettings.paper : getPaperDimension('letter');
        const width = paper.orientation === 'landscape' ? paper.height : paper.width;
        const height = paper.orientation === 'landscape' ? paper.width : paper.height;
        const margins = pageSettings.margins ? pageSettings.margins : INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_MARGINS_NORMAL;
        const header = pageSettings.header ? pageSettings.header : 0;
        const footer = pageSettings.footer ? pageSettings.footer : 0;

        return {
            width: `${(width - (margins * 2))}px`,
            height: `${(height - (margins * 2) - header - footer)}px`,
            minHeight: `${(height - (margins * 2) - header - footer)}px`,
        }
    }

    return {
        width: `${(getPaperDimension('letter').width - (INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_MARGINS_NORMAL * 2))}px`,
        height: `${(getPaperDimension('letter').height - (INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_MARGINS_NORMAL * 2))}px`,
        minHeight: `${(getPaperDimension('letter').height - (INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_MARGINS_NORMAL * 2))}px`,
    }
}

export const getFooterTemplateGridStyle = (pageSettings) => {
    if (pageSettings) {
        const paper = pageSettings.paper ? pageSettings.paper : getPaperDimension('letter');
        const width = paper.orientation === 'landscape' ? paper.height : paper.width;
        const margins = pageSettings.margins ? pageSettings.margins : INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_MARGINS_NORMAL;
        let height = paper.orientation === 'landscape' ? paper.width : paper.height;

        height = pageSettings.footer ? pageSettings.footer : INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_HEADER_FOOTER_HEIGHT_DEFAULT;

        return {
            width: `${(width - (margins * 2))}px`,
            height: `${height}px`,
            minHeight: `${height}px`,
        }
    }

    return {
        width: `${(getPaperDimension('letter').width - (INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_MARGINS_NORMAL * 2))}px`,
        height: `${INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_HEADER_FOOTER_HEIGHT_DEFAULT}px`,
        minHeight: `${INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_HEADER_FOOTER_HEIGHT_DEFAULT}px`,
    }
}

export const getGridSize = (pageSettings) => {    
    if (pageSettings) {
        const paper = pageSettings.paper ? pageSettings.paper : getPaperDimension('letter');
        const margins = pageSettings.margins ? pageSettings.margins : INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_MARGINS_NORMAL;
        const width = paper.orientation === 'landscape' ? paper.height : paper.width;
        const grid = getGridSizeByWidthAndMargins(width, margins);

        return grid;
    }

    return null;
}

export const getGridSizeByWidthAndMargins = (width, margins) => {
    return parseInt((width - margins) / INTERNAL_FORM_TEMPLATE_CELL_WIDTH, 10);
}

export const getTemplateCols = (pageSettings) => {
    if (pageSettings) {
        const grid = getGridSize(pageSettings);

        return { lg: grid, md: grid, sm: grid, xs: grid, xxs: grid }
    }

    return null;
}

export const renderContentDefinition = (options) => {
    const { pageSettings } = options.selectedPage;
    const paper = pageSettings && pageSettings.paper ? pageSettings.paper : getPaperDimension('letter');
    const margins = pageSettings.margins ? pageSettings.margins : INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_MARGINS_NORMAL;
    const width = paper.orientation === 'landscape' ? paper.height : paper.width;
    const height = paper.orientation === 'landscape' ? paper.width : paper.height;
    const gridCellWidth = INTERNAL_FORM_TEMPLATE_CELL_WIDTH;
    const gridSize = getGridSizeByWidthAndMargins(width, margins);
    const defaults = {
        readOnly: false,
        isDesignMode: false,
        renderInputs: true,
        renderControls: true,
        renderPrintElements: true,
        referenceObject: null,
        pageSettings: pageSettings,
        definition: null,
        defaultValue: null,
        value: null,
        rowHeight: INTERNAL_FORM_TEMPLATE_ROW_HEIGHT,
        gridWidth: width,
        gridHeight: height,
        gridMargins: margins,
        gridCellWidth: gridCellWidth,
        gridSize: gridSize,
        getValue: null,
        getControlValue: null,
        onChange: null,
    };

    options = { ...defaults, ...options };

    if (options.definition) {
        return options.definition.map((o, oi) => {
            return <div
                key={`${o.id}_${oi}`}
                data-grid={o.position}
            >
                {
                    options.renderInputs && iih.isInputElement(o) ?
                        <>
                            <Observer>{() =>
                                iih.renderInput(o, {
                                    readOnly: options.readOnly,
                                    referenceObject: options.selectedPage,
                                    value: fn.isFunction(options.getValue) ? options.getValue(o.key) : null,
                                    onChange: fn.isFunction(options.handleDataChange) ? options.handleDataChange(o) : null,
                                })
                            }</Observer>
                        </> : null
                }
                {
                    options.renderControls && tch.isControlElement(o) ?
                        <>
                            <Observer>{() =>
                                <>
                                    {
                                        tch.renderControl(o, {
                                            rowHeight: INTERNAL_FORM_TEMPLATE_ROW_HEIGHT,
                                            gridWidth: width,
                                            gridHeight: height,
                                            gridMargins: margins,
                                            gridCellWidth: gridCellWidth,
                                            gridSize: gridSize,
                                            readOnly: options.readOnly,
                                            value: fn.isFunction(options.getControlValue) ? options.getControlValue(o) : null,
                                            referenceObject: options.selectedPage,
                                            getValue: options.getValue,
                                            getControlValue: options.getControlValue,
                                            onChange: options.handleDataChange,
                                        })
                                    }
                                </>
                            }</Observer>
                        </> : null
                }
                {
                    isInternalFormElement(o) ?
                        <>
                            {
                                options.renderPrintElements && renderElement(o, {
                                    defaultValue: fn.isFunction(options.getPrintElementValue) ? options.getPrintElementValue(o) : null,
                                    pageSettings: pageSettings,
                                    isDesignMode: options.isDesignMode,
                                })
                            }
                        </> : null
                }
            </div>
        })
    }
}