import { createContext } from 'react';
import { decorate, observable, action } from 'mobx';
import moment from 'moment';

import * as fn from '../utilities/_functions';
import * as eh from '../utilities/examHelper';
import api from '../api';

export class ExamCreate {
    appointment = null;
    templates = [];
    selectedTemplateId = null;
    pretestImportType = 'today';
    isSaving = false;
    isLoading = false;
    isReady = false;

    cancelExamCreate = null;
    cancelExamTemplateSearch = null;
    cancelPreviousExams = null;
    cancelPublicPredefinedData = null;
    cancelUserPredefinedData = null;

    initialize = (appointment) => {
        const that = this;
        this.clear();
        
        if (appointment) {            
            this.appointment = appointment;
            this.selectedTemplateId = eh.getDefaultExamTemplateId(appointment.services);
        }

        this.isLoading = true;

        return new Promise((resolve, reject) => {
            api.ExamTemplates.search(
                {
                    parameters: [
                        {
                            field: 'PublishedTemplateId',
                            operator: '!=',
                            value: null
                        },
                        {
                            field: 'IsSystemTemplate',
                            value: false
                        },
                    ],
                    loadProperties: false,
                    includeTotalCount: false,
                },
                (c) => { that.cancelExamTemplateSearch = c }
            )
                .then(({ data }) => {
                    const examTemplates = data && data.result ? data.result : [];
                    that.templates = examTemplates;

                    if (!that.selectedTemplateId && examTemplates && examTemplates.length > 0) {
                        that.selectedTemplateId = examTemplates.sort((a, b) => {
                            if (a.isDefault && !b.isDefault) {
                                return -1;
                            }
                            else if (!a.isDefault && b.isDefault) {
                                return 1;
                            }
                            else {
                                return a.name.localeCompare(b.name);
                            }
                        })[0].id;
                    }

                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isLoading = false;
                    that.isReady = true;
                })
        })
    }

    blank = () => {
        const that = this;
        const start = moment();

        return this.create({
            creationType: 'Blank',
            appointmentId: that.appointment.id,
            pretestImportType: that.pretestImportType,
            startUtc: start.clone().utc(),
        });
    }

    predefinedData = (predefinedDataId) => {
        const that = this;
        const start = moment();

        return this.create({
            creationType: 'PredefinedData',
            appointmentId: that.appointment.id,
            predefinedDataId: predefinedDataId,
            pretestImportType: that.pretestImportType,
            startUtc: start.clone().utc(),
        });
    }

    previousExam = (previousExamId) => {
        const that = this;
        const start = moment();

        return this.create({
            creationType: 'PreviousExam',
            appointmentId: that.appointment.id,
            previousExamId: previousExamId,
            pretestImportType: that.pretestImportType,
            startUtc: start.clone().utc(),
        });
    }

    create = (option) => {
        const that = this;
        this.isSaving = true;

        return new Promise((resolve, reject) => {
            option.examTemplateId = that.selectedTemplateId;

            api.Exams.create(
                option,
                (c) => { that.cancelExamCreate = c }
            )
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.cancelExamCreate = null;
                    that.isSaving = false;
                })
        })
    }

    clear = () => {
        this.appointment = null;
        this.templates.clear();
        this.selectedTemplateId = null;
        this.pretestImportType = 'today';
        this.isSaving = false;
        this.isLoading = false;
        this.isReady = false;

        if (fn.isFunction(this.cancelExamCreate)) {
            this.cancelExamCreate();
            this.cancelExamCreate = null;
        }

        if (fn.isFunction(this.cancelExamTemplateSearch)) {
            this.cancelExamTemplateSearch();
            this.cancelExamTemplateSearch = null;
        }

        if (fn.isFunction(this.cancelPreviousExams)) {
            this.cancelPreviousExams();
            this.cancelPreviousExams = null;
        }

        if (fn.isFunction(this.cancelPublicPredefinedData)) {
            this.cancelPublicPredefinedData();
            this.cancelPublicPredefinedData = null;
        }

        if (fn.isFunction(this.cancelUserPredefinedData)) {
            this.cancelUserPredefinedData();
            this.cancelUserPredefinedData = null;
        }
    }
}

decorate(ExamCreate, {
    appointment: observable,
    templates: observable,
    selectedTemplateId: observable,
    pretestImportType: observable,
    blank: action,
    template: action,
    create: action,
    isSaving: observable,
    isLoading: observable,
    isReady: observable,
})

export default createContext(new ExamCreate());