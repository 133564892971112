import React, { useEffect, useContext, useRef, useState } from 'react';
import FadeIn from 'react-fade-in';
import { Observer } from 'mobx-react-lite';
import { GlobalHotKeys } from 'react-hotkeys';
import { ContextMenu } from 'devextreme-react/context-menu';
import moment from 'moment';

import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';

import ExamViewStore from '../../../../stores/ExamViewStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as fn from '../../../../utilities/_functions';
import * as ah from '../../../../utilities/addressHelper';
import * as uh from '../../../../utilities/userHelper';
import * as bh from '../../../../utilities/badgeHelper';
import * as oh from '../../../../utilities/operationHelper';

import './ViewReferrals.scss';

function ViewReferrals(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const validateRef = useRef(null);
    const exam = useContext(ExamViewStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        loadData();
        quickDrawerFocus(props.drawer);

        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const loadData = () => {
        if (!exam.isReferralsReady) {
            exam.loadReferrals()
                .then(() => {
                    if (isMounted.current) {
                        setIsReady(true);
                    }
                })
        }
        setIsReady(true);
    }

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            props.onCancel();
        }
    }

    const handleReferralClick = (event, { id }) => {
        if (isMounted.current) {
            if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                props.onSuccess(event, { action: 'update', updated: false, data: { id: id } });
            }
        }
    }

    const handleCopyReferralClick = (event, referralId) => {
        if (isMounted.current) {
            if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                props.onSuccess(event, { action: 'copy', data: { id: referralId } });
            }
        }
    }

    const handleReferralAppointmentClick = (event, { id }) => {
        if (isMounted.current) {
            if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                props.onSuccess(event, { action: 'appointment', updated: false, data: { id: id } });
            }
        }
    }

    const handleCreateReferral = (event) => {
        if (isMounted.current) {
            if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                props.onSuccess(event, { action: 'create', updated: false });
            }
        }
    }

    const handleContextMenuItemClick = event => {
        const { itemData } = event;

        if (itemData.callback && fn.isFunction(itemData.callback)) {
            itemData.callback();
        }
    }

    const getReferredToName = (referralAppointment) => {
        if (!referralAppointment) return null;
        if (referralAppointment.externalDoctorName) return referralAppointment.externalDoctorName;
        if (referralAppointment.externalClinicName) return referralAppointment.externalClinicName;
        return referralAppointment.recipientName;
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form ref={validateRef}>
            <Observer>{() =>
                <fieldset>
                    <div className='quick-drawer'>
                        <QuickDrawerHeader
                            drawer={props.drawer}
                            icon={oh.getIcon('referral', 'default')}
                            action='View'
                            category='Referrals'
                            className='orders'
                            onCancel={handleCancel}
                        />
                        <div className='quick-drawer-body'>
                            {
                                isReady && exam.isReferralsReady ?
                                    <FadeIn>
                                        <div className='referrals-container'>
                                            <div className='body-content p-0'>
                                                <section className='referrals'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-0 validate validate-required'>
                                                                <Observer>{() => <>
                                                                    {
                                                                        exam.referrals && exam.referrals.filter(r => !r.isCompleted).length > 0 ?
                                                                            exam.referrals.filter(r => !r.isCompleted).sort((a, b) => { return moment.utc(a.CreatedDateUtc) - moment.utc(b.CreatedDateUtc) }).map((r, ri) => {
                                                                                return <div
                                                                                    key={`exam-draft-referral-${ri}`}
                                                                                    className={'referral-wrapper mb-4' + (ri !== 0 ? ' pt-4 border-top' : '')}
                                                                                    onClick={(e) => { handleReferralClick(e, r) }}
                                                                                >
                                                                                    <span className='d-flex flex-row mb-1'>
                                                                                        {bh.renderReferralStatus(r, 'p-1')}
                                                                                    </span>
                                                                                    <span className='descriptor fw-500 d-block text-gray-700 text-truncate text-truncate-xxl'>
                                                                                        {r.subject}
                                                                                    </span>
                                                                                    <span className='text-muted fs-xs d-block'>
                                                                                        By <span className='text-gray-700 fw-500'>{uh.getDisplayFullNameById(r.createdById)}</span> on {fn.formatFullDateWithOrWithoutYear(moment.utc(r.createdDateUtc).local())}
                                                                                    </span>
                                                                                </div>
                                                                            }) : null
                                                                    }
                                                                    {
                                                                        exam.referrals && exam.referrals.filter(r => r.isCompleted).length > 0 ?
                                                                            exam.referrals.filter(r => r.isCompleted).sort((a, b) => { return moment.utc(a.CreatedDateUtc) - moment.utc(b.CreatedDateUtc) }).map((r, ri) => {
                                                                                return r.referralAppointments && r.referralAppointments.length > 0 ?
                                                                                    r.referralAppointments.sort((x, y) => { return moment.utc(x.CreatedDateUtc) - moment.utc(y.CreatedDateUtc) }).map((ra, rai) => {
                                                                                        return <div
                                                                                            key={`exam-referral-appointment-${ri}-${rai}`}
                                                                                            className={'referral-wrapper mb-4' + (ri !== 0 || exam.referrals.filter(r => !r.isCompleted).length > 0 ? ' pt-4 border-top' : '')}
                                                                                        >
                                                                                            <div className='d-flex flex-row'>
                                                                                                <div className='flex-1' onClick={(e) => { handleReferralAppointmentClick(e, ra) }}>
                                                                                                    <span className='d-flex flex-row mb-1'>
                                                                                                        {bh.renderReferralAppointmentStatus(ra, 'p-1')}
                                                                                                    </span>
                                                                                                    <span className='descriptor fw-500 d-block text-gray-700 text-truncate'>
                                                                                                        {r.subject}
                                                                                                    </span>
                                                                                                    {
                                                                                                        ra.start ?
                                                                                                            <span className='descriptor fw-500 d-block text-gray-700 text-truncate text-truncate-xxl'>
                                                                                                                {fn.formatFullDate(moment(ra.start))} @ {fn.formatDate(moment(ra.start), 'h:mm a').toLowerCase()}
                                                                                                            </span> : null

                                                                                                    }
                                                                                                    <span className='descriptor d-block text-gray-700 text-truncate mb-1'>
                                                                                                        {getReferredToName(ra)}
                                                                                                        {
                                                                                                            ra.externalAddress ?
                                                                                                                <>
                                                                                                                    {ah.getAddressHtml(ra.externalAddress)}
                                                                                                                </> : null
                                                                                                        }
                                                                                                    </span>
                                                                                                    <span className='text-muted fs-xs d-block'>
                                                                                                        By <span className='text-gray-700 fw-500'>{uh.getDisplayFullNameById(ra.createdById)}</span> on {fn.formatFullDateWithOrWithoutYear(moment.utc(r.createdDateUtc).local())}
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <div id={`referral_${ra.id}`} className='btn btn-icon'>
                                                                                                        <i className='fal fa-ellipsis-v fs-xxl'></i>
                                                                                                    </div>
                                                                                                    <ContextMenu
                                                                                                        target={`#referral_${ra.id}`}
                                                                                                        position={{ my: 'top right', at: 'top left' }}
                                                                                                        dataSource={[{
                                                                                                            icon: 'fal fa-copy',
                                                                                                            name: 'Clone',
                                                                                                            callback: (e) => handleCopyReferralClick(e, ra.referralId)
                                                                                                        }]}
                                                                                                        displayExpr='name'
                                                                                                        showEvent="dxcontextmenu click"
                                                                                                        onItemClick={handleContextMenuItemClick}
                                                                                                    >
                                                                                                    </ContextMenu>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    }) : null
                                                                            }) : null
                                                                    }
                                                                </>
                                                                }</Observer>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </FadeIn> : renderQuickDrawerLoading()
                            }
                        </div>
                        <div className='quick-drawer-action'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='float-right'>
                                        <button
                                            type='button'
                                            className={'btn btn-link btn-cancel mr-2'}
                                            onClick={handleCancel}
                                        >Cancel</button>
                                        <button
                                            data-create-referral
                                            type='button'
                                            className='btn btn-success'
                                            onClick={handleCreateReferral}
                                        >Create</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            }</Observer>
        </form>
    </>
}

export default ViewReferrals;