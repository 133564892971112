import React, { useContext, useEffect, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { Observer } from 'mobx-react-lite';
import momentLocalizer from 'react-widgets-moment';
import { GlobalHotKeys } from 'react-hotkeys';
import moment from 'moment';

import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';

import ServiceOverrideUpdateStore from '../../../../stores/ServiceOverrideUpdateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as oh from '../../../../utilities/operationHelper';

import './UpdateServiceOverride.scss';

moment.locale('en');
momentLocalizer();


function UpdateServiceOverride(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const serviceUserOverride = useContext(ServiceOverrideUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);

    useEffect(() => {
        quickDrawerFocus(props.drawer);
        return () => {
            isMounted.current = false;
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (serviceUserOverride.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleAdditionalMinutesChange = (event, suoEntry) => {
        suoEntry.additionalTimeInMinutes = event.target.value;
        serviceUserOverride.hasUnsavedChanges = true;
    }

    const handleIsBookableChange = (event, suoEntry) => {
        suoEntry.isBookable = !suoEntry.isBookable;
        if (!suoEntry.isBookable) {
            suoEntry.additionalTimeInMinutes = 0;
        }
        serviceUserOverride.hasUnsavedChanges = true;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        // if (fn.validateForm(validateRef.current)) {
        await serviceUserOverride.save(true);
        if (isMounted.current) {
            if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                props.onSuccess(event, null);
            }
        }
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <fieldset disabled={serviceUserOverride.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('service', 'update')}
                        action='Update'
                        category={`Overrides - ${serviceUserOverride.serviceName}`}
                        className='services'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        <Observer>{() =>
                            serviceUserOverride.isReady ?
                                <FadeIn>
                                    <div className='update-service body-content'>
                                        <Observer>{() =>
                                            <section>
                                                {
                                                    serviceUserOverride.data.map((suo) => {
                                                        return <div className='mb-3' key={`override-${suo.entityId}-${suo.entityType}`}>
                                                            <label>{suo.entityName}</label>
                                                            <div className='form-group mb-2'>
                                                                <label htmlFor={`additional-min-${suo.entityId}-${suo.entityType}`}><small>Additional Minutes</small></label>
                                                                <input
                                                                    id={`additional-min-${suo.entityId}-${suo.entityType}`}
                                                                    type='number'
                                                                    className='form-control'
                                                                    disabled={!suo.isBookable}
                                                                    step={5}
                                                                    value={suo.additionalTimeInMinutes}
                                                                    onChange={(evt) => handleAdditionalMinutesChange(evt, suo)}
                                                                />
                                                            </div>
                                                            <div className='form-check'>
                                                                <input
                                                                    id={`bookable-min-${suo.entityId}-${suo.entityType}`}
                                                                    type="checkbox"
                                                                    name={`bookable-min-${suo.entityId}-${suo.entityType}`}
                                                                    className='form-check-input'
                                                                    defaultChecked={suo.isBookable ? suo.isBookable : false}
                                                                    onChange={(evt) => handleIsBookableChange(evt, suo)}
                                                                />
                                                                <label className='form-check-label' htmlFor={`bookable-min-${suo.entityId}-${suo.entityType}`}>Bookable</label>
                                                            </div>
                                                            <div className='form-group'></div>
                                                        </div>
                                                    })
                                                }
                                            </section>
                                        }</Observer>
                                    </div>
                                </FadeIn> : renderQuickDrawerLoading()
                        }</Observer>
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form >
    </>
}

export default UpdateServiceOverride;