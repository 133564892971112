import React, { useEffect, useContext, useRef, useState } from 'react';
import FadeIn from 'react-fade-in';
import { useObserver } from 'mobx-react-lite';
import TimeAgo from 'react-timeago';
import { toast } from 'react-toastify';
import { GlobalHotKeys } from 'react-hotkeys';
import moment from 'moment';

import BodyEnd from '../../_shared/BodyEnd';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';
import PurchaseModal from '../../purchases/PurchaseModal/_index';

import CustomerViewStore from '../../../../stores/CustomerViewStore';
import PurchaseViewStore from '../../../../stores/PurchaseViewStore';
import PurchaseUpdateStore from '../../../../stores/PurchaseUpdateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';
import useSignalR from '../../../hooks/useSignalR';

import * as oh from '../../../../utilities/operationHelper';
import * as bh from '../../../../utilities/badgeHelper';
import * as sys from '../../../../utilities/systemHelper';

import './ViewInvoices.scss';

function Invoices(props) {
    const isMounted = useRef(true);
    const purchaseModalTimer = useRef(null);
    const focusTimer = useRef(null);
    const purchaseModalRef = useRef(null);
    const customer = useContext(CustomerViewStore);
    const purchaseView = useContext(PurchaseViewStore);
    const purchaseUpdate = useContext(PurchaseUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const signalR = useSignalR();
    const [isPurchaseModalActive, setIsPurchaseModalActive] = useState(false);

    useEffect(() => {
        
        signalR.on('Purchase', (updated) => {
            if (updated && customer.data.id === updated.customerId) {
                customer.loadPurchases(true);
            }
        });

        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100)

        return () => {
            isMounted.current = false;

            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
            if (purchaseModalTimer.current) { clearTimeout(purchaseModalTimer.current); purchaseModalTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handlePurchaseModalOpen = ({ id }) => {
        if (id && isMounted.current) {
            purchaseView.load(id)
                .then(() => {
                    if (isMounted.current) {
                        setIsPurchaseModalActive(true);
                        purchaseModalRef.current.show();
                    }
                })
        }
    }

    const handlePurchaseModalSuccess = (event, result) => {
        if (result && result.updated) {
            toast.dark(() => <p data-purch-upd>Purchase updated.</p>);
            setIsPurchaseModalActive(false);
        }
    }

    const handlePurchaseModalClose = () => {
        purchaseView.clear();
        purchaseUpdate.clear();
        setIsPurchaseModalActive(false);
    }

    const handleStartInvoice = appointment => {
        if (isMounted.current) {
            purchaseModalRef.current.changeMode('update');
            purchaseUpdate.start(customer.data)
                .then(() => {
                    if (isMounted.current) {
                        purchaseModalRef.current.show();
                    }
                })
        }
    }

    const renderPurchaseDateTime = purchase => {
        const start = moment.utc(purchase.createdDateUtc).local();

        return moment().startOf('day').isSame(start.clone().startOf('day')) ?
            <TimeAgo
                className='text-gray-700 fs-xs ml-auto'
                date={start.toDate()}
                title={sys.getFormattedLongDate(start, true)}
                minPeriod={60}
            /> :
            <span
                className='text-gray-700 fs-xs ml-auto'
            >
                {sys.getFormattedLongDate(start)}
            </span>
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened && !isPurchaseModalActive) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                props.onCancel(event);
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form>
            <fieldset>
                <div className='invoices-container quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('invoice')}
                        action='View Customer'
                        category='Invoices'
                        className='invoices'
                        onCancel={props.onCancel}
                    />
                    <div className='quick-drawer-body'>
                        {
                            customer && customer.isPurchasesReady ?
                                <FadeIn>
                                    <div className='body-content'>
                                        <section>
                                            <div className='row mt-n3'>
                                                {
                                                    customer.purchases.length > 0 ?
                                                        customer.purchases.map((p, pi) => {
                                                            return <div key={`customer-purchase-${pi}`} className='col-12'>
                                                                <div
                                                                    className='d-flex flex-column align-items-center'
                                                                >
                                                                    <div className='d-flex flex-row w-100 px-1 pt-3 pb-2'>
                                                                        <div className='mb-0 flex-1 text-dark'>
                                                                            <div className='d-flex mb-h'>
                                                                                <span
                                                                                    className='fw-500 text-gray-700 text-info-hover cursor-pointer'
                                                                                    onClick={() => { handlePurchaseModalOpen(p) }}
                                                                                >
                                                                                    Invoice # {p.number}
                                                                                </span>
                                                                                {renderPurchaseDateTime(p)}
                                                                            </div>
                                                                            <div className='d-flex mb-h'>
                                                                                <div className='flex-1'>
                                                                                    {bh.renderPurchaseBalanceStatus(p, 'p-1 mr-1 mb-1')}{bh.renderPurchaseReferenceTypes(p, 'p-1 mr-1 mb-1')}
                                                                                </div>
                                                                                <span className='text-gray-700 ml-auto'>{bh.renderPurchaseAmount(p)}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }) : <div className='col-12 p-3'>
                                                            <span className='text-muted'>
                                                                No invoice found
                                                            </span>
                                                        </div>
                                                }
                                            </div>
                                        </section>
                                    </div>
                                </FadeIn> : renderQuickDrawerLoading()
                        }
                    </div>
                    <div className='quick-drawer-action pl-3'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={props.onCancel}
                                    >Done</button>
                                    <button
                                        type='button'
                                        className='btn btn-primary'
                                        onClick={handleStartInvoice}
                                    >Create</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
        <BodyEnd>
            <PurchaseModal ref={purchaseModalRef} defaultMode='view' onSuccess={handlePurchaseModalSuccess} onClose={handlePurchaseModalClose} />
        </BodyEnd>
    </>)
}

export default Invoices;