import React, { useEffect, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import FadeIn from 'react-fade-in';
import { useObserver } from 'mobx-react-lite';
import MaskedInput from 'react-text-mask'
import { DropdownList } from 'react-widgets'
import { GlobalHotKeys } from 'react-hotkeys';

import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';

import CustomerStoreCreditTransferStore from '../../../../stores/CustomerStoreCreditTransferStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as rts from '../../../../constants/routes';
import * as MaskKeys from '../../../../constants/maskKeys';
import * as fn from '../../../../utilities/_functions';
import * as ch from '../../../../utilities/customerHelper';
import * as ph from '../../../../utilities/personHelper';
import * as sys from '../../../../utilities/systemHelper';
import * as ah from '../../../../utilities/addressHelper';

import './StoreCreditTransfer.scss';

function StoreCreditTransfer(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const focusTimer = useRef(null);
    const storeCredit = useContext(CustomerStoreCreditTransferStore);
    const quickDrawer = useContext(QuickDrawerStore);

    useEffect(() => {
        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100)

        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (storeCredit.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleTransferToCustomerChange = (relatedParty) => {
        storeCredit.data.toCustomerId = relatedParty && relatedParty.id ? relatedParty.id : null;
        storeCredit.hasUnsavedChanges = true;
    }

    const handleAmountChange = event => {
        const amount = event.target.value ? fn.parseCurrency(event.target.value) : null;
        storeCredit.data.amount = amount;
        storeCredit.hasUnsavedChanges = true;
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            storeCredit.save()
                .then(() => {
                    if (isMounted.current) {
                        if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                            props.onSuccess(event, { updated: true });
                        }
                    }
                })
        }
    }

    const renderCustomer = () => {
        return <div
            className='profile-wrapper'
        >
            <div className='profile'>
                <span
                    className={`profile-image profile-initials rounded-circle d-flex text-white ${ch.getProfileColor(storeCredit.customer)} fw-500`}
                    title={ph.getFullName(storeCredit.customer)}
                >
                    {storeCredit.customer.initials}
                </span>
            </div>
            <div className='description flex-1'>
                <Link to={`${rts.Customers.Home}/${storeCredit.customer.id}`} className='name text-gray-700' onClick={quickDrawer.deactivateAll}>{ph.getFullName(storeCredit.customer, true)}
                    {
                        storeCredit.customer.dateOfBirth || storeCredit.customer.sex || storeCredit.customer.gender || storeCredit.customer.pronoun ?
                            <small className='text-nowrap ml-2'>({`${ph.getAge(storeCredit.customer.dateOfBirth)} ${ph.getSexGenderPronounDisplay(storeCredit.customer)}`.trim()})</small> : null
                    }
                </Link>
                {
                    storeCredit.customer.address && storeCredit.customer.address.country ?
                        <div className='info'>{ah.getAddressHtml(storeCredit.customer.address)}</div> : null
                }
            </div>
        </div>
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <fieldset disabled={storeCredit.isSaving}>
                <div className='customer-store-credit-container quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={'fal fa-box-usd'}
                        action='Transfer Customer'
                        category='Store Credit'
                        className='customers'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        {
                            storeCredit.isReady && !!storeCredit.customer ?
                                <FadeIn>
                                    <div className='body-content'>
                                        <section className='customer'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0'>
                                                        <label><small>Transfer from</small></label>
                                                        {renderCustomer()}
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='divider py-2 mb-5' />
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group'>
                                                        <label htmlFor='update-personal-primary-contact-person-input validate validate-required' className='required'><small>Transfer to</small></label>
                                                        <DropdownList
                                                            data={(
                                                                storeCredit.relationships ?
                                                                    [{}, ...storeCredit.relationships.map(r => {
                                                                        r.relatedTo.relationship = r.relationship;
                                                                        return r.relatedTo;
                                                                    })] : []
                                                            )}
                                                            disabled={!ch.checkCanUpdate(storeCredit.customer)}
                                                            valueField='id'
                                                            value={storeCredit.data.toCustomerId}
                                                            itemComponent={({ item }) => (
                                                                item && item.id ?
                                                                    <div
                                                                        className='profile-wrapper pb-0'
                                                                    >
                                                                        <div className='profile d-flex align-items-center'>
                                                                            <span
                                                                                className={`profile-image profile-image-md profile-initials rounded-circle d-flex ${ch.getProfileColor(item)} fw-500`}
                                                                                title={item.fullName}
                                                                            >
                                                                                {item.initials}
                                                                            </span>
                                                                        </div>
                                                                        <span className='description'>
                                                                            <span>{item.fullName}{(item.relationship ? <span className='ml-1'>(<span className='fw-500 fs-90 text-success-700'>{item.relationship}</span>)</span> : <></>)}</span>
                                                                            {
                                                                                item.emailAddress ? <span className='fs-90 text-info'>{item.emailAddress}</span> : null
                                                                            }
                                                                            {
                                                                                item.phoneNumber ? <span className='fs-90 text-info'>{sys.getFormattedPhoneNumber(item.phoneNumber)}</span> : null
                                                                            }
                                                                        </span>
                                                                    </div> : <div className='profile-wrapper pb-0'>
                                                                        <span className='text-gray-700'></span>
                                                                    </div>
                                                            )}
                                                            valueComponent={({ item }) => (
                                                                item ?
                                                                    <div
                                                                        className='profile-wrapper pb-0'
                                                                    >
                                                                        <div className='profile d-flex align-items-center'>
                                                                            <span
                                                                                className={`profile-image profile-image-md profile-initials rounded-circle d-flex ${ch.getProfileColor(item)} fw-500`}
                                                                                title={item.fullName}
                                                                            >
                                                                                {item.initials}
                                                                            </span>
                                                                        </div>
                                                                        <span className='description'>
                                                                            <span>{item.fullName}{(item.relationship ? <span className='ml-1'>(<span className='fw-500 fs-90 text-success-700'>{item.relationship}</span>)</span> : <></>)}</span>
                                                                            {
                                                                                item.emailAddress ? <span className='fs-90 text-info'>{item.emailAddress}</span> : null
                                                                            }
                                                                            {
                                                                                item.phoneNumber ? <span className='fs-90 text-info'>{sys.getFormattedPhoneNumber(item.phoneNumber)}</span> : null
                                                                            }
                                                                        </span>
                                                                    </div> : null
                                                            )}
                                                            onChange={handleTransferToCustomerChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-7'>
                                                    <label htmlFor='transfer-amount validate validate-required' className='required'><small>Amount</small></label>
                                                    <MaskedInput
                                                        id='transfer-amount'
                                                        className='form-control currency-input'
                                                        mask={MaskKeys.CURRENCY_POSITIVE_MASK}
                                                        autoComplete='off'
                                                        value={storeCredit.data.amount ? storeCredit.data.amount : ''}
                                                        onChange={handleAmountChange}
                                                    />
                                                </div>
                                                <div className='col-5'>
                                                    <label htmlFor='transfer-amount-by'><small>&nbsp;</small></label>
                                                    <select
                                                        id='transfer-amount-by'
                                                        className='custom-select form-control'
                                                        value={'Store Credit'}
                                                        disabled={true}
                                                    >
                                                        <option value='Store Credit'>Store Credit</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </FadeIn> : renderQuickDrawerLoading()
                        }
                    </div>
                    <div className='quick-drawer-action pl-3'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                        disabled={!ch.checkCanUpdate(storeCredit.customer)}
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>)
}

export default StoreCreditTransfer;