import React, { useContext, useEffect, useState, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { Observer } from 'mobx-react-lite';
import { GlobalHotKeys } from 'react-hotkeys';

import BodyEnd from '../../_shared/BodyEnd';
import ConfirmModal from '../../_shared/ConfirmModal';
import { quickDrawerFocus } from '../../_shared/QuickDrawer';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import HtmlEditor from '../../_shared/HtmlEditor';

import PurchaseUpdateStore from '../../../../stores/PurchaseUpdateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as oh from '../../../../utilities/operationHelper';

import './UncollectiblePurchase.scss';

function UncollectiblePurchase(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const purchase = useContext(PurchaseUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [confirmUncollectible, setConfirmUncollectible] = useState(false);

    useEffect(() => {
        quickDrawerFocus(props.drawer);
        return () => {
            isMounted.current = false;
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (purchase.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleReasonChange = event => {
        const html = event.value;
        purchase.uncollectibleReason = (html ? fn.stripHtml(html) : null);
        purchase.uncollectibleReasonHtml = ((html === '<p><br></p>') ? null : html);
        purchase.hasUnsavedChanges = true;
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            setConfirmUncollectible(true);
        }
    }

    const handleConfirmUncollectible = event => {
        purchase.uncollectiblePurchase(true)
            .then(() => {
                if (isMounted.current) {
                    setConfirmUncollectible(false);
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, purchase.data);
                    }
                }
            })
    }

    const handleConfirmCancel = event => {
        setConfirmUncollectible(false);
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) && !confirmUncollectible ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <Observer>{() =>
                <fieldset disabled={purchase.isSaving}>
                    <div className='void-purchase-container quick-drawer'>
                        <QuickDrawerHeader
                            drawer={props.drawer}
                            icon={oh.getIcon('purchase', 'no-charge')}
                            action='Purchase'
                            category='Uncollectible'
                            className=''
                            onCancel={props.onCancel}
                        />
                        <div className='quick-drawer-body'>
                            <FadeIn>
                                <div className='body-content'>
                                    <Observer>{() =>
                                        <>
                                            <section>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='form-group validate validate-required'>
                                                            <label className='required'><small>Uncollectible Reason</small></label>
                                                            <div className='input-group'>
                                                                <Observer>{() =>
                                                                    <HtmlEditor
                                                                        disableTab={true}
                                                                        onValueChanged={handleReasonChange}
                                                                    />
                                                                }</Observer>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </>
                                    }</Observer>
                                </div>
                            </FadeIn>
                        </div>
                        <div className='quick-drawer-action'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='float-right'>
                                        <button
                                            type='button'
                                            className='btn btn-link btn-cancel mr-2'
                                            onClick={props.onCancel}
                                        >Cancel</button>
                                        <Observer>{() =>
                                            <button
                                                type='submit'
                                                className='btn btn-info'
                                            >Continue</button>
                                        }</Observer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            }</Observer>
        </form>
        <Observer>{() =>
            <BodyEnd>
                <ConfirmModal
                    icon={<i className={'text-danger mr-2 ' + (oh.getIcon('purchase', 'no-charge'))}></i>}
                    message={<>Continue to <span className='fw-500'>&nbsp;write-off invoice # {purchase.data ? purchase.data.invoiceNumber : ''}</span>? </>}
                    descriptionClassName='warning-danger warning-flashing'
                    show={confirmUncollectible}
                    onOption1Click={handleConfirmUncollectible}
                    onCancel={handleConfirmCancel}
                />
            </BodyEnd>
        }</Observer>
    </>
}

export default UncollectiblePurchase;