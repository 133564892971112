import React, { useEffect, useContext, useState, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { GlobalHotKeys } from 'react-hotkeys';
import { toJS } from 'mobx';
import { useObserver } from 'mobx-react-lite';
import { Mention, MentionsInput } from 'react-mentions';

import QuickDrawerHeader from '../../../../_shared/QuickDrawerHeader';
import { quickDrawerFocus } from '../../../../_shared/QuickDrawer';

import ExamControlViewStore from '../../../../../../stores/ExamControlViewStore';
import QuickDrawerStore from '../../../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../../../constants/errorMessages';
import * as fn from '../../../../../../utilities/_functions';
import * as oh from '../../../../../../utilities/operationHelper';
import * as tch from '../../../../../../utilities/templateControlHelper';
import * as mh from '../../../../../../utilities/mentionHelper';

import './UpdateExistingExamControl.scss';
import api from '../../../../../../api';

function UpdateExistingExamControl(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const examControl = useContext(ExamControlViewStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [size, setSize] = useState('');
    const [align, setAlign] = useState('');
    const [valign, setValign] = useState('');
    const [equation, setEquation] = useState('');
    const [timestampDestination, setTimestampDestination] = useState('');
    const [pretestTemplates, setPretestTemplates] = useState(null);
    const [pretestTypes, setPretestTypes] = useState(null);
    const [pretestOptionType, setPretestOptionType] = useState('');
    const [pretestOptionIsMultiple, setPretestOptionIsMultiple] = useState(false);
    const [pretestOptionScaleToFit, setPretestOptionScaleToFit] = useState(false);
    const [prescriptionTypes, setPrescriptionTypes] = useState(null);
    const [prescriptionTemplates, setPrescriptionTemplates] = useState(null);
    const [prescriptionCreateType, setPrescriptionCreateType] = useState('');
    const [prescriptionCreateMapping, setPrescriptionCreateMapping] = useState([]);
    const [overwrite, setOverwrite] = useState([]);
    const [copyFieldsMapping, setCopyFieldsMapping] = useState([]);
    const [prescriptionListType, setPrescriptionListType] = useState('');
    const [prescriptionListMode, setPrescriptionListMode] = useState('all');
    const [prescriptionSummaryMode, setPrescriptionSummaryMode] = useState('all');
    const [prescriptionSummaryMultiple, setPrescriptionSummaryMultiple] = useState(true);
    const [options, setOptions] = useState([]);
    const [inline, setInline] = useState(false);
    const [isLinkButton, setIsLinkButton] = useState(false);
    const [inputOptions, setInputOptions] = useState([]);
    const [industryDevices, setIndustryDevices] = useState([]);
    const [importIndustryDeviceId, setImportIndustryDeviceId] = useState(null);
    const [importIndustryDeviceMapping, setImportIndustryDeviceMapping] = useState([]);
    const [exportIndustryDeviceId, setIndustryExportDeviceId] = useState(null);
    const [exportIndustryDeviceSection, setExportIndustryDeviceSection] = useState(null);
    const [exportIndustryDeviceMapping, setExportIndustryDeviceMapping] = useState([]);

    useEffect(() => {
        if (props.extraProps.inputOptions && props.extraProps.inputOptions.length > 0) {
            setInputOptions(toJS(props.extraProps.inputOptions));
        }

        focusTimer.current = setTimeout(() => {
            setSize(examControl.getMetadata('size'));
            setAlign(examControl.getMetadata('align'));
            setValign(examControl.getMetadata('valign'));
            setOptions(examControl.getMetadata('options'));
            setInline(examControl.getMetadata('inline'));
            setIsLinkButton(examControl.getMetadata('link'));
            setEquation(examControl.getMetadata('equation'));
            setTimestampDestination(examControl.getMetadata('timestamp'));

            if (tch.hasField(examControl.data.type, tch.TEMPLATE_CONTROL_METADATA_PRETEST_OPTIONS)) {
                const pretestOption = examControl.getMetadata('pretest');

                if (pretestOption) {
                    setPretestOptionType(pretestOption.type);
                    setPretestOptionIsMultiple(pretestOption.isMultiple);
                    setPretestOptionScaleToFit(pretestOption.scaleToFit);
                }

                loadPretestData();
            }

            if (tch.hasField(examControl.data.type, tch.TEMPLATE_CONTROL_METADATA_OVERWRITE)) {
                setOverwrite(examControl.getMetadata('overwrite'));
            }

            if (tch.hasField(examControl.data.type, tch.TEMPLATE_CONTROL_METADATA_COPY_FIELDS_OPTIONS)) {
                const copyFieldsOption = examControl.getMetadata('copyFields');

                if (copyFieldsOption) {
                    setCopyFieldsMapping(copyFieldsOption.mapping);
                }

                loadPrescriptionData();
            }

            if (tch.hasField(examControl.data.type, tch.TEMPLATE_CONTROL_METADATA_PRESCRIPTION_CREATE_OPTIONS)) {
                const prescriptionCreateOption = examControl.getMetadata('prescriptionCreate');

                if (prescriptionCreateOption) {
                    setPrescriptionCreateType(prescriptionCreateOption.type);
                    setPrescriptionCreateMapping(prescriptionCreateOption.mapping);
                }

                loadPrescriptionData();
            }

            if (tch.hasField(examControl.data.type, tch.TEMPLATE_CONTROL_METADATA_PRESCRIPTION_LIST_OPTIONS)) {
                const prescriptionListOption = examControl.getMetadata('prescriptionList');

                if (prescriptionListOption) {
                    setPrescriptionListType(prescriptionListOption.type);
                    setPrescriptionListMode(prescriptionListOption.mode ? prescriptionListOption.mode : 'all');
                }

                loadPrescriptionData();
            }

            if (tch.hasField(examControl.data.type, tch.TEMPLATE_CONTROL_METADATA_PRESCRIPTION_SUMMARY_OPTIONS)) {
                const prescriptionSummaryOption = examControl.getMetadata('prescriptionSummary');

                if (prescriptionSummaryOption) {
                    setPrescriptionSummaryMode(prescriptionSummaryOption.mode ? prescriptionSummaryOption.mode : 'all');
                    setPrescriptionSummaryMultiple(!fn.isNullOrUndefined(prescriptionSummaryOption.multiple) ? prescriptionSummaryOption.multiple : true);
                }

                loadPrescriptionData();
            }

            if (tch.hasField(examControl.data.type, tch.TEMPLATE_CONTROL_METADATA_IMPORT_FROM_DEVICE_OPTIONS) ||
                tch.hasField(examControl.data.type, tch.TEMPLATE_CONTROL_METADATA_EXPORT_TO_DEVICE_OPTIONS)) {

                if (tch.hasField(examControl.data.type, tch.TEMPLATE_CONTROL_METADATA_IMPORT_FROM_DEVICE_OPTIONS)) {
                    const importOption = examControl.getMetadata('import');

                    if (importOption) {
                        setImportIndustryDeviceId(importOption.industryDeviceId ? importOption.industryDeviceId : null);
                        setImportIndustryDeviceMapping(importOption.mapping ? importOption.mapping : []);
                    }
                }

                if (tch.hasField(examControl.data.type, tch.TEMPLATE_CONTROL_METADATA_EXPORT_TO_DEVICE_OPTIONS)) {
                    const exportOption = examControl.getMetadata('export');

                    if (exportOption) {
                        setIndustryExportDeviceId(exportOption.industryDeviceId ? exportOption.industryDeviceId : null);
                        setExportIndustryDeviceSection(exportOption.section ? exportOption.section : null);
                        setExportIndustryDeviceMapping(exportOption.mapping ? exportOption.mapping : []);
                    }
                }

                loadPretestData();
                loadIndustryDevices();
            }

            quickDrawerFocus(props.drawer);
        }, 100)

        return (() => {
            isMounted.current = false;
            examControl.clear();
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        })
    }, []) // eslint-disable-line

    const loadIndustryDevices = () => {
        api.IndustryDevices.all()
            .then(({ data }) => {
                if (isMounted.current) {
                    if (data && data.length > 0) {
                        if (isMounted.current && data) {
                            const industryDevicesData = data.sort((a, b) => a.displayName.localeCompare(b.displayName));
                            setIndustryDevices(industryDevicesData);
                        }
                    }
                }
            })
    }

    const loadPretestData = () => {
        api.PretestPublishedTemplates.all()
            .then(({ data }) => {
                if (isMounted.current && data) {
                    const sortedPretestTypes = data.map(d => { return d.pretestType }).sort((a, b) => a.displayOrder - b.displayOrder);
                    setPretestTemplates(data);
                    setPretestTypes(sortedPretestTypes);
                }
            })
    }

    const loadPrescriptionData = () => {
        api.PrescriptionPublishedTemplates.all()
            .then(({ data }) => {
                if (isMounted.current && data) {
                    const sortedPrescriptionTypes = data.map(d => { return d.prescriptionType }).sort((a, b) => a.displayOrder - b.displayOrder);
                    setPrescriptionTemplates(data);
                    setPrescriptionTypes(sortedPrescriptionTypes);
                }
            })
    }

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (examControl.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = event => {
        event.preventDefault();
        handlePopulateMetadata();
        examControl.save()
            .then(data => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, data);
                    }
                }
            })
    }

    const handleLabelChange = event => {
        examControl.data.label = event.target.value;
        examControl.hasUnsavedChanges = true;
    }

    const handleTextChange = event => {
        examControl.data.text = event.target.value;
        examControl.hasUnsavedChanges = true;
    }

    const handleSizeChange = event => {
        setSize(event.target.value);
        examControl.hasUnsavedChanges = true;
    }

    const handleAlignChange = value => {
        setAlign((value ? value : 'left'));
        examControl.hasUnsavedChanges = true;
    }

    const handleValignChange = value => {
        setValign((value ? value : 'top'));
        examControl.hasUnsavedChanges = true;
    }

    const handleInlineChange = event => {
        setInline((event.target.value === 'true'));
        examControl.hasUnsavedChanges = true;
    }

    const handleLinkButtonChange = event => {
        setIsLinkButton((event.target.value === 'true'));
        examControl.hasUnsavedChanges = true;
    }

    const handleEquationChange = (event, newValue) => {
        setEquation(newValue);
        examControl.hasUnsavedChanges = true;
    }

    const handleTimestampChange = (event, newValue) => {
        setTimestampDestination(newValue);
        examControl.hasUnsavedChanges = true;
    }

    const handlePretestOptionTypeChange = (event) => {
        setPretestOptionType(event.target.value);
        examControl.hasUnsavedChanges = true;
    }

    const handlePretestOptionIsMultipleChange = event => {
        setPretestOptionIsMultiple((event.target.value === 'true'));
        examControl.hasUnsavedChanges = true;
    }

    const handlePretestOptionScaleToFitChange = event => {
        setPretestOptionScaleToFit((event.target.value === 'true'));
        examControl.hasUnsavedChanges = true;
    }

    const handlePrescriptionCreateTypeChange = (event) => {
        const id = event.target.value;
        setPrescriptionCreateType(id);
        examControl.hasUnsavedChanges = true;
    }

    const handlePrescriptionCreateMappingSourceChange = (event, index) => {
        const tempPrescriptionCreateMapping = [...prescriptionCreateMapping];

        tempPrescriptionCreateMapping[index].source = event.target.value;
        setPrescriptionCreateMapping(tempPrescriptionCreateMapping);
        examControl.hasUnsavedChanges = true;
    }

    const handlePrescriptionCreateMappingDestinationChange = (event, index) => {
        const tempPrescriptionCreateMapping = [...prescriptionCreateMapping];

        tempPrescriptionCreateMapping[index].destination = event.target.value;
        setPrescriptionCreateMapping(tempPrescriptionCreateMapping);
        examControl.hasUnsavedChanges = true;
    }

    const handlePrescriptionListTypeChange = (event) => {
        const id = event.target.value;
        setPrescriptionListType(id);
        examControl.hasUnsavedChanges = true;
    }

    const handlePrescriptionListModeChange = (event, mode) => {
        setPrescriptionListMode(mode);
        examControl.hasUnsavedChanges = true;
    }

    const handlePrescriptionSummaryModeChange = (event, mode) => {
        setPrescriptionSummaryMode(mode);
        examControl.hasUnsavedChanges = true;
    }

    const handlePrescriptionSummaryMultipleChange = (event, multiple) => {
        setPrescriptionSummaryMultiple(multiple);
        examControl.hasUnsavedChanges = true;
    }

    const handleOverwriteChange = event => {
        setOverwrite((event.target.value === 'true'));
        examControl.hasUnsavedChanges = true;
    }

    const handleCopyFieldMappingSourceChange = (event, index) => {
        const tempCopyFieldsMapping = [...copyFieldsMapping];

        tempCopyFieldsMapping[index].source = event.target.value;
        setCopyFieldsMapping(tempCopyFieldsMapping);
        examControl.hasUnsavedChanges = true;
    }

    const handleCopyFieldMappingDestinationChange = (event, index) => {
        const tempCopyFieldMapping = [...copyFieldsMapping];

        tempCopyFieldMapping[index].destination = event.target.value;
        setCopyFieldsMapping(tempCopyFieldMapping);
        examControl.hasUnsavedChanges = true;
    }

    const handleAddCopyFieldMapping = event => {
        event.preventDefault();
        setCopyFieldsMapping(p => [...copyFieldsMapping, { source: '', destination: '' }]);
        focusTimer.current = setTimeout(() => {
            const element = document.querySelector(`#new-exam-control-copy-field-source-${(copyFieldsMapping.length)}`)
            if (element) { element.focus(); }
        }, 50);
        examControl.hasUnsavedChanges = true;
    }

    const handleRemoveCopyFieldMapping = (mapping, index) => {
        setCopyFieldsMapping(copyFieldsMapping.filter((o, oi) => oi !== index));
        examControl.hasUnsavedChanges = true;
    }

    const handleImportIndustryDeviceChange = (event) => {
        const industryDeviceId = event.target.value;
        setImportIndustryDeviceId(industryDeviceId);
        examControl.hasUnsavedChanges = true;
    }

    const handleImportIndustryDeviceMappingSourceChange = (event, index) => {
        const tempImportIndustryDeviceMapping = [...importIndustryDeviceMapping];

        tempImportIndustryDeviceMapping[index].source = event.target.value;
        setImportIndustryDeviceMapping(tempImportIndustryDeviceMapping);
        examControl.hasUnsavedChanges = true;
    }

    const handleImportIndustryDeviceMappingDestinationChange = (event, index) => {
        const tempImportIndustryDeviceMapping = [...importIndustryDeviceMapping];

        tempImportIndustryDeviceMapping[index].destination = event.target.value;
        setImportIndustryDeviceMapping(tempImportIndustryDeviceMapping);
        examControl.hasUnsavedChanges = true;
    }

    const handleAddImportIndustryDeviceMapping = event => {
        event.preventDefault();
        setImportIndustryDeviceMapping(p => [...importIndustryDeviceMapping, { source: '', destination: '' }]);
        focusTimer.current = setTimeout(() => {
            const element = document.querySelector(`#new-exam-control-import-device-mapping-source-${(importIndustryDeviceMapping.length)}`)
            if (element) { element.focus(); }
        }, 50);
        examControl.hasUnsavedChanges = true;
    }

    const handleRemoveImportIndustryDeviceMapping = (mapping, index) => {
        setImportIndustryDeviceMapping(importIndustryDeviceMapping.filter((o, oi) => oi !== index));
        examControl.hasUnsavedChanges = true;
    }

    const handleExportDeviceChange = (event) => {
        const industryDeviceId = event.target.value;
        setIndustryExportDeviceId(industryDeviceId);
        examControl.hasUnsavedChanges = true;
    }

    const handleExportDeviceSectionChange = (event) => {
        const deviceSection = event.target.value;
        setExportIndustryDeviceSection(deviceSection);
        examControl.hasUnsavedChanges = true;
    }

    const handleExportDeviceMappingSourceChange = (event, index) => {
        const tempExportDeviceMapping = [...exportIndustryDeviceMapping];

        tempExportDeviceMapping[index].source = event.target.value;
        setExportIndustryDeviceMapping(tempExportDeviceMapping);
        examControl.hasUnsavedChanges = true;
    }

    const handleExportDeviceMappingDestinationChange = (event, index) => {
        const tempExportDeviceMapping = [...exportIndustryDeviceMapping];

        tempExportDeviceMapping[index].destination = event.target.value;
        setExportIndustryDeviceMapping(tempExportDeviceMapping);
        examControl.hasUnsavedChanges = true;
    }

    const handleAddExportDeviceMapping = event => {
        event.preventDefault();
        setExportIndustryDeviceMapping(p => [...exportIndustryDeviceMapping, { source: '', destination: '' }]);
        focusTimer.current = setTimeout(() => {
            const element = document.querySelector(`#new-exam-control-export-device-mapping-source-${(exportIndustryDeviceMapping.length)}`)
            if (element) { element.focus(); }
        }, 50);
        examControl.hasUnsavedChanges = true;
    }

    const handleRemoveExportDeviceMapping = (mapping, index) => {
        setExportIndustryDeviceMapping(exportIndustryDeviceMapping.filter((o, oi) => oi !== index));
        examControl.hasUnsavedChanges = true;
    }

    const handlePopulateMetadata = () => {
        populateMetadata(tch.TEMPLATE_CONTROL_METADATA_SIZE, 'size', (size ? size.trim() : null));
        populateMetadata(tch.TEMPLATE_CONTROL_METADATA_ALIGN, 'align', (align ? align.trim() : null));
        populateMetadata(tch.TEMPLATE_CONTROL_METADATA_VALIGN, 'valign', (valign ? valign.trim() : null));
        populateMetadata(tch.TEMPLATE_CONTROL_METADATA_OPTIONS, 'options', options);
        populateMetadata(tch.TEMPLATE_CONTROL_METADATA_INLINE, 'inline', inline);
        populateMetadata(tch.TEMPLATE_CONTROL_METADATA_LINK, 'link', isLinkButton);
        populateMetadata(tch.TEMPLATE_CONTROL_METADATA_EQUATION, 'equation', (equation ? equation.trim() : null));
        populateMetadata(tch.TEMPLATE_CONTROL_METADATA_TIMESTAMP_DESTINATION, 'timestamp', (timestampDestination ? timestampDestination.trim() : null));
        populateMetadata(tch.TEMPLATE_CONTROL_METADATA_PRETEST_OPTIONS, 'pretest', (
            pretestOptionType && pretestOptionIsMultiple !== null ? {
                type: pretestOptionType,
                isMultiple: pretestOptionIsMultiple,
                scaleToFit: pretestOptionScaleToFit,
            } : null
        ));
        populateMetadata(tch.TEMPLATE_CONTROL_METADATA_OVERWRITE, 'overwrite', overwrite);
        populateMetadata(tch.TEMPLATE_CONTROL_METADATA_COPY_FIELDS_OPTIONS, 'copyFields', (
            copyFieldsMapping !== null ? {
                mapping: copyFieldsMapping,
            } : null
        ));
        populateMetadata(tch.TEMPLATE_CONTROL_METADATA_PRESCRIPTION_CREATE_OPTIONS, 'prescriptionCreate', (
            prescriptionCreateType && prescriptionCreateMapping !== null ? {
                type: prescriptionCreateType,
                mapping: prescriptionCreateMapping,
            } : null
        ));
        populateMetadata(tch.TEMPLATE_CONTROL_METADATA_PRESCRIPTION_LIST_OPTIONS, 'prescriptionList', (
            prescriptionListType && prescriptionListMode ? {
                type: prescriptionListType,
                mode: prescriptionListMode,
            } : null
        ));
        populateMetadata(tch.TEMPLATE_CONTROL_METADATA_PRESCRIPTION_SUMMARY_OPTIONS, 'prescriptionSummary', (
            prescriptionSummaryMode ? {
                mode: prescriptionSummaryMode,
                multiple: !!prescriptionSummaryMultiple,
            } : null
        ));
        populateMetadata(tch.TEMPLATE_CONTROL_METADATA_IMPORT_FROM_DEVICE_OPTIONS, 'import', (
            importIndustryDeviceId && importIndustryDeviceMapping ? {
                industryDeviceId: importIndustryDeviceId,
                mapping: importIndustryDeviceMapping,
            } : null
        ));
        populateMetadata(tch.TEMPLATE_CONTROL_METADATA_EXPORT_TO_DEVICE_OPTIONS, 'export', (
            exportIndustryDeviceId && exportIndustryDeviceSection && exportIndustryDeviceMapping ? {
                industryDeviceId: exportIndustryDeviceId,
                section: exportIndustryDeviceSection,
                mapping: exportIndustryDeviceMapping,
            } : null
        ));
    }

    const handleAddPrescriptionCreateMapping = event => {
        event.preventDefault();
        setPrescriptionCreateMapping(p => [...prescriptionCreateMapping, { source: '', destination: '' }]);
        focusTimer.current = setTimeout(() => {
            const element = document.querySelector(`#new-exam-control-prescription-create-source-${(prescriptionCreateMapping.length)}`)
            if (element) { element.focus(); }
        }, 50);
        examControl.hasUnsavedChanges = true;
    }

    const handleRemovePrescriptionCreateMapping = (mapping, index) => {
        setPrescriptionCreateMapping(prescriptionCreateMapping.filter((o, oi) => oi !== index));
        examControl.hasUnsavedChanges = true;
    }

    const renderCopyFieldMapping = () => {
        return <>
            {
                copyFieldsMapping && copyFieldsMapping.length > 0 ?
                    copyFieldsMapping.map((f, fi) => {
                        return <ul
                            key={`exam_input_copy_field_mapping_${fi}`}
                            className='list-inline no-style m-0 mt-n2 mb-2'
                        >
                            <li className='list-inline-item m-0 option-sm'>
                                <MentionsInput
                                    singleLine
                                    id={`new-exam-control-copy-field-source-${fi}`}
                                    className='react-mentions-wrapper'
                                    autoComplete='off'
                                    value={f.source ? f.source : ''}
                                    title={mh.getDisplay(f.source)}
                                    onChange={e => { handleCopyFieldMappingSourceChange(e, fi) }}
                                >
                                    <Mention
                                        trigger='#'
                                        data={inputOptions}
                                        markup={mh.MENTION_MARKUP}
                                    />
                                </MentionsInput>
                            </li>
                            <li className='list-inline-item mx-2'>to</li>
                            <li className='list-inline-item m-0 option-sm'>
                                <MentionsInput
                                    singleLine
                                    id={`new-exam-control-copy-field-destination-${fi}`}
                                    className='react-mentions-wrapper'
                                    autoComplete='off'
                                    value={f.destination ? f.destination : ''}
                                    title={mh.getDisplay(f.destination)}
                                    onChange={e => { handleCopyFieldMappingDestinationChange(e, fi) }}
                                >
                                    <Mention
                                        trigger='#'
                                        data={inputOptions}
                                        markup={mh.MENTION_MARKUP}
                                    />
                                </MentionsInput>
                            </li>
                            <li className='list-inline-item m-0 ml-2'>
                                <button
                                    type='button'
                                    className='btn btn-link btn-icon btn-sm'
                                    autoComplete='off'
                                    onClick={e => { handleRemoveCopyFieldMapping(f, fi) }}
                                >
                                    <i className={'fal fa-minus-circle text-danger'}></i>
                                </button>
                            </li>
                        </ul>
                    }) : <div></div>
            }
            <button
                type='button'
                className='btn btn-link btn-icon-left btn-sm'
                onClick={handleAddCopyFieldMapping}
            >
                <i className='fal fa-plus-circle'></i> Add mapping
            </button>
        </>
    }

    const renderPrescriptionCreateMapping = () => {
        return <>
            {
                prescriptionCreateMapping && prescriptionCreateMapping.length > 0 ?
                    prescriptionCreateMapping.map((o, oi) => {
                        return <ul
                            key={`exam_input_prescription_mapping_${oi}`}
                            className='list-inline no-style m-0 mt-n2 mb-2'
                        >
                            <li className='list-inline-item m-0 option-sm'>
                                <MentionsInput
                                    singleLine
                                    id={`new-exam-control-prescription-create-source-${oi}`}
                                    className='react-mentions-wrapper'
                                    autoComplete='off'
                                    value={o.source ? o.source : ''}
                                    title={mh.getDisplay(o.source)}
                                    onChange={e => { handlePrescriptionCreateMappingSourceChange(e, oi) }}
                                >
                                    <Mention
                                        trigger='#'
                                        data={inputOptions}
                                        markup={mh.MENTION_MARKUP}
                                    />
                                </MentionsInput>
                            </li>
                            <li className='list-inline-item mx-2'>to</li>
                            <li className='list-inline-item m-0 option-sm'>
                                <MentionsInput
                                    singleLine
                                    id={`new-exam-control-prescription-create-destination-${oi}`}
                                    className='react-mentions-wrapper'
                                    autoComplete='off'
                                    value={o.destination ? o.destination : ''}
                                    title={mh.getDisplay(o.destination)}
                                    onChange={e => { handlePrescriptionCreateMappingDestinationChange(e, oi) }}
                                >
                                    <Mention
                                        trigger='#'
                                        data={(prescriptionTemplates && prescriptionTemplates.length > 0 && prescriptionCreateType ?
                                            prescriptionTemplates.filter(t => t.prescriptionTypeId === prescriptionCreateType)[0].definition.definition
                                                .filter(d => d.key && d.name)
                                                .map(d => {
                                                    return {
                                                        id: d.key,
                                                        display: d.name
                                                    }
                                                }) : null)}
                                        markup={mh.MENTION_MARKUP}
                                    />
                                </MentionsInput>
                            </li>
                            <li className='list-inline-item m-0 ml-2'>
                                <button
                                    type='button'
                                    className='btn btn-link btn-icon btn-sm'
                                    autoComplete='off'
                                    onClick={e => { handleRemovePrescriptionCreateMapping(o, oi) }}
                                >
                                    <i className={'fal fa-minus-circle text-danger'}></i>
                                </button>
                            </li>
                        </ul>
                    }) : <div></div>
            }
            <button
                type='button'
                className='btn btn-link btn-icon-left btn-sm'
                onClick={handleAddPrescriptionCreateMapping}
            >
                <i className='fal fa-plus-circle'></i> Add mapping
            </button>
        </>
    }

    const handleOptionChange = (event, index) => {
        const tempOptions = [...options];
        tempOptions[index] = event.target.value;
        setOptions(tempOptions);
        examControl.hasUnsavedChanges = true;
    }

    const handleAddOption = event => {
        event.preventDefault();
        setOptions(o => [...options, '']);
        focusTimer.current = setTimeout(() => {
            const element = document.querySelector(`#new-exam-control-${examControl.data.key}-${(options.length)}`)
            if (element) { element.focus(); }
        }, 50);
        examControl.hasUnsavedChanges = true;
    }

    const handleRemoveOption = (option, index) => {
        setOptions(options.filter((o, oi) => oi !== index));
        examControl.hasUnsavedChanges = true;
    }

    const handleMoveOption = (from, to) => {
        const tempOptions = [...options];
        const element = tempOptions[from];

        tempOptions.splice(from, 1);
        tempOptions.splice(to, 0, element);

        setOptions(tempOptions);
        examControl.hasUnsavedChanges = true;
    }

    const renderOptions = () => {
        return <>
            {
                options && options.length > 0 ?
                    options.map((o, oi) => {
                        return <ul
                            key={`exam_input_options_${oi}`}
                            className='list-inline no-style m-0 mb-2'
                        >
                            <li className='list-inline-item m-0 mr-1'>{oi + 1}.&nbsp;</li>
                            <li className='list-inline-item m-0 option'>
                                <input
                                    id={`update-exam-control-${examControl.data.key}-${oi}`}
                                    type='text'
                                    className='form-control form-control-sm'
                                    autoComplete='off'
                                    value={o}
                                    onChange={(e) => handleOptionChange(e, oi)}
                                />
                            </li>
                            <li className='list-inline-item m-0 ml-2'>
                                <button
                                    type='button'
                                    className='btn btn-link btn-icon btn-sm'
                                    autoComplete='off'
                                    onClick={e => { handleRemoveOption(o, oi) }}
                                >
                                    <i className={'fal fa-minus-circle text-danger'}></i>
                                </button>
                            </li>
                            <li className='list-inline-item m-0'>
                                <button
                                    type='button'
                                    className='btn btn-link btn-icon btn-sm'
                                    autoComplete='off'
                                    disabled={options.length === 1 || oi === 0}
                                    onClick={e => { handleMoveOption(oi, (oi - 1)) }}
                                >
                                    <i className={'fal fa-arrow-up' + (options.length === 1 || oi === 0 ? ' text-gray-500' : ' text-primary')}></i>
                                </button>
                            </li>
                            <li className='list-inline-item m-0'>
                                <button
                                    type='button'
                                    className='btn btn-link btn-icon btn-sm'
                                    disabled={options.length === 1 || oi === (options.length - 1)}
                                    onClick={e => { handleMoveOption(oi, (oi + 1)) }}
                                >
                                    <i className={'fal fa-arrow-down' + (options.length === 1 || oi === (options.length - 1) ? ' text-gray-500' : ' text-primary')}></i>
                                </button>
                            </li>
                        </ul>
                    }) : <div></div>
            }
            <button
                type='button'
                className='btn btn-link btn-icon-left btn-sm'
                onClick={handleAddOption}
            >
                <i className='fal fa-plus-circle'></i> Add option
            </button>
        </>
    }

    const renderImportIndustryDeviceMapping = () => {
        const industryDevice = getIndustryDevice(importIndustryDeviceId);

        return <>
            {
                importIndustryDeviceMapping && importIndustryDeviceMapping.length > 0 ?
                    importIndustryDeviceMapping.map((d, di) => {
                        return <ul
                            key={`exam_input_import_device_mapping_${di}`}
                            className='list-inline no-style m-0 mt-n2 mb-2'
                        >
                            <li className='list-inline-item m-0 option-sm'>
                                <select
                                    id={`new-exam-control-import-device-mapping-source-${di}`}
                                    className='custom-select form-control'
                                    value={d.source ? d.source : ''}
                                    onChange={e => { handleImportIndustryDeviceMappingSourceChange(e, di) }}
                                >
                                    <option value=''></option>
                                    {
                                        industryDevice && industryDevice.deviceToPcFixedWidthMappings.length > 0 ?
                                            industryDevice.deviceToPcFixedWidthMappings.map((m, mi) => {
                                                return <option
                                                    key={`import_device_mapping_destination_${mi}`}
                                                    value={m.id}
                                                >{`${m.name} (${m.id})`}</option>
                                            }) : null
                                    }
                                </select>
                            </li>
                            <li className='list-inline-item mx-2'>to</li>
                            <li className='list-inline-item m-0 option-sm'>
                                <MentionsInput
                                    singleLine
                                    id={`new-exam-control-import-device-mapping-destination-${di}`}
                                    className='react-mentions-wrapper'
                                    autoComplete='off'
                                    value={d.destination ? d.destination : ''}
                                    title={mh.getDisplay(d.destination)}
                                    onChange={e => { handleImportIndustryDeviceMappingDestinationChange(e, di) }}
                                >
                                    <Mention
                                        trigger='#'
                                        data={inputOptions}
                                        markup={mh.MENTION_MARKUP}
                                    />
                                </MentionsInput>
                            </li>
                            <li className='list-inline-item m-0 ml-2'>
                                <button
                                    type='button'
                                    className='btn btn-link btn-icon btn-sm'
                                    autoComplete='off'
                                    onClick={e => { handleRemoveImportIndustryDeviceMapping(d, di) }}
                                >
                                    <i className={'fal fa-minus-circle text-danger'}></i>
                                </button>
                            </li>
                        </ul>
                    }) : <div></div>
            }
            <button
                type='button'
                className='btn btn-link btn-icon-left btn-sm'
                onClick={handleAddImportIndustryDeviceMapping}
            >
                <i className='fal fa-plus-circle'></i> Add mapping
            </button>
        </>
    }

    const renderExportDeviceSection = () => {
        const device = getIndustryDevice(exportIndustryDeviceId);

        return <>
            {
                device ? <select
                    id={`new-exam-control-export-device-section`}
                    className='custom-select form-control'
                    value={exportIndustryDeviceSection ? exportIndustryDeviceSection : ''}
                    onChange={handleExportDeviceSectionChange}
                >
                    <option value=''></option>
                    {
                        device && device.pcToDeviceFixedWidthMappingSections.length > 0 ?
                            device.pcToDeviceFixedWidthMappingSections.map((s, si) => {
                                return <option
                                    key={`export_device_section_${si}`}
                                    value={s.key}
                                >{s.key}</option>
                            }) : null
                    }
                </select> : null
            }
        </>
    }

    const renderExportDeviceMapping = () => {
        if (industryDevices && industryDevices.length > 0 && pretestTemplates && pretestTemplates.length > 0) {
            const device = getIndustryDevice(exportIndustryDeviceId);
            const deviceMappingOptions = exportIndustryDeviceSection ? device.pcToDeviceFixedWidthMappingSections.filter(s => s.key === exportIndustryDeviceSection)[0].value.filter(v => v.id && v.name) : [];
            const pretestInputOptions = pretestTemplates.map(pt => { return pt.definition.definition.filter(d => d.key && d.name).map(d => { return { id: d.key, display: d.name } }) }).flat();
            const exportInputOptions = [...inputOptions, ...pretestInputOptions];

            return <>
                {
                    device && exportIndustryDeviceMapping && exportIndustryDeviceMapping.length > 0 ?
                        exportIndustryDeviceMapping.map((d, di) => {
                            return <ul
                                key={`exam_input_export_device_mapping_${di}`}
                                className='list-inline no-style m-0 mt-n2 mb-2'
                            >
                                <li className='list-inline-item m-0 option-sm'>
                                    <MentionsInput
                                        singleLine
                                        id={`new-exam-control-export-device-mapping-source-${di}`}
                                        className='react-mentions-wrapper'
                                        autoComplete='off'
                                        value={d.source ? d.source : ''}
                                        title={mh.getDisplay(d.source)}
                                        onChange={e => { handleExportDeviceMappingSourceChange(e, di) }}
                                    >
                                        <Mention
                                            trigger='#'
                                            data={exportInputOptions}
                                            markup={mh.MENTION_MARKUP}
                                        />
                                    </MentionsInput>
                                </li>
                                <li className='list-inline-item mx-2'>to</li>
                                <li className='list-inline-item m-0 option-sm'>
                                    <select
                                        id={`new-exam-control-export-device-mapping-destination-${di}`}
                                        className='custom-select form-control'
                                        value={d.destination ? d.destination : ''}
                                        onChange={e => { handleExportDeviceMappingDestinationChange(e, di) }}
                                    >
                                        <option value=''></option>
                                        {
                                            deviceMappingOptions && deviceMappingOptions.length > 0 ?
                                                deviceMappingOptions.map((m, mi) => {
                                                    return <option
                                                        key={`export_device_mapping_destination_${mi}`}
                                                        value={m.id}
                                                    >{`${m.name} (${m.id})`}</option>
                                                }) : null
                                        }
                                    </select>
                                </li>
                                <li className='list-inline-item m-0 ml-2'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-icon btn-sm'
                                        autoComplete='off'
                                        onClick={e => { handleRemoveExportDeviceMapping(d, di) }}
                                    >
                                        <i className={'fal fa-minus-circle text-danger'}></i>
                                    </button>
                                </li>
                            </ul>
                        }) : <div></div>
                }
                <button
                    type='button'
                    className='btn btn-link btn-icon-left btn-sm'
                    onClick={handleAddExportDeviceMapping}
                >
                    <i className='fal fa-plus-circle'></i> Add mapping
                </button>
            </>
        }

        return <></>;
    }

    const populateMetadata = (field, key, value) => {
        if (tch.hasField(examControl.data.type, field)) {
            examControl.setMetadata(key, value);
        } else {
            examControl.removeMetadata(key);
        }
    }

    const getIndustryDevice = (industryDeviceId) => {
        return industryDevices && industryDevices.length > 0 ? industryDevices.filter(d => d.id === industryDeviceId)[0] : null;
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form onSubmit={handleSubmit}>
            <fieldset disabled={examControl.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('exam-input', 'control')}
                        action='Update'
                        category={tch.getName(examControl.data.type)}
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        <FadeIn>
                            <div className='update-exam-control body-content'>
                                <section>
                                    {
                                        tch.hasField(examControl.data.type, tch.TEMPLATE_CONTROL_LABEL) ?
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label htmlFor='new-exam-control-label'><small>Label</small></label>
                                                        <input
                                                            id='new-exam-control-label'
                                                            type='text'
                                                            maxLength={100}
                                                            className='form-control'
                                                            value={examControl.data.label ? examControl.data.label : ''}
                                                            autoComplete={'off'}
                                                            onChange={handleLabelChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        tch.hasField(examControl.data.type, tch.TEMPLATE_CONTROL_TEXT) ?
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label htmlFor='new-exam-control-text'><small>Text</small></label>
                                                        <input
                                                            id='new-exam-control-text'
                                                            type='text'
                                                            maxLength={100}
                                                            className='form-control'
                                                            value={examControl.data.text ? examControl.data.text : ''}
                                                            autoComplete={'off'}
                                                            onChange={handleTextChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        tch.hasField(examControl.data.type, tch.TEMPLATE_CONTROL_METADATA_LINK) ?
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label><small>Link Button</small></label>
                                                        <div>
                                                            <div className='custom-control custom-radio custom-control-link mb-2'>
                                                                <input
                                                                    id={`new-exam-control-link-true`}
                                                                    type='radio'
                                                                    name='exam-control-link'
                                                                    className='custom-control-input'
                                                                    value={true}
                                                                    checked={isLinkButton}
                                                                    onChange={handleLinkButtonChange}
                                                                />
                                                                <label
                                                                    htmlFor={`new-exam-control-link-true`}
                                                                    className='custom-control-label'
                                                                >
                                                                    Yes
                                                                </label>
                                                            </div>
                                                            <div className='custom-control custom-radio custom-control-link'>
                                                                <input
                                                                    id={`new-exam-control-link-false`}
                                                                    type='radio'
                                                                    name='exam-control-link'
                                                                    className='custom-control-input'
                                                                    value={false}
                                                                    checked={!isLinkButton}
                                                                    onChange={handleLinkButtonChange}
                                                                />
                                                                <label
                                                                    htmlFor={`new-exam-control-link-false`}
                                                                    className='custom-control-label'
                                                                >
                                                                    No
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        tch.hasField(examControl.data.type, tch.TEMPLATE_CONTROL_METADATA_SIZE) ?
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label className='required' htmlFor='new-exam-control-size'><small>Size</small></label>
                                                        <select
                                                            id='new-exam-control-size'
                                                            className='custom-select form-control'
                                                            value={size ? size : ''}
                                                            onChange={handleSizeChange}
                                                        >
                                                            <option value='sm'>Small</option>
                                                            <option value=''>Standard</option>
                                                            <option value='lg'>Large</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        tch.hasField(examControl.data.type, tch.TEMPLATE_CONTROL_METADATA_ALIGN) ?
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label className='required' htmlFor='new-exam-control-align'><small>Align</small></label>
                                                        <div>
                                                            <div className='btn-group'>
                                                                <button
                                                                    type='button'
                                                                    className={'btn btn-secondary' + (align === 'left' ? ' active' : '')}
                                                                    value={(align ? align : '')}
                                                                    onClick={() => { handleAlignChange('left') }}
                                                                >
                                                                    <i className='fal fa-align-left'></i>
                                                                </button>
                                                                <button
                                                                    type='button'
                                                                    className={'btn btn-secondary' + (align === 'center' ? ' active' : '')}
                                                                    value={(align ? align : '')}
                                                                    onClick={() => { handleAlignChange('center') }}
                                                                >
                                                                    <i className='fal fa-align-center'></i>
                                                                </button>
                                                                <button
                                                                    type='button'
                                                                    className={'btn btn-secondary' + (align === 'right' ? ' active' : '')}
                                                                    value={(align ? align : '')}
                                                                    onClick={() => { handleAlignChange('right') }}
                                                                >
                                                                    <i className='fal fa-align-right'></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        tch.hasField(examControl.data.type, tch.TEMPLATE_CONTROL_METADATA_VALIGN) ?
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label className='required' htmlFor='new-exam-control-valign'><small>Vertical Align</small></label>
                                                        <div>
                                                            <div className='btn-group'>
                                                                <button
                                                                    type='button'
                                                                    className={'btn btn-secondary' + (valign === 'top' ? ' active' : '')}
                                                                    value={(valign ? valign : '')}
                                                                    onClick={() => { handleValignChange('top') }}
                                                                >
                                                                    <i className='fal fa-arrow-to-top'></i>
                                                                </button>
                                                                <button
                                                                    type='button'
                                                                    className={'btn btn-secondary' + (valign === 'middle' ? ' active' : '')}
                                                                    value={(valign ? valign : '')}
                                                                    onClick={() => { handleValignChange('middle') }}
                                                                >
                                                                    <i className='fal fa-horizontal-rule'></i>
                                                                </button>
                                                                <button
                                                                    type='button'
                                                                    className={'btn btn-secondary' + (valign === 'bottom' ? ' active' : '')}
                                                                    value={(valign ? valign : '')}
                                                                    onClick={() => { handleValignChange('bottom') }}
                                                                >
                                                                    <i className='fal fa-arrow-to-bottom'></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        tch.hasField(examControl.data.type, tch.TEMPLATE_CONTROL_METADATA_INLINE) ?
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label><small>Inline</small></label>
                                                        <div>
                                                            <div className='custom-control custom-radio custom-control-inline'>
                                                                <input
                                                                    id={`new-exam-control-inline-true`}
                                                                    type='radio'
                                                                    name='exam-control-inline'
                                                                    className='custom-control-input'
                                                                    value={true}
                                                                    checked={inline}
                                                                    onChange={handleInlineChange}
                                                                />
                                                                <label
                                                                    htmlFor={`new-exam-control-inline-true`}
                                                                    className='custom-control-label'
                                                                >
                                                                    Yes
                                                                </label>
                                                            </div>
                                                            <div className='custom-control custom-radio custom-control-inline'>
                                                                <input
                                                                    id={`new-exam-control-inline-false`}
                                                                    type='radio'
                                                                    name='exam-control-inline'
                                                                    className='custom-control-input'
                                                                    value={false}
                                                                    checked={!inline}
                                                                    onChange={handleInlineChange}
                                                                />
                                                                <label
                                                                    htmlFor={`new-exam-control-inline-false`}
                                                                    className='custom-control-label'
                                                                >
                                                                    No
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        tch.hasField(examControl.data.type, tch.TEMPLATE_CONTROL_METADATA_OPTIONS) ?
                                            <div className='row options'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label className='required' htmlFor='new-exam-control-options'><small>Options</small></label>
                                                        {renderOptions()}
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        tch.hasField(examControl.data.type, tch.TEMPLATE_CONTROL_METADATA_EQUATION) ?
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label className='required' htmlFor='new-exam-control-equation'><small>Equation</small></label>
                                                        <MentionsInput
                                                            singleLine
                                                            className='react-mentions-wrapper'
                                                            value={equation ? equation : ''}
                                                            onChange={handleEquationChange}
                                                        >
                                                            <Mention
                                                                trigger='#'
                                                                data={inputOptions}
                                                                markup={mh.MENTION_MARKUP}
                                                            />
                                                        </MentionsInput>
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        tch.hasField(examControl.data.type, tch.TEMPLATE_CONTROL_METADATA_TIMESTAMP_DESTINATION) ?
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label className='required' htmlFor='new-exam-control-timestamp'><small>Map to</small></label>
                                                        <MentionsInput
                                                            singleLine
                                                            className='react-mentions-wrapper'
                                                            value={timestampDestination ? timestampDestination : ''}
                                                            onChange={handleTimestampChange}
                                                        >
                                                            <Mention
                                                                trigger='#'
                                                                data={inputOptions}
                                                                markup={mh.MENTION_MARKUP}
                                                            />
                                                        </MentionsInput>
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        tch.hasField(examControl.data.type, tch.TEMPLATE_CONTROL_METADATA_PRETEST_OPTIONS) ?
                                            <>
                                                {
                                                    pretestTypes && pretestTypes.length > 0 ?
                                                        <FadeIn>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='form-group mb-4'>
                                                                        <label className='required' htmlFor='new-exam-pretest-type'><small>Type</small></label>
                                                                        <select
                                                                            id='new-exam-pretest-type'
                                                                            className='custom-select form-control'
                                                                            value={pretestOptionType ? pretestOptionType : ''}
                                                                            onChange={handlePretestOptionTypeChange}
                                                                        >
                                                                            <option value=''></option>
                                                                            {
                                                                                pretestTypes ?
                                                                                    pretestTypes.map((t, ti) => {
                                                                                        return <option
                                                                                            key={`new_exam_pretest_type_${ti}`}
                                                                                            value={t.id}
                                                                                        >{t.name}</option>
                                                                                    }) : null
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='form-group mb-4'>
                                                                        <label className='required' htmlFor='new-exam-pretest-multi'><small>Allow Multiple Import</small></label>
                                                                        <div>
                                                                            <div className='custom-control custom-radio custom-control-inline'>
                                                                                <input
                                                                                    id={`new-exam-pretest-multi-true`}
                                                                                    type='radio'
                                                                                    name='new-exam-pretest-multi'
                                                                                    className='custom-control-input'
                                                                                    value={true}
                                                                                    checked={pretestOptionIsMultiple}
                                                                                    onChange={handlePretestOptionIsMultipleChange}
                                                                                />
                                                                                <label
                                                                                    htmlFor={`new-exam-pretest-multi-true`}
                                                                                    className='custom-control-label'
                                                                                >
                                                                                    Yes
                                                                                </label>
                                                                            </div>
                                                                            <div className='custom-control custom-radio custom-control-inline'>
                                                                                <input
                                                                                    id={`new-exam-pretest-multi-false`}
                                                                                    type='radio'
                                                                                    name='new-exam-pretest-multi'
                                                                                    className='custom-control-input'
                                                                                    value={false}
                                                                                    checked={!pretestOptionIsMultiple}
                                                                                    onChange={handlePretestOptionIsMultipleChange}
                                                                                />
                                                                                <label
                                                                                    htmlFor={`new-exam-pretest-multi-false`}
                                                                                    className='custom-control-label'
                                                                                >
                                                                                    No
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='form-group mb-4'>
                                                                        <label className='required' htmlFor='new-exam-pretest-scale'><small>Scale To Fit</small></label>
                                                                        <div>
                                                                            <div className='custom-control custom-radio custom-control-inline'>
                                                                                <input
                                                                                    id={`new-exam-pretest-scale-true`}
                                                                                    type='radio'
                                                                                    name='new-exam-pretest-scale'
                                                                                    className='custom-control-input'
                                                                                    value={true}
                                                                                    checked={pretestOptionScaleToFit}
                                                                                    onChange={handlePretestOptionScaleToFitChange}
                                                                                />
                                                                                <label
                                                                                    htmlFor={`new-exam-pretest-scale-true`}
                                                                                    className='custom-control-label'
                                                                                >
                                                                                    Yes
                                                                                </label>
                                                                            </div>
                                                                            <div className='custom-control custom-radio custom-control-inline'>
                                                                                <input
                                                                                    id={`new-exam-pretest-scale-false`}
                                                                                    type='radio'
                                                                                    name='new-exam-pretest-scale'
                                                                                    className='custom-control-input'
                                                                                    value={false}
                                                                                    checked={!pretestOptionScaleToFit}
                                                                                    onChange={handlePretestOptionScaleToFitChange}
                                                                                />
                                                                                <label
                                                                                    htmlFor={`new-exam-pretest-scale-false`}
                                                                                    className='custom-control-label'
                                                                                >
                                                                                    No
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </FadeIn> : null
                                                }
                                            </> : null
                                    }
                                    {
                                        tch.hasField(examControl.data.type, tch.TEMPLATE_CONTROL_METADATA_OVERWRITE) ?
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label><small>Overwrite existing data</small></label>
                                                        <div>
                                                            <div className='custom-control custom-radio custom-control-link mb-2'>
                                                                <input
                                                                    id={`new-exam-control-overwrite-true`}
                                                                    type='radio'
                                                                    name='exam-control-overwrite'
                                                                    className='custom-control-input'
                                                                    value={true}
                                                                    checked={(fn.isNullOrUndefined(overwrite) || overwrite)}
                                                                    onChange={handleOverwriteChange}
                                                                />
                                                                <label
                                                                    htmlFor={`new-exam-control-overwrite-true`}
                                                                    className='custom-control-label'
                                                                >
                                                                    Yes
                                                                </label>
                                                            </div>
                                                            <div className='custom-control custom-radio custom-control-link'>
                                                                <input
                                                                    id={`new-exam-control-overwrite-false`}
                                                                    type='radio'
                                                                    name='exam-control-overwrite'
                                                                    className='custom-control-input'
                                                                    value={false}
                                                                    checked={overwrite === false}
                                                                    onChange={handleOverwriteChange}
                                                                />
                                                                <label
                                                                    htmlFor={`new-exam-control-overwrite-false`}
                                                                    className='custom-control-label'
                                                                >
                                                                    No
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        tch.hasField(examControl.data.type, tch.TEMPLATE_CONTROL_METADATA_COPY_FIELDS_OPTIONS) ?
                                            <>
                                                <div className='row options'>
                                                    <div className='col-12'>
                                                        <div className='form-group mb-4'>
                                                            <label className='required' htmlFor='new-exam-control-options'><small>Field mappings</small></label>
                                                            {renderCopyFieldMapping()}
                                                        </div>
                                                    </div>
                                                </div>
                                            </> : null
                                    }
                                    {
                                        tch.hasField(examControl.data.type, tch.TEMPLATE_CONTROL_METADATA_PRESCRIPTION_CREATE_OPTIONS) ?
                                            <>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='form-group mb-4'>
                                                            <label className='required' htmlFor='new-exam-prescription-create-type'><small>Type</small></label>
                                                            <select
                                                                id='new-exam-prescription-create-type'
                                                                className='custom-select form-control'
                                                                value={prescriptionCreateType ? prescriptionCreateType : ''}
                                                                onChange={handlePrescriptionCreateTypeChange}
                                                            >
                                                                <option value=''></option>
                                                                {
                                                                    prescriptionTypes ?
                                                                        prescriptionTypes.map((t, ti) => {
                                                                            return <option
                                                                                key={`new_exam_prescription_create_type_${ti}`}
                                                                                value={t.id}
                                                                            >{t.name}</option>
                                                                        }) : null
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    prescriptionCreateType ?
                                                        <FadeIn>
                                                            <div className='row options'>
                                                                <div className='col-12'>
                                                                    <div className='form-group mb-4'>
                                                                        <label className='required' htmlFor='new-exam-control-options'><small>{prescriptionCreateType && prescriptionTypes && prescriptionTypes.length > 0 ? `${prescriptionTypes.filter(t => t.id === prescriptionCreateType)[0].name} prescription` : 'Prescription'} mapping</small></label>
                                                                        {renderPrescriptionCreateMapping()}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </FadeIn> : null
                                                }
                                            </> : null
                                    }
                                    {
                                        tch.hasField(examControl.data.type, tch.TEMPLATE_CONTROL_METADATA_PRESCRIPTION_LIST_OPTIONS) ?
                                            <>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='form-group mb-4'>
                                                            <label className='required' htmlFor='new-exam-prescription-list-type'><small>Type</small></label>
                                                            <select
                                                                id='new-exam-prescription-create-list'
                                                                className='custom-select form-control'
                                                                value={prescriptionListType ? prescriptionListType : ''}
                                                                onChange={handlePrescriptionListTypeChange}
                                                            >
                                                                <option value=''></option>
                                                                {
                                                                    prescriptionTypes ?
                                                                        prescriptionTypes.map((t, ti) => {
                                                                            return <option
                                                                                key={`new_exam_prescription_list_type_${ti}`}
                                                                                value={t.id}
                                                                            >{t.name}</option>
                                                                        }) : null
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='form-group mb-2'>
                                                            <label className='required'><small>Show</small></label>
                                                            <div className='custom-control custom-radio mb-2'>
                                                                <input
                                                                    id='new-exam-prescription-list-mode-all'
                                                                    type='radio'
                                                                    name='new-exam-prescription-list-mode'
                                                                    className='custom-control-input'
                                                                    checked={prescriptionListMode === 'all'}
                                                                    onChange={(e) => { handlePrescriptionListModeChange(e, 'all') }}
                                                                />
                                                                <label
                                                                    htmlFor='new-exam-prescription-list-mode-all'
                                                                    className='custom-control-label'
                                                                >
                                                                    All prescription(s)
                                                                </label>
                                                            </div>
                                                            <div className='custom-control custom-radio mb-2'>
                                                                <input
                                                                    id='new-exam-prescription-list-mode-previous'
                                                                    type='radio'
                                                                    name='new-exam-prescription-list-mode'
                                                                    className='custom-control-input'
                                                                    checked={prescriptionListMode === 'previous'}
                                                                    onChange={(e) => { handlePrescriptionListModeChange(e, 'previous') }}
                                                                />
                                                                <label
                                                                    htmlFor='new-exam-prescription-list-mode-previous'
                                                                    className='custom-control-label'
                                                                >
                                                                    Previous prescription(s)
                                                                </label>
                                                            </div>
                                                            <div className='custom-control custom-radio mb-2'>
                                                                <input
                                                                    id='new-exam-prescription-list-mode-exam'
                                                                    type='radio'
                                                                    name='new-exam-prescription-list-mode'
                                                                    className='custom-control-input'
                                                                    checked={prescriptionListMode === 'exam'}
                                                                    onChange={(e) => { handlePrescriptionListModeChange(e, 'exam') }}
                                                                />
                                                                <label
                                                                    htmlFor='new-exam-prescription-list-mode-exam'
                                                                    className='custom-control-label'
                                                                >
                                                                    Exam prescription(s)
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </> : null
                                    }
                                    {
                                        tch.hasField(examControl.data.type, tch.TEMPLATE_CONTROL_METADATA_PRESCRIPTION_SUMMARY_OPTIONS) ?
                                            <>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='form-group mb-2'>
                                                            <label className='required'><small>Show</small></label>
                                                            <div className='custom-control custom-radio mb-2'>
                                                                <input
                                                                    id='new-exam-prescription-summary-mode-all'
                                                                    type='radio'
                                                                    name='new-exam-prescription-summary-mode'
                                                                    className='custom-control-input'
                                                                    checked={prescriptionSummaryMode === 'all'}
                                                                    onChange={(e) => { handlePrescriptionSummaryModeChange(e, 'all') }}
                                                                />
                                                                <label
                                                                    htmlFor='new-exam-prescription-summary-mode-all'
                                                                    className='custom-control-label'
                                                                >
                                                                    All prescription(s)
                                                                </label>
                                                            </div>
                                                            <div className='custom-control custom-radio mb-2'>
                                                                <input
                                                                    id='new-exam-prescription-summary-mode-previous'
                                                                    type='radio'
                                                                    name='new-exam-prescription-summary-mode'
                                                                    className='custom-control-input'
                                                                    checked={prescriptionSummaryMode === 'previous'}
                                                                    onChange={(e) => { handlePrescriptionSummaryModeChange(e, 'previous') }}
                                                                />
                                                                <label
                                                                    htmlFor='new-exam-prescription-summary-mode-previous'
                                                                    className='custom-control-label'
                                                                >
                                                                    Previous prescription(s)
                                                                </label>
                                                            </div>
                                                            <div className='custom-control custom-radio mb-2'>
                                                                <input
                                                                    id='new-exam-prescription-summary-mode-exam'
                                                                    type='radio'
                                                                    name='new-exam-prescription-summary-mode'
                                                                    className='custom-control-input'
                                                                    checked={prescriptionSummaryMode === 'exam'}
                                                                    onChange={(e) => { handlePrescriptionSummaryModeChange(e, 'exam') }}
                                                                />
                                                                <label
                                                                    htmlFor='new-exam-prescription-summary-mode-exam'
                                                                    className='custom-control-label'
                                                                >
                                                                    Exam prescription(s)
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='form-group mb-2'>
                                                            <label className='required'><small>Collapsible</small></label>
                                                            <div className='custom-control custom-radio mb-2'>
                                                                <input
                                                                    id='new-exam-prescription-summary-collapsible-multiple'
                                                                    type='radio'
                                                                    name='new-exam-prescription-summary-collapsible'
                                                                    className='custom-control-input'
                                                                    checked={prescriptionSummaryMultiple === true}
                                                                    onChange={(e) => { handlePrescriptionSummaryMultipleChange(e, true) }}
                                                                />
                                                                <label
                                                                    htmlFor='new-exam-prescription-summary-collapsible-multiple'
                                                                    className='custom-control-label'
                                                                >
                                                                    Allow multiple prescription type(s) open
                                                                </label>
                                                            </div>
                                                            <div className='custom-control custom-radio mb-2'>
                                                                <input
                                                                    id='new-exam-prescription-summary-collapsible-single'
                                                                    type='radio'
                                                                    name='new-exam-prescription-summary-collapsible'
                                                                    className='custom-control-input'
                                                                    checked={prescriptionSummaryMultiple === false}
                                                                    onChange={(e) => { handlePrescriptionSummaryMultipleChange(e, false) }}
                                                                />
                                                                <label
                                                                    htmlFor='new-exam-prescription-summary-collapsible-single'
                                                                    className='custom-control-label'
                                                                >
                                                                    Only one prescription type can open at a time
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </> : null
                                    }
                                    {
                                        tch.hasField(examControl.data.type, tch.TEMPLATE_CONTROL_METADATA_IMPORT_FROM_DEVICE_OPTIONS) ?
                                            <>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='form-group mb-4'>
                                                            <label className='required'><small>Import from device</small></label>
                                                            <select
                                                                id='new-exam-control-import-devices'
                                                                className='custom-select form-control'
                                                                value={importIndustryDeviceId ? importIndustryDeviceId : ''}
                                                                onChange={handleImportIndustryDeviceChange}
                                                            >
                                                                <option></option>
                                                                {
                                                                    industryDevices && industryDevices.length > 0 ?
                                                                        industryDevices.map((d, di) => {
                                                                            return <option
                                                                                key={`import-device-${di}`}
                                                                                value={d.id}
                                                                            >
                                                                                {d.displayName}
                                                                            </option>
                                                                        }) : null
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    importIndustryDeviceId ?
                                                        <div className='row options'>
                                                            <div className='col-12'>
                                                                <div className='form-group mb-4'>
                                                                    <label className='required' htmlFor='new-exam-control-import-device-mapping'><small>Mapping</small></label>
                                                                    {renderImportIndustryDeviceMapping()}
                                                                </div>
                                                            </div>
                                                        </div> : null
                                                }
                                            </> : null
                                    }
                                    {
                                        tch.hasField(examControl.data.type, tch.TEMPLATE_CONTROL_METADATA_EXPORT_TO_DEVICE_OPTIONS) ?
                                            <>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='form-group mb-4'>
                                                            <label className='required'><small>Export to device</small></label>
                                                            <select
                                                                id='new-exam-control-export-devices'
                                                                className='custom-select form-control'
                                                                value={exportIndustryDeviceId ? exportIndustryDeviceId : ''}
                                                                onChange={handleExportDeviceChange}
                                                            >
                                                                <option></option>
                                                                {
                                                                    industryDevices && industryDevices.length > 0 ?
                                                                        industryDevices.map((d, di) => {
                                                                            return <option
                                                                                key={`export-device-${di}`}
                                                                                value={d.id}
                                                                            >
                                                                                {d.displayName}
                                                                            </option>
                                                                        }) : null
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    exportIndustryDeviceId ?
                                                        <div className='row options'>
                                                            <div className='col-12'>
                                                                <div className='form-group mb-4'>
                                                                    <label className='required' htmlFor='new-exam-control-export-device-section'><small>Map to</small></label>
                                                                    {renderExportDeviceSection()}
                                                                </div>
                                                            </div>
                                                        </div> : null
                                                }
                                                {
                                                    exportIndustryDeviceSection && exportIndustryDeviceId ?
                                                        <div className='row options'>
                                                            <div className='col-12'>
                                                                <div className='form-group mb-4'>
                                                                    <label className='required' htmlFor='new-exam-control-export-device-mapping'><small>Mapping</small></label>
                                                                    {renderExportDeviceMapping()}
                                                                </div>
                                                            </div>
                                                        </div> : null
                                                }
                                            </> : null
                                    }
                                </section>
                            </div>
                        </FadeIn>
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>)
}

export default UpdateExistingExamControl;