import { createContext } from 'react';
import { decorate, observable, action, computed } from 'mobx';
import moment, { isMoment } from 'moment';
import { ALL_USERS } from '../constants/storageKeys';

import api from '../api';
import * as fn from '../utilities/_functions';

export class ScheduleCreate {
    date = null;
    start = null;
    end = null;
    effectiveType = 'next4Weeks';
    userId = null;
    frequency = 'once';
    acceptBooking = true;
    isTimeOff = false;
    isLocked = false;
    daysOfWeek = [];
    breaks = [];
    overwriteWorkDayIds = [];
    overwriteTimeOffIds = [];
    workingHolidays = [];
    note = null;
    noteHtml = null;
    isExisting = false;
    isReady = false;
    isSaving = false;
    isLoading = false;
    hasUnsavedChanges = false;
    cancelSchedule = null;
    cancelScheduleUpdate = null;
    cancelScheduleConflict = null;

    initialize = (userId, date, startTime, endTime) => {
        const that = this;

        this.clear();
        this.userId = userId;
        this.date = isMoment(date) ? date.startOf('day') : moment(date).startOf('day');
        this.start = moment(`${this.date.format('YYYY-MM-DD')} ${startTime}`, 'YYYY-MM-DD h:mm a');
        this.end = moment(`${this.date.format('YYYY-MM-DD')} ${endTime}`, 'YYYY-MM-DD h:mm a');
        this.daysOfWeek.push(this.date.format('dddd'));

        return new Promise((resolve, reject) => {
            that.refresh()
                .then(() => {
                    resolve();
                })
                .catch(error => {
                    reject(error);
                })
                .finally(() => {
                    that.isReady = true;
                })
        })
    }

    refresh = () => {
        const that = this;
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            // clear certain fields, otherwise the previous user data will leak into the current one
            this.breaks = [];
            this.note = null;
            this.noteHtml = null;
            this.acceptBooking = true;

            api.Schedules.get(that.date.format('YYYY-MM-DD'), that.date.format('YYYY-MM-DD'), that.userId, (c) => { that.cancelSchedule = c })
                .then(({ data }) => {
                    if (data && data.length > 0) {
                        that.start = moment(data[0].start);
                        that.end = moment(data[0].end);
                        that.note = data[0].note;
                        that.noteHtml = data[0].noteHtml;
                        that.acceptBooking = data[0].acceptBooking;
                        that.isTimeOff = data[0].isTimeOff;
                        that.isLocked = data[0].isLocked;
                        that.isExisting = true;

                        if (data[0].breaks && data[0].breaks.length > 0) {
                            that.breaks = data[0].breaks.map(b => {
                                return {
                                    title: b.title,
                                    start: moment(b.start),
                                    end: moment(b.end),
                                }
                            })
                        }
                        else {
                            that.breaks = [];
                        }
                    }
                    else {
                        that.isExisting = false;
                    }
                    resolve();
                })
                .catch(error => {
                    reject(error);
                })
                .finally(() => {
                    that.isLoading = false;
                })
        })
    }

    conflict = () => {
        const that = this;

        return new Promise((resolve, reject) => {
            const option = that.getSaveOption();
            
            if (that.cancelScheduleConflict != null && fn.isFunction(that.cancelScheduleConflict)) {
                that.cancelScheduleConflict();
                that.cancelScheduleConflict = null;
            }
            
            api.Schedules.conflict(option, (c) => { that.cancelScheduleConflict = c })
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(error => {
                    reject(error);
                })
        })
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            // if something changed or if this is a brand new entry (hasUnsavedChanges would be false)
            if (that.hasUnsavedChanges || !that.isExisting) {
                if (!that.cancelScheduleUpdate) {
                    const option = that.getSaveOption();
                    api.Schedules.save(option, (c) => { that.cancelScheduleUpdate = c })
                        .then(() => {
                            that.hasUnsavedChanges = false;
                            resolve();
                        })
                        .catch(error => {
                            reject(error);
                        })
                        .finally(() => {
                            that.cancelScheduleUpdate = null;
                            that.isSaving = false;
                        })
                }
                else {
                    resolve();
                }
            } else {
                that.hasUnsavedChanges = false;
                that.isSaving = false;
                resolve();
            }
        })
    }

    clear = () => {
        this.date = null;
        this.start = null;
        this.end = null;
        this.effectiveType = 'next4Weeks';
        this.userId = null;
        this.frequency = 'once';
        this.acceptBooking = true;
        this.isTimeOff = false;
        this.isLocked = false;
        this.daysOfWeek = [];
        this.breaks = [];
        this.overwriteWorkDayIds = [];
        this.overwriteTimeOffIds = [];
        this.workingHolidays = [];
        this.note = null;
        this.noteHtml = null;
        this.isExisting = false;
        this.isReady = false;
        this.isSaving = false;
        this.isLoading = false;
        this.hasUnsavedChanges = false;

        if (fn.isFunction(this.cancelSchedule)) {
            this.cancelSchedule();
            this.cancelSchedule = null;
        }

        if (fn.isFunction(this.cancelScheduleUpdate)) {
            this.cancelScheduleUpdate();
            this.cancelScheduleUpdate = null;
        }

        if (fn.isFunction(this.cancelScheduleConflict)) {
            this.cancelScheduleConflict();
            this.cancelScheduleConflict = null;
        }
    }

    getSaveOption = () => {
        const users = JSON.parse(window.localStorage.getItem(ALL_USERS));
        const selectedUser = users.find(u => u.id === this.userId);

        return {
            userId: this.userId,
            acceptBooking: this.acceptBooking,
            isTimeOff: this.isTimeOff,
            isLocked: this.isLocked,
            repeatUnit: this.repeatUnit,
            repeatFrequency: this.repeatFrequency,
            date: this.date.clone().startOf('day'),
            startTime: moment(this.start, 'h:mm a').format('HH:mm'),
            endTime: moment(this.end, 'h:mm a').format('HH:mm'),
            effectiveUntil: this.effectiveUntil,
            daysOfWeek: this.daysOfWeek,
            note: this.note,
            noteHtml: this.noteHtml,
            breaks: this.breaks.map(b => {
                return {
                    title: b.title,
                    startTime: b.start.format('HH:mm'),
                    endTime: b.end.format('HH:mm'),
                }
            }),
            overwriteWorkdayIds: this.overwriteWorkDayIds,
            overwriteTimeOffIds: this.overwriteTimeOffIds,
            workingHolidays: this.workingHolidays,
        };
    }

    get repeatUnit() {
        switch (this.frequency) {
            case 'once':
                return null;

            case 'every':
                return 1;

            case 'everyOther':
                return 2;

            case 'every3Weeks':
                return 3;

            case 'every4Weeks':
                return 4;

            default:
                return null;
        }
    }

    get repeatFrequency() {
        switch (this.frequency) {
            case 'once':
                return null;

            case 'every':
            case 'everyOther':
            case 'every3Weeks':
            case 'every4Weeks':
                return 'Weekly';

            default:
                return null;
        }
    }

    get effectiveUntil() {
        switch (this.effectiveType) {
            case 'next4Weeks':
                return this.date.clone().add(4, 'weeks');

            case 'next8Weeks':
                return this.date.clone().add(8, 'weeks');

            case 'endOfThisMonth':
                return this.date.clone().startOf('month').add(1, 'months').add(-1, 'days');

            case 'endOfNextMonth':
                return this.date.clone().startOf('month').add(2, 'months').add(-1, 'days');

            case 'endOfYear':
                return this.date.clone().startOf('year').add(1, 'years').add(-1, 'days');

            case 'forever':
            default:
                return null;
        }
    }

    get isExpired() {
        if (this.end) {
            return this.end.clone().startOf('day').add(1, 'days').add(-1, 'seconds').isBefore(moment())
        }
        return true;
    }
}

decorate(ScheduleCreate, {
    date: observable,
    start: observable,
    end: observable,
    repeatUnit: computed,
    repeatFrequency: computed,
    effectiveUntil: computed,
    effectiveType: observable,
    isExpired: computed,
    userId: observable,
    frequency: observable,
    acceptBooking: observable,
    isTimeOff: observable,
    isLocked: observable,
    daysOfWeek: observable.deep,
    breaks: observable.deep,
    overwriteWorkDayIds: observable,
    overwriteTimeOffIds: observable,
    workingHolidays: observable,
    note: observable,
    noteHtml: observable,
    isExisting: observable,
    isReady: observable,
    isSaving: observable,
    isLoading: observable,
    hasUnsavedChanges: observable,
    initialize: action,
    refresh: action,
    save: action,
    clear: action,
})

export default createContext(new ScheduleCreate());