import React, { useEffect, useContext, useRef, useState } from 'react';
import FadeIn from 'react-fade-in';
import { Observer } from 'mobx-react-lite';
import List from 'devextreme-react/list';
import { GlobalHotKeys } from 'react-hotkeys';
import TimeAgo from 'react-timeago';
import moment from 'moment';

import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';

import { getNewWorkOrderItem } from '../../../../stores/WorkOrderCreateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as oh from '../../../../utilities/operationHelper';
import * as bh from '../../../../utilities/badgeHelper';
import * as sys from '../../../../utilities/systemHelper';

import './AddWorkOrderItem.scss';

function AddWorkOrderItem(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const quickDrawer = useContext(QuickDrawerStore);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [availableWorkItems, setAvailableWorkItems] = useState([]);
    const [selectedWorkItems, setSelectedWorkItems] = useState([]);

    useEffect(() => {
        if (isMounted.current) {
            setAvailableWorkItems(getAvailableWorkOrderItems());
        }
        return () => {
            isMounted.current = false;
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleToggleWorkItem = (event, item) => {
        const tempSelectedWorkItems = [...selectedWorkItems];
        const index = selectedWorkItems.findIndex(woi => woi.transactionItemId === item.transactionItemId);

        if (index >= 0) {
            tempSelectedWorkItems.splice(index, 1);
        } else {
            tempSelectedWorkItems.push(item);
        }

        if (isMounted.current) {
            setSelectedWorkItems(tempSelectedWorkItems);
            setHasUnsavedChanges(true);
        }
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (props.onSuccess && fn.isFunction(props.onSuccess)) {
            const data = {
                id: props.extraProps.id,
                items: selectedWorkItems,
            }
            props.onSuccess(event, { updated: true, data: data });
        }
    }

    const renderPurchaseDateTime = purchase => {
        const start = moment.utc(purchase.createdDateUtc).local();

        return moment().startOf('day').isSame(start.clone().startOf('day')) ?
            <TimeAgo
                className='text-gray-700 ml-auto'
                date={start.toDate()}
                title={sys.getFormattedLongDate(start, true)}
                minPeriod={60}
            />
            : <span
                className='text-gray-700 ml-auto'
                title={sys.getFormattedLongDate(start, true)}
            >
                {sys.getFormattedLongDate(start)}
            </span>
    }

    const renderTransactionItems = (item) => {
        return <Observer>{() => <>
            <div className='form-group border-top mt-3 mb-0 pt-3'>
                <div className='d-flex'>
                    <div className='d-flex flex-column align-items-center justify-content-center'>
                        <div className='form-group'>
                            <div className='custom-control custom-checkbox'>
                                <input
                                    id={`selected-work-order-item-${item.id}`}
                                    type='checkbox'
                                    name={`selected-work-order-item-${item.id}`}
                                    className='custom-control-input'
                                    checked={selectedWorkItems.some(s => s.id === item.id)}
                                    onChange={(e) => { handleToggleWorkItem(e, item) }}
                                />
                                <label htmlFor={`selected-work-order-item-${item.id}`} className={'custom-control-label'}></label>
                            </div>
                        </div>
                    </div>
                    <div
                        className='product-info cursor-pointer ml-2'
                        onClick={(e) => { handleToggleWorkItem(e, item) }}
                    >
                        <ul className='title'>
                            <li className='quantity'>
                                <h4>
                                    <strong>{item.quantity}<small>x</small></strong>
                                </h4>
                            </li>
                            <li className='detail has-quantity'>
                                <span className='text-gray-700'>{item.referenceType ? item.referenceType : 'Custom'}</span>
                                <h4 className='m-0 text-truncate text-truncate-xxl'><strong>{item.descriptor}</strong></h4>
                                <small className='brand'>
                                    <strong className='mr-1'>{item.product && item.product.brandName ? item.product.brandName : 'Custom'}</strong>
                                    <>({item.product && item.product.supplierName ? item.product.supplierName : 'Custom'})</>
                                </small>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>}</Observer>
    }

    const getAvailableWorkOrderItems = () => {
        if (props && props.extraProps) {
            const { purchase, workOrders, templates } = props.extraProps;
            if (purchase && purchase.items && purchase.items.length > 0) {
                const transactionItems = purchase.items.filter(pi => !workOrders || !workOrders.some(wo => wo.items.some(woi => woi.transactionItemId === pi.id || pi.ids.some(pid => (woi.transactionItemIds && woi.transactionItemIds.some(wid => pid === wid))))));
                const workOrderItems = transactionItems.map(ti => { return getNewWorkOrderItem(ti) });

                for (let x = 0; x < workOrderItems.length; x++) {
                    const template = templates && templates.length > 0 ? templates.filter(t => t.productTypes.some(pt => !workOrderItems[x].product || (pt.id === workOrderItems[x].product.typeId)))[0] : null;
                    workOrderItems[x].workOrderPublishedTemplateId = template ? template.id : (templates && templates.length > 0 ? templates[0].id : null);
                }

                return workOrderItems;
            }
        }

        return [];
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <fieldset>
                <div className='add-order-item-container quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('work-order', 'default')}
                        action='Add'
                        category='Work Order Item(s)'
                        className='orders'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        <Observer>{() =>
                            <>
                                {
                                    props.extraProps && availableWorkItems && availableWorkItems.length > 0 ?
                                        <FadeIn>
                                            <div className='body-content'>
                                                {
                                                    props.extraProps && props.extraProps.purchase ?
                                                        <>
                                                            <section>
                                                                <div className='row'>
                                                                    <div className='col-12'>
                                                                        <div
                                                                            className='d-flex flex-column align-items-center'
                                                                        >
                                                                            <div className='d-flex flex-row w-100 pt-1'>
                                                                                <div className='mb-0 flex-1 text-dark'>
                                                                                    <div className='d-flex mb-h'>
                                                                                        Invoice # {props.extraProps.purchase.number}
                                                                                        {renderPurchaseDateTime(props.extraProps.purchase)}
                                                                                    </div>
                                                                                    <div className='d-flex mb-h'>
                                                                                        <div className='flex-1'>
                                                                                            {bh.renderPurchaseReferenceTypes(props.extraProps.purchase, 'p-1 mr-1 mb-1')}
                                                                                        </div>
                                                                                        <span className='text-gray-700 ml-auto'>{bh.renderPurchaseAmount(props.extraProps.purchase)}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </section>
                                                            {
                                                                availableWorkItems && availableWorkItems.length > 0 ?
                                                                    <section
                                                                        className='m-0'
                                                                    >
                                                                        <div className='row'>
                                                                            <div className='col-12'>
                                                                                {
                                                                                    availableWorkItems.length > 0 ?
                                                                                        <List
                                                                                            dataSource={availableWorkItems}
                                                                                            activeStateEnabled={false}
                                                                                            focusStateEnabled={false}
                                                                                            hoverStateEnabled={false}
                                                                                            itemRender={renderTransactionItems}
                                                                                        >
                                                                                        </List> : null
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </section> : null
                                                            }
                                                        </> : null
                                                }
                                            </div>
                                        </FadeIn> :
                                        <FadeIn>
                                            <div className='body-content'>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='text-muted'>No item can be added to this work order</div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </FadeIn>
                                }
                            </>
                        }</Observer>
                    </div>
                    <div className='quick-drawer-action pl-3'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <Observer>{() =>
                                        <button
                                            type='submit'
                                            className='btn btn-success'
                                            disabled={!selectedWorkItems || selectedWorkItems.length === 0}
                                        >Add</button>
                                    }</Observer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>
}

export default AddWorkOrderItem;