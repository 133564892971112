import { createContext } from 'react';
import { decorate, observable, action } from 'mobx';

import * as fn from '../utilities/_functions';
import api from '../api';

export class PublicInsuranceVerification {
    subsidizedServices = [];
    isActivated = false;
    isReady = false;

    cancelServicesGet = null;

    initialize = () => {
        const that = this;

        this.clear();
        this.isActivated = true;

        return new Promise((resolve, reject) => {
            api.Services.search({
                parameters: [{
                    field: 'SubsidyCode',
                    operator: '!=',
                    value: null,
                }, {
                    field: 'DeactivatedDateUtc',
                    value: null,
                }],
                sortByFields: [{
                    field: 'Code',
                    direction: 'ASC',
                }],
                includeTotalCount: false
            }, (c) => { that.cancelServicesGet = c })
                .then(({ data }) => {
                    if (data && data.result && data.result.length > 0) {
                        that.subsidizedServices = data.result;
                    }
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isReady = true;
                })
        })
    }

    clear = () => {
        this.subsidizedServices.clear();
        this.isActivated = false;
        this.isReady = false;

        if (fn.isFunction(this.cancelServicesGet)) {
            this.cancelServicesGet();
            this.cancelServicesGet = null;
        }
    }
}

decorate(PublicInsuranceVerification, {
    subsidizedServices: observable,
    isActivated: observable,
    isReady: observable,
    initialize: action,
    clear: action,
})

export default createContext(new PublicInsuranceVerification());