import React, { useContext, useEffect, useRef, useState } from 'react';
import { Observer } from 'mobx-react-lite';
import FadeIn from 'react-fade-in';
import { DropdownList } from 'react-widgets'
import { GlobalHotKeys } from 'react-hotkeys';

import BodyEnd from '../../_shared/BodyEnd';
import ConfirmModal from '../../_shared/ConfirmModal';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';

import ExamViewStore from '../../../../stores/ExamViewStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';
import CacheStore from '../../../../stores/CacheStore';

import * as fn from '../../../../utilities/_functions';
import * as bh from '../../../../utilities/badgeHelper';
import * as pih from '../../../../utilities/publicInsuranceBillingReportHelper';
import * as ErrorMessages from '../../../../constants/errorMessages';

import './ConfirmDiagnosticCodes.scss';

function ConfirmDiagnosticCodes(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const viewExam = useContext(ExamViewStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const cache = useContext(CacheStore);
    const [confirmReopenExam, setConfirmReopenExam] = useState(false);

    useEffect(() => {
        quickDrawerFocus(props.drawer);

        return () => {
            isMounted.current = false;
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (viewExam.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            if (fn.isFunction(props.onSuccess)) {
                props.onSuccess(event, { valid: true });
            }
        }
    }

    const handleConfirmReopenExam = event => {
        if (isMounted.current) {
            if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                props.onSuccess(event, { updated: true });
            }
            setConfirmReopenExam(false);
        }
    }

    const handleConfirmCancelReopenExam = event => {
        if (isMounted.current) {
            setConfirmReopenExam(false);

            if (fn.isFunction(props.onCancel)) {
                props.onCancel();
            }
        }
    }

    const handleDiagnosticCodeChange = (appointmentService, diagnosticCode) => {
        if (appointmentService) {
            const index = viewExam.data.appointment.services.findIndex(s => s.id === appointmentService.id);

            if (index > -1) {
                viewExam.data.appointment.services[index].diagnosticCode = diagnosticCode;
            }
        }
    }

    const getServiceDiagnosticCodeValue = (appointmentService) => {
        if (appointmentService) {
            const index = viewExam.data.appointment.services.findIndex(s => s.id === appointmentService.id);

            if (index > -1) {
                if (viewExam.data.appointment.services[index].diagnosticCode && viewExam.data.appointment.services[index].lastPublicInsuranceBilling && viewExam.data.appointment.services[index].lastPublicInsuranceBilling.isLocked) {
                    return viewExam.data.appointment.services[index].diagnosticCode;
                } else {
                    if (!viewExam.data.appointment.services[index].diagnosticCode && viewExam.data.diagnosticCodes && viewExam.data.diagnosticCodes.length > 0) {
                        viewExam.data.appointment.services[index].diagnosticCode = viewExam.data.diagnosticCodes[0];
                    }

                    if (viewExam.data.appointment.services[index].diagnosticCode && (!viewExam.data.diagnosticCodes || viewExam.data.diagnosticCodes.length === 0)) {
                        viewExam.data.appointment.services[index].diagnosticCode = null;
                    }

                    return viewExam.data.appointment.services[index].diagnosticCode;
                }
            }
        }

        return '';
    }

    const getDiagnosticCodeDescription = (code) => {
        if (code) {
            const diagnosticCodes = cache.getReferenceDataOptions('PublicInsuranceDiagnosticType')[0];

            if (diagnosticCodes.options && diagnosticCodes.options.length > 0) {
                const diagnosticCode = diagnosticCodes.options.filter(o => o.key === code)[0];

                if (diagnosticCode) {
                    return `${code} - ${diagnosticCode.value}`;
                }
            }
        }

        return code;
    }

    return <>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <Observer>{() =>
                <>
                    {
                        (props.drawer === quickDrawer.drawerOpened) ?
                            <GlobalHotKeys
                                keyMap={{
                                    close: ['esc'],
                                }}
                                handlers={{
                                    close: event => {
                                        handleCancel(event)
                                    },
                                }}
                                allowChanges={true}
                            /> : null
                    }
                    <fieldset>
                        <div className='quick-drawer'>
                            <QuickDrawerHeader
                                drawer={props.drawer}
                                icon={'fal fa-clipboard-list-check'}
                                action='Confirm Billing'
                                category='Diagnostic Code(s)'
                                onCancel={handleCancel}
                            />
                            <div className='quick-drawer-body'>
                                {
                                    viewExam.isReady ?
                                        <FadeIn>
                                            <div className='confirm-diagnostic-codes body-content'>
                                                {
                                                    viewExam.data.appointment && viewExam.data.appointment.services && viewExam.data.appointment.services.some(s => s.isDiagnosticCodeRequired === true) ?
                                                        <section>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='validate validate-required'>
                                                                        <label className='required'><small>Service / Diagnostic Code</small></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                viewExam.data.appointment.services.filter(s => s.isDiagnosticCodeRequired === true).map((s, si) => {
                                                                    return <div
                                                                        key={`service-diagnostic-code-select-${si}`}
                                                                        className='row mb-2'
                                                                    >
                                                                        <div className='col-12'>
                                                                            <div className='d-flex align-items-center'>
                                                                                <div className='col-3'>
                                                                                    {bh.renderServiceCode(s, 'fs-base')}
                                                                                </div>
                                                                                <div className='px-2'>
                                                                                    <i className='fas fa-equals'></i>
                                                                                </div>
                                                                                <div className='flex-1 validate validate-required' title={(viewExam.lockedServices.some(l => l.code === s.code) ? pih.getLockedReason(viewExam.lockedServices.filter(l => l.code === s.code)[0].lastPublicInsuranceBilling) : '')}>
                                                                                    <DropdownList
                                                                                        data={(viewExam.data.diagnosticCodes && viewExam.data.diagnosticCodes.length > 0 ? viewExam.data.diagnosticCodes : [])}
                                                                                        value={getServiceDiagnosticCodeValue(s)}
                                                                                        disabled={viewExam.lockedServices.some(l => l.code === s.code)}
                                                                                        itemComponent={({ item }) => (
                                                                                            getDiagnosticCodeDescription(item)
                                                                                        )}
                                                                                        valueComponent={({ item }) => (
                                                                                            getDiagnosticCodeDescription(item)
                                                                                        )}
                                                                                        onChange={(item) => { handleDiagnosticCodeChange(s, item) }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                })
                                                            }
                                                        </section> : null
                                                }
                                            </div>
                                        </FadeIn> : renderQuickDrawerLoading()
                                }
                            </div>
                            <Observer>{() =>
                                viewExam.isReady ?
                                    <div className='quick-drawer-action'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='float-right'>
                                                    <button
                                                        type='button'
                                                        className='btn btn-link btn-cancel mr-2'
                                                        onClick={handleCancel}
                                                    >Cancel</button>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-success'
                                                    >Continue</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : null
                            }</Observer>
                        </div>
                    </fieldset>
                </>
            }</Observer >
        </form >
        <BodyEnd>
            <Observer>{() =>
                <ConfirmModal
                    icon={<i className='fal fa-clipboard-list-check text-danger mr-2'></i>}
                    message={<>
                        {
                            confirmReopenExam ?
                                <>Continue to <span className='fw-500'>&nbsp;reopen the exam</span>?</> :
                                <></>
                        }
                    </>}
                    show={confirmReopenExam}
                    onOption1Click={handleConfirmReopenExam}
                    onCancel={handleConfirmCancelReopenExam}
                />
            }</Observer>
        </BodyEnd>
    </>
}

export default ConfirmDiagnosticCodes;