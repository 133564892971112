import { createContext } from 'react';
import { decorate, observable, action, computed } from 'mobx';

import * as fn from '../utilities/_functions';
import api from '../api';

export class PretestCreate {
    id = null;
    data = {
        typeIds: [],
        timelineItemId: null,
        customerId: null,
        appointmentId: null,
        pretestData: [],
        inputMethodType: 'Manual'
    };
    pretestTypes = [];
    industryDevices = [];
    logicalDevices = [];
    isReady = false;
    isSaving = false;
    hasUnsavedChanges = false;

    cancelPretestCreate = null;
    cancelPretestTypes = null;
    cancelIndustryDevices = null;
    cancelLogicalDevices = null;
    cancelDevices = null;

    initialize = (customerId, appointmentId, timelineItemId) => {
        const that = this;

        this.clear();
        this.data.customerId = customerId;
        this.data.appointmentId = appointmentId;
        this.data.timelineItemId = timelineItemId;

        return new Promise((resolve, reject) => {
            Promise.all([
                api.IndustryDevices.all((c) => { that.cancelIndustryDevices = c }),
                api.LogicalDevices.all((c) => { that.cancelLogicalDevices = c }),
                api.PretestTypes.all(true, (c) => { that.cancelPretestTypes = c })
            ])
                .then(response => {
                    if (response[2].data && response[2].data.length > 0) {
                        for (let di = 0; di < response[2].data.length; di++) {
                            if (response[2].data[di].pretestPublishedTemplate) {
                                response[2].data[di].industryDeviceId = response[2].data[di].pretestPublishedTemplate.definition.industryDeviceId;
                                response[2].data[di].industryDevice = response[0].data && response[0].data.length > 0 ? response[0].data.filter(idv => idv.id === response[2].data[di].industryDeviceId)[0] : null;
                                response[2].data[di].logicalDeviceId = response[2].data[di].pretestPublishedTemplate.definition.logicalDeviceId;
                                response[2].data[di].logicalDevice = response[1].data && response[1].data.length > 0 ? response[1].data.filter(dv => dv.id === response[2].data[di].logicalDeviceId)[0] : null;
                            }
                        }
                        that.pretestTypes = response[2].data.some(d => d.pretestPublishedTemplate) ? response[2].data.filter(d => d.pretestPublishedTemplate) : [];
                    }
                    that.industryDevices = response[0].data && response[0].data.length > 0 ? response[0].data : [];
                    that.logicalDevices = response[1].data && response[1].data.length > 0 ? response[1].data : [];
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isReady = true;
                })
        })
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            if (that.hasUnsavedChanges) {
                const option = {
                    customerId: that.data.customerId,
                    appointmentId: that.data.appointmentId,
                    items: that.selectedTypes.map(t => {
                        return {
                            typeId: t.id,
                            pretestPublishedTemplateId: t.pretestPublishedTemplateId,
                            inputMethodType: that.data.inputMethodType,
                            pretestData: that.data.pretestData.filter(d => t.pretestPublishedTemplate.definition.definition.some(m => m.key === d.id))
                        }
                    })
                };

                api.Pretests.create(option, (c) => { that.cancelPretestCreate = c })
                    .then(() => {
                        that.hasUnsavedChanges = false;
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                that.hasUnsavedChanges = false;
                that.isSaving = false;
                resolve();
            }
        })
    }

    clear = () => {
        this.id = null;
        this.data.typeIds.clear();
        this.data.timelineItemId = null;
        this.data.customerId = null;
        this.data.appointmentId = null;
        this.data.pretestData.clear();
        this.pretestTypes.clear();
        this.industryDevices.clear();
        this.logicalDevices.clear();
        this.template = null;
        this.isReady = false;
        this.isSaving = false;
        this.hasUnsavedChanges = false;

        if (fn.isFunction(this.cancelPretestCreate)) {
            this.cancelPretestCreate();
            this.cancelPretestCreate = null;
        }

        if (fn.isFunction(this.cancelPretestTypes)) {
            this.cancelPretestTypes();
            this.cancelPretestTypes = null;
        }

        if (fn.isFunction(this.cancelIndustryDevices)) {
            this.cancelIndustryDevices();
            this.cancelIndustryDevices = null;
        }

        if (fn.isFunction(this.cancelLogicalDevices)) {
            this.cancelLogicalDevices();
            this.cancelLogicalDevices = null;
        }

        if (fn.isFunction(this.cancelDevices)) {
            this.cancelDevices();
            this.cancelDevices = null;
        }
    }

    get selectedTypes() {
        return this.data.typeIds ? this.pretestTypes.filter(t => this.data.typeIds.some(i => i === t.id)) : []
    }
}

decorate(PretestCreate, {
    id: observable,
    data: observable.deep,
    pretestTypes: observable,
    industryDevices: observable,
    logicalDevices: observable,
    template: observable,
    isReady: observable,
    isSaving: observable,
    hasUnsavedChanges: observable,
    initialize: action,
    save: action,
    clear: action,
    selectedTypes: computed,
})

export default createContext(new PretestCreate());