import { createContext } from 'react';
import { decorate, observable, action } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';
import moment, { isMoment } from 'moment';

export class BusinessDayUpdate {
    data = null;
    date = null;
    isReady = false;
    isSaving = false;
    isLoading = false;
    hasUnsavedChanges = false;
    cancelBusinessDay = null;
    cancelBusinessDayUpdate = null;
    cancelBusinessDayDelete = null;

    initialize = (date) => {
        const that = this;
        this.clear();
        this.date = isMoment(date) ? date.clone() : moment(date);

        return new Promise((resolve, reject) => {
            that.refresh()
                .then(() => {
                    resolve();
                })
                .catch(error => {
                    reject(error);
                })
                .finally(() => {
                    that.isReady = true;
                });
        })
    }

    refresh = () => {
        const that = this;
        that.isLoading = true;

        return new Promise((resolve, reject) => {
            api.BusinessDays.get(that.date.format('YYYY-MM-DD'), (c) => { that.cancelBusinessDay = c })
                .then(({ data }) => {
                    that.data = data;
                    resolve();
                })
                .catch(error => {
                    reject(error);
                })
                .finally(() => {
                    that.isLoading = false;
                })
        })
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            if (that.hasUnsavedChanges) {
                const option = {
                    date: that.date,
                    startTime: !that.data.isClosed ? that.data.start : null,
                    endTime: !that.data.isClosed ? that.data.end : null,
                    isClosed: that.data.isClosed,
                    note: that.data.note,
                    noteHtml: that.data.noteHtml,
                }

                api.BusinessDays.save(option, (c) => { that.cancelBusinessDayUpdate = c })
                    .then(() => {
                        that.hasUnsavedChanges = false;
                        resolve();
                    })
                    .catch(error => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                that.hasUnsavedChanges = false;
                that.isSaving = false;
                resolve();
            }
        })
    }

    delete = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            if (that.hasUnsavedChanges) {
                api.BusinessDays.delete(that.date, (c) => { that.cancelBusinessDayDelete = c })
                    .then(() => {
                        that.hasUnsavedChanges = false;
                        resolve();
                    })
                    .catch(error => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                that.hasUnsavedChanges = false;
                that.isSaving = false;
                resolve();
            }
        })
    }

    clear = () => {
        this.data = null;
        this.date = null;
        this.isReady = false;
        this.isSaving = false;
        this.isLoading = false;
        this.hasUnsavedChanges = false;

        if (fn.isFunction(this.cancelBusinessDay)) {
            this.cancelBusinessDay();
            this.cancelSchedule = null;
        }

        if (fn.isFunction(this.cancelBusinessDayUpdate)) {
            this.cancelBusinessDayUpdate();
            this.cancelBusinessDayUpdate = null;
        }

        if (fn.isFunction(this.cancelBusinessDayDelete)) {
            this.cancelBusinessDayDelete();
            this.cancelBusinessDayDelete = null;
        }
    }
}

decorate(BusinessDayUpdate, {
    data: observable,
    date: observable,
    isReady: observable,
    isSaving: observable,
    isLoading: observable,
    hasUnsavedChanges: observable,
    initialize: action,
    refresh: action,
    save: action,
    delete: action,
    clear: action,
})

export default createContext(new BusinessDayUpdate());