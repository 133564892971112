import React, { useEffect, useContext, useState, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { GlobalHotKeys } from 'react-hotkeys';
import { useObserver } from 'mobx-react-lite';
import { DropdownList, Combobox } from 'react-widgets';

import QuickDrawerHeader from '../../../../_shared/QuickDrawerHeader';
import { quickDrawerFocus } from '../../../../_shared/QuickDrawer';
import ColorPicker from '../../../../_shared/ColorPicker';
import RichTextEditor from '../../../../_shared/RichTextEditor';

import InternalFormInputCreateStore from '../../../../../../stores/InternalFormInputCreateStore';
import QuickDrawerStore from '../../../../../../stores/QuickDrawerStore';
import CacheStore from '../../../../../../stores/CacheStore';

import * as ErrorMessages from '../../../../../../constants/errorMessages';
import * as fn from '../../../../../../utilities/_functions';
import * as oh from '../../../../../../utilities/operationHelper';
import * as ifh from '../../../../../../utilities/internalFormInputHelper';

import './NewInternalFormInput.scss';

function NewInternalFormInput(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const internalFormInput = useContext(InternalFormInputCreateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const cache = useContext(CacheStore);
    const [metadataReady, setMetadataReady] = useState(false);
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [align, setAlign] = useState('left');
    const [fontColor, setFontColor] = useState('');
    const [fontSize, setFontSize] = useState(13);
    const [fontFamily, setFontFamily] = useState('Arial');
    const [fontStyle, setFontStyle] = useState([]);
    const [fontLetterSpacing, setFontLetterSpacing] = useState(null);
    const [options, setOptions] = useState([]);
    const [border] = useState(null);
    const [borderWidth, setBorderWidth] = useState(1);
    const [borderColor, setBorderColor] = useState('#000');
    const [defaultValue, setDefaultValue] = useState(null);

    useEffect(() => {
        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100)

        return (() => {
            isMounted.current = false;
            internalFormInput.clear();
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        })
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (internalFormInput.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = event => {
        event.preventDefault();
        handlePopulateMetadata();
        internalFormInput.save()
            .then(data => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, data);
                    }
                }
            })
    }

    const handleTypeChange = event => {
        internalFormInput.data.type = event.target.value;

        if (!internalFormInput.data.type) { setMetadataReady(false); return; }

        if (metadataReady) {
            internalFormInput.data.metadata = null;
            setMetadataReady(false);
            focusTimer.current = setTimeout(() => {
                setMetadataReady(true);
                setFocusToName();
            }, 100);
        } else {
            setMetadataReady(true);
            setFocusToName();
        }

        internalFormInput.hasUnsavedChanges = true;
    }

    const handleNameChange = event => {
        internalFormInput.data.name = event.target.value;
        internalFormInput.data.key = event.target.value.replace(/[^0-9a-zA-Z]/g, '');
        internalFormInput.hasUnsavedChanges = true;
    }

    const handleKeyChange = event => {
        internalFormInput.data.key = event.target.value.replace(/[^0-9a-zA-Z]/g, '');
        internalFormInput.hasUnsavedChanges = true;
    }

    const handleDescriptionChange = content => {
        const html = content;
        internalFormInput.data.descriptionHtml = ((html === '<p><br></p>') ? null : html);
        internalFormInput.hasUnsavedChanges = true;
    }

    const handleFontFamilyChange = (value) => {
        setFontFamily(value);
        internalFormInput.hasUnsavedChanges = true;
    }

    const handleFontSizeChange = (value) => {
        setFontSize(value);
        internalFormInput.hasUnsavedChanges = true;
    }

    const handleFontColorChange = (color) => {
        setFontColor(color.value);
        internalFormInput.hasUnsavedChanges = true;
    }

    const handleFontStyleChange = value => {
        const index = fontStyle.findIndex(s => s === value);
        const tempFontStyle = [...fontStyle];

        if (index === -1) {
            tempFontStyle.push(value);
        } else {
            tempFontStyle.splice(index, 1);
        }

        setFontStyle(tempFontStyle);
        internalFormInput.hasUnsavedChanges = true;
    }

    const handleFontLetterSpacing = event => {
        const spacing = event.target.value;
        setFontLetterSpacing((spacing ? spacing : 0));
        internalFormInput.hasUnsavedChanges = true;
    }

    const handleAlignChange = value => {
        setAlign((value ? value : 'left'));
        internalFormInput.hasUnsavedChanges = true;
    }

    const handleBorderStyleChange = (item) => {
        if (item) {
            setBorderWidth(item.data.width);
        }
        internalFormInput.hasUnsavedChanges = true;
    }

    const handleBorderColorChange = (color) => {
        setBorderColor(color.value);
        internalFormInput.hasUnsavedChanges = true;
    }

    const handlePopulateMetadata = () => {
        populateMetadata(ifh.INTERNAL_FORM_INPUT_METADATA_ALIGN, 'align', align);
        populateMetadata(ifh.INTERNAL_FORM_INPUT_METADATA_FONT_COLOR, 'fontColor', fontColor);
        populateMetadata(ifh.INTERNAL_FORM_INPUT_METADATA_FONT_SIZE, 'fontSize', fontSize);
        populateMetadata(ifh.INTERNAL_FORM_INPUT_METADATA_FONT_FAMILY, 'fontFamily', fontFamily);
        populateMetadata(ifh.INTERNAL_FORM_INPUT_METADATA_FONT_STYLE, 'fontStyle', fontStyle);
        populateMetadata(ifh.INTERNAL_FORM_INPUT_METADATA_FONT_LETTER_SPACING, 'fontLetterSpacing', fontLetterSpacing);
        populateMetadata(ifh.INTERNAL_FORM_INPUT_METADATA_OPTIONS, 'options', options);
        populateMetadata(ifh.INTERNAL_FORM_INPUT_METADATA_BORDER, 'border', border);
        populateMetadata(ifh.INTERNAL_FORM_INPUT_METADATA_BORDER_COLOR, 'borderColor', borderColor);
        populateMetadata(ifh.INTERNAL_FORM_INPUT_METADATA_BORDER_WIDTH, 'borderWidth', borderWidth);
        populateMetadata(ifh.INTERNAL_FORM_INPUT_METADATA_DEFAULT_VALUE, 'defaultValue', defaultValue);
    }

    const handleOptionChange = (event, index) => {
        const tempOptions = [...options];
        tempOptions[index] = event.target.value;
        setOptions(tempOptions);
        internalFormInput.hasUnsavedChanges = true;
    }

    const handleAddOption = event => {
        event.preventDefault();
        setOptions(o => [...options, '']);
        setTimeout(() => {
            const element = document.querySelector(`#new-internal-form-input-${internalFormInput.data.key}-${(options.length)}`)
            if (element) { element.focus(); }
        }, 50);
        internalFormInput.hasUnsavedChanges = true;
    }

    const handleRemoveOption = (option, index) => {
        setOptions(options.filter((o, oi) => oi !== index));
        internalFormInput.hasUnsavedChanges = true;
    }

    const handleMoveOption = (from, to) => {
        const tempOptions = [...options];
        const element = tempOptions[from];

        tempOptions.splice(from, 1);
        tempOptions.splice(to, 0, element);

        setOptions(tempOptions);
        internalFormInput.hasUnsavedChanges = true;
    }

    const handleDefaultValueTextChange = event => {
        setDefaultValue(event.target.value);
        internalFormInput.hasUnsavedChanges = true;
    }

    // const handleDefaultValueRichTextChange = content => {
    //     const html = content;
    //     setDefaultValue(((html === '<p><br></p>') ? null : html));
    //     internalFormInput.hasUnsavedChanges = true;
    // }

    const handleDefaultValueCheckboxChange = event => {
        if (!defaultValue) {
            setDefaultValue([]);
        }

        if (event.target.checked) {
            defaultValue.push(event.target.value);
        } else {
            const index = defaultValue.findIndex(v => v === event.target.value);
            if (index >= 0) {
                defaultValue.splice(index, 1);
            }
        }

        if (defaultValue.length === 0) {
            setDefaultValue(null);
        }

        setDefaultValue(defaultValue);
        internalFormInput.hasUnsavedChanges = true;
    }

    const renderOptions = () => {
        return <>
            {
                options && options.length > 0 ?
                    options.map((o, oi) => {
                        return <ul
                            key={`internal_form_input_options_${oi}`}
                            className='list-inline no-style m-0 mb-2'
                        >
                            <li className='list-inline-item m-0 mr-1'>{oi + 1}.&nbsp;</li>
                            <li className='list-inline-item m-0 option'>
                                <input
                                    id={`new-internal-form-input-${internalFormInput.data.key}-${oi}`}
                                    type='text'
                                    className='form-control form-control-sm'
                                    autoComplete='off'
                                    value={o}
                                    onChange={(e) => handleOptionChange(e, oi)}
                                />
                            </li>
                            <li className='list-inline-item m-0 ml-2'>
                                <button
                                    type='button'
                                    className='btn btn-link btn-icon btn-sm'
                                    autoComplete='off'
                                    onClick={e => { handleRemoveOption(o, oi) }}
                                >
                                    <i className={'fal fa-minus-circle text-danger'}></i>
                                </button>
                            </li>
                            <li className='list-inline-item m-0'>
                                <button
                                    type='button'
                                    className='btn btn-link btn-icon btn-sm'
                                    autoComplete='off'
                                    disabled={options.length === 1 || oi === 0}
                                    onClick={e => { handleMoveOption(oi, (oi - 1)) }}
                                >
                                    <i className={'fal fa-arrow-up' + (options.length === 1 || oi === 0 ? ' text-gray-500' : ' text-primary')}></i>
                                </button>
                            </li>
                            <li className='list-inline-item m-0'>
                                <button
                                    type='button'
                                    className='btn btn-link btn-icon btn-sm'
                                    disabled={options.length === 1 || oi === (options.length - 1)}
                                    onClick={e => { handleMoveOption(oi, (oi + 1)) }}
                                >
                                    <i className={'fal fa-arrow-down' + (options.length === 1 || oi === (options.length - 1) ? ' text-gray-500' : ' text-primary')}></i>
                                </button>
                            </li>
                        </ul>
                    }) : <div></div>
            }
            <button
                type='button'
                className='btn btn-link btn-icon-left btn-sm'
                onClick={handleAddOption}
            >
                <i className='fal fa-plus-circle'></i> Add option
            </button>
        </>
    }

    const renderDefaultValueEditor = () => {
        switch (internalFormInput.data.type) {
            case 'SingleLineText':
                return <input
                    id='new-internal-form-input-default'
                    type='text'
                    maxLength={100}
                    className='form-control'
                    value={defaultValue ? defaultValue : ''}
                    autoComplete={'off'}
                    onChange={handleDefaultValueTextChange}
                />;

            case 'MultiLineText':
                return <textarea
                    id='new-internal-form-input-default'
                    className='form-control'
                    value={defaultValue ? defaultValue : ''}
                    autoComplete={'off'}
                    onChange={handleDefaultValueTextChange}
                />;
            // return <RichTextEditor
            //     id='new-internal-form-input-default'
            //     mode='none'
            //     disableTab={true}
            //     value={defaultValue ? defaultValue : ''}
            //     onChange={handleDefaultValueRichTextChange}
            // />;

            case 'Dropdown':
                return <select
                    id='new-internal-form-input-default'
                    className='custom-select form-control'
                    value={defaultValue}
                    onChange={handleDefaultValueTextChange}
                >
                    <option value=''></option>
                    {
                        options && options.length > 0 ?
                            options.map((o, oi) => {
                                return <option key={`${internalFormInput.key}_metadata_${oi}`} value={o}>{o}</option>
                            }) : null
                    }
                </select>;

            case 'Checkbox':
                return <div className='checkbox-wrapper'>
                    {
                        options && options.length > 0 ?
                            options.map((o, oi) => {
                                return <div
                                    key={`${internalFormInput.key}_metadata_${oi}`}
                                    className={'custom-control custom-checkbox mb-2'}
                                >
                                    <input
                                        id={`new-internal-form-input-default-${internalFormInput.key}-${oi}`}
                                        type='checkbox'
                                        name='internal-form-input-default'
                                        className='custom-control-input'
                                        value={o}
                                        checked={defaultValue && defaultValue.split('|').some(v => v === o)}
                                        onChange={handleDefaultValueCheckboxChange}
                                    />
                                    <label
                                        htmlFor={`new-internal-form-input-inline-${internalFormInput.key}-${oi}`}
                                        className='custom-control-label'
                                    >
                                        {o}
                                    </label>
                                </div>
                            }) : null
                    }
                </div>;

            case 'Circle':
                return <div className='checkbox-wrapper'>
                    {
                        options && options.length > 0 ?
                            options.map((o, oi) => {
                                return <div
                                    key={`${internalFormInput.key}_metadata_${oi}`}
                                    className={'custom-control custom-checkbox mb-2'}
                                >
                                    <input
                                        id={`new-internal-form-input-default-${internalFormInput.key}-${oi}`}
                                        type='checkbox'
                                        name='internal-form-input-default'
                                        className='custom-control-input'
                                        value={o}
                                        checked={defaultValue && defaultValue.split('|').some(v => v === o)}
                                        onChange={handleDefaultValueCheckboxChange}
                                    />
                                    <label
                                        htmlFor={`new-internal-form-input-inline-${internalFormInput.key}-${oi}`}
                                        className='custom-control-label'
                                    >
                                        {o}
                                    </label>
                                </div>
                            }) : null
                    }
                </div>;

            case 'Check':
                return <div className='checkbox-wrapper'>
                    {
                        options && options.length > 0 ?
                            options.map((o, oi) => {
                                return <div
                                    key={`${internalFormInput.key}_metadata_${oi}`}
                                    className={'custom-control custom-checkbox mb-2'}
                                >
                                    <input
                                        id={`new-internal-form-input-default-${internalFormInput.key}-${oi}`}
                                        type='checkbox'
                                        name='internal-form-input-default'
                                        className='custom-control-input'
                                        value={o}
                                        checked={defaultValue && defaultValue.split('|').some(v => v === o)}
                                        onChange={handleDefaultValueCheckboxChange}
                                    />
                                    <label
                                        htmlFor={`new-internal-form-input-inline-${internalFormInput.key}-${oi}`}
                                        className='custom-control-label'
                                    >
                                        {o}
                                    </label>
                                </div>
                            }) : null
                    }
                </div>;

            default:
                return null
        }
    }

    const populateMetadata = (field, key, value) => {
        if (ifh.hasField(internalFormInput.data.type, field)) {
            internalFormInput.setMetadata(key, value);
        } else {
            internalFormInput.removeMetadata(key);
        }
    }

    const setFocusToName = () => {
        setTimeout(() => {
            const element = document.querySelector('#new-internal-form-input-name');
            if (element) {
                element.focus();
            }
        }, 100)
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form onSubmit={handleSubmit}>
            <fieldset disabled={internalFormInput.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('internal-form-input', 'default')}
                        action='New'
                        category='Internal Form Input'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        <FadeIn>
                            <div className='new-internal-form-input body-content'>
                                <section>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='form-group mb-4'>
                                                <label className='required' htmlFor='new-internal-form-input-type'><small>Type</small></label>
                                                <select
                                                    id='new-internal-form-input-type'
                                                    className='custom-select form-control'
                                                    value={internalFormInput.data.type ? internalFormInput.data.type : ''}
                                                    onChange={handleTypeChange}
                                                >
                                                    <option value=''></option>
                                                    {
                                                        cache.getReferenceDataOptions('InternalFormInputType').map((option, di) => {
                                                            return <option key={`internal_form_input_type_${di}`} value={option.key}>{option.value}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        metadataReady ?
                                            <FadeIn>
                                                <>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-4'>
                                                                <label className='required' htmlFor='new-internal-form-input-name'><small>Name</small></label>
                                                                <input
                                                                    id='new-internal-form-input-name'
                                                                    type='text'
                                                                    maxLength={100}
                                                                    className='form-control'
                                                                    autoComplete={'off'}
                                                                    value={internalFormInput.data.name ? internalFormInput.data.name : ''}
                                                                    onChange={handleNameChange}
                                                                />
                                                                <small className='text-gray-700'><strong>Important:</strong> Name must be unique across all inputs.</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        ifh.hasField(internalFormInput.data.type, ifh.INTERNAL_FORM_INPUT_METADATA_FONT_FAMILY) ||
                                                            ifh.hasField(internalFormInput.data.type, ifh.INTERNAL_FORM_INPUT_METADATA_FONT_SIZE) ||
                                                            ifh.hasField(internalFormInput.data.type, ifh.INTERNAL_FORM_INPUT_METADATA_FONT_COLOR) ?
                                                            <div className='row'>
                                                                {
                                                                    ifh.hasField(internalFormInput.data.type, ifh.INTERNAL_FORM_INPUT_METADATA_FONT_FAMILY) ?
                                                                        <div className='col-6'>
                                                                            <div className='form-group mb-4'>
                                                                                <label htmlFor='new-internal-form-font-family'><small>Font</small></label>
                                                                                <DropdownList
                                                                                    data={['Arial', 'Arial Black', 'Georgia', 'Impact', 'Times New Roman', 'Verdana']}
                                                                                    value={fontFamily}
                                                                                    itemComponent={({ item }) => (
                                                                                        <div>
                                                                                            <span style={{ fontFamily: item }}>{item}</span>
                                                                                        </div>
                                                                                    )}
                                                                                    valueComponent={({ item }) => (
                                                                                        item ?
                                                                                            <div>
                                                                                                <span style={{ fontFamily: item }}>{item}</span>
                                                                                            </div> : null
                                                                                    )}
                                                                                    onChange={handleFontFamilyChange}
                                                                                />
                                                                            </div>
                                                                        </div> : null
                                                                }
                                                                {
                                                                    ifh.hasField(internalFormInput.data.type, ifh.INTERNAL_FORM_INPUT_METADATA_FONT_SIZE) ?
                                                                        <div className='col-3'>
                                                                            <div className='form-group mb-4'>
                                                                                <label htmlFor='new-internal-form-font-size'><small>Size</small></label>
                                                                                <Combobox
                                                                                    data={[8, 9, 10, 11, 12, 13, 14, 18, 21, 24, 28, 30, 32, 36, 48, 60, 72, 96]}
                                                                                    value={fontSize}
                                                                                    autoSelectMatches={false}
                                                                                    itemComponent={({ item }) => (
                                                                                        <div>
                                                                                            <span>{item}</span>
                                                                                        </div>
                                                                                    )}
                                                                                    valueComponent={({ item }) => (
                                                                                        item ?
                                                                                            <div>
                                                                                                <span>{item}</span>
                                                                                            </div> : null
                                                                                    )}
                                                                                    onChange={handleFontSizeChange}
                                                                                />
                                                                            </div>
                                                                        </div> : null
                                                                }
                                                                {
                                                                    ifh.hasField(internalFormInput.data.type, ifh.INTERNAL_FORM_INPUT_METADATA_FONT_COLOR) ?
                                                                        <div className='col-3'>
                                                                            <div className='form-group mb-4'>
                                                                                <label htmlFor='new-internal-form-font-color'><small>Color</small></label>
                                                                                <ColorPicker color={fontColor} popup='right' onChange={handleFontColorChange} />
                                                                            </div>
                                                                        </div> : null
                                                                }
                                                            </div> : null
                                                    }
                                                    {
                                                        ifh.hasField(internalFormInput.data.type, ifh.INTERNAL_FORM_INPUT_METADATA_FONT_STYLE) ||
                                                            ifh.hasField(internalFormInput.data.type, ifh.INTERNAL_FORM_INPUT_METADATA_FONT_LETTER_SPACING) ?
                                                            <div className='row'>
                                                                {
                                                                    ifh.hasField(internalFormInput.data.type, ifh.INTERNAL_FORM_INPUT_METADATA_FONT_STYLE) ?
                                                                        <div className='col-6'>
                                                                            <div className='form-group mb-4'>
                                                                                <label htmlFor='new-internal-form-font-style'><small>Style</small></label>
                                                                                <div>
                                                                                    <div className='btn-group'>
                                                                                        <button
                                                                                            type='button'
                                                                                            className={'btn btn-primary' + (fontStyle.some(s => s === 'bold') ? ' active' : '')}
                                                                                            value={(align ? align : '')}
                                                                                            onClick={() => { handleFontStyleChange('bold') }}
                                                                                        >
                                                                                            <i className='fal fa-bold'></i>
                                                                                        </button>
                                                                                        <button
                                                                                            type='button'
                                                                                            className={'btn btn-primary' + (fontStyle.some(s => s === 'italic') ? ' active' : '')}
                                                                                            value={(align ? align : '')}
                                                                                            onClick={() => { handleFontStyleChange('italic') }}
                                                                                        >
                                                                                            <i className='fal fa-italic'></i>
                                                                                        </button>
                                                                                        <button
                                                                                            type='button'
                                                                                            className={'btn btn-primary' + (fontStyle.some(s => s === 'underline') ? ' active' : '')}
                                                                                            value={(align ? align : '')}
                                                                                            onClick={() => { handleFontStyleChange('underline') }}
                                                                                        >
                                                                                            <i className='fal fa-underline'></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div> : null
                                                                }
                                                                {
                                                                    ifh.hasField(internalFormInput.data.type, ifh.INTERNAL_FORM_INPUT_METADATA_FONT_LETTER_SPACING) ?
                                                                        <div className='col-3'>
                                                                            <div className='form-group mb-4'>
                                                                                <label htmlFor='new-internal-form-font-letter-spacing'><small>Spacing</small></label>
                                                                                <input
                                                                                    id='new-internal-form-font-letter-spacing'
                                                                                    type='number'
                                                                                    className='form-control'
                                                                                    min={-2}
                                                                                    step={0.1}
                                                                                    max={2}
                                                                                    value={fontLetterSpacing}
                                                                                    onChange={handleFontLetterSpacing}
                                                                                />
                                                                            </div>
                                                                        </div> : null
                                                                }
                                                            </div> : null
                                                    }
                                                    {
                                                        ifh.hasField(internalFormInput.data.type, ifh.INTERNAL_FORM_INPUT_METADATA_ALIGN) ?
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='form-group mb-4'>
                                                                        <label className='required' htmlFor='new-internal-form-input-align'><small>Align</small></label>
                                                                        <div>
                                                                            <div className='btn-group'>
                                                                                <button
                                                                                    type='button'
                                                                                    className={'btn btn-secondary' + (align === 'left' ? ' active' : '')}
                                                                                    value={(align ? align : '')}
                                                                                    onClick={() => { handleAlignChange('left') }}
                                                                                >
                                                                                    <i className='fal fa-align-left'></i>
                                                                                </button>
                                                                                <button
                                                                                    type='button'
                                                                                    className={'btn btn-secondary' + (align === 'center' ? ' active' : '')}
                                                                                    value={(align ? align : '')}
                                                                                    onClick={() => { handleAlignChange('center') }}
                                                                                >
                                                                                    <i className='fal fa-align-center'></i>
                                                                                </button>
                                                                                <button
                                                                                    type='button'
                                                                                    className={'btn btn-secondary' + (align === 'right' ? ' active' : '')}
                                                                                    value={(align ? align : '')}
                                                                                    onClick={() => { handleAlignChange('right') }}
                                                                                >
                                                                                    <i className='fal fa-align-right'></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> : null
                                                    }
                                                    {
                                                        ifh.hasField(internalFormInput.data.type, ifh.INTERNAL_FORM_INPUT_METADATA_BORDER) ?
                                                            <>
                                                                <div className='row'>
                                                                    <div className='col-5'>
                                                                        <div className='form-group mb-3'>
                                                                            <label htmlFor='new-internal-form-border-style'><small>Style</small></label>
                                                                            <DropdownList
                                                                                data={[
                                                                                    { key: '1px solid #000', data: { width: 1, style: 'solid' } },
                                                                                    { key: '2px solid #000', data: { width: 2, style: 'solid' } },
                                                                                    { key: '3px solid #000', data: { width: 3, style: 'solid' } },
                                                                                ]}
                                                                                valueField='key'
                                                                                value={(`${borderWidth}px solid #000`)}
                                                                                itemComponent={({ item }) => (
                                                                                    <div className='p-0' style={{ fontSize: '1px', borderTop: item.key }}>&nbsp;</div>
                                                                                )}
                                                                                valueComponent={({ item }) => (
                                                                                    item ? <div className='p-0' style={{ fontSize: '1px', borderTop: item.key }}>&nbsp;</div> : null
                                                                                )}
                                                                                onChange={handleBorderStyleChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <div className='form-group mb-4'>
                                                                            <label htmlFor='new-internal-form-border-color'><small>Color</small></label>
                                                                            <ColorPicker color={borderColor} popup='right' onChange={handleBorderColorChange} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </> : null
                                                    }
                                                    {
                                                        ifh.hasField(internalFormInput.data.type, ifh.INTERNAL_FORM_INPUT_METADATA_OPTIONS) ?
                                                            <div className='row options'>
                                                                <div className='col-12'>
                                                                    <div className='form-group mb-4'>
                                                                        <label className='required' htmlFor='new-internal-form-input-options'><small>Options</small></label>
                                                                        {renderOptions()}
                                                                    </div>
                                                                </div>
                                                            </div> : null
                                                    }
                                                    {
                                                        showAdvanced ?
                                                            <>
                                                                <FadeIn>
                                                                    {
                                                                        ifh.hasField(internalFormInput.data.type, ifh.INTERNAL_FORM_INPUT_METADATA_DEFAULT_VALUE) ?
                                                                            <div className='row'>
                                                                                <div className='col-12'>
                                                                                    <div className='form-group mb-4'>
                                                                                        <label htmlFor='new-internal-form-input-default'><small>Default Value</small></label>
                                                                                        {renderDefaultValueEditor()}
                                                                                    </div>
                                                                                </div>
                                                                            </div> : null
                                                                    }
                                                                    {
                                                                        ifh.hasField(internalFormInput.data.type, ifh.INTERNAL_FORM_INPUT_DESCRIPTION) ?
                                                                            <div className='row'>
                                                                                <div className='col-12'>
                                                                                    <div className='form-group mb-4'>
                                                                                        <label htmlFor='new-internal-form-input-description'><small>Description</small></label>
                                                                                        <RichTextEditor
                                                                                            mode='none'
                                                                                            disableTab={true}
                                                                                            value={internalFormInput.data.descriptionHtml ? internalFormInput.data.descriptionHtml : ''}
                                                                                            onChange={handleDescriptionChange}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div> : null
                                                                    }
                                                                    <div className='row'>
                                                                        <div className='col-12'>
                                                                            <div className='form-group mb-4'>
                                                                                <label className='required' htmlFor='new-internal-form-input-key'><small>Key</small></label>
                                                                                <input
                                                                                    id='new-internal-form-input-key'
                                                                                    type='text'
                                                                                    maxLength={100}
                                                                                    className='form-control'
                                                                                    autoComplete='off'
                                                                                    value={internalFormInput.data.key ? internalFormInput.data.key : ''}
                                                                                    onChange={handleKeyChange}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </FadeIn>
                                                            </> :
                                                            <div className='mt-4 pt-4 border-top'>
                                                                <button type='button' className='btn btn-link btn-sm p-0' onClick={() => { setShowAdvanced(true) }}>&raquo;	Show advanced options</button>
                                                            </div>
                                                    }
                                                </>
                                            </FadeIn> : null
                                    }
                                </section>
                            </div>
                        </FadeIn>
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>)
}

export default NewInternalFormInput;