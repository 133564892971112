import React, { useEffect, useContext, useRef, useState } from 'react';
import { Observer } from 'mobx-react-lite';
import FadeIn from 'react-fade-in';
import { GlobalHotKeys } from 'react-hotkeys';
import { toJS } from 'mobx';

import LoadingOverlay from '../../_shared/LoadingOverlay'
import BodyEnd from '../../_shared/BodyEnd';
import ConfirmModal from '../../_shared/ConfirmModal';
import { renderQuickDrawerLoading } from '../../_shared/QuickDrawer';

import CustomerMergeStore from '../../../../stores/CustomerMergeStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as ph from '../../../../utilities/personHelper';
import * as ah from '../../../../utilities/addressHelper';
import * as ch from '../../../../utilities/customerHelper';
import * as oh from '../../../../utilities/operationHelper';
import * as sys from '../../../../utilities/systemHelper';

function MergeCustomers(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const modalBodyRef = useRef(null);
    const merge = useContext(CustomerMergeStore);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [confirmMerge, setConfirmMerge] = useState(false);

    useEffect(() => {
        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleClose = event => {
        if (fn.isFunction(props.onClose)) {
            if (hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    setHasUnsavedChanges(false);
                    merge.clear();
                    props.onClose(event);
                }
            } else {
                props.onClose(event);
            }
        }
    }

    const handleSave = () => {
        if (isMounted.current) {
            setConfirmMerge(true);
        }
    }

    const handleSwap = () => {
        const tempData = toJS(merge.mergeFromData);
        merge.mergeFromData = merge.mergeToData;
        merge.mergeToData = tempData;
    }

    const handleMergeConfirm = (event) => {
        const { id } = merge.mergeToData;

        merge.save()
            .then(() => {
                if (fn.isFunction(props.onSuccess)) {
                    props.onSuccess(event, { updated: true, id: id });
                }
            })
            .finally(() => {
                if (isMounted.current) {
                    setConfirmMerge(false);
                }
            })
    }

    const handleMergeCancel = () => {
        if (isMounted.current) {
            setConfirmMerge(false);
        }
    }

    const renderTitle = () => {
        return <div className='popup-title'>
            <div className='actions left-actions pt-1'>
                <ul className='ml-2'>
                    <li>
                        <h4 className='mb-0'>Merging Customer Profiles</h4>
                    </li>
                </ul>
            </div>
            <div className='actions right-actions'>
                <ul>
                    <li>
                        <button
                            type='button'
                            className='btn btn-light px-3'
                            onClick={handleSwap}
                        >
                            Swap
                        </button>
                    </li>
                    <li>
                        <button
                            type='button'
                            className='btn btn-success px-3'
                            onClick={handleSave}
                        >
                            Continue
                        </button>
                    </li>
                    <li>
                        <button
                            type='button'
                            className='btn btn-icon btn-close'
                            onClick={handleClose}
                        >
                            <i className='close-icon fal fa-times fs-xl'></i>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    }

    const renderCustomer = (customer, label, additionalCss) => {
        return <div
            className={'profile-wrapper p-0' + (additionalCss ? ` ${additionalCss}` : '')}
        >
            <div className='profile'>
                <span
                    className={`profile-image profile-initials rounded-circle d-flex text-white ${ch.getProfileColor(customer)} fw-500`}
                    title={customer.fullName}
                >
                    {customer.initials}
                </span>
            </div>
            <div className='description'>
                {label}
                <div className='name'>
                    {ph.getFullName(customer, true)}
                    {
                        customer.dateOfBirth || customer.sex || customer.gender || customer.pronoun ?
                            <small className='text-nowrap ml-2'>({`${ph.getAge(customer.dateOfBirth)} ${ph.getSexGenderPronounDisplay(customer)}`.trim()})</small> : null
                    }
                </div>
                {
                    customer.address && customer.address.country ?
                        <div className='info'>{ah.getAddressHtml(customer.address)}</div> : null
                }
                {
                    customer.emailAddress ?
                        <div className='info'>
                            <a
                                href={`mailto:${customer.emailAddress}`}
                            >{customer.emailAddress}
                            </a>
                        </div> : null
                }
                {
                    customer.phoneNumber ?
                        <div className='info'>
                            <a
                                href={`tel:${customer.phoneNumber}`}
                            >{sys.getFormattedPhoneNumber(customer.phoneNumber)}
                            </a>
                        </div> : null
                }
                {
                    customer.patientProfile && customer.patientProfile.healthCardNumber ?
                        <div className='info'>
                            <div className='mt-1 fw-500'>Health card</div>
                            <span className='mr-1'>{ph.formatHealthCard(customer.patientProfile.healthCardNumber)}</span>
                            {
                                customer.patientProfile.healthCardExpiryDate ?
                                    <small className='text-nowrap'>(Exp. {fn.formatDate(customer.patientProfile.healthCardExpiryDate, 'MMM Do, YYYY')})</small> : null
                            }
                        </div> : null
                }
            </div>
        </div>
    }

    const renderDetail = (label, mergeFromCount, mergeToCount) => {
        return <tr className='fs-lg'>
            <td className='text-right py-0 pr-6'>
                {
                    mergeFromCount > 0 ?
                        <small className='text-danger-700 fs-xs mr-1'>(-{mergeFromCount})</small> : null
                }
                <strong className={(mergeFromCount > 0 ? 'text-gray-700' : 'text-gray-500')}>0</strong>
            </td>
            <td className='text-center px-2 py-0'>
                <span className={(mergeFromCount > 0 ? 'text-gray-700 fw-500' : 'text-gray-500')}>{label}</span>
            </td>
            <td className='text-left py-0 pl-6'>
                <strong className='text-gray-700'>{mergeFromCount + mergeToCount}</strong>
                {
                    mergeFromCount > 0 ?
                        <small className='text-success-700 fs-xs ml-1'>(+{mergeFromCount})</small> : null
                }
            </td>
        </tr>
    }

    return <>
        <Observer>{() =>
            <>
                {
                    props.display ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleClose(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <Observer>{() =>
            <>
                {
                    merge.isReady ?
                        <>
                            <Observer>{() => <LoadingOverlay isLoading={merge.isSaving} />}</Observer>
                            {renderTitle()}
                            <div ref={modalBodyRef}>
                                <FadeIn>
                                    <div className='customer-merge-body p-2'>
                                        <table className='table table-borderless w-100'>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <Observer>{() =>
                                                            <div className='d-flex justify-content-end'>
                                                                {renderCustomer(merge.mergeFromData, <strong className='text-danger-700'>Deactivating...</strong>, 'deactivating')}
                                                            </div>
                                                        }</Observer>
                                                    </td>
                                                    <td className='text-center' style={{ verticalAlign: 'middle' }}>
                                                        <i className='fal fa-chevron-double-right fs-xxl'></i>
                                                    </td>
                                                    <td>
                                                        <Observer>{() =>
                                                            <div className='d-flex justify-content-start'>
                                                                {renderCustomer(merge.mergeToData, <strong className='text-success-700'>Merging into...</strong>)}
                                                            </div>
                                                        }</Observer>
                                                    </td>
                                                </tr>
                                                {renderDetail('Appointments', merge.mergeFromData.appointmentCount, merge.mergeToData.appointmentCount)}
                                                {renderDetail('Exams', merge.mergeFromData.examCount, merge.mergeToData.examCount)}
                                                {renderDetail('Invoices', merge.mergeFromData.purchaseCount, merge.mergeToData.purchaseCount)}
                                                {renderDetail('Work Orders', merge.mergeFromData.workOrderCount, merge.mergeToData.workOrderCount)}
                                                {renderDetail('Prescriptions', merge.mergeFromData.prescriptionCount, merge.mergeToData.prescriptionCount)}
                                                {renderDetail('Referrals', merge.mergeFromData.referralAppointmentCount, merge.mergeToData.referralAppointmentCount)}
                                                {renderDetail('Notes', merge.mergeFromData.noteCount, merge.mergeToData.noteCount)}
                                            </tbody>
                                        </table>
                                    </div>
                                </FadeIn>
                            </div>
                        </> : renderQuickDrawerLoading()
                }
            </>
        }</Observer>
        <Observer>{() =>
            <BodyEnd>
                <ConfirmModal
                    icon={<i className={`${oh.getIcon('customer', 'view')} text-danger mr-2`}></i>}
                    message={<>Continue to <span className='fw-500'>&nbsp;merge these {merge.mergeToData ? ph.getPreferredFirstLastName(merge.mergeToData) : null} profiles together</span>?</>}
                    description={<span className='description warning-danger warning-flashing'><strong>Important:</strong> Merged files cannot be reactivate, and it cannot be undone!</span>}
                    show={confirmMerge}
                    onOption1Click={handleMergeConfirm}
                    onCancel={handleMergeCancel}
                />
            </BodyEnd>
        }</Observer>
    </>
}

export default MergeCustomers;