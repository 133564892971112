import React, { useContext, useEffect, useState, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import FadeIn from 'react-fade-in';
import momentLocalizer from 'react-widgets-moment';
import { GlobalHotKeys } from 'react-hotkeys';
import moment from 'moment';

import BodyEnd from '../../_shared/BodyEnd';
import ConfirmModal from '../../_shared/ConfirmModal';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';

import ScheduleUpdateStore from '../../../../stores/ScheduleUpdateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';
import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as ph from '../../../../utilities/personHelper';
import * as oh from '../../../../utilities/operationHelper';

import './DeleteSchedule.scss';

moment.locale('en');
momentLocalizer();

function DeleteSchedule(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const schedule = useContext(ScheduleUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [confirmDelete, setConfirmDelete] = useState(false);

    useEffect(() => {
        quickDrawerFocus(props.drawer);

        return () => {
            isMounted.current = false;
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (schedule.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleDeleteTypeChange = (event) => {
        var value = event.target.value;
        schedule.deleteType = value;
        schedule.hasUnsavedChanges = true;
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            setConfirmDelete(true);
        }
    }

    const handleConfirmDelete = event => {
        event.preventDefault();
        schedule.delete()
            .then(data => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, { updated: true, data: data });
                    }
                }
            })

        setConfirmDelete(false);
    }

    const handleConfirmCancel = event => {
        event.preventDefault();
        setConfirmDelete(false);

        if (props.onCancel && fn.isFunction(props.onCancel)) {
            props.onCancel(event);
        }
    }

    const renderScheduleDateTime = () => {
        if (!schedule.data.start) return null;

        const start = moment(schedule.data.start);
        const end = schedule.data.end ? moment(schedule.data.end) : null;
        const hasEndTime = !!end;
        const weekday = start.format('dddd');
        const day = start.format('D');
        const ordinal = start.format('Do').replace(day, '');
        const dateHtml = `${start.format('MMMM D')}<sup>${ordinal}</sup>${((start.year() !== moment().year()) ? `, ${start.format('YYYY')}` : '')}`;
        const startTimeHtml = `${start.format('h:mm')}${(!hasEndTime || start.format('a') !== end.format('a') ? ` ${start.format('a')}` : '')}`;
        const endTimeHtml = hasEndTime ? `${end.format('h:mm')} ${end.format('a')}` : '';

        return <ul className='list-inline no-style m-0'>
            <li className='list-inline-item m-0'>
                <div className='text'>
                    <small className='weekday'>{weekday}</small>
                    <span className='date' dangerouslySetInnerHTML={{ __html: dateHtml }}></span>
                </div>
            </li>
            <li className='list-inline-item my-0 mx-1'><small>@</small></li>
            <li className='list-inline-item m-0'>
                <div className='text'>
                    <span className='time' dangerouslySetInnerHTML={{ __html: startTimeHtml }}></span>
                </div>
            </li>
            <li className='list-inline-item my-0 mx-1'><small>to</small></li>
            <li className='list-inline-item m-0'>
                <div className='text'>
                    <span className='time' dangerouslySetInnerHTML={{ __html: endTimeHtml }}></span>
                </div>
            </li>
        </ul>
    }

    const renderUser = () => {
        const { user } = schedule.data;

        return <div
            className='profile-wrapper'
        >
            <div className='profile'>
                <span
                    className={`profile-image rounded-circle fw-500 d-block` + (user && !user.profilePictureUri ? ` profile-initials bg-color${user.color}-500` : '')}
                    style={user && user.profilePictureUri ? {
                        backgroundImage: `url(${user.profilePictureUri})`,
                        backgroundSize: 'cover',
                    } : null}
                    title={user ? user.fullName : 'System'}>
                    {user && !user.profilePictureUri ? <div className='d-initials'>{user.initials}</div> : null}
                </span>
            </div>
            <div className='description'>
                <div className='name'>{user.fullName}</div>
                {
                    user.emailAddress ?
                        <div className='info'>
                            <a
                                href={`mailto:${user.emailAddress}`}
                            >{user.emailAddress}
                            </a>
                        </div> : null
                }
            </div>
        </div>
    }

    return <>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <Observer>{() =>
                <>
                    {
                        (props.drawer === quickDrawer.drawerOpened) && !confirmDelete ?
                            <GlobalHotKeys
                                keyMap={{
                                    close: ['esc'],
                                }}
                                handlers={{
                                    close: event => {
                                        handleCancel(event)
                                    },
                                }}
                                allowChanges={true}
                            /> : null
                    }
                    <fieldset disabled={schedule.isSaving}>
                        <div className='quick-drawer'>
                            <QuickDrawerHeader
                                drawer={props.drawer}
                                icon={oh.getIcon('schedule', 'delete')}
                                action='Delete'
                                category='Schedule'
                                className='delete'
                                onCancel={handleCancel}
                            />
                            <div className='quick-drawer-body'>
                                {
                                    schedule.isReady && schedule.data ?
                                        <FadeIn>
                                            <div className='delete-schedule body-content'>
                                                <section className='resource'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-0'>
                                                                <label><small>For</small></label>
                                                                {renderUser()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section className='date-time'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            {renderScheduleDateTime()}
                                                        </div>
                                                    </div>
                                                </section>
                                                <Observer>{() =>
                                                    <section>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className='form-group validate validate-required'>
                                                                    <label className='required'><small>Deleting</small></label>
                                                                    <Observer>{() =>
                                                                        <>
                                                                            <div className='custom-control custom-radio mb-2'>
                                                                                <input
                                                                                    id='deleteThisSchedule'
                                                                                    type='radio'
                                                                                    name='deleteScheduleType'
                                                                                    value='ThisOneOnly'
                                                                                    className='custom-control-input'
                                                                                    checked={schedule.deleteType === 'ThisOneOnly'}
                                                                                    onChange={handleDeleteTypeChange}
                                                                                />
                                                                                <label
                                                                                    htmlFor='deleteThisSchedule'
                                                                                    className='custom-control-label'
                                                                                >
                                                                                    This work day only
                                                                                </label>
                                                                            </div>
                                                                            <div className='custom-control custom-radio mb-2'>
                                                                                <input
                                                                                    id='deleteDayOfWeekSchedules'
                                                                                    type='radio'
                                                                                    name='deleteScheduleType'
                                                                                    value='DayOfWeek'
                                                                                    className='custom-control-input'
                                                                                    checked={schedule.deleteType === 'DayOfWeek'}
                                                                                    onChange={handleDeleteTypeChange}
                                                                                />
                                                                                <label
                                                                                    htmlFor='deleteDayOfWeekSchedules'
                                                                                    className='custom-control-label'
                                                                                >
                                                                                    This and all following {schedule.date.format('dddd')} schedule
                                                                                </label>
                                                                            </div>
                                                                            <div className='custom-control custom-radio mb-2'>
                                                                                <input
                                                                                    id='deleteFromNowSchedules'
                                                                                    type='radio'
                                                                                    name='deleteScheduleType'
                                                                                    value='FromNow'
                                                                                    className='custom-control-input'
                                                                                    checked={schedule.deleteType === 'FromNow'}
                                                                                    onChange={handleDeleteTypeChange}
                                                                                />
                                                                                <label
                                                                                    htmlFor='deleteFromNowSchedules'
                                                                                    className='custom-control-label'
                                                                                >
                                                                                    Every day from now on
                                                                                </label>
                                                                            </div>
                                                                        </>
                                                                    }</Observer>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                }</Observer>
                                            </div>
                                        </FadeIn> : renderQuickDrawerLoading()
                                }
                            </div>
                            <Observer>{() =>
                                schedule.isReady ?
                                    <div className='quick-drawer-action'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='float-right'>
                                                    <button
                                                        type='button'
                                                        className='btn btn-link btn-cancel mr-2'
                                                        onClick={props.onCancel}
                                                    >Cancel</button>
                                                    <Observer>{() =>
                                                        <button
                                                            type='submit'
                                                            className='btn btn-danger'
                                                            disabled={!schedule.deleteType}
                                                        >Delete</button>
                                                    }</Observer>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : null
                            }</Observer>
                        </div>
                    </fieldset>
                </>
            }</Observer>
        </form>
        <BodyEnd>
            <ConfirmModal
                icon={<i className='fal fa-calendar-times text-danger mr-2'></i>}
                message={<>Continue to delete <span className='fw-500'>&nbsp;{schedule.data ? ph.getPreferredFirstName(schedule.data.user) : ''}'s schedule</span>? </>}
                show={confirmDelete}
                onOption1Click={handleConfirmDelete}
                onCancel={handleConfirmCancel}
            />
        </BodyEnd>
    </>
}

export default DeleteSchedule;