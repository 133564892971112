import { createContext } from 'react';
import { decorate, observable, action } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class ServiceOverrideUpdate {
    id = null;
    data = null;
    types = [];
    options = [];
    hasUnsavedChanges = false;
    isLoading = false;
    isSaving = false;
    isReady = false;
    serviceName = '';
    
    cancelServiceOverrideDelete = null;
    cancelServiceOverrideGet = null;
    cancelServiceOverrideOptions = null;
    cancelServiceOverrideTypes = null;
    cancelServiceOverrideUpdate = null;

    initialize = async (id) => {
        this.clear();
        this.id = id; // id = Service Id
        this.isReady = false;
        await this.refresh();
    }

    refresh = async () => {
        this.isLoading = true;

        try {
            const { data } = await api.ServiceUserOverrides.get(this.id, (c) => { this.cancelServiceOverrideGet = c });
            this.data = data;
            this.isReady = true;
        }
        finally {
            this.isLoading = false;
        }
    }

    save = async (notify) => {
        if (!!notify) {
            this.isSaving = true;
        }

        if (this.hasUnsavedChanges) {
            try {
                await api.ServiceUserOverrides.update(this.id, this.data, (c) => { this.cancelServiceOverrideGet = c });
                return this.data;
            }
            finally {
                this.isSaving = false;
            }
        } 
        this.isSaving = false;
    }

    clear = () => {
        this.id = null;
        this.data = null;
        this.types.clear();
        this.options.clear();
        this.hasUnsavedChanges = false;
        this.isLoading = false;
        this.isSaving = false;
        this.isReady = false;

        if (fn.isFunction(this.cancelServiceOverrideDelete)) {
            this.cancelServiceOverrideDelete();
            this.cancelServiceOverrideDelete = null;
        }

        if (fn.isFunction(this.cancelServiceOverrideGet)) {
            this.cancelServiceOverrideGet();
            this.cancelServiceOverrideGet = null;
        }

        if (fn.isFunction(this.cancelServiceOverrideOptions)) {
            this.cancelServiceOverrideOptions();
            this.cancelServiceOverrideOptions = null;
        }

        if (fn.isFunction(this.cancelServiceOverrideTypes)) {
            this.cancelServiceOverrideTypes();
            this.cancelServiceOverrideTypes = null;
        }

        if (fn.isFunction(this.cancelServiceOverrideUpdate)) {
            this.cancelServiceOverrideUpdate();
            this.cancelServiceOverrideUpdate = null;
        }
    }
}

decorate(ServiceOverrideUpdate, {
    id: observable,
    data: observable,
    types: observable,
    options: observable,
    hasUnsavedChanges: observable,
    isLoading: observable,
    isSaving: observable,
    isReady: observable,
    serviceName: observable,
    initialize: action,
    refresh: action,
    save: action,
    clear: action,
})

export default createContext(new ServiceOverrideUpdate());