import { createContext } from 'react';
import { decorate, observable, action, toJS, computed } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class PrescriptionPrintTemplateUpdate {
    id = null;
    data = null;
    isSaving = false;
    isLoading = false;
    isReady = false;
    hasUnsavedChanges = false;

    cancelPrescriptionPrintPublishedTemplateCreate = null;
    cancelPrescriptionPrintTemplate = null;
    cancelPrescriptionPrintTemplateUpdate = null;

    initialize = (id) => {
        const that = this;
        this.clear();
        this.isLoading = true;

        return new Promise((resolve) => {
            api.PrescriptionPrintTemplates.get(id, (c) => { that.cancelPrescriptionPrintTemplate = c })
                .then(({ data }) => {
                    that.id = data.id;
                    that.data = data;
                })
                .finally(() => {
                    that.isLoading = false;
                    that.isReady = true;
                    resolve();
                })
        })
    }

    sortDefinition = () => {
        if (this.data && this.data.headerDefinition && this.data.headerDefinition.length > 0) {
            const headerDefinition = toJS(this.data.headerDefinition)
                .sort((a, b) => {
                    if (a.position.y === b.position.y) {
                        return a.position.x > b.position.x ? 1 : -1;
                    }

                    return a.position.y > b.position.y ? 1 : -1;
                })

            this.data.headerDefinition = headerDefinition;
        }

        if (this.data && this.data.bodyDefinition && this.data.bodyDefinition.length > 0) {
            const bodyDefinition = toJS(this.data.bodyDefinition)
                .sort((a, b) => {
                    if (a.position.y === b.position.y) {
                        return a.position.x > b.position.x ? 1 : -1;
                    }

                    return a.position.y > b.position.y ? 1 : -1;
                })

            this.data.bodyDefinition = bodyDefinition;
        }

        if (this.data && this.data.footerDefinition && this.data.footerDefinition.length > 0) {
            const footerDefinition = toJS(this.data.footerDefinition)
                .sort((a, b) => {
                    if (a.position.y === b.position.y) {
                        return a.position.x > b.position.x ? 1 : -1;
                    }

                    return a.position.y > b.position.y ? 1 : -1;
                })

            this.data.footerDefinition = footerDefinition;
        }
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            if (that.hasUnsavedChanges) {
                that.sortDefinition();
                let option = toJS(that.data);

                if (that.hasUnsavedChanges) {
                    api.PrescriptionPrintTemplates.update(that.id, option, (c) => { that.cancelPrescriptionPrintTemplateUpdate = c })
                        .then(() => {
                            that.hasUnsavedChanges = false;
                            resolve(option);
                        })
                        .catch(() => {
                            reject();
                        })
                        .finally(() => {
                            that.isSaving = false;
                        })
                } else {
                    that.hasUnsavedChanges = false;
                    that.isSaving = false;
                    resolve(option);
                }
            }
        })
    }

    publish = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            api.PrescriptionPrintPublishedTemplates.create({ prescriptionPrintTemplateId: that.id }, (c) => { that.cancelPrescriptionPrintPublishedTemplateCreate = c })
                .then(() => {
                    that.hasUnsavedChanges = false;
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    clear = () => {
        this.id = null;
        this.data = null;
        this.isReady = false;
        this.isSaving = false;
        this.isLoading = false;
        this.isReady = false;
        this.hasUnsavedChanges = false;

        if (fn.isFunction(this.cancelPrescriptionPrintPublishedTemplateCreate)) {
            this.cancelPrescriptionPrintPublishedTemplateCreate();
            this.cancelPrescriptionPrintPublishedTemplateCreate = null;
        }

        if (fn.isFunction(this.cancelPrescriptionPrintTemplate)) {
            this.cancelPrescriptionPrintTemplate();
            this.cancelPrescriptionPrintTemplate = null;
        }

        if (fn.isFunction(this.cancelPrescriptionPrintTemplateUpdate)) {
            this.cancelPrescriptionPrintTemplateUpdate();
            this.cancelPrescriptionPrintTemplateUpdate = null;
        }
    }

    get isNotFound() {
        return this.isReady && !this.data;
    }
}

decorate(PrescriptionPrintTemplateUpdate, {
    id: observable,
    data: observable.deep,
    isReady: observable,
    isSaving: observable,
    isLoading: observable,
    hasUnsavedChanges: observable,
    initialize: action,
    save: action,
    publish: action,
    sortDefinition: action,
    clear: action,
    isNotFound: computed,
})

export default createContext(new PrescriptionPrintTemplateUpdate());