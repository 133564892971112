import React, { Component } from 'react';
import { Popup } from 'devextreme-react';

import MergeCustomers from './Merge';

import * as fn from '../../../../utilities/_functions';

import './_index.scss';

const CUSTOMER_MERGE_POPUP_ID = '__customer-merge-popup';
const CUSTOMER_MERGE_MODAL_GRID_WIDTH = 900;
const CUSTOMER_MERGE_MODAL_GRID_HEIGHT = 450;

export default class CustomerMergeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            display: false,
            zIndex: null,
        };
        this.handleClose = this.handleClose.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
        this.renderContent = this.renderContent.bind(this);
    }

    show = () => {
        const zIndex = fn.getHighestZIndex() + 1;
        this.setState({ display: true, zIndex: zIndex });
    }

    close = () => {
        this.handleClose();
    }

    handleShowing = event => {
        const popup = document.getElementById(CUSTOMER_MERGE_POPUP_ID);
        if (popup) {
            // const { zIndex } = this.state;
            // popup.style.zIndex = zIndex;
        }
    }

    handleClose = event => {
        const popup = document.getElementById(CUSTOMER_MERGE_POPUP_ID);

        if (popup) {
            // popup.style.zIndex = null;
        }

        this.setState({ display: false, zIndex: null });

        if (fn.isFunction(this.props.onClose)) {
            this.props.onClose();
        }
    }

    handleSuccess = (event, result) => {
        if (fn.isFunction(this.props.onSuccess)) {
            this.props.onSuccess(event, result);
        }
    }

    renderTitle = () => {
        return <div className='popup-title-draggable'></div>
    }

    renderContent = () => {
        const contentProps = {
            display: this.state.display,
            onSuccess: this.handleSuccess,
            onClose: this.handleClose,
        };

        return <MergeCustomers {...contentProps} />;
    }

    render() {
        return <>{
            <Popup
                wrapperAttr={{ id: CUSTOMER_MERGE_POPUP_ID, class: 'dx-popup-customer-merge' }}
                animation={{
                    show: {
                        type: 'pop',
                        duration: 200,
                        from: { opacity: 0, scale: 0 },
                        to: { opacity: 1, scale: 1 }
                    },
                    hide: {
                        type: 'pop',
                        duration: 200,
                        from: { opacity: 1, scale: 1 },
                        to: { opacity: 0, scale: 0 }
                    }
                }}
                visible={this.state.display}
                shading={true}
                shadingColor="rgba(0, 0, 0, 0.2)"
                width={CUSTOMER_MERGE_MODAL_GRID_WIDTH}
                height={CUSTOMER_MERGE_MODAL_GRID_HEIGHT}
                dragEnabled={true}
                dragOutsideBoundary={true}
                showTitle={true}
                titleRender={this.renderTitle}
                contentRender={this.renderContent}
                onShowing={this.handleShowing}
                onShown={this.handleShowing}
                onHiding={this.handleClose}
            />
        }</>
    }
}