import React, { Component } from 'react';
import { Observer } from 'mobx-react-lite';
import { Popup } from 'devextreme-react';

import PublicInsuranceVerification from './Verification';

import PublicInsuranceVerificationModalStore from '../../../../stores/PublicInsuranceVerificationModalStore';

import * as fn from '../../../../utilities/_functions';

import './_index.scss';

const PUBLIC_INSURANCE_VERIFICATION_POPUP_ID = '__public-insurance-verification-popup';
const PUBLIC_INSURANCE_VERIFICATION_MODAL_GRID_WIDTH = 600;

export default class PublicInsuranceVerificationModal extends Component {
    static contextType = PublicInsuranceVerificationModalStore;

    constructor(props) {
        super(props);
        this.popupRef = React.createRef();
        this.handleClose = this.handleClose.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
        this.renderContent = this.renderContent.bind(this);
    }

    show = () => {
        if (this.popupRef.current) {
            const { instance } = this.popupRef.current;

            if (instance) {
                instance.show();
            }
        }
    }

    close = () => {
        this.handleClose();
    }

    handleClose = event => {
        if (this.popupRef.current) {
            const { instance } = this.popupRef.current;

            if (instance && this.context.isActivated) {
                instance.hide();
                this.context.isActivated = false;

                if (fn.isFunction(this.props.onClose)) {
                    this.props.onClose();
                }
            }
        }
    }

    handleSuccess = (event, result) => {
        if (fn.isFunction(this.props.onSuccess)) {
            this.props.onSuccess(event, result);
        }
    }

    renderTitle = () => {
        return <div className='popup-title-draggable'></div>
    }

    renderContent = () => {
        const contentProps = {
            show: this.show,
            close: this.close,
            onSuccess: this.handleSuccess,
            onClose: this.handleClose,
        };

        return <PublicInsuranceVerification {...contentProps} />;
    }

    render() {
        return <>
            <Observer>{() =>
                <>{
                    this.context.isActivated ? <>{this.show()}</> : <>{this.close()}</>
                }</>
            }</Observer>
            {
                <Popup
                    ref={this.popupRef}
                    wrapperAttr={{ id: PUBLIC_INSURANCE_VERIFICATION_POPUP_ID, class: 'dx-popup-public-insurance-verification' }}
                    animation={{
                        show: {
                            type: 'pop',
                            duration: 200,
                            from: { opacity: 0, scale: 0 },
                            to: { opacity: 1, scale: 1 }
                        },
                        hide: {
                            type: 'pop',
                            duration: 200,
                            from: { opacity: 1, scale: 1 },
                            to: { opacity: 0, scale: 0 }
                        }
                    }}
                    shading={true}
                    shadingColor="rgba(0, 0, 0, 0.2)"
                    width={PUBLIC_INSURANCE_VERIFICATION_MODAL_GRID_WIDTH}
                    height={'auto'}
                    dragEnabled={true}
                    dragOutsideBoundary={false}
                    showTitle={true}
                    titleRender={this.renderTitle}
                    contentRender={this.renderContent}
                    onHiding={this.handleClose}
                />
            }</>
    }
}