import { createContext } from 'react';
import { decorate, observable, action, computed, toJS } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';
import * as pth from '../utilities/printTemplateHelper';

export class ExamTemplateView {
    id = null;
    data = null;
    selectedSectionId = null;
    isReady = false;
    isSaving = false;
    isLoading = false;
    hasUnsavedChanges = false;

    cancelExamPublishedTemplatePublish = null;
    cancelExamTemplateGet = null;
    cancelExamTemplateUpdate = null;

    load = id => {
        const that = this;

        this.clear();
        this.id = id;

        return new Promise((resolve, reject) => {
            that.refresh()
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isReady = true;
                });
        })
    }

    refresh = () => {
        const that = this;
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            api.ExamTemplates.get(
                that.id,
                (c) => { that.cancelExamTemplateGet = c }
            )
                .then(({ data }) => {
                    that.data = data;
                    that.selectedSectionId = that.data.sections[0].id;
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isLoading = false;
                })
        })
    }

    addSection = (name, roles, isHiddenWhenEmpty) => {
        const that = this;

        const newSection = {
            id: fn.newId('exsc_'),
            name: name,
            displayOrder: that.data.sections.length,
            definition: [],
            roles: roles ? roles : [],
            pageSettings: {
                paper: pth.getPaperDimension('letter'),
                margins: pth.PRINT_TEMPLATE_PAGE_SETTINGS_MARGINS_NORMAL,
                orientation: 'Portrait',
                header: 0,
                footer: 0,
            },
            isHiddenWhenEmpty: isHiddenWhenEmpty,
        };

        this.data.sections.push(newSection);
        this.selectedSectionId = newSection.id;
        this.hasUnsavedChanges = true;

        return Promise.resolve();
    }

    updateSection = (id, name, roles, isHiddenWhenEmpty) => {
        for (let i = 0; i < this.data.sections.length; i++) {
            if (this.data.sections[i].id === id) {
                this.data.sections[i].name = name;
                this.data.sections[i].roles = roles;
                this.data.sections[i].isHiddenWhenEmpty = isHiddenWhenEmpty;
                this.hasUnsavedChanges = true;
            }
        }

        return Promise.resolve();
    }

    removeSection = (id) => {
        const index = this.data.sections.findIndex(s => s.id === id);

        if (index >= 0) {
            this.data.sections.splice(index, 1);
            this.selectedSectionId = this.data.sections[0].id;
            this.hasUnsavedChanges = true;
        }

        return Promise.resolve();
    }

    sortDefinition = () => {
        if (this.data && this.data.sections && this.data.sections.length > 0) {
            for (let i = 0; i < this.data.sections.length; i++) {
                if (this.data.sections[i] && this.data.sections[i].definition && this.data.sections[i].definition.length > 0) {
                    const definition = toJS(this.data.sections[i].definition)
                        .sort((a, b) => {
                            if (a.position.y === b.position.y) {
                                return a.position.x > b.position.x ? 1 : -1;
                            }

                            return a.position.y > b.position.y ? 1 : -1;
                        })

                    this.data.sections[i].definition = definition;
                }
            }
        }
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            if (that.hasUnsavedChanges) {
                that.sortDefinition();
                let option = toJS(that.data);

                api.ExamTemplates.update(
                    that.id,
                    option,
                    (c) => { that.cancelExamTemplateUpdate = c }
                )
                    .then(() => {
                        that.hasUnsavedChanges = false;
                        resolve(option);
                    })
                    .catch(() => {
                        reject();
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                that.isSaving = false;
                resolve();
            }
        })
    }

    publish = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            api.ExamPublishedTemplates.create(
                { examTemplateId: that.id },
                (c) => { that.cancelExamPublishedTemplatePublish = c }
            )
                .then(({ data }) => {
                    that.hasUnsavedChanges = false;
                    resolve(data);
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }


    clear = () => {
        this.id = null;
        this.data = null;
        this.selectedSectionId = null;
        this.isReady = false;
        this.isSaving = false;
        this.isLoading = false;
        this.hasUnsavedChanges = false;

        if (fn.isFunction(this.cancelExamPublishedTemplatePublish)) {
            this.cancelExamPublishedTemplatePublish();
            this.cancelExamPublishedTemplatePublish = null;
        }

        if (fn.isFunction(this.cancelExamTemplateGet)) {
            this.cancelExamTemplateGet();
            this.cancelExamTemplateGet = null;
        }

        if (fn.isFunction(this.cancelExamTemplateUpdate)) {
            this.cancelExamTemplateUpdate();
            this.cancelExamTemplateUpdate = null;
        }
    }

    get selectedSection() {
        return this.data && this.data.sections ?
            this.data.sections.filter(s => s.id === this.selectedSectionId)[0] : null;
    }

    get sortedSections() {
        if (!this.data || !this.data.sections) return [];

        const sections = toJS(this.data.sections);
        const sortedSections = sections.sort((a, b) => { return a.displayOrder - b.displayOrder });

        return sortedSections;
    }

    get isNotFound() {
        return this.isReady && !this.data;
    }
}

decorate(ExamTemplateView, {
    id: observable,
    data: observable.deep,
    selectedSectionId: observable,
    isReady: observable,
    isSaving: observable,
    isLoading: observable,
    hasUnsavedChanges: observable,
    addSection: action,
    updateSection: action,
    removeSection: action,
    load: action,
    refresh: action,
    save: action,
    clear: action,
    selectedSection: computed,
    sortedSections: computed,
    isNotFound: computed,
})

export default createContext(new ExamTemplateView());