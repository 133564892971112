import { createContext } from 'react';
import { decorate, observable, action, computed } from 'mobx';

import * as fn from '../utilities/_functions';

import api from '../api';
import moment from 'moment';

const PAGE_SIZE = 25;

export class NotificationSearchStore {
    page = 1;
    items = [];
    total = null;
    userId = null;
    isRead = null;
    isExpired = null;
    sortField = null;
    sortDirection = 'ASC';
    isLoading = false;
    isSaving = false;
    isReady = false;

    cancelNotificationSearch = null;
    cancelNotificationRead = null;
    cancelNotificationExpire = null;

    initialize = async (userId, isRead, isExpired = false, sortField = 'CreatedDateUtc', sortDirection = 'DESC') => {
        this.clear();

        this.items.clear();
        this.total = null;
        this.userId = userId;
        this.isRead = isRead;
        this.isExpired = isExpired;

        if (sortField) {
            this.sortField = sortField;
        }

        if (sortDirection) {
            this.sortDirection = sortDirection
        }

        await this.refresh();
        this.isReady = true;
    }

    execute = async (page, notify = true) => {
        const that = this;
        const offset = page ? ((page - 1) * PAGE_SIZE) : 0;

        this.page = page;

        if (notify) {
            this.isLoading = true;
        }

        const { data } = await api.Notifications.search({
            parameters: that.getParameters(),
            sortByFields: that.getSortByFields(),
            offset: offset,
            limit: PAGE_SIZE,
            includeTotalCount: true,
        }, (c) => { that.cancelNotificationSearch = c });

        action(e => {
            that.items = data.result && data.result.length > 0 ? data.result : [];
            that.total = data.total ? data.total : 0;
        })();

        if (notify) {
            this.isLoading = false;
        }
    }

    refresh = async () => {
        return await this.execute(this.page);
    }

    read = async (id, notify = false) => {
        if (notify) {
            this.isSaving = true;
        }
        await api.Notifications.read(id, (c) => { this.cancelNotificationRead = c });
        this.isSaving = false;
    }

    expire = async (id, notify = false) => {
        if (notify) {
            this.isSaving = true;
        }

        const index = this.items.findIndex(i => i.id === id);

        if (index >= 0) {
            this.items.splice(index, 1);
        }

        await api.Notifications.expire(id, (c) => { this.cancelNotificationExpire = c });
        this.isSaving = false;
    }

    clear = () => {
        this.page = 1;
        this.items.clear();
        this.total = null;
        this.userId = null;
        this.isRead = null;
        this.isExpired = false;
        this.sortField = null;
        this.sortDirection = 'ASC';
        this.isLoading = false;
        this.isSaving = false;
        this.isReady = false;

        if (fn.isFunction(this.cancelNotificationSearch)) {
            this.cancelNotificationSearch();
            this.cancelTodoInstanceSearch = null;
        }

        if (fn.isFunction(this.cancelNotificationRead)) {
            this.cancelNotificationRead();
            this.cancelNotificationRead = null;
        }

        if (fn.isFunction(this.cancelNotificationExpire)) {
            this.cancelNotificationExpire();
            this.cancelNotificationExpire = null;
        }
    }

    getParameters = () => {
        const parameters = [];

        parameters.push({
            field: 'UserId',
            value: this.userId,
        });

        if (!fn.isNullOrUndefined(this.isRead)) {
            parameters.push({
                field: 'ReadDateUtc',
                value: null,
                operator: (this.isRead ? '!=' : '==')
            })
        }

        if (!fn.isNullOrUndefined(this.isExpired)) {
            parameters.push({
                field: 'ExpiryDateUtc',
                value: moment.utc(),
                operator: (this.isExpired ? '<=' : '>')
            })
        }

        return parameters;
    }

    getSortByFields = () => {
        const sortByFields = [];

        sortByFields.push({
            field: 'IsHighPriority',
            direction: 'DESC',
        });

        sortByFields.push({
            field: this.sortField,
            direction: this.sortDirection,
        });

        return sortByFields;
    }

    get pageIndex() {
        return this.page - 1;
    }

    get pageCount() {
        return Math.ceil(this.total / PAGE_SIZE);
    }

    get hasUnread() {
        return this.items && this.items.length > 0 && this.items.some(i => !i.isRead);
    }

    get hasHighPriority() {
        return this.items && this.items.length > 0 && this.items.some(i => !i.isHighPriority);
    }
}

decorate(NotificationSearchStore, {
    page: observable,
    items: observable,
    total: observable,
    userId: observable,
    isRead: observable,
    isExpired: observable,
    sortField: observable,
    sortDirection: observable,
    isLoading: observable,
    isSaving: observable,
    isReady: observable,
    initialize: action,
    execute: action,
    refresh: action,
    read: action,
    expire: action,
    clear: action,
    pageIndex: computed,
    pageCount: computed,
    hasUnread: computed,
    hasHighPriority: computed,
})

export { PAGE_SIZE };
export default createContext(new NotificationSearchStore());