import React, { Component } from 'react';
import { ToastContainer, cssTransition } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

export default class ToastNotification extends Component {
    render() {
        return (
            <ToastContainer
                className='toastify-wrapper'
                toastClassName='toastify-toast'
                bodyClassName='toastify-body'
                progressClassName='toastify-progress'
                position='bottom-left'
                autoClose={5000}
                transition={cssTransition({
                    enter: 'fadeIn',
                    exit: 'fadeOut',
                    duration: 300,
                    appendPosition: false,
                    collapse: true,
                    collapseDuration: 300
                })}
                style={{ zIndex: 2147000000 }} // 2147483647 is max z-index
                hideProgressBar={true}
                newestOnTop={true}
                closeOnClick={false}
                rtl={false}
                draggable={false}
                closeButton={true}
                pauseOnVisibilityChange
                pauseOnHover
            />
        )
    }
}
