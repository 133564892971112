import React, { useContext, useEffect, useRef, useState } from 'react';
import { Observer } from 'mobx-react-lite';
import FadeIn from 'react-fade-in';
import Dropzone from 'react-dropzone';
import TimeAgo from 'react-timeago';
import { toast } from 'react-toastify';
import { GlobalHotKeys } from 'react-hotkeys';
import moment from 'moment';

import BodyEnd from '../../_shared/BodyEnd';
import LoadingOverlay from '../../_shared/LoadingOverlay';
import HtmlEditor from '../../_shared/HtmlEditor';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import { renderQuickDrawerLoading } from '../../_shared/QuickDrawer';

import AuthStore from '../../../../stores/AuthStore';
import NoteCreateStore from '../../../../stores/NoteCreateStore';
import NoteSearchStore from '../../../../stores/NoteSearchStore';
import FileViewerStore from '../../../../stores/FileViewerStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as fn from '../../../../utilities/_functions';
import * as ph from '../../../../utilities/personHelper';
import * as uh from '../../../../utilities/userHelper';
import * as oh from '../../../../utilities/operationHelper';
import * as sys from '../../../../utilities/systemHelper';
import * as nh from '../../../../utilities/noteHelper';

import './ViewAttachments.scss';

function Attachments(props) {
    const isMounted = useRef(true);
    const renderTimer = useRef(null);
    const editorTimer = useRef(null);
    const bodyRef = useRef(null);
    const newDropzoneRef = useRef(null);
    const newEditorRef = useRef(null);
    const newFileNameRef = useRef([]);
    const newNoteBodyHtml = useRef(null);
    const newNoteBodyPreview = useRef(null);
    const auth = useContext(AuthStore);
    const newNote = useContext(NoteCreateStore);
    const noteSearch = useContext(NoteSearchStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const fileViewer = useContext(FileViewerStore);
    const [isRendered, setIsRendered] = useState(false);

    useEffect(() => {
        renderTimer.current = setTimeout(() => {
            if (!isRendered) {
                if (bodyRef.current) {
                    bodyRef.current.scrollTop = 99999;
                }
                setIsRendered(true);
            }
        }, 500)
        return () => {
            isMounted.current = false;
            newNoteBodyHtml.current = null;
            newNoteBodyPreview.current = null;
            if (renderTimer.current) { clearTimeout(renderTimer.current); renderTimer.current = null; }
            if (editorTimer.current) { clearTimeout(editorTimer.current); editorTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleFilesDrop = (files) => {
        files.forEach((file) => {
            const reader = new FileReader();
            const uri = URL.createObjectURL(file);

            reader.onload = event => { handleFileReaderLoad(event, files, file, uri, handleMultiFilesRead) };
            reader.readAsDataURL(file);
        })

        newFileNameRef.current = newFileNameRef.current.slice(0, files.length);
    }

    const handleFileReaderLoad = (event, files, file, uri, callback) => {
        const reader = event.target;
        const base64 = reader.result;
        callback(files, file, uri, base64);
    }

    const handleMultiFilesRead = (files, file, uri, base64) => {
        if (noteSearch.customerId) {
            noteSearch.newNote.attachments.push({
                extension: file.name.substring(file.name.lastIndexOf('.')),
                fileName: file.name,
                size: file.size,
                mimeType: file.type,
                base64: base64,
                uri: uri,
                isLoaded: true,
            });

            if (files.length === noteSearch.newNote.attachments.length) {
                editorTimer.current = setTimeout(() => {
                    const instance = newFileNameRef.current[0].getInstance();
                    instance.setSelection(0, 9999);
                    instance.focus();
                }, 100)
            }
        } else {
            newNote.data.attachments.push({
                extension: file.name.substring(file.name.lastIndexOf('.')),
                fileName: file.name,
                size: file.size,
                mimeType: file.type,
                base64: base64,
                uri: uri,
                isLoaded: true,
            });

            if (files.length === newNote.data.attachments.length) {
                editorTimer.current = setTimeout(() => {
                    const instance = newFileNameRef.current[0].getInstance();
                    instance.setSelection(0, 9999);
                    instance.focus();
                }, 100)
            }
        }
    }

    const handleAttachmentFileNameChange = (e, attachment, index) => {
        const fileName = `${fn.stripHtml(e.value).trim().replace(attachment.extension, '')}${attachment.extension}`;
        noteSearch.newNote.attachments[index].fileName = fileName;
    }

    const handleAttachmentRemove = (event, attachment) => {
        event.stopPropagation();

        if (noteSearch.customerId) {
            noteSearch.newNote.attachments.remove(attachment);
        } else {
            newNote.data.attachments.remove(attachment);
        }
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (noteSearch.newNote.attachments && noteSearch.newNote.attachments.length > 0) {
            const totalSize = noteSearch.newNote.attachments.map(a => { return parseInt(a.size, 10) }).reduce((a, b) => a + b, 0);
            const hasInvalidAttachments = noteSearch.newNote.attachments.some(a => a.size <= 0);

            noteSearch.newNote.bodyHtml = newNoteBodyHtml.current;
            noteSearch.newNote.preview = newNoteBodyPreview.current;
            newNoteBodyHtml.current = null;
            newNoteBodyPreview.current = null;

            if (totalSize > nh.MAX_UPLOAD_SIZE) {
                toast.error(() => <p><strong>Error:</strong> Exceeded the maximum upload size of {fn.toFileSize(nh.MAX_UPLOAD_SIZE, true)}.</p>, { position: 'top-center' });
            }
            if (hasInvalidAttachments) {
                toast.error(() => <p><strong>Error:</strong> Corrupted files detected.  Please verify these files and try again.</p>, { position: 'top-center' });
            }
            else {
                noteSearch.save(true)
                    .then(() => {
                        if (isMounted.current) {
                            if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                                props.onSuccess(event);
                            }
                        }
                    })
            }
        } else {
            if (props.onCancel && fn.isFunction(props.onCancel)) {
                props.onCancel(event);
            }
        }
    }

    const handleAttachmentPreview = (event, attachment) => {
        if (!attachment || attachment.size <= 0) return;

        const attachments = (noteSearch.customer && noteSearch.customer.notes && noteSearch.customer.notes.some(n => n.attachments && n.attachments.length > 0) ?
            noteSearch.customer.notes.filter(n =>
                n.attachments && n.attachments.length > 0).map(n => {
                    return n.attachments.map(a => { return a })
                }) : []).flat();
        const index = attachments.findIndex(a => a.id === attachment.id);

        fileViewer.initialize(
            () => {
                return noteSearch.customer ? <div className='fs-lg'>
                    {ph.getPreferredFirstLastName(noteSearch.customer)}
                    <small className='d-block text-gray-700'>View attachment(s)</small>
                </div> : null
            },
            'attachment',
            attachments,
            index,
            noteSearch.customer.id
        );
    }

    const handleNewAttachmentPreview = (event, attachment) => {
        fileViewer.initialize(
            () => {
                return noteSearch.customer ? <div className='fs-lg'>
                    {ph.getPreferredFirstLastName(noteSearch.customer)}
                    <small className='d-block text-gray-700'>View attachment(s)</small>
                </div> : null
            },
            'attachment',
            [attachment],
            0,
            noteSearch.customer.id
        );
    }

    const handleMessageChange = (e) => {
        const html = e.value;
        const preview = fn.stripHtml(html).trim().substring(0, 200);
        const bodyHtml = (html === '<p></p>') || (html === '<p><br></p>') ? '' : html;

        newNoteBodyHtml.current = bodyHtml;
        newNoteBodyPreview.current = preview;
    }

    const handleAddMoreAttachments = (e) => {
        newDropzoneRef.current.open();
    }

    const renderExistingAttachments = () => {
        const existingAttachmentNotes = getExistingCustomerAttachmentNotes();
        return <>
            {
                existingAttachmentNotes.length > 0 ?
                    existingAttachmentNotes.map((n, ni) => {
                        return <div key={`note-${ni}`} className={'d-flex flex-column align-items-center'}>
                            <div className='d-flex flex-row w-100 py-4'>
                                <div className='d-inline-block align-middle mr-3'>
                                    <span
                                        className={'profile-image profile-image-md rounded-circle d-block' + (n.resource && !n.resource.profilePictureUri ? ` profile-initials text-white bg-color${n.resource.color}-500` : ' mt-1')}
                                        style={!n.resource || n.resource.profilePictureUri ? {
                                            backgroundImage: `url(${(n.resource && n.resource.profilePictureUri ? n.resource.profilePictureUri : '/media/img/default-genki.jpg')})`,
                                            backgroundSize: 'cover',
                                        } : null}
                                        title={n.resource ? n.resource.fullName : 'System'}>
                                        {n.resource && !n.resource.profilePictureUri ? <div className='d-initials fs-xs'>{n.resource.initials}</div> : null}
                                        <span className={`badge badge-icon${(n.referenceId === noteSearch.referenceId ? '' : ` bg-${n.filterType}-900`)}`}></span>
                                    </span>
                                </div>
                                <div className='mb-0 flex-1 text-dark'>
                                    <div className='d-flex'>
                                        <span className='text-gray-800 fw-500'>
                                            {n.resource ? uh.getDisplayShortName(n.resource) : 'System'}
                                        </span>
                                        {
                                            moment().startOf('day').isSame(moment.utc(n.createdDateUtc).local().startOf('day')) ?
                                                <TimeAgo
                                                    className='text-muted fs-xs opacity-70 ml-auto'
                                                    date={moment.utc(n.createdDateUtc).local().toDate()}
                                                    title={sys.getFormattedLongDate(moment.utc(n.createdDateUtc).local(), true)}
                                                    minPeriod={60}
                                                /> :
                                                <span
                                                    className='text-muted fs-xs opacity-70 ml-auto'
                                                    title={sys.getFormattedLongDate(moment.utc(n.createdDateUtc).local(), true)}
                                                >
                                                    {sys.getFormattedShortDate(moment.utc(n.createdDateUtc).local())}
                                                </span>
                                        }
                                    </div>
                                    {
                                        n.bodyHtml ?
                                            <div
                                                className={'html mt-1 ' + (n.attachments && n.attachments.length > 0 ? 'mb-2' : 'mb-0')}
                                                dangerouslySetInnerHTML={{ __html: n.bodyHtml }}
                                            ></div> : null
                                    }
                                    {
                                        n.attachments && n.attachments.length > 0 ?
                                            n.attachments.map((a, ai) => {
                                                return <div
                                                    key={`attachment-${ni}-${ai}`}
                                                    className={'card attachment border' + (ai !== (n.attachments.length - 1) ? ' mb-2' : '')}
                                                >
                                                    <div className='card-body'>
                                                        <div className='d-flex flex-row w-100'>
                                                            <div className='d-flex align-middle align-items-center mr-3'>
                                                                {fn.toFileIcon(a.mimeType, 'fs-xxl')}
                                                            </div>
                                                            <div className='flex-1'>
                                                                <div className='d-block text-truncate text-truncate-md fw-500 text-gray-800'>{a.fileName}</div>
                                                                <div className='d-flex flex-row align-items-center fs-xs'>
                                                                    <button
                                                                        type='button'
                                                                        className='btn btn-xs btn-link p-0'
                                                                        onClick={(e) => { handleAttachmentPreview(e, a) }}
                                                                    >
                                                                        {a.size > 0 ? <>Preview</> : <span className='text-danger fw-500 flashing slow'>Corrupted</span>}
                                                                    </button>
                                                                    <span className='line-height-1'>&nbsp;&middot;&nbsp;</span>
                                                                    <span className='line-height-1 text-gray-500 fs-xs'>{fn.toFileSize(a.size, true)}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }) : null
                                    }
                                </div>
                            </div>
                            {
                                existingAttachmentNotes.length !== (ni + 1) ?
                                    <hr className={'m-0 w-100' + (
                                        !moment.utc(noteSearch.customer.notes[ni].createdDateUtc).local().startOf('day').isSameOrAfter(moment().add(-2, 'weeks').startOf('day')) &&
                                            moment.utc(noteSearch.customer.notes[ni + 1].createdDateUtc).local().startOf('day').isSameOrAfter(moment().add(-2, 'weeks').startOf('day')) ?
                                            ' new' : ''
                                    )} /> : null
                            }
                        </div>
                    }) : null
            }
            {
                noteSearch.newNote && noteSearch.newNote && (noteSearch.newNote.bodyHtml || (noteSearch.newNote.attachments && noteSearch.newNote.attachments.length > 0)) ?
                    <div className={'d-flex flex-column align-items-center'}>
                        <div className='d-flex flex-row w-100 py-4'>
                            <div className='d-inline-block align-middle mr-3'>
                                <span
                                    className={'profile-image profile-image-md rounded-circle d-block' + (auth.currentUser && !auth.currentUser.profilePictureUri ? ` profile-initials text-white bg-color${auth.currentUser.color}-500` : ' mt-1')}
                                    style={auth.currentUser && auth.currentUser.profilePictureUri ? {
                                        backgroundImage: `url(${(auth.currentUser.profilePictureUri)})`,
                                        backgroundSize: 'cover',
                                    } : null}
                                    title={auth.currentUser ? auth.currentUser.fullName : 'System'}>
                                    {auth.currentUser && !auth.currentUser.profilePictureUri ? <div className='d-initials fs-xs'>{auth.currentUser.initials}</div> : null}
                                </span>
                            </div>
                            <div className='mb-0 flex-1 text-dark'>
                                <div className='d-flex'>
                                    <span className='text-gray-800 fw-500'>
                                        {auth.currentUser ? uh.getDisplayShortName(auth.currentUser) : 'System'}
                                    </span>
                                    <span className='text-danger fs-xs ml-auto'>
                                        Not uploaded yet
                                    </span>
                                </div>
                                {
                                    noteSearch.newNote.attachments && noteSearch.newNote.attachments.length > 0 ?
                                        noteSearch.newNote.attachments.map((a, ai) => {
                                            return <div
                                                key={`attachment-new-${ai}`}
                                                className={'card attachment border' + (ai !== (noteSearch.newNote.attachments.length - 1) ? ' mb-2' : '')}
                                            >
                                                <div className='card-body'>
                                                    <div className='d-flex flex-row w-100'>
                                                        <div className='d-flex align-middle align-items-center mr-3'>
                                                            {fn.toFileIcon(a.mimeType, 'fs-xxl')}
                                                        </div>
                                                        <div className='flex-1'>
                                                            <div className='d-flex'>
                                                                <Observer>{() =>
                                                                    <HtmlEditor
                                                                        ref={el => newFileNameRef.current[ai] = el}
                                                                        className='attachment-filename'
                                                                        disableTab={true}
                                                                        disableEnter={true}
                                                                        defaultValue={`<p>${a.fileName.replace(a.extension, '')}</p>`}
                                                                        onValueChanged={(e) => { handleAttachmentFileNameChange(e, a, ai) }}
                                                                    />
                                                                }</Observer>
                                                                <span className='attachment-extension'>
                                                                    {a.extension}
                                                                </span>
                                                            </div>
                                                            <div className='d-flex flex-row align-items-center fs-xs'>
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-xs btn-link p-0'
                                                                    onClick={(e) => { handleNewAttachmentPreview(e, a) }}
                                                                >
                                                                    {a.size > 0 ? <>Preview</> : <span className='text-danger fw-500 flashing slow'>Corrupted</span>}
                                                                </button>
                                                                <span className='line-height-1'>&nbsp;&middot;&nbsp;</span>
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-xs btn-link p-0 text-danger'
                                                                    onClick={(e) => { handleAttachmentRemove(e, a) }}
                                                                >
                                                                    Remove
                                                                </button>
                                                                <span className='line-height-1'>&nbsp;&middot;&nbsp;</span>
                                                                <span className='line-height-1 text-gray-500 fs-xs'>{fn.toFileSize(a.size, true)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }) : null
                                }
                            </div>
                        </div>
                    </div> : null
            }
        </>
    }

    const renderNewCustomerAttachment = () => {
        const newAttachmentNotes = getNewCustomerAttachmentNotes();
        return <>
            {
                newAttachmentNotes > 0 ?
                    newAttachmentNotes.map((n, ni) => {
                        return <div key={`note-${ni}`} className={'d-flex flex-column align-items-center'}>
                            <div className='d-flex flex-row w-100 py-4'>
                                <div className='d-inline-block align-middle mr-3'>
                                    <span
                                        className={'profile-image profile-image-md rounded-circle d-block' + (n.resource && !n.resource.profilePictureUri ? ` profile-initials text-white bg-color${n.resource.color}-500` : ' mt-1')}
                                        style={!n.resource || n.resource.profilePictureUri ? {
                                            backgroundImage: `url(${(n.resource && n.resource.profilePictureUri ? n.resource.profilePictureUri : '/media/img/default-genki.jpg')})`,
                                            backgroundSize: 'cover',
                                        } : null}
                                        title={n.resource ? n.resource.fullName : 'System'}>
                                        {n.resource && !n.resource.profilePictureUri ? <div className='d-initials fs-xs'>{n.resource.initials}</div> : null}
                                        <span className={`badge badge-icon bg-color${n.filterType}-900`}></span>
                                    </span>
                                </div>
                                <div className='mb-0 flex-1 text-dark'>
                                    <div className='d-flex'>
                                        <span className='text-gray-800 fw-500'>
                                            {n.resource ? ph.getPreferredFullName(n.resource) : 'System'}
                                        </span>
                                        <span className='text-danger fs-xs ml-auto'>
                                            Not uploaded yet
                                        </span>
                                    </div>
                                    {
                                        n.bodyHtml ?
                                            <div
                                                className={'html mt-1 ' + (n.attachments && n.attachments.length > 0 ? 'mb-2' : 'mb-0')}
                                                dangerouslySetInnerHTML={{ __html: n.bodyHtml }}
                                            ></div> : null
                                    }
                                    {
                                        n.attachments && n.attachments.length > 0 ?
                                            n.attachments.map((a, ai) => {
                                                return <div
                                                    key={`attachment-${ni}-${ai}`}
                                                    className={'card attachment border' + (ai !== (n.attachments.length - 1) ? ' mb-2' : '')}
                                                >
                                                    <div className='card-body'>
                                                        <div className='d-flex flex-row w-100'>
                                                            <div className='d-flex align-middle align-items-center mr-3'>
                                                                {fn.toFileIcon(a.mimeType, 'fs-xxl')}
                                                            </div>
                                                            <div className='flex-1'>
                                                                <div className='d-block text-truncate text-truncate-md fw-500 text-gray-800'>{a.fileName}</div>
                                                                <div className='d-flex flex-row align-items-center fs-xs'>
                                                                    <button
                                                                        type='button'
                                                                        className='btn btn-xs btn-link p-0'
                                                                        onClick={(e) => { handleAttachmentPreview(e, a) }}
                                                                    >
                                                                        {a.size > 0 ? <>Preview</> : <span className='text-danger fw-500 flashing slow'>Corrupted</span>}
                                                                    </button>
                                                                    <span className='line-height-1'>&nbsp;&middot;&nbsp;</span>
                                                                    <button
                                                                        type='button'
                                                                        className='btn btn-xs btn-link p-0 text-danger'
                                                                        onClick={(e) => { handleAttachmentRemove(e, a) }}
                                                                    >
                                                                        Remove
                                                                    </button>
                                                                    <span className='line-height-1'>&nbsp;&middot;&nbsp;</span>
                                                                    <span className='line-height-1 text-gray-500 fs-xs'>{fn.toFileSize(a.size, true)}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }) : null
                                    }
                                </div>
                            </div>
                            {
                                newAttachmentNotes.length !== (ni + 1) ?
                                    <hr className={'m-0 w-100' + (
                                        !moment.utc(newNote.customer.notes[ni].createdDateUtc).local().startOf('day').isSameOrAfter(moment().add(-2, 'weeks').startOf('day')) &&
                                            moment.utc(newNote.customer.notes[ni + 1].createdDateUtc).local().startOf('day').isSameOrAfter(moment().add(-2, 'weeks').startOf('day')) ?
                                            ' new' : ''
                                    )} /> : null
                            }
                        </div>
                    }) : null
            }
            {
                newNote.data && (newNote.data.bodyHtml || (newNote.data.attachments && newNote.data.attachments.length > 0)) ?
                    <div className={'d-flex flex-column align-items-center'}>
                        <div className='d-flex flex-row w-100 py-4'>
                            <div className='d-inline-block align-middle mr-3'>
                                <span
                                    className={'profile-image profile-image-md rounded-circle d-block' + (auth.currentUser && !auth.currentUser.profilePictureUri ? ` profile-initials text-white bg-color${auth.currentUser.color}-500` : ' mt-1')}
                                    style={auth.currentUser && auth.currentUser.profilePictureUri ? {
                                        backgroundImage: `url(${(auth.currentUser.profilePictureUri)})`,
                                        backgroundSize: 'cover',
                                    } : null}
                                    title={auth.currentUser ? auth.currentUser.fullName : 'System'}>
                                    {auth.currentUser && !auth.currentUser.profilePictureUri ? <div className='d-initials fs-xs'>{auth.currentUser.initials}</div> : null}
                                </span>
                            </div>
                            <div className='mb-0 flex-1 text-dark'>
                                <div className='d-flex'>
                                    <span className='text-gray-800 fw-500'>
                                        {auth.currentUser ? uh.getDisplayShortName(auth.currentUser) : 'System'}
                                    </span>
                                    <span className='text-danger fs-xs ml-auto'>
                                        Not uploaded yet
                                    </span>
                                </div>
                                {
                                    newNote.data.bodyHtml ?
                                        <div
                                            className={'html mt-1 ' + (newNote.data.attachments && newNote.data.attachments.length > 0 ? 'mb-2' : 'mb-0')}
                                            dangerouslySetInnerHTML={{ __html: newNote.data.bodyHtml }}
                                        ></div> : null
                                }
                                {
                                    newNote.data.attachments && newNote.data.attachments.length > 0 ?
                                        newNote.data.attachments.map((a, ai) => {
                                            return <div
                                                key={`attachment-new-${ai}`}
                                                className={'card attachment border' + (ai !== (newNote.data.attachments.length - 1) ? ' mb-2' : '')}
                                            >
                                                <div className='card-body'>
                                                    <div className='d-flex flex-row w-100'>
                                                        <div className='d-flex align-middle align-items-center mr-3'>
                                                            {fn.toFileIcon(a.mimeType, 'fs-xxl')}
                                                        </div>
                                                        <div className='flex-1'>
                                                            <div className='d-flex'>
                                                                <Observer>{() =>
                                                                    <HtmlEditor
                                                                        ref={el => newFileNameRef.current[ai] = el}
                                                                        className='attachment-filename'
                                                                        disableTab={true}
                                                                        disableEnter={true}
                                                                        defaultValue={`<p>${a.fileName.replace(a.extension, '')}</p>`}
                                                                        onValueChanged={(e) => { handleAttachmentFileNameChange(e, a, ai) }}
                                                                    />
                                                                }</Observer>
                                                                <span className='attachment-extension'>
                                                                    {a.extension}
                                                                </span>
                                                            </div>
                                                            <div className='d-flex flex-row align-items-center fs-xs'>
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-xs btn-link p-0'
                                                                    onClick={(e) => { handleNewAttachmentPreview(e, a) }}
                                                                >
                                                                    {a.size > 0 ? <>Preview</> : <span className='text-danger fw-500 flashing slow'>Corrupted</span>}
                                                                </button>
                                                                <span className='line-height-1'>&nbsp;&middot;&nbsp;</span>
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-xs btn-link p-0 text-danger'
                                                                    onClick={(e) => { handleAttachmentRemove(e, a) }}
                                                                >
                                                                    Remove
                                                                </button>
                                                                <span className='line-height-1'>&nbsp;&middot;&nbsp;</span>
                                                                <span className='line-height-1 text-gray-500 fs-xs'>{fn.toFileSize(a.size, true)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }) : null
                                }
                            </div>
                        </div>
                    </div> : null
            }
        </>
    }

    const renderDropzoneHtmlEditor = () => {
        const hasAttachments = noteSearch.newNote && noteSearch.newNote.attachments && noteSearch.newNote.attachments.length > 0;
        return <>
            <HtmlEditor
                ref={newEditorRef}
                className={(hasAttachments ? '' : 'd-none')}
                height={130}
                placeholder='Write note here...'
                toolbar={{ items: ['bold', 'italic', 'underline', 'separator', 'orderedList', 'bulletList', 'separator', 'color', 'background'] }}
                disableTab={true}
                onValueChanged={handleMessageChange}
            ></HtmlEditor>
            <div
                className={(hasAttachments ? 'd-none' : '')}
            >
                <Dropzone
                    ref={newDropzoneRef}
                    onDrop={handleFilesDrop}
                    noClick={true}
                >
                    {({ getRootProps, getInputProps, open, isDragActive, isDragAccept, isDragReject }) => (
                        <div {...getRootProps({ className: 'dropzone w-100 pt-3 pl-3 pr-3 pb-0' })}>
                            <input {...getInputProps()} />
                            <div className={
                                'd-flex border border-dashed border-3 flex-column h-100 align-items-center justify-content-center text-gray-700' +
                                (!isDragActive ? ' border-transparent' : '') +
                                (isDragAccept ? ' border-info' : '') +
                                (isDragReject ? ' border-danger' : '')
                            }>
                                <span className='fs-sm mb-2'>Drag the files here</span>
                                <span className='fw-500 fs-xs mb-2'>- or -</span>
                                <button
                                    type='button'
                                    className='btn btn-sm btn-default mb-2'
                                    onClick={open}
                                >
                                    Select files from computer
                                </button>
                                <small className='fw-500 text-gray-700'>** Maximum upload size: <span className='fw-400 text-success'>100 MB</span> **</small>
                            </div>
                        </div>
                    )}
                </Dropzone>
            </div>
        </>
    }

    const getNewCustomerAttachmentNotes = () => {
        if (!newNote.customer || !newNote.customer.notes) return [];
        return newNote.customer.notes.filter(n => n.attachments && n.attachments.length > 0);
    }

    const getExistingCustomerAttachmentNotes = () => {
        if (!noteSearch.customer || !noteSearch.customer.notes) return [];
        return noteSearch.customer.notes.filter(n => n.attachments && n.attachments.length > 0);
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    props.onCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form onSubmit={handleSubmit}>
            <Observer>{() =>
                <fieldset disabled={newNote.isSaving || noteSearch.isSaving}>
                    <div className='quick-drawer view-attachments-drawer'>
                        <QuickDrawerHeader
                            drawer={props.drawer}
                            icon={oh.getIcon('note', 'attachment')}
                            action='View'
                            category={(props.extraProps && props.extraProps.category ? props.extraProps.category : 'Attachments')}
                            className='notes'
                            onCancel={props.onCancel}
                        />
                        <div ref={bodyRef} className='quick-drawer-body'>
                            {
                                fn.isNullOrUndefined(noteSearch.customerId) ?
                                    <FadeIn>
                                        <div className={'view-note body-content py-1' + (isRendered ? ' rendered' : '')}>
                                            {renderNewCustomerAttachment()}
                                        </div>
                                    </FadeIn> :
                                    <>
                                        {
                                            noteSearch.isReady ?
                                                <FadeIn>
                                                    <div className={'view-note body-content py-1' + (isRendered ? ' rendered' : '')}>
                                                        {renderExistingAttachments()}
                                                    </div>
                                                </FadeIn> : renderQuickDrawerLoading()
                                        }
                                    </>
                            }
                        </div>
                        <div className='quick-drawer-action'>
                            <div className='row'>
                                <div className='col-12'>
                                    <hr className='m-0 w-100' />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <Observer>{() => <>{renderDropzoneHtmlEditor()}</>}</Observer>
                                </div>
                            </div>
                            <div className='row px-4 py-3'>
                                <div className='col-12'>
                                    <div className='float-right'>
                                        {
                                            noteSearch.newNote && noteSearch.newNote.attachments && noteSearch.newNote.attachments.length > 0 ?
                                                <>
                                                    <button
                                                        type='button'
                                                        className='btn btn-light'
                                                        onClick={handleAddMoreAttachments}
                                                    >Add more</button>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-success ml-2'
                                                    >Upload</button>
                                                </> : 
                                                <button
                                                    type='button'
                                                    className='btn btn-link btn-cancel ml-2'
                                                    onClick={props.onCancel}
                                                >Cancel</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            }</Observer>
        </form>
        <BodyEnd>
            <Observer>{() =>
                <LoadingOverlay isLoading={noteSearch.isSaving} message={nh.UPLOAD_MESSAGE} />
            }</Observer>
        </BodyEnd>
    </>
}

export default Attachments;