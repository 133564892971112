import React, { useEffect, useContext, useState, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { useObserver } from 'mobx-react-lite';
import { GlobalHotKeys } from 'react-hotkeys';
import moment from 'moment';

import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';

import ExamCreateStore from '../../../../stores/ExamCreateStore';
import PredefinedExamSelectStore from '../../../../stores/PredefinedExamSelectStore';
import PreviousExamSelectStore from '../../../../stores/PreviousExamSelectStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';
import SettingStore from '../../../../stores/SettingStore';

import * as fn from '../../../../utilities/_functions';
import * as aeh from '../../../../utilities/appointmentHelper';
import * as eh from '../../../../utilities/examHelper';
import * as oh from '../../../../utilities/operationHelper';
import api from '../../../../api';

import './NewExam.scss';

function NewExam(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const focusTimer = useRef(null);
    const exam = useContext(ExamCreateStore);
    const predefinedExam = useContext(PredefinedExamSelectStore);
    const previousExam = useContext(PreviousExamSelectStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const setting = useContext(SettingStore);
    const [fallbackDefaultExamTemplateId, setFallbackDefaultExamTemplateId] = useState(null);
    const [startExamMethod, setStartExamMethod] = useState('blank');
    const [selectedPredefinedExam, setSelectedPredefinedExam] = useState(null);
    const [selectedPreviousExam, setSelectedPreviousExam] = useState(null);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        quickDrawerFocus(props.drawer);

        api.ExamTemplates.search(
            {
                parameters: [
                    {
                        field: 'PublishedTemplateId',
                        operator: '!=',
                        value: null
                    },
                    {
                        field: 'IsSystemTemplate',
                        value: false
                    },
                    {
                        field: 'IsDefault',
                        value: true
                    }
                ],
                sortByFields: [{
                    field: 'CreatedDateUtc',
                    direction: 'DESC',
                }],
                includeTotalCount: false,
                loadProperties: false,
                offset: 0,
                limit: 1,
            })
            .then(({ data }) => {
                if (isMounted.current) {
                    if (data && data.result && data.result.length > 0) {
                        setFallbackDefaultExamTemplateId(data.result[0].id);
                    }
                    setIsReady(true);
                }
            })

        return (() => {
            isMounted.current = false;

            exam.clear();
            predefinedExam.clear();
            previousExam.clear();

            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        });
    }, []) // eslint-disable-line

    useEffect(() => {
        // Fallback logic - should never happen.
        if (fallbackDefaultExamTemplateId) {
            if (exam.isReady && !exam.selectedTemplateId) {
                exam.selectedTemplateId = fallbackDefaultExamTemplateId;
            }

            if (exam.isReady && exam.selectedTemplateId) {
                if (predefinedExam.isReady && !predefinedExam.examTemplateId) {
                    predefinedExam.examTemplateId = exam.selectedTemplateId;
                    predefinedExam.refresh();
                }

                if (previousExam.isReady && !previousExam.examTemplateId) {
                    previousExam.examTemplateId = exam.selectedTemplateId;
                }
            }
        }
    }, [fallbackDefaultExamTemplateId, exam.isReady, predefinedExam.isReady, previousExam.isReady]) // eslint-disable-line

    const handleSubmit = event => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            switch (startExamMethod) {
                case 'blank':
                    exam.blank()
                        .then(data => {
                            if (isMounted.current) {
                                if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                                    props.onSuccess(event, {
                                        appointmentId: exam.appointment.id,
                                        pretest: exam.pretestImportType
                                    });
                                }
                            }
                        })
                        .catch((error) => {
                            eh.displayStartExamError(error);
                        })
                    break;

                case 'predefined':
                    if (selectedPredefinedExam) {
                        exam.predefinedData(selectedPredefinedExam.id)
                            .then(data => {
                                if (isMounted.current) {
                                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                                        props.onSuccess(event, {
                                            appointmentId: exam.appointment.id,
                                            pretest: exam.pretestImportType
                                        });
                                    }
                                }
                            })
                            .catch((error) => {
                                eh.displayStartExamError(error);
                            })
                    } else {
                        handlePredefinedExam();
                    }
                    break;

                case 'previous':
                    if (selectedPreviousExam) {
                        exam.previousExam(selectedPreviousExam.id)
                            .then(data => {
                                if (isMounted.current) {
                                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                                        props.onSuccess(event, {
                                            appointmentId: exam.appointment.id,
                                            pretest: exam.pretestImportType
                                        });
                                    }
                                }
                            })
                            .catch((error) => {
                                eh.displayStartExamError(error);
                            })
                    } else {
                        handlePreviousExam();
                    }
                    break;

                default:
                    break;
            }
        }
    }

    const handleTemplateChange = event => {
        const { value } = event.target;

        exam.selectedTemplateId = value;
        predefinedExam.examTemplateId = value;
        previousExam.examTemplateId = value;

        predefinedExam.refresh();
        setSelectedPredefinedExam(null);
    }

    const handlePredefinedExam = () => {
        if (!predefinedExam.isReady) { predefinedExam.initialize(exam.appointment); }
        quickDrawer.activateQuickDrawer('exam', 'predefined', null, handlePredefinedExamSuccess, handlePredefinedExamCancel)
            .then(drawer => {
                if (isMounted.current) {
                    focusTimer.current = setTimeout(() => {
                        quickDrawerFocus(drawer);
                    }, 100);
                }
            });
    }

    const handlePredefinedExamSuccess = (data) => {
        setSelectedPredefinedExam(data);
    }

    const handlePredefinedExamCancel = () => { }

    const handlePreviousExam = () => {
        if (!previousExam.isReady) { previousExam.initialize(exam.appointment); }
        quickDrawer.activateQuickDrawer('exam', 'previous', null, handlePreviousExamSuccess, handlePreviousExamCancel)
            .then(drawer => {
                focusTimer.current = setTimeout(() => {
                    quickDrawerFocus(drawer);
                }, 100);
            });
    }

    const handlePreviousExamSuccess = (data) => {
        setSelectedPreviousExam(data);
    }

    const handlePreviousExamCancel = () => { }

    const handleStartExamMethodChange = event => {
        const startExam = event.target.value;
        setStartExamMethod(startExam);

        if (startExam !== 'previous' && exam.pretestImportType === 'previous') {
            exam.pretestImportType = 'today';
        }
    }

    const handleExamsStartNewWindowChange = () => {
        setting.examsStartNewWindow = !setting.examsStartNewWindow;
    }

    const handlePretestChange = event => {
        exam.pretestImportType = event.target.value;
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                props.onCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <fieldset disabled={exam.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('exam', 'new')}
                        action='Start'
                        category='Exam'
                        className='exams'
                        onCancel={props.onCancel}
                    />
                    <div className='quick-drawer-body'>
                        {
                            isReady && exam.isReady && predefinedExam.isReady && previousExam.isReady ?
                                <FadeIn>
                                    <div className='new-exam body-content'>
                                        <section className='date-time'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    {aeh.renderAppointmentDateTime(exam.appointment)}
                                                </div>
                                            </div>
                                        </section>
                                        <section className='customer'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0'>
                                                        <label><small>For</small></label>
                                                        {aeh.renderAppointmentCustomer(exam.appointment, quickDrawer)}
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group validate validate-required mb-0'>
                                                        <label className='required' htmlFor='exam-template'><small>Template</small></label>
                                                        <select
                                                            id='exam-template'
                                                            className='custom-select form-control'
                                                            value={exam.selectedTemplateId ? exam.selectedTemplateId : ''}
                                                            onChange={handleTemplateChange}
                                                        >
                                                            <option value=''></option>
                                                            {
                                                                exam.templates ?
                                                                    exam.templates.map((t, ti) => {
                                                                        return <option
                                                                            key={`exam_template_${ti}`}
                                                                            value={t.id}
                                                                        >{t.name}</option>
                                                                    }) :
                                                                    <option value=''></option>
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0'>
                                                        <label><small>Start with</small></label>
                                                        <div className='custom-control custom-radio mb-2'>
                                                            <input
                                                                id='startExamBlank'
                                                                type='radio'
                                                                name='startExamOption'
                                                                className='custom-control-input'
                                                                checked={startExamMethod === 'blank'}
                                                                value='blank'
                                                                onChange={handleStartExamMethodChange}
                                                            />
                                                            <label
                                                                htmlFor='startExamBlank'
                                                                className='custom-control-label'
                                                            >
                                                                A blank exam
                                                            </label>
                                                        </div>
                                                        {
                                                            predefinedExam.filteredDataSets && predefinedExam.filteredDataSets.length > 0 ?
                                                                <>
                                                                    <div className='custom-control custom-radio mb-2'>
                                                                        <input
                                                                            id='startExamPredefinedData'
                                                                            type='radio'
                                                                            name='startExamOption'
                                                                            className='custom-control-input'
                                                                            checked={startExamMethod === 'predefined'}
                                                                            value='predefined'
                                                                            onChange={handleStartExamMethodChange}
                                                                        />
                                                                        <label
                                                                            htmlFor='startExamPredefinedData'
                                                                            className='custom-control-label'
                                                                        >
                                                                            A prefilled exam template
                                                                        </label>
                                                                    </div>
                                                                    {
                                                                        startExamMethod === 'predefined' && selectedPredefinedExam ?
                                                                            <div
                                                                                className='pl-5 mb-2 cursor-pointer'
                                                                                onClick={handlePredefinedExam}
                                                                            >
                                                                                <strong className='text-primary fs-lg'>
                                                                                    <i className={'mr-1 ' + oh.getIcon('exam')}></i>{selectedPredefinedExam.name}
                                                                                </strong>
                                                                            </div> : null
                                                                    }
                                                                </> : null
                                                        }
                                                        {
                                                            previousExam.filteredDataSets && previousExam.filteredDataSets.length > 0 ?
                                                                <>
                                                                    <div className='custom-control custom-radio mb-2'>
                                                                        <input
                                                                            id='startExamPrevious'
                                                                            type='radio'
                                                                            name='startExamOption'
                                                                            className='custom-control-input'
                                                                            checked={startExamMethod === 'previous'}
                                                                            value='previous'
                                                                            onChange={handleStartExamMethodChange}
                                                                        />
                                                                        <label
                                                                            htmlFor='startExamPrevious'
                                                                            className='custom-control-label'
                                                                        >
                                                                            Copying from a previous exam
                                                                        </label>
                                                                    </div>
                                                                    {
                                                                        startExamMethod === 'previous' && selectedPreviousExam ?
                                                                            <div
                                                                                className='pl-5 mb-2 cursor-pointer'
                                                                                onClick={handlePreviousExam}
                                                                            >
                                                                                <strong className='text-primary fs-lg'>
                                                                                    <i className={'mr-1 ' + oh.getIcon('exam')}></i>{fn.formatFullDateWithOrWithoutYear(moment.utc(selectedPreviousExam.scheduledStartUtc).local())}
                                                                                </strong>
                                                                            </div> : null
                                                                    }
                                                                </> : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0'>
                                                        <label><small>Include</small></label>
                                                        <div className='custom-control custom-radio mb-2'>
                                                            <input
                                                                id='new-exam-pretest-today'
                                                                type='radio'
                                                                name='new-exam-pretest-import'
                                                                className='custom-control-input'
                                                                value='today'
                                                                checked={exam.pretestImportType === 'today'}
                                                                onChange={handlePretestChange}
                                                            />
                                                            <label
                                                                htmlFor='new-exam-pretest-today'
                                                                className='custom-control-label'
                                                            >
                                                                Today's pretest data
                                                            </label>
                                                        </div>
                                                        {
                                                            startExamMethod === 'previous' && previousExam.filteredDataSets && previousExam.filteredDataSets.length > 0 ?
                                                                <div className='custom-control custom-radio mb-2'>
                                                                    <input
                                                                        id='new-exam-pretest-previous'
                                                                        type='radio'
                                                                        name='new-exam-pretest-import'
                                                                        className='custom-control-input'
                                                                        value='previous'
                                                                        checked={exam.pretestImportType === 'previous'}
                                                                        onChange={handlePretestChange}
                                                                    />
                                                                    <label
                                                                        htmlFor='new-exam-pretest-previous'
                                                                        className='custom-control-label'
                                                                    >
                                                                        Previous exam's pretest data
                                                                    </label>
                                                                </div> : null
                                                        }
                                                        <div className='custom-control custom-radio mb-2'>
                                                            <input
                                                                id='new-exam-pretest-none'
                                                                type='radio'
                                                                name='new-exam-pretest-import'
                                                                className='custom-control-input'
                                                                value='none'
                                                                checked={exam.pretestImportType === 'none'}
                                                                onChange={handlePretestChange}
                                                            />
                                                            <label
                                                                htmlFor='new-exam-pretest-none'
                                                                className='custom-control-label'
                                                            >
                                                                No pretest data
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0'>
                                                        <div className='custom-control custom-checkbox mr-4'>
                                                            <input
                                                                id='new-exam-start-new-window'
                                                                type='checkbox'
                                                                name='new-exam-start-new-window'
                                                                className='custom-control-input'
                                                                checked={setting.examsStartNewWindow}
                                                                onChange={handleExamsStartNewWindowChange}
                                                            />
                                                            <label
                                                                htmlFor='new-exam-start-new-window'
                                                                className='custom-control-label'
                                                            >
                                                                Starts exam in new window
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </FadeIn> :
                                <>
                                    {renderQuickDrawerLoading()}
                                </>
                        }
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={props.onCancel}
                                    >Cancel</button>
                                    <button
                                        data-submit-start-exam
                                        type='submit'
                                        className='btn btn-success'
                                    >Start</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>)
}

export default NewExam;