import { createContext } from 'react';
import { decorate, observable, action, toJS } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';
import moment from 'moment';

export class ServiceAttributeUpdate {
    serviceId = null;
    data = null;
    types = [];
    options = [];
    isLoading = false;
    isReady = false;
    hasUnsavedChanges = false;
    isChangeImmediate = true;
    cancelService = null;
    isSaving = false;
    isActiveStartingDateInFuture = false;

    initialize = async (id) => {
        this.clear();
        this.serviceId = id;
        this.isReady = false;
        await this.refresh();
    }

    refresh = async () => {
        this.isLoading = true;

        try {
            const { data } = await api.ServiceAttributes.getForUpdate(this.serviceId, (c) => { this.cancelService = c });
            this.isActiveStartingDateInFuture = moment().isBefore(moment(data.activeStartingDate));
            if (!data.id) {
                // this data is from Services table, not from ServiceAttributes table
                this.isChangeImmediate = true;
            }
            else {
                this.isChangeImmediate = false;
            }
            this.data = data;
            this.isReady = true;
        }
        catch(ex) {
            throw ex;
        }
        finally {
            this.isLoading = false;
        }
    }

    deletePending = async (notify) => {
        if (!!notify) {
            this.isSaving = true;
        }

        await api.ServiceAttributes.deletePending(this.serviceId, (c) => { this.cancelService = c });
        this.isSaving = false;
        return this.data;
    }

    save = async (notify) => {
        if (!!notify) {
            this.isSaving = true;
        }

        if (this.hasUnsavedChanges) {
            try {
                let option = toJS(this.data);
                if (this.data.activeStartingDate) {
                    option.activeStartingDate = moment(this.data.activeStartingDate).clone().format('YYYY-MM-DD'); // remove time and timezone information
                }
                option.fee = isNaN(option.fee) ? fn.parseCurrency(option.fee) : option.fee;
                option.isChangeImmediate = this.isChangeImmediate;
                if (option.isChangeImmediate) {
                    option.effectiveStarting = null;
                }
                else {
                    option.effectiveStarting = moment(this.data.effectiveStarting).clone().format('YYYY-MM-DD');
                }
                await api.ServiceAttributes.update(this.serviceId, option, (c) => { this.cancelService = c });
                this.hasUnsavedChanges = false;
                return this.data;
            }
            finally {
                this.isSaving = false;
            }
        } 
        this.isSaving = false;
    }

    clear = () => {
        this.serviceId = null;
        this.data = null;
        this.types.clear();
        this.options.clear();
        this.isLoading = false;
        this.isReady = false;

        if (fn.isFunction(this.cancelService)) {
            this.cancelService();
            this.cancelService = null;
        }

        if (fn.isFunction(this.cancelServiceOptions)) {
            this.cancelServiceOptions();
            this.cancelServiceOptions = null;
        }

        if (fn.isFunction(this.cancelServiceTypes)) {
            this.cancelServiceTypes();
            this.cancelServiceTypes = null;
        }
    }

}

decorate(ServiceAttributeUpdate, {
    serviceId: observable,
    data: observable,
    types: observable,
    options: observable,
    isSaving: observable,
    hasUnsavedChanges: observable,
    isChangeImmediate: observable,
    isActiveStartingDateInFuture: observable,
    isLoading: observable,
    isReady: observable,
    initialize: action,
    refresh: action,
    clear: action,
    save: action,
})

export default createContext(new ServiceAttributeUpdate());