import React, { useEffect, useContext, useRef } from 'react';
import { useObserver } from 'mobx-react-lite';

import AuthStore from '../../../stores/AuthStore';

import * as fn from '../../../utilities/_functions';
import * as StorageKeys from '../../../constants/storageKeys';
import api from '../../../api';

import './SwitchPinScreen.scss';

const SWITCH_PIN_SCREEN = '__switch_pin_screen__';
const SWITCH_PIN_INPUT = '__switch_pin_input__';

const showSwitchPinScreen = () => {
    const screen = document.querySelector(`#${SWITCH_PIN_SCREEN}`);

    if (screen) {
        screen.style.display = 'block';
        setTimeout(() => {
            document.querySelector(`#${SWITCH_PIN_INPUT}`).focus();
        }, 100)
    }
}

const hideSwitchPinScreen = () => {
    setTimeout(() => {
        const screen = document.querySelector(`#${SWITCH_PIN_SCREEN}`);
        if (screen) {
            document.querySelector(`#${SWITCH_PIN_SCREEN}`).style.display = 'none';
        }
    }, 550)
}

function SwitchPinScreen() {
    const isMounted = useRef(true);
    const cancelLockscreenDeactivateRef = useRef();
    const auth = useContext(AuthStore);

    useEffect(() => {
        auth.isSwitchPinReady = false;
        auth.isLockReady = false;
        
        return () => {
            isMounted.current = false;
            if (fn.isFunction(cancelLockscreenDeactivateRef.current)) { cancelLockscreenDeactivateRef.current(); cancelLockscreenDeactivateRef.current = null; }
        }
    }, []) // eslint-disable-line

    const handleSwitchPinKeyDown = event => {
        const code = event.which || event.keyCode;
        if ((code < 48 || code > 57) && code !== 8 && code !== 13 && code !== 27 && code !== 46) {
            event.preventDefault();
        }
    }

    const handleSwitchPinChange = event => {
        auth.switchPin = event.target.value;
    }

    const handleSwitchPinClick = event => {
        document.querySelector(`#${SWITCH_PIN_INPUT}`).focus();
    }

    const getMaskedSwitchPin = () => {
        return String.fromCharCode(8226).repeat((auth.switchPin ? auth.switchPin.length : 0));
    }

    const handleSubmit = event => {
        event.preventDefault();
        auth.handleSwitchUser()
            .then(() => {
                api.Lockscreen.deactivate(window.localStorage.getItem(StorageKeys.SESSION_ID), (c) => { cancelLockscreenDeactivateRef.current = c });
                auth.deactivateSwitchPinScreen();
                hideSwitchPinScreen();
            })
            .catch(() => {
                const input = document.querySelector(`#${SWITCH_PIN_INPUT}`);

                input.classList.add('shake');
                input.focus();

                setTimeout(() => {
                    input.classList.remove('shake');
                }, 500);
            })
    }

    return useObserver(() => <>
        <div
            id={SWITCH_PIN_SCREEN}
            className={`switch-pin-screen animated faster${(auth.isSwitchPinReady ? (auth.isSwitchPinActivated ? ' fadeIn' : ' fadeOut') : ' d-none')}`}
        >
            <form onSubmit={handleSubmit}>
                <fieldset disabled={auth.isSwitchPinInProcess}>
                    <div className='icon-wrapper'>
                        <i className={`fal ${(auth.isSwitchPinActivated ? 'fa-lock-alt' : 'fa-lock-open-alt')}`}></i>
                    </div>
                    <div className='input-group'>
                        <input
                            id={SWITCH_PIN_INPUT}
                            className={`form-control m-0 p-0 animated fast${(auth.switchPin && auth.switchPin.length > 0 ? ' back' : ' front')}`}
                            type='text'
                            autoComplete='off'
                            placeholder='Your PIN'
                            maxLength={10}
                            disabled={auth.isProcessing}
                            value={auth.switchPin ? auth.switchPin : ''}
                            onChange={handleSwitchPinChange}
                            onKeyDown={handleSwitchPinKeyDown}
                        />
                        <div
                            className={`mask-input ${(auth.isProcessing ? ' loading' : '')}${(auth.switchPin && auth.switchPin.length > 0 ? '' : ' d-none')}`}
                            onClick={handleSwitchPinClick}
                        >
                            {getMaskedSwitchPin()}
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    </>)
}

export { showSwitchPinScreen, hideSwitchPinScreen };
export default SwitchPinScreen;