import React, { useEffect, useContext, useRef, useState } from 'react';
import { Observer } from 'mobx-react-lite';
import FadeIn from 'react-fade-in';
import { GlobalHotKeys } from 'react-hotkeys';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';
import { evaluate } from 'mathjs';
import moment from 'moment';

import BodyEnd from '../../_shared/BodyEnd';
import LoadingOverlay from '../../_shared/LoadingOverlay'
import TemplateGrid from '../../_shared/TemplateGrid';

import useSignalR from '../../../hooks/useSignalR';
import PretestCreateStore from '../../../../stores/PretestCreateStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as th from '../../../../utilities/templateHelper';
import * as tih from '../../../../utilities/templateInputHelper';
import * as tch from '../../../../utilities/templateControlHelper';
import * as mth from '../../../../utilities/modalTemplateHelper';
import * as mh from '../../../../utilities/mentionHelper';
import * as dh from '../../../../utilities/deviceHelper';

function CreatePretest(props) {
    const isMounted = useRef(true);
    const renderTimer = useRef(null);
    const focusTimer = useRef(null);
    const modalBodyRef = useRef(null);
    const deviceSyncTriggerIdRef = useRef(uuid());
    const pretest = useContext(PretestCreateStore);
    const signalR = useSignalR();
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [isGridRendered, setIsGridRendered] = useState(false);
    const [isListeningFromDevice, setIsListeningFromDevice] = useState(false);

    useEffect(() => {
        signalR.on('DeviceSync', (updated) => {
            if (updated) {
                if (isMounted.current) {
                    if (updated.status === 'Started') {
                        setIsListeningFromDevice(true);
                    }
                    if (updated.status === 'Success' && updated.result && updated.result.length > 0) {
                        let found = false;
                        const definition = pretest.selectedTypes.map(t => { return t.pretestPublishedTemplate.definition.definition }).flat();
                        const mappings = definition.some(d => d.metadata && d.metadata.deviceMapping) ?
                            definition.filter(d => d.metadata && d.metadata.deviceMapping).map(d => {
                                return {
                                    to: d.key,
                                    from: d.metadata.deviceMapping,
                                    metadata: d.metadata,
                                }
                            }) : null;

                        if (mappings && mappings.length > 0) {
                            for (let i = 0; i < updated.result.length; i++) {
                                const mapping = mappings.filter(m => m.from === updated.result[i].id)[0];

                                if (mapping) {
                                    let value = updated.result[i].value;

                                    if (!found) {
                                        pretest.data.pretestData.clear();
                                        found = true;
                                    }

                                    if (!applyInputFormatting(mapping.to, value, mapping.metadata)) {
                                        setValue(mapping.to, value);
                                    }
                                }
                            }
                        }
                        pretest.data.inputMethodType = 'DeviceSync';
                        pretest.hasUnsavedChanges = true;
                        setHasUnsavedChanges(true);
                        setIsListeningFromDevice(false);
                    }
                    if (updated.status === 'Success' && (!updated.result || updated.result.length === 0)) {
                        toast.error(() => <p><strong>No data recorded.</strong> Please try again.</p>, { position: 'top-center', style: { width: '380px' } });
                        setIsListeningFromDevice(false);
                    }
                    if (updated.status === 'NoData') {
                        toast.error(() => <p><strong>{pretest.template.industryDevice.name} timed out.</strong> Please try again.</p>, { position: 'top-center', style: { width: '380px' } });
                        setIsListeningFromDevice(false);
                    }
                    if (updated.status === 'Exception') {
                        toast.error(() => ErrorMessages.GENERIC_ERROR_HTML, { position: 'top-center', style: { width: '380px' } });
                        setIsListeningFromDevice(false);
                    }
                }
            }
        });

        return () => {
            isMounted.current = false;
            if (renderTimer.current) { clearTimeout(renderTimer.current); renderTimer.current = null; }
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        if (pretest && pretest.data && isGridRendered) {
            firstElementFocus();
        }
    }, [isGridRendered]) // eslint-disable-line

    useEffect(() => {
        if (!!props.display) {
            if (!!props.device) {
                const deviceSyncTrigger = dh.getDeviceSyncTrigger(deviceSyncTriggerIdRef.current);

                if (!!deviceSyncTrigger) {
                    const base64 = dh.getDeviceSyncReadWriteBase64(props.device, signalR.connectionId);
                    deviceSyncTrigger.setAttribute('href', base64);
                    deviceSyncTrigger.click();
                }
            }
        }
        else {
            setIsGridRendered(false);
        }
    }, [props.display, props.device]) // eslint-disable-line

    const firstElementFocus = () => {
        const found = modalBodyRef && modalBodyRef.current ? modalBodyRef.current.querySelectorAll('input[type="text"]:not([disabled])') : null;

        if (found && found.length > 0) {
            const notEmpty = Array.from(found).filter(el => !el.value);

            focusTimer.current = setTimeout(() => {
                if (notEmpty && notEmpty.length > 0) {
                    notEmpty[0].focus();
                } else {
                    found[0].focus();
                }
            }, 100);
        }
    }

    const handleDataChange = ({ type }) => {
        switch (type) {
            case 'SingleLineText':
            case 'MultiLineText':
            case 'Dropdown':
            case 'Date':
            case 'Time':
                return handleInputChange;

            // case 'MultiLineText':
            case 'RichText':
                return handleRichTextEditorChange;

            case 'Radio':
                return handleRadioChange;

            case 'Checkbox':
                return handleCheckboxChange;

            default:
                return () => { };
        }
    }

    const handleInputFormatting = (event, key, input) => {
        if (!key || !input) return;

        const value = getValue(key);
        applyInputFormatting(key, value, input.metadata);
    }

    const handleInputChange = (event, key) => {
        const value = event.target.value;
        setValue(key, value);
    }

    const handleRichTextEditorChange = (content, key) => {
        const html = content === '<p><br></p>' ? null : content;
        setValue(key, html);
    }

    const handleRadioChange = (event, key) => {
        const value = event.target.value;
        const originalValue = getValue(key);

        if (value === originalValue) {
            setValue(key, null);
        } else {
            setValue(key, value);
        }
    }

    const handleCheckboxChange = (event, key) => {
        const value = event.target.value;
        const originalValue = getValue(key);
        const originalArray = originalValue ? originalValue.split('|') : [];
        const index = originalArray.findIndex(o => o === value);

        if (index >= 0) {
            originalArray.splice(index, 1);
        } else {
            originalArray.push(value);
        }

        setValue(key, originalArray.join('|'));
    }

    const handleControlClick = (event, control) => {
        switch (control.type) {
            case tch.TEMPLATE_CONTROL_TIMESTAMP_BUTTON:
                if (control.metadata.timestamp) {
                    const variables = mh.parseVariables(control.metadata.timestamp);

                    if (variables && variables.length > 0) {
                        for (let i = 0; i < variables.length; i++) {
                            if (variables[i] && variables[i].key) {
                                setValue(variables[i].key, moment().format('h:mm a'));
                            }
                        }
                    }
                }
                break;

            default:
                break;
        }
    }

    const handleSave = event => {
        if (!pretest.data.pretestData || pretest.data.pretestData.filter(d => !d.id.startsWith('__')).length === 0) {
            toast.error(() => ErrorMessages.GENERIC_FORM_ERROR_HTML, { position: 'top-center', style: { width: '380px' } });
        } else {
            pretest.hasUnsavedChanges = true;
            pretest.save()
                .then(() => {
                    if (isMounted.current) {
                        setHasUnsavedChanges(false);
                        props.onSuccess(event, { updated: true });
                    }
                })
        }
    }

    const handleClose = event => {
        if (fn.isFunction(props.onClose)) {
            if (hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    setHasUnsavedChanges(false);
                    pretest.data.pretestData.clear();
                    props.onClose(event);
                }
            } else {
                pretest.data.pretestData.clear();
                props.onClose(event);
            }
        }
        setIsListeningFromDevice(false);
    }

    const renderPretestTemplateContent = ({ pretestPublishedTemplate }) => {
        if (isMounted.current && pretestPublishedTemplate) {
            const { modalSettings, definition } = pretestPublishedTemplate.definition;

            if (definition && definition.length > 0) {
                return definition.map((o) => {
                    return <div
                        key={o.id}
                        data-grid={o.position}
                    >
                        {
                            isMounted.current ?
                                <>
                                    {
                                        mth.isModalElement(o) ? mth.renderElement(o, {
                                            defaultValue: null,
                                            modalSettings: modalSettings,
                                        }) : null
                                    }
                                    {
                                        tch.isControlElement(o) ? <Observer>{() =>
                                            tch.renderControl(o, {
                                                readOnly: false,
                                                value: getControlValue(o),
                                                onClick: handleControlClick
                                            })
                                        }</Observer> : null
                                    }
                                    {
                                        tih.isInputElement(o) ? <Observer>{() =>
                                            tih.renderInput(o, {
                                                readOnly: false,
                                                value: getValue(o.key),
                                                onChange: handleDataChange(o),
                                                onBlur: handleInputFormatting,
                                            })
                                        }</Observer> : null
                                    }
                                </> : null
                        }
                    </div>
                })
            }
        }
    }

    const getControlValue = control => {
        let value = '';

        switch (control.type) {
            case tch.TEMPLATE_CONTROL_CALCULATION_TEXTBOX:
                let equation = control.metadata.equation;
                const variables = mh.parseVariables(equation);

                if (variables && variables.length > 0) {
                    for (let i = 0; i < variables.length; i++) {
                        const data = pretest.data.pretestData.filter(d => d.id === variables[i].key)[0];
                        equation = data ? equation.replace(variables[i].markup, data.value) : '';
                    }
                    console.log(evaluate(equation));
                    try {
                        value = Math.round((evaluate(equation) + Number.EPSILON) * 10000) / 10000;
                    } catch {
                        value = '';
                    }
                }
                return value;

            default:
                return value;
        }
    }

    const getValue = (key) => {
        const data = pretest.data.pretestData.filter(d => d.id === key);
        if (data && data.length > 0) {
            return data[0].value;
        }

        return null;
    }

    const setValue = (key, value) => {
        const index = pretest.data.pretestData.findIndex(d => d.id === key);

        if (index >= 0) {
            if (value) {
                pretest.data.pretestData[index].value = value;
            } else {
                pretest.data.pretestData.splice(index, 1);
            }
        } else {
            pretest.data.pretestData.push({ id: key, value: value });
        }

        pretest.hasUnsavedChanges = true;
        setHasUnsavedChanges(true);
    }

    const setGridRendered = () => {
        if (pretest.selectedTypes) {
            renderTimer.current = setTimeout(() => {
                setIsGridRendered(true);
                th.rerenderTemplate();
            }, 100)
        }
    }

    const getTemplateCols = ({ pretestPublishedTemplate }) => {
        let grid;

        if (pretestPublishedTemplate) {
            const { modalSettings } = pretestPublishedTemplate.definition;
            grid = modalSettings.size.grid;
        } else {
            grid = mth.getSizeDimension('medium').grid;
        }

        return { lg: grid, md: grid, sm: grid, xs: grid, xxs: grid }
    }

    const getTemplateGridStyle = ({ pretestPublishedTemplate }, index) => {
        if (pretestPublishedTemplate) {
            const { modalSettings } = pretestPublishedTemplate.definition;

            if (modalSettings) {
                const size = modalSettings.size ? modalSettings.size : mth.getSizeDimension('medium');
                const margins = modalSettings.margins ? modalSettings.margins : mth.MODAL_TEMPLATE_MARGINS;

                return {
                    width: `${size.width}px`,
                    padding: `${margins}px`,
                    marginTop: index > 0 ? `-${margins}px` : null,
                }
            }
        }

        return {
            width: `${mth.getSizeDimension('medium').width}px`,
            height: `${mth.getSizeDimension('medium').height}px`,
            padding: `${mth.MODAL_TEMPLATE_MARGINS / 2}px`,
        }
    }

    const applyInputFormatting = (key, value, metadata) => {
        if (!!key && !!metadata) {
            let updatedValue = value;
            const isDataTypeValid = tih.validateDataType(metadata, updatedValue);

            if (isDataTypeValid) {
                updatedValue = tih.applyPadding(metadata, updatedValue);
                updatedValue = tih.applyFormat(metadata, updatedValue);
            }

            if (value !== updatedValue) {
                setValue(key, updatedValue);
                return true;
            }
        }

        return false;
    }

    return <>
        <Observer>{() =>
            <>
                {
                    props.display && props.mode.toLowerCase() === 'create' ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleClose(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <Observer>{() =>
            pretest.isReady && pretest.data && pretest.selectedTypes && pretest.selectedTypes.length > 0 ?
                <div
                    className={'modal-dialog animated fastest' + (props.ready ? ' zoomIn' : ' zoomOut')}
                    role='document'
                    style={{
                        width: `${Math.max.apply(Math, pretest.selectedTypes.map(t => { return t.pretestPublishedTemplate.definition.modalSettings.size.width }))}px`,
                        maxWidth: `${Math.max.apply(Math, pretest.selectedTypes.map(t => { return t.pretestPublishedTemplate.definition.modalSettings.size.width }))}px`
                    }}
                >
                    <div className='modal-content'>
                        <Observer>{() => <LoadingOverlay isLoading={pretest.isSaving} />}</Observer>
                        <div className='modal-header'>
                            <div className='actions right-actions'>
                                <ul>
                                    <li>
                                        <button
                                            data-pretest-save
                                            type='button'
                                            className='btn btn-success px-3'
                                            onClick={handleSave}
                                        >
                                            Save
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            type='button'
                                            className='btn btn-icon btn-close'
                                            onClick={handleClose}
                                        >
                                            <i className='close-icon fal fa-times fs-xl'></i>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div ref={modalBodyRef} className='modal-body p-0'>
                            <FadeIn>
                                <div className='pretest-header'>
                                    <div className='pretest-description'>
                                        <Observer>{() =>
                                            <>
                                                {
                                                    pretest.selectedTypes.map((t, ti) => {
                                                        return <div
                                                            key={`pretest-template-${ti}`}
                                                        >
                                                            <div
                                                                className='form-template form-template-internal'
                                                                style={getTemplateGridStyle(t, ti)}
                                                            >
                                                                <h3 className='py-0 px-1'>{t.name}</h3>
                                                                <TemplateGrid
                                                                    isEditable={false}
                                                                    cols={getTemplateCols(t)}
                                                                    rowHeight={mth.MODAL_TEMPLATE_ROW_HEIGHT}
                                                                    margin={[0, 0]}
                                                                >
                                                                    {renderPretestTemplateContent(t)}
                                                                </TemplateGrid>
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </>
                                        }</Observer>
                                        {setGridRendered()}
                                    </div>
                                </div>
                            </FadeIn>
                            {/* <div className='text-hidden'>{pretest.selectedTypes && pretest.selectedTypes.length > 0 ? pretest.selectedTypes[0].device.listenerId : null}</div> */}
                        </div>
                    </div>
                </div> : null
        }</Observer>
        <BodyEnd>{dh.renderDeviceSyncTrigger(deviceSyncTriggerIdRef.current)}</BodyEnd>
        {
            isListeningFromDevice ?
                <BodyEnd>
                    <div className={'device-overlay'} style={{ zIndex: fn.getHighestZIndex() + 1 }}>
                        <div className='device-wrapper'>
                            <div className='device-message'>
                                <h1 className='text-primary-900 fw-500'>{props.device.name} is ready</h1>
                                <h5 className='text-gray-800 mb-4'>Listening for input...</h5>
                                <a href={dh.getDeviceSyncCancel()} className='btn btn-link p-0' onClick={() => { setIsListeningFromDevice(false) }}>Cancel</a>
                            </div>
                        </div>
                    </div>
                </BodyEnd> : null
        }
    </>
}

export default CreatePretest;