import React, { useContext, useEffect, useState, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import FadeIn from 'react-fade-in';
import { toJS } from 'mobx';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import { GlobalHotKeys } from 'react-hotkeys';
import { DateTimePicker, DropdownList, Multiselect } from 'react-widgets'
import { toast } from 'react-toastify';
import uuid from 'react-uuid';

import BodyEnd from '../../_shared/BodyEnd';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import CustomerFilter from '../../_shared/CustomerFilter';
import { quickDrawerFocus } from '../../_shared/QuickDrawer';
import ConfirmModal from '../../_shared/ConfirmModalComponent';

import GroupAppointmentCreateStore from '../../../../stores/GroupAppointmentCreateStore';
import CustomerCreateStore from '../../../../stores/CustomerCreateStore';
import AuthStore from '../../../../stores/AuthStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';
import CacheStore from '../../../../stores/CacheStore';

import api from '../../../../api';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as ph from '../../../../utilities/personHelper';
import * as ch from '../../../../utilities/customerHelper';
import * as sys from '../../../../utilities/systemHelper';
import * as oh from '../../../../utilities/operationHelper';
import * as bh from '../../../../utilities/badgeHelper';

import './NewGroupAppointment.scss';

moment.locale('en');
momentLocalizer();

const GROUP_APPOINTMENT_CUSTOMER_INPUT_ID = 'group_appointment_customer_input_';

function NewGroupAppointment(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const focusTimer = useRef(null);
    const searchTimer = useRef(null);
    const startTimePickerTimer = useRef(null);
    const endTimePickerTimer = useRef(null);
    const confirmModalRef = useRef(null);
    const newAppointmentCustomerFilterRef = useRef([]);
    const cache = useContext(CacheStore);
    const groupAppointment = useContext(GroupAppointmentCreateStore);
    const newCustomer = useContext(CustomerCreateStore);
    const auth = useContext(AuthStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [isReady, setIsReady] = useState(false);
    const [services, setServices] = useState([]);
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [startTimePickerOpen, setStartTimePickerOpen] = useState(false);
    const [endTimePickerOpen, setEndTimePickerOpen] = useState(false);
    const [customerSearchResult, setCustomerSearchResult] = useState('');
    const [conflicts, setConflicts] = useState(false);
    const [eligibles, setEligibles] = useState(null);
    const [ineligibles, setIneligibles] = useState(null);
    const [mohReady, setMohReady] = useState(null);
    const [relationships, setRelationships] = useState([]);
    const [relationshipUpdates, setRelationshipUpdates] = useState([]);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        quickDrawerFocus(props.drawer);
        checkConflicts();

        api.Services.all()
            .then(({ data }) => {
                if (isMounted.current) {
                    if (data) {
                        const bookableServices = data.filter(d => !!d.isBookable);
                        if (bookableServices && bookableServices.length > 0) {
                            setServices(bookableServices);
                        }
                    }
                }
            })
            .finally(() => {
                if (isMounted.current) {
                    setIsReady(true);
                    focusTimer.current = setTimeout(() => {
                        if (isMounted.current && newAppointmentCustomerFilterRef.current && newAppointmentCustomerFilterRef.current[0] && newAppointmentCustomerFilterRef.current[0].element) {
                            if (props.extraProps && props.extraProps.customer) {
                                handleCustomerSearchClick(0, props.extraProps.customer);

                                if (props.extraProps.services) {
                                    groupAppointment.data.customerServices[0].services = props.extraProps.services;

                                    setTimeout(() => {
                                        checkEligibility();
                                    }, 300);
                                }

                                checkConflicts();
                            }
                            else {
                                newAppointmentCustomerFilterRef.current[0].element.focus();
                            }
                        }
                    }, 500);
                }
            })

        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
            if (searchTimer.current) { clearTimeout(searchTimer.current); searchTimer.current = null; }
            if (startTimePickerTimer.current) { clearTimeout(startTimePickerTimer.current); startTimePickerTimer.current = null; }
            if (endTimePickerTimer.current) { clearTimeout(endTimePickerTimer.current); endTimePickerTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (groupAppointment.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    const newCustomers = groupAppointment.data.customerServices.filter(s => !s.customerId && s.customer).map(s => { return s.customer });
                    if (newCustomers && newCustomers.length > 0) {
                        confirmModalRef.current.show({
                            icon: <i className={`${oh.getIcon('customer', 'new')} text-success mr-2`}></i>,
                            message: <><span><strong>Save new customer(s)</strong> before exiting?</span></>,
                            option1ClassName: 'btn btn-success shadow-0 bootbox-accept',
                            option1Text: 'Yes',
                            cancelText: 'No',
                            onOption1Click: handleSaveNewCustomersBeforeExit,
                            onCancel: () => { props.onCancel(); }
                        })
                    }
                    else {
                        props.onCancel();
                    }
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSaveNewCustomersBeforeExit = (event) => {
        handleCommitUpdate(event, false)
            .then((response) => {
                if (isMounted.current && response && response.length > 0) {
                    toast.dark(() => <p data-cust-ctd>Customer(s) created.</p>);
                }

                if (props.onCancel && fn.isFunction(props.onCancel)) {
                    props.onCancel();
                }
            });
    }

    const checkConflicts = () => {
        groupAppointment.checkConflicts()
            .then(data => {
                if (isMounted.current) {
                    setConflicts(data);
                }
            })
    }

    const checkEligibility = () => {
        if (groupAppointment.data && groupAppointment.data.customerServices && groupAppointment.data.customerServices.length > 0) {
            groupAppointment.checkEligibility()
                .then(data => {
                    if (isMounted.current) {
                        const eligibleData = data && data.some(d => d.isPublicHealthChecked && d.isEligible) ? data.filter(d => d.isPublicHealthChecked && d.isEligible) : null;
                        const ineligibleData = data && data.some(d => !d.isPublicHealthChecked || !d.isEligible) ? data.filter(d => !d.isPublicHealthChecked || !d.isEligible) : null;
                        const isMohReady = data && !data.some(e => !e.isMohReady);

                        setEligibles(eligibleData);
                        setIneligibles(ineligibleData);
                        setMohReady(isMohReady);
                    }
                })
        } else {
            setEligibles(null);
            setIneligibles(null);
            setMohReady(null);
        }
    }

    const handleResourceChange = ({ id }) => {
        groupAppointment.data.userId = id;
        groupAppointment.data.duration = groupAppointment.recommendedDuration;        
        groupAppointment.hasUnsavedChanges = true;
        checkConflicts();
    }

    const handleDatePickerDisplay = event => {
        setStartTimePickerOpen(false);
        setEndTimePickerOpen(false);
        if (!datePickerOpen) {
            setDatePickerOpen('date');
        }
    }

    const handleDatePickerChange = value => {
        const newStart = moment(`${moment(value).format('YYYY-MM-DD')} ${groupAppointment.data.start.format('h:mm a')}`, 'YYYY-MM-DD h:mm a');
        groupAppointment.data.start = newStart;
        groupAppointment.hasUnsavedChanges = true;
        checkConflicts();
        checkEligibility();
        setDatePickerOpen(false);
    }

    const handleStartTimePickerDisplay = event => {
        setDatePickerOpen(false);
        setEndTimePickerOpen(false);
        if (!startTimePickerOpen) {
            setStartTimePickerOpen('time');
            startTimePickerTimer.current = setTimeout(() => {
                const picker = document.querySelector('#startTimePicker .rw-state-selected');
                if (picker) {
                    picker.focus();
                }  // UI HACK: need to pass focus to the selected item otherwise mouse scroll and then click will cause focus to trigger.
            }, 300)
        }
    }

    const handleStartTimePickerChange = value => {
        const newStart = moment(`${groupAppointment.data.start.format('YYYY-MM-DD')} ${moment(value).format('h:mm a')}`, 'YYYY-MM-DD h:mm a');
        groupAppointment.data.start = newStart;
        groupAppointment.hasUnsavedChanges = true;
        checkConflicts();
        setStartTimePickerOpen(false);
    }

    const handleEndTimePickerDisplay = event => {
        setDatePickerOpen(false);
        setStartTimePickerOpen(false);
        if (!endTimePickerOpen) {
            setEndTimePickerOpen('time');
            endTimePickerTimer.current = setTimeout(() => {
                const picker = document.querySelector('#endTimePicker .rw-state-selected');
                if (picker) {
                    picker.focus();
                }  // UI HACK: need to pass focus to the selected item otherwise mouse scroll and then click will cause focus to trigger.
            }, 300)
        }
    }

    const handleEndTimePickerChange = value => {
        const start = groupAppointment.data.start.clone();
        const newEnd = moment(`${groupAppointment.end.format('YYYY-MM-DD')} ${moment(value).format('h:mm a')}`, 'YYYY-MM-DD h:mm a');
        const duration = newEnd.diff(start, 'minutes');
        groupAppointment.data.duration = duration;
        groupAppointment.hasUnsavedChanges = true;
        checkConflicts();
        setEndTimePickerOpen(false);
    }

    const handlePrimaryContactChange = (primaryContact) => {
        groupAppointment.data.primaryContactId = primaryContact ? primaryContact.id : null;
        groupAppointment.data.primaryContact = primaryContact;

        if (primaryContact) {
            api.CustomerRelationships.get(primaryContact.id)
                .then(({ data }) => {
                    const filteredRelationships = data && data.length > 0 && data.some(r => r.customerId === primaryContact.id) ? data.filter(r => r.customerId === primaryContact.id) : [];

                    for (let i = 0; i < groupAppointment.data.customerServices.length; i++) {
                        if (groupAppointment.data.customerServices[i].customer) {
                            if (groupAppointment.data.customerServices[i].customer.id === primaryContact.id) {
                                groupAppointment.data.customerServices[i].relationship = null;
                            }
                            else {
                                const filteredRelationship = filteredRelationships.filter(r => r.relatedTo.id === groupAppointment.data.customerServices[i].customer.id)[0];

                                if (filteredRelationship) {
                                    groupAppointment.data.customerServices[i].relationship = filteredRelationship.relationship;
                                }
                                else {
                                    groupAppointment.data.customerServices[i].relationship = null;
                                }
                            }
                        }
                    }
                })
        }
        else {
            for (let i = 0; i < groupAppointment.data.customerServices.length; i++) {
                groupAppointment.data.customerServices[i].relationship = null;
            }
        }

        setRelationshipUpdates([]);
        groupAppointment.hasUnsavedChanges = true;
    }

    const handleNewCustomer = event => {
        let extraProps;

        if (!newCustomer.isReady) {
            newCustomer.initialize(false);
        }
        if (!!groupAppointment.data.primaryContact) {
            extraProps = { primaryContact: toJS(groupAppointment.data.primaryContact) };
        }
        // setExistingCustomerData(null);
        setCustomerSearchResult(null);
        quickDrawer.activateQuickDrawer('customer', 'create', extraProps, handleNewCustomerSuccess, handleNewCustomerCancel);
    }

    const handleNewCustomerSuccess = customer => {
        if (customer && customer.data) {
            const index = groupAppointment.data.customerServices.length - 1;
            const { data } = customer;

            data.id = uuid();
            data.referenceId = data.id;
            groupAppointment.data.customerServices[index].customer = data;

            if (groupAppointment.data.customerServices.length < 2) {
                groupAppointment.addNewCustomerService();
            }

            if (!groupAppointment.data.primaryContact) {
                groupAppointment.data.primaryContact = data;
                groupAppointment.data.primaryContactId = data.id;
            }

            newCustomer.clear();
            groupAppointment.hasUnsavedChanges = true;
        }
    }

    const handleNewCustomerCancel = event => {
        newCustomer.clear();
    }

    const handleCustomerSearchChange = (event, index, term) => {
        if (groupAppointment.data.customerServices[index].customerId || groupAppointment.data.customerServices[index].customer) {
            groupAppointment.data.customerServices[index].customerId = null;
            groupAppointment.data.customerServices[index].customer = null;
            groupAppointment.data.customerServices[index].customer.services.clear();
        }

        if (searchTimer.current) {
            clearTimeout(searchTimer.current);
        }

        if (term && term.length >= 2) {
            const exclude = groupAppointment.data.customerServices.some(c => !!c.customer) ? groupAppointment.data.customerServices.filter(c => !!c.customer).map(c => { return c.customer.id }) : null;
            api.Customers.fullSearch(term, false, false, 5, null, null, null, null, null, exclude)
                .then(({ data }) => {
                    if (isMounted.current) {
                        setCustomerSearchResult(data.result);
                    }
                })
        } else {
            setCustomerSearchResult(null);
        }
        setEligibles(null);
        setIneligibles(null);
    }

    const handleCustomerSearchClick = (index, customer) => {
        newAppointmentCustomerFilterRef.current[index].isLoading(true);
        setCustomerSearchResult(null);

        api.Customers.get(customer.id)
            .then(({ data: customerData }) => {
                if (isMounted.current) {
                    if (newAppointmentCustomerFilterRef.current && newAppointmentCustomerFilterRef.current[index]) {
                        newAppointmentCustomerFilterRef.current[index].isLoading(false);
                    }

                    if (groupAppointment.data.customerServices && groupAppointment.data.customerServices[index]) {
                        groupAppointment.data.customerServices[index].customerId = customerData.id;
                        groupAppointment.data.customerServices[index].customer = customerData;
                    }

                    if (groupAppointment.data.customerServices.length === 1) {
                        groupAppointment.addNewCustomerService();
                    }

                    checkConflicts();
                    refreshPrimaryContacts()
                        .then((relationshipData) => {
                            if (isMounted.current) {
                                if (!groupAppointment.data.primaryContact) {
                                    if (customerData.primaryContactPerson) {
                                        groupAppointment.data.primaryContactId = customerData.primaryContactPerson.id;
                                        groupAppointment.data.primaryContact = customerData.primaryContactPerson;
                                    }
                                    else {
                                        groupAppointment.data.primaryContactId = customerData.id;
                                        groupAppointment.data.primaryContact = customerData;
                                    }
                                }

                                if (relationshipData && relationshipData.length > 0) {
                                    const filteredRelationships = relationshipData.filter(r => r.customerId === groupAppointment.data.primaryContact.id);

                                    if (filteredRelationships && filteredRelationships.length > 0) {
                                        const filteredRelationship = filteredRelationships.filter(r => r.relatedTo.id === customerData.id)[0];

                                        if (filteredRelationship) {
                                            groupAppointment.data.customerServices[index].relationship = filteredRelationship.relationship;
                                        }
                                    }
                                }
                            }
                        })

                    setTimeout(() => {
                        if (isMounted.current) {
                            const input = document.querySelector(`#${GROUP_APPOINTMENT_CUSTOMER_INPUT_ID}${(index + 1)}`);

                            if (input) {
                                input.focus();
                            }
                        }
                    }, 1000);
                    groupAppointment.hasUnsavedChanges = true;
                }
            })
    }

    const handleCustomerAdd = (event) => {
        groupAppointment.addNewCustomerService();
        groupAppointment.hasUnsavedChanges = true;
    }

    const handleCustomerRemove = (event, index) => {
        const primaryContacts = getPrimaryContactOptions();

        groupAppointment.data.customerServices.splice(index, 1);

        if (!groupAppointment.data.customerServices) {
            groupAppointment.data.customerServices = [];
        }

        if (groupAppointment.data.customerServices.length === 0) {
            groupAppointment.addNewCustomerService();
        }

        if (groupAppointment.data.customerServices.some(c => c.customer) && !primaryContacts.some(p => p.id === groupAppointment.data.primaryContactId)) {
            groupAppointment.data.primaryContact = groupAppointment.data.customerServices.filter(c => c.customer)[0].customer;
            groupAppointment.data.primaryContactId = groupAppointment.data.customerServices.filter(c => c.customer)[0].customer.id;
        }
        else if (!groupAppointment.data.customerServices.some(c => c.customer)) {
            groupAppointment.data.primaryContact = null;
            groupAppointment.data.primaryContactId = null;
            setRelationships([]);
        }

        checkEligibility();
        checkConflicts();
        groupAppointment.hasUnsavedChanges = true;
    }

    const handleRelationshipChange = (event, customer, index) => {
        if (groupAppointment.data.primaryContact && customer && customer.id) {
            const relationship = event.target.value;
            const tempRelationshipUpdates = Array.from(new Set([...relationshipUpdates]));
            const ri = relationshipUpdates.findIndex(r => r.party1Id === groupAppointment.data.primaryContact.id && r.party2Id === customer.id);
            const relationshipUpdate = {
                party1Id: groupAppointment.data.primaryContact.id,
                party2Id: customer.id,
                relationshipType: relationship
            }

            if (ri > -1) {
                tempRelationshipUpdates[ri] = relationshipUpdate;
            }
            else {
                tempRelationshipUpdates.push(relationshipUpdate);
            }

            setRelationshipUpdates(tempRelationshipUpdates);
            groupAppointment.data.customerServices[index].relationship = relationship;
            groupAppointment.hasUnsavedChanges = true;
        }
    }

    const handleServiceSelection = (index, tag, metadata) => {
        switch (metadata.action) {
            case 'insert':
                if (!groupAppointment.data.customerServices[index].services.some(s => s.id === metadata.dataItem.id)) {
                    groupAppointment.data.customerServices[index].services.push({
                        id: metadata.dataItem.id,
                        code: metadata.dataItem.code,
                        name: metadata.dataItem.code,
                        duration: metadata.dataItem.duration,
                        isRepeatable: metadata.dataItem.isRepeatable,
                        userOverrides: metadata.dataItem.userOverrides
                    });
                }

                if (!!groupAppointment.data.existingAppointmentId) {  // This means it's converting from an individual appointment to a group appointment.
                    groupAppointment.data.duration = groupAppointment.data.duration + metadata.dataItem.duration;
                }
                break;

            case 'remove':
                const serviceIndex = groupAppointment.data.customerServices[index].services.findIndex(s => s.id === metadata.dataItem.id);
                if (serviceIndex !== -1) {
                    groupAppointment.data.customerServices[index].services.splice(serviceIndex, 1);

                    if (!!groupAppointment.data.existingAppointmentId) {  // This means it's converting from an individual appointment to a group appointment.
                        groupAppointment.data.duration = groupAppointment.data.duration - metadata.dataItem.duration;
                    }
                }
                break;

            default:
                break;
        }

        if (!groupAppointment.data.existingAppointmentId) {
            groupAppointment.data.duration = groupAppointment.recommendedDuration;
        }

        groupAppointment.hasUnsavedChanges = true;
        checkConflicts();
        checkEligibility();
    }

    const handleDurationChange = event => {
        groupAppointment.data.duration = parseInt(event.target.value, 10);
        groupAppointment.hasUnsavedChanges = true;
        checkConflicts();
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            groupAppointment.checkEligibility(true)
                .then(eligibilityData => {
                    if (isMounted.current) {
                        const eligibleData = eligibilityData && eligibilityData.some(d => d.isEligible) ? eligibilityData.filter(d => d.isEligible) : null;
                        const ineligibleData = eligibilityData && eligibilityData.some(d => !d.isEligible) ? eligibilityData.filter(d => !d.isEligible) : null;

                        setEligibles(eligibleData);
                        setIneligibles(ineligibleData);

                        if (!ineligibleData) {
                            groupAppointment.checkConflicts(true)
                                .then(conflictData => {
                                    if (isMounted.current) {
                                        if (!conflictData) {
                                            handleCommitUpdate(event, true);
                                        } else {
                                            showConfirmConflictsModal();
                                        }
                                    }
                                })
                        } else {
                            confirmModalRef.current.show({
                                icon: <i className={`${oh.getIcon('appointment', 'new')} text-warning mr-2`}></i>,
                                message: <>Some patients&nbsp;<strong>might not be eligible</strong>&nbsp;for their service(s).  Continue?</>,
                                option1ClassName: 'btn btn-warning shadow-0 bootbox-accept',
                                onOption1Click: handleIneligibleConfirm,
                                onCancel: handleIneligibleCancel
                            });
                        }
                    }
                })
                .catch(error => {
                    console.error(error);
                })
        }
    }

    const showConfirmConflictsModal = () => {
        confirmModalRef.current.show({
            icon: <i className={`${oh.getIcon('appointment', 'new')} text-warning mr-2`}></i>,
            message: <><span>Appointment&nbsp;<strong>conflict(s) detected</strong>.  Continue?</span></>,
            option1ClassName: 'btn btn-warning shadow-0 bootbox-accept',
            onOption1Click: handleConflictsConfirm,
            onCancel: handleConflictsCancel
        });
    }

    const handleSaveNewCustomers = event => {
        return new Promise((resolve) => {
            const newCustomers = groupAppointment.data.customerServices.filter(s => !s.customerId && s.customer).map(s => { return s.customer });

            if (!!newCustomers && newCustomers.length > 0) {
                const newCustomerRequests = newCustomers.map(n => {
                    return api.Customers.create(n)
                });

                Promise.all(newCustomerRequests)
                    .then((response) => {
                        if (isMounted.current) {
                            if (response && response.length > 0) {
                                const defaultRelationshipRequests = [];

                                for (let ri = 0; ri < response.length; ri++) {
                                    const tempCustomerServices = toJS(groupAppointment.data.customerServices);
                                    const ci = tempCustomerServices.findIndex(c => !c.customerId && c.customer.referenceId === response[ri].data.referenceId);
                                    const ui1 = relationshipUpdates.findIndex(u => u.party1Id === response[ri].data.referenceId);
                                    const ui2 = relationshipUpdates.findIndex(u => u.party2Id === response[ri].data.referenceId);
                                    const tempRelationshipUpdates = Array.from(new Set([...relationshipUpdates]));

                                    if (ri > 0) {
                                        defaultRelationshipRequests.push(
                                            api.CustomerRelationships.create({
                                                party1Id: response[0].data.id,
                                                party2Id: response[ri].data.id,
                                            })
                                        );
                                    }

                                    if (ci > -1) {
                                        tempCustomerServices[ci].customerId = response[ri].data.id;
                                        tempCustomerServices[ci].customer.id = response[ri].data.id;

                                        groupAppointment.data.customerServices = tempCustomerServices;
                                    }

                                    if (ui1 > -1) {
                                        tempRelationshipUpdates[ui1].party1Id = response[ri].data.id;
                                    }

                                    if (ui2 > -1) {
                                        tempRelationshipUpdates[ui2].party2Id = response[ri].data.id;
                                    }

                                    if (ui1 > -1 || ui2 > -1) {
                                        setRelationshipUpdates(tempRelationshipUpdates);
                                    }

                                    if (groupAppointment.data.primaryContactId === response[ri].data.referenceId) {
                                        groupAppointment.data.primaryContactId = response[ri].data.id
                                        groupAppointment.data.primaryContact.id = response[ri].data.id
                                    }
                                }

                                if (defaultRelationshipRequests && defaultRelationshipRequests.length > 0) {
                                    Promise.all(defaultRelationshipRequests);
                                }
                            }

                            resolve();
                        }
                    })
            }
            else {
                resolve();
            }
        })
    }

    const handleCommitUpdate = (event, saveGroupAppointment) => {
        setIsSaving(true);

        handleSaveNewCustomers(event)
            .then(() => {
                if (isMounted.current) {
                    const requests = [];
                    const relationshipOptions = Array.isArray(relationshipUpdates) ? JSON.parse(JSON.stringify(relationshipUpdates)) : [];

                    if (saveGroupAppointment) {
                        requests.push(groupAppointment.save(true));

                        // Create default relationships between everyone in this appointment.  It won't overwrite if there is existing relationship.
                        if (groupAppointment.data.primaryContact) {
                            const primaryContact = groupAppointment.data.primaryContact;

                            for (let c1 = 0; c1 < groupAppointment.data.customerServices.length; c1++) {
                                const party1 = groupAppointment.data.customerServices[c1].customer;

                                if (party1 && primaryContact.id !== party1.id) {
                                    // Check to make sure no manual updates have been made already
                                    if (!relationshipOptions.some(u1 => u1.party1Id === primaryContact.id && u1.party2Id === party1.id)) {
                                        relationshipOptions.push({
                                            party1Id: primaryContact.id,
                                            party2Id: party1.id,
                                            overwrite: false,
                                        })
                                    }
                                }

                                for (let p2 = 0; p2 < groupAppointment.data.customerServices.length; p2++) {
                                    const party2 = groupAppointment.data.customerServices[p2].customer;

                                    if (party1 && party2 && party1.id !== party2.id) {
                                        // Check to make sure no manual updates have been made already
                                        if (!relationshipOptions.some(u1 => u1.party1Id === party1.id && u1.party2Id === party2.id)) {
                                            relationshipOptions.push({
                                                party1Id: party1.id,
                                                party2Id: party2.id,
                                                overwrite: false,
                                            })
                                        }
                                    }
                                }
                            }
                        }
                    }

                    if (relationshipOptions && relationshipOptions.length > 0) {
                        requests.push(api.Batch.relationships({ relationships: relationshipOptions }));
                    }

                    Promise.all(requests)
                        .then(response => {
                            if (isMounted.current) {
                                if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                                    const data = Array.isArray(response) && response[0] ? response[0] : (response && response.data ? response.data : null);
                                    props.onSuccess(event, { updated: saveGroupAppointment, data: saveGroupAppointment ? data : null });
                                }
                            }
                        })
                        .finally(() => {
                            setIsSaving(false);
                        })
                }
            })
    }

    const handleIneligibleConfirm = event => {
        groupAppointment.checkConflicts(true)
            .then(conflicts => {
                if (isMounted.current) {
                    if (!conflicts) {
                        handleCommitUpdate(event, true);
                    } else {
                        showConfirmConflictsModal();
                    }
                }
            })

        confirmModalRef.current.close();
    }

    const handleIneligibleCancel = event => {
        confirmModalRef.current.close();
    }

    const handleConflictsConfirm = event => {
        handleCommitUpdate(event, true);
        confirmModalRef.current.close();
    }

    const handleConflictsCancel = event => {
        confirmModalRef.current.close();
    }

    // const getCustomer = () => {
    //     return (newCustomerData ? newCustomerData : existingCustomerData);
    // }

    const getAppointmentDateTime = () => {
        if (!groupAppointment.data.start) return null;

        const start = groupAppointment.data.start.clone();
        const end = groupAppointment.end ? groupAppointment.end.clone() : null;
        const hasEndTime = !!end;
        const weekday = groupAppointment.data.start.format('dddd');
        const day = start.format('D');
        const ordinal = start.format('Do').replace(day, '');
        const dateHtml = `${start.format('MMMM D')}<sup>${ordinal}</sup>${((start.year() !== moment().year()) ? `, ${start.format('YYYY')}` : '')}`;
        const startTimeHtml = `${start.format('h:mm')}${(!hasEndTime || start.format('a') !== end.format('a') ? ` ${start.format('a')}` : '')}`;
        const endTimeHtml = hasEndTime ? `${end.format('h:mm')} ${end.format('a')}` : '';

        return <ul className='list-inline no-style m-0'>
            <li className='list-inline-item m-0' onClick={handleDatePickerDisplay}>
                <div className='control transparent'>
                    <div
                        className={'control-overlay' + (datePickerOpen ? '' : ' d-none')}
                        onClick={() => { setDatePickerOpen(false) }}
                    ></div>
                    <DateTimePicker
                        id='datePicker'
                        defaultOpen={false}
                        open={datePickerOpen}
                        dateFormat={dt => String(dt.getDate())}
                        dayFormat={day => ['S', 'M', 'T', 'W', 'T', 'F', 'S'][day.getDay()]}
                        views={['month', 'year']}
                        value={groupAppointment.data.start.toDate()}
                        footer={false}
                        date={true}
                        time={false}
                        onSelect={handleDatePickerChange}
                    />
                </div>
                <div className={'text' + (datePickerOpen ? ' active' : '')}>
                    <small className='weekday'>{weekday}</small>
                    <span className='date' dangerouslySetInnerHTML={{ __html: dateHtml }}></span>
                </div>
            </li>
            <li className='list-inline-item my-0 mx-1'><small>@</small></li>
            <li className='list-inline-item m-0' onClick={handleStartTimePickerDisplay}>
                <div className='control transparent'>
                    <div
                        className={'control-overlay' + (startTimePickerOpen ? '' : ' d-none')}
                        onClick={() => { setStartTimePickerOpen(false) }}
                    ></div>
                    <DateTimePicker
                        id='startTimePicker'
                        defaultOpen={false}
                        open={startTimePickerOpen}
                        value={groupAppointment.startTime.toDate()}
                        step={5}
                        date={false}
                        time={true}
                        onSelect={handleStartTimePickerChange}
                    />
                </div>
                <div className={'text' + (startTimePickerOpen ? ' active' : '')}>
                    <span className='time' dangerouslySetInnerHTML={{ __html: startTimeHtml }}></span>
                </div>
            </li>
            {
                hasEndTime ? <>
                    <li className='list-inline-item my-0 mx-1'><small>to</small></li>
                    <li className='list-inline-item m-0' onClick={handleEndTimePickerDisplay}>
                        <div className='control transparent'>
                            <div
                                className={'control-overlay' + (endTimePickerOpen ? '' : ' d-none')}
                                onClick={() => { setEndTimePickerOpen(false) }}
                            ></div>
                            <DateTimePicker
                                id='endTimePicker'
                                defaultOpen={false}
                                open={endTimePickerOpen}
                                value={groupAppointment.endTime.toDate()}
                                step={5}
                                date={false}
                                time={true}
                                onSelect={handleEndTimePickerChange}
                            />
                        </div>
                        <div className={'text' + (endTimePickerOpen ? ' active' : '')}>
                            <span className='time' dangerouslySetInnerHTML={{ __html: endTimeHtml }}></span>
                        </div>
                    </li>
                </> : null
            }
        </ul>
    }

    const renderCustomer = (customerId, customer, existingServices, index) => {
        return <div className='customer-selected d-flex'>
            <div className='flex-1'>
                <div
                    className='profile-wrapper'
                >
                    <div className='profile'>
                        <span
                            className={`profile-image profile-initials rounded-circle d-flex text-white ${ch.getProfileColor(customer)} fs-sm mr-2`}
                            title={ph.getFullName(customer)}
                        >
                            {`${customer.firstName[0]}${customer.lastName[0]}`.toUpperCase()}
                        </span>
                    </div>
                    <div className='description flex-1'>
                        {
                            !customerId ?
                                <div>{bh.renderAppointmentNew(null, 'badge-sm fs-sm mb-1')}</div> : null
                        }
                        <div className='d-flex'>
                            <div className='flex-1'>
                                <div
                                    className='text-gray-700'
                                    title={customer.dateOfBirth ? `DOB: ${moment(customer.dateOfBirth).format('YYYY-MM-DD')}` : null}
                                >
                                    {ph.getFullName(customer, true)}
                                    {
                                        customer.dateOfBirth || customer.sex || customer.gender || customer.pronoun ?
                                            <small className='ml-2'>({`${ph.getAge(customer.dateOfBirth)} ${ph.getSexGenderPronounDisplay(customer)}`.trim()})</small> : null
                                    }
                                    {
                                        customer.dateOfBirth ?
                                            <div className='fs-sm info text-gray-700'><span className='text-nowrap mr-1'><strong className='mr-1'>DOB:</strong>{sys.getFormattedLongDate(customer.dateOfBirth)}</span></div> : null
                                    }
                                    {
                                        customer.patientProfile && customer.patientProfile.healthCardNumber ?
                                            <div className='fs-sm info text-gray-700'><span className='text-nowrap mr-1'><strong className='mr-1'>HC #:</strong>{ph.formatHealthCard(customer.patientProfile.healthCardNumber)}</span><small className={'text-nowrap'}>(Exp. {fn.formatDate(customer.patientProfile.healthCardExpiryDate, 'MMM Do, YYYY')})</small></div> : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='fs-sm mt-2'>
                            <select
                                className='custom-select form-control'
                                placeholder='Relationship'
                                disabled={!groupAppointment.data || !groupAppointment.data.primaryContact || groupAppointment.data.primaryContact.id === customer.id}
                                value={groupAppointment.data && groupAppointment.data.customerServices && groupAppointment.data.customerServices[index].relationship ? groupAppointment.data.customerServices[index].relationship : ''}
                                onChange={(e) => { handleRelationshipChange(e, customer, index) }}
                            >
                                <option value=''>{!groupAppointment.data || !groupAppointment.data.primaryContact || groupAppointment.data.primaryContact.id === customer.id ? 'Self' : 'Not confirmed'}</option>
                                <optgroup label='Common'>
                                    {
                                        ph.COMMON_RELATIONSHIP_TYPES.map((r, ri) => {
                                            return <option
                                                key={`customer_common_relationship_type_${customer.id}_${ri}`} value={r}>
                                                {
                                                    cache.getReferenceDataOptions('CustomerRelationshipType').some(o => o.key === r) ?
                                                        cache.getReferenceDataOptions('CustomerRelationshipType').filter(o => o.key === r)[0].value : r
                                                }
                                            </option>
                                        })
                                    }
                                </optgroup>
                                <optgroup label='Less common'>
                                    {
                                        cache.getReferenceDataOptions('CustomerRelationshipType').filter(option => !ph.COMMON_RELATIONSHIP_TYPES.some(o => o === option.key)).map((option, di) => {
                                            return <option key={`customer_less_common_relationship_type_${customer.id}_${di}`} value={option.key}>{option.value}</option>
                                        })
                                    }
                                </optgroup>
                            </select>
                            <div className='validate validate-required'>
                                <Multiselect
                                    allowCreate={false}
                                    data={renderServices()}
                                    valueField='id'
                                    textField='name'
                                    placeholder='Services'
                                    tagComponent={({ item }) => (
                                        <span
                                            className='tag'
                                            style={{
                                                backgroundColor: item.colorHexValue,
                                                borderColor: item.colorHexValue,
                                            }}
                                        >
                                            <strong>{item.code}</strong>
                                        </span>
                                    )}
                                    value={(existingServices && existingServices.length > 0 ? existingServices.map(s => { return s.id }) : [])}
                                    onChange={(tag, metadata) => { handleServiceSelection(index, tag, metadata) }}
                                />
                            </div>
                            {
                                index === (groupAppointment.data.customerServices.length - 1) ?
                                    <button
                                        type='button'
                                        className='btn btn-link px-0 pb-0'
                                        onClick={handleCustomerAdd}
                                    >Add another patient</button> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    const renderServices = () => {
        // const filteredServices = services.filter(s =>
        //     (!s.activeStartingDate || moment(s.activeStartingDate).isSameOrBefore(moment(appointment.data.start))) &&
        //     appointment.data.services.filter(a => a.id === s.id).length === 0);
        const filteredServices = services.filter(s =>
            (!s.activeStartingDate || moment(s.activeStartingDate).isSameOrBefore(moment(groupAppointment.data.start))));

        return filteredServices.map(s => {
            return {
                id: s.id,
                name: `${s.code} - ${s.name}`,
                code: s.code,
                colorHexValue: s.colorHexValue,
                duration: (s.defaultDurationInMinutes ? s.defaultDurationInMinutes : 0),
                isRepeatable: s.isRepeatable,
                userOverrides: s.userOverrides
            }
        })
    }

    const refreshPrimaryContacts = () => {
        return new Promise((resolve) => {
            const customerIds = Array.from([...new Set(groupAppointment.data.customerServices.filter(c => c.customerId).map(c => { return c.customerId }))]);

            if (customerIds && customerIds.length > 0 && groupAppointment.data.customerServices.some(c => c.customer && c.customer.primaryContactPersonId)) {
                const primaryContactPersonIds = Array.from([...new Set(groupAppointment.data.customerServices.filter(c => c.customer && c.customer.primaryContactPersonId).map(c => { return c.customer.primaryContactPersonId }))]);
                customerIds.push(...primaryContactPersonIds);
            }

            if (customerIds && customerIds.length > 0) {
                api.CustomerRelationships.search({
                    parameters: [{
                        field: 'Party1Id',
                        value: customerIds.join(','),
                        operator: 'Contains'
                    }],
                    includeTotalCount: false,
                })
                    .then(({ data }) => {
                        if (isMounted.current) {
                            if (data && data.result && data.result.length > 0) {
                                setRelationships(data.result);
                                resolve(data.result);
                            }
                            else {
                                setRelationships([]);
                                resolve();
                            }
                        }
                    })
            }
            else {
                setRelationships([]);
                resolve();
            }
        })
    }

    const getPrimaryContactOptions = () => {
        let result = [];

        if (groupAppointment.data.customerServices.some(c => c.customer)) {
            for (let ci = 0; ci < groupAppointment.data.customerServices.length; ci++) {
                if (groupAppointment.data.customerServices[ci].customer && !result.some(r => r.id === groupAppointment.data.customerServices[ci].customer.id)) {
                    result.push(groupAppointment.data.customerServices[ci].customer);
                }
            }
        }

        if (relationships && relationships.length > 0) {
            for (let ri = 0; ri < relationships.length; ri++) {
                if (!result.some(r => r.id === relationships[ri].relatedTo.id)) {
                    result.push(relationships[ri].relatedTo);
                }
            }
        }

        if (groupAppointment.data.primaryContact && !result.some(r => (
            r.id === groupAppointment.data.primaryContact.id ||
            r.id === groupAppointment.data.primaryContact.referenceId ||
            r.referenceId === groupAppointment.data.primaryContact.referenceId
        ))) {
            groupAppointment.data.primaryContact = null;
            groupAppointment.data.primaryContactId = null;
        }

        return result;
    }

    const getSuggestionOptions = () => {
        let result = [];

        if (relationships && relationships.length > 0) {
            for (let ri = 0; ri < relationships.length; ri++) {
                if (!groupAppointment.data.customerServices.some(c => c.customer && c.customer.id === relationships[ri].relatedTo.id) &&
                    !result.some(r => r.id === relationships[ri].relatedTo.id)) {
                    result.push(relationships[ri].relatedTo);
                }
            }
        }

        return result;
    }

    return <>
        {
            (datePickerOpen || startTimePickerOpen || endTimePickerOpen) ?
                <GlobalHotKeys
                    keyMap={{
                        closeAllPickers: ['esc'],
                    }}
                    handlers={{
                        closeAllPickers: event => {
                            if (!confirmModalRef.current || !confirmModalRef.current.isVisible) {
                                setDatePickerOpen(false);
                                setStartTimePickerOpen(false);
                                setEndTimePickerOpen(false);
                            }
                        }
                    }}
                    allowChanges={true}
                /> :
                <>
                    {
                        (props.drawer === quickDrawer.drawerOpened) ?
                            <GlobalHotKeys
                                keyMap={{
                                    close: ['esc'],
                                }}
                                handlers={{
                                    close: event => {
                                        if (!confirmModalRef.current || !confirmModalRef.current.isVisible) {
                                            handleCancel(event)
                                        }
                                    },
                                }}
                                allowChanges={true}
                            /> : null
                    }
                </>
        }
        <Observer>{() =>
            <form ref={validateRef} onSubmit={handleSubmit}>
                <fieldset disabled={newCustomer.isSaving || groupAppointment.isSaving || isSaving}>
                    <div className='quick-drawer'>
                        <QuickDrawerHeader
                            drawer={props.drawer}
                            icon={'fal fa-users-class'}
                            action='New'
                            category='Group Appointment'
                            className='appointments'
                            onCancel={handleCancel}
                        />
                        <div className='quick-drawer-body'>
                            {
                                isReady ?
                                    <FadeIn>
                                        <div className='new-group-appointment body-content'>
                                            {
                                                eligibles && cache.resources.filter(r => r.id === groupAppointment.data.userId)[0] ?
                                                    <section>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                {
                                                                    mohReady === true ?
                                                                        <div className='alert alert-info p-3 mb-0' role='alert'>
                                                                            <strong className='d-block mb-2'>Information</strong>
                                                                            <ul className='pl-3 mb-0'>
                                                                                {
                                                                                    eligibles.map((i, ii) => {
                                                                                        return <li key={`new_group_appointment_eligible_${ii}`}>{bh.renderServiceCode(i.service, 'float-left mr-1')} {ph.getPreferredFirstName(i.customer)}'s eligibility confirmed.</li>
                                                                                    })
                                                                                }
                                                                            </ul>
                                                                        </div> :
                                                                        <>
                                                                            {
                                                                                mohReady === false ?
                                                                                    <div className={'alert alert-warning p-3 mb-0'} role='alert'>
                                                                                        <strong className='d-block mb-2'>Warning</strong>
                                                                                        <ul className='pl-3 mb-0'>
                                                                                            <li><strong className='text-warning-900'>{auth.currentTenant.publicInsuranceUnitId} verification has not been setup.</strong></li>
                                                                                            {
                                                                                                eligibles.filter(e => e.isAgeChecked).map((i, ii) => {
                                                                                                    return <li key={`view_appointment_eligible_${ii}`}>{bh.renderServiceCode(i.service, 'float-left mr-1')} {ph.getPreferredFirstName(i.customer)}'s age eligibility confirmed.</li>
                                                                                                })
                                                                                            }
                                                                                            {
                                                                                                conflicts ?
                                                                                                    <>
                                                                                                        {
                                                                                                            conflicts.upcoming && conflicts.upcoming.length > 0 ?
                                                                                                                conflicts.upcoming.map((u, ui) => {
                                                                                                                    return <li key={`group_appointment_upcoming_${ui}`}>{ph.getPreferredFirstName(u)} has an upcoming paid appointment.</li>
                                                                                                                }) : null
                                                                                                        }
                                                                                                        {
                                                                                                            conflicts.appointment ?
                                                                                                                <li>{cache.resources.filter(r => r.id === groupAppointment.data.userId)[0].shortName} has an appointment at the same time.</li> : null
                                                                                                        }
                                                                                                        {
                                                                                                            conflicts.schedule ?
                                                                                                                <li>{cache.resources.filter(r => r.id === groupAppointment.data.userId)[0].shortName} is not scheduled to work at this time.</li> : null
                                                                                                        }
                                                                                                        {
                                                                                                            !conflicts.schedule && conflicts.businessDay ?
                                                                                                                <li>This is outside of business hours.</li> : null
                                                                                                        }
                                                                                                        {
                                                                                                            conflicts.break ?
                                                                                                                <li>{cache.resources.filter(r => r.id === groupAppointment.data.userId)[0].shortName} is scheduled to be on break at this time.</li> : null
                                                                                                        }
                                                                                                        {
                                                                                                            conflicts.timeOff ?
                                                                                                                <li>{cache.resources.filter(r => r.id === groupAppointment.data.userId)[0].shortName} is scheduled to be away on this day.</li> : null
                                                                                                        }

                                                                                                    </> : null
                                                                                            }
                                                                                        </ul>
                                                                                    </div> : null
                                                                            }
                                                                        </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </section> : null
                                            }
                                            {
                                                (conflicts || ineligibles) && mohReady !== false && cache.resources.filter(r => r.id === groupAppointment.data.userId)[0] ?
                                                    <section>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className='alert alert-warning p-3 mb-0' role='alert'>
                                                                    <strong className='d-block mb-2'>Warning</strong>
                                                                    <ul className='pl-3 mb-0'>
                                                                        {
                                                                            ineligibles ?
                                                                                <>
                                                                                    {
                                                                                        ineligibles.map((i, ii) => {
                                                                                            return <li key={`new_group_appointment_ineligible_${ii}`}>{bh.renderServiceCode(i.service, 'float-left mr-1')} {i.earliestEligibleDate ? <>{ph.getPreferredFirstName(i.customer)} is not eligible until <span className='tt-underline'>{moment(i.earliestEligibleDate).format('YYYY-MM-DD')}</span>.</> : (i.ineligibilityReason ? `${(!!i.ineligibilityCode ? `(Error: ${i.ineligibilityCode}) ` : '')} ${ph.getPreferredFirstName(i.customer)} - ${i.ineligibilityReason}` : `${ph.getPreferredFirstName(i.customer)} - Cannot verify.  Reason unknown.`)}</li>
                                                                                        })
                                                                                    }
                                                                                </> : null
                                                                        }
                                                                        {
                                                                            conflicts ?
                                                                                <>
                                                                                    {
                                                                                        conflicts.upcoming && conflicts.upcoming.length > 0 ?
                                                                                            conflicts.upcoming.map((u, ui) => {
                                                                                                return <li key={`group_appointment_upcoming_${ui}`}>{ph.getPreferredFirstName(u)} has an upcoming paid appointment.</li>
                                                                                            }) : null
                                                                                    }
                                                                                    {
                                                                                        conflicts.appointment ?
                                                                                            <li>{cache.resources.filter(r => r.id === groupAppointment.data.userId)[0].shortName} has another appointment at the same time.</li> : null
                                                                                    }
                                                                                    {
                                                                                        conflicts.schedule ?
                                                                                            <li>{cache.resources.filter(r => r.id === groupAppointment.data.userId)[0].shortName} is not scheduled to work at this time.</li> : null
                                                                                    }
                                                                                    {
                                                                                        !conflicts.schedule && conflicts.businessDay ?
                                                                                            <li>This is outside of business hours.</li> : null
                                                                                    }
                                                                                    {
                                                                                        conflicts.break ?
                                                                                            <li>{cache.resources.filter(r => r.id === groupAppointment.data.userId)[0].shortName} is scheduled to be on break at this time.</li> : null
                                                                                    }
                                                                                    {
                                                                                        conflicts.timeOff ?
                                                                                            <li>{cache.resources.filter(r => r.id === groupAppointment.data.userId)[0].shortName} is scheduled to be away on this day.</li> : null
                                                                                    }

                                                                                </> : null
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section> : null
                                            }
                                            {/* {
                                                existingCustomerData && existingCustomerData.hasOutstandingBalance ?
                                                    <section>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className='alert alert-danger p-3 mb-0' role='alert'>
                                                                    <strong className='d-block mb-2'>Important</strong>
                                                                    <ul className='pl-3 mb-0'>
                                                                        <li>{ph.getPreferredFirstName(existingCustomerData)} an outstanding balance on file.</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section> : null
                                            } */}
                                            <section className='date-time'>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <Observer>{() => getAppointmentDateTime()}</Observer>
                                                    </div>
                                                </div>
                                            </section>
                                            <section className='resource'>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='form-group mb-0'>
                                                            <label><small>With</small></label>
                                                            <Observer>{() =>
                                                                <DropdownList
                                                                    data={cache.resources}
                                                                    valueField='id'
                                                                    value={groupAppointment.data && groupAppointment.data.userId ? groupAppointment.data.userId : ''}
                                                                    itemComponent={({ item }) => (
                                                                        <div
                                                                            className='profile-wrapper'
                                                                        >
                                                                            <div className='profile'>
                                                                                <span
                                                                                    className={`profile-image rounded-circle d-block fw-500` + (item && !item.profilePictureUri ? ` profile-initials bg-color${item.color}-500` : '')}
                                                                                    style={item && item.profilePictureUri ? {
                                                                                        backgroundImage: `url(${item.profilePictureUri})`,
                                                                                        backgroundSize: 'cover',
                                                                                    } : null}
                                                                                    title={item ? item.fullName : 'System'}>
                                                                                    {!item.profilePictureUri ? <div className='d-initials fs-xs'>{item.initials}</div> : null}
                                                                                </span>
                                                                            </div>
                                                                            <span className='description'>{item.fullName}</span>
                                                                        </div>
                                                                    )}
                                                                    valueComponent={({ item }) => (
                                                                        item ?
                                                                            <div
                                                                                className='profile-wrapper'
                                                                            >
                                                                                <div className='profile'>
                                                                                    <span
                                                                                        className={`profile-image rounded-circle d-block fw-500` + (item && !item.profilePictureUri ? ` profile-initials bg-color${item.color}-500` : '')}
                                                                                        style={item && item.profilePictureUri ? {
                                                                                            backgroundImage: `url(${item.profilePictureUri})`,
                                                                                            backgroundSize: 'cover',
                                                                                        } : null}
                                                                                        title={item ? item.fullName : 'System'}>
                                                                                        {item && !item.profilePictureUri ? <div className='d-initials fs-xs'>{item.initials}</div> : null}
                                                                                    </span>
                                                                                </div>
                                                                                <span className='description'>{item.fullName}</span>
                                                                            </div> : null
                                                                    )}
                                                                    onChange={handleResourceChange}
                                                                />}</Observer>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <Observer>{() => <>
                                                {
                                                    groupAppointment.data.customerServices && groupAppointment.data.customerServices.some(c => !!c.customer) && groupAppointment.data.primaryContact ?
                                                        <section className='primary-contact'>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='form-group mb-0 validate validate-required'>
                                                                        <label className='required' htmlFor='contact-input'><small>Primary Contact</small></label>
                                                                        <Observer>{() =>
                                                                            <DropdownList
                                                                                data={getPrimaryContactOptions()}
                                                                                valueField='id'
                                                                                value={groupAppointment.data && groupAppointment.data.primaryContactId ? groupAppointment.data.primaryContactId : ''}
                                                                                itemComponent={({ item }) => (
                                                                                    <div
                                                                                        className='profile-wrapper'
                                                                                    >
                                                                                        <div className='profile'>
                                                                                            <span
                                                                                                className={`profile-image profile-initials rounded-circle d-flex text-white ${ch.getProfileColor(item)} fs-sm mr-2`}
                                                                                                title={ph.getFullName(item)}
                                                                                            >
                                                                                                {item.firstName && item.lastName ? `${item.firstName[0]}${item.lastName[0]}`.toUpperCase() : ''}
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className='description'>
                                                                                            <div
                                                                                                title={item.dateOfBirth ? `DOB: ${moment(item.dateOfBirth).format('YYYY-MM-DD')}` : null}
                                                                                            >
                                                                                                {ph.getFullName(item, true)}
                                                                                                {
                                                                                                    item.dateOfBirth || item.sex || item.gender || item.pronoun ?
                                                                                                        <small className='ml-2'>({`${ph.getAge(item.dateOfBirth)} ${ph.getSexGenderPronounDisplay(item)}`.trim()})</small> : null
                                                                                                }
                                                                                            </div>
                                                                                            {
                                                                                                !item.emailAddress && !item.phoneNumber ?
                                                                                                    <div className='fs-base text-danger text-truncate text-truncate-xl'>No contact information on file.</div> : null
                                                                                            }
                                                                                            {
                                                                                                item.emailAddress ?
                                                                                                    <div className='fs-base text-info text-truncate text-truncate-xl'>{item.emailAddress}</div> : null
                                                                                            }
                                                                                            {
                                                                                                item.phoneNumber ?
                                                                                                    <div className='fs-base text-info text-truncate text-truncate-xl'>{sys.getFormattedPhoneNumber(item.phoneNumber)}</div> : null
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                                valueComponent={({ item }) => (
                                                                                    item ?
                                                                                        <div
                                                                                            className='profile-wrapper py-o'
                                                                                        >
                                                                                            <div className='profile'>
                                                                                                <span
                                                                                                    className={`profile-image profile-initials rounded-circle d-flex text-white ${ch.getProfileColor(item)} fs-sm mr-2`}
                                                                                                    title={ph.getFullName(item)}
                                                                                                >
                                                                                                    {item.firstName && item.lastName ? `${item.firstName[0]}${item.lastName[0]}`.toUpperCase() : ''}
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className='description'>
                                                                                                <div
                                                                                                    className='text-gray-700'
                                                                                                    title={item.dateOfBirth ? `DOB: ${moment(item.dateOfBirth).format('YYYY-MM-DD')}` : null}
                                                                                                >
                                                                                                    {ph.getFullName(item, true)}
                                                                                                    {
                                                                                                        item.dateOfBirth || item.sex || item.gender || item.pronoun ?
                                                                                                            <small className='ml-2 text-gray'>({`${ph.getAge(item.dateOfBirth)} ${ph.getSexGenderPronounDisplay(item)}`.trim()})</small> : null
                                                                                                    }
                                                                                                </div>
                                                                                                {
                                                                                                    !item.emailAddress && !item.phoneNumber ?
                                                                                                        <div className='fs-base text-danger text-truncate text-truncate-xl'>No contact information on file.</div> : null
                                                                                                }
                                                                                                {
                                                                                                    item.emailAddress ?
                                                                                                        <div className='fs-base text-info text-truncate text-truncate-xl'>{item.emailAddress}</div> : null
                                                                                                }
                                                                                                {
                                                                                                    item.phoneNumber ?
                                                                                                        <div className='fs-base text-info text-truncate text-truncate-xl'>{sys.getFormattedPhoneNumber(item.phoneNumber)}</div> : null
                                                                                                }
                                                                                            </div>
                                                                                        </div> : null
                                                                                )}
                                                                                onChange={handlePrimaryContactChange}
                                                                            />
                                                                        }</Observer>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section> : null
                                                }
                                            </>}</Observer>
                                            <Observer>{() =>
                                                groupAppointment.data.customerServices && groupAppointment.data.customerServices.length > 0 ?
                                                    groupAppointment.data.customerServices.map(({ customerId, customer, services }, ci) => {
                                                        return ci === 0 || groupAppointment.data.primaryContact ?
                                                            <section
                                                                key={`group-appointment-customer-services-${ci}`}
                                                                className='customer'>
                                                                <div className='row'>
                                                                    <div className='col-12'>
                                                                        <div className='form-group mb-0'>
                                                                            <div className='d-flex align-items-center mb-1'>
                                                                                <label className={'flex-1 mb-0' + (ci < 2 ? ' required' : '')} htmlFor={`customer-input-${ci}`}><small>Patient #{(ci + 1)}</small></label>
                                                                                {
                                                                                    !customer ?
                                                                                        <>
                                                                                            <button
                                                                                                type='button'
                                                                                                className='btn btn-link p-0'
                                                                                                onClick={handleNewCustomer}
                                                                                            >Add new customer</button>
                                                                                            {
                                                                                                ci > 1 ?
                                                                                                    <button
                                                                                                        type='button'
                                                                                                        className='btn btn-icon line-height-1'
                                                                                                        title='Remove'
                                                                                                        onClick={(e) => { handleCustomerRemove(e, ci) }}
                                                                                                    >
                                                                                                        <i className='fal fa-times fs-lg text-danger'></i>
                                                                                                    </button> : null
                                                                                            }
                                                                                        </> :
                                                                                        <>
                                                                                            <button
                                                                                                type='button'
                                                                                                className='btn btn-icon line-height-1'
                                                                                                title='Change customer'
                                                                                                onClick={(e) => { handleCustomerRemove(e, ci) }}
                                                                                            >
                                                                                                <i className='fal fa-times fs-lg text-danger'></i>
                                                                                            </button>
                                                                                        </>
                                                                                }
                                                                            </div>
                                                                            {
                                                                                !customer ?
                                                                                    <>
                                                                                        <div className='validate validate-required'>
                                                                                            <div className='dropdown'>
                                                                                                <CustomerFilter
                                                                                                    id={`${GROUP_APPOINTMENT_CUSTOMER_INPUT_ID}${ci}`}
                                                                                                    ref={(element) => newAppointmentCustomerFilterRef.current[ci] = element}
                                                                                                    delay={500}
                                                                                                    onChange={(e, term) => { handleCustomerSearchChange(e, ci, term) }}
                                                                                                />
                                                                                                <ul className='dropdown-menu'>
                                                                                                    {
                                                                                                        (customerSearchResult && customerSearchResult.length > 0 ? customerSearchResult.slice(0, 5) : getSuggestionOptions())
                                                                                                            .map((s, si) => {
                                                                                                                return <li
                                                                                                                    key={`search_result_${ci}_${si}`}
                                                                                                                    className={'dropdown-menu-item'}>
                                                                                                                    <div
                                                                                                                        className='profile-wrapper'
                                                                                                                        onClick={() => handleCustomerSearchClick(ci, s)}
                                                                                                                    >
                                                                                                                        <div className='profile d-flex flex-column justify-content-center align-content-center'>
                                                                                                                            <span
                                                                                                                                className={`profile-image profile-image-md profile-initials rounded-circle fs-xs text-white ${ch.getProfileColor(s)}`}
                                                                                                                                title={ph.getPreferredFullName(s)}
                                                                                                                            >
                                                                                                                                {s.initials}
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                        <div className='description'>
                                                                                                                            <div
                                                                                                                                className='fw-500 text-gray-700'
                                                                                                                                title={s.dateOfBirth ? `DOB: ${moment(s.dateOfBirth).format('YYYY-MM-DD')}` : null}
                                                                                                                            >
                                                                                                                                {ph.getLastFirstName(s, true)}
                                                                                                                                {
                                                                                                                                    s.dateOfBirth || s.sex || s.gender || s.pronoun ?
                                                                                                                                        <small className='ml-2 text-gray'>({`${ph.getAge(s.dateOfBirth)} ${ph.getSexGenderPronounDisplay(s)}`.trim()})</small> : null
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                            {
                                                                                                                                s.address && s.address.country ?
                                                                                                                                    <div className='fs-95 info text-truncate text-truncate-xl'>{s.address.fullLine}</div> : null
                                                                                                                            }
                                                                                                                            {
                                                                                                                                s.dateOfBirth ?
                                                                                                                                    <div className='fs-90 info text-truncate text-truncate-xl'>DOB: {fn.formatDate(s.dateOfBirth)}</div> : null
                                                                                                                            }
                                                                                                                            {
                                                                                                                                s.emailAddress ?
                                                                                                                                    <div className='fs-90 text-info text-truncate text-truncate-xl'>{s.emailAddress}</div> : null
                                                                                                                            }
                                                                                                                            {
                                                                                                                                s.primaryPhoneNumber ?
                                                                                                                                    <div className='fs-90 text-info text-truncate text-truncate-xl'>{sys.getFormattedPhoneNumber(s.primaryPhoneNumber.number)}</div> : null
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </li>
                                                                                                            })
                                                                                                    }
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </> : null
                                                                            }
                                                                            {
                                                                                customer ?
                                                                                    <>
                                                                                        {renderCustomer(customerId, customer, services, ci)}
                                                                                    </> : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </section> : <></>
                                                    }) : null
                                            }
                                            </Observer>
                                            <section>
                                                <div className='row'>
                                                    <div className='col-5'>
                                                        <Observer>{() =>
                                                            <div className='form-group mb-0 validate validate-required'>
                                                                <label className='required' htmlFor='new-appointment-duration'><small>Duration</small></label>
                                                                <div className='input-group'>
                                                                    <input
                                                                        id='new-appointment-duration'
                                                                        type='number'
                                                                        className='form-control'
                                                                        min={5}
                                                                        step={5}
                                                                        value={(groupAppointment.data.duration ? groupAppointment.data.duration : '')}
                                                                        onChange={handleDurationChange}
                                                                    />
                                                                    <div className='input-group-append'>
                                                                        <span className='input-group-text'>min.</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }</Observer>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </FadeIn> : null
                            }
                        </div>
                        <div className='quick-drawer-action pl-3'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='float-right'>
                                        <button
                                            type='button'
                                            className='btn btn-link btn-cancel mr-2'
                                            onClick={handleCancel}
                                        >Cancel</button>
                                        <button
                                            type='submit'
                                            className='btn btn-success'
                                        >Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </form>
        }</Observer>
        <BodyEnd>
            <ConfirmModal ref={confirmModalRef} />
        </BodyEnd>
    </>
}

export default NewGroupAppointment;