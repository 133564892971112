import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import emailMask from 'text-mask-addons/dist/emailMask';

const currencyMask = createNumberMask({
    allowDecimal: true,
    allowNegative: true,
});

const currencyPositiveMask = createNumberMask({
    allowDecimal: true,
    allowNegative: false,
});

const phoneExtensionMask = createNumberMask({
    prefix: '', 
    includeThousandsSeparator: '', 
    thousandsSeparatorSymbol: '', 
    decimalSymbol: '', 
    decimalLimit: 0,
    allowLeadingZeroes: true
});

export const CURRENCY_MASK = currencyMask;
export const CURRENCY_POSITIVE_MASK = currencyPositiveMask;
export const EMAIL_MASK = emailMask;
// export const PHONE_MASK = ['+', '1', ' ', /[2-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /[xX]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
export const PHONE_MASK = ['+', '1', ' ', /[2-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
export const PHONE_EXT_MASK = phoneExtensionMask;
export const POSTAL_MASK = [/(?![dfioquwzDFIOQUWZ])[a-zA-Z]/, /\d/, /(?![dfioquDFIOQU])[a-zA-Z]/, /\d/, /(?![dfioquDFIOQU])[a-zA-Z]/, /\d/];
export const OHIP_MASK = [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /[a-zA-Z]/, /[a-zA-Z]/];
export const OHIP_OLD_MASK = [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
export const OHIP_NO_CODE_MASK = [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
export const OHIP_CODE_MASK = [/[a-zA-Z]/, /[a-zA-Z]/];
export const DATE_MASK = [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
export const DATE_PIPE = createAutoCorrectedDatePipe('yyyy/mm/dd');