import React, { useEffect, useContext, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { GlobalHotKeys } from 'react-hotkeys';
import { useObserver } from 'mobx-react-lite';

import { quickDrawerFocus, renderQuickDrawerLoading } from '../../../../_shared/QuickDrawer';
import QuickDrawerHeader from '../../../../_shared/QuickDrawerHeader';
import RichTextEditor from '../../../../_shared/RichTextEditor';
import ConfirmModal from '../../../../_shared/ConfirmModalComponent';

import LogicalDeviceUpdateStore from '../../../../../../stores/LogicalDeviceUpdateStore';
import QuickDrawerStore from '../../../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../../../constants/errorMessages';
import * as fn from '../../../../../../utilities/_functions';

import './UpdateLogicalDevice.scss';
import BodyEnd from '../../../../_shared/BodyEnd';

function UpdateLogicalDevice(props) {
    const isMounted = useRef(true);
    const confirmModalRef = useRef(null);
    const focusTimer = useRef(null);
    const validateRef = useRef(null);
    const logicalDevice = useContext(LogicalDeviceUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);

    useEffect(() => {
        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100)

        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (logicalDevice.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            logicalDevice.save()
                .then(data => {
                    if (isMounted.current) {
                        if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                            props.onSuccess(event, {updated: true, deleted: false, data: data});
                        }
                    }
                })
        }
    }

    const handleNameChange = event => {
        logicalDevice.data.name = event.target.value;
        logicalDevice.hasUnsavedChanges = true;
    }

    const handleDescriptionChange = content => {
        const html = content;
        logicalDevice.data.descriptionHtml = ((html === '<p><br></p>') ? null : html);
        logicalDevice.hasUnsavedChanges = true;
    }

    const handleDelete = event => {
        confirmModalRef.current.show({
            icon: <i className='fal fa-laptop-code text-danger mr-2'></i>,
            message: <>Continue to delete&nbsp;<span className='fw-500 tt-underline'>{logicalDevice.data && logicalDevice.data.name ? logicalDevice.data.name : 'this logical device'}</span>?</>,
            option1ClassName: 'btn btn-danger shadow-0 bootbox-accept',
            onOption1Click: handleConfirmDelete,
            onCancel: handleConfirmDeleteCancel,
        })
    }

    const handleConfirmDelete = (event) => {
        logicalDevice.delete()
            .then(() => {
                if (isMounted.current) {
                    confirmModalRef.current.close();
                    
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, {updated: false, deleted: true});
                    }
                }
            })
    }

    const handleConfirmDeleteCancel = (e) => {
        confirmModalRef.current.close();
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <fieldset disabled={logicalDevice.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={'fal fa-laptop-code'}
                        action='Update'
                        category='Logical Device'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        {
                            logicalDevice.isReady ?
                                <FadeIn>
                                    <div className='update-logical-device body-content'>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label htmlFor='update-logical-device-industry-device'><small>Physical Device (Make / Model)</small></label>
                                                        <input
                                                            id='update-logical-device-industry-device'
                                                            type='text'
                                                            className='form-control'
                                                            value={logicalDevice.data.industryDevice ? logicalDevice.data.industryDevice.displayName : ''}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4 validate validate-required'>
                                                        <label className='required' htmlFor='update-logical-device-name'><small>Name</small></label>
                                                        <input
                                                            id='update-logical-device-name'
                                                            type='text'
                                                            maxLength={100}
                                                            className='form-control'
                                                            autoComplete={'off'}
                                                            value={logicalDevice.data.name ? logicalDevice.data.name : ''}
                                                            onChange={handleNameChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label htmlFor='update-logical-device-description'><small>Description</small></label>
                                                        <RichTextEditor
                                                            mode='none'
                                                            disableTab={true}
                                                            value={logicalDevice.data.descriptionHtml ? logicalDevice.data.descriptionHtml : ''}
                                                            onChange={handleDescriptionChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </FadeIn> : renderQuickDrawerLoading()
                        }
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-4'></div>
                            <div className='col-8'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-danger mr-2'
                                        onClick={handleDelete}
                                    >Delete</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
        <BodyEnd>
            <ConfirmModal ref={confirmModalRef} />
        </BodyEnd>
    </>)
}

export default UpdateLogicalDevice;