import React from 'react';
import moment from 'moment';

import * as fn from './_functions';
import * as dg from './dx/dataGridHelper';

export const isEndState = referralAppointment => {
    return referralAppointment && ['Completed', 'Canceled'].some(s => s === referralAppointment.status);
}

export const renderCommunicationStatus = referralAppointment => {
    if (referralAppointment.requestMethod && referralAppointment.requestMethod.toLowerCase() !== 'manual' && referralAppointment.referral.lastCommunication) {
        if (referralAppointment.referral.lastCommunication.failedDateUtc) {
            return <div className='flex-1'><strong className='text-danger flashing slow'>Attention:</strong> The referral letter failed to sent via <span className='tt-underline'>{referralAppointment.requestMethod.toLowerCase()}</span> to recipient.<br />Last attempt was {fn.formatDate(moment.utc(referralAppointment.failedDateUtc).local())} @ {fn.formatDate(moment.utc(referralAppointment.failedDateUtc).local(), 'h:mm a').toLowerCase()}.</div>
        }

        if (!referralAppointment.referral.lastCommunication.failedDateUtc && referralAppointment.referral.lastCommunication.deliveredDateUtc) {
            return <><strong className='text-gray-700 mr-1'>Note:</strong>Referral letter was successfully delivered via <span className='tt-underline'>{referralAppointment.requestMethod.toLowerCase()}</span> on {fn.formatDateWithOrWithoutYear(moment.utc(referralAppointment.referral.lastCommunication.deliveredDateUtc).local())} @ {fn.formatDate(moment.utc(referralAppointment.referral.lastCommunication.deliveredDateUtc).local(), 'h:mm a').toLowerCase()}.</>
        }

        if (!referralAppointment.referral.lastCommunication.failedDateUtc && !referralAppointment.referral.lastCommunication.deliveredDateUtc) {
            return <>

                {
                    referralAppointment.referral.lastCommunication.sentDateUtc ?
                        <>
                            <strong className='text-gray-700 mr-1'>Note:</strong>Referral letter was sent via <span className='tt-underline'>{referralAppointment.requestMethod.toLowerCase()}</span> on {fn.formatDate(moment.utc(referralAppointment.referral.lastCommunication.sentDateUtc).local())} @ {fn.formatDate(moment.utc(referralAppointment.referral.lastCommunication.sentDateUtc).local(), 'h:mm a').toLowerCase()}.
                        </> :
                        <>
                            <strong className='text-gray-700 mr-1'>Note:</strong>Referral letter will be sent via <span className='tt-underline'>{referralAppointment.requestMethod.toLowerCase()}</span> shortly.
                        </>
                }
            </>
        }
    }

    return <><strong className='text-gray-700 mr-1'>Note:</strong>Referral letter was sent <span className='tt-underline'>{getRequestMethodDescription(referralAppointment.requestMethod)}</span>, and it is not tracked by the system.</>
}

export const getQuickFilterDescription = (type) => {
    if (type) {
        switch (type) {
            case 'Outstanding':
                return 'All outstanding appointments';

            case 'Upcoming':
                return 'Upcoming appointments';

            case 'IncompletePast':
                return 'Incomplete past appointments';

            case 'RecentUpdated':
                return 'Recently updated appointments';

            case 'RecentCompleted':
                return 'Recently completed appointments';

            case 'RecentNoResponse':
                return 'Recently marked as no response';

            case 'RecentCanceled':
                return 'Recently canceled appointments';

            default:
                return null;
        }
    }
    return null;
}

export const getQuickFilterDefinition = (tenant, type) => {
    if (type) {
        const appointmentDateFilters = getAppointmentDateFilters();
        const lastUpdatedFilters = getLastUpdatedDateFilters();

        switch (type) {
            case 'Outstanding':
                return [{
                    column: 'Status',
                    headerFilters: ['Ready', 'Requested', 'Outstanding', 'Booked', 'Notified', 'Confirmed', 'Seen']
                }]

            case 'Upcoming':
                return [{
                    column: 'Status',
                    headerFilters: ['Ready', 'Requested', 'Outstanding', 'Booked', 'Notified', 'Confirmed', 'Seen']
                }, {
                    column: 'Date',
                    headerFilters: [appointmentDateFilters.filter(u => u.text === 'Next 30 days')[0].value]
                }]

            case 'IncompletePast':
                return [{
                    column: 'Status',
                    headerFilters: ['Ready', 'Requested', 'Outstanding', 'Booked', 'Notified', 'Confirmed', 'Seen']
                }, {
                    column: 'Date',
                    filter: {
                        operator: '<',
                        value: moment().startOf('day').toDate(),
                    }
                }]

            case 'RecentUpdated':
                return [{
                    column: 'LastUpdatedDateUtc',
                    headerFilters: [lastUpdatedFilters.filter(u => u.text === 'Last 30 days')[0].value]
                }]

            case 'RecentCompleted':
                return [{
                    column: 'Status',
                    headerFilters: ['Completed']
                }, {
                    column: 'LastUpdatedDateUtc',
                    headerFilters: [lastUpdatedFilters.filter(u => u.text === 'Last 30 days')[0].value]
                }]

            case 'RecentNoResponse':
                return [{
                    column: 'Status',
                    headerFilters: ['NoResponse']
                }, {
                    column: 'LastUpdatedDateUtc',
                    headerFilters: [lastUpdatedFilters.filter(u => u.text === 'Last 30 days')[0].value]
                }]

            case 'RecentCanceled':
                return [{
                    column: 'Status',
                    headerFilters: ['Canceled']
                }, {
                    column: 'LastUpdatedDateUtc',
                    headerFilters: [lastUpdatedFilters.filter(u => u.text === 'Last 30 days')[0].value]
                }]

            default:
                return;
        }
    }
}

export const getRequestMethodDescription = requestMethod => {
    if (requestMethod) {
        switch (requestMethod.toLowerCase()) {
            case 'manual':
                return 'manually';

            case 'email':
                return 'via email';

            case 'fax':
                return 'via fax';

            default:
                return '';
        }
    }
}

export const getReferralAppointmentStatusDescription = status => {
    if (status) {
        switch (status.toLowerCase()) {
            case 'ready':
                return 'Ready to send';

            case 'requested':
                return 'Waiting for appointment';

            case 'booked':
                return 'Appointment booked';

            case 'notified':
                return 'Patient notified';

            case 'confirmed':
                return 'Patient confirmed';

            case 'seen':
                return 'Waiting for report';

            case 'noresponse':
                return 'No response';

            case 'completed':
                return 'Completed';

            case 'canceled':
                return 'Canceled';

            default:
                return '';
        }
    }
}

export const getStatusFilters = () => {
    return [
        {
            text: getReferralAppointmentStatusDescription('Ready'),
            value: 'Ready'
        },
        {
            text: getReferralAppointmentStatusDescription('Requested'),
            value: 'Requested'
        },
        {
            text: getReferralAppointmentStatusDescription('Booked'),
            value: 'Booked'
        },
        {
            text: getReferralAppointmentStatusDescription('Notified'),
            value: 'Notified'
        },
        {
            text: getReferralAppointmentStatusDescription('Confirmed'),
            value: 'Confirmed'
        },
        {
            text: getReferralAppointmentStatusDescription('Seen'),
            value: 'Seen'
        },
        {
            text: getReferralAppointmentStatusDescription('NoResponse'),
            value: 'No Response'
        },
        {
            text: getReferralAppointmentStatusDescription('Completed'),
            value: 'Completed'
        },
        {
            text: getReferralAppointmentStatusDescription('Canceled'),
            value: 'Canceled'
        },
    ];
}

export const getAppointmentDateFilters = () => {
    return dg.getDefaultNextDateFilters('Date');
}

export const getLastUpdatedDateFilters = () => {
    return dg.getDefaultLastDateFilters('LastUpdatedDateUtc');
}