import { createContext } from 'react';
import uuid from 'react-uuid';
import { decorate, observable, action, toJS } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class ExamDatasetCreate {
    id = null;
    data = {
        examDatasetGroupId: null,
        key: null,
        name: null,
        category: null,
        label: null,
        definition: [],
    };
    categories = [];
    isReady = false;
    isSaving = false;
    isLoading = false;
    hasUnsavedChanges = false;

    cancelExamDatasetCreate = null;
    cancelExamDatasetCategories = null;

    initialize = (examDatasetGroupId) => {
        const that = this;

        this.clear();
        this.id = uuid();
        this.data.examDatasetGroupId = examDatasetGroupId;

        return new Promise((resolve, reject) => {
            that.refreshCategories()
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isReady = true;
                })
        })
    }

    refreshCategories = (notify) => {
        const that = this;

        if (!!notify) {
            this.isLoading = true;
        }

        return new Promise((resolve, reject) => {
            api.ExamDatasetCategories.all(that.data.examDatasetGroupId, (c) => { that.cancelExamDatasetCategories = c })
                .then(({ data }) => {
                    that.categories = data && data.length > 0 ? data : [];
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isLoading = false;
                })
        })
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            let option = toJS(that.data);

            if (that.hasUnsavedChanges) {
                api.ExamDatasets.create(option, (c) => { that.cancelExamDatasetCreate = c })
                    .then(({ data }) => {
                        that.id = data.id;
                        that.hasUnsavedChanges = false;
                        option.id = data.id;

                        that.refreshCategories()
                            .finally(() => {
                                resolve(option);
                            })
                    })
                    .catch(() => {
                        reject();
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                that.hasUnsavedChanges = false;
                that.isSaving = false;
            }
        })
    }

    clear = () => {
        this.id = null;
        this.data.key = null;
        this.data.name = null;
        this.data.category = null;
        this.data.label = null;
        this.data.descriptionHtml = null;
        this.data.definition = [];
        this.categories.clear();
        this.isReady = false;
        this.isSaving = false;
        this.isLoading = false;
        this.hasUnsavedChanges = false;

        if (fn.isFunction(this.cancelExamDatasetCreate)) {
            this.cancelExamDatasetCreate();
            this.cancelExamDatasetCreate = null;
        }

        if (fn.isFunction(this.cancelExamDatasetCategories)) {
            this.cancelExamDatasetCategories();
            this.cancelExamDatasetCategories = null;
        }
    }
}

decorate(ExamDatasetCreate, {
    id: observable,
    data: observable.deep,
    categories: observable,
    isReady: observable,
    isSaving: observable,
    isLoading: observable,
    initialize: action,
    refreshCategories: action,
    hasUnsavedChanges: observable,
    save: action,
    clear: action,
})

export default createContext(new ExamDatasetCreate());