import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Observer } from 'mobx-react-lite';
import FadeIn from 'react-fade-in';
import { toast } from 'react-toastify';
import momentLocalizer from 'react-widgets-moment';
import { GlobalHotKeys } from 'react-hotkeys';
import moment from 'moment';

import BodyEnd from '../../_shared/BodyEnd';
import ConfirmModal from '../../_shared/ConfirmModal';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import HtmlEditor from '../../_shared/HtmlEditor';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';

import CustomerUpdateStore from '../../../../stores/CustomerUpdateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as rts from '../../../../constants/routes';
import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as ah from '../../../../utilities/addressHelper';
import * as ch from '../../../../utilities/customerHelper';
import * as ph from '../../../../utilities/personHelper';
import * as sys from '../../../../utilities/systemHelper';
import * as oh from '../../../../utilities/operationHelper';

import './ReactivateCustomer.scss';

moment.locale('en');
momentLocalizer();

function ReactivateCustomer(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const customer = useContext(CustomerUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [confirmReactivate, setConfirmReactivate] = useState(false);

    useEffect(() => {
        quickDrawerFocus(props.drawer);

        return () => {
            isMounted.current = false;
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (customer.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleAdditionalNoteChange = (e) => {
        const value = e.value;
        const noteHtml = value === '<p></p>' || value === '<p><br></p>' ? null : value;
        const note = !!noteHtml ? fn.stripHtml(noteHtml).substring(0, 100) : null;

        customer.deactivateNotePreview = note;
        customer.deactivateNoteBodyHtml = noteHtml;
    }

    const handleSubmit = event => {
        event.preventDefault();
        if (isMounted.current) {
            if (fn.validateForm(validateRef.current)) {
                setConfirmReactivate(true);
            }
        }
    }

    const handleConfirmReactivate = event => {
        event.preventDefault();
        customer.reactivate()
            .then(data => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, { updated: true, data: data });
                    }
                }
                quickDrawer.deactivateAll();
            })
            .catch(error => {
                const msg = <p><strong>Cannot reactivate customer profile.</strong>{error && error.response && error.response.data ? <>&nbsp;{error.response.data}</> : null}</p>;
                toast.error(() => msg, { position: 'top-center', style: { width: '380px' } });
            })

        setConfirmReactivate(false);
    }

    const handleConfirmReactivateCancel = event => {
        event.preventDefault();
        setConfirmReactivate(false);

        if (props.onCancel && fn.isFunction(props.onCancel)) {
            props.onCancel(event);
        }
    }

    const renderCustomer = () => {
        return <div
            className='profile-wrapper'
        >
            <div className='profile'>
                <span
                    className={`profile-image profile-initials rounded-circle d-flex text-white ${ch.getProfileColor(customer.data)} fw-500`}
                    title={customer.data.fullName}
                >
                    {customer.data.initials}
                </span>
            </div>
            <div className='description'>
                <Link
                    to={`${rts.Customers.Home}/${customer.data.id}`}
                    className='name text-gray-700 text-info-hover'
                    onClick={quickDrawer.deactivateAll}>{ph.getFullName(customer.data, true)}
                    {
                        customer.data.dateOfBirth || customer.data.sex || customer.data.gender || customer.data.pronoun ?
                            <small className='text-nowrap ml-2'>({`${ph.getAge(customer.data.dateOfBirth)} ${ph.getSexGenderPronounDisplay(customer.data)}`.trim()})</small> : null
                    }
                </Link>
                {
                    customer.data.address && customer.data.address.country ?
                        <div className='info'>{ah.getAddressHtml(customer.data.address)}</div> : null
                }
                {
                    customer.data.emailAddress ?
                        <div className='info'>
                            <a
                                href={`mailto:${customer.data.emailAddress}`}
                            >{customer.data.emailAddress}
                            </a>
                        </div> : null
                }
                {
                    customer.data.phoneNumber ?
                        <div className='info'>
                            <a
                                href={`tel:${customer.data.phoneNumber}`}
                            >{sys.getFormattedPhoneNumber(customer.data.phoneNumber)}
                            </a>
                        </div> : null
                }
                {
                    customer.data.primaryContactPerson ?
                        <div className='mt-2 border-left border-3 border-gray-300 pl-3 py-1'>
                            <small className='text-primary-400 fs-75 text-uppercase d-block'>Primary Contact</small>
                            {
                                customer.data.primaryContactPerson.id ?
                                    <Link
                                        to={`${rts.Customers.Home}/${customer.data.primaryContactPerson.id}`}
                                        className='d-block fs-lg text-gray-700 text-info-hover'
                                        onClick={quickDrawer.deactivateAll}
                                    >
                                        {ph.getPreferredFirstLastName(customer.data.primaryContactPerson)}
                                        {
                                            customer.data.primaryContactPerson.relationship ?
                                                <small className='ml-1'>(<span className='fw-500 fs-90 text-success-700'>{customer.data.primaryContactPerson.relationship}</span>)</small> : null
                                        }
                                    </Link> :
                                    <div className='fs-lg text-gray-700 '>{ph.getPreferredFirstLastName(customer.data.primaryContactPerson)}
                                        {
                                            customer.data.primaryContactPerson.relationship ?
                                                <small className='ml-1'>(<span className='fw-500 fs-90 text-success-700'>{customer.data.primaryContactPerson.relationship}</span>)</small> : null
                                        }
                                    </div>
                            }
                            {
                                customer.data.primaryContactPerson.emailAddress ?
                                    <div className='info fs-95'>
                                        <a
                                            href={`mailto:${customer.data.primaryContactPerson.emailAddress}`}
                                        >{customer.data.primaryContactPerson.emailAddress}
                                        </a>
                                    </div> : null
                            }
                            {
                                customer.data.primaryContactPerson.phoneNumber ?
                                    <div className='info fs-95'>
                                        <a
                                            href={`tel:${customer.data.primaryContactPerson.phoneNumber}`}
                                        >{sys.getFormattedPhoneNumber(customer.data.primaryContactPerson.phoneNumber)}
                                        </a>
                                    </div> : null
                            }
                        </div> : null
                }
            </div>
        </div>
    }

    return <>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <Observer>{() =>
                <>
                    {
                        (props.drawer === quickDrawer.drawerOpened) && !confirmReactivate ?
                            <GlobalHotKeys
                                keyMap={{
                                    close: ['esc'],
                                }}
                                handlers={{
                                    close: event => {
                                        handleCancel(event)
                                    },
                                }}
                                allowChanges={true}
                            /> : null
                    }
                    <fieldset disabled={customer.isSaving}>
                        <div className='quick-drawer'>
                            <QuickDrawerHeader
                                drawer={props.drawer}
                                icon={oh.getIcon('customer', 'new')}
                                action='Reactivate'
                                category='Customer'
                                onCancel={handleCancel}
                            />
                            <div className='quick-drawer-body'>
                                {
                                    customer.isReady ?
                                        <FadeIn>
                                            <div className='reactivate-customer body-content'>
                                                <section className='customer'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-0'>
                                                                <label><small>Customer</small></label>
                                                                {renderCustomer()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className={'form-group validate validate-required'}>
                                                                <label className='required'><small>Reactivate reason</small></label>
                                                                <div className='input-group'>
                                                                    <Observer>{() =>
                                                                        <HtmlEditor
                                                                            disableTab={true}
                                                                            onValueChanged={handleAdditionalNoteChange}
                                                                        />
                                                                    }</Observer>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </FadeIn> : renderQuickDrawerLoading()
                                }
                            </div>
                            <Observer>{() =>
                                customer.isReady ?
                                    <div className='quick-drawer-action'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='float-right'>
                                                    <button
                                                        type='button'
                                                        className='btn btn-link btn-cancel mr-2'
                                                        onClick={props.onCancel}
                                                    >Cancel</button>
                                                    <Observer>{() =>
                                                        <button
                                                            type='submit'
                                                            className='btn btn-success'
                                                        >Reactivate</button>
                                                    }</Observer>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : null
                            }</Observer>
                        </div>
                    </fieldset>
                </>
            }</Observer>
        </form>
        <BodyEnd>
            <ConfirmModal
                icon={<i className='fal fa-user-plus text-success mr-2'></i>}
                message={<>Continue to <span className='fw-500'>&nbsp;reactivate {customer.data ? ph.getPreferredFirstName(customer.data) : ''}'s profile</span>? </>}
                option1ClassName={'btn btn-success shadow-0 bootbox-accept'}
                show={confirmReactivate}
                onOption1Click={handleConfirmReactivate}
                onCancel={handleConfirmReactivateCancel}
            />
        </BodyEnd>
    </>
}

export default ReactivateCustomer;