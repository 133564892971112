import { createContext } from 'react';
import { decorate, observable, action, computed } from 'mobx';
import moment from 'moment';

import api from '../api';
import * as fn from '../utilities/_functions';
import * as ah from '../utilities/appointmentHelper';

export class GroupAppointmentView {
    groupId = null;
    appointments = [];
    isLoading = false;
    isReady = false;
    cancelGroupAppointmentGet = null;

    initialize = (groupId) => {
        const that = this;

        this.clear();
        this.groupId = groupId;
        this.isReady = false;

        return new Promise((resolve, reject) => {
            that.refresh()
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isReady = true;
                })
        })
    }

    refresh = () => {
        const that = this;
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            api.GroupAppointments.get(
                that.groupId,
                (c) => { that.cancelGroupAppointmentGet = c; }
            )
                .then(({ data }) => {
                    if (data) {
                        that.appointments = data.appointments;
                    }

                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isLoading = false;
                })
        })
    }

    clear = () => {
        this.groupId = null;
        this.appointments.clear();
        this.isLoading = false;
        this.isReady = false;

        if (fn.isFunction(this.cancelGroupAppointmentGet)) {
            this.cancelGroupAppointmentGet();
            this.cancelGroupAppointmentGet = null;
        }
    }

    get data() {
        if (this.appointments && this.appointments.length > 0) {
            if (this.appointments.some(a => a.primaryContactId === a.customerId)) {
                return this.appointments.filter(a => a.primaryContactId === a.customerId)[0]
            }
            else {
                return this.appointments[0];
            }
        }

        return null;
    }

    get start() {
        if (!this.data) return null;
        return moment(this.data.start);
    }

    get startUtc() {
        if (!this.data) return null;
        return moment.utc(this.data.startUtc);
    }

    get end() {
        if (!this.data) return null;
        return moment(this.data.end);
    }

    get endUtc() {
        if (!this.data) return null;
        return moment(this.data.endUtc);
    }

    get notes() {
        if (this.appointments && this.appointments.length > 0) {
            return ah.groupAppointmentNotes(this.appointments);
        }

        return [];
    }
}

decorate(GroupAppointmentView, {
    groupId: observable,
    appointments: observable.deep,
    isLoading: observable,
    isReady: observable,
    initialize: action,
    refresh: action,
    clear: action,
    data: computed,
    start: computed,
    startUtc: computed,
    end: computed,
    endUtc: computed,
    notes: computed,
})

export default createContext(new GroupAppointmentView());