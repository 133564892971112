import React, { useEffect, useContext, useState, useRef } from 'react';
import { toJS } from 'mobx';
import FadeIn from 'react-fade-in';
import { GlobalHotKeys } from 'react-hotkeys';
import { Observer } from 'mobx-react-lite';
import List, { ItemDragging } from 'devextreme-react/list';
import DataSource from 'devextreme/data/data_source';

import BodyEnd from '../../../../_shared/BodyEnd';
import ConfirmModal from '../../../../_shared/ConfirmModal';
import QuickDrawerHeader from '../../../../_shared/QuickDrawerHeader';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../../../_shared/QuickDrawer';
import RichTextEditor from '../../../../_shared/RichTextEditor';

import MedicalReportTenantExamDatasetGroupViewStore from '../../../../../../stores/MedicalReportTenantExamDatasetGroupViewStore';
import QuickDrawerStore from '../../../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../../../constants/errorMessages';
import * as fn from '../../../../../../utilities/_functions';
import * as oh from '../../../../../../utilities/operationHelper';

import './UpdateMedicalReportTenantExamDatasetGroup.scss';

function UpdateMedicalReportTenantExamDatasetGroup(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const updateMedicalReportGroup = useContext(MedicalReportTenantExamDatasetGroupViewStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    useEffect(() => {
        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100)

        return (() => {
            isMounted.current = false;
            updateMedicalReportGroup.clear();
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        })
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (updateMedicalReportGroup.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = event => {
        event.preventDefault();
        updateMedicalReportGroup.save()
            .then(data => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, data);
                    }
                }
            })
    }

    const handleDelete = () => {
        setConfirmDelete(true);
    }

    const handleConfirmDelete = () => {
        updateMedicalReportGroup.delete()
            .then(() => {
                setConfirmDelete(false);
                handleCancel();
            })
    }

    const handleConfirmDeleteCancel = () => {
        setConfirmDelete(false);
    }

    const handleNameChange = event => {
        updateMedicalReportGroup.data.name = event.target.value;
        updateMedicalReportGroup.hasUnsavedChanges = true;
    }

    const handleDescriptionChange = content => {
        const html = content;
        updateMedicalReportGroup.data.descriptionHtml = ((html === '<p><br></p>') ? null : html);
        updateMedicalReportGroup.hasUnsavedChanges = true;
    }

    const handleExamDatasetReorder = (event) => {
        const { toIndex, fromIndex } = event;
        let tempExamDatasets = [...toJS(updateMedicalReportGroup.selectedDatasets)];

        tempExamDatasets.splice(fromIndex, 1);
        tempExamDatasets.splice(toIndex, 0, toJS(updateMedicalReportGroup.selectedDatasets[fromIndex]));

        for (let i = 0; i < tempExamDatasets.length; i++) {
            tempExamDatasets[i].displayOrder = (i + 1);
        }

        updateMedicalReportGroup.data.examDatasetIds = tempExamDatasets.sort((a, b) => a.displayOrder - b.displayOrder).map(d => { return d.id });
        updateMedicalReportGroup.hasUnsavedChanges = true;
    }

    const handleSelectDataset = event => {
        const extraProps = {
            examPublishedTemplateId: updateMedicalReportGroup.data.examPublishedTemplateId,
            selectedDatasets: toJS(updateMedicalReportGroup.selectedDatasets)
        }
        quickDrawer.activateQuickDrawer('medicalReport', 'exam-data', extraProps, handleAddDatasetSuccess, handleAddDatasetCancel)
            .then(drawer => {
                if (isMounted.current) {
                    focusTimer.current = setTimeout(() => {
                        quickDrawerFocus(drawer);
                    }, 100);
                }
            });
    }

    const handleAddDatasetSuccess = (result) => {
        if (result && result.updated) {
            let tempExamDatasetIds = toJS(updateMedicalReportGroup.data.examDatasetIds);

            tempExamDatasetIds = tempExamDatasetIds.filter(ti => result.data.items.some(i => ti === i.id));
            tempExamDatasetIds = Array.from(new Set([...tempExamDatasetIds, ...result.data.items.map(i => { return i.id })]));

            updateMedicalReportGroup.data.examDatasetIds = tempExamDatasetIds;
            updateMedicalReportGroup.hasUnsavedChanges = true;
        }
    }

    const handleAddDatasetCancel = () => {
    }

    const renderExamDataset = (item, index) => {
        return <>
            <div className={'form-group mb-0' + (index < (updateMedicalReportGroup.selectedDatasets.length - 1) ? ' border-bottom' : '')}>
                <div className='cursor-pointer'>
                    <h4 className='m-0 text-truncate text-truncate-xxl'><strong>{item.label}</strong></h4>
                    <small>{item.key}</small>
                </div>
            </div>
        </>
    }

    return <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form onSubmit={handleSubmit}>
            <fieldset disabled={updateMedicalReportGroup.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('exam-dataset', 'default')}
                        action='Update'
                        category='Medical Report Exam Dataset'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        <Observer>{() =>
                            <>
                                {
                                    updateMedicalReportGroup.isReady ?
                                        <FadeIn>
                                            <div className='update-medical-report-exam-dataset body-content'>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-4'>
                                                                <label className='required' htmlFor='update-exam-dataset-name'><small>Name</small></label>
                                                                <Observer>{() =>
                                                                    <input
                                                                        id='update-exam-dataset-name'
                                                                        type='text'
                                                                        maxLength={100}
                                                                        className='form-control'
                                                                        autoComplete={'off'}
                                                                        value={updateMedicalReportGroup.data.name ? updateMedicalReportGroup.data.name : ''}
                                                                        onChange={handleNameChange}
                                                                    />
                                                                }</Observer>
                                                                <small className='text-gray-700'><strong>Important:</strong> Name must be unique across all inputs.</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row datasets'>
                                                        <div className='col-12'>
                                                            <label><small>Exam Dataset(s)</small></label>
                                                            <div>
                                                                <List
                                                                    dataSource={
                                                                        new DataSource({
                                                                            key: "id",
                                                                            store: updateMedicalReportGroup.selectedDatasets,
                                                                            paginate: false,
                                                                        })
                                                                    }
                                                                    activeStateEnabled={false}
                                                                    focusStateEnabled={false}
                                                                    hoverStateEnabled={false}
                                                                    itemRender={(data) => { return renderExamDataset(data) }}
                                                                >
                                                                    <ItemDragging
                                                                        allowReordering={true}
                                                                        onReorder={handleExamDatasetReorder}
                                                                    >
                                                                    </ItemDragging>
                                                                </List>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section>
                                                    {
                                                        showAdvanced ?
                                                            <>
                                                                <FadeIn>
                                                                    <div className='row'>
                                                                        <div className='col-12'>
                                                                            <div className='form-group mb-4'>
                                                                                <label htmlFor='update-exam-dataset-description'><small>Description</small></label>
                                                                                <Observer>{() =>
                                                                                    <RichTextEditor
                                                                                        mode='none'
                                                                                        disableTab={true}
                                                                                        value={updateMedicalReportGroup.data.descriptionHtml ? updateMedicalReportGroup.data.descriptionHtml : ''}
                                                                                        onChange={handleDescriptionChange}
                                                                                    />
                                                                                }</Observer>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </FadeIn>
                                                            </> :
                                                            <div className='mt-4 pt-4 border-top'>
                                                                <button type='button' className='btn btn-link btn-sm p-0' onClick={() => { setShowAdvanced(true) }}>&raquo;	Show advanced options</button>
                                                            </div>
                                                    }
                                                </section>
                                            </div>
                                        </FadeIn> : renderQuickDrawerLoading()
                                }
                            </>
                        }</Observer>
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-4'>
                                <button
                                    type='button'
                                    className='btn btn-light'
                                    onClick={handleSelectDataset}
                                >
                                    Add
                                </button>
                            </div>
                            <div className='col-8'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-danger mr-2'
                                        onClick={handleDelete}
                                    >Delete</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
        <BodyEnd>
            <ConfirmModal
                icon={<i className='fal fa-database text-danger mr-2'></i>}
                message={<>Continue to delete dataset group {updateMedicalReportGroup.data && updateMedicalReportGroup.data.key ? `[${updateMedicalReportGroup.data.key}]` : ''}? </>}
                show={confirmDelete}
                onOption1Click={handleConfirmDelete}
                onCancel={handleConfirmDeleteCancel}
            />
        </BodyEnd>
    </>
}

export default UpdateMedicalReportTenantExamDatasetGroup;