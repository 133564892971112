import React, { useEffect, useContext, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { useObserver } from 'mobx-react-lite';
import { GlobalHotKeys } from 'react-hotkeys';

import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';

import PredefinedExamSelectStore from '../../../../stores/PredefinedExamSelectStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as fn from '../../../../utilities/_functions';
import * as oh from '../../../../utilities/operationHelper';

import './PredefinedExam.scss';

function PredefinedExam(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const predefinedExam = useContext(PredefinedExamSelectStore);
    const quickDrawer = useContext(QuickDrawerStore);

    useEffect(() => {
        quickDrawerFocus(props.drawer);

        return () => {
            isMounted.current = false;
        }
    }, []) // eslint-disable-line

    const handleSubmit = event => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            predefinedExam.save()
                .then(data => {
                    if (isMounted.current) {
                        if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                            props.onSuccess(event, data);
                        }
                    }
                })
        }
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                props.onCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <fieldset disabled={predefinedExam.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('exam')}
                        action='Choose a'
                        category='Prefilled Exam Template'
                        className='exams'
                        onCancel={props.onCancel}
                    />
                    <div className='quick-drawer-body'>
                        {
                            predefinedExam.isReady ?
                                <FadeIn>
                                    <div className='predefined-exam body-content'>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0 validate validate-required'>
                                                        <label className='required'><small>Select a template</small></label>
                                                        {
                                                            predefinedExam.dataSets && predefinedExam.dataSets.length > 0 ?
                                                                predefinedExam.dataSets.map((d, di) => {
                                                                    return <div
                                                                        key={`predefined-exam-${di}`}
                                                                        className='custom-control custom-radio mb-2'
                                                                    >
                                                                        <input
                                                                            id={`predefinedExam-${di}`}
                                                                            type='radio'
                                                                            name='predefinedExam'
                                                                            className='custom-control-input'
                                                                            value={d.id}
                                                                            checked={predefinedExam.selectedId === d.id}
                                                                            onChange={() => { predefinedExam.selectedId = d.id }}
                                                                        />
                                                                        <label
                                                                            htmlFor={`predefinedExam-${di}`}
                                                                            className='custom-control-label'
                                                                        >
                                                                            {d.name}
                                                                        </label>
                                                                    </div>
                                                                }) : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </FadeIn> : renderQuickDrawerLoading()
                        }
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={props.onCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                    >Select</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>)
}

export default PredefinedExam;