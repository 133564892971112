import { createContext } from 'react';
import uuid from 'react-uuid';
import { decorate, observable, action, computed, toJS } from 'mobx';
import moment from 'moment';

import api from '../api';
import * as fn from '../utilities/_functions';
import { CURRENT_USER } from '../constants/storageKeys';

export class NoteCreate {
    data = {
        id: null,
        customerId: null,
        filterType: null,
        noteType: null,
        referenceId: null,
        preview: null,
        bodyHtml: null,
        attachments: [],
        resource: null,
        createdDateUtc: null,
    };
    customer = null;
    referenceType = null;
    referenceId = null;
    isSaving = false;
    saveToServer = true;

    cancelNote = null;

    initialize = (customer, noteType, referenceType, referenceId, saveToServer) => {
        this.clear();

        if (!customer) throw new Error('Object "customer" is required to create new note.');

        this.customer = customer;
        this.referenceType = referenceType;
        this.referenceId = referenceId;
        this.data.customerId = customer.id;
        this.data.filterType = referenceType;
        this.data.noteType = fn.isNullOrUndefined(noteType) ? 'Customer' : noteType;
        this.data.referenceId = referenceId;

        if (!fn.isNullOrUndefined(saveToServer)) {
            this.saveToServer = saveToServer;
        }

        if (fn.isNullOrUndefined(this.customer.notes)) {
            this.customer.notes = [];
        }
    }

    remove = ({ id }) => {
        const index = this.customer.notes.findIndex(n => n.id === id);
        if (index >= 0) {
            this.customer.notes.splice(index, 1);
        }
        return this.customer.notes;
    }

    save = (notify) => {
        const that = this;
        const currentUser = JSON.parse(window.localStorage.getItem(CURRENT_USER));

        this.data.id = uuid();
        this.data.customerId = this.customer.id;
        this.data.resource = currentUser;
        this.data.createdDateUtc = moment.utc().toDate();

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            if (that.hasUnsavedChanges) {
                if (that.saveToServer) {
                    api.Notes.create(this.data, (c) => { that.cancelNote = c })
                        .then(({ data }) => {
                            that.data.id = data.id;
                            that.customer.notes.push(that.data);
                            resolve(toJS(that.customer.notes));
                        })
                        .catch(() => {
                            reject();
                        })
                        .finally(() => {
                            that.isSaving = false;
                        })
                } else {
                    resolve(toJS(this.data));
                }
            } else {
                that.isSaving = false;
                resolve();
            }
        })
    }

    clear = () => {
        this.data.id = null;
        this.data.customerId = null;
        this.data.resource = null;
        this.data.noteType = null;
        this.customer = null;
        this.referenceId = null;
        this.referenceType = null;
        this.saveToServer = true;
        this.isSaving = false;
        this.clearNewNote();

        if (fn.isFunction(this.cancelNote)) {
            this.cancelNote();
            this.cancelNote = null;
        }
    }

    clearNewNote = () => {
        this.data.referenceId = null;
        this.data.preview = null;
        this.data.bodyHtml = null;
        this.data.attachments.clear();
        this.data.createdDateUtc = null;
    }

    get hasUnsavedChanges() {
        return (this.data.preview || this.data.attachments.length > 0);
    }
}

decorate(NoteCreate, {
    data: observable.deep,
    customer: observable.deep,
    referenceType: observable,
    referenceId: observable,
    isSaving: observable,
    saveToServer: observable,
    initialize: action,
    save: action,
    clear: action,
    clearNewNote: action,
    hasUnsavedChanges: computed,
})

export default createContext(new NoteCreate());