export const getInventoryTypeDescription = (inventoryType) => {
    if (inventoryType) {
        switch (inventoryType.toLowerCase()) {
            case 'physical':
                return 'Physical inventory';

            case 'nonphysical':
                return 'Non-Physical inventory'

            case 'thirdparty':
                return 'Third-party inventory'

            default:
                break;
        }
    }

    return null;
}

export const clone = (source, destination) => {
    destination.isStocked = source.isStocked;
    destination.isTracked = source.isTracked;
    destination.name = `Copy of ${source.name}`;
    destination.descriptor = `Copy of ${source.descriptor}`;
    destination.quantity = 1;
    destination.cost = source.cost;
    destination.price = source.price;
    destination.data = source.data;
    destination.skuNumber = null;
    destination.serialNumbers = new Array(1);

    return destination;
}