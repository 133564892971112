import { createContext } from 'react';
import uuid from 'react-uuid';
import { decorate, observable, action, toJS } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class ReferralTenantExamDatasetGroupCreate {
    id = null;
    data = {
        name: null,
        descriptionHtml: null,
        examDatasetIds: [],
        examPublishedTemplateId: null,
    };
    datasets = [];
    isReady = false;
    isSaving = false;
    hasUnsavedChanges = false;

    cancelReferralTenantExamDatasetGroupCreate = null;

    initialize = (examPublishedTemplateId) => {
        const that = this;

        this.clear();
        this.id = uuid();
        this.data.examPublishedTemplateId = examPublishedTemplateId;

        return new Promise((resolve, reject) => {
            api.ExamDatasetGroups.search({
                parameters: [{
                    field: 'ExamPublishedTemplateId',
                    value: examPublishedTemplateId,
                }],
                sortByFields: [{
                    field: 'CreatedDateUtc',
                    direction: 'DESC',
                }],
                includeTotalCount: false,
                loadProperties: true,
                limit: 1
            })
                .then(({ data }) => {
                    that.datasets = data != null && data.result && data.result.length > 0 && data.result[0].examDatasets && data.result[0].examDatasets.length > 0 ? data.result[0].examDatasets : []
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isReady = true;
                })
        })
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            let option = toJS(that.data);

            if (that.hasUnsavedChanges) {
                api.ReferralTenantExamDatasetGroups.create(option, (c) => { that.cancelReferralTenantExamDatasetGroupCreate = c })
                    .then(({ data }) => {
                        that.id = data.id;
                        that.hasUnsavedChanges = false;
                        option.id = data.id;
                        resolve(option);
                    })
                    .catch(() => {
                        reject();
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                that.hasUnsavedChanges = false;
                that.isSaving = false;
            }
        })
    }

    clear = () => {
        this.id = null;
        this.data.name = null;
        this.data.descriptionHtml = null;
        this.data.examDatasetIds.clear();
        this.data.examPublishedTemplateId = null;
        this.datasets.clear();
        this.isReady = false;
        this.isSaving = false;
        this.hasUnsavedChanges = false;

        if (fn.isFunction(this.cancelReferralTenantExamDatasetGroupCreate)) {
            this.cancelReferralTenantExamDatasetGroupCreate();
            this.cancelReferralTenantExamDatasetGroupCreate = null;
        }
    }
}

decorate(ReferralTenantExamDatasetGroupCreate, {
    id: observable,
    data: observable.deep,
    datasets: observable,
    isReady: observable,
    isSaving: observable,
    initialize: action,
    hasUnsavedChanges: observable,
    save: action,
    clear: action,
})

export default createContext(new ReferralTenantExamDatasetGroupCreate());