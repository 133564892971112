import React from 'react';
import { useObserver } from 'mobx-react-lite';

import BodyEnd from '../../_shared/BodyEnd';
import ConfirmModal from '../../_shared/ConfirmModal';

function NotAvailableModal(props) {
    return useObserver(() => <>
        <BodyEnd>
            <ConfirmModal
                icon={<i className='text-danger mr-2 fal fa-ban'></i>}
                message={<>1 or more item(s) in this work order is no longer available in your inventory.</>}
                description={<>Please fix and try again.</>}
                cancelText={'Cancel'}
                option1ClassName={'d-none'}
                show={props.display}                
                onCancel={props.onCancel}
            />
        </BodyEnd>
    </>)
}

export default NotAvailableModal;