import { createContext } from 'react';
import { decorate, observable, reaction, computed, action } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class FileViewer {
    title = null;
    type = null;
    files = [];
    index = null;
    customerId = null;
    initialIndex = null;
    isReady = false;
    renderTitle = null;
    disposer = null;
    onCloseCallback = null;

    cancelAttachmentDownload = null;
    cancelPrescriptionDownload = null;
    cancelPreviewDocumentDownload = null;
    cancelPrintedDocumentDownload = null;
    cancelCommunicationDownload = null;

    constructor() {
        reaction(
            () => this.index, (index) => {
                const that = this;

                if (index !== null && index < that.files.length) {
                    const nextIndex = (index + 1) < that.files.length ? (index + 1) : 0;
                    const prevIndex = (index - 1) > -1 ? (index - 1) : (that.files.length - 1);

                    if (!that.files[index].isLoaded) {
                        that.loadFile(that.files[index])
                            .then(file => {
                                if (that.files && that.files[index]) {
                                    that.files[index].uri = file.uri;
                                    that.files[index].base64 = file.base64;
                                }
                            })
                            .catch(() => {
                                if (that.files && that.files[index]) {
                                    that.files[index].hasError = true;
                                }
                            })
                            .finally(() => {
                                if (that.files && that.files[index]) {
                                    that.files[index].isLoaded = true;
                                }
                            })
                    }
                    if (nextIndex !== index && !that.files[nextIndex].isLoaded) {
                        that.loadFile(that.files[nextIndex])
                            .then(file => {
                                if (that.files && that.files[nextIndex]) {
                                    that.files[nextIndex].uri = file.uri;
                                    that.files[nextIndex].base64 = file.base64;
                                }
                            })
                            .catch(() => {
                                if (that.files && that.files[nextIndex]) {
                                    that.files[nextIndex].hasError = true;
                                }
                            })
                            .finally(() => {
                                if (that.files && that.files[nextIndex]) {
                                    that.files[nextIndex].isLoaded = true;
                                }
                            })
                    }
                    if (prevIndex !== index && prevIndex !== nextIndex && !that.files[prevIndex].isLoaded) {
                        that.loadFile(that.files[prevIndex])
                            .then(file => {
                                if (that.files && that.files[prevIndex]) {
                                    that.files[prevIndex].uri = file.uri;
                                    that.files[prevIndex].base64 = file.base64;
                                }
                            })
                            .catch(() => {
                                if (that.files && that.files[prevIndex]) {
                                    that.files[prevIndex].hasError = true;
                                }
                            })
                            .finally(() => {
                                if (that.files && that.files[prevIndex]) {
                                    that.files[prevIndex].isLoaded = true;
                                }
                            })
                    }
                }
            }
        )
    }

    initialize = (title, type, files, index, customerId) => {
        this.clear();

        if (files && index > -1) {
            for (let i = 0; i < files.length; i++) {
                this.files.push({
                    id: files[i].id,
                    type: type,
                    fileName: files[i].fileName,
                    mimeType: files[i].mimeType,
                    size: files[i].size,
                    key: files[i].id,
                    createdById: files[i].createdById,
                    createdDateUtc: files[i].createdDateUtc,
                    uri: files[i].uri,
                    base64: files[i].base64,
                    isLoaded: files[i].isLoaded,
                    hasError: files[i].hasError,
                })
            }

            this.renderTitle = title;
            this.initialIndex = index;
            this.index = index;
            this.customerId = customerId;
            this.isReady = true;
        }
    }

    clear = () => {
        this.title = null;
        this.type = null;
        this.files.clear();
        this.index = null;
        this.initialIndex = null;
        this.customerId = null;
        this.isReady = false;
        this.onCloseCallback = null;

        if (fn.isFunction(this.cancelAttachmentDownload)) {
            this.cancelAttachmentDownload();
            this.cancelAttachmentDownload = null;
        }

        if (fn.isFunction(this.cancelPrescriptionDownload)) {
            this.cancelPrescriptionDownload();
            this.cancelPrescriptionDownload = null;
        }

        if (fn.isFunction(this.cancelPreviewDocumentDownload)) {
            this.cancelPreviewDocumentDownload();
            this.cancelPreviewDocumentDownload = null;
        }

        if (fn.isFunction(this.cancelPrintedDocumentDownload)) {
            this.cancelPrintedDocumentDownload();
            this.cancelPrintedDocumentDownload = null;
        }

        if (fn.isFunction(this.cancelCommunicationDownload)) {
            this.cancelCommunicationDownload();
            this.cancelCommunicationDownload = null;
        }

        if (fn.isFunction(this.disposer)) {
            this.disposer();
            this.disposer = null;
        }
    }

    loadFile = file => {
        const that = this;

        if (file) {
            return new Promise((resolve, reject) => {
                switch (file.type) {
                    case 'attachment':
                        api.Attachments.download(file.key, (c) => { that.cancelAttachmentDownload = c })
                            .then(({ data }) => {
                                const reader = new FileReader();
                                file.uri = URL.createObjectURL(data);
                                reader.readAsDataURL(data);
                                reader.onloadend = () => {
                                    const base64data = reader.result;
                                    file.base64 = base64data;
                                    resolve(file);
                                }
                            })
                            .catch(() => { reject() });
                        break;

                    case 'previewDocument':
                        api.PreviewDocuments.download(file.key, (c) => { that.cancelPrintedDocumentDownload = c })
                            .then(({ data }) => {
                                const reader = new FileReader();
                                file.uri = URL.createObjectURL(data);
                                reader.readAsDataURL(data);
                                reader.onloadend = () => {
                                    const base64data = reader.result;
                                    file.base64 = base64data;
                                    resolve(file);
                                }
                            })
                            .catch(() => { reject() });
                        break;

                    case 'printedDocument':
                        api.PrintedDocuments.download(file.key, (c) => { that.cancelPrintedDocumentDownload = c })
                            .then(({ data }) => {
                                const reader = new FileReader();
                                file.uri = URL.createObjectURL(data);
                                reader.readAsDataURL(data);
                                reader.onloadend = () => {
                                    const base64data = reader.result;
                                    file.base64 = base64data;
                                    resolve(file);
                                }
                            })
                            .catch(() => { reject() });
                        break;

                    case 'communication':
                        api.Communications.download(file.key, (c) => { that.cancelCommunicationDownload = c })
                            .then(({ data }) => {
                                const reader = new FileReader();
                                file.uri = URL.createObjectURL(data);
                                reader.readAsDataURL(data);
                                reader.onloadend = () => {
                                    const base64data = reader.result;
                                    file.base64 = base64data;
                                    resolve(file);
                                }
                            })
                            .catch(() => { reject() });
                        break;

                    default:
                        return Promise.reject();
                }
            })
        }

        return Promise.reject();
    }

    get currentFile() {
        if (!this.files || this.files.length === 0) return null;
        return this.files[this.index];
    }

    get canSend() {
        return this.currentFile && ['attachment', 'printedDocument'].some(d => d === this.currentFile.type);
    }
}

decorate(FileViewer, {
    title: observable,
    type: observable,
    files: observable.deep,
    index: observable,
    customerId: observable,
    initialIndex: observable,
    isReady: observable,
    currentFile: computed,
    canSend: computed,
    initialize: action,
    loadFile: action,
    clear: action,
})

export default createContext(new FileViewer());