import React, { useContext, useEffect, useRef, useState } from 'react';
import FadeIn from 'react-fade-in';
import { Observer } from 'mobx-react-lite';
import momentLocalizer from 'react-widgets-moment';
import { GlobalHotKeys } from 'react-hotkeys';
import moment from 'moment';

import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import TimelineItemViewStore from '../../../../stores/TimelineItemViewStore';
import ServiceCreateStore from '../../../../stores/ServiceCreateStore';
import ServiceAttributeUpdateStore from '../../../../stores/ServiceAttributeUpdateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as oh from '../../../../utilities/operationHelper';

import './UpdateServiceTimelineItems.scss';
import { DataGrid } from 'devextreme-react';
import { Column, RowDragging, Selection } from 'devextreme-react/data-grid';
import { toJS } from 'mobx';
import api from '../../../../api';

moment.locale('en');
momentLocalizer();

// The logic in this component is bit messy because it needs to deal with both new Service (which save into Service table) and update Service Attributes as well
// This component could be called from NewService.js and UpdateServiceAttributes.js
function UpdateServiceTimelineItems(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const timelineItems = useContext(TimelineItemViewStore);
    const newService = useContext(ServiceCreateStore);
    const updateService = useContext(ServiceAttributeUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [ready, setReady] = useState(false);
    const [availableList, setAvailableList] = useState([]);
    const [addedList, setAddedList] = useState([]);
    const rowKeysFromAddedList = useRef([]);
    const rowKeysFromAvailableList = useRef([]);
    const serviceRef = useRef({});

    // cannot use the "update store" hasUnsavedChanges flag. It will mess up the state since this is level 2 quickdraw
    const hasUnsavedChangesRef = useRef(false);

    const loadData = async () => {
        await timelineItems.initialize();
        if (props.extraProps.serviceId) {
            // update service attributes
            serviceRef.current = updateService;
        }
        else {
            // new service
            serviceRef.current = newService;
        }

        var tempList = JSON.parse(JSON.stringify(timelineItems.items));
        serviceRef.current.data.timelineItems.forEach(a => {
            // the service attribute model timeline items do not have name filled
            const item = timelineItems.items.find(t => t.id === a.id);
            a.name = item.name;
        });
        setAddedList(JSON.parse(JSON.stringify(serviceRef.current.data.timelineItems.sort((a, b) => a.displayOrder - b.displayOrder))));
        // based on what are already in the service store, initialize the available and added list
        for (let savedItem of serviceRef.current.data.timelineItems) {
            const index = tempList.findIndex(tmp => {
                return tmp.id === savedItem.id;
            });
            tempList.splice(index, 1);
        }
        setAvailableList(tempList);
        setReady(true);
        isMounted.current = true;
    }

    useEffect(() => {
        loadData();
        quickDrawerFocus(props.drawer);

        return () => {
            isMounted.current = false;
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (hasUnsavedChangesRef.current) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }
    const handleSubmit = async (event) => {
        event.preventDefault();

        for (let i = 0; i < addedList.length; i++) {
            addedList[i].displayOrder = i * 10 + 10;
        }
        serviceRef.current.data.timelineItems = addedList;
        if (props.extraProps.serviceId) {
            try {
                await api.Services.updateTimelineItems(props.extraProps.serviceId, serviceRef.current.data.timelineItems);
            }
            catch (e)
            {
                window.alert('Service timeline items update failed: ' + e);
            }
        }
        if (props.onSuccess && fn.isFunction(props.onSuccess)) {
            props.onSuccess(event, { updated: false, data: null });
        }
    }
    const handleAvailableSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
        // temporarily store the "checked" items from datagrid
        rowKeysFromAvailableList.current = selectedRowKeys;
    }
    const handleAddedSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
        // temporarily store the "checked" items from datagrid
        rowKeysFromAddedList.current = selectedRowKeys;
    }
    const handleAddRowsFromAvailableList = () => {
        hasUnsavedChangesRef.current = true;
        // Remove from one list and add to the other list
        const toBeAdded = [];
        var tempList = JSON.parse(JSON.stringify(availableList));
        for (let key of rowKeysFromAvailableList.current) {
            const index = tempList.findIndex(item => {
                return item.id === key;
            });

            const item = tempList.splice(index, 1)[0];
            toBeAdded.push(item);
        }
        setAvailableList(tempList);
        setAddedList([...addedList, ...toBeAdded]);
    }
    const handleRemoveRowsFromAddedList = () => {
        hasUnsavedChangesRef.current = true;
        // Remove from one list and add to the other list
        const toBeRemoved = [];
        var tempList = JSON.parse(JSON.stringify(addedList));
        for (let key of rowKeysFromAddedList.current) {
            const index = tempList.findIndex(item => {
                return item.id === key;
            });

            const item = tempList.splice(index, 1)[0];
            toBeRemoved.push(item);
        }
        // original sort is based on name; try to keep the same when items are added back to available list
        const sortedList = [...availableList, ...toBeRemoved].sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        setAvailableList(sortedList);
        setAddedList(tempList);
    }
    const handleReorder = (event) => {
        hasUnsavedChangesRef.current = true;
        const { toIndex, fromIndex } = event;
        var tempList = JSON.parse(JSON.stringify(addedList));
        tempList.splice(fromIndex, 1);
        tempList.splice(toIndex, 0, toJS(addedList[fromIndex]));
        setAddedList(tempList);
    }
    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <fieldset disabled={serviceRef.current.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('service', 'update')}
                        action='Update'
                        category={`Service Timeline Items`}
                        className='services'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        <Observer>{() =>
                            ready ?
                                <FadeIn>
                                    <div className='update-service-timeline body-content'>
                                        <Observer>{() =>
                                            <>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <label><small>Select Available Item(s)</small></label>
                                                            <DataGrid
                                                                keyExpr="id"
                                                                dataSource={availableList}
                                                                rowAlternationEnabled={true}
                                                                hoverStateEnabled={true}
                                                                showBorders={true}
                                                                onSelectionChanged={handleAvailableSelectionChanged}
                                                            >
                                                                <Selection
                                                                    mode="multiple"
                                                                    selectAllMode='allPages'
                                                                    showCheckBoxesMode='always'
                                                                />
                                                                <Column dataField="name"
                                                                    caption='Name'
                                                                />
                                                            </DataGrid>
                                                        </div>
                                                    </div>
                                                    <div class='row'>
                                                        <div className='col-12'>
                                                            <div className='mt-2 float-right'>
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-primary width-sm'
                                                                    title='Add Timeline Item(s)'
                                                                    onClick={handleAddRowsFromAvailableList}
                                                                >Add</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <label><small>Added Item(s)</small></label>
                                                            <DataGrid
                                                                keyExpr="id"
                                                                dataSource={addedList}
                                                                rowAlternationEnabled={true}
                                                                hoverStateEnabled={true}
                                                                showBorders={true}
                                                                onSelectionChanged={handleAddedSelectionChanged}
                                                            >
                                                                <RowDragging
                                                                    allowReordering={true}
                                                                    onReorder={handleReorder}
                                                                    showDragIcons={true}
                                                                />
                                                                <Selection
                                                                    mode="multiple"
                                                                    selectAllMode='allPages'
                                                                    showCheckBoxesMode='always'
                                                                />
                                                                <Column dataField="name"
                                                                    caption='Name'
                                                                />
                                                            </DataGrid>
                                                        </div>
                                                    </div>
                                                    <div class='row'>
                                                        <div className='col-12'>
                                                            <div className='mt-2 float-right'>
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-warning width-sm'
                                                                    title='Add Timeline Item(s)'
                                                                    onClick={handleRemoveRowsFromAddedList}
                                                                >Remove</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </>
                                        }</Observer>
                                    </div>
                                </FadeIn> : renderQuickDrawerLoading()
                        }</Observer>
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                        onClick={handleSubmit}
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form >
    </>
}

export default UpdateServiceTimelineItems;