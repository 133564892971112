import React from 'react';
import { isMobile } from 'react-device-detect';
import MaskedInput from 'react-text-mask'
import uuid from 'react-uuid';
import { toJS } from 'mobx';

import RichTextEditor from '../components/views/_shared/RichTextEditor';
import TabbedTextArea from '../components/views/_shared/TabbedTextArea';
import ImageAnnotation from '../components/views/_shared/ImageAnnotation';

import * as MaskKeys from '../constants/maskKeys';
import * as ErrorMessages from '../constants/errorMessages';
import * as fn from '../utilities/_functions';

export const TEMPLATE_INPUT_DESCRIPTION = 'TEMPLATE_INPUT_DESCRIPTION';
export const TEMPLATE_INPUT_LABEL = 'TEMPLATE_INPUT_LABEL';
export const TEMPLATE_INPUT_METADATA_IS_REQUIRED = 'TEMPLATE_INPUT_METADATA_IS_REQUIRED';
export const TEMPLATE_INPUT_METADATA_SIZE = 'TEMPLATE_INPUT_METADATA_SIZE';
export const TEMPLATE_INPUT_METADATA_PLACEHOLDER = 'TEMPLATE_INPUT_METADATA_PLACEHOLDER';
export const TEMPLATE_INPUT_METADATA_HINT = 'TEMPLATE_INPUT_METADATA_HINT';
export const TEMPLATE_INPUT_METADATA_ALIGN = 'TEMPLATE_INPUT_METADATA_ALIGN';
export const TEMPLATE_INPUT_METADATA_VALIGN = 'TEMPLATE_INPUT_METADATA_VALIGN';
export const TEMPLATE_INPUT_METADATA_OPTIONS = 'TEMPLATE_INPUT_METADATA_OPTIONS';
export const TEMPLATE_INPUT_METADATA_INLINE = 'TEMPLATE_INPUT_METADATA_INLINE';
export const TEMPLATE_INPUT_METADATA_DEFAULT_VALUE = 'TEMPLATE_INPUT_METADATA_DEFAULT_VALUE';
export const TEMPLATE_INPUT_METADATA_IMAGE_URL = 'TEMPLATE_INPUT_METADATA_IMAGE_URL';
export const TEMPLATE_INPUT_METADATA_INPUT_MASK = 'TEMPLATE_INPUT_METADATA_INPUT_MASK';
export const TEMPLATE_INPUT_METADATA_DISPLAY = 'TEMPLATE_INPUT_METADATA_DISPLAY';
export const TEMPLATE_INPUT_METADATA_MAPPING = 'TEMPLATE_INPUT_METADATA_MAPPING';
export const TEMPLATE_INPUT_METADATA_DATA_TYPE = 'TEMPLATE_INPUT_METADATA_DATA_TYPE';
export const TEMPLATE_INPUT_METADATA_DATA_PADDING_TYPE = 'TEMPLATE_INPUT_METADATA_DATA_PADDING_TYPE';
export const TEMPLATE_INPUT_METADATA_DATA_PADDING_CHARACTER = 'TEMPLATE_INPUT_METADATA_DATA_PADDING_CHARACTER';
export const TEMPLATE_INPUT_METADATA_DATA_PADDING_LENGTH = 'TEMPLATE_INPUT_METADATA_DATA_PADDING_LENGTH';
export const TEMPLATE_INPUT_METADATA_DATA_DECIMAL_PLACES = 'TEMPLATE_INPUT_METADATA_DATA_DECIMAL_PLACES';
export const TEMPLATE_INPUT_METADATA_DATA_DEFAULT_NEGATIVE = 'TEMPLATE_INPUT_METADATA_DATA_DEFAULT_NEGATIVE';
export const TEMPLATE_INPUT_METADATA_DATA_FORMAT = 'TEMPLATE_INPUT_METADATA_DATA_FORMAT';
export const TEMPLATE_INPUT_METADATA_DATA_VALIDATION = 'TEMPLATE_INPUT_METADATA_DATA_VALIDATION';
export const TEMPLATE_INPUT_METADATA_FILE_TEXT = 'TEMPLATE_INPUT_METADATA_FILE_TEXT';
export const TEMPLATE_INPUT_METADATA_FILE_PREVIEW = 'TEMPLATE_INPUT_METADATA_FILE_PREVIEW';
export const TEMPLATE_INPUT_METADATA_FILE_MULTIPLE = 'TEMPLATE_INPUT_METADATA_FILE_MULTIPLE';

export const InputElementTypes = ['SingleLineText', 'MultiLineText', 'RichText', 'Dropdown', 'Radio', 'Checkbox', 'Date', 'Time', 'ImageAnnotation', 'MaskedInput', 'FileViewer'];

export const isInputElement = ({ type }) => {
    return InputElementTypes.some(t => t === type);
}

export const getIcon = (type) => {
    switch (type) {
        case 'SingleLineText':
            return 'fal fa-text';

        case 'MultiLineText':
            return 'fal fa-align-left';

        case 'RichText':
            return 'fal fa-spell-check';

        case 'Dropdown':
            return 'fal fa-chevron-square-down';

        case 'Radio':
            return 'fal fa-scrubber';

        case 'Checkbox':
            return 'fal fa-check-square';

        case 'Check':
            return 'fal fa-check-square';

        case 'Circle':
            return 'fal fa-circle';

        case 'Date':
            return 'fal fa-calendar-day';

        case 'Time':
            return 'fal fa-clock';

        case 'ImageAnnotation':
            return 'fal fa-image-polaroid';

        case 'MaskedInput':
            return 'fal fa-asterisk';

        case 'FileViewer':
            return 'fal fa-file-search';

        default:
            return false
    }
}

export const hasField = (type, field) => {
    switch (type) {
        case 'SingleLineText':
            return [
                TEMPLATE_INPUT_LABEL,
                TEMPLATE_INPUT_DESCRIPTION,
                TEMPLATE_INPUT_METADATA_IS_REQUIRED,
                TEMPLATE_INPUT_METADATA_PLACEHOLDER,
                TEMPLATE_INPUT_METADATA_HINT,
                TEMPLATE_INPUT_METADATA_SIZE,
                TEMPLATE_INPUT_METADATA_ALIGN,
                TEMPLATE_INPUT_METADATA_DEFAULT_VALUE,
                TEMPLATE_INPUT_METADATA_DISPLAY,
                TEMPLATE_INPUT_METADATA_MAPPING,
                TEMPLATE_INPUT_METADATA_DATA_TYPE,
                TEMPLATE_INPUT_METADATA_DATA_PADDING_TYPE,
                TEMPLATE_INPUT_METADATA_DATA_PADDING_CHARACTER,
                TEMPLATE_INPUT_METADATA_DATA_PADDING_LENGTH,
                TEMPLATE_INPUT_METADATA_DATA_DECIMAL_PLACES,
                TEMPLATE_INPUT_METADATA_DATA_DEFAULT_NEGATIVE,
                TEMPLATE_INPUT_METADATA_DATA_FORMAT,
                TEMPLATE_INPUT_METADATA_DATA_VALIDATION,
            ].some(f => f === field);

        case 'MultiLineText':
        case 'RichText':
            return [
                TEMPLATE_INPUT_LABEL,
                TEMPLATE_INPUT_DESCRIPTION,
                TEMPLATE_INPUT_METADATA_IS_REQUIRED,
                TEMPLATE_INPUT_METADATA_PLACEHOLDER,
                TEMPLATE_INPUT_METADATA_HINT,
                TEMPLATE_INPUT_METADATA_SIZE,
                TEMPLATE_INPUT_METADATA_ALIGN,
                TEMPLATE_INPUT_METADATA_DEFAULT_VALUE,
                TEMPLATE_INPUT_METADATA_DISPLAY,
                TEMPLATE_INPUT_METADATA_MAPPING,
            ].some(f => f === field);

        case 'MaskedInput':
            return [
                TEMPLATE_INPUT_LABEL,
                TEMPLATE_INPUT_DESCRIPTION,
                TEMPLATE_INPUT_METADATA_IS_REQUIRED,
                TEMPLATE_INPUT_METADATA_PLACEHOLDER,
                TEMPLATE_INPUT_METADATA_HINT,
                TEMPLATE_INPUT_METADATA_SIZE,
                TEMPLATE_INPUT_METADATA_ALIGN,
                TEMPLATE_INPUT_METADATA_INPUT_MASK,
                TEMPLATE_INPUT_METADATA_DISPLAY,
                TEMPLATE_INPUT_METADATA_MAPPING,
            ].some(f => f === field);

        case 'Date':
        case 'Time':
            return [
                TEMPLATE_INPUT_LABEL,
                TEMPLATE_INPUT_DESCRIPTION,
                TEMPLATE_INPUT_METADATA_IS_REQUIRED,
                TEMPLATE_INPUT_METADATA_PLACEHOLDER,
                TEMPLATE_INPUT_METADATA_SIZE,
                TEMPLATE_INPUT_METADATA_ALIGN,
                TEMPLATE_INPUT_METADATA_DISPLAY,
            ].some(f => f === field);

        case 'Dropdown':
            return [
                TEMPLATE_INPUT_LABEL,
                TEMPLATE_INPUT_DESCRIPTION,
                TEMPLATE_INPUT_METADATA_IS_REQUIRED,
                TEMPLATE_INPUT_METADATA_PLACEHOLDER,
                TEMPLATE_INPUT_METADATA_HINT,
                TEMPLATE_INPUT_METADATA_SIZE,
                TEMPLATE_INPUT_METADATA_ALIGN,
                TEMPLATE_INPUT_METADATA_OPTIONS,
                TEMPLATE_INPUT_METADATA_DEFAULT_VALUE,
                TEMPLATE_INPUT_METADATA_DISPLAY,
                TEMPLATE_INPUT_METADATA_MAPPING,
            ].some(f => f === field);

        case 'Radio':
        case 'Checkbox':
        case 'Check':
        case 'Circle':
            return [
                TEMPLATE_INPUT_LABEL,
                TEMPLATE_INPUT_DESCRIPTION,
                TEMPLATE_INPUT_METADATA_IS_REQUIRED,
                TEMPLATE_INPUT_METADATA_PLACEHOLDER,
                TEMPLATE_INPUT_METADATA_HINT,
                TEMPLATE_INPUT_METADATA_SIZE,
                TEMPLATE_INPUT_METADATA_OPTIONS,
                TEMPLATE_INPUT_METADATA_INLINE,
                TEMPLATE_INPUT_METADATA_DEFAULT_VALUE,
                TEMPLATE_INPUT_METADATA_VALIGN,
                TEMPLATE_INPUT_METADATA_DISPLAY,
            ].some(f => f === field);

        case 'ImageAnnotation':
            return [
                TEMPLATE_INPUT_LABEL,
                TEMPLATE_INPUT_DESCRIPTION,
                TEMPLATE_INPUT_METADATA_IMAGE_URL,
                TEMPLATE_INPUT_METADATA_DISPLAY,
            ].some(f => f === field);

        case 'FileViewer':
            return [
                TEMPLATE_INPUT_LABEL,
                TEMPLATE_INPUT_DESCRIPTION,
                TEMPLATE_INPUT_METADATA_FILE_TEXT,
                TEMPLATE_INPUT_METADATA_FILE_MULTIPLE,
                TEMPLATE_INPUT_METADATA_FILE_PREVIEW,
            ].some(f => f === field);

        default:
            return false
    }
}

export const renderInput = (input, options) => {
    const defaults = {
        inputKey: `_${uuid()}`,
        readOnly: null,
        isDesignMode: false,
        referenceObject: null,
        value: null,
        files: [],
        onChange: null,
        onBlur: null,
        onClick: null,
        onDoubleClick: null,
        onContextMenu: null,
    };
    options = { ...defaults, ...options }
    input = toJS(input);
    const errors = getValidationErrors(input, options);

    switch (input.type) {
        case 'SingleLineText':
            return <div
                className={'element-wrapper element-input h-100' + (!input.label ? ' no-label' : '') + (input.metadata && input.metadata.size ? ` element-input-${input.metadata.size}` : '')}
                data-id={input.id}
            >
                <div
                    className={'form-group' + (options.value ? ' has-value' : '') + (!input.label ? ' no-label' : '') + (input.metadata && input.metadata.validation ? ' validate validate-regex' : '')}
                    onClick={options.onClick}
                    onDoubleClick={options.onDoubleClick}
                    onContextMenu={options.onContextMenu}
                >
                    {
                        input.label ?
                            <label
                                className={
                                    'label-input' +
                                    (input.metadata && input.metadata.isRequired ? ' required' : '') +
                                    (errors && errors.length > 0 ? ' invalid' : '')
                                }
                                htmlFor={options.inputKey}
                            >
                                <small>{input.label}{input.label && input.metadata && input.metadata.hint ? <span className='text-normal fs-80 text-gray-500 ml-1'>{input.metadata.hint}</span> : null}</small>
                            </label> : null
                    }
                    <div className='control-wrapper'>
                        <input
                            id={options.inputKey}
                            type='text'
                            className={
                                'form-control' +
                                (` text-${(input.metadata && input.metadata.align ? input.metadata.align : 'left')}`) +
                                (input.metadata && input.metadata.size ? ` form-control-${input.metadata.size} px-2` : '') +
                                (errors && errors.length > 0 ? ' invalid' : '')
                            }
                            data-key={input.key}
                            data-bind={input.key}
                            data-validate-regex={input.metadata ? input.metadata.validation : null}
                            data-virtual-tabindex={input.metadata && !!input.metadata.virtualTabIndex ? input.metadata.virtualTabIndex : null}
                            autoComplete='off'
                            disabled={options.readOnly}
                            title={options.value ? options.value : null}
                            placeholder={input.metadata && input.metadata.placeholder ? input.metadata.placeholder : null}
                            value={options.value ? options.value : ''}
                            onChange={fn.isFunction(options.onChange) && !options.readOnly ? (e) => { options.onChange(e, input.key, options.referenceObject) } : () => { }}
                            onBlur={fn.isFunction(options.onBlur) && !options.readOnly ? (e) => { options.onBlur(e, input.key, input, options.referenceObject) } : () => { }}
                        />
                    </div>
                </div>
            </div>

        case 'MultiLineText':
            return <div
                className={'element-wrapper element-input h-100' + (!input.label ? ' no-label' : '') + (input.metadata && input.metadata.size ? ` element-input-${input.metadata.size}` : '')}
                data-id={input.id}
            >
                <div
                    className={'form-group' + (options.value ? ' has-value' : '') + (!input.label ? ' no-label' : '')}
                    onDoubleClick={options.onDoubleClick}
                    onContextMenu={options.onContextMenu}
                >
                    {
                        input.label ?
                            <label
                                className={
                                    (input.metadata && input.metadata.isRequired ? 'required' : '') +
                                    (errors && errors.length > 0 ? ' invalid' : '')
                                }
                                htmlFor={options.inputKey}
                            >
                                <small>{input.label}{input.label && input.metadata && input.metadata.hint ? <span className='text-normal fs-80 text-gray-500 ml-1'>{input.metadata.hint}</span> : null}</small>
                            </label> : null
                    }
                    <div className='control-wrapper'>
                        <TabbedTextArea
                            id={options.inputKey}
                            type='text'
                            className={
                                'form-control h-100' +
                                (` text-${(input.metadata && input.metadata.align ? input.metadata.align : 'left')}`) +
                                (input.metadata && input.metadata.size ? ` form-control-${input.metadata.size} px-2` : '') +
                                (errors && errors.length > 0 ? ' invalid' : '')
                            }
                            data-key={input.key}
                            data-bind={input.key}
                            data-validate-regex={input.metadata ? input.metadata.validation : null}
                            data-virtual-tabindex={input.metadata && !!input.metadata.virtualTabIndex ? input.metadata.virtualTabIndex : null}
                            autoComplete='off'
                            disabled={options.readOnly}
                            title={options.value ? options.value : null}
                            placeholder={input.metadata && input.metadata.placeholder ? input.metadata.placeholder : null}
                            value={options.value ? options.value : ''}
                            onChange={fn.isFunction(options.onChange) && !options.readOnly ? (e) => { options.onChange(e, input.key, options.referenceObject) } : () => { }}
                            onBlur={fn.isFunction(options.onBlur) && !options.readOnly ? (e) => { options.onBlur(e, input.key, input, options.referenceObject) } : () => { }}
                        />
                    </div>
                </div>
            </div>

        case 'RichText':
            return <div
                className={'element-wrapper element-input h-100' + (!input.label ? ' no-label' : '') + (input.metadata && input.metadata.size ? ` element-input-${input.metadata.size}` : '')}
                data-id={input.id}
            ><div
                className={'form-group' + (options.value ? ' has-value' : '') + (!input.label ? ' no-label' : '')}
                onDoubleClick={options.onDoubleClick}
                onContextMenu={options.onContextMenu}
            >
                    {
                        input.label ?
                            <label
                                className={
                                    (input.metadata && input.metadata.isRequired ? 'required' : '') +
                                    (errors && errors.length > 0 ? ' invalid' : '')
                                }
                                htmlFor={options.inputKey}
                            >
                                <small>{input.label}{input.label && input.metadata && input.metadata.hint ? <span className='text-normal fs-80 text-gray-500 ml-1'>{input.metadata.hint}</span> : null}</small>
                            </label> : null
                    }
                    <div className='control-wrapper'>
                        <RichTextEditor
                            id={options.inputKey}
                            mode='full'
                            className={
                                'quill' + (input.metadata && input.metadata.size ? ` quill-${input.metadata.size}` : '')
                            }
                            dataAttributes={[
                                { name: 'data-key', value: input.key },
                                { name: 'data-bind', value: input.key },
                                { name: 'data-virtual-tabindex', value: input.metadata && !!input.metadata.virtualTabIndex ? input.metadata.virtualTabIndex : null }
                            ]}
                            disableTab={true}
                            readOnly={options.readOnly}
                            placeholder={input.metadata && input.metadata.placeholder ? input.metadata.placeholder : null}
                            value={options.value ? options.value : ''}
                            theme={'bubble'}
                            onChange={fn.isFunction(options.onChange) ? (content) => { options.onChange(content, input.key, options.referenceObject) } : () => { }}
                        />
                    </div>
                </div>
            </div>

        case 'Dropdown':
            return <div
                className={'element-wrapper element-input h-100' + (!input.label ? ' no-label' : '') + (input.metadata && input.metadata.size ? ` element-input-${input.metadata.size}` : '')}
                data-id={input.id}
            >
                <div
                    className={'form-group' + (options.value ? ' has-value' : '') + (!input.label ? ' no-label' : '')}
                    onDoubleClick={options.onDoubleClick}
                    onContextMenu={options.onContextMenu}
                >
                    {
                        input.label ?
                            <label
                                className={
                                    (input.metadata && input.metadata.isRequired ? 'required' : '') +
                                    (errors && errors.length > 0 ? ' invalid' : '')
                                }
                                htmlFor={options.inputKey}
                            >
                                <small>{input.label}{input.label && input.metadata && input.metadata.hint ? <span className='text-normal fs-80 text-gray-500 ml-1'>{input.metadata.hint}</span> : null}</small>
                            </label> : null
                    }
                    <div className='control-wrapper'>
                        {
                            options.readOnly ?
                                <input
                                    id={options.inputKey}
                                    type='text'
                                    className={
                                        'form-control' +
                                        (` text-${(input.metadata && input.metadata.align ? input.metadata.align : 'left')}`) +
                                        (input.metadata && input.metadata.size ? ` form-control-${input.metadata.size} px-2` : '') +
                                        (errors && errors.length > 0 ? ' invalid' : '')
                                    }
                                    data-key={input.key}
                                    data-bind={input.key}
                                    data-virtual-tabindex={input.metadata && !!input.metadata.virtualTabIndex ? input.metadata.virtualTabIndex : null}
                                    autoComplete='off'
                                    disabled={true}
                                    title={options.value ? options.value : null}
                                    value={options.value ? options.value : ''}
                                /> :
                                <select
                                    id={options.inputKey}
                                    className={
                                        'custom-select form-control' +
                                        (input.metadata && input.metadata.size ? ` form-control-${input.metadata.size} custom-select-${input.metadata.size}` : '') +
                                        (errors && errors.length > 0 ? ' invalid' : '')
                                    }
                                    data-key={input.key}
                                    data-bind={input.key}
                                    data-virtual-tabindex={input.metadata && !!input.metadata.virtualTabIndex ? input.metadata.virtualTabIndex : null}
                                    title={options.value ? options.value : null}
                                    placeholder={input.metadata && input.metadata.placeholder ? input.metadata.placeholder : null}
                                    value={options.value ? options.value : ''}
                                    disabled={options.readOnly}
                                    onChange={fn.isFunction(options.onChange) && !options.readOnly ? (e) => { options.onChange(e, input.key, options.referenceObject) } : () => { }}
                                >
                                    <option value=''></option>
                                    {
                                        input.metadata && input.metadata.options && input.metadata.options.length > 0 ?
                                            input.metadata.options.map((o, oi) => {
                                                return <option
                                                    key={`${input.key}_metadata_${oi}`}
                                                    value={o}
                                                >
                                                    {o}
                                                </option>
                                            }) : null
                                    }
                                </select>
                        }
                    </div>
                </div>
            </div>

        case 'Radio':
            return <div
                className={'element-wrapper element-input h-100' + (!input.label ? ' no-label' : '') + (input.metadata && input.metadata.size ? ` element-input-${input.metadata.size}` : '')}
                data-id={input.id}
            >
                <div
                    className={'form-group' + (options.value ? ' has-value' : '') + (!input.label ? ' no-label' : '') + (input.metadata && input.metadata.valign && input.metadata.valign !== 'top' ? ` text-${input.metadata.valign}` : '')}
                    onDoubleClick={options.onDoubleClick}
                    onContextMenu={options.onContextMenu}
                >
                    {
                        input.label ?
                            <label
                                className={
                                    (input.metadata && input.metadata.isRequired ? ' required' : '') +
                                    (errors && errors.length > 0 ? ' invalid' : '')
                                }
                            >
                                <small>{input.label}{input.label && input.metadata && input.metadata.hint ? <span className='text-normal fs-80 text-gray-500 ml-1'>{input.metadata.hint}</span> : null}</small>
                            </label> : null
                    }
                    <div className={(input.label && input.label.length > 0 ? 'radio-wrapper' : '')}>
                        {
                            input.metadata && input.metadata.options && input.metadata.options.length > 0 ?
                                input.metadata.options.map((o, oi) => {
                                    return <div
                                        key={`${input.key}_metadata_${oi}`}
                                        className={'custom-control custom-radio' + (input.metadata && input.metadata.inline ? ' custom-control-inline' : '')}
                                    >
                                        <input
                                            id={`${input.key}-${oi}`}
                                            type='checkbox'
                                            name={`new-template-input-inline-${input.key}`}
                                            disabled={options.readOnly}
                                            className={
                                                'custom-control-input' +
                                                (errors && errors.length > 0 ? ' invalid' : '')
                                            }
                                            value={o ? o : ''}
                                            data-key={input.key}
                                            data-bind={input.key}
                                            data-virtual-tabindex={input.metadata && !!input.metadata.virtualTabIndex ? input.metadata.virtualTabIndex : null}
                                            checked={(options.value && options.value.split('|').some(v => v === o))}
                                            onChange={fn.isFunction(options.onChange) && !options.readOnly ? (e) => { options.onChange(e, input.key, options.referenceObject) } : (e) => { e.preventDefault(); e.stopPropagation(); }}
                                            onClick={!fn.isFunction(options.onChange) || options.readOnly ? (e) => { e.preventDefault(); e.stopPropagation(); } : null}
                                        />
                                        <label
                                            htmlFor={options.readOnly ? '' : `${input.key}-${oi}`}
                                            className={
                                                'custom-control-label' +
                                                (errors && errors.length > 0 ? ' invalid' : '')
                                            }
                                        >
                                            {o}{input.metadata && input.metadata.placeholder ? <span className='text-normal text-gray-600 ml-1'>{input.metadata.placeholder}</span> : null}
                                        </label>
                                    </div>
                                }) : null
                        }
                    </div>
                </div>
            </div>

        case 'Checkbox':
            return <div
                className={'element-wrapper element-input h-100' + (!input.label ? ' no-label' : '') + (input.metadata && input.metadata.size ? ` element-input-${input.metadata.size}` : '')}
                data-id={input.id}
            >
                <div
                    className={'form-group' + (options.value ? ' has-value' : '') + (!input.label ? ' no-label' : '') + (input.metadata && input.metadata.valign && input.metadata.valign !== 'top' ? ` text-${input.metadata.valign}` : '')}
                    onDoubleClick={options.onDoubleClick}
                    onContextMenu={options.onContextMenu}
                >
                    {
                        input.label ?
                            <label
                                className={
                                    (input.metadata && input.metadata.isRequired ? 'required' : '') +
                                    (errors && errors.length > 0 ? ' invalid' : '')
                                }
                            >
                                <small>{input.label}{input.label && input.metadata && input.metadata.hint ? <span className='text-normal fs-80 text-gray-500 ml-1'>{input.metadata.hint}</span> : null}</small>
                            </label> : null
                    }
                    <div className={(input.label && input.label.length > 0 ? 'checkbox-wrapper' : '')}>
                        {
                            input.metadata && input.metadata.options && input.metadata.options.length > 0 ?
                                input.metadata.options.map((o, oi) => {
                                    return <div
                                        key={`${input.key}_metadata_${oi}`}
                                        className={'custom-control custom-checkbox' + (input.metadata && input.metadata.inline ? ' custom-control-inline' : '')}
                                    >
                                        <input
                                            id={`${input.key}-${oi}`}
                                            type='checkbox'
                                            name={`new-template-input-inline-${input.key}`}
                                            className={
                                                'custom-control-input' +
                                                (errors && errors.length > 0 ? ' invalid' : '')
                                            }
                                            disabled={options.readOnly}
                                            value={o ? o : ''}
                                            data-key={input.key}
                                            data-bind={input.key}
                                            data-virtual-tabindex={input.metadata && !!input.metadata.virtualTabIndex ? input.metadata.virtualTabIndex : null}
                                            checked={(options.value && options.value.split('|').some(v => v === o))}
                                            onChange={fn.isFunction(options.onChange) && !options.readOnly ? (e) => { options.onChange(e, input.key, options.referenceObject) } : (e) => { e.preventDefault(); e.stopPropagation(); }}
                                            onClick={!fn.isFunction(options.onChange) || options.readOnly ? (e) => { e.preventDefault(); e.stopPropagation(); } : null}
                                        />
                                        <label
                                            htmlFor={options.readOnly ? '' : `${input.key}-${oi}`}
                                            className={
                                                'custom-control-label' +
                                                (errors && errors.length > 0 ? ' invalid' : '')
                                            }
                                        >
                                            {o}{input.metadata && input.metadata.placeholder ? <span className='text-normal text-gray-600 ml-1'>{input.metadata.placeholder}</span> : null}
                                        </label>
                                    </div>
                                }) : null
                        }
                    </div>
                </div>
            </div>

        case 'Check':
            return <div
                className={'element-wrapper element-input h-100' + (!input.label ? ' no-label' : '') + (input.metadata && input.metadata.size ? ` element-input-${input.metadata.size}` : '')}
                data-id={input.id}
            >
                <div
                    className={'form-group' + (options.value ? ' has-value' : '') + (!input.label ? ' no-label' : '') + (input.metadata && input.metadata.valign && input.metadata.valign !== 'top' ? ` text-${input.metadata.valign}` : '')}
                    onDoubleClick={options.onDoubleClick}
                    onContextMenu={options.onContextMenu}
                >
                    {
                        input.label ?
                            <label
                                className={
                                    (input.metadata && input.metadata.isRequired ? 'required' : '') +
                                    (errors && errors.length > 0 ? ' invalid' : '')
                                }
                            >
                                <small>{input.label}{input.label && input.metadata && input.metadata.hint ? <span className='text-normal fs-80 text-gray-500 ml-1'>{input.metadata.hint}</span> : null}</small>
                            </label> : null
                    }
                    <div className={(input.label && input.label.length > 0 ? 'checkbox-wrapper' : '')}>
                        {
                            input.metadata && input.metadata.options && input.metadata.options.length > 0 ?
                                input.metadata.options.map((o, oi) => {
                                    return <div
                                        key={`${input.key}_metadata_${oi}`}
                                        className={'custom-control custom-checkbox' + (input.metadata && input.metadata.inline ? ' custom-control-inline' : '')}
                                    >
                                        <input
                                            id={`${input.key}-${oi}`}
                                            type='checkbox'
                                            name={`new-template-input-inline-${input.key}`}
                                            className={
                                                'custom-control-input' +
                                                (errors && errors.length > 0 ? ' invalid' : '')
                                            }
                                            disabled={options.readOnly}
                                            value={o ? o : ''}
                                            data-key={input.key}
                                            data-bind={input.key}
                                            data-virtual-tabindex={input.metadata && !!input.metadata.virtualTabIndex ? input.metadata.virtualTabIndex : null}
                                            checked={(options.value && options.value.split('|').some(v => v === o))}
                                            onChange={fn.isFunction(options.onChange) && !options.readOnly ? (e) => { options.onChange(e, input.key, options.referenceObject) } : (e) => { e.preventDefault(); e.stopPropagation(); }}
                                            onClick={!fn.isFunction(options.onChange) || options.readOnly ? (e) => { e.preventDefault(); e.stopPropagation(); } : null}
                                        />
                                        <label
                                            htmlFor={options.readOnly ? '' : `${input.key}-${oi}`}
                                            className={
                                                'custom-control-label' +
                                                (errors && errors.length > 0 ? ' invalid' : '')
                                            }
                                        >
                                            {o}{input.metadata && input.metadata.placeholder ? <span className='text-normal text-gray-600 ml-1'>{input.metadata.placeholder}</span> : null}
                                        </label>
                                    </div>
                                }) : null
                        }
                    </div>
                </div>
            </div>

        case 'Circle':
            return <div
                className={'element-wrapper element-input h-100' + (!input.label ? ' no-label' : '') + (input.metadata && input.metadata.size ? ` element-input-${input.metadata.size}` : '')}
                data-id={input.id}
            >
                <div
                    className={'form-group' + (options.value ? ' has-value' : '') + (!input.label ? ' no-label' : '') + (input.metadata && input.metadata.valign && input.metadata.valign !== 'top' ? ` text-${input.metadata.valign}` : '')}
                    onDoubleClick={options.onDoubleClick}
                    onContextMenu={options.onContextMenu}
                >
                    {
                        input.label ?
                            <label
                                className={
                                    (input.metadata && input.metadata.isRequired ? 'required' : '') +
                                    (errors && errors.length > 0 ? ' invalid' : '')
                                }
                            >
                                <small>{input.label}{input.label && input.metadata && input.metadata.hint ? <span className='text-normal fs-80 text-gray-500 ml-1'>{input.metadata.hint}</span> : null}</small>
                            </label> : null
                    }
                    <div className={(input.label && input.label.length > 0 ? 'checkbox-wrapper' : '')}>
                        {
                            input.metadata && input.metadata.options && input.metadata.options.length > 0 ?
                                input.metadata.options.map((o, oi) => {
                                    return <div
                                        key={`${input.key}_metadata_${oi}`}
                                        className={'custom-control custom-checkbox' + (input.metadata && input.metadata.inline ? ' custom-control-inline' : '')}
                                    >
                                        <input
                                            id={`${input.key}-${oi}`}
                                            type='checkbox'
                                            name={`new-template-input-inline-${input.key}`}
                                            className={
                                                'custom-control-input' +
                                                (errors && errors.length > 0 ? ' invalid' : '')
                                            }
                                            disabled={options.readOnly}
                                            value={o ? o : ''}
                                            data-key={input.key}
                                            data-bind={input.key}
                                            data-virtual-tabindex={input.metadata && !!input.metadata.virtualTabIndex ? input.metadata.virtualTabIndex : null}
                                            checked={(options.value && options.value.split('|').some(v => v === o))}
                                            onChange={fn.isFunction(options.onChange) && !options.readOnly ? (e) => { options.onChange(e, input.key, options.referenceObject) } : (e) => { e.preventDefault(); e.stopPropagation(); }}
                                            onClick={!fn.isFunction(options.onChange) || options.readOnly ? (e) => { e.preventDefault(); e.stopPropagation(); } : null}
                                        />
                                        <label
                                            htmlFor={options.readOnly ? '' : `${input.key}-${oi}`}
                                            className={
                                                'custom-control-label' +
                                                (errors && errors.length > 0 ? ' text-danger' : '')
                                            }
                                        >
                                            {o}{input.metadata && input.metadata.placeholder ? <span className='text-normal text-gray-600 ml-1'>{input.metadata.placeholder}</span> : null}
                                        </label>
                                    </div>
                                }) : null
                        }
                    </div>
                </div>
            </div>

        case 'Date':
            return <div
                className={'element-wrapper element-input h-100' + (!input.label ? ' no-label' : '') + (input.metadata && input.metadata.size ? ` element-input-${input.metadata.size}` : '')}
                data-id={input.id}
            >
                <div
                    className={'form-group' + (options.value ? ' has-value' : '') + (!input.label ? ' no-label' : '')}
                    onDoubleClick={options.onDoubleClick}
                    onContextMenu={options.onContextMenu}
                >
                    {
                        input.label ?
                            <label className={
                                'label-input' +
                                (input.metadata && input.metadata.isRequired ? ' required' : '') +
                                (errors && errors.length > 0 ? ' invalid' : '')
                            }
                                htmlFor={options.inputKey}
                            >
                                <small>{input.label}<span className='text-normal fs-80 text-gray-500 ml-1'>(YYYY-MM-DD)</span></small>
                            </label> : null
                    }
                    <div className='control-wrapper'>
                        {
                            isMobile ?
                                <input
                                    id={options.inputKey}
                                    type='text'
                                    className={
                                        'form-control' +
                                        (` text-${(input.metadata && input.metadata.align ? input.metadata.align : 'left')}`) +
                                        (input.metadata && input.metadata.size ? ` form-control-${input.metadata.size} px-2` : '') +
                                        (errors && errors.length > 0 ? ' invalid' : '')
                                    }
                                    autoComplete='off'
                                    data-key={input.key}
                                    data-bind={input.key}
                                    data-virtual-tabindex={input.metadata && !!input.metadata.virtualTabIndex ? input.metadata.virtualTabIndex : null}
                                    disabled={options.readOnly}
                                    title={options.value ? options.value : null}
                                    value={options.value ? options.value : ''}
                                    onChange={fn.isFunction(options.onChange) && !options.readOnly ? (e) => { options.onChange(e, input.key, options.referenceObject) } : () => { }}
                                /> :
                                <MaskedInput
                                    id={options.inputKey}
                                    type='text'
                                    mask={MaskKeys.DATE_MASK}
                                    pipe={MaskKeys.DATE_PIPE}
                                    className={
                                        'form-control' +
                                        (` text-${(input.metadata && input.metadata.align ? input.metadata.align : 'left')}`) +
                                        (input.metadata && input.metadata.size ? ` form-control-${input.metadata.size} px-2` : '') +
                                        (errors && errors.length > 0 ? ' invalid' : '')
                                    }
                                    autoComplete='off'
                                    data-key={input.key}
                                    data-bind={input.key}
                                    data-virtual-tabindex={input.metadata && !!input.metadata.virtualTabIndex ? input.metadata.virtualTabIndex : null}
                                    disabled={options.readOnly}
                                    title={options.value ? options.value : null}
                                    value={options.value ? options.value : ''}
                                    onChange={fn.isFunction(options.onChange) && !options.readOnly ? (e) => { options.onChange(e, input.key, options.referenceObject) } : () => { }}
                                />
                        }
                    </div>
                </div>
            </div>

        case 'Time':
            return <div
                className={'element-wrapper element-input h-100' + (!input.label ? ' no-label' : '') + (input.metadata && input.metadata.size ? ` element-input-${input.metadata.size}` : '')}
                data-id={input.id}
            >
                <div
                    className={'form-group' + (options.value ? ' has-value' : '') + (!input.label ? ' no-label' : '')}
                    onDoubleClick={options.onDoubleClick}
                    onContextMenu={options.onContextMenu}
                >
                    {
                        input.label ?
                            <label
                                className={
                                    'label-input' +
                                    (input.metadata && input.metadata.isRequired ? ' required' : '') +
                                    (errors && errors.length > 0 ? ' invalid' : '')
                                }
                                htmlFor={options.inputKey}
                            >
                                <small>{input.label}</small>
                            </label> : null
                    }
                    <div className='control-wrapper'>
                        <input
                            id={options.inputKey}
                            type='text'
                            className={
                                'form-control' +
                                (` text-${(input.metadata && input.metadata.align ? input.metadata.align : 'left')}`) +
                                (input.metadata && input.metadata.size ? ` form-control-${input.metadata.size} px-2` : '') +
                                (errors && errors.length > 0 ? ' invalid' : '')
                            }
                            spellCheck={false}
                            autoComplete='off'
                            data-key={input.key}
                            data-bind={input.key}
                            data-virtual-tabindex={input.metadata && !!input.metadata.virtualTabIndex ? input.metadata.virtualTabIndex : null}
                            disabled={options.readOnly}
                            title={options.value ? options.value : null}
                            value={options.value ? options.value : ''}
                            onChange={fn.isFunction(options.onChange) && !options.readOnly ? (e) => { options.onChange(e, input.key, options.referenceObject) } : () => { }}
                        />
                    </div>
                </div>
            </div>

        case 'ImageAnnotation':
            return <div
                className='element-wrapper h-100'
                data-id={input.id}
            >
                <div
                    className={'form-group' + (options.value ? ' has-value' : '') + (!input.label ? ' no-label' : '')}
                    onDoubleClick={options.onDoubleClick}
                    onContextMenu={options.onContextMenu}
                >
                    {
                        input.label ?
                            <label className='label-input'><small>{input.label}</small></label> : null
                    }
                    <div className='control-wrapper'>
                        <ImageAnnotation
                            className='image-background'
                            src={(input.metadata && input.metadata.imageUrl ? input.metadata.imageUrl : '')}
                            width={(input.metadata && input.metadata.imageWidth ? input.metadata.imageWidth : '')}
                            height={(input.metadata && input.metadata.imageHeight ? input.metadata.imageHeight : '')}
                            alt='Background'
                            disabled={options.readOnly}
                            value={options.value}
                            onChange={fn.isFunction(options.onChange) && !options.readOnly ? (e, content) => { options.onChange(e, input.key, options.referenceObject, content) } : () => { }}
                        />
                    </div>
                </div>
            </div>

        case 'MaskedInput':
            const maskKey = getMaskKey(input.metadata);
            const css = getMaskCss(input.metadata);

            return <div
                className={'element-wrapper element-input h-100' + (!input.label ? ' no-label' : '') + (input.metadata && input.metadata.size ? ` element-input-${input.metadata.size}` : '')}
                data-id={input.id}
            >
                <div
                    className={'form-group' + (options.value ? ' has-value' : '') + (!input.label ? ' no-label' : '')}
                    onDoubleClick={options.onDoubleClick}
                    onContextMenu={options.onContextMenu}
                >
                    {
                        input.label ?
                            <label
                                className={
                                    'label-input' +
                                    (input.metadata && input.metadata.isRequired ? ' required' : '') +
                                    (errors && errors.length > 0 ? ' invalid' : '')
                                }
                                htmlFor={options.inputKey}
                            >
                                <small>{input.label}{input.label && input.metadata && input.metadata.hint ? <span className='text-normal fs-80 text-gray-500 ml-1'>{input.metadata.hint}</span> : null}</small>
                            </label> : null
                    }
                    <div className='control-wrapper'>
                        {
                            maskKey && !isMobile ?
                                <MaskedInput
                                    id={options.inputKey}
                                    type='text'
                                    mask={maskKey}
                                    className={
                                        'form-control' +
                                        (` text-${(input.metadata && input.metadata.align ? input.metadata.align : 'left')}`) +
                                        (input.metadata && input.metadata.size ? ` form-control-${input.metadata.size} px-2` : '') +
                                        (errors && errors.length > 0 ? ' invalid' : '') +
                                        (css ? ` ${css}` : '')
                                    }
                                    autoComplete='off'
                                    data-key={input.key}
                                    data-bind={input.key}
                                    data-virtual-tabindex={input.metadata && !!input.metadata.virtualTabIndex ? input.metadata.virtualTabIndex : null}
                                    disabled={options.readOnly}
                                    title={options.value ? options.value : null}
                                    value={options.value ? options.value : ''}
                                    onChange={fn.isFunction(options.onChange) && !options.readOnly ? (e) => { options.onChange(e, input.key, options.referenceObject) } : () => { }}
                                /> :
                                <input
                                    id={options.inputKey}
                                    type='text'
                                    className={
                                        'form-control' +
                                        (` text-${(input.metadata && input.metadata.align ? input.metadata.align : 'left')}`) +
                                        (input.metadata && input.metadata.size ? ` form-control-${input.metadata.size} px-2` : '') +
                                        (errors && errors.length > 0 ? ' invalid' : '') +
                                        (css ? ` ${css}` : '')
                                    }
                                    data-key={input.key}
                                    data-bind={input.key}
                                    data-virtual-tabindex={input.metadata && !!input.metadata.virtualTabIndex ? input.metadata.virtualTabIndex : null}
                                    autoComplete='off'
                                    disabled={options.readOnly}
                                    title={options.value ? options.value : null}
                                    value={options.value ? options.value : ''}
                                    onChange={fn.isFunction(options.onChange) && !options.readOnly ? (e) => { options.onChange(e, input.key, options.referenceObject) } : () => { }}
                                />
                        }
                    </div>
                </div>
            </div>

        case 'FileViewer':
            const fileData = options.files.filter(f => f.key === input.key);

            return <div
                className={'element-wrapper element-input h-100' + (!input.label ? ' no-label' : '') + (input.metadata && input.metadata.size ? ` element-input-${input.metadata.size}` : '')}
                data-id={input.id}
            >
                <div
                    className={'form-group justify-content-start' + (options.value ? ' has-value' : '') + (!input.label ? ' no-label' : '')}
                    onDoubleClick={options.onDoubleClick}
                    onContextMenu={options.onContextMenu}
                >
                    {
                        input.label ?
                            <label
                                className={
                                    'label-input' +
                                    (input.metadata && input.metadata.isRequired ? ' required' : '') +
                                    (errors && errors.length > 0 ? ' invalid' : '')
                                }
                                htmlFor={options.inputKey}
                            >
                                <small>{input.label}{input.label && input.metadata && input.metadata.hint ? <span className='text-normal fs-80 text-gray-500 ml-1'>{input.metadata.hint}</span> : null}</small>
                            </label> : null
                    }
                    <div className='control-wrapper d-inline-block w-100'>
                        {
                            !options.readOnly ?
                                <div className='custom-file'>
                                    <input
                                        id={options.inputKey}
                                        type='file'
                                        className='custom-file-input custom-file-input-link'
                                        accept='.jpg, .jpeg, .png, .gif, .bmp, .pdf'
                                        data-key={input.key}
                                        data-bind={input.key}
                                        data-virtual-tabindex={input.metadata && !!input.metadata.virtualTabIndex ? input.metadata.virtualTabIndex : null}
                                        autoComplete='off'
                                        disabled={options.readOnly}
                                        multiple={input.metadata && input.metadata.fileMultiple === true}
                                        onChange={fn.isFunction(options.onChange) && !options.readOnly ? (e) => { options.onChange(e, input.key, options.referenceObject) } : () => { }}
                                    />
                                    <label
                                        htmlFor={options.inputKey}
                                        className='custom-file-link'
                                    >
                                        {input.metadata && input.metadata.fileText ? input.metadata.fileText : `Upload file${input.metadata && input.metadata.fileMultiple === true ? '(s)' : ''}`}
                                    </label>
                                </div> : null
                        }
                        {
                            input.metadata && input.metadata.filePreview === true && fileData && fileData.length === 1 && fileData[0].files && fileData[0].files.filter(f => !!f.uri).length === 1 ?
                                renderFile(fileData[0].files[0]) : null
                        }
                    </div>
                </div>
            </div>

        default:
            break;
    }
}

export const getDefaultPosition = templateInput => {
    switch (templateInput.type) {
        case 'SingleLineText':
        case 'Dropdown':
        case 'Date':
        case 'Time':
        case 'MaskedInput':
            return templateInput.label ? { w: 12, h: 4 } : { w: 12, h: 4 };

        case 'MultiLineText':
        case 'FileViewer':
            return templateInput.label ? { w: 12, h: 8 } : { w: 12, h: 6 };

        case 'RichText':
            return templateInput.label ? { w: 12, h: 8 } : { w: 12, h: 6 };

        case 'Radio':
        case 'Checkbox':
        case 'ImageAnnotation':
            if (templateInput.metadata && templateInput.metadata.inline && !!templateInput.metadata.inline) {
                return templateInput.label ? { w: 12, h: 3 } : { w: 12, h: 2 };
            } else {
                return templateInput.label ? { w: 12, h: 6 } : { w: 12, h: 2 };
            }

        case 'Check':
        case 'Circle':
            return templateInput.label ? { w: 12, h: 6 } : { w: 4, h: 4 };

        default:
            return {};
    }
}

const renderFile = file => {
    if (!file || !file.uri) return;

    switch (file.mimeType) {
        case 'application/pdf':
            return <embed src={`${file.uri}`} type='application/pdf' width='100%' height='100%' />;

        case 'image/jpeg':
        case 'image/jpg':
        case 'image/gif':
        case 'image/png':
        case 'image/bmp':
            return <div className='file-image-wrapper'>
                <img src={file.uri} alt={file.fileName} style={{ width: '100%' }} />
            </div>

        default:
            return <div className='d-flex w-100 h-100 align-items-center justify-content-center'>
                <div className='text-center'>
                    <div className='d-block fs-xl text-white'><strong>{file.fileName}</strong> cannot be previewed.</div>
                    <div className='d-block text-gray-700'>Size: {fn.toFileSize(file.size, true)}</div>
                </div>
            </div>
    }
}

const getMaskKey = (metadata) => {
    if (metadata) {
        switch (metadata.inputmask) {
            case 'email':
                return MaskKeys.EMAIL_MASK;

            case 'phone':
                return MaskKeys.PHONE_MASK;

            case 'postal':
                return MaskKeys.POSTAL_MASK;

            case 'ohip':
                return MaskKeys.OHIP_MASK;

            case 'ohip-no-code':
                return MaskKeys.OHIP_NO_CODE_MASK;

            case 'ohip-code':
                return MaskKeys.OHIP_CODE_MASK;

            default:
                return null;
        }
    }

    return null;
}

const getMaskCss = (metadata) => {
    if (metadata) {
        switch (metadata.inputmask) {
            case 'email':
                return 'text-lowercase';

            case 'phone':
                return null;

            case 'postal':
                return 'text-uppercase';

            case 'ohip':
                return 'text-uppercase';

            case 'ohip-no-code':
                return null;

            case 'ohip-code':
                return 'text-uppercase';

            default:
                return null;
        }
    }

    return null;
}

const getValidationErrors = ({ type, metadata }, options) => {
    const errors = [];

    if (metadata && options) {
        if (metadata.isRequired && !options.value) {
            errors.push(ErrorMessages.GENERIC_ERROR_REQUIRED);
        }

        if (type && type === 'Date' && options.value) {
            if (!fn.regexTester.date.test(options.value)) {
                errors.push(ErrorMessages.INVALID_DATE_FORMAT);
            }
        }

        if (metadata.inputmask && options.value) {
            switch (metadata.inputmask) {
                case 'date':
                    if (!fn.regexTester.date.test(options.value)) {
                        errors.push(ErrorMessages.INVALID_DATE_FORMAT);
                    }
                    break;

                case 'email':
                    if (!fn.regexTester.email.test(options.value.toLowerCase())) {
                        errors.push(ErrorMessages.INVALID_EMAIL_FORMAT);
                    }
                    break;

                case 'phone':
                    if (!fn.regexTester.phone.test(options.value)) {
                        errors.push(ErrorMessages.INVALID_PHONE_FORMAT);
                    }
                    break;

                case 'postal':
                    if (!fn.regexTester.postal.test(options.value.toUpperCase())) {
                        errors.push(ErrorMessages.INVALID_POSTAL_FORMAT);
                    }
                    break;

                case 'ohip':
                    const ohip = options.value.replace(/\W/g, '');

                    if (!fn.regexTester.ohip.test(ohip)) {
                        errors.push(ErrorMessages.INVALID_OHIP_FORMAT);
                    }
                    break;

                case 'ohip-no-code':
                    const ohipNoCode = options.value.replace(/\W/g, '');

                    if (!fn.regexTester.ohipNoCode.test(ohipNoCode)) {
                        errors.push(ErrorMessages.INVALID_OHIP_FORMAT);
                    }
                    break;

                case 'ohip-code':
                    const ohipCode = options.value.replace(/\W/g, '');

                    if (!fn.regexTester.ohipCode.test(ohipCode)) {
                        errors.push(ErrorMessages.INVALID_OHIP_FORMAT);
                    }
                    break;

                default:
                    break;
            }
        }
    }

    return errors;
}



export const getDataTypes = () => {
    return ['String', 'Integer', 'Decimal'];
}

export const checkDataTypeOption = (dataType, option) => {
    if (!dataType || !option) return false;

    switch (dataType) {
        case 'String':
            return [
                TEMPLATE_INPUT_METADATA_DATA_PADDING_TYPE,
                TEMPLATE_INPUT_METADATA_DATA_PADDING_CHARACTER,
                TEMPLATE_INPUT_METADATA_DATA_PADDING_LENGTH,
                TEMPLATE_INPUT_METADATA_DATA_VALIDATION,
            ].some(t => t === option);

        case 'Integer':
            return [
                TEMPLATE_INPUT_METADATA_DATA_PADDING_TYPE,
                TEMPLATE_INPUT_METADATA_DATA_PADDING_CHARACTER,
                TEMPLATE_INPUT_METADATA_DATA_PADDING_LENGTH,
                TEMPLATE_INPUT_METADATA_DATA_DEFAULT_NEGATIVE,
                TEMPLATE_INPUT_METADATA_DATA_FORMAT,
                TEMPLATE_INPUT_METADATA_DATA_VALIDATION,
            ].some(t => t === option);

        case 'Decimal':
            return [
                TEMPLATE_INPUT_METADATA_DATA_DECIMAL_PLACES,
                TEMPLATE_INPUT_METADATA_DATA_DEFAULT_NEGATIVE,
                TEMPLATE_INPUT_METADATA_DATA_FORMAT,
                TEMPLATE_INPUT_METADATA_DATA_VALIDATION,
            ].some(t => t === option);

        default:
            return false;
    }
}

export const getFormatOptions = (dataType, decimalPlaces = 2) => {
    switch (dataType) {
        case 'String':
            break;

        case 'Integer':
            return [{
                type: 'None',
                example: 'Display as is',
            }, {
                type: 'PlusMinus',
                example: '+1, -1, 0',
            }, {
                type: 'PlusMinusPlusZero',
                example: '+1, -1, +0',
            }]

        case 'Decimal':
            return [{
                type: 'None',
                example: 'Display as is',
            }, {
                type: 'PlusMinus',
                example: `+1.${''.padEnd((decimalPlaces), '0')}, -1.${''.padEnd(decimalPlaces, '0')}, 0.${''.padEnd(decimalPlaces, '0')}`,
            }, {
                type: 'PlusMinusPlusZero',
                example: `+1.${''.padEnd(decimalPlaces, '0')}, -1.${''.padEnd(decimalPlaces, '0')}, +0.${''.padEnd(decimalPlaces, '0')}`,
            }]

        default:
            return [];
    }
}

export const validateDataType = (metadata, value) => {
    if (!metadata || !metadata.dataType || !value) return true;

    let unformattedValue = value;

    unformattedValue = removePadding(metadata, unformattedValue);
    unformattedValue = removeFormat(metadata, unformattedValue);

    switch (metadata.dataType) {
        case 'String':
            return true;

        case 'Integer':
            const integerValue = parseFloat(unformattedValue, 10);
            return Number.isInteger(integerValue);

        case 'Decimal':
            const floatValue = parseFloat(unformattedValue, 10);
            return Number.isInteger(floatValue) || (Number(floatValue) === floatValue && floatValue % 1 !== 0);

        default:
            return true;
    }
}

export const applyFormat = (metadata, value) => {
    if (!metadata || !value || !metadata.format || metadata.paddingType) return value;

    let unformattedValue = value;
    const isDefaultNegative = !fn.isNullOrUndefined(metadata.isDefaultNegative) ? !!metadata.isDefaultNegative : false;

    unformattedValue = removeFormat(metadata, unformattedValue);

    switch (metadata.dataType) {
        case 'String':
            break;

        case 'Integer':
            let integerValue = parseInt(unformattedValue, 10);

            if (integerValue > 0 && isDefaultNegative) {
                integerValue = integerValue * -1;
            }

            switch (metadata.format) {
                case 'PlusMinus':
                    return `${(integerValue > 0 ? '+' : '')}${integerValue}`.replace('++', '+');

                case 'PlusMinusPlusZero':
                    return `${(integerValue >= 0 ? '+' : '')}${integerValue}`.replace('++', '+');

                default:
                    return integerValue;
            }

        case 'Decimal':
            const decimalPlaces = !!metadata.decimalPlaces && Number.isInteger(parseInt(metadata.decimalPlaces, 10)) ? parseInt(metadata.decimalPlaces, 10) : 2;
            let decimalValue = parseFloat(unformattedValue, 10).toFixed(decimalPlaces);

            if (decimalValue > 0 && isDefaultNegative) {
                decimalValue = (decimalValue * -1.0).toFixed(decimalPlaces);
            }

            switch (metadata.format) {
                case 'PlusMinus':
                    return `${(parseFloat(decimalValue, 10) > 0 ? '+' : '')}${decimalValue}`.replace('++', '+');

                case 'PlusMinusPlusZero':
                    return `${(parseFloat(decimalValue) >= 0 ? '+' : '')}${decimalValue}`.replace('++', '+');

                default:
                    return decimalValue;
            }

        default:
            return value;
    }
}

export const applyPadding = (metadata, value) => {
    if (!metadata || !value || !metadata.paddingType || !metadata.paddingCharacter || !metadata.paddingLength) return value;

    let unformattedValue = value;
    const length = parseInt(metadata.paddingLength, 10);

    unformattedValue = removePadding(metadata, unformattedValue);

    if (['String', 'Integer'].some(i => i === metadata.dataType)) {
        switch (metadata.paddingType) {
            case 'left':
                return unformattedValue.padStart(length, metadata.paddingCharacter);

            case 'right':
                return unformattedValue.padEnd(length, metadata.paddingCharacter);

            default:
                return value;
        }
    }

    return value;
}

export const removeFormat = (metadata, value) => {
    if (!metadata || !value || !metadata.format) return value;

    let stringValue = value ? value.toString() : '';

    switch (metadata.dataType) {
        case 'String':
            return stringValue;

        case 'Integer':
        case 'Decimal':
            return stringValue.replace(/^[+]+/, '');

        default:
            return stringValue;
    }
}

export const removePadding = (metadata, value) => {
    if (!metadata || !value || !metadata.paddingType || !metadata.paddingCharacter) return value;

    if (['String', 'Integer'].some(i => i === metadata.dataType)) {
        switch (metadata.paddingType) {
            case 'Left':
                return value.replace(new RegExp('^[' + metadata.paddingCharacter + ']+'), '');

            case 'Right':
                return value.replace(new RegExp('[' + metadata.paddingCharacter + ']+$'), '');

            default:
                return value;
        }
    }

    return value;
}