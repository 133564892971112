import { createContext } from 'react';
import { toJS, decorate, observable, action } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export const getNewWorkOrder = (customerId, purchaseId) => {
    return {
        id: fn.newId('wo_'),
        customerId: customerId,
        purchaseId: purchaseId,
        items: [],
        isHighPriority: false,
    }
}

export const getNewWorkOrderItem = (transactionItem) => {
    return {
        id: fn.newId('woi_'),
        transactionItemId: transactionItem.id,
        otherTransactionItemIds: transactionItem.ids && transactionItem.ids.length > 0 ? transactionItem.ids.filter(d => d !== transactionItem.id) : [],
        transactionItemIds: [transactionItem.id, ...(transactionItem.ids && transactionItem.ids.length > 0 ? transactionItem.ids.filter(d => d !== transactionItem.id) : [])],
        descriptor: transactionItem.descriptor,
        workOrderPublishedTemplateId: null,
        referenceId: transactionItem.product ? transactionItem.product.id : null,
        referenceType: transactionItem.referenceType,
        quantity: transactionItem.quantity,
        transactionItemType: transactionItem.type,
        product: transactionItem.product,
        displayOrder: 10,
        isNew: true,
    }
}

export class WorkOrderCreate {
    customerId = null;
    purchaseId = null;
    purchase = null;
    data = null;
    templates = null;
    hasUnsavedChanges = false;
    isLoading = false;
    isSaving = false;
    isReady = false;

    cancelWorkOrderCreate = null;
    cancelWorkOrderSearch = null;
    cancelWorkOrderPublishedTemplate = null;
    cancelWorkOrderLink = null;
    cancelPurchaseGet = null;

    initialize = (customerId, purchaseId) => {
        const that = this;

        this.clear();
        this.customerId = customerId;
        this.purchaseId = purchaseId;

        return new Promise((resolve, reject) => {
            const requests = [];

            requests.push(api.WorkOrderPublishedTemplates.all((c) => { that.cancelWorkOrderPublishedTemplate = c }));

            if (purchaseId) {
                requests.push(api.Purchases.get(purchaseId, (c) => { that.cancelPurchaseGet = c }));
            }

            Promise.all(requests)
                .then(response => {
                    action(() => {
                        const templates = response[0] && response[0].data ? response[0].data.sort((a, b) => { return a.name > b.name ? 1 : -1 }) : [];
                        const purchase = response[1] && response[1].data ? response[1].data : null;
                        const suggestedWorkOrders = [];

                        if (templates && templates.length > 0 && purchase && purchase.items && purchase.items.length > 0) {
                            const validWorkOrders = purchase.workOrders && purchase.workOrders.length > 0 ? purchase.workOrders.filter(wo => !wo.isExpired && !wo.isDeactivated) : [];
                            const items = validWorkOrders && validWorkOrders.length > 0 ? purchase.items.filter(i => !validWorkOrders.some(w => w.items.some(wi => i.ids.some(ii => wi.transactionItemIds.some(wt => wt === ii))))) : purchase.items;
                            const rootTemplates = templates.filter(t => !t.parentId);
                            let workOrder = null; 

                            for (let pi = 0; pi < items.length; pi++) {
                                const item = items[pi];
                                const childTemplates = workOrder ? templates.filter(tp => tp.parentId === workOrder.items[0].workOrderPublishedTemplateId).sort((a, b) => { return a.displayOrder - b.displayOrder }) : [];  // eslint-disable-line

                                if (item.product && rootTemplates.some(rt => rt.productTypes.some(pt => pt.id === item.product.typeId))) {
                                    const rootTemplate = rootTemplates.filter(rt => rt.productTypes.some(pt => pt.id === item.product.typeId))[0];
                                    const rootWorkOrderItem = getNewWorkOrderItem(item);

                                    if (workOrder) {
                                        suggestedWorkOrders.push(workOrder);
                                    }

                                    workOrder = getNewWorkOrder(that.customerId, that.purchaseId);
                                    rootWorkOrderItem.workOrderPublishedTemplateId = rootTemplate.id;
                                    workOrder.items.push(rootWorkOrderItem);
                                }
                                else if (workOrder && item.product && childTemplates.some(ct => ct.productTypes.some(pt => pt.id === item.product.typeId))) {
                                    const childTemplate = childTemplates.filter(ct => ct.productTypes.some(pt => pt.id === item.product.typeId))[0];
                                    const childWorkOrderItem = getNewWorkOrderItem(item);

                                    childWorkOrderItem.workOrderPublishedTemplateId = childTemplate ? childTemplate.id : templates[0].id;
                                    childWorkOrderItem.displayOrder = Math.max.apply(Math, workOrder.items.map(wi => { return wi.displayOrder; })) + 10;

                                    workOrder.items.push(childWorkOrderItem);
                                }

                                if (workOrder && pi === (items.length - 1)) {
                                    suggestedWorkOrders.push(workOrder);
                                }
                            }
                        }

                        that.templates = templates;
                        that.purchase = purchase;

                        if (suggestedWorkOrders && suggestedWorkOrders.length > 0) {
                            that.data = suggestedWorkOrders;
                        } else {
                            that.data = [];
                        }

                        resolve();
                    })();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isReady = true;
                    that.isLoading = false;
                })
        })
    }

    updateWorkOrderPublishedTemplate = (workOrder, item, templateId) => {
        const workOrderIndex = this.data.findIndex(wo => wo.id === workOrder.id);

        if (workOrderIndex >= 0) {
            const itemIndex = this.data[workOrderIndex].items.findIndex(it => it.id === item.id);

            if (itemIndex >= 0) {
                this.data[workOrderIndex].items[itemIndex].workOrderPublishedTemplateId = templateId;
            }
        }
    }

    save = (notify) => {
        const that = this;
        const saveData = toJS([...that.data]);

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {

            if (saveData && saveData.length > 0) {
                const requests = [];

                for (let wo = 0; wo < saveData.length; wo++) {
                    const option = toJS(saveData[wo]);

                    if (option && option.items && option.items.length > 0) {
                        for (let oi = 0; oi < option.items.length; oi++) {
                            option.items[oi].product = null;
                        }
                    }

                    requests.push(api.WorkOrders.create(option, (c) => { that.cancelWorkOrderCreate = c }));
                }

                Promise.all(requests)
                    .then(() => {
                        if (saveData && saveData.length > 1) {
                            api.WorkOrders.link(
                                saveData[0].id,
                                {
                                    selectedIds: saveData.filter(sd => sd.id !== saveData[0].id).map(sd => { return sd.id }),
                                    reasonHtml: '<p>Same invoice</p>',
                                },
                                (c) => { that.cancelWorkOrderLink = c }
                            )
                                .then(() => {
                                    that.data = null;
                                    that.hasUnsavedChanges = false;
                                    resolve();
                                })
                                .catch((error) => {
                                    reject(error);
                                })
                                .finally(() => {
                                    that.isSaving = false;
                                })
                        }
                        else {
                            that.data = null;
                            that.hasUnsavedChanges = false;
                            that.isSaving = false;
                            resolve();
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }
        })
    }

    clear = () => {
        this.customerId = null;
        this.purchaseId = null;
        this.purchase = null;
        this.data = null;
        this.templates = null;
        this.hasUnsavedChanges = false;
        this.isLoading = false;
        this.isSaving = false;
        this.isReady = false;

        if (fn.isFunction(this.cancelWorkOrderCreate)) {
            this.cancelWorkOrderCreate();
            this.cancelWorkOrderCreate = null;
        }

        if (fn.isFunction(this.cancelWorkOrderSearch)) {
            this.cancelWorkOrderSearch();
            this.cancelWorkOrderSearch = null;
        }

        if (fn.isFunction(this.cancelWorkOrderPublishedTemplate)) {
            this.cancelWorkOrderPublishedTemplate();
            this.cancelWorkOrderPublishedTemplate = null;
        }

        if (fn.isFunction(this.cancelWorkOrderLink)) {
            this.cancelWorkOrderLink();
            this.cancelWorkOrderLink = null;
        }

        if (fn.isFunction(this.cancelPurchaseGet)) {
            this.cancelPurchaseGet();
            this.cancelPurchaseGet = null;
        }
    }
}

decorate(WorkOrderCreate, {
    customerId: observable,
    purchaseId: observable,
    purchase: observable,
    data: observable,
    templates: observable,
    hasUnsavedChanges: observable,
    isLoading: observable,
    isSaving: observable,
    isReady: observable,
    initialize: action,
    updateWorkOrderPublishedTemplate: action,
    getNewWorkOrder: action,
    getNewWorkOrderItem: action,
    save: action,
    clear: action,
})

export default createContext(new WorkOrderCreate());