import { createContext } from 'react';
import { decorate, observable, action } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';
import moment, { isMoment } from 'moment';

export class ScheduleUpdate {
    date = null;
    deleteType = null;
    userId = null;
    data = null;
    note = null;
    noteHtml = null;
    isReady = false;
    isSaving = false;
    isLoading = false;
    hasUnsavedChanges = false;
    cancelSchedule = null;
    cancelScheduleUpdate = null;
    cancelScheduleDelete = null;

    initialize = (userId, date) => {
        const that = this;

        this.clear();
        this.userId = userId;
        this.date = isMoment(date) ? date.startOf('day') : moment(date).startOf('day');
        return new Promise((resolve, reject) => {
            that.refresh()
                .then(() => {
                    resolve();
                })
                .catch(error => {
                    reject(error);
                })
                .finally(() => {
                    that.isReady = true;
                });
        })
    }

    refresh = () => {
        const that = this;
        that.isLoading = true;

        return new Promise((resolve, reject) => {
            api.Schedules.get(that.date.format('YYYY-MM-DD'), that.date.format('YYYY-MM-DD'), that.userId, (c) => { that.cancelSchedule = c })
                .then(({ data }) => {
                    that.data = data && data.length > 0 ? data[0] : null;
                    resolve();
                })
                .catch(error => {
                    reject(error);
                })
                .finally(() => {
                    that.isLoading = false;
                })
        })
    }

    delete = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            if (that.hasUnsavedChanges) {
                api.Schedules.delete(
                    {
                        userId: that.userId,
                        date: that.date.clone().startOf('day'),
                        type: that.deleteType,
                    },
                    (c) => { that.cancelScheduleDelete = c }
                )
                    .then(() => {
                        that.hasUnsavedChanges = false;
                        resolve();
                    })
                    .catch(error => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                that.hasUnsavedChanges = false;
                that.isSaving = false;
                resolve();
            }
        })
    }

    clear = () => {
        this.date = null;
        this.deleteType = null;
        this.userId = null;
        this.note = null;
        this.noteHtml = null;
        this.isExisting = false;
        this.isReady = false;
        this.isSaving = false;
        this.isLoading = false;
        this.hasUnsavedChanges = false;

        if (fn.isFunction(this.cancelSchedule)) {
            this.cancelSchedule();
            this.cancelSchedule = null;
        }

        if (fn.isFunction(this.cancelScheduleUpdate)) {
            this.cancelScheduleUpdate();
            this.cancelScheduleUpdate = null;
        }

        if (fn.isFunction(this.cancelScheduleDelete)) {
            this.cancelScheduleDelete();
            this.cancelScheduleDelete = null;
        }
    }
}

decorate(ScheduleUpdate, {
    date: observable,
    deleteType: observable,
    userId: observable,
    note: observable,
    noteHtml: observable,
    isReady: observable,
    isSaving: observable,
    isLoading: observable,
    hasUnsavedChanges: observable,
    initialize: action,
    refresh: action,
    delete: action,
    clear: action,
})

export default createContext(new ScheduleUpdate());