import React, { useContext, useEffect, useState, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { Observer } from 'mobx-react-lite';
import momentLocalizer from 'react-widgets-moment';
import { GlobalHotKeys } from 'react-hotkeys';
import TimeAgo from 'react-timeago';
import moment from 'moment';

import BodyEnd from '../../_shared/BodyEnd';
import ConfirmModal from '../../_shared/ConfirmModal';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';

import PretestCreateStore from '../../../../stores/PretestCreateStore';
import ExamViewStore from '../../../../stores/ExamViewStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as oh from '../../../../utilities/operationHelper';
import * as sys from '../../../../utilities/systemHelper';

import api from '../../../../api';

import './PretestImport.scss';

moment.locale('en');
momentLocalizer();

function PretestImport(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const quickDrawer = useContext(QuickDrawerStore);
    const pretestCreate = useContext(PretestCreateStore);
    const viewExam = useContext(ExamViewStore);
    const [existingPretestIds, setExistingPretestIds] = useState([]);
    const [selectedPretestIds, setSelectedPretestIds] = useState([]);
    const [pretests, setPretests] = useState([]);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [expandOlderPretests, setExpandOlderPretests] = useState(false);
    const [confirmClearPretest, setConfirmClearPretest] = useState(false);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        if (props && props.extraProps) {
            if (props.extraProps.selectedPretestIds) {
                setExistingPretestIds(props.extraProps.selectedPretestIds);
                setSelectedPretestIds(props.extraProps.selectedPretestIds);
            }
        }

        if (viewExam.isReady && !isReady) {
            api.Pretests.search(
                {
                    parameters: [
                        { field: 'DeactivatedDateUtc', value: null },
                        { field: 'CustomerId', value: viewExam.data.customer.id },
                    ],
                    sortByFields: [{ field: 'CreatedDateUtc', direction: 'DESC', }],
                    includeTotalCount: false,
                },
            )
                .then(({ data }) => {
                    if (isMounted.current) {
                        const tempPretests = data && data.result ? data.result : [];
                        setPretests(tempPretests);
                    }
                })
                .finally(() => {
                    setIsReady(true);
                })
        }

        quickDrawerFocus(props.drawer);

        return (() => {
            isMounted.current = false;
            pretestCreate.clear();
        })
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = event => {
        event.preventDefault();
        setHasUnsavedChanges(false);

        if (props.onSuccess && fn.isFunction(props.onSuccess)) {
            props.onSuccess(event, { updated: true, data: { selectedPretestIds } });
        }
    }

    const handleSelectPretest = event => {
        const id = event.target.value;
        const newSelectedPretests = [...selectedPretestIds];
        const index = newSelectedPretests.findIndex(p => p === id);

        if (index < 0) {
            newSelectedPretests.push(id);
        }
        else {
            newSelectedPretests.splice(index, 1);
        }

        setSelectedPretestIds(newSelectedPretests);
        setHasUnsavedChanges(true);
    }

    const handleExpandOlderPretests = () => {
        setExpandOlderPretests(true);
    }

    const handlePretestAdd = () => {
        const extraProps = {
            customer: viewExam.data.customer,
            appointment: viewExam.data.appointment,
        }
        quickDrawer.activateQuickDrawer('pretest', 'create', extraProps, handlePretestAddSuccess, handlePretestAddCancel);
    }

    const handlePretestAddSuccess = (result) => {
        pretestCreate.clear();
    }

    const handlePretestAddCancel = () => {
        viewExam.loadPretests();
        pretestCreate.clear();
    }

    const handlePretestClear = () => {
        if (isMounted.current) {
            setConfirmClearPretest(true);
        }
    }

    const handleConfirmClearPretest = event => {
        if (isMounted.current) {
            setHasUnsavedChanges(false);
            setConfirmClearPretest(false);

            if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                props.onSuccess(event, { updated: true, data: { selectedPretestIds: [] } });
            }
        }
    }

    const handleConfirmCancelClearPretest = event => {
        if (isMounted.current) {
            setConfirmClearPretest(false);
        }
    }

    const renderPretestItem = (pretest, key) => {
        return <div
            key={key}
            className='custom-control custom-checkbox mb-4'
        >
            <input
                id={`pretest-item-${key}`}
                type='checkbox'
                name='pretest-import'
                className='custom-control-input'
                value={pretest.id}
                checked={selectedPretestIds.some(p => p === pretest.id)}
                onChange={handleSelectPretest}
            />
            <label
                htmlFor={`pretest-item-${key}`}
                className='custom-control-label d-block'
            >
                <span className='d-flex flex-row'>
                    <span className='fs-sm d-block text-gray-700'>{pretest.pretestType.name}</span>
                </span>
                <span className='text-muted fs-xs d-block'>
                    {
                        moment().startOf('day').isSame(moment.utc(pretest.createdDateUtc).local().startOf('day')) ?
                            <>
                                Performed by <span className='text-gray-700 fw-500 mr-1'>{pretest.createdBy}</span>
                                <TimeAgo
                                    className='text-muted fs-xs'
                                    formatter={(value, unit, suffix) => { return fn.timeAgoPastFormatter(value, unit, suffix, 'second', 10, 'Just now') }}
                                    date={moment.utc(pretest.createdDateUtc).local().toDate()}
                                    title={sys.getFormattedLongDate(moment.utc(pretest.createdDateUtc).local(), true)}
                                    minPeriod={60}
                                />
                            </> :
                            <>
                                Performed by <span className='text-gray-700 fw-500 mr-1'>{pretest.createdBy}</span>
                                <br />
                                on&nbsp;
                                <span
                                    className='text-muted fs-xs'
                                    title={sys.getFormattedLongDate(moment.utc(pretest.createdDateUtc).local(), true)}
                                >
                                    {sys.getFormattedLongDate(moment.utc(pretest.createdDateUtc).local())}
                                </span>
                            </>
                    }
                </span>
            </label>
        </div>
    }

    const getExistingPretests = () => {
        if (!pretests) return [];
        const existingPretests = pretests.filter(p => existingPretestIds.some(s => p.id === s));
        return existingPretests && existingPretests.length > 0 ? existingPretests : [];
    }

    const getRecentPretests = () => {
        if (!pretests) return [];
        const recentDateUtc = moment.utc((viewExam.data.actualStartUtc ? viewExam.data.actualStartUtc : viewExam.data.scheduledStartUtc)).startOf('day').add(-1, 'months');
        const recentPretests = pretests.filter(p => !existingPretestIds.some(s => p.id === s) && moment.utc(p.createdDateUtc).isSameOrAfter(recentDateUtc));
        return recentPretests && recentPretests.length > 0 ? recentPretests : [];
    }

    const getOlderPretests = () => {
        if (!pretests) return [];
        const olderDateUtc = moment.utc((viewExam.data.actualStartUtc ? viewExam.data.actualStartUtc : viewExam.data.scheduledStartUtc)).startOf('day').add(-1, 'months');
        const olderPretests = pretests.filter(p => !existingPretestIds.some(s => p.id === s) && moment.utc(p.createdDateUtc).isBefore(olderDateUtc));
        return olderPretests && olderPretests.length > 0 ? olderPretests : [];
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <Observer>{() =>
                <fieldset>
                    <div className='quick-drawer'>
                        <QuickDrawerHeader
                            drawer={props.drawer}
                            icon={oh.getIcon('pretest', 'default')}
                            action='Import'
                            category='Pretest Result(s)'
                            className='pretests'
                            onCancel={handleCancel}
                        />
                        <div className='quick-drawer-body'>
                            {
                                viewExam.isReady && isReady ?
                                    <FadeIn>
                                        <div className='pretest-import body-content validate validate-required'>
                                            <section className='mb-n3'>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='form-group mb-0'>
                                                            <label className='required'><small>Select one or more pretest to import</small></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <Observer>{() =>
                                                <>
                                                    {
                                                        viewExam.data && getExistingPretests().length > 0 ?
                                                            <section className='pl-4'>
                                                                <div className='row'>
                                                                    <div className='col-12'>
                                                                        <div className='form-group'>
                                                                            <label className='mb-2'><small className='text-primary'>Selected test result(s)</small></label>
                                                                            {
                                                                                getExistingPretests().map((p, pi) => {
                                                                                    return renderPretestItem(p, `existing_pretest_test_${pi}`)
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </section> : null
                                                    }
                                                </>
                                            }</Observer>
                                            <Observer>{() =>
                                                <>
                                                    <section className='pl-4'>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className='form-group'>
                                                                    <label className='mb-2'><small className='text-primary'>Recent test result(s)</small></label>
                                                                    {
                                                                        viewExam.data && getRecentPretests().length > 0 ?
                                                                            <>
                                                                                {
                                                                                    getRecentPretests().map((p, pi) => {
                                                                                        return renderPretestItem(p, `recent_pretest_test_${pi}`)
                                                                                    })
                                                                                }
                                                                            </> : <p className='text-muted'>No recent pretest found</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </>
                                            }</Observer>
                                            <Observer>{() =>
                                                <>
                                                    {
                                                        getOlderPretests().length > 0 ?
                                                            <>
                                                                {
                                                                    expandOlderPretests ?
                                                                        <FadeIn>
                                                                            <section className='pl-4'>
                                                                                <div className='row'>
                                                                                    <div className='col-12'>
                                                                                        <div className='form-group'>
                                                                                            <label className='mb-2'><small>Older test result(s)</small></label>
                                                                                            {
                                                                                                getOlderPretests().map((p, pi) => {
                                                                                                    return renderPretestItem(p, `older_pretest_test_${pi}`)
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </section>
                                                                        </FadeIn> : <div className='pl-4'>
                                                                            <button
                                                                                type='button'
                                                                                className='btn btn-link p-0 ml-n2'
                                                                                onClick={handleExpandOlderPretests}
                                                                            >
                                                                                <i className='fal fa-angle-down mr-2'></i> See older test(s)
                                                                            </button>
                                                                        </div>
                                                                }
                                                            </> : null
                                                    }
                                                </>
                                            }</Observer>
                                        </div>
                                    </FadeIn> : renderQuickDrawerLoading()
                            }
                        </div>
                        <div className='quick-drawer-action'>
                            <div className='row'>
                                <div className='col-4'>
                                    <button
                                        type='button'
                                        className='btn btn-light btn-xl'
                                        onClick={handlePretestAdd}
                                    >Add</button>
                                </div>
                                <div className='col-8'>
                                    <div className='float-right'>
                                        <button
                                            type='button'
                                            className='btn btn-danger mr-2'
                                            onClick={handlePretestClear}
                                        >
                                            Clear
                                        </button>
                                        <button
                                            type='submit'
                                            className='btn btn-success'
                                        >
                                            {existingPretestIds && existingPretestIds.length > 0 ? 'Update' : 'Import'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            }</Observer>
        </form>
        <BodyEnd>
            <Observer>{() =>
                <ConfirmModal
                    icon={<i className={oh.getIcon('pretest', 'default') + ' text-danger mr-2'}></i>}
                    message={<>
                        {
                            confirmClearPretest ?
                                <>Continue to<span className='fw-500'>&nbsp;clear pretest data&nbsp;</span>in exam?</> :
                                <></>
                        }
                    </>}
                    option1ClassName={'btn btn-danger shadow-0 bootbox-accept'}
                    show={confirmClearPretest}
                    onOption1Click={handleConfirmClearPretest}
                    onCancel={handleConfirmCancelClearPretest}
                />
            }</Observer>
        </BodyEnd>
    </>
}

export default PretestImport;