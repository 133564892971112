import { createContext } from 'react';
import { decorate, observable, reaction } from 'mobx';
import moment from 'moment';

import api from '../api';
import * as StorageKeys from '../constants/storageKeys';
import * as fn from '../utilities/_functions';

export class Auth {
    currentUser = null;
    loggedInUser = null;
    currentTenant = null;
    switchPin = null;
    isLocked = false;
    isLockReady = false;
    isSwitchPinReady = false;
    isSwitchPinActivated = false;
    tokenRenewalTimeout = null;
    isProcessing = false;
    cancelAuthorizeStart = null;
    cancelTenants = null;
    cancelTenantsReload = null;
    cancelTenantsSwitch = null;
    cancelSettings = null;
    cancelSettingsReload = null;
    cancelSettingsSwitch = null;
    cancelUserGet = null;
    cancelUserSwitch = null;
    currentUserDisposer = null;
    currentTenantDisposer = null;
    loggedInUserDisposer = null;

    constructor() {
        this.currentUserDisposer = reaction(
            () => this.currentUser, (user) => {
                if (user) {
                    window.localStorage.setItem(StorageKeys.CURRENT_USER, JSON.stringify(user));
                } else {
                    window.localStorage.removeItem(StorageKeys.CURRENT_USER);
                }
            }
        );
        this.currentTenantDisposer = reaction(
            () => this.currentTenant, (tenant) => {
                if (tenant) {
                    window.localStorage.setItem(StorageKeys.CURRENT_TENANT, JSON.stringify(tenant));
                } else {
                    window.localStorage.removeItem(StorageKeys.CURRENT_TENANT);
                }
            }
        );
        this.loggedInUserDisposer = reaction(
            () => this.loggedInUser, (user) => {
                if (user) {
                    window.localStorage.setItem(StorageKeys.LOGGED_IN_USER, JSON.stringify(user));
                } else {
                    window.localStorage.removeItem(StorageKeys.LOGGED_IN_USER);
                }
            }
        )

        this.currentUser = JSON.parse(window.localStorage.getItem(StorageKeys.CURRENT_USER));
        this.currentTenant = JSON.parse(window.localStorage.getItem(StorageKeys.CURRENT_TENANT));
        this.loggedInUser = JSON.parse(window.localStorage.getItem(StorageKeys.LOGGED_IN_USER));
    }

    handleAuthentication = async (user, authResult) => {
        const isFailover = !!process.env.REACT_APP_IS_FAILOVER;
        this.isProcessing = true;
        const hostParts = window.location.host.split('.');
        let subdomain = hostParts[0];

        if (process.env.NODE_ENV === 'development') {
            subdomain = process.env.REACT_APP_DEBUG_ORG_SUBDOMAIN;
        }

        // console.log('isFailover', isFailover);
        // console.log('subdomain', subdomain);
        // console.log('identity', user.sub);

        const { data: authorization } = isFailover ? await api.Authorize.failover({ subdomain, identity: user.sub }, (c) => { this.cancelAuthorizeStart = c; }) : await api.Authorize.start({ subdomain, identity: user.sub }, (c) => { this.cancelAuthorizeStart = c; });

        // console.log('authorization', authorization);
        this.setAuthorization(authorization);

        // Authorization has defaultTenant embedded in it, so user.defaultTenantId is the default tenant id
        const { data: loggedInUser } = await api.Users.get(authorization.id, (c) => { this.cancelUserGet = c; });

        // console.log('loggedInUser', loggedInUser);
        this.currentUser = loggedInUser;
        this.loggedInUser = loggedInUser;

        // the currentUser.defaultTenantId is only used in login - it signals which tenant is the default tenant for this user
        const { data: tenant } = await api.Tenants.get(this.currentUser.defaultTenantId, false, (c) => { this.cancelTenants = c; });

        // console.log('tenant', tenant);
        this.currentTenant = tenant;

        // console.log('handleAuthentication');
        const { data: settings } = await api.Settings.all(this.currentUser.id, (c) => { this.cancelSettings = c; });
        this.setSettings(settings);

        moment().tz(tenant.timeZoneId);

        this.setSession(authResult);

        this.isProcessing = false;
    }

    activateLockScreen = () => {
        this.isLockReady = true;
        this.isLocked = true;
        this.isSwitchPinActivated = false;
        return Promise.resolve();
    }

    deactivateLockScreen = () => {
        this.isLockReady = false;
        this.isLocked = false;
        this.isSwitchPinActivated = false;
        this.currentUser = JSON.parse(window.localStorage.getItem(StorageKeys.CURRENT_USER));

        return Promise.resolve();
    }

    activateSwitchPinScreen = () => {
        this.isSwitchPinReady = true;
        this.isSwitchPinActivated = true;
        return Promise.resolve();
    }

    deactivateSwitchPinScreen = () => {
        if (this.isSwitchPinActivated) {
            this.isSwitchPinActivated = false;
        }
        return Promise.resolve();
    }

    refresh = () => {
        this.currentUser = JSON.parse(window.localStorage.getItem(StorageKeys.CURRENT_USER));
        this.loggedInUser = JSON.parse(window.localStorage.getItem(StorageKeys.LOGGED_IN_USER));
    }

    reset() {
        this.switchPin = null;
        this.currentUser = null;
        this.loggedInUser = null;
        this.isLocked = false;
        this.isLockReady = false;
        this.isSwitchPinReady = false;
        this.isSwitchPinActivated = false;
        this.isProcessing = false;

        if (fn.isFunction(this.cancelAuthorizeStart)) {
            this.cancelAuthorizeStart = null;
        }

        if (fn.isFunction(this.cancelTenants)) {
            this.cancelTenants();
            this.cancelTenants = null;
        }

        if (fn.isFunction(this.cancelTenantsReload)) {
            this.cancelTenantsReload();
            this.cancelTenantsReload = null;
        }

        if (fn.isFunction(this.cancelTenantsSwitch)) {
            this.cancelTenantsSwitch();
            this.cancelTenantsSwitch = null;
        }

        if (fn.isFunction(this.cancelSettings)) {
            this.cancelSettings();
            this.cancelSettings = null;
        }

        if (fn.isFunction(this.cancelSettingsReload)) {
            this.cancelSettingsReload();
            this.cancelSettingsReload = null;
        }

        if (fn.isFunction(this.cancelSettingsSwitch)) {
            this.cancelSettingsSwitch();
            this.cancelSettingsSwitch = null;
        }

        if (fn.isFunction(this.cancelUserGet)) {
            this.cancelUserGet();
            this.cancelUserGet = null;
        }

        if (fn.isFunction(this.cancelUserSwitch)) {
            this.cancelUserSwitch();
            this.cancelUserSwitch = null;
        }

        if (fn.isFunction(this.currentUserDisposer)) {
            this.currentUserDisposer();
            this.currentUserDisposer = null;
        }

        if (fn.isFunction(this.currentTenantDisposer)) {
            this.currentTenantDisposer();
            this.currentTenantDisposer = null;
        }

        if (fn.isFunction(this.loggedInUserDisposer)) {
            this.loggedInUserDisposer();
            this.loggedInUserDisposer = null;
        }
    }

    setAuthorization = (authorization) => {
        // const clientIp = window.localStorage.getItem(StorageKeys.CLIENT_IP);
        // const updated = (clientIp !== authorization.ip || (this.currentUser && this.currentUser.id !== authorization.id));

        //        if (updated) {
        window.localStorage.setItem(StorageKeys.CLIENT_IP, authorization.ip);
        window.localStorage.setItem(StorageKeys.AUTHORIZATION_KEY, authorization.key);
        window.localStorage.setItem(StorageKeys.AUTHORIZATION_TOKEN, authorization.token);
        //      }
        //    return updated;
    }

    setSettings = (settings) => {
        window.localStorage.setItem(StorageKeys.SETTINGS, JSON.stringify(settings));
    }

    setSession = (authResult) => {
        const now = new Date();
        const tokenExpiresIn = parseInt(process.env.REACT_APP_AUTH0_SESSION_EXPIRY);
        const tokenExpiresAt = (!!authResult && !!authResult.exp ? new Date((authResult.exp * 1000)).getTime() : now.getTime() + tokenExpiresIn);
        const nightlyLogoutTime = new Date(new Date().setHours(3, 59, 59));
        const nightlyLogoutAt = now.getTime() >= nightlyLogoutTime.getTime() ? nightlyLogoutTime.setDate(now.getDate() + 1) : nightlyLogoutTime.getTime();
        const expiresAt = Math.min(tokenExpiresAt, nightlyLogoutAt);

        window.localStorage.setItem(StorageKeys.EXPIRES_AT, JSON.stringify(expiresAt));
    }

    clearSession = () => {
        for (let item of StorageKeys.CLEAR_FIELDS) {
            window.localStorage.removeItem(item);
        }
    }

    get isSessionActive() {
        const expiresAtJson = window.localStorage.getItem(StorageKeys.EXPIRES_AT);

        // if (expiresAtJson && this.currentUser && this.currentTenant) {  // TODO: Is this causing the multiple refresh???
        if (expiresAtJson) {
            const expiresAt = JSON.parse(expiresAtJson);
            const isExpired = new Date().getTime() >= expiresAt;

            if (expiresAt && isExpired) {
                return false;
            }

            return !isExpired;
        }

        return false;
    }

    get isSessionValid() {
        if (!this.isSessionActive) {
            const expiresAtJson = window.localStorage.getItem(StorageKeys.EXPIRES_AT);
            const currentUser = window.localStorage.getItem(StorageKeys.CURRENT_USER);
            const currentTenant = window.localStorage.getItem(StorageKeys.CURRENT_TENANT);
            const loggedInUser = window.localStorage.getItem(StorageKeys.LOGGED_IN_USER);
            const isValid = !expiresAtJson && !currentUser && !currentTenant && !loggedInUser;

            return isValid;
        }

        return true;
    }

    get isImpersonating() {
        if (this.currentUser && this.loggedInUser) {
            return (this.currentUser.id !== this.loggedInUser.id);
        }
        return false;
    }
}

decorate(Auth, {
    currentUser: observable.deep,
    currentTenant: observable.deep,
    loggedInUser: observable,
    switchPin: observable,
    isLocked: observable,
    isLockReady: observable,
    isSwitchPinActivated: observable,
    isSwitchPinReady: observable,
    isProcessing: observable,
    //isSessionActive: computed, // after upgraded to react-router v6, it returns stale values, which appeared to be the right behavior
})

export default createContext(new Auth());
