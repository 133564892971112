import React, { useEffect, useContext, useRef, Fragment } from 'react';
import { Link } from 'react-router-dom';
import FadeIn from 'react-fade-in';
import { useObserver } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import { GlobalHotKeys } from 'react-hotkeys';

import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';

import CustomerUpdateStore from '../../../../stores/CustomerUpdateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';
import CacheStore from '../../../../stores/CacheStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as rts  from '../../../../constants/routes';
import * as fn from '../../../../utilities/_functions';
import * as ch from '../../../../utilities/customerHelper';
import * as ph from '../../../../utilities/personHelper';
import * as sys from '../../../../utilities/systemHelper';
import * as oh from '../../../../utilities/operationHelper';
import * as ah from '../../../../utilities/addressHelper';

import './UpdateRelationships.scss';

function UpdateRelationships(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const customer = useContext(CustomerUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const cache = useContext(CacheStore);

    useEffect(() => {
        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100)

        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (customer.hasRelationshipUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleLinkedRelationshipRemove = (event, { id }) => {
        const index = customer.relationships.findIndex(r => r.id === id);

        if (index >= 0) {
            customer.relationships.splice(index, 1);
            customer.hasRelationshipUnsavedChanges = true;
        }
    }

    const handleLinkedRelationshipChange = (event, { id }) => {
        const relationship = event.target.value;
        const index = customer.relationships.findIndex(r => r.id === id);

        if (index >= 0) {
            customer.relationships[index].relationship = relationship;
            customer.hasRelationshipUnsavedChanges = true;
        }
    }

    const handleLinkedRelationshipAdd = () => {
        quickDrawer.activateQuickDrawer('customer', 'relationship-create', null, handleLinkedRelationshipAddSuccess, handleLinkedRelationshipAddCancel);
    }

    const handleLinkedRelationshipAddSuccess = (result) => {
        if (result && result.updated) {
            customer.loadRelationships();
            toast.dark(() => <p data-linked-rel-updated>Linked relationship added.</p>);
        }
    }

    const handleLinkedRelationshipAddCancel = () => {
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (customer.hasRelationshipUnsavedChanges) {
            const updated = customer.hasRelationshipUnsavedChanges;

            customer.updateRelationshipInformation()
                .then(data => {
                    if (isMounted.current) {
                        if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                            props.onSuccess(event, { updated: updated, data: data });
                        }
                    }
                })
        } else {
            if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                props.onSuccess(event, { updated: false, data: null });
            }
        }
    }

    const renderCustomer = () => {
        return <div
            className='profile-wrapper'
        >
            <div className='profile'>
                <span
                    className={`profile-image profile-initials rounded-circle d-flex text-white ${ch.getProfileColor(customer.data)} fw-500`}
                    title={ph.getFullName(customer.data)}
                >
                    {customer.data.initials}
                </span>
            </div>
            <div className='description flex-1'>
                <Link to={`${rts.Customers.Home}/${customer.data.id}`} className='name text-gray-700' onClick={quickDrawer.deactivateAll}>{ph.getFullName(customer.data, true)}
                    {
                        customer.data.dateOfBirth || customer.data.sex || customer.data.gender || customer.data.pronoun ?
                            <small className='text-nowrap ml-2'>({`${ph.getAge(customer.data.dateOfBirth)} ${ph.getSexGenderPronounDisplay(customer.data)}`.trim()})</small> : null
                    }
                </Link>
                {
                    customer.data.address && customer.data.address.country ?
                        <div className='info'>{ah.getAddressHtml(customer.data.address)}</div> : null
                }
                {
                    customer.data.emailAddress ?
                        <div className='info'>
                            <a
                                href={`mailto:${customer.data.emailAddress}`}
                            >{customer.data.emailAddress}
                            </a>
                        </div> : null
                }
                {
                    customer.data.phoneNumber ?
                        <div className='info'>
                            <a
                                href={`tel:${customer.data.phoneNumber}`}
                            >{sys.getFormattedPhoneNumber(customer.data.phoneNumber)}
                            </a>
                        </div> : null
                }
            </div>
        </div>
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form onSubmit={handleSubmit}>
            <fieldset disabled={customer.isSaving}>
                <div className='update-customer-relationships-container quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('customer', 'relationship')}
                        action='Update Customer'
                        category='Linked Relationships'
                        className='customers'
                        onCancel={props.onCancel}
                    />
                    <div className='quick-drawer-body'>
                        {
                            customer.isReady && customer.relationships ?
                                <FadeIn>
                                    <div className='body-content'>
                                        <section className='customer'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0'>
                                                        <label><small>Customer</small></label>
                                                        {renderCustomer()}
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0'>
                                                        <label><small>Linked Relationship(s)</small></label>
                                                        {
                                                            customer.relationships && customer.relationships.filter(r => r.id).length > 0 ?
                                                                customer.relationships.filter(r => r.id).map((r, ri) => {
                                                                    return <Fragment
                                                                        key={`customer_relationship_${ri}`}
                                                                    >
                                                                        <div className='profile-wrapper'>
                                                                            <div className='profile'>
                                                                                <span
                                                                                    className={`profile-image profile-initials rounded-circle d-flex text-white ${ch.getProfileColor(r.relatedTo)} fw-500`}
                                                                                    title={ph.getFullName(r.relatedTo)}
                                                                                >
                                                                                    {r.relatedTo.initials}
                                                                                </span>
                                                                            </div>
                                                                            <div className='description flex-1'>
                                                                                <Link to={`${rts.Customers.Home}/${r.relatedTo.id}`} className='name text-gray-700' onClick={quickDrawer.deactivateAll}>{ph.getFullName(r.relatedTo, true)}
                                                                                    {
                                                                                        r.relatedTo.dateOfBirth || r.relatedTo.sex || r.relatedTo.gender || r.relatedTo.pronoun ?
                                                                                            <small className='text-nowrap ml-2'>({`${ph.getAge(r.relatedTo.dateOfBirth)} ${ph.getSexGenderPronounDisplay(r.relatedTo)}`.trim()})</small> : null
                                                                                    }
                                                                                </Link>
                                                                                {
                                                                                    r.relatedTo.address && r.relatedTo.address.country ?
                                                                                        <div className='info'>{ah.getAddressHtml(r.relatedTo.address)}</div> : null
                                                                                }
                                                                                <div className='py-2'>
                                                                                    <select
                                                                                        id='update-personal-salutation-type-select'
                                                                                        className='custom-select form-control'
                                                                                        disabled={!ch.checkCanUpdate(customer.data)}
                                                                                        value={r.relationship ? r.relationship : ''}
                                                                                        onChange={(e) => { handleLinkedRelationshipChange(e, r) }}
                                                                                    >
                                                                                        <option value=''>Not confirmed</option>
                                                                                        <optgroup label='Common'>
                                                                                            {
                                                                                                ph.COMMON_RELATIONSHIP_TYPES.map((r, ri) => {
                                                                                                    return <option key={`customer_common_relationship_type_${ri}`} value={r}>
                                                                                                        {
                                                                                                            cache.getReferenceDataOptions('CustomerRelationshipType').some(o => o.key === r) ?
                                                                                                                cache.getReferenceDataOptions('CustomerRelationshipType').filter(o => o.key === r)[0].value : r
                                                                                                        }
                                                                                                    </option>
                                                                                                })
                                                                                            }
                                                                                        </optgroup>
                                                                                        <optgroup label='Less common'>
                                                                                            {
                                                                                                cache.getReferenceDataOptions('CustomerRelationshipType').filter(option => !ph.COMMON_RELATIONSHIP_TYPES.some(o => o === option.key)).map((option, di) => {
                                                                                                    return <option key={`customer_less_common_relationship_type_${di}`} value={option.key}>{option.value}</option>
                                                                                                })
                                                                                            }
                                                                                        </optgroup>
                                                                                    </select>
                                                                                    {
                                                                                        r.relationship ?
                                                                                            <div className='pt-1 px-2 text-info-400'>{ph.getPreferredFirstName(r.relatedTo)} is <strong className='tt-underline'>{ph.getPreferredFirstName(customer.data)}'s {cache.getReferenceDataOptions('CustomerRelationshipType').filter(o => o.key === r.relationship)[0].value.toLowerCase()}</strong>.</div> : null
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className='pl-4'>
                                                                                <button
                                                                                    type='button'
                                                                                    className='btn btn-link p-0 text-danger line-height-1'
                                                                                    disabled={!ch.checkCanUpdate(customer.data)}
                                                                                    onClick={(e) => { handleLinkedRelationshipRemove(e, r) }}
                                                                                >
                                                                                    <i className='fal fa-times'></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            customer.relationships.filter(r => r.id).length !== (ri + 1) ?
                                                                                <hr className='mb-4 w-100' /> : null
                                                                        }
                                                                    </Fragment>
                                                                }) : <span className='d-block text-muted'>No linked relationship found</span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </FadeIn> : renderQuickDrawerLoading()
                        }
                    </div>
                    <div className='quick-drawer-action pl-3'>
                        <div className='row'>
                            <div className='col-4'>
                                <button
                                    type='button'
                                    className='btn btn-light btn-xl'
                                    disabled={!ch.checkCanUpdate(customer.data)}
                                    onClick={handleLinkedRelationshipAdd}
                                >Add</button>
                            </div>
                            <div className='col-8'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={props.onCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                        disabled={!ch.checkCanUpdate(customer.data)}
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>)
}

export default UpdateRelationships;