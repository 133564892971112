import React from 'react';
import moment from 'moment';
import * as sys from './systemHelper';

export const toTypeIcon = (type, className) => {
    if (type) {
        switch (type.toLowerCase()) {
            case 'paid':
                return <i className={'fal fa-envelope-open-dollar text-info text-center' + (className ? ` ${className}` : '')} style={{ minWidth: '35px' }}></i>;

            case 'collectible':
                return <i className={'fal fa-sensor text-success text-center' + (className ? ` ${className}` : '')} style={{ minWidth: '35px' }}></i>;

            case 'uncollectible':
                return <i className={'fal fa-sensor-alert text-danger text-center' + (className ? ` ${className}` : '')} style={{ minWidth: '35px' }}></i>;

            default:
                break;
        }
    }

    return <i className={'fal fa-question-square text-center' + (className ? ` ${className}` : '')} style={{ color: '#f5af14', minWidth: '35px' }}></i>;
}

export const isEndState = publicInsuranceBillingReport => {
    return publicInsuranceBillingReport && (['Paid', 'Rejected'].some(s => s === publicInsuranceBillingReport.status) || publicInsuranceBillingReport.isExpired);
}

export const getLockedReason = publicInsuranceBillingReport => {
    if (!publicInsuranceBillingReport || !publicInsuranceBillingReport.status) return;

    switch (publicInsuranceBillingReport.status) {
        case 'Unknown':
            return 'Unknown reason.';

        case 'Ignore':
            return '--';

        case 'Submitted':
            return `Submitted to ${publicInsuranceBillingReport.publicInsuranceUnitId}${publicInsuranceBillingReport.submittedDateUtc ? ` on ${sys.getFormattedShortDate(moment.utc(publicInsuranceBillingReport.submittedDateUtc).local())}.` : '.'}`;

        case 'Paid':
            return `Approved by ${publicInsuranceBillingReport.publicInsuranceUnitId}${publicInsuranceBillingReport.paidDateUtc ? ` - ${moment.utc(publicInsuranceBillingReport.paidDateUtc).local().startOf('day').isBefore(moment().startOf('day')) ? 'paid' : 'will be paid'} on ${sys.getFormattedShortDate(moment.utc(publicInsuranceBillingReport.paidDateUtc).local())}.` : '.'}`;

        default:
            if (publicInsuranceBillingReport.expiryDateUtc) {
                return `Expired${publicInsuranceBillingReport.expiryDateUtc ? ` on ${sys.getFormattedShortDate(moment.utc(publicInsuranceBillingReport.expiryDateUtc).local())}.` : '.'}`;
            }
            return 'Unknown reason.';
    }
}

export const getExpiryDate = (tenant) => {
    if (!tenant) return null;

    switch (tenant.publicInsuranceUnitId) {
        case 'OHIP':
            return moment().startOf('day').add(-3, 'months').toDate();

        default:
            return null;
    }
}

export const getSubmissionMethodDescription = submissionMethod => {
    if (submissionMethod) {
        switch (submissionMethod.toLowerCase()) {
            case 'manual':
                return 'Manually';

            case 'auto':
                return 'Automatically';

            default:
                return '';
        }
    }
}

export const getPublicInsuranceBillingReportStatusDescription = status => {
    if (status) {
        switch (status.toLowerCase()) {
            case 'unknown':
                return 'Unknown';

            case 'scheduled':
                return 'Scheduled to submit';

            case 'submitted':
                return 'Claim submitted';

            case 'failed':
                return 'Claim rejected';

            case 'notready':
                return 'Not ready to submit';

            case 'ready':
                return 'Ready to submit';

            case 'paid':
                return 'Payment sent';

            default:
                return '';
        }
    }
}

export const getQuickFilterDescription = (type) => {
    if (type) {
        switch (type) {
            case 'Unsubmitted':
                return 'Un-submitted claims';

            case 'Scheduled':
                return 'Scheduled to submit';

            case 'RecentUpdated':
                return 'Recently updated claims';

            case 'RecentPaid':
                return 'Recently paid claims';

            case 'RecentFailed':
                return 'Recently rejected claims';

            case 'LastReconciled':
                return `Reconciliation for ${moment().add(-1, 'months').format('MMMM, YYYY')}`;

            case 'Last2Reconciled':
                return `Reconciliation for ${moment().add(-2, 'months').format('MMMM, YYYY')}`;

            case 'Last3Reconciled':
                return `Reconciliation for ${moment().add(-3, 'months').format('MMMM, YYYY')}`;

            default:
                return null;
        }
    }
    return null;
}

export const getQuickFilterDefinition = (tenant, type) => {
    if (type) {
        const expiryDate = getExpiryDate(tenant);
        const lastUpdatedFilters = getLastUpdateFilters();

        switch (type) {
            case 'Unsubmitted':
                return [{
                    column: 'Status',
                    headerFilters: ['NotReady', 'Ready', 'Scheduled', 'Failed']
                }, {
                    column: 'ScheduledStartUtc',
                    filter: {
                        operator: '>',
                        value: expiryDate,
                    }
                }]

            case 'Scheduled':
                return [{
                    column: 'Status',
                    headerFilters: ['Scheduled']
                }, {
                    column: 'ScheduledStartUtc',
                    filter: {
                        operator: '>',
                        value: expiryDate,
                    }
                }]

            case 'RecentUpdated':
                return [{
                    column: 'LastUpdatedDateUtc',
                    headerFilters: [lastUpdatedFilters.filter(u => u.text === 'This month')[0].value, lastUpdatedFilters.filter(u => u.text === 'Last month')[0].value]
                }, {
                    column: 'ScheduledStartUtc',
                    filter: {
                        operator: '>',
                        value: expiryDate,
                    }
                }]

            case 'RecentPaid':
                return [{
                    column: 'Status',
                    headerFilters: ['Paid']
                }, {
                    column: 'LastUpdatedDateUtc',
                    headerFilters: [lastUpdatedFilters.filter(u => u.text === 'This month')[0].value, lastUpdatedFilters.filter(u => u.text === 'Last month')[0].value]
                }, {
                    column: 'ScheduledStartUtc',
                    filter: {
                        operator: '>',
                        value: expiryDate,
                    }
                }]

            case 'RecentFailed':
                return [{
                    column: 'Status',
                    headerFilters: ['Failed']
                }, {
                    column: 'ScheduledStartUtc',
                    filter: {
                        operator: '>',
                        value: expiryDate,
                    }
                }]

            case 'LastReconciled':
                return [{
                    column: 'Status',
                    headerFilters: ['Paid', 'Failed']
                }, {
                    column: 'LastUpdatedDateUtc',
                    filter: {
                        operator: 'between',
                        value: [moment().startOf('month').startOf('day').toDate(), moment().endOf('month').startOf('day').add(1, 'days').toDate()],
                    }
                }]

            case 'Last2Reconciled':
                return [{
                    column: 'Status',
                    headerFilters: ['Paid', 'Failed']
                }, {
                    column: 'LastUpdatedDateUtc',
                    filter: {
                        operator: 'between',
                        value: [moment().add(-1, 'months').startOf('month').startOf('day').toDate(), moment().add(-1, 'months').endOf('month').startOf('day').add(1, 'days').toDate()],
                    }
                }]

            case 'Last3Reconciled':
                return [{
                    column: 'Status',
                    headerFilters: ['Paid', 'Failed']
                }, {
                    column: 'LastUpdatedDateUtc',
                    filter: {
                        operator: 'between',
                        value: [moment().add(-2, 'months').startOf('month').startOf('day').toDate(), moment().add(-2, 'months').endOf('month').startOf('day').add(1, 'days').toDate()],
                    }
                }]

            default:
                return;
        }
    }
}

export const getStatusFilters = () => {
    return [
        {
            text: getPublicInsuranceBillingReportStatusDescription('NotReady'),
            value: 'NotReady'
        },
        {
            text: getPublicInsuranceBillingReportStatusDescription('Ready'),
            value: 'Ready'
        },
        {
            text: getPublicInsuranceBillingReportStatusDescription('Scheduled'),
            value: 'Scheduled'
        },
        {
            text: getPublicInsuranceBillingReportStatusDescription('Submitted'),
            value: 'Submitted'
        },
        {
            text: getPublicInsuranceBillingReportStatusDescription('Failed'),
            value: 'Failed'
        },
        {
            text: getPublicInsuranceBillingReportStatusDescription('Paid'),
            value: 'Paid'
        },
    ];
}

export const getExamFilters = () => {
    return [
        {
            text: 'Today',
            value: [['ScheduledStartUtc', '>=', moment().startOf('day').toDate()], 'and', ['ScheduledStartUtc', '<', moment().add(1, 'days').startOf('day').toDate()]]
        },
        {
            text: 'Yesterday',
            value: [['ScheduledStartUtc', '>=', moment().add(-1, 'days').startOf('day').toDate()], 'and', ['ScheduledStartUtc', '<', moment().startOf('day').toDate()]]
        },
        {
            text: 'This week',
            value: [['ScheduledStartUtc', '>=', moment().startOf('week').startOf('day').toDate()], 'and', ['ScheduledStartUtc', '<', moment().endOf('week').startOf('day').add(1, 'days').toDate()]]
        },
        {
            text: 'This month',
            value: [['ScheduledStartUtc', '>=', moment().startOf('month').startOf('day').toDate()], 'and', ['ScheduledStartUtc', '<', moment().endOf('month').startOf('day').add(1, 'days').toDate()]]
        },
        {
            text: 'Last month',
            value: [['ScheduledStartUtc', '>=', moment().add(-1, 'months').startOf('month').startOf('day').toDate()], 'and', ['ScheduledStartUtc', '<', moment().add(-1, 'months').endOf('month').startOf('day').add(1, 'days').toDate()]]
        },
        {
            text: moment().add(-2, 'months').startOf('month').startOf('day').format('MMMM, YYYY'),
            value: [['ScheduledStartUtc', '>=', moment().add(-2, 'months').startOf('month').startOf('day').toDate()], 'and', ['ScheduledStartUtc', '<', moment().add(-2, 'months').endOf('month').startOf('day').add(1, 'days').toDate()]]
        },
        {
            text: moment().add(-3, 'months').startOf('month').startOf('day').format('MMMM, YYYY'),
            value: [['ScheduledStartUtc', '>=', moment().add(-3, 'months').startOf('month').startOf('day').toDate()], 'and', ['ScheduledStartUtc', '<', moment().add(-3, 'months').endOf('month').startOf('day').add(1, 'days').toDate()]]
        },
    ];
}

export const getLastUpdateFilters = () => {
    return [
        {
            text: 'Today',
            value: [['LastUpdatedDateUtc', '>=', moment().startOf('day').toDate()], 'and', ['LastUpdatedDateUtc', '<', moment().add(1, 'days').startOf('day').toDate()]]
        },
        {
            text: 'Yesterday',
            value: [['LastUpdatedDateUtc', '>=', moment().add(-1, 'days').startOf('day').toDate()], 'and', ['LastUpdatedDateUtc', '<', moment().startOf('day').toDate()]]
        },
        {
            text: 'This week',
            value: [['LastUpdatedDateUtc', '>=', moment().startOf('week').startOf('day').toDate()], 'and', ['LastUpdatedDateUtc', '<', moment().endOf('week').startOf('day').add(1, 'days').toDate()]]
        },
        {
            text: 'This month',
            value: [['LastUpdatedDateUtc', '>=', moment().startOf('month').startOf('day').toDate()], 'and', ['LastUpdatedDateUtc', '<', moment().endOf('month').startOf('day').add(1, 'days').toDate()]]
        },
        {
            text: 'Last month',
            value: [['LastUpdatedDateUtc', '>=', moment().add(-1, 'months').startOf('month').startOf('day').toDate()], 'and', ['LastUpdatedDateUtc', '<', moment().add(-1, 'months').endOf('month').startOf('day').add(1, 'days').toDate()]]
        },
        {
            text: moment().add(-2, 'months').startOf('month').startOf('day').format('MMMM, YYYY'),
            value: [['LastUpdatedDateUtc', '>=', moment().add(-2, 'months').startOf('month').startOf('day').toDate()], 'and', ['LastUpdatedDateUtc', '<', moment().add(-2, 'months').endOf('month').startOf('day').add(1, 'days').toDate()]]
        },
        {
            text: moment().add(-3, 'months').startOf('month').startOf('day').format('MMMM, YYYY'),
            value: [['LastUpdatedDateUtc', '>=', moment().add(-3, 'months').startOf('month').startOf('day').toDate()], 'and', ['LastUpdatedDateUtc', '<', moment().add(-3, 'months').endOf('month').startOf('day').add(1, 'days').toDate()]]
        },
    ];
}