import { createContext } from 'react';
import { decorate, observable, action, toJS } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class LogicalDeviceUpdate {
    id = null;
    data = null;
    isReady = false;
    isSaving = false;
    isLoading = false;
    hasUnsavedChanges = false;

    cancelLogicalDeviceGet = null;
    cancelLogicalDeviceUpdate = null;

    initialize = (id) => {
        const that = this;
        this.clear();

        this.id = id;

        return new Promise((resolve, reject) => {
            that.refresh()
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isReady = true;
                })
        })
    }

    refresh = (notify) => {
        const that = this;

        if (!!notify) {
            this.isLoading = true;
        }

        return new Promise((resolve, reject) => {
            api.LogicalDevices.get(that.id, c => { this.cancelLogicalDeviceGet = c })
                .then(({ data }) => {
                    that.data = data;
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isLoading = false;
                })
        })
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            let option = toJS(that.data);

            if (that.hasUnsavedChanges) {
                api.LogicalDevices.save(option, (c) => { that.cancelLogicalDeviceUpdate = c })
                    .then(() => {
                        that.hasUnsavedChanges = false;
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                that.hasUnsavedChanges = false;
                that.isSaving = false;
                resolve();
            }
        })
    }

    delete = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            api.LogicalDevices.delete(that.id, (c) => { that.cancelLogicalDeviceUpdate = c })
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.hasUnsavedChanges = false;
                    that.isSaving = false;
                })
        })
    }

    clear = () => {
        this.id = null;
        this.data = null;
        this.hasUnsavedChanges = false;
        this.isReady = false;
        this.isSaving = false;
        this.isLoading = false;

        if (fn.isFunction(this.cancelLogicalDeviceGet)) {
            this.cancelLogicalDeviceGet();
            this.cancelLogicalDeviceGet = null;
        }

        if (fn.isFunction(this.cancelLogicalDeviceUpdate)) {
            this.cancelLogicalDeviceUpdate();
            this.cancelLogicalDeviceUpdate = null;
        }
    }
}

decorate(LogicalDeviceUpdate, {
    id: observable,
    data: observable.deep,
    hasUnsavedChanges: observable,
    isReady: observable,
    isSaving: observable,
    isUpdate: observable,
    initialize: action,
    refresh: action,
    save: action,
    delete: action,
    clear: action,
})

export default createContext(new LogicalDeviceUpdate());