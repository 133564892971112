import React, { useState, useRef, useEffect, useContext } from 'react';
import { Observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import DataGrid, { Scrolling, Column, ColumnFixing, Selection, Paging, Pager, Sorting, FilterRow } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { GlobalHotKeys } from 'react-hotkeys';

import NoteTooltip from './NoteTooltip';

import PrivateInsuranceProviderCreateStore from '../../../../stores/PrivateInsuranceProviderCreateStore';
import PrivateInsuranceProviderUpdateStore from '../../../../stores/PrivateInsuranceProviderUpdateStore';
import ExternalClinicCreateStore from '../../../../stores/ExternalClinicCreateStore';
import ExternalClinicUpdateStore from '../../../../stores/ExternalClinicUpdateStore';
import ExternalDoctorCreateStore from '../../../../stores/ExternalDoctorCreateStore';
import ExternalDoctorUpdateStore from '../../../../stores/ExternalDoctorUpdateStore';
import ProductSupplierCreateStore from '../../../../stores/ProductSupplierCreateStore';
import ContactCreateStore from '../../../../stores/ContactCreateStore';
import ContactUpdateStore from '../../../../stores/ContactUpdateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as fn from '../../../../utilities/_functions';
import * as sys from '../../../../utilities/systemHelper';

import api from '../../../../api';

const ADDRESS_BOOK_UPDATED_MESSAGE = <p>Address book updated.</p>;

function AddressBookList(props) {
    const isMounted = useRef(true);
    const dataGridRef = useRef(null);
    const privateInsuranceNew = useContext(PrivateInsuranceProviderCreateStore);
    const privateInsuranceView = useContext(PrivateInsuranceProviderUpdateStore);
    const externalClinicNew = useContext(ExternalClinicCreateStore);
    const externalClinicView = useContext(ExternalClinicUpdateStore);
    const externalDoctorNew = useContext(ExternalDoctorCreateStore);
    const externalDoctorView = useContext(ExternalDoctorUpdateStore);
    const productSupplier = useContext(ProductSupplierCreateStore);
    const contactNew = useContext(ContactCreateStore);
    const contactView = useContext(ContactUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [type, setType] = useState(props.type);
    const [inEditMode, setInEditMode] = useState(false);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        }
    }, []) // eslint-disable-line

    const handleClose = (event) => {
        if (fn.isFunction(props.onClose)) {
            props.onClose(event);
        }
        setInEditMode(false);
    }

    const renderTitle = () => {
        return <div className='popup-title'>
            <div className='actions left-actions pt-1'>
                <ul className='ml-2'>
                    <li>
                        <h4 className='mb-0'>
                            Address Book
                        </h4>
                    </li>
                    <li>
                        <div className='ml-4'>
                            <div className='custom-control custom-switch'>
                                <input
                                    id='address-book-update-switch'
                                    type='checkbox'
                                    name='address-book-update-switch'
                                    className='custom-control-input'
                                    checked={inEditMode}
                                    onChange={handleEditModeChange}
                                />
                                <label
                                    htmlFor='address-book-update-switch'
                                    className='custom-control-label'
                                >
                                    {
                                        inEditMode ?
                                            <small className='text-danger'>Exit update mode</small> :
                                            <small>Enter update mode</small>
                                    }
                                </label>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div className='actions right-actions'>
                <ul>
                    {inEditMode ? renderAddButton() : null}
                    <li>
                        <button
                            type='button'
                            className='btn btn-icon btn-close'
                            onClick={handleClose}
                        >
                            <i className='close-icon fal fa-times fs-xl'></i>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    }

    const handleSelectionChanged = (rowInfo) => {
        if (inEditMode) {
            // const currentUser = JSON.parse(window.localStorage.getItem(StorageKeys.CURRENT_USER));

            if (rowInfo.selectedRowsData[0] && rowInfo.selectedRowsData[0].type) {
                switch (rowInfo.selectedRowsData[0].type) {
                    case 'ExternalClinic':
                        externalClinicView.initialize(rowInfo.selectedRowsData[0].id);
                        quickDrawer.activateQuickDrawer('externalClinic', 'update', null, handleExternalClinicUpdateSuccess, handleExternalClinicUpdateCancel);
                        break;

                    case 'ExternalDoctor':
                        externalDoctorView.initialize(rowInfo.selectedRowsData[0].id);
                        quickDrawer.activateQuickDrawer('externalDoctor', 'update', null, handleExternalDoctorUpdateSuccess, handleExternalDoctorUpdateCancel);
                        break;

                    case 'PrivateInsurance':
                        privateInsuranceView.initialize(rowInfo.selectedRowsData[0].id);
                        quickDrawer.activateQuickDrawer('privateInsurance', 'update', null, handlePrivateInsuranceUpdateSuccess, handlePrivateInsuranceUpdateCancel);
                        break;

                    case 'ProductSupplier':
                        api.ProductSuppliers.get(rowInfo.selectedRowsData[0].id)
                            .then(({ data }) => {
                                productSupplier.load(data.name)
                                    .then(() => {
                                        quickDrawer.activateQuickDrawer('productSupplier', 'create', { isProductSupplierChecked: true }, handleProductSupplierUpdateSuccess, handleProductSupplierUpdateCancel);
                                    })
                            })
                        break;

                    case 'Contact':
                        contactView.initialize(rowInfo.selectedRowsData[0].id);
                        quickDrawer.activateQuickDrawer('contact', 'update', null, handleContactUpdateSuccess, handleContactUpdateCancel);
                        break;

                    case 'User':
                        // if (rowInfo.selectedRowsData[0].id === currentUser.id) {
                        //     alert('yay');
                        // }
                        // else {
                        //     alert('nah');
                        // }
                        break;

                    default:
                        break;
                }
            }
        }
        else if (fn.isFunction(props.onSelected)) {
            props.onSelected(rowInfo);
        }
    }

    const handleCellPrepared = (cellInfo) => {
        if (cellInfo.rowType === 'data') {
            if (inEditMode) {
                // const currentUser = JSON.parse(window.localStorage.getItem(StorageKeys.CURRENT_USER));

                switch (cellInfo.data.type) {
                    case 'ExternalClinic':
                    case 'ExternalDoctor':
                    case 'PrivateInsurance':
                    case 'ProductSupplier':
                    case 'Contact':
                        cellInfo.cellElement.classList.add('cursor-pointer');
                        break;

                    case 'User':
                        cellInfo.cellElement.classList.add('cursor-not-allowed');
                        // if (cellInfo.data.id === currentUser.id) {
                        //     cellInfo.cellElement.classList.add('cursor-pointer');
                        // }
                        // else {
                        //     cellInfo.cellElement.classList.add('cursor-not-allowed');
                        // }
                        break;

                    default:
                        cellInfo.cellElement.classList.add('cursor-not-allowed');
                        break;
                }
            }
            else if (fn.isFunction(props.onSelected)) {
                cellInfo.cellElement.classList.add('cursor-pointer');
            }
        }
    }

    const handlePrivateInsuranceAdd = (event) => {
        privateInsuranceNew.initialize();
        quickDrawer.activateQuickDrawer('privateInsurance', 'create', null, handlePrivateInsuranceAddSuccess, handlePrivateInsuranceAddCancel);
    }

    const handlePrivateInsuranceAddSuccess = (result) => {
        if (result && result.updated) {
            toast.dark(() => ADDRESS_BOOK_UPDATED_MESSAGE);
            dataGridRef.current.instance.deselectAll();
            dataGridRef.current.instance.refresh();
        }
        privateInsuranceNew.clear();
    }

    const handlePrivateInsuranceAddCancel = (event) => {
        dataGridRef.current.instance.deselectAll();
        privateInsuranceNew.clear();
    }

    const handlePrivateInsuranceUpdateSuccess = (result) => {
        if (result && result.updated) {
            toast.dark(() => ADDRESS_BOOK_UPDATED_MESSAGE);
            dataGridRef.current.instance.deselectAll();
            dataGridRef.current.instance.refresh();
        }
        privateInsuranceView.clear();
    }

    const handlePrivateInsuranceUpdateCancel = (event) => {
        dataGridRef.current.instance.deselectAll();
        privateInsuranceView.clear();
    }

    const handleExternalClinicAdd = (event) => {
        externalClinicNew.initialize();
        quickDrawer.activateQuickDrawer('externalClinic', 'create', null, handleExternalClinicAddSuccess, handleExternalClinicAddCancel);
    }

    const handleExternalClinicAddSuccess = (result) => {
        if (result && result.updated) {
            toast.dark(() => ADDRESS_BOOK_UPDATED_MESSAGE);
            dataGridRef.current.instance.deselectAll();
            dataGridRef.current.instance.refresh();
        }
        externalClinicNew.clear();
    }

    const handleExternalClinicAddCancel = (event) => {
        dataGridRef.current.instance.deselectAll();
        externalClinicNew.clear();
    }

    const handleExternalClinicUpdateSuccess = (result) => {
        if (result && result.updated) {
            toast.dark(() => ADDRESS_BOOK_UPDATED_MESSAGE);
            dataGridRef.current.instance.deselectAll();
            dataGridRef.current.instance.refresh();
        }
        externalClinicView.clear();
    }

    const handleExternalClinicUpdateCancel = (event) => {
        dataGridRef.current.instance.deselectAll();
        externalClinicView.clear();
    }

    const handleExternalDoctorAdd = (event) => {
        externalDoctorNew.initialize();
        quickDrawer.activateQuickDrawer('externalDoctor', 'create', null, handleExternalDoctorAddSuccess, handleExternalDoctorAddCancel);
    }

    const handleExternalDoctorAddSuccess = (result) => {
        if (result && result.updated) {
            toast.dark(() => ADDRESS_BOOK_UPDATED_MESSAGE);
            dataGridRef.current.instance.deselectAll();
            dataGridRef.current.instance.refresh();
        }
        externalDoctorNew.clear();
    }

    const handleExternalDoctorAddCancel = (event) => {
        dataGridRef.current.instance.deselectAll();
        externalDoctorNew.clear();
    }

    const handleExternalDoctorUpdateSuccess = (result) => {
        if (result && result.updated) {
            toast.dark(() => ADDRESS_BOOK_UPDATED_MESSAGE);
            dataGridRef.current.instance.deselectAll();
            dataGridRef.current.instance.refresh();
        }
        externalDoctorView.clear();
    }

    const handleExternalDoctorUpdateCancel = (event) => {
        dataGridRef.current.instance.deselectAll();
        externalClinicView.clear();
    }

    const handleProductSupplierAdd = (event) => {
        productSupplier.initialize(true);
        quickDrawer.activateQuickDrawer('productSupplier', 'create', null, handleProductSupplierAddSuccess, handleProductSupplierAddCancel);
    }

    const handleProductSupplierAddSuccess = (result) => {
        if (result && result.updated) {
            toast.dark(() => ADDRESS_BOOK_UPDATED_MESSAGE);
            dataGridRef.current.instance.deselectAll();
            dataGridRef.current.instance.refresh();
        }
        productSupplier.clear();
    }

    const handleProductSupplierAddCancel = (event) => {
        dataGridRef.current.instance.deselectAll();
        productSupplier.clear();
    }

    const handleProductSupplierUpdateSuccess = (result) => {
        if (result && result.updated) {
            toast.dark(() => ADDRESS_BOOK_UPDATED_MESSAGE);
            dataGridRef.current.instance.deselectAll();
            dataGridRef.current.instance.refresh();
        }
        productSupplier.clear();
    }

    const handleProductSupplierUpdateCancel = (event) => {
        dataGridRef.current.instance.deselectAll();
        productSupplier.clear();
    }

    const handleContactAdd = (event) => {
        contactNew.initialize();
        quickDrawer.activateQuickDrawer('contact', 'create', null, handleContactAddSuccess, handleContactAddCancel);
    }

    const handleContactAddSuccess = (result) => {
        if (result && result.updated) {
            toast.dark(() => ADDRESS_BOOK_UPDATED_MESSAGE);
            dataGridRef.current.instance.deselectAll();
            dataGridRef.current.instance.refresh();
        }
        contactNew.clear();
    }

    const handleContactAddCancel = (event) => {
        dataGridRef.current.instance.deselectAll();
        contactNew.clear();
    }

    const handleContactUpdateSuccess = (result) => {
        if (result && result.updated) {
            toast.dark(() => ADDRESS_BOOK_UPDATED_MESSAGE);
            dataGridRef.current.instance.deselectAll();
            dataGridRef.current.instance.refresh();
        }
        contactView.clear();
    }

    const handleContactUpdateCancel = (event) => {
        dataGridRef.current.instance.deselectAll();
        contactView.clear();
    }

    const renderAddButton = () => {
        switch (type) {
            case 'ExternalClinic':
                return <li>
                    <button
                        type='button'
                        className='btn btn-info'
                        onClick={handleExternalClinicAdd}
                    >
                        Add Clinic
                    </button>
                </li>;

            case 'ExternalDoctor':
                return <li>
                    <button
                        type='button'
                        className='btn btn-info'
                        onClick={handleExternalDoctorAdd}
                    >
                        Add Doctor
                    </button>
                </li>;

            case 'PrivateInsurance':
                return <li>
                    <button
                        type='button'
                        className='btn btn-info'
                        onClick={handlePrivateInsuranceAdd}
                    >
                        Add Insurance Company
                    </button>
                </li>;

            case 'ProductSupplier':
                return <li>
                    <button
                        type='button'
                        className='btn btn-info'
                        onClick={handleProductSupplierAdd}
                    >
                        Add Vendor / Supplier
                    </button>
                </li>;

            case 'Contact':
                return <li>
                    <button
                        type='button'
                        className='btn btn-info'
                        onClick={handleContactAdd}
                    >
                        Add Other Contact
                    </button>
                </li>;

            default:
                return;
        }
    }

    const renderContent = () => {
        return <>
            <div className='popup-body'>
                <section>
                    <ul className='nav nav-tabs nav-tabs-clean' role='tablist'>
                        {
                            !props.allowedTypes || props.allowedTypes.length === 0 || props.allowedTypes.some(t => t === 'All') ?
                                <li className='nav-item'>
                                    <button
                                        type='button'
                                        className={'nav-link bg-transparent py-0 px-4 fs-sm' + (type === '' ? ' text-primary font-weight-bold active' : ' fw-400 text-muted')} data-toggle='tab' role='tab'
                                        onClick={() => { handleFilterTypeChange('') }}
                                    >
                                        All
                                    </button>
                                </li> : null
                        }
                        {
                            !props.allowedTypes || props.allowedTypes.length === 0 || props.allowedTypes.some(t => t === 'ExternalClinic') ?
                                <li className='nav-item'>
                                    <button
                                        type='button'
                                        className={'nav-link bg-transparent py-0 px-4 fs-sm' + (type === 'ExternalClinic' ? ' text-primary font-weight-bold active' : ' fw-400 text-muted')} data-toggle='tab' role='tab'
                                        onClick={() => { handleFilterTypeChange('ExternalClinic') }}
                                    >
                                        Clinics
                                    </button>
                                </li> : null
                        }
                        {
                            !props.allowedTypes || props.allowedTypes.length === 0 || props.allowedTypes.some(t => t === 'ExternalDoctor') ?
                                <li className='nav-item'>
                                    <button
                                        type='button'
                                        className={'nav-link bg-transparent py-0 px-4 fs-sm' + (type === 'ExternalDoctor' ? ' text-primary font-weight-bold active' : ' fw-400 text-muted')} data-toggle='tab' role='tab'
                                        onClick={() => { handleFilterTypeChange('ExternalDoctor') }}
                                    >
                                        Doctors
                                    </button>
                                </li> : null
                        }
                        {
                            !props.allowedTypes || props.allowedTypes.length === 0 || props.allowedTypes.some(t => t === 'PrivateInsurance') ?
                                <li className='nav-item'>
                                    <button
                                        type='button'
                                        className={'nav-link bg-transparent py-0 px-4 fs-sm' + (type === 'PrivateInsurance' ? ' text-primary font-weight-bold active' : ' fw-400 text-muted')} data-toggle='tab' role='tab'
                                        onClick={() => { handleFilterTypeChange('PrivateInsurance') }}
                                    >
                                        Insurance Companies
                                    </button>
                                </li> : null
                        }
                        {
                            !props.allowedTypes || props.allowedTypes.length === 0 || props.allowedTypes.some(t => t === 'ProductSupplier') ?
                                <li className='nav-item'>
                                    <button
                                        type='button'
                                        className={'nav-link bg-transparent py-0 px-4 fs-sm' + (type === 'ProductSupplier' ? ' text-primary font-weight-bold active' : ' fw-400 text-muted')} data-toggle='tab' role='tab'
                                        onClick={() => { handleFilterTypeChange('ProductSupplier') }}
                                    >
                                        Vendors / Suppliers
                                    </button>
                                </li> : null
                        }
                        {
                            !props.allowedTypes || props.allowedTypes.length === 0 || props.allowedTypes.some(t => t === 'Tenant') ?
                                <li className='nav-item'>
                                    <button
                                        data-address-book-account-tab
                                        type='button'
                                        className={'nav-link bg-transparent py-0 px-4 fs-sm' + (type === 'Tenant' ? ' text-primary font-weight-bold active' : ' fw-400 text-muted')} data-toggle='tab' role='tab'
                                        onClick={() => { handleFilterTypeChange('Tenant') }}
                                    >
                                        Account
                                    </button>
                                </li> : null
                        }
                        {
                            !props.allowedTypes || props.allowedTypes.length === 0 || props.allowedTypes.some(t => t === 'User') ?
                                <li className='nav-item'>
                                    <button
                                        type='button'
                                        className={'nav-link bg-transparent py-0 px-4 fs-sm' + (type === 'User' ? ' text-primary font-weight-bold active' : ' fw-400 text-muted')} data-toggle='tab' role='tab'
                                        onClick={() => { handleFilterTypeChange('User') }}
                                    >
                                        Staff
                                    </button>
                                </li> : null
                        }
                        {
                            !props.allowedTypes || props.allowedTypes.length === 0 || props.allowedTypes.some(t => t === 'Contact') ?
                                <li className='nav-item'>
                                    <button
                                        type='button'
                                        className={'nav-link bg-transparent py-0 px-4 fs-sm' + (type === 'Contact' ? ' text-primary font-weight-bold active' : ' fw-400 text-muted')} data-toggle='tab' role='tab'
                                        onClick={() => { handleFilterTypeChange('Contact') }}
                                    >
                                        Others
                                    </button>
                                </li> : null
                        }
                    </ul>
                </section>
                <section>
                    <DataGrid
                        ref={dataGridRef}
                        width='100%'
                        height='590px'
                        keyExpr={'id'}
                        dataSource={
                            new CustomStore({
                                key: "id",
                                load: (loadOptions) => {
                                    return handleLoadDataGrid(loadOptions, false, true);
                                },
                                totalCount: (loadOptions) => {
                                    return handleLoadDataGrid(loadOptions, true, false);
                                }
                            })
                        }
                        columnAutoWidth={true}
                        remoteOperations={true}
                        showBorders={false}
                        showRowLines={true}
                        showColumnLines={false}
                        allowChanges={true}
                        showColumnHeaders={true}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        activeStateEnabled={false}
                        focusStateEnabled={false}
                        hoverStateEnabled={true}
                        onSelectionChanged={handleSelectionChanged}
                        onCellPrepared={handleCellPrepared}
                    >
                        <Scrolling mode='standard' />
                        <FilterRow visible={true} />
                        <Paging defaultPageSize={50} />
                        <Pager displayMode={'full'} showInfo={true} infoText={'Page {0} of {1}'} visible={true} showNavigationButtons={true} />
                        <Sorting mode='single' />
                        <Selection mode={getSelectionMode()} />
                        <ColumnFixing enabled={true} />
                        <Column
                            alignment='left'
                            dataField={'SortName'}
                            caption={'Name'}
                            fixed={true}
                            width={350}
                            cellRender={({ data }) => {
                                return !!data.primaryName ?
                                    <>
                                        <strong className='fw-500 text-gray-700' title={data.primaryName}>{data.primaryName}</strong>
                                        {
                                            !!data.secondaryName && data.primaryName.toLowerCase() !== data.secondaryName.toLowerCase() ?
                                                <><br /><small className='text-gray-600' title={data.secondaryName}>{data.secondaryName}</small></> : null
                                        }
                                    </> :
                                    <strong className='fw-500 text-gray-700' title={data.secondaryName}>{data.secondaryName}</strong>
                            }}
                        />
                        <Column
                            alignment='left'
                            dataField={'NoteHtml'}
                            caption={''}
                            fixed={true}
                            width={35}
                            resized={false}
                            cellRender={({ data }) => {
                                return !!data.noteHtml ? <div>
                                    <NoteTooltip noteHtml={data.noteHtml} />
                                </div> : null
                            }}
                        />
                        <Column
                            alignment='left'
                            dataField={'SortAddress'}
                            caption={getDataColumnCaption('SortAddress')}
                            visible={getDataColumnVisible('SortAddress')}
                            width={250}
                            cellRender={({ data }) => {
                                return !!data.address ? <span title={`${data.address.addressLine1} ${data.address.addressLine2}`}>{`${data.address.addressLine1} ${data.address.addressLine2}`}</span> : null
                            }}
                        />
                        <Column
                            alignment='left'
                            dataField={'PrimaryPhoneNumber'}
                            caption={getDataColumnCaption('PrimaryPhoneNumber')}
                            visible={getDataColumnVisible('PrimaryPhoneNumber')}
                            width={125}
                            cellRender={({ data }) => {
                                return !!data.primaryPhoneNumber ? <span title={sys.getFormattedPhoneNumber(data.primaryPhoneNumber)}>{sys.getFormattedPhoneNumber(data.primaryPhoneNumber)}</span> : null
                            }}
                        />
                        <Column
                            alignment='left'
                            dataField={'FaxNumber'}
                            caption={getDataColumnCaption('FaxNumber')}
                            visible={getDataColumnVisible('FaxNumber')}
                            width={125}
                            cellRender={({ data }) => {
                                return !!data.faxNumber ? <span title={sys.getFormattedPhoneNumber(data.faxNumber)}>{sys.getFormattedPhoneNumber(data.faxNumber)}</span> : null
                            }}
                        />
                        <Column
                            alignment='left'
                            dataField={'PrimaryEmailAddress'}
                            caption={getDataColumnCaption('PrimaryEmailAddress')}
                            visible={getDataColumnVisible('PrimaryEmailAddress')}
                            width={175}
                            cellRender={({ data }) => {
                                return !!data.primaryEmailAddress ? <a href={`mailto:${data.primaryEmailAddress.toLowerCase()}`} title={data.primaryEmailAddress.toLowerCase()}>{data.primaryEmailAddress.toLowerCase()}</a> : null
                            }}
                        />
                        <Column
                            alignment='left'
                            dataField={'SecondaryPhoneNumber'}
                            caption={getDataColumnCaption('SecondaryPhoneNumber')}
                            visible={getDataColumnVisible('SecondaryPhoneNumber')}
                            width={125}
                            cellRender={({ data }) => {
                                return !!data.secondaryPhoneNumber ? <span title={sys.getFormattedPhoneNumber(data.secondaryPhoneNumber)}>{sys.getFormattedPhoneNumber(data.secondaryPhoneNumber)}</span> : null
                            }}
                        />
                        <Column
                            alignment='left'
                            dataField={'SecondaryEmailAddress'}
                            caption={getDataColumnCaption('SecondaryEmailAddress')}
                            visible={getDataColumnVisible('SecondaryEmailAddress')}
                            width={175}
                            cellRender={({ data }) => {
                                return !!data.secondaryEmailAddress ? <a href={`mailto:${data.secondaryEmailAddress.toLowerCase()}`} title={data.secondaryEmailAddress.toLowerCase()}>{data.secondaryEmailAddress.toLowerCase()}</a> : null
                            }}
                        />
                        <Column
                            alignment='left'
                            dataField={'WebsiteUrl'}
                            caption={getDataColumnCaption('WebsiteUrl')}
                            visible={getDataColumnVisible('WebsiteUrl')}
                            width={175}
                            cellRender={({ data }) => {
                                return !!data.websiteUrl ? <a href={`${data.websiteUrl.toLowerCase()}`} title={data.websiteUrl.toLowerCase()} target='_blank' rel='noopener noreferrer'>{data.websiteUrl.toLowerCase()}</a> : null
                            }}
                        />
                    </DataGrid>
                </section>
            </div>
        </>
    }

    const handleEditModeChange = () => {
        if (isMounted.current) {
            setInEditMode(!inEditMode);
        }
    }

    const handleFilterTypeChange = (type) => {
        if (isMounted.current) {
            setType(type);
            dataGridRef.current.instance.repaint();
        }
    }

    const handleLoadDataGrid = (loadOptions, includeTotalCount, includeResult) => {
        return new Promise((resolve) => {
            const parameters = !!type ? [{ field: 'Type', value: type }] : [];
            const sortByFields = loadOptions.sort && loadOptions.sort.length > 0 ?
                [{
                    field: loadOptions.sort[0].selector,
                    direction: loadOptions.sort[0].desc ? 'DESC' : 'ASC'
                }] : [{
                    field: 'SortName',
                    direction: 'ASC'
                }];

            if (props.allowedTypes && props.allowedTypes.length > 0 && !parameters.some(p => p.field === 'Type')) {
                parameters.push({ field: 'Type', operator: 'Contains', value: props.allowedTypes.join(',') });
            }

            if (loadOptions && loadOptions.filter && loadOptions.filter.length > 0) {
                const filters = [];

                if (Array.isArray(loadOptions.filter[0])) {
                    for (let fs = 0; fs < loadOptions.filter.length; fs++) {
                        filters.push(loadOptions.filter[fs]);
                    }
                }
                else {
                    filters.push(loadOptions.filter);
                }

                for (let fi = 0; fi < filters.length; fi++) {
                    parameters.push({
                        field: filters[fi][0],
                        operator: 'Contains',
                        value: filters[fi][2],
                    })
                }
            }

            const options = {
                limit: includeResult === true ? loadOptions.take : null,
                offset: includeResult === true ? loadOptions.skip : null,
                parameters: parameters,
                sortByFields: includeResult === true ? sortByFields : null,
                includeTotalCount: includeTotalCount,
                includeResult: includeResult,
            };
            api.AddressBook.search(options)
                .then(({ data }) => {
                    if (includeResult) {
                        resolve(data.result);
                    }
                    else if (includeTotalCount) {
                        resolve(data.total);
                    }
                    else {
                        resolve();
                    }
                })
        })
    }

    const getDataColumnCaption = (field) => {
        switch (field) {
            case 'SortAddress':
                return 'Address';

            case 'PrimaryPhoneNumber':
                switch (type) {
                    case 'ExternalClinic':
                    case 'ExternalDoctor':
                    case 'PrivateInsurance':
                    case 'Tenant':
                        return 'Phone';

                    case 'User':
                        return 'Personal Phone';

                    default:
                        return 'Phone 1';
                }

            case 'FaxNumber':
                return 'Fax';

            case 'PrimaryEmailAddress':
                switch (type) {
                    case 'ExternalClinic':
                    case 'ExternalDoctor':
                    case 'PrivateInsurance':
                    case 'Tenant':
                        return 'Email';

                    case 'User':
                        return 'Personal Email';

                    default:
                        return 'Email 1';
                }

            case 'SecondaryPhoneNumber':
                switch (type) {
                    case 'User':
                        return 'Work Phone';

                    default:
                        return 'Phone 2';
                }

            case 'SecondaryEmailAddress':
                switch (type) {
                    case 'User':
                        return 'Work Email';

                    default:
                        return 'Email 2';
                }

            case 'WebsiteUrl':
                return 'Website';

            default:
                return '';
        }
    }

    const getDataColumnVisible = (field) => {
        switch (field) {
            case 'SortAddress':
                return true;

            case 'PrimaryPhoneNumber':
                return true;

            case 'FaxNumber':
                switch (type) {
                    case 'User':
                        return false;

                    default:
                        return true;
                }
            case 'PrimaryEmailAddress':
                return true;

            case 'SecondaryPhoneNumber':
                switch (type) {
                    case 'ExternalClinic':
                    case 'ExternalDoctor':
                    case 'PrivateInsurance':
                    case 'Tenant':
                        return false;

                    default:
                        return true;
                }

            case 'SecondaryEmailAddress':
                switch (type) {
                    case 'ExternalClinic':
                    case 'ExternalDoctor':
                    case 'PrivateInsurance':
                    case 'Tenant':
                        return false;

                    default:
                        return true;
                }

            case 'WebsiteUrl':
                switch (type) {
                    case 'ExternalClinic':
                    case 'ExternalDoctor':
                    case 'PrivateInsurance':
                    case 'ProductSupplier':
                    case 'Contact':
                        return true;

                    default:
                        return false;
                }

            default:
                return false;
        }
    }

    const getSelectionMode = () => {
        if (inEditMode) {
            return 'single';
        }
        else if (fn.isFunction(props.onSelected)) {
            return props.multiSelect === true ? 'multiple' : 'single'
        }

        return 'none'
    }

    return <>
        <Observer>{() =>
            <>
                {
                    !inEditMode ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleClose(event);
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
                {renderTitle()}
                {renderContent()}
            </>
        }</Observer>
    </>
}

export default AddressBookList;