import { createContext } from 'react';
import { decorate, observable, action, toJS } from 'mobx';

import * as fn from '../utilities/_functions';
import api from '../api';

export class TodoUpdate {
    id = null;
    data = {
        id: null,
        isHighPriority: false,
        due: 'Asap',
        dueValue: null,
        bodyHtml: null,
        dueDateTime: null,
        dueDateTimeUtc: null,
        dueInMinutes: this.defaultDueInMinutes,
        isSingleInstance: true,
        users: [],
        groups: []
    };
    hasUnsavedChanges = false;
    isReady = false;
    isLoading = false;
    isSaving = false;

    cancelTodoGet = null;
    cancelTodoSave = null;

    initialize = async (id) => {
        this.clear();
        this.id = id;

        try {
            this.isLoading = true;
            const { data } = await api.Todos.get(id, (c) => { this.cancelTodoGet = c });
            this.data = data;
        }
        catch (error) {
            throw error;
        }
        finally {
            this.isLoading = true;
            this.isReady = true;
        }
    }

    save = async (notify) => {
        if (!!notify) {
            this.isSaving = true;
        }

        if (this.hasUnsavedChanges) {
            try {
                let option = toJS(this.data);

                await api.Todos.update(option.id, option, (c) => { this.cancelTodoSave = c });
                this.hasUnsavedChanges = false;

                return this.data;
            }
            finally {
                this.isSaving = false;
            }
        }
        this.isSaving = false;
    }

    addUsers = async (userIds, groupIds, notify) => {
        if (!!notify) {
            this.isSaving = true;
        }

        try {
            let option = {
                userIds: userIds,
                groupIds: groupIds
            }

            await api.Todos.addUsers(this.data.id, option, (c) => { this.cancelTodoSave = c });
            return this.data;
        }
        finally {
            this.isSaving = false;
        }
    }

    clear = () => {
        this.id = null;
        this.data = {
            id: null,
            isHighPriority: false,
            due: 'Asap',
            dueValue: null,
            bodyHtml: null,
            dueDateTime: null,
            dueDateTimeUtc: null,
            dueInMinutes: this.defaultDueInMinutes,
            isSingleInstance: true,
            users: [],
            groups: []
        };

        this.hasUnsavedChanges = false;
        this.isReady = false;
        this.isSaving = false;
        this.isLoading = false;

        if (fn.isFunction(this.cancelTodoGet)) {
            this.cancelTodoGet();
            this.cancelTodoGet = null;
        }

        if (fn.isFunction(this.cancelTodoSave)) {
            this.cancelTodoSave();
            this.cancelTodoSave = null;
        }
    }
}

decorate(TodoUpdate, {
    id: observable,
    data: observable.deep,
    hasUnsavedChanges: observable,
    isReady: observable,
    isLoading: observable,
    isSaving: observable,
    initialize: action,
    save: action,
    addUsers: action,
    clear: action,
})

export default createContext(new TodoUpdate());

