import { createContext } from 'react';
import { decorate, observable, action, toJS } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class ExamDatasetView {
    id = null;
    data = null;
    categories = [];
    isLoading = false;
    isSaving = false;
    saveToServer = true;
    hasUnsavedChanges = false;

    cancelExamDatasetGet = null;
    cancelExamDatasetUpdate = null;
    cancelExamDatasetCategories = null;

    load = (id, saveToServer) => {
        const that = this;
        this.clear();
        this.id = id;
        this.isLoading = true;

        if (saveToServer) {
            this.saveToServer = saveToServer;
        }

        return new Promise((resolve, reject) => {
            api.ExamDatasets.get(id, (c) => { that.cancelExamDatasetGet = c })
                .then(({ data }) => {
                    that.data = data;
                    that.refreshCategories()
                        .finally(() => {
                            resolve();
                        })
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isLoading = false;
                })
        })
    }

    loadExisting = (existing) => {
        const that = this;
        
        this.clear();
        this.id = existing.id;
        this.saveToServer = false;
        this.data = existing;

        return new Promise((resolve) => {
            that.refreshCategories()
                .finally(() => {
                    resolve();
                })
        })
    }

    refreshCategories = (notify) => {
        const that = this;

        if (!!notify) {
            this.isLoading = true;
        }

        return new Promise((resolve, reject) => {
            if (that.data) {
                api.ExamDatasetCategories.all(that.data.examDatasetGroupId, (c) => { that.cancelExamDatasetCategories = c })
                    .then(({ data }) => {
                        that.categories = data && data.length > 0 ? data : [];
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isLoading = false;
                    })
            }
            else {
                resolve();
            }
        })
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            const option = toJS(that.data);

            if (that.hasUnsavedChanges) {
                if (that.saveToServer) {
                    api.ExamDatasets.update(that.id, option, (c) => { that.cancelExamDatasetUpdate = c })
                        .then(() => {
                            that.hasUnsavedChanges = false;
                            that.refreshCategories()
                                .finally(() => {
                                    resolve(option);
                                })
                        })
                        .catch(() => {
                            reject();
                        })
                        .finally(() => {
                            that.isSaving = false;
                        })
                } else {
                    resolve(option);
                }
            } else {
                that.hasUnsavedChanges = false;
                that.isSaving = false;
                resolve();
            }
        })
    }

    delete = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            api.ExamDatasets.delete(that.id, null, (c) => { that.cancelExamDatasetUpdate = c })
                .then(() => {
                    that.hasUnsavedChanges = false;

                    that.refreshCategories()
                        .finally(() => {
                            resolve();
                        })
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    clear = () => {
        this.id = null;
        this.data = null;
        this.categories.clear();
        this.hasUnsavedChanges = false;
        this.isLoading = false;
        this.isSaving = false;
        this.saveToServer = true;

        if (fn.isFunction(this.cancelExamDatasetGet)) {
            this.cancelExamDatasetGet();
            this.cancelExamDatasetGet = null;
        }

        if (fn.isFunction(this.cancelExamDatasetUpdate)) {
            this.cancelExamDatasetUpdate();
            this.cancelExamDatasetUpdate = null;
        }

        if (fn.isFunction(this.cancelExamDatasetCategories)) {
            this.cancelExamDatasetCategories();
            this.cancelExamDatasetCategories = null;
        }
    }
}

decorate(ExamDatasetView, {
    id: observable,
    data: observable,
    categories: observable,
    hasUnsavedChanges: observable,
    isLoading: observable,
    isSaving: observable,
    refreshCategories: action,
    load: action,
    save: action,
    delete: action,
    clear: action,
})

export default createContext(new ExamDatasetView());