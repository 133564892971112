import { createContext } from 'react';
import { decorate, reaction, action, computed, observable } from 'mobx';
import moment from 'moment';

import api from '../api';
import * as StorageKeys from '../constants/storageKeys';
import * as fn from '../utilities/_functions';
import * as ph from '../utilities/personHelper';

const IS_READY = '__CACHE_IS_READY__';

export class Cache {
    isLoading = false;
    isReady = window.localStorage.getItem(IS_READY) ? !!JSON.parse(window.localStorage.getItem(IS_READY)) : false;
    cancelReferenceData = null;
    cancelResource = null;
    cancelServices = null;
    cancelTaxRates = null;
    cancelUsers = null;
    isReadyDisposer = null;

    constructor() {
        this.isReadyDisposer = reaction(
            () => this.isReady, (isReady) => {
                window.localStorage.setItem(IS_READY, JSON.stringify(isReady));
            }
        )
    }

    initialize = () => {
        if (!this.isReady) {
            const that = this;

            return new Promise((resolve) => {
                that.isLoading = true;

                Promise.all([
                    api.Resources.all((c) => { that.cancelResource = c }),
                    api.Services.all(false, (c) => { that.cancelServices = c }),
                    api.ReferenceData.all((c) => { that.cancelReferenceData = c }),
                    api.Users.all((c) => { that.cancelUsers = c }),
                    api.TaxRates.get(moment().format('YYYY-MM-DD'), (c) => { that.cancelTaxRates = c }),
                    api.Groups.all((c) => { that.cancelUsers = c }),
                ])
                    .then(result => {
                        window.localStorage.setItem(StorageKeys.RESOURCES, JSON.stringify(result[0].data));
                        window.localStorage.setItem(StorageKeys.SERVICES, JSON.stringify(result[1].data));
                        window.localStorage.setItem(StorageKeys.REFERENCE_DATA, JSON.stringify(result[2].data));
                        window.localStorage.setItem(StorageKeys.TAX_RATES, JSON.stringify(result[4].data));
                        var groupsData = result[5].data.result;
                        groupsData.forEach(grp => {
                            grp.users = grp.users.map(u => ({ "id": u.id, "name": ((u.firstName || '') + ' ' + (u.lastName || '')).trim() }));
                            grp.userNames = grp.users.map(u => u.name); // for datagrid in Groups management
                        });

                        window.localStorage.setItem(StorageKeys.ALL_GROUPS, JSON.stringify(groupsData));

                        if (result[3].data) {
                            for (let u = 0; u < result[3].data.length; u++) {
                                result[3].data[u].displayFullName = ph.getPreferredFirstLastName(result[3].data[u]);
                                result[3].data[u].displayShortName = result[3].data[u].salutation === 'Dr' ? ph.getPreferredFirstLastName(result[3].data[u]) : ph.getPreferredFirstName(result[3].data[u]);

                                for (let x = 0; x < 2; x++) {
                                    if (result[3].data.some(r => r.id !== result[3].data[u].id && `${ph.getPreferredFirstName(r)} ${(r.lastName ? `${r.lastName.substring(0, x)}${x > 0 ? '.' : ''}` : '')}`.trim() === result[3].data[u].displayShortName)) {
                                        result[3].data[u].displayShortName = `${ph.getPreferredFirstName(result[3].data[u])} ${(result[3].data[u].lastName ? `${result[3].data[u].lastName.substring(0, (x + 1))}.` : '')}`.trim();
                                    } else {
                                        break;
                                    }
                                }
                            }
                        }

                        window.localStorage.setItem(StorageKeys.ALL_USERS, JSON.stringify(result[3].data));

                        resolve();
                    })
                    .finally(() => {
                        that.isLoading = false;
                    })
            })
        }

        return Promise.resolve();
    }

    getReferenceDataOptions = (key) => {
        if (this.referenceData && this.referenceData.length > 0 && this.referenceData.some(d => d.name === key)) {
            const { options } = this.referenceData.filter(d => d.name === key)[0];
            return options && options.length > 0 ? options : [];
        }

        return [];
    }

    get resources() {
        const resources = JSON.parse(window.localStorage.getItem(StorageKeys.RESOURCES));
        return resources && resources.length > 0 ? resources : [];
    }

    get services() {
        const services = JSON.parse(window.localStorage.getItem(StorageKeys.SERVICES));
        return services && services.length > 0 ? services : [];
    }

    get referenceData() {
        const referenceData = JSON.parse(window.localStorage.getItem(StorageKeys.REFERENCE_DATA));
        return referenceData && referenceData.length > 0 ? referenceData : [];
    }

    // **** The check for "isLoading" is only meant to trigger a refresh of the getters. 
    // Computed getters won't get refreshed unless it's referencing an observable
    get users() {
        if (!this.isLoading) {
            const users = JSON.parse(window.localStorage.getItem(StorageKeys.ALL_USERS));
            return users && users.length > 0 ? users : [];
        }
        return [];
    }

    get activeUsers() {
        if (!this.isLoading) {
            const activeUsers = this.users && this.users.length > 0 ? this.users.filter(u => !u.isDeactivated) : [];
            return activeUsers && activeUsers.length > 0 ? activeUsers : [];
        }
        return [];
    }

    get taxRates() {
        if (!this.isLoading) {
            const taxRates = JSON.parse(window.localStorage.getItem(StorageKeys.TAX_RATES));
            return taxRates && taxRates.length > 0 ? taxRates : [];
        }
        return [];
    }

    get groups() {
        if (!this.isLoading) {
            const groups = JSON.parse(window.localStorage.getItem(StorageKeys.ALL_GROUPS));
            return groups && groups.length > 0 ? groups : [];
        }
        return [];
    }

    reload = () => {
        const that = this;
        return new Promise((resolve, reject) => {
            that.clear()
                .then(() => {
                    that.initialize()
                        .then(() => {
                            resolve();
                        })
                        .catch(() => {
                            reject();
                        })
                })
                .catch(() => {
                    reject();
                });
        })
    }

    clear = () => {
        window.localStorage.removeItem(IS_READY);
        window.localStorage.removeItem(StorageKeys.RESOURCES);
        window.localStorage.removeItem(StorageKeys.SERVICES);
        window.localStorage.removeItem(StorageKeys.REFERENCE_DATA);
        window.localStorage.removeItem(StorageKeys.ALL_USERS);
        window.localStorage.removeItem(StorageKeys.TAX_RATES);

        if (fn.isFunction(this.cancelReferenceData)) {
            this.cancelReferenceData();
            this.cancelReferenceData = null;
        }

        if (fn.isFunction(this.cancelResource)) {
            this.cancelResource();
            this.cancelResource = null;
        }

        if (fn.isFunction(this.cancelServices)) {
            this.cancelServices();
            this.cancelServices = null;
        }

        if (fn.isFunction(this.cancelTaxRates)) {
            this.cancelTaxRates();
            this.cancelTaxRates = null;
        }

        if (fn.isFunction(this.cancelUsers)) {
            this.cancelUsers();
            this.cancelUsers = null;
        }

        if (fn.isFunction(this.isReadyDisposer)) {
            this.isReadyDisposer();
            this.isReadyDisposer = null;
        }

        return Promise.resolve();
    }
}

decorate(Cache, {
    isReady: observable,
    isLoading: observable,
    initialize: action,
    getReferenceDataOptions: action,
    resources: computed,
    services: computed,
    referenceData: computed,
    users: computed,
    groups: computed,
    activeUsers: computed,
    taxRates: computed,
})

export default createContext(new Cache());
