import React from 'react';
import { toast } from 'react-toastify';

import * as ErrorMessages from '../constants/errorMessages';

export const isEndState = exam => {
    return exam && ['Completed'].some(s => s === exam.status);
}

export const displayStartExamError = (error) => {
    if (error && error.response && error.response.status) {
        switch (error.response.status.toString()) {
            case '401':
                toast.error(() => <p><strong>Cannot start someone else's exam</strong>.</p>, { position: 'top-center', style: { width: '380px' } });
                return;

            case '404':
                toast.error(() => <p><strong>Cannot start exam</strong>.  Exam template not found.</p>, { position: 'top-center', style: { width: '380px' } });
                return;

            default:
                break;
        }
    }

    toast.error(() => ErrorMessages.GENERIC_FORM_ERROR_HTML, { position: 'top-center', style: { width: '380px' } });
}

export const getDefaultService = (services) => {
    if (!services || services.length === 0) return null;

    return services.slice().sort((a, b) => {
        if (a.priority > b.priority) {
            return -1;
        }
        else if (a.priority < b.priority) {
            return 1;
        }
        else {
            if (a.examTemplateId && !b.examTemplateId) {
                return -1;
            }
            else if (!a.examTemplateId && b.examTemplateId) {
                return 1;
            }
            else {
                return a.code.localeCompare(b.code);
            }
        }
    })[0];
}

export const getDefaultExamTemplateId = (services) => {
    if (!services || services.length === 0) return null;

    const defaultService = getDefaultService(services);
    let examTemplateId = null;

    if (defaultService.examTemplateId) {
        examTemplateId = defaultService.examTemplateId;
    }
    else if (services.slice().some(s => s.examTemplateId)) {
        examTemplateId = services.slice().filter(s => s.examTemplateId).sort((a, b) => {
            if (a.priority > b.priority) {
                return -1;
            }
            else if (a.priority < b.priority) {
                return 1;
            }
            else {
                return a.code.localeCompare(b.code);
            }
        })[0].examTemplateId;
    }

    return examTemplateId;
}