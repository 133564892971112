import { createContext } from 'react';
import { decorate, observable, action, toJS, computed } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class WorkOrderUpdate {
    id = null;
    data = null;
    purchase = null;
    templates = null;
    updatedStatus = null;
    updatedReason = null;
    updatedReasonHtml = null;
    deleteReason = null;
    deleteReasonHtml = null;
    hasUnsavedChanges = false;
    isLoading = false;
    isSaving = false;
    isReady = false;

    cancelWorkOrderGet = null;
    cancelWorkOrderStatus = null;
    cancelWorkOrderUnlink = null;
    cancelWorkOrderUpdate = null;
    cancelWorkOrderDelete = null;
    cancelWorkOrderRedo = null;
    cancelPurchaseGet = null;

    initialize = id => {
        if (id === this.id) return Promise.resolve();

        const that = this;
        this.clear();
        this.id = id;

        return new Promise((resolve, reject) => {
            api.WorkOrderPublishedTemplates.all((c) => { that.cancelWorkOrderPublishedTemplate = c })
                .then(({ data }) => {
                    that.templates = data;
                    that.refresh()
                        .then(() => {
                            resolve();
                        })
                        .catch((error) => {
                            reject(error);
                        })
                        .finally(() => {
                            that.isReady = true;
                        })
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isReady = true;
                })
        })
    }

    refresh = () => {
        const that = this;

        this.isLoading = true;

        return new Promise((resolve, reject) => {
            api.WorkOrders.get(that.id, (c) => { that.cancelWorkOrderGet = c })
                .then(({ data: workOrderData }) => {
                    action(() => {
                        if (workOrderData.purchase) {
                            api.Purchases.get(workOrderData.purchase.id, (c) => { that.cancelPurchaseGet = c })
                                .then(({ data: purchaseData }) => {
                                    if (that.data && that.data.items) {
                                        const workItems = toJS(workOrderData.items);

                                        for (let ii = 0; ii < workItems.length; ii++) {
                                            const existingItemData = that.data.items.some(i => i.id === workItems[ii].id) ? that.data.items.filter(i => i.id === workItems[ii].id)[0].workOrderData : [];
                                            const newItemData = toJS(workItems[ii].workOrderData);

                                            for (let ni = 0; ni < newItemData.length; ni++) {
                                                if (!existingItemData.some(ed => ed.id === newItemData[ni].id)) {
                                                    existingItemData.push(newItemData[ni]);
                                                }
                                                else if (existingItemData.some(ed => ed.id === newItemData[ni].id && !ed.value)) {
                                                    const existingIndex = existingItemData.findIndex(ed => ed.id === newItemData[ni].id);
                                                    existingItemData[existingIndex].value = newItemData[ni].value;
                                                }
                                            }

                                            workItems[ii].workOrderData = existingItemData;
                                        }

                                        workOrderData.items = workItems;
                                    }

                                    that.data = workOrderData;
                                    that.purchase = purchaseData;
                                    that.isLoading = false;

                                    resolve();
                                })
                                .catch(() => {
                                    that.isLoading = false;
                                    reject();
                                })
                        }
                        else {
                            that.data = workOrderData;
                            that.isLoading = false;

                            resolve();
                        }
                    })();
                })
                .catch(() => {
                    that.isLoading = false;
                    reject();
                })
        })
    }

    status = () => {
        const that = this;

        that.isSaving = true;

        return new Promise((resolve, reject) => {
            const options = {
                status: that.updatedStatus,
                note: that.updatedReason,
                noteHtml: that.updatedReasonHtml
            };

            api.WorkOrders.status(that.id, options, (c) => { that.cancelWorkOrderStatus = c })
                .then(() => {
                    resolve(options);
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    unlink = (id) => {
        const that = this;

        that.isSaving = true;

        return new Promise((resolve, reject) => {
            api.WorkOrders.unlink(that.id, { id: id }, (c) => { that.cancelWorkOrderUnlink = c })
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    delete = () => {
        const that = this;

        that.isSaving = true;

        return new Promise((resolve, reject) => {
            api.WorkOrders.delete(that.id, { reason: that.deleteReason, reasonHtml: that.deleteReasonHtml }, (c) => { that.cancelWorkOrderDelete = c })
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    redo = () => {
        const that = this;

        that.isSaving = true;

        return new Promise((resolve, reject) => {
            api.WorkOrders.redo(that.id, (c) => { that.cancelWorkOrderRedo = c })
                .then(({ data }) => {
                    if (data && data.id) {
                        api.WorkOrders.link(that.id, {
                            selectedIds: [data.id],
                            reason: 'Redo',
                            reasonHtml: '<p>Redo</p>',
                        }, (c) => { that.cancelWorkOrderRedo = c })
                            .then(() => {
                                resolve(data.id);
                            })
                            .catch(() => {
                                reject();
                            })
                            .finally(() => {
                                that.isSaving = false;
                            })
                    }
                    else {
                        reject();
                        that.isSaving = false;
                    }
                })
                .catch(() => {
                    reject();
                    that.isSaving = false;
                })
        })
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            if (that.hasUnsavedChanges) {
                api.WorkOrders.update(
                    that.data.id,
                    {
                        isHighPriority: that.data.isHighPriority,
                        items: that.data.items,
                    },
                    (c) => { that.cancelWorkOrderUpdate = c }
                )
                    .then(() => {
                        that.hasUnsavedChanges = false;
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                that.hasUnsavedChanges = false;
                that.isSaving = false;
                resolve();
            }
        })
    }

    clearUpdatedStatus = () => {
        this.updatedStatus = null;
        this.updatedReason = null;
        this.updatedReasonHtml = null;
    }

    clear = () => {
        this.id = null;
        this.data = null;
        this.purchase = null;
        this.templates = null;
        this.hasUnsavedChanges = false;
        this.isLoading = false;
        this.isSaving = false;
        this.isReady = false;

        if (fn.isFunction(this.cancelWorkOrderGet)) {
            this.cancelWorkOrderGet();
            this.cancelWorkOrderGet = null;
        }

        if (fn.isFunction(this.cancelWorkOrderStatus)) {
            this.cancelWorkOrderStatus();
            this.cancelWorkOrderStatus = null;
        }

        if (fn.isFunction(this.cancelWorkOrderUnlink)) {
            this.cancelWorkOrderUnlink();
            this.cancelWorkOrderUnlink = null;
        }

        if (fn.isFunction(this.cancelWorkOrderUpdate)) {
            this.cancelWorkOrderUpdate();
            this.cancelWorkOrderUpdate = null;
        }

        if (fn.isFunction(this.cancelWorkOrderDelete)) {
            this.cancelWorkOrderDelete();
            this.cancelWorkOrderDelete = null;
        }

        if (fn.isFunction(this.cancelWorkOrderRedo)) {
            this.cancelWorkOrderRedo();
            this.cancelWorkOrderRedo = null;
        }

        if (fn.isFunction(this.cancelPurchaseGet)) {
            this.cancelPurchaseGet();
            this.cancelPurchaseGet = null;
        }
    }

    get isNotFound() {
        return this.isReady && !this.data;
    }
}

decorate(WorkOrderUpdate, {
    id: observable,
    data: observable,
    purchase: observable,
    templates: observable,
    updatedStatus: observable,
    updatedReason: observable,
    updatedReasonHtml: observable,
    deleteReason: observable,
    deleteReasonHtml: observable,
    hasUnsavedChanges: observable,
    isLoading: observable,
    isSaving: observable,
    isReady: observable,
    initialize: action,
    unlink: action,
    delete: action,
    redo: action,
    save: action,
    clear: action,
    clearUpdatedStatus: action,
    isNotFound: computed,
})

export default createContext(new WorkOrderUpdate());