import { createContext } from 'react';
 import { decorate, observable, action, toJS } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class ExamTemplateClone {
    id = null;
    data = {
        name: null,
        descriptionHtml: null,
    };
    isReady = false;
    isSaving = false;
    isLoading = false;
    hasUnsavedChanges = false;

    cancelExamTemplateGet = null;
    cancelExamTemplateClone = null;

    initialize = (id) => {
        const that = this;

        this.clear();
        this.id = id;
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            api.ExamTemplates.get(that.id, (c) => { that.cancelExamTemplateGet = c })
                .then(({ data }) => {
                    if (data) {
                        that.data.name = data.name ? `Copy of ${data.name}` : '';
                        that.data.descriptionHtml = data.descriptionHtml;
                    }
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isLoading = false;
                    that.isReady = true;
                })
        })
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            let option = toJS(that.data);

            api.ExamTemplates.clone(that.id, option, (c) => { that.cancelExamTemplateClone = c })
                .then(({ data }) => {
                    that.id = data.id;
                    that.hasUnsavedChanges = false;
                    option.id = data.id;
                    resolve(option);
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    clear = () => {
        this.id = null;
        this.data.name = null;
        this.data.descriptionHtml = null;
        this.hasUnsavedChanges = false;

        if (fn.isFunction(this.cancelExamTemplateGet)) {
            this.cancelExamTemplateGet();
            this.cancelExamTemplateGet = null;
        }

        if (fn.isFunction(this.cancelExamTemplateClone)) {
            this.cancelExamTemplateClone();
            this.cancelExamTemplateClone = null;
        }
    }
}

decorate(ExamTemplateClone, {
    id: observable,
    data: observable.deep,
    hasUnsavedChanges: observable,
    isReady: observable,
    isSaving: observable,
    initialize: action,
    save: action,
    clear: action,
})

export default createContext(new ExamTemplateClone());