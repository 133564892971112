import React, { useEffect, useContext, useRef, useState } from 'react';
import FadeIn from 'react-fade-in';
import { Observer } from 'mobx-react-lite';
import { CheckBox } from 'devextreme-react/check-box';
import DataGrid, { Column, Grouping, GroupPanel, Selection } from 'devextreme-react/data-grid';
import { GlobalHotKeys } from 'react-hotkeys';

import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as oh from '../../../../utilities/operationHelper';

import api from '../../../../api';

import './AddExamData.scss';

function AddReferralExamData(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const dataSetRef = useRef(null);
    const allDatasets = useRef([]);
    const hasUnsavedChanges = useRef(false);
    const quickDrawer = useContext(QuickDrawerStore);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        if (isMounted.current) {
            if (props && props.extraProps) {
                const { examPublishedTemplateId, examDatasetIds } = props.extraProps;

                api.ExamDatasetGroups.search({
                    parameters: [{
                        field: 'ExamPublishedTemplateId',
                        value: examPublishedTemplateId,
                    }],
                    includeTotalCount: false,
                    loadProperties: true,
                    limit: 1
                })
                    .then(({ data }) => {
                        if (isMounted.current) {
                            const datasets = data && data.result && data.result.length > 0 ? data.result[0].examDatasets : [];
                            if (examDatasetIds && examDatasetIds.length > 0) {
                                allDatasets.current = examDatasetIds.map(di => { return datasets.filter(da => di === da.id)[0] }).filter(di => !!di);
                            }
                            else {
                                allDatasets.current = datasets;
                            }
                        }
                    })
                    .finally(() => {
                        if (isMounted.current) {
                            setIsReady(true);
                        }
                    })
            }
        }
        return () => {
            isMounted.current = false;
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (hasUnsavedChanges.current) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (props.onSuccess && fn.isFunction(props.onSuccess)) {
            const selectedKeys = dataSetRef.current.instance.getSelectedRowKeys();
            const selectedDatasets = selectedKeys && selectedKeys.length > 0 ? allDatasets.current.filter(a => selectedKeys.some(id => id === a.id)) : [];
            const sortedSelectedDatasets = selectedDatasets.sort((a, b) => {
                if (a.category === b.category) {
                    return b.label - a.label;
                }
                return a.category > b.category ? 1 : -1;
            })
            const data = {
                items: sortedSelectedDatasets,
            }
            props.onSuccess(event, { updated: true, data: data });
        }
    }

    const handleSelectAllChange = (element) => {
        if (element.value === true) {
            const keys = allDatasets.current.map(d => { return d.id });
            dataSetRef.current.instance.selectRows(keys);
        }
        else {
            dataSetRef.current.instance.selectRows([]);
        }
    }

    const handleCellClick = (e) => {
        if (e.rowType === 'group' && e.columnIndex !== 0) {
            const key = e.component.getKeyByRowIndex(e.rowIndex);
            const expanded = e.component.isRowExpanded(key);

            if (expanded) {
                e.component.collapseRow(key);
            }
            else {
                e.component.expandRow(key);
            }
        }
        else if (e.rowType === 'data' && e.columnIndex !== 0) {
            const selectedKeys = dataSetRef.current.instance.getSelectedRowKeys();

            if (selectedKeys.some(k => k === e.data.id)) {
                dataSetRef.current.instance.selectRows((selectedKeys.filter(k => k !== e.data.id)));
            }
            else {
                dataSetRef.current.instance.selectRows([...selectedKeys, ...[e.data.id]]);
            }
        }
    }

    const handleCellPrepared = (cellInfo) => {
        if (cellInfo.rowType === 'group') {
            cellInfo.cellElement.classList.add('cursor-pointer');
        }
    }

    const handleSelectedRowKeysChange = () => {
        const categories = allDatasets.current.map(d => { return d.category }).filter((v, i, a) => a.indexOf(v) === i);

        if (categories && categories.length > 0) {
            for (let ci = 0; ci < categories.length; ci++) {
                const groupElement = document.querySelector(`[data-category="${categories[ci]}"]`);
                if (groupElement) {
                    groupElement.innerHTML = getSelectedCount(categories[ci]);
                }
            }
        }

        hasUnsavedChanges.current = true;
    }

    const handleContentReady = ({ element }) => {
        const cols = element.querySelectorAll('.dx-datagrid colgroup col');
        const groupRows = element.querySelectorAll('.dx-datagrid .dx-group-row');
        const dataRows = element.querySelectorAll('.dx-datagrid .dx-data-row');

        for (let gi = 0; gi < groupRows.length; gi++) {
            groupRows[gi].deleteCell(0);
        }

        for (let di = 0; di < dataRows.length; di++) {
            dataRows[di].deleteCell(1);
        }

        cols[0].style.width = '30px';
        cols[1].remove();

        if (props && props.extraProps && props.extraProps.selectedDatasets && props.extraProps.selectedDatasets.length > 0) {
            const keys = props.extraProps.selectedDatasets.map(d => { return d.id });
            dataSetRef.current.instance.selectRows(keys);
        }
    }

    const renderSelectedCounts = (group) => {
        return <small>(<span data-category={`${group.key}`}>{getSelectedCount(group.key)}</span>{` / ${allDatasets.current.filter(d => d.category === group.key).length}`})</small>
    }

    const getSelectedCount = (category) => {
        if (dataSetRef.current && dataSetRef.current.instance) {
            const selectedKeys = dataSetRef.current.instance.getSelectedRowKeys();
            const selectedCategoryKeys = allDatasets.current.filter(d => d.category === category && selectedKeys.some(s => s === d.id));

            if (selectedCategoryKeys && selectedCategoryKeys.length > 0) {
                return selectedCategoryKeys.length;
            }
        }

        return 0;
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <fieldset>
                <div className='add-exam-data-container quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('exam-dataset', 'default')}
                        action='Add'
                        category='Exam Data'
                        className='exam'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        <Observer>{() =>
                            <>
                                {
                                    isReady ?
                                        <FadeIn>
                                            <div className='body-content'>
                                                {
                                                    allDatasets.current && allDatasets.current.length > 0 ?
                                                        <section
                                                            className='m-0'
                                                        >
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='form-group mb-2'>
                                                                        <CheckBox
                                                                            defaultValue={false}
                                                                            text='Select all'
                                                                            onValueChanged={handleSelectAllChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    {
                                                                        allDatasets.current.length > 0 ?
                                                                            <DataGrid
                                                                                ref={dataSetRef}
                                                                                keyExpr={'id'}
                                                                                dataSource={allDatasets.current}
                                                                                activeStateEnabled={false}
                                                                                focusStateEnabled={false}
                                                                                hoverStateEnabled={false}
                                                                                showBorders={true}
                                                                                showRowLines={true}
                                                                                showColumnHeaders={false}
                                                                                allowColumnReordering={false}
                                                                                allowColumnResizing={false}
                                                                                sorting={{ mode: 'none' }}
                                                                                paging={{ enabled: false }}
                                                                                onCellClick={handleCellClick}
                                                                                onCellPrepared={handleCellPrepared}
                                                                                onSelectedRowKeysChange={handleSelectedRowKeysChange}
                                                                                onContentReady={handleContentReady}
                                                                            >
                                                                                <GroupPanel visible={true} />
                                                                                <Grouping autoExpandAll={false} />
                                                                                <Selection
                                                                                    mode='multiple'
                                                                                    selectAllMode='allPages'
                                                                                    showCheckBoxesMode='always'
                                                                                />
                                                                                <Column
                                                                                    dataField={'category'}
                                                                                    dataType='string'
                                                                                    groupIndex={0}
                                                                                    groupCellRender={({ data }) => {
                                                                                        return <div className='d-flex'>
                                                                                            <div className='flex-1'>{data.key}</div>
                                                                                            <div className='px-3'>{renderSelectedCounts(data)}</div>
                                                                                        </div>
                                                                                    }} />
                                                                                <Column
                                                                                    dataField={'label'}
                                                                                    alignment='left'
                                                                                    width={'100%'}
                                                                                    activeStateEnabled={false}
                                                                                    focusStateEnabled={false}
                                                                                    hoverStateEnabled={false}
                                                                                />
                                                                            </DataGrid> : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </section> : null
                                                }
                                            </div>
                                        </FadeIn> :
                                        <FadeIn>
                                            <div className='body-content'>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='text-muted'>No exam dataset found.</div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </FadeIn>
                                }
                            </>
                        }</Observer>
                    </div>
                    <div className='quick-drawer-action pl-3'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <Observer>{() =>
                                        <button
                                            type='submit'
                                            className='btn btn-success'
                                        >Update</button>
                                    }</Observer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>
}

export default AddReferralExamData;