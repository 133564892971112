import { CURRENT_TENANT, CURRENT_USER } from '../constants/storageKeys';
import * as Roles from '../constants/roles';

export const getCurrentUser = () => {
    return JSON.parse(window.localStorage.getItem(CURRENT_USER));
}

export const getCurrentTenant = () => {
    return JSON.parse(window.localStorage.getItem(CURRENT_TENANT));
}

export const check = (access) => {
    if (!access) return false;
    const currentUser = getCurrentUser();
    if (currentUser && currentUser.accesses.length === 0) throw new Error('Invalid user');

    return currentUser && currentUser.accesses.some(a => a === access);
}

export const checkAll = (accesses) => {
    if (!accesses || accesses.length === 0) return false;
    const currentUser = getCurrentUser();
    if (currentUser && currentUser.accesses.length === 0) throw new Error('Invalid user');
    const checkAccesses = Array.isArray(accesses) ? accesses : [accesses];
    return currentUser && currentUser.accesses.filter(a => checkAccesses.some(c => c === a)).length === checkAccesses.length;
}

export const checkAny = (accesses) => {
    if (!accesses || accesses.length === 0) return false;
    const currentUser = getCurrentUser();
    if (currentUser && currentUser.accesses.length === 0) throw new Error('Invalid user');
    const checkAccesses = Array.isArray(accesses) ? accesses : [accesses];
    return currentUser && currentUser.accesses.some(a => checkAccesses.some(c => c === a));
}

export const sameUser = (id) => {
    if (!id) return false;
    const currentUser = getCurrentUser();
    return (currentUser && currentUser.id === id);
}

export const hasFullAccess = (id) => {
    if (!id) return false;
    return isOwner(id) || isOrganizationAdmin(id) || isTenantAdmin(id) || isSuperAdmin(id);
}

export const isOwner = (id) => {
    if (!id) return false;
    const currentUser = getCurrentUser();
    return currentUser.roles.some(r => r.isSystemRole && r.name === Roles.ORGANIZATION_OWNER);
}

export const isSuperAdmin = (id) => {
    if (!id) return false;
    const currentUser = getCurrentUser();
    return currentUser.roles.some(r => r.isSystemRole && r.name === Roles.SUPER_ADMINISTRATOR);
}

export const isOrganizationAdmin = (id) => {
    if (!id) return false;
    const currentUser = getCurrentUser();
    return currentUser.roles.some(r => r.name === Roles.ORGANIZATION_ADMINISTRATOR);
}

export const isTenantAdmin = (id) => {
    if (!id) return false;
    const currentUser = getCurrentUser();
    const currentTenant = getCurrentTenant();
    return currentUser.roles.some(r => r.name === Roles.TENANT_ADMINISTRATOR && r.organizationId === currentTenant.organizationId && r.tenantId === currentTenant.id);
}