import { createContext } from 'react';
import { decorate, observable, action, computed } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

const PAGE_SIZE = 25;

export class TodoInstanceSearchStore {
    page = 1;
    items = [];
    total = null;
    userId = null;
    statuses = [];
    displayToUserTypes = [];
    sortField = null;
    sortDirection = 'ASC';
    isLoading = false;
    isSaving = false;
    isReady = false;

    cancelTodoInstanceSearch = null;
    cancelTodoInstanceStatus = null;

    initialize = async (
        userId,
        displayToUserTypes = ['Assignee', 'Creator'],
        statuses = ['Pending', 'InProgress', 'Reopened'],
        sortField = 'SortByDateUtc',
        sortDirection = 'ASC'
    ) => {
        this.clear();

        this.items.clear();
        this.total = null;
        this.userId = userId;

        if (displayToUserTypes) {
            this.displayToUserTypes = displayToUserTypes;
        }

        if (statuses) {
            this.statuses = statuses;
        }

        if (sortField) {
            this.sortField = sortField;
        }

        if (sortDirection) {
            this.sortDirection = sortDirection
        }

        await this.refresh();
        this.isReady = true;
    }

    execute = async (page, notify = true) => {
        const that = this;
        const offset = page ? ((page - 1) * PAGE_SIZE) : 0;

        this.page = page;

        if (notify) {
            this.isLoading = true;
        }

        const { data } = await api.TodoInstances.search({
            parameters: that.getParameters(),
            sortByFields: that.getSortByFields(),
            offset: offset,
            limit: PAGE_SIZE,
            includeTotalCount: true,
        }, (c) => { that.cancelTodoInstanceSearch = c });

        action(e => {
            that.items = data.result && data.result.length > 0 ? data.result : [];
            that.total = data.total ? data.total : 0;
        })();

        if (notify) {
            this.isLoading = false;
        }
    }

    refresh = async () => {
        return await this.execute(this.page);
    }

    status = async (id, status, notify = false) => {
        if (notify) {
            this.isSaving = true;
        }
        await api.TodoInstances.status(id, { status }, (c) => { this.cancelTodoInstanceStatus = c });
        this.isSaving = false;
    }

    clear = () => {
        this.page = 1;
        this.items.clear();
        this.total = null;
        this.userId = null;
        this.statuses.clear();
        this.displayToUserTypes.clear();
        this.sortField = null;
        this.sortDirection = 'ASC';
        this.isLoading = false;
        this.isSaving = false;
        this.isReady = false;

        if (fn.isFunction(this.cancelTodoInstanceSearch)) {
            this.cancelTodoInstanceSearch();
            this.cancelTodoInstanceSearch = null;
        }

        if (fn.isFunction(this.cancelTodoInstanceStatus)) {
            this.cancelTodoInstanceStatus();
            this.cancelTodoInstanceStatus = null;
        }
    }

    getParameters = () => {
        const parameters = [];

        parameters.push({
            field: 'DisplayToUserId',
            value: this.userId,
        });

        if (this.statuses && this.statuses.length > 0) {
            parameters.push({
                field: 'Status',
                value: this.statuses.join(','),
                operator: 'Contains'
            })
        }

        if (this.displayToUserTypes && this.displayToUserTypes.length > 0) {
            parameters.push({
                field: 'DisplayToUserType',
                value: this.displayToUserTypes.join(','),
                operator: 'Contains'
            })
        }

        return parameters;
    }

    getSortByFields = () => {
        const sortByFields = [];

        sortByFields.push({
            field: 'IsHighPriority',
            direction: 'DESC',
        });

        sortByFields.push({
            field: this.sortField,
            direction: this.sortDirection,
        });

        return sortByFields;
    }

    get pageIndex() {
        return this.page - 1;
    }

    get pageCount() {
        return Math.ceil(this.total / PAGE_SIZE);
    }
}

decorate(TodoInstanceSearchStore, {
    page: observable,
    items: observable,
    total: observable,
    userId: observable,
    statuses: observable,
    displayToUserTypes: observable,
    sortField: observable,
    sortDirection: observable,
    isLoading: observable,
    isSaving: observable,
    isReady: observable,
    initialize: action,
    execute: action,
    refresh: action,
    status: action,
    clear: action,
    pageIndex: computed,
    pageCount: computed,
})

export { PAGE_SIZE };
export default createContext(new TodoInstanceSearchStore());