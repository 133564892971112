import React, { useEffect, useContext, useState, useRef } from 'react';
import { toJS } from 'mobx';
import FadeIn from 'react-fade-in';
import { GlobalHotKeys } from 'react-hotkeys';
import { Observer } from 'mobx-react-lite';
import List, { ItemDragging } from 'devextreme-react/list';
import DataSource from 'devextreme/data/data_source';
import { toast } from 'react-toastify';

import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import { renderQuickDrawerLoading } from '../../_shared/QuickDrawer';

import CustomerUpdateStore from '../../../../stores/CustomerUpdateStore';
import PrivateInsuranceProviderCreateStore from '../../../../stores/PrivateInsuranceProviderCreateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as oh from '../../../../utilities/operationHelper';

import api from '../../../../api';

import './ListPrivateInsurance.scss';

function ListPrivateInsurance(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const customer = useContext(CustomerUpdateStore);
    const privateInsuranceProvider = useContext(PrivateInsuranceProviderCreateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [selectedPrivateInsurances, setSelectedPrivateInsurances] = useState([]);
    const [privateInsuranceProviders, setPrivateInsuranceProviders] = useState([]);
    const [canUpdate, setCanUpdate] = useState(false);

    useEffect(() => {
        if (props && props.extraProps && props.extraProps.privateInsurances && props.extraProps.privateInsurances.length > 0) {
            setSelectedPrivateInsurances(toJS(props.extraProps.privateInsurances));
        }
        else if (customer.privateInsurances && customer.privateInsurances.length > 0) {
            setSelectedPrivateInsurances(toJS(customer.privateInsurances));
        }
        if (props && props.extraProps && props.extraProps.privateProviders && props.extraProps.privateProviders.length > 0) {
            setSelectedPrivateInsurances(toJS(props.extraProps.privateProviders));
        }
        else {
            loadPrivateInsuranceProviders();
        }
        if (props && props.extraProps && props.extraProps.canUpdate && props.extraProps.canUpdate === true) {
            setCanUpdate(true);
        }
        else {
            setCanUpdate(false);
        }

        return (() => {
            isMounted.current = false;
            privateInsuranceProvider.clear();
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        })
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (customer.hasPrivateInsuranceUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const loadPrivateInsuranceProviders = () => {
        return new Promise((resolve, reject) => {
            api.PrivateInsuranceProviders.all()
                .then(({ data }) => {
                    if (isMounted.current) {
                        setPrivateInsuranceProviders(data);
                    }
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        })
    }

    const handleSubmit = event => {
        event.preventDefault();
        customer.updatePrivateInsurancesDisplayOrder()
            .then(() => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, { updated: true });
                    }
                }
            })
    }

    const handlePrivateInsuranceReorder = (event) => {
        const { toIndex, fromIndex } = event;
        let tempPrivateInsurances = [...selectedPrivateInsurances];

        tempPrivateInsurances.splice(fromIndex, 1);
        tempPrivateInsurances.splice(toIndex, 0, toJS(selectedPrivateInsurances[fromIndex]));

        for (let i = 0; i < tempPrivateInsurances.length; i++) {
            tempPrivateInsurances[i].displayOrder = (i + 1);
        }

        tempPrivateInsurances = tempPrivateInsurances.sort((a, b) => a.displayOrder - b.displayOrder);

        setSelectedPrivateInsurances(tempPrivateInsurances);
        customer.privateInsurances = tempPrivateInsurances;
        customer.hasPrivateInsuranceUnsavedChanges = true;
    }

    const handleUpdatePrivateInsurance = (event, id) => {
        customer.selectedPrivateInsuranceId = id;
        quickDrawer.activateQuickDrawer('customer', 'private-insurance-update', null, handleInsuranceInformationUpdateSuccess, handleInsuranceInformationUpdateCancel);
    }

    const handleInsuranceInformationUpdateSuccess = (result) => {
        if (result && (result.updated || result.deleted)) {
            if (isMounted.current) {
                customer.loadInsurance()
                    .then(() => {
                        if (isMounted.current) {
                            setSelectedPrivateInsurances(toJS(customer.privateInsurances));
                        }
                    })
                toast.dark(() => <p data-ins-upd>Customer's insurance updated.</p>);
            }
        }
    }

    const handleInsuranceInformationUpdateCancel = () => { }

    const renderPrivateInsurance = (item, index) => {
        const privateInsuranceProvider = privateInsuranceProviders.some(p => p.id === item.privateInsuranceProviderId) ? privateInsuranceProviders.filter(p => p.id === item.privateInsuranceProviderId)[0] : null;

        return <>
            <div className={'form-group mb-0' + (index < (selectedPrivateInsurances.length - 1) ? ' border-bottom' : '')}>
                <div className='cursor-pointer'>
                    <label className='m-0 text-truncate text-truncate-xxl'><small>{(index === 0 ? <span className='text-success'>Primary</span> : (index === 1 ? <span>Secondary</span> : <span>Other(s)</span>))}</small></label>
                    <div className='profile-wrapper pb-0'>
                        <div className='description mb-2'>
                            {
                                privateInsuranceProvider ?
                                    <div className='name'>{privateInsuranceProvider.name}</div> : null
                            }
                            {
                                item.policyHolderName ?
                                    <div className='info'><strong className='mr-1'>Primary holder:</strong>{item.policyHolderName}</div> : null
                            }
                            {
                                item.memberId || item.planNumber || item.accountNumber || item.policyNumber ?
                                    <div className='info'>{[item.memberId, item.planNumber, item.accountNumber, item.policyNumber].filter(i => i !== null).join(' - ')}</div> : null
                            }
                        </div>
                    </div>
                    {
                        canUpdate ?
                            <button type='button' className='btn btn-link p-0 mb-2' onClick={e => { handleUpdatePrivateInsurance(e, item.privateInsurancePolicyId) }}>Update insurance</button> : null
                    }
                </div>
            </div>
        </>
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form onSubmit={handleSubmit}>
            <fieldset disabled={customer.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('customer', 'private-insurance')}
                        action='Update Customer'
                        category='Insurances'
                        className='customers'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        <Observer>{() =>
                            customer.isPrivateInsuranceReady ?
                                <FadeIn>
                                    <div className='private-insurance-list body-content'>
                                        <FadeIn>
                                            <section>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div>
                                                            <List
                                                                dataSource={
                                                                    new DataSource({
                                                                        key: "id",
                                                                        store: selectedPrivateInsurances,
                                                                        paginate: false,
                                                                    })
                                                                }
                                                                activeStateEnabled={false}
                                                                focusStateEnabled={false}
                                                                hoverStateEnabled={false}
                                                                itemRender={(data, index) => { return renderPrivateInsurance(data, index) }}
                                                            >
                                                                <ItemDragging
                                                                    allowReordering={true}
                                                                    onReorder={handlePrivateInsuranceReorder}
                                                                >
                                                                </ItemDragging>
                                                            </List>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </FadeIn>
                                    </div>
                                </FadeIn> : renderQuickDrawerLoading()
                        }</Observer>
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset >
        </form >
    </>
}

export default ListPrivateInsurance;