import React, { useEffect, useContext, useState, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { GlobalHotKeys } from 'react-hotkeys';
import { DropdownList, Combobox } from 'react-widgets';
import { useObserver } from 'mobx-react-lite';

import QuickDrawerHeader from '../../../../_shared/QuickDrawerHeader';
import { quickDrawerFocus } from '../../../../_shared/QuickDrawer';
import BorderPicker from '../../../../_shared/BorderPicker';
import ColorPicker from '../../../../_shared/ColorPicker';
import RichTextEditor from '../../../../_shared/RichTextEditor';

import ReferralElementViewStore from '../../../../../../stores/ReferralElementViewStore';
import QuickDrawerStore from '../../../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../../../constants/errorMessages';
import * as fn from '../../../../../../utilities/_functions';
import * as rth from '../../../../../../utilities/referralTemplateHelper';
import * as fm from '../../../../../../utilities/formatHelper';

import './UpdateExistingReferralElement.scss';

function UpdateExistingReferralInput(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const referralElement = useContext(ReferralElementViewStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [align, setAlign] = useState('');
    const [valign, setValign] = useState('');
    const [padding, setPadding] = useState([]);
    const [fontColor, setFontColor] = useState('#000');
    const [fontSize, setFontSize] = useState(null);
    const [fontFamily, setFontFamily] = useState('');
    const [fontStyle, setFontStyle] = useState([]);
    const [fontLetterSpacing, setFontLetterSpacing] = useState(null);
    const [image, setImage] = useState(null);
    const [backgroundColor, setBackgroundColor] = useState('');
    const [border, setBorder] = useState(null);
    const [borderWidth, setBorderWidth] = useState(1);
    const [borderStyle, setBorderStyle] = useState('solid');
    const [borderColor, setBorderColor] = useState('#000');
    const [alternateBackgroundColor, setAlternateBackgroundColor] = useState('');
    const [labelAlign, setLabelAlign] = useState('');
    const [labelFontColor, setLabelFontColor] = useState('#000');
    const [labelFontSize, setLabelFontSize] = useState(null);
    const [labelFontFamily, setLabelFontFamily] = useState('');
    const [labelFontStyle, setLabelFontStyle] = useState([]);
    const [dataAlign, setDataAlign] = useState('');
    const [dataFontColor, setDataFontColor] = useState('#000');
    const [dataFontSize, setDataFontSize] = useState(null);
    const [dataFontFamily, setDataFontFamily] = useState('');
    const [dataFontStyle, setDataFontStyle] = useState([]);
    const [content, setContent] = useState('');
    const [format, setFormat] = useState(null);
    const [signatureHasLine, setSignatureHasLine] = useState(true);
    const [signatureHasDescription, setSignatureHasDescription] = useState(true);
    const [signatureDescriptionLine1, setSignatureDescriptionLine1] = useState('DrFirstLastName');
    const [signatureDescriptionLine2, setSignatureDescriptionLine2] = useState('LicenseOnly');

    useEffect(() => {
        const existingAlign = referralElement.getMetadata('align');
        const existingValign = referralElement.getMetadata('valign');
        const existingPadding = referralElement.getMetadata('padding');
        const existingFontColor = referralElement.getMetadata('fontColor');
        const existingFontSize = referralElement.getMetadata('fontSize');
        const existingFontFamily = referralElement.getMetadata('fontFamily');
        const existingFontStyle = referralElement.getMetadata('fontStyle');
        const existingFontLetterSpacing = referralElement.getMetadata('fontLetterSpacing');
        const existingImage = referralElement.getMetadata('image');
        const existingBackgroundColor = referralElement.getMetadata('backgroundColor');
        const existingBorder = referralElement.getMetadata('border');
        const existingAlternateBackgroundColor = referralElement.getMetadata('alternateBackgroundColor');
        const existingContent = referralElement.getMetadata('content');
        const existingFormat = referralElement.getMetadata('format');
        const existingLabel = referralElement.getMetadata('label');
        const existingData = referralElement.getMetadata('data');
        const existingSignature = referralElement.getMetadata('signature');

        setAlign((existingAlign ? existingAlign : 'left'));
        setValign((existingValign ? existingValign : 'middle'));
        setPadding((existingPadding ? existingPadding : [0, 0, 0, 0]));
        setFontColor((existingFontColor ? existingFontColor : '#000'));
        setFontSize((existingFontSize ? existingFontSize : 13));
        setFontFamily((existingFontFamily ? existingFontFamily : 'Arial'));
        setFontStyle((existingFontStyle ? existingFontStyle : []));
        setFontLetterSpacing((existingFontLetterSpacing ? existingFontLetterSpacing : 0));
        setImage((existingImage ? existingImage : null));
        setBackgroundColor((existingBackgroundColor ? existingBackgroundColor : ''));
        setBorder((existingBorder ? existingBorder : null));
        setAlternateBackgroundColor((existingAlternateBackgroundColor ? existingAlternateBackgroundColor : '#f3f3f3'));
        setContent((existingContent ? existingContent : ''));
        setFormat((existingFormat ? existingFormat : rth.getFormatOptions(referralElement.data.type)[0] ? rth.getFormatOptions(referralElement.data.type)[0].type : ''));

        setLabelAlign((existingLabel && existingLabel.align ? existingLabel.align : 'left'));
        setLabelFontColor((existingLabel && existingLabel.fontColor ? existingLabel.fontColor : '#000'));
        setLabelFontSize((existingLabel && existingLabel.fontSize ? existingLabel.fontSize : 13));
        setLabelFontFamily((existingLabel && existingLabel.fontFamily ? existingLabel.fontFamily : 'Arial'));
        setLabelFontStyle((existingLabel && existingLabel.fontStyle ? existingLabel.fontStyle : []));

        setDataAlign((existingData && existingData.align ? existingData.align : 'left'));
        setDataFontColor((existingData && existingData.fontColor ? existingData.fontColor : '#000'));
        setDataFontSize((existingData && existingData.fontSize ? existingData.fontSize : 13));
        setDataFontFamily((existingData && existingData.fontFamily ? existingData.fontFamily : 'Arial'));
        setDataFontStyle((existingData && existingData.fontStyle ? existingData.fontStyle : []));

        setSignatureHasLine((existingSignature && !fn.isNullOrUndefined(existingSignature.hasLine) ? existingSignature.hasLine : true));
        setSignatureHasDescription((existingSignature && !fn.isNullOrUndefined(existingSignature.hasDescription) ? existingSignature.hasDescription : true));
        setSignatureDescriptionLine1((existingSignature && existingSignature.description1 ? existingSignature.description1 : 'DrFirstLastName'));
        setSignatureDescriptionLine2((existingSignature && existingSignature.description2 ? existingSignature.description2 : 'LicenseOnly'));

        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100)

        return (() => {
            isMounted.current = false;

            referralElement.clear();
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        })
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (referralElement.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = event => {
        event.preventDefault();
        handlePopulateMetadata();
        referralElement.save()
            .then(data => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, { container: props.extraProps.container, data: data });
                    }
                }
            })
    }

    const handleContentChange = content => {
        const html = ((content === '<p><br></p>') ? null : content);
        setContent(html);
        referralElement.hasUnsavedChanges = true;
    }

    const handleFormatChange = event => {
        setFormat(event.target.value);
        referralElement.hasUnsavedChanges = true;
    }

    const handleFontFamilyChange = (value) => {
        setFontFamily(value);
        referralElement.hasUnsavedChanges = true;
    }

    const handleFontSizeChange = (value) => {
        setFontSize(value);
        referralElement.hasUnsavedChanges = true;
    }

    const handleFontColorChange = (color) => {
        setFontColor(color.value);
        referralElement.hasUnsavedChanges = true;
    }

    const handleFontStyleChange = value => {
        const index = fontStyle.findIndex(s => s === value);
        const tempFontStyle = [...fontStyle];

        if (index === -1) {
            tempFontStyle.push(value);
        } else {
            tempFontStyle.splice(index, 1);
        }

        setFontStyle(tempFontStyle);
        referralElement.hasUnsavedChanges = true;
    }

    const handleFontLetterSpacing = event => {
        const spacing = event.target.value;
        setFontLetterSpacing((spacing ? spacing : 0));
        referralElement.hasUnsavedChanges = true;
    }

    const handleAlignChange = value => {
        setAlign((value ? value : 'left'));
        referralElement.hasUnsavedChanges = true;
    }

    const handleValignChange = value => {
        setValign((value ? value : 'middle'));
        referralElement.hasUnsavedChanges = true;
    }

    const handlePaddingChange = (event, index) => {
        const newPadding = [...padding];

        newPadding[index] = event.target.value;
        setPadding(newPadding);
        referralElement.hasUnsavedChanges = true;
    }

    const handleBorderChange = (event, data) => {
        setBorder(data);
        referralElement.hasUnsavedChanges = true;
    }

    const handleBorderStyleChange = (item) => {
        if (item) {
            setBorderWidth(item.data.width);
            setBorderStyle(item.data.style);
        }
        referralElement.hasUnsavedChanges = true;
    }

    const handleBorderColorChange = (color) => {
        setBorderColor(color.value);
        referralElement.hasUnsavedChanges = true;
    }

    const handleBackgroundColorChange = (color) => {
        setBackgroundColor(color.value);
        referralElement.hasUnsavedChanges = true;
    }

    const handleImageClick = event => {
        setImage(null);
        referralElement.hasUnsavedChanges = true;
    }

    const handleImageChange = event => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = handleFileReaderLoad;
        reader.readAsDataURL(file);
    }

    const handleFileReaderLoad = (event) => {
        const reader = event.target;
        const base64 = reader.result;
        const tempImage = new Image();

        tempImage.src = event.target.result;
        tempImage.onload = () => {
            const newImage = {
                base64: base64,
                width: tempImage.width,
                height: tempImage.height,
            }
            setImage(newImage);
        }
        referralElement.hasUnsavedChanges = true;
    }

    const handleLabelFontFamilyChange = (value) => {
        setLabelFontFamily(value);
        referralElement.hasUnsavedChanges = true;
    }

    const handleLabelFontSizeChange = (value) => {
        setLabelFontSize(value);
        referralElement.hasUnsavedChanges = true;
    }

    const handleLabelFontColorChange = (color) => {
        setLabelFontColor(color.value);
        referralElement.hasUnsavedChanges = true;
    }

    const handleLabelFontStyleChange = value => {
        const index = fontStyle.findIndex(s => s === value);
        const tempFontStyle = [...fontStyle];

        if (index === -1) {
            tempFontStyle.push(value);
        } else {
            tempFontStyle.splice(index, 1);
        }

        setLabelFontStyle(tempFontStyle);
        referralElement.hasUnsavedChanges = true;
    }

    const handleLabelAlignChange = value => {
        setLabelAlign((value ? value : 'left'));
        referralElement.hasUnsavedChanges = true;
    }

    const handleDataFontFamilyChange = (value) => {
        setDataFontFamily(value);
        referralElement.hasUnsavedChanges = true;
    }

    const handleDataFontSizeChange = (value) => {
        setDataFontSize(value);
        referralElement.hasUnsavedChanges = true;
    }

    const handleDataFontColorChange = (color) => {
        setDataFontColor(color.value);
        referralElement.hasUnsavedChanges = true;
    }

    const handleDataFontStyleChange = value => {
        const index = fontStyle.findIndex(s => s === value);
        const tempFontStyle = [...fontStyle];

        if (index === -1) {
            tempFontStyle.push(value);
        } else {
            tempFontStyle.splice(index, 1);
        }

        setDataFontStyle(tempFontStyle);
        referralElement.hasUnsavedChanges = true;
    }

    const handleDataAlignChange = value => {
        setDataAlign((value ? value : 'left'));
        referralElement.hasUnsavedChanges = true;
    }

    const handleSignatureHasLineChange = event => {
        const value = event.target.value === 'true';
        setSignatureHasLine(value);
        referralElement.hasUnsavedChanges = true;
    }

    const handleSignatureHasDescriptionChange = event => {
        const value = event.target.value === 'true';
        setSignatureHasDescription(value);
        referralElement.hasUnsavedChanges = true;
    }

    const handleSignatureDescriptionLine1FormatChange = event => {
        const value = event.target.value;
        setSignatureDescriptionLine1(value);
        referralElement.hasUnsavedChanges = true;
    }

    const handleSignatureDescriptionLine2FormatChange = event => {
        const value = event.target.value;
        setSignatureDescriptionLine2(value);
        referralElement.hasUnsavedChanges = true;
    }

    const handlePopulateMetadata = () => {
        populateMetadata(rth.REFERRAL_TEMPLATE_METADATA_ALIGN, 'align', align);
        populateMetadata(rth.REFERRAL_TEMPLATE_METADATA_VALIGN, 'valign', valign);
        populateMetadata(rth.REFERRAL_TEMPLATE_METADATA_PADDING, 'padding', padding);
        populateMetadata(rth.REFERRAL_TEMPLATE_METADATA_FONT_COLOR, 'fontColor', fontColor);
        populateMetadata(rth.REFERRAL_TEMPLATE_METADATA_FONT_SIZE, 'fontSize', fontSize);
        populateMetadata(rth.REFERRAL_TEMPLATE_METADATA_FONT_FAMILY, 'fontFamily', fontFamily);
        populateMetadata(rth.REFERRAL_TEMPLATE_METADATA_FONT_STYLE, 'fontStyle', fontStyle);
        populateMetadata(rth.REFERRAL_TEMPLATE_METADATA_FONT_LETTER_SPACING, 'fontLetterSpacing', fontLetterSpacing);
        populateMetadata(rth.REFERRAL_TEMPLATE_METADATA_BACKGROUND_COLOR, 'backgroundColor', backgroundColor);
        populateMetadata(rth.REFERRAL_TEMPLATE_METADATA_BORDER, 'border', border);
        populateMetadata(rth.REFERRAL_TEMPLATE_METADATA_BORDER_COLOR, 'borderColor', borderColor);
        populateMetadata(rth.REFERRAL_TEMPLATE_METADATA_ALTERNATE_BACKGROUND_COLOR, 'alternateBackgroundColor', alternateBackgroundColor);
        populateMetadata(rth.REFERRAL_TEMPLATE_METADATA_CONTENT, 'content', content);
        populateMetadata(rth.REFERRAL_TEMPLATE_METADATA_IMAGE, 'image', image);
        populateMetadata(rth.REFERRAL_TEMPLATE_METADATA_FORMAT, 'format', format);

        populateMetadata(rth.REFERRAL_TEMPLATE_METADATA_EXAM_DATA, 'label', {
            align: labelAlign,
            fontColor: labelFontColor,
            fontSize: labelFontSize,
            fontFamily: labelFontFamily,
            fontStyle: labelFontStyle,
        });

        populateMetadata(rth.REFERRAL_TEMPLATE_METADATA_EXAM_DATA, 'data', {
            align: dataAlign,
            fontColor: dataFontColor,
            fontSize: dataFontSize,
            fontFamily: dataFontFamily,
            fontStyle: dataFontStyle,
        });

        populateMetadata(rth.REFERRAL_TEMPLATE_METADATA_DOCTOR_SIGNATURE, 'signature', {
            hasLine: signatureHasLine,
            hasDescription: signatureHasDescription,
            description1: signatureDescriptionLine1,
            description2: signatureDescriptionLine2,
        });
    }

    const populateMetadata = (field, key, value) => {
        if (rth.hasField(referralElement.data.type, field)) {
            referralElement.setMetadata(key, value);
        } else {
            referralElement.removeMetadata(key);
        }
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form onSubmit={handleSubmit}>
            <fieldset disabled={referralElement.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={rth.getIcon(referralElement.data.type)}
                        action='Update'
                        category={`${rth.getDescription(referralElement.data.type)}`}
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        <FadeIn>
                            <div className='update-referral-text body-content'>
                                <section>
                                    {
                                        rth.hasField(referralElement.data.type, rth.REFERRAL_TEMPLATE_METADATA_CONTENT) ?
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label htmlFor='update-referral-text'><small>Content</small></label>
                                                        <RichTextEditor
                                                            mode='basic'
                                                            disableTab={true}
                                                            value={content}
                                                            onChange={handleContentChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        rth.hasField(referralElement.data.type, rth.REFERRAL_TEMPLATE_METADATA_FORMAT) ?
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label htmlFor='update-referral-format'><small>Format</small></label>
                                                        {
                                                            rth.getFormatOptions(referralElement.data.type).map((o, oi) => {
                                                                return <div
                                                                    key={`update-referral-format-${oi}`}
                                                                    className='custom-control custom-radio mb-2'
                                                                >
                                                                    <input
                                                                        id={`update-referral-format-${oi}`}
                                                                        type='radio'
                                                                        name='updateReferralFormat'
                                                                        value={o.type}
                                                                        className='custom-control-input'
                                                                        checked={format === o.type}
                                                                        onChange={handleFormatChange}
                                                                    />
                                                                    <label
                                                                        htmlFor={`update-referral-format-${oi}`}
                                                                        className='custom-control-label'
                                                                        dangerouslySetInnerHTML={{ __html: o.example }}
                                                                    >
                                                                    </label>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        rth.hasField(referralElement.data.type, rth.REFERRAL_TEMPLATE_METADATA_FONT_FAMILY) ||
                                            rth.hasField(referralElement.data.type, rth.REFERRAL_TEMPLATE_METADATA_FONT_SIZE) ||
                                            rth.hasField(referralElement.data.type, rth.REFERRAL_TEMPLATE_METADATA_FONT_COLOR) ?
                                            <div className='row'>
                                                {
                                                    rth.hasField(referralElement.data.type, rth.REFERRAL_TEMPLATE_METADATA_FONT_FAMILY) ?
                                                        <div className='col-6'>
                                                            <div className='form-group mb-4'>
                                                                <label htmlFor='update-referral-font-family'><small>Font</small></label>
                                                                <DropdownList
                                                                    data={['Arial', 'Arial Black', 'Georgia', 'Impact', 'Times New Roman', 'Verdana']}
                                                                    value={fontFamily}
                                                                    itemComponent={({ item }) => (
                                                                        <div>
                                                                            <span style={{ fontFamily: item }}>{item}</span>
                                                                        </div>
                                                                    )}
                                                                    valueComponent={({ item }) => (
                                                                        item ?
                                                                            <div>
                                                                                <span style={{ fontFamily: item }}>{item}</span>
                                                                            </div> : null
                                                                    )}
                                                                    onChange={handleFontFamilyChange}
                                                                />
                                                            </div>
                                                        </div> : null
                                                }
                                                {
                                                    rth.hasField(referralElement.data.type, rth.REFERRAL_TEMPLATE_METADATA_FONT_SIZE) ?
                                                        <div className='col-3'>
                                                            <div className='form-group mb-4'>
                                                                <label htmlFor='update-referral-font-size'><small>Size</small></label>
                                                                <Combobox
                                                                    data={[8, 9, 10, 11, 12, 13, 14, 18, 21, 24, 28, 30, 32, 36, 48, 60, 72, 96]}
                                                                    value={fontSize}
                                                                    autoSelectMatches={false}
                                                                    itemComponent={({ item }) => (
                                                                        <div>
                                                                            <span>{item}</span>
                                                                        </div>
                                                                    )}
                                                                    valueComponent={({ item }) => (
                                                                        item ?
                                                                            <div>
                                                                                <span>{item}</span>
                                                                            </div> : null
                                                                    )}
                                                                    onChange={handleFontSizeChange}
                                                                />
                                                            </div>
                                                        </div> : null
                                                }
                                                {
                                                    rth.hasField(referralElement.data.type, rth.REFERRAL_TEMPLATE_METADATA_FONT_COLOR) ?
                                                        <div className='col-3'>
                                                            <div className='form-group mb-4'>
                                                                <label htmlFor='update-referral-font-color'><small>Color</small></label>
                                                                <ColorPicker color={fontColor} popup='right' onChange={handleFontColorChange} />
                                                            </div>
                                                        </div> : null
                                                }
                                            </div> : null
                                    }
                                    {
                                        rth.hasField(referralElement.data.type, rth.REFERRAL_TEMPLATE_METADATA_FONT_STYLE) ||
                                            rth.hasField(referralElement.data.type, rth.REFERRAL_TEMPLATE_METADATA_FONT_LETTER_SPACING) ?
                                            <div className='row'>
                                                {
                                                    rth.hasField(referralElement.data.type, rth.REFERRAL_TEMPLATE_METADATA_FONT_STYLE) ?
                                                        <div className='col-6'>
                                                            <div className='form-group mb-4'>
                                                                <label htmlFor='update-referral-font-style'><small>Style</small></label>
                                                                <div>
                                                                    <div className='btn-group'>
                                                                        <button
                                                                            type='button'
                                                                            className={'btn btn-primary' + (fontStyle.some(s => s === 'bold') ? ' active' : '')}
                                                                            value={(align ? align : '')}
                                                                            onClick={() => { handleFontStyleChange('bold') }}
                                                                        >
                                                                            <i className='fal fa-bold'></i>
                                                                        </button>
                                                                        <button
                                                                            type='button'
                                                                            className={'btn btn-primary' + (fontStyle.some(s => s === 'italic') ? ' active' : '')}
                                                                            value={(align ? align : '')}
                                                                            onClick={() => { handleFontStyleChange('italic') }}
                                                                        >
                                                                            <i className='fal fa-italic'></i>
                                                                        </button>
                                                                        <button
                                                                            type='button'
                                                                            className={'btn btn-primary' + (fontStyle.some(s => s === 'underline') ? ' active' : '')}
                                                                            value={(align ? align : '')}
                                                                            onClick={() => { handleFontStyleChange('underline') }}
                                                                        >
                                                                            <i className='fal fa-underline'></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> : null
                                                }
                                                {
                                                    rth.hasField(referralElement.data.type, rth.REFERRAL_TEMPLATE_METADATA_FONT_LETTER_SPACING) ?
                                                        <div className='col-3'>
                                                            <div className='form-group mb-4'>
                                                                <label htmlFor='update-referral-font-letter-spacing'><small>Spacing</small></label>
                                                                <input
                                                                    id='update-referral-font-letter-spacing'
                                                                    type='number'
                                                                    className='form-control'
                                                                    min={-2}
                                                                    step={0.1}
                                                                    max={2}
                                                                    value={fontLetterSpacing}
                                                                    onChange={handleFontLetterSpacing}
                                                                />
                                                            </div>
                                                        </div> : null
                                                }
                                            </div> : null
                                    }
                                    {
                                        rth.hasField(referralElement.data.type, rth.REFERRAL_TEMPLATE_METADATA_ALIGN) ||
                                            rth.hasField(referralElement.data.type, rth.REFERRAL_TEMPLATE_METADATA_VALIGN) ?
                                            <div className='row'>
                                                {
                                                    rth.hasField(referralElement.data.type, rth.REFERRAL_TEMPLATE_METADATA_ALIGN) ?
                                                        <div className='col-6'>
                                                            <div className='form-group mb-4'>
                                                                <label htmlFor='update-referral-text-align'><small>Align</small></label>
                                                                <div>
                                                                    <div className='btn-group'>
                                                                        <button
                                                                            type='button'
                                                                            className={'btn btn-primary' + (align === 'left' ? ' active' : '')}
                                                                            value={(align ? align : '')}
                                                                            onClick={() => { handleAlignChange('left') }}
                                                                        >
                                                                            <i className='fal fa-align-left'></i>
                                                                        </button>
                                                                        <button
                                                                            type='button'
                                                                            className={'btn btn-primary' + (align === 'center' ? ' active' : '')}
                                                                            value={(align ? align : '')}
                                                                            onClick={() => { handleAlignChange('center') }}
                                                                        >
                                                                            <i className='fal fa-align-center'></i>
                                                                        </button>
                                                                        <button
                                                                            type='button'
                                                                            className={'btn btn-primary' + (align === 'right' ? ' active' : '')}
                                                                            value={(align ? align : '')}
                                                                            onClick={() => { handleAlignChange('right') }}
                                                                        >
                                                                            <i className='fal fa-align-right'></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> : null
                                                }
                                                {
                                                    rth.hasField(referralElement.data.type, rth.REFERRAL_TEMPLATE_METADATA_VALIGN) ?
                                                        <div className='form-group mb-4'>
                                                            <label htmlFor='update-referral-text-valign'><small>Vertical Align</small></label>
                                                            <div>
                                                                <div className='btn-group'>
                                                                    <button
                                                                        type='button'
                                                                        className={'btn btn-primary' + (valign === 'top' ? ' active' : '')}
                                                                        value={(valign ? valign : '')}
                                                                        onClick={() => { handleValignChange('top') }}
                                                                    >
                                                                        <i className='fal fa-arrow-to-top'></i>
                                                                    </button>
                                                                    <button
                                                                        type='button'
                                                                        className={'btn btn-primary' + (valign === 'middle' ? ' active' : '')}
                                                                        value={(valign ? valign : '')}
                                                                        onClick={() => { handleValignChange('middle') }}
                                                                    >
                                                                        <i className='fal fa-horizontal-rule'></i>
                                                                    </button>
                                                                    <button
                                                                        type='button'
                                                                        className={'btn btn-primary' + (valign === 'bottom' ? ' active' : '')}
                                                                        value={(valign ? valign : '')}
                                                                        onClick={() => { handleValignChange('bottom') }}
                                                                    >
                                                                        <i className='fal fa-arrow-to-bottom'></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div> : null
                                                }
                                            </div> : null
                                    }
                                    {
                                        rth.hasField(referralElement.data.type, rth.REFERRAL_TEMPLATE_METADATA_PADDING) ?
                                            <div className='row'>
                                                <div className='col-3'>
                                                    <div className='form-group mb-4'>
                                                        <label htmlFor='update-referral-padding-top'><small>Top</small></label>
                                                        <input
                                                            id='update-referral-padding-top'
                                                            type='number'
                                                            className='form-control'
                                                            min={-50}
                                                            step={rth.REFERRAL_TEMPLATE_ROW_HEIGHT}
                                                            max={50}
                                                            value={(padding && padding[0] ? padding[0] : 0)}
                                                            onChange={(e) => { handlePaddingChange(e, 0) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-3'>
                                                    <div className='form-group mb-4'>
                                                        <label htmlFor='update-referral-padding-right'><small>Right</small></label>
                                                        <input
                                                            id='update-referral-padding-right'
                                                            type='number'
                                                            className='form-control'
                                                            min={-50}
                                                            step={rth.REFERRAL_TEMPLATE_ROW_HEIGHT}
                                                            max={50}
                                                            value={(padding && padding[1] ? padding[1] : 0)}
                                                            onChange={(e) => { handlePaddingChange(e, 1) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-3'>
                                                    <div className='form-group mb-4'>
                                                        <label htmlFor='update-referral-padding-bottom'><small>Bottom</small></label>
                                                        <input
                                                            id='update-referral-padding-bottom'
                                                            type='number'
                                                            className='form-control'
                                                            min={-50}
                                                            step={rth.REFERRAL_TEMPLATE_ROW_HEIGHT}
                                                            max={50}
                                                            value={(padding && padding[2] ? padding[2] : 0)}
                                                            onChange={(e) => { handlePaddingChange(e, 2) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-3'>
                                                    <div className='form-group mb-4'>
                                                        <label htmlFor='update-referral-padding-left'><small>Left</small></label>
                                                        <input
                                                            id='update-referral-padding-left'
                                                            type='number'
                                                            className='form-control'
                                                            min={-50}
                                                            step={rth.REFERRAL_TEMPLATE_ROW_HEIGHT}
                                                            max={50}
                                                            value={(padding && padding[3] ? padding[3] : 0)}
                                                            onChange={(e) => { handlePaddingChange(e, 3) }}
                                                        />
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        rth.hasField(referralElement.data.type, rth.REFERRAL_TEMPLATE_METADATA_BORDER) ?
                                            <>
                                                <div className='row'>
                                                    <div className='col-3'>
                                                        <div className='form-group mb-3'>
                                                            <label htmlFor='update-referral-border'><small>Border</small></label>
                                                            <BorderPicker border={border} width={borderWidth} style={borderStyle} color={borderColor} onChange={handleBorderChange} />
                                                        </div>
                                                    </div>
                                                    <div className='col-5 offset-1'>
                                                        <div className='form-group mb-3'>
                                                            <label htmlFor='update-referral-border-style'><small>Style</small></label>
                                                            <DropdownList
                                                                data={[
                                                                    { key: '1px solid #000', data: { width: 1, style: 'solid' } },
                                                                    { key: '2px solid #000', data: { width: 2, style: 'solid' } },
                                                                    { key: '3px solid #000', data: { width: 3, style: 'solid' } },
                                                                    { key: '1px dashed #000', data: { width: 1, style: 'dashed' } },
                                                                    { key: '2px dashed #000', data: { width: 2, style: 'dashed' } },
                                                                    { key: '3px dashed #000', data: { width: 3, style: 'dashed' } },
                                                                    { key: '1px dotted #000', data: { width: 1, style: 'dotted' } },
                                                                    { key: '2px dotted #000', data: { width: 2, style: 'dotted' } },
                                                                    { key: '3px dotted #000', data: { width: 3, style: 'dotted' } },
                                                                    { key: '3px double #000', data: { width: 3, style: 'double' } },
                                                                    { key: '6px double #000', data: { width: 6, style: 'double' } },
                                                                ]}
                                                                valueField='key'
                                                                value={(`${borderWidth}px ${borderStyle} #000`)}
                                                                itemComponent={({ item }) => (
                                                                    <div className='p-0' style={{ fontSize: '1px', borderTop: item.key }}>&nbsp;</div>
                                                                )}
                                                                valueComponent={({ item }) => (
                                                                    item ? <div className='p-0' style={{ fontSize: '1px', borderTop: item.key }}>&nbsp;</div> : null
                                                                )}
                                                                onChange={handleBorderStyleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-3'>
                                                        <div className='form-group mb-4'>
                                                            <label htmlFor='update-referral-border-color'><small>Color</small></label>
                                                            <ColorPicker color={borderColor} popup='right' onChange={handleBorderColorChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </> : null
                                    }
                                    {
                                        rth.hasField(referralElement.data.type, rth.REFERRAL_TEMPLATE_METADATA_BACKGROUND_COLOR) ?
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label htmlFor='update-referral-background-color'><small>Background Color</small></label>
                                                        <ColorPicker color={backgroundColor} popup='right' onChange={handleBackgroundColorChange} />
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        rth.hasField(referralElement.data.type, rth.REFERRAL_TEMPLATE_METADATA_IMAGE) ?
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label htmlFor='update-referral-background-image'><small>Image</small></label>
                                                        {
                                                            image ?
                                                                <div>
                                                                    <img src={image.base64} width={(image.width > 300 ? 300 : image.width)} alt='Example' />
                                                                    <button
                                                                        type='button'
                                                                        className='btn btn-link px-0 btn-sm d-block'
                                                                        onClick={handleImageClick}
                                                                    >Change image</button>
                                                                </div> :
                                                                <div className='custom-file'>
                                                                    <input
                                                                        id='update-referral-background-image-url'
                                                                        type='file'
                                                                        className='custom-file-input'
                                                                        accept='image/*'
                                                                        onChange={handleImageChange} />
                                                                    <label
                                                                        htmlFor='update-referral-background-image-url'
                                                                        className='custom-file-label'>
                                                                    </label>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        rth.hasField(referralElement.data.type, rth.REFERRAL_TEMPLATE_METADATA_EXAM_DATA) ?
                                            <>
                                                <div className='row mb-2'>
                                                    <div className='col-12'>
                                                        <strong className='text-uppercase'>Exam Label</strong>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <div className='form-group mb-4'>
                                                            <label htmlFor='update-referral-exam-label-font-family'><small>Font</small></label>
                                                            <DropdownList
                                                                data={['Arial', 'Arial Black', 'Georgia', 'Impact', 'Times New Roman', 'Verdana']}
                                                                value={labelFontFamily}
                                                                itemComponent={({ item }) => (
                                                                    <div>
                                                                        <span style={{ fontFamily: item }}>{item}</span>
                                                                    </div>
                                                                )}
                                                                valueComponent={({ item }) => (
                                                                    item ?
                                                                        <div>
                                                                            <span style={{ fontFamily: item }}>{item}</span>
                                                                        </div> : null
                                                                )}
                                                                onChange={handleLabelFontFamilyChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-3'>
                                                        <div className='form-group mb-4'>
                                                            <label htmlFor='update-referral-exam-label-font-size'><small>Size</small></label>
                                                            <Combobox
                                                                data={[8, 9, 10, 11, 12, 13, 14, 18, 21, 24, 28, 30, 32, 36, 48, 60, 72, 96]}
                                                                value={labelFontSize}
                                                                autoSelectMatches={false}
                                                                itemComponent={({ item }) => (
                                                                    <div>
                                                                        <span>{item}</span>
                                                                    </div>
                                                                )}
                                                                valueComponent={({ item }) => (
                                                                    item ?
                                                                        <div>
                                                                            <span>{item}</span>
                                                                        </div> : null
                                                                )}
                                                                onChange={handleLabelFontSizeChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-3'>
                                                        <div className='form-group mb-4'>
                                                            <label htmlFor='update-referral-exam-label-font-color'><small>Color</small></label>
                                                            <ColorPicker color={labelFontColor} popup='right' onChange={handleLabelFontColorChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <div className='form-group mb-4'>
                                                            <label htmlFor='update-referral-exam-label-font-style'><small>Style</small></label>
                                                            <div>
                                                                <div className='btn-group'>
                                                                    <button
                                                                        type='button'
                                                                        className={'btn btn-primary' + (labelFontStyle.some(s => s === 'bold') ? ' active' : '')}
                                                                        onClick={() => { handleLabelFontStyleChange('bold') }}
                                                                    >
                                                                        <i className='fal fa-bold'></i>
                                                                    </button>
                                                                    <button
                                                                        type='button'
                                                                        className={'btn btn-primary' + (labelFontStyle.some(s => s === 'italic') ? ' active' : '')}
                                                                        onClick={() => { handleLabelFontStyleChange('italic') }}
                                                                    >
                                                                        <i className='fal fa-italic'></i>
                                                                    </button>
                                                                    <button
                                                                        type='button'
                                                                        className={'btn btn-primary' + (labelFontStyle.some(s => s === 'underline') ? ' active' : '')}
                                                                        onClick={() => { handleLabelFontStyleChange('underline') }}
                                                                    >
                                                                        <i className='fal fa-underline'></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className='form-group mb-4'>
                                                            <label htmlFor='update-referral-exam-label-text-align'><small>Align</small></label>
                                                            <div>
                                                                <div className='btn-group'>
                                                                    <button
                                                                        type='button'
                                                                        className={'btn btn-primary' + (labelAlign === 'left' ? ' active' : '')}
                                                                        onClick={() => { handleLabelAlignChange('left') }}
                                                                    >
                                                                        <i className='fal fa-align-left'></i>
                                                                    </button>
                                                                    <button
                                                                        type='button'
                                                                        className={'btn btn-primary' + (labelAlign === 'center' ? ' active' : '')}
                                                                        onClick={() => { handleLabelAlignChange('center') }}
                                                                    >
                                                                        <i className='fal fa-align-center'></i>
                                                                    </button>
                                                                    <button
                                                                        type='button'
                                                                        className={'btn btn-primary' + (labelAlign === 'right' ? ' active' : '')}
                                                                        value={(labelAlign ? labelAlign : '')}
                                                                        onClick={() => { handleLabelAlignChange('right') }}
                                                                    >
                                                                        <i className='fal fa-align-right'></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row mb-2'>
                                                    <div className='col-12'>
                                                        <hr />
                                                    </div>
                                                </div>
                                                <div className='row mb-2'>
                                                    <div className='col-12'>
                                                        <strong className='text-uppercase'>Exam Data</strong>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <div className='form-group mb-4'>
                                                            <label htmlFor='update-referral-exam-data-font-family'><small>Font</small></label>
                                                            <DropdownList
                                                                data={['Arial', 'Arial Black', 'Courier New', 'Georgia', 'Impact', 'Times New Roman', 'Verdana']}
                                                                value={dataFontFamily}
                                                                itemComponent={({ item }) => (
                                                                    <div>
                                                                        <span style={{ fontFamily: item }}>{item}</span>
                                                                    </div>
                                                                )}
                                                                valueComponent={({ item }) => (
                                                                    item ?
                                                                        <div>
                                                                            <span style={{ fontFamily: item }}>{item}</span>
                                                                        </div> : null
                                                                )}
                                                                onChange={handleDataFontFamilyChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-3'>
                                                        <div className='form-group mb-4'>
                                                            <label htmlFor='update-referral-exam-data-font-size'><small>Size</small></label>
                                                            <Combobox
                                                                data={[8, 9, 10, 11, 12, 13, 14, 18, 21, 24, 28, 30, 32, 36, 48, 60, 72, 96]}
                                                                value={dataFontSize}
                                                                autoSelectMatches={false}
                                                                itemComponent={({ item }) => (
                                                                    <div>
                                                                        <span>{item}</span>
                                                                    </div>
                                                                )}
                                                                valueComponent={({ item }) => (
                                                                    item ?
                                                                        <div>
                                                                            <span>{item}</span>
                                                                        </div> : null
                                                                )}
                                                                onChange={handleDataFontSizeChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-3'>
                                                        <div className='form-group mb-4'>
                                                            <label htmlFor='update-referral-exam-data-font-color'><small>Color</small></label>
                                                            <ColorPicker color={dataFontColor} popup='right' onChange={handleDataFontColorChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <div className='form-group mb-4'>
                                                            <label htmlFor='update-referral-exam-data-font-style'><small>Style</small></label>
                                                            <div>
                                                                <div className='btn-group'>
                                                                    <button
                                                                        type='button'
                                                                        className={'btn btn-primary' + (dataFontStyle.some(s => s === 'bold') ? ' active' : '')}
                                                                        onClick={() => { handleDataFontStyleChange('bold') }}
                                                                    >
                                                                        <i className='fal fa-bold'></i>
                                                                    </button>
                                                                    <button
                                                                        type='button'
                                                                        className={'btn btn-primary' + (dataFontStyle.some(s => s === 'italic') ? ' active' : '')}
                                                                        onClick={() => { handleDataFontStyleChange('italic') }}
                                                                    >
                                                                        <i className='fal fa-italic'></i>
                                                                    </button>
                                                                    <button
                                                                        type='button'
                                                                        className={'btn btn-primary' + (dataFontStyle.some(s => s === 'underline') ? ' active' : '')}
                                                                        onClick={() => { handleDataFontStyleChange('underline') }}
                                                                    >
                                                                        <i className='fal fa-underline'></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className='form-group mb-4'>
                                                            <label htmlFor='update-referral-exam-data-text-align'><small>Align</small></label>
                                                            <div>
                                                                <div className='btn-group'>
                                                                    <button
                                                                        type='button'
                                                                        className={'btn btn-primary' + (dataAlign === 'left' ? ' active' : '')}
                                                                        onClick={() => { handleDataAlignChange('left') }}
                                                                    >
                                                                        <i className='fal fa-align-left'></i>
                                                                    </button>
                                                                    <button
                                                                        type='button'
                                                                        className={'btn btn-primary' + (dataAlign === 'center' ? ' active' : '')}
                                                                        onClick={() => { handleDataAlignChange('center') }}
                                                                    >
                                                                        <i className='fal fa-align-center'></i>
                                                                    </button>
                                                                    <button
                                                                        type='button'
                                                                        className={'btn btn-primary' + (dataAlign === 'right' ? ' active' : '')}
                                                                        value={(dataAlign ? dataAlign : '')}
                                                                        onClick={() => { handleDataAlignChange('right') }}
                                                                    >
                                                                        <i className='fal fa-align-right'></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </> : null
                                    }
                                    {
                                        rth.hasField(referralElement.data.type, rth.REFERRAL_TEMPLATE_METADATA_DOCTOR_SIGNATURE) ?
                                            <>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='form-group mb-4'>
                                                            <label><small>Show line?</small></label>
                                                            <div>
                                                                <div className='custom-control custom-radio custom-control-inline'>
                                                                    <input
                                                                        id={`update-referral-signature-line-yes`}
                                                                        type='radio'
                                                                        name='updateReferralSignatureLine'
                                                                        value={true}
                                                                        className='custom-control-input'
                                                                        checked={signatureHasLine === true}
                                                                        onChange={handleSignatureHasLineChange}
                                                                    />
                                                                    <label
                                                                        htmlFor={`update-referral-signature-line-yes`}
                                                                        className='custom-control-label'
                                                                    >
                                                                        Yes
                                                                    </label>
                                                                </div>
                                                                <div className='custom-control custom-radio custom-control-inline'>
                                                                    <input
                                                                        id={`update-referral-signature-line-no`}
                                                                        type='radio'
                                                                        name='updateReferralSignatureLine'
                                                                        value={false}
                                                                        className='custom-control-input'
                                                                        checked={signatureHasLine === false}
                                                                        onChange={handleSignatureHasLineChange}
                                                                    />
                                                                    <label
                                                                        htmlFor={`update-referral-signature-line-no`}
                                                                        className='custom-control-label'
                                                                    >
                                                                        No
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='form-group mb-4'>
                                                            <label><small>Show Description?</small></label>
                                                            <div>
                                                                <div className='custom-control custom-radio custom-control-inline'>
                                                                    <input
                                                                        id={`update-referral-signature-description-yes`}
                                                                        type='radio'
                                                                        name='updateReferralDescription'
                                                                        value={true}
                                                                        className='custom-control-input'
                                                                        checked={signatureHasDescription === true}
                                                                        onChange={handleSignatureHasDescriptionChange}
                                                                    />
                                                                    <label
                                                                        htmlFor={`update-referral-signature-description-yes`}
                                                                        className='custom-control-label'
                                                                    >
                                                                        Yes
                                                                    </label>
                                                                </div>
                                                                <div className='custom-control custom-radio custom-control-inline'>
                                                                    <input
                                                                        id={`update-referral-signature-description-no`}
                                                                        type='radio'
                                                                        name='updateReferralDescription'
                                                                        value={false}
                                                                        className='custom-control-input'
                                                                        checked={signatureHasDescription === false}
                                                                        onChange={handleSignatureHasDescriptionChange}
                                                                    />
                                                                    <label
                                                                        htmlFor={`update-referral-signature-description-no`}
                                                                        className='custom-control-label'
                                                                    >
                                                                        No
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    signatureHasDescription ? <>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className='form-group mb-4'>
                                                                    <label><small>Line 1 Format</small></label>
                                                                    {
                                                                        fm.DOCTOR_NAME_FORMAT_OPTIONS.map((o, oi) => {
                                                                            return <div
                                                                                key={`update-referral-signature-description1-${oi}`}
                                                                                className='custom-control custom-radio mb-2'
                                                                            >
                                                                                <input
                                                                                    id={`update-referral-signature-description1-${oi}`}
                                                                                    type='radio'
                                                                                    name='updateReferralLine1Format'
                                                                                    value={o.type}
                                                                                    className='custom-control-input'
                                                                                    checked={signatureDescriptionLine1 === o.type}
                                                                                    onChange={handleSignatureDescriptionLine1FormatChange}
                                                                                />
                                                                                <label
                                                                                    htmlFor={`update-referral-signature-description1-${oi}`}
                                                                                    className='custom-control-label'
                                                                                    dangerouslySetInnerHTML={{ __html: o.example }}
                                                                                >
                                                                                </label>
                                                                            </div>
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className='form-group mb-4'>
                                                                    <label><small>Line 2 Format</small></label>
                                                                    {
                                                                        fm.DOCTOR_LICENSE_BILLING_FORMAT_OPTIONS.map((o, oi) => {
                                                                            return <div
                                                                                key={`update-referral-signature-description2-${oi}`}
                                                                                className='custom-control custom-radio mb-2'
                                                                            >
                                                                                <input
                                                                                    id={`update-referral-signature-description2-${oi}`}
                                                                                    type='radio'
                                                                                    name='updateReferralLine2Format'
                                                                                    value={o.type}
                                                                                    className='custom-control-input'
                                                                                    checked={signatureDescriptionLine2 === o.type}
                                                                                    onChange={handleSignatureDescriptionLine2FormatChange}
                                                                                />
                                                                                <label
                                                                                    htmlFor={`update-referral-signature-description2-${oi}`}
                                                                                    className='custom-control-label'
                                                                                    dangerouslySetInnerHTML={{ __html: o.example }}
                                                                                >
                                                                                </label>
                                                                            </div>
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </> : null
                                                }
                                            </> : null
                                    }
                                </section>
                            </div>
                        </FadeIn>
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-4'></div>
                            <div className='col-8'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>)
}

export default UpdateExistingReferralInput;