import React, { Component } from 'react';
import { Popup } from 'devextreme-react';

import BodyEnd from './BodyEnd';
import PrescriptionSummary from './PrescriptionSummary';

import * as fn from '../../../utilities/_functions';

import './PrescriptionSummaryModal.scss';

const PRESCRIPTION_SUMMARY_POPUP_ID = '__prescription-summary-popup';

export default class PrescriptionSummaryModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            display: false,
            zIndex: null,
            options: null,
            renderRecenter: false,
        };
        this.popupRef = React.createRef();
        this.prescriptionSummaryRef = React.createRef();
        this.handleClose = this.handleClose.bind(this);
        this.renderContent = this.renderContent.bind(this);
    }

    componentWillUnmount() {
        if (this.props.onClose) { this.props.onClose(); }
    }

    show = (options) => {
        const zIndex = fn.getHighestZIndex() + 1;
        const defaults = {
            template: null,
            prescriptions: [],
            enableAction: false,
            enableSelect: false,
            showDraftDefault: false,
            onDblClick: null,
            onRegenerate: null,
            onView: null,
            onUpdate: null,
            onClone: null,
            onDownload: null,
            onPrint: null,
            onDelete: null,
            onSelect: null,
            onAddNote: null,
            onRequestFinalize: null,
        };
        options = { ...defaults, ...options }
        this.setState({ options: options, display: true, zIndex: zIndex });
    }

    recenter = () => {
        if (this.popupRef && this.popupRef.current) {
            this.popupRef.current.instance.option('position', {
                my: 'center',
                at: 'center',
                of: window,
            })
        }
    }

    refresh = (prescriptions) => {
        const { options } = this.state;
        const filteredPrescriptions = prescriptions && prescriptions.length > 0 ? prescriptions.filter(p => p.prescriptionTypeId === options.template.prescriptionTypeId) : [];
        options.prescriptions = filteredPrescriptions;
        this.setState({ options: options });
        this.prescriptionSummaryRef.current.refreshPrescriptions(filteredPrescriptions);
    }

    close = () => {
        this.handleClose();
    }

    isOpened = () => {
        return this.state.display;
    }

    handleShowing = event => {
        const popup = document.getElementById(PRESCRIPTION_SUMMARY_POPUP_ID);
        if (popup) {
            // const { zIndex } = this.state;
            // popup.style.zIndex = zIndex;
        }
    }

    handleShown = event => {
        this.setState({ renderRecenter: true });
    }

    handleClose = event => {
        const popup = document.getElementById(PRESCRIPTION_SUMMARY_POPUP_ID);

        if (popup) {
            // popup.style.zIndex = null;
        }

        this.setState({ display: false, zIndex: null, options: null, renderRecenter: false });
    }

    renderTitle = () => {
        return <div className='popup-title-draggable'></div>
    }

    renderContent = () => {
        const { options } = this.state;

        return options ?
            <>
                <div className='popup-title'>
                    <div className='actions right-actions'>
                        <ul>
                            <li>
                                <button
                                    type='button'
                                    className='btn btn-icon btn-close'
                                    onClick={this.handleClose}
                                >
                                    <i className='close-icon fal fa-times fs-xl'></i>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='popup-body'>
                    <PrescriptionSummary
                        ref={this.prescriptionSummaryRef}
                        gridHeight={360}
                        templates={[options.template]}
                        prescriptions={options.prescriptions}
                        enableAction={options.enableAction}
                        enableSelect={options.enableSelect}
                        enableExpand={false}
                        enableHover={true}
                        showDraftDefault={options.showDraftDefault}
                        scaleToFit={false}
                        multiple={false}
                        onDblClick={options.onDblClick}
                        onRegenerate={options.onRegenerate}
                        onView={options.onView}
                        onUpdate={options.onUpdate}
                        onClone={options.onClone}
                        onDownload={options.onDownload}
                        onPrint={options.onPrint}
                        onDelete={options.onDelete}
                        onSelect={options.onSelect}
                        onAddNote={options.onAddNote}
                        onRequestFinalize={options.onRequestFinalize}
                    />
                </div>
            </> : null
    }

    render() {
        return <>
            <Popup
                ref={this.popupRef}
                wrapperAttr={{ id: PRESCRIPTION_SUMMARY_POPUP_ID, class: 'dx-popup-prescription-summary' }}
                animation={{
                    show: {
                        type: 'pop',
                        duration: 200,
                        from: { opacity: 0, scale: 0 },
                        to: { opacity: 1, scale: 1 }
                    },
                    hide: {
                        type: 'pop',
                        duration: 200,
                        from: { opacity: 1, scale: 1 },
                        to: { opacity: 0, scale: 0 }
                    }
                }}
                visible={this.state.display}
                shading={true}
                shadingColor="rgba(0, 0, 0, 0.2)"
                width={1280}
                height={500}
                dragEnabled={true}
                dragOutsideBoundary={true}
                showTitle={true}
                titleRender={this.renderTitle}
                contentRender={this.renderContent}
                onShowing={this.handleShowing}
                onShown={this.handleShown}
                onHiding={this.handleClose}
            />
            {
                this.state.renderRecenter ?
                    <BodyEnd>
                        <button
                            type='button'
                            className='dx-popup-recenter'
                            style={{ zIndex: fn.getHighestZIndex() }}
                            title='Re-center'
                            onClick={() => { this.recenter() }}
                        >
                            <i className='fal fa-arrows'></i>
                        </button>
                    </BodyEnd> : null
            }
        </>
    }
}