import React, { useContext, useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useObserver } from 'mobx-react-lite';

import SearchBarStore from '../../../stores/SearchBarStore';

import * as rts from '../../../constants/routes';
import * as fn from '../../../utilities/_functions';
import * as ch from '../../../utilities/customerHelper';
import * as ph from '../../../utilities/personHelper';

import './Searchbar.scss';

const SEARCHBAR_INPUT = '__searchbar_input__';

const searchbarFocus = () => {
    setTimeout(() => {
        const element = document.querySelector(`#${SEARCHBAR_INPUT}`);

        if (element) {
            element.focus();
            element.select();
        }
    }, 100)
}

const searchbarBlur = () => {
    setTimeout(() => {
        const element = document.querySelector(`#${SEARCHBAR_INPUT}`);

        if (element) {
            element.blur();
        }
    }, 100)
}

function Searchbar() {
    const searchTimer = useRef(null);
    const navigate = useNavigate();
    const search = useContext(SearchBarStore);
    const [selectedIndex, setSelectedIndex] = useState(-1);

    useEffect(() => {
        return () => {
            if (searchTimer.current) {
                clearTimeout(searchTimer.current);
            }
            search.clear();
        }
    }, []) // eslint-disable-line

    const activateSearchbar = () => {
        search.isActivated = true;
        searchbarFocus();
    }

    const deactivateSearchbar = () => {
        search.isActivated = false;
        searchbarBlur();
    }

    const handleSearchChange = event => {
        const term = event.target.value;

        search.searchTerm = term;

        if (searchTimer.current) {
            clearTimeout(searchTimer.current);
            search.searchResult.clear();
        }

        searchTimer.current = setTimeout(() => {
            search.execute()
                .then(() => {
                    setSelectedIndex(-1);
                })
        }, 500);
    }

    const handleSearchKeyDown = event => {
        if (selectedIndex !== -1 && (!search.searchResult || search.searchResult.length === 0)) {
            setSelectedIndex(-1);
            return;
        }

        let newIndex;
        const code = event.which || event.keyCode;

        switch (code) {
            case 38: // up
                event.preventDefault();
                newIndex = selectedIndex - 1;
                if (newIndex < -1) {
                    newIndex = Math.min(search.searchResult.length, 5) - 1;
                }
                break;

            case 40: // down
                event.preventDefault();
                newIndex = selectedIndex + 1;
                if (newIndex > (Math.min(search.searchResult.length, 5) - 1)) {
                    newIndex = -1;
                }
                break;

            case 13: // enter
                const index = (selectedIndex === -1 && search.searchResult.length === 1) ? 0 : selectedIndex;

                if (fn.isNullOrUndefined(index) || index === -1) {
                    navigate(`${rts.SearchResults.Home}?q=${encodeURIComponent(search.searchTerm).replace(/%20/g, "+")}`);
                } else {
                    navigate(`${rts.Customers.Home}/${search.searchResult[index].id}`);
                    search.clear();
                }

                deactivateSearchbar();
                break;

            default:
                break;
        }

        if (!fn.isNullOrUndefined(newIndex)) {
            setSelectedIndex(newIndex);
        }
    }

    const handleSearchClickCapture = event => {
        search.clear();
        deactivateSearchbar();
    }

    return useObserver(() => <>
        <div className={'search searchbar' + (search.isActivated ? ' active' : '')}>
            <i className={'fal fa-search' + (search.isActivated ? '' : ' active')} onClick={activateSearchbar}></i>
            <i className={'fal fa-arrow-left' + (search.isActivated ? ' active' : '')} onClick={deactivateSearchbar}></i>
            <div className='dropdown'>
                <input
                    data-search-bar-input
                    id={SEARCHBAR_INPUT}
                    type='text'
                    className='form-control dropdown-toggle'
                    value={search.searchTerm ? search.searchTerm : ''}
                    placeholder='Search for anything'
                    autoComplete='off'
                    spellCheck={false}
                    onFocus={activateSearchbar}
                    onChange={handleSearchChange}
                    onKeyDown={handleSearchKeyDown}
                />
                <ul className={'dropdown-menu' + (search.isActivated && search.searchResult && search.searchResult.length > 0 ? ' active' : '')}>
                    {
                        search.searchResult && search.searchResult.length > 0 ?
                            search.searchResult.slice(0, 5).map((s, si) => {
                                return <li
                                    key={`search_result_${si}`}
                                    className={'dropdown-menu-item' + ((selectedIndex === si) ? ' active' : '')}>
                                    <Link to={`${rts.Customers.Home}/${s.id}`} onClickCapture={handleSearchClickCapture}>
                                        <div
                                            className='profile-wrapper'
                                        >
                                            <div className='profile'>
                                                <span
                                                    className={`profile-image profile-image-md profile-initials rounded-circle fs-xs text-white ${ch.getProfileColor(s)}`}
                                                    title={ph.getPreferredFullName(s)}
                                                >
                                                    {s.initials}
                                                </span>
                                            </div>
                                            <div className='description'>
                                                <div className='name'>{ph.getLastFirstName(s, true)}
                                                    {
                                                        s.dateOfBirth || s.sex || s.gender || s.pronoun ?
                                                            <small className='ml-2 text-gray'>({`${ph.getAge(s.dateOfBirth)} ${ph.getSexGenderPronounDisplay(s)}`.trim()})</small> : null
                                                    }
                                                </div>
                                                {
                                                    s.isSystemCustomer ?
                                                        <div className='info fw-500 text-primary'>System account</div> : null
                                                }
                                                <div className='info'>{s.address && s.address.addressLine1 ? s.address.addressLine1 : null}</div>
                                                <div className='info fs-90'>{s.dateOfBirth ? `DOB: ${fn.formatDate(s.dateOfBirth)}` : null}</div>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            }) : null
                    }
                </ul>
            </div>
        </div>
    </>)
}

export { searchbarFocus, searchbarBlur };
export default Searchbar;