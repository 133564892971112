import React, { useContext, useRef, useEffect, useState } from 'react';
import { toJS } from 'mobx';
import { Observer } from 'mobx-react-lite';
import { GlobalHotKeys } from 'react-hotkeys';
import moment from 'moment';

import TemplateGrid from '../../_shared/TemplateGrid';
import { renderQuickDrawerLoading } from '../../_shared/QuickDrawer';

import InternalFormCreateStore from '../../../../stores/InternalFormCreateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';
import AuthStore from '../../../../stores/AuthStore';

import api from '../../../../api';

import * as fn from '../../../../utilities/_functions';
import * as ph from '../../../../utilities/personHelper';
import * as ch from '../../../../utilities/customerHelper';
import * as ith from '../../../../utilities/internalFormTemplateHelper';

import './_index.scss'

function InternalFormViewer(props) {
    const isMounted = useRef(true);
    const searchTimer = useRef(null);
    const notesTimer = useRef(null);
    const handleCancelInternalFormSave = useRef(null);
    const handleCancelNotes = useRef(null);
    const handleCancelWorkOrders = useRef(null);
    const handleCancelAppointments = useRef(null);
    const handleCancelExams = useRef(null);
    const internalForm = useContext(InternalFormCreateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const auth = useContext(AuthStore);
    const [customer, setCustomer] = useState(null);
    const [workOrders, setWorkOrders] = useState([]);
    const [workOrder, setWorkOrder] = useState(null);
    const [appointments, setAppointments] = useState([]);
    const [appointment, setAppointment] = useState(null);
    const [exams, setExams] = useState([]);
    const [exam, setExam] = useState(null);
    const [notes, setNotes] = useState(null);
    const [customerSearchTerm, setCustomerSearchTerm] = useState('');
    const [customerSearchResult, setCustomerSearchResult] = useState([]);
    const [display, setDisplay] = useState(props.display);
    const [ready, setReady] = useState(false);

    useEffect(() => {
        if (internalForm.customer) { setCustomer(toJS(internalForm.customer)) };
        if (internalForm.workOrder) { setWorkOrder(toJS(internalForm.workOrder)) };
        if (internalForm.appointment) { setAppointment(toJS(internalForm.appointment)) };
        if (internalForm.exam) { setExam(toJS(internalForm.exam)) };
        if (internalForm.notes) { setNotes(toJS(internalForm.notes)) };

        loadData();

        return () => {
            isMounted.current = false;
            if (searchTimer.current) { clearTimeout(searchTimer.current); searchTimer.current = null; }
            if (notesTimer.current) { clearTimeout(notesTimer.current); notesTimer.current = null; }
            if (handleCancelInternalFormSave.current) { handleCancelInternalFormSave.current(); handleCancelInternalFormSave.current = null; }
            if (handleCancelWorkOrders.current) { handleCancelWorkOrders.current(); handleCancelWorkOrders.current = null; }
            if (handleCancelAppointments.current) { handleCancelAppointments.current(); handleCancelAppointments.current = null; }
            if (handleCancelExams.current) { handleCancelExams.current(); handleCancelExams.current = null; }
            if (handleCancelNotes.current) { handleCancelNotes.current(); handleCancelNotes.current = null; }
        }
    }, []) // eslint-disable-line

    const loadData = () => {
        return new Promise((resolve, reject) => {
            if (internalForm.customer) {
                const request = [];

                if (hasWorkOrderFields()) {
                    request.push(
                        api.WorkOrders.search(
                            {
                                parameters: [
                                    { field: 'CustomerId', value: internalForm.customer.id, },
                                    { field: 'DeactivatedDateUtc', value: null, },
                                ],
                                sortByFields: [
                                    { field: 'CreatedDateUtc', direction: 'ASC', },
                                    { field: 'Number', direction: 'ASC', }
                                ],
                                includeTotalCount: false,
                                loadProperties: true,
                            },
                            (c) => { handleCancelWorkOrders.current = c })
                            .then(({ data }) => {
                                if (isMounted.current) {
                                    const workOrderData = data.result ? data.result : [];
                                    setWorkOrders(workOrderData);
                                }
                            })
                    );
                }

                if (hasAppointmentFields()) {
                    request.push(
                        api.Appointments.search(
                            {
                                parameters: [
                                    { field: 'CustomerId', value: internalForm.customer.id, },
                                    { field: 'DeactivatedDateUtc', value: null, },
                                ],
                                sortByFields: [
                                    { field: 'CreatedDateUtc', direction: 'ASC', },
                                ],
                                includeTotalCount: false,
                                loadProperties: true,
                            },
                            (c) => { handleCancelAppointments.current = c })
                            .then(({ data }) => {
                                if (isMounted.current) {
                                    const appointmentData = data.result ? data.result : [];
                                    setAppointments(appointmentData);
                                }
                            })
                    )
                }

                if (hasExamFields()) {
                    request.push(
                        api.Exams.search(
                            {
                                parameters: [
                                    { field: 'CustomerId', value: internalForm.customer.id, },
                                    { field: 'DeactivatedDateUtc', value: null, },
                                    { field: 'Status', operator: '!=', value: 'Deleted', },
                                ],
                                sortByFields: [
                                    { field: 'CreatedDateUtc', direction: 'ASC', },
                                ],
                                includeTotalCount: false,
                                loadProperties: true,
                            },
                            (c) => { handleCancelExams.current = c })
                            .then(({ data }) => {
                                if (isMounted.current) {
                                    const examData = data.result ? data.result : [];
                                    setExams(examData);
                                }
                            })
                    );
                }

                Promise.all(request)
                    .finally(() => {
                        setReady(true);
                        resolve();
                    })
            } else {
                setReady(true);
                resolve();
            }
        })
    }

    const handleClose = event => {
        if (!quickDrawer.context1 || !quickDrawer.action1) {
            document.querySelector('html').classList.remove('no-scroll');
        }
        if (props.onClose && fn.isFunction(props.onClose)) {
            props.onClose();
        }

        quickDrawer.deactivateAll();
        setDisplay(false);
    }

    const handleCustomerSearchChange = event => {
        const term = event.target.value;

        if (customer) {
            setCustomer(null);
            setWorkOrder(null);
            setWorkOrders(null);
            setAppointment(null);
            setAppointments(null);
            setExam(null);
            setExams(null);
            setNotes(null);
        }

        setCustomerSearchTerm(term);

        if (searchTimer.current) {
            clearTimeout(searchTimer.current);
        }

        if (term && term.length >= 2) {
            searchTimer.current = setTimeout(() => {
                api.Customers.fullSearch(term, false, false, 5)
                    .then(({ data }) => {
                        if (isMounted.current) {
                            setCustomerSearchResult(data.result);
                        }
                    })
            }, 500);
        } else {
            setCustomerSearchResult(null);
        }
    }

    const handleCustomerSearchClick = customer => {
        internalForm.customer = customer;
        internalForm.workOrder = null;
        internalForm.appointment = null;
        internalForm.exam = null;
        internalForm.notes = null;
        internalForm.hasUnsavedChanges = true;

        setCustomer(customer);
        loadData();

        api.Notes.search(
            {
                parameters: [
                    { field: 'CustomerId', value: internalForm.customer.id, },
                    { field: 'DeactivatedDateUtc', value: null, },
                ],
                sortByFields: [
                    { field: 'CreatedDateUtc', direction: 'ASC', },
                ],
                includeTotalCount: false,
                loadProperties: true,
            },
            (c) => { handleCancelNotes.current = c })
            .then(({ data }) => {
                if (isMounted.current) {
                    let noteData = data.result ? data.result : [];
                    noteData = noteData.sort((a, b) => { return new Date(a.createdDateUtc).getTime() - new Date(b.createdDateUtc).getTime() });

                    internalForm.notes = noteData;
                    setNotes(noteData);
                }
            })
    }

    const handleWorkOrderChange = event => {
        const id = event.target.value;
        const selectedWorkOrder = workOrders.filter(o => o.id === id)[0];

        internalForm.workOrder = selectedWorkOrder;
        internalForm.notes = notes;
        internalForm.hasUnsavedChanges = true;
        setWorkOrder(selectedWorkOrder);
    }

    const handleAppointmentChange = event => {
        const id = event.target.value;
        const selectedAppointment = appointments.filter(o => o.id === id)[0];

        internalForm.appointment = selectedAppointment;
        internalForm.notes = notes;
        internalForm.hasUnsavedChanges = true;
        setAppointment(selectedAppointment);
    }

    const handleExamChange = event => {
        const id = event.target.value;
        const selectedExam = exams.filter(o => o.id === id)[0];

        internalForm.exam = selectedExam;
        internalForm.notes = notes;
        internalForm.hasUnsavedChanges = true;
        setWorkOrder(selectedExam);
    }
    
    const handleSelectedPageIndexChange = (event, index) => {
        internalForm.selectedPageIndex = index;
        internalForm.hasUnsavedChanges = true;
    }

    const handleDataChange = ({ type }) => {
        switch (type) {
            case 'SingleLineText':
            case 'MultiLineText':
            case 'Dropdown':
                return handleInputChange;

            case 'Checkbox':
                return handleCheckboxChange;

            case 'Circle':
            case 'Check':
                return handleSelectableChange;

            default:
                return () => { };
        }
    }

    const handleInputChange = (event, key) => {
        const value = event.target.value;
        setValue(key, value);
    }

    const handleCheckboxChange = (event, key) => {
        const value = event.target.value;
        const originalValue = getValue(key);
        const originalArray = originalValue ? originalValue.split('|') : [];
        const index = originalArray.findIndex(o => o === value);

        if (index >= 0) {
            originalArray.splice(index, 1);
        } else {
            originalArray.push(value);
        }

        setValue(key, originalArray.join('|'));
    }

    const handleSelectableChange = (event, key) => {
        const originalValue = !!getValue(key);
        setValue(key, !originalValue);
    }

    const handleCompleteForm = (event) => {
        let printElements = [];

        for (let p = 0; p < internalForm.selectedTemplate.pages.length; p++) {
            if (internalForm.selectedTemplate.pages[p].headerDefinition && internalForm.selectedTemplate.pages[p].headerDefinition.length > 0) {
                const headerElements = internalForm.selectedTemplate.pages[p].headerDefinition.filter(o => ith.isInternalFormElement(o));
                printElements = printElements.concat(headerElements);
            }
            if (internalForm.selectedTemplate.pages[p].bodyDefinition && internalForm.selectedTemplate.pages[p].bodyDefinition.length > 0) {
                const bodyElements = internalForm.selectedTemplate.pages[p].bodyDefinition.filter(o => ith.isInternalFormElement(o));
                printElements = printElements.concat(bodyElements);
            }
            if (internalForm.selectedTemplate.pages[p].footerDefinition && internalForm.selectedTemplate.pages[p].footerDefinition.length > 0) {
                const footerElements = internalForm.selectedTemplate.pages[p].footerDefinition.map(o => ith.isInternalFormElement(o));
                printElements = printElements.concat(footerElements);
            }
        }

        if (printElements && printElements.length > 0) {
            const sources = {
                tenant: auth.currentTenant,
                customer: internalForm.customer,
                workOrder: internalForm.workOrder,
                appointment: internalForm.appointment,
                exam: internalForm.exam,
                notes: internalForm.notes,
            };

            for (let e = 0; e < printElements.length; e++) {
                if (!internalForm.data.some(d => d.id === printElements[e].id)) {
                    const elementValue = ith.getValue(printElements[e], sources);

                    if (elementValue) {
                        internalForm.data.push({ id: printElements[e].id, value: elementValue });
                    }
                }
            }
        }

        internalForm.save()
            .then((data) => {
                props.onSaved(event, {
                    status: 'success',
                    id: data.id,
                    name: internalForm.selectedTemplate.name
                });
            })
            .catch((error) => {
                if (isMounted.current) {
                    if (props.onSaved && fn.isFunction(props.onSaved)) {
                        props.onSaved(event, { status: 'error', error: error });
                    }
                }
            });
    }

    const renderContentDefinition = (selectedPage, definition) => {
        if (isMounted.current) {
            const options = {
                selectedPage: selectedPage,
                definition: definition,
                getValue: getValue,
                getControlValue: getControlValue,
                getPrintElementValue: getPrintElementValue,
                handleDataChange: handleDataChange,
            }
            return ith.renderContentDefinition(options);
        }
    }

    const setValue = (key, value) => {
        if (typeof (value) === 'undefined') { value = null; }

        const index = internalForm.data.findIndex(d => d.id === key);

        if (index >= 0) {
            internalForm.data[index].value = value;
            internalForm.hasUnsavedChanges = true;
        } else {
            internalForm.data.push({ id: key, value: value });
        }
    }

    const getPrintElementValue = (element) => {
        const sources = {
            tenant: auth.currentTenant,
            customer: internalForm.customer,
            workOrder: internalForm.workOrder,
            appointment: internalForm.appointment,
            exam: internalForm.exam,
            notes: internalForm.notes,
        };

        return ith.getValue(element, sources);
    }

    const getValue = key => {
        const data = internalForm.data.filter(d => d.id === key);
        if (data && data.length > 0) {
            return data[0].value;
        }

        return null;
    }

    const getControlValue = control => {
        return 'null';
    }

    const getCustomerName = () => {
        if (customer) {
            return ph.getLastFirstName(customer);
        } else {
            return customerSearchTerm ? customerSearchTerm : '';
        }
    }

    const hasActionFields = () => {
        if (fn.isNullOrUndefined((props.hideActionFields) || props.hideActionFields !== false)) {
            return hasCustomerFields() || hasWorkOrderFields() || hasAppointmentFields() || hasExamFields();
        }

        return false;
    }

    const hasCustomerFields = () => {
        if (internalForm.selectedTemplate) {
            for (let i = 0; i < internalForm.selectedTemplate.pages.length; i++) {
                if (internalForm.selectedTemplate.pages[i].headerDefinition.some(d => ith.isCustomerField(d.type)) ||
                    internalForm.selectedTemplate.pages[i].bodyDefinition.some(d => ith.isCustomerField(d.type)) ||
                    internalForm.selectedTemplate.pages[i].footerDefinition.some(d => ith.isCustomerField(d.type))) {
                    return true;
                }
            }
        }

        return false;
    }

    const hasWorkOrderFields = () => {
        if (internalForm.selectedTemplate) {
            for (let i = 0; i < internalForm.selectedTemplate.pages.length; i++) {
                if (internalForm.selectedTemplate.pages[i].headerDefinition.some(d => ith.isWorkOrderField(d.type)) ||
                    internalForm.selectedTemplate.pages[i].bodyDefinition.some(d => ith.isWorkOrderField(d.type)) ||
                    internalForm.selectedTemplate.pages[i].footerDefinition.some(d => ith.isWorkOrderField(d.type))) {
                    return true;
                }
            }
        }

        return false;
    }

    const hasAppointmentFields = () => {
        return false;
    }

    const hasExamFields = () => {
        return false;
    }

    return <Observer>{() => <>
        {
            internalForm.isSelectedTemplateReady ?
                <GlobalHotKeys
                    keyMap={{
                        close: ['esc'],
                    }}
                    handlers={{
                        close: event => {
                            handleClose();
                        },
                    }}
                    allowChanges={true}
                /> : null
        }
        <div
            className={'panel panel-fullscreen internal-form-viewer' + (display ? '' : ' d-none')}
            style={{ zIndex: fn.getHighestZIndex() }}
        >
            <div className='panel-hdr bg-alt-800'>
                <div className='flex-1'></div>
                <div className='flex-1'>
                    {
                        internalForm.isSelectedTemplateReady ?
                            <h3 className='text-white text-center mb-0'>
                                {internalForm.selectedTemplate.name}
                            </h3> : null
                    }
                </div>
                <div className='panel-toolbar flex-1 justify-content-end'>
                    <div className='mr-4'>
                        <button
                            type='button'
                            className='btn btn-info'
                            onClick={handleCompleteForm}
                        >
                            Done
                        </button>
                    </div>
                    <button
                        className='btn btn-icon'
                        onClick={handleClose}
                        title='Close'
                    >
                        <i className='fal fa-times fs-xl'></i>
                    </button>
                </div>
            </div>
            <div className='panel-container show'>
                <div className={'panel-content' + (hasActionFields() ? ' has-action' : ' no-action')}>
                    <div className='panel-template'>
                        <ul
                            className='nav nav-tabs'
                            style={{ width: ith.getPageStyle(internalForm.selectedPage.pageSettings).width }}
                            role='tablist'
                        >
                            {
                                internalForm.isSelectedTemplateReady ?
                                    <>
                                        {
                                            internalForm.selectedTemplate.pages.map((p, pi) => {
                                                return <li
                                                    key={`internal-form-tab-${pi}`}
                                                    className='nav-item'
                                                >
                                                    <Observer>{() =>
                                                        <div
                                                            className={'nav-link' + (p.id === internalForm.selectedPage.id ? ' active' : '')}
                                                            role='tab'
                                                        >
                                                            <button
                                                                type='button'
                                                                className={'btn btn-link p-0' + (p.id === internalForm.selectedPage.id ? '' : ' text-white')}
                                                                onClick={(e) => { handleSelectedPageIndexChange(e, pi) }}
                                                            >
                                                                Page {(p.displayOrder + 1)}
                                                            </button>
                                                        </div>
                                                    }
                                                    </Observer>
                                                </li>
                                            })
                                        }
                                    </> : <li className='nav-item'><div className='nav-link active'><button type='button' className='btn btn-link p-0'>Page 1</button></div></li>
                            }
                        </ul>
                        <Observer>{() =>
                            <>
                                <div
                                    className='tab-content bg-white border border-top-0'
                                    style={ith.getPageContentStyle(internalForm.selectedPage.pageSettings)}
                                >
                                    <div className={'tab-pane bg-white active'}>
                                        <div
                                            className='flex-1 form-template internal-form-template'
                                            style={ith.getPageStyle(internalForm.selectedPage.pageSettings)}
                                        >
                                            {
                                                internalForm.isSelectedTemplateReady ?
                                                    <>
                                                        {
                                                            internalForm.selectedPage.pageSettings.header ?
                                                                <div
                                                                    className='page-template-header'
                                                                    style={ith.getHeaderFooterTemplateContainerStyle(internalForm.selectedPage.pageSettings)}
                                                                >
                                                                    <TemplateGrid
                                                                        isEditable={false}
                                                                        cols={ith.getTemplateCols(internalForm.selectedPage.pageSettings)}
                                                                        rowHeight={ith.INTERNAL_FORM_TEMPLATE_ROW_HEIGHT}
                                                                        margin={[0, 0]}
                                                                        style={ith.getHeaderTemplateGridStyle(internalForm.selectedPage.pageSettings)}
                                                                    >
                                                                        {renderContentDefinition(internalForm.selectedPage, internalForm.selectedPage.headerDefinition)}
                                                                    </TemplateGrid>
                                                                </div> : null
                                                        }
                                                        <TemplateGrid
                                                            isEditable={false}
                                                            cols={ith.getTemplateCols(internalForm.selectedPage.pageSettings)}
                                                            rowHeight={ith.INTERNAL_FORM_TEMPLATE_ROW_HEIGHT}
                                                            margin={[0, 0]}
                                                            style={ith.getBodyTemplateGridStyle(internalForm.selectedPage.pageSettings)}
                                                        >
                                                            {renderContentDefinition(internalForm.selectedPage, internalForm.selectedPage.bodyDefinition)}
                                                        </TemplateGrid>
                                                        {
                                                            internalForm.selectedPage.pageSettings.footer ?
                                                                <div
                                                                    className='page-template-footer'
                                                                    style={ith.getHeaderFooterTemplateContainerStyle(internalForm.selectedPage.pageSettings)}
                                                                >
                                                                    <TemplateGrid
                                                                        isEditable={false}
                                                                        cols={ith.getTemplateCols(internalForm.selectedPage.pageSettings)}
                                                                        rowHeight={ith.INTERNAL_FORM_TEMPLATE_ROW_HEIGHT}
                                                                        margin={[0, 0]}
                                                                        style={ith.getFooterTemplateGridStyle(internalForm.selectedPage.pageSettings)}
                                                                    >
                                                                        {renderContentDefinition(internalForm.selectedPage, internalForm.selectedPage.footerDefinition)}
                                                                    </TemplateGrid>
                                                                </div> : null
                                                        }
                                                    </> : renderQuickDrawerLoading()
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        </Observer>
                    </div>
                    <div className={'panel-action' + (hasActionFields() ? ' show' : '')}>
                        {
                            ready ?
                                <section>
                                    {
                                        hasWorkOrderFields() ?
                                            <div className='row customer'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label htmlFor='new-internal-form-customer'><small>Customer</small></label>
                                                        <div className='dropdown'>
                                                            <i className={'fal fa-check customer-status' + (internalForm.customer ? ' customer-status-selected' : '')}></i>
                                                            <input
                                                                id='new-internal-form-customer'
                                                                type='text'
                                                                className='form-control mb-0 dropdown-toggle'
                                                                spellCheck={false}
                                                                autoComplete='off'
                                                                value={getCustomerName()}
                                                                onChange={handleCustomerSearchChange}
                                                            />
                                                            <ul className='dropdown-menu'>
                                                                {
                                                                    customerSearchResult && customerSearchResult.length > 0 ?
                                                                        customerSearchResult.slice(0, 5).map((s, si) => {
                                                                            return <li
                                                                                key={`search_result_${si}`}
                                                                                className={'dropdown-menu-item'}>
                                                                                <div
                                                                                    className='profile-wrapper'
                                                                                    onClick={() => handleCustomerSearchClick(s)}
                                                                                >
                                                                                    <div className='profile'>
                                                                                        <span
                                                                                            className={`profile-image profile-image-md profile-initials rounded-circle text-white ${ch.getProfileColor(s)}`}
                                                                                            title={ph.getPreferredFullName(s)}
                                                                                        >
                                                                                            {s.initials}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className='description'>
                                                                                        <div className='name'>{ph.getLastFirstName(s, true)}
                                                                                            {
                                                                                                s.dateOfBirth || s.sex || s.gender || s.pronoun ?
                                                                                                    <small className='ml-2 text-gray'>({`${ph.getAge(s.dateOfBirth)} ${ph.getSexGenderPronounDisplay(s)}`.trim()})</small> : null
                                                                                            }
                                                                                        </div>
                                                                                        <div className='info'>{s.address && s.address.addressLine1 ? s.address.addressLine1 : null}</div>
                                                                                        <div className='info fs-90'>{s.dateOfBirth ? `DOB: ${fn.formatDate(s.dateOfBirth)}` : null}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        }) : null
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        hasAppointmentFields() ?
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label htmlFor='new-internal-form-appointment'><small>Appointment</small></label>
                                                        <select
                                                            id='new-internal-form-appointment'
                                                            className='custom-select form-control'
                                                            value={appointment ? appointment.id : ''}
                                                            onChange={handleAppointmentChange}
                                                        >
                                                            <option value=''></option>
                                                            {
                                                                appointments ?
                                                                    appointments.map((o, oi) => {
                                                                        return <option
                                                                            key={`new_internal_form_appointment_${oi}`}
                                                                            value={o.id}
                                                                        >Appointment on {fn.formatDate(moment(o.start), 'YYYY-MM-DD')}</option>
                                                                    }) : null
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        hasExamFields() ?
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label htmlFor='new-internal-form-exam'><small>Exam</small></label>
                                                        <select
                                                            id='new-internal-form-exam'
                                                            className='custom-select form-control'
                                                            value={exam ? exam.id : ''}
                                                            onChange={handleExamChange}
                                                        >
                                                            <option value=''></option>
                                                            {
                                                                exams ?
                                                                    exams.map((o, oi) => {
                                                                        return <option
                                                                            key={`new_internal_form_exam_${oi}`}
                                                                            value={o.id}
                                                                        >Exam on {fn.formatDate(moment.utc(o.actualStartUtc).local(), 'YYYY-MM-DD')}</option>
                                                                    }) : null
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        hasWorkOrderFields() ?
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label htmlFor='new-internal-form-work-order'><small>Work Order</small></label>
                                                        <select
                                                            id='new-internal-form-work-order'
                                                            className='custom-select form-control'
                                                            value={workOrder ? workOrder.id : ''}
                                                            onChange={handleWorkOrderChange}
                                                        >
                                                            <option value=''></option>
                                                            {
                                                                workOrders ?
                                                                    workOrders.map((o, oi) => {
                                                                        return <option
                                                                            key={`new_internal_form_work_order_${oi}`}
                                                                            value={o.id}
                                                                        >Work order # {o.number}</option>
                                                                    }) : null
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                </section> : null
                        }
                    </div>
                </div>
            </div>
        </div>
    </>}</Observer>
}

export default InternalFormViewer;