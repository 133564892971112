import React, { useContext, useEffect, useRef, useState } from 'react';
import { toJS } from 'mobx';
import { Observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import FadeIn from 'react-fade-in';
import { GlobalHotKeys } from 'react-hotkeys';

import { quickDrawerFocus } from '../../_shared/QuickDrawer';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';

import ProductTypeCreateStore from '../../../../stores/ProductTypeCreateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as oh from '../../../../utilities/operationHelper';

import api from '../../../../api';

import './NewProductType.scss';

function NewProductType(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const productTypeRef = useRef(null);
    const validateRef = useRef(null);
    const cancelProductDeleteSearch = useRef(null);
    const productType = useContext(ProductTypeCreateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [isProductTypeChecked, setIsProductTypeChecked] = useState(false);

    useEffect(() => {
        quickDrawerFocus(props.drawer);

        return () => {
            isMounted.current = false;
            if (cancelProductDeleteSearch.current && fn.isFunction(cancelProductDeleteSearch.current)) { cancelProductDeleteSearch.current(); cancelProductDeleteSearch.current = null; }
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (productType.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleNameChange = event => {
        productType.data.name = event.target.value ? event.target.value : null;
        productType.hasUnsavedChanges = true;
    }

    const handleCheckProductType = event => {
        const { name } = productType.data;
        const searchName = name ? name.trim() : null;

        if (!isProductTypeChecked) {
            if (searchName) {
                productType.load(searchName)
                    .then(() => {
                        setIsProductTypeChecked(true);
                    })
            }
        }
    }

    const handleQuantityChange = event => {
        const quantity = parseInt(event.target.value, 10);
        productType.data.defaultQuantity = quantity;
        productType.hasUnsavedChanges = true;
    }

    const handleWorkOrderPublishedTemplateChange = event => {
        const workOrderPublishedTemplateId = event.target.value;
        productType.data.workOrderPublishedTemplateId = workOrderPublishedTemplateId;
        productType.hasUnsavedChanges = true;
    }

    const handleExtraDataChange = (event, index) => {
        productType.data.extraData[index].name = event.target.value;
        productType.hasUnsavedChanges = true;
    }

    const handleAddExtraData = event => {
        event.preventDefault();
        productType.data.extraData.push({ originalData: '', data: '' });
        focusTimer.current = setTimeout(() => {
            const element = document.querySelector(`#product-type-extra-data-${(productType.data.extraData.length - 1)}`)
            if (element) { element.focus(); }
        }, 50);
        productType.hasUnsavedChanges = true;
    }

    const handleRemoveExtraData = (option, index) => {
        productType.data.extraData = productType.data.extraData.filter((d, di) => di !== index);
        productType.hasUnsavedChanges = true;
    }

    const handleMoveExtraData = (from, to) => {
        const extraData = toJS([...productType.data.extraData]);
        const element = extraData[from];

        extraData.splice(from, 1);
        extraData.splice(to, 0, element);

        productType.data.extraData = extraData;
        productType.hasUnsavedChanges = true;
    }

    const handleDelete = event => {
        api.Products.search(
            {
                parameters: [{
                    field: 'TypeId',
                    value: productType.data.id,
                }, {
                    field: 'DeactivatedDateUtc',
                    value: null,
                }],
                includeTotalCount: true,
                includeResult: false,
            },
            (c) => { cancelProductDeleteSearch.current = c })
            .then(({ data }) => {
                if (isMounted.current) {
                    if (data && data.total > 0) {
                        toast.error(() => <p><strong>Cannot delete.</strong> There are still product(s) associated to {productType.data.name}.</p>, { position: 'top-center', style: { width: '380px' } });
                    } else {
                        productType.delete()
                            .then(() => {
                                if (isMounted.current) {
                                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                                        props.onSuccess(event, { isUpdated: true });
                                    }
                                }
                            })
                    }
                }
            })
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            if (isMounted.current) {
                productType.save(true)
                    .then(data => {
                        if (isMounted.current) {
                            if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                                props.onSuccess(event, { isUpdated: true, data: data });
                            }
                        }
                    })
            }
        }
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <fieldset disabled={productType.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('product', 'type')}
                        action={productType.data.id ? 'Update' : 'Add New'}
                        category='Product Category'
                        className='products'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        <FadeIn>
                            <div className='new-product-type body-content'>
                                <Observer>{() =>
                                    <section>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div ref={productTypeRef} className='form-group mb-0 validate validate-required'>
                                                    <label htmlFor='new-product-type-name-input' className='required'><small>Name</small></label>
                                                    <input
                                                        id='new-product-type-name-input'
                                                        type='text'
                                                        className='form-control'
                                                        spellCheck={false}
                                                        maxLength='150'
                                                        autoComplete='off'
                                                        disabled={productType.isLoading}
                                                        value={productType.data && productType.data.name ? productType.data.name : ''}
                                                        onChange={handleNameChange}
                                                        onBlur={handleCheckProductType}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                }</Observer>
                                <Observer>{() => <>
                                    {
                                        !productType.isLoading && isProductTypeChecked ?
                                            <FadeIn>
                                                <Observer>{() =>
                                                    <section className='mt-4'>
                                                        <div className='row'>
                                                            <div className='col-4'>
                                                                <div className='form-group mb-0 validate validate-required'>
                                                                    <label htmlFor='new-product-type-quantity-input' className='required'><small>Default Quantity</small></label>
                                                                    <input
                                                                        id='new-product-type-quantity-input'
                                                                        type='number'
                                                                        className='form-control'
                                                                        min={1}
                                                                        step={1}
                                                                        value={productType.data && productType.data.defaultQuantity ? productType.data.defaultQuantity : 1}
                                                                        onChange={handleQuantityChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                }</Observer>
                                                <Observer>{() =>
                                                    <section className='mt-4'>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className='form-group mb-0'>
                                                                    <label htmlFor='new-product-type-template'><small>Work Order Template</small></label>
                                                                    <select
                                                                        id='new-product-type-template'
                                                                        className='custom-select form-control'
                                                                        value={productType.data.workOrderPublishedTemplateId ? productType.data.workOrderPublishedTemplateId : ''}
                                                                        onChange={handleWorkOrderPublishedTemplateChange}
                                                                    >
                                                                        <option value=''>(None)</option>
                                                                        {
                                                                            productType.workOrderPublishedTemplates.map((t, ti) => {
                                                                                return <option key={`product_type_template_${ti}`} value={t.id}>{t.name}</option>
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                }</Observer>
                                                <Observer>{() =>
                                                    <section className='mt-4'>
                                                        <div className='row extra-data'>
                                                            <div className='col-12'>
                                                                <div className='form-group mb-0'>
                                                                    <label><small>Extra Data</small></label>
                                                                    {
                                                                        productType.data && productType.data.extraData && productType.data.extraData.length > 0 ?
                                                                            productType.data.extraData.map((d, di) => {
                                                                                return <ul
                                                                                    key={`product_type_extra_data_${di}`}
                                                                                    className='list-inline no-style m-0 mb-2'
                                                                                >
                                                                                    <li className='list-inline-item m-0 mr-1'>{di + 1}.&nbsp;</li>
                                                                                    <li className='list-inline-item m-0 data validate validate-required'>
                                                                                        <input
                                                                                            id={`product-type-extra-data-${di}`}
                                                                                            type='text'
                                                                                            className='form-control'
                                                                                            autoComplete='off'
                                                                                            value={d.name}
                                                                                            onChange={(e) => handleExtraDataChange(e, di)}
                                                                                        />
                                                                                    </li>
                                                                                    <li className='list-inline-item m-0 ml-2'>
                                                                                        <button
                                                                                            type='button'
                                                                                            className='btn btn-link btn-icon btn-sm'
                                                                                            autoComplete='off'
                                                                                            onClick={e => { handleRemoveExtraData(d, di) }}
                                                                                        >
                                                                                            <i className={'fal fa-minus-circle text-danger'}></i>
                                                                                        </button>
                                                                                    </li>
                                                                                    <li className='list-inline-item m-0'>
                                                                                        <button
                                                                                            type='button'
                                                                                            className='btn btn-link btn-icon btn-sm'
                                                                                            autoComplete='off'
                                                                                            disabled={productType.data.extraData.length === 1 || di === 0}
                                                                                            onClick={e => { handleMoveExtraData(di, (di - 1)) }}
                                                                                        >
                                                                                            <i className={'fal fa-arrow-up' + (productType.data.extraData.length === 1 || di === 0 ? ' text-gray-500' : ' text-primary')}></i>
                                                                                        </button>
                                                                                    </li>
                                                                                    <li className='list-inline-item m-0'>
                                                                                        <button
                                                                                            type='button'
                                                                                            className='btn btn-link btn-icon btn-sm'
                                                                                            disabled={productType.data.extraData.length === 1 || di === (productType.data.extraData.length - 1)}
                                                                                            onClick={e => { handleMoveExtraData(di, (di + 1)) }}
                                                                                        >
                                                                                            <i className={'fal fa-arrow-down' + (productType.data.extraData.length === 1 || di === (productType.data.extraData.length - 1) ? ' text-gray-500' : ' text-primary')}></i>
                                                                                        </button>
                                                                                    </li>
                                                                                </ul>
                                                                            }) : <div></div>
                                                                    }
                                                                    <button
                                                                        type='button'
                                                                        className='btn btn-link btn-icon-left btn-sm'
                                                                        onClick={handleAddExtraData}
                                                                    >
                                                                        <i className='fal fa-plus-circle'></i> Add extra data
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                }</Observer>
                                            </FadeIn> : null
                                    }
                                </>
                                }</Observer>
                            </div>
                        </FadeIn>
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    {
                                        productType.data.id ?
                                            <button
                                                type='button'
                                                className='btn btn-danger mr-2'
                                                onClick={handleDelete}
                                            >
                                                Delete
                                            </button> :
                                            <button
                                                type='button'
                                                className='btn btn-link btn-cancel mr-2'
                                                onClick={handleCancel}
                                            >
                                                Cancel
                                            </button>
                                    }
                                    <button
                                        data-submit-save-product-type
                                        type='submit'
                                        className='btn btn-success'
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form >
    </>
}

export default NewProductType;