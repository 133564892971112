import React, { useEffect, useContext, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { useObserver } from 'mobx-react-lite';
import { GlobalHotKeys } from 'react-hotkeys';

import QuickDrawerHeader from '../../../../_shared/QuickDrawerHeader';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../../../_shared/QuickDrawer';
import AddressInput from '../../../../_shared/AddressInput';

import OrganizationCreateUpdateStore from '../../../../../../stores/OrganizationCreateUpdateStore';
import AuthStore from '../../../../../../stores/AuthStore';
import QuickDrawerStore from '../../../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../../../constants/errorMessages';
import * as fn from '../../../../../../utilities/_functions';
import * as sys from '../../../../../../utilities/systemHelper';
import * as oh from '../../../../../../utilities/operationHelper';

import './UpdateOrganizationAddress.scss';

function UpdateOrganizationAddress(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const focusTimer = useRef(null);
    const mailingAddressRef = useRef(null);
    const billingAddressRef = useRef(null);
    const organization = useContext(OrganizationCreateUpdateStore);
    const auth = useContext(AuthStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const cachedAddressRef = useRef(null);

    useEffect(() => {
        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100)

        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleMailingAddressChange = (data) => {
        organization.newAddress = data;
        organization.hasAddressUnsavedChanges = true;
    }

    const handleBillingAddressChange = (data) => {
        organization.newBillingAddress = data;
        organization.hasAddressUnsavedChanges = true;
    }

    const handleCancel = () => {
        if (cachedAddressRef.current)
            organization.data.address = cachedAddressRef.current;

        organization.clearNewAddress();
        if (fn.isFunction(props.onCancel)) {
            if (organization.hasAddressUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current) && mailingAddressRef.current.validate() && billingAddressRef.current.validate()) {
            if (organization.hasAddressUnsavedChanges) {
                const updated = organization.hasAddressUnsavedChanges;

                if (organization.data.id) {
                    // if this is an existing org, then call backend to update address. Otherwise, leave it to the "Create" to create the address
                    organization.updateAddressInformation()
                        .then(data => {
                            if (isMounted.current) {
                                if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                                    props.onSuccess(event, { updated: updated, data: data });
                                }
                            }
                        });
                }
                else {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, { updated: updated, data: null });
                    }
                }
            } else {
                if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                    props.onSuccess(event, { updated: false, data: null });
                }
            }
        }
    }

    const renderOrganization = () => {
        return <div
            className='profile-wrapper'
        >
            <div className='profile'>
                <span
                    className={`d-flex bg-color${organization.data.color}-500 fw-500`}
                    title={organization.data.legalName}
                >
                    {organization.data.legalName}
                </span>
            </div>
            <div className='description flex-1'>
                {
                    organization.data.emailAddress ?
                        <div className='info'>
                            <a
                                href={`mailto:${organization.data.emailAddress}`}
                            >{organization.data.emailAddress}
                            </a>
                        </div> : null
                }
                {
                    organization.data.phoneNumber ?
                        <div className='info'>
                            <a
                                href={`tel:${organization.data.phoneNumber}`}
                            >{sys.getFormattedPhoneNumber(organization.data.phoneNumber)}
                            </a>
                        </div> : null
                }
            </div>
        </div>
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <fieldset disabled={organization.isSaving}>
                <div className='update-organization-address-container quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('organization', 'address')}
                        action='Update Organization'
                        category='Address'
                        className='organizations'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        {
                            organization.isReady && organization.data ?
                                <FadeIn>
                                    <div className='body-content'>
                                        <section className='organization'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0'>
                                                        <label><small>Organization</small></label>
                                                        {renderOrganization()}
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <AddressInput
                                                ref={mailingAddressRef}
                                                address={(organization.data && organization.data.address ? organization.data.address : {})}
                                                label={'Mailing address'}
                                                required={true}
                                                defaultCountryCode={(auth.currentUser && auth.currentTenant && auth.currentTenant.address ? auth.currentTenant.address.countryCode : null)}
                                                onChange={handleMailingAddressChange}
                                            />
                                        </section>
                                        <section>
                                            <AddressInput
                                                ref={billingAddressRef}
                                                address={(organization.data && organization.data.billingAddress ? organization.data.billingAddress : {})}
                                                label={'Billing address'}
                                                required={true}
                                                defaultCountryCode={(auth.currentUser && auth.currentTenant && auth.currentTenant.address ? auth.currentTenant.address.countryCode : null)}
                                                onChange={handleBillingAddressChange}
                                            />
                                        </section>
                                    </div>
                                </FadeIn> : renderQuickDrawerLoading()
                        }
                    </div>
                    <div className='quick-drawer-action pl-3'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>)
}

export default UpdateOrganizationAddress;
