export const PAPER_LETTER_WIDTH = 816;
export const PAPER_LETTER_HEIGHT = 1056;
export const PAPER_A4_WIDTH = 796.8;
export const PAPER_A4_HEIGHT = 1123.2;
export const PAPER_LEGAL_WIDTH = 816;
export const PAPER_LEGAL_HEIGHT = 1344;
export const MARGINS_NARROW = 30;
export const MARGINS_NORMAL = 60;
export const MARGINS_WIDE = 90;
export const HEADER_FOOTER_HEIGHT_MIN = 30;
export const HEADER_FOOTER_HEIGHT_DEFAULT = 90;
export const HEADER_FOOTER_HEIGHT_MAX = 150;