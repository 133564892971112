import React, { useEffect, useContext, useState, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { useObserver } from 'mobx-react-lite';
import { GlobalHotKeys } from 'react-hotkeys';
import moment from 'moment';

import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';

import ExamViewStore from '../../../../stores/ExamViewStore';
import PredefinedExamSelectStore from '../../../../stores/PredefinedExamSelectStore';
import PreviousExamSelectStore from '../../../../stores/PreviousExamSelectStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as fn from '../../../../utilities/_functions';
import * as aeh from '../../../../utilities/appointmentHelper';
import * as oh from '../../../../utilities/operationHelper';

import './PrefillExam.scss';
import BodyEnd from '../../_shared/BodyEnd';
import ConfirmModal from '../../_shared/ConfirmModalComponent';

function PrefillExam(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const confirmModalRef = useRef(null);
    const exam = useContext(ExamViewStore);
    const predefinedExam = useContext(PredefinedExamSelectStore);
    const previousExam = useContext(PreviousExamSelectStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [prefillExamMethod, setPrefillExamMethod] = useState('predefined');
    const [selectedPredefinedExam, setSelectedPredefinedExam] = useState(null);
    const [selectedPreviousExam, setSelectedPreviousExam] = useState(null);
    const [clearExamData, setClearExamData] = useState(false);

    useEffect(() => {
        quickDrawerFocus(props.drawer);

        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleSubmit = event => {
        event.preventDefault();

        if ((!!selectedPredefinedExam || !!selectedPreviousExam) && prefillExamMethod !== 'previousSection' && clearExamData) {
            confirmModalRef.current.show({
                icon: <i className={`${oh.getIcon('exam', 'prefill')} text-danger mr-2`}></i>,
                message: <>You are about to&nbsp;<strong>delete all existing exam data</strong>.  Continue?</>,
                descriptionClassName: 'warning-danger warning-flashing',
                option1ClassName: 'btn btn-danger shadow-0 bootbox-accept',
                option1Text: 'Continue',
                cancelText: 'Cancel',
                onOption1Click: handleClearAndPrefill,
                onCancel: handleClearAndPrefillCancel
            })
        }
        else {
            handlePrefill(event);
        }
    }

    const handleClearAndPrefill = (event) => {
        for (let i = 0; i < exam.data.examData.length; i++) {
            exam.data.examData[i].value = null;
        }
        confirmModalRef.current.close();
        exam.hasUnsavedChanges = true;
        handlePrefill(event);
    }

    const handleClearAndPrefillCancel = () => {
        confirmModalRef.current.close();
    }

    const handlePrefill = (event) => {
        switch (prefillExamMethod) {
            case 'predefined':
                if (selectedPredefinedExam) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, { type: 'predefined', data: selectedPredefinedExam });
                    }
                } else {
                    handlePredefinedExam();
                }
                break;

            case 'previous':
                if (selectedPreviousExam) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, { type: 'previous', data: selectedPreviousExam });
                    }
                } else {
                    handlePreviousExam();
                }
                break;

            case 'previousSection':
                if (selectedPreviousExam && props.extraProps.selectedSection) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, { type: 'previousSection', data: selectedPreviousExam, section: props.extraProps.selectedSection });
                    }
                } else {
                    handlePreviousSection();
                }
                break;

            default:
                break;
        }
    }

    const handlePredefinedExam = () => {
        if (!predefinedExam.isReady) { predefinedExam.initialize(exam.data.appointment, exam.data.examTemplateId); }
        quickDrawer.activateQuickDrawer('exam', 'predefined', null, handlePredefinedExamSuccess, handlePredefinedExamCancel)
            .then(drawer => {
                if (isMounted.current) {
                    focusTimer.current = setTimeout(() => {
                        quickDrawerFocus(drawer);
                    }, 100);
                }
            });
    }

    const handlePredefinedExamSuccess = (data) => {
        setSelectedPredefinedExam(data);
    }

    const handlePredefinedExamCancel = () => {
    }

    const handlePreviousExam = () => {
        if (!previousExam.isReady) { previousExam.initialize(exam.data.appointment); }
        quickDrawer.activateQuickDrawer('exam', 'previous', null, handlePreviousExamSuccess, handlePreviousExamCancel)
            .then(drawer => {
                if (isMounted.current) {
                    focusTimer.current = setTimeout(() => {
                        quickDrawerFocus(drawer);
                    }, 100);
                }
            });
    }

    const handlePreviousExamSuccess = (data) => {
        setSelectedPreviousExam(data);
    }

    const handlePreviousExamCancel = () => {
    }

    const handlePreviousSection = () => {
        if (!previousExam.isReady) { previousExam.initialize(exam.data.appointment); }
        quickDrawer.activateQuickDrawer('exam', 'previous', null, handlePreviousSectionSuccess, handlePreviousSectionCancel)
            .then(drawer => {
                if (isMounted.current) {
                    focusTimer.current = setTimeout(() => {
                        quickDrawerFocus(drawer);
                    }, 100);
                }
            });
    }

    const handlePreviousSectionSuccess = (data) => {
        setSelectedPreviousExam(data);
    }

    const handlePreviousSectionCancel = () => {
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                props.onCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form onSubmit={handleSubmit}>
            <fieldset disabled={exam.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('exam', 'prefill')}
                        action='Prefill'
                        category='Exam'
                        className='exams'
                        onCancel={props.onCancel}
                    />
                    <div className='quick-drawer-body'>
                        {
                            exam.isReady && predefinedExam.isReady && previousExam.isReady ?
                                <FadeIn>
                                    <div className='new-exam body-content'>
                                        <section className='date-time'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    {aeh.renderAppointmentDateTime(exam.data.appointment)}
                                                </div>
                                            </div>
                                        </section>
                                        <section className='customer'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0'>
                                                        <label><small>For</small></label>
                                                        {aeh.renderAppointmentCustomer(exam.data.appointment, quickDrawer)}
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0'>
                                                        <label><small>Prefill with</small></label>
                                                        {
                                                            predefinedExam.dataSets && predefinedExam.dataSets.length > 0 ?
                                                                <>
                                                                    <div className='custom-control custom-radio mb-2'>
                                                                        <input
                                                                            id='prefillExamPredefinedData'
                                                                            type='radio'
                                                                            name='prefillExamOption'
                                                                            className='custom-control-input'
                                                                            checked={prefillExamMethod === 'predefined'}
                                                                            onChange={() => { setPrefillExamMethod('predefined') }}
                                                                        />
                                                                        <label
                                                                            htmlFor='prefillExamPredefinedData'
                                                                            className='custom-control-label'
                                                                        >
                                                                            A prefilled exam template
                                                                        </label>
                                                                    </div>
                                                                    {
                                                                        prefillExamMethod === 'predefined' && selectedPredefinedExam ?
                                                                            <div
                                                                                className='pl-5 mb-2 cursor-pointer'
                                                                                onClick={handlePredefinedExam}
                                                                            >
                                                                                <strong className='text-primary fs-lg'>
                                                                                    <i className={'mr-1 ' + oh.getIcon('exam')}></i>{selectedPredefinedExam.name}
                                                                                </strong>
                                                                            </div> : null
                                                                    }
                                                                </> : null
                                                        }
                                                        {
                                                            previousExam.dataSets && previousExam.dataSets.length > 0 ?
                                                                <>
                                                                    <div className='custom-control custom-radio mb-2'>
                                                                        <input
                                                                            id='prefillExamPrevious'
                                                                            type='radio'
                                                                            name='prefillExamOption'
                                                                            className='custom-control-input'
                                                                            checked={prefillExamMethod === 'previous'}
                                                                            onChange={() => { setPrefillExamMethod('previous') }}
                                                                        />
                                                                        <label
                                                                            htmlFor='prefillExamPrevious'
                                                                            className='custom-control-label'
                                                                        >
                                                                            Copying from a previous exam
                                                                        </label>
                                                                    </div>
                                                                    {
                                                                        prefillExamMethod === 'previous' && selectedPreviousExam ?
                                                                            <div
                                                                                className='pl-5 mb-2 cursor-pointer'
                                                                                onClick={handlePreviousExam}
                                                                            >
                                                                                <strong className='text-primary fs-lg'>
                                                                                    <i className={'mr-1 ' + oh.getIcon('exam')}></i>{fn.formatFullDateWithOrWithoutYear(moment.utc(selectedPreviousExam.scheduledStartUtc).local())}
                                                                                </strong>
                                                                            </div> : null
                                                                    }
                                                                </> : null
                                                        }
                                                        {
                                                            previousExam.dataSets && previousExam.dataSets.length > 0 && props.extraProps && props.extraProps.selectedSection ?
                                                                <>
                                                                    <div className='custom-control custom-radio mb-2'>
                                                                        <input
                                                                            id='prefillExamSectionPrevious'
                                                                            type='radio'
                                                                            name='prefillExamOption'
                                                                            className='custom-control-input'
                                                                            checked={prefillExamMethod === 'previousSection'}
                                                                            onChange={() => { setPrefillExamMethod('previousSection') }}
                                                                        />
                                                                        <label
                                                                            htmlFor='prefillExamSectionPrevious'
                                                                            className='custom-control-label'
                                                                        >
                                                                            Copying from <strong className='tt-underline'>{props.extraProps.selectedSection.name} tab</strong> in a previous exam
                                                                        </label>
                                                                    </div>
                                                                    {
                                                                        prefillExamMethod === 'previousSection' && selectedPreviousExam ?
                                                                            <div
                                                                                className='pl-5 mb-2 cursor-pointer'
                                                                                onClick={handlePreviousSection}
                                                                            >
                                                                                <strong className='text-primary fs-lg'>
                                                                                    <i className={'mr-1 ' + oh.getIcon('exam')}></i>{fn.formatFullDateWithOrWithoutYear(moment.utc(selectedPreviousExam.scheduledStartUtc).local())}
                                                                                </strong>
                                                                            </div> : null
                                                                    }
                                                                </> : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        {
                                            prefillExamMethod !== 'previousSection' && !!exam.data.examData && exam.data.examData.length > 0 && exam.data.examData.filter(d => !!d.value).length > 0 ?
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-0'>
                                                                <label><small>Reset exam</small></label>
                                                                <div className='custom-control custom-checkbox mb-2'>
                                                                    <input
                                                                        id='clearAndPrefill'
                                                                        type='checkbox'
                                                                        name='clearAndPrefill'
                                                                        className='custom-control-input'
                                                                        checked={clearExamData}
                                                                        onChange={() => { setClearExamData(!clearExamData) }}
                                                                    />
                                                                    <label
                                                                        htmlFor='clearAndPrefill'
                                                                        className='custom-control-label'
                                                                    >
                                                                        <span className={(clearExamData ? 'fs-500 text-danger' : null)}>Delete exam data and start over</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section> : null
                                        }
                                    </div>
                                </FadeIn> : renderQuickDrawerLoading()
                        }
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={props.onCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-info'
                                    >Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
        <BodyEnd>
            <ConfirmModal ref={confirmModalRef} />
        </BodyEnd>
    </>)
}

export default PrefillExam;