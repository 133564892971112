import React, { useContext, useEffect, useRef, useState } from 'react';
import { Observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import FadeIn from 'react-fade-in';
import TimeAgo from 'react-timeago';
import { GlobalHotKeys } from 'react-hotkeys';
import { ContextMenu } from 'devextreme-react/context-menu';
import { toast } from 'react-toastify';
import moment from 'moment';

import BodyEnd from '../../_shared/BodyEnd';
import ConfirmModal from '../../_shared/ConfirmModal';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import HtmlEditor from '../../_shared/HtmlEditor';

import NoteSearchStore from '../../../../stores/NoteSearchStore';
import FileViewerStore from '../../../../stores/FileViewerStore';
import AuthStore from '../../../../stores/AuthStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';
import useSignalR from '../../../hooks/useSignalR';

import * as fn from '../../../../utilities/_functions';
import * as ph from '../../../../utilities/personHelper';
import * as uh from '../../../../utilities/userHelper';
import * as ah from '../../../../utilities/accessHelper';
import * as oh from '../../../../utilities/operationHelper';
import * as sys from '../../../../utilities/systemHelper';

import './ViewNotes.scss';

function ViewNotes(props) {
    const isMounted = useRef(true);
    const bodyScrollTimer = useRef(null);
    const validateRef = useRef(null);
    const bodyRef = useRef(null);
    const newEditorRef = useRef(null);
    const updateEditorRef = useRef(null);
    const updateNoteRef = useRef(null);
    const noteSearch = useContext(NoteSearchStore);
    const auth = useContext(AuthStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const fileViewer = useContext(FileViewerStore);
    const signalR = useSignalR();
    const [title, setTitle] = useState('Notes');
    const [icon, setIcon] = useState('');
    const [isRendered, setIsRendered] = useState(false);
    const [isFilterRendered, setIsFilterRendered] = useState(null);
    const [expandFilter, setExpandFilter] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [includeDeleted, setIncludeDeleted] = useState(false);
    const [confirmDeleteNote, setConfirmDeleteNote] = useState(null);

    useEffect(() => {
        if (props && props.extraProps) {
            if (props.extraProps.title) {
                setTitle(props.extraProps.title);
            }
            if (props.extraProps.icon) {
                setIcon(props.extraProps.icon);
            }
            if (props.extraProps.renderFilters === false) {
                setIsFilterRendered(false);
            }
        }

        signalR.on('Note', (updated) => {
            if (updated && noteSearch.customer && noteSearch.customer && noteSearch.customer.id === updated.customerId) {
                noteSearch.refresh();
            }
        });

        return () => {
            isMounted.current = false;
            if (bodyScrollTimer.current) { clearTimeout(bodyScrollTimer.current); bodyScrollTimer.current = null; }
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        const filters = [];

        newEditorRef.current.getInstance().focus();

        if (isRendered) {
            bodyScrollTimer.current = setTimeout(() => {
                if (bodyRef.current) {
                    bodyRef.current.scrollTop = 99999;
                }

                if (noteSearch.referenceId) {
                    filters.push(noteSearch.referenceId);
                }

                if (isMounted.current) {
                    setSelectedFilters(filters);

                    if (isFilterRendered === null) {
                        setIsFilterRendered(true);
                    }
                }
            }, 100)
        }
    }, [isRendered]) // eslint-disable-line

    const handleUpdateMessageChange = (e) => {
        const html = e.value;
        const preview = fn.stripHtml(html).trim().substring(0, 200);
        const bodyHtml = (html === '<p></p>') || (html === '<p><br></p>') ? '' : html;

        updateNoteRef.current.preview = preview;
        updateNoteRef.current.bodyHtml = bodyHtml;
    }

    const handleMessageChange = (e) => {
        const html = e.value;
        const preview = fn.stripHtml(html).trim().substring(0, 200);
        const bodyHtml = (html === '<p></p>') || (html === '<p><br></p>') ? '' : html;

        noteSearch.newNote.bodyHtml = bodyHtml;
        noteSearch.newNote.preview = preview;
    }

    const handleCancelUpdateNote = (e) => {
        noteSearch.updateNote = null;
        updateNoteRef.current = null;
    }

    const handleSaveUpdateNote = (e) => {
        if (fn.validateForm(validateRef.current)) {
            noteSearch.updateNote = updateNoteRef.current;
            noteSearch.update()
                .then(() => {
                    updateEditorRef.current.getInstance().option('value', null);
                    updateNoteRef.current = null;
                    noteSearch.updateNote = null;
                    toast.dark(() => <p data-note-upd>Note updated.</p>);
                })
        }
    }

    const handlePinNote = (event, { id, isPinned }) => {
        noteSearch.pin(id, !isPinned);
    }

    const handleEditNote = (event, note) => {
        const instance = updateEditorRef.current.getInstance();

        updateNoteRef.current = toJS(note);
        noteSearch.updateNote = toJS(note);

        instance.option('value', updateNoteRef.current.bodyHtml);
        instance.setSelection(0, 9999);
        instance.focus();
    }

    const handleDeleteNote = (event, { id }) => {
        setConfirmDeleteNote(id);
    }

    const handleConfirmDeleteNote = event => {
        noteSearch.removeNoteIds.push(confirmDeleteNote);
        noteSearch.save()
            .then(() => {
                if (isMounted.current) {
                    setConfirmDeleteNote(null);
                }
            })
    }

    const handleConfirmNoteCancel = event => {
        setConfirmDeleteNote(null);
    }

    const handleSeeAttachments = () => {
        noteSearch.clearNewNote();
        quickDrawer.activateQuickDrawer('note', 'attachment', null, handleAttachmentUpdateSuccess);
    }

    const handleAttachmentUpdateSuccess = () => { }

    const handleContextMenuItemClick = event => {
        const { itemData } = event;

        if (itemData.callback && fn.isFunction(itemData.callback)) {
            itemData.callback();
        }

        event.component.hide();
    }

    const handleSubmit = event => {
        event.preventDefault();
        noteSearch.save()
            .then((updated) => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        const isUpdated = !!updated || (noteSearch.removeNoteIds && noteSearch.removeNoteIds.length > 0);
                        props.onSuccess(event, { updated: isUpdated });
                    }
                }
            })
    }

    const handleFilterToggle = (event, filter) => {
        event.preventDefault();

        const filters = [...selectedFilters];
        const index = filters.findIndex(f => f === filter);

        if (index === -1) {
            filters.push(filter);
        } else {
            filters.splice(index, 1);
        }

        setSelectedFilters(filters);

        bodyScrollTimer.current = setTimeout(() => {
            if (bodyRef.current) {
                bodyRef.current.scrollTop = 99999;
            }
        }, 100)
    }

    const handleDeleteToggle = (event) => {
        setIncludeDeleted(!includeDeleted);
    }

    const handleFilterClear = (event) => {
        event.preventDefault();
        setSelectedFilters([]);

        bodyScrollTimer.current = setTimeout(() => {
            if (bodyRef.current) {
                bodyRef.current.scrollTop = 99999;
            }
        }, 100)
    }

    const handleFilterExpandToggle = event => {
        event.preventDefault();
        setExpandFilter((!expandFilter));
    }

    const handleAttachmentPreview = (event, attachment) => {
        const attachments = (noteSearch.customer && noteSearch.customer.notes && noteSearch.customer.notes.some(n => n.attachments && n.attachments.length > 0) ?
            noteSearch.customer.notes.filter(n =>
                n.attachments && n.attachments.length > 0).map(n => {
                    return n.attachments.map(a => { return a })
                }) : []).flat();
        const index = attachments.findIndex(a => a.id === attachment.id);

        fileViewer.initialize(
            () => {
                return noteSearch.customer ? <div className='fs-lg'>
                    {ph.getPreferredFirstLastName(noteSearch.customer)}
                    <small className='d-block text-gray-700'>View attachment(s)</small>
                </div> : null
            },
            'attachment',
            attachments,
            index,
            noteSearch.customer.id
        );
    }

    const hasFilter = (filter) => {
        return selectedFilters.some(f => f === filter);
    }

    const getFilteredNotes = () => {
        if (!noteSearch.customer || !noteSearch.customer.notes) return [];
        if (selectedFilters.length === 0) return noteSearch.customer.notes.filter(n => (includeDeleted || !n.deactivatedDateUtc));
        return noteSearch.customer.notes.filter(n => (includeDeleted || !n.deactivatedDateUtc) && (n.isPinned || hasFilter(n.referenceId) || hasFilter(n.filterType)));
    }

    const getReferenceFilteredNoteCount = () => {
        return noteSearch.customer.notes ? noteSearch.customer.notes.filter(n => ((includeDeleted || !n.deactivatedDateUtc) && n.referenceId === noteSearch.referenceId)).length : 0;
    }

    const getFilteredNoteCount = (filterType) => {
        return noteSearch.customer.notes ? noteSearch.customer.notes.filter(n => ((includeDeleted || !n.deactivatedDateUtc) && n.filterType === filterType)).length : 0;
    }

    const getDeletedNoteCount = () => {
        return noteSearch.customer.notes ? noteSearch.customer.notes.filter(n => n.deactivatedDateUtc).length : 0;
    }

    const getNoteIndicator = (note) => {
        if (!fn.isNullOrUndefined(note.deactivatedDateUtc)) {
            return <span className='badge p-0'><i className='fas fa-trash-alt text-gray-900'></i></span>
        }
        else if (note.isPinned) {
            return <span className='badge p-0'><i className='fas fa-thumbtack rotate-45 text-info'></i></span>
        }
        else {
            return <span className={`badge badge-icon bg-appointments-900`}></span>
        }
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    props.onCancel(event);
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <Observer>{() =>
                <fieldset disabled={!noteSearch.isReady}>
                    <div className='quick-drawer view-note-drawer'>
                        <QuickDrawerHeader
                            drawer={props.drawer}
                            icon={icon ? icon : oh.getIcon('note')}
                            action='View'
                            category={title ? title : 'Notes'}
                            className='notes'
                            onCancel={props.onCancel}
                        />
                        <div className={'note-filters-wrapper' + (expandFilter ? ' expanded' : '')}>
                            {
                                noteSearch.isReady && noteSearch.customer && noteSearch.customer.notes && isRendered && isFilterRendered ?
                                    <ul className='note-filters'>
                                        {
                                            noteSearch.referenceId && noteSearch.referenceType ?
                                                <li>
                                                    <button
                                                        type='button'
                                                        className={`btn btn-sm btn-toggle-danger` + (hasFilter(noteSearch.referenceId) ? ' active' : '')}
                                                        onClick={e => handleFilterToggle(e, noteSearch.referenceId)}
                                                    >
                                                        <span className={`${oh.getIcon(noteSearch.referenceType)} mr-1`}></span>This {oh.getDescription(noteSearch.referenceType)} <small>({getReferenceFilteredNoteCount()})</small>
                                                    </button>
                                                </li> : null
                                        }
                                        <li>
                                            <button
                                                type='button'
                                                className={'btn btn-sm btn-toggle-default' + (selectedFilters.length === 0 ? ' active' : '')}
                                                onClick={e => handleFilterClear(e)}
                                            >
                                                <span className='fal fa-filter mr-0'></span> All Notes <small>({noteSearch.customer && noteSearch.customer.notes ? noteSearch.customer.notes.length : 0})</small>
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                type='button'
                                                className={'btn btn-sm btn-toggle-customer' + (hasFilter('customer') ? ' active' : '')}
                                                onClick={e => handleFilterToggle(e, 'customer')}
                                            >
                                                <span className={`${oh.getIcon('customer')} mr-1`}></span>{oh.getDescription('customer')} <small>({getFilteredNoteCount('customer')})</small>
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                type='button'
                                                className={'btn btn-sm btn-toggle-appointment' + (hasFilter('appointment') ? ' active' : '')}
                                                onClick={e => handleFilterToggle(e, 'appointment')}
                                            >
                                                <span className={`${oh.getIcon('appointment')} mr-1`}></span>{oh.getDescription('appointment')} <small>({getFilteredNoteCount('appointment')})</small>
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                type='button'
                                                className={'btn btn-sm btn-toggle-purchase' + (hasFilter('purchase') ? ' active' : '')}
                                                onClick={e => handleFilterToggle(e, 'purchase')}
                                            >
                                                <span className={`${oh.getIcon('purchase')} mr-1`}></span>{oh.getDescription('purchase')} <small>({getFilteredNoteCount('purchase')})</small>
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                type='button'
                                                className={'btn btn-sm btn-toggle-work-order' + (hasFilter('work-order') ? ' active' : '')}
                                                onClick={e => handleFilterToggle(e, 'work-order')}
                                            >
                                                <span className={`${oh.getIcon('work-order')} mr-1`}></span>{oh.getDescription('work-order')} <small>({getFilteredNoteCount('work-order')})</small>
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                type='button'
                                                className={'btn btn-sm btn-toggle-default' + (includeDeleted ? ' active' : '')}
                                                onClick={handleDeleteToggle}
                                            >
                                                <span className={`fal fa-trash-alt mr-1`}></span>Deleted <small>({getDeletedNoteCount()})</small>
                                            </button>
                                        </li>
                                    </ul> : <ul className='note-filters'></ul>
                            }
                            {
                                isFilterRendered === true ?
                                    <button
                                        type='button'
                                        className='btn btn-icon btn-expand'
                                        onClick={handleFilterExpandToggle}
                                    >
                                        <i className={`fal fa-${(expandFilter ? 'times' : 'angle-down')} fs-xl`}></i>
                                    </button> : null
                            }
                        </div>
                        <div ref={bodyRef} className='quick-drawer-body'>
                            {
                                noteSearch.isReady ?
                                    <>
                                        <FadeIn>
                                            <div className={'view-note body-content py-1' + (isRendered ? ' rendered' : '')}>
                                                {
                                                    getFilteredNotes().length > 0 ?
                                                        getFilteredNotes().map((n, ni) => {
                                                            return <div
                                                                key={`note-${ni}`}
                                                                className={
                                                                    'd-flex flex-column align-items-center position-relative' +
                                                                    (!fn.isNullOrUndefined(n.deactivatedDateUtc) ? ' deleted' : '')
                                                                }
                                                            >
                                                                {
                                                                    !!noteSearch.updateNote && noteSearch.updateNote.id !== n.id ?
                                                                        <div className='note-overlay'></div> : null
                                                                }
                                                                <div className='d-flex flex-row w-100 py-4 overflow-hidden'>
                                                                    <div className='d-inline-block align-middle mr-3'>
                                                                        <span
                                                                            className={'profile-image profile-image-md rounded-circle d-block' + (n.resource && !n.resource.profilePictureUri ? ` profile-initials text-white bg-color${n.resource.color}-500` : ' mt-1')}
                                                                            style={!n.resource || n.resource.profilePictureUri ? {
                                                                                backgroundImage: `url(${(n.resource && n.resource.profilePictureUri ? n.resource.profilePictureUri : '/media/img/default-genki.jpg')})`,
                                                                                backgroundSize: 'cover',
                                                                            } : null}
                                                                            title={n.resource ? n.resource.fullName : 'System'}>
                                                                            {n.resource && !n.resource.profilePictureUri ? <div className='d-initials fs-xs'>{n.resource.initials}</div> : null}
                                                                            {getNoteIndicator(n)}
                                                                        </span>
                                                                    </div>
                                                                    <div className='mb-0 flex-1 text-dark'>
                                                                        <div className='d-flex'>
                                                                            <span className='text-gray-800 fw-500'>
                                                                                <span className='note-by position-relative'>
                                                                                    {n.resource ? uh.getDisplayShortName(n.resource) : 'System'}
                                                                                </span>
                                                                            </span>
                                                                            <Observer>{() => <span className='flex-1 position-relative'>
                                                                                <span className='badges pl-1'>
                                                                                    {
                                                                                        !!noteSearch.updateNote && n.id === noteSearch.updateNote.id ?
                                                                                            <>
                                                                                                <span className='badge badge-danger ml-h'>Editing ...</span>
                                                                                            </> :
                                                                                            <>
                                                                                                {
                                                                                                    n.lastUpdatedDateUtc ?
                                                                                                        <span className='badge badge-dark ml-h'>Edited</span> : null
                                                                                                }
                                                                                                {
                                                                                                    n.isPinned ?
                                                                                                        <span className='badge badge-info ml-h'>Pinned</span> : null
                                                                                                }
                                                                                            </>
                                                                                    }
                                                                                </span>
                                                                            </span>
                                                                            }</Observer>
                                                                            <span className='ml-auto'>
                                                                                {
                                                                                    n.lastUpdatedDateUtc ?
                                                                                        moment().startOf('day').isSame(moment.utc(n.lastUpdatedDateUtc).local().startOf('day')) ?
                                                                                            <TimeAgo
                                                                                                className='text-muted fs-xs opacity-70'
                                                                                                date={moment.utc(n.lastUpdatedDateUtc).local().toDate()}
                                                                                                title={sys.getFormattedLongDate(moment.utc(n.lastUpdatedDateUtc).local(), true)}
                                                                                                formatter={(value, unit, suffix) => { return fn.timeAgoPastFormatter(value, unit, suffix, 'second', 10, 'Just now', true) }}
                                                                                                minPeriod={60}
                                                                                            /> :
                                                                                            <span
                                                                                                className='text-muted fs-xs opacity-70'
                                                                                                title={sys.getFormattedLongDate(moment.utc(n.lastUpdatedDateUtc).local(), true)}
                                                                                            >
                                                                                                {sys.getFormattedLongDate(moment.utc(n.lastUpdatedDateUtc).local())}
                                                                                            </span> :
                                                                                        moment().startOf('day').isSame(moment.utc(n.createdDateUtc).local().startOf('day')) ?
                                                                                            <TimeAgo
                                                                                                className='text-muted fs-xs opacity-70'
                                                                                                date={moment.utc(n.createdDateUtc).local().toDate()}
                                                                                                title={moment.utc(n.createdDateUtc).local().format('dddd MMMM D, YYYY h:mm a')}
                                                                                                formatter={(value, unit, suffix) => { return fn.timeAgoPastFormatter(value, unit, suffix, 'second', 10, 'Just now', true) }}
                                                                                                minPeriod={60}
                                                                                            /> :
                                                                                            <span
                                                                                                className='text-muted fs-xs opacity-70'
                                                                                                title={sys.getFormattedLongDate(moment.utc(n.createdDateUtc).local(), true)}
                                                                                            >
                                                                                                {sys.getFormattedLongDate(moment.utc(n.createdDateUtc).local())}
                                                                                            </span>
                                                                                }
                                                                                <div
                                                                                    id={`${n.id}`}
                                                                                    className='btn btn-icon btn-xs p-0 line-height-1 mr-n2'
                                                                                >
                                                                                    <i className='fal fa-ellipsis-v fs-xl'></i>
                                                                                </div>
                                                                                <ContextMenu
                                                                                    target={`#${n.id}`}
                                                                                    position={{ my: 'top right', at: 'top left' }}
                                                                                    dataSource={[{
                                                                                        icon: (n.isPinned ? 'text-info rotate-45 fas' : 'fal') + ' fa-thumbtack',
                                                                                        name: n.isPinned ? 'Unpin' : 'Pin',
                                                                                        callback: (e) => handlePinNote(e, n),
                                                                                    }, {
                                                                                        icon: 'fal fa-pen',
                                                                                        name: 'Edit',
                                                                                        disabled: auth.currentUser.id !== n.createdById,
                                                                                        callback: (e) => handleEditNote(e, n),
                                                                                    }, {
                                                                                        icon: 'fal fa-trash-alt',
                                                                                        name: 'Delete',
                                                                                        disabled: !ah.hasFullAccess(auth.currentUser.id) && auth.currentUser.id !== n.createdById,
                                                                                        callback: (e) => handleDeleteNote(e, n),
                                                                                    }]}
                                                                                    displayExpr='name'
                                                                                    showEvent="dxcontextmenu click"
                                                                                    onItemClick={handleContextMenuItemClick}
                                                                                >
                                                                                </ContextMenu>
                                                                            </span>
                                                                        </div>
                                                                        <div
                                                                            className={'html mt-1 ' + (n.attachments && n.attachments.length > 0 ? 'mb-2' : 'mb-0')}
                                                                            dangerouslySetInnerHTML={{ __html: n.bodyHtml }}
                                                                        ></div>
                                                                        {
                                                                            !fn.isNullOrUndefined(n.deactivatedDateUtc) ?
                                                                                <div className='text-gray-600'>
                                                                                    <small><em>Deleted by: {uh.getDisplayShortNameById(n.deactivatedById, n.deactivatedBy)} - {moment.utc(n.deactivatedDateUtc).local().format('YYYY-MM-DD')}</em></small>
                                                                                </div> : null
                                                                        }
                                                                        {
                                                                            n.attachments && n.attachments.length > 0 ?
                                                                                <>
                                                                                    {
                                                                                        n.attachments.slice(0, 3).map((a, ai) => {
                                                                                            return <div
                                                                                                key={`attachment-${ni}-${ai}`}
                                                                                                className={'card attachment border' + (ai !== (n.attachments.length - 1) ? ' mb-2' : '')}
                                                                                            >
                                                                                                <div className='card-body'>
                                                                                                    <div className='d-flex flex-row w-100'>
                                                                                                        <div className='d-flex align-middle align-items-center mr-3'>
                                                                                                            {fn.toFileIcon(a.mimeType, 'fs-xxl')}
                                                                                                        </div>
                                                                                                        <div className='flex-1'>
                                                                                                            <div className='d-block text-truncate text-truncate-md fw-500 text-gray-800'>{a.fileName}</div>
                                                                                                            <div className='d-flex flex-row align-items-center fs-xs'>
                                                                                                                <button
                                                                                                                    type='button'
                                                                                                                    className='btn btn-xs btn-link p-0'
                                                                                                                    onClick={(e) => { handleAttachmentPreview(e, a) }}
                                                                                                                >
                                                                                                                    Preview
                                                                                                                </button>
                                                                                                                <span className='line-height-1'>&nbsp;&middot;&nbsp;</span>
                                                                                                                <span className='line-height-1 text-gray-500 fs-xs'>{fn.toFileSize(a.size, true)}</span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        })
                                                                                    }
                                                                                    {
                                                                                        n.attachments.length > 3 ?
                                                                                            <div
                                                                                                className='card border cursor-pointer border-dashed d-inline-block'
                                                                                                onClick={(e) => { handleAttachmentPreview(e, n.attachments[3]) }}
                                                                                            >
                                                                                                <div className='card-body'>
                                                                                                    <div className='fs-lg p-2 text-center'>
                                                                                                        +{n.attachments.length - 3}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div> : null
                                                                                    }
                                                                                </> : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {
                                                                    getFilteredNotes().length !== (ni + 1) ?
                                                                        <hr className={'m-0 w-100' + (
                                                                            !moment.utc(noteSearch.customer.notes[ni].createdDateUtc).local().startOf('day').isSameOrAfter(moment().add(-2, 'weeks').startOf('day')) &&
                                                                                moment.utc(noteSearch.customer.notes[ni + 1].createdDateUtc).local().startOf('day').isSameOrAfter(moment().add(-2, 'weeks').startOf('day')) ?
                                                                                ' new' : ''
                                                                        )} /> : null
                                                                }
                                                            </div>
                                                        }) : null
                                                }
                                            </div>
                                        </FadeIn>
                                        {setIsRendered(true)}
                                    </> : null
                            }
                        </div>
                        <div className='quick-drawer-action'>
                            <div className='row'>
                                <div className='col-12'>
                                    <hr className='m-0 w-100' />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 validate validate-required'>
                                    <Observer>{() => <>
                                        <HtmlEditor
                                            ref={newEditorRef}
                                            height={130}
                                            className={!!noteSearch.updateNote ? 'd-none' : ''}
                                            placeholder='Write note here...'
                                            toolbar={{ items: ['bold', 'italic', 'underline', 'separator', 'orderedList', 'bulletList', 'separator', 'color', 'background'] }}
                                            disableTab={true}
                                            onValueChanged={handleMessageChange}
                                        ></HtmlEditor>
                                        <HtmlEditor
                                            ref={updateEditorRef}
                                            height={130}
                                            className={!!noteSearch.updateNote ? '' : 'd-none'}
                                            placeholder='Update note here...'
                                            toolbar={{ items: ['bold', 'italic', 'underline', 'separator', 'orderedList', 'bulletList', 'separator', 'color', 'background'] }}
                                            disableTab={true}
                                            onValueChanged={handleUpdateMessageChange}
                                        ></HtmlEditor>
                                    </>
                                    }</Observer>
                                </div>
                            </div>
                            <div className='row px-4 py-3'>
                                <div className='col-4'>
                                    <button
                                        type='button'
                                        className='btn btn-icon ml-n2'
                                        title='Attachments'
                                        disabled={!!noteSearch.updateNote}
                                        onClick={handleSeeAttachments}
                                    >
                                        <i className='fal fa-paperclip'></i>
                                    </button>
                                </div>
                                <div className='col-8'>
                                    {
                                        !!noteSearch.updateNote ?
                                            <div className='float-right'>
                                                <button
                                                    type='button'
                                                    className='btn btn-link btn-cancel mr-2'
                                                    onClick={handleCancelUpdateNote}
                                                >Cancel</button>
                                                <button
                                                    type='button'
                                                    className='btn btn-success'
                                                    onClick={handleSaveUpdateNote}
                                                >Save</button>
                                            </div> :
                                            <div className='float-right'>
                                                <button
                                                    type='button'
                                                    className='btn btn-link btn-cancel mr-2'
                                                    onClick={props.onCancel}
                                                >Cancel</button>
                                                <button
                                                    type='submit'
                                                    className='btn btn-success'
                                                >Save</button>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            }</Observer>
        </form>
        <BodyEnd>
            <Observer>{() =>
                <ConfirmModal
                    icon={<i className='fal fa-calendar text-danger mr-2'></i>}
                    message={<>Continue to delete this note? </>}
                    show={confirmDeleteNote}
                    onOption1Click={handleConfirmDeleteNote}
                    onCancel={handleConfirmNoteCancel}
                />
            }</Observer>
        </BodyEnd>
    </>
}

export default ViewNotes;