import { createContext } from 'react';
import { decorate, observable, action, computed } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class ProductView {
    id = null;
    data = null;
    types = [];
    options = [];
    isLoading = false;
    isReady = false;

    cancelProduct = null;
    cancelProductOptions = null;
    cancelProductTypes = null;

    initialize = (id) => {
        const that = this;

        this.clear();
        this.id = id;
        this.isReady = false;

        return new Promise((resolve, reject) => {
            that.refresh()
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isReady = true;
                })
        })
    }

    refresh = () => {
        const that = this;
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            Promise.all([
                api.ProductTypes.all((c) => { that.cancelProductTypes = c }),
                api.ProductOptions.all((c) => { that.cancelProductOptions = c }),
                api.Products.get(that.id, (c) => { that.cancelProduct = c })
            ])
                .then(response => {
                    that.types = response[0].data;
                    that.options = response[1].data;
                    that.data = response[2].data;
                    if (!that.data.serialNumbers || that.data.serialNumbers.length === 0) {
                        that.data.serialNumbers = new Array(1);
                    }
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isLoading = false;
                })
        })
    }

    clear = () => {
        this.id = null;
        this.data = null;
        this.types.clear();
        this.options.clear();
        this.isLoading = false;
        this.isReady = false;

        if (fn.isFunction(this.cancelProduct)) {
            this.cancelProduct();
            this.cancelProduct = null;
        }

        if (fn.isFunction(this.cancelProductOptions)) {
            this.cancelProductOptions();
            this.cancelProductOptions = null;
        }

        if (fn.isFunction(this.cancelProductTypes)) {
            this.cancelProductTypes();
            this.cancelProductTypes = null;
        }
    }

    get selectedType() {
        if (!this.data.typeId || !this.types || this.types.length === 0) return null;
        return this.types.filter(t => t.id === this.data.typeId)[0];
    }

    get brands() {
        if (!this.data || !this.data.type.id || !this.types || this.types.length === 0) return [];
        return this.types.filter(t => t.id === this.data.type.id)[0].brands;
    }
}

decorate(ProductView, {
    id: observable,
    data: observable,
    types: observable,
    options: observable,
    isLoading: observable,
    isReady: observable,
    initialize: action,
    refresh: action,
    clear: action,
    selectedType: computed,
    brands: computed,
})

export default createContext(new ProductView());