import { createContext } from 'react';
import { decorate, observable, action, computed } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

const PAGE_SIZE = 50;
const FULL_SEARCH_SIZE = 250;

export class ServiceSearch {
    page = 1;
    pageSize = PAGE_SIZE;
    searchTerm = null;
    includeSubsidizedServices = true;
    includeNonSubsidizedServices = true;
    services = [];
    total = null;
    isLoading = false;
    isSaving = false;
    isReady = false;

    cancelServiceDelete = null;
    cancelServiceFullSearch = null;
    cancelServiceSearch = null;
    cancelServiceSearchCount = null;
    cancelServiceTypes = null;

    initialize = async (includeNonSubsidizedServices = true, includeSubsidizedServices = true, pageSize = PAGE_SIZE) => {
        this.clear();
        this.includeNonSubsidizedServices = includeNonSubsidizedServices;
        this.includeSubsidizedServices = includeSubsidizedServices;
        this.pageSize = pageSize;

        await this.refresh();

        this.isReady = true;
    }

    execute = async (page, notify = true) => {
        const offset = page ? ((page - 1) * this.pageSize) : 0;

        this.page = page;

        if (notify) {
            this.isLoading = true;
        }

        try {
            const { data } = await api.Services.search({
                parameters: this.getParameters(),
                sortByFields: [{
                    field: 'Name',
                    direction: 'ASC',
                }],
                offset: offset,
                limit: this.pageSize,
                includeTotalCount: true,
            }, (c) => { this.cancelServiceSearch = c });
            action(e => {
                if (data) {
                    this.services = data.result;
                    this.total = data.total;
                }
            })();
        }
        finally {
            if (notify) {
                this.isLoading = false;
            }
        }
    }

    reload = async (id, notify = true) => {
        if (notify) {
            this.isLoading = true;
        }

        try {
            const { data } = await api.Services.get(id, (c) => { this.cancelServiceSearch = c });

            action(e => {
                const index = this.services.findIndex(s => s.id === id);
                if (data) { // item1 array is empty if this is a delete operation
                    if (index >= 0) // UPDATE
                        this.services[index] = data;
                    else // INSERT
                        this.services.unshift(data);
                }
                else
                    this.services.splice(index, 1);

            })();
        }
        finally {
            if (notify) {
                this.isLoading = false;
            }
        }
    }

    delete = (serviceIds) => {
        const that = this;

        this.isSaving = true;

        return new Promise((resolve, reject) => {
            Promise.all(
                [...serviceIds].map(p => {
                    return api.Services.delete(p.id, (c) => { that.cancelServiceDelete = c })
                })
            )
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    refresh = async () => {
        if (this.searchTerm) {
            return await this.search();
        }

        return await this.execute(this.page, false);
    }

    search = async () => {
        this.isLoading = true;
        try {
            const { data } = await api.Services.fullSearch(this.searchTerm, FULL_SEARCH_SIZE, 0, (c) => { this.cancelServiceFullSearch = c });
            action(e => {
                if (data) {
                    this.services = data.result;
                    this.total = data.total;
                }
            })();
        }
        finally {
            this.isLoading = false;
        }
    }

    delete = (serviceIds) => {
        const that = this;

        this.isSaving = true;

        return new Promise((resolve, reject) => {
            Promise.all(
                that.services.filter(p => serviceIds.some(i => i === p.id)).map(p => {
                    return api.Services.delete(p.id, (c) => { that.cancelServiceDelete = c })
                })
            )
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    clear = () => {
        this.page = 1;
        this.pageSize = PAGE_SIZE;
        this.searchTerm = null;
        this.includeSubsidizedServices = true;
        this.includeNonSubsidizedServices = true;
        this.services.clear();

        this.total = null;
        this.isLoading = false;
        this.isReady = false;

        if (fn.isFunction(this.cancelServiceDelete)) {
            this.cancelServiceDelete();
            this.cancelServiceDelete = null;
        }

        if (fn.isFunction(this.cancelServiceFullSearch)) {
            this.cancelServiceFullSearch();
            this.cancelServiceFullSearch = null;
        }

        if (fn.isFunction(this.cancelServiceSearch)) {
            this.cancelServiceSearch();
            this.cancelServiceSearch = null;
        }

        if (fn.isFunction(this.cancelServiceSearchCount)) {
            this.cancelServiceSearchCount();
            this.cancelServiceSearchCount = null;
        }

        if (fn.isFunction(this.cancelServiceTypes)) {
            this.cancelServiceTypes();
            this.cancelServiceTypes = null;
        }
    }

    getParameters = () => {
        const parameters = [];

        if (!this.includeSubsidizedServices || !this.includeNonSubsidizedServices) {
            if (!this.includeSubsidizedServices) {
                parameters.push({
                    field: 'SubsidyCode',
                    value: null,
                })
            }

            if (!this.includeNonSubsidizedServices) {
                parameters.push({
                    field: 'SubsidyCode',
                    operator: '!=',
                    value: null,
                })
            }
        }

        parameters.push({
            field: 'DeactivatedDateUtc',
            value: null,
        })

        return parameters;
    }

    get pageIndex() {
        return this.page - 1;
    }

    get pageCount() {
        return Math.ceil(this.total / this.pageSize);
    }
}

decorate(ServiceSearch, {
    page: observable,
    pageSize: observable,
    searchTerm: observable,
    includeSubsidizedServices: observable,
    includeNonSubsidizedServices: observable,
    services: observable.deep,
    total: observable,
    display: observable,
    isLoading: observable,
    isSaving: observable,
    isReady: observable,
    initialize: action,
    execute: action,
    refresh: action,
    reload: action,
    search: action,
    refreshTotal: action,
    delete: action,
    clear: action,
    pageIndex: computed,
    pageCount: computed,
})

export { PAGE_SIZE, FULL_SEARCH_SIZE };
export default createContext(new ServiceSearch());