import { createContext } from 'react';
import { decorate, observable, action, computed, toJS } from 'mobx';

import * as StorageKeys from '../constants/storageKeys';
import * as fn from '../utilities/_functions';
import * as eh from '../utilities/examHelper';

import api from '../api';

export class PredefinedExamSelect {
    examTemplateId = null;
    dataSets = [];
    appointment = null;
    selectedId = null;
    isLoading = false;
    isSaving = false;
    isReady = false;

    cancelPublicPredefinedData = null;
    cancelUserPredefinedData = null;

    initialize = (appointment, examTemplateId) => {
        const that = this;
        this.clear();

        if (appointment) {
            this.appointment = appointment;
            this.examTemplateId = eh.getDefaultExamTemplateId(appointment.services);
        }

        if (examTemplateId) {
            this.examTemplateId = examTemplateId;
        }

        return that.refresh()
            .finally(() => {
                that.isReady = true;
            })
    }

    refresh = () => {
        const that = this;

        return new Promise((resolve, reject) => {
            const currentUser = JSON.parse(window.localStorage.getItem(StorageKeys.CURRENT_USER));
            that.isLoading = true;

            Promise.all([
                api.PredefinedData.search(
                    {
                        parameters: [
                            {
                                field: 'UserId',
                                value: currentUser.id,
                            },
                            {
                                field: 'ExamTemplateId',
                                value: that.examTemplateId,
                            },
                        ],
                    },
                    (c) => { that.cancelUserPredefinedData = c }
                ),
                api.PredefinedData.search(
                    {
                        parameters: [
                            {
                                field: 'IsPublic',
                                value: true,
                            },
                            {
                                field: 'ExamTemplateId',
                                value: that.examTemplateId,
                            },
                        ],
                    },
                    (c) => { that.cancelPublicPredefinedData = c }
                ),
            ])
                .then(response => {
                    const userPredefinedDataSets = response[0].data.result ? response[0].data.result : [];
                    const publicPredefinedDataSets = response[1].data.result ? response[1].data.result : [];

                    that.dataSets = userPredefinedDataSets.concat(publicPredefinedDataSets.filter(d => !userPredefinedDataSets.some(u => u.id === d.id)));
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isLoading = false;
                })
        })
    }

    save = () => {
        const that = this;

        return new Promise((resolve) => {
            resolve(toJS(that.selectedData));
        })
    }

    clear = () => {
        this.examTemplateId = null;
        this.selectedId = null;
        this.dataSets.clear();
        this.appointment = null;
        this.isLoading = false;
        this.isSaving = false;
        this.isReady = false;

        if (fn.isFunction(this.cancelPublicPredefinedData)) {
            this.cancelPublicPredefinedData();
            this.cancelPublicPredefinedData = null;
        }

        if (fn.isFunction(this.cancelUserPredefinedData)) {
            this.cancelUserPredefinedData();
            this.cancelUserPredefinedData = null;
        }
    }

    get selectedData() {
        if (this.filteredDataSets && this.filteredDataSets.length > 0) {
            return this.filteredDataSets.filter(d => d.id === this.selectedId)[0];
        }

        return null;
    }

    get filteredDataSets() {
        if (this.dataSets && this.dataSets.length > 0 && this.examTemplateId) {
            return this.dataSets.some(d => d.examTemplateId === this.examTemplateId) ? this.dataSets.filter(d => d.examTemplateId === this.examTemplateId) : [];
        }

        return this.dataSets && this.dataSets.length > 0 ? this.dataSets : [];
    }
}

decorate(PredefinedExamSelect, {
    examTemplateId: observable,
    selectedId: observable,
    dataSets: observable.deep,
    appointment: observable,
    isLoading: observable,
    isSaving: observable,
    isReady: observable,
    save: action,
    selectedData: computed,
    filteredDataSets: computed,
})

export default createContext(new PredefinedExamSelect());