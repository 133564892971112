import React, { useContext, useEffect, useState, useRef, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { toJS } from 'mobx';
import { useObserver } from 'mobx-react-lite';
import FadeIn from 'react-fade-in';
import { toast } from 'react-toastify';
import { GlobalHotKeys } from 'react-hotkeys';
import { ContextMenu } from 'devextreme-react/context-menu';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';

import BodyEnd from '../../_shared/BodyEnd';
import ConfirmModal from '../../_shared/ConfirmModalComponent';
import CommunicationModal from '../../communications/CommunicationModal/_index';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';

import useSignalR from '../../../hooks/useSignalR';

import GroupAppointmentViewStore from '../../../../stores/GroupAppointmentViewStore';
import GroupAppointmentUpdateStore from '../../../../stores/GroupAppointmentUpdateStore';
import AppointmentCreateStore from '../../../../stores/AppointmentCreateStore';
import AppointmentUpdateStore from '../../../../stores/AppointmentUpdateStore';
import CustomerUpdateStore from '../../../../stores/CustomerUpdateStore';
import GroupAppointmentNoteSearchStore from '../../../../stores/GroupAppointmentNoteSearchStore';
import NoteSearchStore from '../../../../stores/NoteSearchStore';
import CommunicationStore from '../../../../stores/CommunicationStore';
import AuthStore from '../../../../stores/AuthStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as rts from '../../../../constants/routes';
import * as fn from '../../../../utilities/_functions';
import * as ah from '../../../../utilities/addressHelper';
import * as ch from '../../../../utilities/customerHelper';
import * as aph from '../../../../utilities/appointmentHelper';
import * as amh from '../../../../utilities/appointmentCommunicationHelper';
import * as bh from '../../../../utilities/badgeHelper';
import * as ph from '../../../../utilities/personHelper';
import * as sys from '../../../../utilities/systemHelper';
import * as uh from '../../../../utilities/userHelper';
import * as th from '../../../../utilities/timelineHelper';
import * as ach from '../../../../utilities/accessHelper';
import * as oh from '../../../../utilities/operationHelper';

import './ViewGroupAppointment.scss';

moment.locale('en');
momentLocalizer();

function ViewGroupAppointment(props) {
    const signalR = useSignalR();
    const isMounted = useRef(true);
    const quickDrawerTimer = useRef(null);
    const communicationModalRef = useRef(null);
    const confirmModalRef = useRef(null);
    const noteSearch = useContext(NoteSearchStore);
    const groupAppointmentNoteSearch = useContext(GroupAppointmentNoteSearchStore);
    const groupAppointment = useContext(GroupAppointmentViewStore);
    const updateGroupAppointment = useContext(GroupAppointmentUpdateStore);
    const newAppointment = useContext(AppointmentCreateStore);
    const updateAppointment = useContext(AppointmentUpdateStore);
    const customerUpdate = useContext(CustomerUpdateStore);
    const communication = useContext(CommunicationStore);
    const auth = useContext(AuthStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [isSaving, setIsSaving] = useState(false);
    const [eligibles, setEligibles] = useState(null);
    const [ineligibles, setIneligibles] = useState(null);
    const [mohReady, setMohReady] = useState(false);
    const [communicationActive, setCommunicationActive] = useState(false);
    let onSuccess, onCancel, onError;

    useEffect(() => {
        signalR.on('Appointment', (updated) => {
            if (updated && groupAppointment.appointments && groupAppointment.appointments.some(a => a.id === updated.id)) {
                groupAppointment.refresh();
                updateGroupAppointment.refresh();
            }
        });
        signalR.on('Customer', (updated) => {
            if (updated && groupAppointment.appointments && groupAppointment.appointments.some(a => a.customerId === updated.id)) {
                groupAppointment.refresh();
                updateGroupAppointment.refresh();
            }
        });
        signalR.on('Note', (updated) => {
            if (updated && (
                (groupAppointment.appointments && groupAppointment.appointments.some(a => a.customerId === updated.customerId)) ||
                (groupAppointment.data && groupAppointment.data.primaryContact && groupAppointment.data.primaryContact.id === updated.customerId)
            )) {
                groupAppointment.refresh();
                updateGroupAppointment.refresh();
            }
        });
        signalR.on('Purchase', (updated) => {
            if (updated && groupAppointment.appointments && groupAppointment.appointments.some(a => a.customerId === updated.customerId)) {
                groupAppointment.refresh();
                updateGroupAppointment.refresh();
            }
        });
        signalR.on('Payment', (updated) => {
            if (updated && groupAppointment.appointments && groupAppointment.appointments.some(a => a.customerId === updated.customerId)) {
                groupAppointment.refresh();
                updateGroupAppointment.refresh();
            }
        });
        signalR.on('Pretest', (updated) => {
            if (updated && groupAppointment.appointments && groupAppointment.appointments.some(a => a.customerId === updated.customerId)) {
                groupAppointment.refresh();
                updateGroupAppointment.refresh();
            }
        });

        quickDrawerFocus(props.drawer);

        return () => {
            if (quickDrawerTimer.current) { clearTimeout(quickDrawerTimer.current); quickDrawerTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleChangeAppointment = () => {
        onSuccess = quickDrawer.success1Callback;
        onCancel = quickDrawer.cancel1Callback;
        onError = quickDrawer.error1Callback;

        quickDrawer.deactivateQuickDrawer1()
            .then(() => {
                if (isMounted.current) {
                    quickDrawerTimer.current = setTimeout(() => {
                        quickDrawer.activateQuickDrawer('group-appointment', 'update', null, handleChangeAppointmentSuccess, handleChangeAppointmentCancel, handleChangeAppointmentError);
                    }, 200)
                }
            })
    }

    const handleChangeAppointmentSuccess = (result) => {
        if (fn.isFunction(onSuccess)) {
            if (result.updated) {
                if (!result.data) {
                    result.data = { action: 'updated' };
                }
                else {
                    result.data.action = 'updated';
                }
                onSuccess(result);
                onSuccess = null;
                onCancel = null;
                onError = null;
            }
        }
        updateGroupAppointment.clear();
    }

    const handleChangeAppointmentCancel = () => {
        if (fn.isFunction(onCancel)) {
            onCancel();
            onSuccess = null;
            onCancel = null;
            onError = null;
        }
        updateGroupAppointment.clear();
    }

    const handleChangeAppointmentError = () => {
        if (fn.isFunction(onError)) {
            onError();
            onSuccess = null;
            onCancel = null;
            onError = null;
        }
        updateGroupAppointment.clear();
    }

    const handleVerifyEligibility = (event, appointmentId) => {
        updateGroupAppointment.checkEligibility(false, appointmentId)
            .then((data) => {
                const eligibleData = data && data.some(e => e.isPublicHealthChecked && e.isEligible) ? data.filter(e => e.isPublicHealthChecked && e.isEligible) : null;
                const ineligibleData = data && data.some(e => !e.isPublicHealthChecked || !e.isEligible) ? data.filter(e => !e.isPublicHealthChecked || !e.isEligible) : null;
                const isMohReady = data && !data.some(e => !e.isMohReady);

                setEligibles(eligibleData);
                setIneligibles(ineligibleData);
                setMohReady(isMohReady);

                updateGroupAppointment.hasUnsavedChanges = true;
                updateGroupAppointment.save();
            })
    }

    const handleContextMenuItemClick = event => {
        const { itemData } = event;

        if (itemData.callback && fn.isFunction(itemData.callback)) {
            itemData.callback();
        }

        event.component.hide();
    }

    const handleUpdateCustomer = (event, customer) => {
        customerUpdate.initialize(customer.id, false)
        quickDrawer.activateQuickDrawer('customer', 'personal', null, handleUpdateCustomerSuccess, handleUpdateCustomerCancel);
    }

    const handleUpdateCustomerSuccess = (result) => {
        if (result && result.updated) {
            toast.dark(() => <p data-cust-upd>Customer updated.</p>);
        }
        customerUpdate.clear();
    }

    const handleUpdateCustomerCancel = () => {
        customerUpdate.clear();
    }

    const handleSeeNotes = () => {
        if (groupAppointment.data.customer) {
            groupAppointmentNoteSearch.initialize(toJS(groupAppointment.appointments));
            quickDrawer.activateQuickDrawer('group-appointment', 'note', null, handleNoteUpdateSuccess, handleNoteUpdateCancel);
        }
    }

    const handleNoteUpdateSuccess = () => {
        noteSearch.clear();
    }

    const handleNoteUpdateCancel = () => {
        noteSearch.clear();
    }

    const handleDeleteAppointment = () => {
        onSuccess = quickDrawer.success1Callback;
        onCancel = quickDrawer.cancel1Callback;
        onError = quickDrawer.error1Callback;

        updateGroupAppointment.initialize(groupAppointment.groupId);
        quickDrawer.activateQuickDrawer('group-appointment', 'delete', null, handleDeleteAppointmentSuccess);
    }

    const handleDeleteAppointmentSuccess = () => {
        quickDrawer.deactivateQuickDrawer1();
        if (fn.isFunction(onSuccess)) {
            onSuccess('deleted');
            onSuccess = null;
            onCancel = null;
            onError = null;
        }
        updateGroupAppointment.clear();
    }

    const handleAppointmentTimelineChange = (data, appointments, timelineItem) => {
        const reminderType = data.isPrebook ? 'GroupAppointmentPrebookReminder' : (appointments.some(a => (a.isNew || a.isFirstExam)) ? 'GroupAppointmentReminderNew' : 'GroupAppointmentReminder');

        if (timelineItem.onClick) {
            const onSuccess = timelineItem.name === 'Reminder' ? () => { handleSendAppointmentCommunication(reminderType) } : null;
            const extraProps = {
                timelineItem: timelineItem,
                appointment: data,
                customer: data.customer,
            };
            th.quickDrawerTrigger(timelineItem.onClick, quickDrawer, extraProps, onSuccess);
        } else {
            if (timelineItem.performedDateUtc) {
                handleConfirmAppointmentTimelineShow(data, timelineItem);
            } else {
                handleAppointmentTimelineCommit(data, timelineItem);
            }
        }
    }

    const handleConfirmAppointmentTimelineShow = (data, timelineItem) => {
        if (timelineItem) {
            const formattedText = timelineItem.isGrouped ? th.format(groupAppointment.appointments, timelineItem, timelineItem.menuFormat) : th.format(data, timelineItem, timelineItem.groupMenuFormat);

            confirmModalRef.current.show({
                icon: <i className='fal fa-backspace text-danger mr-2'></i>,
                message: <>Continue to <span className='fw-500'>&nbsp;undo&nbsp;'{formattedText}'&nbsp;</span>? </>,
                onOption1Click: () => { handleConfirmAppointmentTimelineDelete(data, timelineItem) },
                onCancel: handleConfirmAppointmentTimelineCancel,
            })
        }
    }

    const handleConfirmAppointmentTimelineDelete = (data, timelineItem) => {
        handleAppointmentTimelineCommit(data, timelineItem);
        confirmModalRef.current.close();
    }

    const handleConfirmAppointmentTimelineCancel = () => {
        confirmModalRef.current.close();
    }

    const handleAppointmentTimelineCommit = (data, timelineItem) => {
        setIsSaving(true);

        if (timelineItem.isGrouped) {
            updateGroupAppointment.groupTimeline(timelineItem.id)
                .then(() => {
                    if (isMounted.current) {
                        toast.dark(() => <p data-grp-appt-updated>Appointment updated.</p>);
                        setIsSaving(false);
                    }
                })
        }
        else {
            updateGroupAppointment.timeline(data.id, timelineItem.id)
                .then(() => {
                    if (isMounted.current) {
                        toast.dark(() => <p data-grp-appt-updated>Appointment updated.</p>);
                        setIsSaving(false);
                    }
                })
        }
    }

    const handleSendAppointmentCommunication = (action) => {
        const isValid = amh.checkGroupAppointmentIsValid(groupAppointment.data);
        const description = !isValid ?
            <strong data-please-setup-contact className='text-danger'>Please setup contact information and try again.</strong> :
            (
                groupAppointment.data && groupAppointment.data.primaryContact.contactInstructions && groupAppointment.data.primaryContact.contactInstructions.length > 0 ?
                    <><button type='button' className='btn btn-link p-0 fs-lg' onClick={e => { handleSendAppointmentCommunicationContactInstructions(groupAppointment.data.primaryContact.id) }}><strong className='text-danger'>Important:</strong>&nbsp;<span className='text-white'>See communication instructions</span></button></> : null
            );

        confirmModalRef.current.show({
            icon: amh.getIcon(groupAppointment.data, action),
            message: amh.getMessage(groupAppointment.data, action),
            description: description,
            option1ClassName: 'btn btn-appointment shadow-0 bootbox-accept' + (!isValid ? ' d-none' : ''),
            option1Text: 'Email',
            option1Disabled: (!groupAppointment.data || !groupAppointment.data.primaryContact.defaultEmailAddressContact),
            option2ClassName: 'btn btn-secondary shadow-0 bootbox-accept' + (!isValid ? ' d-none' : ''),
            option2Text: 'SMS',
            option2Disabled: (!groupAppointment.data || !groupAppointment.data.primaryContact.defaultPhoneNumberContact),
            cancelText: (isValid ? 'No' : 'OK'),
            onOption1Click: (e) => { handleCommunicationSend(e, 'email', action) },
            onOption2Click: (e) => { handleCommunicationSend(e, 'sms', action) },
            onCancel: handleCommunicationCancel
        })

        if (isMounted.current) {
            setCommunicationActive(true);
        }
    }

    const handleSendAppointmentCommunicationContactInstructions = (customerId) => {
        confirmModalRef.current.close();
        handleContactInstructions(customerId);
    }

    const handleCommunicationSend = (event, type, action) => {
        let defaultContact;

        if (type) {
            switch (type.toLowerCase()) {
                case 'email':
                    defaultContact = groupAppointment.data.primaryContact.defaultEmailAddressContact;
                    break;

                case 'sms':
                    defaultContact = groupAppointment.data.primaryContact.defaultPhoneNumberContact;
                    break;

                default:
                    break;
            }
        }

        communication.initialize(
            type,
            action,
            groupAppointment.data.groupId,
            [],
            groupAppointment.data.primaryContactId,
            defaultContact,
            null,
            null,
            null,
            groupAppointment.appointments.map(a => { return a.id }),
        )
            .then(() => {
                confirmModalRef.current.close();
                communicationModalRef.current.show(type);
            })
    }

    const handleCommunicationCancel = (event) => {
        confirmModalRef.current.close();

        if (isMounted.current) {
            setCommunicationActive(false);
        }
    }

    const handleAppointmentStatusChange = (event, appointment, newStatus) => {
        setIsSaving(true);
        updateAppointment.initialize(appointment.id)
            .then(() => {
                updateAppointment.status(newStatus)
                    .then(() => {
                        if (isMounted.current) {
                            toast.dark(() => <p data-grp-appt-updated>Appointment updated.</p>);
                            updateAppointment.clear();
                            setIsSaving(false);
                        }
                    })
            })
    }

    const handleGroupAppointmentStatusChange = (newStatus) => {
        setIsSaving(true);
        updateGroupAppointment.status(newStatus)
            .then(() => {
                if (isMounted.current) {
                    toast.dark(() => <p data-grp-appt-updated>Appointment updated.</p>);
                    setIsSaving(false);
                }
            })
    }

    const handleBookAnotherAppointment = (event, customer) => {
        newAppointment.stage(customer);

        if (fn.isFunction(props.onCancel)) {
            props.onCancel();
        }
    }

    const handleCommunicationSent = (event, result) => {
        if (result && result.type) {
            toast.dark(() => <p data-comm-sent><span className='tt-capitalize'>{result.type}</span> sent.</p>);
        }
        else {
            toast.dark(() => <p data-comm-sent>Communication sent.</p>);
        }

        communicationModalRef.current.close();
    }

    const handleCommunicationClose = () => {
        if (isMounted.current) {
            setCommunicationActive(false);
        }
    }

    const handleContactInstructions = (customerId) => {
        noteSearch.initialize(customerId, null, 'contactInstruction', false, true);
        quickDrawer.activateQuickDrawer('note', 'view', { title: 'Communication Instructions', icon: 'fal fa-comment-alt-exclamation', renderFilters: false });
    }

    const renderAppointmentDateTime = () => {
        if (!groupAppointment.data || !groupAppointment.data.start) return null;

        const start = moment(groupAppointment.data.start);
        const end = groupAppointment.data.end ? moment(groupAppointment.data.end) : null;
        const hasEndTime = !!end;
        const weekday = start.format('dddd');
        const day = start.format('D');
        const ordinal = start.format('Do').replace(day, '');
        const dateHtml = `${start.format('MMMM D')}<sup>${ordinal}</sup>${((start.year() !== moment().year()) ? `, ${start.format('YYYY')}` : '')}`;
        const startTimeHtml = `${start.format('h:mm')}${(!hasEndTime || start.format('a') !== end.format('a') ? ` ${start.format('a')}` : '')}`;
        const endTimeHtml = hasEndTime ? `${end.format('h:mm')} ${end.format('a')}` : '';

        return <div>
            <div className='d-flex'>
                <div className='flex-1'>
                    <ul className='list-inline m-0'>
                        <li className='list-inline-item m-0'>
                            <div className='text'>
                                <small className='weekday'>{weekday}</small>
                                <span className='date' dangerouslySetInnerHTML={{ __html: dateHtml }}></span>
                            </div>
                        </li>
                        <li className='list-inline-item my-0 mx-1'><small>@</small></li>
                        <li className='list-inline-item m-0'>
                            <div className='text'>
                                <span className='time' dangerouslySetInnerHTML={{ __html: startTimeHtml }}></span>
                            </div>
                        </li>
                        <li className='list-inline-item my-0 mx-1'><small>to</small></li>
                        <li className='list-inline-item m-0'>
                            <div className='text'>
                                <span className='time' dangerouslySetInnerHTML={{ __html: endTimeHtml }}></span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    }

    const renderResource = () => {
        if (!groupAppointment.data) return;

        const resource = groupAppointment.data.resource;

        return <div
            className='profile-wrapper pr-0'
        >
            <div className='profile'>
                <span
                    className={`profile-image rounded-circle d-block fw-500` + (resource && !resource.profilePictureUri ? ` profile-initials bg-color${resource.color}-500` : '')}
                    style={resource && resource.profilePictureUri ? {
                        backgroundImage: `url(${resource.profilePictureUri})`,
                        backgroundSize: 'cover',
                    } : null}
                    title={resource ? resource.fullName : 'System'}>
                    {resource && !resource.profilePictureUri ? <div className='d-initials'>{resource.initials}</div> : null}
                </span>
            </div>
            <div className='description flex-1'>
                <div className='name'>{resource.fullName}</div>
                <div className='services'>
                    <div>
                        {bh.renderAppointmentRescheduledByOffice(groupAppointment.data, 'p-1 mr-1 mt-1')}
                        {bh.renderAppointmentRescheduledByCustomer(groupAppointment.data, 'p-1 mr-1 mt-1')}
                    </div>
                </div>
            </div>
        </div>
    }

    const renderPrimaryContact = () => {
        if (!groupAppointment.data) return;

        const { primaryContact } = groupAppointment.data;

        return <div
            className='profile-wrapper pr-0 pb-0'
        >
            <div className='profile'>
                <span
                    className={`profile-image profile-initials rounded-circle d-flex text-white ${ch.getProfileColor(primaryContact)} fw-500`}
                    title={ph.getFullName(primaryContact)}
                >
                    {primaryContact.initials}
                </span>
            </div>
            <div className='description flex-1'>
                {bh.renderSpokenLanguage(primaryContact, 'fs-sm')}
                <Link
                    to={`${rts.Customers.Home}/${primaryContact.id}`}
                    className='name text-gray-700 text-info-hover'
                    onClick={quickDrawer.deactivateAll}
                >
                    {ph.getFullName(primaryContact, true)}
                    {
                        primaryContact.dateOfBirth || primaryContact.sex || primaryContact.gender || primaryContact.pronoun ?
                            <small className='text-nowrap ml-2'>({`${ph.getAge(primaryContact.dateOfBirth, moment(groupAppointment.data.start))} ${ph.getSexGenderPronounDisplay(primaryContact)}`.trim()})</small> : null
                    }
                </Link>
                {
                    primaryContact.address && primaryContact.address.country ?
                        <div className='info'>{ah.getAddressHtml(primaryContact.address)}</div> : null
                }
                {
                    primaryContact.emailAddress ?
                        <div className='info'>
                            <a
                                href={`mailto:${primaryContact.emailAddress}`}
                            >{primaryContact.emailAddress}
                            </a>
                        </div> : null
                }
                {
                    primaryContact.phoneNumber ?
                        <div className='info'>
                            <a
                                href={`tel:${primaryContact.phoneNumber}`}
                            >{sys.getFormattedPhoneNumber(primaryContact.phoneNumber)}
                            </a>
                        </div> : null
                }
                {
                    primaryContact.contactInstructions && primaryContact.contactInstructions.length > 0 ?
                        <div className='info'>
                            <button
                                type='button'
                                className='btn btn-link text-danger text-danger-hover fw-500 p-0'
                                onClick={e => { handleContactInstructions(primaryContact.id) }}
                            >See communication instructions</button>
                        </div> : null
                }
            </div>
            <div className='fs-sm pl-2'>
                <div className='mb-2'>
                    <button
                        type='button'
                        className='btn btn-icon line-height-1 mr-n2'
                        title={`Edit ${ph.getFullName(primaryContact, true)}`}
                        onClick={e => { handleUpdateCustomer(e, primaryContact) }}
                    >
                        <i className='fal fa-pen fs-lg'></i>
                    </button>
                </div>
            </div>
        </div>
    }

    const renderAppointment = (appointment) => {
        if (!appointment.customer) return;

        const { customer } = appointment;
        const contextMenuItems = [{
            icon: 'fal fa-pen',
            name: `Edit ${ph.getPreferredFirstName(customer)}`,
            callback: e => { handleUpdateCustomer(e, customer) },
        }];

        if (aph.isEndState(appointment)) {
            contextMenuItems.push({
                icon: 'fal fa-calendar-plus',
                name: `Book another appointment`,
                callback: e => { handleBookAnotherAppointment(e, customer) },
            });
        }

        if (ach.hasFullAccess(auth.currentUser.id) || th.isEnabled(groupAppointment.data, 'AppointmentDayOnly')) {
            if (appointment.status === 'Booked') {
                contextMenuItems.push({
                    icon: 'fas fa-check',
                    name: `Mark ${ph.getPreferredFirstName(customer)} as Complete`,
                    callback: e => { handleAppointmentStatusChange(e, appointment, 'Completed') },
                });
                contextMenuItems.push({
                    icon: 'fas fa-times',
                    name: `Mark ${ph.getPreferredFirstName(customer)} as No Show`,
                    callback: e => { handleAppointmentStatusChange(e, appointment, 'NoShow') },
                })
            }
            else if (appointment.status === 'Completed' && !getAllAreCompletedOrNoShow()) {
                contextMenuItems.push({
                    icon: 'fas fa-check',
                    name: `Undo ${ph.getPreferredFirstName(customer)} as Complete`,
                    callback: e => { handleAppointmentStatusChange(e, appointment, 'Booked') },
                });
            }
            else if (appointment.status === 'NoShow' && !getAllAreCompletedOrNoShow()) {
                contextMenuItems.push({
                    icon: 'fas fa-times',
                    name: `Undo ${ph.getPreferredFirstName(customer)} as No Show`,
                    callback: e => { handleAppointmentStatusChange(e, appointment, 'Booked') },
                });
            }
        }

        return <div
            className='profile-wrapper pr-0'
        >
            <div className='profile'>
                <span
                    className={`profile-image profile-initials rounded-circle d-flex text-white ${ch.getProfileColor(customer)} fw-500`}
                    title={ph.getFullName(customer)}
                >
                    {customer.initials}
                </span>
            </div>
            <div className='description flex-1'>
                <Link
                    to={`${rts.Customers.Home}/${customer.id}`}
                    className='name text-gray-700 text-info-hover'
                    onClick={quickDrawer.deactivateAll}
                >
                    {ph.getFullName(customer, true)}
                    {
                        customer.dateOfBirth || customer.sex || customer.gender || customer.pronoun ?
                            <small className='text-nowrap ml-2'>({`${ph.getAge(customer.dateOfBirth, moment(groupAppointment.data.start))} ${ph.getSexGenderPronounDisplay(customer)}`.trim()})</small> : null
                    }
                </Link>
                {
                    customer.dateOfBirth ?
                        <div className='info text-gray-700'><span className='text-nowrap mr-1'><strong className='mr-1'>DOB:</strong>{sys.getFormattedLongDate(customer.dateOfBirth)}</span></div> : null
                }
                {
                    customer.patientProfile && customer.patientProfile.healthCardNumber ?
                        <div className='info text-gray-700'><span className='text-nowrap mr-1'><strong className='mr-1'>HC #:</strong>{ph.formatHealthCard(customer.patientProfile.healthCardNumber)}</span><small className={'text-nowrap'}>(Exp. {fn.formatDate(customer.patientProfile.healthCardExpiryDate, 'MMM Do, YYYY')})</small></div> : null
                }
                {
                    !getAllAreCompleted() && appointment.status === 'Completed' ?
                        <div className=''>
                            <strong className='text-success'>{ph.getPreferredFirstName(customer)}'s appointment completed</strong>
                        </div> : null
                }
                {
                    !getAllAreNoShow() && appointment.status === 'NoShow' ?
                        <div className=''>
                            <strong className='text-danger'>{ph.getPreferredFirstName(customer)} did not show</strong>
                        </div> : null
                }
                <div className='py-1'>
                    {bh.renderAppointmentNew(appointment, 'p-1 mr-1')}
                    {bh.renderAppointmentFirstExam(appointment, 'p-1 mr-1')}
                    {
                        appointment.services.map((s, si) => {
                            return <Fragment
                                key={`service_${appointment.id}_${si}`}
                            >
                                {bh.renderServiceCode(s, 'p-1 mr-1', (!s.isEligible || !aph.isEndState(appointment)))}
                            </Fragment>
                        })
                    }
                </div>
            </div>
            <div className='fs-sm pl-2'>
                <div className='mb-2'>
                    <div
                        id={`view_appointment_context_menu_${appointment.id}_${customer.id}`}
                        className='btn btn-icon line-height-1 mr-n2'
                    >
                        <i className='fal fa-ellipsis-v fs-180'></i>
                    </div>
                    <ContextMenu
                        target={`#view_appointment_context_menu_${appointment.id}_${customer.id}`}
                        position={{ my: 'top right', at: 'top left' }}
                        dataSource={contextMenuItems}
                        displayExpr='name'
                        showEvent="dxcontextmenu click"
                        onItemClick={handleContextMenuItemClick}
                    >
                    </ContextMenu>
                </div>
                {
                    !aph.isEndState(appointment) && appointment.services.some(s => s.isEligibilityCheckRequired) && getIsEditable() ?
                        <div className='mb-2'>
                            <button
                                type='button'
                                className='btn btn-icon line-height-1 mr-n2'
                                title={`Verify eligibility`}
                                onClick={(e) => { handleVerifyEligibility(e, appointment.id) }}
                            >
                                <i className='fal fa-file-search fs-lg'></i>
                            </button>
                        </div> : null
                }
            </div>
        </div>
    }

    const renderAppointmentTimeline = () => {
        const isEditable = getIsEditable();
        const timeline = updateGroupAppointment.appointments && updateGroupAppointment.appointments.length > 0 ? updateGroupAppointment.appointments.reduce((array, appointment) => {
            if (appointment && appointment.timeline && appointment.timeline.length > 0) {
                for (var ti = 0; ti < appointment.timeline.length; ti++) {
                    const timelineItem = appointment.timeline[ti];
                    const isEditableTimelineItem = isEditable && th.isEnabled(appointment, timelineItem.restriction, timelineItem.id, timelineItem.id, timelineItem.displayOrder) && (timelineItem.performedDateUtc || !timelineItem.isAutomated);
                    const isPerformedTimelineItem = !!timelineItem.performedDateUtc;

                    if (isEditableTimelineItem || isPerformedTimelineItem) {
                        if (array.some(a => a.id === timelineItem.id)) {
                            const index = array.findIndex(a => a.id === timelineItem.id);
                            const existingItem = array[index];
                            const isExistingItemAfter = !!timelineItem.performedDateUtc && moment.utc(existingItem.performedDateUtc).isAfter(moment.utc(timelineItem.performedDateUtc));

                            if (!existingItem.performedDateUtc || isExistingItemAfter) {
                                array[index] = timelineItem;
                            }
                        }
                        else {
                            array.push(toJS(timelineItem));
                        }
                    }
                }
            }
            return array;
        }, []) : [];
        // const timeline = isEditable ? updateGroupAppointment.data.timeline.filter(t => th.isEnabled(updateGroupAppointment.data, t.restriction, t.id, t.id, t.displayOrder) && (t.performedDateUtc || !t.isAutomated)) : updateGroupAppointment.data.timeline.filter(t => t.performedDateUtc);

        return timeline && timeline.length > 0 ?
            timeline.sort((a, b) => a.displayOrder - b.displayOrder).map((t, ti) => {
                if (t.isGrouped) {
                    const started = (updateGroupAppointment.data.timeline.some(l => l.displayOrder > t.displayOrder && l.performedDateUtc) || getSomeAreCompletedOrNoShow());

                    return <div
                        key={`timeline_grouped_${updateGroupAppointment.data.id}_${ti}`}
                        className={
                            'timeline-item' +
                            ((isEditable && !t.performedDateUtc) ? ' incomplete' : '') +
                            (started ? ' started' : '')}
                    >
                        <div className='timeline-left'>
                            <span
                                className={'timeline-icon has-icon'}
                                title={th.format(updateGroupAppointment.appointments, t, t.tooltipFormat)}
                                style={
                                    (isEditable && !t.performedDateUtc) ? null : {
                                        borderColor: t.colorHexValue,
                                        color: t.colorHexValue
                                    }
                                }
                            >
                                <i className={`fal fa-${t.icon}`}></i>
                            </span>
                        </div>
                        <div className='timeline-content'>
                            <div className='d-flex'>
                                {
                                    (isEditable && (t.onClick || !t.performedDateUtc)) ?
                                        <>
                                            <div className='flex-1'>
                                                <button
                                                    type='button'
                                                    className='btn btn-link p-0 text-truncate'
                                                    disabled={isSaving}
                                                    title={(t.performedDateUtc ? th.format(updateGroupAppointment.appointments, t, t.tooltipFormat) : null)}
                                                    onClick={() => handleAppointmentTimelineChange(updateGroupAppointment.data, updateGroupAppointment.appointments, t)}
                                                >
                                                    <span dangerouslySetInnerHTML={{ __html: th.format(updateGroupAppointment.appointments, t, (t.onClick && t.performedDateUtc ? t.performedHtmlFormat : t.menuFormat), true) }}></span>
                                                </button>
                                            </div>
                                        </> :
                                        <>
                                            <span
                                                title={(t.performedDateUtc ? th.format(updateGroupAppointment.appointments, t, t.tooltipFormat) : null)}
                                                dangerouslySetInnerHTML={{ __html: th.format(updateGroupAppointment.appointments, t, t.performedHtmlFormat, true) }}
                                            >
                                            </span>
                                            {
                                                (isEditable && !t.isAutomated)
                                                    ? <button
                                                        type='button'
                                                        className='btn btn-icon btn-close mr-n2'
                                                        title={(t.performedDateUtc ? th.format(updateGroupAppointment.appointments, t, t.tooltipFormat) : null)}
                                                        onClick={() => handleAppointmentTimelineChange(updateGroupAppointment.data, updateGroupAppointment.appointments, t)}
                                                    >
                                                        <i className='fal fa-times'></i>
                                                    </button> : null
                                            }
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                }
                else {
                    return updateGroupAppointment.appointments.map((appointment, appointmentIndex) => {
                        const groupedItem = appointment.timeline.filter(tl => tl.id === t.id)[0];
                        const groupStarted = (appointment.timeline.some(l => l.displayOrder > t.displayOrder && l.performedDateUtc) || getSomeAreCompletedOrNoShow());

                        return groupedItem && appointment.status !== 'NoShow' && (!groupedItem.isAutomated || (groupedItem.isAutomated && groupedItem.performedDateUtc)) ?
                            <div
                                key={`timeline_not_grouped_${appointment.id}_${ti}_${appointmentIndex}`}
                                className={
                                    'timeline-item' +
                                    ((isEditable && !groupedItem.performedDateUtc) ? ' incomplete' : '') +
                                    (groupStarted ? ' started' : '')}
                            >
                                <div className='timeline-left'>
                                    <span
                                        className={'timeline-icon has-icon'}
                                        title={th.format(appointment, groupedItem, groupedItem.tooltipFormat)}
                                        style={
                                            (isEditable && !groupedItem.performedDateUtc) ? null : {
                                                borderColor: groupedItem.colorHexValue,
                                                color: groupedItem.colorHexValue
                                            }
                                        }
                                    >
                                        <i className={`fal fa-${groupedItem.icon}`}></i>
                                    </span>
                                </div>
                                <div className='timeline-content'>
                                    <div className='d-flex'>
                                        {
                                            isEditable && (groupedItem.onClick || !groupedItem.performedDateUtc) ?
                                                <>
                                                    <div className='flex-1'>
                                                        <button
                                                            type='button'
                                                            className='btn btn-link p-0 text-truncate'
                                                            disabled={isSaving}
                                                            title={(groupedItem.performedDateUtc ? th.format(appointment, groupedItem, groupedItem.tooltipFormat) : null)}
                                                            onClick={() => handleAppointmentTimelineChange(appointment, updateGroupAppointment.appointments, groupedItem)}
                                                        >
                                                            <span dangerouslySetInnerHTML={{ __html: th.format(appointment, groupedItem, (groupedItem.onClick && groupedItem.performedDateUtc ? groupedItem.groupPerformedHtmlFormat : groupedItem.groupMenuFormat), true) }}></span>
                                                        </button>
                                                    </div>
                                                </> :
                                                <>
                                                    <span
                                                        title={(groupedItem.performedDateUtc ? th.format(appointment, groupedItem, groupedItem.tooltipFormat) : null)}
                                                        dangerouslySetInnerHTML={{ __html: th.format(appointment, groupedItem, groupedItem.groupPerformedHtmlFormat, true) }}
                                                    >
                                                    </span>
                                                    {
                                                        (isEditable && !groupedItem.isAutomated)
                                                            ? <button
                                                                type='button'
                                                                className='btn btn-icon btn-close mr-n2'
                                                                title={(groupedItem.performedDateUtc ? th.format(appointment, groupedItem, groupedItem.tooltipFormat) : null)}
                                                                onClick={() => handleAppointmentTimelineChange(appointment, updateGroupAppointment.appointments, groupedItem)}
                                                            >
                                                                <i className='fal fa-times'></i>
                                                            </button> : null
                                                    }
                                                </>
                                        }
                                    </div>
                                </div>
                            </div> : <Fragment key={`timeline_not_grouped_${appointment.id}_${ti}_${appointmentIndex}`}></Fragment>
                    })
                }
            }) : null
    }

    const renderIconButtons = () => {
        if (groupAppointment.isReady) {
            const notes = aph.groupAppointmentNotes(groupAppointment.appointments);

            return <>
                <button
                    type='button'
                    className='btn btn-icon'
                    onClick={handleSeeNotes}
                    title='Notes'
                >
                    <i className='fal fa-comment-alt-lines'></i>
                    {
                        notes && notes.length > 0 ?
                            <span className={`badge badge-icon`}>{notes.length}</span> : null
                    }
                </button>
            </>
        }
    }

    const getIsEditable = () => {
        const isCompletedOrNoShow = !getAllAreCompletedOrNoShow();
        return ach.hasFullAccess(auth.currentUser.id) || (groupAppointment.appointments && groupAppointment.data && (isCompletedOrNoShow && moment(groupAppointment.data.start).startOf('day').diff(moment().startOf('day')) >= 0));
    }

    const getAllAreBooked = () => {
        return aph.isAllAreStatus(groupAppointment.appointments, 'Booked');
    }

    const getSomeAreBooked = () => {
        return aph.isSomeAreStatus(groupAppointment.appointments, 'Booked');
    }

    const getSomeAreCompleted = () => {
        return aph.isSomeAreStatus(groupAppointment.appointments, 'Completed');
    }

    const getSomeAreNoShow = () => {
        return aph.isSomeAreStatus(groupAppointment.appointments, 'NoShow');
    }

    const getAllAreCompleted = () => {
        return aph.isAllAreStatus(groupAppointment.appointments, 'Completed');
    }

    const getAllAreNoShow = () => {
        return aph.isAllAreStatus(groupAppointment.appointments, 'NoShow');
    }

    const getAllAreCompletedOrNoShow = () => {
        return aph.isAllAreStatus(groupAppointment.appointments, ['Completed', 'NoShow']);
    }

    const getSomeAreCompletedOrNoShow = () => {
        return aph.isSomeAreStatus(groupAppointment.appointments, 'Completed') || aph.isSomeAreStatus(groupAppointment.appointments, 'NoShow');
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) && !communicationActive && !communication.isReady ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                props.onCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form>
            <fieldset>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('appointment')}
                        action='View'
                        category='Group Appointment'
                        className='appointments'
                        onCancel={props.onCancel}
                    />
                    <div className='quick-drawer-body'>
                        {
                            groupAppointment.isReady && updateGroupAppointment.isReady ?
                                <FadeIn>
                                    <div className='view-group-appointment body-content'>
                                        {
                                            groupAppointment.data && groupAppointment.data.customer && groupAppointment.data.customer.hasOutstandingBalance ?
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='alert alert-danger mb-0' role='alert'>
                                                                <strong className='d-block mb-2'>Important</strong>
                                                                <ul className='pl-3 mb-0'>
                                                                    <li>{ph.getPreferredFirstName(groupAppointment.data.customer)} an outstanding balance on file.</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section> : null
                                        }
                                        {
                                            eligibles ?
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            {
                                                                mohReady ?
                                                                    <div className={'alert alert-info p-3 mb-0'} role='alert'>
                                                                        <strong className='d-block mb-2'>Information</strong>
                                                                        <ul className='pl-3 mb-0'>
                                                                            {
                                                                                eligibles.map((i, ii) => {
                                                                                    return <li key={`new_group_appointment_eligible_${ii}`}>{bh.renderServiceCode(i.service, 'float-left mr-1')} {ph.getPreferredFirstName(i.customer)}'s eligibility confirmed.</li>
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div> :
                                                                    <div className={'alert alert-warning p-3 mb-0'} role='alert'>
                                                                        <strong className='d-block mb-2'>Warning</strong>
                                                                        <ul className='pl-3 mb-0'>
                                                                            <li><strong className='text-warning-900'>{auth.currentTenant.publicInsuranceUnitId} verification has not been setup.</strong></li>
                                                                            {
                                                                                eligibles.filter(e => e.isAgeChecked).map((i, ii) => {
                                                                                    return <li key={`view_group_appointment_eligible_${ii}`}>{bh.renderServiceCode(i.service, 'float-left mr-1')} Age eligibility confirmed.</li>
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </section> : null
                                        }
                                        {
                                            ineligibles ?
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='alert alert-warning p-3 mb-0' role='alert'>
                                                                <strong className='d-block mb-2'>Warning</strong>
                                                                <ul className='pl-3 mb-0'>
                                                                    {
                                                                        !mohReady ? <li><strong className='text-warning-900'>{auth.currentTenant.publicInsuranceUnitId} verification has not been setup.</strong></li> : null
                                                                    }
                                                                    {
                                                                        ineligibles.map((i, ii) => {
                                                                            return <li key={`view_group_appointment_ineligible_${ii}`}>{bh.renderServiceCode(i.service, 'float-left mr-1')} {i.earliestEligibleDate ? <>{ph.getPreferredFirstName(i.customer)} is not eligible until <span className='tt-underline'>{moment(i.earliestEligibleDate).format('YYYY-MM-DD')}</span>.</> : (i.ineligibilityReason ? `${(!!i.ineligibilityCode ? `(Error: ${i.ineligibilityCode}) ` : '')} ${ph.getPreferredFirstName(i.customer)} - ${i.ineligibilityReason}` : `${ph.getPreferredFirstName(i.customer)} - Cannot verify.  Reason unknown.`)}</li>
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section> : null
                                        }
                                        <section className='date-time'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    {renderAppointmentDateTime()}
                                                </div>
                                            </div>
                                        </section>
                                        <section className='resource'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0'>
                                                        <label><small>With</small></label>
                                                        {renderResource()}
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section className='customer'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0'>
                                                        <label><small>Primary Contact</small></label>
                                                        {renderPrimaryContact()}
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section className='customer'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0'>
                                                        <label><small>For</small></label>
                                                        {
                                                            groupAppointment.appointments && groupAppointment.appointments.length > 0 ?
                                                                groupAppointment.appointments.slice().sort((a, b) => {
                                                                    if (a.customer.id === a.primaryContactId) {
                                                                        return 1;
                                                                    }
                                                                    else if (a.customer.dateOfBirth && b.customer.dateOfBirth) {
                                                                        return moment(a.customer.dateOfBirth).isBefore(moment(b.customer.dateOfBirth)) ? -1 : 1;
                                                                    }
                                                                    else {
                                                                        return a.customer.firstName - b.customer.firstName;
                                                                    }
                                                                }).map((a, ai) => {
                                                                    return <div key={`group_group_appointment_${a.groupId}_${a.id}_${ai}`} className='mt-2'>
                                                                        {renderAppointment(a)}
                                                                    </div>
                                                                }) : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        {
                                            groupAppointment.data ?
                                                <section className='timeline-status'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className={'timeline' + ((getAllAreCompleted() ? ' completed' : '')) + ((getAllAreNoShow() ? ' no-show' : ''))}>
                                                                <div className={'timeline-item' + ((groupAppointment.data.timeline.some(t => t.performedDateUtc) || getSomeAreCompletedOrNoShow()) ? ' started' : '')}>
                                                                    <div className='timeline-left'>
                                                                        <span className='timeline-icon' title={`Booked on ${fn.formatFullDateWithOrWithoutYear(moment.utc(groupAppointment.data.createdDateUtc).local(), moment(groupAppointment.data.start))}\n${uh.getDisplayShortNameById(groupAppointment.data.createdById, groupAppointment.data.createdBy)}`}></span>
                                                                    </div>
                                                                    <div className='timeline-content'>
                                                                        <div className='d-flex'>
                                                                            <span className='flex-1'>
                                                                                {
                                                                                    getSomeAreBooked() && getIsEditable() ?
                                                                                        <button
                                                                                            type='button'
                                                                                            className='btn btn-link btn-sm p-0'
                                                                                            onClick={() => { handleSendAppointmentCommunication((groupAppointment.appointments.some(a => (a.isNew || a.isFirstExam)) ? 'GroupAppointmentBookedNew' : 'GroupAppointmentBooked')) }}
                                                                                            title={`Booked on ${th.format(groupAppointment.data, { performedDateUtc: groupAppointment.data.createdDateUtc, performedBy: groupAppointment.data.createdBy, performedById: groupAppointment.data.createdById }, '{PerformedDate} by {PerformedBy}', false)}`}
                                                                                        >
                                                                                            Booked on <strong><span dangerouslySetInnerHTML={{ __html: th.format(groupAppointment.data, { performedDateUtc: groupAppointment.data.createdDateUtc, performedBy: groupAppointment.data.createdBy, performedById: groupAppointment.data.createdById }, '{PerformedDate}', true) }}></span></strong>
                                                                                        </button> :
                                                                                        <span
                                                                                            title={`Booked on ${th.format(groupAppointment.data, { performedDateUtc: groupAppointment.data.createdDateUtc, performedBy: groupAppointment.data.createdBy, performedById: groupAppointment.data.createdById }, '{PerformedDate} by {PerformedBy}', false)}`}
                                                                                        >
                                                                                            Booked on <strong><span dangerouslySetInnerHTML={{ __html: th.format(groupAppointment.data, { performedDateUtc: groupAppointment.data.createdDateUtc, performedBy: groupAppointment.data.createdBy, performedById: groupAppointment.data.createdById }, '{PerformedDate}', true) }}></span></strong>
                                                                                        </span>
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    updateGroupAppointment.isReady ?
                                                                        <>{renderAppointmentTimeline()}</> : null
                                                                }
                                                                {
                                                                    updateGroupAppointment.isReady ?
                                                                        <>
                                                                            {
                                                                                getSomeAreBooked() || getAllAreCompleted() ?
                                                                                    <div className={'timeline-item' + (getAllAreCompleted() ? '' : ' incomplete')}>
                                                                                        <div className='timeline-left'>
                                                                                            <span
                                                                                                className={'timeline-icon has-icon' + (getAllAreCompleted() ? ' text-success-600 border-success-600' : '')}
                                                                                            >
                                                                                                <i className={`fas fa-check`}></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className='timeline-content'>
                                                                                            {
                                                                                                getAllAreCompleted() ?
                                                                                                    <>
                                                                                                        <span
                                                                                                            className='fw-500 text-success-600'
                                                                                                            title={`Completed at ${th.format(updateGroupAppointment.data, { performedDateUtc: updateGroupAppointment.data.completedDateUtc, performedBy: updateGroupAppointment.data.completedBy, performedById: updateGroupAppointment.data.completedById }, '{PerformedTime} by {PerformedBy}', false)}`}
                                                                                                        >
                                                                                                            Appointment completed
                                                                                                        </span>
                                                                                                        {
                                                                                                            ach.hasFullAccess(auth.currentUser.id) || !updateGroupAppointment.data.completedDateUtc || moment.utc(updateGroupAppointment.data.completedDateUtc).local().startOf('day').isSame(moment().startOf('day'))
                                                                                                                ? <button
                                                                                                                    type='button'
                                                                                                                    className='btn btn-icon btn-close mr-n2'
                                                                                                                    onClick={() => handleGroupAppointmentStatusChange('Booked')}
                                                                                                                >
                                                                                                                    <i className='fal fa-times'></i>
                                                                                                                </button> : null
                                                                                                        }
                                                                                                    </> :
                                                                                                    <>
                                                                                                        {
                                                                                                            ach.hasFullAccess(auth.currentUser.id) ?
                                                                                                                <button
                                                                                                                    type='button'
                                                                                                                    className='btn btn-link p-0'
                                                                                                                    disabled={isSaving}
                                                                                                                    onClick={() => handleGroupAppointmentStatusChange('Completed')}
                                                                                                                >
                                                                                                                    Mark {getSomeAreCompletedOrNoShow() ? 'rest' : 'all'} as Completed
                                                                                                                </button> :
                                                                                                                <button
                                                                                                                    type='button'
                                                                                                                    className='btn btn-link p-0'
                                                                                                                    disabled={isSaving || !th.isEnabled(updateGroupAppointment.data, 'AppointmentDayOnly')}
                                                                                                                    onClick={() => handleGroupAppointmentStatusChange('Completed')}
                                                                                                                >
                                                                                                                    Mark {getSomeAreCompletedOrNoShow() ? 'rest' : 'all'} as Completed
                                                                                                                </button>
                                                                                                        }
                                                                                                    </>
                                                                                            }
                                                                                        </div>
                                                                                    </div> : null
                                                                            }
                                                                            {
                                                                                getSomeAreBooked() || getAllAreNoShow() ?
                                                                                    <div className={'timeline-item' + (getAllAreNoShow() ? '' : ' incomplete')}>
                                                                                        <div className='timeline-left'>
                                                                                            <span
                                                                                                className={'timeline-icon has-icon' + (getAllAreNoShow() ? ' text-danger border-danger' : '')}
                                                                                            >
                                                                                                <i className={`fas fa-exclamation`}></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className='timeline-content'>
                                                                                            {
                                                                                                getAllAreNoShow() ?
                                                                                                    <>
                                                                                                        <span
                                                                                                            className='fw-500 text-danger'
                                                                                                            title={`Marked as No Show at ${th.format(updateGroupAppointment.data, { performedDateUtc: updateGroupAppointment.data.noShowDateUtc, performedBy: updateGroupAppointment.data.noShowBy, performedById: updateGroupAppointment.data.noShowById }, '{PerformedTime} by {PerformedBy}', false)}`}
                                                                                                        >
                                                                                                            Customers did not show
                                                                                                        </span>
                                                                                                        {
                                                                                                            ach.hasFullAccess(auth.currentUser.id) || !updateGroupAppointment.data.noShowDateUtc || moment.utc(updateGroupAppointment.data.noShowDateUtc).local().startOf('day').isSame(moment().startOf('day'))
                                                                                                                ? <button
                                                                                                                    type='button'
                                                                                                                    className='btn btn-icon btn-close mr-n2'
                                                                                                                    onClick={() => handleGroupAppointmentStatusChange('Booked')}
                                                                                                                >
                                                                                                                    <i className='fal fa-times'></i>
                                                                                                                </button> : null
                                                                                                        }
                                                                                                    </> :
                                                                                                    <>
                                                                                                        {
                                                                                                            ach.hasFullAccess(auth.currentUser.id) ?
                                                                                                                <button
                                                                                                                    type='button'
                                                                                                                    className='btn btn-link p-0'
                                                                                                                    disabled={isSaving}
                                                                                                                    onClick={() => handleGroupAppointmentStatusChange('NoShow')}
                                                                                                                >
                                                                                                                    Mark {getSomeAreCompletedOrNoShow() ? 'rest' : 'all'} as No Show
                                                                                                                </button> :
                                                                                                                <button
                                                                                                                    type='button'
                                                                                                                    className='btn btn-link p-0'
                                                                                                                    disabled={isSaving || !th.isEnabled(updateGroupAppointment.data, 'AppointmentDayOnly')}
                                                                                                                    onClick={() => handleGroupAppointmentStatusChange('NoShow')}
                                                                                                                >
                                                                                                                    Mark {getSomeAreCompletedOrNoShow() ? 'rest' : 'all'} as No Show
                                                                                                                </button>
                                                                                                        }
                                                                                                    </>
                                                                                            }
                                                                                        </div>
                                                                                    </div> : null
                                                                            }
                                                                            {
                                                                                getAllAreCompletedOrNoShow() && getSomeAreCompleted() && getSomeAreNoShow() ?
                                                                                    <div className={'timeline-item warning'}>
                                                                                        <div className='timeline-left'>
                                                                                            <span
                                                                                                className={'timeline-icon has-icon text-warning border-warning'}
                                                                                            >
                                                                                                <i className={`fas fa-check`}></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className='timeline-content'>
                                                                                            <span
                                                                                                className='fw-500 text-warning'
                                                                                            >
                                                                                                Completed, but some did not show
                                                                                            </span>
                                                                                            {
                                                                                                ach.hasFullAccess(auth.currentUser.id) || !updateGroupAppointment.data.completedDateUtc || moment.utc(updateGroupAppointment.data.completedDateUtc).local().startOf('day').isSame(moment().startOf('day'))
                                                                                                    ? <button
                                                                                                        type='button'
                                                                                                        className='btn btn-icon btn-close mr-n2'
                                                                                                        onClick={() => handleGroupAppointmentStatusChange('Booked')}
                                                                                                    >
                                                                                                        <i className='fal fa-times'></i>
                                                                                                    </button> : null
                                                                                            }
                                                                                        </div>
                                                                                    </div> : null
                                                                            }
                                                                        </> : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section> : null
                                        }
                                    </div>
                                </FadeIn> : renderQuickDrawerLoading()
                        }
                    </div>
                    <div className='quick-drawer-action pl-3'>
                        <div className='row'>
                            <div className='col-5'>
                                {groupAppointment.isReady && updateGroupAppointment.isReady ? renderIconButtons() : null}
                            </div>
                            <div className='col-7'>
                                <div className='float-right'>
                                    {
                                        groupAppointment.isReady && updateGroupAppointment.isReady && getIsEditable() && getAllAreBooked() ?
                                            <>
                                                <button
                                                    type='button'
                                                    className='btn btn-danger mr-2'
                                                    onClick={handleDeleteAppointment}
                                                >Delete</button>
                                                <button
                                                    type='button'
                                                    className='btn btn-primary'
                                                    onClick={handleChangeAppointment}
                                                >Change</button>
                                            </> :
                                            <>
                                                <button
                                                    type='button'
                                                    className='btn btn-link btn-cancel'
                                                    onClick={props.onCancel}
                                                >Cancel</button>
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
        <BodyEnd>
            <ConfirmModal ref={confirmModalRef} />
            <CommunicationModal ref={communicationModalRef} onSent={handleCommunicationSent} onClose={handleCommunicationClose} />
        </BodyEnd>
    </>)
}

export default ViewGroupAppointment;