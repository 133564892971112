import { createContext } from 'react';
import { decorate, observable, action } from 'mobx';
import { CURRENT_TENANT } from '../constants/storageKeys';

import api from '../api';
import * as fn from '../utilities/_functions';

export class UserCreate {
    data = {
        firstName: '',
        lastName: '',
        username: '',
        emailAddress: '',
        providerType: 'Social',
        providerName: 'Google',
        password: '',
        salutation: null,
        acceptBooking: false,
        isAssociate: false,
    };
    isLoading = false;
    isSaving = false;
    isReady = false;
    hasUnsavedChanges = false;

    cancelUser = null;
    cancelUserCreate = null;

    initialize = (id, full) => {
        this.clear();
        this.isReady = true;
    }

    save = async (notify) => {
        if (!!notify) {
            this.isSaving = true;
        }

        if (this.hasUnsavedChanges) {
            try {
                const tenant = JSON.parse(window.localStorage.getItem(CURRENT_TENANT));
                await api.Users.create(
                    {
                        providerType: this.data.providerType,
                        providerName: this.data.providerType !== 'Social' ? 'Auth0' : this.data.providerName,
                        organizationId: tenant.organizationId,
                        tenantId: tenant.id,
                        firstName: this.data.firstName,
                        lastName: this.data.lastName,
                        username: this.data.username,
                        emailAddress: this.data.emailAddress,
                        password: this.data.password,
                        profilePictureUri: this.data.profilePictureUri,
                        phoneNumber: this.data.phoneNumber ? this.data.phoneNumber.number : null,
                        personalEmailAddress: this.personalEmailAddress,
                        personalPhoneNumber: this.data.personalPhoneNumber ? this.data.personalPhoneNumber.number : null,
                        designation: this.data.designation,
                        salutation: this.data.salutation,
                        acceptBooking: this.data.acceptBooking,
                        isAssociate: this.data.isAssociate,
                        registrationNumber: this.data.registrationNumber,
                        licenseNumber: this.data.licenseNumber,
                        signature: this.data.signature,
                    },
                    (c) => { this.cancelUserCreate = c });
                this.hasUnsavedChanges = false;
                return this.data;
            }
            catch(e) {
                if (e.response) {
                    throw e.response.data;
                }
                else throw e;
            }
            finally {
                this.isSaving = false;
            }
        }
        else {
            this.isSaving = false;
        }
    }

    clear = () => {
        this.data = {
            firstName: '',
            lastName: '',
            username: '',
            emailAddress: '',
            providerType: 'Social',
            providerName: 'Google',
            password: '',
            salutation: null,
            acceptBooking: false,
            isAssociate: false
        };
        this.isSaving = false;
        this.isLoading = false;
        this.isReady = false;
        this.hasUnsavedChanges = false;

        if (fn.isFunction(this.cancelUser)) {
            this.cancelUser();
            this.cancelUser = null;
        }

        if (fn.isFunction(this.cancelUserCreate)) {
            this.cancelUserCreate();
            this.cancelUserCreate = null;
        }
    }
}

decorate(UserCreate, {
    data: observable.deep,
    isSaving: observable,
    isLoading: observable,
    isReady: observable,
    hasUnsavedChanges: observable,
    initialize: action,
    refresh: action,
    clear: action,
})

export default createContext(new UserCreate());