import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { CURRENT_USER } from '../constants/storageKeys';

export const reactPlugin = new ReactPlugin();
export const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.REACT_APP_APPINSIGHTS_KEY,
        enableAutoRouteTracking: true,
        disableFetchTracking: true,
        disableAjaxTracking: true,
        loggingLevelConsole: 2,
        extensions: [reactPlugin],
        extensionConfig: [reactPlugin]
    }
});
export const createAppInsightsMessage = (properties) => {
    const currentUser = JSON.parse(window.localStorage.getItem(CURRENT_USER));
    return {
        user: currentUser,
        ...properties
    }
}

// var telemetryInitializer = (envelope) => {
// 	envelope.data.accountId = JSON.parse(window.localStorage.getItem(CURRENT_USER)).id;
// };
appInsights.loadAppInsights();
//appInsights.addTelemetryInitializer(telemetryInitializer);
