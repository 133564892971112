import React, { useEffect, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import FadeIn from 'react-fade-in';
import { useObserver } from 'mobx-react-lite';
import { GlobalHotKeys } from 'react-hotkeys';

import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import AddressInput from '../../_shared/AddressInput';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';

import CustomerUpdateStore from '../../../../stores/CustomerUpdateStore';
import AuthStore from '../../../../stores/AuthStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as rts from '../../../../constants/routes';
import * as fn from '../../../../utilities/_functions';
import * as ch from '../../../../utilities/customerHelper';
import * as ph from '../../../../utilities/personHelper';
import * as sys from '../../../../utilities/systemHelper';
import * as oh from '../../../../utilities/operationHelper';

import './UpdateAddress.scss';

function UpdateAddress(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const focusTimer = useRef(null);
    const homeAddressRef = useRef(null);
    const shippingAddressRef = useRef(null);
    const customer = useContext(CustomerUpdateStore);
    const auth = useContext(AuthStore);
    const quickDrawer = useContext(QuickDrawerStore);

    useEffect(() => {
        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100)

        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (customer.hasAddressUnsavedChanges || customer.isShippingAddressSameAsHome) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleIsShippingSameAsHomeChange = () => {
        customer.hasAddressUnsavedChanges = true;
        customer.data.isShippingAddressSameAsHome = !customer.data.isShippingAddressSameAsHome;
    }

    const handleHomeAddressChange = (address) => {
        customer.newAddress = address;
        customer.hasAddressUnsavedChanges = true;
    }

    const handleShippingAddressChange = (address) => {
        customer.newShippingAddress = address;
        customer.hasAddressUnsavedChanges = true;
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (homeAddressRef.current.validate() && shippingAddressRef.current.validate()) {
            if (customer.hasAddressUnsavedChanges) {
                const updated = customer.hasAddressUnsavedChanges;

                customer.updateAddressInformation()
                    .then(data => {
                        if (isMounted.current) {
                            if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                                props.onSuccess(event, { updated: updated, data: data });
                            }
                        }
                    })
            } else {
                if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                    props.onSuccess(event, { updated: false, data: null });
                }
            }
        }
    }

    const renderCustomer = () => {
        return <div
            className='profile-wrapper'
        >
            <div className='profile'>
                <span
                    className={`profile-image profile-initials rounded-circle d-flex text-white ${ch.getProfileColor(customer.data)} fw-500`}
                    title={ph.getFullName(customer.data)}
                >
                    {customer.data.initials}
                </span>
            </div>
            <div className='description flex-1'>
                <Link to={`${rts.Customers.Home}/${customer.data.id}`} className='name text-gray-700' onClick={quickDrawer.deactivateAll}>{ph.getFullName(customer.data, true)}
                    {
                        customer.data.dateOfBirth || customer.data.sex || customer.data.gender || customer.data.pronoun ?
                            <small className='text-nowrap ml-2'>({`${ph.getAge(customer.data.dateOfBirth)} ${ph.getSexGenderPronounDisplay(customer.data)}`.trim()})</small> : null
                    }
                </Link>
                {
                    customer.data.emailAddress ?
                        <div className='info'>
                            <a
                                href={`mailto:${customer.data.emailAddress}`}
                            >{customer.data.emailAddress}
                            </a>
                        </div> : null
                }
                {
                    customer.data.phoneNumber ?
                        <div className='info'>
                            <a
                                href={`tel:${customer.data.phoneNumber}`}
                            >{sys.getFormattedPhoneNumber(customer.data.phoneNumber)}
                            </a>
                        </div> : null
                }
            </div>
        </div>
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <fieldset disabled={customer.isSaving}>
                <div className='update-customer-address-container quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('customer', 'address')}
                        action='Update Customer'
                        category='Address'
                        className='customers'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        {
                            customer.isReady && customer.data ?
                                <FadeIn>
                                    <div className='body-content'>
                                        <section className='customer'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0'>
                                                        <label><small>Customer</small></label>
                                                        {renderCustomer()}
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <AddressInput
                                                ref={homeAddressRef}
                                                address={(customer.data && customer.data.address ? customer.data.address : {})}
                                                label={'Home address'}
                                                defaultCountryCode={(auth.currentUser && auth.currentTenant && auth.currentTenant.address ? auth.currentTenant.address.countryCode : null)}
                                                canUpdate={ch.checkCanUpdate(customer.data)}
                                                onChange={handleHomeAddressChange}
                                            />
                                        </section>
                                        <section>
                                            <div className='divider py-2 mb-5' />
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group'>
                                                        <div className='custom-control custom-checkbox'>
                                                            <input
                                                                id='address-existing-is-shipping-same'
                                                                type='checkbox'
                                                                name='address-existing-is-shipping-same'
                                                                className='custom-control-input'
                                                                disabled={!ch.checkCanUpdate(customer.data)}
                                                                checked={customer.data.isShippingAddressSameAsHome ? customer.data.isShippingAddressSameAsHome : false}
                                                                onChange={handleIsShippingSameAsHomeChange}
                                                            />
                                                            <label
                                                                htmlFor='address-existing-is-shipping-same'
                                                                className='custom-control-label'
                                                            >
                                                                Shipping address same as home address
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section className={(customer.data.isShippingAddressSameAsHome ? 'd-none' : '')}>
                                            <AddressInput
                                                ref={shippingAddressRef}
                                                address={(customer.data && customer.data.shippingAddress ? customer.data.shippingAddress : {})}
                                                label={'Shipping address'}
                                                defaultCountryCode={(auth.currentUser && auth.currentTenant && auth.currentTenant.address ? auth.currentTenant.address.countryCode : null)}
                                                canUpdate={ch.checkCanUpdate(customer.data)}
                                                onChange={handleShippingAddressChange}
                                            />
                                        </section>
                                    </div>
                                </FadeIn> : renderQuickDrawerLoading()
                        }
                    </div>
                    <div className='quick-drawer-action pl-3'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                        disabled={!ch.checkCanUpdate(customer.data)}
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>)
}

export default UpdateAddress;