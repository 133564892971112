import React, { useContext } from 'react';
import AuthStore from '../../../stores/AuthStore';
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import api from '../../../api';
import SignalRContext from '../../../components/SignalRContext'

function Logout({ isFailover }) {
    const { logout } = useAuth0();
    const auth = useContext(AuthStore);
    const location = useLocation();
    const signalr = useContext(SignalRContext);

    const qs = queryString.parse(location.search);
    const loginName = qs.user;

    auth.reset();
    auth.clearSession();

    if (loginName) {
        api.Organizations.subdomain(loginName)
            .then(({ data }) => {
                signalr.disconnect();

                // console.log('Subdomain:', data.subdomain);
                if (data && data.subdomain && process.env.NODE_ENV !== 'development') {
                    const originUrl = process.env.REACT_APP_PUBLIC_URL.replace(/^https:\/\/[^.]+./, `https://${data.subdomain}.`);
                    // console.log('Origin:', originUrl);
                    logout({
                        returnTo: originUrl
                    });
                }
                else {
                    logout({
                        returnTo: window.location.origin
                    });
                }
            });
    }
    else {
        if (isFailover) {
            window.history.pushState({}, '', '/');
            window.location.reload();
        }
        else {
            logout({
                returnTo: window.location.origin
                // If you want to do switch Google account (or other social logins), you would need to do a hard logout, i.e. federated logout from the IDP
            });
        }
    }

    return (
        <></>
    )
}

export default Logout;