import React from 'react';
import uuid from 'react-uuid';

import * as ModalSettings from '../constants/modalSettings';

export const MODAL_TEMPLATE_SIZE_SMALL_WIDTH = ModalSettings.SMALL_WIDTH;
export const MODAL_TEMPLATE_SIZE_SMALL_HEIGHT = ModalSettings.SMALL_HEIGHT;
export const MODAL_TEMPLATE_SIZE_SMALL_GRID = ModalSettings.SMALL_GRID;
export const MODAL_TEMPLATE_SIZE_MEDIUM_WIDTH = ModalSettings.MEDIUM_WIDTH;
export const MODAL_TEMPLATE_SIZE_MEDIUM_HEIGHT = ModalSettings.MEDIUM_HEIGHT;
export const MODAL_TEMPLATE_SIZE_MEDIUM_GRID = ModalSettings.MEDIUM_GRID;
export const MODAL_TEMPLATE_SIZE_LARGE_WIDTH = ModalSettings.LARGE_WIDTH;
export const MODAL_TEMPLATE_SIZE_LARGE_HEIGHT = ModalSettings.LARGE_HEIGHT;
export const MODAL_TEMPLATE_SIZE_LARGE_GRID = ModalSettings.LARGE_GRID;
export const MODAL_TEMPLATE_MARGINS = ModalSettings.MARGINS;

export const MODAL_TEMPLATE_ROW_HEIGHT = 5;

export const MODAL_ELEMENT_RICHTEXT_EDITOR = 'Richtext';
export const MODAL_ELEMENT_IMAGE = 'Image';

export const MODAL_TEMPLATE_METADATA_FIELDS = 'MODAL_TEMPLATE_METADATA_FIELDS';
export const MODAL_TEMPLATE_METADATA_ALIGN = 'MODAL_TEMPLATE_METADATA_ALIGN';
export const MODAL_TEMPLATE_METADATA_VALIGN = 'MODAL_TEMPLATE_METADATA_VALIGN';
export const MODAL_TEMPLATE_METADATA_PADDING = 'MODAL_TEMPLATE_METADATA_PADDING';
export const MODAL_TEMPLATE_METADATA_FONT_COLOR = 'MODAL_TEMPLATE_METADATA_FONT_COLOR';
export const MODAL_TEMPLATE_METADATA_FONT_SIZE = 'MODAL_TEMPLATE_METADATA_FONT_SIZE';
export const MODAL_TEMPLATE_METADATA_FONT_FAMILY = 'MODAL_TEMPLATE_METADATA_FONT_FAMILY';
export const MODAL_TEMPLATE_METADATA_FONT_STYLE = 'MODAL_TEMPLATE_METADATA_FONT_STYLE';
export const MODAL_TEMPLATE_METADATA_FONT_LETTER_SPACING = 'MODAL_TEMPLATE_METADATA_FONT_LETTER_SPACING';
export const MODAL_TEMPLATE_METADATA_BACKGROUND_COLOR = 'MODAL_TEMPLATE_METADATA_BACKGROUND_COLOR';
export const MODAL_TEMPLATE_METADATA_BORDER = 'MODAL_TEMPLATE_METADATA_BORDER';
export const MODAL_TEMPLATE_METADATA_BORDER_COLOR = 'MODAL_TEMPLATE_METADATA_BORDER_COLOR';
export const MODAL_TEMPLATE_METADATA_ALTERNATE_BACKGROUND_COLOR = 'MODAL_TEMPLATE_METADATA_BACKGROUND_COLOR';
export const MODAL_TEMPLATE_METADATA_CONTENT = 'MODAL_TEMPLATE_METADATA_CONTENT';
export const MODAL_TEMPLATE_METADATA_IMAGE = 'MODAL_TEMPLATE_METADATA_IMAGE';
export const MODAL_TEMPLATE_METADATA_FORMAT = 'MODAL_TEMPLATE_METADATA_FORMAT';
export const MODAL_TEMPLATE_METADATA_DISPLAY = 'MODAL_TEMPLATE_METADATA_DISPLAY';
export const MODAL_TEMPLATE_METADATA_SIGNATURE = 'MODAL_TEMPLATE_METADATA_SIGNATURE';

export const ModalElementTypes = [
    MODAL_ELEMENT_RICHTEXT_EDITOR,
    MODAL_ELEMENT_IMAGE,
];

export const createElement = (type) => {
    return {
        id: uuid(),
        type: type,
        metadata: {},
        position: getDefaultPosition(type),
    };
}

export const isModalElement = ({ type }) => {
    return ModalElementTypes.some(t => t === type);
}

export const hasField = (type, field) => {
    switch (type) {
        case MODAL_ELEMENT_RICHTEXT_EDITOR:
            return [
                MODAL_TEMPLATE_METADATA_ALIGN,
                MODAL_TEMPLATE_METADATA_VALIGN,
                MODAL_TEMPLATE_METADATA_PADDING,
                MODAL_TEMPLATE_METADATA_FONT_COLOR,
                MODAL_TEMPLATE_METADATA_FONT_SIZE,
                MODAL_TEMPLATE_METADATA_FONT_FAMILY,
                MODAL_TEMPLATE_METADATA_FONT_LETTER_SPACING,
                MODAL_TEMPLATE_METADATA_BACKGROUND_COLOR,
                MODAL_TEMPLATE_METADATA_BORDER,
                MODAL_TEMPLATE_METADATA_BORDER_COLOR,
                MODAL_TEMPLATE_METADATA_CONTENT,
                MODAL_TEMPLATE_METADATA_DISPLAY,
            ].some(f => f === field);

        case MODAL_ELEMENT_IMAGE:
            return [
                MODAL_TEMPLATE_METADATA_ALIGN,
                MODAL_TEMPLATE_METADATA_VALIGN,
                MODAL_TEMPLATE_METADATA_IMAGE,
                MODAL_TEMPLATE_METADATA_DISPLAY,
            ].some(f => f === field);

        default:
            return false;
    }
}

export const getName = (type) => {
    switch (type) {
        case MODAL_ELEMENT_RICHTEXT_EDITOR:
            return 'Richtext';

        case MODAL_ELEMENT_IMAGE:
            return 'Image';

        default:
            return '';
    }
}

export const getDescription = (type) => {
    switch (type) {
        case MODAL_ELEMENT_RICHTEXT_EDITOR:
            return 'Richtext';

        case MODAL_ELEMENT_IMAGE:
            return 'Image';

        default:
            return '';
    }
}

export const getIcon = (type) => {
    switch (type) {
        case MODAL_ELEMENT_RICHTEXT_EDITOR:
            return 'fal fa-text-size';

        case MODAL_ELEMENT_IMAGE:
            return 'fal fa-image';

        default:
            return '';
    }
}

export const renderElement = (element, options) => {
    const defaults = {
        controlKey: `_${uuid()}`,
        readOnly: null,
        isDesignMode: true,
        defaultValue: null,
        quickDrawer: null,
        onClick: null,
        onDoubleClick: null,
        onContextMenu: null,
    };
    options = { ...defaults, ...options }

    if (options.modalSettings) {
        options.modalSettings.size = options.modalSettings.size ? options.modalSettings.size : getSizeDimension('medium');
        options.modalSettings.margins = options.modalSettings.margins ? options.modalSettings.margins : MODAL_TEMPLATE_MARGINS;
    } else {
        options.modalSettings = {
            size: getSizeDimension('medium'),
            margins: MODAL_TEMPLATE_MARGINS,
        };
    }

    switch (element.type) {
        case MODAL_ELEMENT_RICHTEXT_EDITOR:
            return <div
                className={'form-group print-element no-label' + (element.metadata && element.metadata.valign && element.metadata.valign !== 'top' ? ` text-${element.metadata.valign}` : '')}
                style={{
                    borderTop: element.metadata && element.metadata.border && element.metadata.border.top ? element.metadata.border.top : 'none',
                    borderRight: element.metadata && element.metadata.border && element.metadata.border.right ? element.metadata.border.right : 'none',
                    borderBottom: element.metadata && element.metadata.border && element.metadata.border.bottom ? element.metadata.border.bottom : 'none',
                    borderLeft: element.metadata && element.metadata.border && element.metadata.border.left ? element.metadata.border.left : 'none',
                    backgroundColor: element.metadata && element.metadata.backgroundColor ? element.metadata.backgroundColor : 'transparent',
                }}
                data-id={element.id}
                onDoubleClick={options ? options.onDoubleClick : null}
                onContextMenu={options ? options.onContextMenu : null}
            >
                <div
                    data-key={`pe_${element.id}`}
                    className='element-wrapper'
                    style={{
                        lineHeight: 1.2,
                        textAlign: element.metadata && element.metadata.align ? element.metadata.align : 'left',
                        padding: element.metadata && element.metadata.padding && element.metadata.padding.length === 4 ? element.metadata.padding.map(p => { return `${p}px` }).join(' ') : null,
                        color: element.metadata && element.metadata.fontColor ? element.metadata.fontColor : null,
                        fontSize: element.metadata && element.metadata.fontSize ? `${element.metadata.fontSize}px` : null,
                        fontFamily: element.metadata && element.metadata.fontFamily ? element.metadata.fontFamily : null,
                        fontWeight: element.metadata && element.metadata.fontStyle && element.metadata.fontStyle.some(s => s === 'bold') ? 'bold' : null,
                        fontStyle: element.metadata && element.metadata.fontStyle && element.metadata.fontStyle.some(s => s === 'italic') ? 'italic' : null,
                        textDecoration: element.metadata && element.metadata.fontStyle && element.metadata.fontStyle.some(s => s === 'underline') ? 'underline' : null,
                        letterSpacing: element.metadata && element.metadata.fontLetterSpacing ? `${element.metadata.fontLetterSpacing}px` : null,
                    }}
                >
                    {
                        element.type === MODAL_ELEMENT_RICHTEXT_EDITOR ?
                            <span dangerouslySetInnerHTML={{ __html: element.metadata.content }}></span> :
                            <span><p>{options.defaultValue ? options.defaultValue : null}</p></span>
                    }
                </div>
            </div>

        case MODAL_ELEMENT_IMAGE:
            return <div
                className={'form-group print-element no-label' + (element.metadata && element.metadata.valign && element.metadata.valign !== 'top' ? ` text-${element.metadata.valign}` : '')}
                data-id={element.id}
                onDoubleClick={options ? options.onDoubleClick : null}
                onContextMenu={options ? options.onContextMenu : null}
            >
                <div
                    data-key={`pe_${element.id}`}
                    className='element-wrapper'
                    style={{
                        textAlign: element.metadata && element.metadata.align ? element.metadata.align : 'left',
                    }}
                >
                    <img
                        src={`${(element.metadata && element.metadata.image ? element.metadata.image.base64 : null)}`}
                        style={{
                            width: (element.metadata && element.metadata.image ? getContainImage(element, options.modalSettings).width : null),
                            height: (element.metadata && element.metadata.image ? getContainImage(element, options.modalSettings).height : null),
                        }}
                        alt='print-element'
                    />
                </div>
            </div>

        default:
            break;
    }
}

export const getDefaultPosition = type => {
    switch (type) {
        case MODAL_ELEMENT_IMAGE:
            return { w: 10, h: 10, minW: 1 };

        case MODAL_ELEMENT_RICHTEXT_EDITOR:
            return { w: 20, h: 10, minW: 2 };

        default:
            return { w: 10, h: 5, minW: 2, };
    }
}

export const getContainImage = (imageElement, modalSettings) => {
    let { width: imageWidth, height: imageHeight } = imageElement.metadata.image;
    const containerWidth = parseFloat(imageElement.position.w * ((modalSettings.size.width - (modalSettings.margins * 2)) / 64), 10);
    const containerHeight = parseFloat(imageElement.position.h * MODAL_TEMPLATE_ROW_HEIGHT, 10);

    if (containerHeight >= containerWidth) {
        if (imageHeight > containerHeight) {
            const heightScale = containerHeight / imageHeight;

            imageWidth = imageWidth * heightScale;
            imageHeight = containerHeight;
        }

        if (imageWidth > containerWidth) {
            const scale = containerWidth / imageWidth;

            imageWidth = containerWidth;
            imageHeight = imageHeight * scale;
        }
    }
    else if (containerWidth > containerHeight) {
        if (imageWidth > containerWidth) {
            const widthScale = containerWidth / imageWidth;

            imageWidth = containerWidth;
            imageHeight = imageHeight * widthScale;
        }

        if (imageHeight > containerHeight) {
            const scale = containerHeight / imageHeight;

            imageWidth = imageWidth * scale;
            imageHeight = containerHeight;
        }
    }

    return {
        width: imageWidth,
        height: imageHeight,
    };
}

export const getSizeDimension = (size) => {
    if (!size) {
        size = 'medium';
    }

    switch (size.toLowerCase()) {
        case 'small':
            return {
                type: size.toLowerCase(),
                width: MODAL_TEMPLATE_SIZE_SMALL_WIDTH,
                height: MODAL_TEMPLATE_SIZE_SMALL_HEIGHT,
                grid: MODAL_TEMPLATE_SIZE_SMALL_GRID,
            };

        case 'medium':
            return {
                type: size.toLowerCase(),
                width: MODAL_TEMPLATE_SIZE_MEDIUM_WIDTH,
                height: MODAL_TEMPLATE_SIZE_MEDIUM_HEIGHT,
                grid: MODAL_TEMPLATE_SIZE_MEDIUM_GRID,
            };

        case 'large':
            return {
                type: size.toLowerCase(),
                width: MODAL_TEMPLATE_SIZE_LARGE_WIDTH,
                height: MODAL_TEMPLATE_SIZE_LARGE_HEIGHT,
                grid: MODAL_TEMPLATE_SIZE_LARGE_GRID,
            };

        default:
            return {
                type: size.toLowerCase(),
                width: MODAL_TEMPLATE_SIZE_MEDIUM_WIDTH,
                height: MODAL_TEMPLATE_SIZE_MEDIUM_HEIGHT,
                grid: MODAL_TEMPLATE_SIZE_MEDIUM_GRID,
            };
    }
}