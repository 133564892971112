import { createContext } from 'react';
import uuid from 'react-uuid';
import { decorate, observable, action, toJS } from 'mobx';

import * as fn from '../utilities/_functions';
import * as ith from '../utilities/internalFormTemplateHelper';

import api from '../api';

export class InternalFormTemplateCreate {
    id = null;
    data = {
        pageSettings: { 
            paper: ith.getPaperDimension('letter'),
            margins: ith.INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_MARGINS_NORMAL,
        },
        category: null,
        name: null,
        descriptionHtml: null,
        settings: [],
    };
    isReady = false;
    isSaving = false;
    hasUnsavedChanges = false;

    cancelInternalFormTemplateCreate = null;

    initialize = () => {
        this.clear();
        this.id = uuid();
        this.isReady = true;
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            let option = toJS(that.data);

            api.InternalFormTemplates.create(option, (c) => { that.cancelInternalFormTemplateCreate = c })
                .then(({ data }) => {
                    that.id = data.id;
                    that.hasUnsavedChanges = false;
                    option.id = data.id;
                    resolve(option);
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    clear = () => {
        this.id = null;
        this.data.pageSettings = { 
            paper: ith.getPaperDimension('letter'),
            margins: ith.INTERNAL_FORM_TEMPLATE_PAGE_SETTINGS_MARGINS_NORMAL,
        };
        this.data.category = null;
        this.data.name = null;
        this.data.descriptionHtml = null;
        this.data.settings.clear();
        this.hasUnsavedChanges = false;

        if (fn.isFunction(this.cancelInternalFormTemplateCreate)) {
            this.cancelInternalFormTemplateCreate();
            this.cancelInternalFormTemplateCreate = null;
        }
    }
}

decorate(InternalFormTemplateCreate, {
    id: observable,
    data: observable.deep,
    hasUnsavedChanges: observable,
    isReady: observable,
    isSaving: observable,
    initialize: action,
    save: action,
    clear: action,
})

export default createContext(new InternalFormTemplateCreate());