import { createContext } from 'react';
import { decorate, observable, action, toJS } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class WorkOrderInputUpdate {
    id = null;
    data = null;
    hasUnsavedChanges = false;
    isLoading = false;
    isSaving = false;
    saveToServer = true;

    cancelWorkOrderInputGet = null;
    cancelWorkOrderInputUpdate = null;

    load = (id, saveToServer) => {
        const that = this;
        this.clear();
        this.id = id;
        this.isLoading = true;

        if (saveToServer) {
            this.saveToServer = saveToServer;
        }

        return new Promise((resolve, reject) => {
            api.WorkOrderInputs.get(id, (c) => { that.cancelWorkOrderInputGet = c })
                .then(({ data }) => {
                    that.data = data;
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isLoading = false;
                })
        })
    }

    loadExisting = (existing) => {
        this.clear();
        this.id = existing.id;
        this.saveToServer = false;
        this.data = existing;

        return Promise.resolve();
    }

    setMetadata = (key, value) => {
        let metadata = this.data.metadata;

        if (!metadata) {
            metadata = {};
        }

        if (!metadata.hasOwnProperty(key)) {
            metadata = { ...metadata, ...{ [key]: value } };
        } else {
            metadata[key] = value;
        }

        this.data.metadata = metadata;
    }

    removeMetadata = (key) => {
        let metadata = this.data.metadata;

        if (metadata && metadata.hasOwnProperty(key)) {
            delete metadata[key];
        }
    }

    getMetadata = (key) => {
        if (!this.data.metadata) return undefined;
        return this.data.metadata[key];
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            const option = toJS(that.data);

            if (that.hasUnsavedChanges) {
                if (that.saveToServer) {
                    api.WorkOrderInputs.update(that.id, option, (c) => { that.cancelWorkOrderInputUpdate = c })
                        .then(() => {
                            that.hasUnsavedChanges = false;
                            resolve(option);
                        })
                        .catch(() => {
                            reject();
                        })
                        .finally(() => {
                            that.isSaving = false;
                        })
                } else {
                    resolve(option);
                }
            } else {
                that.hasUnsavedChanges = false;
                that.isSaving = false;
                resolve(option);
            }
        })
    }

    clear = () => {
        this.id = null;
        this.data = null;
        this.hasUnsavedChanges = false;
        this.isLoading = false;
        this.isSaving = false;
        this.saveToServer = true;

        if (fn.isFunction(this.cancelWorkOrderInputGet)) {
            this.cancelWorkOrderInputGet();
            this.cancelWorkOrderInputGet = null;
        }

        if (fn.isFunction(this.cancelWorkOrderInputUpdate)) {
            this.cancelWorkOrderInputUpdate();
            this.cancelWorkOrderInputUpdate = null;
        }
    }
}

decorate(WorkOrderInputUpdate, {
    id: observable,
    data: observable,
    hasUnsavedChanges: observable,
    isLoading: observable,
    isSaving: observable,
    load: action,
    save: action,
    setMetadata: action,
    removeMetadata: action,
    getMetadata: action,
    clear: action,
})

export default createContext(new WorkOrderInputUpdate());