import React, { useLayoutEffect } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';

import * as fn from '../../../utilities/_functions';
import * as th from '../../../utilities/templateHelper';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

function TemplateGrid(props) {
    useLayoutEffect(() => {        
        if (props.onContentReady && fn.isFunction(props.onContentReady)) {
            props.onContentReady();
        }
      });
      
    const handleResize = (layout, oldItem, item, placeholder, event, resizeHandler) => {
        const element = resizeHandler && resizeHandler.parentElement ? resizeHandler.parentElement.querySelector('.element-wrapper') : null;
        const rowHeight = getRowHeight();

        if (element) {
            const minHeight = Math.ceil(element.offsetHeight / rowHeight);

            if (item.h < minHeight) {
                item.h = minHeight;
            }
        }
    }

    const getRowHeight = () => {
        return (props.rowHeight ? props.rowHeight : 10);
    }

    return <>
        <ResponsiveReactGridLayout
            breakpoints={props.breakpoints ? props.breakpoints : { lg: 1200, md: 960, sm: 640, xs: 560, xxs: 320 }}
            cols={props.cols ? props.cols : {
                lg: th.TEMPLATE_DESIGNER_COL_SIZE,
                md: th.TEMPLATE_DESIGNER_COL_SIZE,
                sm: th.TEMPLATE_DESIGNER_COL_SIZE,
                xs: th.TEMPLATE_DESIGNER_COL_SIZE,
                xxs: th.TEMPLATE_DESIGNER_COL_SIZE
            }}
            autoSize={props.autoSize ? props.autoSize : true}
            rowHeight={getRowHeight()}
            margin={props.margin ? props.margin : [0, 5]}
            style={props.style ? props.style : null}
            isDroppable={props.isEditable ? true : false}
            isDraggable={props.isEditable ? true : false}
            isResizable={props.isEditable ? true : false}
            isBounded={props.isBounded ? props.isBounded : false}
            onDrag={props.onDrag}
            onDragStop={props.onDragStop}
            onDragStart={props.onDragStart}
            compactType={null}
            verticalCompact={true}
            preventCollision={true}
            resizeHandles={['s', 'e', 'se']}
            onDrop={props.onDrop}
            onLayoutChange={props.onLayoutChange}
            onResize={handleResize}
            useCSSTransforms={props.useCSSTransforms == null ? true : props.useCSSTransforms}

        >
            {props.children}
        </ResponsiveReactGridLayout>
    </>
}

export default TemplateGrid;