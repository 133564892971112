import React from 'react';
import uuid from 'react-uuid';
import { toJS } from 'mobx';
import moment from 'moment';

import BodyEnd from '../components/views/_shared/BodyEnd';
import PrescriptionHistory from '../components/views/_shared/PrescriptionHistory';
import PrescriptionSummary from '../components/views/_shared/PrescriptionSummary';
import ExamDiagnosticCodeList from '../components/views/_shared/ExamDiagnosticCodeList';
import TemplateGrid from '../components/views/_shared/TemplateGrid';

import * as fn from '../utilities/_functions';
import * as tih from '../utilities/templateInputHelper';
import * as tth from '../utilities/templateTextHelper';
import * as pth from '../utilities/printTemplateHelper';
import * as mh from '../utilities/mentionHelper';
import * as dh from '../utilities/deviceHelper';

export const TEMPLATE_CONTROL_CALCULATION_TEXTBOX = 'CalculationTextbox';
export const TEMPLATE_CONTROL_CALCULATION_CHECKBOX = 'CalculationCheckbox';
export const TEMPLATE_CONTROL_TIMESTAMP_BUTTON = 'TimestampButton';
export const TEMPLATE_CONTROL_PRETEST_SELECTOR = 'PretestSelector';
export const TEMPLATE_CONTROL_COPY_FIELDS_BUTTON = 'CopyFieldsButton';
export const TEMPLATE_CONTROL_SET_FIELDS_BUTTON = 'SetFieldsButton';
export const TEMPLATE_CONTROL_PRESCRIPTION_CREATE_BUTTON = 'PrescriptionCreateButton';
export const TEMPLATE_CONTROL_PRESCRIPTION_COPY_BUTTON = 'PrescriptionCopyButton';
export const TEMPLATE_CONTROL_PRODUCT_COPY_BUTTON = 'ProductCopyButton';
export const TEMPLATE_CONTROL_PRESCRIPTION_LIST = 'PrescriptionList';
export const TEMPLATE_CONTROL_PRESCRIPTION_SUMMARY = 'PrescriptionSummary';
export const TEMPLATE_CONTROL_DIAGNOSTIC_CODE_LIST = 'DiagnosticCodeList';
export const TEMPLATE_CONTROL_REFERRING_PROVIDER_NUMBER = 'ReferringProviderNumber';
export const TEMPLATE_CONTROL_IMPORT_FROM_DEVICE_BUTTON = 'ImportFromDeviceButton';
export const TEMPLATE_CONTROL_EXPORT_TO_DEVICE_BUTTON = 'ExportToDeviceButton';

export const TEMPLATE_CONTROL_LABEL = 'TEMPLATE_CONTROL_LABEL';
export const TEMPLATE_CONTROL_TEXT = 'TEMPLATE_CONTROL_TEXT';
export const TEMPLATE_CONTROL_METADATA_LINK = 'TEMPLATE_CONTROL_METADATA_LINK';
export const TEMPLATE_CONTROL_METADATA_SIZE = 'TEMPLATE_CONTROL_METADATA_SIZE';
export const TEMPLATE_CONTROL_METADATA_ALIGN = 'TEMPLATE_CONTROL_METADATA_ALIGN';
export const TEMPLATE_CONTROL_METADATA_VALIGN = 'TEMPLATE_CONTROL_METADATA_VALIGN';
export const TEMPLATE_CONTROL_METADATA_OPTIONS = 'TEMPLATE_CONTROL_METADATA_OPTIONS';
export const TEMPLATE_CONTROL_METADATA_INLINE = 'TEMPLATE_CONTROL_METADATA_INLINE';
export const TEMPLATE_CONTROL_METADATA_EQUATION = 'TEMPLATE_CONTROL_METADATA_EQUATION';
export const TEMPLATE_CONTROL_METADATA_TIMESTAMP_DESTINATION = 'TEMPLATE_CONTROL_METADATA_TIMESTAMP_DESTINATION';
export const TEMPLATE_CONTROL_METADATA_FIELDS_EQUATIONS = 'TEMPLATE_CONTROL_METADATA_FIELDS_EQUATIONS';
export const TEMPLATE_CONTROL_METADATA_COPY_FIELDS_OPTIONS = 'TEMPLATE_CONTROL_COPY_FIELDS_OPTIONS';
export const TEMPLATE_CONTROL_METADATA_SET_FIELDS_OPTIONS = 'TEMPLATE_CONTROL_SET_FIELDS_OPTIONS';
export const TEMPLATE_CONTROL_METADATA_OVERWRITE = 'TEMPLATE_CONTROL_OVERWRITE';
export const TEMPLATE_CONTROL_METADATA_PRETEST_OPTIONS = 'TEMPLATE_CONTROL_METADATA_PRETEST_OPTIONS';
export const TEMPLATE_CONTROL_METADATA_PRESCRIPTION_CREATE_OPTIONS = 'TEMPLATE_CONTROL_METADATA_PRESCRIPTION_CREATE_OPTIONS';
export const TEMPLATE_CONTROL_METADATA_PRESCRIPTION_COPY_OPTIONS = 'TEMPLATE_CONTROL_METADATA_PRESCRIPTION_COPY_OPTIONS';
export const TEMPLATE_CONTROL_METADATA_PRESCRIPTION_LIST_OPTIONS = 'TEMPLATE_CONTROL_METADATA_PRESCRIPTION_LIST_OPTIONS';
export const TEMPLATE_CONTROL_METADATA_PRESCRIPTION_SUMMARY_OPTIONS = 'TEMPLATE_CONTROL_METADATA_PRESCRIPTION_SUMMARY_OPTIONS';
export const TEMPLATE_CONTROL_METADATA_PRODUCT_COPY_OPTIONS = 'TEMPLATE_CONTROL_METADATA_PRODUCT_COPY_OPTIONS';
export const TEMPLATE_CONTROL_METADATA_IMPORT_FROM_DEVICE_OPTIONS = 'TEMPLATE_CONTROL_METADATA_IMPORT_FROM_DEVICE_OPTIONS';
export const TEMPLATE_CONTROL_METADATA_EXPORT_TO_DEVICE_OPTIONS = 'TEMPLATE_CONTROL_METADATA_EXPORT_TO_DEVICE_OPTIONS';

export const ControlElementTypes = [
    TEMPLATE_CONTROL_CALCULATION_TEXTBOX,
    TEMPLATE_CONTROL_CALCULATION_CHECKBOX,
    TEMPLATE_CONTROL_TIMESTAMP_BUTTON,
    TEMPLATE_CONTROL_PRETEST_SELECTOR,
    TEMPLATE_CONTROL_COPY_FIELDS_BUTTON,
    TEMPLATE_CONTROL_SET_FIELDS_BUTTON,
    TEMPLATE_CONTROL_PRESCRIPTION_CREATE_BUTTON,
    TEMPLATE_CONTROL_PRESCRIPTION_COPY_BUTTON,
    TEMPLATE_CONTROL_PRODUCT_COPY_BUTTON,
    TEMPLATE_CONTROL_PRESCRIPTION_LIST,
    TEMPLATE_CONTROL_PRESCRIPTION_SUMMARY,
    TEMPLATE_CONTROL_DIAGNOSTIC_CODE_LIST,
    TEMPLATE_CONTROL_REFERRING_PROVIDER_NUMBER,
    TEMPLATE_CONTROL_IMPORT_FROM_DEVICE_BUTTON,
    TEMPLATE_CONTROL_EXPORT_TO_DEVICE_BUTTON,
];

export const createElement = (type) => {
    const element = {
        id: uuid(),
        type: type,
        text: null,
        descriptionHtml: null,
        metadata: {},
    };

    if (hasField(type, TEMPLATE_CONTROL_LABEL)) {
        element.label = getDefaultLabel(element);
    }

    element.position = getDefaultPosition(element);

    return element;
}

export const isControlElement = ({ type }) => {
    return ControlElementTypes.some(t => t === type);
}

export const hasField = (type, field) => {
    switch (type) {
        case TEMPLATE_CONTROL_CALCULATION_TEXTBOX:
            return [
                TEMPLATE_CONTROL_LABEL,
                TEMPLATE_CONTROL_METADATA_SIZE,
                TEMPLATE_CONTROL_METADATA_ALIGN,
                TEMPLATE_CONTROL_METADATA_EQUATION,
            ].some(f => f === field);

        case TEMPLATE_CONTROL_CALCULATION_CHECKBOX:
            return [
                TEMPLATE_CONTROL_LABEL,
                TEMPLATE_CONTROL_METADATA_SIZE,
                TEMPLATE_CONTROL_METADATA_VALIGN,
                TEMPLATE_CONTROL_METADATA_FIELDS_EQUATIONS,
            ].some(f => f === field);

        case TEMPLATE_CONTROL_TIMESTAMP_BUTTON:
            return [
                TEMPLATE_CONTROL_TEXT,
                TEMPLATE_CONTROL_METADATA_SIZE,
                TEMPLATE_CONTROL_METADATA_ALIGN,
                TEMPLATE_CONTROL_METADATA_TIMESTAMP_DESTINATION,
            ].some(f => f === field);

        case TEMPLATE_CONTROL_PRETEST_SELECTOR:
            return [
                TEMPLATE_CONTROL_METADATA_PRETEST_OPTIONS,
            ].some(f => f === field);

        case TEMPLATE_CONTROL_COPY_FIELDS_BUTTON:
            return [
                TEMPLATE_CONTROL_TEXT,
                TEMPLATE_CONTROL_METADATA_SIZE,
                TEMPLATE_CONTROL_METADATA_ALIGN,
                TEMPLATE_CONTROL_METADATA_OVERWRITE,
                TEMPLATE_CONTROL_METADATA_COPY_FIELDS_OPTIONS,
            ].some(f => f === field);

        case TEMPLATE_CONTROL_SET_FIELDS_BUTTON:
            return [
                TEMPLATE_CONTROL_TEXT,
                TEMPLATE_CONTROL_METADATA_SIZE,
                TEMPLATE_CONTROL_METADATA_ALIGN,
                TEMPLATE_CONTROL_METADATA_OVERWRITE,
                TEMPLATE_CONTROL_METADATA_SET_FIELDS_OPTIONS,
            ].some(f => f === field);

        case TEMPLATE_CONTROL_PRESCRIPTION_CREATE_BUTTON:
            return [
                TEMPLATE_CONTROL_TEXT,
                TEMPLATE_CONTROL_METADATA_SIZE,
                TEMPLATE_CONTROL_METADATA_ALIGN,
                TEMPLATE_CONTROL_METADATA_PRESCRIPTION_CREATE_OPTIONS,
            ].some(f => f === field);

        case TEMPLATE_CONTROL_PRESCRIPTION_COPY_BUTTON:
            return [
                TEMPLATE_CONTROL_TEXT,
                TEMPLATE_CONTROL_METADATA_SIZE,
                TEMPLATE_CONTROL_METADATA_ALIGN,
                TEMPLATE_CONTROL_METADATA_PRESCRIPTION_COPY_OPTIONS,
            ].some(f => f === field);

        case TEMPLATE_CONTROL_PRODUCT_COPY_BUTTON:
            return [
                TEMPLATE_CONTROL_TEXT,
                TEMPLATE_CONTROL_METADATA_SIZE,
                TEMPLATE_CONTROL_METADATA_ALIGN,
                TEMPLATE_CONTROL_METADATA_PRODUCT_COPY_OPTIONS,
            ].some(f => f === field);

        case TEMPLATE_CONTROL_PRESCRIPTION_LIST:
            return [
                TEMPLATE_CONTROL_LABEL,
                TEMPLATE_CONTROL_METADATA_PRESCRIPTION_LIST_OPTIONS,
            ].some(f => f === field);

        case TEMPLATE_CONTROL_PRESCRIPTION_SUMMARY:
            return [
                TEMPLATE_CONTROL_LABEL,
                TEMPLATE_CONTROL_METADATA_PRESCRIPTION_SUMMARY_OPTIONS,
            ].some(f => f === field);

        case TEMPLATE_CONTROL_DIAGNOSTIC_CODE_LIST:
            return [
                TEMPLATE_CONTROL_LABEL,
                TEMPLATE_CONTROL_METADATA_SIZE,
            ].some(f => f === field);

        case TEMPLATE_CONTROL_REFERRING_PROVIDER_NUMBER:
            return [
                TEMPLATE_CONTROL_LABEL,
                TEMPLATE_CONTROL_METADATA_SIZE,
            ].some(f => f === field);

        case TEMPLATE_CONTROL_IMPORT_FROM_DEVICE_BUTTON:
            return [
                TEMPLATE_CONTROL_TEXT,
                TEMPLATE_CONTROL_METADATA_LINK,
                TEMPLATE_CONTROL_METADATA_SIZE,
                TEMPLATE_CONTROL_METADATA_ALIGN,
                TEMPLATE_CONTROL_METADATA_IMPORT_FROM_DEVICE_OPTIONS,
            ].some(f => f === field);

        case TEMPLATE_CONTROL_EXPORT_TO_DEVICE_BUTTON:
            return [
                TEMPLATE_CONTROL_TEXT,
                TEMPLATE_CONTROL_METADATA_LINK,
                TEMPLATE_CONTROL_METADATA_SIZE,
                TEMPLATE_CONTROL_METADATA_ALIGN,
                TEMPLATE_CONTROL_METADATA_EXPORT_TO_DEVICE_OPTIONS,
            ].some(f => f === field);

        default:
            return false
    }
}

export const getEditor = (type) => {
    switch (type) {
        case TEMPLATE_CONTROL_CALCULATION_TEXTBOX:
            return 'Text';

        case TEMPLATE_CONTROL_CALCULATION_CHECKBOX:
            return 'Checkbox';

        case TEMPLATE_CONTROL_TIMESTAMP_BUTTON:
            return 'Button';

        case TEMPLATE_CONTROL_PRETEST_SELECTOR:
        case TEMPLATE_CONTROL_COPY_FIELDS_BUTTON:
        case TEMPLATE_CONTROL_SET_FIELDS_BUTTON:
        case TEMPLATE_CONTROL_PRESCRIPTION_CREATE_BUTTON:
        case TEMPLATE_CONTROL_PRESCRIPTION_COPY_BUTTON:
        case TEMPLATE_CONTROL_PRODUCT_COPY_BUTTON:
        case TEMPLATE_CONTROL_PRESCRIPTION_LIST:
        case TEMPLATE_CONTROL_PRESCRIPTION_SUMMARY:
        case TEMPLATE_CONTROL_DIAGNOSTIC_CODE_LIST:
        case TEMPLATE_CONTROL_REFERRING_PROVIDER_NUMBER:
        case TEMPLATE_CONTROL_IMPORT_FROM_DEVICE_BUTTON:
        case TEMPLATE_CONTROL_EXPORT_TO_DEVICE_BUTTON:
            return 'Custom';

        default:
            return 'Text';
    }
}

export const getName = (type) => {
    switch (type) {
        case TEMPLATE_CONTROL_CALCULATION_TEXTBOX:
            return 'Calculation Textbox';

        case TEMPLATE_CONTROL_CALCULATION_CHECKBOX:
            return 'Calculation Checkbox';

        case TEMPLATE_CONTROL_TIMESTAMP_BUTTON:
            return 'Timestamp Button';

        case TEMPLATE_CONTROL_PRETEST_SELECTOR:
            return 'Pretest Selector';

        case TEMPLATE_CONTROL_COPY_FIELDS_BUTTON:
            return 'Copy Fields Button';

        case TEMPLATE_CONTROL_SET_FIELDS_BUTTON:
            return 'Set Fields Button';

        case TEMPLATE_CONTROL_PRESCRIPTION_CREATE_BUTTON:
            return 'Rx Create Button';

        case TEMPLATE_CONTROL_PRESCRIPTION_COPY_BUTTON:
            return 'Rx Copy Button';

        case TEMPLATE_CONTROL_PRODUCT_COPY_BUTTON:
            return 'Product Copy Button';

        case TEMPLATE_CONTROL_PRESCRIPTION_LIST:
            return 'Exam Prescription List';

        case TEMPLATE_CONTROL_PRESCRIPTION_SUMMARY:
            return 'Exam Prescription Summary';

        case TEMPLATE_CONTROL_DIAGNOSTIC_CODE_LIST:
            return 'Exam Diagnostic Codes';

        case TEMPLATE_CONTROL_REFERRING_PROVIDER_NUMBER:
            return 'Exam Referring Doctor License';

        case TEMPLATE_CONTROL_IMPORT_FROM_DEVICE_BUTTON:
            return 'Import From Device';

        case TEMPLATE_CONTROL_EXPORT_TO_DEVICE_BUTTON:
            return 'Export To Device';

        default:
            return 'Custom Control';
    }
}

export const getIcon = (type) => {
    switch (type) {
        case TEMPLATE_CONTROL_CALCULATION_TEXTBOX:
            return 'fal fa-calculator-alt';

        case TEMPLATE_CONTROL_CALCULATION_CHECKBOX:
            return 'fal fa-calculator-alt';

        case TEMPLATE_CONTROL_TIMESTAMP_BUTTON:
            return 'fal fa-clock';

        case TEMPLATE_CONTROL_PRETEST_SELECTOR:
            return 'fal fa-bullseye-pointer';

        case TEMPLATE_CONTROL_COPY_FIELDS_BUTTON:
            return 'fal fa-copy';

        case TEMPLATE_CONTROL_SET_FIELDS_BUTTON:
            return 'fal fa-copy';

        case TEMPLATE_CONTROL_PRESCRIPTION_CREATE_BUTTON:
            return 'fal fa-prescription';

        case TEMPLATE_CONTROL_PRESCRIPTION_COPY_BUTTON:
            return 'fal fa-prescription';

        case TEMPLATE_CONTROL_PRODUCT_COPY_BUTTON:
            return 'fal fa-box'

        case TEMPLATE_CONTROL_PRESCRIPTION_LIST:
            return 'fal fa-prescription';

        case TEMPLATE_CONTROL_PRESCRIPTION_SUMMARY:
            return 'fal fa-prescription';

        case TEMPLATE_CONTROL_DIAGNOSTIC_CODE_LIST:
            return 'fal fa-comment-medical';

        case TEMPLATE_CONTROL_REFERRING_PROVIDER_NUMBER:
            return 'fal fa-user-md';

        case TEMPLATE_CONTROL_IMPORT_FROM_DEVICE_BUTTON:
            return 'fal fa-file-import';

        case TEMPLATE_CONTROL_EXPORT_TO_DEVICE_BUTTON:
            return 'fal fa-file-export';

        default:
            return '';
    }
}

export const getControlsInLayout = (layoutDefinition) => {
    if (!layoutDefinition || layoutDefinition.length === 0) return null;
    return layoutDefinition.filter(d => isControlElement(d));
}

export const renderControl = (control, options) => {
    const defaults = {
        controlKey: `_${uuid()}`,
        readOnly: null,
        isDesignMode: false,
        referenceObject: null,
        rowHeight: null,
        gridWidth: null,
        gridHeight: null,
        gridMargins: null,
        gridSize: null,
        gridCellWidth: null,
        value: null,
        quickDrawer: null,
        pretests: null,
        prescriptions: null,
        pretestPublishedTemplates: null,
        prescriptionPublishedTemplates: null,
        onClick: null,
        onChange: null,
        onImport: null,
        onDoubleClick: null,
        onContextMenu: null,
        getValue: null,
        getControlValue: null,
    };
    options = { ...defaults, ...options }
    control = toJS(control);

    switch (control.type) {
        case TEMPLATE_CONTROL_CALCULATION_TEXTBOX:
            return <div
                className={'element-wrapper element-input h-100' + (!control.label ? ' no-label' : '') + (control.metadata && control.metadata.size ? ` element-input-${control.metadata.size}` : '')}
                data-id={control.id}
            >
                <div
                    className={'form-group' + (options.value ? ' has-value' : '') + (!control.label ? ' no-label' : '')}
                    onDoubleClick={options.onDoubleClick} onContextMenu={options.onContextMenu}
                >
                    {
                        control.label ?
                            <label className='label-input' htmlFor={options.controlKey}><small>{control.label}</small></label> : null
                    }
                    <div className='control-wrapper'>
                        <input
                            id={options.controlKey}
                            type='text'
                            className={
                                'form-control' +
                                (` text-${(control.metadata && control.metadata.align ? control.metadata.align : 'left')}`) +
                                (control.metadata && control.metadata.size ? ` form-control-${control.metadata.size} px-2` : '')
                            }
                            data-virtual-tabindex={control.metadata && !!control.metadata.virtualTabIndex ? control.metadata.virtualTabIndex : null}
                            autoComplete='off'
                            disabled={true}
                            title={options.value ? options.value : null}
                            value={options.value ? options.value : ''}
                        />
                    </div>
                </div>
            </div>

        case TEMPLATE_CONTROL_CALCULATION_CHECKBOX:
            return <div
                className={'element-wrapper element-input h-100 no-label' + (control.metadata && control.metadata.size ? ` element-input-${control.metadata.size}` : '')}
                data-id={control.id}
            >
                <div
                    className={'form-group no-label' + (options.value ? ' has-value' : '') + (control.metadata && control.metadata.valign && control.metadata.valign !== 'top' ? ` text-${control.metadata.valign}` : '')}
                    onDoubleClick={options.onDoubleClick}
                    onContextMenu={options.onContextMenu}
                >
                    <div>
                        <div
                            className={'custom-control custom-checkbox' + (control.metadata && control.metadata.inline ? ' custom-control-inline' : '')}
                        >
                            <input
                                id={`${options.controlKey}`}
                                type='checkbox'
                                name={`new-template-input-inline-${options.controlKey}`}
                                className={'custom-control-input'}
                                disabled={options.readOnly}
                                data-virtual-tabindex={control.metadata && !!control.metadata.virtualTabIndex ? control.metadata.virtualTabIndex : null}
                                checked={(options.value && options.value === control.label)}
                                onChange={fn.isFunction(options.onChange) && !options.readOnly ? (e) => { options.onChange(e, control, options.referenceObject) } : (e) => { e.preventDefault(); e.stopPropagation(); }}
                                onClick={!fn.isFunction(options.onChange) || options.readOnly ? (e) => { e.preventDefault(); e.stopPropagation(); } : null}
                            />
                            <label
                                htmlFor={options.readOnly ? '' : `${options.controlKey}`}
                                className={'custom-control-label'}
                            >
                                {control.label}
                            </label>
                        </div>
                    </div>
                </div>
            </div>

        case TEMPLATE_CONTROL_TIMESTAMP_BUTTON:
            return <div
                className={'element-wrapper element-input h-100' + (!control.label ? ' no-label' : '') + (control.metadata && control.metadata.size ? ` element-input-${control.metadata.size}` : '')}
                data-id={control.id}
            >
                <div
                    className={'form-group no-label' + (options.value ? ' has-value' : '')}
                    onDoubleClick={options.onDoubleClick} onContextMenu={options.onContextMenu}
                >
                    <div
                        className={
                            'control-wrapper' +
                            (` text-${(control.metadata && control.metadata.align ? control.metadata.align : 'left')}`)
                        }
                    >
                        <button
                            id={options.controlKey}
                            type='button'
                            className={
                                'btn btn-secondary' +
                                (control.metadata && control.metadata.size ? ` btn-${control.metadata.size}` : '') +
                                (options.readOnly ? ` d-none` : '')
                            }
                            data-virtual-tabindex={control.metadata && !!control.metadata.virtualTabIndex ? control.metadata.virtualTabIndex : null}
                            onClick={fn.isFunction(options.onClick) && !options.readOnly ? (e) => { options.onClick(e, control, options.referenceObject, null, null, options.parentControl) } : () => { }}
                        >
                            {control.text ? control.text : 'Time'}
                        </button>
                    </div>
                </div>
            </div>

        case TEMPLATE_CONTROL_PRETEST_SELECTOR:
            if (control && control.metadata && control.metadata.pretest && control.metadata.pretest.type && options.pretestPublishedTemplates) {
                const pretestPublishedTemplate = options.pretestPublishedTemplates.filter(t => t.pretestTypeId === control.metadata.pretest.type)[0];

                if (pretestPublishedTemplate && pretestPublishedTemplate.definition) {
                    const { modalSettings } = pretestPublishedTemplate.definition;
                    const width = control.metadata.pretest.scaleToFit ? ((options.gridWidth / options.gridSize) * (control.position.w + 1)) : modalSettings.size.width;

                    return <div style={{ overflow: 'hidden' }}>
                        <TemplateGrid
                            isEditable={false}
                            cols={{ lg: modalSettings.size.grid, md: modalSettings.size.grid, sm: modalSettings.size.grid, xs: modalSettings.size.grid, xxs: modalSettings.size.grid }}
                            rowHeight={options.rowHeight}
                            margin={[0, 0]}
                            style={{ width: `${width}px`, minWidth: `${width}px` }}
                        >
                            {
                                options.isDesignMode ?
                                    renderPretestDefaultContent(control, options) :
                                    renderPretestExamContent(control, options)
                            }
                        </TemplateGrid>
                    </div>
                }
            }
            return null;

        case TEMPLATE_CONTROL_COPY_FIELDS_BUTTON:
            return <div
                className={'element-wrapper element-input h-100' + (!control.label ? ' no-label' : '') + (control.metadata && control.metadata.size ? ` element-input-${control.metadata.size}` : '')}
                data-id={control.id}
            >
                <div
                    className={'form-group no-label' + (options.value ? ' has-value' : '')}
                    onDoubleClick={options.onDoubleClick} onContextMenu={options.onContextMenu}
                >
                    <div
                        className={
                            'control-wrapper' +
                            (` text-${(control.metadata && control.metadata.align ? control.metadata.align : 'left')}`)
                        }
                    >
                        <button
                            id={options.controlKey}
                            type='button'
                            className={
                                'btn btn-secondary' +
                                (control.metadata && control.metadata.size ? ` btn-${control.metadata.size}` : '') +
                                (options.readOnly ? ` d-none` : '')
                            }
                            data-virtual-tabindex={control.metadata && !!control.metadata.virtualTabIndex ? control.metadata.virtualTabIndex : null}
                            onClick={fn.isFunction(options.onClick) && !options.readOnly ? (e) => { options.onClick(e, control, options.referenceObject, null, null, options.parentControl) } : () => { }}
                        >
                            {control.text ? control.text : 'Copy'}
                        </button>
                    </div>
                </div>
            </div>

        case TEMPLATE_CONTROL_SET_FIELDS_BUTTON:
            return <div
                className={'element-wrapper element-input h-100' + (!control.label ? ' no-label' : '') + (control.metadata && control.metadata.size ? ` element-input-${control.metadata.size}` : '')}
                data-id={control.id}
            >
                <div
                    className={'form-group no-label' + (options.value ? ' has-value' : '')}
                    onDoubleClick={options.onDoubleClick} onContextMenu={options.onContextMenu}
                >
                    <div
                        className={
                            'control-wrapper' +
                            (` text-${(control.metadata && control.metadata.align ? control.metadata.align : 'left')}`)
                        }
                    >
                        <button
                            id={options.controlKey}
                            type='button'
                            className={
                                'btn btn-secondary' +
                                (control.metadata && control.metadata.size ? ` btn-${control.metadata.size}` : '') +
                                (options.readOnly ? ` d-none` : '')
                            }
                            data-virtual-tabindex={control.metadata && !!control.metadata.virtualTabIndex ? control.metadata.virtualTabIndex : null}
                            onClick={fn.isFunction(options.onClick) && !options.readOnly ? (e) => { options.onClick(e, control, options.referenceObject, null, null, options.parentControl) } : () => { }}
                        >
                            {control.text ? control.text : 'Set'}
                        </button>
                    </div>
                </div>
            </div>

        case TEMPLATE_CONTROL_PRESCRIPTION_CREATE_BUTTON:
            return <div
                className={'element-wrapper element-input h-100' + (!control.label ? ' no-label' : '') + (control.metadata && control.metadata.size ? ` element-input-${control.metadata.size}` : '')}
                data-id={control.id}
            >
                <div
                    className={'form-group no-label' + (options.value ? ' has-value' : '')}
                    onDoubleClick={options.onDoubleClick} onContextMenu={options.onContextMenu}
                >
                    <div
                        className={
                            'control-wrapper' +
                            (` text-${(control.metadata && control.metadata.align ? control.metadata.align : 'left')}`)
                        }
                    >
                        <button
                            id={options.controlKey}
                            type='button'
                            className={
                                'btn btn-secondary' +
                                (control.metadata && control.metadata.size ? ` btn-${control.metadata.size}` : '') +
                                (options.readOnly ? ` d-none` : '')
                            }
                            data-virtual-tabindex={control.metadata && !!control.metadata.virtualTabIndex ? control.metadata.virtualTabIndex : null}
                            onClick={fn.isFunction(options.onClick) && !options.readOnly ? (e) => { options.onClick(e, control, options.referenceObject, null, null, options.parentControl) } : () => { }}
                        >
                            {control.text ? control.text : 'Create Rx'}
                        </button>
                    </div>
                </div>
            </div>

        case TEMPLATE_CONTROL_PRESCRIPTION_COPY_BUTTON:
            return <div
                className={'element-wrapper element-input h-100' + (!control.label ? ' no-label' : '') + (control.metadata && control.metadata.size ? ` element-input-${control.metadata.size}` : '')}
                data-id={control.id}
            >
                <div
                    className={'form-group no-label' + (options.value ? ' has-value' : '')}
                    onDoubleClick={options.onDoubleClick} onContextMenu={options.onContextMenu}
                >
                    <div
                        className={
                            'control-wrapper' +
                            (` text-${(control.metadata && control.metadata.align ? control.metadata.align : 'left')}`)
                        }
                    >
                        <button
                            id={options.controlKey}
                            type='button'
                            className={
                                'btn btn-secondary' +
                                (control.metadata && control.metadata.size ? ` btn-${control.metadata.size}` : '') +
                                (options.readOnly ? ` d-none` : '')
                            }
                            data-virtual-tabindex={control.metadata && !!control.metadata.virtualTabIndex ? control.metadata.virtualTabIndex : null}
                            onClick={fn.isFunction(options.onClick) && !options.readOnly ? (e) => { options.onClick(e, control, options.referenceObject, null, null, options.parentControl) } : () => { }}
                        >
                            {control.text ? control.text : 'Copy Rx'}
                        </button>
                    </div>
                </div>
            </div>

        case TEMPLATE_CONTROL_PRODUCT_COPY_BUTTON:
            return <div
                className={'element-wrapper element-input h-100' + (!control.label ? ' no-label' : '') + (control.metadata && control.metadata.size ? ` element-input-${control.metadata.size}` : '')}
                data-id={control.id}
            >
                <div
                    className={'form-group no-label' + (options.value ? ' has-value' : '')}
                    onDoubleClick={options.onDoubleClick} onContextMenu={options.onContextMenu}
                >
                    <div
                        className={
                            'control-wrapper' +
                            (` text-${(control.metadata && control.metadata.align ? control.metadata.align : 'left')}`)
                        }
                    >
                        <button
                            id={options.controlKey}
                            type='button'
                            className={
                                'btn btn-secondary' +
                                (control.metadata && control.metadata.size ? ` btn-${control.metadata.size}` : '') +
                                (options.readOnly ? ` d-none` : '')
                            }
                            data-virtual-tabindex={control.metadata && !!control.metadata.virtualTabIndex ? control.metadata.virtualTabIndex : null}
                            onClick={fn.isFunction(options.onClick) && !options.readOnly ? (e) => { options.onClick(e, control, options.referenceObject) } : () => { }}
                        >
                            {control.text ? control.text : 'Copy Product'}
                        </button>
                    </div>
                </div>
            </div>

        case TEMPLATE_CONTROL_PRESCRIPTION_LIST:
            const listTemplate = control.metadata && control.metadata.prescriptionList && options.prescriptionPublishedTemplates ? options.prescriptionPublishedTemplates.filter(t => t.prescriptionTypeId === control.metadata.prescriptionList.type)[0] : null;
            const listData = control.metadata && control.metadata.prescriptionList && options.prescriptions ? options.prescriptions.filter(p => p.prescriptionTypeId === control.metadata.prescriptionList.type) : null;
            let filteredListData;

            if (control.metadata && control.metadata.prescriptionList && listData) {
                switch (control.metadata.prescriptionList.mode) {
                    case 'previous':
                        filteredListData = listData.filter(d => moment(d.writtenDate).startOf('day').isBefore(moment.utc(options.referenceObject.scheduledStart).startOf('day')));
                        break;

                    case 'exam':
                        filteredListData = listData.filter(d => d.examId === options.referenceObject.id);
                        break;

                    case 'all':
                    default:
                        filteredListData = listData;
                }
            }

            return <div
                className={'form-group' + (options.value ? ' has-value' : '') + (!control.label ? ' no-label' : '')}
                onDoubleClick={options.onDoubleClick} onContextMenu={options.onContextMenu}
            >
                {
                    control.label ?
                        <label className='label-input' htmlFor={options.controlKey}><small>{control.label}</small></label> : null
                }
                <div
                    className='control-wrapper prescription-list-wrapper'
                >
                    <PrescriptionHistory
                        stickyColumnWidth={125}
                        template={listTemplate}
                        data={filteredListData}
                        enableAction={true}
                        onView={fn.isFunction(options.onClick) ? (e, prescription) => { options.onClick(e, control, options.referenceObject, 'view', prescription) } : () => { }}
                        onUpdate={fn.isFunction(options.onClick) ? (e, prescription) => { options.onClick(e, control, options.referenceObject, 'update', prescription) } : () => { }}
                        onDownload={fn.isFunction(options.onClick) ? (e, prescription) => { options.onClick(e, control, options.referenceObject, 'download', prescription) } : () => { }}
                        onPrint={fn.isFunction(options.onClick) ? (e, prescription) => { options.onClick(e, control, options.referenceObject, 'print', prescription) } : () => { }}
                        onDelete={fn.isFunction(options.onClick) ? (e, prescription) => { options.onClick(e, control, options.referenceObject, 'delete', prescription) } : () => { }}
                        onRegenerate={fn.isFunction(options.onClick) ? (e, prescription) => { options.onClick(e, control, options.referenceObject, 'regenerate', prescription) } : () => { }}
                    />
                </div>
            </div>

        case TEMPLATE_CONTROL_PRESCRIPTION_SUMMARY:
            const prescriptionTemplates = options.prescriptionPublishedTemplates ? options.prescriptionPublishedTemplates.slice().sort((a, b) => { return a.prescriptionType.displayOrder - b.prescriptionType.displayOrder }) : [];
            const prescriptions = options.prescriptions ? options.prescriptions : null;
            let filteredPrescriptions;

            if (control.metadata && control.metadata.prescriptionSummary && prescriptions) {
                switch (control.metadata.prescriptionSummary.mode) {
                    case 'previous':
                        filteredPrescriptions = prescriptions.filter(d => moment(d.writtenDate).startOf('day').isBefore(moment.utc(options.referenceObject.scheduledStart).startOf('day')));
                        break;

                    case 'exam':
                        filteredPrescriptions = prescriptions.filter(d => d.examId === options.referenceObject.id);
                        break;

                    case 'all':
                    default:
                        filteredPrescriptions = prescriptions;
                }
            }

            return <div
                className={'form-group' + (options.value ? ' has-value' : '') + (!control.label ? ' no-label' : '')}
                onDoubleClick={options.onDoubleClick} onContextMenu={options.onContextMenu}
            >
                {
                    control.label ?
                        <label className='label-input' htmlFor={options.controlKey}><small>{control.label}</small></label> : null
                }
                <div
                    className='control-wrapper disable-scroll'
                >
                    <PrescriptionSummary
                        templates={prescriptionTemplates ? toJS(prescriptionTemplates) : []}
                        prescriptions={filteredPrescriptions ? toJS(filteredPrescriptions) : []}
                        enableAction={true}
                        enableExpand={true}
                        enableHover={true}
                        showDraftDefault={true}
                        multiple={(control && control.metadata && control.metadata.prescriptionSummary && !fn.isNullOrUndefined(control.metadata.prescriptionSummary.multiple) ? !!control.metadata.prescriptionSummary.multiple : true)}
                        showEmptyPrescriptionTypes={false}
                        onDblClick={fn.isFunction(options.onClick) ? (e, prescription) => { options.onClick(e, control, options.referenceObject, 'dblClick', prescription) } : () => { }}
                        onRegenerate={fn.isFunction(options.onClick) ? (e, prescription) => { options.onClick(e, control, options.referenceObject, 'regenerate', prescription) } : () => { }}
                        onSelect={fn.isFunction(options.onClick) ? (e, prescription) => { options.onClick(e, control, options.referenceObject, 'select', prescription) } : () => { }}
                        onView={fn.isFunction(options.onClick) ? (e, prescription) => { options.onClick(e, control, options.referenceObject, 'view', prescription) } : () => { }}
                        onUpdate={fn.isFunction(options.onClick) ? (e, prescription) => { options.onClick(e, control, options.referenceObject, 'update', prescription) } : () => { }}
                        onClone={fn.isFunction(options.onClick) ? (e, prescription) => { options.onClick(e, control, options.referenceObject, 'clone', prescription) } : () => { }}
                        onDownload={fn.isFunction(options.onClick) ? (e, prescription) => { options.onClick(e, control, options.referenceObject, 'download', prescription) } : () => { }}
                        onSendEmail={fn.isFunction(options.onClick) ? (e, prescription) => { options.onClick(e, control, options.referenceObject, 'sendEmail', prescription) } : () => { }}
                        onSendSms={fn.isFunction(options.onClick) ? (e, prescription) => { options.onClick(e, control, options.referenceObject, 'sendSms', prescription) } : () => { }}
                        onSendFax={fn.isFunction(options.onClick) ? (e, prescription) => { options.onClick(e, control, options.referenceObject, 'sendFax', prescription) } : () => { }}
                        onPrint={fn.isFunction(options.onClick) ? (e, prescription) => { options.onClick(e, control, options.referenceObject, 'print', prescription) } : () => { }}
                        onDelete={fn.isFunction(options.onClick) ? (e, prescription) => { options.onClick(e, control, options.referenceObject, 'delete', prescription) } : () => { }}
                        onAddNote={fn.isFunction(options.onClick) ? (e, prescription) => { options.onClick(e, control, options.referenceObject, 'addNote', prescription) } : () => { }}
                    />
                </div>
            </div>

        case TEMPLATE_CONTROL_DIAGNOSTIC_CODE_LIST:
            return <div
                className={'form-group ' + (options.value ? ' has-value' : '') + (!control.label ? ' no-label' : '')}
                onDoubleClick={options.onDoubleClick} onContextMenu={options.onContextMenu}
            >
                {
                    control.label ?
                        <label className='label-input' htmlFor={options.controlKey}><small>{control.label}</small></label> : null
                }
                <div
                    className={'control-wrapper exam-diagnostic-codes-wrapper' + (control.metadata && control.metadata.size ? ` control-${control.metadata.size}` : '')}
                >
                    <ExamDiagnosticCodeList
                        selectedCodes={options.value}
                        readOnly={options.readOnly}
                        dataAttributes={[
                            { name: 'data-virtual-tabindex', value: control.metadata && !!control.metadata.virtualTabIndex ? control.metadata.virtualTabIndex : null }
                        ]}
                        onChange={fn.isFunction(options.onChange) ? (codes) => { options.onChange(null, options.controlKey, options.referenceObject, codes) } : () => { }}
                    />
                </div>
            </div>

        case TEMPLATE_CONTROL_REFERRING_PROVIDER_NUMBER:
            return <div
                className={'element-wrapper element-input h-100' + (!control.label ? ' no-label' : '') + (control.metadata && control.metadata.size ? ` element-input-${control.metadata.size}` : '')}
                data-id={control.id}
            >
                <div
                    className={'form-group' + (options.value ? ' has-value' : '') + (!control.label ? ' no-label' : '')}
                    onDoubleClick={options.onDoubleClick} onContextMenu={options.onContextMenu}
                >
                    {
                        control.label ?
                            <label className='label-input' htmlFor={options.controlKey}><small>{control.label}</small></label> : null
                    }
                    <div className='control-wrapper'>
                        <input
                            id={options.controlKey}
                            type='text'
                            className={
                                'form-control' +
                                (` text-${(control.metadata && control.metadata.align ? control.metadata.align : 'left')}`) +
                                (control.metadata && control.metadata.size ? ` form-control-${control.metadata.size} px-2` : '')
                            }
                            data-virtual-tabindex={control.metadata && !!control.metadata.virtualTabIndex ? control.metadata.virtualTabIndex : null}
                            autoComplete='off'
                            disabled={options.readOnly}
                            title={options.value ? options.value : null}
                            value={options.value ? options.value : ''}
                            onChange={fn.isFunction(options.onChange) && !options.readOnly ? (e) => { options.onChange(e, options.controlKey, options.referenceObject) } : () => { }}
                        />
                    </div>
                </div>
            </div>

        case TEMPLATE_CONTROL_IMPORT_FROM_DEVICE_BUTTON:
            return <>
                <div
                    className={
                        'element-wrapper element-input h-100' +
                        (!control.label ? ' no-label' : '') +
                        (control.metadata && control.metadata.size ? ` element-input-${control.metadata.size}` : '') +
                        (control.metadata && control.metadata.link ? ` element-input-link` : '')
                    }
                    data-id={control.id}
                >
                    <div
                        className={'form-group no-label' + (options.value ? ' has-value' : '')}
                        onDoubleClick={options.onDoubleClick} onContextMenu={options.onContextMenu}
                    >
                        <div
                            className={
                                'control-wrapper' +
                                (` text-${(control.metadata && control.metadata.align ? control.metadata.align : 'left')}`)
                            }
                        >
                            <button
                                id={options.controlKey}
                                type='button'
                                className={
                                    'btn' +
                                    (control.metadata && control.metadata.link ? ` btn-link p-0` : ' btn-secondary') +
                                    (control.metadata && control.metadata.size ? ` btn-${control.metadata.size}` : '') +
                                    (control.metadata && control.metadata.link ? ` text-${(control.metadata && control.metadata.align ? control.metadata.align : 'left')}` : '') +
                                    (options.readOnly ? ` d-none` : '')
                                }
                                data-virtual-tabindex={control.metadata && !!control.metadata.virtualTabIndex ? control.metadata.virtualTabIndex : null}
                                onClick={fn.isFunction(options.onClick) && !options.readOnly ? (e) => { options.onClick(e, control, options.referenceObject) } : () => { }}
                            >
                                {control.text ? control.text : 'Import'}
                            </button>
                            {dh.renderDeviceSyncTrigger(options.controlKey)}
                        </div>
                    </div>
                </div>
                {
                    !options.isDesignMode && !options.readOnly ?
                        <BodyEnd>{dh.renderDeviceSyncTrigger(control.id)}</BodyEnd> : null
                }
            </>

        case TEMPLATE_CONTROL_EXPORT_TO_DEVICE_BUTTON:
            return <>
                <div
                    className={
                        'element-wrapper element-input h-100' +
                        (!control.label ? ' no-label' : '') +
                        (control.metadata && control.metadata.size ? ` element-input-${control.metadata.size}` : '') +
                        (control.metadata && control.metadata.link ? ` element-input-link` : '')
                    }
                    data-id={control.id}
                >
                    <div
                        className={'form-group no-label' + (options.value ? ' has-value' : '')}
                        onDoubleClick={options.onDoubleClick} onContextMenu={options.onContextMenu}
                    >
                        <div
                            className={
                                'control-wrapper' +
                                (` text-${(control.metadata && control.metadata.align ? control.metadata.align : 'left')}`)
                            }
                        >
                            <button
                                id={options.controlKey}
                                type='button'
                                className={
                                    'btn' +
                                    (control.metadata && control.metadata.link ? ` btn-link p-0` : ' btn-secondary') +
                                    (control.metadata && control.metadata.size ? ` btn-${control.metadata.size}` : '') +
                                    (control.metadata && control.metadata.link ? ` text-${(control.metadata && control.metadata.align ? control.metadata.align : 'left')}` : '') +
                                    (options.readOnly ? ` d-none` : '')
                                }
                                data-virtual-tabindex={control.metadata && !!control.metadata.virtualTabIndex ? control.metadata.virtualTabIndex : null}
                                onClick={fn.isFunction(options.onClick) && !options.readOnly ? (e) => { options.onClick(e, control, options.referenceObject) } : () => { }}
                            >
                                {control.text ? control.text : 'Export'}
                            </button>
                        </div>
                    </div>
                </div>
                {
                    !options.isDesignMode && !options.readOnly ?
                        <BodyEnd>{dh.renderDeviceSyncTrigger(control.id)}</BodyEnd> : null
                }
            </>

        default:
            break;
    }
}

export const getDefaultLabel = control => {
    switch (control.type) {
        case TEMPLATE_CONTROL_CALCULATION_TEXTBOX:
            return 'Textbox';

        case TEMPLATE_CONTROL_TIMESTAMP_BUTTON:
        case TEMPLATE_CONTROL_PRETEST_SELECTOR:
        case TEMPLATE_CONTROL_COPY_FIELDS_BUTTON:
        case TEMPLATE_CONTROL_SET_FIELDS_BUTTON:
        case TEMPLATE_CONTROL_PRESCRIPTION_CREATE_BUTTON:
        case TEMPLATE_CONTROL_PRESCRIPTION_COPY_BUTTON:
        case TEMPLATE_CONTROL_PRODUCT_COPY_BUTTON:
        default:
            return null;
    }
}

export const getDefaultPosition = control => {
    switch (control.type) {
        case TEMPLATE_CONTROL_CALCULATION_TEXTBOX:
        case TEMPLATE_CONTROL_CALCULATION_CHECKBOX:
        case TEMPLATE_CONTROL_REFERRING_PROVIDER_NUMBER:
            return control.label ? { w: 12, h: 4 } : { w: 12, h: 2 };

        case TEMPLATE_CONTROL_TIMESTAMP_BUTTON:
        case TEMPLATE_CONTROL_COPY_FIELDS_BUTTON:
        case TEMPLATE_CONTROL_SET_FIELDS_BUTTON:
        case TEMPLATE_CONTROL_PRESCRIPTION_CREATE_BUTTON:
        case TEMPLATE_CONTROL_PRESCRIPTION_COPY_BUTTON:
        case TEMPLATE_CONTROL_PRODUCT_COPY_BUTTON:
        case TEMPLATE_CONTROL_IMPORT_FROM_DEVICE_BUTTON:
        case TEMPLATE_CONTROL_EXPORT_TO_DEVICE_BUTTON:
            return { w: 6, h: 4 };

        case TEMPLATE_CONTROL_PRETEST_SELECTOR:
        case TEMPLATE_CONTROL_PRESCRIPTION_LIST:
        case TEMPLATE_CONTROL_DIAGNOSTIC_CODE_LIST:
            return { w: 6, h: 6 };

        case TEMPLATE_CONTROL_PRESCRIPTION_SUMMARY:
            return { w: 12, h: 6 };

        default:
            return {};
    }
}

export const replaceSelectorDefinition = (layoutDefinition, selectorName, templates, data) => {
    const newLayoutDefinition = [];
    let multipleY = 0;

    for (let i = 0; i < layoutDefinition.length; i++) {
        if (layoutDefinition[i].type && layoutDefinition[i].type === selectorName) {
            const selectorDefinition = JSON.parse(JSON.stringify(layoutDefinition[i]));
            const { y } = selectorDefinition.position;
            let instances, template, isMultiple;

            switch (selectorName) {
                case TEMPLATE_CONTROL_PRETEST_SELECTOR:
                    template = templates.filter(t => t.pretestTypeId === selectorDefinition.metadata.pretest.type)[0];
                    instances = template ? layoutDefinition.filter(d => d.type === selectorName && d.metadata.pretest.type === template.pretestTypeId) : null;
                    isMultiple = template ? selectorDefinition.metadata.pretest.isMultiple : false;
                    break;

                default:
                    template = null;
                    instances = [];
                    break;
            }

            if (template && template.definition && template.definition.definition) {
                const instanceIndex = instances.findIndex(d => d.id === selectorDefinition.id);
                const { definition: templateLayoutDefinition } = template.definition;
                let newY;

                for (let j = 0; j < templateLayoutDefinition.length; j++) {
                    const newControlDefinition = JSON.parse(JSON.stringify(templateLayoutDefinition[j]));
                    const newKey = `${newControlDefinition.key}_${instanceIndex}${isMultiple ? '_0' : ''}`;

                    newControlDefinition.position.y = newControlDefinition.position.y + y + multipleY;

                    if (newControlDefinition.metadata.equation) {
                        const variables = mh.parseVariables(newControlDefinition.metadata.equation);

                        if (variables && variables.length > 0) {
                            for (let k = 0; k < variables.length; k++) {
                                const newVariableKey = `${variables[k].key}_${instanceIndex}${isMultiple ? '_0' : ''}`;
                                newControlDefinition.metadata.equation = newControlDefinition.metadata.equation.replace(variables[k].key, newVariableKey);
                            }
                        }
                    }

                    newControlDefinition.key = newKey;
                    newLayoutDefinition.push(newControlDefinition);

                    newY = newControlDefinition.position.y + newControlDefinition.position.h;
                }

                if (isMultiple) {
                    const multipleKeys = templateLayoutDefinition.map(d => { return `${d.key}_${instanceIndex}_` });

                    if (multipleKeys && multipleKeys.length > 0) {
                        const multipleData = data.filter(d => multipleKeys.some(k => d.id.startsWith(k)));

                        if (multipleData && multipleData.length > 0) {
                            const maxCount = Math.max.apply(Math, multipleData.map(d => { return parseInt(d.id.substring(d.id.lastIndexOf('_') + 1), 10) })) + 1;
                            const defaultControlLayoutDefinition = getDefaultControlLayoutDefinition(templateLayoutDefinition);
                            const defaultHeight = Math.max.apply(Math, defaultControlLayoutDefinition.map(d => { return d.position.y + d.position.h }));

                            for (let k = 1; k < maxCount; k++) {
                                for (let l = 0; l < templateLayoutDefinition.length; l++) {
                                    const newTemplateDefinition = JSON.parse(JSON.stringify(templateLayoutDefinition[l]));
                                    const newKey = `${newTemplateDefinition.key}_${instanceIndex}_${k}`;

                                    newTemplateDefinition.position.y = newTemplateDefinition.position.y + y + multipleY;

                                    if (newTemplateDefinition.metadata.equation) {
                                        const variables = mh.parseVariables(newTemplateDefinition.metadata.equation);

                                        if (variables && variables.length > 0) {
                                            for (let k = 0; k < variables.length; k++) {
                                                const newVariableKey = `${variables[k].key}_${instanceIndex}_${k}`;
                                                newTemplateDefinition.metadata.equation = newTemplateDefinition.metadata.equation.replace(variables[k].key, newVariableKey);
                                            }
                                        }
                                    }

                                    newTemplateDefinition.id = `${newTemplateDefinition.id}_${instanceIndex}_${k}`;
                                    newTemplateDefinition.key = newKey;
                                    newTemplateDefinition.position.y = newTemplateDefinition.position.y + (defaultHeight * k) + multipleY + k;
                                    newLayoutDefinition.push(newTemplateDefinition);
                                }

                                newY = Math.max.apply(Math, newLayoutDefinition.map(d => { return d.position.y + d.position.h }));
                            }

                            multipleY = multipleY + ((defaultHeight * (maxCount - 1)) + maxCount - 1);
                        }
                    }
                }

                switch (selectorName) {
                    case TEMPLATE_CONTROL_PRETEST_SELECTOR:
                        selectorDefinition.metadata.pretest.instance = instanceIndex;
                        break;

                    default:
                        break;
                }

                selectorDefinition.position.y = newY;
                selectorDefinition.position.h = 2;
                newLayoutDefinition.push(selectorDefinition);
            }
        } else {
            const otherControl = JSON.parse(JSON.stringify(layoutDefinition[i]));

            otherControl.position.y = otherControl.position.y + multipleY;
            newLayoutDefinition.push(otherControl);
        }
    }

    return newLayoutDefinition;
}

export const replacePretestSelectorDefinition = (layoutDefinition, pretestTemplates, data) => {
    const newLayoutDefinition = [];
    let multipleY = 0;

    for (let i = 0; i < layoutDefinition.length; i++) {
        if (layoutDefinition[i].type && layoutDefinition[i].type === TEMPLATE_CONTROL_PRETEST_SELECTOR) {
            const selectorDefinition = JSON.parse(JSON.stringify(layoutDefinition[i]));
            const { y } = selectorDefinition.position;
            const pretestTemplate = pretestTemplates.filter(t => t.pretestTypeId === selectorDefinition.metadata.pretest.type)[0];

            if (pretestTemplate && pretestTemplate.definition && pretestTemplate.definition.definition) {
                const instances = layoutDefinition.filter(d => d.type === TEMPLATE_CONTROL_PRETEST_SELECTOR && d.metadata.pretest.type === pretestTemplate.pretestTypeId);
                const instanceIndex = instances.findIndex(d => d.id === selectorDefinition.id);
                const { definition: pretestLayoutDefinition } = pretestTemplate.definition;
                const isMultiple = selectorDefinition.metadata.pretest.isMultiple;
                let newY;

                for (let j = 0; j < pretestLayoutDefinition.length; j++) {
                    const newPretestControlDefinition = JSON.parse(JSON.stringify(pretestLayoutDefinition[j]));
                    const newKey = `${newPretestControlDefinition.key}_${instanceIndex}${isMultiple ? '_0' : ''}`;

                    newPretestControlDefinition.position.y = newPretestControlDefinition.position.y + y + multipleY;

                    if (newPretestControlDefinition.metadata.equation) {
                        const variables = mh.parseVariables(newPretestControlDefinition.metadata.equation);

                        if (variables && variables.length > 0) {
                            for (let k = 0; k < variables.length; k++) {
                                const newVariableKey = `${variables[k].key}_${instanceIndex}${isMultiple ? '_0' : ''}`;
                                newPretestControlDefinition.metadata.equation = newPretestControlDefinition.metadata.equation.replace(variables[k].key, newVariableKey);
                            }
                        }
                    }

                    newPretestControlDefinition.key = newKey;
                    newLayoutDefinition.push(newPretestControlDefinition);

                    newY = newPretestControlDefinition.position.y + newPretestControlDefinition.position.h;
                }

                if (isMultiple) {
                    const multipleKeys = pretestLayoutDefinition.map(d => { return `${d.key}_${instanceIndex}_` });

                    if (multipleKeys && multipleKeys.length > 0) {
                        const multipleData = data.filter(d => multipleKeys.some(k => d.id.startsWith(k)));

                        if (multipleData && multipleData.length > 0) {
                            const maxCount = Math.max.apply(Math, multipleData.map(d => { return parseInt(d.id.substring(d.id.lastIndexOf('_') + 1), 10) })) + 1;
                            const defaultPretestLayoutDefinition = getDefaultPretestLayoutDefinition(pretestLayoutDefinition);
                            const defaultHeight = Math.max.apply(Math, defaultPretestLayoutDefinition.map(d => { return d.position.y + d.position.h }));

                            for (let k = 1; k < maxCount; k++) {
                                for (let l = 0; l < pretestLayoutDefinition.length; l++) {
                                    const newPretestDefinition = JSON.parse(JSON.stringify(pretestLayoutDefinition[l]));
                                    const newKey = `${newPretestDefinition.key}_${instanceIndex}_${k}`;

                                    newPretestDefinition.position.y = newPretestDefinition.position.y + y + multipleY;

                                    if (newPretestDefinition.metadata.equation) {
                                        const variables = mh.parseVariables(newPretestDefinition.metadata.equation);

                                        if (variables && variables.length > 0) {
                                            for (let k = 0; k < variables.length; k++) {
                                                const newVariableKey = `${variables[k].key}_${instanceIndex}_${k}`;
                                                newPretestDefinition.metadata.equation = newPretestDefinition.metadata.equation.replace(variables[k].key, newVariableKey);
                                            }
                                        }
                                    }

                                    newPretestDefinition.id = `${newPretestDefinition.id}_${instanceIndex}_${k}`;
                                    newPretestDefinition.key = newKey;
                                    newPretestDefinition.position.y = newPretestDefinition.position.y + (defaultHeight * k) + multipleY + k;
                                    newLayoutDefinition.push(newPretestDefinition);
                                }

                                newY = Math.max.apply(Math, newLayoutDefinition.map(d => { return d.position.y + d.position.h }));
                            }

                            multipleY = multipleY + ((defaultHeight * (maxCount - 1)) + maxCount - 1);
                        }
                    }
                }

                selectorDefinition.metadata.pretest.instance = instanceIndex;
                selectorDefinition.position.y = newY;
                selectorDefinition.position.h = 2;
                newLayoutDefinition.push(selectorDefinition);
            }
        } else {
            const otherControl = JSON.parse(JSON.stringify(layoutDefinition[i]));

            otherControl.position.y = otherControl.position.y + multipleY;
            newLayoutDefinition.push(otherControl);
        }
    }

    return newLayoutDefinition;
}

const renderPretestDefaultContent = (control, options) => {
    if (control && control.metadata && control.metadata.pretest && control.metadata.pretest.type && options.pretestPublishedTemplates) {
        const pretestPublishedTemplate = options.pretestPublishedTemplates.filter(t => t.pretestTypeId === control.metadata.pretest.type)[0];

        if (pretestPublishedTemplate && pretestPublishedTemplate.definition) {
            const { definition } = pretestPublishedTemplate.definition;
            if (definition) {
                return definition.map((o) => {
                    // This is child template (ex. rendering template within template) with its own tabindex, 
                    // so in order to maintain position based on parent's tabindex we divide its original 
                    // tabindex by 1000 (default increment size) and add it to the parent template's tabindex.
                    if (!fn.isNullOrUndefined(control.metadata.virtualTabIndex) && !fn.isNullOrUndefined(o.metadata.virtualTabIndex)) {
                        o.metadata.virtualTabIndex = control.metadata.virtualTabIndex + (o.metadata.virtualTabIndex / 1000);
                    }

                    return <div
                        key={o.id}
                        data-grid={o.position}
                    >
                        <>
                            {isControlElement(o) ? renderControl(o, options) : null}
                            {tth.isTextElement(o) ? tth.renderText(o) : null}
                            {pth.isPrintElement(o) ? pth.renderElement(o) : null}
                            {tih.isInputElement(o) ? tih.renderInput(o) : null}
                        </>
                    </div>
                })
            }
        }
    }
}

const renderPretestExamContent = (control, options) => {
    if (control && control.metadata && control.metadata.pretest && control.metadata.pretest.type && options.pretestPublishedTemplates && options.referenceObject) {
        const controls = options.referenceObject.template.sections.map(s => { return s.definition }).flat().filter(d => d.type === control.type && d.metadata.pretest.type === control.metadata.pretest.type);
        const instance = controls && controls.length > 0 ? controls.findIndex(c => c.id === control.id) : 0;
        const pretestPublishedTemplate = options.pretestPublishedTemplates.filter(t => t.pretestTypeId === control.metadata.pretest.type)[0];
        const renderDefinition = []
        let renderNumberOfInstance = 1;
        let offsetY = 0;

        if (pretestPublishedTemplate && pretestPublishedTemplate.definition) {
            const { definition } = pretestPublishedTemplate.definition;

            if (definition) {
                const { examData } = options.referenceObject;

                control.metadata.instance = instance;

                if (control.metadata.pretest.isMultiple) {
                    const controlData = examData.filter(e => definition.filter(d => d.originalKey || d.key).map(d => { return d.originalKey || d.key }).some(d => e.id.startsWith(d)));
                    renderNumberOfInstance = renderNumberOfInstance + (controlData.length > 0 ? Math.max.apply(Math, controlData.filter(d => (d.id.match(/_/g) || []).length === 2).map(d => { return d.id.substring((d.id.lastIndexOf('_') + 1)) })) : 0);
                }

                for (let subInstance = 0; subInstance < renderNumberOfInstance; subInstance++) {
                    let controlDefinition = [...toJS(definition)];

                    if (control.metadata.pretest.isMultiple) {
                        control.metadata.subInstance = subInstance;
                    }

                    if (subInstance < (renderNumberOfInstance - 1)) {
                        controlDefinition = controlDefinition.filter(d => d.type !== TEMPLATE_CONTROL_IMPORT_FROM_DEVICE_BUTTON && d.type !== TEMPLATE_CONTROL_EXPORT_TO_DEVICE_BUTTON);
                    }

                    if (subInstance > 0) {
                        offsetY = offsetY + Math.max.apply(Math, (controlDefinition.filter(d => d.type !== TEMPLATE_CONTROL_IMPORT_FROM_DEVICE_BUTTON && d.type !== TEMPLATE_CONTROL_EXPORT_TO_DEVICE_BUTTON).map(d => d.position.h + (d.position.originalY || d.position.y))));
                    }

                    for (let i = 0; i < controlDefinition.length; i++) {
                        if (controlDefinition[i].key && !controlDefinition[i].originalKey) {
                            controlDefinition[i].originalKey = controlDefinition[i].key
                            controlDefinition[i].key = `${controlDefinition[i].originalKey}_${instance}${(control.metadata.pretest.isMultiple ? `_${subInstance}` : '')}`;
                        }

                        if (!fn.isNullOrUndefined(controlDefinition[i].position.y) && fn.isNullOrUndefined(controlDefinition[i].position.originalY)) {
                            controlDefinition[i].position.originalY = controlDefinition[i].position.y;
                        }

                        controlDefinition[i].position.y = (controlDefinition[i].position.originalY + offsetY);
                    }

                    for (let j = 0; j < controlDefinition.length; j++) {
                        if (controlDefinition[j].metadata.equation && !controlDefinition[j].metadata.originalEquation) {
                            controlDefinition[j].metadata.originalEquation = controlDefinition[j].metadata.equation;

                            let equation = controlDefinition[j].metadata.originalEquation;
                            const variables = mh.parseVariables(controlDefinition[j].metadata.originalEquation);

                            if (variables && variables.length > 0) {
                                for (let k = 0; k < variables.length; k++) {
                                    const newVariableKey = `${variables[k].key}_${instance}${(control.metadata.pretest.isMultiple ? `_${subInstance}` : '')}`;
                                    equation = equation.replace(variables[k].key, newVariableKey);
                                }

                                controlDefinition[j].metadata.equation = equation;
                            }
                        }
                    }

                    renderDefinition.push(...controlDefinition);
                }

                if (offsetY !== 0) {
                    if ((fn.isNullOrUndefined(control.metadata.offsetY)) || control.metadata.offsetY !== offsetY) {
                        const sectionIndex = options.referenceObject.template.sections.findIndex(s => s.definition.some(d => d.id === control.id));
                        let foundIndex = -1

                        for (let d = 0; d < options.referenceObject.template.sections[sectionIndex].definition.length; d++) {
                            if (options.referenceObject.template.sections[sectionIndex].definition[d].id === control.id) {
                                foundIndex = d;
                            }

                            if (d > foundIndex && options.referenceObject.template.sections[sectionIndex].definition[d].position.y > control.position.y) {
                                if (fn.isNullOrUndefined(options.referenceObject.template.sections[sectionIndex].definition[d].position.originalY)) {
                                    options.referenceObject.template.sections[sectionIndex].definition[d].position.originalY = options.referenceObject.template.sections[sectionIndex].definition[d].position.y;
                                }

                                options.referenceObject.template.sections[sectionIndex].definition[d].position.y = options.referenceObject.template.sections[sectionIndex].definition[d].position.originalY + offsetY;
                            }
                        }

                        control.metadata.offsetY = offsetY;
                    }
                }

                return renderDefinition.map((o, oi) => {
                    // This is child template (ex. rendering template within template) with its own tabindex, 
                    // so in order to maintain position based on parent's tabindex we divide its original 
                    // tabindex by 1000 (default increment size) and add it to the parent template's tabindex.
                    if (!fn.isNullOrUndefined(control.metadata.virtualTabIndex) && !fn.isNullOrUndefined(o.metadata.virtualTabIndex)) {
                        o.metadata.virtualTabIndex = control.metadata.virtualTabIndex + (o.metadata.virtualTabIndex / 1000);
                    }

                    return <div
                        key={`${o.id}_${oi}`}
                        data-grid={o.position}
                    >
                        <>
                            {isControlElement(o) ? renderControl(o, {
                                parentControl: control,
                                rowHeight: options.rowHeight,
                                gridWidth: options.width,
                                gridHeight: options.height,
                                gridMargins: options.margins,
                                gridCellWidth: options.gridCellWidth,
                                gridSize: options.gridSize,
                                readOnly: options.readOnly,
                                isDesignMode: options.isDesignMode,
                                value: options.getControlValue(o),
                                referenceObject: options.referenceObject,
                                pretests: options.pretests,
                                prescriptions: options.prescriptions,
                                pretestPublishedTemplates: options.pretestPublishedTemplates,
                                prescriptionPublishedTemplates: options.prescriptionPublishedTemplates,
                                getValue: options.getValue,
                                getControlValue: options.getControlValue,
                                onClick: options.onClick,
                                onChange: options.onChange,
                            }) : null
                            }
                            {
                                tih.isInputElement(o) ? tih.renderInput(o, {
                                    readOnly: options.readOnly,
                                    referenceObject: options.referenceObject,
                                    value: fn.isFunction(options.getValue) ? options.getValue(o.key) : null,
                                    onChange: fn.isFunction(options.onChange) ? (e, key, referenceObject) => { options.onChange(e, o.type, key, referenceObject) } : null,
                                    onBlur: fn.isFunction(options.pretestOnBlur) && !options.readOnly ? (e) => { options.pretestOnBlur(e, o.key, o) } : () => { }
                                }
                                ) : null
                            }
                            {tth.isTextElement(o) ? tth.renderText(o) : null}
                            {pth.isPrintElement(o) ? pth.renderElement(o) : null}
                        </>
                    </div>
                })
            }
        }
    }

    return null;
}

const getDefaultControlLayoutDefinition = (controlLayoutDefinition) => {
    const defaultControlLayoutDefinition = JSON.parse(JSON.stringify(controlLayoutDefinition));
    const diffY = defaultControlLayoutDefinition[0].position.y;

    for (let i = 0; i < defaultControlLayoutDefinition.length; i++) {
        defaultControlLayoutDefinition[i].position.y = defaultControlLayoutDefinition[i].position.y - diffY;
    }

    return defaultControlLayoutDefinition;
}

const getDefaultPretestLayoutDefinition = (pretestLayoutDefinition) => {
    const defaultPretestLayoutDefinition = JSON.parse(JSON.stringify(pretestLayoutDefinition));
    const diffY = defaultPretestLayoutDefinition[0].position.y;

    for (let i = 0; i < defaultPretestLayoutDefinition.length; i++) {
        defaultPretestLayoutDefinition[i].position.y = defaultPretestLayoutDefinition[i].position.y - diffY;
    }

    return defaultPretestLayoutDefinition;
}