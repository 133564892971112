import { createContext } from 'react';
import { decorate, observable, action } from 'mobx';

import * as fn from '../utilities/_functions';
import api from '../api';

export class TodoInstanceViewStore {
    id = null;
    data = null;
    isLoading = false;
    isSaving = false;
    isReady = false;

    cancelTodoInstanceGet = null;
    cancelTodoInstanceStatus = null;
    cancelTodoInstanceNote = null;
    cancelTodoInstanceDelete = null;
    cancelTodoInstanceDeleteAll = null;

    initialize = async (id) => {
        this.clear();
        this.id = id;
        await this.refresh();
        this.isReady = true;
    }

    refresh = async () => {
        this.isLoading = true;

        try {
            const { data } = await api.TodoInstances.get(this.id, (c) => { this.cancelTodoInstanceGet = c });
            this.data = data;
        }
        catch (error) {
            throw error;
        }
        finally {
            this.isLoading = false;
        }
    }

    status = async (status, notify = false) => {
        if (!!notify) {
            this.isSaving = true;
        }
        await api.TodoInstances.status(this.id, { status }, (c) => { this.cancelTodoInstanceStatus = c });
        this.isSaving = false;
    }

    note = async (html, notify = false) => {
        if (!!notify) {
            this.isSaving = true;
        }
        await api.TodoInstances.note(this.id, { html }, (c) => { this.cancelTodoInstanceNote = c });
        this.isSaving = false;
    }

    delete = async (notify = false) => {
        if (this.data.isSingleInstance) {
            if (!!notify) {
                this.isSaving = true;
            }
            await api.TodoInstances.delete(this.id, (c) => { this.cancelTodoInstanceDelete = c });
            this.isSaving = false;
        }
        else {
            this.deleteAll(notify);
        }
    }

    deleteAll = async (notify = false) => {
        if (!!notify) {
            this.isSaving = true;
        }
        await api.Todos.delete(this.data.todoId, (c) => { this.cancelTodoInstanceDeleteAll = c });
        this.isSaving = false;
    }

    clear = () => {
        this.id = null;
        this.data = null;
        this.isLoading = false;
        this.isReady = false;

        if (fn.isFunction(this.cancelTodoInstanceGet)) {
            this.cancelTodoInstanceGet();
            this.cancelTodoInstanceGet = null;
        }

        if (fn.isFunction(this.cancelTodoInstanceStatus)) {
            this.cancelTodoInstanceStatus();
            this.cancelTodoInstanceStatus = null;
        }

        if (fn.isFunction(this.cancelTodoInstanceNote)) {
            this.cancelTodoInstanceNote();
            this.cancelTodoInstanceNote = null;
        }

        if (fn.isFunction(this.cancelTodoInstanceDelete)) {
            this.cancelTodoInstanceDelete();
            this.cancelTodoInstanceDelete = null;
        }

        if (fn.isFunction(this.cancelTodoInstanceDeleteAll)) {
            this.cancelTodoInstanceDeleteAll();
            this.cancelTodoInstanceDeleteAll = null;
        }
    }
}

decorate(TodoInstanceViewStore, {
    id: observable,
    data: observable,
    isLoading: observable,
    isSaving: observable,
    isReady: observable,
    initialize: action,
    refresh: action,
    status: action,
    note: action,
    clear: action,
})

export default createContext(new TodoInstanceViewStore());