import React, { Component } from 'react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import uuid from 'react-uuid';
import { toast } from 'react-toastify';

import * as ErrorMessages from '../../../constants/errorMessages';

import * as fn from '../../../utilities/_functions';
import * as ah from '../../../utilities/addressHelper';
import * as gh from '../../../utilities/geocodingHelper';

export default class AddressInput extends Component {
    constructor(props) {
        super(props);
        this.idRef = React.createRef();
        this.validateRef = React.createRef();
        this.canUpdateRef = React.createRef();
        this.requiredRef = React.createRef();
        this.defaultAddressRef = React.createRef();
        this.state = {
            searchTerm: null,
            address: null,
            isAutoDetect: true,
            hasUnsavedChanges: false,
        };

        this.idRef.current = uuid();
        this.canUpdateRef.current = fn.isNullOrUndefined(this.props.canUpdate) ? true : this.props.canUpdate === true;
        this.requiredRef.current = fn.isNullOrUndefined(this.props.required) ? false : this.props.required === true;
        this.defaultAddressRef.current = { isAutoDetect: true };

        this.canUpdate = this.canUpdate.bind(this);
        this.validate = this.validate.bind(this);
        this.setAddress = this.setAddress.bind(this);
        this.getAddress = this.getAddress.bind(this);
        this.getRegionOptions = this.getRegionOptions.bind(this);
        this.getRegionPlaceholder = this.getRegionPlaceholder.bind(this);
        this.getPostalPlaceholder = this.getPostalPlaceholder.bind(this);

        this.handleAutoDetectChange = this.handleAutoDetectChange.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleAutocompleteChange = this.handleAutocompleteChange.bind(this);
        this.handleAutocompleteSelect = this.handleAutocompleteSelect.bind(this);
        this.handleAutocompleteError = this.handleAutocompleteError.bind(this);
        this.handleStreetNumberChange = this.handleStreetNumberChange.bind(this);
        this.handleStreetNameChange = this.handleStreetNameChange.bind(this);
        this.handleUnitSuiteTypeChange = this.handleUnitSuiteTypeChange.bind(this);
        this.handleUnitSuiteChange = this.handleUnitSuiteChange.bind(this);
        this.handleLine2Change = this.handleLine2Change.bind(this);
        this.handleLocalityChange = this.handleLocalityChange.bind(this);
        this.handleRegionCodeChange = this.handleRegionCodeChange.bind(this);
        this.handleRegionChange = this.handleRegionChange.bind(this);
        this.handlePostalChange = this.handlePostalChange.bind(this);
    }

    componentDidMount() {
        this.initialize();
    }

    componentWillUnmount() {

    }

    initialize = () => {
        if (!!this.props.defaultCountryCode) {
            this.defaultAddressRef.current.countryCode = this.props.defaultCountryCode;
            this.defaultAddressRef.current.country = ah.COUNTRIES.filter(o => o.alpha2 === this.props.defaultCountryCode)[0].name;
        }

        if (this.props.address && !!this.props.address.regionCode && !!this.props.address.countryCode && (!!this.props.address.streetName || !!this.props.address.postalCode)) {
            this.setAddress(this.props.address);
        }
        else {
            this.setState({
                address: this.defaultAddressRef.current,
            });
        }
    }

    isEmpty = () => {
        const { address, searchTerm } = this.state;
        return !address || (!searchTerm && !address.streetNumber && !address.streetName && !address.line2 && !address.unitSuite && !address.locality && !address.regionCode && !address.postalCode);
    }

    canUpdate = () => {
        return !!this.state.address.countryCode && (this.canUpdateRef.current === true);
    }

    shouldValidate = () => {
        return this.canUpdate() && !this.isEmpty();
    }

    validate = () => {
        if (!this.shouldValidate()) {
            return fn.validateForm(this.validateRef.current);
        }

        return true;
    }

    setAddress = (address) => {
        const searchTerm = gh.getStreetNumberName(address);
        address = address ? address : {};
        this.setState({ searchTerm: searchTerm, address: address });
    }

    getAddress = () => {
        const { address } = this.state;
        return JSON.parse(JSON.stringify(address));
    }

    getRegionOptions = () => {
        const { address } = this.state;

        let options = [];

        if (address) {
            switch (address.countryCode) {
                case 'CA':
                    options = ah.CANADIAN_PROVINCES;
                    break;

                case 'US':
                    options = ah.US_STATES;
                    break;

                default:
                    break;
            }
        }

        return options;
    }

    getRegionPlaceholder = () => {
        const { address } = this.state;

        if (address) {
            switch (address.countryCode) {
                case 'CA':
                    return 'Province';

                case 'US':
                    return 'States';

                default:
                    break;
            }
        }

        return 'Region'
    }

    getPostalPlaceholder = () => {
        const { address } = this.state;

        if (address) {
            switch (address.countryCode) {
                case 'CA':
                    return 'Postal Code';

                case 'US':
                    return 'Zip Code';

                default:
                    break;
            }
        }

        return 'Postal'
    }

    onChange = () => {
        if (!!this.props.onChange && fn.isFunction(this.props.onChange)) {
            const { address } = this.state;

            if (!this.isEmpty()) {
                this.props.onChange(address);
            }
            else {
                this.props.onChange(null);
            }
        }
    }

    handleClearAddress = event => {
        const that = this;

        this.setState({
            searchTerm: null,
            address: that.defaultAddressRef.current,
            isAutoDetect: true,
            hasUnsavedChanges: true,
        }, that.onChange)
    }

    handleAutoDetectChange = event => {
        const isAutoDetect = event.target.checked;
        this.setState({ isAutoDetect: isAutoDetect });
    }

    handleCountryChange = (event) => {
        const that = this;
        const countryCode = event.target.value;
        const newAddress = { isAutoDetect: true };

        newAddress.countryCode = countryCode;
        newAddress.country = [...event.target.options].filter(o => o.value === countryCode)[0].innerText;

        this.setState({
            searchTerm: null,
            address: newAddress,
            hasAddressUnsavedChanges: true
        }, that.onChange);
    }

    handleAutocompleteChange = (search) => {
        const that = this;
        const { address } = this.state;
        const newAddress = { countryCode: address.countryCode, country: address.country };

        this.setState({
            searchTerm: search,
            address: newAddress,
            hasAddressUnsavedChanges: true
        }, that.onChange);
    }

    handleAutocompleteSelect = (geocodeAddress) => {
        const that = this;

        geocodeByAddress(geocodeAddress)
            .then((results) => {
                if (results && results.length > 0) {
                    const newAddress = gh.setAddress(results[0]);
                    const { searchTerm, isAutoDetect } = that.state;
                    let newIsAutoDetect = isAutoDetect;

                    if (!newAddress.streetNumber && !newAddress.streetName && !!newAddress.locality && !!searchTerm) {
                        const search = searchTerm.split(newAddress.locality)[0].split(',')[0].trim();
                        const streetNumberName = search.match(fn.regexMatch.streetNumberAndName);

                        if (!!streetNumberName && streetNumberName.length === 3) {
                            newAddress.streetNumber = streetNumberName[1].trim();
                            newAddress.streetName = streetNumberName[2].trim();
                        }

                        newIsAutoDetect = false;
                    }

                    if (!!newAddress.streetNumber && /[0-9]/.test(newAddress.streetNumber) && /[a-zA-Z]/.test(newAddress.streetNumber)) {
                        const streetNumber = newAddress.streetNumber.replace(/[a-zA-Z]/g, '').replace(/[^0-9a-z]/gi, '').trim();
                        const unitSuite = newAddress.streetNumber.replace(streetNumber, '').replace(/[^a-z]/gi, '').trim();

                        if (newAddress.streetNumber.replace(/\s/g, '').replace(/[^0-9a-z]/gi, '') === `${streetNumber}${unitSuite}`) {
                            newAddress.streetNumber = streetNumber;
                            newAddress.unitSuiteType = 'Unit';
                            newAddress.unitSuite = unitSuite;
                        }
                    }

                    that.setState({
                        searchTerm: gh.getStreetNumberName(newAddress),
                        address: newAddress,
                        isAutoDetect: newIsAutoDetect,
                        hasAddressUnsavedChanges: true
                    }, that.onChange);
                }
                else {
                    that.setState({
                        searchTerm: null,
                        hasAddressUnsavedChanges: true
                    }, that.onChange);
                }
            })
            .catch((error) => {
                if (error !== 'ZERO_RESULTS') {
                    toast.error(() => <p>Oops, something went wrong. Try again later.</p>, { position: 'top-center' });
                }
            });
    }

    handleAutocompleteError = (error) => {
        if (error !== 'ZERO_RESULTS') {
            toast.error(() => ErrorMessages.GENERIC_ERROR_HTML, { position: 'top-center' });
        }
    }

    handleStreetNumberChange = (event) => {
        const that = this;
        const streetNumber = event.target.value;
        const newAddress = this.state.address;

        newAddress.streetNumber = streetNumber;

        this.setState({
            address: newAddress,
            hasUnsavedChanges: true
        }, that.onChange);
    }

    handleStreetNameChange = (event) => {
        const that = this;
        const streetName = event.target.value;
        const newAddress = this.state.address;

        newAddress.streetName = streetName;

        this.setState({
            address: newAddress,
            hasUnsavedChanges: true
        }, that.onChange);
    }

    handleUnitSuiteTypeChange = (event) => {
        const that = this;
        const unitSuiteType = event.target.value;
        const newAddress = this.state.address;

        newAddress.unitSuiteType = unitSuiteType;

        this.setState({
            address: newAddress,
            hasUnsavedChanges: true
        }, that.onChange);
    }

    handleUnitSuiteChange = (event) => {
        const that = this;
        const unitSuite = event.target.value;
        const newAddress = this.state.address;

        newAddress.unitSuite = unitSuite;

        this.setState({
            address: newAddress,
            hasUnsavedChanges: true
        }, that.onChange);
    }

    handleLine2Change = (event) => {
        const that = this;
        const line2 = event.target.value;
        const newAddress = this.state.address;

        newAddress.line2 = line2;

        this.setState({
            address: newAddress,
            hasUnsavedChanges: true
        }, that.onChange);
    }

    handleLocalityChange = (event) => {
        const that = this;
        const locality = event.target.value;
        const newAddress = this.state.address;

        newAddress.locality = locality;
        newAddress.sublocality = null;

        this.setState({
            address: newAddress,
            hasUnsavedChanges: true
        }, that.onChange);
    }

    handleRegionCodeChange = (event) => {
        const that = this;
        const regionCode = event.target.value;
        const newAddress = this.state.address;

        newAddress.regionCode = regionCode;
        newAddress.region = [...event.target.options].filter(o => o.value === regionCode)[0].innerText;

        this.setState({
            address: newAddress,
            hasUnsavedChanges: true
        }, that.onChange);
    }

    handleRegionChange = (event) => {
        const that = this;
        const region = event.target.value;
        const newAddress = this.state.address;

        newAddress.regionCode = null;
        newAddress.region = region;

        this.setState({
            address: newAddress,
            hasUnsavedChanges: true
        }, that.onChange);
    }

    handlePostalChange = (event) => {
        const that = this;
        const postalCode = event.target.value;
        const newAddress = this.state.address;

        newAddress.postalCode = postalCode;

        this.setState({
            address: newAddress,
            hasUnsavedChanges: true
        }, that.onChange);
    }

    render() {
        const { label, defaultCountryCode } = this.props;
        const { searchTerm, address, isAutoDetect } = this.state;
        const regionOptions = this.getRegionOptions();
        const instanceId = this.idRef.current;

        return address ? <div ref={this.validateRef}>
            <div className='row'>
                <div className='col-12'>
                    <div className='form-group'>
                        <div className='d-flex'>
                            {
                                !!label ?
                                    typeof (label) === 'string' ? <label><small>{label}</small></label> : label                                    
                                    : <label><small>Address</small></label>
                            }
                            <div className='flex-1 text-right'>
                                <div className='custom-control custom-switch'>
                                    <input
                                        id={`__address-input-auto-detect-${instanceId}`}
                                        type='checkbox'
                                        name={`__address-input-auto-detect-${instanceId}`}
                                        className='custom-control-input'
                                        checked={isAutoDetect}
                                        onChange={this.handleAutoDetectChange}
                                    />
                                    <label
                                        htmlFor={`__address-input-auto-detect-${instanceId}`}
                                        className='custom-control-label'
                                    >
                                        {
                                            <small className={(isAutoDetect ? 'text-gray-700' : 'text-gray-500')}>Auto-detect</small>
                                        }
                                    </label>
                                </div>
                            </div>
                            {
                                this.canUpdateRef.current === true && this.requiredRef.current === false ?
                                    <div>
                                        <button
                                            type='button'
                                            className='btn btn-icon-only p-0 ml-2'
                                            onClick={this.handleClearAddress}
                                        >
                                            <i className='fal fa-times text-danger'></i>
                                        </button>
                                    </div> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <div className='form-group mb-3'>
                        <select
                            className='custom-select form-control'
                            placeholder='Country'
                            value={address.countryCode ? address.countryCode : ''}
                            onChange={this.handleCountryChange}
                        >
                            <option value=''>Country</option>
                            {
                                !!defaultCountryCode ?
                                    <>
                                        <optgroup label='Detected'>
                                            {
                                                ah.COUNTRIES.filter(c1 => defaultCountryCode === c1.alpha2).map((c1, ci1) => {
                                                    return <option
                                                        key={`__address_input_country_detected_${instanceId}_${ci1}`}
                                                        value={c1.alpha2}
                                                    >{c1.name}</option>
                                                })
                                            }
                                        </optgroup>
                                        <optgroup label='Other'>
                                            {
                                                ah.COUNTRIES.filter(c1 => defaultCountryCode !== c1.alpha2).map((c1, ci1) => {
                                                    return <option
                                                        key={`__address_input_country_other_${instanceId}_${ci1}`}
                                                        value={c1.alpha2}
                                                    >{c1.name}</option>
                                                })
                                            }
                                        </optgroup>
                                    </> :
                                    <>
                                        {
                                            ah.COUNTRIES.map((c1, ci1) => {
                                                return <option
                                                    key={`__address_input_country_${ci1}`}
                                                    value={c1.alpha2}
                                                >{c1.name}</option>
                                            })
                                        }
                                    </>
                            }
                        </select>
                    </div>
                </div>
            </div>
            <div className='row'>
                {
                    isAutoDetect ?

                        <div className='col-12'>
                            <PlacesAutocomplete
                                value={searchTerm ? searchTerm : ''}
                                debounce={500}
                                highlightFirstSuggestion={true}
                                searchOptions={{
                                    types: ['geocode'],
                                    componentRestrictions: { country: address.countryCode },
                                }}
                                onChange={(a) => { this.handleAutocompleteChange(a) }}
                                onSelect={(a) => { this.handleAutocompleteSelect(a) }}
                                onError={this.handleAutocompleteError}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                    <div className='autocomplete-wrapper'>
                                        <input
                                            {...getInputProps({
                                                id: `__address-input-search-term-${instanceId}`,
                                                className: 'form-control',
                                                placeholder: 'Line 1',
                                                spellCheck: false,
                                                autoComplete: `autocomplete-${Math.random().toString(36).substring(2, 10)}`,
                                                disabled: !this.canUpdate(),
                                            })}
                                        />
                                        <div className={'autocomplete-dropdown-container' + (suggestions && suggestions.length > 0 ? ' show' : '')}>
                                            <div className='dropdown-menu w-100 dropdown-menu-animated'>
                                                {
                                                    suggestions.map((suggestion, si) => {
                                                        const className = suggestion.active
                                                            ? 'dropdown-item py-2 px-3 w-100 active'
                                                            : 'dropdown-item py-2 px-3 w-100';
                                                        return (
                                                            <div key={`suggestion-${si}`}>
                                                                <div
                                                                    {...getSuggestionItemProps(suggestion, {
                                                                        className,
                                                                    })}
                                                                >
                                                                    <span className='d-block fs-sm text-truncate'>{suggestion.description}</span>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                        </div> :
                        <>
                            <div className={'col-4' + (this.shouldValidate() ? ' validate validate-required' : '')}>
                                <input
                                    type='text'
                                    className={'form-control'}
                                    placeholder='Street No.'
                                    disabled={!this.canUpdate()}
                                    value={address.streetNumber ? address.streetNumber : ''}
                                    onChange={this.handleStreetNumberChange}
                                />
                            </div>
                            <div className={'col-8' + (this.shouldValidate() ? ' validate validate-required' : '')}>
                                <input
                                    type='text'
                                    className={'form-control'}
                                    placeholder='Street Name'
                                    disabled={!this.canUpdate()}
                                    value={address.streetName ? address.streetName : ''}
                                    onChange={this.handleStreetNameChange}
                                />
                            </div>
                        </>
                }
            </div>
            <div className='row mt-2'>
                <div className={'col-4' + (this.shouldValidate() && !!address.unitSuite ? ' validate validate-required' : '')}>
                    <select
                        className={'custom-select form-control'}
                        placeholder='Unit type'
                        disabled={!this.canUpdate()}
                        value={address.unitSuiteType ? address.unitSuiteType : ''}
                        onChange={this.handleUnitSuiteTypeChange}
                    >
                        <option value=''>Unit Type</option>
                        <option value='Suite'>Suite</option>
                        <option value='Unit'>Unit</option>
                        <option value='Apt'>Apt</option>
                    </select>
                </div>
                <div className={'col-8' + (this.shouldValidate() && !!address.unitSuiteType ? ' validate validate-required' : '')}>
                    <input
                        type='text'
                        className={'form-control'}
                        placeholder='Unit No.'
                        disabled={!this.canUpdate()}
                        value={address.unitSuite ? address.unitSuite : ''}
                        onChange={this.handleUnitSuiteChange}
                    />
                </div>
            </div>
            <div className='row mt-2'>
                <div className={'col-12'}>
                    <input
                        type='text'
                        className={'form-control'}
                        placeholder='Line 2'
                        disabled={!this.canUpdate()}
                        value={address.line2 ? address.line2 : ''}
                        onChange={this.handleLine2Change}
                    />
                </div>
            </div>
            <div className='row mt-2'>
                <div className={'col-6' + (this.shouldValidate() ? ' validate validate-required' : '')}>
                    <input
                        type='text'
                        className={'form-control'}
                        placeholder='City'
                        disabled={!this.canUpdate()}
                        value={address.locality ? address.locality : ''}
                        onChange={this.handleLocalityChange}
                    />
                </div>
                <div className={'col-6' + (this.shouldValidate() ? ' validate validate-required' : '')}>
                    {
                        regionOptions && regionOptions.length > 0 ?
                            <select
                                className={'custom-select form-control'}
                                disabled={!this.canUpdate()}
                                value={address.regionCode ? address.regionCode : ''}
                                onChange={this.handleRegionCodeChange}
                            >
                                <option value=''>{this.getRegionPlaceholder()}</option>
                                {
                                    regionOptions.map((o, oi) => {
                                        return <option
                                            key={`__address_input_region_${instanceId}_${oi}`}
                                            value={o.code}
                                        >{o.name}</option>
                                    })
                                }
                            </select> :
                            <input
                                type='text'
                                className={'form-control'}
                                placeholder={this.getRegionPlaceholder()}
                                disabled={!this.canUpdate()}
                                value={address.region ? address.region : ''}
                                onChange={this.handleRegionChange}
                            />
                    }
                </div>
            </div>
            <div className='row mt-2'>
                <div className={'col-12' + (this.shouldValidate() ? ' validate validate-required' : '')}>
                    <input
                        type='text'
                        className={'form-control'}
                        placeholder={this.getPostalPlaceholder()}
                        disabled={!this.canUpdate()}
                        value={address.postalCode ? address.postalCode : ''}
                        onChange={this.handlePostalChange}
                    />
                </div>
            </div>
        </div> : null
    }
}