import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { AuthWrapper } from "./components/AuthWrapper";
import { EXPIRES_AT } from './constants/storageKeys';
import FailoverLogin from './components/views/failover/FailoverLogin';

/** IMPORTANT - DO NOT CHANGE THE ORDERING BELOW. The app.core.scss is the base style of the application, and IT MUST BE LOADED BEFORE we import the App.js; otherwise, the css will compiled in a different order which will affect the look and feel. */
import './components/styles/app.core.scss';
import App from './components/App';
/** IMPORTANT - DO NOT CHANGE THE ORDERING ABOVE. */

const FailoverAuthWrapper = ({ children }) => {
    let isExpired = true;
    const expiresAtJson = window.localStorage.getItem(EXPIRES_AT);
    if (expiresAtJson) {    
        const expiresAt = JSON.parse(expiresAtJson);
        isExpired = new Date().getTime() >= expiresAt;
    }
    const [isLoggedIn, setLoggedIn] = useState(!isExpired);
    return isLoggedIn ? children : window.history.pushState({}, '', '/') || <FailoverLogin onLoginSuccess={() => setLoggedIn(true)} />;
};

const AppWrapper = () => {
    const isFailover = !!process.env.REACT_APP_IS_FAILOVER; 

    return isFailover ? (
        <FailoverAuthWrapper>
            <App isFailover={isFailover}/>
        </FailoverAuthWrapper>
    ) : (
        <AuthWrapper>
            <App isFailover={isFailover}/>
        </AuthWrapper>
    );
};


ReactDOM.render(<AppWrapper />, document.getElementById('root'));
