import { createContext } from 'react';
import { decorate, observable, action } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class TenantCreateUpdate {
    data = null;
    isLoading = false;
    isSaving = false;
    isReady = false;
    hasUnsavedChanges = false;
    newAddress = {
        geocode: null,
        streetNumber: null,
        streetName: null,
        unitSuiteType: null,
        unitSuite: null,
        line2: null,
        locality: null,
        sublocality: null,
        postalCode: null,
        region: null,
        regionCode: null,
        country: null,
        countryCode: null,
    };

    cancelTenantCreateUpdate = null;
    cancelAddressInformationUpdate = null;
    
    initialize = (item) => {
        this.clear();
        if (item) {
            this.data = item;
        }
        this.isReady = true;
    }

    refresh = () => {
        const that = this;
        return new Promise((resolve, reject) => {
            api.Tenants.get(
                this.id,
                false,
                (c) => { that.cancelTenantCreateUpdate = c }
            )
                .then(({ data }) => {
                    that.data = data;
                    that.clearNewAddress();
                    resolve();
                })
                .catch(() => {
                    reject();
                })
        })
    }

    save = async (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        try {
            if (this.data.id) {
                await api.Tenants.update(
                    this.data.id,
                    this.data,
                    (c) => { that.cancelTenantCreateUpdate = c });
            }
            else {
                await api.Tenants.create({
                    ...this.data,
                    address: this.newAddress
                },
                    (c) => { that.cancelTenantCreateUpdate = c });
            }
            that.hasUnsavedChanges = false;
        }
        catch(e) {
            if (e.response) {
                throw e.response.data;
            }
            else throw e;
        }
        finally {
            this.isSaving = false;
        }
    }

    updateAddressInformation = (notify) => {
        const that = this;
        return new Promise((resolve, reject) => {
            if (that.hasAddressUnsavedChanges) {
                if (!!notify) {
                    that.isSaving = true;
                }

                api.Tenants.update(
                    that.data.id,
                    {
                        addressInformation: {
                            existingAddress: that.data.address,
                            newAddress: that.newAddress
                        }
                    },
                    (c) => { that.cancelAddressInformationUpdate = c }
                )
                    .then(({data}) => {
                        // the backend returned the new/udpated address so we can refresh the view
                        this.data.address = data.address;
                        that.clearNewAddress();
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    })
                    .finally(() => {
                        that.isSaving = false;
                        that.hasAddressUnsavedChanges = false;
                    })
            } else {
                resolve();
            }
        })
    }

    delete = (tenants) => {
        const that = this;

        this.isSaving = true;

        return new Promise((resolve, reject) => {
            Promise.all(
                [...tenants].map(g => {
                    return api.Tenants.delete(g.id, (c) => { that.cancelTenantCreateUpdate = c })
                })
            )
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    clear = () => {
        this.data = {
            name: '',
            displayOrder: 100,
            users: []
        };
        this.isLoading = false;
        this.isReady = false;
        this.hasUnsavedChanges = false;
        this.clearNewAddress();

        if (fn.isFunction(this.cancelTenantCreateUpdate)) {
            this.cancelTenantCreateUpdate();
            this.cancelTenantCreateUpdate = null;
        }
    }

    clearNewAddress = () => {
        this.newAddress.geocode = null;
        this.newAddress.streetNumber = null;
        this.newAddress.streetName = null;
        this.newAddress.unitSuiteType = null;
        this.newAddress.unitSuite = null;
        this.newAddress.line2 = null;
        this.newAddress.locality = null;
        this.newAddress.sublocality = null;
        this.newAddress.postalCode = null;
        this.newAddress.region = null;
        this.newAddress.regionCode = null;
        this.newAddress.country = null;
        this.newAddress.countryCode = null;
    }
}

decorate(TenantCreateUpdate, {
    data: observable.deep,
    isSaving: observable,
    isLoading: observable,
    isReady: observable,
    newAddress: observable.deep,
    hasUnsavedChanges: observable,
    initialize: action,
    refresh: action,
    clear: action,
    clearNewAddress: action
})

export default createContext(new TenantCreateUpdate());