import { createContext } from 'react';
import { decorate, observable, action, computed, toJS } from 'mobx';
import moment from 'moment';

import api from '../api';
import * as fn from '../utilities/_functions';

export class ExamView {
    appointmentId = null;
    previousExamId = null;
    selectedSectionId = null;
    selectedPreviousExamSectionId = null;
    data = null;
    referrals = [];
    medicalReports = [];
    previousExams = [];
    pretests = [];
    prescriptions = [];
    reopenReason = null;
    deactivateReason = null;
    importDeviceData = null;
    exportDeviceData = null;

    hasUnsavedChanges = false;
    isLoading = false;
    isSaving = false;
    isReady = false;
    isPretestsReady = false;
    isPrescriptionsReady = false;
    isReferralsReady = false;
    isMedicalReportsReady = false;

    cancelExamGet = null;
    cancelExamSearch = null;
    cancelExamUpdate = null;
    cancelExamComplete = null;
    cancelExamReopen = null;
    cancelPrescriptions = null;
    cancelPretests = null;
    cancelPreviousExamSearch = null;
    cancelReferrals = null;
    cancelMedicalReports = null;

    initialize = (appointmentId) => {
        const that = this;
        this.clear();
        this.appointmentId = appointmentId;
        this.isReady = false;

        return new Promise((resolve, reject) => {
            if (that.appointmentId) {
                that.refresh(true)
                    .then(() => {
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isReady = true;
                    })
            } else {
                resolve();
            }
        })
    }

    refresh = (loadPreviousExams = false) => {
        const that = this;
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            api.Exams.search(
                {
                    parameters: [
                        {
                            field: 'AppointmentId',
                            value: that.appointmentId,
                        },
                        {
                            field: 'DeactivatedDateUtc',
                            value: null,
                        },
                        {
                            field: 'Status',
                            value: 'New,InProcess,Completed',
                            operator: 'Contains'
                        },
                    ],
                    loadProperties: false,
                    includeTotalCount: false,
                },
                (c) => { that.cancelExamSearch = c }
            )
                .then(({ data }) => {
                    if (data && data.result && data.result.length > 0) {
                        const examId = data.result[0].id;

                        api.Exams.get(examId, true, (c) => { that.cancelExamGet = c })
                            .then(({ data }) => {
                                const searchOption = {
                                    parameters: [
                                        {
                                            field: 'Id',
                                            operator: '!=',
                                            value: examId,
                                        },
                                        {
                                            field: 'CustomerId',
                                            value: data.appointment.customerId,
                                        },
                                        {
                                            field: 'DeactivatedDateUtc',
                                            value: null,
                                        },
                                        {
                                            field: 'Status',
                                            operator: '!=',
                                            value: 'Deleted',
                                        },
                                    ],
                                    sortByFields: [{
                                        field: 'ScheduledStartUtc',
                                        direction: 'DESC',
                                    }, {
                                        field: 'CreatedDateUtc',
                                        direction: 'DESC',
                                    }],
                                    loadProperties: true,
                                    includeTotalCount: false,
                                };

                                that.data = data;
                                that.data.examData = [
                                    ...data.template.sections.map(s => {
                                        return s.definition.filter(d => d.key)
                                    })
                                        .flat()
                                        .map(d => {
                                            return { id: d.key, value: null }
                                        })
                                        .filter(e => !data.examData.some(d => e.id === d.id)),
                                    ...data.examData
                                ];

                                if (loadPreviousExams) {
                                    that.previousExams.clear();
                                    api.Exams.search(searchOption, (c) => { that.cancelPreviousExamSearch = c })
                                        .then(({ data: previousExams }) => {
                                            if (previousExams.result && previousExams.result.length > 0) {
                                                that.previousExams = previousExams.result;
                                            }
                                        })
                                }

                                resolve();
                            })
                            .catch((error) => {
                                reject(error);
                            })
                            .finally(() => {
                                that.isLoading = false;
                            })
                    } else {
                        that.isLoading = false;
                        resolve();
                    }
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isLoading = false;
                })
        })
    }

    refreshAppointment = (notify) => {
        const that = this;

        if (!!notify) {
            this.isLoading = true;
        }

        return new Promise((resolve, reject) => {
            api.Appointments.get(that.appointmentId)
                .then(({ data }) => {
                    that.data.appointment = data;
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isLoading = false;
                })
        })
    }

    loadPretests = (notify) => {
        const that = this;

        if (!!notify) {
            this.isLoading = true;
        }

        return new Promise((resolve, reject) => {
            if (that.data) {
                api.Pretests.search(
                    {
                        parameters: [
                            { field: 'DeactivatedDateUtc', value: null },
                            { field: 'CustomerId', value: that.data.customer.id },
                        ],
                        sortByFields: [{ field: 'CreatedDateUtc', direction: 'DESC', }],
                        includeTotalCount: false,
                    },
                    (c) => { that.cancelPretests = c }
                )
                    .then(({ data }) => {
                        that.pretests = data && data.result ? data.result : [];
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isLoading = false;
                        that.isPretestsReady = true;
                    })
            } else {
                resolve();
            }
        })
    }

    loadPrescriptions = (notify) => {
        const that = this;

        if (!!notify) {
            this.isLoading = true;
        }

        return new Promise((resolve, reject) => {
            if (that.data) {
                api.Prescriptions.search(
                    {
                        parameters: [
                            { field: 'DeactivatedDateUtc', value: null },
                            { field: 'CustomerId', value: that.data.customer.id },
                        ],
                        sortByFields: [{ field: 'WrittenDate', direction: 'DESC', }],
                        includeTotalCount: false,
                    },
                    (c) => { that.cancelPrescriptions = c }
                )
                    .then(({ data }) => {
                        that.prescriptions = data && data.result ? data.result : [];
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isLoading = false;
                        that.isPrescriptionsReady = true;
                    })
            } else {
                resolve();
            }
        })
    }

    loadReferrals = (notify) => {
        const that = this;

        if (!!notify) {
            this.isLoading = true;
        }

        return new Promise((resolve, reject) => {
            if (that.data) {
                api.Referrals.search(
                    {
                        parameters: [
                            { field: 'ExamId', value: that.data.id },
                            { field: 'DeactivatedDateUtc', value: null },
                        ],
                        sortByFields: [{ field: 'CreatedDateUtc', direction: 'DESC', }],
                        includeTotalCount: false,
                    },
                    (c) => { that.cancelReferrals = c }
                )
                    .then(({ data }) => {
                        that.referrals = data && data.result ? data.result : [];
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isLoading = false;
                        that.isReferralsReady = true;
                    })
            } else {
                resolve();
            }
        })
    }

    loadMedicalReports = (notify) => {
        const that = this;

        if (!!notify) {
            this.isLoading = true;
        }

        return new Promise((resolve, reject) => {
            if (that.data) {
                api.MedicalReports.search(
                    {
                        parameters: [
                            { field: 'ExamId', value: that.data.id },
                            { field: 'DeactivatedDateUtc', value: null },
                        ],
                        sortByFields: [{ field: 'CreatedDateUtc', direction: 'DESC', }],
                        includeTotalCount: false,
                    },
                    (c) => { that.cancelMedicalReports = c }
                )
                    .then(({ data }) => {
                        that.medicalReports = data && data.result ? data.result : [];
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isLoading = false;
                        that.isMedicalReportsReady = true;
                    })
            } else {
                resolve();
            }
        })
    }

    upload = (files, notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            api.Exams.upload(that.data.id, {
                files: files,
            }, (c) => { that.cancelExamUpdate = c })
                .then(({ data }) => {
                    that.hasUnsavedChanges = false;
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            if (that.hasUnsavedChanges) {
                const examData = [...that.data.examData.filter(d => d.value).map(d => { return fn.removeEmptyProperties(toJS(d)) })];
                api.Exams.update(that.data.id, {
                    data: examData,
                    diagnosticCodes: that.data.diagnosticCodes && that.data.diagnosticCodes.length > 0 ? that.data.diagnosticCodes : [],
                    referringProviderNumber: that.data.referringProviderNumber ? that.data.referringProviderNumber : null,
                }, (c) => { that.cancelExamUpdate = c })
                    .then(() => {
                        if (that.data.status === 'New') {
                            that.data.status = 'InProcess';
                        }
                        that.hasUnsavedChanges = false;
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                resolve();
            }
        })
    }

    delete = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            api.Exams.delete(
                that.data.id,
                { deactivateReason: that.deactivateReason },
                (c) => { that.cancelExamUpdate = c })
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isSaving = false;
                    that.clearReopen();
                })
        })
    }

    complete = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            const option = { serviceDiagnosticCodes: [] };

            if (that.hasRequiredDiagnosticCodeServices) {
                option.serviceDiagnosticCodes = that.requiredDiagnosticCodeServices.map(s => {
                    return {
                        id: s.id,
                        diagnosticCode: s.diagnosticCode
                    }
                })
            }

            api.Exams.complete(that.data.id, option, (c) => { that.cancelExamComplete = c })
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    reopen = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            api.Exams.reopen(
                that.data.id,
                { reopenReason: that.reopenReason },
                (c) => { that.cancelExamReopen = c })
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isSaving = false;
                    that.clearReopen();
                })
        })
    }

    clearPretests = () => {
        this.pretests.clear();
        this.isPretestsReady = false;
    }

    clearPrescriptions = () => {
        this.prescriptions.clear();
        this.isPrescriptionsReady = false;
    }

    clearReferrals = () => {
        this.referrals.clear();
        this.isReferralsReady = false;
    }

    clearMedicalReports = () => {
        this.medicalReports.clear();
        this.isMedicalReportsReady = false;
    }

    clearReopen = () => {
        this.reopenReason = null;
    }

    clearDelete = () => {
        this.deactivateReason = null;
    }

    clear = () => {
        this.appointmentId = null;
        this.previousExamId = null;
        this.selectedSectionId = null;
        this.selectedPreviousExamSectionId = null;
        this.data = null;
        this.importDeviceData = null;
        this.exportDeviceData = null;
        this.previousExams.clear();
        this.hasUnsavedChanges = false;
        this.isLoading = false;
        this.isSaving = false;
        this.isReady = false;
        this.clearPretests();
        this.clearPrescriptions();
        this.clearReferrals();
        this.clearMedicalReports();
        this.clearReopen();
        this.clearDelete();

        if (fn.isFunction(this.cancelExamGet)) {
            this.cancelExamGet();
            this.cancelExamGet = null;
        }

        if (fn.isFunction(this.cancelExamSearch)) {
            this.cancelExamSearch();
            this.cancelExamSearch = null;
        }

        if (fn.isFunction(this.cancelExamUpdate)) {
            this.cancelExamUpdate();
            this.cancelExamUpdate = null;
        }

        if (fn.isFunction(this.cancelExamComplete)) {
            this.cancelExamComplete();
            this.cancelExamComplete = null;
        }

        if (fn.isFunction(this.cancelExamReopen)) {
            this.cancelExamReopen();
            this.cancelExamReopen = null;
        }

        if (fn.isFunction(this.cancelPrescriptions)) {
            this.cancelPrescriptions();
            this.cancelPrescriptions = null;
        }

        if (fn.isFunction(this.cancelPretests)) {
            this.cancelPretests();
            this.cancelPretests = null;
        }

        if (fn.isFunction(this.cancelPreviousExamSearch)) {
            this.cancelPreviousExamSearch();
            this.cancelPreviousExamSearch = null;
        }

        if (fn.isFunction(this.cancelReferrals)) {
            this.cancelReferrals();
            this.cancelReferrals = null;
        }

        if (fn.isFunction(this.cancelMedicalReports)) {
            this.cancelMedicalReports();
            this.cancelMedicalReports = null;
        }
    }

    get previousExam() {
        if (!this.previousExams || this.previousExams.length === 0) return null;
        return this.previousExams.some(p => p.id === this.previousExamId) ? this.previousExams.filter(p => p.id === this.previousExamId)[0] : null;
    }

    get selectedSection() {
        if (!this.data || !this.data.template || !this.data.template.sections) return null;
        const sections = toJS(this.data.template.sections);
        return sections ? (sections.some(s => s.id === this.selectedSectionId) ? sections.filter(s => s.id === this.selectedSectionId)[0] : this.sortedSections[0]) : null;
    }

    get selectedPreviousExamSection() {
        if (!this.previousExam || !this.previousExam.template || !this.previousExam.template.sections) return null;
        const sections = toJS(this.previousExam.template.sections);
        return sections ? (sections.some(s => s.id === this.selectedPreviousExamSectionId) ? sections.filter(s => s.id === this.selectedPreviousExamSectionId)[0] : this.sortedPreviousExamSections[0]) : null;
    }

    get sortedSections() {
        if (!this.data || !this.data.template || !this.data.template.sections) return [];

        const sections = toJS(this.data.template.sections);
        const sortedSections = sections.sort((a, b) => { return a.displayOrder - b.displayOrder });

        return sortedSections;
    }

    get sortedPreviousExamSections() {
        if (!this.previousExam || !this.previousExam.template || !this.previousExam.template.sections) return [];

        const sections = toJS(this.previousExam.template.sections);
        const sortedSections = sections.sort((a, b) => { return a.displayOrder - b.displayOrder });

        return sortedSections;
    }

    get relatedPretests() {
        if (!this.pretests) return [];

        const rangeStart = moment.utc(this.data.actualStartUtc).local().startOf('day').utc();
        const rangeEnd = rangeStart.clone().add(1, 'days').add(-1, 'seconds');
        const relatedPretests = toJS(this.pretests.filter(p => moment(p.createdDateUtc).isSameOrAfter(rangeStart) && moment(p.createdDateUtc).isSameOrBefore(rangeEnd)));

        return relatedPretests;
    }

    get requiredDiagnosticCodeServices() {
        if (this.data && this.data.appointment && this.data.appointment.services.length > 0 && this.data.appointment.services.some(s => !!s.isDiagnosticCodeRequired)) {
            return this.data.appointment.services.filter(s => !!s.isDiagnosticCodeRequired);
        }

        return [];
    }

    get hasRequiredDiagnosticCodeServices() {
        return this.requiredDiagnosticCodeServices.length > 0;
    }

    get lockedServices() {
        if (this.data && this.data.services && this.data.services.length > 0 && this.data.services.some(s => s.lastPublicInsuranceBilling && !!s.lastPublicInsuranceBilling.isLocked)) {
            return this.data.services.filter(s => s.lastPublicInsuranceBilling && !!s.lastPublicInsuranceBilling.isLocked);
        }

        return [];
    }

    get fileViewers() {
        if (!this.data || !this.data.template || !this.data.template.sections) return [];

        const inputs = [];
        const { sections } = this.data.template;

        for (let si = 0; si < sections.length; si++) {
            if (sections[si].definition && sections[si].definition.length > 0) {
                for (let di = 0; di < sections[si].definition.length; di++) {
                    if (sections[si].definition[di].type === 'FileViewer' && !inputs.some(i => i.id === sections[si].definition[di].id)) {
                        inputs.push(sections[si].definition[di]);
                    }
                }
            }
        }

        return inputs;
    }

    get previousExamFileViewers() {
        if (!this.previousExam || !this.previousExam.template || !this.previousExam.template.sections) return [];

        const inputs = [];
        const { sections } = this.previousExam.template;

        for (let si = 0; si < sections.length; si++) {
            if (sections[si].definition && sections[si].definition.length > 0) {
                for (let di = 0; di < sections[si].definition.length; di++) {
                    if (sections[si].definition[di].type === 'FileViewer' && !inputs.some(i => i.id === sections[si].definition[di].id)) {
                        inputs.push(sections[si].definition[di]);
                    }
                }
            }
        }

        return inputs;
    }

    get isNotFound() {
        return this.isReady && !this.data;
    }
}

decorate(ExamView, {
    appointmentId: observable,
    previousExamId: observable,
    selectedSectionId: observable,
    selectedPreviousExamSectionId: observable,
    data: observable,
    referrals: observable.deep,
    medicalReports: observable.deep,
    previousExams: observable.deep,
    pretests: observable.deep,
    prescriptions: observable.deep,
    reopenReason: observable,
    deactivateReason: observable,
    importDeviceData: observable,
    exportDeviceData: observable,
    initialize: action,
    refresh: action,
    refreshAppointment: action,
    loadPretests: action,
    loadPrescriptions: action,
    loadReferrals: action,
    loadMedicalReports: action,
    save: action,
    delete: action,
    upload: action,
    complete: action,
    reopen: action,
    clear: action,
    clearPretests: action,
    clearPrescriptions: action,
    clearReferrals: action,
    clearMedicalReports: action,
    clearReopen: action,
    previousExam: computed,
    selectedSection: computed,
    sortedSections: computed,
    sortedPreviousExamSections: computed,
    relatedPretests: computed,
    hasUnsavedChanges: observable,
    isLoading: observable,
    isSaving: observable,
    isReady: observable,
    isPretestsReady: observable,
    isPrescriptionsReady: observable,
    isReferralsReady: observable,
    isMedicalReportsReady: observable,
    requiredDiagnosticCodeServices: computed,
    hasRequiredDiagnosticCodeServices: computed,
    lockedServices: computed,
    fileViewers: computed,
    previousExamFileViewers: computed,
    isNotFound: computed,
})

export default createContext(new ExamView());