import React, { useRef, useEffect, useContext, useState } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import TimeAgo from 'react-timeago';
import { GlobalHotKeys } from 'react-hotkeys';
import { usePageVisibility } from 'react-page-visibility';
import moment from 'moment';

import { quickDrawerFocus } from './QuickDrawer';
import BodyEnd from '../_shared/BodyEnd';
import ConfirmModal from './ConfirmModal';
import TodoModal from '../todo/TodoModal/_index';

import TodoCreateStore from '../../../stores/TodoCreateStore';
import TodoInstanceSearchStore from '../../../stores/TodoInstanceSearchStore';
import NotificationSearchStore from '../../../stores/NotificationSearchStore';
import AuthStore from '../../../stores/AuthStore';
import QuickDrawerStore from '../../../stores/QuickDrawerStore';
import SettingStore from '../../../stores/SettingStore';

import useSignalR from '../../hooks/useSignalR';

import * as rts from '../../../constants/routes';

import * as fn from '../../../utilities/_functions';
import * as uh from '../../../utilities/userHelper';
import * as bh from '../../../utilities/badgeHelper';

import api from '../../../api';

const NOTIFICATION_BUTTON_ID = '__notification_button__'
const NOTIFICATION_MENU_ID = '__notification_menu__'

function NavbarNotification() {
    const isMounted = useRef(true);
    const todoDetailRef = useRef(null);
    const isPageVisible = useRef(null);
    const auth = useContext(AuthStore);
    const signalR = useSignalR();
    const quickDrawer = useContext(QuickDrawerStore);
    const setting = useContext(SettingStore);
    const focusTimer = useRef(null);
    const newTodo = useContext(TodoCreateStore);
    const searchTodo = useContext(TodoInstanceSearchStore);
    const searchNotification = useContext(NotificationSearchStore);
    const navigate = useNavigate();
    const pageVisibility = usePageVisibility();
    const [showNotificationMenu, setShowNotificationMenu] = useState(false);
    const [notificationMenuTab, setNotificationMenuTab] = useState('task');
    const [confirmComplete, setConfirmComplete] = useState(null);
    const [forceTodoReload, setForceTodoReload] = useState(false);
    const [forceNotificationReload, setForceNotificationReload] = useState(false);

    useEffect(() => {
        loadData();

        document.addEventListener('click', handleHideNotificationMenuClick);

        isPageVisible.current = pageVisibility;

        signalR.on('Todo', async (updated) => {
            if (updated && (
                (updated.userIds && updated.userIds.some(u => u === auth.currentUser.id)) ||
                (updated.createdByIds && updated.createdByIds.some(u => u === auth.currentUser.id)
                ))) {
                if (isPageVisible.current) {
                    searchTodo.refresh();
                } else if (isMounted.current) {
                    setForceTodoReload(true);
                }
            }
        });
        signalR.on('Notification', async (updated) => {
            if ((updated.id && searchNotification.items && searchNotification.items.some(n => n.id === updated.id)) ||
                (updated.userIds && updated.userIds.some(u => u === auth.currentUser.id))) {
                if (isPageVisible.current) {
                    searchNotification.refresh();
                } else if (isMounted.current) {
                    setForceNotificationReload(true);
                }
            }
        });

        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
            document.removeEventListener('click', handleHideNotificationMenuClick);
        }

    }, []) // eslint-disable-line

    useEffect(() => {
        if (pageVisibility) {
            if (forceTodoReload) {
                searchTodo.refresh()
                    .then(() => {
                        if (isMounted.current) {
                            setForceTodoReload(false);
                        }
                    })
            }
            if (forceNotificationReload) {
                searchNotification.refresh()
                    .then(() => {
                        if (isMounted.current) {
                            setForceTodoReload(false);
                        }
                    })
            }
        }
        isPageVisible.current = pageVisibility;
    }, [pageVisibility]) // eslint-disable-line

    const loadData = async () => {
        const displayToUserTypes = ['Assignee'];

        if (setting.showCreatedByYouTasks) {
            displayToUserTypes.push('Creator');
        }

        await searchNotification.initialize(auth.currentUser.id);
        await searchTodo.initialize(auth.currentUser.id, displayToUserTypes);
    }

    const handleNotificationButtonClick = () => {
        const newShowNotificationMenu = !showNotificationMenu;

        if (newShowNotificationMenu) {
            if (searchNotification.hasUnread) {
                setNotificationMenuTab('notification');
            }
            else {
                setNotificationMenuTab('task');
            }
        }

        setShowNotificationMenu(newShowNotificationMenu);
    }

    const handleHideNotificationMenuClick = (event) => {
        const notificationButton = document.querySelector(`#${NOTIFICATION_BUTTON_ID}`);
        const notificationMenu = document.querySelector(`#${NOTIFICATION_MENU_ID}`);
        const isMenuVisible = notificationMenu.offsetParent !== null;
        const isButton = event.target.id === NOTIFICATION_BUTTON_ID || notificationButton.contains(event.target);
        const isMenu = event.target.id === NOTIFICATION_MENU_ID || notificationMenu.contains(event.target);
        const hideNotificationMenu = isMenuVisible && !isButton && !isMenu;

        if (hideNotificationMenu) {
            setShowNotificationMenu(false);
        }
    }

    const handleViewTodoListClick = (e) => {
        navigate(rts.Tasks.Home);
        setShowNotificationMenu(false);
    }

    const handleAddTodoClick = (e) => {
        setShowNotificationMenu(false);
        newTodo.initialize();
        quickDrawer.activateQuickDrawer('todo', 'create', null, handleNewTodoSuccess, handleNewTodoCancel)
            .then(drawer => {
                if (isMounted.current) {
                    focusTimer.current = setTimeout(() => {
                        quickDrawerFocus(drawer);
                    }, 100);
                }
            });
    }

    const handleNewTodoSuccess = () => {
        toast.dark(() => <p data-task-ctd>Task created.</p>);
        newTodo.clear();
    }

    const handleNewTodoCancel = () => {
        newTodo.clear();
    }

    const handleViewTodoDetail = (item) => {
        todoDetailRef.current.show(item);
        setShowNotificationMenu(false);
    }

    const handleMarkCompleteClick = (event, item) => {
        setConfirmComplete(item);
    }

    const handleConfirmComplete = (e) => {
        searchTodo.status(confirmComplete.id, 'Completed');
        toast.dark(() => <p data-task-comp>Task completed.</p>);
        setConfirmComplete(null);
    }

    const handleConfirmCancel = (e) => {
        setConfirmComplete(null);
    }

    const handleShowCreatedByYouChange = (e) => {
        const toggle = !setting.showCreatedByYouTasks;
        const index = searchTodo.displayToUserTypes.findIndex(t => t === 'Creator')
        setting.showCreatedByYouTasks = toggle;

        if (setting.showCreatedByYouTasks) {
            if (index < 0) {
                searchTodo.displayToUserTypes.push('Creator');
            }
        } else {
            if (index >= 0) {
                searchTodo.displayToUserTypes.splice(index, 1);
            }
        }

        searchTodo.refresh();
    }

    const renderTaskAssignedToYou = (task) => {
        const user = uh.getById(task.createdById);
        return <div
            className='d-flex'
        >
            <span className='mr-2'>
                <span
                    className={'profile-image rounded-circle d-inline-block' + (user && !user.profilePictureUri ? ` profile-initials text-white bg-color${user.color}-500` : '')}
                    style={!user || user.profilePictureUri ? {
                        backgroundImage: `url(${(user && user.profilePictureUri ? user.profilePictureUri : '/media/img/default-genki.jpg')})`,
                        backgroundSize: 'cover',
                    } : null}
                    title={uh.getDisplayFullName(user)}
                >
                    {user && !user.profilePictureUri ? <div className='d-initials'>{user.initials}</div> : null}
                </span>
            </span>
            <div className='d-flex flex-column flex-1 ml-1'>
                <div
                    className='cursor-pointer'
                    onClick={(e) => { handleViewTodoDetail(task) }}
                >
                    <span className={'name fw-500' + (task.isHighPriority ? ' high-priority' : '')}>
                        {task.isHighPriority ? <i className='fas fa-exclamation text-danger flashing slow mr-2'></i> : null}
                        <span className='d-inline-block text-truncate text-truncate-md'>
                            {
                                task.assignedUsers && task.assignedUsers.length > 0 ?
                                    <>{setting.showCreatedByYouTasks ? <span className='text-gray-600 mr-1'>By:</span> : null}{user ? uh.getDisplayShortName(user) : 'System'}</> :
                                    <>{auth.currentUser.id === task.createdById ? 'Reminder' : null}</>
                            }
                        </span>
                        {showNotificationMenu ? bh.renderTodoDueStatus(task, 'fw-n float-right mt-nh', true) : null}</span>
                    <span className='msg-a msg-truncate fs-sm html mt-n1' title={fn.stripHtml(task.bodyHtml)} dangerouslySetInnerHTML={{ __html: task.bodyHtml }}></span>
                    {
                        task.workingOnById ?
                            <span className='fs-nano fw-500 text-success-600'>{uh.getDisplayShortNameById(task.workingOnById)} is working on it.</span> : null
                    }
                </div>
                <div className='d-flex'>
                    <TimeAgo
                        className='fs-nano text-muted mt-h'
                        date={moment.utc(task.createdDateUtc).local().toDate()}
                        title={moment.utc(task.createdDateUtc).local().format('dddd MMMM D, YYYY h:mm a')} minPeriod={60}
                    />
                    <div className='flex-1 text-right pt-o'>
                        <button
                            type='button'
                            className='btn btn-link btn-complete text-uppercase fs-nano p-0'
                            onClick={(e) => { handleMarkCompleteClick(e, task) }}
                        >Mark complete</button>
                    </div>
                </div>
            </div>
        </div>
    }

    const renderTaskCreatedByYou = (task) => {
        if (!task) return;

        return <div
            className='d-flex'
        >
            <span className='mr-2'>
                {
                    task.assignedUsers && task.assignedUsers.length === 1 ?
                        <span
                            className={'profile-image rounded-circle d-inline-block' + (task.assignedUsers[0] && !task.assignedUsers[0].profilePictureUri ? ` profile-initials text-white bg-color${task.assignedUsers[0].color}-500` : '')}
                            style={!task.assignedUsers[0] || task.assignedUsers[0].profilePictureUri ? {
                                backgroundImage: `url(${(task.assignedUsers[0] && task.assignedUsers[0].profilePictureUri ? task.assignedUsers[0].profilePictureUri : '/media/img/default-genki.jpg')})`,
                                backgroundSize: 'cover',
                            } : null}
                            title={uh.getDisplayShortNameById(task.assignedUsers[0].id)}
                        >
                            {task.assignedUsers[0] && !task.assignedUsers[0].profilePictureUri ? <div className='d-initials'>{task.assignedUsers[0].initials}</div> : null}
                        </span> : null
                }
                {
                    task.assignedUsers && task.assignedUsers.length > 1 ?
                        <span
                            className={'profile-image rounded-circle d-inline-block' + (task.assignedUsers && (!task.assignedUsers.some(u => u.profilePictureUri) || task.assignedUsers.length > 1) ? ` profile-initials d-flex bg-${(task.assignedUsers.length > 1 ? 'primary' : `color${task.assignedUsers[0].color}`)}-500 text-white fw-500` : '')}
                            style={task.assignedUsers && task.assignedUsers.length === 1 && task.assignedUsers[0].profilePictureUri ? {
                                backgroundImage: task.assignedUsers[0].profilePictureUri,
                                backgroundSize: 'cover',
                            } : null}
                            title={task.assignedUsers.map(u => { return uh.getDisplayShortNameById(u.id) }).join(', ')}
                        >
                            {
                                task.assignedUsers && (!task.assignedUsers.some(u => u.profilePictureUri) || task.assignedUsers.length > 1) ?
                                    <div className='d-initials'>{task.assignedUsers && task.assignedUsers.length === 1 ? task.assignedUsers[0].initials : task.assignedUsers.length}</div> : null
                            }
                        </span> : null
                }
            </span>
            <div className='d-flex flex-column flex-1 ml-1'>
                <div
                    className='cursor-pointer'
                    onClick={(e) => { handleViewTodoDetail(task) }}
                >
                    <span className={'name fw-500' + (task.isHighPriority ? ' high-priority' : '')}>
                        {task.isHighPriority ? <i className='fas fa-exclamation text-danger flashing slow mr-2'></i> : null}
                        <span className='d-inline-block text-truncate text-truncate-md'><span className='text-gray-600 mr-1'>To:</span>{task.assignedUsers.map(u => { return uh.getDisplayShortNameById(u.id) }).join(', ')}</span>
                        {showNotificationMenu ? bh.renderTodoDueStatus(task, 'fw-n float-right mt-nh', true) : null}
                    </span>
                    <span className='msg-a msg-truncate fs-sm html mt-n1' title={fn.stripHtml(task.bodyHtml)} dangerouslySetInnerHTML={{ __html: task.bodyHtml }}></span>
                    {
                        task.workingOnById ?
                            <span className='fs-nano fw-500 text-success-600'>{uh.getDisplayShortNameById(task.workingOnById)} is working on it.</span> : null
                    }
                </div>
                <div className='d-flex'>
                    <span className='fs-nano text-muted mt-h'>
                        You created this <TimeAgo
                            className='tt-underline'
                            date={moment.utc(task.createdDateUtc).local().toDate()}
                            title={moment.utc(task.createdDateUtc).local().format('dddd MMMM D, YYYY h:mm a')} minPeriod={60}
                        />
                    </span>
                </div>
            </div>
        </div >
    }

    const renderNotificationItem = (notificationItem) => {
        const createdBy = uh.getById(notificationItem.createdById);

        return <div
            className={'d-flex notification-body'}
        >
            <span className='mr-2'>
                <span
                    className={'profile-image rounded-circle d-inline-block' + (createdBy && !createdBy.profilePictureUri ? ` profile-initials text-white bg-color${createdBy.color}-500` : '')}
                    style={!createdBy || createdBy.profilePictureUri ? {
                        backgroundImage: `url(${(createdBy && createdBy.profilePictureUri ? createdBy.profilePictureUri : '/media/img/default-genki.jpg')})`,
                        backgroundSize: 'cover',
                    } : null}
                    title={uh.getDisplayFullName(createdBy)}
                >
                    {createdBy && !createdBy.profilePictureUri ? <div className='d-initials'>{createdBy.initials}</div> : null}
                </span>
            </span>
            <div className='d-flex flex-column flex-1 ml-1'>
                <div
                    className='cursor-pointer'
                    onClick={(e) => { handleNotificationClickBehavior(e, notificationItem) }}
                >
                    <span className={'name fw-500' + (notificationItem.isHighPriority ? ' high-priority' : '')}>
                        {notificationItem.isHighPriority ? <i className='fas fa-exclamation text-danger flashing slow mr-2'></i> : null}
                        <span className='d-inline-block text-truncate text-truncate-md'>{getNotificationTitle(notificationItem)}</span>
                        {
                            !notificationItem.isRead ?
                                <span className={'badge bg-success text-white text-shadow-0 float-right mt-nh'}><strong>NEW</strong></span> : null
                        }
                    </span>
                    <span className={'msg-a fs-sm html mt-n1' + (!notificationItem.isRead ? ' fw-500' : '') + (getNotificationDescriptionCss(notificationItem))} title={fn.stripHtml(notificationItem.descriptionHtml)} dangerouslySetInnerHTML={{ __html: notificationItem.descriptionHtml }}></span>
                </div>
                <div className='d-flex'>
                    <TimeAgo
                        className='fs-nano text-muted mt-h'
                        date={moment.utc(notificationItem.createdDateUtc).local().toDate()}
                        title={moment.utc(notificationItem.createdDateUtc).local().format('dddd MMMM D, YYYY h:mm a')} minPeriod={60}
                    />
                    <div className='flex-1 text-right pt-o'>
                        {renderNotificationAction(notificationItem)}
                        {
                            !!notificationItem.canOverrideExpiryDate ?
                                <button
                                    type='button'
                                    className='btn btn-link btn-complete text-uppercase fs-nano p-0 ml-2'
                                    onClick={(e) => { handleNotificationHideClick(e, notificationItem) }}
                                >Hide</button> : null
                        }
                    </div>
                </div>
            </div>
        </div>
    }

    const getNotificationTitle = (notificationItem) => {
        if (!notificationItem) return;

        switch (notificationItem.reference) {
            case 'TodoCreated':
                return `New Task: ${uh.getDisplayShortNameById(notificationItem.createdById)}`;

            case 'TodoUpdated':
                return `Task Updated: ${uh.getDisplayShortNameById(notificationItem.createdById)}`;

            case 'TodoCompleted':
                return `Task Completed: ${uh.getDisplayShortNameById(notificationItem.createdById)}`;

            case 'TodoCanceled':
                return `Task Canceled: ${uh.getDisplayShortNameById(notificationItem.createdById)}`;

            case 'EmailFailed':
                return `Email not sent`;

            case 'FaxFailed':
                return `Fax not sent`;

            case 'SmsFailed':
                return `SMS not sent`;

            case 'HealthCardCheckFailed':
                return `Health Card not verified`;

            case 'PublicInsurancePaymentFailed':
                return `Payment failed`;

            case 'System':
                return notificationItem.title ? notificationItem.title : 'System message';

            default:
                return notificationItem.title;
        }
    }

    const handleNotificationClickBehavior = (event, notificationItem) => {
        if (!notificationItem) return;

        switch (notificationItem.reference) {
            case 'TodoCreated':
            case 'TodoUpdated':
                openTodoDetailById(notificationItem.referenceId)
                break;

            case 'TodoCompleted':
            case 'TodoCanceled':
                break;

            case 'EmailFailed':
                return `Email not sent`;

            case 'FaxFailed':
                return `Fax not sent`;

            case 'SmsFailed':
                return `SMS not sent`;

            case 'HealthCardCheckFailed':
                return `Health Card not verified`;

            case 'PublicInsurancePaymentFailed':
                return `Payment failed`;

            case 'System':
                return notificationItem.title ? notificationItem.title : 'System message';

            default:
                break;
        }

        if (!notificationItem.isRead) {
            searchNotification.read(notificationItem.id);
        }
    }

    const handleNotificationHideClick = (event, notificationItem) => {
        searchNotification.expire(notificationItem.id);
    }

    const renderNotificationAction = (notificationItem) => {
        if (!notificationItem) return;

        switch (notificationItem.reference) {
            case 'TodoCreated':
            case 'TodoUpdated':
            case 'TodoCompleted':
                return <button
                    type='button'
                    className='btn btn-link btn-complete text-uppercase fs-nano p-0'
                    onClick={(e) => { openTodoDetailById(notificationItem.referenceId) }}
                >Open Task</button>

            case 'TodoCanceled':
            case 'EmailFailed':
            case 'FaxFailed':
            case 'SmsFailed':
            case 'HealthCardCheckFailed':
            case 'PublicInsurancePaymentFailed':
            case 'System':
                break;

            default:
                break;
        }
    }

    const getNotificationDescriptionCss = (notificationItem) => {
        if (!notificationItem) return '';

        switch (notificationItem.reference) {
            case 'TodoCanceled':
                return ' text-gray-500 text-strike';

            case 'TodoCreated':
            case 'TodoUpdated':
            case 'TodoCompleted':
            case 'EmailFailed':
            case 'FaxFailed':
            case 'SmsFailed':
            case 'HealthCardCheckFailed':
            case 'PublicInsurancePaymentFailed':
            case 'System':
                return '';

            default:
                return '';
        }

    }

    const openTodoDetailById = (id) => {
        api.TodoInstances.get(id)
            .then(({ data }) => {
                handleViewTodoDetail(data);
            })
    }

    return useObserver(() => <>
        {
            !!showNotificationMenu ?
                <GlobalHotKeys
                    keyMap={{
                        close: ['esc'],
                    }}
                    handlers={{
                        close: () => {
                            setShowNotificationMenu(false)
                        },
                    }}
                    allowChanges={true}
                /> : null
        }
        <div className={'d-flex align-items-center justify-content-center ml-2 pt-h' + (showNotificationMenu ? ' show' : '')}>
            <button
                id={NOTIFICATION_BUTTON_ID}
                type='button'
                className='btn btn-icon header-icon'
                data-toggle="dropdown"
                title='Tasks & Notifications'
                onClick={handleNotificationButtonClick}
            >
                <i className='fal fa-bell'></i>
                {
                    searchNotification.hasUnread ?
                        <>
                            {
                                searchNotification.hasHighPriority ?
                                    <span className='badge badge-solid-success text-shadow-0 badge-dot float-right flashing mr-1 ml-n2'></span> :
                                    <i className='fas fa-exclamation text-danger fs-b float-right flashing slow mr-1 ml-n2'></i>
                            }
                        </> :
                        <>
                            {
                                searchTodo.total && searchTodo.total > 0 ?
                                    <span className='badge badge-icon bg-warning text-shadow-0 float-right mr-1 ml-n2'>{searchTodo.total}</span> : null
                            }
                        </>
                }
            </button>
            <div id={NOTIFICATION_MENU_ID} className={'notification-dropdown-menu dropdown-menu dropdown-xl' + (showNotificationMenu ? ' mt-h show' : '')}>
                <ul className='nav nav-tabs nav-tabs-clean w-100' role='tablist'>
                    <li className='nav-item flex-1'>
                        <div className={'nav-link fs-md fw-500' + (notificationMenuTab === 'task' ? ' active' : '')}>
                            <button
                                type='button'
                                className='btn btn-link pt-2 px-4 w-100'
                                onClick={() => { setNotificationMenuTab('task') }}
                            >
                                Tasks
                                {
                                    searchTodo.total && searchTodo.total > 0 ?
                                        <small className='ml-1'>({searchTodo.total})</small> : null
                                }
                            </button>
                        </div>
                    </li>
                    <li className='nav-item flex-1'>
                        <div className={'nav-link fs-md fw-500' + (notificationMenuTab === 'notification' ? ' active' : '')}>
                            <button
                                type='button'
                                className='btn btn-link pt-2 px-4 w-100'
                                onClick={() => { setNotificationMenuTab('notification') }}
                            >
                                Notifications
                                {
                                    searchNotification.total && searchNotification.total > 0 ?
                                        <small className='ml-1'>({searchNotification.total})</small> : null
                                }
                            </button>
                        </div>
                    </li>
                </ul>
                <div className='tab-content tab-notification'>
                    <div className={'tab-pane' + (notificationMenuTab === 'task' ? ' active' : '')}>
                        {
                            searchTodo.isReady && searchTodo.items && searchTodo.items.length > 0 ?
                                <ul className='notification h-100'>
                                    {
                                        searchTodo.items.map((t, ti) => {
                                            return t.displayToUserType === 'Assignee' ?
                                                <li
                                                    key={`task_pending_${ti}`}
                                                    className='notification-item'
                                                >
                                                    {renderTaskAssignedToYou(t)}
                                                </li> :
                                                <li
                                                    key={`task_pending_${ti}`}
                                                    className='notification-item'
                                                >
                                                    {renderTaskCreatedByYou(t)}
                                                </li>
                                        })
                                    }
                                </ul> : null
                        }
                    </div>
                    <div className={'tab-pane' + (notificationMenuTab === 'notification' ? ' active' : '')}>
                        {
                            searchNotification.isReady && searchNotification.items && searchNotification.items.length > 0 ?
                                <ul className='notification h-100'>
                                    {
                                        searchNotification.items.map((n, ni) => {
                                            return <li
                                                key={`notification_${ni}`}
                                                className={'notification-item' + (!n.isRead ? ' unread' : '')}
                                            >
                                                {renderNotificationItem(n)}
                                            </li>
                                        })
                                    }
                                </ul> : null
                        }
                    </div>
                </div>
                <div className='notification-footer py-2 px-3 bg-faded d-block rounded-bottom border-faded border-bottom-0 border-right-0 border-left-0'>
                    {
                        notificationMenuTab === 'task' ?
                            <div className='d-flex'>
                                <div>
                                    <button
                                        type='button'
                                        className='btn btn-link p-0 text-uppercase'
                                        onClick={handleAddTodoClick}
                                    >
                                        <small className='fw-500'>Add new</small>
                                    </button>
                                    <span className='text-gray-500 mx-2'>|</span>
                                    <button type='button' className='btn btn-link p-0 text-uppercase' onClick={handleViewTodoListClick}>
                                        <small className='fw-500'>See all</small>
                                    </button>
                                    <a href={rts.Tasks.Home} target='_blank' className='ml-1' rel='noopener noreferrer'><small><i className='fal fa-external-link'></i></small></a>
                                </div>
                                <div className='flex-1 text-right pt-o'>
                                    <div className='custom-control custom-switch'>
                                        <input
                                            id='nav-tasks-created-by-you'
                                            type='checkbox'
                                            name='nav-tasks-created-by-you'
                                            className='custom-control-input'
                                            checked={setting.showCreatedByYouTasks}
                                            onChange={handleShowCreatedByYouChange}
                                        />
                                        <label
                                            htmlFor='nav-tasks-created-by-you'
                                            className='custom-control-label'
                                        >
                                            <small>Tasks created by you</small>
                                        </label>
                                    </div>
                                </div>
                            </div> : null
                    }
                </div>
            </div>
        </div>
        <BodyEnd>
            <TodoModal ref={todoDetailRef} />
            <ConfirmModal
                icon={<i className='fal fa-tasks text-success mr-2'></i>}
                message={<>Mark this task as&nbsp;<span className='fw-500'>"Completed"</span>?</>}
                show={confirmComplete}
                option1ClassName={'btn btn-success shadow-0 bootbox-accept'}
                onOption1Click={handleConfirmComplete}
                onCancel={handleConfirmCancel}
            />
        </BodyEnd>
    </>)
}

export default NavbarNotification;