import { createContext } from 'react';
import { decorate, observable, action, computed, toJS } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class ProductCreate {
    data = {
        id: null,
        typeId: null,
        brandId: null,
        quantity: 1,
        isStocked: false,
        isTracked: false,
        name: null,
        descriptor: null,
        skuNumber: null,
        serialNumbers: new Array(1),
        cost: null,
        price: null,
        referenceNumber: null,
        note: null,
        noteHtml: null,
        data: [],
    };
    types = [];
    options = [];
    hasUnsavedChanges = false;
    isReady = false;
    isSaving = false;
    saveToServer = true;

    cancelProductCreate = null;
    cancelProductOptions = null;
    cancelProductTypes = null;

    initialize = (typeId, brandId, saveToServer) => {
        const that = this;
        this.clear();

        this.data.typeId = typeId;
        this.data.brandId = brandId;

        if (!fn.isNullOrUndefined(saveToServer)) {
            this.saveToServer = saveToServer;
        }

        return new Promise((resolve, reject) => {
            Promise.all([
                api.ProductTypes.all((c) => { that.cancelProductTypes = c }),
                api.ProductOptions.all((c) => { that.cancelProductOptions = c }),
            ])
                .then(response => {
                    that.types = response[0].data;
                    that.options = response[1].data;
                    that.updateType(typeId);
                    that.data.defaultApplyTax = this.selectedType.defaultApplyTax;
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isReady = true;
                })
        })
    }

    updateType = (id) => {
        this.data.typeId = id;

        if (this.selectedType) {
            if (this.selectedType.inventory === 'Physical') {
                this.data.isStocked = true;
                this.data.isTracked = true;
                this.data.quantity = 0;
            } else {
                this.data.isStocked = false;
                this.data.isTracked = false;
                this.data.quantity = null;
            }
        }

        this.hasUnsavedChanges = true;
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            let option = toJS(that.data);

            option.cost = fn.parseCurrency(option.cost);
            option.price = fn.parseCurrency(option.price);
            
            if (!option.isTracked) {
                option.referenceNumber = null;
                option.note = null;
                option.noteHtml = null;
            }

            if (that.hasUnsavedChanges) {
                if (that.saveToServer) {
                    api.Products.create(option, (c) => { that.cancelProductCreate = c })
                        .then(({ data }) => {
                            that.hasUnsavedChanges = false;
                            that.data.id = data.id;
                            resolve(that.data);
                        })
                        .catch(() => {
                            reject();
                        })
                        .finally(() => {
                            that.isSaving = false;
                        })
                } else {
                    resolve(option);
                }
            } else {
                that.hasUnsavedChanges = false;
                that.isSaving = false;
                resolve(option);
            }
        })
    }

    clear = () => {
        this.data.id = null;
        this.data.typeId = null;
        this.data.brandId = null;
        this.data.isStocked = false;
        this.data.isTracked = false;
        this.data.quantity = 1;
        this.data.name = null;
        this.data.descriptor = null;
        this.data.skuNumber = null;
        this.data.serialNumbers = new Array(1);
        this.data.cost = null;
        this.data.price = null;
        this.data.referenceNumber = null;
        this.data.note = null;
        this.data.noteHtml = null;
        this.data.data.clear();
        this.types.clear();
        this.options.clear();
        this.hasUnsavedChanges = false;
        this.isReady = false;
        this.isSaving = false;
        this.saveToServer = true;

        if (fn.isFunction(this.cancelProductCreate)) {
            this.cancelProductCreate();
            this.cancelProductCreate = null;
        }

        if (fn.isFunction(this.cancelProductOptions)) {
            this.cancelProductOptions();
            this.cancelProductOptions = null;
        }

        if (fn.isFunction(this.cancelProductTypes)) {
            this.cancelProductTypes();
            this.cancelProductTypes = null;
        }
    }

    get selectedType() {
        if (!this.data.typeId || !this.types || this.types.length === 0) return null;
        return this.types.filter(t => t.id === this.data.typeId)[0];
    }

    get brands() {
        if (!this.data.typeId || !this.types || this.types.length === 0) return [];
        return this.types.filter(t => t.id === this.data.typeId)[0].brands;
    }
}

decorate(ProductCreate, {
    data: observable.deep,
    types: observable,
    options: observable,
    hasUnsavedChanges: observable,
    isReady: observable,
    isSaving: observable,
    saveToServer: observable,
    initialize: action,
    updateType: action,
    save: action,
    clear: action,
    selectedType: computed,
    brands: computed,
})

export default createContext(new ProductCreate());