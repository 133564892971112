import React, { Fragment, useEffect, useContext, useRef, useState } from 'react';
import { Observer } from 'mobx-react-lite';
import { Toolbar, Item } from 'devextreme-react/html-editor';
import { Multiselect } from 'react-widgets'
import { ScrollView } from 'devextreme-react';
import uuid from 'react-uuid';

import LoadingOverlay from '../../_shared/LoadingOverlay';
import HtmlEditor from '../../_shared/HtmlEditor';

import CommunicationStore from '../../../../stores/CommunicationStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as ah from '../../../../utilities/addressHelper';

import api from '../../../../api';

function EmailCommunication(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const searchTimer = useRef(null);
    const modalBodyRef = useRef(null);
    const messageRef = useRef(null);
    const validateRef = useRef(null);
    const requireRerender = useRef(false);
    const updateScrollRef = useRef(null);
    const bodyHtmlRef = useRef(null);
    const cancelSearch = useRef(null);
    const communication = useContext(CommunicationStore);

    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState(null);
    const [contacts, setContacts] = useState([]);
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [mode, setMode] = useState('Update');
    const [previewDevice, setPreviewDevice] = useState('Desktop');
    const [subject, setSubject] = useState(null);
    const [previewText, setPreviewText] = useState(null);
    const [isRerendering, setIsRerendering] = useState(false);
    const [isMessageExpanded, setIsMessageExpanded] = useState(false);

    useEffect(() => {
        if (props.defaultValues) {
            if (props.defaultValues.subject) {
                setSubject(props.defaultValues.subject);
            }
        }

        firstElementFocus();

        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
            if (searchTimer.current) { clearTimeout(searchTimer.current); searchTimer.current = null; }
            if (cancelSearch.current && fn.isFunction(cancelSearch.current)) { cancelSearch.current(); cancelSearch.current = null; }
            communication.clear();
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        setDefaultContacts();

        if (communication.isReady && communication.templates && communication.templates.length > 0) {
            setMode('Preview');

            if (communication.selectedTemplate) {
                setSubject(communication.selectedTemplate.subject);
                setPreviewText(communication.selectedTemplate.preview);
                messageRef.current.setHtml(communication.selectedTemplate.body, true);
            }
        }
    }, [communication.isReady]) // eslint-disable-line

    const setDefaultContacts = () => {
        if (communication.defaultContact && communication.defaultContact.email) {
            const { defaultContact } = communication;
            const selectedContact = {
                id: uuid(),
                name: defaultContact.name ? defaultContact.name : 'New',
                value: defaultContact.email ? defaultContact.email : null,
                isNew: false,
            };
            const newSelectedContacts = [selectedContact];

            setSelectedContacts(newSelectedContacts);
        }
    }

    const firstElementFocus = () => {
        const found = modalBodyRef && modalBodyRef.current ? modalBodyRef.current.querySelectorAll('input[type="text"]:not([disabled])') : null;

        if (found && found.length > 0) {
            const notEmpty = Array.from(found).filter(el => !el.value);

            focusTimer.current = setTimeout(() => {
                if (notEmpty && notEmpty.length > 0) {
                    notEmpty[0].focus();
                } else {
                    found[0].focus();
                }
            }, 100);
        }
    }

    const handleTemplateChange = event => {
        const selectedTemplateId = event.target.value;

        communication.updateSelectedTemplateId(selectedTemplateId)
            .then(() => {
                if (isMounted.current) {
                    if (communication.selectedTemplate) {
                        setSubject(communication.selectedTemplate.subject);
                        setPreviewText(communication.selectedTemplate.preview);
                        messageRef.current.setHtml(communication.selectedTemplate.body, true);
                    }
                    else {
                        setSubject(null);
                        setPreviewText(null);
                        messageRef.current.setHtml(null, true);
                    }

                    requireRerender.current = true;
                    communication.hasUnsavedChanges = true;
                }
            })
    }

    const handleEmailToSearch = (searchTerm, metadata) => {
        setSearchTerm(searchTerm);
        setContacts([]);

        if (searchTimer.current) {
            clearTimeout(searchTimer.current);
            searchTimer.current = null;
        }

        if (searchTerm && searchTerm.length >= 2) {
            searchTimer.current = setTimeout(() => {
                setIsLoading(true);

                if (fn.isFunction(cancelSearch.current)) { cancelSearch.current(); cancelSearch.current = null; }

                api.AddressBookLookUp.fullSearch(searchTerm, null, 'Email', communication.customerId, 10, 0, false, true, false, (c) => { cancelSearch.current = c })
                    .then(({ data }) => {
                        if (isMounted.current) {
                            let filteredResult = data.result && data.result.length > 0 ? data.result.filter(r => r.contactType === 'Email') : [];

                            if (filteredResult && filteredResult.length > 0 && fn.regexTester.email.test(searchTerm)) {
                                filteredResult = filteredResult.filter(r => r.value.toLowerCase() === searchTerm.toLowerCase());
                            }

                            setContacts((filteredResult && filteredResult.length > 0 ? filteredResult : []));
                        }
                    })
                    .finally(() => {
                        setIsLoading(false);
                    })
            }, 500);
        } else {
            if (isMounted.current) {
                setContacts(contacts);
            }
        }
    }

    const handleEmailToCreate = (searchTerm) => {
        if (fn.regexTester.email.test(searchTerm)) {
            const email = searchTerm.toLowerCase();

            if (!selectedContacts.some(c => c.value === email)) {
                const newContact = {
                    id: uuid(),
                    name: 'New',
                    value: email,
                    isNew: true,
                };
                const newSelectedContacts = [...selectedContacts, newContact];

                setSelectedContacts(newSelectedContacts);

                if (searchTimer.current) {
                    clearTimeout(searchTimer.current);
                    searchTimer.current = null;
                }
            }
        }
    }

    const handleEmailToChange = (dataItems, metadata) => {
        setSelectedContacts(dataItems);
    }

    const handleSuggestionAdd = (event, suggestion) => {
        const selectedContact = {
            id: uuid(),
            name: suggestion.name ? suggestion.name : 'New',
            value: suggestion.email ? suggestion.email : null,
            isNew: false,
        };
        const newSelectedContacts = [...selectedContacts, selectedContact];

        setSelectedContacts(newSelectedContacts);
    }

    const handleSubjectChange = (event) => {
        const subject = event.target.value;
        setSubject(subject);
        requireRerender.current = true;
        communication.hasUnsavedChanges = true;
    }

    const handlePreviewChange = (event) => {
        const preview = event.target.value;
        setPreviewText(preview);
        requireRerender.current = true;
        communication.hasUnsavedChanges = true;
    }

    const handleMessageChange = (e) => {
        const newBodyHtml = e.value;
        bodyHtmlRef.current = newBodyHtml;
        requireRerender.current = true;
        communication.hasUnsavedChanges = true;
    }

    const handleMessageExpandToggle = () => {
        const newValue = !isMessageExpanded;
        setIsMessageExpanded(newValue);

        setTimeout(() => {
            const scroller = updateScrollRef.current.instance;
            scroller.scrollTo(999999);
        }, 300);
    }

    const handleClose = event => {
        if (fn.isFunction(props.onClose)) {
            if (communication.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    communication.clear();
                    communication.hasUnsavedChanges = false;
                    props.onClose(event);
                }
            } else {
                props.onClose(event);
            }
        }
    }

    const handleModeToggle = () => {
        switch (mode) {
            case 'Preview':
                setMode('Update');
                break;

            case 'Update':
                setMode('Preview');
                if (requireRerender.current) {
                    if (communication.selectedTemplate) {
                        setIsRerendering(true);
                        api.CommunicationTemplates.fill(communication.selectedTemplateId, {
                            referenceIds: [communication.reference1Id],
                            subject: subject,
                            preview: previewText,
                            body: bodyHtmlRef.current
                        })
                            .then(({ data }) => {
                                communication.renderedContent = data.renderedTemplate;
                            })
                            .finally(() => {
                                setIsRerendering(false);
                            })
                    }
                    else {
                        communication.renderedContent = bodyHtmlRef.current;
                    }
                }
                requireRerender.current = false;
                break;

            default:
                break;
        }
    }

    const handleSend = event => {
        if (fn.validateForm(validateRef.current)) {
            const option = {
                type: 'Email',
                secured: props.secured,
                to: selectedContacts,
                emailOption: {
                    subject: subject,
                    preview: previewText,
                    bodyHtml: bodyHtmlRef.current,
                }
            };
            communication.send(option, true)
                .then((data) => {
                    if (fn.isFunction(props.onSent)) {
                        option.response = data;
                        props.onSent(event, option);
                    }
                })
                .finally(() => {
                    if (fn.isFunction(props.onClose)) {
                        props.onClose(event);
                    }
                })
        }
    }

    const renderTitle = () => {
        return <div className='popup-title'>
            <div className='actions left-actions pt-1'>
                <ul className='ml-2'>
                    <li>
                        <h4 className='mb-0'>
                            {
                                props.secured === true ?
                                    <>Sending <strong className='text-danger'>Secure Email</strong>...</> :
                                    <>Sending {(communication.selectedTemplate ? communication.selectedTemplate.name.toLowerCase() : 'email')} ...</>
                            }
                        </h4>
                    </li>
                </ul>
            </div>
            <div className='actions right-actions'>
                <ul>
                    <li>
                        {renderModeButton()}
                    </li>
                    <li>
                        <button
                            data-send-email
                            type='button'
                            className='btn btn-success px-3'
                            disabled={communication.isSaving}
                            onClick={handleSend}
                        >
                            Send
                        </button>
                    </li>
                    <li>
                        <button
                            type='button'
                            className='btn btn-icon btn-close'
                            onClick={handleClose}
                        >
                            <i className='close-icon fal fa-times fs-xl'></i>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    }

    const renderEmailRecipientMultiSelect = () => {
        return <Observer>{() => <>
            <Multiselect
                allowCreate={true}
                busy={isLoading}
                data={contacts}
                valueField='id'
                textField='name'
                filter={false}
                disabled={!props.emailToEnabled}
                itemComponent={({ item }) => (
                    <>
                        <div
                            className='w-100 p-2'
                        >
                            <div className='d-flex flex-row'>
                                <div className='d-flex flex-column justify-content-center pr-2'>
                                    <span
                                        className={`profile-image profile-image-md profile-initials rounded-circle d-flex m-auto bg-gray-700 fw-500`}
                                        title={(item.name ? item.name : item.companyName)}
                                    >
                                        <span className='fs-80'>{getInitials(item.name ? item.name : item.companyName)}</span>
                                    </span>
                                </div>
                                <div className='d-flex flex-1 flex-column justify-content-center text-dark external-appointment-detail'>
                                    <div
                                        className='fs-lg fw-500 line-height-1 pt-1'
                                    >
                                        <small className={'d-block text-gray-700 mb-o'}>
                                            {
                                                item.name ?
                                                    <span className='fw-500'>
                                                        {item.name}
                                                        {
                                                            item.companyName ?
                                                                <small className='ml-1'>({item.companyName})</small> : null
                                                        }
                                                    </span> :
                                                    <span className='fw-500'>{item.companyName}</span>
                                            }
                                        </small>
                                        {
                                            item.address && item.address.country ?
                                                <small className='d-block text-gray-600 address-sameline mb-o'>{ah.getAddressHtml(item.address)}</small> : null
                                        }
                                        <small className='d-block text-info fw-500'>Email: {item.value}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                tagComponent={({ item }) => (
                    <span className={'tag ' + (item.isNew ? 'bg-gray-800' : 'bg-primary-700')}>
                        <strong>{item.name ? item.name : item.companyName} - {item.value}</strong>
                    </span>
                )}
                messages={{
                    createOption: () => (
                        searchTerm && fn.regexTester.email.test(searchTerm) ?
                            <>Send email to <strong>{searchTerm}</strong></> :
                            <>Search by <strong>name</strong> or <strong>email address</strong></>
                    ),
                    emptyList: '',
                    emptyFilter: ''
                }}
                value={selectedContacts}
                onCreate={handleEmailToCreate}
                onSearch={handleEmailToSearch}
                onChange={handleEmailToChange}
            />
            {renderSuggestions()}
        </>}</Observer>
    }

    const renderEmailFiles = () => {
        return <>
            <div className='form-group mb-4'>
                <label><small>File(s)</small></label>
                <div className='files'>
                    {
                        communication.files && communication.files.length > 0 ?
                            <>
                                {
                                    communication.files.map((f, fi) => {
                                        return <span
                                            key={`email_file_${fi}`}
                                            className='file-icon file-icon-text text-truncate text-truncate-sm'
                                            style={{
                                                color: fn.toFileColor(f.fileName),
                                                borderColor: fn.toFileColor(f.fileName),
                                            }}
                                            title={f.fileName}
                                        >
                                            {fn.toFileIcon(f.fileName)} {f.fileName}
                                        </span>
                                    })
                                }
                            </> : null
                    }
                    {
                        communication.selectedTemplate && communication.selectedTemplate.attachments && communication.selectedTemplate.attachments.length > 0 ?
                            <>
                                {
                                    communication.selectedTemplate.attachments.map((a, ai) => {
                                        return <span
                                            key={`email_template_attachment_${ai}`}
                                            className='file-icon file-icon-text text-truncate text-truncate-sm'
                                            style={{
                                                color: fn.toFileColor(a),
                                                borderColor: fn.toFileColor(a),
                                            }}
                                            title={a}
                                        >
                                            {fn.toFileIcon(a)} {a}
                                        </span>
                                    })
                                }
                            </> : null
                    }
                    {
                        (!communication.files || communication.files.length === 0) &&
                            (!communication.selectedTemplate || !communication.selectedTemplate.attachments || communication.selectedTemplate.attachments.length === 0) ?
                            <span className='no-file-text'>No attachment</span> : null
                    }
                </div>
            </div>
        </>
    }

    const renderContent = () => {
        return <>
            <div className='popup-body'>
                <form ref={validateRef}>
                    <fieldset disabled={communication.isSaving}>
                        {
                            props.secured === true ?
                                <section className='pt-4 px-4'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <p className='fw-500 text-danger'><i className='fas fa-lock mr-2'></i> This email will be protected by <a href='https://www.paubox.com' target='_blank' rel='noopener noreferrer'>Paubox</a> (HIPAA compliant provider).</p>
                                        </div>
                                    </div>
                                </section> : null
                        }
                        <section className={'relative ' + (props.secured === true ? 'pb-4 px-4' : 'p-4') + (mode === 'Preview' ? '' : ' d-none')} height={'75vh'}>
                            <LoadingOverlay isLoading={isRerendering} />
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='form-group mb-4 validate validate-required'>
                                        <label htmlFor='email-send-to' className='required'><small>To</small></label>
                                        {renderEmailRecipientMultiSelect()}
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-10'>
                                    {renderEmailFiles()}
                                </div>
                                <div className='col-2'>
                                    <div className='form-group'>
                                        <div className='text-right pt-2'>
                                            <button
                                                type='button'
                                                className='btn btn-icon mx-o'
                                                onClick={() => { setPreviewDevice('Desktop') }}
                                            >
                                                <i className={'fas fa-desktop ' + (previewDevice === 'Desktop' ? 'text-primary' : 'text-gray-500')}></i>
                                            </button>
                                            <button
                                                type='button'
                                                className='btn btn-icon mx-o'
                                                onClick={() => { setPreviewDevice('Mobile') }}
                                            >
                                                <i className={'fas fa-mobile-alt ' + (previewDevice === 'Mobile' ? 'text-primary' : 'text-gray-500')}></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='form-group'>
                                        <iframe
                                            title={communication.selectedTemplate ? communication.selectedTemplate.name : ''}
                                            className={`preview-frame preview-${previewDevice.toLowerCase()}`}
                                            srcDoc={fn.replaceAHref(communication.renderedContent)}
                                        >
                                        </iframe>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div className={'h-100' + (mode === 'Update' ? '' : ' d-none')}>
                            <ScrollView ref={updateScrollRef} height={'75vh'}>
                                <section className={(props.secured === true ? 'pb-4 px-4' : 'p-4')}>
                                    {
                                        !isMessageExpanded ? <>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4 validate validate-required'>
                                                        <label htmlFor='email-send-to' className='required'><small>To</small></label>
                                                        {renderEmailRecipientMultiSelect()}
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                communication.templates && communication.templates.length > 0 ?
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-4'>
                                                                <label htmlFor='email-template'><small>Template</small></label>
                                                                <select
                                                                    id='email-template'
                                                                    className='custom-select form-control'
                                                                    value={communication.selectedTemplate ? communication.selectedTemplate.id : ''}
                                                                    onChange={handleTemplateChange}
                                                                >
                                                                    <option value=''>(Write your own email)</option>
                                                                    {
                                                                        communication.templates ?
                                                                            communication.templates.map((t, ti) => {
                                                                                return <option
                                                                                    key={`email_template_${ti}`}
                                                                                    value={t.id}
                                                                                >{t.name}</option>
                                                                            }) : null
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div> : null
                                            }
                                            <div className='row'>
                                                <div className='col-12'>
                                                    {renderEmailFiles()}
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4 validate validate-required'>
                                                        <label className='required' htmlFor='email-subject'><small>Subject</small></label>
                                                        <input
                                                            id='email-subject'
                                                            type='text'
                                                            className='form-control'
                                                            maxLength='255'
                                                            autoComplete='off'
                                                            value={subject ? subject : ''}
                                                            onChange={handleSubjectChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label htmlFor='email-preview'><small>Preview text</small></label>
                                                        <input
                                                            id='email-preview'
                                                            type='text'
                                                            className='form-control'
                                                            maxLength='255'
                                                            autoComplete='off'
                                                            value={previewText ? previewText : ''}
                                                            onChange={handlePreviewChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </> : null
                                    }
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='form-group mb-2 email-message validate validate-required'>
                                                <label className='required' htmlFor='email-message'><small>Message</small></label>
                                                <div className='float-right'>
                                                    <button
                                                        type='button'
                                                        className='btn btn-icon line-height-1'
                                                        onClick={handleMessageExpandToggle}
                                                    >
                                                        <i className={'fal ' + (isMessageExpanded ? 'fa-compress-alt' : 'fa-expand-alt')}></i>
                                                    </button>
                                                </div>
                                                <HtmlEditor
                                                    ref={messageRef}
                                                    height={isMessageExpanded ? '65vh' : '220px'}
                                                    disableTab={true}
                                                    onValueChanged={handleMessageChange}
                                                >
                                                    <Toolbar>
                                                        <Item name='bold' />
                                                        <Item name='italic' />
                                                        <Item name='underline' />
                                                        <Item name='separator' />
                                                        <Item name='alignLeft' />
                                                        <Item name='alignCenter' />
                                                        <Item name='alignRight' />
                                                        <Item name='separator' />
                                                        <Item name='size' acceptedValues={['8px', '9px', '10px', '11px', '12px', '13px', '14px', '16px', '18px', '20px', '21px', '22px', '24px', '26px', '28px', '30px', '32px', '36px', '40px', '44px', '48px', '54px', '60px']} />
                                                        <Item name='separator' />
                                                        <Item name='orderedList' />
                                                        <Item name='bulletList' />
                                                        <Item name='separator' />
                                                        <Item name='color' />
                                                        <Item name='background' />
                                                    </Toolbar>
                                                </HtmlEditor>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </ScrollView>
                        </div>
                    </fieldset>
                </form>
            </div>
        </>
    }

    const renderSuggestions = () => {
        if (props.suggestionsEnabled === false || !communication.suggestedEmails || communication.suggestedEmails.length === 0) return;

        var suggestions = communication.suggestedEmails.filter(e => !selectedContacts.some(c => c.value === e.email));

        return suggestions && suggestions.length > 0 ?
            <div className='p-1'>
                <span className='text-gray-600 py-h mr-h'>Suggestion(s):</span>
                {
                    suggestions.map((s, si) => {
                        return <Fragment
                            key={`suggest-email-${si}`}
                        >
                            <button
                                type='button'
                                className='btn btn-link btn-sm fw-500 p-0'
                                onClick={(e) => { handleSuggestionAdd(e, s) }}
                            >
                                {s.name}
                            </button>
                            {
                                suggestions.length !== (si + 1) ?
                                    <span className='text-info mr-1'>,</span> : null
                            }
                        </Fragment>
                    })
                }
            </div> : null
    }

    const getInitials = (name) => {
        if (!name) return 'X';

        const result = name.replace('Dr. ', '').replace(/[^a-zA-Z ]/g, '');

        if (!result) return name[0].toUpperCase();

        const parts = result.split(' ');

        switch (parts.length) {
            case 0:
                return name[0].toUpperCase();

            case 1:
                return result[0].toUpperCase();

            case 2:
                return `${parts[0][0]}${parts[1][0]}`.toUpperCase();

            default:
                return `${parts[0][0]}${parts[(parts.length - 1)][0]}`.toUpperCase();
        }
    }

    const renderModeButton = () => {
        if (communication.templates && communication.templates.length > 0) {
            switch (mode) {
                case 'Preview':
                    return <button
                        type='button'
                        className='btn btn-primary px-3'
                        onClick={handleModeToggle}
                    >
                        Change
                    </button>

                case 'Update':
                    return <button
                        type='button'
                        className='btn btn-info px-3'
                        onClick={handleModeToggle}
                    >
                        Preview
                    </button>

                default:
                    return;
            }
        }
    }

    return <>
        <Observer>{() =>
            <>
                {
                    communication.isReady ?
                        <>
                            <Observer>{() => <LoadingOverlay isLoading={communication.isSaving} />}</Observer>
                            {renderTitle()}
                            {renderContent()}
                        </> :
                        <>
                            <div className='popup-title'></div>
                            <div className='popup-body'></div>
                        </>
                }
            </>
        }</Observer>
    </>
}

export default EmailCommunication;