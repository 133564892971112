import React, { useEffect, useContext, useState, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { Observer } from 'mobx-react-lite';
import { GlobalHotKeys } from 'react-hotkeys';

import BodyEnd from '../../_shared/BodyEnd';
import ConfirmModal from '../../_shared/ConfirmModal';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import ExternalClinicFilter from './ExternalClinicFilter';

import ExternalDoctorUpdateStore from '../../../../stores/ExternalDoctorUpdateStore';
import ExternalClinicCreateStore from '../../../../stores/ExternalClinicCreateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';
import CacheStore from '../../../../stores/CacheStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as ph from '../../../../utilities/personHelper';
import * as oh from '../../../../utilities/operationHelper';
import * as adh from '../../../../utilities/addressHelper';
import * as sys from '../../../../utilities/systemHelper';

import api from '../../../../api';

import './UpdateExternalDoctor.scss';

function UpdateExternalDoctor(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const searchTimer = useRef(null);
    const focusTimer = useRef(null);
    const externalClinicFilterRef = useRef(null);
    const externalDoctor = useContext(ExternalDoctorUpdateStore);
    const externalClinicNew = useContext(ExternalClinicCreateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const cache = useContext(CacheStore);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [enabledLinkClinic, setEnabledLinkClinic] = useState(false);
    const [externalClinicSearchResult, setExternalClinicSearchResult] = useState(null);

    useEffect(() => {
        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
            if (searchTimer.current) { clearTimeout(searchTimer.current); searchTimer.current = null; }
            externalDoctor.clear();
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (externalDoctor.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleDelete = (event) => {
        setConfirmDelete(true);
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            // customer.checkConflicts(true)
            //     .then(conflicts => {
            //         if (isMounted.current) {
            //             if (!conflicts) {
            //                 handleCommitUpdate(event);
            //             } else {
            //                 setConflicts(conflicts);
            //                 setConfirmConflicts(true);
            //             }
            //         }
            //     })
            externalDoctor.save()
                .then((data) => {
                    if (isMounted.current) {
                        if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                            props.onSuccess(event, { updated: true, data: data });
                        }
                    }
                })
        }
    }

    const handleFirstNameChange = (event) => {
        const value = event.target.value;

        externalDoctor.data.firstName = value;
        externalDoctor.hasUnsavedChanges = true;
    }

    const handleLastNameChange = (event) => {
        const value = event.target.value;

        externalDoctor.data.lastName = value;
        externalDoctor.hasUnsavedChanges = true;
    }

    const handleSuffixChange = (event) => {
        const value = event.target.value;

        externalDoctor.data.suffix = value;
        externalDoctor.hasUnsavedChanges = true;
    }

    const handleBillingNumberChange = (event) => {
        const value = event.target.value;

        externalDoctor.data.billingNumber = value;
        externalDoctor.hasUnsavedChanges = true;
    }

    const handleNewExternalClinicClick = (event) => {
        externalClinicNew.initialize();
        quickDrawer.activateQuickDrawer('externalClinic', 'create', null, handleExternalClinicAddSuccess, handleExternalClinicAddCancel);
    }

    const handleExternalClinicAddSuccess = (result) => {
        if (result && result.updated && result.data) {
            api.ExternalClinics.get(result.data.id)
                .then(({ data }) => {
                    externalDoctor.data.externalClinics.push(data);
                    externalDoctor.hasUnsavedChanges = true;
                });
        }
        externalClinicNew.clear();
    }

    const handleExternalClinicAddCancel = (event) => {
        externalClinicNew.clear();
    }

    const handleLinkToAnotherClinicClick = (event) => {
        setEnabledLinkClinic(true);
        setTimeout(() => {
            if (externalClinicFilterRef.current) {
                externalClinicFilterRef.current.focus();
            }
        }, 300)
    }

    const handleRemoveExternalClinicClick = (event, externalClinic) => {
        const index = externalDoctor.data.externalClinics.findIndex(c => c.id === externalClinic.id);
        if (index >= 0) {
            externalDoctor.data.externalClinics.splice(index, 1);
            externalDoctor.hasUnsavedChanges = true;
        }
    }

    const handleExternalClinicSearchChange = (event, term) => {
        if (searchTimer.current) {
            clearTimeout(searchTimer.current);
        }

        if (term && term.length >= 2) {
            api.ExternalClinics.fullSearch(term, 5)
                .then(({ data }) => {
                    if (isMounted.current) {
                        setExternalClinicSearchResult(data.result);
                    }
                })
        } else {
            setExternalClinicSearchResult(null);
        }
    }

    const handleExternalClinicSearchClick = externalClinic => {
        externalClinicFilterRef.current.isLoading(true);
        setExternalClinicSearchResult(null);

        api.ExternalClinics.get(externalClinic.id)
            .then(({ data: externalClinicData }) => {
                externalDoctor.data.externalClinics.push(externalClinicData);
                externalDoctor.hasUnsavedChanges = true;
            })
            .finally(() => {
                setEnabledLinkClinic(false);
            })
    }

    const handleConfirmDelete = (event) => {
        externalDoctor.delete()
            .then(() => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, { updated: true });
                    }
                }
            })
        setConfirmDelete(false);
    }

    const handleConfirmCancel = (event) => {
        setConfirmDelete(false);
    }

    return <>
        {
            (props.drawer === quickDrawer.drawerOpened) ?
                <GlobalHotKeys
                    keyMap={{
                        close: ['esc'],
                    }}
                    handlers={{
                        close: event => {
                            handleCancel(event)
                        },
                    }}
                    allowChanges={true}
                /> : null
        }
        <form ref={validateRef} onSubmit={handleSubmit}>
            <fieldset disabled={externalDoctor.isSaving}>
                <div className='update-external-doctor-container quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('external-doctor', 'default')}
                        action='Update'
                        category='External Doctor'
                        className='external-doctors'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        <Observer>{() => <>
                            {
                                externalDoctor.isReady ?
                                    <FadeIn>
                                        <div className='body-content'>
                                            <section>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <div className='form-group'>
                                                            <label htmlFor='new-external-doctor-salutation-type-select'><small>Prefix</small></label>
                                                            <select
                                                                id='new-external-doctor-salutation-type-select'
                                                                className='custom-select form-control'
                                                                value={'Dr.'}
                                                                disabled={true}
                                                            >
                                                                <option value='Dr.'>Dr.</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <div className='form-group validate validate-required'>
                                                            <label className='required' htmlFor='new-external-doctor-firstname-input'><small>First Name</small></label>
                                                            <input
                                                                id='new-external-doctor-firstname-input'
                                                                type='text'
                                                                className='form-control'
                                                                spellCheck={false}
                                                                maxLength='50'
                                                                autoComplete='off'
                                                                value={externalDoctor.data.firstName ? externalDoctor.data.firstName : ''}
                                                                onChange={handleFirstNameChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className='form-group validate validate-required'>
                                                            <label className='required' htmlFor='new-external-doctor-lastname-input'><small>Last Name</small></label>
                                                            <input
                                                                id='new-external-doctor-lastname-input'
                                                                type='text'
                                                                className='form-control'
                                                                maxLength='50'
                                                                spellCheck={false}
                                                                autoComplete='off'
                                                                value={externalDoctor.data.lastName ? externalDoctor.data.lastName : ''}
                                                                onChange={handleLastNameChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <div className='form-group'>
                                                            <label htmlFor='new-external-doctor-suffix-type-select'><small>Suffix</small></label>
                                                            <select
                                                                id='new-external-doctor-suffix-type-select'
                                                                className='custom-select form-control'
                                                                value={externalDoctor.data.suffix ? externalDoctor.data.suffix : ''}
                                                                onChange={handleSuffixChange}
                                                            >
                                                                <option value=''></option>
                                                                {
                                                                    cache.getReferenceDataOptions('SuffixType').map((option, di) => {
                                                                        return <option key={`suffix_type_${di}`} value={option.key}>{option.value}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='form-group'>
                                                            <label htmlFor='new-external-doctor-billing-number-input'><small>Billing Number</small></label>
                                                            <input
                                                                id='new-external-doctor-billing-number-input'
                                                                type='text'
                                                                className='form-control'
                                                                maxLength='50'
                                                                spellCheck={false}
                                                                autoComplete='off'
                                                                value={externalDoctor.data.billingNumber ? externalDoctor.data.billingNumber : ''}
                                                                onChange={handleBillingNumberChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section className='external-clinics'>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='form-group mb-0'>
                                                            <div className='d-flex'>
                                                                <div className='flex-1'>
                                                                    <label><small>Works at Clinic(s)</small></label>
                                                                </div>
                                                                <div className='ml-auto'>
                                                                    <button
                                                                        type='button'
                                                                        className='btn btn-link p-0'
                                                                        onClick={handleNewExternalClinicClick}
                                                                    >Add new clinic</button>
                                                                </div>
                                                            </div>
                                                            {
                                                                externalDoctor.data.externalClinics && externalDoctor.data.externalClinics.length > 0 ?
                                                                    externalDoctor.data.externalClinics.map((c, ci) => {
                                                                        return <div
                                                                            key={`new_external_doctor_clinic_${ci}`}
                                                                            className={'clinic-container' + (ci > 0 ? ' mt-2' : '')}
                                                                        >
                                                                            <div className='clinic-detail'>
                                                                                <div className='text-truncate'><strong>{c.name}</strong></div>
                                                                                {
                                                                                    c.address ?
                                                                                        <div className='text-gray-600'>
                                                                                            {adh.getAddressHtml(c.address)}
                                                                                        </div> : null
                                                                                }
                                                                                {
                                                                                    c.emailAddress ?
                                                                                        <div>
                                                                                            <strong>Email:</strong>&nbsp;<a
                                                                                                href={`mailto:${c.emailAddress.toLowerCase()}`}
                                                                                            >{c.emailAddress.toLowerCase()}
                                                                                            </a>
                                                                                        </div> : null
                                                                                }
                                                                                {
                                                                                    c.phoneNumber ?
                                                                                        <div>
                                                                                            <strong>Phone:</strong>&nbsp;<a
                                                                                                href={`tel:${c.phoneNumber}`}
                                                                                            >{sys.getFormattedPhoneNumber(c.phoneNumber)}
                                                                                            </a>
                                                                                        </div> : null
                                                                                }
                                                                                {
                                                                                    c.faxNumber ?
                                                                                        <div>
                                                                                            <strong>Fax:</strong>&nbsp;<span>{sys.getFormattedPhoneNumber(c.faxNumber)}
                                                                                            </span>
                                                                                        </div> : null
                                                                                }
                                                                            </div>
                                                                            <div className='clinic-action'>
                                                                                <button
                                                                                    type='button'
                                                                                    className='btn btn-icon line-height-1'
                                                                                    onClick={(e) => { handleRemoveExternalClinicClick(e, c) }}
                                                                                >
                                                                                    <i className='fal fa-times text-danger fs-sm' />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    }) : null
                                                            }
                                                            {
                                                                enabledLinkClinic ?
                                                                    <div className='external-clinic-search mt-2'>
                                                                        <div className='dropdown'>
                                                                            <ExternalClinicFilter
                                                                                id={'external-clinic-filter'}
                                                                                ref={externalClinicFilterRef}
                                                                                placeholder={'Search for clinic to link'}
                                                                                delay={500}
                                                                                onChange={handleExternalClinicSearchChange}
                                                                            />
                                                                            <ul className='dropdown-menu'>
                                                                                {
                                                                                    externalClinicSearchResult && externalClinicSearchResult.length > 0 ?
                                                                                        externalClinicSearchResult.slice(0, 5).map((s, si) => {
                                                                                            return <li
                                                                                                key={`search_result_${si}`}
                                                                                                className={'dropdown-menu-item'}>
                                                                                                <div
                                                                                                    className='external-clinic-profile'
                                                                                                    onClick={() => { handleExternalClinicSearchClick(s) }}
                                                                                                >
                                                                                                    <div className='text-truncate'><strong>{s.name}</strong></div>
                                                                                                    {
                                                                                                        s.address ?
                                                                                                            <div className='text-gray-600'>
                                                                                                                {adh.getAddressHtml(s.address)}
                                                                                                            </div> : null
                                                                                                    }
                                                                                                </div>
                                                                                            </li>
                                                                                        }) : null
                                                                                }
                                                                            </ul>
                                                                        </div>
                                                                    </div> :
                                                                    <button type='button' className='btn btn-link p-0 mt-2' onClick={handleLinkToAnotherClinicClick}>Link to another clinic</button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </FadeIn> : null
                            }
                        </>
                        }</Observer>
                    </div>
                    <div className='quick-drawer-action pl-3'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-danger mr-2'
                                        onClick={handleDelete}
                                    >Delete</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
        <BodyEnd>
            <ConfirmModal
                icon={<i className='fal fa-clinic-medical text-danger mr-2'></i>}
                message={<>Delete<span className='fw-500 mx-1'>{externalDoctor.originalData ? `Dr. ${ph.getFirstLastName(externalDoctor.originalData)}` : ''}</span> from address book? </>}
                show={confirmDelete}
                onOption1Click={handleConfirmDelete}
                onCancel={handleConfirmCancel}
            />
        </BodyEnd>
    </>
}

export default UpdateExternalDoctor;