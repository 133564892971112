import { createContext } from 'react';
 import { decorate, observable, action, toJS } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';
import * as fh from '../utilities/formTemplateHelper';

export class FormTemplateClone {
    id = null;
    data = {
        name: null,
        descriptionHtml: null,
        access: 'Anonymous',
        destinations: ['Email'],
        settings: [],
    };
    isReady = false;
    isSaving = false;
    isLoading = false;
    hasUnsavedChanges = false;

    cancelFormTemplateGet = null;
    cancelFormTemplateClone = null;

    initialize = (id) => {
        const that = this;

        this.clear();
        this.id = id;
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            api.FormTemplates.get(that.id, (c) => { that.cancelFormTemplateGet = c })
                .then(({ data }) => {
                    if (data) {
                        that.data.name = data.name ? `Copy of ${data.name}` : '';
                        that.data.descriptionHtml = data.descriptionHtml;
                        that.data.access = data.access;
                        that.data.destinations = data.destinations;
                        that.data.settings = data.settings ? data.settings : fh.getDestinationSettingKeys(this.data.destinations).map(d => { return { id: d, value: null } });
                    }
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isLoading = false;
                    that.isReady = true;
                })
        })
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            let option = toJS(that.data);

            api.FormTemplates.clone(that.id, option, (c) => { that.cancelFormTemplateClone = c })
                .then(({ data }) => {
                    that.id = data.id;
                    that.hasUnsavedChanges = false;
                    option.id = data.id;
                    resolve(option);
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    clear = () => {
        this.id = null;
        this.data.name = null;
        this.data.descriptionHtml = null;
        this.data.access = 'Anonymous';
        this.data.destinations = ['Email'];
        this.data.settings.clear();
        this.hasUnsavedChanges = false;

        if (fn.isFunction(this.cancelFormTemplateGet)) {
            this.cancelFormTemplateGet();
            this.cancelFormTemplateGet = null;
        }

        if (fn.isFunction(this.cancelFormTemplateClone)) {
            this.cancelFormTemplateClone();
            this.cancelFormTemplateClone = null;
        }
    }
}

decorate(FormTemplateClone, {
    id: observable,
    data: observable.deep,
    hasUnsavedChanges: observable,
    isReady: observable,
    isSaving: observable,
    initialize: action,
    save: action,
    clear: action,
})

export default createContext(new FormTemplateClone());