import React from 'react';
import * as fn from '../utilities/_functions';

export const GENERIC_ERROR = 'Oops, something is wrong.  Please try again.';
export const GENERIC_ERROR_HTML = <p><strong>Oops!</strong> Something went wrong. Please try again.</p>;
export const GENERIC_ERROR_REQUIRED = 'This is required.';
export const GENERIC_ERROR_ALL_REQUIRED_HTML = <p><strong>Oops!</strong> Please fill in all required fields to proceed.</p>;
export const GENERIC_FORM_ERROR_HTML = <p><strong>Oops!</strong> Please correct the highlighted errors to proceed.</p>;
export const INVALID_EMAIL_FORMAT = 'This is an invalid email address.';
export const INVALID_EMAIL_REQUIRED = 'Email is required.'
export const INVALID_PASSWORD_FORMAT = 'Password must be minimum 6 characters long.';
export const INVALID_PASSWORD_REQUIRED = 'Password is required.'
export const INVALID_NAME_REQUIRED = 'Name is required.'
export const INVALID_NAME_FORMAT = 'Please check the spelling.';
export const INVALID_PHONE_REQUIRED = 'Phone number is required.';
export const INVALID_PHONE_FORMAT = 'This is an invalid phone number.';
export const INVALID_POSTAL_FORMAT = 'This is an invalid postal code.';
export const INVALID_OHIP_FORMAT = 'This is an invalid health card.';
export const INVALID_DATE_FORMAT = 'This is an invalid date.';
export const INVALID_ADDRESS_REQUIRED = 'Address is required.';
export const INVALID_ADDRESS_SELECTION = 'You must select one of the address suggestions from the dropdown.';
export const INVALID_ADDRESS_STREET_NUMBER_REQUIRED = 'Street number is missing.';
export const INVALID_ADDRESS_STREET_NAME_REQUIRED = 'Must include both street number and name.';
export const INVALID_ADDRESS_OUT_OF_AREA = 'This address seems to be much further than the postal code entered.';
export const INVALID_UNIT_SUITE_TYPE_REQUIRED = 'Unit type is missing.';
export const INVALID_UNIT_SUITE_REQUIRED = 'Unit number is missing.';
export const INVALID_WEBSITE_FORMAT = 'This is an invalid website.';
export const INVALID_FACEBOOK_FORMAT = 'This is an invalid Facebook page.';
export const INVALID_TWITTER_FORMAT = 'This is an invalid Twitter page.';
export const INVALID_INSTAGRAM_FORMAT = 'This is an invalid Instagram page.';
export const INVALID_YOUTUBE_FORMAT = 'This is an invalid YouTube page.';
export const INVALID_CREDIT_CARD_REQUIRED = 'Credit card is required.';
export const INVALID_CREDIT_CARD_INFORMATION = 'The credit card you entered is invalid or have expired.';
export const INVALID_CREDIT_CARD_UNSUPPORTED_DEBIT_HTML = 'It cannot be any <strong class="text-uppercase">debit cards</strong>.';
export const INVALID_CREDIT_CARD_UNSUPPORTED_PREPAID_HTML = 'It cannot be any <strong class="text-uppercase">prepaid credit cards</strong>.';
export const INVALID_CREDIT_CARD_UNSUPPORTED_UNKNOWN_HTML = 'We can only accept <strong>credit cards</strong>.';
export const INVALID_CREDIT_CARD_AUTHENTICATION = 'We need your authorization to continue. Please try again.';
export const INVALID_CREDIT_CARD_UNKNOWN_ERROR = 'Something might be wrong with your card. Please contact your bank.';
export const UNSUPPORTED_FILE_TYPE = 'Unsupported file format detected.';
export const UNSUPPORTED_FILE_TYPE_HTML = <p>Unsupported file format detected.</p>;
export const DISCARD_CHANGES = 'There are unsaved changes, do you want to discard them?';
export const DEVICE_NOT_READY_HTML = <p>Device not ready. Please refresh the page and try again.</p>

export const toastOption = () => { 
    return { 
        toastId: 'form-error', 
        position: 'top-center', 
        style: { 
            width: '380px', 
            zIndex: 
            fn.getHighestZIndex() + 1 
        }
    } 
}