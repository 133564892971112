import React, { useContext, useEffect, useState, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import FadeIn from 'react-fade-in';
import momentLocalizer from 'react-widgets-moment';
import { GlobalHotKeys } from 'react-hotkeys';
import { DateTimePicker } from 'react-widgets';
import moment from 'moment';

import BodyEnd from '../../../_shared/BodyEnd';
import ConfirmModal from '../../../_shared/ConfirmModal';
import QuickDrawerHeader from '../../../_shared/QuickDrawerHeader';
import RichTextEditor from '../../../_shared/RichTextEditor';
import { renderQuickDrawerLoading } from '../../../_shared/QuickDrawer';

import BusinessDayUpdateStore from '../../../../../stores/BusinessDayUpdateStore';
import QuickDrawerStore from '../../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../../constants/errorMessages';
import * as fn from '../../../../../utilities/_functions';
import * as oh from '../../../../../utilities/operationHelper';

import api from '../../../../../api';

import './NewBusinessDay.scss';

moment.locale('en');
momentLocalizer();

function NewBusinessDay(props) {
    const businessDayUpdate = useContext(BusinessDayUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const isMounted = useRef(true);
    const pickerTimer = useRef(null);
    const [startDatePickerOpen, setStartDatePickerOpen] = useState(false);
    const [endDatePickerOpen, setEndDatePickerOpen] = useState(false);
    const [overlapWorkdays, setOverlapWorkdays] = useState([]);
    let cancelSchedules = null;
    let schedules = toJS(props.extraProps);

    const loadSchedulesForDay = async (date) => {
        const resp = await api.Schedules.get(moment(date).format('YYYY-MM-DD'), moment(date).format('YYYY-MM-DD'), null, (c) => { cancelSchedules = c })
        schedules = resp.data;
    }

    useEffect(() => {
        if (!schedules) {
            loadSchedulesForDay(businessDayUpdate.date);
        }

        return () => {
            isMounted.current = false;

            businessDayUpdate.clear();
            quickDrawer.clear();

            if (pickerTimer.current) { clearTimeout(pickerTimer.current); pickerTimer.current = null; }

            if (fn.isFunction(cancelSchedules)) {
                cancelSchedules();
            }
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (businessDayUpdate.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleIsClosedChange = (event) => {
        businessDayUpdate.data.isClosed = !businessDayUpdate.data.isClosed;
        businessDayUpdate.hasUnsavedChanges = true;
    }

    const handleStartChange = (value) => {
        const time = moment(value);
        const start = time.clone().format('HH:mm');
        const end = time.clone().add(1, 'hours').format('HH:mm');

        businessDayUpdate.data.start = start;
        businessDayUpdate.data.end = end;
        businessDayUpdate.hasUnsavedChanges = true;
    }

    const handleEndChange = (value) => {
        const time = moment(value);
        const end = time.clone().format('HH:mm');

        businessDayUpdate.data.end = end;
        businessDayUpdate.hasUnsavedChanges = true;
    }

    const handleNoteChange = (content, delta, source, editor) => {
        const note = editor.getText().replace(/(\r\n|\n|\r)/g, ' ').trim();
        const noteHtml = (content === '<p><br></p>') ? null : content;

        businessDayUpdate.data.note = note;
        businessDayUpdate.data.noteHtml = noteHtml;
        businessDayUpdate.hasUnsavedChanges = true;
    }

    const handleSubmit = async event => {
        event.preventDefault();
        const workdays = schedules.filter(s => !s.isTimeOff && moment(businessDayUpdate.data.date).isSame(moment(s.start), 'day'));
        if (getIsClosed() && !!workdays && !!workdays.length) {
            setOverlapWorkdays(workdays);
        }
        else {
            await handleConfirmUpdate(event, false);
        }
    }

    const handleConfirmUpdate = async (event, deleteWorkdays) => {
        event.preventDefault();
        if (deleteWorkdays) {
            for (let wd of overlapWorkdays) {
                await api.Schedules.delete(
                    {
                        userId: wd.userId,
                        date: moment(wd.start).startOf('day'),
                        type: 'ThisOneOnly',
                    }
                )
            }
        }
        const data = await businessDayUpdate.save();
        if (isMounted.current) {
            if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                props.onSuccess(event, data);
            }
        }
    }

    const renderDateTime = () => {
        if (!businessDayUpdate.data || !businessDayUpdate.data.date) return null;

        const date = moment(businessDayUpdate.data.date);
        const weekday = date.format('dddd');
        const day = date.format('D');
        const ordinal = date.format('Do').replace(day, '');
        const dateHtml = `${date.format('MMMM D')}<sup>${ordinal}</sup>${((date.year() !== moment().year()) ? `, ${date.format('YYYY')}` : '')}`;

        return <>
            <ul className='list-inline no-style m-0'>
                <li className='list-inline-item m-0'>
                    <div className='text'>
                        <small className='weekday'>{weekday}</small>
                        <span className='date' dangerouslySetInnerHTML={{ __html: dateHtml }}></span>
                    </div>
                </li>
            </ul>
            {
                businessDayUpdate.data.specialDates && businessDayUpdate.data.specialDates.length > 0 ?
                    <div className='mt-o'>
                        {
                            businessDayUpdate.data.specialDates.map((d, di) => {
                                return <span
                                    key={`special-date-${di}`}
                                    className={'badge text-white text-shadow-0 mx-o mr-1' + (d.isHoliday ? ' bg-danger-700' : ' bg-warning-700')}
                                    title={d.name}
                                >
                                    <span className='d-inline fs-xs'>{d.name}</span>
                                </span>
                            })
                        }
                    </div> : null
            }
        </>
    }

    const getStartTime = () => {
        if (businessDayUpdate.data && businessDayUpdate.data.start) {
            const time = moment(businessDayUpdate.data.start, 'HH:mm');
            const startTime = moment().startOf('day').hours(time.hour()).minutes(time.minute());
            return startTime;
        }

        return null;
    }

    const getEndTime = () => {
        if (businessDayUpdate.data && businessDayUpdate.data.end) {
            const time = moment(businessDayUpdate.data.end, 'HH:mm');
            const endTime = moment().startOf('day').hours(time.hour()).minutes(time.minute());
            return endTime;
        }

        return null;
    }

    const getIsClosed = () => {
        return businessDayUpdate.data ? businessDayUpdate.data.isClosed : true;
    }

    const getIsPast = () => {
        return businessDayUpdate.data && moment(businessDayUpdate.data.date).startOf('day').isBefore(moment().startOf('day'));
    }

    return <>
        <form onSubmit={handleSubmit}>
            <Observer>{() =>
                <>
                    {
                        (startDatePickerOpen || endDatePickerOpen) ?
                            <GlobalHotKeys
                                keyMap={{
                                    closeAllPickers: ['esc'],
                                }}
                                handlers={{
                                    closeAllPickers: event => {
                                        setStartDatePickerOpen(false);
                                        setEndDatePickerOpen(false);
                                    }
                                }}
                                allowChanges={true}
                            /> :
                            <>
                                {
                                    (props.drawer === quickDrawer.drawerOpened) ?
                                        <GlobalHotKeys
                                            keyMap={{
                                                close: ['esc'],
                                            }}
                                            handlers={{
                                                close: event => {
                                                    handleCancel(event)
                                                },
                                            }}
                                            allowChanges={true}
                                        /> : null
                                }
                            </>
                    }
                    <fieldset>
                        <div className='quick-drawer'>
                            <QuickDrawerHeader
                                drawer={props.drawer}
                                icon={oh.getIcon('business-hours', 'default')}
                                action={'Update'}
                                category='Work day'
                                className={'schedules'}
                                onCancel={handleCancel}
                            />
                            <div className='quick-drawer-body'>
                                {
                                    businessDayUpdate.isReady ? <>
                                        <FadeIn>
                                            <div className='new-business-day body-content'>
                                                {
                                                    getIsPast() ?
                                                        <section>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className={'alert mb-0 alert-warning'} role='alert'>
                                                                        <strong className='d-block mb-2'>Important</strong>
                                                                        <ul className='pl-3 mb-0'>
                                                                            <li>Business work day locked. This is in the past.</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section> : null
                                                }
                                                <section className='date-time'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            {renderDateTime()}
                                                        </div>
                                                    </div>
                                                </section>
                                                <section
                                                    className='business-hours'
                                                >
                                                    <div className='row'>
                                                        <Observer>{() =>
                                                            <div className='col-12'>
                                                                <label><small>Business hours</small></label>
                                                                <div className='d-flex flex-row'>
                                                                    <ul
                                                                        className='list-inline no-style m-0 mb-2'
                                                                    >
                                                                        <li className='list-inline-item m-0 time'>
                                                                            <DateTimePicker
                                                                                value={getStartTime() && !getIsClosed() ? getStartTime().toDate() : null}
                                                                                step={30}
                                                                                date={false}
                                                                                time={true}
                                                                                min={moment().startOf('day').add(6, 'hours').toDate()}
                                                                                max={(getEndTime() ? getEndTime().clone().add(-1, 'hours') : moment().startOf('day').add(22, 'hours')).toDate()}
                                                                                disabled={getIsPast() || getIsClosed()}
                                                                                parse={fn.parseReformatTime}
                                                                                onChange={value => { handleStartChange(value) }}
                                                                            />
                                                                        </li>
                                                                        <li className='list-inline-item m-0 mx-2'>to</li>
                                                                        <li className='list-inline-item m-0 time'>
                                                                            <DateTimePicker
                                                                                value={getEndTime() && !getIsClosed() ? getEndTime().toDate() : null}
                                                                                step={30}
                                                                                date={false}
                                                                                time={true}
                                                                                min={getStartTime() ? getStartTime().clone().add(1, 'hours').toDate() : null}
                                                                                max={moment().startOf('day').add(23, 'hours').toDate()}
                                                                                disabled={getIsPast() || getIsClosed() || !getStartTime()}
                                                                                parse={fn.parseReformatTime}
                                                                                onChange={value => { handleEndChange(value) }}
                                                                            />
                                                                        </li>
                                                                    </ul>
                                                                    <div className='p-2'>
                                                                        <div className='custom-control custom-checkbox'>
                                                                            <input
                                                                                id={`business-hours-close`}
                                                                                type='checkbox'
                                                                                name={`business-hours-close`}
                                                                                className='custom-control-input'
                                                                                checked={getIsClosed()}
                                                                                disabled={getIsPast()}
                                                                                onChange={e => { handleIsClosedChange(e) }}
                                                                            />
                                                                            <label
                                                                                htmlFor={`business-hours-close`}
                                                                                className='custom-control-label'
                                                                            >
                                                                                Closed
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }</Observer>
                                                    </div>
                                                </section>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group'>
                                                                <label><small>Note</small></label>
                                                                <div className='input-group'>
                                                                    <Observer>{() =>
                                                                        <RichTextEditor
                                                                            mode='basic'
                                                                            value={businessDayUpdate.data && businessDayUpdate.data.noteHtml ? businessDayUpdate.data.noteHtml : null}
                                                                            disableTab={true}
                                                                            readOnly={getIsPast()}
                                                                            onChange={handleNoteChange}
                                                                        />
                                                                    }</Observer>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </FadeIn>
                                    </> : renderQuickDrawerLoading()
                                }
                            </div>
                            <div className='quick-drawer-action pl-3'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='float-right'>
                                            <button
                                                type='button'
                                                className='btn btn-link btn-cancel'
                                                onClick={handleCancel}
                                            >Cancel</button>
                                            {
                                                !getIsPast() ?
                                                    <button
                                                        type='submit'
                                                        className='btn btn-success ml-2'
                                                    >Save</button> : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </>
            }</Observer>
        </form>
        <BodyEnd>
            <ConfirmModal
                icon={<i className={'text-danger mr-2 ' + oh.getIcon('business-hours', 'default')}></i>}
                message={<>There are other work schedules on the day. Do you want to remove these entries?</>}
                show={overlapWorkdays && overlapWorkdays.length}
                onOption1Click={(e) => handleConfirmUpdate(e, true)}
                onOption2Click={(e) => handleConfirmUpdate(e, false)}
                onCancel={() => setOverlapWorkdays([])}
                option2ClassName={'btn btn-secondary shadow-0'}
                option2Text={'No'}
                cancelText={'Cancel'}
            />
        </BodyEnd>
    </>
}

export default NewBusinessDay;