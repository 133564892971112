import React, { useEffect, useContext, useState, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { GlobalHotKeys } from 'react-hotkeys';
import { useObserver } from 'mobx-react-lite';

import QuickDrawerHeader from '../../../../_shared/QuickDrawerHeader';

import InternalFormTemplateUpdateStore from '../../../../../../stores/InternalFormTemplateUpdateStore';
import QuickDrawerStore from '../../../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../../../constants/errorMessages';
import * as fn from '../../../../../../utilities/_functions';
import * as oh from '../../../../../../utilities/operationHelper';

import './NewInternalFormPage.scss';

function NewInternalFormPage(props) {
    const isMounted = useRef(true);
    const internalFormTemplate = useContext(InternalFormTemplateUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [name, setName] = useState('');
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = event => {
        event.preventDefault();
        internalFormTemplate.hasUnsavedChanges = true;
        internalFormTemplate.addPage(name)
            .then(() => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        setHasUnsavedChanges(false);
                        props.onSuccess();
                    }
                }
            })
    }

    const handleNameChange = event => {
        setName(event.target.value);
        setHasUnsavedChanges(true);
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form onSubmit={handleSubmit}>
            <fieldset disabled={internalFormTemplate.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('internal-form-page', 'add')}
                        action='New'
                        category='Internal Form Page'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        <FadeIn>
                            <div className='new-internal-form-page body-content'>
                                <section>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='form-group mb-2'>
                                                <label className='required' htmlFor='new-internal-form-page-name'><small>Name</small></label>
                                                <input
                                                    id='new-internal-form-page-name'
                                                    type='text'
                                                    maxLength={100}
                                                    className='form-control'
                                                    autoComplete={'off'}
                                                    value={name ? name : ''}
                                                    onChange={handleNameChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </FadeIn>
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-4'></div>
                            <div className='col-8'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                    >Add</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>)
}

export default NewInternalFormPage;