import { createContext } from 'react';
import { decorate, observable, action, toJS } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class PrescriptionTemplateCreate {
    id = null;
    prescriptionTypes = [];
    data = {
        prescriptionTypeId: null,
        name: null,
        descriptionHtml: null,
    };
    isReady = false;
    isSaving = false;
    hasUnsavedChanges = false;

    cancelPrescriptionTemplates = null;
    cancelPrescriptionTypes = null;

    initialize = () => {
        const that = this;

        this.clear();
        this.id = fn.newId('rxtp_');

        return new Promise((resolve, reject) => {
            api.PrescriptionTypes.all((c) => { that.cancelPrescriptionTypes = c })
                .then(({ data }) => {
                    that.prescriptionTypes = data;
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isReady = true;
                })
        })
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            let option = toJS(that.data);

            if (that.hasUnsavedChanges) {
                api.PrescriptionTemplates.create(option, (c) => { that.cancelPrescriptionTemplates = c })
                    .then(({ data }) => {
                        that.hasUnsavedChanges = false;
                        that.id = data.id;
                        option.id = data.id;
                        resolve(option);
                    })
                    .catch(() => {
                        reject();
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                that.hasUnsavedChanges = false;
                that.isSaving = false;
                resolve(option);
            }
        })
    }

    clear = () => {
        this.id = null;
        this.prescriptionTypes.clear();
        this.data.prescriptionTypeId = null;
        this.data.name = null;
        this.data.descriptionHtml = null;
        this.hasUnsavedChanges = false;

        if (fn.isFunction(this.cancelPrescriptionTemplates)) {
            this.cancelPrescriptionTemplates();
            this.cancelPrescriptionTemplates = null;
        }

        if (fn.isFunction(this.cancelPrescriptionTypes)) {
            this.cancelPrescriptionTypes();
            this.cancelPrescriptionTypes = null;
        }
    }
}

decorate(PrescriptionTemplateCreate, {
    id: observable,
    prescriptionTypes: observable.deep,
    data: observable.deep,
    hasUnsavedChanges: observable,
    isReady: observable,
    isSaving: observable,
    initialize: action,
    save: action,
    clear: action,
})

export default createContext(new PrescriptionTemplateCreate());