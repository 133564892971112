import React, { useContext, useEffect, useState, useRef, Fragment } from 'react';
import { Observer } from 'mobx-react-lite';
import FadeIn from 'react-fade-in';
import momentLocalizer from 'react-widgets-moment';
import { GlobalHotKeys } from 'react-hotkeys';
import { Multiselect } from 'react-widgets'
import moment from 'moment';

import BodyEnd from '../../_shared/BodyEnd';
import ConfirmModal from '../../_shared/ConfirmModal';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';

import AppointmentUpdateStore from '../../../../stores/AppointmentUpdateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as fn from '../../../../utilities/_functions';
import * as ah from '../../../../utilities/addressHelper';
import * as ch from '../../../../utilities/customerHelper';
import * as ph from '../../../../utilities/personHelper';
import * as sys from '../../../../utilities/systemHelper';
import * as oh from '../../../../utilities/operationHelper';
import * as bh from '../../../../utilities/badgeHelper';
import * as pih from '../../../../utilities/publicInsuranceBillingReportHelper';
import * as ErrorMessages from '../../../../constants/errorMessages';

import './UpdateServices.scss';
import api from '../../../../api';

moment.locale('en');
momentLocalizer();

function UpdateServices(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const appointment = useContext(AppointmentUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [services, setServices] = useState([]);
    const [isReady, setIsReady] = useState(false);
    const [eligibles, setEligibles] = useState(null);
    const [ineligibles, setIneligibles] = useState(null);
    const [confirmIneligibles, setConfirmIneligibles] = useState(false);

    useEffect(() => {
        quickDrawerFocus(props.drawer);

        api.Services.all()
            .then(({ data }) => {
                setServices(data);
            })
            .finally(() => {
                setIsReady(true);
            })

        return () => {
            isMounted.current = false;
        }
    }, []) // eslint-disable-line

    const checkEligibility = () => {
        if (appointment.data.customer && appointment.data.services && appointment.data.services.length > 0) {
            appointment.checkEligibility()
                .then(data => {
                    if (isMounted.current) {
                        const eligibleData = data && data.some(d => d.isPublicHealthChecked && d.isEligible) ? data.filter(d => d.isPublicHealthChecked && d.isEligible) : null;
                        const ineligibleData = data && data.some(d => !d.isPublicHealthChecked || !d.isEligible) ? data.filter(d => !d.isPublicHealthChecked || !d.isEligible) : null;
                        setEligibles(eligibleData);
                        setIneligibles(ineligibleData);
                    }
                })
        } else {
            setEligibles(null);
            setIneligibles(null);
        }
    }

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (appointment.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleServiceSelection = (tag, metadata) => {
        if (metadata && metadata.dataItem) {
            switch (metadata.action) {
                case 'insert':
                    if (!appointment.data.services.some(s => s.id === metadata.dataItem.id)) {
                        appointment.data.services.push(JSON.parse(JSON.stringify(metadata.dataItem)));
                    }
                    break;

                case 'remove':
                    const index = appointment.data.services.findIndex(s => s.id === metadata.dataItem.id);
                    if (index !== -1) {
                        appointment.data.services.splice(index, 1);
                    }
                    break;

                default:
                    break;
            }

            appointment.hasUnsavedChanges = true;
            checkEligibility();
        }
    }

    const handleSubmit = event => {
        event.preventDefault();
        if (fn.validateForm(validateRef.current)) {
            appointment.checkEligibility(appointment.data.customer, true)
                .then(eligibilityData => {
                    if (isMounted.current) {
                        const eligibleData = eligibilityData && eligibilityData.some(d => d.isEligible) ? eligibilityData.filter(d => d.isEligible) : null;
                        const ineligibleData = eligibilityData && eligibilityData.some(d => !d.isEligible) ? eligibilityData.filter(d => !d.isEligible) : null;

                        setEligibles(eligibleData);
                        setIneligibles(ineligibleData);

                        if (!ineligibleData) {
                            handleCommitUpdate(event);
                        } else {
                            setConfirmIneligibles(true);
                        }
                    }
                })
        }
    }

    const handleCommitUpdate = event => {
        appointment.save(true)
            .then(() => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        const recallFrequencyInMonth = appointment.data.services.some(s => Number.isInteger(s.recallFrequencyInMonth) && s.recallFrequencyInMonth >= 0) ? Math.max.apply(Math, appointment.data.services.filter(s => Number.isInteger(s.recallFrequencyInMonth) && s.recallFrequencyInMonth >= 0).map(s => { return s.recallFrequencyInMonth })) : null;
                        props.onSuccess(event, { updated: true, data: { id: appointment.id, recallFrequencyInMonth: recallFrequencyInMonth } });
                    }
                }
            })
    }

    const handleIneligibleConfirm = event => {
        handleCommitUpdate(event);
        setConfirmIneligibles(false);
    }

    const handleIneligibleCancel = event => {
        setConfirmIneligibles(false);
    }

    const renderAppointmentDateTime = () => {
        if (!appointment.data || !appointment.data.start) return null;

        const start = moment(appointment.data.start);
        const end = appointment.data.end ? moment(appointment.data.end) : null;
        const hasEndTime = !!end;
        const weekday = start.format('dddd');
        const day = start.format('D');
        const ordinal = start.format('Do').replace(day, '');
        const dateHtml = `${start.format('MMMM D')}<sup>${ordinal}</sup>${((start.year() !== moment().year()) ? `, ${start.format('YYYY')}` : '')}`;
        const startTimeHtml = `${start.format('h:mm')}${(!hasEndTime || start.format('a') !== end.format('a') ? ` ${start.format('a')}` : '')}`;
        const endTimeHtml = hasEndTime ? `${end.format('h:mm')} ${end.format('a')}` : '';

        return <div>
            <div className='d-flex'>
                <div className='flex-1'>
                    <ul className='list-inline m-0'>
                        <li className='list-inline-item m-0'>
                            <div className='text'>
                                <small className='weekday'>{weekday}</small>
                                <span className='date' dangerouslySetInnerHTML={{ __html: dateHtml }}></span>
                            </div>
                        </li>
                        <li className='list-inline-item my-0 mx-1'><small>@</small></li>
                        <li className='list-inline-item m-0'>
                            <div className='text'>
                                <span className='time' dangerouslySetInnerHTML={{ __html: startTimeHtml }}></span>
                            </div>
                        </li>
                        <li className='list-inline-item my-0 mx-1'><small>to</small></li>
                        <li className='list-inline-item m-0'>
                            <div className='text'>
                                <span className='time' dangerouslySetInnerHTML={{ __html: endTimeHtml }}></span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    }

    const renderResource = () => {
        const { resource } = appointment.data;

        return <div
            className='profile-wrapper'
        >
            <div className='profile'>
                <span
                    className={`profile-image rounded-circle fw-500 d-block` + (resource && !resource.profilePictureUri ? ` profile-initials bg-color${resource.color}-500` : '')}
                    style={resource && resource.profilePictureUri ? {
                        backgroundImage: `url(${resource.profilePictureUri})`,
                        backgroundSize: 'cover',
                    } : null}
                    title={resource ? resource.fullName : 'System'}>
                    {resource && !resource.profilePictureUri ? <div className='d-initials'>{resource.initials}</div> : null}
                </span>
            </div>
            <div className='description'>
                <div className='name'>{resource.fullName}</div>
                <div className='services'>
                    {bh.renderAppointmentNew(appointment.data, 'p-1 mr-1')}
                    {bh.renderAppointmentFirstExam(appointment.data, 'p-1 mr-1')}
                    {bh.renderAppointmentPrebook(appointment.data, 'p-1 mr-1')}
                    {
                        appointment.data.services.map((s, si) => {
                            return <Fragment
                                key={`service_${si}`}
                            >
                                {bh.renderServiceCode(s, 'p-1 mr-1')}
                            </Fragment>
                        })
                    }
                </div>
            </div>
        </div>
    }

    const renderCustomer = () => {
        const customer = appointment.data.customer;
        return <div
            className='profile-wrapper'
        >
            <div className='profile'>
                <span
                    className={`profile-image profile-initials rounded-circle d-flex text-white ${ch.getProfileColor(customer)} fw-500`}
                    title={customer.fullName}
                >
                    {customer.initials}
                </span>
            </div>
            <div className='description flex-1'>
                <div className='name'>{ph.getFullName(customer, true)}
                    {
                        customer.dateOfBirth || customer.sex || customer.gender || customer.pronoun ?
                            <small className='text-nowrap ml-2'>({`${ph.getAge(customer.dateOfBirth, moment(appointment.data.start))} ${ph.getSexGenderPronounDisplay(customer)}`.trim()})</small> : null
                    }
                </div>
                {
                    customer.address && customer.address.country ?
                        <div className='info'>{ah.getAddressHtml(customer.address)}</div> : null
                }
                {
                    customer.emailAddress ?
                        <div className='info'>
                            <a
                                href={`mailto:${customer.emailAddress}`}
                            >{customer.emailAddress}
                            </a>
                        </div> : null
                }
                {
                    customer.phoneNumber ?
                        <div className='info'>
                            <a
                                href={`tel:${customer.phoneNumber}`}
                            >{sys.getFormattedPhoneNumber(customer.phoneNumber)}
                            </a>
                        </div> : null
                }
            </div>
        </div>
    }

    const renderServices = () => {
        const filteredServices = services.filter(s =>
            (!s.activeStartingDate || moment(s.activeStartingDate).isSameOrBefore(moment(appointment.data.start))) &&
            appointment.data.services.filter(a => a.id === s.id).length === 0);

        return filteredServices.map(s => {
            return {
                id: s.id,
                name: `${s.code} - ${s.name}`,
                code: s.code,
                colorHexValue: s.colorHexValue,
                duration: (s.defaultDurationInMinutes ? s.defaultDurationInMinutes : 0),
                recallFrequencyInMonth: (s.defaultRecallPeriodInMonth && s.defaultRecallPeriodInMonth > 0 ? s.defaultRecallPeriodInMonth : null),
            }
        })
    }

    const getLockedServices = () => {
        return appointment.data.services.filter(s => s.lastPublicInsuranceBilling && s.lastPublicInsuranceBilling.isLocked);
    }

    const getEditableServices = () => {
        return appointment.data.services.filter(s => !s.lastPublicInsuranceBilling || !s.lastPublicInsuranceBilling.isLocked);
    }

    return <>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <Observer>{() =>
                <>
                    {
                        (props.drawer === quickDrawer.drawerOpened) ?
                            <GlobalHotKeys
                                keyMap={{
                                    close: ['esc'],
                                }}
                                handlers={{
                                    close: event => {
                                        handleCancel(event)
                                    },
                                }}
                                allowChanges={true}
                            /> : null
                    }
                    <fieldset disabled={appointment.isSaving}>
                        <div className='quick-drawer'>
                            <QuickDrawerHeader
                                drawer={props.drawer}
                                icon={'fal fa-hand-holding-medical'}
                                action='Update'
                                category='Exam Services'
                                className='appointments'
                                onCancel={handleCancel}
                            />
                            <div className='quick-drawer-body'>
                                {
                                    appointment.isReady && isReady ?
                                        <FadeIn>
                                            <div className='update-exam-services body-content'>
                                                {
                                                    eligibles ?
                                                        <section>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='alert alert-info p-3 mb-0' role='alert'>
                                                                        <strong className='d-block mb-2'>Information</strong>
                                                                        <ul className='pl-3 mb-0'>
                                                                            {
                                                                                eligibles.map((i, ii) => {
                                                                                    return <li key={`update_service_eligible_${ii}`}>{bh.renderServiceCode(i.service, 'float-left mr-1')} Eligibility confirmed.</li>
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section> : null
                                                }
                                                {
                                                    ineligibles ?
                                                        <section>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='alert alert-warning p-3 mb-0' role='alert'>
                                                                        <strong className='d-block mb-2'>Warning</strong>
                                                                        <ul className='pl-3 mb-0'>
                                                                            {
                                                                                ineligibles.map((i, ii) => {
                                                                                    return <li key={`update_service_ineligible_${ii}`}>{bh.renderServiceCode(i.service, 'float-left mr-1')} {i.earliestEligibleDate ? <>{i.ineligibilityCode ? `(Error: ${i.ineligibilityCode}) ` : ''}Not eligible until <span className='tt-underline'>{moment(i.earliestEligibleDate).format('YYYY-MM-DD')}</span>.</> : (i.ineligibilityReason ? i.ineligibilityReason : 'Cannot verify.  Reason unknown.')}</li>
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section> : null
                                                }
                                                <section className='date-time'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            {renderAppointmentDateTime()}
                                                        </div>
                                                    </div>
                                                </section>
                                                <section className='resource'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-0'>
                                                                <label><small>With</small></label>
                                                                {renderResource()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section className='customer'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-0'>
                                                                <label><small>For</small></label>
                                                                {renderCustomer()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section className='services'>
                                                    {
                                                        getLockedServices() && getLockedServices().length > 0 ?
                                                            <>
                                                                <div className='row'>
                                                                    <div className='col-12 mb-4'>
                                                                        <div className='form-group'>
                                                                            <label><small>Locked services</small></label>
                                                                            <table className='table table-borderless locked-services'>
                                                                                <tbody>
                                                                                    {
                                                                                        getLockedServices().map((l, li) => {
                                                                                            return <tr
                                                                                                key={`new-appointment-services-locked-${li}`}
                                                                                            >
                                                                                                <td>
                                                                                                    {bh.renderServiceCode(l, 'fs-md')}
                                                                                                </td>
                                                                                                <td className='w-100'>
                                                                                                    <span className='px-2'>
                                                                                                        {pih.getLockedReason(l.lastPublicInsuranceBilling)}
                                                                                                    </span>
                                                                                                </td>
                                                                                            </tr>
                                                                                        })
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-12 mb-0'>
                                                                        <div className='form-group'>
                                                                            <label htmlFor='new-appointment-services'><small>Other services</small></label>
                                                                            <Observer>{() => <Multiselect
                                                                                allowCreate={false}
                                                                                data={renderServices()}
                                                                                defaultValue={getEditableServices()}
                                                                                valueField='id'
                                                                                textField='name'
                                                                                tagComponent={({ item }) => (
                                                                                    <span
                                                                                        className='tag'
                                                                                        style={{
                                                                                            backgroundColor: item.colorHexValue,
                                                                                            borderColor: item.colorHexValue,
                                                                                        }}
                                                                                    >
                                                                                        <strong>{item.code}</strong>
                                                                                    </span>
                                                                                )}
                                                                                onChange={handleServiceSelection}
                                                                            />}</Observer>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </> :
                                                            <div className='row'>
                                                                <div className='col-12 mb-0'>
                                                                    <div className='form-group validate validate-required'>
                                                                        <label htmlFor='new-appointment-services' className='required'><small>Services</small></label>
                                                                        <Observer>{() => <Multiselect
                                                                            allowCreate={false}
                                                                            data={renderServices()}
                                                                            defaultValue={appointment.data.services}
                                                                            valueField='id'
                                                                            textField='name'
                                                                            tagComponent={({ item }) => (
                                                                                <span
                                                                                    className='tag'
                                                                                    style={{
                                                                                        backgroundColor: item.colorHexValue,
                                                                                        borderColor: item.colorHexValue,
                                                                                    }}
                                                                                >
                                                                                    <strong>{item.code}</strong>
                                                                                </span>
                                                                            )}
                                                                            onChange={handleServiceSelection}
                                                                        />}</Observer>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }
                                                </section>
                                            </div>
                                        </FadeIn> : renderQuickDrawerLoading()
                                }
                            </div>
                            <Observer>{() =>
                                appointment.isReady ?
                                    <div className='quick-drawer-action'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='float-right'>
                                                    <button
                                                        type='button'
                                                        className='btn btn-link btn-cancel mr-2'
                                                        onClick={handleCancel}
                                                    >Cancel</button>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-success'
                                                    >Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : null
                            }</Observer>
                        </div>
                    </fieldset>
                </>
            }</Observer>
        </form>
        <BodyEnd>
            <Observer>{() =>
                <ConfirmModal
                    icon={<i className={`${oh.getIcon('appointment', 'new')} text-warning mr-2`}></i>}
                    message={<>{appointment.data && appointment.data.customer ? ph.getPreferredFirstName(appointment.data.customer) : ''}&nbsp;<strong>might not be eligible</strong>&nbsp;for the service(s).  Continue?</>}
                    option1ClassName={'btn btn-warning shadow-0 bootbox-accept'}
                    show={confirmIneligibles}
                    onOption1Click={handleIneligibleConfirm}
                    onCancel={handleIneligibleCancel}
                />
            }</Observer>
        </BodyEnd>
    </>
}

export default UpdateServices;