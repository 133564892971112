import * as fn from './_functions';
import moment, { isMoment } from 'moment';
import * as StorageKeys from '../constants/storageKeys';

export const COMMON_RELATIONSHIP_TYPES = ['Father', 'Mother', 'Son', 'Daughter', 'Brother', 'Sister', 'Husband', 'Wife', 'Grandfather', 'Grandmother', 'Grandson', 'Granddaughter', 'Guardian'];
export const SALUTATION_TYPES = ['Dr', 'Miss', 'Mr', 'Mrs', 'Ms'];

export const parseName = (name) => {
    if (!name || !/^[a-zA-Z\s.,'-]+$/.test(name)) return null;

    if (name.lastIndexOf(',') >= 0) {
        name = name.split(',').reverse().join(' ');
    }

    let nameParts = name.split(' ');

    if (nameParts && nameParts.length > 0) {
        const salutation = SALUTATION_TYPES.filter(t => t === nameParts[0] || t === `${nameParts[0]}.`)[0];

        if (!!salutation) {
            nameParts = nameParts.slice(1);
        }

        switch (nameParts.length) {
            case 1:
                return {
                    salutation: salutation,
                    firstName: nameParts[0],
                    middleName: null,
                    lastName: null,
                };

            case 2:
                return {
                    salutation: salutation,
                    firstName: nameParts[0],
                    middleName: null,
                    lastName: nameParts[1],
                };

            case 3:
                return {
                    salutation: salutation,
                    firstName: nameParts[0],
                    middleName: nameParts[1],
                    lastName: nameParts[2],
                };

            default:
                return {
                    salutation: salutation,
                    firstName: nameParts[0],
                    middleName: nameParts[1],
                    lastName: nameParts.slice(2).join(' '),
                }
        }
    }
}

export const getFullName = (obj, includePreferredFirstName) => {
    let fullName;

    if (!!obj.systemDisplayName) {
        return obj.systemDisplayName;
    }

    if (obj.middleName) {
        fullName = `${fn.isNullOrUndefined(obj.firstName) ? '' : obj.firstName}${includePreferredFirstName === true && obj.preferredFirstName && obj.preferredFirstName !== obj.firstName ? ` (${obj.preferredFirstName})` : ''} ${obj.middleName.substring(0, 1)}. ${(fn.isNullOrUndefined(obj.lastName) ? '' : obj.lastName)}`.trim();
    }
    else {
        fullName = `${fn.isNullOrUndefined(obj.firstName) ? '' : obj.firstName}${includePreferredFirstName === true && obj.preferredFirstName && obj.preferredFirstName !== obj.firstName ? ` (${obj.preferredFirstName})` : ''} ${(fn.isNullOrUndefined(obj.lastName) ? '' : obj.lastName)}`.trim();
    }

    if (obj.suffix) {
        fullName = `${fullName} ${obj.suffix}.`;
    }

    if (obj.salutation && obj.salutation === 'Dr') {
        fullName = `Dr. ${fullName}`;
    }

    return fullName;
}

export const getLastFirstMiddleName = (obj, includePreferredFirstName) => {
    let lastFirstMiddleName = getLastFirstName(obj, includePreferredFirstName);

    if (!!obj.systemDisplayName) {
        return obj.systemDisplayName;
    }

    return `${lastFirstMiddleName}${(obj.middleName ? ` ${obj.middleName}` : '')}`;
}

export const getFirstLastName = (obj, includePreferredFirstName) => {
    let firstlastname;
    let lastName = fn.isNullOrUndefined(obj.lastName) ? '' : obj.lastName;

    if (!!obj.systemDisplayName) {
        return obj.systemDisplayName;
    }

    if (obj.suffix) {
        lastName = `${lastName} ${obj.suffix}.`;
    }

    firstlastname = `${fn.isNullOrUndefined(obj.firstName) ? '' : obj.firstName}${includePreferredFirstName === true && obj.preferredFirstName && obj.preferredFirstName !== obj.firstName ? ` (${obj.preferredFirstName})` : ''} ${lastName}`;

    if (obj.salutation && obj.salutation === 'Dr') {
        firstlastname = `Dr. ${firstlastname}`;
    }

    return firstlastname;
}

export const getPreferredFullName = (obj, includeFirstName) => {
    let fullName;
    let firstName = obj.preferredFirstName ? obj.preferredFirstName : fn.isNullOrUndefined(obj.firstName) ? '' : obj.firstName;

    if (!!obj.systemDisplayName) {
        return obj.systemDisplayName;
    }

    if (obj.middleName) {
        fullName = `${firstName}${includeFirstName === true && obj.preferredFirstName && obj.preferredFirstName !== obj.firstName ? ` (${fn.isNullOrUndefined(obj.firstName) ? '' : obj.firstName})` : ''} ${obj.middleName.substring(0, 1)}. ${(fn.isNullOrUndefined(obj.lastName) ? '' : obj.lastName)}`.trim();
    }
    else {
        fullName = `${firstName}${includeFirstName === true && obj.preferredFirstName && obj.preferredFirstName !== obj.firstName ? ` (${fn.isNullOrUndefined(obj.firstName) ? '' : obj.firstName})` : ''} ${(fn.isNullOrUndefined(obj.lastName) ? '' : obj.lastName)}`.trim();
    }

    if (obj.suffix) {
        fullName = `${fullName} ${obj.suffix}.`;
    }

    if (obj.salutation && obj.salutation === 'Dr') {
        fullName = `Dr. ${fullName}`;
    }

    return fullName;
}

export const getPreferredFirstLastName = (obj, includeFirstName) => {
    if (!obj) return '';

    let firstlastname;
    let firstName = obj.preferredFirstName ? obj.preferredFirstName : fn.isNullOrUndefined(obj.firstName) ? '' : obj.firstName;
    let lastName = fn.isNullOrUndefined(obj.lastName) ? '' : obj.lastName;

    if (!!obj.systemDisplayName) {
        return obj.systemDisplayName;
    }

    if (obj.suffix) {
        lastName = `${lastName} ${obj.suffix}.`;
    }

    firstlastname = `${firstName}${includeFirstName === true && obj.preferredFirstName && obj.preferredFirstName !== obj.firstName ? ` (${fn.isNullOrUndefined(obj.firstName) ? '' : obj.firstName})` : ''} ${lastName}`;

    if (obj.salutation && obj.salutation === 'Dr') {
        firstlastname = `Dr. ${firstlastname}`;
    }

    return firstlastname;
}

export const getLastFirstName = (obj, includePreferredFirstName) => {
    let lastName = fn.isNullOrUndefined(obj.lastName) ? '' : obj.lastName;

    if (!!obj.systemDisplayName) {
        return obj.systemDisplayName;
    }

    if (obj.suffix) {
        lastName = `${lastName} ${obj.suffix}.`;
    }

    return `${lastName}, ${fn.isNullOrUndefined(obj.firstName) ? '' : obj.firstName}${includePreferredFirstName === true && obj.preferredFirstName && obj.preferredFirstName !== obj.firstName ? ` (${obj.preferredFirstName})` : ''}`;
}

export const getPreferredLastFirstName = (obj, includeFirstName) => {
    let lastFirstName;
    let firstName = obj && obj.preferredFirstName && obj.preferredFirstName !== obj.firstName ? obj.preferredFirstName : fn.isNullOrUndefined(obj.firstName) ? '' : obj.firstName;
    let lastName = obj && fn.isNullOrUndefined(obj.lastName) ? '' : obj.lastName;

    if (!!obj.systemDisplayName) {
        return obj.systemDisplayName;
    }

    if (obj.suffix) {
        lastName = `${lastName} ${obj.suffix}.`;
    }

    lastFirstName = `${lastName}, ${firstName}${includeFirstName === true && obj.preferredFirstName && obj.preferredFirstName !== obj.firstName ? ` (${fn.isNullOrUndefined(obj.firstName) ? '' : obj.firstName})` : ''}`;
    return lastFirstName;
}

export const getPreferredLastFirstMiddleName = (obj, includeFirstName) => {
    let preferredLastFirstMiddleName = getLastFirstName(obj, includeFirstName);

    if (!!obj.systemDisplayName) {
        return obj.systemDisplayName;
    }

    return `${preferredLastFirstMiddleName}${(obj.middleName ? ` ${obj.middleName}` : '')}`;
}

export const getPreferredFirstName = (obj) => {
    if (!obj) return '';
    let firstName = obj && obj.preferredFirstName ? obj.preferredFirstName : fn.isNullOrUndefined(obj.firstName) ? '' : obj.firstName;

    if (!!obj.systemDisplayName) {
        return obj.systemDisplayName;
    }

    return firstName;
}

export const getPreferredFirstNameLastInitial = (obj) => {
    if (!obj) return '';
    let firstName = obj && obj.preferredFirstName ? obj.preferredFirstName : fn.isNullOrUndefined(obj.firstName) ? '' : obj.firstName;
    let lastInitial = obj && obj.lastName && obj.lastName.length > 0 ? `${obj.lastName[0]}.` : '';
    if (!!obj.systemDisplayName) {
        return obj.systemDisplayName;
    }

    return (firstName + ' ' + lastInitial).trim();
}

export const getInitials = obj => {
    let initials;

    if (obj && typeof (obj) === 'string') {
        const parts = obj.split(' ');

        if (parts.length > 1) {
            initials = `${parts[0].substring(0, 1)}${parts[1].substring(0, 1)}`;
        }
        else {
            initials = `${parts[0].substring(0, 1)}${parts[0].length > 1 ? parts[0].substring(1, 1) : ''}`;
        }
    }
    else {
        if (obj && !fn.isNullOrUndefined(fn.isNullOrUndefined(obj.firstName) ? '' : obj.firstName)) {
            initials = `${initials ? initials : ''}${fn.isNullOrUndefined(obj.firstName) ? '' : obj.firstName.substring(0, 1).toUpperCase()}`;
        }
        if (obj && !fn.isNullOrUndefined(fn.isNullOrUndefined(obj.lastName) ? '' : obj.lastName)) {
            initials = `${initials ? initials : ''}${fn.isNullOrUndefined(obj.lastName) ? '' : obj.lastName.substring(0, 1).toUpperCase()}`;
        }
    }

    return initials;
}

export const getAge = (dateOfBirth, asOfDate, fullDescription) => {
    if (dateOfBirth) {
        const year = fullDescription === true ? 'years old' : 'yr.';
        const month = fullDescription === true ? 'months old' : 'mo.';
        const week = fullDescription === true ? 'weeks old' : 'wk.';
        const day = fullDescription === true ? 'days old' : 'day';
        const dob = isMoment(dateOfBirth) ? dateOfBirth.clone() : moment(dateOfBirth);
        const aod = asOfDate ? (isMoment(asOfDate) ? asOfDate.clone() : moment(asOfDate)) : moment();

        if (dob.isValid) {
            const years = aod.clone().diff(dob, 'years');
            const months = aod.clone().diff(dob, 'months');
            const weeks = aod.clone().diff(dob, 'weeks');
            const days = aod.clone().diff(dob, 'days');
            return years > 0 ? `${years} ${year}` : (months > 0 ? `${months} ${month}` : (weeks > 0 ? `${weeks} ${week}` : (days > 1 ? `${days} ${day}` : '')));
        }
    }

    return '';
}

export const getSexCode = (sex) => {
    if (!sex) return '';

    switch (sex) {
        case 'Male':
            return 'M';

        case 'Female':
            return 'F';

        default:
            return '';
    }
}

export const getGenderCode = (gender, sex) => {
    let code;
    const sexCode = getSexCode(sex);

    switch (gender) {
        case 'Male':
            code = 'M';
            break;

        case 'Female':
            code = 'F';
            break;

        case 'NonBinary':
            return 'NB';

        default:
            return sexCode;
    }

    if (sexCode && sexCode !== code) {
        code = `T${code}`;
    }

    return code;
}

export const getSexDescription = (sex) => {
    if (!sex) return '';

    switch (sex) {
        case 'Male':
            return 'Male';

        case 'Female':
            return 'Female';

        default:
            return '';
    }
}

export const getSexDisplay = (person) => {
    if (!person || !person.sex) return '';
    return getSexDescription(person.sex);
}

export const getGenderDescription = (gender) => {
    if (!gender) return '';

    switch (gender) {
        case 'Male':
            return 'Male';

        case 'Female':
            return 'Female';

        case 'NonBinary':
            return 'Non-Binary';

        case 'Other':
            return 'Other';

        default:
            return '';
    }
}

export const getGenderDisplay = (person) => {
    if (!person || !person.gender) return '';

    if (person.gender === 'Other' && !!person.genderOther) {
        return person.genderOther;
    }

    return getGenderDescription(person.gender);
}

export const getPronounDescription = (pronoun) => {
    if (!pronoun) return '';

    switch (pronoun) {
        case 'HeHim':
            return "He/Him";

        case 'HeThey':
            return "He/They";

        case 'SheHer':
            return 'She/Her';

        case 'SheThey':
            return "She/They";

        case 'AeAer':
            return "Ae/Aer";

        case 'FaeFaer':
            return "Fae/Faer";

        case 'EyEm':
            return "Ey/Em";

        case 'PerPer':
            return "Per/Per";

        case 'VeVer':
            return "Ve/Ver";

        case 'XeXem':
            return "Xe/Xem";

        case 'ZeHir':
            return "Ze/Hir";

        case 'TheyThem':
            return 'They/Them';

        case 'Other':
            return 'Other';

        default:
            break;
    }

    return '';
}

export const getPronounDisplay = (person) => {
    if (!person || !person.pronoun) {
        return '';
    }

    if (person.pronoun === 'Other' && !!person.pronounOther) {
        return person.pronounOther;
    }

    return getPronounDescription(person.pronoun);
}

export const getSexGenderPronounDisplay = (person) => {
    if (!person) return '';

    if (!!person.pronoun) {
        return getPronounDisplay(person);
    }
    else if (!!person.gender) {
        return getGenderDisplay(person);
    }
    else if (!!person.sex) {
        return getSexDisplay(person);
    }

    return ''
}

export const getPhoneIcon = type => {
    if (!type) return;

    switch (type.toLowerCase()) {
        case 'mobile':
            return 'fal fa-mobile-alt';

        case 'home':
            return 'fal fa-phone-rotary';

        case 'work':
            return 'fal fa-phone-office';

        default:
            return;
    }
}

export const getHealthCardType = healthcard => {
    if (!healthcard) return null;

    const cleansedHealthcard = healthcard.replace(/\W/g, '');
    const length = cleansedHealthcard.length;

    switch (length) {
        case 10:
            if (fn.regexTester.ohipOld.test(cleansedHealthcard)) {
                return 'RedWhite';
            }
            return null;

        case 11:
            if (fn.regexTester.ohipOld.test(cleansedHealthcard)) {
                return 'RedWhiteN';
            }
            return null;

        case 12:
            if (fn.regexTester.ohip.test(cleansedHealthcard)) {
                return 'Green';
            }
            return null;

        default:
            return null;
    }
}

export const isHealthCard = (value) => {
    return !!parseHealthCard(value)
}

export const parseHealthCard = (value) => {
    if (!value) return null;

    const testValue = value.replace(/\W/g, '').trim();

    if (fn.regexTester.ohip.test(testValue) || fn.regexTester.ohipOld.test(testValue)) {
        return testValue;
    }

    return null;
}

export const formatHealthCard = healthcard => {
    if (!healthcard) return healthcard;

    const cleansedHealthcard = healthcard.replace(/\W/g, '');
    const healthcardType = getHealthCardType(healthcard);

    switch (healthcardType) {
        case 'RedWhite':
            return `${cleansedHealthcard.substring(0, 4)} ${cleansedHealthcard.substring(4, 7)} ${cleansedHealthcard.substring(7, 10)}`;

        case 'RedWhiteN':
            return `${cleansedHealthcard.substring(0, 4)} ${cleansedHealthcard.substring(4, 7)} ${cleansedHealthcard.substring(7, 10)} N`;

        case 'Green':
            return `${cleansedHealthcard.substring(0, 4)}-${cleansedHealthcard.substring(4, 7)}-${cleansedHealthcard.substring(7, 10)}-${cleansedHealthcard.substring(10, 12)}`.toUpperCase();

        default:
            return healthcard;
    }
}

export const getReversedRelationship = (party1, relationship) => {
    if (party1 && relationship) {
        const gender = getGenderDescription(party1.gender, party1.sex);

        switch (relationship) {
            case 'Grandparent':
            case 'Grandfather':
            case 'Grandmother':
                switch (gender) {
                    case 'Male':
                        return 'Grandson';

                    case 'Female':
                        return 'Granddaughter';

                    default:
                        return 'Grandchild';
                }

            case 'Parent':
            case 'Father':
            case 'Mother':
                switch (gender) {
                    case 'Male':
                        return 'Son';

                    case 'Female':
                        return 'Daughter';

                    default:
                        return 'Child';
                }

            case 'Child':
            case 'Son':
            case 'Daughter':
                switch (gender) {
                    case 'Male':
                        return 'Father';

                    case 'Female':
                        return 'Mother';

                    default:
                        return 'Parent';
                }

            case 'Sibling':
            case 'Brother':
            case 'Sister':
                switch (gender) {
                    case 'Male':
                        return 'Brother';

                    case 'Female':
                        return 'Sister';

                    default:
                        return 'Sibling';
                }

            case 'Grandchild':
            case 'Grandson':
            case 'Granddaughter':
                switch (gender) {
                    case 'Male':
                        return 'Grandfather';

                    case 'Female':
                        return 'Grandmother';

                    default:
                        return 'Grandparent';
                }

            case 'Relative':
                return 'Relative';

            case 'Uncle':
            case 'Aunt':
                switch (gender) {
                    case 'Male':
                        return 'Nephew';

                    case 'Female':
                        return 'Niece';

                    default:
                        return 'Relative';
                }

            case 'Nephew':
            case 'Niece':
                switch (gender) {
                    case 'Male':
                        return 'Uncle';

                    case 'Female':
                        return 'Aunt';

                    default:
                        return 'Relative';
                }

            case 'Stepparent':
            case 'Stepfather':
            case 'Stepmother':
                switch (gender) {
                    case 'Male':
                        return 'Stepson';

                    case 'Female':
                        return 'Stepdaughter';

                    default:
                        return 'Stepchild';
                }

            case 'Stepchild':
            case 'Stepson':
            case 'Stepdaughter':
                switch (gender) {
                    case 'Male':
                        return 'Stepfather';

                    case 'Female':
                        return 'Stepdaughter';

                    default:
                        return 'Stepparent';
                }

            case 'Stepsibling':
            case 'Stepbrother':
            case 'Stepsister':
                switch (gender) {
                    case 'Male':
                        return 'Stepbrother';

                    case 'Female':
                        return 'Stepsister';

                    default:
                        return 'Stepsibling';
                }

            case 'InLaw':
            case 'FatherInLaw':
            case 'MotherInLaw':
                switch (gender) {
                    case 'Male':
                        return 'SonInLaw';

                    case 'Female':
                        return 'DaughterInLaw';

                    default:
                        return 'ChildInLaw';
                }

            case 'SonInLaw':
            case 'DaughterInLaw':
            case 'ChildInLaw':
                switch (gender) {
                    case 'Male':
                        return 'FatherInLaw';

                    case 'Female':
                        return 'MotherInLaw';

                    default:
                        return 'InLaw';
                }

            case 'SiblingInLaw':
            case 'BrotherInLaw':
            case 'SisterInLaw':
                switch (gender) {
                    case 'Male':
                        return 'BrotherInLaw';

                    case 'Female':
                        return 'SisterInLaw';

                    default:
                        return 'SiblingInLaw';
                }

            case 'Husband':
            case 'Wife':
                switch (gender) {
                    case 'Male':
                        return 'Husband';

                    case 'Female':
                        return 'Wife';

                    default:
                        break;
                }
                break;

            case 'CommonLaw':
                return 'CommonLaw';

            case 'Partner':
                return 'Partner';

            case 'Spouse':
                return 'Spouse';

            case 'Guardian':
                return 'Ward';

            case 'Caregiver':
                return 'Client';

            case 'Ward':
                return 'Guardian';

            case 'Client':
                return 'Caregiver';

            default:
                return null;
        }
    }

    return null;
}

export const getFamilyRelationship = (relationship1, relationship2) => {
    const mapping = JSON.parse(window.localStorage.getItem(StorageKeys.REFERENCE_DATA)).filter(d => d.name === 'RelationshipMapping')[0].value;

    if (relationship1 && relationship2 && mapping) {
        var party1To2 = mapping.filter(m => m.relationship1 === relationship1 && m.relationship2 === relationship2)[0];

        if (party1To2) {
            return party1To2.party2Relationship;
        }

        var party2To1 = mapping.filter(m => m.relationship1 === relationship2 && m.relationship2 === relationship1)[0];

        if (party2To1 != null) {
            return party2To1.party1Relationship;
        }
    }

    return null;
}