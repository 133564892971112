import React, { Component } from 'react';
import { Popup } from 'devextreme-react';
import { GlobalHotKeys } from 'react-hotkeys';
import uuid from 'react-uuid';

import * as StorageKey from '../../../constants/storageKeys';
import * as rts from '../../../constants/routes';
import * as fn from '../../../utilities/_functions';


import './RefreshModal.scss';

const REFRESH_POPUP_ID = '__refresh-popup';
const REFRESH_MODAL_GRID_WIDTH = 560;

export default class RefreshModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            display: false,
            zIndex: null,
            isBreakingUpdate: false,
        };
        this.handleClose = this.handleClose.bind(this);
    }

    componentWillUnmount() {
        if (this.props.onClose) { this.props.onClose(); }
    }

    show = (isBreakingUpdate = false) => {
        const zIndex = fn.getHighestZIndex() + 1;
        this.setState({ isBreakingUpdate: isBreakingUpdate, display: true, zIndex: zIndex });
    }

    close = () => {
        this.handleClose();
    }

    handleClose = event => {
        this.setState({ display: false, zIndex: null, isBreakingUpdate: false });
    }

    handleLater = event => {
        const now = new Date();
        const expiresAt = now.setMinutes(now.getMinutes() + 15);
        window.localStorage.setItem(StorageKey.REFRESH_LATER_EXPIRES_AT, expiresAt);
        this.handleClose();
    }

    handleRefresh = event => {
        const sessionId = uuid();
        window.localStorage.setItem(StorageKey.SESSION_ID, sessionId);
        window.location.reload();
    }

    renderTitle = () => {
        return <div className='popup-title-draggable'></div>
    }

    renderContent = () => {
        const { isBreakingUpdate } = this.state;

        return <>
            <div className='popup-title'>
                <div className='actions left-actions pt-1'>
                    <ul className='ml-2'>
                        <li>
                            <h4 className='fw-500 mb-0'>
                                Update required
                            </h4>
                        </li>
                    </ul>
                </div>
                {
                    !isBreakingUpdate ?
                        <div className='actions right-actions'>
                            <ul>
                                <li>
                                    <button
                                        type='button'
                                        className='btn btn-icon btn-close'
                                        onClick={this.handleLater}
                                    >
                                        <i className='close-icon fal fa-times fs-xl'></i>
                                    </button>
                                </li>
                            </ul>
                        </div> : null
                }
            </div>
            <div className='popup-body p-4'>
                <p className='fs-lg mb-0'>
                    {
                        isBreakingUpdate ?
                            <>An important update has been applied to GENKI and requires you to log in again.</> :
                            <>GENKI has been updated and needs to refresh.  If you have unsaved changes, you can continue working and refresh at a later time.</>
                    }
                </p>
            </div>
            <div className='popup-footer px-4 pb-4'>
                <div className='d-flex justify-content-end'>
                    {
                        isBreakingUpdate ?
                            <>
                                <a
                                    href={rts.Logout}
                                    className='btn btn-primary ml-2'
                                >Log in again</a>
                            </> :
                            <>
                                <button
                                    type='button'
                                    className='btn btn-light ml-2'
                                    onClick={this.handleLater}
                                >Later</button>
                                <button
                                    type='button'
                                    className='btn btn-primary ml-2'
                                    onClick={this.handleRefresh}
                                >Refresh</button>
                            </>
                    }
                </div>
            </div>
        </>
    }

    render() {
        return <>
            {
                this.state.display ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                this.handleClose(event);
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
            <Popup
                wrapperAttr={{ id: REFRESH_POPUP_ID, class: 'dx-refresh-popup' }}
                animation={{
                    show: {
                        type: 'pop',
                        duration: 200,
                        from: { opacity: 0, scale: 0 },
                        to: { opacity: 1, scale: 1 }
                    },
                    hide: {
                        type: 'pop',
                        duration: 200,
                        from: { opacity: 1, scale: 1 },
                        to: { opacity: 0, scale: 0 }
                    }
                }}
                visible={this.state.display}
                shading={true}
                shadingColor="rgba(0, 0, 0, 0.75)"
                width={REFRESH_MODAL_GRID_WIDTH}
                height={'auto'}
                dragEnabled={false}
                showTitle={true}
                titleRender={this.renderTitle}
                contentRender={this.renderContent}
                onHiding={this.handleClose}
            />
        </>
    }
}