import React, { useEffect, useContext, useState, useRef, Fragment } from 'react';
import FadeIn from 'react-fade-in';
import { GlobalHotKeys } from 'react-hotkeys';
import { Observer } from 'mobx-react-lite';
import { Mention } from 'devextreme-react/html-editor';
import { Combobox } from 'react-widgets'

import BodyEnd from '../../../../_shared/BodyEnd';
import ConfirmModal from '../../../../_shared/ConfirmModal';
import QuickDrawerHeader from '../../../../_shared/QuickDrawerHeader';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../../../_shared/QuickDrawer';
import RichTextEditor from '../../../../_shared/RichTextEditor';
import HtmlEditor from '../../../../_shared/HtmlEditor';

import ExamDatasetViewStore from '../../../../../../stores/ExamDatasetViewStore';
import QuickDrawerStore from '../../../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../../../constants/errorMessages';
import * as fn from '../../../../../../utilities/_functions';
import * as oh from '../../../../../../utilities/operationHelper';

import './UpdateExamDataset.scss';

function UpdateExamDataset(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const expressionRef = useRef(null);
    const examDataset = useContext(ExamDatasetViewStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [showExpression, setShowExpression] = useState(false);
    const [definition, setDefinition] = useState([]);
    const [ready, setReady] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    useEffect(() => {
        focusTimer.current = setTimeout(() => {
            if (examDataset.data && examDataset.data.definition && examDataset.data.definition.length > 0) {
                setDefinition(examDataset.data.definition);
            }
            setReady(true);
            quickDrawerFocus(props.drawer);
        }, 100)

        return (() => {
            isMounted.current = false;
            examDataset.clear();
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        })
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (examDataset.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (!!expressionRef.current) {
            const updateDataset = [...definition];
            updateDataset.push(expressionRef.current);
            setDefinition(updateDataset);
            expressionRef.current = null;
            examDataset.hasUnsavedChanges = true;
            setShowExpression(false);
        }
        else {
            handleSave(event);
        }
    }

    const handleSave = event => {
        examDataset.data.definition = definition;
        examDataset.save()
            .then(data => {
                if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                    if (data) {
                        data.container = props.extraProps && props.extraProps.container ? props.extraProps.container : null;
                        props.onSuccess(event, { updated: true, data: data });
                    } else {
                        props.onSuccess(event, { updated: false });
                    }
                }
            })
    }

    const handleDelete = () => {
        setConfirmDelete(true);
    }

    const handleConfirmDelete = () => {
        examDataset.delete()
            .then(() => {
                setConfirmDelete(false);
                handleCancel();
            })
    }

    const handleConfirmDeleteCancel = () => {
        setConfirmDelete(false);
    }

    const handleCategoriesChange = value => {
        examDataset.data.category = value;
        examDataset.hasUnsavedChanges = true;
    }

    const handleCategoryChange = event => {
        examDataset.data.category = event.target.value;
        examDataset.hasUnsavedChanges = true;
    }

    const handleLabelChange = event => {
        examDataset.data.label = event.target.value;
        examDataset.hasUnsavedChanges = true;
    }

    const handleDescriptionChange = content => {
        const html = content;
        examDataset.data.descriptionHtml = ((html === '<p><br></p>') ? null : html);
        examDataset.hasUnsavedChanges = true;
    }

    const handleExpressionChange = (e) => {
        expressionRef.current = e.value;
    }

    const handleAddDataset = (e) => {
        setShowExpression(true);
    }

    const handleRemoveDataset = (e, index) => {
        const updateDefinition = [...definition];
        updateDefinition.splice(index, 1);
        setDefinition(updateDefinition);
        examDataset.hasUnsavedChanges = true;
    }

    const handleAddExpressionToDataset = () => {
        if (!!expressionRef.current) {
            const updateDataset = [...definition];
            updateDataset.push(expressionRef.current);
            setDefinition(updateDataset);
        }

        expressionRef.current = null;
        examDataset.hasUnsavedChanges = true;
        setShowExpression(false);
    }

    return <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form onSubmit={handleSubmit}>
            <fieldset disabled={examDataset.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('exam-dataset', 'default')}
                        action='Update'
                        category='Exam Dataset'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        {
                            ready && examDataset.data ?
                                <FadeIn>
                                    <div className='update-exam-dataset body-content'>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label className='required' htmlFor='update-exam-dataset-name'><small>Name</small></label>
                                                        <input
                                                            id='update-exam-dataset-name'
                                                            type='text'
                                                            maxLength={100}
                                                            className='form-control'
                                                            autoComplete={'off'}
                                                            disabled={true}
                                                            value={examDataset.data.name ? examDataset.data.name : ''}
                                                        />
                                                        <small className='text-gray-700'><strong>Important:</strong> Name must be unique across all inputs.</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label className='required' htmlFor='update-exam-dataset-category'><small>Category</small></label>
                                                        <Observer>{() =>
                                                            <>
                                                                {
                                                                    examDataset.categories && examDataset.categories.length > 0 ?
                                                                        <Combobox
                                                                            className='placeholder placeholder-sm'
                                                                            data={examDataset.categories}
                                                                            filter={(item, value) => {
                                                                                return item.toLowerCase().startsWith(value.toLowerCase());
                                                                            }}
                                                                            value={examDataset.data.category}
                                                                            itemComponent={({ item }) => (
                                                                                item ? <>{item}</> : <></>
                                                                            )}
                                                                            valueComponent={({ item }) => (
                                                                                item ? <>{item}</> : <></>
                                                                            )}
                                                                            onChange={handleCategoriesChange}
                                                                        /> :
                                                                        <input
                                                                            id='update-exam-dataset-category'
                                                                            type='text'
                                                                            maxLength={100}
                                                                            className='form-control'
                                                                            autoComplete={'off'}
                                                                            value={examDataset.data.category ? examDataset.data.category : ''}
                                                                            onChange={handleCategoryChange}
                                                                        />
                                                                }
                                                            </>
                                                        }</Observer>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label className='required' htmlFor='update-exam-dataset-label'><small>Label</small></label>
                                                        <Observer>{() =>
                                                            <input
                                                                id='update-exam-dataset-label'
                                                                type='text'
                                                                maxLength={100}
                                                                className='form-control'
                                                                autoComplete={'off'}
                                                                value={examDataset.data.label ? examDataset.data.label : ''}
                                                                onChange={handleLabelChange}
                                                            />
                                                        }</Observer>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row datasets'>
                                                <div className='col-12'>
                                                    <label><small>Dataset(s)</small></label>
                                                    <ul
                                                        className='list-inline no-style m-0 mb-1'
                                                    >
                                                        <Observer>{() =>
                                                            <>
                                                                {
                                                                    definition && definition.length > 0 ?
                                                                        definition.map((d, di) => {
                                                                            return <Fragment
                                                                                key={`new_exam_dataset_${di}`}
                                                                            >
                                                                                <li className='list-inline-item m-0 mr-1 number'>{di + 1}.&nbsp;</li>
                                                                                <li className='list-inline-item m-0 expression'>
                                                                                    <div className='border-1 border-faded html' dangerouslySetInnerHTML={{ __html: d }}></div>
                                                                                </li>
                                                                                <li className='list-inline-item m-0 ml-2'>
                                                                                    <button
                                                                                        type='button'
                                                                                        className='btn btn-link btn-icon btn-sm'
                                                                                        onClick={e => { handleRemoveDataset(e, di) }}
                                                                                    >
                                                                                        <i className='fal fa-minus-circle text-danger'></i>
                                                                                    </button>
                                                                                </li>
                                                                            </Fragment>
                                                                        }) : null
                                                                }
                                                            </>
                                                        }</Observer>
                                                        {
                                                            showExpression ? <>
                                                                <li className='list-inline-item m-0 mr-1 number'>{definition.length + 1}.&nbsp;</li>
                                                                <li className='list-inline-item m-0 expression'>
                                                                    <HtmlEditor
                                                                        onValueChanged={handleExpressionChange}
                                                                    >
                                                                        <Mention
                                                                            marker={'#'}
                                                                            minSearchLength={2}
                                                                            dataSource={(props && props.extraProps && props.extraProps.examDataKeys ? props.extraProps.examDataKeys : [])}
                                                                            displayExpr={'id'}
                                                                            valueExpr={'id'}
                                                                            searchExpr={['id', 'display']}
                                                                        />
                                                                    </HtmlEditor>
                                                                </li>
                                                                <li className='list-inline-item m-0 ml-2'>
                                                                    <button
                                                                        type='button'
                                                                        className='btn btn-link btn-icon btn-sm'
                                                                        onClick={handleAddExpressionToDataset}
                                                                    >
                                                                        <i className='fal fa-check text-success'></i>
                                                                    </button>
                                                                </li>
                                                            </> : null
                                                        }
                                                    </ul>
                                                    <button
                                                        type='button'
                                                        className='btn btn-link btn-icon-left btn-sm mb-2'
                                                        disabled={showExpression}
                                                        onClick={handleAddDataset}
                                                    >
                                                        <i className='fal fa-plus-circle'></i> Add dataset
                                                    </button>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            {
                                                showAdvanced ?
                                                    <>
                                                        <FadeIn>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='form-group mb-4'>
                                                                        <label htmlFor='update-exam-dataset-description'><small>Description</small></label>
                                                                        <Observer>{() =>
                                                                            <RichTextEditor
                                                                                mode='none'
                                                                                disableTab={true}
                                                                                value={examDataset.data.descriptionHtml ? examDataset.data.descriptionHtml : ''}
                                                                                onChange={handleDescriptionChange}
                                                                            />
                                                                        }</Observer>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='form-group mb-4'>
                                                                        <label className='required' htmlFor='update-exam-dataset-key'><small>Key</small></label>
                                                                        <input
                                                                            id='update-exam-dataset-key'
                                                                            type='text'
                                                                            maxLength={100}
                                                                            className='form-control'
                                                                            autoComplete='off'
                                                                            disabled={true}
                                                                            value={examDataset.data.key ? examDataset.data.key : ''}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </FadeIn>
                                                    </> :
                                                    <div className='mt-4 pt-4 border-top'>
                                                        <button type='button' className='btn btn-link btn-sm p-0' onClick={() => { setShowAdvanced(true) }}>&raquo;	Show advanced options</button>
                                                    </div>
                                            }
                                        </section>
                                    </div>
                                </FadeIn> : renderQuickDrawerLoading()
                        }
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-danger mr-2'
                                        onClick={handleDelete}
                                    >Delete</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
        <BodyEnd>
            <ConfirmModal
                icon={<i className='fal fa-database text-danger mr-2'></i>}
                message={<>Continue to delete dataset {examDataset.data && examDataset.data.key ? `[${examDataset.data.key}]` : ''}? </>}
                show={confirmDelete}
                onOption1Click={handleConfirmDelete}
                onCancel={handleConfirmDeleteCancel}
            />
        </BodyEnd>
    </>
}

export default UpdateExamDataset;