import React, { useEffect, useContext, useState, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { Observer } from 'mobx-react-lite';
import MaskedInput from 'react-text-mask'
import { GlobalHotKeys } from 'react-hotkeys';
import moment from 'moment';

import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';

import PurchaseUpdateStore from '../../../../stores/PurchaseUpdateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as MaskKeys from '../../../../constants/maskKeys';
import * as fn from '../../../../utilities/_functions';
import * as oh from '../../../../utilities/operationHelper';
import * as ph from '../../../../utilities/personHelper';
import * as ah from '../../../../utilities/addressHelper';
import * as ch from '../../../../utilities/customerHelper';
import * as bh from '../../../../utilities/badgeHelper';

import api from '../../../../api';

import './UpdateCost.scss';

function UpdateCost(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const focusTimer = useRef(null);
    const purchase = useContext(PurchaseUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [privateInsurance, setPrivateInsurance] = useState(null);

    useEffect(() => {
        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100);

        if (!privateInsurance && purchase.data) {
            loadInsuranceData();
        }

        if (!!purchase.data && !!purchase.data.customer && !!purchase.data.remainingBalance) {
            if (!!purchase.data.customer.storeCreditBalance) {
                purchase.newPaymentMethod = 'StoreCredit';
                purchase.newPaymentAmount = purchase.data.customer.storeCreditBalance >= purchase.data.remainingBalance ? purchase.data.remainingBalance : purchase.data.customer.storeCreditBalance;
            }
            else {
                purchase.newPaymentAmount = purchase.data.remainingBalance;
            }
        }
        purchase.newPaymentDate = moment().startOf('day');

        return () => {
            isMounted.current = false;

            purchase.clearNewPayment();
            setPrivateInsurance(null);

            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const loadInsuranceData = () => {
        api.CustomerPrivateInsurances.search({
            parameters: [{
                field: 'CustomerId',
                value: purchase.data.customerId,
            }],
            sortByFields: [{
                field: 'CreatedDateUtc',
                direction: 'DESC',
            }],
            includeTotalCount: false,
        })
            .then(({ data }) => {
                if (isMounted.current) {
                    const insurance = data && data.result ? data.result[0] : null;
                    setPrivateInsurance(insurance);
                }
            })
    }

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (purchase.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleUnitCostChange = (event, item) => {
        const amount = event.target.value ? fn.parseCurrency(event.target.value) : null;
        purchase.updateUnitCost(item.id, amount);
        purchase.hasUnsavedChanges = true;
    }

    const handleSubmit = event => {
        event.preventDefault();

        const isUpdated = purchase.hasUnsavedChanges;
        
        purchase.saveUnitCost(true)
            .then(() => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, { updated: isUpdated });
                    }
                }
            })
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <Observer>{() =>
                <fieldset disabled={purchase.isSaving}>
                    <div className='purchase-update-cost-container quick-drawer'>
                        <QuickDrawerHeader
                            drawer={props.drawer}
                            icon={oh.getIcon('purchase', 'payment')}
                            action='Purchase'
                            category='Update cost'
                            className='purchases'
                            onCancel={handleCancel}
                        />
                        <div className='quick-drawer-body'>
                            <Observer>{() =>
                                <>
                                    {
                                        purchase.data ?
                                            <FadeIn>
                                                <div className='body-content'>
                                                    <section>
                                                        <div className='row'>
                                                            <div className='col-6'>
                                                                <h3 className='purchase-title'>Invoice Detail</h3>
                                                                <p>Invoice # <strong>{purchase.data.invoiceNumber}</strong></p>
                                                            </div>
                                                            <div className='col-6'>
                                                                <div className='text-right'>
                                                                    {bh.renderPurchaseBalanceStatus(purchase.data, 'text-truncate text-truncate-md fs-sm p-2')}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <Observer>{() =>
                                                        <>
                                                            {
                                                                purchase.data.customer ?
                                                                    <section className='customer'>
                                                                        <div className='row'>
                                                                            <div className='col-12'>
                                                                                <label><small>Billing Information</small></label>
                                                                            </div>
                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className='col-12'>
                                                                                <Observer>{() =>
                                                                                    <div
                                                                                        className='profile-wrapper'
                                                                                    >
                                                                                        <div className='profile'>
                                                                                            <span
                                                                                                className={`profile-image profile-initials rounded-circle d-flex text-white ${ch.getProfileColor(purchase.data.customer)} fw-500`}
                                                                                                title={purchase.data.customer.fullName}
                                                                                            >
                                                                                                {purchase.data.customer.initials}
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className='description flex-1'>
                                                                                            <div className='name'>{ph.getFullName(purchase.data.customer, true)}
                                                                                                {
                                                                                                    purchase.data.customer.dateOfBirth || purchase.data.customer.sex || purchase.data.customer.gender || purchase.data.customer.pronoun ?
                                                                                                        <small className='text-nowrap ml-2'>({`${ph.getAge(purchase.data.customer.dateOfBirth)} ${ph.getSexGenderPronounDisplay(purchase.data.customer)}`.trim()})</small> : null
                                                                                                }
                                                                                            </div>
                                                                                            {
                                                                                                purchase.data.billingAddress ?
                                                                                                    <div className='info'>{ah.getAddressHtml(purchase.data.billingAddress)}</div> : null
                                                                                            }
                                                                                            {
                                                                                                purchase.data.customerEmail ?
                                                                                                    <div className='info'>
                                                                                                        <a
                                                                                                            href={`mailto:${purchase.data.customerEmail}`}
                                                                                                        >{purchase.data.customerEmail}
                                                                                                        </a>
                                                                                                    </div> : null
                                                                                            }
                                                                                            {
                                                                                                purchase.data.customerPhoneNumber ?
                                                                                                    <div className='info'>
                                                                                                        <a
                                                                                                            href={`tel:${purchase.data.customerPhoneNumber}`}
                                                                                                        >{purchase.data.customerPhoneNumber}
                                                                                                        </a>
                                                                                                    </div> : null
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                }</Observer>
                                                                            </div>
                                                                        </div>
                                                                    </section> : null
                                                            }
                                                        </>
                                                    }</Observer>
                                                    <section>
                                                        <div className='row'>
                                                            <div className='col-4'>
                                                                <label><small>Invoice Total</small></label>
                                                                <Observer>{() =>
                                                                    <strong className='d-block fs-xl'>{fn.formatCurrency(purchase.data.total)}</strong>
                                                                }</Observer>
                                                            </div>
                                                            <div className='col-4'>
                                                                <label><small>Cost</small></label>
                                                                <Observer>{() =>
                                                                    <strong className='d-block fs-xl'>{fn.formatCurrency(purchase.data.totalCost)}</strong>
                                                                }</Observer>
                                                            </div>
                                                            <div className='col-4'>
                                                                <label><small>Profit</small></label>
                                                                <Observer>{() =>
                                                                    <strong className='d-block fs-xl'>{fn.formatCurrency((purchase.data.total - purchase.data.totalCost))}</strong>
                                                                }</Observer>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <div className='purchase-items'>
                                                        {
                                                            purchase.data.items && purchase.data.items.length > 0 ?
                                                                <>
                                                                    {
                                                                        purchase.data.items.map((i, ii) => {
                                                                            return i.type !== 'Service' ?
                                                                                <section key={`purchase-item-${ii}`}>
                                                                                    <div className='row mb-2'>
                                                                                        <div className='col-12'>
                                                                                            <small>{i.referenceType}</small>
                                                                                            <div className='fs-lg fw-500'>
                                                                                                {i.descriptor}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row'>
                                                                                        <div className='col-1'>
                                                                                            <label><small>&nbsp;</small></label>
                                                                                            <div
                                                                                                className='form-control text-right border-transparent px-0'
                                                                                            >
                                                                                                {i.quantity ? `${i.quantity}x` : ''}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='col-4'>
                                                                                            <label htmlFor='unit-cost-amount'><small>Unit Cost</small></label>
                                                                                            <Observer>{() =>
                                                                                                <MaskedInput
                                                                                                    id='unit-cost-amount'
                                                                                                    type='text'
                                                                                                    className='form-control currency-input'
                                                                                                    mask={MaskKeys.CURRENCY_POSITIVE_MASK}
                                                                                                    autoComplete='off'
                                                                                                    tabIndex={100 + ii}
                                                                                                    value={!fn.isNullOrUndefined(i.unitCost) ? i.unitCost : ''}
                                                                                                    onChange={(e) => { handleUnitCostChange(e, i) }}
                                                                                                />
                                                                                            }</Observer>
                                                                                        </div>
                                                                                        <div className='col-4'>
                                                                                            <label><small>Total Cost</small></label>
                                                                                            <Observer>{() =>
                                                                                                <div
                                                                                                    className='form-control border-transparent px-0 fw-500'
                                                                                                >
                                                                                                    {
                                                                                                        !fn.isNullOrUndefined(i.quantity) && !fn.isNullOrUndefined(i.unitCost) && i.quantity.toString().length > 0 && i.unitCost.toString().length > 0 ?
                                                                                                            `= ${fn.formatCurrency(i.quantity * i.unitCost)}` : ''
                                                                                                    }
                                                                                                </div>
                                                                                            }</Observer>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row'>
                                                                                        <div className='col-12'>
                                                                                            <hr className='m-0 mt-4 mb-0 w-100' />
                                                                                        </div>
                                                                                    </div>
                                                                                </section> : null
                                                                        })
                                                                    }
                                                                </> : null
                                                        }
                                                    </div>
                                                </div>
                                            </FadeIn> : renderQuickDrawerLoading()
                                    }
                                </>
                            }</Observer>
                        </div>
                        <div className='quick-drawer-action pl-3'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='float-right'>
                                        <button
                                            type='button'
                                            className='btn btn-link btn-cancel mr-2'
                                            onClick={handleCancel}
                                        >Cancel</button>
                                        <button
                                            type='submit'
                                            className='btn btn-success'
                                        >Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            }</Observer>
        </form>
    </>
}

export default UpdateCost;