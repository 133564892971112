import React, { Component } from 'react';
import { Popup } from 'devextreme-react';
import { GlobalHotKeys } from 'react-hotkeys';
import { isMoment } from 'moment';

import * as StorageKey from '../../../constants/storageKeys';
import * as fn from '../../../utilities/_functions';
import * as sys from '../../../utilities/systemHelper';


import './MaintenanceModal.scss';

const MAINTENANCE_POPUP_ID = '__maintenance-popup';
const MAINTENANCE_MODAL_GRID_WIDTH = 560;

export default class MaintenanceModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            display: false,
            zIndex: null,
            from: null,
            to: null,
        };
        this.handleClose = this.handleClose.bind(this);
    }

    componentWillUnmount() {
        if (this.props.onClose) { this.props.onClose(); }
    }

    show = (from, to) => {
        const zIndex = fn.getHighestZIndex() + 1;
        this.setState({ display: true, zIndex: zIndex, from: from, to: to });
    }

    close = () => {
        this.handleClose();
    }

    handleClose = event => {
        this.setState({ display: false, zIndex: null, from: null, to: null });
    }

    handleLater = event => {
        const now = new Date();
        const expiresAt = now.setMinutes(now.getMinutes() + 60);
        window.localStorage.setItem(StorageKey.PLANNED_MAINTENANCE_LATER_EXPIRES_AT, expiresAt);
        this.handleClose();
    }

    handleRefresh = event => {
        window.location.reload();
    }

    renderTitle = () => {
        return <div className='popup-title-draggable'></div>
    }

    renderContent = () => {
        const { from, to } = this.state;

        return <>
            <div className='popup-title'>
                <div className='actions left-actions pt-1'>
                    <ul className='ml-2'>
                        <li>
                            <h4 className='fw-500 mb-0'>
                                Planned maintenance
                            </h4>
                        </li>
                    </ul>
                </div>
                <div className='actions right-actions'>
                    <ul>
                        <li>
                            <button
                                type='button'
                                className='btn btn-icon btn-close'
                                onClick={this.handleClose}
                            >
                                <i className='close-icon fal fa-times fs-xl'></i>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='popup-body p-4'>
                <p className='fs-lg mb-0'>
                    {
                        !!from && !!to ?
                            <>GENKI will be offline from <span className='tt-underline'>{from && isMoment(from) ? sys.getFormattedLongDate(from, true) : ''} until {from && isMoment(to) ? sys.getFormattedLongDate(to, true) : ''}</span> for a system update. During this time, you won't be able to access the system.  Thank you for your patience.</> : null
                    }
                    {
                        !!from && !to ?
                            <>GENKI will be offline on <span className='tt-underline'>{from && isMoment(from) ? sys.getFormattedLongDate(from, true) : ''}</span> for a couple of hours for a system update. During this time, you won't be able to access the system.  Thank you for your patience.</> : null
                    }
                </p>
            </div>
            <div className='popup-footer px-4 pb-4'>
                <div className='d-flex justify-content-end'>
                    <button
                        type='button'
                        className='btn btn-primary ml-2'
                        onClick={this.handleLater}
                    >Ok</button>
                </div>
            </div>
        </>
    }

    render() {
        return <>
            {
                this.state.display ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                this.handleClose(event);
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
            <Popup
                wrapperAttr={{ id: MAINTENANCE_POPUP_ID, class: 'dx-refresh-popup' }}
                animation={{
                    show: {
                        type: 'pop',
                        duration: 200,
                        from: { opacity: 0, scale: 0 },
                        to: { opacity: 1, scale: 1 }
                    },
                    hide: {
                        type: 'pop',
                        duration: 200,
                        from: { opacity: 1, scale: 1 },
                        to: { opacity: 0, scale: 0 }
                    }
                }}
                visible={this.state.display}
                shading={true}
                shadingColor="rgba(0, 0, 0, 0.75)"
                width={MAINTENANCE_MODAL_GRID_WIDTH}
                height={'auto'}
                dragEnabled={false}
                showTitle={true}
                titleRender={this.renderTitle}
                contentRender={this.renderContent}
                onHiding={this.handleClose}
            />
        </>
    }
}