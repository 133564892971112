import React from 'react';
import uuid from 'react-uuid';

export const TEMPLATE_TEXT_METADATA_ALIGN = 'TEMPLATE_TEXT_METADATA_ALIGN';
export const TEMPLATE_TEXT_METADATA_VALIGN = 'TEMPLATE_TEXT_METADATA_VALIGN';
export const TextElementTypes = ['SmallLabel', 'RegularLabel', 'LargeLabel', 'Heading1', 'Heading2', 'Heading3', 'Heading4', 'SmallText', 'RegularText', 'Line'];

export const createElement = (type) => {
    return {
        id: uuid(),
        type: type,
        text: type,
        metadata: {},
        position: getDefaultPosition(type),
    };
}

export const isTextElement = ({ type }) => {
    return TextElementTypes.some(t => t === type);
}

export const hasField = (type, field) => {
    switch (type) {
        case 'SmallLabel':
        case 'RegularLabel':
        case 'LargeLabel':
        case 'Heading1':
        case 'Heading2':
        case 'Heading3':
        case 'Heading4':
        case 'SmallText':
        case 'RegularText':
            return [
                TEMPLATE_TEXT_METADATA_ALIGN,
                TEMPLATE_TEXT_METADATA_VALIGN,
            ].some(f => f === field);

        default:
            return false
    }
}

export const getEditor = (type) => {
    switch (type) {
        case 'SmallLabel':
        case 'RegularLabel':
        case 'LargeLabel':
        case 'Heading1':
        case 'Heading2':
        case 'Heading3':
        case 'Heading4':
            return 'Text';

        case 'SmallText':
        case 'RegularText':
            return 'RichText';

        default:
            return 'Text';
    }
}

export const renderText = (textElement, onDoubleClick, onContextMenu) => {
    switch (textElement.type) {
        case 'SmallLabel':
            return <div
                className={'form-group' + (textElement.metadata && textElement.metadata.valign && textElement.metadata.valign !== 'top' ? ` text-${textElement.metadata.valign}` : '')}
                onDoubleClick={onDoubleClick}
                onContextMenu={onContextMenu}
            >
                <label className={`label-text-input label-text-input-sm text-${(textElement.metadata && textElement.metadata.align ? textElement.metadata.align : 'left')}`}><small>{textElement.text}</small></label>
            </div>

        case 'RegularLabel':
            return <div
                className={'form-group' + (textElement.metadata && textElement.metadata.valign && textElement.metadata.valign !== 'top' ? ` text-${textElement.metadata.valign}` : '')}
                onDoubleClick={onDoubleClick}
                onContextMenu={onContextMenu}
            >
                <label className={`label-text-input text-${(textElement.metadata && textElement.metadata.align ? textElement.metadata.align : 'left')}`}><small>{textElement.text}</small></label>
            </div>

        case 'LargeLabel':
            return <div
                className={'form-group' + (textElement.metadata && textElement.metadata.valign && textElement.metadata.valign !== 'top' ? ` text-${textElement.metadata.valign}` : '')}
                onDoubleClick={onDoubleClick}
                onContextMenu={onContextMenu}
            >
                <label className={`label-text-input label-text-input-lg text-${(textElement.metadata && textElement.metadata.align ? textElement.metadata.align : 'left')}`}><small>{textElement.text}</small></label>
            </div>

        case 'Heading1':
            return <div
                className={'form-group' + (textElement.metadata && textElement.metadata.valign && textElement.metadata.valign !== 'top' ? ` text-${textElement.metadata.valign}` : '')}
                onDoubleClick={onDoubleClick}
                onContextMenu={onContextMenu}
            >
                <h1 className={`element-wrapper text-${(textElement.metadata && textElement.metadata.align ? textElement.metadata.align : 'left')}`}>{textElement.text}</h1>
            </div>

        case 'Heading2':
            return <div
                className={'form-group' + (textElement.metadata && textElement.metadata.valign && textElement.metadata.valign !== 'top' ? ` text-${textElement.metadata.valign}` : '')}
                onDoubleClick={onDoubleClick}
                onContextMenu={onContextMenu}
            >
                <h2 className={`text-${(textElement.metadata && textElement.metadata.align ? textElement.metadata.align : 'left')}`}>{textElement.text}</h2>
            </div>

        case 'Heading3':
            return <div
                className={'form-group' + (textElement.metadata && textElement.metadata.valign && textElement.metadata.valign !== 'top' ? ` text-${textElement.metadata.valign}` : '')}
                onDoubleClick={onDoubleClick}
                onContextMenu={onContextMenu}
            >
                <h3 className={`text-${(textElement.metadata && textElement.metadata.align ? textElement.metadata.align : 'left')}`}>{textElement.text}</h3>
            </div>

        case 'Heading4':
            return <div
                className={'form-group' + (textElement.metadata && textElement.metadata.valign && textElement.metadata.valign !== 'top' ? ` text-${textElement.metadata.valign}` : '')}
                onDoubleClick={onDoubleClick}
                onContextMenu={onContextMenu}
            >
                <h4 className={`text-${(textElement.metadata && textElement.metadata.align ? textElement.metadata.align : 'left')}`}>{textElement.text}</h4>
            </div>

        case 'SmallText':
            return <div
                className={'form-group' + (textElement.metadata && textElement.metadata.valign && textElement.metadata.valign !== 'top' ? ` text-${textElement.metadata.valign}` : '')}
                onDoubleClick={onDoubleClick}
                onContextMenu={onContextMenu}
            >
                <div
                    className={`html fs-sm text-${(textElement.metadata && textElement.metadata.align ? textElement.metadata.align : 'left')}`}
                    dangerouslySetInnerHTML={{ __html: textElement.text }}
                ></div>
            </div>

        case 'RegularText':
            return <div
                className={'form-group' + (textElement.metadata && textElement.metadata.valign && textElement.metadata.valign !== 'top' ? ` text-${textElement.metadata.valign}` : '')}
                onDoubleClick={onDoubleClick}
                onContextMenu={onContextMenu}
            >
                <div
                    className={`html text-${(textElement.metadata && textElement.metadata.align ? textElement.metadata.align : 'left')}`}
                    dangerouslySetInnerHTML={{ __html: textElement.text }}
                ></div>
            </div>

        case 'Line':
            return <div className='border border-top border-left-0 border-right-0 border-bottom-0 py-h'></div>

        default:
            break;
    }
}

export const getDefaultPosition = type => {
    switch (type) {
        case 'SmallLabel':
        case 'RegularLabel':
        case 'LargeLabel':
        case 'Line':
            return { w: 8, h: 2 };

        case 'Heading1':
        case 'Heading2':
            return { w: 8, h: 3 };

        case 'Heading3':
        case 'Heading4':
            return { w: 8, h: 2 };

        case 'SmallText':
        case 'RegularText':
            return { w: 8, h: 2 };

        default:
            return {};
    }
}