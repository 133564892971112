import React, { useEffect, useContext, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { toJS } from 'mobx';
import FadeIn from 'react-fade-in';
import { GlobalHotKeys } from 'react-hotkeys';
import { Observer } from 'mobx-react-lite';
import moment from 'moment';

import BodyEnd from '../../_shared/BodyEnd';
import ConfirmModal from '../../_shared/ConfirmModal';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import { quickDrawerFocus } from '../../_shared/QuickDrawer';

import CustomerUpdateStore from '../../../../stores/CustomerUpdateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as rts  from '../../../../constants/routes';
import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as ch from '../../../../utilities/customerHelper';
import * as ph from '../../../../utilities/personHelper';
import * as oh from '../../../../utilities/operationHelper';
import * as ah from '../../../../utilities/addressHelper';

import './DeletePrivateInsurance.scss';

function DeletePrivateInsurance(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const focusTimer = useRef(null);
    const customer = useContext(CustomerUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [deleteMethod, setDeleteMethod] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState(false);

    useEffect(() => {
        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100)

        return () => {
            isMounted.current = false;

            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (customer.hasPrivateInsuranceUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleDeleteMethodChange = (event) => {
        const value = event.target.value;
        setDeleteMethod(value);
        customer.hasPrivateInsuranceUnsavedChanges = true;
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            if (deleteMethod) {
                setConfirmDelete(true);
            }
        }
    }

    const handleConfirmDelete = (event) => {
        customer.deletePrivateInsuranceInformation(deleteMethod)
            .then(data => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, { updated: !!deleteMethod, data: data });
                    }
                    setConfirmDelete(false);
                }
            })
    }

    const handleCancelDelete = () => {
        setConfirmDelete(false);
    }

    const renderCustomer = () => {
        return <div
            className='profile-wrapper'
        >
            <div className='profile'>
                <span
                    className={`profile-image profile-initials rounded-circle d-flex text-white ${ch.getProfileColor(customer.data)} fw-500`}
                    title={ph.getFullName(customer.data)}
                >
                    {customer.data.initials}
                </span>
            </div>
            <div className='description flex-1'>
                <Link to={`${rts.Customers.Home}/${customer.data.id}`} className='name text-gray-700' onClick={quickDrawer.deactivateAll}>{ph.getFullName(customer.data, true)}
                    {
                        customer.data.dateOfBirth || customer.data.sex || customer.data.gender || customer.data.pronoun ?
                            <small className='text-nowrap ml-2'>({`${ph.getAge(customer.data.dateOfBirth)} ${ph.getSexGenderPronounDisplay(customer.data)}`.trim()})</small> : null
                    }
                </Link>
                {
                    customer.data.address && customer.data.address.country ?
                        <div className='info'>{ah.getAddressHtml(customer.data.address)}</div> : null
                }
            </div>
        </div>
    }

    const getConfirmMessage = () => {
        let message;

        switch (deleteMethod) {
            case 'Unlink':
                message = <>Continue to <span className='fw-500'>&nbsp;unlink {(customer && customer.data ? ph.getPreferredFirstName(customer.data) : '')}'s insurance from {getOtherPlanMemberNames()}</span>?</>
                break;

            case 'Delete':
                message = <>Continue to <span className='fw-500'>&nbsp;delete insurance for {(customer && customer.data ? ph.getPreferredFirstName(customer.data) : '')}{(customer && customer.selectedPrivateInsurance && customer.selectedPrivateInsurance.members.length > 1 ? ', ' : ' and ')}{getOtherPlanMemberNames()}</span>?</>
                break;

            default:
                message = <></>
        }

        return message;
    }

    const getOtherPlanMemberNames = () => {
        const sortedMembers = toJS(customer.selectedPrivateInsurance.members).sort((a, b) => (a.dateOfBirth ? moment(a.dateOfBirth).unix() : 0) - (b.dateOfBirth ? moment(b.dateOfBirth).unix() : 0));

        return [...new Set(sortedMembers.map(m => { return ph.getPreferredFirstName(m) }))].join(', ').replace(/,(?=[^,]*$)/, ' and');
    }

    return <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) && !confirmDelete ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <fieldset disabled={customer.isSaving}>
                <div className='delete-customer-insurance-container quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('customer', 'private-insurance')}
                        action='Remove Customer'
                        category='Insurance'
                        className='delete'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        <FadeIn>
                            <div className='body-content'>
                                <Observer>{() =>
                                    <section className='customer'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='form-group mb-0'>
                                                    <label><small>Customer</small></label>
                                                    {renderCustomer()}
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                }</Observer>
                                <Observer>{() =>
                                    <section className='mt-3'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='form-group mb-0 validate validate-required'>
                                                    <label className='required'><small>Action</small></label>
                                                    <div className='custom-control custom-radio mb-2'>
                                                        <input
                                                            id='removeInsurance'
                                                            type='radio'
                                                            name='deleteMethod'
                                                            value='Unlink'
                                                            className='custom-control-input'
                                                            disabled={!ch.checkCanUpdate(customer.data)}
                                                            checked={deleteMethod === 'Unlink'}
                                                            onChange={handleDeleteMethodChange}
                                                        />
                                                        <label
                                                            htmlFor='removeInsurance'
                                                            className='custom-control-label'
                                                        >
                                                            Unlink insurance information <span className='d-block fs-sm text-danger'>(Remove from {ph.getPreferredFirstName(customer.data)} only)</span>
                                                        </label>
                                                    </div>
                                                    <div className='custom-control custom-radio mb-2'>
                                                        <input
                                                            id='deleteInsurance'
                                                            type='radio'
                                                            name='deleteMethod'
                                                            value='Delete'
                                                            className='custom-control-input'
                                                            disabled={!ch.checkCanUpdate(customer.data)}
                                                            checked={deleteMethod === 'Delete'}
                                                            onChange={handleDeleteMethodChange}
                                                        />
                                                        <label
                                                            htmlFor='deleteInsurance'
                                                            className='custom-control-label'
                                                        >
                                                            Delete insurance information <span className='d-block fs-sm text-danger'>(Remove from ALL plan members)</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                }</Observer>
                            </div>
                        </FadeIn>
                    </div>
                    <div className='quick-drawer-action pl-3'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-danger'
                                        disabled={!ch.checkCanUpdate(customer.data)}
                                    >Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
        <BodyEnd>
            <ConfirmModal
                icon={<i className={'text-danger mr-2 ' + oh.getIcon('customer', 'private-insurance')}></i>}
                descriptionClassName='warning-danger warning-flashing'
                message={getConfirmMessage()}
                show={confirmDelete}
                onOption1Click={handleConfirmDelete}
                onCancel={handleCancelDelete}
            />
        </BodyEnd >
    </>
}

export default DeletePrivateInsurance;