import React, { useEffect, useContext, useState, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { GlobalHotKeys } from 'react-hotkeys';
import { useObserver } from 'mobx-react-lite';

import QuickDrawerHeader from '../../../../_shared/QuickDrawerHeader';
import { quickDrawerFocus } from '../../../../_shared/QuickDrawer';
import RichTextEditor from '../../../../_shared/RichTextEditor';

import PretestTextViewStore from '../../../../../../stores/PretestTextViewStore';
import QuickDrawerStore from '../../../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../../../constants/errorMessages';
import * as fn from '../../../../../../utilities/_functions';
import * as oh from '../../../../../../utilities/operationHelper';
import * as tth from '../../../../../../utilities/templateTextHelper';

import './UpdatePretestInput.scss';

function UpdatePretestInput(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const pretestText = useContext(PretestTextViewStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [align, setAlign] = useState('');
    const [valign, setValign] = useState('');

    useEffect(() => {
        focusTimer.current = setTimeout(() => {
            const existingAlign = pretestText.getMetadata('align');
            const existingValign = pretestText.getMetadata('valign');

            setAlign((existingAlign ? existingAlign : 'left'));
            setValign((existingValign ? existingValign : 'top'));
            quickDrawerFocus(props.drawer);
        }, 100)

        return (() => {
            isMounted.current = false;
            pretestText.clear();
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        })
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (pretestText.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = event => {
        event.preventDefault();
        handlePopulateMetadata();
        pretestText.save()
            .then(data => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, data);
                    }
                }
            })
    }

    const handleTextChange = event => {
        pretestText.data.text = event.target.value;
        pretestText.hasUnsavedChanges = true;
    }

    const handleRichTextChange = content => {
        const html = content;
        pretestText.data.text = ((html === '<p><br></p>') ? null : html);
        pretestText.hasUnsavedChanges = true;
    }

    const handleAlignChange = value => {
        setAlign((value ? value : 'left'));
        pretestText.hasUnsavedChanges = true;
    }

    const handleValignChange = value => {
        setValign((value ? value : 'top'));
        pretestText.hasUnsavedChanges = true;
    }

    const handlePopulateMetadata = () => {
        populateMetadata(tth.TEMPLATE_TEXT_METADATA_ALIGN, 'align', align);
        populateMetadata(tth.TEMPLATE_TEXT_METADATA_VALIGN, 'valign', valign);
    }

    const populateMetadata = (field, key, value) => {
        if (tth.hasField(pretestText.data.type, field)) {
            pretestText.setMetadata(key, value);
        } else {
            pretestText.removeMetadata(key);
        }
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form onSubmit={handleSubmit}>
            <fieldset disabled={pretestText.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('pretest-input', 'text')}
                        action='Update'
                        category='Pretest Text'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        <FadeIn>
                            <div className='update-pretest-text body-content'>
                                <section>
                                    {
                                        tth.getEditor(pretestText.data.type) === 'Text' ?
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label className='required' htmlFor='update-pretest-text'><small>Text</small></label>
                                                        <input
                                                            id='new-pretest-input-text'
                                                            type='text'
                                                            className='form-control'
                                                            autoComplete='off'
                                                            value={pretestText.data.text ? pretestText.data.text : ''}
                                                            onChange={handleTextChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        tth.getEditor(pretestText.data.type) === 'RichText' ?
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label htmlFor='update-pretest-text'><small>Description</small></label>
                                                        <RichTextEditor
                                                            mode='none'
                                                            disableTab={true}
                                                            value={pretestText.data.text ? pretestText.data.text : ''}
                                                            onChange={handleRichTextChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        tth.hasField(pretestText.data.type, tth.TEMPLATE_TEXT_METADATA_ALIGN) ?
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label className='required' htmlFor='update-pretest-text-align'><small>Align</small></label>
                                                        <div>
                                                            <div className='btn-group'>
                                                                <button
                                                                    type='button'
                                                                    className={'btn btn-secondary' + (align === 'left' ? ' active' : '')}
                                                                    value={(align ? align : '')}
                                                                    onClick={() => { handleAlignChange('left') }}
                                                                >
                                                                    <i className='fal fa-align-left'></i>
                                                                </button>
                                                                <button
                                                                    type='button'
                                                                    className={'btn btn-secondary' + (align === 'center' ? ' active' : '')}
                                                                    value={(align ? align : '')}
                                                                    onClick={() => { handleAlignChange('center') }}
                                                                >
                                                                    <i className='fal fa-align-center'></i>
                                                                </button>
                                                                <button
                                                                    type='button'
                                                                    className={'btn btn-secondary' + (align === 'right' ? ' active' : '')}
                                                                    value={(align ? align : '')}
                                                                    onClick={() => { handleAlignChange('right') }}
                                                                >
                                                                    <i className='fal fa-align-right'></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        tth.hasField(pretestText.data.type, tth.TEMPLATE_TEXT_METADATA_VALIGN) ?
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label className='required' htmlFor='update-pretest-text-valign'><small>Vertical Align</small></label>
                                                        <div>
                                                            <div className='btn-group'>
                                                                <button
                                                                    type='button'
                                                                    className={'btn btn-secondary' + (valign === 'top' ? ' active' : '')}
                                                                    value={(valign ? valign : '')}
                                                                    onClick={() => { handleValignChange('top') }}
                                                                >
                                                                    <i className='fal fa-arrow-to-top'></i>
                                                                </button>
                                                                <button
                                                                    type='button'
                                                                    className={'btn btn-secondary' + (valign === 'middle' ? ' active' : '')}
                                                                    value={(valign ? valign : '')}
                                                                    onClick={() => { handleValignChange('middle') }}
                                                                >
                                                                    <i className='fal fa-horizontal-rule'></i>
                                                                </button>
                                                                <button
                                                                    type='button'
                                                                    className={'btn btn-secondary' + (valign === 'bottom' ? ' active' : '')}
                                                                    value={(valign ? valign : '')}
                                                                    onClick={() => { handleValignChange('bottom') }}
                                                                >
                                                                    <i className='fal fa-arrow-to-bottom'></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : null
                                    }
                                </section>
                            </div>
                        </FadeIn>
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-4'></div>
                            <div className='col-8'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>)
}

export default UpdatePretestInput;