import React, { useContext, useEffect, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { useObserver } from 'mobx-react-lite';
import { GlobalHotKeys } from 'react-hotkeys';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';

import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';

import ProductViewStore from '../../../../stores/ProductViewStore';
import ProductCreateStore from '../../../../stores/ProductCreateStore';
import ProductUpdateStore from '../../../../stores/ProductUpdateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as AccessType from '../../../../constants/accessTypes';
import * as fn from '../../../../utilities/_functions';
import * as pdh from '../../../../utilities/productHelper';
import * as ah from '../../../../utilities/accessHelper';
import * as oh from '../../../../utilities/operationHelper';

import './ViewProduct.scss';

moment.locale('en');
momentLocalizer();

function ViewProduct(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const productUpdateTimer = useRef(null);
    const product = useContext(ProductViewStore);
    const newProduct = useContext(ProductCreateStore);
    const updateProduct = useContext(ProductUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    let onSuccess, onCancel, onError;

    useEffect(() => {
        quickDrawerFocus(props.drawer);
        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
            if (productUpdateTimer.current) { clearTimeout(productUpdateTimer.current); productUpdateTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleCloneProduct = () => {
        if (product.data) {
            newProduct.initialize(product.data.typeId, product.data.brandId)
                .then(() => {
                    if (isMounted.current) {
                        newProduct.data = pdh.clone(product.data, newProduct.data);
                        newProduct.data.referenceNumber = null;
                        newProduct.data.note = null;
                        newProduct.data.noteHtml = null;
                    }
                });

            quickDrawer.activateQuickDrawer('product', 'create', null)
                .then(drawer => {
                    if (isMounted.current) {
                        focusTimer.current = setTimeout(() => {
                            quickDrawerFocus(drawer);
                        }, 100);
                    }
                });
        }
    }

    const handleDeleteProduct = () => {
        onSuccess = quickDrawer.success1Callback;
        onCancel = quickDrawer.cancel1Callback;
        onError = quickDrawer.error1Callback;

        updateProduct.initialize(product.id)
            .then(() => {
                if (isMounted.current) {
                    quickDrawer.activateQuickDrawer('product', 'delete', null, handleDeleteProductSuccess);
                }
            })
    }

    const handleDeleteProductSuccess = () => {
        quickDrawer.deactivateQuickDrawer1();
        if (fn.isFunction(onSuccess)) {
            onSuccess('deleted');
            onSuccess = null;
            onCancel = null;
            onError = null;
        }
        updateProduct.clear();
    }

    const handleChangeProduct = () => {
        onSuccess = quickDrawer.success1Callback;
        onCancel = quickDrawer.cancel1Callback;
        onError = quickDrawer.error1Callback;

        updateProduct.initialize(product.id)
            .then(() => {
                if (isMounted.current) {
                    quickDrawer.deactivateQuickDrawer1()
                        .then(() => {
                            productUpdateTimer.current = setTimeout(() => {
                                quickDrawer.activateQuickDrawer('product', 'update', null, handleChangeProductSuccess, handleChangeProductCancel, handleChangeProductError);
                            }, 200)
                        })
                }
            })
    }

    const handleChangeProductSuccess = () => {
        if (fn.isFunction(onSuccess)) {
            onSuccess('updated');
            onSuccess = null;
            onCancel = null;
            onError = null;
        }
        updateProduct.clear();
    }

    const handleChangeProductCancel = () => {
        if (fn.isFunction(onCancel)) {
            onCancel();
            onSuccess = null;
            onCancel = null;
            onError = null;
        }
        updateProduct.clear();
    }

    const handleInventoryHistory = () => {
        quickDrawer.activateQuickDrawer('product', 'inventory-history');
    }

    const handleChangeProductError = () => {
        if (fn.isFunction(onError)) {
            onError();
            onSuccess = null;
            onCancel = null;
            onError = null;
        }
        updateProduct.clear();
    }

    const getOptionValue = key => {
        if (product.data.data && product.data.data.some(d => d.key === key)) {
            return product.data.data.filter(d => d.key === key)[0].value;
        }
        return '';
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                props.onCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form>
            <fieldset>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('product')}
                        action='View'
                        category='Product'
                        className='products'
                        onCancel={props.onCancel}
                    />
                    <div className='quick-drawer-body'>
                        {
                            product.isReady ?
                                <FadeIn>
                                    <div className='view-product body-content'>
                                        <section className='product-info'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <label><small>{product.data.type ? product.data.type.name : 'Unknown'}</small></label>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <ul className='title'>
                                                        {
                                                            product.data.isStocked && product.data.isTracked ?
                                                                <li className='quantity'>
                                                                    <h4 className={product.data.quantity ? '' : 'text-danger'}>
                                                                        <strong>{product.data.quantity ? product.data.quantity : 0}<small>x</small></strong>
                                                                    </h4>
                                                                </li> : null
                                                        }
                                                        <li className={'detail' + (product.data.isStocked && product.data.isTracked ? ' has-quantity' : '')}>
                                                            <small className='brand text-truncate' title={`${product.data.brand.name} (${product.data.supplier.name})`}><strong>{product.data.brand.name}</strong> ({product.data.supplier.name})</small>
                                                            <h4 className='m-0 text-truncate' title={product.data.name}><strong>{product.data.name}</strong></h4>
                                                            <span className='text-gray text-truncate' title={product.data.descriptor}>{product.data.descriptor}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </section>
                                        <section className='product-price'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <label><small>Price</small></label>
                                                    <h4><strong className={product.data.price < 0 ? ' text-danger' : ''}>{fn.formatCurrency(product.data.price, '--')}</strong> {ah.check(AccessType.UPDATE_PRODUCT) ? <small className={product.data.cost ? '' : 'd-none'}>({fn.formatCurrency(product.data.cost, '--')})</small> : null}</h4>
                                                </div>
                                            </div>
                                        </section>
                                        {
                                            product.data.data && product.data.data.length > 0 ?
                                                <section className='product-data'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <label><small>Extra Data</small></label>
                                                            <ul className='leaders'>
                                                                {
                                                                    product.data.type.data.map((t, ti) => {
                                                                        return <li
                                                                            key={`product_data_${ti}`}
                                                                        >
                                                                            <span>{t.name}</span>
                                                                            <span>{getOptionValue(t.key)}</span>
                                                                        </li>
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </section> : null
                                        }
                                    </div>
                                </FadeIn> : renderQuickDrawerLoading()
                        }
                    </div>
                    <div className='quick-drawer-action pl-3'>
                        <div className='row'>
                            <div className='col-5'>
                                {
                                    ah.check(AccessType.UPDATE_PRODUCT) && (!props.extraProps || fn.isNullOrUndefined(props.extraProps.canClone) || props.extraProps.canClone === true) ?
                                        <button
                                            type='button'
                                            className='btn btn-icon'
                                            title='Clone product'
                                            onClick={handleCloneProduct}
                                        >
                                            <i className='fal fa-copy'></i>
                                        </button> : null
                                }
                                {
                                    product.data && product.data.isTracked && (!props.extraProps || fn.isNullOrUndefined(props.extraProps.canViewHistory) || props.extraProps.canViewHistory === true) ?
                                        <button
                                            type='button'
                                            className='btn btn-icon'
                                            title='Inventory history'
                                            onClick={handleInventoryHistory}
                                        >
                                            <i className='fal fa-history'></i>
                                        </button> : null
                                }
                            </div>
                            <div className='col-7'>
                                <div className='float-right'>
                                    {
                                        ah.check(AccessType.UPDATE_PRODUCT) ?
                                            <>
                                                {
                                                    !props.extraProps || fn.isNullOrUndefined(props.extraProps.canDelete) || props.extraProps.canDelete === true ?
                                                        <button
                                                            type='button'
                                                            className='btn btn-danger mr-2'
                                                            onClick={handleDeleteProduct}
                                                        >Delete</button> :
                                                        <button
                                                            type='button'
                                                            className='btn btn-link btn-cancel mr-2'
                                                            onClick={props.onCancel}
                                                        >Cancel</button>
                                                }
                                                <button
                                                    type='button'
                                                    className='btn btn-primary'
                                                    onClick={handleChangeProduct}
                                                >Change</button>
                                            </> :
                                            <>
                                                <button
                                                    type='button'
                                                    className='btn btn-link btn-cancel'
                                                    onClick={props.onCancel}
                                                >Cancel</button>
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>)
}

export default ViewProduct;