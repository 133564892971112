import moment from 'moment';

export const parseFilteringColumn = (columns, expression) => {
    if (Array.isArray(expression)) {
        if (expression.length === 3 && !Array.isArray(expression[0])) {
            const index = columns.findIndex(col => col.dataField === expression[0]);
            return index >= 0 ? index : null;
        }
        else {
            for (let ei = 0; ei < expression.length; ei++) {
                return parseFilteringColumn(columns, expression[ei]);
            }
        }
    }

    return null;
}

export const getDefaultNextDateFilters = (fieldName) => {
    return [
        {
            text: 'Today',
            value: [[fieldName, '>=', moment().startOf('day').toDate()], 'and', [fieldName, '<', moment().add(1, 'days').startOf('day').toDate()]]
        },
        {
            text: 'Tomorrow',
            value: [[fieldName, '>=', moment().add(1, 'days').startOf('day').toDate()], 'and', [fieldName, '<', moment().add(2, 'days').startOf('day').toDate()]]
        },
        {
            text: 'Next 7 days',
            value: [[fieldName, '>=', moment().add(1, 'days').startOf('day').toDate()], 'and', [fieldName, '<', moment().startOf('day').add(8, 'days').toDate()]]
        },
        {
            text: 'Next 14 days',
            value: [[fieldName, '>=', moment().add(1, 'days').startOf('day').toDate()], 'and', [fieldName, '<', moment().startOf('day').add(15, 'days').toDate()]]
        },
        {
            text: 'Next 30 days',
            value: [[fieldName, '>=', moment().add(1, 'days').startOf('day').toDate()], 'and', [fieldName, '<', moment().startOf('day').add(31, 'days').toDate()]]
        },
    ]
}

export const getDefaultLastDateFilters = (fieldName) => {
    return [
        {
            text: 'Today',
            value: [[fieldName, '>=', moment().startOf('day').toDate()], 'and', [fieldName, '<', moment().add(1, 'days').startOf('day').toDate()]]
        },
        {
            text: 'Yesterday',
            value: [[fieldName, '>=', moment().add(-1, 'days').startOf('day').toDate()], 'and', [fieldName, '<', moment().startOf('day').toDate()]]
        },
        {
            text: 'Last 7 days',
            value: [[fieldName, '>=', moment().add(-7, 'days').startOf('day').toDate()], 'and', [fieldName, '<', moment().startOf('day').add(1, 'days').toDate()]]
        },
        {
            text: 'Last 14 days',
            value: [[fieldName, '>=', moment().add(-14, 'days').startOf('day').toDate()], 'and', [fieldName, '<', moment().startOf('day').add(1, 'days').toDate()]]
        },
        {
            text: 'Last 30 days',
            value: [[fieldName, '>=', moment().add(-30, 'days').startOf('day').toDate()], 'and', [fieldName, '<', moment().startOf('day').add(1, 'days').toDate()]]
        },
    ]
}
