import { createContext } from 'react';
import uuid from 'react-uuid';
import { decorate, observable, action, toJS } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';
import * as fh from '../utilities/formTemplateHelper';

export class FormTemplateCreate {
    id = null;
    data = {
        name: null,
        descriptionHtml: null,
        access: 'Anonymous',
        destinations: ['Email'],
        settings: [],
    };
    isReady = false;
    isSaving = false;
    hasUnsavedChanges = false;

    cancelFormTemplateCreate = null;

    initialize = () => {
        this.clear();
        this.id = uuid();
        this.data.settings = fh.getDestinationSettingKeys(this.data.destinations).map(d => { return { id: d, value: null } });
        this.isReady = true;
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            let option = toJS(that.data);

            api.FormTemplates.create(option, (c) => { that.cancelFormTemplateCreate = c })
                .then(({ data }) => {
                    that.id = data.id;
                    that.hasUnsavedChanges = false;
                    option.id = data.id;
                    resolve(option);
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    clear = () => {
        this.id = null;
        this.data.name = null;
        this.data.descriptionHtml = null;
        this.data.access = 'Anonymous';
        this.data.destinations = ['Email'];
        this.data.settings.clear();
        this.hasUnsavedChanges = false;

        if (fn.isFunction(this.cancelFormTemplateCreate)) {
            this.cancelFormTemplateCreate();
            this.cancelFormTemplateCreate = null;
        }
    }
}

decorate(FormTemplateCreate, {
    id: observable,
    data: observable.deep,
    hasUnsavedChanges: observable,
    isReady: observable,
    isSaving: observable,
    initialize: action,
    save: action,
    clear: action,
})

export default createContext(new FormTemplateCreate());