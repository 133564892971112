import React, { useContext, useEffect, useRef, useState } from 'react';
import FadeIn from 'react-fade-in';
import { Observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import { GlobalHotKeys } from 'react-hotkeys';
import MaskedInput from 'react-text-mask'

import BodyEnd from '../../_shared/BodyEnd';

import ConfirmModal from '../../_shared/ConfirmModal';
import AddressInput from '../../_shared/AddressInput';
import { quickDrawerFocus } from '../../_shared/QuickDrawer';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import RichTextEditor from '../../_shared/RichTextEditor';

import ProductSupplierCreateStore from '../../../../stores/ProductSupplierCreateStore';
import AuthStore from '../../../../stores/AuthStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as MaskKeys from '../../../../constants/maskKeys';
import * as fn from '../../../../utilities/_functions';
import * as oh from '../../../../utilities/operationHelper';

import api from '../../../../api';

import './NewProductSupplier.scss';

function NewProductSupplier(props) {
    const isMounted = useRef(true);
    const productSupplierRef = useRef(null);
    const addressRef = useRef(null);
    const validateRef = useRef(null);
    const cancelProductDeleteSearch = useRef(null);
    const productSupplier = useContext(ProductSupplierCreateStore);
    const auth = useContext(AuthStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [isProductSupplierChecked, setIsProductSupplierChecked] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    useEffect(() => {
        quickDrawerFocus(props.drawer);

        if (props && props.extraProps) {
            if (props.extraProps.isProductSupplierChecked) {
                setIsProductSupplierChecked(props.extraProps.isProductSupplierChecked);
            }
        }

        return () => {
            isMounted.current = false;
            if (cancelProductDeleteSearch.current && fn.isFunction(cancelProductDeleteSearch.current)) { cancelProductDeleteSearch.current(); cancelProductDeleteSearch.current = null; }
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (productSupplier.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleNameChange = event => {
        productSupplier.data.name = event.target.value;
        productSupplier.hasUnsavedChanges = true;
    }

    const handleCheckProductSupplier = event => {
        const { name } = productSupplier.data;
        const searchName = name ? name.trim() : null;

        if (!isProductSupplierChecked) {
            if (searchName) {
                productSupplier.load(searchName)
                    .then(() => {
                        setIsProductSupplierChecked(true);
                    })
            }
        }
    }

    const handleAccountNumberChange = event => {
        productSupplier.data.accountNumber = event.target.value;
        productSupplier.hasUnsavedChanges = true;
    }

    const handleAddressChange = (address) => {
        productSupplier.data.address = address;
        productSupplier.hasUnsavedChanges = true;
    }

    const handleContactPersonChange = event => {
        productSupplier.data.contactPerson = event.target.value;
        productSupplier.hasUnsavedChanges = true;
    }

    const handleEmailAddressChange = event => {
        productSupplier.data.emailAddress = event.target.value;
        productSupplier.hasUnsavedChanges = true;
    }

    const handlePrimaryPhoneChange = event => {
        productSupplier.data.primaryPhone = event.target.value;
        productSupplier.hasUnsavedChanges = true;
    }

    const handleSecondaryPhoneChange = event => {
        productSupplier.data.secondaryPhone = event.target.value;
        productSupplier.hasUnsavedChanges = true;
    }

    const handleFaxChange = event => {
        productSupplier.data.fax = event.target.value;
        productSupplier.hasUnsavedChanges = true;
    }

    const handleWebsiteUrlChange = event => {
        productSupplier.data.websiteUrl = event.target.value;
        productSupplier.hasUnsavedChanges = true;
    }

    const handleNoteChange = (content, delta, source, editor) => {
        const noteHtml = (content === '<p><br></p>') ? null : content;

        productSupplier.data.noteHtml = noteHtml;
        productSupplier.hasUnsavedChanges = true;
    }

    const handleDelete = event => {
        setConfirmDelete(true);
    }

    const handleConfirmDelete = (event) => {
        api.Products.search(
            {
                parameters: [{
                    field: 'SupplierId',
                    value: productSupplier.data.id,
                }, {
                    field: 'DeactivatedDateUtc',
                    value: null,
                }],
                includeTotalCount: true,
                includeResult: false,
            },
            (c) => { cancelProductDeleteSearch.current = c })
            .then(({ data }) => {
                if (isMounted.current) {
                    if (data && data.total > 0) {
                        toast.error(() => <p><strong>Cannot delete.</strong> There are still product(s) associated to {productSupplier.data.name}.</p>, { position: 'top-center', style: { width: '380px' } });
                    } else {
                        productSupplier.delete()
                            .then(() => {
                                if (isMounted.current) {
                                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                                        props.onSuccess(event, { updated: true });
                                    }
                                }
                            })
                    }
                }
            })
        setConfirmDelete(false);
    }

    const handleConfirmCancel = (event) => {
        setConfirmDelete(false);
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            if (isMounted.current) {
                productSupplier.save(true)
                    .then(data => {
                        if (isMounted.current) {
                            if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                                props.onSuccess(event, { updated: true, data: data });
                            }
                        }
                    })
            }
        }
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <fieldset disabled={productSupplier.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('product', 'supplier')}
                        action={productSupplier.data.id ? 'Update' : 'Add New'}
                        category='Product Supplier'
                        className='products'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        <FadeIn>
                            <div className='new-product-supplier body-content'>
                                <Observer>{() =>
                                    <section>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div ref={productSupplierRef} className='form-group mb-0 validate validate-required'>
                                                    <label htmlFor='new-product-supplier-name-input' className='required'><small>Name</small></label>
                                                    <input
                                                        id='new-product-supplier-name-input'
                                                        type='text'
                                                        className='form-control'
                                                        spellCheck={false}
                                                        maxLength='150'
                                                        autoComplete='off'
                                                        disabled={productSupplier.isLoading}
                                                        value={productSupplier.data && productSupplier.data.name ? productSupplier.data.name : ''}
                                                        onChange={handleNameChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                }</Observer>
                                <Observer>{() => <>
                                    {
                                        !productSupplier.isLoading && isProductSupplierChecked ?
                                            <FadeIn>
                                                <Observer>{() =>
                                                    <section className='mt-4'>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className='form-group mb-0'>
                                                                    <label htmlFor='new-product-supplier-account-number-input'><small>Account Number</small></label>
                                                                    <input
                                                                        id='new-product-supplier-account-number-input'
                                                                        type='text'
                                                                        className='form-control'
                                                                        spellCheck={false}
                                                                        maxLength='150'
                                                                        autoComplete='off'
                                                                        value={productSupplier.data && productSupplier.data.accountNumber ? productSupplier.data.accountNumber : ''}
                                                                        onChange={handleAccountNumberChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                }</Observer>
                                                <Observer>{() => <>
                                                    <section className='mt-4'>
                                                        <AddressInput
                                                            ref={addressRef}
                                                            address={(productSupplier.data && productSupplier.data.existingAddress ? productSupplier.data.existingAddress : {})}
                                                            label={'Address'}
                                                            defaultCountryCode={(auth.currentUser && auth.currentTenant && auth.currentTenant.address ? auth.currentTenant.address.countryCode : null)}
                                                            onChange={handleAddressChange}
                                                        />
                                                    </section>
                                                </>
                                                }</Observer>
                                                <Observer>{() =>
                                                    <section className='mt-4'>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className='form-group mb-0'>
                                                                    <label htmlFor='new-product-supplier-contact-input'><small>Contact Person</small></label>
                                                                    <input
                                                                        id='new-product-supplier-contact-input'
                                                                        type='text'
                                                                        className='form-control'
                                                                        spellCheck={false}
                                                                        maxLength='150'
                                                                        autoComplete='off'
                                                                        value={productSupplier.data && productSupplier.data.contactPerson ? productSupplier.data.contactPerson : ''}
                                                                        onChange={handleContactPersonChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                }</Observer>
                                                <Observer>{() =>
                                                    <section className='mt-4'>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className='form-group mb-0 validate validate-email'>
                                                                    <label htmlFor='new-product-supplier-email-input'><small>Email</small></label>
                                                                    <MaskedInput
                                                                        id='new-product-supplier-email-input'
                                                                        type='text'
                                                                        className='form-control'
                                                                        guide={false}
                                                                        spellCheck={false}
                                                                        mask={MaskKeys.EMAIL_MASK}
                                                                        maxLength='150'
                                                                        autoComplete='off'
                                                                        value={productSupplier.data.emailAddress ? productSupplier.data.emailAddress : ''}
                                                                        onChange={handleEmailAddressChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                }</Observer>
                                                <section className='mt-4'>
                                                    <div className='row'>
                                                        <div className='col-6'>
                                                            <Observer>{() =>
                                                                <div className='form-group mb-0'>
                                                                    <label htmlFor='new-product-supplier-primary-phone-input'><small>Primary Phone</small></label>
                                                                    <MaskedInput
                                                                        id='new-product-supplier-primary-phone-input'
                                                                        type='text'
                                                                        className='form-control'
                                                                        guide={false}
                                                                        spellCheck={false}
                                                                        mask={MaskKeys.PHONE_MASK}
                                                                        autoComplete='off'
                                                                        value={productSupplier.data.primaryPhone ? productSupplier.data.primaryPhone : ''}
                                                                        onChange={handlePrimaryPhoneChange}
                                                                    />
                                                                </div>
                                                            }</Observer>
                                                        </div>
                                                        <div className='col-6'>
                                                            <Observer>{() =>
                                                                <div className='form-group mb-0'>
                                                                    <label htmlFor='new-product-supplier-secondary-phone-input'><small>Secondary Phone</small></label>
                                                                    <MaskedInput
                                                                        id='new-product-supplier-secondary-phone-input'
                                                                        type='text'
                                                                        className='form-control'
                                                                        guide={false}
                                                                        spellCheck={false}
                                                                        mask={MaskKeys.PHONE_MASK}
                                                                        autoComplete='off'
                                                                        value={productSupplier.data.secondaryPhone ? productSupplier.data.secondaryPhone : ''}
                                                                        onChange={handleSecondaryPhoneChange}
                                                                    />
                                                                </div>
                                                            }</Observer>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section className='mt-4'>
                                                    <div className='row'>
                                                        <div className='col-6'>
                                                            <Observer>{() =>
                                                                <div className='form-group mb-0'>
                                                                    <label htmlFor='new-product-supplier-fax-input'><small>Fax</small></label>
                                                                    <MaskedInput
                                                                        id='new-product-supplier-fax-input'
                                                                        type='text'
                                                                        className='form-control'
                                                                        guide={false}
                                                                        spellCheck={false}
                                                                        mask={MaskKeys.PHONE_MASK}
                                                                        autoComplete='off'
                                                                        value={productSupplier.data.fax ? productSupplier.data.fax : ''}
                                                                        onChange={handleFaxChange}
                                                                    />
                                                                </div>
                                                            }</Observer>
                                                        </div>
                                                    </div>
                                                </section>
                                                <Observer>{() =>
                                                    <section className='mt-4'>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className='form-group mb-0'>
                                                                    <label htmlFor='new-product-supplier-website-input'><small>Website</small></label>
                                                                    <input
                                                                        id='new-product-supplier-website-input'
                                                                        type='text'
                                                                        className='form-control'
                                                                        spellCheck={false}
                                                                        maxLength='150'
                                                                        autoComplete='off'
                                                                        value={productSupplier.data.websiteUrl ? productSupplier.data.websiteUrl : ''}
                                                                        onChange={handleWebsiteUrlChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                }</Observer>
                                                <Observer>{() =>
                                                    <section className='mt-4'>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className={'form-group'}>
                                                                    <label><small>Note</small></label>
                                                                    <div className='input-group'>
                                                                        <RichTextEditor
                                                                            mode='basic'
                                                                            value={productSupplier.data.noteHtml}
                                                                            disableTab={true}
                                                                            onChange={handleNoteChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                }</Observer>
                                            </FadeIn> : null
                                    }</>
                                }</Observer>
                            </div>
                        </FadeIn>
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    {
                                        productSupplier.data.id ?
                                            <button
                                                type='button'
                                                className='btn btn-danger mr-2'
                                                onClick={handleDelete}
                                            >
                                                Delete
                                            </button> :
                                            <button
                                                type='button'
                                                className='btn btn-link btn-cancel mr-2'
                                                onClick={handleCancel}
                                            >
                                                Cancel
                                            </button>
                                    }
                                    {
                                        isProductSupplierChecked ?
                                            <button
                                                data-submit-save-new-supplier
                                                type='submit'
                                                className='btn btn-success'
                                            >
                                                Save
                                            </button> :
                                            <button
                                                data-check-new-supplier-name
                                                type='button'
                                                className='btn btn-alt'
                                                onClick={handleCheckProductSupplier}
                                            >
                                                Check
                                            </button>

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
        <BodyEnd>
            <ConfirmModal
                icon={<i className='fal fa-truck text-danger mr-2'></i>}
                message={<>Continue to delete <span className='fw-500 ml-2'>{productSupplier.originalData ? productSupplier.originalData.name : ''}</span>? </>}
                show={confirmDelete}
                onOption1Click={handleConfirmDelete}
                onCancel={handleConfirmCancel}
            />
        </BodyEnd>
    </>
}

export default NewProductSupplier;