import React, { Component } from 'react';
import { Popup } from 'devextreme-react';

import ViewProducts from './View';

import * as fn from '../../../../../utilities/_functions';

import './_index.scss';

const PRODUCT_SELECTOR_POPUP_ID = '__product-selector-popup';

export default class ItemSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedList: null,
            itemType: null,
            productType: null,
            reference: null,
            display: false,
            zIndex: null,
        };
        this.handleClose = this.handleClose.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    componentWillUnmount() {
        if (this.props.onClose) { this.props.onClose(); }
    }

    show = (selectedList, reference, itemType, productType) => {
        const zIndex = fn.getHighestZIndex() + 1;
        const popup = document.getElementById(PRODUCT_SELECTOR_POPUP_ID);

        if (popup) {
            // popup.style.zIndex = zIndex;
        }

        this.setState({ 
            selectedList: selectedList, 
            itemType: itemType, 
            productType: productType,
            reference: reference, 
            display: true, 
            zIndex: zIndex 
        });
    }

    close = () => {
        this.handleClose();
    }

    handleShowing = event => {
        const popup = document.getElementById(PRODUCT_SELECTOR_POPUP_ID);
        if (popup) {
            // const { zIndex } = this.state;
            // popup.style.zIndex = zIndex;
        }
    }

    handleShown = event => {
        this.handleShowing(event);
    }

    handleClose = event => {
        const popup = document.getElementById(PRODUCT_SELECTOR_POPUP_ID);

        if (popup) {
            // popup.style.zIndex = null;
        }

        this.setState({ 
            selectedList: null, 
            itemType: null, 
            productType: null,
            reference: null, 
            display: false, 
            zIndex: null, 
        });
    }

    handleSelect = (event, data) => {
        if (fn.isFunction(this.props.onSelect)) {
            this.props.onSelect(event, {
                reference: this.state.reference,
                data: data
            });

            if (!!this.state.reference) {
                this.close();
            }
        }
    }

    renderTitle = () => {
        return <div className='popup-title-draggable'></div>
    }

    renderContent = () => {
        return this.state.display ? <ViewProducts
            selected={this.state.selectedList}
            itemType={this.state.itemType}
            productType={this.state.productType}
            onSelect={this.handleSelect}
            onClose={this.handleClose}
        /> : null
    }

    render() {
        return <>{
            <Popup
                wrapperAttr={{ id: PRODUCT_SELECTOR_POPUP_ID, class: 'dx-popup-product-selector' }}
                visible={this.state.display}
                shading={true}
                shadingColor="rgba(0, 0, 0, 0.2)"
                width={1280}
                height={735}
                dragEnabled={true}
                dragOutsideBoundary={true}
                showTitle={true}
                titleRender={this.renderTitle}
                contentRender={this.renderContent}
                onShowing={this.handleShowing}
                onShown={this.handleShowing}
                onHiding={this.handleClose}
            />
        }</>
    }
}