import React, { Component } from 'react';
import { StickyTable, Row, Cell } from 'react-sticky-table';

import * as fn from '../../../utilities/_functions';
import * as ph from '../../../utilities/prescriptionHelper';

import './PrescriptionHistory.scss';

export default class PrescriptionHistory extends Component {
    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleView = this.handleView.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.handlePrint = this.handlePrint.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    componentDidMount() {
        const containerHeight = this.containerRef.current.parentElement.offsetHeight;
        const tableHeight = this.containerRef.current.querySelector('.sticky-table-table').offsetHeight + 2;
        this.containerRef.current.style.height = `${(Math.min(containerHeight, tableHeight))}px`;
    }

    handleUpdate = (event, prescription) => {
        if (fn.isFunction(this.props.onUpdate)) {
            this.props.onUpdate(event, prescription);
        }
    }

    handleClone = (event, prescription) => {
        if (fn.isFunction(this.props.onClone)) {
            this.props.onClone(event, prescription);
        }
    }

    handleRegenerate = (event, prescription) => {
        if (fn.isFunction(this.props.onRegenerate)) {
            this.props.onRegenerate(event, prescription);
        }
    }

    handleView = (event, prescription) => {
        if (fn.isFunction(this.props.onView)) {
            this.props.onView(event, prescription);
        }
    }

    handleDownload = (event, prescription) => {
        if (fn.isFunction(this.props.onDownload)) {
            this.props.onDownload(event, prescription);
        }
    }

    handlePrint = (event, prescription) => {
        if (fn.isFunction(this.props.onPrint)) {
            this.props.onPrint(event, prescription);
        }
    }

    handleDelete = (event, prescription) => {
        if (fn.isFunction(this.props.onDelete)) {
            this.props.onDelete(event, prescription);
        }
    }

    handleSelect = (event, prescription) => {
        if (fn.isFunction(this.props.onSelect)) {
            this.props.onSelect(event, prescription);
        }
    }

    renderHeaderRow = (template, prescriptionData, stickyColumnWidth) => {
        if (template) {
            const headers = this.getHeaderDefinitions(template, prescriptionData);

            if (headers && headers.length > 0) {
                return <Row>
                    {
                        headers.map((h, hi) => {
                            return <Cell
                                key={`prescription_history_header_${hi}`}
                                style={(hi === 0 && stickyColumnWidth ? { width: `${stickyColumnWidth}px`, maxWidth: `${stickyColumnWidth}px` } : null)}
                            >
                                <div
                                    style={(hi === 0 ? { width: '100px', maxWidth: '100px' } : null)}
                                >
                                    {h.metadata.prescriptionHistory.columnLabel}
                                </div>
                            </Cell>
                        })
                    }
                    {
                        this.props.enableAction && this.props.enableAction === true ?
                            <Cell
                                className='sticky-table-action p-2'
                                style={{ width: '30px', maxWidth: '30px' }}
                            >
                                <div></div>
                            </Cell> : null
                    }
                    {
                        this.props.enableSelect && this.props.enableSelect === true ?
                            <Cell
                                className='sticky-table-action p-2'
                                style={{ width: '50px', maxWidth: '50px' }}
                            >
                                <div></div>
                            </Cell> : null
                    }
                </Row>
            }
        }

        return <Row>
            <Cell>Column 1</Cell>
            <Cell>Column 2</Cell>
            <Cell>Column 3</Cell>
        </Row>
    }

    renderContentRows = (template, prescriptionData, stickyColumnWidth) => {
        if (template) {
            return prescriptionData && prescriptionData.length > 0 ?
                prescriptionData.map((rowData, di) => {
                    return <Row
                        name={`${template.prescriptionType.name}`}
                        key={`prescription_history_column_${di}`}
                        data-exam-id={rowData.examId}
                    >
                        {this.renderContentCell(template, prescriptionData, rowData, stickyColumnWidth)}
                        {
                            this.props.enableAction && this.props.enableAction === true ?
                                <Cell
                                    className='sticky-table-action p-2'
                                    style={{ width: '30px', maxWidth: '30px', verticalAlign: 'top' }}
                                >
                                    <div className='dropleft'>
                                        <button
                                            type='button'
                                            className='btn btn-icon dropdown-toggle dropdown-toggle-no-icon line-height-0'
                                        >
                                            <i className={'fal fa-ellipsis-v fs-xxl'}></i>
                                        </button>
                                        <div className={'dropdown-menu dropdown-menu-inline'}>
                                            {
                                                rowData.writtenById ?
                                                    <>
                                                        {
                                                            this.props.onRegenerate && fn.isFunction(this.props.onRegenerate) && rowData.isPrinted ?
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-icon width-auto px-2'
                                                                    title='Regenerate prescription'
                                                                    onClick={(e) => this.handleRegenerate(e, rowData)}
                                                                >
                                                                    <i className='fab fa-sync text-primary'></i>
                                                                </button> : null
                                                        }
                                                    </> : null
                                            }
                                            {
                                                this.props.onUpdate && fn.isFunction(this.props.onUpdate) && ph.canUpdate(rowData) ?
                                                    <button
                                                        type='button'
                                                        className='btn btn-icon width-auto px-2'
                                                        title='Update prescription'
                                                        onClick={(e) => this.handleUpdate(e, rowData)}
                                                    >
                                                        <i className='fal fa-pen'></i>
                                                    </button> : null
                                            }
                                            {
                                                this.props.onClone && fn.isFunction(this.props.onClone) && ph.canClone(rowData) ?
                                                    <button
                                                        type='button'
                                                        className='btn btn-icon width-auto px-2'
                                                        title='Clone prescription'
                                                        onClick={(e) => this.handleClone(e, rowData)}
                                                    >
                                                        <i className='fal fa-copy'></i>
                                                    </button> : null
                                            }
                                            {
                                                rowData.writtenById ?
                                                    <>
                                                        {
                                                            this.props.onView && fn.isFunction(this.props.onView) ?
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-icon width-auto px-2'
                                                                    title='View prescription'
                                                                    onClick={(e) => this.handleView(e, rowData)}
                                                                >
                                                                    <i className='fal fa-file-search'></i>
                                                                </button> : null
                                                        }
                                                        {
                                                            this.props.onDownload && fn.isFunction(this.props.onDownload) ?
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-icon width-auto px-2'
                                                                    title='Download prescription'
                                                                    onClick={(e) => this.handleDownload(e, rowData)}
                                                                >
                                                                    <i className='fal fa-file-download'></i>
                                                                </button> : null
                                                        }
                                                        {
                                                            this.props.onSms && fn.isFunction(this.props.onSms) ?
                                                                <button
                                                                    className='btn btn-icon width-auto px-2'
                                                                    title='Send prescription via SMS'
                                                                    onClick={() => alert('Not ready')}
                                                                >
                                                                    <i className='fal fa-comments-alt'></i>
                                                                </button> : null
                                                        }
                                                        {
                                                            this.props.onEmail && fn.isFunction(this.props.onEmail) ?
                                                                <button
                                                                    className='btn btn-icon width-auto px-2'
                                                                    title='Email prescription'
                                                                    onClick={() => alert('Not ready')}
                                                                >
                                                                    <i className='fal fa-envelope'></i>
                                                                </button> : null
                                                        }
                                                        {
                                                            this.props.onPrint && fn.isFunction(this.props.onPrint) ?
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-icon width-auto px-2'
                                                                    title='Print prescription'
                                                                    onClick={(e) => { this.handlePrint(e, rowData) }}
                                                                >
                                                                    <i className='fal fa-print'></i>
                                                                </button> : null
                                                        }
                                                    </> : null
                                            }
                                            {
                                                this.props.onDelete && fn.isFunction(this.props.onDelete) && ph.canUpdate(rowData) ?
                                                    <button
                                                        type='button'
                                                        className='btn btn-icon width-auto px-2'
                                                        title='Delete prescription'
                                                        onClick={(e) => this.handleDelete(e, rowData)}
                                                    >
                                                        <i className='fal fa-trash-alt text-danger'></i>
                                                    </button> : null
                                            }
                                        </div>
                                    </div>
                                </Cell> : null
                        }
                        {
                            this.props.enableSelect && this.props.enableSelect === true ?
                                <Cell
                                    className='sticky-table-action p-2'
                                    style={{ width: '50px', maxWidth: '50px', textAlign: 'right', verticalAlign: 'middle' }}
                                >
                                    {
                                        this.props.onSelect && fn.isFunction(this.props.onSelect) ?
                                            <button
                                                className='btn btn-sm btn-info'
                                                onClick={(e) => this.handleSelect(e, rowData)}
                                            >
                                                <i className='fas fa-chevron-right'></i>
                                            </button> : null
                                    }
                                </Cell> : null
                        }
                    </Row >
                }) : <div className='empty-row'><div className='text-muted'>No prescription found.</div></div>
        }

        return null;
    }

    renderContentCell = (template, prescriptionData, rowData, stickyColumnWidth) => {
        if (template) {
            const headers = this.getHeaderDefinitions(template, prescriptionData);
            const controls = this.getControlDefinitions(template);

            return headers.map((header, hi) => {
                return <Cell
                    key={`prescription_history_column_cell_${hi}`}
                    style={(hi === 0 && stickyColumnWidth ? { width: `${stickyColumnWidth}px`, maxWidth: `${stickyColumnWidth}px` } : null)}
                >
                    {this.renderPrescriptionData(header, hi, controls, rowData, stickyColumnWidth)}
                </Cell>
            })
        }
    }

    renderPrescriptionData = (header, index, controls, rowData, stickyColumnWidth) => {
        const keys = controls.filter(c => c.metadata.prescriptionHistory.column === header.metadata.prescriptionHistory.column).map(c => { return c.key });

        if (keys) {
            return keys.map((key) => {
                return <div
                    key={`prescription_history_data_${key}`}
                    style={(index === 0 && stickyColumnWidth ? { width: `${stickyColumnWidth}px`, maxWidth: `${stickyColumnWidth}px` } : null)}
                    title={fn.regexTester.html.test(this.getPrescriptionData(key, rowData)) ? fn.stripHtml(this.getPrescriptionData(key, rowData)) : this.getPrescriptionData(key, rowData)}
                    dangerouslySetInnerHTML={{ __html: this.getPrescriptionData(key, rowData) }}
                ></div>
            })
        }

        return '';
    }

    getHeaderDefinitions = (template, prescriptionData) => {
        const { definition: templateLayout } = template;
        const { definition: controlDefinitions } = templateLayout;
        let headerDefinitions = controlDefinitions
            .filter(d => d.metadata && d.metadata.prescriptionHistory && d.metadata.prescriptionHistory.show)
            .sort((a, b) => parseInt(a.metadata.prescriptionHistory.column, 10) - parseInt(b.metadata.prescriptionHistory.column, 10));

        if (!fn.isNullOrUndefined(prescriptionData) && prescriptionData.length > 0) {
            const filteredHeaderDefinitions = headerDefinitions.filter(h => prescriptionData.some(p => p.prescriptionData.some(d => h.key === d.id)));
            headerDefinitions = filteredHeaderDefinitions && filteredHeaderDefinitions.length > 0 ? filteredHeaderDefinitions : headerDefinitions;
        } else {
            headerDefinitions = headerDefinitions.filter(d => d.key.startsWith('__'));
        }

        const missingHeaders = headerDefinitions.filter(d1 => d1.metadata.prescriptionHistory.row === '2' && !headerDefinitions.some(d2 => d2.metadata.prescriptionHistory.row === '1' && d2.metadata.prescriptionHistory.column === d1.metadata.prescriptionHistory.column));

        if (missingHeaders && missingHeaders.length > 0) {
            for (let m = 0; m < missingHeaders.length; m++) {
                headerDefinitions.push(controlDefinitions.filter(c => c.metadata && c.metadata.prescriptionHistory && c.metadata.prescriptionHistory.row === '1' && c.metadata.prescriptionHistory.column === missingHeaders[m].metadata.prescriptionHistory.column)[0]);
            }
        }

        headerDefinitions = headerDefinitions
            .filter(h => h.metadata.prescriptionHistory.row === '1')
            .sort((a, b) => parseInt(a.metadata.prescriptionHistory.row, 10) < parseInt(b.metadata.prescriptionHistory.row, 10) ? -1 : 1)
            .sort((a, b) => parseInt(a.metadata.prescriptionHistory.column, 10) < parseInt(b.metadata.prescriptionHistory.column, 10) ? -1 : 1);

        return headerDefinitions;
    }

    getControlDefinitions = (template) => {
        const { definition: templateLayout } = template;
        const { definition: controlDefinitions } = templateLayout;
        return controlDefinitions
            .filter(d => d.metadata && d.metadata.prescriptionHistory && d.metadata.prescriptionHistory.show)
            .sort((a, b) => parseInt(`${a.metadata.prescriptionHistory.column}${a.metadata.prescriptionHistory.row}`, 10) - parseInt(`${b.metadata.prescriptionHistory.column}${b.metadata.prescriptionHistory.row}`, 10));
    }

    getPrescriptionData = (key, rowData) => {
        const data = rowData.prescriptionData.filter(d => d.id === key)[0]

        if (data) {
            return data.value;
        }

        return '&nbsp;';
    }

    render() {
        const { template, data: prescriptionData, stickyColumnWidth, enableAction, enableSelect } = this.props;

        return (
            <div
                ref={this.containerRef}
                className={'prescription-history-list' + (this.props.className ? ` ${this.props.className}` : '') + (!prescriptionData || prescriptionData.length === 0 ? ' empty-table' : '')}
            >
                <StickyTable
                    leftStickyColumnCount={1}
                    rightStickyColumnCount={((enableAction === true || enableSelect === true) ? 1 : null)}
                >
                    {
                        <>
                            {this.renderHeaderRow(template, prescriptionData, stickyColumnWidth)}
                            {this.renderContentRows(template, prescriptionData, stickyColumnWidth)}
                        </>
                    }
                </StickyTable>
            </div>)
    }
}