import { createContext } from 'react';
import { decorate, observable, action, computed } from 'mobx';
import moment from 'moment';

import api from '../api';
import * as fn from '../utilities/_functions';

export class AppointmentView {
    id = null;
    data = null;
    isLoading = false;
    isReady = false;
    cancelAppointmentGet = null;

    initialize = (id) => {
        const that = this;

        this.clear();
        this.id = id;
        this.isReady = false;

        return new Promise((resolve, reject) => {
            that.refresh()
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isReady = true;
                })
        })
    }

    refresh = () => {
        const that = this;
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            api.Appointments.get(
                that.id,
                (c) => { that.cancelAppointmentGet = c; }
            )
                .then(({ data }) => {
                    that.data = data;
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isLoading = false;
                })
        })
    }

    clear = () => {
        this.id = null;
        this.data = null;
        this.isLoading = false;
        this.isReady = false;

        if (fn.isFunction(this.cancelAppointmentGet)) {
            this.cancelAppointmentGet();
            this.cancelAppointmentGet = null;
        }
    }

    get start() {
        if (!this.data) return null;
        return moment(this.data.start);
    }

    get startUtc() {
        if (!this.data) return null;
        return moment.utc(this.data.startUtc);
    }

    get end() {
        if (!this.data) return null;
        return moment(this.data.end);
    }

    get endUtc() {
        if (!this.data) return null;
        return moment(this.data.endUtc);
    }
}

decorate(AppointmentView, {
    id: observable,
    data: observable,
    isLoading: observable,
    isReady: observable,
    initialize: action,
    refresh: action,
    clear: action,
    start: computed,
    startUtc: computed,
    end: computed,
    endUtc: computed,
})

export default createContext(new AppointmentView());