import { createContext } from 'react';
import { decorate, observable, action, computed, toJS } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class ProductUpdate {
    id = null;
    originalData = null;
    data = null;
    types = [];
    options = [];
    referenceNumber = null;
    note = null;
    noteHtml = null;
    newBrandMapping = null;
    hasUnsavedChanges = false;
    isLoading = false;
    isSaving = false;
    isReady = false;

    cancelProductDelete = null;
    cancelProductGet = null;
    cancelProductOptions = null;
    cancelProductTypes = null;
    cancelProductUpdate = null;

    initialize = (id) => {
        const that = this;

        this.clear();
        this.id = id;
        this.isReady = false;

        return new Promise((resolve, reject) => {
            that.refresh()
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isReady = true;
                })
        })
    }

    refresh = () => {
        const that = this;
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            Promise.all([
                api.ProductTypes.all((c) => { that.cancelProductTypes = c }),
                api.ProductOptions.all((c) => { that.cancelProductOptions = c }),
                api.Products.get(that.id, (c) => { that.cancelProductGet = c })
            ])
                .then((response) => {
                    that.types = response[0].data;
                    that.options = response[1].data;
                    that.data = response[2].data;
                    that.originalData = response[2].data;
                    if (!that.data.serialNumbers || that.data.serialNumbers.length === 0) {
                        that.data.serialNumbers = new Array(1);
                    }
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isLoading = false;
                })
        })
    }

    updateType = (id) => {
        const selectedBrand = this.selectedType && this.selectedType.brands ? this.selectedType.brands.filter(b => b.id === this.data.brandId)[0] : null;

        if (this.newBrandMapping && this.selectedType && this.selectedType.brands && this.selectedType.brands.some(b => b.name === this.newBrandMapping.name)) {
            const index = this.selectedType.brands.findIndex(b => b.id === this.newBrandMapping.id);

            this.selectedType.brands.splice(index, 1);
            this.newBrandMapping = null;
        }

        this.data.typeId = id;

        if (this.selectedType) {
            if (this.selectedType.inventory === 'Physical') {
                this.data.isStocked = true;
                this.data.isTracked = true;
            } else {
                this.data.isStocked = false;
                this.data.isTracked = false;
                this.data.quantity = null;
            }
        }

        if (selectedBrand && !this.selectedType.brands.some(b => b.name === selectedBrand.name)) {
            this.newBrandMapping = selectedBrand;
            this.selectedType.brands.push(selectedBrand);
        } else {
            this.data.brandId = this.selectedType.brands.filter(b => b.name === selectedBrand.name)[0].id;
        }

        this.hasUnsavedChanges = true;
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            let option = toJS(that.data);

            option.cost = isNaN(option.cost) ? fn.parseCurrency(option.cost) : option.cost;
            option.price = isNaN(option.price) ? fn.parseCurrency(option.price) : option.price;

            if (option.isTracked && that.originalData.quantity !== option.quantity) {
                option.referenceNumber = that.referenceNumber;
                option.note = that.note;
                option.noteHtml = that.noteHtml;
            } 

            if (that.hasUnsavedChanges) {
                api.Products.update(that.data.id, option, (c) => { that.cancelProductUpdate = c })
                    .then(() => {
                        that.hasUnsavedChanges = false;
                        resolve(that.data);
                    })
                    .catch(() => {
                        reject();
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                that.hasUnsavedChanges = false;
                that.isSaving = false;
                resolve();
            }
        })
    }

    delete = () => {
        const that = this;
        this.isSaving = true;

        return new Promise((resolve, reject) => {
            api.Products.delete(that.id, (c) => { that.cancelProductDelete = c })
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    clear = () => {
        this.id = null;
        this.data = null;
        this.originalData = null;
        this.types.clear();
        this.options.clear();
        this.referenceNumber = null;
        this.note = null;
        this.noteHtml = null;
        this.newBrandMapping = null;
        this.hasUnsavedChanges = false;
        this.isLoading = false;
        this.isSaving = false;
        this.isReady = false;

        if (fn.isFunction(this.cancelProductDelete)) {
            this.cancelProductDelete();
            this.cancelProductDelete = null;
        }

        if (fn.isFunction(this.cancelProductGet)) {
            this.cancelProductGet();
            this.cancelProductGet = null;
        }

        if (fn.isFunction(this.cancelProductOptions)) {
            this.cancelProductOptions();
            this.cancelProductOptions = null;
        }

        if (fn.isFunction(this.cancelProductTypes)) {
            this.cancelProductTypes();
            this.cancelProductTypes = null;
        }

        if (fn.isFunction(this.cancelProductUpdate)) {
            this.cancelProductUpdate();
            this.cancelProductUpdate = null;
        }
    }

    get selectedType() {
        if (!this.data || !this.data.typeId || !this.types || this.types.length === 0) return null;
        return this.types.filter(t => t.id === this.data.typeId)[0];
    }

    get brands() {
        if (!this.data || !this.data.type.id || !this.types || this.types.length === 0) return [];
        return this.types.filter(t => t.id === this.data.type.id)[0].brands;
    }
}

decorate(ProductUpdate, {
    id: observable,
    data: observable,
    originalData: observable,
    types: observable,
    options: observable,
    referenceNumber: observable,
    note: observable,
    noteHtml: observable,
    newBrandMapping: observable,
    hasUnsavedChanges: observable,
    isLoading: observable,
    isSaving: observable,
    isReady: observable,
    initialize: action,
    refresh: action,
    updateType: action,
    save: action,
    delete: action,
    clear: action,
    selectedType: computed,
    brands: computed,
})

export default createContext(new ProductUpdate());