export const hasAutomatedCommunication = (status) => {
    if (!status) return null;

    switch (status) {
        case 'Ready':
            return 'CustomerNotified';

        default:
            break;
    }
    return null;
}