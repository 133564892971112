import React, { useEffect, useContext, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { GlobalHotKeys } from 'react-hotkeys';
import { useObserver } from 'mobx-react-lite';

import { quickDrawerFocus, renderQuickDrawerLoading } from '../../../../_shared/QuickDrawer';
import QuickDrawerHeader from '../../../../_shared/QuickDrawerHeader';
import RichTextEditor from '../../../../_shared/RichTextEditor';

import InternalFormTemplateUpdateStore from '../../../../../../stores/InternalFormTemplateUpdateStore';
import QuickDrawerStore from '../../../../../../stores/QuickDrawerStore';
import CacheStore from '../../../../../../stores/CacheStore';

import * as ErrorMessages from '../../../../../../constants/errorMessages';
import * as fn from '../../../../../../utilities/_functions';
import * as oh from '../../../../../../utilities/operationHelper';

import './UpdateInternalFormTemplate.scss';

function UpdateInternalFormTemplate(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const internalFormTemplate = useContext(InternalFormTemplateUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const cache = useContext(CacheStore);

    useEffect(() => {
        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100)
        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (internalFormTemplate.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = event => {
        event.preventDefault();
        internalFormTemplate.save()
            .then(data => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, data);
                    }
                }
            })
    }

    const handleNameChange = event => {
        internalFormTemplate.data.name = event.target.value;
        internalFormTemplate.hasUnsavedChanges = true;
    }

    const handleDescriptionChange = content => {
        const html = content;
        internalFormTemplate.data.descriptionHtml = ((html === '<p><br></p>') ? null : html);
        internalFormTemplate.hasUnsavedChanges = true;
    }

    const handleCategoryChange = event => {
        const category = event.target.value;
        internalFormTemplate.data.category = category;
        internalFormTemplate.hasUnsavedChanges = true;
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form onSubmit={handleSubmit}>
            <fieldset disabled={internalFormTemplate.isSaving}>
                <div className='quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('internal-form-template', 'default')}
                        action='Update'
                        category='Internal Form Template'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        {
                            internalFormTemplate.isReady ?
                                <FadeIn>
                                    <div className='new-internal-form-template body-content'>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label className='required' htmlFor='new-internal-form-template-name'><small>Name</small></label>
                                                        <input
                                                            id='new-internal-form-template-name'
                                                            type='text'
                                                            maxLength={100}
                                                            className='form-control'
                                                            autoComplete={'off'}
                                                            value={internalFormTemplate.data.name ? internalFormTemplate.data.name : ''}
                                                            onChange={handleNameChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label htmlFor='new-internal-form-template-category'><small>Category</small></label>
                                                        <select
                                                            id='new-internal-form-template-category'
                                                            className='custom-select form-control'                                                            
                                                            value={internalFormTemplate.data.category ? internalFormTemplate.data.category : ''}
                                                            onChange={handleCategoryChange}
                                                        >
                                                            <option value=''>(All)</option>
                                                            {
                                                                cache.getReferenceDataOptions('InternalFormCategoryType').map((option, di) => {
                                                                    return <option key={`new-internal-form-category-${di}`} value={option.key}>{option.value}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-4'>
                                                        <label htmlFor='new-internal-form-template-description'><small>Description</small></label>
                                                        <RichTextEditor
                                                            mode='none'
                                                            disableTab={true}
                                                            value={internalFormTemplate.data.descriptionHtml ? internalFormTemplate.data.descriptionHtml : ''}
                                                            onChange={handleDescriptionChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </FadeIn> : renderQuickDrawerLoading()
                        }
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-4'></div>
                            <div className='col-8'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>)
}

export default UpdateInternalFormTemplate;