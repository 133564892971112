import { createContext } from 'react';
import { decorate, observable, action } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class WorkOrderItemUpdate {
    id = null;
    data = null;
    updatedReason = null;
    updatedReasonHtml = null;
    hasUnsavedChanges = false;
    isLoading = false;
    isSaving = false;
    isReady = false;

    cancelWorkOrderItem = null;
    cancelWorkOrderItemUpdate = null;

    initialize = id => {
        const that = this;

        this.clear();
        this.id = id;

        return new Promise((resolve, reject) => {
            this.refresh()
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isReady = true;
                })
        })
    }

    refresh = () => {
        const that = this;

        this.isLoading = true;

        return new Promise((resolve, reject) => {
            api.WorkOrderItems.get(that.id, (c) => { that.cancelWorkOrderItem = c })
                .then(({ data }) => {
                    that.data = data;
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isLoading = false;
                })
        })
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            if (that.hasUnsavedChanges) {
                api.WorkOrderItems.override(
                    that.data.id,
                    {
                        brandName: that.data.overriddenBrandName,
                        supplierName: that.data.overriddenSupplierName,
                        descriptor: that.data.overriddenDescriptor,
                        unitCost: that.data.overriddenUnitCost,
                        updatedReason: that.updatedReason,
                        updatedReasonHtml: that.updatedReasonHtml,
                    },
                    (c) => { that.cancelWorkOrderItemUpdate = c }
                )
                    .then(() => {
                        that.hasUnsavedChanges = false;
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                that.hasUnsavedChanges = false;
                that.isSaving = false;
                resolve();
            }
        })
    }

    clear = () => {
        this.id = null;
        this.data = null;
        this.updatedReason = null;
        this.updatedReasonHtml = null;
        this.hasUnsavedChanges = false;
        this.isLoading = false;
        this.isSaving = false;
        this.isReady = false;

        if (fn.isFunction(this.cancelWorkOrderItem)) {
            this.cancelWorkOrderItem();
            this.cancelWorkOrderItem = null;
        }

        if (fn.isFunction(this.cancelWorkOrderItemUpdate)) {
            this.cancelWorkOrderItemUpdate();
            this.cancelWorkOrderItemUpdate = null;
        }
    }
}

decorate(WorkOrderItemUpdate, {
    id: observable,
    data: observable,
    updatedReason: observable,
    updatedReasonHtml: observable,
    isLoading: observable,
    isSaving: observable,
    isReady: observable,
    initialize: action,
    unlink: action,
    save: action,
    clear: action,
})

export default createContext(new WorkOrderItemUpdate());