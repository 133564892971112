import { createContext } from 'react';
import { decorate, observable, action } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class TimelineItemView {
    items = null;
    isLoading = false;
    isReady = false;
    hasUnsavedChanges = false;

    cancelTimelineItems = null;

    initialize = async () => {
        await this.refresh();
        this.isReady = true;
    }

    refresh = async () => {
        this.isLoading = true;
        try {
            const { data } = await api.TimelineItems.all((c) => { this.cancelTimelineItems = c });
            this.items = data;
        }
        catch(e) {
            console.log(e);
        }
        finally {
            this.isLoading = false;
        }
    }

    clear = () => {
        this.id = null;
        this.items = null;
        this.isLoading = false;
        this.isReady = false;

        if (fn.isFunction(this.cancelTimelineItems)) {
            this.cancelTimelineItems();
            this.cancelTimelineItems = null;
        }
    }
}

decorate(TimelineItemView, {
    id: observable,
    data: observable.deep,
    isLoading: observable,
    isReady: observable,
    initialize: action,
    refresh: action,
    save: action,
    clear: action,
})

export default createContext(new TimelineItemView());