import React, { useEffect, useContext, useState, useRef, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { toJS } from 'mobx';
import FadeIn from 'react-fade-in';
import { Observer } from 'mobx-react-lite';
import { Combobox } from 'react-widgets'
import { toast } from 'react-toastify';
import { GlobalHotKeys } from 'react-hotkeys';
import moment from 'moment';

import BodyEnd from '../../_shared/BodyEnd';
import ConfirmModal from '../../_shared/ConfirmModal';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';
import RichTextEditor from '../../_shared/RichTextEditor';

import CustomerUpdateStore from '../../../../stores/CustomerUpdateStore';
import PrivateInsuranceProviderCreateStore from '../../../../stores/PrivateInsuranceProviderCreateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as rts from '../../../../constants/routes';
import * as fn from '../../../../utilities/_functions';
import * as ch from '../../../../utilities/customerHelper';
import * as ph from '../../../../utilities/personHelper';
import * as oh from '../../../../utilities/operationHelper';
import * as ah from '../../../../utilities/addressHelper';

import api from '../../../../api';

import './UpdatePrivateInsurance.scss';

function UpdatePrivateInsurance(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const focusTimer = useRef(null);
    const customer = useContext(CustomerUpdateStore);
    const privateInsuranceProvider = useContext(PrivateInsuranceProviderCreateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [privateInsuranceProviders, setPrivateInsuranceProviders] = useState([]);
    const [members, setMembers] = useState([]);
    const [isPrimaryHolder, setIsPrimaryHolder] = useState(false);
    const [confirmUpdate, setConfirmUpdate] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100)

        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (customer.hasPrivateInsuranceUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const loadPrivateInsuranceProviders = () => {
        return new Promise((resolve, reject) => {
            api.PrivateInsuranceProviders.all()
                .then(({ data }) => {
                    if (isMounted.current) {
                        setPrivateInsuranceProviders(data);
                    }
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        })
    }

    const handlePrivateInsuranceList = (event) => {
        if (!customer.hasPrivateInsuranceUnsavedChanges || window.confirm(ErrorMessages.DISCARD_CHANGES)) {
            customer.hasPatientUnsavedChanges = false;
            customer.loadInsurance()
                .then(() => {
                    if (isMounted.current) {
                        quickDrawer.activateQuickDrawer('customer', 'private-insurance', null, handleInsuranceInformationListSuccess, handleInsuranceInformationListCancel);
                    }
                })
        }
    }

    const handleInsuranceInformationListSuccess = (event, result) => {
        if (isMounted.current && result && result.updated) {
            customer.loadInsurance();
        }
    }

    const handleInsuranceInformationListCancel = (event) => {
    }

    const handleInsuranceProviderChange = (event) => {
        const privateInsuranceProviderId = event.target.value;
        customer.selectedPrivateInsurance.privateInsuranceProviderId = privateInsuranceProviderId;
        customer.hasPrivateInsuranceUnsavedChanges = true;
    }

    const handlePrimaryPolicyHolderChange = (value) => {
        if (isMounted.current && customer.selectedPrivateInsurance) {
            if (value) {
                let relationship = typeof (value) === 'string' ? null : value;

                if (typeof (value) === 'string') {
                    relationship = customer.relationships.filter(r => r.relatedTo.fullName.toLowerCase() === value.toLowerCase())[0];
                }

                if (relationship) {
                    customer.selectedPrivateInsurance.primaryPolicyHolderId = relationship.relatedTo.id;
                    customer.selectedPrivateInsurance.policyHolderName = relationship.relatedTo.fullName;

                    if (!customer.selectedPrivateInsurance.members.some(p => p.id === customer.selectedPrivateInsurance.primaryPolicyHolderId)) {
                        customer.selectedPrivateInsurance.members.push(relationship.relatedTo);
                    }
                } else {
                    customer.selectedPrivateInsurance.primaryPolicyHolderId = null;
                    customer.selectedPrivateInsurance.policyHolderName = value;
                }
            } else {
                customer.selectedPrivateInsurance.primaryPolicyHolderId = null;
                customer.selectedPrivateInsurance.policyHolderName = null;
            }

            customer.hasPrivateInsuranceUnsavedChanges = true;
        }
    }

    const handleIsPrimaryHolderChange = () => {
        if (isMounted.current) {
            if (!customer.selectedPrivateInsurance || !customer.selectedPrivateInsurance.members) { customer.selectedPrivateInsurance.members = []; }

            const newIsPrimaryHolder = !(isPrimaryHolder || (customer.selectedPrivateInsurance && customer.selectedPrivateInsurance.primaryPolicyHolderId === customer.id));

            if (newIsPrimaryHolder) {
                customer.selectedPrivateInsurance.primaryPolicyHolderId = customer.id;
                customer.selectedPrivateInsurance.policyHolderName = customer.data.fullName;
            } else {
                customer.selectedPrivateInsurance.primaryPolicyHolderId = null;
                customer.selectedPrivateInsurance.policyHolderName = null;
            }

            customer.hasPrivateInsuranceUnsavedChanges = true;

            setIsPrimaryHolder(newIsPrimaryHolder);
        }
    }

    const handleMemberChange = (event) => {
        if (isMounted.current && customer.selectedPrivateInsurance) {
            const memberId = event.target.value;
            customer.selectedPrivateInsurance.memberId = memberId;
            customer.hasPrivateInsuranceUnsavedChanges = true;
        }
    }

    const handlePlanNumberChange = (event) => {
        if (isMounted.current && customer.selectedPrivateInsurance) {
            const planNumber = event.target.value;
            customer.selectedPrivateInsurance.planNumber = planNumber;
            customer.hasPrivateInsuranceUnsavedChanges = true;
        }
    }

    const handleAccountNumberChange = (event) => {
        if (isMounted.current && customer.selectedPrivateInsurance) {
            const accountNumber = event.target.value;
            customer.selectedPrivateInsurance.accountNumber = accountNumber;
            customer.hasPrivateInsuranceUnsavedChanges = true;
        }
    }

    const handlePolicyNumberChange = (event) => {
        if (isMounted.current && customer.selectedPrivateInsurance) {
            const policyNumber = event.target.value;
            customer.selectedPrivateInsurance.policyNumber = policyNumber;
            customer.hasPrivateInsuranceUnsavedChanges = true;
        }
    }

    const handleMembersChange = (event, member) => {
        if (isMounted.current && customer.selectedPrivateInsurance) {
            if (!customer.selectedPrivateInsurance || !customer.selectedPrivateInsurance.members) { customer.selectedPrivateInsurance.members = [] }

            const index = customer.selectedPrivateInsurance.members.map(m => { return m.id }).indexOf(member.id);

            if (index === -1) {
                customer.selectedPrivateInsurance.members.push(member);
            } else {
                customer.selectedPrivateInsurance.members.splice(index, 1);
            }

            customer.hasPrivateInsuranceUnsavedChanges = true;
        }
    }

    const handleInsuranceNoteChange = (content) => {
        if (isMounted.current && customer.selectedPrivateInsurance) {
            const noteHtml = (content === '<p><br></p>') ? null : content;
            customer.selectedPrivateInsurance.noteHtml = noteHtml;
            customer.hasPrivateInsuranceUnsavedChanges = true;
        }
    }

    const handleNewInsurance = () => {
        if (isMounted.current) {
            quickDrawer.activateQuickDrawer('customer', 'private-insurance-create', null, handleNewPrivateInsuranceSuccess, handleNewPrivateInsuranceCancel);
        }
    }

    const handleNewPrivateInsuranceSuccess = (result) => {
        if (result && result.updated) {
            customer.clearNewPrivateInsurance();
            toast.dark(() => <p data-ins-upd>Customer's insurance updated.</p>);

            if (props.onCancel && fn.isFunction(props.onCancel)) {
                props.onCancel();
            }
        }
    }

    const handleNewPrivateInsuranceCancel = () => {
        customer.clearNewPrivateInsurance();
    }

    const handleDeletePrivateInsurance = () => {
        if (isMounted.current) {
            if (customer.selectedPrivateInsurance && customer.selectedPrivateInsurance.members.length > 0) {
                quickDrawer.activateQuickDrawer('customer', 'private-insurance-delete', null, handleDeletePrivateInsuranceSuccess, handleDeletePrivateInsuranceCancel);
            } else {
                setConfirmDelete(true);
            }
        }
    }

    const handleDeletePrivateInsuranceSuccess = (event, result) => {
        if (result && result.updated) {
            toast.dark(() => <p data-ins-upd>Customer's insurance updated.</p>);

            if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                props.onSuccess(event, { deleted: true, data: null });
            }
        }
    }

    const handleDeletePrivateInsuranceCancel = () => { }

    const handleUpdatePrivateInsuranceSubmit = (event) => {
        event.preventDefault();

        const {
            privateInsuranceProviderId,
            policyHolderName,
            memberId,
            planNumber,
            accountNumber,
            policyNumber,
        } = customer.selectedPrivateInsurance;

        const isValid = privateInsuranceProviderId || policyHolderName || memberId || planNumber || accountNumber || policyNumber;

        if (isValid) {
            if (isMounted.current) {
                if (customer.hasPrivateInsuranceUnsavedChanges) {
                    if (customer.selectedPrivateInsurance && customer.selectedPrivateInsurance.members && customer.selectedPrivateInsurance.members.length > 1) {
                        setConfirmUpdate(true);
                    } else {
                        handleConfirmUpdate(event);
                    }
                } else {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, { updated: false, data: null });
                    }
                }
            }
        }
        else {
            toast.error(() => <p>At least one field is required to proceed.</p>, { position: 'top-center', style: { width: '380px' } });
        }
    }

    const handleConfirmUpdate = (event) => {
        const updated = customer.hasPrivateInsuranceUnsavedChanges;

        customer.updatePrivateInsuranceInformation()
            .then(data => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, { updated: updated, data: data });
                    }

                    if (isMounted.current) { setConfirmUpdate(false); }
                }
            })
    }

    const handleCancelUpdate = () => {
        if (isMounted.current) {
            setConfirmUpdate(false);
        }
    }

    const handleConfirmDelete = (event) => {
        customer.deletePrivateInsuranceInformation('Delete')
            .then(data => {
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, { deleted: true, data: data });
                    }
                    setConfirmDelete(false);
                }
            })
    }

    const handleCancelDelete = () => {
        if (isMounted.current) {
            setConfirmDelete(false);
        }
    }

    const handlePrivateInsuranceProviderAdd = () => {
        privateInsuranceProvider.initialize();
        quickDrawer.activateQuickDrawer('privateInsurance', 'create', null, handlePrivateInsuranceProviderAddSuccess, handlePrivateInsuranceProviderAddCancel);
    }

    const handlePrivateInsuranceProviderAddSuccess = (result) => {
        if (result && result.updated) {
            loadPrivateInsuranceProviders()
                .then(() => {
                    toast.dark(() => <p data-ins-prov-added>Insurance provider added.</p>);
                })
        }
        privateInsuranceProvider.clear();
    }

    const handlePrivateInsuranceProviderAddCancel = (event) => {
        privateInsuranceProvider.clear();
    }

    const renderCustomer = () => {
        return customer && customer.data ? <div
            className='profile-wrapper'
        >
            <div className='profile'>
                <span
                    className={`profile-image profile-initials rounded-circle d-flex text-white ${ch.getProfileColor(customer.data)} fw-500`}
                    title={ph.getFullName(customer.data)}
                >
                    {customer.data.initials}
                </span>
            </div>
            <div className='description flex-1'>
                <Link to={`${rts.Customers.Home}/${customer.data.id}`} className='name text-gray-700' onClick={quickDrawer.deactivateAll}>{ph.getFullName(customer.data, true)}
                    {
                        customer.data.dateOfBirth || customer.data.sex || customer.data.gender || customer.data.pronoun ?
                            <small className='text-nowrap ml-2'>({`${ph.getAge(customer.data.dateOfBirth)} ${ph.getSexGenderPronounDisplay(customer.data)}`.trim()})</small> : null
                    }
                </Link>
                {
                    customer.data.address && customer.data.address.country ?
                        <div className='info'>{ah.getAddressHtml(customer.data.address)}</div> : null
                }
            </div>
        </div> : null
    }

    const renderInsuranceInformation = () => {
        return isReady ? <FadeIn>
            <div className='body-content'>
                <Observer>{() =>
                    <section className='customer'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='form-group mb-0'>
                                    <label><small>Customer</small></label>
                                    {renderCustomer()}
                                </div>
                            </div>
                        </div>
                    </section>
                }</Observer>
                <Observer>{() =>
                    <>
                        {
                            customer.privateInsurances && customer.privateInsurances.length > 1 && customer.selectedPrivateInsuranceIndex >= 0 ?
                                <section>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='form-group mb-2'>
                                                <label><small>Priority</small></label>
                                                <div className='d-flex align-items-center'>
                                                    <div className='flex-1'>
                                                        {customer.selectedPrivateInsuranceIndex === 0 ? <span className='badge badge-success fs-sm text-shadow-0'>Primary</span> : null}
                                                        {customer.selectedPrivateInsuranceIndex === 1 ? <span className='badge badge-secondary fs-sm text-shadow-0'>Secondary</span> : null}
                                                        {customer.selectedPrivateInsuranceIndex > 1 ? <span className='badge badge-secondary fs-sm text-shadow-0'>Other</span> : null}
                                                    </div>
                                                    <div className='pl-2'>
                                                        <button
                                                            type='button'
                                                            className='btn btn-icon line-height-1 mr-n2'
                                                            title='Set priority'
                                                            onClick={handlePrivateInsuranceList}
                                                        >
                                                            <i className='fal fa-pen fs-lg'></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section> : null
                        }
                    </>
                }</Observer>
                <Observer>{() =>
                    <section className='mt-3'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='form-group mb-0'>
                                    <label htmlFor='insurance-provider'><small>Insurance Provider</small></label>
                                    <select
                                        id='insurance-provider'
                                        className='custom-select form-control'
                                        disabled={!ch.checkCanUpdate(customer.data)}
                                        value={(customer.selectedPrivateInsurance && customer.selectedPrivateInsurance.privateInsuranceProviderId) ? customer.selectedPrivateInsurance.privateInsuranceProviderId : ''}
                                        onChange={handleInsuranceProviderChange}
                                    >
                                        <option value=''></option>
                                        {
                                            privateInsuranceProviders.map((i, ii) => {
                                                return <option
                                                    key={`insurance-provider-${ii}`}
                                                    value={i.id}
                                                >
                                                    {i.name}
                                                </option>
                                            })
                                        }
                                    </select>
                                    <button
                                        type='button'
                                        className='btn btn-link p-0 mt-1'
                                        disabled={!ch.checkCanUpdate(customer.data)}
                                        onClick={handlePrivateInsuranceProviderAdd}
                                    >Add new insurance provider</button>
                                </div>
                            </div>
                        </div>
                    </section>
                }</Observer>
                <Observer>{() =>
                    <section>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='form-group mb-0'>
                                    <label htmlFor='primary-policy-holder'><small>Primary Policy Holder</small></label>
                                    <Combobox
                                        data={(customer.relationships && customer.relationships.length > 0) ? customer.relationships : []}
                                        value={(customer.selectedPrivateInsurance && customer.selectedPrivateInsurance.policyHolderName) ? customer.selectedPrivateInsurance.policyHolderName : ''}
                                        itemComponent={({ item }) => (
                                            item ? <>{item.relatedTo.fullName}</> : <></>
                                        )}
                                        valueComponent={({ item }) => (
                                            item ? <>{item.relatedTo.fullName}</> : <></>
                                        )}
                                        disabled={isPrimaryHolder || !ch.checkCanUpdate(customer.data) || (customer.selectedPrivateInsurance && customer.selectedPrivateInsurance.primaryPolicyHolderId === customer.id)}
                                        onChange={handlePrimaryPolicyHolderChange}
                                    />
                                    <div
                                        className='custom-control custom-checkbox custom-control-inline mt-2'
                                    >
                                        <input
                                            id='insurance-is-primary-holder'
                                            type='checkbox'
                                            name='insurance-is-primary-holder'
                                            className='custom-control-input'
                                            disabled={!ch.checkCanUpdate(customer.data)}
                                            checked={isPrimaryHolder || (customer.selectedPrivateInsurance && customer.selectedPrivateInsurance.primaryPolicyHolderId === customer.id) || false}
                                            onChange={handleIsPrimaryHolderChange}
                                        />
                                        <label
                                            htmlFor='insurance-is-primary-holder'
                                            className='custom-control-label'
                                        >
                                            {ph.getPreferredFirstName(customer.data)} is primary policy holder
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }</Observer>
                <Observer>{() =>
                    <section>
                        <div className='row'>
                            <div className='col-6'>
                                <div className='form-group mb-0'>
                                    <label htmlFor='insurance-member'><small>Member</small></label>
                                    <input
                                        id='insurance-member'
                                        type='text'
                                        className='form-control'
                                        spellCheck={false}
                                        maxLength='50'
                                        autoComplete='off'
                                        disabled={!ch.checkCanUpdate(customer.data)}
                                        value={(customer.selectedPrivateInsurance && customer.selectedPrivateInsurance.memberId) ? customer.selectedPrivateInsurance.memberId : ''}
                                        onChange={handleMemberChange}
                                    />
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='form-group mb-0'>
                                    <label htmlFor='insurance-plan'><small>Plan / Group</small></label>
                                    <input
                                        id='insurance-plan'
                                        type='text'
                                        className='form-control'
                                        spellCheck={false}
                                        maxLength='50'
                                        autoComplete='off'
                                        disabled={!ch.checkCanUpdate(customer.data)}
                                        value={(customer.selectedPrivateInsurance && customer.selectedPrivateInsurance.planNumber) ? customer.selectedPrivateInsurance.planNumber : ''}
                                        onChange={handlePlanNumberChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                }</Observer>
                <Observer>{() =>
                    <section>
                        <div className='row'>
                            <div className='col-6'>
                                <div className='form-group mb-0'>
                                    <label htmlFor='insurance-account'><small>Account</small></label>
                                    <input
                                        id='insurance-account'
                                        type='text'
                                        className='form-control'
                                        spellCheck={false}
                                        maxLength='50'
                                        autoComplete='off'
                                        disabled={!ch.checkCanUpdate(customer.data)}
                                        value={(customer.selectedPrivateInsurance && customer.selectedPrivateInsurance.accountNumber) ? customer.selectedPrivateInsurance.accountNumber : ''}
                                        onChange={handleAccountNumberChange}
                                    />
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='form-group mb-0'>
                                    <label htmlFor='insurance-policy'><small>Policy / Certificate</small></label>
                                    <input
                                        id='insurance-policy'
                                        type='text'
                                        className='form-control'
                                        spellCheck={false}
                                        maxLength='50'
                                        autoComplete='off'
                                        disabled={!ch.checkCanUpdate(customer.data)}
                                        value={(customer.selectedPrivateInsurance && customer.selectedPrivateInsurance.policyNumber) ? customer.selectedPrivateInsurance.policyNumber : ''}
                                        onChange={handlePolicyNumberChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                }</Observer>
                <Observer>{() =>
                    <>
                        {
                            customer.relationships && customer.relationships.length > 0 ?
                                <section>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='form-group mb-0'>
                                                <label htmlFor='primary-policy-holder'><small>Other Plan Member(s)</small></label>
                                                {
                                                    customer.relationships.map((r, ri) => {
                                                        return <Fragment
                                                            key={`insurance_other_plan_member_${ri}`}
                                                        >
                                                            <div className={`d-flex` + (ri !== 0 ? ' mt-2' : '')}>
                                                                <div className='d-flex flex-row align-item-center justify-content-center'>
                                                                    <div
                                                                        className='custom-control custom-checkbox custom-checkbox-inline'
                                                                    >
                                                                        <input
                                                                            id={`update_insurance_other_plan_member_${ri}`}
                                                                            type='checkbox'
                                                                            name='update-insurance-plan-member'
                                                                            className='custom-control-input'
                                                                            disabled={!ch.checkCanUpdate(customer.data) || (customer.selectedPrivateInsurance && customer.selectedPrivateInsurance.primaryPolicyHolderId === r.relatedTo.id && customer.selectedPrivateInsurance.members.some(p => p.id === r.relatedTo.id))}
                                                                            checked={(customer.selectedPrivateInsurance && customer.selectedPrivateInsurance.members) ? customer.selectedPrivateInsurance.members.some(p => p.id === r.relatedTo.id) : false}
                                                                            onChange={(e) => { handleMembersChange(e, r.relatedTo) }}
                                                                        />
                                                                        <label
                                                                            htmlFor={`update_insurance_other_plan_member_${ri}`}
                                                                            className='custom-control-label'
                                                                        >
                                                                            <span className='text-gray-700'>
                                                                                {ph.getFullName(r.relatedTo, true)}{r.relationship ? <span className='fs-80 ml-1'>(<span className='text-success'>{r.relationship}</span>)</span> : <></>}{customer.selectedPrivateInsurance && customer.selectedPrivateInsurance.primaryPolicyHolderId === r.relatedTo.id ? <span className='fs-80 ml-1 text-danger'>- Primary policy holder</span> : <></>}
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fragment>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </section> : null
                        }
                    </>
                }</Observer>
                <Observer>{() =>
                    <section>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='form-group mb-0'>
                                    <label htmlFor='primary-policy-holder'><small>Additional Information</small></label>
                                    <RichTextEditor
                                        mode='basic'
                                        value={(customer.selectedPrivateInsurance && customer.selectedPrivateInsurance.noteHtml) ? customer.selectedPrivateInsurance.noteHtml : null}
                                        disableTab={true}
                                        onChange={handleInsuranceNoteChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                }</Observer>
            </div>
        </FadeIn> : renderQuickDrawerLoading()
    }

    const render = () => {
        if (customer.isPrivateInsuranceReady && customer.isRelationshipsReady) {
            if (!privateInsuranceProviders || privateInsuranceProviders.length === 0) {
                loadPrivateInsuranceProviders()
                    .then(() => {
                        if (isMounted.current) {
                            if (customer.selectedPrivateInsurance && customer.selectedPrivateInsurance.members) {
                                setMembers(toJS(customer.selectedPrivateInsurance.members));
                            }
                            setIsReady(true);
                        }
                    })
            }
        }

        return <></>;
    }

    const getOtherPlanMemberNames = () => {
        const originalMembers = members && members.length > 0 ? members : [];
        const newMembers = customer.selectedPrivateInsurance && customer.selectedPrivateInsurance.members && customer.selectedPrivateInsurance.members.length > 0 ? toJS(customer.selectedPrivateInsurance.members) : [];
        const sortedMembers = originalMembers.concat(newMembers).sort((a, b) => (a.dateOfBirth ? moment(a.dateOfBirth).unix() : 0) - (b.dateOfBirth ? moment(b.dateOfBirth).unix() : 0));

        return [...new Set(sortedMembers.map(m => { return ph.getPreferredFirstName(m) }))].join(', ').replace(/,(?=[^,]*$)/, ' and');
    }

    return <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) && !confirmDelete && !confirmUpdate ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form ref={validateRef} onSubmit={handleUpdatePrivateInsuranceSubmit}>
            <fieldset disabled={customer.isSaving}>
                <div className='update-customer-insurance-container quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('customer', 'private-insurance')}
                        action='Update Customer'
                        category='Insurance'
                        className='customers'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        <Observer>{() => <>{render()}</>}</Observer>
                        <Observer>{() =>
                            <>
                                {
                                    isReady ?
                                        <>
                                            {renderInsuranceInformation()}
                                        </> : null
                                }
                            </>}</Observer>
                    </div>
                    <div className='quick-drawer-action pl-3'>
                        <div className='row'>
                            <div className='col-4'>
                                <button
                                    type='button'
                                    className='btn btn-light'
                                    onClick={handleNewInsurance}
                                >Add</button>
                            </div>
                            <div className='col-8'>
                                <div className='float-right'>
                                    {
                                        customer.selectedPrivateInsurance ?
                                            <button
                                                type='button'
                                                className='btn btn-danger mr-2'
                                                disabled={!ch.checkCanUpdate(customer.data)}
                                                onClick={handleDeletePrivateInsurance}
                                            >Delete</button> : null
                                    }
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                        disabled={!ch.checkCanUpdate(customer.data)}
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
        <BodyEnd>
            <ConfirmModal
                icon={<i className={'text-warning mr-2 ' + oh.getIcon('customer', 'private-insurance')}></i>}
                message={
                    <>
                        This will also update <span className='fw-500'>&nbsp;{getOtherPlanMemberNames()}'s insurance.</span>&nbsp;&nbsp;Continue?
                    </>
                }
                show={confirmUpdate}
                option1Text={'Continue'}
                cancelText={'Cancel'}
                option1ClassName='btn btn-warning shadow-0 bootbox-accept'
                onOption1Click={handleConfirmUpdate}
                onCancel={handleCancelUpdate}
            />
            <ConfirmModal
                icon={<i className={'text-danger mr-2 ' + oh.getIcon('customer', 'private-insurance')}></i>}
                message={<>Continue to <span className='fw-500'>&nbsp;delete insurance for {(customer && customer.data ? ph.getPreferredFirstName(customer.data) : '')}</span>?</>}
                descriptionClassName='warning-danger warning-flashing'
                show={confirmDelete}
                onOption1Click={handleConfirmDelete}
                onCancel={handleCancelDelete}
            />
        </BodyEnd>
    </>
}

export default UpdatePrivateInsurance;