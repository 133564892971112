import React, { useContext, useEffect, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import FadeIn from 'react-fade-in';
import { GlobalHotKeys } from 'react-hotkeys';

import BodyEnd from '../../_shared/BodyEnd';
import ConfirmModal from '../../_shared/ConfirmModalComponent';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';

import AuthStore from '../../../../stores/AuthStore';
import ExamViewStore from '../../../../stores/ExamViewStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as fn from '../../../../utilities/_functions';
import * as ErrorMessages from '../../../../constants/errorMessages';

import './DeleteExam.scss';

function DeleteExam(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const confirmModalRef = useRef(null);
    const auth = useContext(AuthStore);
    const viewExam = useContext(ExamViewStore);
    const quickDrawer = useContext(QuickDrawerStore);

    useEffect(() => {
        quickDrawerFocus(props.drawer);

        return () => {
            isMounted.current = false;
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (viewExam.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            confirmModalRef.current.show({
                icon: <i className='fal fa-trash-alt text-danger mr-2'></i>,
                message: <>Continue to <span className='fw-500'>&nbsp;delete this exam</span>?</>,
                onOption1Click: handleConfirmDeleteExam,
                onCancel: handleConfirmCancelDeleteExam
            });
        }
    }

    const handleReasonChange = (event) => {
        const { value } = event.target;
        viewExam.deactivateReason = value;
        viewExam.hasUnsavedChanges = true;
    }

    const handleConfirmDeleteExam = event => {
        if (isMounted.current) {
            if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                props.onSuccess(event, { updated: true });
            }
            confirmModalRef.current.close();
        }
    }

    const handleConfirmCancelDeleteExam = event => {
        if (isMounted.current) {
            confirmModalRef.current.close();

            if (fn.isFunction(props.onCancel)) {
                props.onCancel();
            }
        }
    }

    const hasLockedServices = () => {
        const lockedServices = viewExam.data.services.filter(s => s.lastPublicInsuranceBilling && s.lastPublicInsuranceBilling.isLocked);
        return lockedServices && lockedServices.length > 0;
    }

    return <>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <Observer>{() =>
                <>
                    {
                        (props.drawer === quickDrawer.drawerOpened) ?
                            <GlobalHotKeys
                                keyMap={{
                                    close: ['esc'],
                                }}
                                handlers={{
                                    close: event => {
                                        handleCancel(event)
                                    },
                                }}
                                allowChanges={true}
                            /> : null
                    }
                    <fieldset>
                        <div className='quick-drawer'>
                            <QuickDrawerHeader
                                drawer={props.drawer}
                                icon={'fal fa-trash-alt'}
                                action='Delete'
                                category='Exam'
                                className='delete'
                                onCancel={handleCancel}
                            />
                            <div className='quick-drawer-body'>
                                {
                                    viewExam.isReady ?
                                        <FadeIn>
                                            <div className='delete-exam body-content'>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            {
                                                                hasLockedServices() ?
                                                                    <div className='alert alert-danger mb-0 pb-0' role='alert'>
                                                                        <strong className='d-block mb-2'>Exam cannot be deleted</strong>
                                                                        <p>Reason: An active or an approved {auth.currentTenant.publicInsuranceUnitId} claim is associated with this exam.</p>
                                                                    </div> :
                                                                    <div className='form-group validate validate-required'>
                                                                        <label className='required'><small>Delete Reason</small></label>
                                                                        <Observer>{() =>
                                                                            <textarea
                                                                                className='form-control'
                                                                                rows={5}
                                                                                value={viewExam.deactivateReason ? viewExam.deactivateReason : ''}
                                                                                onChange={handleReasonChange}
                                                                            />
                                                                        }</Observer>
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </FadeIn> : renderQuickDrawerLoading()
                                }
                            </div>
                            <Observer>{() =>
                                viewExam.isReady ?
                                    <div className='quick-drawer-action'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='float-right'>
                                                    <button
                                                        type='button'
                                                        className='btn btn-link btn-cancel'
                                                        onClick={handleCancel}
                                                    >Cancel</button>
                                                    {
                                                        !hasLockedServices() ?
                                                            <button
                                                                type='submit'
                                                                className='btn btn-danger ml-2'
                                                            >Continue</button> : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div> : null
                            }</Observer>
                        </div>
                    </fieldset>
                </>
            }</Observer>
        </form>
        <BodyEnd>
            <Observer>{() =>
                <ConfirmModal ref={confirmModalRef} />
            }</Observer>
        </BodyEnd>
    </>
}

export default DeleteExam;