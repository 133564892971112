import React, { useEffect, useContext, useRef, useState } from 'react';
import { Observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import FadeIn from 'react-fade-in';
import { GlobalHotKeys } from 'react-hotkeys';
import { toast } from 'react-toastify';
import moment from 'moment';

import BodyEnd from '../../_shared/BodyEnd';
import LoadingOverlay from '../../_shared/LoadingOverlay'
import ConfirmModal from '../../_shared/ConfirmModalComponent';
import { quickDrawerFocus } from '../../_shared/QuickDrawer';
import PublicInsuranceVerificationControl from '../../_shared/PublicInsuranceVerificationControl';
import PurchaseModal from '../../purchases/PurchaseModal/_index';

import PublicInsuranceVerificationModalStore from '../../../../stores/PublicInsuranceVerificationModalStore';
import CustomerCreateStore from '../../../../stores/CustomerCreateStore';
import AppointmentCreateStore from '../../../../stores/AppointmentCreateStore';
import PurchaseUpdateStore from '../../../../stores/PurchaseUpdateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';
import AuthStore from '../../../../stores/AuthStore';

import * as rts from '../../../../constants/routes';
import * as AccessType from '../../../../constants/accessTypes';

import * as fn from '../../../../utilities/_functions';
import * as ah from '../../../../utilities/accessHelper';
import * as ph from '../../../../utilities/personHelper';
import * as oh from '../../../../utilities/operationHelper';
import * as sys from '../../../../utilities/systemHelper';

import api from '../../../../api';

function PublicInsuranceVerification(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const modalBodyRef = useRef(null);
    const verificationRef = useRef(null);
    const purchaseModalRef = useRef(null);
    const confirmModalRef = useRef(null);
    const navigate = useNavigate();
    const modal = useContext(PublicInsuranceVerificationModalStore);
    const customer = useContext(CustomerCreateStore);
    const appointment = useContext(AppointmentCreateStore);
    const purchase = useContext(PurchaseUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const auth = useContext(AuthStore);
    const [healthCardResponse, setHealthCardResponse] = useState(null);
    const [existingCustomer, setExistingCustomer] = useState(null);
    const [subsidizedServiceResponse, setSubsidizedServiceResponse] = useState(null);

    useEffect(() => {
        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleClose = event => {
        setHealthCardResponse(null);
        setExistingCustomer(null);
        setSubsidizedServiceResponse(null);

        if (fn.isFunction(props.onClose)) {
            props.onClose(event);
        }
    }

    const handleValidateHealthCard = (result) => {
        if (result && result.isValid) {
            if (isMounted.current) {
                setHealthCardResponse(result.data);
                setSubsidizedServiceResponse(null);
                setExistingCustomer(result.customer);
            }
        }
    }

    const handleValidateSubsidizedServices = (result) => {
        if (result && result.isValid) {
            if (isMounted.current) {
                setSubsidizedServiceResponse(result.data);
            }
        }
    }

    const handleNewCustomer = event => {
        if (isMounted.current && ah.check(AccessType.UPDATE_CUSTOMER) && !!healthCardResponse) {
            const extraProps = {
                healthCard: healthCardResponse,
            }
            customer.initialize(true);
            quickDrawer.activateQuickDrawer('customer', 'create', extraProps, handleNewCustomerSuccess, handleNewCustomerCancel)
                .then(drawer => {
                    if (isMounted.current) {
                        focusTimer.current = setTimeout(() => {
                            quickDrawerFocus(drawer);
                        }, 100);

                        handleClose(event);
                    }
                });
        }
    }

    const handleNewCustomerSuccess = (result) => {
        if (result && result.updated) {
            api.Customers.get(result.data.id)
                .then(({ data: customer }) => {
                    toast.dark(() => <p data-cust-ctd>Customer created.</p>);

                    confirmModalRef.current.show({
                        icon: <i className={`${oh.getIcon('customer')} text-primary mr-2`}></i>,
                        message: <>What would you like to do for&nbsp;<strong>{ph.getPreferredFirstName(customer)}</strong>?</>,
                        option1ClassName: 'btn btn-primary shadow-0 bootbox-accept',
                        option1Text: 'New Appointment',
                        option2ClassName: 'btn btn-secondary shadow-0 bootbox-accept',
                        option2Text: 'New Invoice',
                        cancelText: 'Cancel',
                        onOption1Click: (e) => { handleNewCustomerAppointment(e, customer) },
                        onOption2Click: (e) => { handleNewCustomerPurchase(e, customer); },
                        onCancel: () => { confirmModalRef.current.close(); }
                    })
                })
        }

        customer.clear();
    }

    const handleNewCustomerAppointment = (event, customer) => {
        let delay = 500;

        if (!window.location.pathname.startsWith(rts.Appointments.Home)) {
            navigate(rts.Appointments.Home);
            delay = 0;
        }

        setTimeout(() => {
            appointment.initialize(true);
            appointment.stage(customer);

            if (!!subsidizedServiceResponse && subsidizedServiceResponse.length > 0) {
                appointment.data.services = subsidizedServiceResponse.map(s => {
                    const { service } = s;
                    return {
                        id: service.id,
                        code: service.code,
                        name: service.code,
                        colorHexValue: service.colorHexValue,
                        duration: (service.defaultDurationInMinutes ? service.defaultDurationInMinutes : 0),
                        isRepeatable: service.isRepeatable,
                    }
                });
                appointment.data.duration = appointment.data.services.reduce((acc, s) => {
                    return acc + s.duration;
                  }, 0);
            }
            
            confirmModalRef.current.close();
            handleClose(event);
        }, delay);

    }

    const handleNewCustomerPurchase = (event, customer) => {
        purchase.start(customer)
            .then(() => {
                purchaseModalRef.current.changeMode('update');
                purchaseModalRef.current.show();
            })

        confirmModalRef.current.close();
        handleClose(event);
    }

    const handleNewCustomerCancel = event => {
        customer.clear();
    }

    const handleAddExistingCustomerAppointment = event => {
        if (isMounted.current && ah.check(AccessType.UPDATE_CUSTOMER) && ah.check(AccessType.UPDATE_APPOINTMENT) && !!existingCustomer) {
            let delay = 500;

            if (!window.location.pathname.startsWith(rts.Appointments.Home)) {
                navigate(rts.Appointments.Home);
                delay = 0;
            }

            setTimeout(() => {
                appointment.initialize(true);
                appointment.stage(existingCustomer);

                if (!!subsidizedServiceResponse && subsidizedServiceResponse.length > 0) {
                    appointment.data.services = subsidizedServiceResponse.map(s => {
                        const { service } = s;
                        return {
                            id: service.id,
                            code: service.code,
                            name: service.code,
                            colorHexValue: service.colorHexValue,
                            duration: (service.defaultDurationInMinutes ? service.defaultDurationInMinutes : 0),
                            isRepeatable: service.isRepeatable,
                        }
                    });
                    appointment.data.duration = appointment.data.services.reduce((acc, s) => {
                        return acc + s.duration;
                      }, 0);
                }

                handleClose(event);
            }, delay);
        }
    }

    const renderEligibleDescription = (data) => {
        if (data) {
            const { isMohReady, isEligible, ineligibilityCode, ineligibilityReason, earliestEligibleDate } = data;

            if (!!isEligible && !!isMohReady) {
                return <span className='fw-500 text-success'>Eligibility confirmed.</span>
            }
            else if (!isMohReady) {
                return <span className='fw-500 text-warning-900'>{auth.currentTenant.publicInsuranceUnitId} verification has not been setup.</span>
            }
            else if (!!earliestEligibleDate) {
                return <span className='fw-500 text-danger'>{ineligibilityCode ? `(Error: ${ineligibilityCode}) ` : ''}Not eligible until <span className='tt-underline'>{moment(earliestEligibleDate).format('YYYY-MM-DD')}</span>.</span>
            }
            else if (!!ineligibilityReason) {
                return <span className='fw-500 text-danger'>{ineligibilityCode ? `(Error: ${ineligibilityCode}) ` : ''}{ineligibilityReason}</span>
            }
            else {
                return <span className='fw-500 text-danger'>Unknown reason.</span>
            }
        }
    }

    const renderTitle = () => {
        return <div className='popup-title'>
            <div className='actions left-actions pt-1'>
                <ul className='ml-2'>
                    <li>
                        <h4 className='mb-0'>Check {auth.currentTenant.publicInsuranceUnitId} Eligibility</h4>
                    </li>
                </ul>
            </div>
            <div className='actions right-actions'>
                <ul>
                    <li>
                        <button
                            type='button'
                            className='btn btn-icon btn-close'
                            onClick={handleClose}
                        >
                            <i className='close-icon fal fa-times fs-xl'></i>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    }

    const setFocus = () => {
        setTimeout(() => {
            verificationRef.current.healthCardInput.inputElement.focus();
        }, 300);
    }

    return <>
        <Observer>{() =>
            <>
                {
                    modal.isActivated ?
                        <>
                            <GlobalHotKeys
                                keyMap={{
                                    close: ['esc'],
                                }}
                                handlers={{
                                    close: event => {
                                        handleClose(event)
                                    },
                                }}
                                allowChanges={true}
                            />
                            {setFocus()}
                        </> : null
                }
            </>
        }</Observer>
        <Observer>{() =>
            <>
                {
                    modal.isReady ?
                        <>
                            <Observer>{() => <LoadingOverlay isLoading={modal.isLoading} />}</Observer>
                            {renderTitle()}
                            <div ref={modalBodyRef}>
                                <FadeIn>
                                    <div className='p-4'>
                                        <PublicInsuranceVerificationControl
                                            ref={verificationRef}
                                            subsidizedServices={modal.subsidizedServices}
                                            onValidateHealthCard={handleValidateHealthCard}
                                            onValidateSubsidizedServices={handleValidateSubsidizedServices}
                                        />
                                        {
                                            healthCardResponse ?
                                                <>
                                                    <div className='row mt-2'>
                                                        <div className='col-12'>
                                                            <div className='mt-2'>
                                                                <label><small>Result</small></label>
                                                                <div className='border border-1 w-100 p-3'>
                                                                    <table className='table table-borderless m-0'>
                                                                        <colgroup>
                                                                            <col />
                                                                            <col className='w-100' />
                                                                        </colgroup>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td className='fw-500 text-right pl-0 py-0'><span className='text-nowrap'>Health Card:</span></td>
                                                                                <td className='p-0'>
                                                                                    {
                                                                                        healthCardResponse.isValid ?
                                                                                            <span className='fw-500 text-success'>Valid{healthCardResponse.expiryDate ? ` until ${sys.getFormattedShortDate(moment(healthCardResponse.expiryDate, 'YYYY-MM-DD'))}.` : ''}</span> :
                                                                                            <span className='fw-500 text-danger'>{(!!healthCardResponse.errorCode ? `(Error: ${healthCardResponse.errorCode}) ` : '')}{healthCardResponse.errorDescription}</span>
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                            {
                                                                                healthCardResponse && healthCardResponse.exists ?
                                                                                    <>
                                                                                        <tr>
                                                                                            <td className='fw-500 text-right pt-2 pl-0 pb-0'><span className='text-nowrap'>Patient Name:</span></td>
                                                                                            <td className='pt-2 pl-0 pb-0'>
                                                                                                {ph.getFullName(healthCardResponse)}
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className='fw-500 text-right pl-0 py-0'><span className='text-nowrap'>Date of Birth:</span></td>
                                                                                            <td className='p-0'>
                                                                                                {
                                                                                                    healthCardResponse.dateOfBirth ?
                                                                                                        <>
                                                                                                            {sys.getFormattedLongDate(moment(healthCardResponse.dateOfBirth).format('YYYY-MM-DD'))}&nbsp;<small>({ph.getAge(moment(healthCardResponse.dateOfBirth).format('YYYY-MM-DD'))} {ph.getSexDescription(healthCardResponse.sex)})</small>
                                                                                                        </> : null
                                                                                                }
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className='fw-500 text-right pl-0 py-0'><span className='text-nowrap'>Existing Patient:</span></td>
                                                                                            <td className='p-0'>
                                                                                                {
                                                                                                    existingCustomer ?
                                                                                                        <>Yes</> :
                                                                                                        <>No</>
                                                                                                }
                                                                                            </td>
                                                                                        </tr>
                                                                                        {
                                                                                            healthCardResponse.exists && subsidizedServiceResponse && subsidizedServiceResponse.length > 0 ?
                                                                                                <>
                                                                                                    <tr><td className='fw-500 text-right pt-2 pl-0 pb-1'><span className='text-nowrap'>Service(s):</span></td><td></td></tr>
                                                                                                    {
                                                                                                        subsidizedServiceResponse
                                                                                                            .sort((a, b) => a.code - b.code)
                                                                                                            .map((s, si) => {
                                                                                                                return <tr key={`__public-insurance-subsidized-services_${si}`}>
                                                                                                                    <td className='fw-500 text-right pl-0 py-0'><span className='text-nowrap'>{s.code}:</span></td>
                                                                                                                    <td className='p-0'>{renderEligibleDescription(s)}</td>
                                                                                                                </tr>
                                                                                                            })
                                                                                                    }
                                                                                                </> : null
                                                                                        }
                                                                                    </> : null
                                                                            }
                                                                            { }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        healthCardResponse && healthCardResponse.exists ?
                                                            <div className='row mt-4'>
                                                                <div className='col-12'>
                                                                    <div className='text-right'>
                                                                        {
                                                                            existingCustomer ?
                                                                                <>
                                                                                    <a
                                                                                        href={`${rts.Customers.Home}/${existingCustomer.id}`}
                                                                                        className='btn btn-light mr-2'
                                                                                    >
                                                                                        Go to Patient file
                                                                                    </a>
                                                                                    <button
                                                                                        type='button'
                                                                                        className='btn btn-primary'
                                                                                        onClick={handleAddExistingCustomerAppointment}
                                                                                    >
                                                                                        Add Appointment
                                                                                    </button>
                                                                                </> :
                                                                                <>
                                                                                    <button
                                                                                        type='button'
                                                                                        className='btn btn-primary'
                                                                                        onClick={handleNewCustomer}
                                                                                    >
                                                                                        Add Patient
                                                                                    </button>
                                                                                </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div> : null
                                                    }
                                                </> : null
                                        }
                                    </div>
                                </FadeIn>
                            </div>
                        </> : null
                }
            </>
        }</Observer>
        <Observer>{() =>
            <BodyEnd>
                <PurchaseModal ref={purchaseModalRef} defaultMode='view'
                // onSuccess={handlePurchaseModalSuccess} onClose={handlePurchaseModalClose} 
                />
                <ConfirmModal ref={confirmModalRef} />
            </BodyEnd>
        }</Observer>
    </>
}

export default PublicInsuranceVerification;