import { useState, useEffect, useContext } from 'react';
import SignalRContext from '../../components/SignalRContext';
import uuid from 'react-uuid';

function useSignalR(label) {
    const [subscriberId] = useState(uuid());
    const signalRContext = useContext(SignalRContext);

    useEffect(() => {
        if (!signalRContext.isConnected) {
            signalRContext.connect();
        }

        return () => {
            signalRContext.offAll(subscriberId);
        }
    }, []) // eslint-disable-line

    const subscribeMessage = (message, callback) => {
        signalRContext.on(subscriberId, message, callback);
    }

    const unsubscribeMessage = (message) => {
        signalRContext.off(subscriberId, message);
    }

    return { on: subscribeMessage, off: unsubscribeMessage, subscriberId, connectionId: signalRContext._connection.connectionId, label };
}

export default useSignalR;